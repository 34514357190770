export const LOGISTIC = [
  {
    id: 1,
    name: 'รับยาที่ร้านยาคุณภาพ',
  },
  {
    id: 2,
    name: 'รับยาที่บ้านทางไปรษณีย์',
  },
  {
    id: 3,
    name: 'รับยาที่โรงพยาบาลวชิรพยาบาล',
  },
]

export const DISTRICT = {
  'Khet Dusit': 'ดุสิต',
  'Khet Bang Phlat': 'บางพลัด',
  'Khet Bang Sue': 'บางซื่อ',
  'Khet Phra Nakhon': 'พระนคร',
}

/* eslint-disable no-empty-pattern */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { FileOutlined } from '@ant-design/icons'
import { Input, Modal, Button, Popconfirm, Tag, Row, Col, Spin } from 'antd'
import styled from 'styled-components'
import Table from '../Table'
import TextBase from '../Text'
import nemesis from '../../images/nemesis.jpg'
import { Formik } from 'formik'

const { Search: SearchAnt } = Input
const Text = styled(TextBase)`
  font-weight: bold;
`
const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`

const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
const Container = styled.div`
  width: 100%;
  padding: 20px;
`

function PatientListTable({ state, history, verifyStatus, fetchUserImage, checkUserDetail, checkMonitoringReport, loading, modalLoading, ...rest }) {
  const [visibleProfile, setVisibleProfile] = useState(false)
  const [profileImage, setProfileImage] = useState()
  const [visiblePatientProfile, setVisiblePatientProfile] = useState()
  const [patientId, setPatientId] = useState()
  const [patient, setPatient] = useState()
  const [showPatientProfile, setShowPatientProfile] = useState(false)
  const [filterData, setFilterData] = useState(false)
  const setColor = (status) => {
    if (status) {
      return 'green'
    }
    return 'red'
  }
  const convertHn = (hn) => {
    const second = hn.substring(0, 2)
    const past = hn.substring(2).split('')
    let pass = 0
    const final = past.map((string) => {
      if (pass || string !== '0') {
        pass = 1
        return string
      }
    })
    return final.join('').concat('/', second)
  }
  const columns = [
    {
      key: 'action',
      width: 200,
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            history.push({
              pathname: `/telemonitoring/${encodeURIComponent(`patientId=${record.id}&userId=${record.userId}`)}`,
            })
          }}
        >
          ดูข้อมูลผู้ป่วย
        </Button>
      ),
      align: 'center',
    },
    {
      title: 'ไอดีผู้ป่วย',
      key: 'customerID',
      width: 200,
      render: (record) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setVisibleProfile(true)
            fetchUserImage(record.userId)
          }}
        >
          <Text>{record.userId}</Text>
        </div>
      ),
      align: 'center',
    },
    {
      title: 'หมายเลขผู้ป่วย',
      key: 'hnId',
      width: 200,
      render: (record) => <Text>{record.hnId && convertHn(record.hnId)}</Text>,
      align: 'center',
    },
    {
      title: 'ชื่อผู้ป่วย',
      key: 'date',
      width: 200,
      render: (record) => (
        <Text
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setVisibleProfile(true)
            fetchUserImage(record.userId)
          }}
        >
          {record.firstname} {record.lastname}
        </Text>
      ),
    },
    {
      title: 'อีเมลผู้ป่วย',
      key: 'email',
      width: 300,
      render: (record) => <Text>{record.email}</Text>,
    },
    {
      title: 'เลขบัตรประชาชน',
      key: 'cId',
      width: 300,
      render: (record) => <Text>{record.cId}</Text>,
    },
    {
      title: 'เบอรโทรศัพท์',
      key: 'mobileNumber',
      width: 300,
      render: (record) => <Text>{record.mobileNumber}</Text>,
    },
  ]

  const onSearch = (text) => {
    const filter = state.dataSource.filter((data) => {
      if (data) {
        const fullname = data.firstname && data.lastname ? `${data.firstname} ${data.lastname}` : false
        return (data.firstname && data.firstname.includes(text)) || (data.lastname && data.lastname.includes(text)) || (data.cId && data.cId.includes(text)) || (fullname && fullname.includes(text))
      }
    })
    setFilterData(filter)
  }

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Spin></Spin>
        </div>
      ) : (
        <Formik initialValues={{ filter: '' }}>
          {(props) => (
            <Container>
              <HeaderWrapper>
                <FileOutlined />
                <Text bold>รายชื่อผู้ป่วย</Text>
                <Search onSearch={onSearch} style={{ width: 200, marginLeft: 'auto' }} />
              </HeaderWrapper>
              <Table scroll={{ x: 'max-content' }} columns={columns} dataSource={filterData || state.dataSource} {...rest} />
              <Modal title="รูปภาพ" visible={visibleProfile} style={{ textAlign: 'center' }} onCancel={() => setVisibleProfile(false)} footer={null}>
                {modalLoading ? <Spin></Spin> : state.image && <img width={200} src={`${state.image}`} />}
              </Modal>
              <Modal
                title="รายละเอียดผู้ป่วย"
                visible={visiblePatientProfile}
                style={{ textAlign: 'center' }}
                onCancel={() => {
                  setVisiblePatientProfile(false)
                }}
                onOk={() => {
                  verifyStatus(patientId)
                  setVisiblePatientProfile(false)
                }}
              >
                {state.userDetail ? (
                  <div>
                    <Row>
                      <Col span={4} className="gutter-row text-left">
                        ชื่อผู้ป่วย
                      </Col>
                      <Col span={20} className="gutter-row text-left">
                        {`${state.userDetail.fullname.title} ${state.userDetail.fullname.firstName} ${state.userDetail.fullname.lastName}`}
                      </Col>
                    </Row>
                    {state.userDetail.drugAllergies.map((drugAllergy) => (
                      <div>
                        <Row>
                          <Col span={4} className="gutter-row text-left">
                            ชื่อยา
                          </Col>
                          <Col span={20} className="gutter-row text-left">
                            {drugAllergy.genericname}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={4} className="gutter-row text-left">
                            อาการ
                          </Col>
                          <Col span={20} className="gutter-row text-left">
                            {drugAllergy.symptom}
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>ไม่มีข้อมูล</div>
                )}
              </Modal>
            </Container>
          )}
        </Formik>
      )}
    </>
  )
}
export default PatientListTable

import React, { useEffect, useState } from 'react'
import Layout from '../Home'
import { Container, HeaderWrapper, Text, Card } from './style'
import { Row, Col, Radio, Space, DatePicker, ConfigProvider } from 'antd'
import { CarOutlined } from '@ant-design/icons'
import CarBooking from '../../components/CarBooking'
import { hooks } from './hooks'
import moment from 'moment'
import 'moment/locale/th'
import thTH from 'antd/es/locale/th_TH'
import { Item } from 'rc-menu'
const CarParkReport = (props) => {
  const { history } = props
  const [date, setDate] = useState(moment())
  const [type, setType] = useState('today')
  const [carLists, setCarLists] = useState([])
  const { events, params } = hooks()

  useEffect(() => {
    events.dispatch(events.fetchBookings())
  }, [])

  useEffect(() => {
    handleCarLists()
  }, [params.donorBookings, date])

  const handleCarLists = () => {
    console.log(params.donorBookings, 12345)
    const filterByDate = params.donorBookings.filter((booking) => {
      return moment(date).format('DD/MM/YYYY') === moment(booking.donationDate).format('DD/MM/YYYY') && booking.isNeedParking === true
    })

    let timeSlot = {}
    filterByDate.map((item) => {
      if (timeSlot[item.donationTime.join('+')]) {
        timeSlot[item.donationTime.join('+')] += 1
      } else {
        timeSlot[item.donationTime.join('+')] = 1
      }
    })
    setCarLists(Object.entries(timeSlot))
  }
  return (
    <Layout
      history={history}
      render={() => {
        return (
          <div className="w-100">
            <Container>
              <HeaderWrapper>
                <CarOutlined className="mr-1" />
                <Text bold>รายงานการจองที่จอดรถ</Text>
              </HeaderWrapper>
            </Container>
            <Container>
              <Radio.Group
                onChange={(e) => {
                  setType(e.target.value)
                  if (e.target.value === 'today') {
                    setDate(moment())
                  } else if (e.target.value === 'tomorrow') {
                    setDate(moment().add(1, 'days'))
                  }
                }}
                value={type}
              >
                <Radio value={'today'}>วันนี้</Radio>
                <Radio value={'tomorrow'}>พรุ่งนี้</Radio>
              </Radio.Group>
              <Space direction="vertical" size={12} className="mb-3">
                <ConfigProvider locale={thTH}>
                  <DatePicker
                    placeholder={'เลือกเวลา'}
                    onChange={(date) => {
                      if (date) {
                        setType(null)
                        setDate(date)
                      } else {
                        setType('today')
                        setDate(moment())
                      }
                    }}
                    format={(value) =>
                      moment(value)
                        .add(543, 'years')
                        .format('LL')
                    }
                  />
                </ConfigProvider>
              </Space>
              <Row justify="center">
                <Col sm={24} md={24} lg={12}>
                  <Card>
                    <h2 style={{ color: '#1b4b63' }}>ยอดจองที่จอดรถ</h2>
                    <h2 style={{ color: '#1b4b63' }}>{`ประจำวันที่ ${moment(date)
                      .add(543, 'years')
                      .format('LL')}`}</h2>
                    <h4>{`ข้อมูล ณ เวลา ${moment()
                      .add(543, 'years')
                      .format('LL HH:mm')}น.`}</h4>
                  </Card>
                </Col>
              </Row>
              <Row justify="center" gutter={[20, 20]}>
                {carLists.map((item) => (
                  <Col sm={24} md={8} lg={6}>
                    <CarBooking data={item} />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        )
      }}
    />
  )
}

export default CarParkReport

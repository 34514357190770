import React, { useEffect } from 'react'
import { Card, Text, TitleText } from './style'
import moment from 'moment'
const CarBooking = ({ data }) => {
  useEffect(() => {
    console.log(data, 'data')
  }, [data])

  const convertTime = (start, end) => {
    return `${moment()
      .startOf('isoWeek')
      .add(start, 'minutes')
      .format('HH:mm')} - ${moment()
      .startOf('isoWeek')
      .add(end, 'minutes')
      .format('HH:mm')}`
  }
  return (
    <Card>
      <TitleText>{`รอบ ${convertTime(data[0].split('+')[0], data[0].split('+')[1])}`}</TitleText>
      <Text>{`${data[1]} คัน`}</Text>
    </Card>
  )
}

export default CarBooking

import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'
const apiEndpoint = '/emsForms'
const apiEndpointReject = '/EmsRejectLogs'
const apiEndpointEmsTypologies = '/EmsTypologies'

class EmsForm {
  @observable info = {
    data: [],
    error: null,
  }

  @action fetchEmsForm = async () => {
    try {
      const { data } = await http.get(apiEndpoint)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchEmsFormReport = async (startDateTime, endDateTime) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/report?startDateTime=${startDateTime}&endDateTime=${endDateTime}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchEmsFormReject = async (startDateTime, endDateTime) => {
    try {
      const { data } = await http.get(`${apiEndpointReject}?filter[include]=appUser&filter[where][calledDate][between][0]=${startDateTime}&filter[where][calledDate][between][1]=${endDateTime}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchEmsTypologies = async () => {
    try {
      const { data } = await http.get(apiEndpointEmsTypologies)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action createEmsForm = async (staffId, status, userId, dataForm = {}, emsType, operationDateTime) => {
    try {
      const response = await http.patch(apiEndpoint, {
        staffId,
        status,
        userId,
        ...dataForm,
        emsType,
        operationDateTime,
        responseData: { operationDateTime },
        stateTimeStamp: [{ status, date: new Date().toLocaleString() }],
      })
      return response
    } catch (error) {
      console.log(error)
    }
  }

  @action updateEmsForm = async (id, detail) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${id}`, detail)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action findEmsFormByUserId = async (userId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/?filter[where][userId]=${userId}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action findEmsFormById = async (id) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/?filter[where][id]=${id}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @computed get getEmsForm() {
    return toJS(this.booking.data)
  }
}

export default new EmsForm()

import React, { useEffect, useRef, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Form, Input, Select, Cascader, Popconfirm, Upload, Modal , Spin , message } from 'antd'
import styled from 'styled-components'
import './upload.css'
import { FiUpload, FiTrash, FiEye } from 'react-icons/fi'
import imageCompression from 'browser-image-compression';
import firebase from 'firebase'
const InputFile = styled('input')`
  color: #262626;
  background-color: #ebebeb;
  display: inline-block;
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: none;
  cursor: pointer;
  width: 0;
  height: 0;
`
const FormBox = styled(Row)`
  padding: 10px;
`
const Text = styled('p')`
  font-size: 18px;
  padding-right: 10px;
  font-weight: bold;
`

const loadIcon = <LoadingOutlined style={{ fontSize: 14, color: 'white' }} spin />;


const UploadImage = ({ handleValue, value, label, preview , path }) => {
  const fileInput = useRef()
  const uploaderId = label.toLowerCase().replace(/\W+/g, '-')
  const [ sizeNow, setSizeNow ] = useState('');
  const [ sizeOrigin, setSizeOrigin ] = useState('');
  const [loading ,setLoading ] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  
  const uploadImageToStorageHandler = async (file) => {
    try {
      const storage = firebase.storage()
      const refImage = storage.ref(path + `/${uploaderId}`)
      await refImage.put(file)
      const imageUrl = await refImage.getDownloadURL();
      return imageUrl
    }catch(e) {
      console.log(e)
      message.error("อัพโหลดผิดพลาด")
      setLoading(false)
      return false
    }
  }

  const deleteImageFromStorageHandler =  () => {
    fileInput.current.value = null
    handleValue()
  }

  const getBase64 = (file) => {
    return new Promise(async (resolve,reject) => {
      setLoading(true)
      let baseURL = '';
      let resize = '';
      const originSize = (file.size / 1024 / 1024).toFixed(2);
      // Make new FileReader
      let reader = new FileReader()
      if(originSize > 0.3){
        resize = await resizeFile(file);
      } else {
        resize = file;
        setSizeNow(`คงเดิม ${originSize} MB`); 
      }
      const response = await uploadImageToStorageHandler(resize)
      setLoading(false)
      if(response) {
        resolve(response)
      } else {
        reject(response)
      }
      setSizeOrigin(`รูปภาพขนาด ${originSize} MB`)
      // Convert the file to base64 text
    })
  }

  
  const resizeFile = async (file) => {
      const options = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1366,
        useWebWorker: true
      }
      const compressedFile = await imageCompression(file, options);
      setSizeNow(`ปรับขนาดเป็น ${(compressedFile.size / 1024 / 1024).toFixed(2)} MB`); // smaller than maxSizeMB
      return compressedFile
  };

  return (
    <div className="uploader-wrapper">
      <label className="uploader-label ant-btn ant-btn-primary" htmlFor={`upload-button-${uploaderId}`}>
       {loading ? <Spin className='mr-2' indicator={loadIcon} /> : <FiUpload />}
        {label}
      </label>
      <input
        ref={fileInput}
        type="file"
        accept="image/png, image/jpeg"
        id={`upload-button-${uploaderId}`}
        onChange={(e) => {
          getBase64(e.target.files[0]).then((base64) => {
            handleValue(base64)
          })
        }}
      />
      {preview && 
      <div className='image-preview'>
        <div className='middle row'>
          <div className='mr-2' onClick={()=> setPreviewVisible(true)}>
            <FiEye/>
          </div>
          <Popconfirm className='ml-2' placement="topLeft" title="This image will be removed" onConfirm={deleteImageFromStorageHandler} okText="Remove" okType="primary" cancelText="No">
            <FiTrash/>
          </Popconfirm>
        </div>
        <div className='black-bg'>
          <img src={preview} style={{ maxWidth: '400px' }} />
        </div>
        <label style={{padding : 5}}>{sizeOrigin} {sizeNow} </label>
      </div>
      }
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        width={window.innerWidth / 1.7}
      >
        <img
          alt="example"
          style={{
            width: "100%"
          }}
          src={preview}
        />
      </Modal>
    </div>
  )
}

export default UploadImage

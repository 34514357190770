import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchBookings } from '../../store/bloodBookingSlice'
const hooks = () => {
  const dispatch = useDispatch()
  const [donorBookings, isDonorBookingLoading, donorErrorMessage] = useSelector((state) => {
    const { donorBookingLists, isLoadingData, donorErrorMessage } = state.bloodDonationBooking
    return [donorBookingLists, isLoadingData]
  })

  return {
    events: {
      dispatch,
      fetchBookings,
    },
    params: {
      isDonorBookingLoading,
      donorBookings,
      donorErrorMessage,
    },
  }
}

export { hooks }

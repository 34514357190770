import React, { useEffect } from 'react'
import Layout from '../Home'
import { Button, Input, message, Space } from 'antd'
import { PlusCircleFilled, SolutionOutlined } from '@ant-design/icons'
import { hooks } from './hooks'
import { HeaderWrapper, Container, Text } from './style'
import HealthCheckUpNoteTable from '../../components/HealthCheckUpNoteTable'
import CreateHealthCheckUp from '../../components/Modal/CreateHealthCheckUp'

const { Search } = Input

const index = (props) => {
  const { events, params } = hooks()

  useEffect(() => {
    events.fetchAllCheckUpNotes(params.tablePage, params.tableRecordLimit, null)
    events.fetchDoctorAdvices()
  }, [])

  return (
    <Layout
      history={props.history}
      render={() => (
        <Container>
          <Space
            direction="vertical"
            size="middle"
            style={{
              display: 'flex',
            }}
          >
            <HeaderWrapper>
              <SolutionOutlined className="mr-1" />
              <Text bold>{`รายการผลตรวจสุขภาพ (${params.count})`}</Text>
              <Search
                allowClear
                style={{ width: 300, marginLeft: 'auto' }}
                placeholder="ชื่อ / บัตรประชาชน / hn"
                onSearch={(keyword) => {
                  events.setSearchKeyword(keyword)
                  events.fetchAllCheckUpNotes(1, params.tableRecordLimit, keyword)
                  events.setTablePage(1)
                }}
              />
            </HeaderWrapper>
            <Button onClick={() => events.setVisibleCreateNewModal(true)} type="primary" icon={<PlusCircleFilled />} className="d-flex align-items-center">
              เพิ่มรายการใหม่
            </Button>
            <HealthCheckUpNoteTable
              keyword={params.searchKeyword}
              page={params.tablePage}
              limit={params.tableRecordLimit}
              setLimit={events.setTableRecordLimit}
              setPage={events.setTablePage}
              setCount={events.setCount}
              fetchAllCheckUpNotes={events.fetchAllCheckUpNotes}
              convertHn={events.convertHn}
            />
          </Space>
          <CreateHealthCheckUp
            visibleModal={params.visibleCreateNewModal}
            setVisibleModal={events.setVisibleCreateNewModal}
            isModalLoading={params.isModalLoading}
            patientInfo={params.patientInfo}
            latestSummary={params.latestSummary}
            doctorAdvices={params.doctorAdvices}
            convertHn={events.convertHn}
            onSearch={(cid) => {
              events.checkLatestCheckUpSummary(cid)
            }}
            createCheckUpNote={events.createCheckUpNote}
            setPatientInfo={events.setPatientInfo}
            setLatestSummary={events.setLatestSummary}
          />
        </Container>
      )}
    />
  )
}

export default index

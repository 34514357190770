import React, { useState, useEffect, useRef } from 'react'
import { Video } from './styles'
import AudioLevelIndicator, {
  useVolume
} from '../../components/Video/AudioLevelIndicator/AudioLevelIndicator'
import NetworkQualityLevel from '../../components/NetworkQualityLevel/NetworkQualityLevel'
import usePublications from '../../hooks/Video/usePublications/usePublications'
import useTrack from '../../hooks/Video/useTrack/useTrack'
import Button from '../../components/Button'
const Participant = ({ participant, videoWidth, isMute, index }) => {
  const publications = usePublications(participant)
  const audioPublication = publications.find(p => p.kind === 'audio')
  const audioTrack = useTrack(audioPublication)
  const volume = useVolume(audioTrack)
  const [videoTracks, setVideoTracks] = useState([])
  const [audioTracks, setAudioTracks] = useState([])
  const [rotate, setRotate] = useState(false);
  const videoRef = useRef()
  const audioRef = useRef()

  const trackpubsToTracks = trackMap => Array.from(trackMap.values())
    .map(publication => publication.track)
    .filter(track => track !== null)

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks))
    setAudioTracks(trackpubsToTracks(participant.audioTracks))

    const trackSubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => [...videoTracks, track])
      } else if (track.kind === 'audio') {
        setAudioTracks(audioTracks => [...audioTracks, track])
      }
    }

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track))
      } else if (track.kind === 'audio') {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track))
      }
    }

    participant.on('trackSubscribed', trackSubscribed)
    participant.on('trackUnsubscribed', trackUnsubscribed)

    return () => {
      setVideoTracks([])
      setAudioTracks([])
      participant.removeAllListeners()
    }
  }, [participant])

  useEffect(() => {
    const videoTrack = videoTracks[0]
    if (videoTrack) {
      videoTrack.attach(videoRef.current)
      return () => {
        videoTrack.detach()
      }
    }
  }, [videoTracks])

  useEffect(() => {
    const audioTrack = audioTracks[0]
    if (audioTrack) {
      audioTrack.attach(audioRef.current)
      return () => {
        audioTrack.detach()
      }
    }
  }, [audioTracks])
  return (
    <Video width={videoWidth}>
      <AudioLevelIndicator volume={volume} />
      <style>{`
        @media (max-width: 1440px) {
          .relative-name > h3 {
            font-size : 16px ;
          }
        }
        @media (max-width: 1024px) {
          .relative-name > h3{
            font-size : 14px;
          }
        }
        @media (max-width: 768px) {
          .relative-name > h3 {
            font-size : 12px;
          }
        }
      `}</style>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>

        <video
          ref={videoRef}
          style={{
            backgroundColor: '#000000',
            width: '100%',
            height: '100%',
            transform: rotate ? "rotateY(180deg)" : "rotateY(0deg)"
          }}
        />
        <audio ref={audioRef} />
        <div style={{ position: 'absolute', top: 15, right: 15, zIndex: 10 }}>
          <NetworkQualityLevel participant={participant} />
        </div>
        <div className='d-flex relative-name' style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9,
        }}>
          <h3 style={{
            width: 'max-content',
            position: 'absolute',
            top: 15,
            left: '50%',
            transform: 'translateX(-50%)',
            color: 'white',
            fontWeight: 'bolder',
            padding: 10,
            borderRadius: 15,
            background: '#00000050',
          }}>
            {participant.identity}
          </h3>
        </div>
        <div onClick={() => setRotate(!rotate)} className='d-flex' style={{ position: 'relative', alignItems: 'flex-end', display: 'flex', zIndex: 9, cursor: 'pointer' }}>
          <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 386.5 381">
              <g id="Group_4" data-name="Group 4" transform="translate(-783 -451)">
                <g id="Group_3" data-name="Group 3">
                  <g id="Group_1" data-name="Group 1">
                    <path id="Path_1" data-name="Path 1" d="M177,124.137V463H0Z" transform="translate(783 348)" fill="#fff" />
                    <path id="Path_2" data-name="Path 2" d="M131.323,124.137V375.552H0Z" transform="translate(815.677 415.863)" />
                  </g>
                  <g id="Group_2" data-name="Group 2" transform="translate(216)">
                    <path id="Path_1-2" data-name="Path 1" d="M0,124.137V463H170.5Z" transform="translate(783 348)" fill="#fff" />
                  </g>
                  {rotate ?
                    (
                      <rect id="Rectangle_1" data-name="Rectangle 1" width="15" height="381" transform="translate(972 451)" fill="#fff" />
                    )
                    :
                    (
                      <rect id="Rectangle_1" data-name="Rectangle 1" width="15" height="381" transform="translate(972 451)" fill="#000" />
                    )
                  }
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </Video>
  )
}

export default Participant

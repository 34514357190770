import React, { useEffect, useState } from 'react'
import { Tag, Input, Button, Popconfirm } from 'antd'
import Table from '../Table'
import styled from 'styled-components'
import TextBase from '../Text'
import moment from 'moment'
import { SyncOutlined } from '@ant-design/icons'

const { Search: SearchAnt } = Input

const Container = styled.div`
  width: 100%;
  padding: 20px;
`
const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`

const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`

const Text = styled(TextBase)`
  font-weight: bold;
`

const FollowUpBookingTable = ({ followUpBookings, loading, buttonLoading, onSearch, fetchNextAppointment, handleUpdateBooking }) => {
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    onSearch(searchText)
  }, [searchText])

  const columns = [
    {
      title: 'ชื่อผู้ป่วย',
      width: '50',
      key: 'patientName',
      align: 'center',
      render: (record) => <Text>    {record.patient && record.patient.fullnameHIE ? record.patient.fullnameHIE : (record.patient && record.patient.fullname ? record.patient.fullname + ' (ชื่อจากApp)' : '')}</Text>,
    },
    {
      title: 'แพทย์',
      width: '50',
      key: 'doctorName',
      align: 'center',
      render: (record) => <Text>{record.doctor?.fullname || '-'}</Text>,
    },
    {
      title: 'เวลานัดหมาย',
      width: '50',
      key: 'admitTime',
      align: 'center',
      render: (record) => (
        <Text>
          {moment(record.admitTime).format('DD/MM/YYYY') || '-'}{' '}
          {record.bookingTime &&
            moment()
              .startOf('day')
              .add(record.bookingTime, 'minutes')
              .format('HH:mm')}
        </Text>
      ),
    },
    {
      title: 'นัดหมายครั้งต่อไป',
      width: '50',
      key: 'nextAppointment',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.admitTime).unix() - moment(b.admitTime).unix(),
      render: (record) => {
        if (record.bookingType === 'ARI (OPSI)') {
          return (
            <Text>
              {moment(record.nextAppointment).format('DD/MM/YYYY')}{' '}
              {moment()
                .startOf('day')
                .add(record.bookingTime, 'minutes')
                .format('HH:mm')}
            </Text>
          )
        } else if (record.nextAppointment) {
          return <Text>{moment(record.nextAppointment).format('DD/MM/YYYY HH:mm')}</Text>
        } else {
          return (
            <Button type="primary" key={record.id} onClick={() => fetchNextAppointment(record.id)} loading={buttonLoading[record.id]}>
              ดึงข้อมูลนัดหมายครั้งต่อไป
            </Button>
          )
        }
      },
    },
    {
      title: 'ยืนยันรายการ',
      width: '50',
      key: 'followUpStatus',
      align: 'center',
      render: (record) => (
        <Popconfirm onConfirm={() => handleUpdateBooking(record.id)} title="ต้องการที่จะยืนยันหรือไม่？" okText="ยืนยัน" cancelText="ยกเลิก">
          <Button type="primary">ยืนยันรายการ</Button>
        </Popconfirm>
      ),
    },
  ]
  return (
    <>
      <Container>
        <HeaderWrapper>
          <SyncOutlined className="mr-1" />
          <Text bold>นัดหมายต่อเนื่อง</Text>
          <Search onSearch={onSearch} style={{ width: 200, marginLeft: 'auto' }} onChange={(e) => setSearchText(e.target.value)} placeholder="ชื่อผู้ป่วย" />
        </HeaderWrapper>
      </Container>
      <Container>
        <Table columns={columns} dataSource={followUpBookings} loading={loading} />
      </Container>
    </>
  )
}

export default FollowUpBookingTable

import http from '../services/httpServiceHealthCare'
const API_URL = '/BloodDonationBookings'
import { mapEphisData } from '../containers/BloodDonationForm/mapEphisData'

export const getBookings = async () => {
  const response = await http.get(`${API_URL}?filter={"include":["appUser","bloodDonationForm"]}`)
  return response
}
export const getBooking = async (bookingId) => {
  const response = await http.get(`${API_URL}?filter={"include":"appUser","where":{"id": ${bookingId}}}`)
  return response
}

export const updateBooking = async (params) => {
  const response = await http.patch(`${API_URL}/${params.bookingId}`, params.latestDonorBookingData)
  return response
}

export const addToEphis = async (form, booking, patientInfo) => {
  const mappedData = mapEphisData(form, booking, patientInfo)
  try {
    const response = await http.post(`${API_URL}/addToEphis`, mappedData)
    return response
  } catch (error) {
    return error.response
  }
}

import { observable, action, computed, toJS } from 'mobx'
import http from '../../services/httpService'

const apiEndpoint = '/Reports'

class Report {
  @observable info = {
    data: [],
    error: null,
  }

  @action fetchReports = async (startDateTime, endDateTime, referType) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/referral/countByHospital?startDateTime=${startDateTime}&endDateTime=${endDateTime}&referType=${referType}`)
      this.data = data
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action fetchEmsReports = async (startDateTime, endDateTime) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/ems/countByDistrict?startDateTime=${startDateTime}&endDateTime=${endDateTime}`)
      this.data = data
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @computed get getReport() {
    return this.data
  }
}

export default new Report()

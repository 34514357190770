import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/PrescriptionPickups'

class PrescriptionPickup {
  @observable info = {
    data: [],
    error: null
  }

  @action createPrescriptionPickup = async (data) => {
    try {
      const res = await http.post(apiEndpoint, data)
      return res.data
    } catch (error) {
      console.warn(error)
    }
  }
}

export default new PrescriptionPickup()

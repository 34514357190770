import { observable, action, computed, toJS } from 'mobx'
// import jwtDecode from 'jwt-decode'

import http from '../../services/httpService'
// import constant from '../../constant'

const apiEndpoint = '/Departments'
// const tokenKey = 'token'

class Department {
  @observable departments = {
    data: [],
    error: null
  }


  @action fetchDepartments = async () => {
    try {
      const departments = await http.get(`${apiEndpoint}`)
      this.departments.data = departments.data
    } catch (error) {
      this.departments.error = error.response
    }
  }

  @action getDepartmentById = async (id) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/${id}`)
      return data
    } catch (error) {
      this.departments.error = error.response
      return null
    }
  }

  @action createDepartment = async (payload) => {
    try {
      const { data } = await http.post(`${apiEndpoint}`, payload)
      return data
    } catch (error) {
      console.log('register error', error)
      return error
    }
  }

   @action updateDepartment = async (data, id) => {
     try {
       http.patch(`${apiEndpoint}/${id}`, data)
       await this.fetchDepartments()
     } catch (e) {
       // console.error(e)
     }
   }

   @action deleteDepartment = async (id) => {
     try {
       await http.delete(`${apiEndpoint}/${id}`)
       await this.fetchDepartments()
     } catch (e) {
       // console.error(e)
     }
   }

  @computed get getDepartments() {
     return toJS(this.departments.data)
   }
}

export default new Department()

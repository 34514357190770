import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { ErrorMessage } from 'formik'
import { ErrMsg } from './index'

export const TextInput = (props) => (
  <React.Fragment>
    <Input {...props} />
    <ErrorMessage component={ErrMsg} name={props.name} />
  </React.Fragment>
)
TextInput.defaultProps = {
  errors: {},
}
TextInput.propTypes = {
  errors: PropTypes.object, //eslint-disable-line
}

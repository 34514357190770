import React, { useEffect, useState } from 'react'
import { Card, message } from 'antd'
import DrugComplianceTable from '../../DrugComplianceTable'
import DrugCard from './DrugCard'
import DrugTime from './DrugTime'
import http from '../../../services/httpServiceEver'
import { Radio } from 'antd'
function DrugCompliance(props) {
  const [drugComplianceList, setDrugComplianceList] = useState(false)
  const [value, setValue] = useState(2)
  const [drugs, setDrugs] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [closeDrugComplianceList, setCloseDrugComplianceList] = useState()
  const { patient, editable } = props

  useEffect(() => {
    fetchDrugCompliance()
  }, [patient])

  const onChange = (e) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    filterCloseDrugComplianceList()
  }, [drugComplianceList])

  const fetchDrugCompliance = async () => {
    try {
      const res = await http.get(`/drugCompliances/?filter[where][userId]=${patient.userId}&filter[include]=drugComplianceType`)
      if (res.status === 200) {
        const filteredDrug = filterDrug(res.data)
        setDrugComplianceList(filteredDrug)
      }
      setIsLoading(false)
    } catch (error) {
      message.error('ไม่มีรายการยา')
      setIsLoading(false)
    }
  }

  const filterDrug = (data) => {
    data.sort((a, b) => {
      return b.id - a.id
    })
    const uniqueData = []
    data.map((item) => {
      uniqueData.filter((uq) => uq.name === item.name && uq.drugId === item.drugId).length > 0 ? null : uniqueData.push(item)
    })
    return uniqueData
  }

  const filterCloseDrugComplianceList = () => {
    let result = []
    for (let i = 0; i < drugComplianceList.length; i++) {
      if (drugComplianceList[i].status === true) {
        result.push(drugComplianceList[i])
      }
    }
    setCloseDrugComplianceList(result)
  }

  const handleStatusDrugCompliance = async (drugId, status) => {
    try {
      setIsLoading(true)
      const res = await http.patch(`/drugCompliances/${drugId}`, {
        status,
      })
      if (res.status === 204 || res.status === 200) {
        message.success('อัปเดตรายการยาเสร็จเรียบร้อยแล้ว')
        fetchDrugCompliance()
      }
    } catch (error) {
      message.error('เกิดข้อผิดพลาดในการอัปเดตรายการยา')
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Card className="mt-2">
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '2px' }}>
          ตัวเลือกรายการ
          <Radio.Group style={{ paddingLeft: '5px' }} onChange={onChange} value={value}>
            <Radio value={1} onClick={() => setCloseDrugComplianceList(false)}>
              ทั้งหมด
            </Radio>
            <Radio value={2} onClick={() => filterCloseDrugComplianceList()}>
              Close Monitoring
            </Radio>
          </Radio.Group>
        </div>
        <DrugComplianceTable editable={editable} loading={isLoading} dataSource={closeDrugComplianceList || drugComplianceList} handleStatusDrugCompliance={handleStatusDrugCompliance} />
        <DrugTime dataSource={patient}></DrugTime>
      </Card>
    </div>
  )
}

export default DrugCompliance

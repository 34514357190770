import React, { Component } from 'react'
import Layout from '../Home'
import ConsultCaseTable from '../../components/ConsultCaseTable'
import { TABLE_TYPES } from '../../constant'

export default class index extends Component {
  render() {
    return (
      <Layout
        {...this.props}
        render={props => (
          <ConsultCaseTable {...props} tableType={TABLE_TYPES.history} style={{ width: '100%' }} />
        )}
      />
    )
  }
}

import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form, Spin } from 'antd'
import { initialValues, emsImpression } from './constant'
import styled from 'styled-components'
import ToggleToken from '../../ToggleToken'
import CardiacArrestForm from './CardiacArrestForm'
import ChestPain from './ChestPain'
import ObviousDeath from './ObviousDeath'
import Sepsis from './Sepsis'
import Respiratory from './Respiratory'
import Stroke from './Stroke'
import TraumaticInjury from './TraumaticInjury'
import EmsTreatment from './EmsTreatment'

const index = ({ updateEmsForm, formDetail, formValues, setFormValues }) => {
  const formId = formDetail.id
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(true)
  const [initialForm, setInitialForm] = useState({})

  const onFinish = (value) => {
    console.log(value)
    updateEmsForm('impressionData', { impressionData: value })
  }

  useEffect(() => {
    if (Object.keys(formDetail).length !== 0) {
      setDefaultValue()
    }
  }, [formDetail])

  const setDefaultValue = () => {
    const { impressionData } = formDetail
    if (impressionData && impressionData.emsImpression) {
      setFormValues(impressionData)
      setInitialForm(impressionData)
    }
    setLoading(false)
  }

  return loading ? (
    <div className="w-100 d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
      <Spin></Spin>
    </div>
  ) : (
    <Form
      form={form}
      name="ems_section5"
      initialValues={initialValues}
      layout="vertical"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 24 }}
      onFinish={(value) => {
        onFinish(value)
      }}
    >
      <Form.Item name="emsImpression">
        <ToggleToken
          title="EMS Impression"
          options={emsImpression}
          values={formValues['emsImpression']}
          setValues={(val) => {
            console.log(val)
            setFormValues({ emsImpression: val })
          }}
          layout={'center'}
          extraInputs={['Other']}
        />
      </Form.Item>
      {formValues['emsImpression'].includes('Cardiac Arrest') && (
        <Form.Item name="cardiacArrest">
          <CardiacArrestForm formId={formId} setFormValue={setFormValues} initialValues={initialForm} />
        </Form.Item>
      )}
      {(formValues['emsImpression'].includes('Cardiac Arrhythmia') || formValues['emsImpression'].includes('Chest pain/Discomfort')) && (
        <Form.Item name="chestPain">
          <ChestPain formId={formId} setFormValue={setFormValues} initialValues={initialForm} />
        </Form.Item>
      )}
      {formValues['emsImpression'].includes('Obvious death') && (
        <Form.Item name="obviousDeath">
          <ObviousDeath formId={formId} setFormValue={setFormValues} initialValues={initialForm} />
        </Form.Item>
      )}
      {(formValues['emsImpression'].includes('Respiratory failure') || formValues['emsImpression'].includes('Respiratory distress')) && (
        <Form.Item name="respiratory">
          <Respiratory setFormValue={setFormValues} initialValues={initialForm} />
        </Form.Item>
      )}
      {formValues['emsImpression'].includes('Sepsis') && (
        <Form.Item name="sepsis">
          <Sepsis setFormValue={setFormValues} initialValues={initialForm} />
        </Form.Item>
      )}
      {formValues['emsImpression'].includes('Stroke/TIA/CVA') && (
        <Form.Item name="stroke">
          <Stroke setFormValue={setFormValues} initialValues={initialForm} />
        </Form.Item>
      )}
      {formValues['emsImpression'].includes('Traumatic injury') && (
        <Form.Item name="traumaticInjury">
          <TraumaticInjury formId={formId} setFormValue={setFormValues} initialValues={initialForm} />
        </Form.Item>
      )}
      <Form.Item name="emsTreatment">
        <EmsTreatment initialValues={initialForm} formValues={formValues} setFormValue={setFormValues} />
      </Form.Item>
    </Form>
  )
}

export default index

export const TabPanes = [
  {
    title: 'รายชื่อทั้งหมด',
    key: 'allUserList',
    roles: ['callCenter']
  },
  {
    title: 'รายชื่อที่รอยืนยัน KYC',
    key: 'kycWaitingList',
    roles: ['callCenter','bloodBankTechnician']
  },
  {
    title: 'รายชื่อที่มีปัญหา',
    key: 'problemsList',
    roles: ['callCenter']
  },
  {
    title: 'คำขอลบบัญชี',
    key: 'requestToDeleteList',
    roles: ['callCenter']
  },
]

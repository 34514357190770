import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from 'antd'
import { Pie } from 'react-chartjs-2'
import { WeightText } from './styles'
import { Chart, ArcElement } from 'chart.js'

Chart.register(ArcElement);
function WeightTrendCard(props) {
  const [values, setValues] = useState(props.trends)

  useEffect(() => {
    setValues(props.trends)
  }, [props])

  return (
    <Card className="mt-2" title="แนวโน้มค่าดัชนีมวลกาย">
      <Row>
        <Col span={12}>
          <Row>
            <WeightText span={12} color="black">
              รายการ
            </WeightText>
            <WeightText span={12} color="black">
              จำนวนครั้ง
            </WeightText>
            <WeightText span={12} color="#32cd32">
              BMI อยู่ในเกณฑ์
            </WeightText>
            <WeightText span={12} color="#32cd32">
              {values.mid}
            </WeightText>
            <WeightText span={12} color="red">
              BMI ต่ำกว่าเกณฑ์
            </WeightText>
            <WeightText span={12} color="red">
              {values.low}
            </WeightText>
            <WeightText span={12} color="#76c9d4">
              BMI สูงกว่าเกณฑ์
            </WeightText>
            <WeightText span={12} color="#76c9d4">
              {values.high}
            </WeightText>
            <WeightText span={12} color="black">
              ทั้งหมด
            </WeightText>
            <WeightText span={12} color="black">
              {values.low + values.mid + values.high}
            </WeightText>
          </Row>
        </Col>
        {values.low + values.mid + values.high ? (
          <Col span={12}>
            <Pie
              data={values.pie}
              options={{
                legend: {
                  display: true,
                  position: 'right',
                },
              }}
            />
          </Col>
        ) : null}
      </Row>
    </Card>
  )
}

export default WeightTrendCard

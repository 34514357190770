import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Spin, Row, Col, Card, Select, DatePicker } from 'antd'
import styled from 'styled-components'
import { LOGISTIC } from './constant'
import Button from '../../components/Button'
import moment from 'moment'
import { filter, set } from 'lodash-es'
const { Option } = Select
const { RangePicker } = DatePicker
const Text = styled.p`
  font-size: 20px;
  color: #2b2b2b;
`
const BookingPrescriptionChart = ({ dataSource, isLoading, setIsloading, doctorTypes, fetchDoctorTypeUsers }) => {
  const [series, setSeries] = useState([])
  const [logisticTypeId, setLogisticTypeId] = useState(false)
  const [doctorId, setDoctorId] = useState(false)
  const [doctorName, setDoctorName] = useState(false)
  const [departmentName, setDepartmentName] = useState(false)
  const [departmentId, setDepartmentId] = useState(false)
  const [dateRange, setDateRange] = useState([])
  const [doctors, setDoctors] = useState([])
  const [isFilter, setIsFilter] = useState(false)
  useEffect(() => {
    handleSeriesData()
  }, [dataSource])

  const filterBookingPrescriptions = () => {
    if (logisticTypeId || (dateRange != null && dateRange.length > 0) || (departmentId && doctorId)) {
      let data = [0, 0, 0]
      let filteredBookingsData = []
      if (logisticTypeId) {
        filteredBookingsData = filterByLogisticTypeId(logisticTypeId)
        data[logisticTypeId - 1] = filteredBookingsData.length
      }
      if (dateRange && dateRange.length > 0) {
        const dataForFilter = logisticTypeId ? filteredBookingsData : dataSource
        filteredBookingsData = filterByDateRange(dataForFilter)
        data = splitDataByLogisticId(filteredBookingsData)
      }
      if (doctorId) {
        const dataForFilter = logisticTypeId || (dateRange != null && dateRange.length > 0) ? filteredBookingsData : dataSource
        filteredBookingsData = filterByDoctorId(dataForFilter)
        data = splitDataByLogisticId(filteredBookingsData)
      }
      setSeriesData(data)
      setIsFilter(true)
    } else {
      setIsFilter(false)
      handleSeriesData()
    }
  }

  const filterByLogisticTypeId = (logisticTypeId) => {
    const filteredBookingsData = dataSource.filter((item) => item.prescription && item.prescription.logisticId === logisticTypeId)
    return filteredBookingsData
  }

  const filterByDateRange = (bookingsData) => {
    const startDate = dateRange[0]
    const endDate = dateRange[1]
    const filteredBookingsData = bookingsData.filter((item) => {
      const bookingDate = moment(item.admitTime)
      if (bookingDate.diff(startDate, 'day') >= 0 && bookingDate.diff(endDate, 'day') <= 0) {
        return item
      }
    })
    return filteredBookingsData
  }

  const filterByDoctorId = (bookingsData) => {
    const filteredBookingsData = bookingsData.filter((item) => item.doctorId === parseInt(doctorId))
    return filteredBookingsData
  }

  const setSeriesData = (seriesData) => {
    let newSeriesData = [
      {
        name: 'จำนวนรายการนัดหมาย',
        data: seriesData
      }
    ]
    setSeries(newSeriesData)
  }

  const handleSeriesData = () => {
    const seriesData = splitDataByLogisticId(dataSource)
    setSeriesData(seriesData)
    clearData()
  }

  const clearData = () => {
    setDateRange([])
    setDoctorId(false)
    setDoctorName(false)
    setDepartmentId(false)
    setLogisticTypeId(false)
  }

  const splitDataByLogisticId = (data) => {
    let seriesData = [0, 0, 0]
    data.map((item) => {
      switch (item.prescription && item.prescription.logisticId) {
        case 1:
          seriesData[0] += 1
          break
        case 2:
          seriesData[1] += 1
          break
        case 3:
          seriesData[2] += 1
          break
        default:
          break
      }
    })
    return seriesData
  }

  let options = {
    chart: {
      id: 'bookingPrescriptions',
      type: 'bar',
      height: 350,
      stacked: true
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        horizontal: true
      }
    },

    colors: ['#008FFB', '#00E396', '#FEB019'],
    xaxis: {
      type: 'category',
      categories: ['รับยาที่ร้านยาคุณภาพ', 'รับยาที่บ้านทางไปรษณีย์', 'รับยาที่โรงพยาบาลวชิรพยาบาล']
    },
    yaxis: {
      show: true,
      labels: {
        formatter: function(val) {
          return val
        }
      },
      showForNullSeries: false
    },
    tooltip: {
      y: {
        formatter: function(value) {
          return value + ' รายการนัดหมาย'
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
    }
  }

  const handleDepartmentChange = async (doctorType) => {
    const doctorTypeId = doctorType ? doctorType.split(',')[0] : null
    const departmentName = doctorType ? doctorType.split(',')[1] : null
    if (doctorType) {
      const doctorList = await fetchDoctorTypeUsers(doctorTypeId)
      setDoctors(doctorList ? doctorList : [])
    } else {
      setDoctorName(null)
      setDoctorId(null)
    }
    setDepartmentName(departmentName)
    setDepartmentId(doctorTypeId)
  }
  const handleDoctorChange = (doctor) => {
    let doctorId = null
    let doctorName = null
    if (doctor) {
      doctorId = doctor.split(',')[0]
      doctorName = doctor.split(',')[1]
    }
    setDoctorId(doctorId ? doctorId : null)
    setDoctorName(doctorName ? doctorName : null)
  }

  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Spin className="text-center" />
        </div>
      ) : (
        <div className="w-100 p-4">
          <Row gutter={[4, 4]}>
            <Col className="d-flex align-items-center">
              <div>
                <p className="mx-2">{`วิธีการจัดส่งยา:`}</p>
              </div>
              <Select
                placeholder="เลือกวิธีการจัดส่ง"
                style={{ width: 120 }}
                allowClear
                onChange={(logisticId) => {
                  console.log(logisticId)
                  setLogisticTypeId(logisticId)
                }}
                style={{ width: '200px' }}
              >
                {LOGISTIC.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col className="d-flex align-items-center">
              <div>
                <p className="mx-2">{`วันที่:`}</p>
              </div>
              <RangePicker
                onChange={(data) => {
                  setDateRange(data)
                }}
                format="DD/MM/YYYY"
                placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                allowClear
              />
            </Col>
            <Col className="d-flex align-items-center">
              <div>
                <p className="mx-2">{`แพทย์:`}</p>
              </div>
              <div>
                <Select placeholder="เลือกแผนก" style={{ width: 120 }} onChange={(dep) => handleDepartmentChange(dep)} allowClear className="mr-2">
                  {doctorTypes && doctorTypes.map((dep) => <Option key={`${dep.id},${dep.name}`}>{dep.name}</Option>)}
                </Select>
                <Select placeholder="เลือกแพทย์" style={{ width: 120 }} onChange={(doctor) => handleDoctorChange(doctor)} value={doctorName} disabled={departmentId ? false : true} allowClear>
                  {doctors !== null && doctors.length && doctors.map((doctor) => <Option key={`${doctor.id},${doctor.fullname}`}>{doctor.fullname}</Option>)}
                </Select>
              </div>
            </Col>
            <Col className="ml-2">
              <Button type="primary" onClick={() => filterBookingPrescriptions()}>
                กรองข้อมูล
              </Button>
            </Col>
          </Row>
          <Row gutter={[8, 8]} justify="center" className="mt-3">
            {isFilter && logisticTypeId && (
              <Col>
                <Text>
                  <b>{`วิธีการจัดส่งยา: `}</b>
                  {`${LOGISTIC[logisticTypeId - 1].name}`}
                </Text>
              </Col>
            )}
            {isFilter && dateRange && dateRange.length > 0 && (
              <>
                <Col>
                  <Text>
                    <b>{`วันที่เริ่มต้น: `}</b>
                    {`${moment(dateRange[0]).format('DD/MM/YYYY')}`}
                  </Text>
                </Col>
                <Col>
                  <Text>
                    <b>{`วันที่สิ้นสุด: `}</b>
                    {`${moment(dateRange[1]).format('DD/MM/YYYY')}`}
                  </Text>
                </Col>
              </>
            )}
            {isFilter && departmentName && (
              <Col>
                <Text>
                  <b>{`แผนก: `}</b>
                  {`${departmentName}`}
                </Text>
              </Col>
            )}
            {isFilter && doctorName && (
              <Col>
                <Text>
                  <b>{`แพทย์: `}</b>
                  {`${doctorName}`}
                </Text>
              </Col>
            )}
          </Row>
          <div id="chart">{series && series.length && <Chart options={options} series={series} type="bar" height={800} className="w-100"></Chart>}</div>
        </div>
      )}
    </>
  )
}

export default BookingPrescriptionChart

import React, { useEffect } from 'react'
import { Card, Form, Button, Col, Row, Spin } from 'antd'
import Tabs from '../../Tabs'
import Table from '../../Table'
import styled from 'styled-components'
import moment from 'moment'
import locale from 'antd/es/locale/th_TH'
import { DatePicker } from '../../Neo'
moment.locale('th')
const { TabPane } = Tabs
const Container = styled.div`
  width: 100%;
  padding: 20px;
`
const Label = styled.span`
  width: 100%;
  padding: 20px;
`

const dateNow = new Date()
const setHourZero = new Date(dateNow.setHours(0, 0, 0, 0))
const startDefault = moment(setHourZero)
const endDefault = moment(new Date())

const initialValues = { startDateTime: startDefault, endDateTime: endDefault }

export default (props) => {
  const { innerTab, events, fetchEmsForm, dataSource, isLoading } = props

  useEffect(() => {
    fetchEmsForm(startDefault, endDefault)
  }, [])

  const onSearchFilterByDate = (values) => {
    fetchEmsForm(values.startDateTime ? values.startDateTime.toDate() : startDefault, values.endDateTime ? values.endDateTime.toDate() : endDefault)
  }

  const validateMessages = {
    required: 'กรุณากรอก ${label}',
  }

  return (
    <Card>
      <Tabs defaultActiveKey="11" type="card">
        {innerTab.map((itemInner, index) => (
          <TabPane tab={itemInner.innerLabel} key={itemInner.key}>
            <Container>
              <FilterableTable onSearchFilterByDate={onSearchFilterByDate} validateMessages={validateMessages} />
            </Container>
            <Spin spinning={isLoading}>
              <Table scroll={{ x: 'max-content' }} columns={events[itemInner.columnInner]} dataSource={dataSource} />
            </Spin>
          </TabPane>
        ))}
      </Tabs>
    </Card>
  )
}

export const FilterableTable = (props) => {
  const { onSearchFilterByDate, validateMessages } = props
  const [formFilter] = Form.useForm()
  return (
    <Form form={formFilter} initialValues={initialValues} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} name="add-case" style={{ maxWidth: '100%' }} onFinish={onSearchFilterByDate} validateMessages={validateMessages}>
      <Row span={24}>
        <Col xs={24} sm={24} md={10} lg={8} xl={8}>
          <Form.Item name="startDateTime" label="วันที่เริ่มต้น" rules={[{ required: false }]}>
            <DatePicker
              value={formFilter.getFieldValue('startDateTime')}
              mode="datetime"
              calendar="BE"
              onChange={(value) => {
                formFilter.setFieldsValue({ startDateTime: moment(value) })
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={10} lg={8} xl={8}>
          <Form.Item name="endDateTime" label="ถึง" rules={[{ required: false }]}>
            <DatePicker
              value={formFilter.getFieldValue('endDateTime')}
              mode="datetime"
              calendar="BE"
              onChange={(value) => {
                formFilter.setFieldsValue({ endDateTime: moment(value) })
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={2} lg={8} xl={8}>
          <Form.Item wrapperCol={{ span: 24 }} className="text-center">
            <Button type="primary" htmlType="submit">
              ค้นหา
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export const tabInfo = [
    {
        tabName : 'แจ้งเตือน',
        key : '1',
        status : ['patientWaiting', 'actionWaiting', 'exitWaiting'],
    },{
        tabName : 'ดำเนินการ',
        key : '2',
        status : ['moving', 'arrived', 'completedSending', 'inHospital'],
    },{
        tabName : 'เสร็จสิ้น',
        key : '3',
        status : ['completed', 'cancelled' ,'pass'],
    },{
        tabName : 'แจ้งเตือนอื่นๆ',
        key : '4',
        status : ['customAdd'],
    }
]
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Spin, Carousel, Typography, Button, Row, Col, InputNumber } from 'antd'
import styled from 'styled-components'
import TextBase from '../Text'
import Table from '../Table'

const { Title } = Typography

const Text = styled(TextBase)`
  font-weight: bold;
`

const CarouselBlack = styled(Carousel)`
  .slick-dots li {
    background: black;
  }
`

const MedicineEphis = ({
  visible,
  setVisible,
  isLoading,
  setEms,
  addTrackingNumber,
  ems,
  isSuccess,
  items,
  prescription,
  emsAvailable,
  fetchUserVisitedByVerifiedFromHIE,
  fetchUserFcmToken,
  patientId,
  drugsDetail,
  booking,
  addPrescriptionPickup,
  prescriptionId,
  doctor,
}) => {
  const [images, setImages] = useState([])
  const [visibleModalImages, setVisibleModalImages] = useState(false)
  const [salerate, setSalerate] = useState(0)
  const [refund, setRefund] = useState(0)
  const [quantity, setQuantity] = useState(0)

  useEffect(() => {
    const calculateSum = () => {
      let sumSalerate = 0
      let sumRefund = 0
      if (items && items.length) {
        items.map((item) => {
          sumSalerate += item.qty * item.salerate
          sumRefund += item.qty * item.refund
        })
        setSalerate(sumSalerate)
        setRefund(sumRefund)
      }
    }
    calculateSum()
  }, [items])
  const column = [
    {
      title: 'ลำดับที่',
      key: 'index',
      align: 'center',
      width: 80,
      render: (text, record, index) => <Text>{index + 1}</Text>,
    },
    {
      title: 'ชื่อยา',
      key: 'drugFullname',
      width: 250,
      maxWidth: 250,
      minWidth: 200,
      render: (record) => <Text>{record.drugNondugName}</Text>,
    },
    {
      title: 'วิธีใช้',
      key: 'drugUsage',
      width: 300,
      render: (record) => (
        <div>
          {record.drugUsage}
          {record.medlblhlp1_name ? <Text>- {record.medlblhlp1_name}</Text> : ''}
          {record.medlblhlp2_name ? <Text>- {record.medlblhlp2_name}</Text> : ''}
          {record.medlblhlp3 ? <Text>- {record.medlblhlp3}</Text> : ''}
          {record.medlblhlp4 ? <Text>- {record.medlblhlp4}</Text> : ''}
        </div>
      ),
    },
    {
      title: 'จำนวน',
      align: 'center',
      key: 'qty',
      width: 80,
      dataIndex: 'qty',
    },
    {
      title: 'ราคาต่อหน่วย (บาท)',
      align: 'center',
      dataIndex: 'unitPrice',
      width: 80,
      key: 'unitPrice',
    },
    {
      title: 'ราคารวม (บาท)',
      align: 'center',
      render: (record) => <div>{record.salerate * record.qty}</div>,
      width: 80,
      key: 'sumPrice',
    },
    {
      title: 'เบิกได้ (บาท)',
      align: 'center',
      render: (record) => <div>{record.refund * record.qty}</div>,
      width: 80,
      key: 'sumRefund',
    },
  ]

  return (
    <div>
      <Modal
        title="รายการยา E-PHIS"
        visible={visible}
        onOk={() => {
          setVisible(false)
          setEms(false)
          setQuantity(0)
        }}
        onCancel={() => {
          setVisible(false)
          setEms(false)
          setQuantity(0)
        }}
        footer={
          ems ? (
            <div>
              <Button style={{ backgroundColor: 'yellow' }} onClick={() => fetchUserVisitedByVerifiedFromHIE()}>
                อัปเดตรายการยา
              </Button>
              <Button
                onClick={() => {
                  setVisible(false)
                }}
                type="danger"
              >
                ปิดหน้าจอ
              </Button>
              <Button
                disabled={[1, 2].includes(prescription.logisticId) && !quantity}
                type="primary"
                onClick={() => {
                  if (prescription.logisticId === 1 || prescription.logisticId === 2) {
                    addTrackingNumber(quantity)
                    isSuccess('PHARMACY_CONFIRM_RX')
                  } else if (prescription.logisticId === 3) {
                    const data = {
                      userId: patientId,
                      received: false,
                      bookingTime: booking.admitTime,
                      orderNumber: booking.vnNumber,
                      vnNumber: booking.vn,
                      hnId: booking.patient.hnId,
                      amount: drugsDetail.finalprice,
                      drugItems: items,
                      prescriptionId,
                      doctor,
                      bookingId: booking.id,
                      bookingMinutesTime: booking.bookingTime,
                      bookingMinutesEndTime: booking.bookingEndTime,
                    }
                    addPrescriptionPickup(data)
                    isSuccess('WAIT_FOR_PATIENT_HOSPITAL')
                  } else {
                    isSuccess('PHARMACY_CONFIRM_RX')
                  }
                  fetchUserFcmToken(patientId, 'เภสัชกรได้เตรียมการส่งยาออกไปหาคุณแล้วรบกวนรอ 2-5 วันทำการเพื่อรับยาของคุณเมื่อได้รับยาแล้วกรุณากด "ได้รับยาแล้ว"')
                  setVisible(false)
                }}
              >
                บันทึก
              </Button>
            </div>
          ) : (
            ''
          )
        }
        width="80vw"
      >
        <Spin spinning={isLoading} style={{ width: '100%' }}>
          <Table
            columns={column}
            scroll={{ x: 'max-content', y: '50vh' }}
            pagination={false}
            dataSource={items}
            footer={() => (
              <div>
                <Row gutter={24} justify="end">
                  <Col>
                    <Title className="text-right" level={4}>
                      ราคารวม {drugsDetail.sumSalerate} บาท
                    </Title>
                  </Col>
                  <Col>
                    <Title className="text-right" level={4}>
                      เบิกได้ {drugsDetail.sumRefund} บาท
                    </Title>
                  </Col>
                  <Col>
                    <Title className="text-right" level={4}>
                      เบิกไม่ได้ {drugsDetail.finalprice} บาท
                    </Title>
                  </Col>
                </Row>
                {(prescription.logisticId === 1 || prescription.logisticId === 2) && ems && (
                  <Row className="mb-4">
                    <Col span={12}>กรุณากรอกจำนวน EMS</Col>
                    <Col span={12}>
                      <InputNumber min={0} defaultValue={0} value={quantity} onChange={(number) => setQuantity(number)} />
                    </Col>
                    {emsAvailable && (
                      <Col
                        span={24}
                        style={{
                          backgroundColor: `${emsAvailable < 5 ? 'red' : emsAvailable < 10 ? 'orange' : 'green'}`,
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'flex-end',
                          padding: '5px',
                          color: '#ffffff',
                        }}
                      >
                        เหลือหมายเลข Tracking {emsAvailable} เลข
                      </Col>
                    )}
                  </Row>
                )}
                {/* <Row  className="justify-content-between">
                  <Col span={12}>
                    <Title level={4}>ค่าใช้จ่ายสุธิรวมค่าค้างชำระอื่นๆ</Title>
                  </Col>
                  <Col span={12} className="d-flex justify-content-end">
                    <Title level={4}>{`${drugsDetail.finalprice} บาท`}</Title>
                  </Col>
                </Row> */}
              </div>
            )}
          />
        </Spin>
      </Modal>
      <Modal title="รูปภาพยา" visible={visibleModalImages} onOk={() => setVisibleModalImages(false)} onCancel={() => setVisibleModalImages(false)} footer={null}>
        <CarouselBlack>
          {images.map((image) => (
            <img src={image.hash} alt="" />
          ))}
        </CarouselBlack>
      </Modal>
    </div>
  )
}

MedicineEphis.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
}

export default MedicineEphis

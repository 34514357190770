import React, { useState, useEffect } from 'react'

const index = () => {
    const [date_live, setDate_live] = useState(new Date())

  useEffect(() => {
    const dateLive = setInterval(() => {
      setDate_live(new Date())
    }, 1000)
    return () => {
      clearInterval(dateLive)
    }
  }, [])

  return (
    <>{date_live.toLocaleTimeString()}</>
  )
}

export default index

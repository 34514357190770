import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import firebase from 'firebase'
import Table from '../../components/Table'
import Button from '../../components/Button'
import moment from 'moment'
import { Modal, Button as AntButton, Table as AntTable, Card, Row, Input, Spin } from 'antd'
const { Search: SearchAnt } = Input

const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`
const DataCard = styled(Card)`
  height: 100%;
  width: '100%';
  min-width: 250px;
  cursor: pointer;
  border-radius: 5px;
  border: .5px solid #d3d3d3;  
  &:hover {
    transform: scale(1.08);
    z-index: 999;
  }
  transition: 0.3s;
`

const Text = styled.p`
  font-size: 14px;
  color: #2b2b2b;
`
const View = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h5`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`

const Description = styled.p`
  font-size: 2.4rem;
  color: #6e6e6e;
`

const typeMessage = [
  { name: 'โรคเบาหวาน', type: 'DIABETES' },
  { name: 'โรคกระดูก', type: 'ORTHO' }
]

const typeMessageObj = {
  diabets: "DIABETES",
  ortho: "ORTHO"
}

const columnsChat = [
  {
    title: 'ข้อความ',
    key: 'text',
    width: 150,
    render: (record) => <Text>{record.text}</Text>,
  },
  {
    title: 'จำนวน(ครั้ง)',
    key: 'text',
    width: 150,
    render: (record) => <Text>{record.count}</Text>,
    sorter: { compare: (a, b) => a.count - b.count },
    align: 'center'
  },
]

const ChatbotHistoryTable = (props) => {
  const [data, setData] = useState({});
  const [userList, setUserList] = useState([])
  const [filterUserList, setFilterUserList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadTotalChat, setIsLoadTotalChat] = useState(false)
  const [visibleHistory, setVisibleModal] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [fullsize, setFullSize] = useState(0)
  const [totalMsg, setTotalMsg] = useState(0)

  useEffect(() => {
    manageData()
  }, [props.userList])

  const manageData = async () => {
    setIsLoading(true)
    let listOfTime = {}
    let listOfId = []
    const firetoreRef = firebase.firestore().collection("CHATBOT_HISTORY_production")
    const fullSize = await firetoreRef.orderBy('createdAt', 'desc').get()
    fullSize.docs.forEach((snap) => {
      listOfId.push(snap.id);
      listOfTime[snap.id] = snap.data()['createdAt']
    })
    const filterList = fillterUserData(listOfId, listOfTime)
    fetchCountAllChat(filterList)
    setFullSize(filterList.length)
    setUserList(filterList);
    setFilterUserList(filterList);
    setIsLoading(false)
  }

  const fillterUserData = (list, listTime) => {
    const listId = [...list];
    const data = props.userList ? [...props.userList] : []
    const filterList = data.flatMap((a) => {
      if (listId.includes(`${a.userId}`)) {
        a.bot_createdAt = listTime[a.userId]
        return a
      }
      return []
    });
    return filterList
  }

  const fetchCountAllChat = async (listData = []) => {
    try {
      setIsLoadTotalChat(true)
      const countSum = await Promise.all(listData.map(async (e) => {
        const dCount = await Promise.all([
          firebase.firestore().collection("CHATBOT_HISTORY_production").doc(e.userId.toString?.()).collection(`MESSAGES_${typeMessageObj.ortho}`).get(),
          firebase.firestore().collection("CHATBOT_HISTORY_production").doc(e.userId.toString?.()).collection(`MESSAGES_${typeMessageObj.diabets}`).get()
        ])
        return dCount[0].size + dCount[1].size
      }))
      const totalRecord = countSum.reduce((a, b) => a + b, 0)
      setTotalMsg(totalRecord)
      setIsLoadTotalChat(false)
    } catch (e) {
      setIsLoadTotalChat(false)
    }
  }

  // NOTE ดึงข้อมูลแชทของ 1 คน
  const fetchPerOnePerson = useCallback(async (id) => {
    let data = {}
    const firetoreRef = firebase.firestore().collection("CHATBOT_HISTORY_production")
    const result = await Promise.all(typeMessage.flatMap(async (type_message, index_type) => {
      const dataRes = await firetoreRef.doc(`${id}`).collection(`MESSAGES_${type_message.type}`).get()
      if ((dataRes?.docs?.length) > 0) {
        dataRes?.docs?.forEach((e) => {
          if (!e?.data()?.user?.avatar) {
            data[id] =
              data[id] ? // NOTE เช็คว่ามี object ของ ไอดีนี้หรือไม่
                data[id][type_message.type] ? // NOTE เช็คว่ามี key ของ type นี้หรือไม่ คือ DIABETES หรือ ORTHO
                  { ...data[id], [type_message.type]: [...data[id][type_message.type], e?.data()] } // NOTE add data
                  :
                  { ...data[id], [type_message.type]: [e?.data()] } // NOTE สร้าง key ต่อไปของ object และ spread key ก่อนหน้า
                :
                { [type_message.type]: [e?.data()] } // NOTE สร้าง key แรกของ object
          }
        })
      }
      if (index_type === (typeMessage?.length - 1)) {
        return data[id]
      }
    }))
    const filterRes = result.filter(a => a)
    if ((filterRes?.length) > 0) {
      const listCount = typeMessage?.flatMap((i) => {
        if (filterRes[0][i.type]) {
          return filterRes[0][i.type]?.reduce((pre, cur, index) => {
            pre[cur?.text] ? pre[cur?.text] = pre[cur?.text] + 1 : pre[cur.text] = 1
            return pre
          }, {})
        } else {
          return []
        }
      })
      const finalList = listCount?.map((obj, index) => {
        return {
          title: typeMessage[index].name,
          data: Object.keys(obj).map((key) => { return { text: key, count: obj[key] } })
        }
      })
      setData(prev => ({ ...prev, [id]: finalList }))
    }
  }, [currentId])



  const columns = [
    {
      title: 'ไอดีผู้ใช้',
      key: 'userId',
      width: 150,
      render: (record) => <Text>{record.userId}</Text>,
      sorter: { compare: (a, b) => a.userId - b.userId },
      align: 'center'
    },
    {
      title: 'ชื่อ',
      key: 'userId',
      width: 150,
      render: (record) => <Text>{record.firstname} {record.lastname}</Text>
    },
    {
      title: 'เวลาแชทล่าสุด',
      key: 'bot_createdAt',
      width: 150,
      render: (record) => <Text>{`${moment(record.bot_createdAt).format('DD/MM/YYYY HH:mm')} น.`}</Text>,
      sorter: { compare: (a, b) => a.bot_createdAt - b.bot_createdAt }
    },
    {
      title: 'ประวัติแชท',
      key: 'userId',
      width: 150,
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            fetchPerOnePerson(record.userId)
            setCurrentId(record.userId)
            setVisibleModal(true)
          }}
        >
          รายละเอียดการแชท
        </Button>
      )
    }
  ]

  const onSearch = (text) => {
    if (text) {
      const listFilter = userList.filter((val) => {
        const fullname = val.firstname && val.lastname ? `${val.firstname} ${val.lastname}` : ''
        return (
          (val.userId.toString().includes(text)) ||
          (val.firstname && val.firstname.includes(text)) ||
          (val.lastname && val.lastname.includes(text)) ||
          (val.firstname && val.lastname && fullname.includes(text))
        )
      })
      setFilterUserList(listFilter)
    } else {
      setFilterUserList(userList)
    }
  }

  const rowId = useCallback((record) => record.userId, [])
  const subRowId = useCallback((record) => record.text, [])

  return (
    <View>
      <Row className='mb-2 mt-2 pt-2 pb-2' align='bottom' justify='space-between'>
        <Row>
          <DataCard className='mr-3 ml-3 mt-2 mb-2 pt-1 pb-1' bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <View>
              <Title className='pb-2'>จำนวนผู้ใช้ที่ใช้แชทบอททั้งหมด</Title>
              <View className='pt-1' style={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                <Description style={{ margin: 0 }}>{fullsize.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,")}&nbsp;</Description>
                <Description style={{ fontSize: 14 }}>คน</Description>
              </View>
            </View>
          </DataCard>
          <DataCard className='ml-3 mr-3 mt-2 mb-2 pt-1 pb-1' bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <View>
              <Title className='pb-1'>จำนวนข้อความส่งผ่านแชทบอท</Title>
              <Spin spinning={isLoadTotalChat}>
                <View className='pt-2' style={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                  <Description style={{ margin: 0 }}>{totalMsg.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,")}&nbsp;</Description>
                  <Description style={{ fontSize: 14 }}>ข้อความ</Description>
                </View>
              </Spin>
            </View>
          </DataCard>
        </Row>
        <Search onSearch={onSearch} style={{ width: 200, marginLeft: 'auto' }} />
      </Row>
      <Table
        rowKey={rowId}
        loading={isLoading}
        scroll={{ x: 'max-content' }}
        columns={columns}
        dataSource={filterUserList}
      />
      <Modal
        width='50%'
        style={{ minWidth: 520, maxWidth: 1100 }}
        title="ประวัติการแชท"
        visible={visibleHistory}
        onOk={() => setVisibleModal(false)}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <AntButton type='primary' danger key="back" onClick={() => setVisibleModal(false)}>
            ปิด
          </AntButton>,
        ]}
      >
        <View style={{ borderRadius: 12 }}>
          {currentId && data[currentId] && data[currentId].map((item, index) => {
            return (
              <View key={index} className="mb-1">
                <Text className="mb-1" style={{ fontSize: 16, color: '#2b2b2b' }}>&#10148; {item.title}</Text>
                <AntTable
                  rowKey={subRowId}
                  dataSource={item.data}
                  columns={columnsChat}
                  pagination={{ pageSize: 5 }}
                />
              </View>
            )
          })}
        </View>
      </Modal>
    </View>
  )
}

export default ChatbotHistoryTable


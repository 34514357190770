import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles, { CheckboxWithLabel, Column, Row } from '../styles'
import { IMPRESSION } from '../constant'
import { get, map, reduce } from 'lodash'

export default function ImpressionTable({ data }) {
  const impression = get(data, "emsImpression", [])
  const impressionArray = map(impression, (item) => item.includes("→") ? item.split("→")[0] : item)
  const impressionMoreContent = reduce(impression, (acc, element) => {
    return element.includes("→") ? {
      ...acc, [element.split("→")[0]]: element.split("→").length > 1 ? element.split("→")[1] : ""
    }
      : { ...acc }
  }, {})
  return (
    <View style={styles.borderedContainer}>
      <Row>
        <Column borderBottom flexGrow={1}>
          <Text style={styles.boldText}>
            Impression
          </Text>
        </Column>
      </Row>
      <Row>
        <Column paddingHorizontal={3}>
          <Row wrap>
            {impressionArray.length > 0 ?
              impressionArray.map((e) => <CheckboxWithLabel key={e} small title={e} checked={impressionArray.includes(e)} more={impressionMoreContent[e]} />)
              :
              IMPRESSION.map((e) => {
                return (
                  <Column key={e.column} paddingVertical={0} maxWidth={95}>
                    {e.keys.map((item) => <CheckboxWithLabel key={item.label} small title={item.label} checked={false} />)}
                  </Column>
                )
              })}
          </Row>
        </Column>
      </Row>
    </View>
  )
}

import React from 'react'
import { PropTypes } from 'prop-types'
import { FileOutlined } from '@ant-design/icons';
import styled from 'styled-components'
// import _get from 'lodash/get'
// import { Formik } from 'formik'
import Table from '../Table'
import TextBase from '../Text'
import Button from '../Button'
import { TABLE_TYPES } from '../../constant'

const Text = styled(TextBase)`
  font-weight: bold;
`
const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
const Container = styled.div`
  width: 100%;
  padding: 20px;
`

const DoctorTypeTable = ({ Cases, tableType,
  data,
  match,
  history,
  handleDelete,
  ...rest }) => {
  const headerText = tableType === TABLE_TYPES.doctorTypes && 'Doctor Types'
  const columns = [
    { title: 'Name', key: 'name', render: (record) => <Text>{record.name}</Text> },
    { title: 'Action',
      key: 'action',
      width: 100,
      render: (record) => (
        <span>
          <Button
            primary
            transparent
            icon="edit"
            onClick={() => {
              history.push(`/doctor-types/${record.id}`)
            }}
          />
          {/* <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
            <Button style={{ marginLeft: 10 }} primary transparent icon="delete" />
          </Popconfirm> */}
        </span>
      ),
    }
  ]


  return (
    <Container>
      <HeaderWrapper>
        <FileOutlined />
        <Text bold>{headerText}</Text>
        <Button style={{ marginLeft: 'auto' }} primary onClick={() => history.push('/doctor-types/new')}>
                   Create Doctor Type
        </Button>
      </HeaderWrapper>
      <Table
        columns={columns}
        dataSource={data}
        {...rest}
      />
    </Container>
  );
}
DoctorTypeTable.defaultProps = {
  handleDelete: () => {}
}
DoctorTypeTable.propTypes = {
  // Cases: PropTypes.array.isRequired,
  // tableType: PropTypes.string.isRequired,
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}
export default DoctorTypeTable


import React, { useState, useEffect } from 'react'
import Layout from '../Home'
import http from '../../services/httpServiceEver'
import { FileOutlined } from '@ant-design/icons'
import { Tabs, List, Table, Spin, Input, Col, Row, Avatar, Popconfirm, Button, message } from 'antd';
import styled from 'styled-components';
import './DoctorList.css'; // Add this for custom CSS

const { TabPane } = Tabs;
const { Search: SearchAnt } = Input

const Text = styled.p`
  font-weight: bold;
  color: ${props => props.theme.primaryText};
  color: ${props => props.second && props.theme.secondaryText};
  ${props => props.bold && 'font-weight: bold;'}
`

const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`
const RemoveHiddenButton = styled(Button)`
  background: #f5b400;
  border: 1px solid #f9c710;
  color: white;
  &:hover {
    background: #ffebb5;
    border: 1px solid #f9c710;
    color: #f5b400;
  }
`


const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
  i {
    margin-right: 15px;
  }
`
const Container = styled.div`
  width: 100%;
  padding: 20px;
`
export const DoctorList = (props) => {
    const [otherTypes, setOtherTypes] = useState([]);
    const [doctorTypes, setDoctorTypes] = useState([]);
    const [selectedType, setSelectedType] = useState('doctorTypes');
    const [selectedTypeId, setSelectedTypeId] = useState(null);
    const [doctorAppUsers, setDoctorAppUsers] = useState([]);
    const [otherAppUsers, setOtherAppUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // Search state

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        fetchTypes();
    }, []);

    // Fetch initial lists of doctorTypes and otherTypes
    const fetchTypes = async () => {
        setLoading(true);
        try {
            const responseOtherType = await http.get('OtherTypes');
            const responseDoctorType = await http.get('doctorTypes');

            setOtherTypes(responseOtherType.data);
            setDoctorTypes(responseDoctorType.data);
        } catch (error) {
            console.error("Error fetching types:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch appUsers for doctorTypes
    const fetchDoctorAppUsers = async (doctorTypeId) => {
        setLoading(true);
        try {
            const response = await http.get(`DoctorTypes/${doctorTypeId}/appUsers?filter[order]=employeeId`);
            setDoctorAppUsers(response.data);
        } catch (error) {
            console.error("Error fetching doctor app users:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch appUsers for otherTypes
    const fetchOtherTypeAppUsers = async (otherTypeId) => {
        setLoading(true);
        try {
            const response = await http.get(`OtherTypes/getAppUserByOtherTypeId?otherTypeId=${otherTypeId}`);
            setOtherAppUsers(response.data);
        } catch (error) {
            console.error("Error fetching other type app users:", error);
        } finally {
            setLoading(false);
        }
    };

    // When an item is selected from the list
    const handleTypeSelection = (item, type) => {
        setSelectedTypeId(item.id)
        if (type === 'doctorTypes') {
            fetchDoctorAppUsers(item.id);
        } else if (type === 'otherTypes') {
            fetchOtherTypeAppUsers(item.id);
        }
    };

    const onHidden = async (user, status) => {
        try {
            const updateDoctor = {
                emailVerified: status,
                verified: status
            }
            const apiEndPoint = `users/${user.id}`
            await http.patch(apiEndPoint, updateDoctor);
            const updatedUser = { ...user, ...updateDoctor };
            if (selectedType === 'doctorTypes') {
                setDoctorAppUsers((prev) =>
                    prev.map((appUser) => (appUser.id === updatedUser.id ? updatedUser : appUser))
                );
            } else if (selectedType === 'otherTypes') {
                setOtherAppUsers((prev) =>
                    prev.map((appUser) => (appUser.id === updatedUser.id ? updatedUser : appUser))
                );
            }
            message.success('แก้ไขข้อมูลเรียบร้อย')
        } catch (error) {
            console.error('Error updating user:', error);
            message.error('เกิดข้อผิดพลาดในการแก้ไขข้อมูล');
        }

    }
    // Columns for the appUsers table (adjust as needed)
    const columns = [
        {
            title: 'ภาพโปรไฟล์',
            dataIndex: 'profileImage',
            key: 'profileImage',
            render: (text) => <Avatar src={text} alt="Profile Image" />,
        },
        {
            title: 'ชื่อ',
            dataIndex: 'fullname',
            key: 'fullname',
        },
        {
            title: 'อีเมล',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'เชี่ยวชาญ',
            dataIndex: ['detail', 'specialist'],
            key: 'specialist',
        },
        {
            title: 'แผนก',
            dataIndex: ['detail', 'department'],
            key: 'department',
        },
        {
            title: 'วุฒิการศึกษา',
            dataIndex: ['detail', 'educational'],
            key: 'educational',
        },
        {
            title: 'คำสั่ง',
            key: 'action',
            render: (record) => {
                const isHidden = !(record.verified && record.emailVerified)
                return isHidden ? (
                    <Popconfirm
                        title="ต้องการจะยกเลิกการซ่อนชื่อผู้ใช้ใช่หรือไม่"
                        onConfirm={() => onHidden(record, true)}
                        okText="ใช่"
                        cancelText="ไม่"
                    >
                        <RemoveHiddenButton>ยกเลิกการซ่อนผู้ใช้งาน</RemoveHiddenButton>
                    </Popconfirm>
                ) : (
                    <Popconfirm
                        title="ต้องการจะซ่อนชื่อผู้ใช้ใช่หรือไม่"
                        onConfirm={() => onHidden(record, false)}
                        okText="ใช่"
                        cancelText="ไม่"
                    >
                        <Button type="danger">ซ่อนผู้ใช้งาน</Button>
                    </Popconfirm>
                )
            }
        }
    ];


    const renderTypeItems = (items, type) => {
        return (
            <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
                {items.map((item) => (
                    <Col span={8} key={item.id}>
                        <div
                            className={`type-item ${selectedTypeId === item.id ? 'selected' : ''}`}
                            onClick={() => handleTypeSelection(item, type)}
                        >
                            {item.name}
                        </div>
                    </Col>
                ))}
            </Row>
        );
    }
    const filteredDoctorAppUsers = doctorAppUsers.filter((user) =>
        user.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredOtherAppUsers = otherAppUsers.filter((user) =>
        user.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const onChangeTab = (key) => {
        setSelectedType(key)
    }


    return (
        <Layout
            {...props}
            render={(
                props, //eslint-disable-line
            ) => (
                <Container>
                    <Tabs defaultActiveKey="doctorTypes" onChange={(key) => onChangeTab(key)} style={{ background: '#fff', padding: 20 }}>
                        <TabPane tab="แพทย์" key="doctorTypes">
                            {loading ? <Spin size="large" /> : renderTypeItems(doctorTypes, 'doctorTypes')}
                        </TabPane>
                        <TabPane tab="วิชาชีพอื่น" key="otherTypes">
                            {loading ? <Spin size="large" /> : renderTypeItems(otherTypes, 'otherTypes')}
                        </TabPane>
                    </Tabs>
                    <HeaderWrapper>
                        <FileOutlined />
                        <Text bold>รายชื่อแพทย์</Text>
                        <Search
                            placeholder="ค้นหา..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ width: 200, marginLeft: 'auto' }}
                        />

                    </HeaderWrapper>
                    {
                        loading ? <Spin size="large" /> : selectedType === 'doctorTypes' ? (
                            <Table dataSource={filteredDoctorAppUsers} columns={columns} rowKey="id" />
                        ) : (
                            <Table dataSource={filteredOtherAppUsers} columns={columns} rowKey="id" />
                        )
                    }
                </Container>
            )}
        />
    )
}
export default DoctorList
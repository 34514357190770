import { observable, action, computed, toJS } from 'mobx'
// import jwtDecode from 'jwt-decode'

import http from '../../services/httpService'

const apiEndpoint = '/Communications'
// const tokenKey = 'token'

class Product {
  @observable payload = {
    data: [],
    token: '',
    error: null,
    searchResults: []
  }

  @action fetchToken = async email => {
    try {
      const token = await http.get(`${apiEndpoint}/getTokenChat?username=${email}`)
      this.payload.token = token.data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchRecordFromRoomId = async sid => {
    try {
      const { data } = await http.get(`${apiEndpoint}/getRecordByRoomId`, {
        params: { roomId: sid }
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @computed get getToken() {
    return toJS(this.payload.token)
  }
}

export default new Product()

import React, { useEffect, useState } from 'react'
import { Form, Select, Input, Button, Row, Col, Tag, message } from 'antd'
import Tabs from '../../../Tabs'
import { tabInfo } from './TabConstant'
import styled from 'styled-components'
import CardEmergency from '../../../Card/Emergency/index'
import http from '../../../../services/httpServiceEver'
const { TabPane } = Tabs

const Container = styled.div`
  padding: 20px;
`
export default (props) => {
  const { tabInit, innerTab, history } = props
  const { defaultActiveKey, onChangeTab, operations, setDetail, user, ems, setCurrentPosition, finishedAdd, tab } = tabInit
  const [emsForm, setEmsForm] = useState([])

  useEffect(() => {
    fetchEmsForm()
    console.log('finishedAdd', finishedAdd)
  }, [finishedAdd])

  const fetchEmsForm = async () => {
    try {
      const response = await http.get('/emsForms')
      if (response && response.status === 200) {
        todayEmsForm(response.data)
      }
    } catch (error) {
      message.error('เกิดข้อผิดพลาดในการโหลดข้อมูล EMS Typologies')
    }
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [year, month, day].join('-')
  }

  const todayEmsForm = (ems) => {
    const emsFormToday = ems.filter((item) => formatDate(item.operationDateTime) === formatDate(new Date()) && item.status === 'customAdd')
    setEmsForm(emsFormToday)
  }

  const Styler = () => (
    <style>{`
            .ant-tabs-tab + .ant-tabs-tab {
                margin: 0 0 0 ${emsForm ? '18' : '32'}px;
            }
        `}</style>
  )
  return (
    <Tabs defaultActiveKey={defaultActiveKey} activeKey={tab} onChange={(key) => onChangeTab(key)} tabBarExtraContent={{ left: operations }}>
      <Styler />
      {tabInfo.map((item, index) =>
        index !== 3 ? (
          <TabPane tab={item.tabName} key={item.key}>
            <Container>
              <CardEmergency
                setDetail={(data) => setDetail(data)}
                user={user}
                ems={ems}
                tab={item.key}
                setCurrentPosition={setCurrentPosition}
                status={item.status}
                ambulance={innerTab[index].ambulance}
                updatePatient={(patient) => innerTab[index].setPatient(patient)}
                setKey={(key) => innerTab[index].setKey(key)}
                setReporter={(reporter) => innerTab[index].setReporter(reporter)}
                history={history}
                setTabActiveKey={onChangeTab}
              />
            </Container>
          </TabPane>
        ) : index === 3 && emsForm.length ? (
          <TabPane tab={item.tabName} key={item.key}>
            <Container>
              <CardEmergency
                setDetail={(data) => setDetail(data)}
                user={user}
                ems={ems}
                tab={item.key}
                setCurrentPosition={setCurrentPosition}
                status={item.status}
                ambulance={innerTab[index].ambulance}
                updatePatient={(patient) => innerTab[index].setPatient(patient)}
                setKey={(key) => innerTab[index].setKey(key)}
                setReporter={(reporter) => innerTab[index].setReporter(reporter)}
                emsForm={emsForm.sort((a, b) => b.id - a.id)}
                setFinishedAdd={(val) => innerTab[index].setFinishedAdd(val)}
                history={history}
                setTabActiveKey={onChangeTab}
              />
            </Container>
          </TabPane>
        ) : null,
      )}
    </Tabs>
  )
}

import React from 'react'
import ReactQuill from 'react-quill'
import { TOOLBAR_OPTIONS } from './constant'
import 'react-quill/dist/quill.snow.css'
import { EditorBox, FloatTitleBox, FloatTitleText } from './style'
const TextEditor = ({ title, value, setValue, readOnly = false }) => {
  return (
    <EditorBox>
      <FloatTitleBox>
        <FloatTitleText backgroundColor="#64e8e3" titleColor="dark">
          {title}
        </FloatTitleText>
      </FloatTitleBox>
      <ReactQuill modules={TOOLBAR_OPTIONS} theme="snow" value={value} onChange={setValue} readOnly={readOnly} className="mt-2" />
    </EditorBox>
  )
}

export default TextEditor

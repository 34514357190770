import React, { useState, useEffect } from 'react'
import { Card, Row, Col } from 'antd'
import { PressureText } from './styles'
import { Pie } from 'react-chartjs-2'
import { Chart, ArcElement } from 'chart.js'

Chart.register(ArcElement);

function PressureTrendCard(props) {
  const [values, setValues] = useState(props.trends)

  useEffect(() => {
    setValues(props.trends)
  }, [props])

  return (
    <Card className="mt-2" title="แนวโน้มค่าความดันโลหิต">
      <Row>
        <Col span={12}>
          <Row>
            <PressureText span={12} color="#000000">
              รายการ
            </PressureText>
            <PressureText span={12} color="#000000">
              จำนวนครั้ง
            </PressureText>
            <PressureText span={12} color="#32cd32">
              ความดันปกติ
            </PressureText>
            <PressureText span={12} color="#32cd32">
              {values.mid}
            </PressureText>
            <PressureText span={12} color="#76c9d4">
              ความดันต่ำ
            </PressureText>
            <PressureText span={12} color="#76c9d4">
              {values.low}
            </PressureText>
            <PressureText span={12} color="red">
              ความดันสูง
            </PressureText>
            <PressureText span={12} color="red">
              {values.high}
            </PressureText>
            <PressureText span={12} color="#000000">
              ทั้งหมด
            </PressureText>
            <PressureText span={12} color="#000000">
              {values.low + values.mid + values.high}
            </PressureText>
          </Row>
        </Col>
        <Col span={12}>
          <Pie
            data={values.pie}
            options={{
              legend: {
                display: true,
                position: 'right',
              },
            }}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default PressureTrendCard

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table, Select, Button, Row, Col, DatePicker } from 'antd'
import ReactExport from 'react-data-export'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
const vReferStatistic = ({ referData, fetchReports, isLoading }) => {
  const [referType, setReferType] = useState('in')
  const [startDateTime, setStartDateTime] = useState(
    moment()
      .subtract(1, 'months')
      .set('hours', 0)
      .format('YYYY-MM-DD'),
  )
  const [endDateTime, setEndDateTime] = useState(
    moment()
      .set('hours', 0)
      .format('YYYY-MM-DD'),
  )

  useEffect(() => {
    fetchReports(startDateTime, endDateTime, referType)
  }, [])

  const columns = [
    {
      title: 'ศูนย์บริการ',
      key: 'serviceCenter',
      render: (record) => <p>{record.hospName}</p>,
      align: 'center',
    },
    {
      title: 'Pending',
      key: 'nbOfPendingRecord',
      render: (record) => <p>{record.nbOfPendingRecord}</p>,
      sorter: (a, b) => a.nbOfPendingRecord - b.nbOfPendingRecord,
      align: 'center',
    },
    {
      title: 'Accept',
      key: 'nbOfAcceptedRecord',
      render: (record) => <p>{record.nbOfAcceptedRecord}</p>,
      sorter: (a, b) => a.nbOfAcceptedRecord - b.nbOfAcceptedRecord,
      align: 'center',
    },
    {
      title: 'Reject',
      key: 'nbOfRejectedRecord',
      render: (record) => <p>{record.nbOfRejectedRecord}</p>,
      sorter: (a, b) => a.nbOfRejectedRecord - b.nbOfRejectedRecord,
      align: 'center',
    },
    {
      title: 'Unknown',
      key: 'nbOfUnknownRecord',
      render: (record) => <p>{record.nbOfUnknownRecord}</p>,
      sorter: (a, b) => a.nbOfUnknownRecord - b.nbOfUnknownRecord,
      align: 'center',
    },
    {
      title: 'Total',
      key: 'total',
      render: ({ nbOfPendingRecord, nbOfAcceptedRecord, nbOfRejectedRecord, nbOfUnknownRecord }) => <p>{nbOfPendingRecord + nbOfAcceptedRecord + nbOfRejectedRecord + nbOfUnknownRecord}</p>,
      align: 'center',
    },
  ]
  return (
    <div>
      <Row type="flex" justify="center" gutter={[10, 10]} className="my-3">
        <Col className="d-flex align-items-center">
          <p>วันที่เริ่มต้น:</p>
          <DatePicker
            format="DD/MM/YYYY"
            defaultValue={moment(startDateTime)}
            onChange={(value) => {
              setStartDateTime(
                moment(value)
                  .set('hours', 0)
                  .format('YYYY-MM-DD'),
              )
            }}
            abbr
          />
        </Col>
        <Col className="d-flex align-items-center">
          วันที่สิ้นสุด:
          <DatePicker
            format="DD/MM/YYYY"
            defaultValue={moment(endDateTime)}
            onChange={(value) => {
              setEndDateTime(
                moment(value)
                  .set('hours', 0)
                  .format('YYYY-MM-DD'),
              )
            }}
            abbr
          />
        </Col>
        <Col className="d-flex align-items-center">
          <Select
            defaultValue="in"
            style={{
              width: 120,
            }}
            onChange={(value) => setReferType(value)}
          >
            <Option value="in">Refer In</Option>
            <Option value="out">Refer Out</Option>
          </Select>
        </Col>
        <Col className="d-flex align-items-center">
          <Button type="primary" onClick={() => fetchReports(startDateTime, endDateTime, referType)}>
            แสดงข้อมูล
          </Button>
        </Col>
        {referData.length > 0 ? (
          <Col className="d-flex align-items-center">
            <ExcelFile
              element={<Button>Export File</Button>}
              filename={`Refer_Statistics_${moment()
                .add(543, 'years')
                .format('DD/MM/YYYY')}`}
            >
              <ExcelSheet data={referData} name="Refer Statistics">
                <ExcelColumn label="ศูนย์บริการ" value="hospName" />
                <ExcelColumn label="Pending" value="nbOfPendingRecord" />
                <ExcelColumn label="Accept" value="nbOfAcceptedRecord" />
                <ExcelColumn label="Reject" value="nbOfRejectedRecord" />
              </ExcelSheet>
            </ExcelFile>
          </Col>
        ) : (
          ''
        )}
      </Row>

      <Table columns={columns} dataSource={referData} pagination={{ pageSize: 7 }} loading={isLoading} bordered></Table>
    </div>
  )
}

export default vReferStatistic

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

import ProfileHeader from '../../components/ProfileHeader'
import PrescriptionTable from '../../components/PrescriptionTable'

@observer
class PrescriptionList extends Component {
  static propTypes = {
    // User: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  async componentDidMount() {
    // const { User } = this.props
    // await User.fetchInfo()
    // await User.setUpNotification()
    // ConsultCase.fetchCases()
  }

  render() {
    const {
      history,
    } = this.props
    return (
      <div
        style={{
          width: '100vw',
          maxWidth: '100vw',
          height: '100vh',
          maxHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <ProfileHeader
          history={history}
        />
        <PrescriptionTable />
      </div>
    )
  }
}
export default PrescriptionList

import React from 'react'
import { Modal } from 'antd'
const CancelBookingFormModal = ({ visibleCancelBookingModal, setVisibleCancelBookingModal, children }) => {
  return (
    <Modal
      visible={visibleCancelBookingModal}
      onOk={() => {
        setVisibleCancelBookingModal(false)
      }}
      onCancel={() => {
        setVisibleCancelBookingModal(false)
      }}
      title="สาเหตุการยกเลิก"
      footer={null}
    >
      {children}
    </Modal>
  )
}

export default CancelBookingFormModal

import React,{useEffect, useState} from 'react'
import { Table, Tag, Select,Card,Row,Col } from 'antd'
import styled from 'styled-components'
import Tabs from '../Tabs';

const { TabPane } = Tabs

const { Option } = Select
const Text = styled('p')`
  font-weight: bold;
`
const HeaderTop = styled.h2`
  margin : 10px;
`
const Container = styled.div`
  width: 100%;
  padding : 10px ;
`
const index = ({ ambulanceData, updateAmbulance }) => {
  const [tab, setTab] = useState('1')
  const [readyCar, setReadyCar] = useState([])
  const [nonReadyCar, setNonReadyCar] = useState([])
  const [totalCar, setTotalCar] = useState([])

  useEffect(()=> {
    fetchFiltered()
  },[ambulanceData])

  const fetchFiltered = () => {
    const ready = ambulanceData.filter(item => item.availability === true)
    const nonReady = ambulanceData.filter(item => item.availability === false)
    setReadyCar(ready);
    setNonReadyCar(nonReady);
    setTotalCar(ambulanceData);
  }

  const onChangeTab = (tab) => {
    setTab(tab)
  }

  const columns = [
    {
      title: 'เลขรถ',
      key: 'no',
      width: 70,
      align: 'center',
      render: (text) => <Text>เลขรถ {text.no}</Text>,
    },
    {
      title: 'ทะเบียน',
      key: 'availability',
      width: 70,
      render: (text) => <Tag>{text.licensePlate}</Tag>,
    },
    {
      title: 'รายละเอียด',
      key: 'note',
      width: 300,
      render: (text) => (
        <Select value={text.note} onChange={(value) => updateAmbulance(value, text.no)} className="w-100">
          <Option style={{ color : 'limegreen'}} value="พร้อมใช้งาน">พร้อมใช้งาน</Option>
          <Option style={{ color : 'orangered'}} value="ทีมไม่พร้อม">ทีมไม่พร้อม</Option>
          <Option style={{ color : 'orangered'}} value="อุปกรณ์ไม่พร้อม">อุปกรณ์ไม่พร้อม</Option>
          <Option style={{ color : 'orangered'}} value="รถพยาบาลไม่พร้อม">รถพยาบาลไม่พร้อม</Option>
          <Option style={{ color : 'blue'}} value="ภารกิจพิเศษ">ภารกิจพิเศษ</Option>
        </Select>
      )
    },
  ]
  const ambulanceTabInfo = [
    {
      key : '1',
      tabName : `พร้อมใช้งาน (${readyCar.length})`,
      dataSource : readyCar
    },
    {
      key : '2',
      tabName : `ไม่พร้อมใช้งาน (${nonReadyCar.length})`,
      dataSource : nonReadyCar
    },
    {
      key : '3',
      tabName : `ทั้งหมด (${totalCar.length})`,
      dataSource : totalCar
    }
  ]


  const Styler = ({color}) => (
    <style>{`
            .ant-tab-margin .ant-tabs-tab:nth-of-type(1){
                margin: 0px 20px 0px 0px;
            }
            #ant-border-dropdown-green > .ant-select-show-arrow > .ant-select-selector {
              border-radius: 20px;
              border-color : limegreen;
              color : limegreen;
            }
            #ant-border-dropdown-red > .ant-select-show-arrow > .ant-select-selector {
              border-radius: 20px;
              border-color : orangered;
              color : orangered;
            }
            #ant-border-dropdown-green > .ant-select-show-arrow > .ant-select-arrow {
              color : limegreen;
            }
            #ant-border-dropdown-red > .ant-select-show-arrow > .ant-select-arrow {
              color : orangered;
            }
            
        `}</style>
    )

  return (
    <Container>
      <Styler color={tab} />
      <HeaderTop>
        สถานะรถพยาบาล
      </HeaderTop>
      <Card style={{ borderRadius : 5}}>
        <Tabs defaultActiveKey="1" onChange={(key) => onChangeTab(key)} className='ant-tab-margin'>
          {
            ambulanceTabInfo.map((item,index)=>(
              <TabPane tab={item.tabName} key={item.key}>
                <Container style={{ minHeight: '180px', maxHeight: '180px', overflowY: 'auto' }}>
                {
                item.dataSource.map((itemInner,indexInner) => {
                  if([25,26,27,29].includes(itemInner.no)){
                    return (
                      <Row style={{padding : 10}}>
                          <Col span={6}>
                            {columns[0].render(itemInner)}
                          </Col>
                          <Col span={6}>
                            {columns[1].render(itemInner)} 
                          </Col>
                          <Col id={`ant-border-dropdown-${itemInner.note === 'พร้อมใช้งาน' ? 'green': 'red'}`} span={12}>
                            {columns[2].render(itemInner)} 
                          </Col>
                        </Row>
                      )
                  }
                }
              )}
              {
                item.dataSource.map((itemInner,indexInner)=> {
                if(![25,26,27,29].includes(itemInner.no)) return (
                  <Row style={{padding : 10}}>
                    <Col span={6}>
                      {columns[0].render(itemInner)}
                    </Col>
                    <Col span={6}>
                      {columns[1].render(itemInner)} 
                    </Col>
                    <Col id={`ant-border-dropdown-${itemInner.note === 'พร้อมใช้งาน' ? 'green': 'red'}`} span={12}>
                      {columns[2].render(itemInner)} 
                    </Col>
                  </Row>
                )}
                )
              }
              </Container>
              </TabPane>
            ))
          }
        </Tabs>
      </Card>
      {/* <Table columns={columns} dataSource={ambulanceData} pagination={false} scroll={{ x: 'max-content' }} /> */}
    </Container>
  )
}

export default index

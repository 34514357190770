import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _orderBy from 'lodash/orderBy'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import Message from './Message'
import FormMessage from './FormMessage'

const Container = styled.div`
  border-radius: 3px;
  box-shadow: 0 0 10px -1px;
  background: white;
  display: flex;
  flex-direction: column;
`
const offsetFormMessage = 92
const MessageListContainer = styled.div`
  padding: 20px;
  flex: 1;
  overflow: auto;
  max-height: ${() => `calc(100vh - ${offsetFormMessage}px)`};
`

@inject('MessengerHistories')
@observer
class MessageList extends Component {
  static propTypes = {
    channel: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    MessengerHistories: PropTypes.object.isRequired,
    patientConsultId: PropTypes.string.isRequired,
  }

  state = {
    messages: []
  }

  componentDidMount() {
    const { channel } = this.props
    channel.on('messageAdded', this.onMessageAdded)
    this.initMessage()
    this.scrollToBottom()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.patientConsultId !== this.props.patientConsultId) {
      this.initMessage()
    }
    this.scrollToBottom()
  }

  onMessageAdded = (message) => {
    const { timestamp, index, body, author } = message.state
    const { userInfo } = this.props
    const jsonBody = JSON.parse(body)
    const { text, user: { _id, avatar } } = jsonBody
    if (author !== userInfo.email) {
      const messagesObj = {
        _id: index,
        text,
        createdAt: moment(timestamp),
        user: {
          _id: 2,
          name: author,
          avatar
        },
        sent: true,
        senderId: _id,
      }
      this.setState(prevState => ({
        messages: [...prevState.messages, messagesObj]
      }))
    }
  }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ behavior: 'smooth' })
    }
  }

  onSend = async ({ text }) => {
    const { userInfo, channel, MessengerHistories, patientConsultId } = this.props
    const { id, profileImage, fullname } = userInfo
    const receiverId = patientConsultId

    const formatMessage = {
      _id: uuidv4(),
      text,
      createdAt: moment().format(),
      user: {
        _id: id,
        name: fullname,
        avatar: profileImage
      },
      sent: true,
      received: true,
      senderId: id,
      receiverId
    }

    this.setState((previousState) => ({
      messages: [...previousState.messages, formatMessage],
    }))

    await channel.sendMessage(JSON.stringify(formatMessage))
    await MessengerHistories.createMessage(formatMessage)
  }

  initMessage = async () => {
    const {
      userInfo,
      MessengerHistories,
      patientConsultId,
    } = this.props
    const doctorConsultId = userInfo.id
    const filter = JSON.stringify({ where: {
      or: [
        {
          and: [
            {
              senderId: doctorConsultId
            },
            {
              receiverId: patientConsultId
            }
          ]
        },
        {
          and: [
            {
              senderId: patientConsultId
            },
            {
              receiverId: doctorConsultId
            }
          ]
        }
      ]
    }
    })

    await MessengerHistories.fetchMessages(filter)
    const messages = MessengerHistories.getMessages

    const reformatMessage = messages.map(val => {
    if (val.user._id === userInfo.id) { // eslint-disable-line
        return {
        _id: val._id, // eslint-disable-line
          text: val.text,
          createdAt: val.createdAt,
          user: {
            _id: 1,
            name: val.user.name,
            avatar: val.user.avatar
          },
          sent: val.sent,
          received: val.received,
          id: val.id,
          senderId: val.senderId,
          receiverId: val.receiverId
        }
      }
      return val
    })
    this.setState({ messages: reformatMessage.reverse() })
  }

  render() {
    const { userInfo } = this.props
    const { messages } = this.state
    const sortedMessages = _orderBy(messages, ['createdAt'], ['asc'])
    let nextMessage
    let prevMessage = {
      createdAt: ''
    }
    return (
      <Container>
        <MessageListContainer>
          {
          sortedMessages.map((message, index) => {
            nextMessage = sortedMessages[index + 1] || { senderId: '' }
            const isSameDay = moment(message.createdAt).isSame(prevMessage.createdAt, 'day')
            const position = getLocationMessage(message, userInfo)
            const isShowAvatar = nextMessage.senderId !== message.senderId
            prevMessage = message
            return (
              <Message
                isShowDate={!isSameDay}
                message={message}
                position={position}
                showAvatar={isShowAvatar}
              />
            )
          })
        }
          <div ref={c => { this.messagesEnd = c }} />
        </MessageListContainer>
        <FormMessage
          onSend={this.onSend}
        />
      </Container>
    )
  }
}

export default MessageList
function getLocationMessage(message, userInfo) {
  return message.senderId === userInfo.id
    ? 'right'
    : 'left'
}

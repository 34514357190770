import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import Intercom from '../Intercom'

const ProtectedRoute = inject('User')(
  observer(({ path, component: Component, render, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (!rest.User.getSession) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }

        return Component ? (
          <>
            <Intercom path={path} auth={rest.User.getSession} />
            <Component {...props} {...rest} />{' '}
          </>
        ) : (
          render(props)
        )
      }}
    />
  ))
)

export default ProtectedRoute

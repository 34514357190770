import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getBookings, getBooking, updateBooking } from '../apiServices/bloodBookingServices'
const initialState = {
  donorBookingLists: [],
  donorBooking: {},
  isLoadingData: false,
  errorMessage: '',
}

export const fetchBookings = createAsyncThunk('bloodDonationBooking/fetchBookings', async (params, { rejectWithValue }) => {
  try {
    const response = await getBookings()
    return response?.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.error)
  }
})

export const fetchBooking = createAsyncThunk('bloodDonationBooking/fetchBooking', async (bookingId, { rejectWithValue }) => {
  try {
    const response = await getBooking(bookingId)
    return response?.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.error)
  }
})

export const updateBookingById = createAsyncThunk('bloodDonationBooking/updateBookingById', async (params, { rejectWithValue }) => {
  try {
    const response = await updateBooking(params)
    params.callback(true)
    return response?.data
  } catch (error) {
    params.callback(false)
    return rejectWithValue(error?.response?.data?.error)
  }
})

export const bloodDonationBooking = createSlice({
  name: 'bloodDonationBooking',
  initialState,
  reducers: {
    resetData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBookings.pending, (state) => {
      state.isLoadingData = true
    })
    builder.addCase(fetchBookings.fulfilled, (state, action) => {
      state.donorBookingLists = action?.payload
      state.errorMessage = ''
      state.isLoadingData = false
    })
    builder.addCase(fetchBookings.rejected, (state, action) => {
      state.errorMessage = action?.payload?.message ?? ''
      state.donorBooking = {}
      state.isLoadingData = false
    })
    builder.addCase(fetchBooking.pending, (state) => {
      state.isLoadingData = true
    })
    builder.addCase(fetchBooking.fulfilled, (state, action) => {
      state.donorBooking = action?.payload.length ? action?.payload[0] : {}
      state.errorMessage = ''
      state.isLoadingData = false
    })
    builder.addCase(fetchBooking.rejected, (state, action) => {
      state.errorMessage = action?.payload?.message ?? ''
      state.donorBooking = {}
      state.isLoadingData = false
    })
    builder.addCase(updateBookingById.pending, (state) => {
      state.isLoadingData = true
    })
    builder.addCase(updateBookingById.fulfilled, (state, action) => {
      state.donorBooking = action?.payload
      state.errorMessage = ''
      state.isLoadingData = false
    })
    builder.addCase(updateBookingById.rejected, (state, action) => {
      state.errorMessage = action?.payload?.message ?? ''
      state.isLoadingData = false
    })
  },
})

export const { resetData } = bloodDonationBooking.actions

export default bloodDonationBooking.reducer

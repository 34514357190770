import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getNotes, getNote, updateNoteById, createCheckupNote } from '../apiServices/healthCheckupServices'
const initialState = {
  notes: [],
  note: {},
  isLoading: false,
  errorMessage: '',
}

export const fetchNotes = createAsyncThunk('healthCheckUp/fetchNotes', async (params, { rejectWithValue }) => {
  try {
    const response = await getNotes(params)
    return response?.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.error)
  }
})

export const fetchNote = createAsyncThunk('healthCheckUp/fetchNote', async (id, { rejectWithValue }) => {
  try {
    const response = await getNote(id)
    return response?.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.error)
  }
})

export const updateNote = createAsyncThunk('healthCheckUp/updateNote', async (params, { rejectWithValue }) => {
  try {
    const response = await updateNoteById(params)
    params.callback(true)
    return response?.data
  } catch (error) {
    params.callback(false)
    return rejectWithValue(error?.response?.data?.error)
  }
})

export const createNote = createAsyncThunk('healthCheckUp/createNote', async (params, { rejectWithValue }) => {
  try {
    const response = await createCheckupNote(params)
    params.callback(true, response?.data?.id)
    return response?.data
  } catch (error) {
    params.callback(false)
    return rejectWithValue(error?.response?.data?.error)
  }
})

export const healthCheckUp = createSlice({
  name: 'healthCheckUp',
  initialState,
  reducers: {
    resetData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotes.pending, (state) => {
      state.isLoadingData = true
    })
    builder.addCase(fetchNotes.fulfilled, (state, action) => {
      state.notes = action?.payload
      state.errorMessage = ''
      state.isLoadingData = false
    })
    builder.addCase(fetchNotes.rejected, (state, action) => {
      state.errorMessage = action?.error?.message ?? ''
      state.notes = []
      state.isLoadingData = false
    })
    builder.addCase(fetchNote.pending, (state) => {
      state.isLoadingData = true
    })
    builder.addCase(fetchNote.fulfilled, (state, action) => {
      state.note = action?.payload
      state.errorMessage = ''
      state.isLoadingData = false
    })
    builder.addCase(fetchNote.rejected, (state, action) => {
      state.errorMessage = action?.error?.message ?? ''
      state.note = {}
      state.isLoadingData = false
    })
    builder.addCase(updateNote.pending, (state) => {
      state.isLoadingData = true
    })
    builder.addCase(updateNote.fulfilled, (state, action) => {
      state.note = action?.payload
      state.errorMessage = ''
      state.isLoadingData = false
    })
    builder.addCase(updateNote.rejected, (state, action) => {
      state.errorMessage = action?.error?.message ?? ''
      state.isLoadingData = false
    })
  },
})

export const { resetData } = healthCheckUp.actions

export default healthCheckUp.reducer

import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles, { CheckboxWithLabel, Column, Row } from '../styles'
import { get } from 'lodash'
import moment from 'moment'

export default function PatientTable({ data }) {

  const patientName = get(data, "firstName", "") + " " + get(data, "lastName", "")
  const age = get(data, "age", "")
  const gender = get(data, "gender", "")
  const birthDate = get(data, "birthDate", "")
  const weight = get(data, "weight", "")
  const cid = get(data, "cid", "")
  const nationality = get(data, "nationality", "")
  const passSportId = get(data, "passSportId", "")
  const treatment = get(data, "treatment", "")
  let treatmentSpilt = treatment
  let treatmentMore = ""
  if (treatment.includes("→")) {
    treatmentSpilt = treatment.split("→")[0]
    treatmentMore = treatment.split("→").length > 1 ? treatment.split("→")[1] : ""
  }
  const moreTreatment = ["ประกันอื่นๆ", "เบิกจ่ายตรง", "ชำระเงินสด", "ประกันท่องเที่ยว", "ประกันชีวิต"].includes(treatmentSpilt)

  return (
    <View style={styles.borderedContainer}>
      <Row>
        <Column borderBottom borderRight flex={3}>
          <Text style={styles.normalText}>11.ชื่อนามสกุลผู้ป่วย</Text>
          <Text style={styles.normalText}>{patientName}</Text>
        </Column>
        <Column borderBottom borderRight width={35}>
          <Text style={styles.normalText}>12.อายุ</Text>
          <Text style={styles.normalText}>{age}</Text>
        </Column>
        <Column borderBottom borderRight flex={1}>
          <Text style={styles.normalText}>13.เพศ</Text>
          <Row paddingHorizontal={3}>
            <CheckboxWithLabel small checked={gender === "ชาย"} title="ชาย" />
            <CheckboxWithLabel small checked={gender === "หญิง"} title="หญิง" />
          </Row>
        </Column>
        <Column borderBottom borderRight flex={1}>
          <Text style={styles.normalText}>14.วัน/เดือน/ปีเกิด</Text>
          <Text style={styles.normalText}>{moment(birthDate).isValid() ? new Date(moment(birthDate)).toLocaleDateString('th-Th', { day: 'numeric', month: 'short', year: 'numeric' }) : ""}</Text>
        </Column>
        <Column borderBottom borderRight width={50}>
          <Text style={styles.normalText}>15.น้ำหนัก </Text>
          <Text style={styles.normalText}>{weight}</Text>
        </Column>
        <Column borderBottom flex={2}>
          <Text style={styles.normalText}>16.เลขบัตรประชาชน</Text>
          <Text style={styles.normalText}>{nationality !== "ไทย" ? "" : cid}</Text>
        </Column>
      </Row>
      <Row>
        <Column borderRight width={105}>
          <Text style={styles.normalText}>17.สัญชาติ(กรณีไม่ใช่คนไทย)</Text>
          <Text style={styles.smallerText}>{nationality}</Text>
        </Column>
        <Column borderRight width={100}>
          <Text style={styles.normalText}>18.Passport ID</Text>
          <Text style={styles.smallerText}>{nationality !== "ไทย" ? cid : ""}</Text>
        </Column>
        <Column flex={1}>
          <Text style={styles.normalText}>19.สิทธิการรักษา</Text>
          <Row paddingHorizontal={3}>
            <CheckboxWithLabel small checked={treatmentSpilt === "ไม่มีหลักประกัน"} title="ไม่มีหลักประกัน" />
            <CheckboxWithLabel small checked={treatmentSpilt === "ประกันสุขภาพ"} title="ประกันสุขภาพ" more={treatmentSpilt === "ประกันสุขภาพ" && treatmentMore} />
            <CheckboxWithLabel small checked={treatmentSpilt === "ข้าราชการ"} title="ข้าราชการ" />
            <CheckboxWithLabel small checked={treatmentSpilt === "ประกันสังคม"} title="ประกันสังคม" more={treatmentSpilt === "ประกันสังคม" && treatmentMore} />
            <CheckboxWithLabel small checked={treatmentSpilt === "แรงงานต่างด้าวขึ้นทะเบียน"} title="แรงงานต่างด้าวขึ้นทะเบียน" />
            <CheckboxWithLabel maxWidth={100} small checked={moreTreatment} title={moreTreatment ? treatmentSpilt : "ประกันอื่นๆ"} more={moreTreatment && treatmentMore} />
          </Row>
        </Column>
      </Row>
    </View>)
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getUserInfo } from '../apiServices/userServices'
const initialState = {
  userInfo: {},
  isLoading: false,
  errorMessage: '',
}

export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async (callback, { rejectWithValue }) => {
  const response = await getUserInfo()
  callback(response)
  if (response?.status < 200 || response?.status >= 300) {
    return rejectWithValue(error?.response?.data?.error)
  }
  return response?.data
})

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetData: () => initialState,
    setUser: (state, action) => {
      state.userInfo = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.userInfo = action?.payload
      state.errorMessage = ''
      state.isLoading = false
    })
    builder.addCase(fetchUserInfo.rejected, (state, action) => {
      state.errorMessage = action?.error?.message ?? ''
      state.userInfo = {}
      state.isLoading = false
    })
  },
})

// Action creators are generated for each case reducer function
export const { resetData, setUser } = user.actions

export default user.reducer

import React, { useState, useCallback, useEffect } from 'react'
import firebase from 'firebase'
import styled from 'styled-components'
import { Row, Col, message, Modal, Button, List, Avatar, Popconfirm } from 'antd'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import UAParser from 'ua-parser-js'
import Layout from '../Home'
import http from '../../services/httpService'
import httpEver from '../../services/httpServiceEver'
import Axios from 'axios'
import Loading from '../../components/Loading'
import Summary from './Summary'
import Room from './Room'
import RoomList from './RoomList'
import RoomDetail from './RoomDetail'
import RoomListDetail from './RoomListDetail'
import Chat from './Chat'
import { PRACTITIONER_ROLES } from '../../constant'
const apiEndpoint = '/Bookings'
import { HoverableListItem } from './styles'

const VideoChat = (props) => {
  const [username, setUsername] = useState('')
  const [roomName, setRoomName] = useState('')
  const [roomNameWithOutDate, setRoomNameWithOutDate] = useState('')
  const [token, setToken] = useState(null)
  const [patientStatus, setPatientStatus] = useState([])
  const [roomList, setRoomList] = useState(true)
  const [patient, setPatient] = useState(false)
  const [loading, setLoading] = useState(true)
  const [observerStaff, setObserverStaff] = useState([])
  const [callButtonLoading, setCallButtonLoading] = useState(false)
  const [roomId, setRoomId] = useState(false)
  const [chat, setChat] = useState(false)
  const [bookingId, setBookingId] = useState(false)
  const [image, setImage] = useState(false)
  const [summary, setSummary] = useState(false)
  const [date, setDate] = useState(false)
  const [note, setNote] = useState(false)
  const [booking, setBooking] = useState(false)
  const [practitionerRole, setPractitionerRole] = useState(jwtDecode(localStorage.getItem('token')).roles[0])
  const [isTestVideoCall, setIsTestVideoCall] = useState(false)
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const [visible, setVisible] = useState(false)

  const deviceInfo = () => {
    const detect = new UAParser()
    return {
      version: detect?.getBrowser()?.version,
      model: detect?.getOS()?.name,
      manufacturer: detect?.getBrowser()?.name,
    }
  }

  const fetchImage = async (patientId) => {
    const response = await httpEver.get(`/kycPatientImages/filterByPatientId?patientId=${patientId}`)
    if (response.status === 200) {
      setImage(response.data[0].image)
    } else {
      setImage(false)
    }
  }

  const checkStatusFirebase = (status, officerId, id, role, filterStatus = [], observerStaffId) => {
    if (filterStatus.includes(status) && role.includes('observerStaff') && id === observerStaffId) {
      return true
    }
    if (filterStatus.includes(status) && role.includes('callCenter')) {
      return true
    }
    if (filterStatus.includes(status) && ((PRACTITIONER_ROLES.includes(role) && officerId == id) || role === 'pharmacy')) {
      return true
    }
    return false
  }

  useEffect(() => {
    checkMobileAndTabletDevice()
    const getFirebaseStatus = () => {
      const { id, roles } = jwtDecode(localStorage.getItem('token'))
      // * filter type calling or waiting from /container/DoctorSetting/constant.js
      let filterStatus = ['inWaitingRoom', 'calling', 'doctorJoin']
      if (['callCenter', 'pharmacy', 'observerStaff'].includes(roles[0])) {
        const filterKey = props.history?.location?.state?.key || ''
        filterStatus = filterKey === 'waiting' ? ['inWaitingRoom', 'doctorJoin'] : filterKey === 'calling' ? ['calling'] : ['inWaitingRoom', 'calling', 'doctorJoin']
      }
      firebase
        .database()
        .ref('patientStatus')
        .on('value', (value) => {
          const data = []
          value.forEach((valueStatus) => {
            // console.log('valueStatus.val()',valueStatus.val());

            if (checkStatusFirebase(valueStatus.val().status, valueStatus.val().officerId, id, roles[0], filterStatus, valueStatus.val().observerStaffId)) {
              data.push({
                ...valueStatus.val(),
                key: valueStatus.key,
              })
            }
          })
          setPatientStatus(data)
          setLoading(false)
        })
    }
    getFirebaseStatus()
  }, [])

  const fetchTwilioToken = async () => http.get('/Communications/getToken')


  const handleSubmitCall = useCallback(
    async (event) => {
      setCallButtonLoading(true)
      const { data } = await fetchTwilioToken()
      const { id, roles } = jwtDecode(localStorage.getItem('token'))
      let status = 'inWaitingRoom'
      console.log('event', event);
      firebase
        .database()
        .ref(`patientStatus/${event.path}`)
        .on('value', (snapshot) => {
          status = snapshot.val().status
        })
      console.log('status', status);
      if (status === 'inWaitingRoom') {
        if (roles.includes('pharmacy')) {
          firebase
            .database()
            .ref(`patientStatus/${event.path}`)
            .update({ officerId: id, status: 'pharmacyJoin' })
        } else if (!roles.includes('observerStaff')) {
          firebase
            .database()
            .ref(`patientStatus/${event.path}`)
            .update({ status: 'doctorJoin' })
        }
        setRoomName(event.path + event.date)
        setRoomNameWithOutDate(event.path)
        setToken(data.token)
      } else if (roles.includes('observerStaff')) {
        setRoomName(event.path + event.date)
        setRoomNameWithOutDate(event.path)
        setToken(data.token)
      } else {
        message.error('มีเจ้าหน้าที่เชื่อมต่อแล้ว')
      }
      if (!roles.includes('observerStaff')) {
        updateDeviceFromDoctor(bookingId, deviceInfo())
      }
      setTimeout(() => {
        setCallButtonLoading(false)
      }, 5000)
    },
    [bookingId],
  )

  const handleInviteObserverStaff = async () => {
    const response = await httpEver.get("Users/observerStaffUsers");
    if (response.status === 200) {
      setObserverStaff(response.data);
      setVisible(true);
    }

  }

  const updateDeviceFromDoctor = async (id, doctorDevice) => {
    try {
      const response = await httpEver.patch(`${apiEndpoint}/${bookingId}`, { doctorDevice })
      console.log('updateDeviceFromDoctor response ===', response)
    } catch (e) {
      console.log('error updateDeviceFromDoctor =======', e)
    }
  }

  const handleDeclineCall = useCallback(async (event) => {
    firebase
      .database()
      .ref(`patientStatus/${event.path}`)
      .update({ status: 'completed', date: '' })
  }, [])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    const { data } = await fetchTwilioToken()
    setToken(data.token)
  }, [])

  const handleLogout = useCallback((event) => {
    setToken(null)
  }, [])

  const setToComplete = () => {
    console.log('123')
    const { id, roles } = jwtDecode(localStorage.getItem('token'))
    firebase
      .database()
      .ref(`patientStatus/${roomId.path}`)
      .update({ status: 'completed', date: '' })
    setSummary(true)
  }

  const createBookingRoom = async (bookingId, sid) => {
    await httpEver.post('/bookingRooms', {
      bookingId,
      sid,
    })
  }

  const updateBookingNote = async (bookingId, notes) => {
    const response = await httpEver.patch(`/Bookings/${bookingId}`, {
      notes,
    })
    if (response && response.data && response.status === 200) {
      message.success('บันทึกโน๊ตสำเร็จ')
    }
    await fetchBooking()
  }

  const fetchBooking = async () => {
    try {
      const { data } = await httpEver.get(`${apiEndpoint}/${bookingId}?filter[include]=doctor&filter[include]=patient&filter[include]=prescription`)
      setBooking(data)
    } catch (error) {
      console.log(error)
    }
  }

  const checkMobileAndTabletDevice = () => {
    const isMobile = (window.mobileAndTabletCheck = function () {
      let check = false
        ; (function (a) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
              a,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4),
            )
          )
            check = true
        })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    })
    setIsMobileDevice(isMobile)
  }

  const handleConfirm = (item) => {
    try {
      firebase.database().ref(`patientStatus/${roomId.path}`).update({ observerStaffId: item.id })
      message.success(`เชิญชวน ${item.fullname} สำเร็จ`);
    } catch (e) {
      message.error('เกิดข้อผิดพลาดในการเชิญชวน')
    }

  };

  const updateNote = (note) => {
    firebase
      .database()
      .ref(`patientStatus/${roomId.path}`)
      .update({ note: note })
  }

  return token ? (
    <Room
      path={roomId?.path}
      roomName={roomName}
      token={token}
      handleLogout={handleLogout}
      setToComplete={setToComplete}
      bookingId={bookingId}
      setPatient={setPatient}
      createBookingRoom={createBookingRoom}
      updateBookingNote={updateBookingNote}
      patient={patient}
      summaryNote={(note) => setNote(note)}
      practitionerRole={practitionerRole}
      onSetRoomList={(condition) => setRoomList(condition)}
      onSetRoomDetail={(condition) => setRoomList(condition)}
      isTestVideoCall={isTestVideoCall}
      booking={booking}
      updateNote={updateNote}
    />
  ) : (
    <Layout
      {...props}
      render={(props) =>
        loading ? (
          <Loading />
        ) : summary ? (
          <Summary
            history={props.history}
            note={note}
            date={date}
            patient={patient}
            roomName={roomName}
            roomNameWithOutDate={roomNameWithOutDate}
            booking={booking}
            isTestVideoCall={isTestVideoCall}
            closeSummary={() => {
              setSummary(false)
              setRoomList(true)
              setPatient(false)
            }}
            updateBookingNote={updateBookingNote}
          />
        ) : chat ? (
          <Chat patient={patient} onCloseChat={(chat) => setChat(chat)} booking={booking} />
        ) : (
          <Row className="app" style={{ width: '100%', minHeight: '100vh' }}>
            <Col md={{ span: 24 }} lg={{ span: 12 }} style={{ height: '100%' }}>
              {roomList ? (
                <RoomList
                  patientStatus={patientStatus}
                  onSetPatient={(patient) => setPatient(patient)}
                  onSetRoomList={(condition) => setRoomList(condition)}
                  onSetBookingId={(bookingId) => setBookingId(bookingId)}
                  onSetBooking={(booking) => setBooking(booking)}
                  onSetRoomId={(roomId) => setRoomId(roomId)}
                  fetchImage={(patientId) => fetchImage(patientId)}
                  setDate={(time) => setDate(time)}
                  isMobileDevice={isMobileDevice}
                />
              ) : (
                <RoomListDetail
                  roomId={roomId}
                  onSetPatient={(patient) => setPatient(patient)}
                  onSetRoomList={(condition) => setRoomList(condition)}
                  onSetRoomDetail={(condition) => setRoomList(condition)}
                  onSetChat={(condition) => setChat(condition)}
                  patient={patient}
                  handleSubmitCall={(roomId) => handleSubmitCall(roomId)}
                  handleDeclineCall={(roomId) => handleDeclineCall(roomId)}
                  isLoading={callButtonLoading}
                  practitionerRole={practitionerRole}
                  isTestVideoCall={isTestVideoCall}
                  setIsTestVideoCall={setIsTestVideoCall}
                  handleInviteObserverStaff={handleInviteObserverStaff}
                />
              )}
            </Col>
            <Col md={{ span: 24 }} lg={{ span: 12 }} style={{ height: '100%' }}>
              {patient && <RoomDetail patient={patient} image={image} />}
            </Col>
            <Modal
              title="รายชื่อผู้สังเกตุการณ์"
              visible={visible}
              onCancel={() => setVisible(false)}
              footer={null}
              bodyStyle={{ height: "400px", overflow: 'auto' }}
            >
              <List
                itemLayout="horizontal"
                dataSource={observerStaff}
                renderItem={(item) => (
                  <Popconfirm
                    title={`ยืนยันในการเชิญ ${item.fullname} ใช่หรือไม่?`}
                    onConfirm={() => handleConfirm(item)}
                    okText="ใช่"
                    cancelText="ไม่ใช่"
                  >
                    <HoverableListItem>
                      <List.Item.Meta
                        avatar={<Avatar src={item.profileImage || '-'} />}
                        title={item.fullname || '-'}
                        description={item.email}
                      />
                    </HoverableListItem>
                  </Popconfirm>
                )}
              />
            </Modal>
          </Row >
        )
      }
    />
  )
}

export default VideoChat

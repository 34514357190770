import React from 'react'
import { Image, Divider, Descriptions, Row, Col, ConfigProvider } from 'antd'
import Avatar from '../../images/default_avatar.png'
import { hooks } from './hooks'
import { TitleText, InfoBox, FloatTitleBox, FloatTitleText, DescriptionText } from './style'
import moment from 'moment'
import 'moment/locale/th'
export const PatientInfo = ({ patientInfo, donorLatestForm }) => {
  const { events } = hooks()
  return (
    patientInfo && (
      <div className="w-100 text-center">
        <Image src={patientInfo?.imageBase64 ? `data:image/png;base64,${patientInfo?.imageBase64}` : Avatar} style={{ maxWidth: '400px', width: '100%', minWidth: '200px' }} />
        <TitleText>{`${patientInfo?.fullname?.title ?? ''}${patientInfo?.fullname?.firstName ?? ''} ${patientInfo?.fullname?.lastName ?? ''}`}</TitleText>
        <Row gutter={[16, 16]} justify="space-evenly">
          <Col xs={24} md={24} xl={11}>
            <InfoBox>
              <FloatTitleBox>
                <FloatTitleText>{`HN`}</FloatTitleText>
              </FloatTitleBox>
              <DescriptionText>{events.convertHn(patientInfo?.hn[0]?.hn)}</DescriptionText>
            </InfoBox>
          </Col>
          <Col xs={24} md={24} xl={11}>
            <InfoBox>
              <FloatTitleBox>
                <FloatTitleText>{`เบอร์ติดต่อ`}</FloatTitleText>
              </FloatTitleBox>
              <DescriptionText>{patientInfo?.contactInfo?.mobileNumber}</DescriptionText>
            </InfoBox>
          </Col>
          <Col xs={24} md={24} xl={11}>
            <InfoBox>
              <FloatTitleBox>
                <FloatTitleText>{`เพศ`}</FloatTitleText>
              </FloatTitleBox>
              <DescriptionText>{patientInfo?.sexName}</DescriptionText>
            </InfoBox>
          </Col>
          <Col xs={24} md={24} xl={11}>
            <InfoBox>
              <FloatTitleBox>
                <FloatTitleText>{`เลขบัตรประชาชน`}</FloatTitleText>
              </FloatTitleBox>
              <DescriptionText>{patientInfo?.cid}</DescriptionText>
            </InfoBox>
          </Col>
          <Col xs={24} md={24} xl={11}>
            <InfoBox>
              <FloatTitleBox>
                <FloatTitleText>{`ที่อยู่`}</FloatTitleText>
              </FloatTitleBox>
              <DescriptionText>{`${patientInfo?.address?.houseNumber} ตำบล ${patientInfo?.address?.tmbpart ?? '-'}, อำเภอ ${patientInfo?.address?.amppart ?? '-'}, จังหวัด ${patientInfo?.address?.chwpart ?? '-'} ${
                patientInfo?.address?.addressPart
              }`}</DescriptionText>
            </InfoBox>
          </Col>
          {donorLatestForm?.donateType && (
            <Col xs={24} md={24} xl={11}>
              <InfoBox>
                <FloatTitleBox>
                  <FloatTitleText backgroundColor="#fff200" titleColor="dark">{`ประเภทการบริจาค`}</FloatTitleText>
                </FloatTitleBox>
                <DescriptionText>{donorLatestForm?.donateType === 'blood' ? 'เลือด' : donorLatestForm?.donateType === 'bloodPlatelet' ? 'เกล็ดเลือด' : ''}</DescriptionText>
              </InfoBox>
            </Col>
          )}
          {donorLatestForm?.donateFor && (
            <Col xs={24} md={24} xl={11}>
              <InfoBox>
                <FloatTitleBox>
                  <FloatTitleText backgroundColor="#fff200" titleColor="dark">{`วัตถุประสงค์การบริจาค`}</FloatTitleText>
                </FloatTitleBox>
                <DescriptionText>{donorLatestForm?.donateFor}</DescriptionText>
              </InfoBox>
            </Col>
          )}
          {donorLatestForm?.donateFor === 'บริจาคให้ตนเองเพื่อการผ่าตัด' && (
            <Col xs={24} md={24} xl={11}>
              <InfoBox>
                <FloatTitleBox>
                  <FloatTitleText backgroundColor="#fff200" titleColor="dark">{`วันที่ผ่าตัด`}</FloatTitleText>
                </FloatTitleBox>
                <DescriptionText>
                  {moment(donorLatestForm?.bloodForSurgeryDate)
                    .add(543, 'years')
                    .format('LL')}
                </DescriptionText>
              </InfoBox>
            </Col>
          )}

          {donorLatestForm?.donateFor === 'บริจาคเลือดโดยระบุชื่อผู้รับเลือด' && (
            <Col xs={24} md={24} xl={11}>
              <InfoBox>
                <FloatTitleBox>
                  <FloatTitleText backgroundColor="#fff200" titleColor="dark">{`ผู้รับเลือด`}</FloatTitleText>
                </FloatTitleBox>
                <DescriptionText>{donorLatestForm?.bloodReceiver}</DescriptionText>
              </InfoBox>
            </Col>
          )}

          <Col xs={24} md={24} xl={11}>
            <InfoBox>
              <FloatTitleBox>
                <FloatTitleText backgroundColor="#fff200" titleColor="dark">{`เคยบริจาคเลือดมาก่อนหรือไม่`}</FloatTitleText>
              </FloatTitleBox>
              <DescriptionText>{donorLatestForm?.haveDonatedBlood}</DescriptionText>
            </InfoBox>
          </Col>

          {donorLatestForm?.haveDonatedBlood === 'เคยบริจาค' && (
            <Col xs={24} md={24} xl={11}>
              <InfoBox>
                <FloatTitleBox>
                  <FloatTitleText backgroundColor="#fff200" titleColor="dark">{`วันที่บริจาคเลือดครั้งล่าสุด`}</FloatTitleText>
                </FloatTitleBox>
                <DescriptionText> {moment(donorLatestForm?.latestDonateDate).format('LL')}</DescriptionText>
              </InfoBox>
            </Col>
          )}

          {donorLatestForm?.haveDonatedBlood === 'เคยบริจาค' && (
            <Col xs={24} md={24} xl={11}>
              <InfoBox>
                <FloatTitleBox>
                  <FloatTitleText backgroundColor="#fff200" titleColor="dark">{`สถานที่บริจาคเลือดครั้งล่าสุด`}</FloatTitleText>
                </FloatTitleBox>
                <DescriptionText>{donorLatestForm?.latestDonatePlace}</DescriptionText>
              </InfoBox>
            </Col>
          )}

          {donorLatestForm?.previousDonatedCount && (
            <Col xs={24} md={24} xl={11}>
              <InfoBox>
                <FloatTitleBox>
                  <FloatTitleText backgroundColor="#fff200" titleColor="dark">{`จำนวนครั้งที่เคยบริจาค`}</FloatTitleText>
                </FloatTitleBox>
                <DescriptionText>{donorLatestForm?.previousDonatedCount ?? 0}</DescriptionText>
              </InfoBox>
            </Col>
          )}
        </Row>
      </div>
    )
  )
}

import styled from 'styled-components'
import { Col } from 'antd'

export const Container = styled.div`
  min-height: 40vh;
`

export const Table = styled.div`
  overflow-y: scroll;
`

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
`

export const Column = styled(Col)`
  padding: 15px;
  background-color: ${(props) => (props.header ? 'gray' : 'white')};
  border: 1px solid ${(props) => (props.header ? '#ffffff' : '#eeeeee')};
  width: 145px;
  text-align: center;
  display: ${(props) => (props.center ? 'flex' : '')};
  align-items: ${(props) => (props.center ? 'center' : '')};
  justify-content: ${(props) => (props.center ? 'center' : '')};
  z-index: ${(props) => (props.fixed ? 1 : 0)};
  position: sticky;
  left: 0;
`

import React, { useState } from 'react';
import { Table, Tabs, Tag, Typography, Card, Row, Col, Spin, DatePicker, Button } from 'antd';
import moment from 'moment';
import AreaPieChart from './AreaPieChart';
import styled from 'styled-components';

const { TabPane } = Tabs;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const DataCard = styled(Card)`
  height: 100%;
  width: 200px;
  cursor: pointer;
  border-radius: 5px;
  border: 0.5px solid #d3d3d3;
  &:hover {
    transform: scale(1.08);
    z-index: 999;
  }
  transition: 0.3s;
`;

const UsersByAreaTab = ({ userArea, isLoading, fetchDataForRange }) => {
    const [key, setKey] = useState("1");
    const [selectedRange, setSelectedRange] = useState([moment().startOf('month'), moment()]);

    const onChangeTab = (key) => {
        setKey(key);
    };

    const handleRangeChange = (dates) => {
        setSelectedRange(dates);
    };

    const handleFetchData = () => {
        if (selectedRange && selectedRange.length === 2) {
            const startDate = selectedRange[0].format('YYYY-MM-DD');
            const endDate = selectedRange[1].format('YYYY-MM-DD');
            fetchDataForRange(startDate, endDate); // fetchDataForRange should fetch data for the selected range
        }
    };

    const columns = [
        {
            title: 'ชื่อ',
            key: 'date',
            width: 150,
            render: (record) => (
                <Text>{record.firstname} {record.lastname}</Text>
            ),
        },
        {
            title: 'เลขบัตรประชาชน',
            key: 'cId',
            width: 150,
            render: (record) => <Text>{record.cId || '-'}</Text>,
        },
        {
            title: 'เบอรโทรศัพท์',
            key: 'mobileNumber',
            width: 150,
            render: (record) => <Text>{record.mobileNumber || '-'}</Text>,
        },
        {
            title: 'เขต',
            key: 'area',
            width: 150,
            render: (record) => <Text>{record.address?.area || '-'}</Text>,
        },
        {
            title: 'เวลาที่ลงทะเบียน',
            key: 'createdAt',
            width: 150,
            render: (record) => (
                <Text>{record.created_at ? moment(record.created_at).format('DD/MM/YYYY - HH:mm') : '-'}</Text>
            ),
        },
        {
            title: 'ข้อมูลอุปกรณ์',
            key: 'verified',
            width: 200,
            align: 'left',
            render: (record) => record.deviceInfo ? (
                <>
                    <Text>เวอร์ชั่น: <Tag color="blue">{record.deviceInfo.version}</Tag></Text>
                    <Text>ยี่ห้อ: <Tag color="blue">{record.deviceInfo.manufacturer}</Tag></Text>
                    <Text>รุ่น: <Tag color="blue">{record.deviceInfo.model}</Tag></Text>
                </>
            ) : <Text> ยังไม่ระบุข้อมูล</Text>,
        },
    ];

    return isLoading ? (
        <div className="w-100 d-flex justify-content-center align-items-center">
            <Spin className="text-center" />
        </div>
    ) : (
        <div>
            <h2>สถิติผู้ใช้ตามพื้นที่</h2>

            {/* Date range picker */}
            <div className="mb-4">
                <Row gutter={[16, 16]} align="middle">
                    <Col>
                        <RangePicker
                            picker="month"
                            onChange={handleRangeChange}
                            value={selectedRange}
                            format="MM/YYYY"
                        />
                    </Col>
                    <Col>
                        <Button type="primary" onClick={handleFetchData}>
                            กรองข้อมูล
                        </Button>
                    </Col>
                </Row>
            </div>

            <Tabs defaultActiveKey="1" onChange={(key) => onChangeTab(key)} className="ant-tab-margin">
                <TabPane tab="จำนวนทั้งหมด" key="1">
                    <div className="mb-4">
                        <Row gutter={[16, 16]}>
                            <Col><DataCard>ดุสิต: {userArea.areaByFilterDusit.count} เขต</DataCard></Col>
                            <Col><DataCard>บางซื่อ: {userArea.areaByFilterBangsue.count} เขต</DataCard></Col>
                            <Col><DataCard>บางพลัด: {userArea.areaByFilterBangphlat.count} เขต</DataCard></Col>
                            <Col><DataCard>พระนคร: {userArea.areaByFilterPhranakhon.count} เขต</DataCard></Col>
                            <Col><DataCard>เขตอื่น: {userArea.otherArea.count} เขต</DataCard></Col>
                        </Row>
                    </div>
                </TabPane>
                <TabPane tab="กราฟ" key="2">
                    <AreaPieChart userArea={userArea} />
                </TabPane>
            </Tabs>

            <div className="mb-4">
                <h3>เปอร์เซ็นการเพิ่มขึ้นของผู้ใช้ในเดือนที่เลือก</h3>
                <Row gutter={[16, 16]}>
                    <Col><DataCard>ดุสิต: จำนวน {userArea.areaByFilterDusit.monthlyCount} คิดเป็น {(userArea.areaByFilterDusit.monthlyIncrease || 0).toFixed(2)}%</DataCard></Col>
                    <Col><DataCard>บางซื่อ: จำนวน {userArea.areaByFilterBangsue.monthlyCount} คิดเป็น {(userArea.areaByFilterBangsue.monthlyIncrease || 0).toFixed(2)}%</DataCard></Col>
                    <Col><DataCard>บางพลัด: จำนวน {userArea.areaByFilterBangphlat.monthlyCount} คิดเป็น {(userArea.areaByFilterBangphlat.monthlyIncrease || 0).toFixed(2)}%</DataCard></Col>
                    <Col><DataCard>พระนคร: จำนวน {userArea.areaByFilterPhranakhon.monthlyCount} คิดเป็น {(userArea.areaByFilterPhranakhon.monthlyIncrease || 0).toFixed(2)}%</DataCard></Col>
                    <Col><DataCard>เขตอื่น ๆ: จำนวน {userArea.otherArea.monthlyCount} คิดเป็น {(userArea.otherArea.monthlyIncrease || 0).toFixed(2)}%</DataCard></Col>
                </Row>
            </div>

            <h3>ผู้ใช้ในพื้น 4 เขต</h3>
            <Table
                scroll={{ x: 'max-content' }}
                columns={columns}
                dataSource={userArea.allArea.users}
            />
        </div>
    );
};

export default UsersByAreaTab;

import React ,{ useEffect, useState } from 'react';
import { Button } from 'antd'
import styled from 'styled-components';

const Wrapped = styled.div`
`

const ReadMoreElementExpand = ({ showDefault , children }) => {
    const [ isReadMoreShown, setReadMoreShown] = useState(false);
    const [ size, setSize ] = useState([]);
    const [ defaultElem , setDefaultElem ] = useState(1);

    useEffect(()=>{
        if(children){
          setSize(React.Children.count(children));
          setDefaultElem(showDefault);
        }
    },[])

    const toggleBtn = () => {
        setReadMoreShown(!isReadMoreShown);
    }
    return (
        <div>
            <Wrapped>
                {children.slice(0,isReadMoreShown ? children.lenght : defaultElem)}
            </Wrapped>
        {
          children && size > showDefault &&
          <div 
                style={{ 
                    borderColor : 'transparent',
                    cursor:'pointer',
                    color : isReadMoreShown ? '#ff4d4f' :'#1890ff' ,
                    fontWeight : 'bold',
                    fontSize : 12
                }} 
                onClick={toggleBtn}>
                {isReadMoreShown ? '...แสดงน้อยลง' : '... แสดงเพิ่ม'}
            </div>
        }
        </div>
    )
}

export default ReadMoreElementExpand;
import { observable, action, computed, toJS } from 'mobx'

import http from '../../services/httpService'
import User from '../User'

const apiEndpoint = '/consultCases'

class ConsultCase {
  @observable cases = {
    data: null,
    error: null
  }

  @action createOrder = async (consultCaseId, data) => {
    try {
      await http.post(`${apiEndpoint}/${consultCaseId}/drugOrder`, data)
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchCases = async (filter) => {
    try {
      const { data } = await http.get(apiEndpoint, {
        params: {
          filter: {
            where: {
              doctorConsultId: User.session.data.id,
              isAnswered: true
            },
            include: ['patientConsult', 'symptom', 'prescriptionApprover'],
            order: 'datetime DESC',
            ...filter
          }
        }
      })
      this.cases.data = data
    } catch (e) {
      this.cases.error = e.response
    }
  }

  @action updateCase = (data) => {
    try {
      http.patch(apiEndpoint, {
        params: {
          id: data.id,
          data
        }
      })
    } catch (e) {
      // console.error(e)
    }
  }

  @action approveDrugOrder = async (consultCaseId, { approverId, drugData = {} }) => {
    try {
      await http.post(`${apiEndpoint}/${consultCaseId}/approveDrugOrder`, {
        approverId,
        drugData
      })
    } catch (e) {
      console.error(e)
    }
  }

  @computed get getCases() {
    return toJS(this.cases.data)
  }
}

export default new ConsultCase()

import httpOma from '../../services/httpServiceHealthCare'
const availableTimesUrl = '/BloodDonationAvailableTimes'
const hooks = () => {
  const getAvailableTime = async (type) => {
    try {
      const { data } = await httpOma.get(`${availableTimesUrl}?filter[where][type]=${type}`)
      return data
    } catch (e) {
      console.log('error ', e)
      return []
    }
  }

  const getSlotDataByTimeRange = async (start, end, type) => {
    try {
      const { data } = await httpOma.get(`${availableTimesUrl}?filter[where][time]=[${start},${end}]&filter[where][type]=${type}`)
      return data
    } catch (e) {
      console.log('error ', e)
      return []
    }
  }

  const addAvailableTime = async (payload, type) => {
    try {
      const { data } = await httpOma.post(`${availableTimesUrl}`, { ...payload, type })
      return data
    } catch (e) {
      console.log('error ', e)
      return e
    }
  }

  const updateAvailableTime = async (id, payload) => {
    try {
      const { data } = await httpOma.patch(`${availableTimesUrl}/${id}`, {
        time: payload.time,
        quantity: parseInt(payload.quantity),
      })
      return data
    } catch (e) {
      console.log('error ', e)
      return e
    }
  }

  const deleteAvailableTimeById = async (id) => {
    try {
      const { data } = await httpOma.delete(`${availableTimesUrl}/${id}`)
      return data
    } catch (e) {
      console.log('error ', e)
      return []
    }
  }

  return {
    events: {
      getAvailableTime,
      getSlotDataByTimeRange,
      deleteAvailableTimeById,
      addAvailableTime,
      updateAvailableTime,
    },
  }
}

export { hooks }

import { configureStore } from '@reduxjs/toolkit'
import bloodDonationFormReducer from './bloodFormSlice'
import bloodDonationBookingReducer from './bloodBookingSlice'
import healthCheckUpReducer from './healthCheckUpSlice'
import userReducer from './userSlice'
export default configureStore({
  reducer: {
    bloodDonationForm: bloodDonationFormReducer,
    bloodDonationBooking: bloodDonationBookingReducer,
    healthCheckUp: healthCheckUpReducer,
    user: userReducer,
  },
})

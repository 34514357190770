import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles, { CheckboxWithLabel, Column, Row } from '../styles'
import { get } from 'lodash'
import moment from 'moment'

export default function PatientTable({ data, patientInfo, convertHn }) {
  const { bloodDonationForm } = data
  const convertForPDF = (text) => {
    return JSON.stringify(`${text || ''}`).replaceAll('"', ' ')
  }
  return (
    <View style={styles.borderedContainer}>
      <Row>
        <Column borderBottom borderRight flex={3}>
          <Text style={styles.normalText}>{convertForPDF(`เจ้าหน้าที่ผู้ลงทะเบียน`)}</Text>
          <Text style={styles.normalText}>{`${bloodDonationForm?.registrarName ?? '-'}`}</Text>
        </Column>
        <Column borderBottom borderRight flex={3}>
          <Text style={styles.normalText}>{convertForPDF(`ชื่อเจ้าหน้าที่เตรียมถุง`)}</Text>
          <Text style={styles.normalText}>{convertForPDF(`${bloodDonationForm?.bloodPrepareStaffName ?? '-'}`)}</Text>
        </Column>
        <Column borderBottom flex={3}>
          <Text style={styles.normalText}>{`ชื่อเจ้าหน้าที่ผู้เจาะเก็บ`}</Text>
          <Text style={styles.normalText}>{convertForPDF(`${bloodDonationForm?.bloodNeedlerName ?? '-'}`)}</Text>
        </Column>
      </Row>
      <Row>
        <Column borderRight flex={2}>
          <Text style={styles.normalText}>{`ชื่อเจ้าหน้าที่เก็บตัวอย่างเลือด`}</Text>
          <Text style={styles.normalText}>{convertForPDF(`${bloodDonationForm?.collectSamplerName ?? '-'}`)}</Text>
        </Column>
        <Column flex={2}>
          <Text style={styles.normalText}>{`ชื่อเจ้าหน้าที่บันทึกข้อมูล/ผู้ตรวจสอบ`}</Text>
          <Text style={styles.normalText}>{convertForPDF(`${bloodDonationForm?.recorderName ?? '-'}`)}</Text>
        </Column>
      </Row>
    </View>
  )
}

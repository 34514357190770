import React, { useState, useEffect, Fragment } from 'react';
import Spinner from './Spinner';

import styled from 'styled-components';
import { Formik } from 'formik';
import Table from '../Table';
import TextBase from '../Text';
import nemesis from '../../images/nemesis.jpg';
import doctor1 from '../../images/doctor1.jpg';
import doctor2 from '../../images/doctor2.jpg';
import doctor3 from '../../images/doctor3.jpg';

import {
  Container,
  Wrapper,
  Sidebar1,
  Sidebar2,
  Sidebar3,
  ImageCardContainer,
  CardImage2,
  CardImage1,
  Image,
  PatientInfoContainer,
  DiagnosesContainer,
  Name,
  DOBContainer,
  PhysicalInfoContainer,
  Field,
  Value,
  ContactInfoContainer,
  VisitTitle,
  VisitHead,
  VisitContainer,
  VisitContent,
  Box,
  NewEncounter,
  TimelineContainer,
  TimelineHead,
  TimelineTitle,
  TimelineFilter,
  TimelineContent,
  CreateBox,
  CreateBoxPlaceholder,
  EncounterText,
  P,
  H3,
  ItemContainer,
  ItemSlots,
  ItemSlot,
  ItemTextField,
  ItemTextValue,
  ItemTextValue2,
  Complaint,
  DoctorSection,
  Avatar,
  DoctorName,
  CommentDate,
  DoctorComment,
  Note,
  Button,
  HR,
  OldItemContainer,
  OldItemSlots,
  OldItemDoctorGroup,
  Comments,
  Arrow,
  ShowComments,
  ArrowButton,
  CardTitle,
  CardBody,
} from './style';

function PatientProfileDetail({ state, history, ...rest }) {
  const [visibleProfile, setVisibleProfile] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [visiblePatientProfile, setVisiblePatientProfile] = useState();
  const [patientId, setPatientId] = useState();
  const [info, setInfo] = useState();
  const [text, setText] = useState('');
  const [show, setShow] = useState(false);
  const [itemId, setItemId] = useState();

  const visitData = [
    { year: '2020', events: ['checkup 3', 'checkup 4', 'checkup 5'] },
    { year: '2019', events: ['checkup 1', 'checkup 2'] },
  ];

  const vitalsData = {
    weight: 82,
    heartRate: 52,
    bloodPressure: '125/75',
    temporature: 38.5,
    pulse: 73,
    complaint:
      "Patient walks in and complains about having headache with dry cough. He said he didn't want to die.",
  };

  const vitalsData2 = [
    {
      id: 1,
      doctors: ['Dr. Rambo Aryoo', 'Dr. Lisa Leeloo', 'Dr. Suchart Ornjai'],
      weight: 90,
      heartRate: 62,
      bloodPressure: '115/73',
      temporature: 38,
      pulse: 75,
      complaint: 'Patient was feeling sick and vomitted more than five times. ',
    },
    {
      id: 2,
      doctors: ['Dr. Saimai Weenna', 'Dr. Jim Jaidee'],
      weight: 86,
      heartRate: 72,
      bloodPressure: '145/71',
      temporature: 38.5,
      pulse: 77,
      complaint: 'Patient was ok. ',
    },
  ];

  const doctor = { name: 'Dr. Pichit Jaidee' };

  return (
    <Container>
      {state.dataSource ? (
        <section>
          <Wrapper>
            <Fragment>
              <Sidebar1>
                <ImageCardContainer>
                  <CardImage2>
                    <CardImage1>
                      <Image width={200} src={nemesis} />
                    </CardImage1>
                  </CardImage2>
                </ImageCardContainer>
                <PatientInfoContainer>
                  <Name>
                    {state.dataSource.fullname.firstName}{' '}
                    {state.dataSource.fullname.lastName}
                  </Name>
                  <DOBContainer>
                    <p>{state.dataSource.DOB}</p>
                    <p>{state.dataSource.sexName}</p>
                  </DOBContainer>
                  <PhysicalInfoContainer>
                    <Field>RACE</Field>
                    <Value>Asian</Value>
                  </PhysicalInfoContainer>
                  <PhysicalInfoContainer>
                    <Field>EYE COLOR</Field>
                    <Value>Brown</Value>
                  </PhysicalInfoContainer>
                  <PhysicalInfoContainer>
                    <Field>HAIR</Field>
                    <Value>Black</Value>
                  </PhysicalInfoContainer>
                  <PhysicalInfoContainer>
                    <Field>ID</Field>
                    <Value>{state.dataSource.cid}</Value>
                  </PhysicalInfoContainer>
                  <PhysicalInfoContainer>
                    <Field>INSURANCE</Field>
                    <Value>AIA</Value>
                  </PhysicalInfoContainer>
                  <PhysicalInfoContainer>
                    <Field>HN</Field>
                    <Value>{state.dataSource.hn[0].hn}</Value>
                  </PhysicalInfoContainer>

                  <div style={{ marginTop: 20 }} />
                  <ContactInfoContainer>
                    <Field>
                      <i className="fas fa-phone-square"></i>
                    </Field>
                    <Value>{state.dataSource.contactInfo.mobileNumber}</Value>
                  </ContactInfoContainer>

                  <ContactInfoContainer>
                    <Field>
                      <i className="fas fa-envelope"></i>
                    </Field>
                    <Value>{state.userImage.email}</Value>
                  </ContactInfoContainer>

                  <ContactInfoContainer>
                    <Field>
                      <i className="fas fa-compass"></i>
                    </Field>
                    <Value>
                      {state.dataSource.address.addressPart}{' '}
                      {state.dataSource.address.houseNumber}{' '}
                      {state.dataSource.address.chwpart}
                    </Value>
                  </ContactInfoContainer>
                </PatientInfoContainer>
                <VisitContainer>
                  <VisitHead>
                    <VisitTitle>Visit encounter</VisitTitle>
                    <VisitContent>
                      {state.userVisit.docs.map((item) => (
                        <div key={item.id}>
                          <Box>
                            <Field>{item.vstdate}</Field>
                            <Field>{item.vn}</Field>
                            <Field>{item.departmentName}</Field>
                          </Box>
                          {/* {item.events.map((i) => (
                            <Box key={i}>
                              <Value>{i}</Value>
                            </Box>
                          ))} */}
                        </div>
                      ))}
                    </VisitContent>
                  </VisitHead>
                </VisitContainer>
              </Sidebar1>
              <Sidebar2>
                <NewEncounter>
                  <CreateBox>
                    <CreateBoxPlaceholder>
                      <i className="fas fa-plus-circle"></i> Create a new
                      encounter
                    </CreateBoxPlaceholder>
                  </CreateBox>
                  <EncounterText>
                    <P>Visit Details</P>
                    <P>Chief Complaint</P>
                    <P>Vitals</P>
                  </EncounterText>
                </NewEncounter>
                <TimelineContainer>
                  <TimelineHead>
                    <TimelineTitle>Health Timeline</TimelineTitle>
                    <TimelineFilter>
                      <i
                        className="fas fa-filter"
                        style={{ color: 'grey' }}
                      ></i>
                      <span style={{ paddingRight: 5 }}></span> Filter
                    </TimelineFilter>
                  </TimelineHead>
                  <TimelineContent>
                    <ItemContainer>
                      <H3>Vitals</H3>
                      <ItemSlots>
                        <ItemSlot>
                          <ItemTextField>Weight</ItemTextField>
                          <ItemTextValue>
                            <p>{vitalsData.weight} </p>
                            <ItemTextValue2>kg</ItemTextValue2>
                          </ItemTextValue>
                        </ItemSlot>
                        <ItemSlot>
                          <ItemTextField>Heart Rate</ItemTextField>
                          <ItemTextValue>
                            <p>{vitalsData.heartRate} </p>
                            <ItemTextValue2>bpm</ItemTextValue2>
                          </ItemTextValue>
                        </ItemSlot>
                        <ItemSlot>
                          <ItemTextField>Blood Pressure</ItemTextField>
                          <ItemTextValue>
                            {vitalsData.bloodPressure}
                          </ItemTextValue>
                        </ItemSlot>
                        <ItemSlot>
                          <ItemTextField>Temporature</ItemTextField>
                          <ItemTextValue>
                            {vitalsData.temporature}
                          </ItemTextValue>
                        </ItemSlot>
                        <ItemSlot>
                          <ItemTextField>Pulse Ox</ItemTextField>
                          <ItemTextValue>
                            <p>{vitalsData.pulse} </p>
                            <ItemTextValue2>%</ItemTextValue2>
                          </ItemTextValue>
                        </ItemSlot>
                      </ItemSlots>
                      <H3>Chief Complaint</H3>
                      <Complaint>{vitalsData.complaint}</Complaint>

                      <DoctorSection>
                        <Avatar src={doctor2} />
                        <DoctorName>
                          <p>{doctor.name}</p>
                          <DoctorComment
                            onSubmit={(e) => {
                              e.preventDefault();
                              setText('');
                            }}
                          >
                            <Note
                              type="text"
                              placeholder="Write a note..."
                              value={text}
                              onChange={(e) => setText(e.target.value)}
                              required
                            ></Note>
                            <Button type="submit" value="Submit"></Button>
                          </DoctorComment>
                        </DoctorName>
                      </DoctorSection>
                    </ItemContainer>
                  </TimelineContent>
                  <HR />

                  <TimelineContent>
                    {vitalsData2.map((item) => (
                      <OldItemContainer key={item.id}>
                        <OldItemDoctorGroup>
                          <Avatar src={doctor1} />
                          <DoctorName>
                            {item.doctors[0]}
                            <CommentDate>10 Aug 2020 at 12:30</CommentDate>
                          </DoctorName>
                        </OldItemDoctorGroup>
                        <H3>Vitals</H3>
                        <OldItemSlots>
                          <ItemSlot>
                            <ItemTextField>Weight</ItemTextField>
                            <ItemTextValue>
                              {item.weight} <ItemTextValue2>kg</ItemTextValue2>
                            </ItemTextValue>
                          </ItemSlot>
                          <ItemSlot>
                            <ItemTextField>Heart Rate</ItemTextField>
                            <ItemTextValue>
                              {item.heartRate}{' '}
                              <ItemTextValue2>bpm</ItemTextValue2>
                            </ItemTextValue>
                          </ItemSlot>
                          <ItemSlot>
                            <ItemTextField>Blood Pressure</ItemTextField>
                            <ItemTextValue>{item.bloodPressure}</ItemTextValue>
                          </ItemSlot>
                          <ItemSlot>
                            <ItemTextField>Temporature</ItemTextField>
                            <ItemTextValue>{item.temporature}</ItemTextValue>
                          </ItemSlot>
                          <ItemSlot>
                            <ItemTextField>Pulse Ox</ItemTextField>
                            <ItemTextValue>
                              {item.pulse} <ItemTextValue2>%</ItemTextValue2>
                            </ItemTextValue>
                          </ItemSlot>
                        </OldItemSlots>
                        <H3>Chief Complaint</H3>
                        <Complaint>{item.complaint}</Complaint>

                        {show && itemId == item.id ? (
                          <Arrow>
                            <ArrowButton
                              onClick={() => {
                                setShow(!show);
                                setItemId(item.id);
                              }}
                            >
                              <i className="fas fa-angle-double-up"></i>
                            </ArrowButton>
                          </Arrow>
                        ) : (
                          <Arrow>
                            <ArrowButton
                              onClick={() => {
                                setShow(!show);
                                setItemId(item.id);
                              }}
                            >
                              <i className="fas fa-angle-double-down"></i>
                            </ArrowButton>
                          </Arrow>
                        )}

                        {show && itemId == item.id ? (
                          <Comments>
                            <ShowComments>
                              <h1>OK</h1>
                            </ShowComments>
                          </Comments>
                        ) : null}
                      </OldItemContainer>
                    ))}
                  </TimelineContent>
                </TimelineContainer>
              </Sidebar2>
              <Sidebar3>
                <DiagnosesContainer>
                  <CardTitle>Recent Diagnoses</CardTitle>
                  {state.userVisit.docs.slice(0, 5).map((item) => (
                    <Box>
                      <CardBody>
                        <strong>Date: </strong>
                        {item.diagnoses[0].visitDateTime.slice(0, 10)}
                      </CardBody>
                      <CardBody>
                        {item.diagnoses[0].icd10ThaiName || '-'}
                      </CardBody>
                    </Box>
                  ))}
                </DiagnosesContainer>
                <PatientInfoContainer>
                  <CardTitle>Appointments</CardTitle>
                  <CardBody>No appointments</CardBody>
                </PatientInfoContainer>
                <PatientInfoContainer>
                  <CardTitle>Allergies</CardTitle>
                  {state.dataSource.drugAllergies.map((item) => (
                    <Box>
                      <CardBody>
                        <strong>ชื่อ: </strong>
                        {item.genericname}
                      </CardBody>
                      <CardBody>
                        <strong>วันที่: </strong>
                        {item.updateDateTime.slice(0, 10)}
                      </CardBody>
                      <CardBody>
                        <strong>อาการ: </strong>
                        {item.symptom}
                      </CardBody>
                    </Box>
                  ))}
                </PatientInfoContainer>
                <PatientInfoContainer>
                  <CardTitle>Medications</CardTitle>
                  <CardBody>Glyburide</CardBody>
                  <CardBody>DC Glyburide</CardBody>
                  <CardBody>Lisinopril</CardBody>
                </PatientInfoContainer>
                <PatientInfoContainer>
                  <CardTitle>Prescriptions</CardTitle>
                  <CardBody>Hydrochlorothiazide 6 in q.d.</CardBody>
                  <CardBody>DC Glyburide 1 in q.d.</CardBody>
                </PatientInfoContainer>
                <PatientInfoContainer>
                  <CardTitle>Immunizations</CardTitle>
                  <CardBody>2004-10-07 03:00:00 - influenza</CardBody>
                </PatientInfoContainer>
              </Sidebar3>
            </Fragment>
          </Wrapper>
        </section>
      ) : (
        <Spinner></Spinner>
      )}
      {/* <h1>{state.dataSource.fullname}</h1> */}
    </Container>
  );
}
export default PatientProfileDetail;

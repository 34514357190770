import React, { Component } from 'react'
import _get from 'lodash/get'
import styled from 'styled-components'
import Loading from './Loading'
import Layout from '../Home'
import FetchChatToken from './FetchChatToken'
import MessageList from './MessageList'
import ChannelList from './ChannelList'

const ChatContainer = styled.div`
  display: grid;
  padding: 20px;
  gap: 10px;
  height: 100vh;
  width: 100%;
  grid-template-columns: 300px 1fr;
`

class index extends Component {
  state = {
    patientConsultId: this.props.match.params.patientId,
  }

  onClickPatientChannel = (id) => () => {
    this.setState({ patientConsultId: id })
  }

  render() {
    const {
      patientConsultId
    } = this.state
    return (
      <Layout
        {...this.props}
        render={(props) => {
          const {
            userInfo
          } = props
          if (!userInfo) return <span>loading</span>
          return (
            <FetchChatToken
              email={_get(userInfo, 'email')}
              render={(_props) => {
                const { channel } = _props
                return (
                  <ChatContainer>
                    <ChannelList
                      patientConsultId={patientConsultId}
                      userInfo={userInfo}
                      onClickPatientChannel={this.onClickPatientChannel}
                    />
                    {
                      channel
                        ? (
                          <MessageList
                            userInfo={userInfo}
                            channel={channel}
                            patientConsultId={patientConsultId}
                          />
                        ) : <Loading />
                    }
                  </ChatContainer>
                )
              }}
            />
          )
        }}
      />
    )
  }
}
export default index

import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '../Home'
import BookingCaseTable from '../../components/BookingCaseTable'
import { TABLE_TYPES } from '../../constant'


export default class PatientBooking extends Component {
  render() {
    return (
      <Container style={{ minWidth: '100%' }}>
        <Row>
          <Col xs={6} md={4} />
          <Col xs={12} md={8} />
        </Row>
      </Container>
    )
  }
}

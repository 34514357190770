import React from 'react'
import { Modal, Spin, Row, Col } from 'antd'
import { isEmpty } from 'lodash'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
const Text = styled('p')`
  font-weight: bold;
`
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const MedicalBenefitModal = ({ visibleMedicalBenefit, setVisibleMedicalBenefit, medicalBenefit, loading }) => {
  return (
    <Modal visible={visibleMedicalBenefit} title="สิทธิ" footer={null} onOk={() => setVisibleMedicalBenefit(false)} onCancel={() => setVisibleMedicalBenefit(false)}>
      <Spin indicator={antIcon} spinning={loading} tip="กรุณารอสักครู่...">
        {!isEmpty(medicalBenefit) ? (
          <Row gutter={16}>
            <Col span={8}>ชื่อ</Col>
            <Col span={16}>
              {medicalBenefit.TITLE_NAME} {medicalBenefit.FNAME} {medicalBenefit.LNAME}
            </Col>
            <Col span={8}>เลขบัตรประชาชน</Col>
            <Col span={16}>{medicalBenefit.PID}</Col>
            <Col span={8}>สิทธิ์</Col>
            <Col span={16}>{medicalBenefit.PTTYPE_NAME}</Col>
          </Row>
        ) : (
          <Text>ไม่มีสิทธิ์</Text>
        )}
      </Spin>
    </Modal>
  )
}

export default MedicalBenefitModal

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getForms, getFormByBookingId, updateForm, getExcelFormByDonateForStatus } from '../apiServices/bloodFormServices'
const initialState = {
  allFormLists: [],
  donorFormLists: [],
  donorLatestForm: [],
  donorFormExcel: [],
  isLoadingData: false,
  isLoadingExcel: false,
  errorMessage: '',
  errorExcelMessage: '',
}

export const fetchDonorFormLists = createAsyncThunk('bloodDonationForm/fetchDonorFormLists', async (cId, { rejectWithValue }) => {
  try {
    const response = await getForms(cId)
    return response?.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.error)
  }
})

export const fetchDonorFormById = createAsyncThunk('bloodDonationForm/fetchDonorFormById', async (bookingId, { rejectWithValue }) => {
  try {
    const response = await getFormByBookingId(bookingId)
    return response?.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.error)
  }
})

export const fetchExcelDonorForm = createAsyncThunk('bloodDonationForm/fetchExcelDonorForm', async (status, { rejectWithValue }) => {
  try {
    const response = await getExcelFormByDonateForStatus(status)
    return response?.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.error)
  }
})

export const updateDonorForm = createAsyncThunk('bloodDonationForm/updateDonorForms', async (params, { rejectWithValue }) => {
  try {
    const response = await updateForm(params)
    params.callback(true)
    return response?.data
  } catch (error) {
    params.callback(false)
    return rejectWithValue(error?.response?.data?.error)
  }
})

export const bloodDonationForm = createSlice({
  name: 'bloodDonationForm',
  initialState,
  reducers: {
    resetData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDonorFormLists.pending, (state) => {
      state.isLoadingData = true
    })
    builder.addCase(fetchDonorFormLists.fulfilled, (state, action) => {
      state.donorFormLists = action?.payload
      state.donorLatestForm = action?.payload?.slice(-1).pop() ?? {}
      state.errorMessage = ''
      state.isLoadingData = false
    })
    builder.addCase(fetchDonorFormLists.rejected, (state, action) => {
      state.errorMessage = action?.payload?.message ?? ''
      state.donorFormLists = []
      state.donorLatestForm = {}
      state.isLoadingData = false
    })
    builder.addCase(fetchDonorFormById.pending, (state) => {
      state.isLoadingData = true
    })
    builder.addCase(fetchDonorFormById.fulfilled, (state, action) => {
      state.donorLatestForm = action?.payload ?? {}
      state.errorMessage = ''
      state.isLoadingData = false
    })
    builder.addCase(fetchDonorFormById.rejected, (state, action) => {
      state.errorMessage = action?.payload?.message ?? ''
      state.donorLatestForm = {}
      state.isLoadingData = false
    })
    builder.addCase(fetchExcelDonorForm.pending, (state) => {
      state.isLoadingExcel = true
    })
    builder.addCase(fetchExcelDonorForm.fulfilled, (state, action) => {
      state.donorFormExcel = action?.payload || []
      state.isLoadingExcel = false
      state.errorExcelMessage = ''
    })
    builder.addCase(fetchExcelDonorForm.rejected, (state, action) => {
      state.errorExcelMessage = action?.payload?.message ?? 'เกิดข้อผิดพลาด'
      state.isLoadingExcel = false
      state.donorFormExcel = []
    })
    builder.addCase(updateDonorForm.pending, (state) => {
      state.isLoadingData = true
    })
    builder.addCase(updateDonorForm.fulfilled, (state, action) => {
      state.donorLatestForm = action?.payload
      state.errorMessage = ''
      state.isLoadingData = false
    })
    builder.addCase(updateDonorForm.rejected, (state, action) => {
      state.errorMessage = action?.payload?.message ?? ''
      state.donorLatestForm = {}
      state.isLoadingData = false
    })
  },
})

export const { resetData } = bloodDonationForm.actions

export default bloodDonationForm.reducer

import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceHealthCare'

const apiEndpoint = '/surveyForms'

class SurveyForm {
  @observable info = {
    data: [],
    error: null
  }

  @action fetchSurveyFormsData = async () => {
    try {
      const { data } = await http.get(`${apiEndpoint}?filter[order]=surveyUserId%20DESC`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @computed get getSurveyFormsData() {
    return toJS(this.info.data)
  }
}

export default new SurveyForm()

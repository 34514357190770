import React, { Component } from 'react'
import { Upload, Modal, Image, message, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import firebase from 'firebase'
import { observer, inject } from 'mobx-react'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

@inject('BookingGallery')
@observer
export default class UploadImage extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
    deleteList: [],
    delete: [],
    loading: false,
    images: [],
    isFileList: true
  }

  componentDidMount() {
    if (this.props.images && this.props.images.length) {
      const { images } = this.props
      const imgs = []
      images.map((image,index) => image.image.map((img,indexImg) => imgs.push({ uid:`${image+index}_${img+indexImg}`,url: img, preview: true, id: image.id })))
      this.setState({
        fileList: imgs
      })
      console.log(images)
    }
  }

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  handleCancel = () => {
    this.setState({ previewVisible: false })
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    })
  }

  handleChange = ({ fileList, file }) => {
    this.setState({ fileList, deleteList: [...this.state.deleteList, file] })
  }

  handleUpload = async () => {
    const { fileList, deleteList } = this.state
    if(!fileList.length == 0){
      await Promise.all(
        fileList.map(async file => {
          if (!file.preview) {
            await this.uploadImageAsPromise(file)
          }
        })
      )
      await this.createBookingGallery()
    }
    if(!deleteList.length == 0){
      await Promise.all(
        deleteList.map(async file => {
          if (file.status === 'removed') {
            await this.DeleteImage(file)
          }
        })
      ) 
      await this.deleteBookingGallery()
    }
  }

  createBookingGallery = async () => {
    const { bookingId, BookingGallery, setVisible } = this.props
    const { images } = this.state
    images.map(async file => {
      let image = [file]
      await BookingGallery.createBookingGallery(image, bookingId)
    })
    if(!images.length == 0){
      message.success('อัปโหลดรูปภาพสำเร็จ')
    }
    this.setState({ images: false, fileList: [], isFileList: true })
    setVisible(false)
  }

  deleteBookingGallery = async () => {
    const { bookingId, BookingGallery, setVisible } = this.props
    const { deleteList } = this.state
    deleteList.map(async file => {
        await BookingGallery.deleteBookingGallery(file.id)
    })
    if (!this.state.delete.length == 0){
    message.success('ลบรูปภาพสำเร็จ')
    }
    this.setState({ deleteList: false, fileList: [], isFileList: true })
    setVisible(false)
  }

  uploadImageAsPromise = imageFile => {
    return new Promise((resolve, reject) => {
      const storageRef = firebase.storage().ref(`${imageFile.uid}/${imageFile.name}`)
      const task = storageRef.put(imageFile.originFileObj)
      task.on(
        'state_changed',
        snapshort => {
          // let percentage = (snapshort.bytesTransferred / snapshort.totalBytes) * 100
          // Process
        },
        error => {
          // Error
          message.error(error.message)
        },
        async () => {
          // Success
          const data = this.state.images
          const image = await storageRef.getDownloadURL()
          data.push(image)
          this.setState({
            images: data
          })
          resolve(data)
        }
      )
    })
  }

  DeleteImage = imageFile => {
    return new Promise((resolve, reject) => {
      const imageRef = firebase.storage().refFromURL(`${imageFile.url}`)
      imageRef.delete().then(() => {
        const data = this.state.delete
        data.push(imageFile)
        this.setState({
          delete: data
        })
        resolve(data)
    }).catch(err => console.log(err))
    })
  }

  render() {
    const { previewVisible, previewImage, fileList, defaultFileList, previewTitle } = this.state
    const { visible, setVisible } = this.props
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )
    return (
      <Modal
        title="อัปโหลดรูปภาพ"
        visible={visible}
        onCancel={() => {
          this.setState({ isFileList: true, fileList: [] })
          setVisible(false)
        }}
        onOk={() => {
          this.setState({ isFileList: true, fileList: [] })
          setVisible(false)
        }}
        footer={
          <div>
            <Button
              onClick={() => {
                this.setState({ isFileList: true, fileList: [] })
                setVisible(false)
              }}
              type="danger"
            >
              ยกเลิก
            </Button>
            <Button type="primary" onClick={() => this.handleUpload()}>
              บันทึก
            </Button>
          </div>
        }
      >
        <Upload
          multiple
          listType="picture-card"
          defaultFileList={defaultFileList}
          fileList={fileList}
          customRequest={this.dummyRequest}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          {uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
          onOk={this.handleCancel}
        >
          <Image alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Modal>
    )
  }
}

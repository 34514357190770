import React, { Component } from 'react'
import { Input } from 'antd'
import PropTypes from 'prop-types'

export default class TextInput extends Component {
  static propsTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }

  render() {
    const {
      label,
      id,
    } = this.props
    return (
      <div className="input-group">
        <label htmlFor={id}>
          {label}
        </label>
        <Input
          {...this.props}
        />
      </div>
    )
  }
}

import React, { useEffect, useState } from 'react'
import { Input, Tag, Row, Col, Tabs, Spin, Card, Steps, DatePicker } from 'antd'
import { CarryOutOutlined, VideoCameraOutlined, FileDoneOutlined, InboxOutlined, CheckCircleOutlined, ProfileOutlined, HistoryOutlined, BellOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import moment from 'moment'
import Table from '../Table'
import TextBase from '../Text'
import CheckHnnumber from '../CheckHnNumber'
const { RangePicker } = DatePicker
const { TabPane } = Tabs
const { Search: SearchAnt } = Input
const { Step } = Steps
const Text = styled(TextBase)`
  font-weight: bold;
`
const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`
const TrackingDiv = styled('div')`
  cursor: pointer;
  position: absolute;
  top: 93%;
  @media (max-width: 992px) {
    position: relative;
    top: 100%;
  }
`
const dateFormatList = 'DD/MM/YYYY'

const PharmacyDashboardTable = ({ dataSource, setVisibleModalTracking, emsAvailable, history }) => {
  const location = history.location
  const [isLoading, setIsloading] = useState(false)
  const [bookings, setBookings] = useState([])
  const [onGoingBookings, setOnGoingBookings] = useState([])
  const [emsBookings, setEmsBookings] = useState([])
  const [completedBookings, setCompletedBookings] = useState([])
  const [filterBookings, setFilterBookings] = useState(false)
  const [filterOnGoingBookings, setFilterOnGoingBookings] = useState(false)
  const [filterEmsBookings, setFilterEmsBookings] = useState(false)
  const [filterCompletedBookings, setFilterCompletedBookings] = useState(false)
  const [currentTab, setCurrentTab] = useState('Bookings')
  const [searchDate, setSearchDate] = useState([])
  useEffect(() => {
    handleBookingsData(dataSource)
    setCurrentTab(location.hash.split('#')[1])
  }, [dataSource])

  const handleBookingsData = (dataSource) => {
    let pharmacyBookings = dataSource.filter((item) => {
      const isAllowedStatus =
        ['PHARMACY_CONFIRM_BOOKING', 'PHARMACY_COMPLETED'].includes(item.status) ||
        (item.prescription &&
          item.prescription.status &&
          [
            'PHARMACY_CONFIRM_RX',
            'WAIT_FOR_PHARMACYSTORE_NOTIFY',
            'WAIT_FOR_PATIENT_PHARMACY',
            'WAIT_FOR_PATIENT_EMS',
            'WAIT_FOR_PATIENT_PHAMACYSTORE',
            'WAIT_FOR_PATIENT_HOSPITAL',
            'SUCCESS_BY_EMS',
            'SUCCESS_BY_PHARMACYSTORE',
            'SUCCESS_BY_PHARMACY',
            'SUCCESS_BY_PATIENT',
          ].includes(item.prescription.status))
      return isAllowedStatus
    })

    let duplicatedBookingIdDoctor = []
    let duplicatedBookingIdPharmacy = []

    pharmacyBookings.map((item) => {
      const filter = pharmacyBookings.filter((x) => {
        if (item.id === x.doctorBookingId) {
          const isPharmacyBooking =
            x.status.includes('PHARMACY_CONFIRM_BOOKING') ||
            (x.status.includes('PHARMACY_COMPLETED') &&
              ![
                'PHARMACY_CONFIRM_RX',
                'WAIT_FOR_PHARMACYSTORE_NOTIFY',
                'WAIT_FOR_PATIENT_PHARMACY',
                'WAIT_FOR_PATIENT_EMS',
                'WAIT_FOR_PATIENT_PHAMACYSTORE',
                'WAIT_FOR_PATIENT_HOSPITAL',
                'SUCCESS_BY_EMS',
                'SUCCESS_BY_PHARMACYSTORE',
                'SUCCESS_BY_PHARMACY',
                'SUCCESS_BY_PATIENT',
              ].includes(item.prescription.status))
          if (isPharmacyBooking) {
            return duplicatedBookingIdDoctor.push(item.id)
          } else {
            return duplicatedBookingIdPharmacy.push(item.id)
          }
        }
      })
      return item
    })

    if (duplicatedBookingIdDoctor) {
      //delete booking doctor for show only booking pharmacy
      pharmacyBookings = pharmacyBookings.filter((item) => !duplicatedBookingIdDoctor.includes(item.id))
    }
    if (duplicatedBookingIdPharmacy) {
      //delete booking pharmacy for show only booking doctor
      pharmacyBookings = pharmacyBookings.filter((item) => !duplicatedBookingIdPharmacy.includes(item.doctorBookingId))
    }

    pharmacyBookings.sort((a, b) => {
      return a.bookingTime - b.bookingTime
    })

    const onGoing = pharmacyBookings.filter((item) => {
      return ['PHARMACY_CONFIRM_BOOKING', 'PHARMACY_COMPLETED'].includes(item.status) || (item.prescription && ['PHARMACY_CONFIRM_RX'].includes(item.prescription.status))
    })
    const ems = pharmacyBookings.filter((item) => {
      return item.prescription && item.prescription.status && ['WAIT_FOR_PHARMACYSTORE_NOTIFY', 'WAIT_FOR_PATIENT_PHARMACY', 'WAIT_FOR_PATIENT_EMS', 'WAIT_FOR_PATIENT_PHAMACYSTORE'].includes(item.prescription.status)
    })
    const completed = pharmacyBookings.filter((item) => {
      return item.prescription && item.prescription.status && ['SUCCESS_BY_EMS', 'SUCCESS_BY_PHARMACYSTORE', 'SUCCESS_BY_PHARMACY', 'SUCCESS_BY_PATIENT'].includes(item.prescription.status)
    })
    setBookings(pharmacyBookings)
    setOnGoingBookings(onGoing)
    setEmsBookings(ems)
    setCompletedBookings(completed)
  }

  const convertHn = (hn) => {
    const second = hn.substring(0, 2)
    const past = hn.substring(2).split('')
    let pass = 0
    const final = past.map((string) => {
      if (pass || string !== '0') {
        pass = 1
        return string
      }
    })
    return final.join('').concat('/', second)
  }

  const handleStatusSteps = (record) => {
    let step = 0
    if (record.prescription && [1, 3].includes(record.prescription.logisticId)) {
      const logisticId = record.prescription.logisticId
      const prescriptionStatus = record.prescription.status
      if (logisticId === 1) {
        if (['DOCTOR_CONFIRM', 'EPHIS_PENDING'].includes(record.status) || (record.status === 'DOCTOR_COMPLETED' && prescriptionStatus === 'PHARMACY_PENDING_RX')) {
          return 0
        }
        if (prescriptionStatus === 'WAIT_FOR_PHARMACYSTORE_NOTIFY') {
          return 1
        }
        if (prescriptionStatus === 'WAIT_FOR_PATIENT_PHAMACYSTORE') {
          return 2
        }
        if (prescriptionStatus === 'SUCCESS_BY_PHARMACYSTORE') {
          return 3
        }
      }
      if (logisticId === 3) {
        if (['DOCTOR_CONFIRM', 'EPHIS_PENDING'].includes(record.status) || (record.status === 'DOCTOR_COMPLETED' && prescriptionStatus === 'PHARMACY_PENDING_RX')) {
          return 0
        } else if (prescriptionStatus === 'WAIT_FOR_PATIENT_HOSPITAL') {
          return 1
        } else if (prescriptionStatus === 'SUCCESS_BY_PHARMACY') {
          return 2
        }
      }
    }
    if (record.prescription) {
      const prescriptionStatus = record.prescription.status
      switch (prescriptionStatus) {
        case 'PHARMACY_CONFIRM_RX':
        case 'WAIT_FOR_PATIENT_HOSPITAL':
          step = 2
          break
        case 'WAIT_FOR_PHARMACYSTORE_NOTIFY':
        case 'WAIT_FOR_PATIENT_PHARMACY':
        case 'WAIT_FOR_PATIENT_EMS':
        case 'WAIT_FOR_PATIENT_PHAMACYSTORE':
          step = 3
          break
        case 'SUCCESS_BY_EMS':
        case 'SUCCESS_BY_PHARMACYSTORE':
        case 'SUCCESS_BY_PHARMACY':
        case 'SUCCESS_BY_PATIENT':
          step = 4
          break
        default:
          step = 0
          break
      }
      return step
    }
    const status = record.status
    switch (status) {
      case 'PHARMACY_CONFIRM_BOOKING':
        step = 0
        break
      case 'PHARMACY_COMPLETED':
        step = 1
        break
      default:
        step = 0
        break
    }
    return step
  }

  const columns = [
    {
      title: 'วันที่แพทย์สั่งยา',
      key: 'dateOfPrescribesDrug',
      width: 100,
      render: (record) => <Text>{moment(record.admitTime).format('DD/MM/YYYY')}</Text>,
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.admitTime).unix() - moment(b.admitTime).unix(),
      align: 'center',
    },
    {
      title: 'เวลาที่แพทย์สั่งยา',
      key: 'timeOfPrescribesDrug',
      width: 70,
      render: (record) => (
        <Text>
          {moment()
            .startOf('day')
            .add(record.bookingTime, 'minutes')
            .format('HH:mm')}
        </Text>
      ),
      align: 'center',
    },
    {
      title: 'ชื่อ-นามสกุล',
      key: 'fullname',
      width: 200,
      render: (record) => <Text>{record.patient && record.patient.fullnameHIE ? record.patient.fullnameHIE : record.patient.fullname || '-'}</Text>,
      align: 'center',
    },
    {
      title: 'HN',
      key: 'hn',
      align: 'center',
      width: 80,
      render: (record) => (
        <Text>
          <CheckHnnumber key={record} data={record} loading={<Spin />} />
        </Text>
      ),
    },
    {
      title: 'แพทย์ผู้สั่งยา',
      key: 'doctorName',
      align: 'center',
      width: 100,
      render: (record) => <Text>{record.doctor ? (record.doctor.fullnameHIE ? record.doctor.fullnameHIE : record.doctor.fullname) : '-'}</Text>,
    },
    {
      title: 'คลินิกย่อย',
      key: 'doctorDepartment',
      align: 'center',
      width: 150,
      render: (record) => (
        <Text
          onClick={() => {
            console.log(record)
          }}
        >
          {(record.doctor && record.doctor.detail?.department) || '-'}
        </Text>
      ),
    },
    {
      title: 'สถานะ',
      key: 'status',
      align: 'center',
      width: 400,
      render: (record) => {
        const step = handleStatusSteps(record)
        return record.prescription && [1, 3].includes(record.prescription.logisticId) ? (
          <>
            {record.prescription.logisticId === 1 ? (
              <div style={{ width: '100%' }}>
                <Steps current={step} labelPlacement="vertical" size="small">
                  <Step title="ยืนยันรายการยา" icon={<CarryOutOutlined />} />
                  <Step title="กำลังจัดส่งไปร้านยา" icon={<HistoryOutlined />} />
                  <Step title="ร้านยาได้รับยาและแจ้งผู้ป่วยแล้ว" icon={<BellOutlined />} />
                  <Step title="ผู้ป่วยมารับยาที่ร้านแล้ว" icon={<CheckCircleOutlined />} />
                </Steps>
              </div>
            ) : (
              record.prescription.logisticId === 3 && (
                <div style={{ width: '100%' }}>
                  <Steps current={step} labelPlacement="vertical" size="small">
                    <Step title="ยืนยันรายการยา" icon={<CarryOutOutlined />} />
                    <Step title="รอผู้ป่วยมารับยาที่ รพ." icon={<HistoryOutlined />} />
                    <Step title="ผู้ป่วยมารับยาที่ รพ. แล้ว" icon={<CheckCircleOutlined />} />
                  </Steps>
                </div>
              )
            )}
          </>
        ) : (
          <div style={{ width: '100%' }}>
            <Steps current={step} labelPlacement="vertical" size="small">
              <Step title="ยืนยันนัดหมาย" icon={<CarryOutOutlined />} />
              <Step title="Call เสร็จสิ้น" icon={<VideoCameraOutlined />} />
              <Step title="ยืนยันรายการยา" icon={<FileDoneOutlined />} />
              <Step title="กำลังจัดส่ง" icon={<InboxOutlined />} />
              <Step title="รับยาแล้ว" icon={<CheckCircleOutlined />} />
            </Steps>
          </div>
        )
      },
    },
  ]
  const handleDateChange = (date, key) => {
    if (date) {
      searchByDate(date, key)
      setSearchDate(date)
    } else {
      clearSearchData()
      setSearchDate([])
    }
  }
  const searchByDate = (date, key) => {
    switch (key) {
      case 'Bookings':
        const filterBookings = bookings.filter((item) => {
          return moment(item.admitTime).isSameOrAfter(moment(date[0]), 'day') && moment(item.admitTime).isSameOrBefore(moment(date[1]), 'day')
        })
        setFilterBookings(filterBookings)
        break
      case 'onGoingBookings':
        const filterOnGoing = onGoingBookings.filter((item) => {
          return moment(item.admitTime).isSameOrAfter(moment(date[0]), 'day') && moment(item.admitTime).isSameOrBefore(moment(date[1]), 'day')
        })
        setFilterOnGoingBookings(filterOnGoing)
        break
      case 'BookingsEms':
        const filterEms = emsBookings.filter((item) => {
          return moment(item.admitTime).isSameOrAfter(moment(date[0]), 'day') && moment(item.admitTime).isSameOrBefore(moment(date[1]), 'day')
        })
        setFilterEmsBookings(filterEms)
        break
      case 'CompletedBookings':
        const filterCompleted = completedBookings.filter((item) => {
          return moment(item.admitTime).isSameOrAfter(moment(date[0]), 'day') && moment(item.admitTime).isSameOrBefore(moment(date[1]), 'day')
        })
        setFilterCompletedBookings(filterCompleted)
        break
      default:
        clearSearchData()
        break
    }
  }
  const onSearch = (data, key) => {
    if (data.length) {
      let dataForFilter
      switch (key) {
        case 'Bookings':
          dataForFilter = searchDate.length ? filterBookings : bookings
          break
        case 'onGoingBookings':
          dataForFilter = searchDate.length ? filterOnGoingBookings : onGoingBookings
          break
        case 'BookingsEms':
          dataForFilter = searchDate.length ? filterEmsBookings : emsBookings
          break
        case 'CompletedBookings':
          dataForFilter = searchDate.length ? filterCompletedBookings : completedBookings
          break
        default:
          dataForFilter = searchDate.length ? filterBookings : bookings
          break
      }
      const dataFiltered = dataForFilter.filter((item) => {
        return (
          (item.patient && item.patient.fullnameHIE.includes(data)) ||
          (item.patient && item.patient.fullname.includes(data)) ||
          (item.patient && item.patient.hnId.includes(data)) ||
          (item.patient && item.patient.cId.includes(data)) ||
          (item.doctor && item.doctor.fullnameHIE && item.doctor.fullnameHIE.includes(data)) ||
          (item.doctor && item.doctor.fullname.includes(data)) ||
          (item.doctor && item.doctor.detail && item.doctor.detail.department.includes(data))
        )
      })
      switch (key) {
        case 'Bookings':
          setFilterBookings(dataFiltered)
          break
        case 'onGoingBookings':
          setFilterOnGoingBookings(dataFiltered)
          break
        case 'BookingsEms':
          setFilterEmsBookings(dataFiltered)
          break
        case 'CompletedBookings':
          setFilterCompletedBookings(dataFiltered)
          break
        default:
          setFilterBookings(dataFiltered)
          break
      }
    } else {
      if (searchDate.length) {
        clearSearchData()
        searchByDate(searchDate, key)
      } else {
        clearSearchData()
      }
    }
  }
  const clearSearchData = () => {
    setFilterBookings(false)
    setFilterOnGoingBookings(false)
    setFilterEmsBookings(false)
    setFilterCompletedBookings(false)
  }

  return (
    <>
      <div className="w-100">
        <Card>
          <Tabs
            activeKey={currentTab ? currentTab : location.hash.split('#')[1]}
            defaultActiveKey={currentTab ? currentTab : location.hash.split('#')[1]}
            onChange={(tab) => {
              setCurrentTab(tab)
              history.replace({
                hash: `${tab}`,
              })
            }}
          >
            <TabPane tab={`รายการทั้งหมด (${bookings.length})`} key="Bookings">
              <Row gutter={[16, 16]} className="w-100 my-2" justify="center">
                <Col xs={24} md={7}>
                  <RangePicker
                    placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                    ranges={{
                      วันนี้: [moment(), moment()],
                      เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
                    }}
                    format={dateFormatList}
                    onChange={(date) => handleDateChange(date, 'Bookings')}
                    className="w-100"
                  />
                </Col>
                <Col xs={24} md={15}>
                  <Search allowClear onSearch={(data) => onSearch(data, 'Bookings')} className="w-100" placeholder="HN, ชื่อ-นามสกุล, หมายเลขบัตรประชาชน, ชื่อ-นามสกุลแพทย์, คลินิกย่อย" />
                </Col>
              </Row>

              <Spin spinning={isLoading}>
                <Table
                  scroll={{ x: 'max-content' }}
                  columns={columns}
                  dataSource={filterBookings || bookings}
                  onChange={(page) => {
                    history.replace({
                      hash: currentTab,
                      state: { page: page.current },
                    })
                  }}
                  pagination={{ current: location.state && location.state.page ? location.state.page : 1, pageSize: 7 }}
                />
              </Spin>
            </TabPane>
            <TabPane tab={`รายการที่ดำเนินอยู่ (${onGoingBookings.length})`} key="onGoingBookings">
              <Row gutter={[16, 16]} className="w-100 my-2" justify="center">
                <Col xs={24} md={7}>
                  <RangePicker
                    placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                    ranges={{
                      วันนี้: [moment(), moment()],
                      เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
                    }}
                    format={dateFormatList}
                    onChange={(date) => handleDateChange(date, 'onGoingBookings')}
                    className="w-100"
                  />
                </Col>
                <Col xs={24} md={15}>
                  <Search allowClear onSearch={(data) => onSearch(data, 'onGoingBookings')} className="w-100" placeholder="HN, ชื่อ-นามสกุล, หมายเลขบัตรประชาชน, ชื่อ-นามสกุลแพทย์, คลินิกย่อย" />
                </Col>
              </Row>

              <Spin spinning={isLoading}>
                <Table
                  scroll={{ x: 'max-content' }}
                  columns={columns}
                  dataSource={filterOnGoingBookings || onGoingBookings}
                  onChange={(page) => {
                    history.replace({
                      hash: currentTab,
                      state: { page: page.current },
                    })
                  }}
                  pagination={{ current: location.state && location.state.page ? location.state.page : 1, pageSize: 7 }}
                />
              </Spin>
            </TabPane>
            <TabPane tab={`รายการส่งไปรษณีย์ (${emsBookings.length})`} key="BookingsEms">
              <Row gutter={[16, 16]} className="w-100 my-2" justify="center">
                <Col xs={24} md={7}>
                  <RangePicker
                    placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                    ranges={{
                      วันนี้: [moment(), moment()],
                      เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
                    }}
                    format={dateFormatList}
                    onChange={(date) => handleDateChange(date, 'BookingsEms')}
                    className="w-100"
                  />
                </Col>
                <Col xs={24} md={15}>
                  <Search allowClear onSearch={(data) => onSearch(data, 'BookingsEms')} className="w-100" placeholder="HN, ชื่อ-นามสกุล, หมายเลขบัตรประชาชน, ชื่อ-นามสกุลแพทย์, คลินิกย่อย" />
                </Col>
              </Row>

              <Spin spinning={isLoading}>
                <Table
                  scroll={{ x: 'max-content' }}
                  columns={columns}
                  dataSource={filterEmsBookings || emsBookings}
                  onChange={(page) => {
                    history.replace({
                      hash: currentTab,
                      state: { page: page.current },
                    })
                  }}
                  pagination={{ current: location.state && location.state.page ? location.state.page : 1, pageSize: 7 }}
                />
              </Spin>
            </TabPane>
            <TabPane tab={`เสร็จสิ้น (${completedBookings.length})`} key="CompletedBookings">
              <Row gutter={[16, 16]} className="w-100 my-2" justify="center">
                <Col xs={24} md={7}>
                  <RangePicker
                    placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                    ranges={{
                      วันนี้: [moment(), moment()],
                      เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
                    }}
                    format={dateFormatList}
                    onChange={(date) => handleDateChange(date, 'CompletedBookings')}
                    className="w-100"
                  />
                </Col>
                <Col xs={24} md={15}>
                  <Search allowClear onSearch={(data) => onSearch(data, 'CompletedBookings')} className="w-100" placeholder="HN, ชื่อ-นามสกุล, หมายเลขบัตรประชาชน, ชื่อ-นามสกุลแพทย์, คลินิกย่อย" />
                </Col>
              </Row>

              <Spin spinning={isLoading}>
                <Table
                  scroll={{ x: 'max-content' }}
                  columns={columns}
                  dataSource={filterCompletedBookings || completedBookings}
                  onChange={(page) => {
                    history.replace({
                      hash: currentTab,
                      state: { page: page.current },
                    })
                  }}
                  pagination={{ current: location.state && location.state.page ? location.state.page : 1, pageSize: 7 }}
                />
              </Spin>
            </TabPane>
          </Tabs>
          <TrackingDiv className="d-flex align-items-center h6" onClick={() => setVisibleModalTracking(true)}>
            <ProfileOutlined style={{ fontSize: '26px' }} />
            <Text className="px-2">หมายเลข Tracking คงเหลือ {emsAvailable} หมายเลข</Text>
          </TrackingDiv>
        </Card>
      </div>
    </>
  )
}

export default PharmacyDashboardTable

import { observable, action, computed, toJS } from 'mobx'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/pharmacyStoreInfos'

class PharmacyStore {
  @observable info = {
    data: [],
    error: null
  }

  @action updatePharmacyStore = async (id, data) => {
    try {
      const response = await http.patch(`${apiEndpoint}/${id}`, data)
      return response
    } catch (error) {
      console.warn(error)
    }
  }

  @action fetchPharmacyStore = async (id) => {
    try {
      const response = await http.get(`${apiEndpoint}/${id}`)
      return response
    } catch (error) {
      console.warn(error);
    }
  }

  @action createPharmacyStore = async (detail) => {
    try {
      const {data} = await http.post(apiEndpoint, detail)
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @computed get getStore() {
    return toJS(this.pharmacyStore.data)
  }
}

export default new PharmacyStore()

import http from '../services/httpServiceHealthCare'
const API_URL = '/HealthCheckUps'
const NOTE_API_URL = '/HealthCheckUpNotes'

export const getNotes = async (params) => {
  const response = await await http.get(`${NOTE_API_URL}/search?page=${params.page}&limit=${params.limit}${params?.keyword ? '&keyword=' + params.keyword : ''}`)
  return response
}

export const getNote = async (id) => {
  const response = await http.get(`${NOTE_API_URL}/${id}`)
  return response
}

export const updateNoteById = async (params) => {
  const response = await http.patch(`${NOTE_API_URL}/${params.id}`, params.data)
  return response
}

export const createCheckupNote = async (params) => {
  const response = await http.post(`${NOTE_API_URL}/`, params.data)
  return response
}

export const getLatestSummary = async (cid) => {
  try {
    const response = await http.get(`${API_URL}/latest/summary?cId=${cid}`)
    return response
  } catch (error) {
    return error.response
  }
}

export const getAllNotes = async () => {
  try {
    const response = await http.get(`${NOTE_API_URL}`)
    return response.data
  } catch (error) {
    return error.response
  }
}

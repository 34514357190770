/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react'
import Layout from '../Home'
import VajiraKYCTable from '../../components/VajiraKYCTable'
import { observer, inject } from 'mobx-react'

@inject('UserInfo')
@inject('KycPatientImage')
@observer
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataSource: [],
      image: '',
      userDetail: null
    }
  }

  async componentDidMount() {
    const { UserInfo } = this.props
    const response = await UserInfo.fetchUserInfos()
    this.setState({
      dataSource: response
    })
  }
  verifyStatus = async id => {
    const { UserInfo } = this.props
    const info = { verifyId: true }
    const response = await UserInfo.updateUserInfo(id, info)
    let updateItems = this.state.dataSource.map(data => {
      if (data.id === id) {
        return {
          ...data,
          verifyId: response.verifyId
        }
      }
      return data
    })
    this.setState({
      dataSource: updateItems
    })
  }

  checkUserDetail = async id => {
    const { UserInfo } = this.props
    const response = await UserInfo.checkUserDetail(id)
    this.setState({
      userDetail: response
    })
  }

  fetchUserImage = async id => {
    const { KycPatientImage } = this.props
    const response = await KycPatientImage.fetchPatientImage(id)
    this.setState({
      image: response && response.image ? response.image : ''
    })
  }

  render() {
    return (
      <Layout
        {...this.props}
        render={(
          props //eslint-disable-line
        ) => (
          <div>
          <VajiraKYCTable
            verifyStatus={this.verifyStatus}
            fetchUserImage={this.fetchUserImage}
            checkUserDetail={this.checkUserDetail}
            {...props}
            state={this.state}
            style={{ width: '90%' }}
          />
          <VajiraKYCTable
          verifyStatus={this.verifyStatus}
          fetchUserImage={this.fetchUserImage}
          checkUserDetail={this.checkUserDetail}
          {...props}
          state={this.state}
          style={{ width: '90%' }}
        />
        </div>
        )}
      />
    )
  }
}

/* eslint-disable */

import React, { Component } from 'react'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css'

import ProtectedRoute from '../../components/ProtectedRoute'
import Summary from '../SummaryOrder'
import Login from '../Login'
import Logout from '../Logout'
import Register from '../Register'
import NotFound from '../NotFound'
import PatientHistory from '../PatientHistory'
import DoctorSetting from '../DoctorSetting'
import PrescriptionTable from '../PrescriptionTable'
import DoctorTypeList from '../DoctorTypeList'
import TelemedLobby from '../VideoLobby'
import CreateDoctorType from '../CreateDoctorType'
import EmsForm from '../EmsForm'
import EmergencyForm from '../EmergencyForm'
import EditDoctorType from '../EditDoctorType'
import SymptomList from '../SymptomList'
import CreateSymptom from '../CreateSymptom'
import EditSymptom from '../EditSymptom'
import PrescriptionList from '../PrescriptionList'
import ChatContainer from '../ChatContainer'
import BookingFromPatient from '../BookingFromPatient'
import UserList from '../UserList'
import DoctorList from '../DoctorList'
import VajiraKYC from '../VajiraKYC'
import Store from '../Store'
import List from '../List'
import HistoryBooking from '../HistoryBooking'
import TeleMonitoring from '../TeleMonitoring'
import PatientProfile from '../PatientProfile'
import PatientListItem from '../../components/PatientListTable/PatientListItem'
import HistoryPrescription from '../HistoryPrescription'
import DataManagement from '../DataManagement'
import UserReport from '../UserReport'
import FollowUpBooking from '../FollowUpBooking'
import HealthCheckUp from '../HealthCheckUp'
import HealthCheckUpNote from '../HealthCheckUp/note'
import BloodDonorList from '../BloodDonorList'
import CarParkReport from '../CarParkReport'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import BloodDonationForm from '../BloodDonationForm'
import BloodDonationSetting from '../BloodDonationSetting'
import BloodDonationHistory from '../BloodDonationHistory'
import BloodDonationEditForm from '../BloodDonationEditForm'
import jwtDecode from 'jwt-decode'
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID
@inject('User')
@observer
class App extends Component {
  componentDidMount() {
    try {
      const { User } = this.props
      User.loginWithJwt()
    } catch (e) {
      // console.error(e)
    }
  }

  getPathByRole() {
    const userToken = localStorage.getItem('token')
    if (userToken) {
      const user = jwtDecode(userToken)
      const role = user?.roles[0] ?? ''
      if (role === 'bloodBankTechnician') {
        return '/blood-donor-list'
      } else if (role === 'observerStaff') {
        return 'telemed-lobby'
      }
    }

    return '/your-settings'
  }

  render() {
    return (
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <BrowserRouter>
          <Switch>
            <ProtectedRoute path="/prescriptionlist" exact component={PrescriptionList} />
            <ProtectedRoute path="/summary" exact component={Summary} />
            <ProtectedRoute path="/history" exact component={PatientHistory} />
            <ProtectedRoute path="/your-settings" exact component={DoctorSetting} />
            <ProtectedRoute path="/telemonitoring" exact component={TeleMonitoring} />
            <ProtectedRoute path="/telemonitoring/:id" exact component={PatientListItem} />
            <ProtectedRoute path="/patientprofile/:patientId" exact component={PatientProfile} />
            <ProtectedRoute path="/telemed-lobby" exact component={TelemedLobby} />
            <ProtectedRoute path="/history-prescription" exact component={HistoryPrescription} />
            <ProtectedRoute path="/emsform" exact component={EmsForm} />
            <ProtectedRoute path="/ems-forms" exact component={EmergencyForm} />
            <ProtectedRoute path="/chat/:patientId" exact component={ChatContainer} />
            <ProtectedRoute path="/doctor-types" exact component={DoctorTypeList} />
            <ProtectedRoute path="/doctor-types/new" exact component={CreateDoctorType} />
            <ProtectedRoute path="/doctor-types/:doctorTypeId" exact component={EditDoctorType} />
            <ProtectedRoute path="/symptoms" exact component={SymptomList} />
            <ProtectedRoute path="/symptoms/new" exact component={CreateSymptom} />
            <ProtectedRoute path="/symptoms/:symptomId" exact component={EditSymptom} />
            <ProtectedRoute path="/prescription" exact component={PrescriptionTable} />
            <ProtectedRoute path="/history-booking" exact component={HistoryBooking} />
            <ProtectedRoute path="/bookingpatient" exact component={BookingFromPatient} />
            <ProtectedRoute path="/follow-up" exact component={FollowUpBooking} />
            <ProtectedRoute path="/list" exact component={List} />
            <ProtectedRoute path="/userlist" exact component={UserList} />
            <ProtectedRoute path="/doctorlist" exact component={DoctorList} />
            <ProtectedRoute path="/vajira-kyc" exact component={VajiraKYC} />
            <ProtectedRoute path="/store" exact component={Store} />
            <ProtectedRoute path="/data-management" exact component={DataManagement} />
            <ProtectedRoute path="/user-report" exact component={UserReport} />
            <ProtectedRoute path="/register" exact component={Register} />
            <ProtectedRoute path="/health-checkup" exact component={HealthCheckUp} />
            <ProtectedRoute path="/health-checkup-note/" exact component={HealthCheckUpNote} />
            <ProtectedRoute path="/health-checkup-note/:healthCheckUpNotesId" component={HealthCheckUpNote} />
            <ProtectedRoute path="/blood-donor-list" exact component={BloodDonorList} />
            <ProtectedRoute path="/blood-donation-history" exact component={BloodDonationHistory} />
            <ProtectedRoute path="/car-park-report" exact component={CarParkReport} />
            <ProtectedRoute path="/blood-donation-form" exact component={BloodDonationForm} />
            <ProtectedRoute path="/blood-donation-edit-form/:bookingId" exact component={BloodDonationEditForm} />
            <ProtectedRoute path="/blood-donation-setting" exact component={BloodDonationSetting} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            {/* <ProtectedRoute path="/" component={Home} /> */}
            <Route path="/not-found" component={NotFound} />
            <Redirect to={this.getPathByRole()} />
          </Switch>
        </BrowserRouter>
      </IntercomProvider>
    )
  }
}

App.propTypes = {
  User: PropTypes.object, //eslint-disable-line
}

App.defaultProps = {
  // User: null
}

export default App

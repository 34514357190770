import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import httpOma from '../../services/httpServiceHealthCare'
import { fetchNotes, createNote } from '../../store/healthCheckUpSlice'
import { getAllNotes, getLatestSummary } from '../../apiServices/healthCheckupServices'
import { triggerUpdateDataByCid } from '../../apiServices/userServices'
import { message } from 'antd'
import moment from 'moment'

const hooks = () => {
  const [searchKeyword, setSearchKeyword] = useState(null)
  const [tablePage, setTablePage] = useState(1)
  const [tableRecordLimit, setTableRecordLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [isHieLoading, setIsHieLoading] = useState(false)
  const [doctorAdvices, setDoctorAdvices] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [visibleCreateNewModal, setVisibleCreateNewModal] = useState(false)
  const [latestSummary, setLatestSummary] = useState({})
  const [patientInfo, setPatientInfo] = useState({})

  const dispatch = useDispatch()

  const fetchAllCheckUpNotes = (page, limit, keyword) => {
    dispatch(
      fetchNotes({
        page,
        limit,
        keyword,
      }),
    )
  }

  const checkLatestCheckUpSummary = async (cid) => {
    const startDate = moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')
    setIsModalLoading(true)
    await triggerUpdateDataByCid(cid, startDate, endDate)
    const response = await getLatestSummary(cid)
    if (response.status !== 200) {
      message.error('ล้มเหลวในการดึงข้อมูล')
      setVisibleCreateNewModal(false)
      setIsModalLoading(false)
      return
    }
    const createdNote = await checkNoteCreated(cid, response.data)
    if (createdNote) {
      message.info('รายการนี้เคยถูกสร้างแล้ว')
      setTimeout(() => {
        window.open(`/health-checkup-note/${createdNote.id}`, '_blank')
        setVisibleCreateNewModal(false)
        setIsModalLoading(false)
      }, 1500)
    } else {
      const patientInfo = await fecthUserInfoByCId(cid)
      if (patientInfo) {
        setPatientInfo(patientInfo)
        setLatestSummary(response.data)
        setIsModalLoading(false)
      } else {
        message.error(`ไม่พบข้อมูลบุคคล รหัส ${cid}`)
        setVisibleCreateNewModal(false)
      }
    }
  }

  const checkNoteCreated = async (cid, latestSummary) => {
    const allNotes = await getAllNotes()
    const createdNote = allNotes.find((item) => item.cId === cid && moment(item.orderDateTime).format('DD/MM/YYYY') === moment(latestSummary.visit?.orderDate).format('DD/MM/YYYY'))
    return createdNote
  }

  const fecthUserInfoByCId = async (cId) => {
    setIsHieLoading(true)
    try {
      const response = await httpOma.get(`UserInfos/hiePatientInfo?cId=${cId}`)
      if (response?.status <= 200 && response?.status >= 300) {
        setIsHieLoading(false)
        message.error('เกิดข้อผิดพลาดในการรับข้อมูลประวัติบุคคล')
      } else {
        setIsHieLoading(false)
        return response.data
      }
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  const fetchDoctorAdvices = async () => {
    try {
      const response = await httpOma.get(`/DoctorAdvices`)
      if (response?.status <= 200 && response?.status >= 300) {
        message.error('เกิดข้อผิดพลาดในการรับข้อมูลประวัติบุคคล')
        setDoctorAdvices([])
      } else {
        setDoctorAdvices(response.data)
      }
    } catch (e) {
      console.log(e)
      setDoctorAdvices([])
    }
  }

  const convertHn = (hn) => {
    const hnString = hn.toString()
    const second = hnString.substring(0, 2)
    const past = hnString.substring(2).split('')
    let pass = 0
    const final = past.map((string) => {
      if (pass || string !== '0') {
        pass = 1
        return string
      }
    })
    return final.join('').concat('/', second)
  }

  const createCheckUpNote = (data) => {
    setIsModalLoading(true)
    dispatch(
      createNote({
        data,
        callback: (createStatus, id) => {
          if (createStatus) {
            message.success('สร้างรายการใหม่สำเร็จ')
            setTimeout(() => {
              window.open(`/health-checkup-note/${id}`)
            }, 1000)
          } else {
            message.error('สร้างรายการใหม่ล้มเหลว')
          }
          setPatientInfo({})
          setLatestSummary({})
          setIsModalLoading(false)
        },
      }),
    )
  }

  return {
    events: {
      setSearchKeyword,
      setTablePage,
      setTableRecordLimit,
      setCount,
      convertHn,
      fetchAllCheckUpNotes,
      fecthUserInfoByCId,
      fetchDoctorAdvices,
      createCheckUpNote,
      checkLatestCheckUpSummary,
      setVisibleCreateNewModal,
      setPatientInfo,
      setLatestSummary,
    },
    params: { searchKeyword, tablePage, tableRecordLimit, count, isHieLoading, doctorAdvices, isModalLoading, visibleCreateNewModal, patientInfo, latestSummary },
  }
}
export { hooks }

import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import { GlucoseValue, GlucoseTrend, GlucoseGraph } from '../constant'
import GlucoseValueCard from './GlucoseValueCard'
import GlucoseTrendCard from './GlucoseTrendCard'
import GlucoseGraphCard from './GlucoseGraphCard'

function SMBG(props) {
  const { editable, glucoses, defaultGlucoses, patient, filterReport, filterByLevelAndDate, filterByDrugTime, checkAllGlucose } = props
  const [values, setValues] = useState(GlucoseValue)
  const [trends, setTrends] = useState(GlucoseTrend)
  const [graphs, setGraphs] = useState(GlucoseGraph)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (glucoses.data && glucoses.data.arr.length && glucoses.data.dates.length) {
      checkGlucoseValue()
    } else {
      setValues(GlucoseValue)
      setTrends(GlucoseTrend)
      setGraphs(GlucoseGraph)
      setIsLoading(false)
    }
  }, [glucoses])

  const findMin = (arr) => Math.min(...arr)
  const findMax = (arr) => Math.max(...arr)
  const findAverage = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length

  const checkGlucoseValue = () => {
    const { arr } = glucoses.data
    setValues({
      min: findMin(arr),
      max: findMax(arr),
      average: findAverage(arr),
    })
    checkGlucoseTrend()
  }

  const checkGlucoseTrend = (defaultParams) => {
    if (glucoses.data && defaultGlucoses) {
      const { arr } = glucoses.data
      let lowest = defaultGlucoses[0]
      let highest = defaultGlucoses[1]
      if (defaultParams && defaultParams.length) {
        lowest = defaultParams[0]
        highest = defaultParams[1]
      }
      const trend = { ...GlucoseTrend }
      arr.map((value) => {
        if (Number(value) < Number(lowest)) {
          trend.low++
        } else if (Number(value) >= Number(lowest) && Number(value) <= Number(highest)) {
          trend.mid++
        } else if (Number(value) > Number(highest)) {
          trend.high++
        }
      })
      setTrends((prev) => ({
        ...trend,
        pie: {
          ...prev.pie,
          datasets: [
            {
              ...prev.pie.datasets[0],
              data: [trend.mid, trend.low, trend.high],
            },
          ],
        },
      }))
      checkGlucoseGraph(lowest, highest)
    }
  }

  const checkGlucoseGraph = (lowest, highest) => {
    const { arr, periods, reasons, dates } = glucoses.data
    setGraphs((prev) => ({
      defaultLow: lowest,
      defaultHigh: highest,
      lines: {
        options: {
          ...prev.lines.options,
          xaxis: {
            categories: dates,
          },
          annotations: {
            yaxis: [
              {
                ...prev.lines.options.annotations.yaxis[0],
                y: highest,
              },
              {
                ...prev.lines.options.annotations.yaxis[1],
                y: lowest,
                y2: highest,
              },
              {
                ...prev.lines.options.annotations.yaxis[2],
                y: lowest,
              },
            ],
          },
          dataLabels: {
            enabled: true,
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              return drugTimeLable(periods[dataPointIndex])
            },
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return `<div class="arrow_box" style="padding: 16px">
                      <p>Glucose Value: <b>${series[seriesIndex][dataPointIndex]}</b></p>
                      <p>Reason: <b>${reasons[dataPointIndex] || '-'}</b></p>
                      <p>Period: <b>${periods[dataPointIndex] || '-'}</b></p>
                      <p>Date: <b>${dates[dataPointIndex] || '-'}</b></p>
                      </div>`
            },
          },
        },
        series: [
          {
            name: 'Glucose Value',
            data: arr,
          },
        ],
      },
    }))
    setIsLoading(false)
  }

  const drugTimeLable = (drugTime) => {
    if (drugTime.includes('ก่อนอาหาร')) {
      return 'ac'
    } else if (drugTime.includes('หลังอาหาร')) {
      return 'pc'
    } else {
      return ''
    }
  }

  return (
    !isLoading && (
      <div>
        <GlucoseValueCard checkAllGlucose={checkAllGlucose} values={values} filterReport={filterReport} filterByLevelAndDate={filterByLevelAndDate} />
        <GlucoseTrendCard trends={trends} />
        <GlucoseGraphCard editable={editable} graphs={graphs} patient={patient} onFinishGlucose={checkGlucoseTrend} filterByDrugTime={filterByDrugTime} />
      </div>
    )
  )
}

export default SMBG

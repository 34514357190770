import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import axios from 'axios'

const apiEndpoint = 'https://fcm.googleapis.com/fcm'

class FCM {
  @observable info = {
    data: [],
    error: null
  }

  @action sendMessage = async (to, notification,data) => {
    try {
      const { info } = await axios.post(
        'https://fcm.googleapis.com/fcm/send',
        {
          to,
          notification,
          data
        },
        {
          headers: {
            Authorization:
              `key=${process.env.REACT_APP_FCM_API_KEY || 'AAAAg2wfmIg:APA91bHTFQu2B2mGSeSmsVdad-2Lpirx_bVCtcS2T7qS4kX_efVbm60XQT2XKCO7M8EIqTl1dH467SYVvHG4r_0s5k-QNHbtF9X0u88iDo16hZDVum4QDSylJw1hl8NhMo9PKlt2qD_k'}`,
            'Content-Type': 'application/json'
          }
        }
      )
      return info
    } catch (error) {
      console.log(error)
    }
  }
}

export default new FCM()

export const STAFF_NAME = [
  {
    id: 1,
    name: 'นายสุระ ประเสริฐกุล',
  },
  {
    id: 2,
    name: 'น.ส.สุธาทิพย์ แสงสว่าง',
  },
  {
    id: 3,
    name: 'น.ส.ปิ่นปินัทธ์ ปิติสุภะโชค',
  },
  {
    id: 4,
    name: 'นางละออง ทวดสิญจน์',
  },
  {
    id: 5,
    name: 'น.ส.วนัทนันท์ สุวรรณสิทธิ์',
  },
  {
    id: 6,
    name: 'น.ส.ศลิน ทวดสิญจน์',
  },
  {
    id: 7,
    name: 'นายทรงพล บุตรสุวรรณ์',
  },
  {
    id: 8,
    name: 'นางอำพันธ์ เจนสุวรรณ์',
  },
  {
    id: 9,
    name: 'น.ส.พัชรีดา แผนกำเหนิด',
  },
  {
    id: 10,
    name: 'นายธีรภัค โชติพรม',
  },
]

import styled from 'styled-components'
import TextBase from '../Text'
import { Input } from 'antd'

const { Search: SearchAnt } = Input

export const Container = styled.div`
  width: 100%;
  padding: 20px;
`
export const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`
export const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
export const Text = styled(TextBase)`
  font-weight: bold;
`
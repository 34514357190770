import React, { useEffect, useState } from 'react'
import { PressureValue, PressureTrend, PressureGraph } from '../constant'
import PressureValueCard from './PressureValueCard'
import PressureTrendCard from './PressureTrendCard'
import PressureGraphCard from './PressureGraphCard'

function HBPM(props) {
  const { pressures, defaultAbove, defaultBelow, patient, filterReport, filterByLevelAndDate, editable, filterByDrugTime, checkAllPressure } = props
  const [values, setValues] = useState(PressureValue)
  const [trends, setTrends] = useState(PressureTrend)
  const [graphs, setGraphs] = useState(PressureGraph)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (pressures.data && pressures.data.high.length && pressures.data.low.length && pressures.data.dates.length) {
      checkPressureValue()
    } else {
      setValues(PressureValue)
      setTrends(PressureTrend)
      setGraphs(PressureGraph)
      setIsLoading(false)
    }
  }, [pressures])

  const findMin = (arr) => Math.min(...arr)
  const findMax = (arr) => Math.max(...arr)
  const findAverage = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length

  const checkPressureValue = () => {
    const { high, low } = pressures.data
    setValues({
      systolic: {
        min: findMin(high),
        max: findMax(high),
        average: findAverage(high),
      },
      diastolic: {
        min: findMin(low),
        max: findMax(low),
        average: findAverage(low),
      },
    })
    checkPressureTrend()
  }

  const checkPressureTrend = (defaultParams) => {
    if (pressures.data && defaultAbove && defaultBelow) {
      console.log(defaultParams)
      const { high } = pressures.data
      let above = {
        low: defaultAbove[0],
        high: defaultAbove[1],
      }
      let below = {
        low: defaultBelow[0],
        high: defaultBelow[1],
      }
      if (defaultParams && defaultParams.above.length && defaultParams.below.length) {
        above = {
          low: defaultParams.above[0],
          high: defaultParams.above[1],
        }
        below = {
          low: defaultParams.below[0],
          high: defaultParams.below[1],
        }
      }
      const trend = { ...PressureTrend }
      high.map((value) => {
        if (Number(value) < Number(above.low)) {
          trend.low++
        } else if (Number(value) >= Number(above.low) && Number(value) <= Number(above.high)) {
          trend.mid++
        } else if (Number(value) > Number(above.high)) {
          trend.high++
        }
      })
      setTrends((prev) => ({
        ...trend,
        pie: {
          ...prev.pie,
          datasets: [
            {
              ...prev.pie.datasets[0],
              data: [trend.mid, trend.low, trend.high],
            },
          ],
        },
      }))
      checkPressureGraph(above, below)
    }
    setIsLoading(false)
  }

  const checkPressureGraph = (above, below) => {
    const { high, low, periods, reasons, dates } = pressures.data
    setGraphs((prev) => ({
      defaultLowAbove: above.low,
      defaultHighAbove: above.high,
      defaultLowBelow: below.low,
      defaultHighBelow: below.high,
      lines: {
        ...prev.lines,
        options: {
          ...prev.lines.options,
          xaxis: {
            categories: dates,
          },
          dataLabels: {
            enabled: true,
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              return drugTimeLable(periods[dataPointIndex])
            },
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return `<div class="arrow_box" style="padding: 16px">
                      <p> <span class="apexcharts-legend-marker" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-radius: 12px;"></span> 
                      Pressure Messurement Above Value: <b>${series[0][dataPointIndex]}</b>
                      </p>
                      <p> <span class="apexcharts-legend-marker" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-radius: 12px;"></span> 
                      Pressure Messurement Below Value: <b>${series[1][dataPointIndex]}</b>
                      </p>
                      <p>Reason: <b>${reasons[dataPointIndex] || '-'}</b></p>
                      <p>Period: <b>${periods[dataPointIndex] || '-'}</b></p>
                      <p>Date: <b>${dates[dataPointIndex] || '-'}</b></p>
                      </div>`
            },
          },
        },
        series: [
          {
            name: 'Pressure Messurement Above Value',
            data: high,
          },
          {
            name: 'Pressure Messurement Below Value',
            data: low,
          },
        ],
      },
    }))
    setIsLoading(false)
  }

  const drugTimeLable = (drugTime) => {
    if (drugTime.includes('ก่อนอาหาร')) {
      return 'ac'
    } else if (drugTime.includes('หลังอาหาร')) {
      return 'pc'
    } else {
      return ''
    }
  }

  return (
    !isLoading && (
      <div>
        <PressureValueCard checkAllPressure={checkAllPressure} pressure={values.systolic} title="ความดันตัวบน (มิลลิเมตรปรอท)" filterReport={filterReport} filterByLevelAndDate={filterByLevelAndDate} />
        <PressureValueCard pressure={values.diastolic} title="ความดันตัวล่าง (มิลลิเมตรปรอท)" />
        <PressureTrendCard trends={trends} />
        <PressureGraphCard editable={editable} graphs={graphs} patient={patient} onFinishPressure={checkPressureTrend} filterByDrugTime={filterByDrugTime} />
      </div>
    )
  )
}

export default HBPM

import React, { useState, useEffect } from 'react'
import http from '../../../services/httpServiceHealthCare'
import Layout from '../../../containers/Home'
import PatientProfile from '../../PatientProfile'
import moment from 'moment'
import { message } from 'antd'

function PatientListItem(props) {
  const [risk, setRisk] = useState('-')
  const [riskData, setRiskData] = useState({})
  const [patientId, setPatientId] = useState(false)
  const [reports, setReports] = useState([])
  const [patient, setPatient] = useState(false)
  const [patientHie, setPatientHie] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [labs, setLabs] = useState([])

  useEffect(() => {
    checkUserId()
  }, [])

  const checkUserId = () => {
    const { match, location } = props
    if (location.state) {
      setPatient(location.state.patient)
    }
    if (match.params.id) {
      const uri = decodeURIComponent(match.params.id)
      const ids = uri.split('&')
      if (ids.length) {
        const id = ids[0].split('=')[1]
        const userId = ids[1].split('=')[1]
        setPatientId(id)
        fetchUserDetail(id)
        fetchUserDetailFromHie(userId)
        fetchMonitoringReport(userId)
        fetchUserVisitedByVerifiedFromHIE(userId)
      }
    }
  }

  const fetchUserDetail = async (id) => {
    const { data } = await http.get(`/UserInfos/${id}`)
    if (data.thaiCVRick && data.thaiCVRick.result) {
      setRisk(data.thaiCVRick.result)
    }
    if (data.thaiCVRick && data.thaiCVRick.data) {
      console.log('data.thaiCVRick1')
      console.log(data.thaiCVRick.data)
      setRiskData(data.thaiCVRick.data)
      
    }
    setPatient(data)
  }

  const fetchUserDetailFromHie = async (id) => {
    const response = await http.get(`/UserInfos/checkUserDetailFromHIE?patientId=${id}`)
    setPatientHie(response.data)
  }

  const fetchUserVisitedByVerifiedFromHIE = async (patientId) => {
    setIsLoading(true)
    const response = await http.get('/UserInfos/newCheckUserVisitedByVerifiedFromHIE', {
      params: { patientId },
    })
    if (response && response.data) {
      const filterLabData = filterLab(response)
      setLabs(filterLabData)
    }
    setIsLoading(false)
  }

  const triggerUpdateData = async (patientId) => {
    const currentDate = moment().format('YYYY-MM-DD')
    const lastYearDate = moment()
      .subtract(1, 'years')
      .format('YYYY-MM-DD')

    await http.get('/UserInfos/triggerUpdateData', {
      params: {
        patientId,
        vstDate_from: lastYearDate,
        vstDate_to: currentDate,
      },
    })
  }

  const filterLab = (labs) => {
    const filterLab = labs.data.docs.filter((doc) => doc.labResults.length)
    const labResults = filterLab.map((fil) => fil.labResults)
    const concatLabResults = [].concat.apply([], labResults)
    const labData = concatLabResults.map((result) => result.labReportData)
    const concatLabData = [].concat.apply([], labData)
    const filterLabData = concatLabData.filter((data) => new Date(data.orderDateTime) >= new Date(new Date().setFullYear(new Date().getFullYear() - 1)))
    return filterLabData
  }

  const fetchMonitoringReport = async (id) => {
    const response = await http.get(`/monitoringReports?filter[where][appUserId]=${id}`)
    if (response.data) {
      setReports(response.data)
    }
  }

  const updateRisk = async (result) => {
    if (patientId) {
      await http.patch(`/UserInfos/${patientId}`, {
        thaiCVRick: result,
      })
    }
  }
  const updateDiagnosisStartDate = async (data) => {
    if (patientId) {
      const response = await http.patch(`/UserInfos/${patientId}`, {
        diagnosisStartDate: data,
      })
      if (response) {
        message.success('กำหนดวันที่เริ่มวินิจฉัยสำเร็จ')
        checkUserId()
      } else {
        message.error('เกิดข้อผิดพลาดในการส่งข้อมูล')
      }
    }
  }

  const updateYearlyAssesmentData = async (data) => {
    if (patientId) {
      const response = await http.patch(`/UserInfos/${patientId}`, {
        yearlyAssessment: data,
      })
      if (response) {
        message.success('บันทึกข้อมูลสำเร็จ')
        checkUserId()
      } else {
        message.error('เกิดข้อผิดพลาดในการส่งข้อมูล')
      }
    }
  }

  return (
    <Layout
      {...props}
      render={(
        props, //eslint-disable-line
      ) => (
        <PatientProfile
          patient={patient}
          reports={reports}
          hie={patientHie}
          risk={risk}
          riskData={riskData}
          setRisk={(data) => setRisk(data)}
          updateRisk={(result) => updateRisk(result)}
          updateDiagnosisStartDate={(data) => updateDiagnosisStartDate(data)}
          updateYearlyAssesmentData={(data) => updateYearlyAssesmentData(data)}
          labs={labs}
          isLoading={isLoading}
        />
      )}
    />
  )
}

export default PatientListItem

import React, { useState, useEffect } from 'react'
import { Row, Col, Tabs, Button } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons'
import PatientProfileCard from './PatientProfileCard'
import RiskModal from './RiskModal'
import YearlyAssessmentModal from './YearlyAssessmentModal'
import RiskCard from './RiskCard'
import LabTable from './Lab/LabTable'
import SMBG from './SMBG'
import HBPM from './HBPM'
import Weight from './Weight'
import DrugCompliance from './DrugCompliance'
import jwtDecode from 'jwt-decode'
import { DRUGTIME } from './constant'
const { TabPane } = Tabs

const Container = styled.div`
  padding: 20px;
`

function PatientProfile(props) {
  const { patient, reports, hie, updateRisk, risk, setRisk, labs, isLoading, updateDiagnosisStartDate, updateYearlyAssesmentData, riskData } = props
  const [visibleRiskModal, setVisibleRiskModal] = useState(false)
  const [visibleYearlyAssessment, setVisibleYearlyAssessment] = useState(false)
  const [glucoses, setGlucoses] = useState(false)
  const [glucosesReport, setGlucosesReport] = useState(false)
  const [checkAllGlucose, setCheckAllGlucose] = useState(0)
  const [pressures, setPressures] = useState(false)
  const [pressuresReport, setPressuresReport] = useState(false)
  const [checkAllPressure, setCheckAllPressure] = useState(0)
  const [weights, setWeights] = useState(false)
  const [weightReports, setWeightReports] = useState(false)
  const [checkAllWeights, setCheckAllWeights] = useState(0)
  const [editable, setEditable] = useState(true)
  const [hideProfile, setHideProfile] = useState(false)

  useEffect(() => {
    checkNotEditableRole()
    if (reports && reports.length) {
      checkFilterGlucoseReports()
      checkFilterPressureReports()
      checkFilterWeightReports()
    }
  }, [reports])

  const checkNotEditableRole = async () => {
    const roles = await jwtDecode(localStorage.getItem('token')).roles
    if (['nurse', 'pharmacySchedule', 'nutritionist'].includes(roles[0])) setEditable(false)
  }

  const checkFilterGlucoseReports = () => {
    const filterReports = reports.filter((report) => report.detail.measurements.glucoseMeasurements)
    setCheckAllGlucose(filterReports.length)
    checkGlucoseValue(filterReports)
  }

  const checkFilterPressureReports = () => {
    const filterReports = reports.filter((report) => report.detail.measurements.pressureMeasurements)
    setCheckAllPressure(filterReports.length)
    checkPressureValue(filterReports)
  }

  const checkFilterWeightReports = () => {
    const filterReports = reports.filter((report) => report.detail.measurements.weightMeasurements)
    setCheckAllWeights(filterReports.length)
    checkWeightValue(filterReports)
  }
  const checkGlucoseValue = (reports, isUpdateReport = true) => {
    const objectValue = {
      data: {
        arr: [],
        periods: [],
        reasons: [],
        dates: [],
      },
      arr: [],
    }
    objectValue.arr = reports.map((report) => {
      const { glucoseMeasurements } = report.detail.measurements
      const { period } = report.detail
      objectValue.data.arr.push(glucoseMeasurements.glucose)
      objectValue.data.periods.push(period)
      objectValue.data.reasons.push(glucoseMeasurements.reason)
      objectValue.data.dates.push(moment(glucoseMeasurements.timeStamp).format('DD-MM-YYYY'))
      return {
        value: glucoseMeasurements.glucose,
        date: moment(glucoseMeasurements.timeStamp).format('DD-MM-YYYY'),
      }
    })
    if (isUpdateReport) {
      setGlucosesReport(reports)
    }
    setGlucoses(objectValue)
  }

  const checkIsBetweenDate = (time, start, end) => {
    const formatMoment = 'DD-MM-YYYY'
    const startDate = moment(start).subtract(1, 'days')
    const endDate = moment(end).add(1, 'days')
    const momentTime = moment(time).format(formatMoment)
    return moment(momentTime, formatMoment).isBetween(moment(startDate, formatMoment), moment(endDate, formatMoment))
  }

  const checkPressureValue = (reports, isUpdateReport = true) => {
    const objectValue = {
      data: {
        high: [],
        low: [],
        periods: [],
        reasons: [],
        dates: [],
      },
      arr: [],
    }
    objectValue.arr = reports.map((report) => {
      const { high, low, periods, reasons, dates } = objectValue.data
      const { pressureMeasurements } = report.detail.measurements
      const { period } = report.detail
      high.push(pressureMeasurements.high)
      low.push(pressureMeasurements.low)
      periods.push(period)
      reasons.push(pressureMeasurements.reason)
      dates.push(moment(pressureMeasurements.timeStamp).format('DD-MM-YYYY'))
      return {
        high: pressureMeasurements.high,
        low: pressureMeasurements.low,
        date: moment(pressureMeasurements.timeStamp).format('DD-MM-YYYY'),
      }
    })
    if (isUpdateReport) {
      setPressuresReport(reports)
    }
    setPressures(objectValue)
  }

  const filterGlucoseReport = (start, end, showAll = false) => {
    let filterReports = []
    if (showAll) {
      filterReports = reports.filter((report) => report.detail.measurements.glucoseMeasurements)
    } else {
      filterReports = reports.filter((report) => {
        const { glucoseMeasurements } = report.detail.measurements
        if (glucoseMeasurements) {
          if (glucoseMeasurements.timeStamp && checkIsBetweenDate(glucoseMeasurements.timeStamp, start, end)) {
            return report
          }
        }
      })
    }
    checkGlucoseValue(filterReports)
  }

  const filterGlucoseByLevelAndDate = (levels, dateRanges) => {
    const defaultGlucoses = patient.defaultGlucoseValue
    let filterReportsByDate = reports.filter((report) => report.detail.measurements.glucoseMeasurements)
    if (dateRanges && dateRanges.length) {
      const dateRange = Math.max(...dateRanges)
      const startDate = moment().subtract(dateRange, 'days')
      filterReportsByDate = reports.filter((report) => {
        const { glucoseMeasurements } = report.detail.measurements
        if (glucoseMeasurements) {
          if (glucoseMeasurements.timeStamp && checkIsBetweenDate(glucoseMeasurements.timeStamp, startDate, moment())) {
            return report
          }
        }
      })
    }
    let filterLow = []
    let filterNormal = []
    let filterHigh = []
    let filterReports = []
    if (levels && levels.length) {
      levels.map((level) => {
        filterReportsByDate.filter((report) => {
          const { glucoseMeasurements } = report.detail.measurements
          if (glucoseMeasurements) {
            const { glucose } = glucoseMeasurements
            const defaultGlucoses0 = parseInt(defaultGlucoses[0])
            const defaultGlucoses1 = parseInt(defaultGlucoses[1])
            const glucoseVal = parseInt(glucose)
            if (level === 'low' && glucose < defaultGlucoses0) {
              filterLow.push(report)
            } else if (level === 'normal' && glucoseVal >= defaultGlucoses0 && glucoseVal <= defaultGlucoses1) {
              filterNormal.push(report)
            } else if (level === 'high' && glucoseVal > defaultGlucoses1) {
              filterHigh.push(report)
            }
          }
        })
      })
      filterReports = [...filterLow, ...filterNormal, ...filterHigh]
    } else {
      filterReports = filterReportsByDate
    }
    checkGlucoseValue(filterReports)
  }

  const filterPressureByLevelAndDate = (levels, dateRanges) => {
    const defaultAbove = patient.defaultPressureMeasurementsAbove
    const defaultBelow = patient.defaultPressureMeasurementsBelow
    let filterReportsByDate = reports.filter((report) => report.detail.measurements.pressureMeasurements)
    if (dateRanges && dateRanges.length) {
      const dateRange = Math.max(...dateRanges)
      const startDate = moment().subtract(dateRange, 'days')
      filterReportsByDate = reports.filter((report) => {
        const { pressureMeasurements } = report.detail.measurements
        if (pressureMeasurements) {
          if (pressureMeasurements.timeStamp && checkIsBetweenDate(pressureMeasurements.timeStamp, startDate, moment())) {
            return report
          }
        }
      })
    }
    let filterLow = []
    let filterNormal = []
    let filterHigh = []
    let filterReports = []
    if (levels && levels.length) {
      levels.map((level) => {
        filterReportsByDate.filter((report) => {
          const { pressureMeasurements } = report.detail.measurements
          if (pressureMeasurements) {
            const { high, low } = pressureMeasurements
            const highNum = parseInt(high)
            const lowNum = parseInt(low)
            const defaultAbove0 = parseInt(defaultAbove[0])
            const defaultAbove1 = parseInt(defaultAbove[1])
            const defaultBelow0 = parseInt(defaultBelow[0])
            const defaultBelow1 = parseInt(defaultBelow[1])
            if (level === 'low' && highNum < defaultAbove0 && lowNum < defaultBelow0) {
              filterLow.push(report)
            } else if (level === 'normal' && highNum >= defaultAbove0 && highNum < defaultAbove1 && lowNum >= defaultBelow0 && lowNum < defaultBelow1) {
              filterNormal.push(report)
            } else if (level === 'high' && highNum > defaultAbove1 && lowNum > defaultBelow1) {
              filterHigh.push(report)
            }
          }
        })
      })
      filterReports = [...filterLow, ...filterNormal, ...filterHigh]
    } else {
      filterReports = filterReportsByDate
    }
    checkPressureValue(filterReports)
  }

  const filterPressureReport = (start, end, showAll = false) => {
    let filterReports = []
    if (showAll) {
      filterReports = reports.filter((report) => report.detail.measurements.pressureMeasurements)
    } else {
      filterReports = reports.filter((report) => {
        const { pressureMeasurements } = report.detail.measurements
        if (pressureMeasurements) {
          if (pressureMeasurements.timeStamp && checkIsBetweenDate(pressureMeasurements.timeStamp, start, end)) {
            return report
          }
        }
      })
    }
    checkPressureValue(filterReports)
  }

  const checkWeightValue = (reports, isUpdateReport = true) => {
    const objectValue = {
      data: {
        arr: [],
        height: [],
        bmi: [],
        dates: [],
      },
      arr: [],
    }
    objectValue.arr = reports.map((report) => {
      const { weightMeasurements } = report.detail.measurements
      objectValue.data.arr.push(weightMeasurements.weight)
      objectValue.data.height.push(weightMeasurements.height)
      objectValue.data.bmi.push(weightMeasurements.bmi)
      objectValue.data.dates.push(moment(weightMeasurements.timeStamp).format('DD-MM-YYYY'))
      return {
        value: weightMeasurements.weight,
        date: moment(weightMeasurements.timeStamp).format('DD-MM-YYYY'),
      }
    })
    if (isUpdateReport) {
      setWeightReports(reports)
    }
    setWeights(objectValue)
  }

  const filterWeightReport = (start, end, showAll = false) => {
    let filterReports = []
    if (showAll) {
      filterReports = reports.filter((report) => report.detail.measurements.weightMeasurements)
    } else {
      filterReports = reports.filter((report) => {
        const { weightMeasurements } = report.detail.measurements
        if (weightMeasurements) {
          if (weightMeasurements.timeStamp && checkIsBetweenDate(weightMeasurements.timeStamp, start, end)) {
            return report
          }
        }
      })
    }
    checkWeightValue(filterReports)
  }

  const filterWeightByLevelAndDate = (levels, dateRanges) => {
    const defaultBMI = [18.5, 22.9]
    let filterReportsByDate = reports.filter((report) => report.detail.measurements.weightMeasurements)
    if (dateRanges && dateRanges.length) {
      const dateRange = Math.max(...dateRanges)
      const startDate = moment().subtract(dateRange, 'days')
      filterReportsByDate = reports.filter((report) => {
        const { weightMeasurements } = report.detail.measurements
        if (weightMeasurements) {
          if (weightMeasurements.timeStamp && checkIsBetweenDate(weightMeasurements.timeStamp, startDate, moment())) {
            return report
          }
        }
      })
    }
    let filterLow = []
    let filterNormal = []
    let filterHigh = []
    let filterReports = []
    if (levels && levels.length) {
      levels.map((level) => {
        filterReportsByDate.filter((report) => {
          const { weightMeasurements } = report.detail.measurements
          if (weightMeasurements) {
            const { bmi } = weightMeasurements
            const defaultBMI0 = parseInt(defaultBMI[0])
            const defaultBMI1 = parseInt(defaultBMI[1])
            if (level === 'low' && bmi < defaultBMI0) {
              filterLow.push(report)
            } else if (level === 'normal' && bmi >= defaultBMI0 && bmi <= defaultBMI1) {
              filterNormal.push(report)
            } else if (level === 'high' && bmi > defaultBMI1) {
              filterHigh.push(report)
            }
          }
        })
      })
      filterReports = [...filterLow, ...filterNormal, ...filterHigh]
    } else {
      filterReports = filterReportsByDate
    }
    checkWeightValue(filterReports)
  }

  const filterByDrugTime = (drugTime, type) => {
    let reports = type === 'glucoses' ? glucosesReport : type === 'pressure' ? pressuresReport : []
    let allReports = []
    let filterReports = []
    drugTime.map((time) => {
      const x = reports.filter((item) => {
        if (item.detail && item.detail.period) {
          return item.detail.period.includes(DRUGTIME[time]) && item
        }
      })
      filterReports = [...filterReports, ...x]
    })
    if (drugTime.includes('all')) {
      const filterStatus = ['ก่อนอาหารเช้า', 'ก่อนอาหารกลางวัน', 'ก่อนอาหารเย็น', 'หลังอาหารเช้า', 'หลังอาหารกลางวัน', 'หลังอาหารเย็น']
      allReports = reports.filter((report) => {
        if (report.detail && report.detail.period) {
          return !filterStatus.includes(report.detail.period) && report
        }
      })
      filterReports = [...filterReports, ...allReports]
    }
    filterReports.sort((a, b) => {
      if (a.id < b.id) {
        return -1
      }
      if (a.id > b.id) {
        return 1
      }
      return 0
    })
    if (type === 'glucoses') {
      checkGlucoseValue(filterReports, false)
    } else if (type === 'pressure') {
      checkPressureValue(filterReports, false)
    }
  }

  return (
    <Container>
      <RiskModal visible={visibleRiskModal} setVisible={(condition) => setVisibleRiskModal(condition)} setRisk={(detail) => setRisk(detail)} updateRisk={(data) => updateRisk(data)} riskData={riskData} />
      <div className="w-100 d-flex justify-content-start pb-3">
        <Button type="primary" className="d-flex justify-content-end align-items-center" onClick={() => setHideProfile(!hideProfile)} icon={hideProfile ? <PlusSquareOutlined /> : <MinusSquareOutlined />}>
          {hideProfile ? 'แสดงโปรไฟล์ผู้ป่วย' : 'ซ่อนโปรไฟล์ผู้ป่วย'}
        </Button>
      </div>
      <Row wrap={false}>
        <Col span={hideProfile ? 0 : 8} style={{ maxWidth: '500px' }}>
          <PatientProfileCard patient={patient} hie={hie} />
          <RiskCard editable={editable} setVisibleRiskModal={(condition) => setVisibleRiskModal(condition)} risk={risk} patient={patient} updateDiagnosisStartDate={(data) => updateDiagnosisStartDate(data)} />
          {editable && <YearlyAssessmentModal patient={patient} updateYearlyAssesmentData={(data) => updateYearlyAssesmentData(data)} />}
        </Col>
        <Col span={hideProfile ? 24 : 15} offset={hideProfile ? 0 : 1}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="SMBG" key="1">
              <SMBG
                editable={editable}
                glucoses={glucoses}
                defaultGlucoses={patient.defaultGlucoseValue ? patient.defaultGlucoseValue : [80, 160]}
                patient={patient}
                checkAllGlucose={checkAllGlucose === (Array.isArray(glucoses.arr) ? glucoses.arr?.length : 0)}
                filterReport={filterGlucoseReport}
                filterByLevelAndDate={filterGlucoseByLevelAndDate}
                filterByDrugTime={filterByDrugTime}
              />
            </TabPane>
            <TabPane tab="HBPM" key="2">
              <HBPM
                editable={editable}
                pressures={pressures}
                defaultAbove={patient.defaultPressureMeasurementsAbove ? patient.defaultPressureMeasurementsAbove : [120, 129]}
                defaultBelow={patient.defaultPressureMeasurementsBelow ? patient.defaultPressureMeasurementsBelow : [80, 84]}
                patient={patient}
                checkAllPressure={checkAllPressure === (Array.isArray(pressures.arr) ? pressures.arr?.length : 0)}
                filterReport={filterPressureReport}
                filterByLevelAndDate={filterPressureByLevelAndDate}
                filterByDrugTime={filterByDrugTime}
              />
            </TabPane>
            <TabPane tab="Weight" key="3">
              <Weight
                editable={editable}
                weights={weights}
                defaultWeights={[18.5, 22.9]}
                patient={patient}
                checkAllWeights={checkAllWeights === (Array.isArray(weights.arr) ? weights.arr?.length : 0)}
                filterReport={filterWeightReport}
                filterByLevelAndDate={filterWeightByLevelAndDate}
              />
            </TabPane>
            <TabPane tab="Drug Compliance" key="4">
              <DrugCompliance editable={editable} patient={patient} />
            </TabPane>
            <TabPane tab="Lab จาก Ephis" key="5">
              <LabTable labs={labs} isLoading={isLoading} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

export default PatientProfile

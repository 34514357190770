import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import _get from 'lodash/get'
import PropTypes from 'prop-types'

const formatDate = (dateText) => {
  if (dateText) {
    const date = new Date(dateText)
    return date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })
  }
  return ''
}

// if necessary
const removeNullDate = (cases) => cases.filter(i => !!i.datetime)

@inject('User')
@inject('ConsultCase')
@observer
class PrescriptionTable extends Component {
  static propTypes = {
    User: PropTypes.object.isRequired,
    ConsultCase: PropTypes.object.isRequired
  }

  async componentDidMount() {
    const { User, ConsultCase } = this.props
    await User.fetchInfo()
    // await User.setUpNotification()
    await ConsultCase.fetchCases({
      where: {
        isAnswered: true,
        prescriptionApproverId: null
      },
      include: ['patientConsult', 'symptom', 'doctorConsult'],
    })
  }

  approveCase = async (caseId) => {
    const { ConsultCase, User } = this.props
    await ConsultCase.approveDrugOrder(caseId, User.session.data.id)
    await ConsultCase.fetchCases({
      where: {
        isAnswered: true,
        prescriptionApproverId: null
      },
      include: ['patientConsult', 'symptom', 'doctorConsult'],
    })
  }

  render() {
    const {
      ConsultCase,
      User,
      ...rest
    } = this.props
    return (
      <div>
        <table {...rest}>
          {/* <caption>History Consult Case</caption> */}
          <thead>
            <tr>
              <th style={{ padding: 10 }}>Image</th>
              <th style={{ padding: 10 }}>Name</th>
              <th style={{ padding: 10 }}>Type</th>
              <th style={{ padding: 10 }}>Symptom</th>
              <th style={{ padding: 10 }}>Description</th>
              <th style={{ padding: 10 }}>Duration (Days)</th>
              <th style={{ padding: 10 }}>Case owner</th>
              <th style={{ padding: 10 }}>Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {
              ConsultCase.getCases && removeNullDate(ConsultCase.getCases).map(item => (
                <tr key={item.id}>
                  <td style={{ padding: 10 }}>
                    <img
                      src={item.patientConsult.profileImage}
                      alt="profileImage"
                      style={{ width: 50, height: 50, objectFit: 'cover' }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>{item.patientConsult.fullname}</td>
                  <td style={{ padding: 10 }}>{item.consultCaseType}</td>
                  <td style={{ padding: 10 }}>{_get(item, 'symptom.name', '')}</td>
                  <td style={{ padding: 10 }}>{item.description}</td>
                  <td style={{ padding: 10 }}>{item.duration}</td>
                  <td style={{ padding: 10 }}>{item.doctorConsult ? item.doctorConsult.fullname : 'Unknown'}</td>
                  <td style={{ padding: 10 }}>{formatDate(item.datetime)}</td>
                  <td style={{ padding: 10 }}><button type="button" onClick={() => this.approveCase(item.id)}>Approve</button></td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }
}
export default PrescriptionTable

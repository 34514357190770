export const tabTableInfo = [
  {
    key: '1',
    tabName: 'การปฏิบัติการ',
    column: 'columns',
    tabDataSource: 'reportDataSource',
    innerTab: [
      {
        key: '11',
        innerLabel: 'Pre-hospital data report',
        columnInner: 'columnsPreHospital',
      },
      {
        key: '12',
        innerLabel: 'Mission Overview',
        columnInner: 'columnsMissionOverview',
      },
    ],
  },
  {
    key: '2',
    tabName: 'ยกเลิก',
    column: 'columnsCancel',
    tabDataSource: 'cancelDataSource',
  },
  {
    key: '3',
    tabName: 'ส่งต่อ',
    column: 'columnsCancel',
    tabDataSource: 'passDataSource',
  },
  {
    key: '4',
    tabName: 'ปฏิเสธการรับเรื่อง',
    column: 'columnsReject',
    tabDataSource: 'rejectDataSource',
  },
]

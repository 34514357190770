import React from 'react'
import { View, Document, Page } from '@react-pdf/renderer'
import styles, { Row } from './styles'

import DetailTable from './components/DetailTable'
import TimeTable from './components/TimeTable'
import PatientTable from './components/PatientTable'
import HeaderSection from './components/HeaderSection'
import VitalSignTable from './components/VitalSignTable'
import SymtomTable from './components/SymtomTable'
import BodySectionTable from './components/BodySectionTable'
import MoreSymtomTable from './components/MoreSymtomTable'
import ImpressionTable from './components/ImpressionTable'
import ActionTable from './components/ActionTable'
import DecisionTable from './components/DecisionTable'

export default function EmsPdfForm({ objectData }) {
  return (
    <Document>
      <Page size="A4">
        <View key="container" style={styles.mainContainer}>
          <View key="content">
            <HeaderSection key="header" data={objectData} />
            <DetailTable data={objectData.responseData} />
            <TimeTable data={objectData.responseData} />
            <PatientTable data={objectData.demographicData} />
            <Row spaceBetween>
              <VitalSignTable data={objectData.assessmentData} />
              <SymtomTable data={objectData.historyData} />
            </Row>
            <Row spaceBetween>
              <BodySectionTable data={objectData.historyData} />
              <MoreSymtomTable data={objectData.assessmentData} />
            </Row>
            <View style={{ height: 1 }} />
            <ImpressionTable data={objectData.impressionData} />
            <View style={{ height: 1 }} />
            <ActionTable data={objectData.impressionData} />
            <DecisionTable data={objectData.transferData} />
          </View>
        </View>
      </Page>
    </Document>
  )
}

import { toJS, observable, action, computed } from 'mobx'
// import jwtDecode from 'jwt-decode'

import http from '../../services/httpService'

const apiEndpoint = '/MessengerHistories'
// const tokenKey = 'token'

class Product {
  @observable payload = {
    data: [],
    error: null,
    searchResults: [],
  }

  @action createMessage = async (message) => {
    try {
      await http.post(`${apiEndpoint}`, message)
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchMessages = async (filter) => {
    try {
      const response = await http.get(`${apiEndpoint}?filter=${filter}`)
      this.payload.data = response.data
    } catch (error) {
      console.log(error)
    }
  }

  @computed get getMessages() {
    return toJS(this.payload.data) || []
  }
}

export default new Product()

import http from '../services/httpServiceHealthCare'
const API_URL = '/BloodDonationForms'
const BOOKING_API_URL = '/BloodDonationBookings'
export const getForms = async (cId) => {
  const response = await http.get(`${API_URL}?filter={"where":{"cId": ${cId}},"include":"bloodDonationBooking"}`)
  return response
}
export const getFormByBookingId = async (bookingId) => {
  const response = await http.get(`${BOOKING_API_URL}/${bookingId}/bloodDonationForm`)
  return response
}

export const updateForm = async (params) => {
  const response = await http.patch(`${API_URL}/${params.formId}`, params.latestDonorFormData)
  return response
}

export const getExcelFormByDonateForStatus = async (status = 'บริจาคเลือดโดยระบุชื่อผู้รับเลือด') => {
  const response = await http.get(`${API_URL}/?filter={"include": ["bloodDonationBooking"],"where": { "donateFor": "${status}"}}`)
  return response
}

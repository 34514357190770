import React, { useState, useEffect } from 'react'
import { Modal, Button, Input, Typography, Col, Row } from 'antd'
import styled from 'styled-components'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'
import firebase from 'firebase'
import jwtDecode from 'jwt-decode'
const { TextArea } = Input

const Container = styled.div`
  padding: 20px;
`
const { Title } = Typography

function Note({
  bookingNotes = [],  // default as empty array
  emitNote,
  setClearNotes,
  clearNotes,
  path
}) {
  const id = jwtDecode(localStorage.getItem('token'))?.id
  const [visibleModal, setVisibleModal] = useState(false)
  const [notes, setNotes] = useState([])
  const [topic, setTopic] = useState('')

  useEffect(() => {
    if (clearNotes) {
      setNotes([])
      setClearNotes(false)
    }
  }, [clearNotes])

  useEffect(() => {
    setNotes(bookingNotes)
  }, [bookingNotes])

  useEffect(() => {
    if (path) {
      const notesRef = firebase.database().ref(`patientStatus/${path}`);
      notesRef.on('value', (snapshot) => {
        const data = snapshot.val();
        if (data.note) {
          setNotes(data.note);
        }
      });
      return () => notesRef.off(); // Clean up the listener
    }

  }, [path]);


  const createNote = () => {
    setNotes((prevState) => [...prevState, { id: notes.length + 1, topic, description: '', userId: id }])
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      createNote();
      setVisibleModal(false);
      setTopic('');
    }
  };


  const onChangeDescription = (value, index) => {
    setNotes((prevNotes) =>
      prevNotes.map((note, idx) => {
        if (index === idx) {
          return { ...prevNotes[index], description: value, timestamp: moment().format() }
        }
        return note
      }),
    )
  }
  const deleteNote = (index) => {
    setNotes((prevNotes) => prevNotes.filter((_, idx) => idx !== index));
  }

  const handleSave = () => {
    emitNote(notes);  // Only call emitNote when explicitly saving the notes
  };

  return (
    <Container>
      <Button type="primary" block icon={<PlusOutlined />} size="large" onClick={() => setVisibleModal(true)}>
        หัวข้อ
      </Button>
      <Modal
        visible={visibleModal}
        onOk={() => {
          createNote()
          setVisibleModal(false)
          setTopic('')
        }}
        onCancel={() => {
          setVisibleModal(false)
          setTopic('')
        }}
        title="เพิ่มหัวข้อ"
      >
        <Input placeholder="กรอกหัวข้อ" value={topic} onChange={(e) => setTopic(e.target.value)} onKeyDown={handleKeyDown} />
      </Modal>
      {notes.length
        ? notes.map((note, index) => (
          <div style={{ marginTop: 20 }}>
            <Row>
              <Col><Title level={3}>{`${note.timestamp ? moment(note.timestamp).format('DD/MM/YYYY') + ' - ' : ''}${note.topic}`}</Title></Col>
              <Col><Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => deleteNote(index)}
                style={{ color: 'red', marginLeft: 10 }}
              /></Col>
            </Row>

            <TextArea rows={4} value={note.description} onChange={(e) => onChangeDescription(e.target.value, index)} />
          </div>
        ))
        : ''}
      <div style={{ width: '100%', textAlign: 'right' }}>
        <Button type="primary" onClick={handleSave} style={{ marginTop: 20 }}>
          บันทึก
        </Button>
      </div>
    </Container>
  )
}
export default Note

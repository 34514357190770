import React, { Component } from 'react'
import Layout from '../Home'
import ConsultCaseTable from '../../components/ConsultCaseTable'
import { TABLE_TYPES } from '../../constant'
import { observer, inject } from 'mobx-react'
import jwtDecode from 'jwt-decode'
import { message } from 'antd'
import moment from 'moment'

@inject('Booking')
@inject('Logistic')
@inject('Prescription')
@inject('UserInfo')
@inject('AppUserIdentity')
@inject('TrackingData')
@inject('UserFcmToken')
@inject('FCM')
@inject('KycPatientImage')
@observer
export default class index extends Component {
  constructor() {
    super()
    this.state = {
      prescriptions: [],
      roles: [],
      trackingNumber: '',
      billingItems: [],
      hieData: {},
      isLoading: false,
      ems: [],
      stickers: [],
      isSticker: false,
      hnId: []
    }
  }

  mounted = async () => {
    const { Logistic } = this.props
    const user = jwtDecode(localStorage.getItem('token'))
    const responseLogistic = await Logistic.fetchTrackingNumber('EB21999770TH')
    if (responseLogistic && responseLogistic.result) {
      this.setState({
        trackingNumber: responseLogistic.result
      })
    }
    let response = await this.fetchBooking()
    this.sortBooking(response)
    this.setState({
      prescriptions: response,
      roles: user.roles
    })
  }

  fetchBooking = async () => {
    const { Booking } = this.props
    let response = await Booking.fetchBookingFilterByPrescription()
    response = response.filter((res) => {
      if (res.prescription && res.prescription.status) {
        return ['SUCCESS_BY_EMS', 'SUCCESS_BY_PHARMACYSTORE', 'SUCCESS_BY_PHARMACY', 'SUCCESS_BY_PATIENT'].includes(res.prescription.status)
      }
    })
    return response
  }

  sortBooking = (data) => {
    data.sort((a, b) => {
      return b.bookingTime - a.bookingTime
    })
    data.sort((a, b) => {
      return moment(b.admitTime).unix() - moment(a.admitTime).unix()
    })
    return data
  }

  fetchUserFcmToken = async (id) => {
    const { UserFcmToken, FCM } = this.props
    const everOmaId = await this.fetchAppUserIdentity(id)
    if (everOmaId) {
      const response = await UserFcmToken.fetchUserFcmToken(everOmaId)
      if (response && response.length) {
        response.forEach(async (res) => {
          let notification = {
            body: 'รายการยาของคุณมาถึงแล้ว',
            title: 'แจ้งเตือน',
            icon: 'ic_launcher',
            color: '#f1c40f',
            sound : 'default'
          }
          await FCM.sendMessage(res.fcmToken, notification)
        })
      }
    }
  }

  componentDidMount() {
    this.mounted()
  }

  updateStatus = (response, id) => {
    let updateItems = this.state.prescriptions.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          status: response.status
        }
      }
      return data
    })
    this.setState({
      prescriptions: updateItems
    })
  }

  fetchUserVisitedByVerifiedFromHIE = (patientId, vnNumber) => {
    const { UserInfo } = this.props
    this.setState(
      {
        isLoading: true
      },
      async () => {
        const everOmaId = await this.fetchAppUserIdentity(patientId)
        await UserInfo.triggerUpdateData(everOmaId)
        const response = await UserInfo.checkDrugsDetailByOrderNo(everOmaId, vnNumber)
        if (response && response.billingItems.length) {
          this.setState({
            billingItems: response.billingItems,
            isLoading: false
          })
        } else {
          this.setState({
            billingItems: [],
            isLoading: false
          })
        }
      }
    )
  }

  fetchAppUserIdentity = async (id) => {
    const { AppUserIdentity } = this.props
    const response = await AppUserIdentity.fetchAppUserIdentity(id)
    if (response && response.length) {
      return response[response.length - 1].everOmaId
    } else {
      return false
    }
  }

  setStatus = async (id, status) => {
    const { Booking } = this.props
    const response = await Booking.setStatus(id, status)
    if (response) {
      message.success('ยืนยันรายการเสร็จสิ้น')
      this.updateStatus(response, id)
    } else {
      message.error('เกิดข้อผิดพลาดบางอย่าง')
    }
  }

  confirmOrder = async (prescriptionId) => {
    const { Prescription } = this.props
    await Prescription.confirmOrderPrescription(prescriptionId)
    this.setStatusPrescription(prescriptionId, 'PATIENT_PENDING_PAYMENT')
  }

  setStatusPrescription = async (id, status) => {
    const { Prescription } = this.props
    const response = await Prescription.updateStatus(id, status)
    if (response) {
      message.success('ยืนยันรายการเสร็จสิ้น')
      this.updateStatusPrescription(response, id)
    } else {
      message.error('เกิดข้อผิดพลาดบางอย่าง')
    }
  }

  updateStatusPrescription = (response, id) => {
    let updateItems = this.state.prescriptions.map((data) => {
      if (data.prescriptionId === id) {
        return {
          ...data,
          prescription: response
        }
      }
      return data
    })
    this.setState({
      prescriptions: updateItems
    })
  }

  fetchDetailTrackingNumber = async (id, isSticker) => {
    const { Prescription } = this.props
    const response = await Prescription.fetchDetailTrackingNumber(id)
    if (isSticker) {
      this.setState({
        stickers: response,
        isSticker
      })
    } else {
      this.setState({
        ems: response
      })
    }
  }

  fetchPatient = (id) => {
    const { AppUserIdentity, UserInfo } = this.props
    this.setState(
      {
        isLoading: true
      },
      async () => {
        const everOmaId = await this.fetchAppUserIdentity(id)
        if (everOmaId) {
          const resHie = await UserInfo.checkUserDetailByVerifiedFromHIE(everOmaId)
          this.setState({
            hieData: resHie
          })
        }
        this.setState({
          isLoading: false
        })
      }
    )
  }

  addTrackingNumber = async (prescriptionId, quantity) => {
    const { Prescription } = this.props
    const response = await Prescription.addTrackingNumber(prescriptionId, quantity)
    message.success('สำเร็จ')
  }

  thaiPost = async (barcode) => {
    const { TrackingData } = this.props
    await TrackingData.updateThaipost(barcode)
  }

  fetchUserImage = async (id) => {
    const { KycPatientImage } = this.props
    const everOmaId = await this.fetchAppUserIdentity(id)
    if (everOmaId) {
      const response = await KycPatientImage.fetchPatientImage(everOmaId)
      if (response) {
        this.setState({
          image: response.image
        })
      } else {
        this.setState({
          image: false
        })
      }
    }
  }

  render() {
    return (
      <Layout
        {...this.props}
        render={(props) => (
          <ConsultCaseTable
            {...props}
            state={this.state}
            tableType={TABLE_TYPES.prescription}
            style={{ width: '100%' }}
            confirmOrder={this.confirmOrder}
            getTracking={this.handleTracking}
            fetchUserVisitedByVerifiedFromHIE={this.fetchUserVisitedByVerifiedFromHIE}
            fetchDetailTrackingNumber={this.fetchDetailTrackingNumber}
            fetchPatient={this.fetchPatient}
            setStatus={this.setStatus}
            setStatusPrescription={this.setStatusPrescription}
            refreshData={this.mounted}
            addTrackingNumber={this.addTrackingNumber}
            thaiPost={this.thaiPost}
            fetchUserFcmToken={this.fetchUserFcmToken}
            setIsSticker={(condition) => this.setState({ isSticker: condition })}
            fetchUserImage={this.fetchUserImage}
            isHistoryPage={true}
            fetchAppUserIdentity={this.fetchAppUserIdentity}
            isLoading={this.isLoading}
            setIsLoading={(status) => {
              this.setState({
                isLoading: status
              })
            }}
          />
        )}
      />
    )
  }
}

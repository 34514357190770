import { Svg, Path } from "@react-pdf/renderer";
import React from 'react'

export default function CheckboxActive({ width, height }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM6.925 11.575C6.6 11.9 6.075 11.9 5.75 11.575L2.75833 8.58333C2.43333 8.25833 2.43333 7.73333 2.75833 7.40833C3.08333 7.08333 3.60833 7.08333 3.93333 7.40833L6.33333 9.80833L12.0667 4.075C12.3917 3.75 12.9167 3.75 13.2417 4.075C13.5667 4.4 13.5667 4.925 13.2417 5.25L6.925 11.575Z"
        fill="#52A4CD"
      />
    </Svg>
  );
}

import { observable, action, computed, toJS } from 'mobx'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/AppUserAndHospitals'

class AppUserAndHospital {
  @observable info = {
    data: [],
    error: null
  }

  @action addDoctorType = async (appUserId, doctorTypeId) => {
    try {
      const { data } = await http.post(apiEndpoint, {
        appUserId,
        doctorTypeId
      })
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action getUserDoctorTypeId = async (appUserId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}?filter[where][appUserId]=${appUserId}`)
      if (data) {
        return data[0].id
      }
      throw 'User not found'
    } catch (error) {
      console.warn(error)
    }
  }

  @action updateDoctorType = async (appUserId, doctorTypeId) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${appUserId}`, {
        doctorTypeId
      })
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @computed get getAppUserAndHospital() {
    return toJS(this.AppUserAndHospital.data)
  }
}

export default new AppUserAndHospital()

export const PDF_VERSION = "FM-CSS01-002 แก้ไขครั้ง 00"

export const BODY_SECTION = [
  { label: "Head/Face/Neck", key: ["head", "face", "neck"] },
  { label: "Chest/Clavicle", key: ["chest", "axilla"] },
  { label: "Spine/Back/Frank", key: ["backOrFrank"] },
  { label: "Abdomen", key: ["abdomen"] },
  { label: "Pelvis", key: ["pelvisOrHip"] },
  { label: "L Arm", key: ["leftArm"] },
  { label: "R Arm", key: ["RightArm"] },
  { label: "L Leg", key: ["leftLeg"] },
  { label: "R Leg", key: ["RightLeg"] }
]

export const BODY_SECTION_HEADER = [
  "Deformit",
  "Dis./Fx.",
  "Contusio",
  "Abrasion",
  "Penetrati",
  "Blunts",
  "Laceratio",
  "Swelling",
  "Tendern",
  "Gunshot",
  "Burns",
  "",
]

export const AIRWAY = [
  "Patency",
  "Secretion",
  "Blood",
  "Mass",
  "Stridor",
]

export const BREATH_SOUNDS = [
  "Clear",
  "Crepitation",
  "Wheezing",
  "Decreased",
  "Absent",
  "Rhonchi"
]

export const EYES = [
  {
    label: "Reactive",
    key: "reactive"
  },
  {
    label: "Nonreactive",
    key: "nonreactive"
  },
  {
    label: "Constricted",
    key: "constricted"
  },
  {
    label: "Dilated",
    key: "dilated"
  },
  {
    label: "Blind",
    key: "blind"
  },
  {
    label: "Cataracts",
    key: "cataracts"
  },
  {
    label: "Glaucoma",
    key: "glaucoma"
  }
]

export const CIRCULATION_COLOR = [
  "Normal",
  "Pale",
  "Cyanosis",
  "Flushed",
  "Juandice"
]

export const IMPRESSION = [
  {
    column: 1,
    keys: [
      {
        label: "Abdominal pain",
        key: "Abdominal pain"
      },
      {
        label: "Airway Obstruction",
        key: "Airway Obstruction"
      },
      {
        label: "Altered L.O.C",
        key: "Altered L.O.C"
      },
      {
        label: "Anaphylaxis",
        key: "Anaphylaxis"
      },
      {
        label: "Animal bite",
        key: "Animal bites"
      },
      {
        label: "Assault",
        key: "Assault"
      }
    ]
  },
  {
    column: 2,
    keys: [
      {
        label: "Asthma",
        key: "Asthma"
      },
      {
        label: "Behavioral/psych",
        key: "Behavioral / psych"
      },
      {
        label: "Bleeding per vagina",
        key: "Bleeding per vagina"
      },
      {
        label: "Burn",
        key: "Burn"
      },
      {
        label: "Cardiac arrest",
        key: "Cardiac Arrest"
      },
      {
        label: "Cardiac arrythmia",
        key: "Cardiac Arrhythmia"
      }
    ]
  },
  {
    column: 3,
    keys: [
      {
        label: "Chest pain/discomfort",
        key: "Chest pain/Discomfort"
      },
      {
        label: "Drowning",
        key: "Drowning"
      },
      {
        label: "Eclampsia",
        key: "Eclampsia"
      },
      {
        label: "Electrical injury",
        key: "Electrical injury"
      },
      {
        label: "Environment injury",
        key: "Environment injury"
      },
      {
        label: "Fall",
        key: "Fall"
      },
    ]
  },
  {
    column: 4,
    keys: [
      {
        label: "GI bleeding",
        key: "Gl bleed"
      },
      {
        label: "Hanging",
        key: "Hanging"
      },
      {
        label: "Headache",
        key: "Headache"
      },
      {
        label: "High risk pregnancy",
        key: "High risk pregnancy"
      },
      {
        label: "Hyperglycemia",
        key: "Hyperglycemia"
      },
      {
        label: "Hypertension",
        key: "Hypertension"
      },
    ]
  },
  {
    column: 5,
    keys: [
      {
        label: "Hypoglycemia",
        key: "Hypoglycemia"
      },
      {
        label: "Hypotension",
        key: "Hypotension"
      },
      {
        label: "Hypothermia",
        key: "Hypothermia"
      },
      {
        label: "Hypovolemia",
        key: "Hypovolemia"
      },
      {
        label: "Intoxication/alcohol ingestion",
        key: "Intoxication/Alcohol ingestion"
      },
      {
        label: "Obvious death",
        key: "Obvious death"
      },
    ]
  },
  {
    column: 6,
    keys: [
      {
        label: "Pediatric problem",
        key: "Pediatrics problem"
      },
      {
        label: "Poison/Drug ingestion",
        key: "Poison/drug ingestion"
      },
      {
        label: "Pregnancy/ob. devivery",
        key: "Pregnancy/Ob Delivery"
      },
      {
        label: "Rape",
        key: "Rape"
      },
      {
        label: "Respiratory distress",
        key: "Respiratory distress"
      },
      {
        label: "Respiratory failure",
        key: "Respiratory failure"
      },
    ]
  },
  {
    column: 7,
    keys: [
      {
        label: "Seizure",
        key: "Seizure"
      },
      {
        label: "Sepsis",
        key: "Sepsis"
      },
      {
        label: "Shock",
        key: "Shock"
      },
      {
        label: "Stroke/TIA/CVA",
        key: "Stroke/TIA/CVA"
      },
      {
        label: "Syncope",
        key: "Syncope"
      },
      {
        label: "Traumatic injury",
        key: "Traumatic injury"
      },
    ]
  },
  {
    column: 8,
    keys: [
      {
        label: "Unknow",
        key: "Unknow"
      },
      {
        label: "Other",
        key: "Other"
      }
    ]
  }
]

export const ACTION_AIRWAY = [
  {
    label: "No",
    key: 'No',
  },
  {
    label: "Clear airway",
    key: 'Clear airway',
  },
  {
    label: "Suction",
    key: 'Suction',
  },
  {
    label: "OPA",
    key: 'OPA',
  },
  {
    label: "NPA",
    key: 'NPA',
  },
  {
    label: "O2Cannular",
    key: 'O2 cannular',
  },
  {
    label: "Mask with bag",
    key: 'Mask with reservoir bag',
  },
  {
    label: "Bag valve mask",
    key: 'Bag valve mask',
  },
  {
    label: "ETT No",
    key: 'ET No',
  },
  {
    label: "LMA No.",
    key: 'LMA No.',
  },
]

export const ACTION_WOUND = [
  {
    label: "No",
    key: 'No',
  },
  {
    label: "Dressing",
    key: 'Dressing',
  },
  {
    label: "Pressure Dressing",
    key: 'Pressure Dressing',
  },
  {
    label: "T-side Dressing",
    key: 'T-side Dressing',
  },
  {
    label: "Uterine massage",
    key: 'Uterine massage',
  },
  {
    label: "Tourniquet",
    key: "Tourniquet"
  },
  {
    label: "Other",
    key: 'Other',
  },
]

export const ACTION_PERIPHERAL = [
  {
    label: "No",
    key: 'No',
  },
  {
    label: "NSS lock",
    key: 'NSS lock',
  },
  {
    label: "NSS",
    key: 'NSS',
  },
  {
    label: "RLS",
    key: 'RLS',
  },
  {
    label: "Acetar",
    key: 'Acetar',
  },
  {
    label: '10% D/N/2',
    key: '10% D/N/2',
  },
  {
    label: "Other",
    key: 'Other',
  },
  {
    label: "Rate/Load",
    key: 'Rate',
    type: "text"
  },
  {
    label: "Time",
    key: 'Time',
    type: "text"
  },
  {
    label: "Total at ER",
    key: 'Total at ER',
    type: "text"
  },
]

export const ACTION_BONE = [
  {
    label: "No",
    key: 'No',
  },
  {
    label: "Vacuum splint",
    key: 'Vacuum Splint',
  },
  {
    label: "ไม้ดาม",
    key: 'ไม้ดาม',
  },
  {
    label: "Cervical hard collar",
    key: 'Cervical hard collar',
  },
  {
    label: "Long spinal board",
    key: 'Long spinal board',
  },
  {
    label: "Head immobilizer",
    key: 'Head Immobilizer',
  },
  {
    label: "KED",
    key: 'KED',
  },
  {
    label: "Traction splint",
    key: 'Traction splint',
  },
]

export const ACTION_CPR = [
  {
    label: "No",
    key: 'No',
  },
  {
    label: "Yes",
    key: 'Yes',
  },
  {
    label: "AED/DF",
    key: 'AED/DF',
  },
  {
    label: "Synchronize cardioversion",
    key: 'Synchronize cardioversion',
  },
  {
    label: "Transcutaneous pacing",
    key: 'Transcutaneous pacing',
  },
]

export const REASON = [
  "เหมาะสม/สามารถรักษาได้",
  "อยู่ใกล้",
  "มีหลักประกัน",
  "เป็นผู้ป่วยเก่า",
  "เป็นความประสงค์"
]

export const DECISION_STATUS = [
  {
    label: "แดง(วิกฤต)",
    key: "วิกฤต"
  },
  {
    label: "เหลือง(เร่งด่วน)",
    key: "เร่งด่วน"
  },
  {
    label: "เขียว(ไม่รุนแรง)",
    key: "ไม่รุนแรง"
  },
  {
    label: "ขาว(ทั่วไป)",
    key: "ทั่วไป"
  },
  {
    label: "ดํา(รับบริการสธ.อื่นๆ)",
    key: "รับบริการสาธารณสุขอื่นๆ"
  },
]

export const CARE_TITLE = [
  {
    label: "การดูแลระบบหายใจ",
    key: "breathingCare"
  },
  {
    label: "การดูแลระบบทางเดินหายใจ",
    key: "respiratoryCare"
  },
  {
    label: "การดูแลระบบไหลเวียนโลหิต",
    key: "bloodCirculationCare"
  },
  {
    label: "การดามกระดูก",
    key: "boneCast"
  },
  {
    label: "การห้ามเลือด",
    key: "bleedingCare"
  },
]

export const LEVEL = [
  "ไม่จำเป็น",
  "ไม่ได้ทำ",
  "ทำและเหมาะสม",
  "ทำแต่ไม่เหมาะสม"
]

export const CONDITION = [
  "ไม่มีการรักษา",
  "ทุเลา",
  "คงเดิม",
  "ทรุดหนัก",
  "เสียชีวิต ณ จุดเกิดเหตุ",
  "เสียชีวิตขณะนำส่ง"
]

export const COLOR_CONDITION = [
  "แดง",
  "เหลือง",
  "เขียว",
  "ขาว",
  "ดํา"
]

import React, { useState, useEffect, useMemo } from 'react'
import { Input, Modal, Button, Popconfirm, Tag, Row, Col, Spin } from 'antd'
import styled from 'styled-components'
import Table from '../../Table'
import TextBase from '../../Text'
import moment from 'moment'
const index = (props) => {
  const { dataSource = null, ...rest } = props
  const [drugTime, setDrugTime] = useState([])

  useEffect(() => {
    handleDrugTimeInfo(dataSource.drugTime)
  }, [dataSource])

  const setDrugData = (time, data) => {
    const TIME_CONSTANT = { morning: 'ช่วงเช้า', lunch: 'ช่วงกลางวัน', evening: 'ช่วงเย็น', bed: 'ช่วงก่อนนอน' }
    const LABEL_CONSTANT = { begin: 'เวลาเริ่มต้น', eat: 'เวลาทานอาหาร', end: 'เวลาสิ้นสุด' }

    const drugData =
      time !== 'bed'
        ? [
            {
              label: `${LABEL_CONSTANT['begin']}${TIME_CONSTANT[time]}`,
              time: data[time]['begin'] || null
            },
            {
              label: `${LABEL_CONSTANT['eat']}${TIME_CONSTANT[time]}`,
              time: data[time]['eat'] || null
            }
          ]
        : [
            {
              label: `${LABEL_CONSTANT['begin']}${TIME_CONSTANT[time]}`,
              time: data[time]['begin'] || null
            }
          ]

    return drugData
  }

  const handleDrugTimeInfo = (data) => {
    let drugTimeData = []
    if (data) {
      if (data.morning) {
        const morningData = setDrugData('morning', data)
        drugTimeData = [...drugTimeData, ...morningData]
      }
      if (data.lunch) {
        const lunchgData = setDrugData('lunch', data)
        drugTimeData = [...drugTimeData, ...lunchgData]
      }
      if (data.evening) {
        const eveningData = setDrugData('evening', data)
        drugTimeData = [...drugTimeData, ...eveningData]
      }
      if (data.bed) {
        const bedData = setDrugData('bed', data)
        drugTimeData = [...drugTimeData, ...bedData]
      }
    }
    setDrugTime(drugTimeData)
  }
  const Text = styled(TextBase)`
    font-weight: bold;
  `

  const columns = [
    {
      key: 'index',
      width: 50,
      title: 'ลำดับที่',
      className: 'px-1',
      fixed: true,
      render: (text, record, index) => (
        <div style={{ cursor: 'pointer' }}>
          <Text>{index + 1}</Text>
        </div>
      ),
      align: 'center'
    },
    {
      key: 'drugTime',
      title: 'กิจกรรม',
      width: 100,
      fixed: true,
      render: (record) => (
        <div style={{ cursor: 'pointer' }}>
          <Text>{record.label}</Text>
        </div>
      ),
      align: 'center'
    },
    {
      title: 'ช่วงเวลาที่คนไข้ตั้ง',
      key: 'timeInfo',
      width: 200,
      render: (record) => <p>{record.time ? moment(record.time).format('HH:mm') : '-'}</p>,
      align: 'center'
    }
  ]

  return <Table scroll={{ x: 'max-content' }} style={{ width: '100%' }} columns={columns} dataSource={drugTime} pagination={false} {...rest} />
}

export default index

import React, { useRef, useState } from "react";
import { FiCode, FiMinus, FiPlus } from "react-icons/fi";
import Picker from "./Picker";
import './stepper.css'
const Stepper = ({id, step, digits, value, onChange}) => {
    const [isOpenned, setOpenned] = useState(false)
    const incrementer = useRef()
    const handleValueChange = (val) => {
        onChange && onChange(val)
    }
    const handleIncrement = (amount) => {
        handleValueChange(value + amount)
    }
    const handleControlToggle = (e) => {
        console.log("expanding stepper control");
        setOpenned(true)
        document.addEventListener("click", hideStepperControl, true)
    }
    const hideStepperControl = (e) => {
        console.log("hide stepper control pls");
        console.log(e.target);
        if (incrementer.current && (!incrementer.current.contains(e.target))) {
            // e.stopPropagation()
            console.log(incrementer.current);
            setOpenned(false)
            document.removeEventListener("click", hideStepperControl, true)
        }
    }
    return (
        <div className="neo-stepper">
                {!isOpenned ? 
                <button type="button" className="neo-stepper-expand" onClick={() => handleControlToggle()}><FiCode/></button>
                :
                <div ref={incrementer} className="neo-stepper-controller">
                    <button type="button" className="neo-stepper-up" onClick={() => handleIncrement(step || 1)}><FiPlus/></button>
                    <button type="button" className="neo-stepper-back" onClick={() => handleIncrement(-(step || 1))}><FiMinus/></button>
                </div>
                }
            <input id={id} value={value} onChange={e => handleValueChange(e.target.value)} style={{width: `${digits}em`}}/>
        </div>
    )
} 
export default Stepper
import React, { useState, useEffect } from 'react'
import { FileOutlined } from '@ant-design/icons'
import { Input, Tag, Row, Col } from 'antd'
import styled from 'styled-components'
import { Formik } from 'formik'
import moment from 'moment'
import jwtDecode from 'jwt-decode'
import firebase from 'firebase'
import Table from '../Table'
import TextBase from '../Text'
import Button from '../Button'
import { BOOKING_DOCTOR_STATUS, ROOM_STATUS } from '../../constant'

const { Search: SearchAnt } = Input
const Text = styled(TextBase)`
  font-weight: bold;
`
const Search = styled(SearchAnt)`
  i {
    color: ${props => props.theme.primaryButton};
  }
`

const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
const Container = styled.div`
  width: 100%;
`
const ListTable = ({ state, refreshData, ...rest }) => {
  const [patientStatus, setPatientStatus] = useState(false)
  const [filterData, setFilterData] = useState(false)
  useEffect(() => {
    const getFirebasePatientStatus = () => {
      firebase
        .database()
        .ref('patientStatus')
        .on('value', value => {
          const data = []
          value.forEach(valueStatus => {
            data.push({
              ...valueStatus.val(),
              key: valueStatus.key
            })
          })
          setPatientStatus(data)
        })
    }
  }, [])
  const getStatus = id => {
    let findPatientStatus
    if (state.key === 'doctor' && patientStatus) {
      findPatientStatus = patientStatus.find(value => value.key == id)
    }
    if (findPatientStatus && findPatientStatus.status) {
      return (
        <Tag
          color={
            findPatientStatus.status === 'exitedWaitingRoom'
              ? 'orange'
              : findPatientStatus.status === 'inWaitingRoom'
                ? 'green'
                : findPatientStatus.status === 'calling'
                  ? 'blue'
                  : 'yellow'
          }
        >
          {ROOM_STATUS[findPatientStatus.status]}
        </Tag>
      )
    }
    return <Tag color="red">ผู้ป่วยยังไม่เข้าห้องรอ</Tag>
  }
  const setColor = (status, key) => {
    if (!status) {
      return 'blue'
    }
    if (status.includes('PENDING')) {
      return 'orange'
    }
    if (status.includes('CONFIRM') || status.includes('PAID') || status.includes('COMPLETED')) {
      return 'green'
    }
    if (status.includes('DECLINE') || status.includes('CANCEL')) {
      return 'red'
    }
    return 'blue'
  }
  const columns = [
    {
      title: 'ชื่อผู้ป่วย',
      key: 'customerID',
      render: record => record.patient && record.patient.fullname ? <Text>{record.patient.fullname}</Text> : '-',
      align: 'center'
    },
    {
      title: 'เบอรโทรศัพท์',
      key: 'telephone',
      render: record => record.patient && record.patient.tel ? <Text>{record.patient.tel}</Text> : '-',
      align: 'center'
    },
    {
      title: 'วันที่',
      key: 'date',
      render: record => <Text>{moment(record.admitTime).format('DD/MM/YYYY')}</Text>,
      align: 'center',
      sorter: (a, b) => moment(b.admitTime).unix() - moment(a.admitTime).unix(),
      defaultSortOrder: 'aescend'
    },
    {
      title: 'เวลา',
      key: 'time',
      render: record => (
        <Text>
          {moment()
            .startOf('day')
            .add(record.bookingTime, 'minutes')
            .format('HH:mm')}
        </Text>
      ),
      align: 'center'
    },
    {
      title: 'ดูแลโดย',
      key: 'consultWith',
      render: record => (
        <Row>
          <Col>
            {record.doctor && record.doctor.roles[0].name ? record.doctor.roles[0].name : null}
          </Col>
          <Col>{record.doctor && record.doctor.fullname ? record.doctor.fullname : null}</Col>
        </Row>
      )
    },
    {
      title: 'สถานะ',
      key: 'status',
      render: record => (
        <Tag color={setColor(record.status)}>{BOOKING_DOCTOR_STATUS[record.status]}</Tag>
      ),
      align: 'center'
    },
    {
      title: 'สถานะของผู้ป่วย',
      key: 'patient_status',
      width: 200,
      align: 'center',
      render: record => (state.key === 'doctor' && BOOKING_DOCTOR_STATUS[record.status] === 'แพทย์ทำการอนุมัติ')
        || (state.key === 'pharmacy'
          && BOOKING_DOCTOR_STATUS[record.status] === 'เภสัชทำการอนุมัติการจองนัด')
        ? getStatus(record.patientId)
        : ''
    },
    {
      title: 'สถานะการชำระเงิน',
      key: 'payment_status',
      align: 'center',
      render: record => ''
    }
  ]
  
  const onSearch = (text) => {
    const filter = state.dataSource.filter(
      data => data.patient && data.patient.fullname.includes(text)
    )
    setFilterData(filter)
  }

  return (
    <Formik initialValues={{ filter: '' }}>
      {props => (
        <Container>
          <HeaderWrapper>
            <FileOutlined />
            <Text bold>รายการทั้งหมด</Text>
            {state.roles && state.roles.includes('callCenter') ? (
              <Text> ({state.time})</Text>
            ) : null}
            <Button className="ml-2" onClick={() => refreshData()}>
              รีโหลด
            </Button>
            <Search onSearch={onSearch} style={{ width: 200, marginLeft: 'auto' }} />
          </HeaderWrapper>
          <Table columns={columns} dataSource={filterData || state.dataSource} {...rest} />
        </Container>
      )}
    </Formik>
  )
}
export default ListTable

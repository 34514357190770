import React, { Component } from 'react';
import Layout from '../Home';
import PatientListTable from '../../components/PatientListTable';
import { observer, inject } from 'mobx-react';
import PatientProfileDetail from '../../components/PatientProfileDetail';

@inject('UserInfo')
@inject('KycPatientImage')
@observer
export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: null,
      userVisit: null,
      userImage: null,
    };
  }

  async componentDidMount() {
    const { UserInfo } = this.props;
    const dataSource = await UserInfo.checkUserDetail(
      this.props.match.params.patientId
    );
    const userImage = await UserInfo.fetchUserInfoByPatientId(
      this.props.match.params.patientId
    );

    const userVisit = await UserInfo.fetchUserVisitedByVerifiedFromHIE(
      this.props.match.params.patientId
    );

    this.setState({
      dataSource: dataSource,
      userVisit: userVisit,
      userImage: userImage,
    });
  }

  render() {
    return (
      <Layout
        {...this.props}
        render={(
          props //eslint-disable-line
        ) => <PatientProfileDetail state={this.state} />}
      />
    );
  }
}

import React from 'react'
import { Modal } from 'antd'
const ReportProblemModal = ({ visibleReportProblemInfoModal, setVisibleReportProblemInfoModal, noteProblems }) => {
  return (
    <Modal
      visible={visibleReportProblemInfoModal}
      onOk={() => {
        setVisibleReportProblemInfoModal(false)
      }}
      onCancel={() => {
        setVisibleReportProblemInfoModal(false)
      }}
      title="รายละเอียดปัญหา"
      footer={null}
    >
      <p>{noteProblems}</p>
    </Modal>
  )
}

export default ReportProblemModal

import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import styled from 'styled-components'
import { Spin, Card, Row, Col, Table, Tabs, Modal } from 'antd'
const DataCard = styled(Card)`
  height: 100%;
  width: '100%';
  min-width: 250px;
  cursor: pointer;
  border-radius: 5px;
  border: .5px solid #d3d3d3;  
  &:hover {
    transform: scale(1.08);
    z-index: 999;
  }
  transition: 0.3s;
`

const Text = styled.p`
  font-size: 14px;
  color: #2b2b2b;
`
const View = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h5`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`

const Description = styled.p`
  font-size: 2.4rem;
  color: #6e6e6e;
`

const ReportTelemedicine = (props) => {
  const { bookings, doctorTypes, otherTypes } = props
  const MONTH_NAMES = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน',
    'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม',
    'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
  ];
  const [series, setSeries] = useState([])
  const [options, setOptions] = useState({})
  const [countUser, setCountUser] = useState(0);
  const [counts, setCounts] = useState([])
  const [summaryOfYears, setSummaryOfYears] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const [useByDoctorType, setUseCountByDoctorType] = useState([])
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (bookings?.length > 0) {
      monthlyCounts()
      checkDoctorTypes()
    }
  }, [bookings])


  const columnDepartments = [
    {
      title: "ชื่อแผนก",
      dataIndex: "name",
    },
    {
      title: "จำนวนแพทย์",
      dataIndex: "sum",
    },
  ];


  const renderExpandedRow = (record) => {
    const columns = [
      { title: 'ชื่อแพทย์', dataIndex: 'fullname', index: 'fullname' },
      { title: 'จำนวน', dataIndex: 'count', index: 'count' },
    ]
    return (
      <Table columns={columns} pagination={false} dataSource={record.doctors} scroll={{ x: 'max-content' }} />
    )
  };



  const columns = (type) => {
    return [
      {
        title: 'ปี พ.ศ.',
        dataIndex: 'year',
        key: 'year',
      },
      ...MONTH_NAMES.map((month) => ({
        title: month,
        dataIndex: [`${month}`, `${type}`],
        key: month,
        render: (text, record) => {
          const bookings = record[month].bookings
          const isCount = type === 'count' && text > 0 && bookings.length > 0
          return (
            <div style={{ cursor: isCount && 'pointer' }} onClick={() => isCount && handleQueryByPeriod(bookings)}>
              {text}
            </div>
          )
        }
      })),
    ];
  }

  const checkDoctorTypes = () => {
    const byDoctorTypes = [];
    const addType = otherTypes.map((doctorType) => ({
      ...doctorType,
      type: 'other'
    }));
    [...doctorTypes, ...addType].forEach((doctorType) => {
      byDoctorTypes.push({
        ...doctorType,
        sum: 0,
        doctors: []
      })
    })
    setUseCountByDoctorType(byDoctorTypes)
  }

  const handleQueryByPeriod = (bookings) => {
    for (const booking of bookings) {

      let bookingByDoctor
      if (booking.doctor.otherTypeId) {
        bookingByDoctor = useByDoctorType.find((doctorType) => doctorType.type === 'other' && doctorType.id === booking.doctor.otherTypeId)
      } else {
        bookingByDoctor = useByDoctorType.find((doctorType) => doctorType.id === booking.doctor.doctorTypeId)
      }
      const existingDoctor = bookingByDoctor?.doctors?.find(
        (doctor) => doctor.id === booking.doctor.id
      );
      if (existingDoctor) {
        existingDoctor.count++;
      } else {
        bookingByDoctor.doctors.push({
          ...booking.doctor,
          count: 1,
        });
      }
      bookingByDoctor.sum++;
    }
    setVisible(true)
  };


  const monthlyCounts = async () => {
    const THAI_YEAR_OFFSET = 543;
    const countMonths = {};
    const countYears = {}
    let countUserByDate = 0
    const filterBookingByStatus = bookings.filter((booking) => !(booking.status.includes('PENDING') || booking.status.includes('DRAFT')) && (booking?.doctor?.doctorTypeId || booking?.doctor?.otherTypeId))
    filterBookingByStatus.forEach((booking) => {
      const createdAt = new Date(booking.admitTime);
      const year = createdAt.getFullYear() + THAI_YEAR_OFFSET;
      const month = MONTH_NAMES[createdAt.getMonth()];

      if (createdAt <= new Date()) {
        const newYear = year;
        countYears[newYear] = (countYears[newYear] || 0) + 1;

        if (!countMonths[year]) {
          countMonths[year] = {};
        }

        if (!countMonths[year][month]) {
          countMonths[year][month] = {
            count: 1,
            uniquePatients: new Set([booking.patientId]),
            bookings: [booking]
          };
        } else {
          countMonths[year][month].count++;
          countMonths[year][month].uniquePatients.add(booking.patientId);
          countMonths[year][month].bookings.push(booking);
        }
        countUserByDate++;
      }

    });
    const allYear = Object.keys(countYears);
    const summary = allYear.map((year) => ({
      name: year,
      data: countYears[year]
    }));
    const years = Object.keys(countMonths);


    const mapOptions = {
      chart: {
        id: 'user-creation-chart',
        type: 'bar',
      },
      xaxis: {
        categories: MONTH_NAMES,
      },
    };
    const mapSeries = years.map((year) => ({
      name: year,
      data: MONTH_NAMES.map((month) => countMonths[year][month]?.count || 0),
    }));
    const data = allYear.map((year) => {
      const yearData = {
        key: year,
        year,
      };

      MONTH_NAMES.forEach((month) => {
        yearData[month] = {
          count: countMonths[year]?.[month]?.count || 0,
          people: countMonths[year]?.[month]?.uniquePatients?.size || 0,
          bookings: countMonths[year]?.[month]?.bookings || []
        }
      });

      return yearData;
    });

    const dataSource = data.sort((a, b) => b.year - a.year);
    setCounts(dataSource)
    setSummaryOfYears(summary)
    setCountUser(countUserByDate)
    setSeries(mapSeries);
    setOptions(mapOptions);
    setIsLoading(false);
  }

  return (
    <div className="h-100">
      {isLoading ? (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
          <Spin className="text-center" />
        </div>
      ) : (
        <>
          <div className="w-100 p-4">
            <Row>
              <DataCard className='ml-3 mr-3 mt-2 mb-2 pt-1 pb-1' bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <View>
                  <Title className='pb-1'>จำนวนครั้งในการทำ Telemedicine</Title>
                  <View className='pt-2' style={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                    <Description style={{ margin: 0 }}>{countUser}</Description>
                    <Description style={{ fontSize: 14 }}>ครั้ง</Description>
                  </View>
                </View>
              </DataCard>
            </Row>
            <Tabs defaultActiveKey='0' >
              <Tabs.TabPane key='0' tab='กราฟ'>
                <Chart options={options} series={series} type="line" height={350} />
              </Tabs.TabPane>
              <Tabs.TabPane key='1' tab='ตาราง'>
                <Row className="ml-3 mr-3 mt-2 mb-2 pt-1 pb-1" gutter={[16, 16]}>
                  <Col>
                    <Table title={() => 'จำนวนการทำ Telemedicine'} dataSource={counts} columns={columns('count')} scroll={{ x: 'max-content' }} pagination={false} />
                  </Col>
                  <Col>
                    <Table title={() => 'จำนวนคนในการทำ Telemedicine'} dataSource={counts} columns={columns('people')} scroll={{ x: 'max-content' }} pagination={false} />
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>

            <Row>
              {
                summaryOfYears.map((summary, index) => (
                  <DataCard key={index} className='ml-3 mr-3 mt-2 mb-2 pt-1 pb-1' bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <View>
                      <Title className='pb-1'>{summary.name}</Title>
                      <View className='pt-2' style={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                        <Description style={{ margin: 0 }}>{summary.data}</Description>
                        <Description style={{ fontSize: 14 }}>ครั้ง</Description>
                      </View>
                    </View>
                  </DataCard>
                ))
              }
            </Row>
          </div>
        </>
      )}
      <Modal visible={visible} onCancel={() => {
        setVisible(false)
        checkDoctorTypes()
      }} width={1000} footer={null}>
        <Table
          columns={columnDepartments}
          dataSource={useByDoctorType}
          rowKey="id"
          expandable={{
            expandedRowRender: renderExpandedRow,
            rowExpandable: (record) => record.doctors.length > 0
          }}
          scroll={{ x: 'max-content' }}
        />
      </Modal>
    </div>
  )
}

export default ReportTelemedicine

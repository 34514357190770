import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchBookings } from '../../store/bloodBookingSlice'
import moment from 'moment'
const hooks = () => {
  const dispatch = useDispatch()
  const [donorBookingLists, isDonorBookingLoading] = useSelector((state) => {
    const { donorBookingLists, isLoadingData } = state.bloodDonationBooking
    return [donorBookingLists, isLoadingData]
  })

  const convertTime = (start, end, unix = false) => {
    if (unix) {
      return parseInt(
        moment()
          .startOf('isoWeek')
          .add(start, 'minutes')
          .format('HH'),
      )
    }
    return `${moment()
      .startOf('isoWeek')
      .add(start, 'minutes')
      .format('HH:mm')} - ${moment()
      .startOf('isoWeek')
      .add(end, 'minutes')
      .format('HH:mm')}`
  }
  return {
    events: { dispatch, fetchBookings, convertTime },
    params: { donorBookingLists, isDonorBookingLoading },
  }
}

export { hooks }

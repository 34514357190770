import { observable, action, computed, toJS } from 'mobx'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/appUserAndPharmacyStores'

class Store {
  @observable info = {
    data: [],
    error: null
  }

  @action fetchAppUserAndPharmacyStores = async (id) => {
    try {
      const { data } = await http.get(`${apiEndpoint}?filter[include]=pharmacyStore&filter[where][appUserId]=${id}`)
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action createAppUserAndPharmacyStore = async (appUserId,pharmacyStoreId) => {
    try {
      const {data} = await http.post(apiEndpoint,{appUserId,pharmacyStoreId})
      return data
    } catch (e) {
      console.warn(e);
    }
  }

  @computed get getStore() {
    return toJS(this.store.data)
  }
}

export default new Store()

import React from 'react'
import { PropTypes } from 'prop-types'
import { FileOutlined, PhoneOutlined, RightOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import styled from 'styled-components'
import _get from 'lodash/get'
import { Formik } from 'formik'
import Table from '../Table'
import TextBase from '../Text'
import Button from '../Button'
import { TABLE_TYPES } from '../../constant'

const { Search: SearchAnt } = Input
const Text = styled(TextBase)`
  font-weight: bold;
`
const Search = styled(SearchAnt)`
  i {
    color: ${props => props.theme.primaryButton};
  }
`
const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
const Container = styled.div`
  width: 100%;
  padding: 0px;
`

const formatDate = dateText => {
  if (dateText) {
    const date = new Date(dateText)
    return date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })
  }
  return ''
}

let columns = [
  {
    title: 'Type',
    key: 'type',
    render: (text, record) => {
      if (record.consultCaseType === 'video') {
        return <VideoCameraOutlined />;
      }
      return <PhoneOutlined />;
    }
  },
  {
    title: 'Date & Time',
    key: 'date',
    render: (text, record) => <Text>{formatDate(record.datetime)}</Text>
  },
  {
    title: 'Full Name',
    key: 'name',
    render: (text, record) => <Text>{record.patientConsult.fullname}</Text>
  },
  {
    title: 'Symptom',
    key: 'symptom',
    render: (text, record) => <Text>{_get(record, 'symptom.name', '')}</Text>
  },
  {
    title: 'Duration (Days)',
    key: 'duration',
    render: (text, record) => <Text>{record.duration}</Text>
  },
  {
    title: 'Status',
    key: 'prescriptionStatus',
    render: (text, record) => (
      <Text>{record.prescriptionApprove ? 'ACCEPT' : 'PENDING'}</Text>
    )
  }
]
// if necessary

const matchSearch = (filter) => (_case) => {
  const { patientConsult, symptom, duration, consultCaseType } = _case
  return [
    _get(patientConsult, 'fullname', ''),
    _get(symptom, 'name', ''),
    consultCaseType,
    duration
  ].some(text => text && text.toLowerCase().includes(filter))
}

const conditionPrescription = (tableType) => tableType === TABLE_TYPES.prescription
  ? (_case) => !!_case.drugData
  : () => true

const hasDateTime = i => !!i.datetime

const filterCase = (cases, search, tableType) => {
  if (!cases) return []
  const formatCases = _cases => _cases
    .filter(hasDateTime)
    .filter(conditionPrescription(tableType))

  if (!search) return formatCases(cases)
  return formatCases((cases.filter(matchSearch(search))))
}

const BookingCaseTable = ({ Cases, tableType,
  onViewDetailCase,
  history,
  ...rest }) => {
  const hasColumnViewDetail = columns.find(column => column.key === 'viewDetail')
  if (tableType === TABLE_TYPES.prescription && !hasColumnViewDetail) {
    const excludeColumns = ['action', 'viewDetail']
    columns = columns.filter(column => !excludeColumns.includes(column.key))
    columns.push({
      title: '',
      key: 'viewDetail',
      render: (text, record) => (
        <Button primary onClick={() => record.showModalSummaryOrder(record)}>
          Approve
        </Button>
      )
    })
  }
  if (tableType === TABLE_TYPES.history) {
    const excludeColumns = ['viewDetail', 'prescriptionStatus', 'action']
    columns = columns.filter(column => !excludeColumns.includes(column.key))
    columns.push({
      title: '',
      key: 'action',
      render: (text, record) => (
        <Button
          transparent
          primary
          onClick={() => {
            history.push(`/history/${record.id}`)
          }}
        >
          <RightOutlined />
        </Button>
      )
    })
  }

  const headerText = tableType === TABLE_TYPES.history ? 'Upcoming Appointment' : 'Prescriptions'

  return (
    <Formik initialValues={{ filter: '' }}>
      {props => {
        const { values, handleChange } = props
        const { filter } = values
        const formattedCases = filterCase(Cases, filter, tableType)
        return (
          <Container>
            <HeaderWrapper>
              <FileOutlined />
              <Text bold>{headerText}</Text>
              <Search
                value={values.filter}
                name="filter"
                placeholder="Search"
                onChange={handleChange}
                // onSearch={handleChange('filter')}
                style={{ width: 200, marginLeft: 'auto' }}
              />
            </HeaderWrapper>
            <Table
              columns={columns}
              dataSource={formattedCases}
              {...rest}
            />
          </Container>
        );
      }}
    </Formik>
  );
}
BookingCaseTable.defaultProps = {
  onViewDetailCase: () => {}
}
BookingCaseTable.propTypes = {
  Cases: PropTypes.array.isRequired,
  tableType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
}
export default BookingCaseTable

import React, { useCallback, useEffect, useState } from 'react'
import { Button, Row, Col, DatePicker, Form, Input, Radio, Select, Spin } from 'antd'
import { formTemplate, initValues } from './constant'
import ToggleToken from '../../ToggleToken'
import { get } from 'lodash'
import moment from 'moment'

const index = ({ emsTypologiesData = [], updateEmsForm, fetchUserInfos, formDetail, formValues, setFormValues }) => {
  const [TITLE_NAME, SET_TITLE_NAME] = useState([])
  const [NATIONALITY, SET_NATIONALITY] = useState([])
  const [birthDateTime, setBirthDateTime] = useState('')
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()

  useEffect(() => {
    if (emsTypologiesData.length > 0) {
      handleEmsTypologies(emsTypologiesData)
    }
  }, [emsTypologiesData])

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    if (get(formDetail, 'demographicData', '')) {
      setLoading(true)
      const defaultFormValue = form.getFieldsValue()
      const defaultValue = {
        ...defaultFormValue,
        titleName: get(formDetail, 'demographicData.titleName', ''),
        firstName: get(formDetail, 'demographicData.firstName', ''),
        lastName: get(formDetail, 'demographicData.lastName', ''),
        age: get(formDetail, 'demographicData.age', ''),
        gender: get(formDetail, 'demographicData.gender', ''),
        weight: get(formDetail, 'demographicData.weight', ''),
        cid: get(formDetail, 'demographicData.cid', ''),
        nationality: get(formDetail, 'demographicData.nationality', ''),
        treatment: get(formDetail, 'demographicData.treatment', ''),
        birthDate: get(formDetail, 'demographicData.birthDate', ''),
      }
      form.setFieldsValue(defaultValue)
      setFormValues(defaultValue)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const handleEmsTypologies = (emsTypologiesData) => {
    const TITLE = emsTypologiesData.filter((item) => item.field === 'PREFIX_NAME')
    const NATIONALITY = emsTypologiesData.filter((item) => item.field === 'NATIONALITY')
    SET_TITLE_NAME(TITLE)
    SET_NATIONALITY(NATIONALITY)
  }

  const handleOptionDropdown = useCallback(
    (type, items) => {
      let data = []
      switch (type) {
        case 'titleName':
          data = TITLE_NAME
          break
        case 'nationality':
          data = NATIONALITY
          break
        default:
          data = items
      }
      return data.map((item, index) => (
        <Select.Option key={index} value={item.label}>
          {item.label}
        </Select.Option>
      ))
    },
    [NATIONALITY, TITLE_NAME],
  )

  const setSelectedDropdown = (key, value) => {
    form.setFieldsValue({ [key]: value })
    setFormValues({ [key]: value })
  }

  const onFormChangeHandler = (key, value) => {
    form.setFieldsValue({ [key]: value })
    setFormValues({ [key]: value })
  }

  const onFinish = (value) => {
    const payload = {
      ...value,
      treatment: value.treatment,
      birthDate: birthDateTime,
    }
    console.log(payload)
    updateEmsForm('demographicData', { demographicData: payload })
  }

  return (
    <Spin spinning={loading}>
      {!loading ? (
        <Form form={form} name="ems_section2" initialValues={initValues} onFinish={onFinish} layout="vertical">
          {formTemplate.map((template, templateIndex) => (
            <>
              <h2>{template.header}</h2>
              <Row className="mt-4" gutter={[25, 1]} key={templateIndex}>
                {template.data.map((data, index) => {
                  return (
                    <Col xs={24} md={12} key={index}>
                      <Form.Item
                        key={index}
                        name={data.value}
                        label={data.label}
                        rules={[
                          {
                            required: data.required,
                            message: `${data.requiredMessage}`,
                          },
                        ]}
                      >
                        {data.type === 'input' ? (
                          <Input onChange={(event) => setFormValues({ [data.value]: event.target.value })} style={{ width: '100%' }} placeholder={data.placeholder ? data.placeholder : ''} maxLength={data.maxLength} />
                        ) : data.type === 'dropdown' ? (
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onSelect={setSelectedDropdown.bind(this, data.value)}
                            placeholder={data.placeholder ? data.placeholder : ''}
                          >
                            {handleOptionDropdown(data.value, data.items ? data.items : [])}
                          </Select>
                        ) : data.type === 'date' ? (
                          <DatePicker showTime placeholder={data.placeholder ? data.placeholder : ''} onChange={(value) => setFormValues(value)} />
                        ) : (
                          data.type === 'toggle' && (
                            <ToggleToken
                              toStr={true}
                              title={data.innerLabel}
                              values={formValues[data.value]}
                              options={data.items}
                              max={1}
                              setValues={(value) => onFormChangeHandler(data.value, value)}
                              extraInputs={data.extraInputs ? data.extraInputs : false}
                            />
                          )
                        )}
                      </Form.Item>
                    </Col>
                  )
                })}
              </Row>
            </>
          ))}
        </Form>
      ) : (
        <div style={{ height: 800 }}></div>
      )}
    </Spin>
  )
}

export default index

import { values } from 'lodash'
import React, { useEffect, useState } from 'react'
import { FiArrowRight, FiChevronDown } from 'react-icons/fi'
import './style.css'

// type PowerSelectType {
//     title: string,
//     max: number,
//     options: string[],
//     values: string[],
//     setValues: function
// }

const ToggleToken = (props) => {
  const getXI = (fullString, choice) => {
    const existed = fullString.split("→")
    return (existed[0] == choice) ? existed[1] : ""
  }
  const { title, layout, extraInput, noData } = props
  const children = props.children instanceof Array ? props.children : [props.children]
  const options = typeof props.options[0] == "object" ? props.options.map(item => {
    if (!item.value){
      item.value = item.label.toLowerCase().replace(/[^\u0E00-\u0E7F\w]+/g, '_')
    }
    return item
  }) : props.options.map((item) => {
    let val = item.toLowerCase().replace(/[^\u0E00-\u0E7F\w]+/g, '_')
    return {label: item, value: val}
  })
  const [power, setPower] = useState(
    options.map((item) => {
      return typeof props.values == 'string' ? props.values.split("→")[0] == item.label : props.values.some(entry => {
        return entry.split("→")[0] == item.label
      })
    })
  )
  const [none, setNone] = useState(props.values.includes("no_data"))
  const [extraInputText, setExtraInputText] = useState((() => {
    if (extraInput){
      return (typeof props.values == "string") ? getXI(props.values, extraInput) : getXI(props.values.find(entry => entry.split("→")[0] == extraInput) || "", extraInput)
    }
  })())
  const [extraInputTexts, setExtraInputTexts] = useState(options.map(item => {
    if ((props.extraInputs && props.extraInputs.includes(item.label)) || (extraInput && extraInput == item.label)){
      return (typeof props.values == "string") ? getXI(props.values, item.label) : getXI(props.values.find(entry => entry.split("→")[0] == item.label) || "", item.label)
    }
    return undefined
  }))
  const attachments = children && React.Children.map(children, element => {
    if (!React.isValidElement(element)) {
      return
    }
    const { attachTo } = element.props
    return attachTo
  })
  let extraInputRef;
  let extraInputRefs = [];

  const exportValues = (newValues) => {
    if (props.toStr){
      props.setValues(newValues.toString())
    } else {
      props.setValues(newValues)
    }
  }
  const handleSelect = (i, item) => {
    let staticPow = structuredClone(power)
    if (power[i]) { // already on -> turn off
      const removed = props.toStr ? "" : props.values.filter((val) => {
        let tbx = val.split("→")
        return tbx[0] !== item
      })
      staticPow[i] = false
      setPower(staticPow)
      exportValues(removed)
    } else {        // already off -> turn on
      let staticVal = structuredClone(props.values)
      if (props.max) {
        if (staticVal.length >= props.max) {       // selected more than allowed
            let tbr = props.toStr ? staticVal : staticVal.shift() // to be removed
            console.log([tbr]);
            let unwantedI
            options.forEach((option, index) => {
              let otbr = tbr.split("→")
              if (otbr[0] === option || otbr[0] === option.label){
                unwantedI = index
              }
            });
            staticPow[unwantedI] = false
            setPower(staticPow)
        }
      }
      staticPow[i] = true
      setPower(staticPow)
      let tbs = item
      if (item == extraInput) { // selected box including text input
        tbs += ("→" + (extraInputText || ""))
        extraInputRef.focus()
      } else if (props.extraInputs && props.extraInputs.includes(item)) {
        tbs += ("→" + (extraInputTexts[i] || ""))
        extraInputRefs[i].focus()
      }
      if (attachments && attachments.includes(item)) {
        tbs += `→${children[attachments.indexOf(item)].props.attachmentVal}`
      }
      let selVal = props.toStr ? "" : staticVal.filter(item => item != "no_data")
      exportValues(props.toStr ? tbs : [...selVal, tbs])
    }
    if (staticPow.reduce((a, b) => a || b)) {
      setNone(false)
    }
  }
  const setInputRef = (input, i) => {
    if (extraInput) {
        extraInputRef = input
    } else {
        extraInputRefs[i] = input
    }
  }
  const setInputText = (e, i) => {
    if (extraInput) {
        setExtraInputText(e.target.value)
    } else if (props.extraInputs) {
        let newExtraInputTexts = structuredClone(extraInputTexts)
        newExtraInputTexts[i] = e.target.value
        setExtraInputTexts(newExtraInputTexts)
    }
  }
  const deselectAll = () => {
    setPower(options.map((i) => false))
    setNone(!none)
    exportValues(["no_data"])
  }
  return (
    <div className={`toggle-token-wrapper${layout ? " " + layout : ""}${title ? " toggle-token-panel" : ""}`}>
      {title ? <div className='toggle-token-title'><h2>{title}</h2>{noData ? <div role="checkbox" tabIndex="0" data-value="no_data" data-none={none} onClick={deselectAll} onKeyPress={(e) => (e.key == 'Enter') && deselectAll() }>{noData}</div> : <></>}</div> : <></>}
      <div className={`toggle-token${options.length > 3 && props.max == 1 ? ' force-break' : ''}`} data-max={props.max}>
        {options.map((item, i) => {
          const toggleItem = item.label ? item.label : item // typeof toggleItem == string
          const isExtraInput = (extraInput == toggleItem) || (props.extraInputs && props.extraInputs.includes(toggleItem))
          return (
            <React.Fragment key={`toggle-token-${title || ""}-${toggleItem}`}>
              <div
                role="checkbox"
                tabIndex="0"
                className="toggle-token-choice"
                data-value={item.value}
                data-included={power[i]}
                onClick={(e) => {
                  handleSelect(i, toggleItem)
                }}
                onKeyPress={(e) => {
                  if (e.key == 'Enter') {
                    handleSelect(i, toggleItem)
                  }
                }}
                style={item.color ? {
                  backgroundColor: `${item.color}${power[i] ? "ff" : "36"}`
                } : {}}
              >
                <div className="toggle-token-label">
                  {toggleItem}
                  {item.expandable && <FiChevronDown/>}
                  {isExtraInput && <FiArrowRight/>}
                </div>
                {(isExtraInput) && (
                    <div className="extra-input">
                        <input
                        ref={(input) => setInputRef(input, i)}
                        value={extraInput ? extraInputText : extraInputTexts[i]}
                        onInput={(e) => {
                          setInputText(e, i)
                          if (power[i]) {
                          const data = props.toStr ? `${options[i].label}→${e.target.value}` : props.values.map((selectedValue) => {
                              const attachmentPresent = (attachments && attachments.includes(toggleItem))
                              const attachmentVal = (attachments && attachments.includes(toggleItem)) ? children[attachments.indexOf(toggleItem)].props.attachmentVal : ""
                              if (selectedValue.includes(options[i].label)) {
                                  return `${options[i].label}→${e.target.value}${attachmentPresent ? "→" + attachmentVal : ""}`
                              }
                              return selectedValue
                          })
                          exportValues(data)
                          }
                        }}
                        onClick={(e) => e.stopPropagation()}
                        />
                    </div>
                )}
                {(attachments && attachments.includes(toggleItem)) &&
                  <div className="attachment">
                    {children[attachments.indexOf(toggleItem)]}
                  </div>
                }
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
export const TTAttch = ({attachTo, attachmentVal, children}) => {
  return (<div>
    {children}
  </div>)
}
export default ToggleToken

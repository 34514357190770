import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/Appointments'

class Appointment {
  @observable info = {
    data: [],
    error: null,
  }

  @action getNextAppointment = async (bookingId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/upcoming?bookingId=${bookingId}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }
}

export default new Appointment()

import styled from 'styled-components';
import { Button } from 'antd';

const StatusButton = styled(Button)`
  &:hover {
    filter: none;
  }
`

const CardProfile = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
`

export { StatusButton, CardProfile }
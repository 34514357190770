import { observable, action, computed, toJS } from 'mobx'

import http from '../../services/httpService'

const apiEndpoint = '/DoctorTypes'

class DoctorTypes {
  @observable doctorTypes = {
    data: [],
    error: null
  }


  @action fetchDoctorTypes = async () => {
    try {
      const doctorTypes = await http.get(`${apiEndpoint}`)
      this.doctorTypes.data = doctorTypes.data
      return doctorTypes.data
    } catch (error) {
      this.doctorTypes.error = error.response
      return null
    }
  }

  @action getDoctorTypeById = async (id) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/${id}`)
      return data
    } catch (error) {
      this.doctorTypes.error = error.response
      return null
    }
  }

  @action createDoctorType = async (payload) => {
    try {
      const { data } = await http.post(`${apiEndpoint}`, payload)
      return data
    } catch (error) {
      console.log('register error', error)
      return error
    }
  }

   @action updateDoctorType = async (data, id) => {
     try {
       http.patch(`${apiEndpoint}/${id}`, data)
       await this.fetchDoctorTypes()
     } catch (e) {
       // console.error(e)
     }
   }

   @action deleteDoctorType = async (id) => {
     try {
       await http.delete(`${apiEndpoint}/${id}`)
       await this.fetchDoctorTypes()
     } catch (e) {
       // console.error(e)
     }
   }

  @computed get getDoctorTypes() {
     return toJS(this.doctorTypes.data)
   }
}

export default new DoctorTypes()

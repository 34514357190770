import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Select, Cascader } from 'antd'
import { initiaCardiacValues, formTemplate } from './constant'
import ToggleToken from '../../ToggleToken'
import styled from 'styled-components'
import UploadImage from './UploadImage'
import TextInput from '../../TextInput'
const Text = styled('p')`
  font-size: 18px;
  padding-right: 10px;
  font-weight: bold;
`
const FormBox = styled(Row)`
  padding: 10px;
`

const CardiacArrestForm = ({ setFormValue, initialValues, formId }) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({
    cpr: 'ไม่',
    biologicalDeath: [],
    forensicSigns: [],
    declarationOfDeath: [],
    layRescuerCpr: '',
    rosc: '',
    lastRhythm: '',
    witnessedUnwitnessedArrest: [],
    reasonToStopCpr: '',
    layRescuerCpr: '',
    mechanicalCprDevice: '',
    initialRhythm: '',
    lastRhythm: '',
    rosc: '',
    roEtiology: '',
    finalStatusAtScene: '',
    ptStatusAtEdArrival: '',
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (initialValues && initialValues.cardiacArrest) {
      setFormData((prev) => ({ ...prev, ...initialValues.cardiacArrest }))
      form.setFieldsValue(initialValues.cardiacArrest)
    }
    setLoading(false)
  }, [initialValues])

  const handleValue = (field, value) => {
    form.setFieldsValue({ [field]: value })
    const allData = form.getFieldsValue(true)
    setFormValue({ ['cardiacArrest']: allData })
  }

  const checkShowByCPR = (showByCpr, value) => {
    const selectedCpr = formData.cpr
    if (!showByCpr) {
      return true
    }
    if (selectedCpr === showByCpr) {
      return true
    }
    if (selectedCpr !== showByCpr) {
      return false
    }
  }

  const handleOptionDropdown = (items) => {
    return items.map((item, index) => (
      <Option key={index} value={item}>
        {item}
      </Option>
    ))
  }
  return loading ? (
    <></>
  ) : (
    <Form form={form} initialValues={initiaCardiacValues} name="cardiacArrest" className="additional-form-section">
      <h2>Cardiac Arrest</h2>
      {formTemplate.map((template, index) => (
        <div className="additional-form-subsection" key={index}>
          {template.header && <p className="h5">{template.header}</p>}
          {template.data.map(
            (data) =>
              checkShowByCPR(data.showByCpr, data.value) && (
                <Form.Item name={data.value}>
                  {data.type === 'upload' ? (
                    <UploadImage
                      path={`EmsImages/${formId}/CardiacArrestForm`}
                      label={data.label}
                      preview={formData[data.value]}
                      handleValue={(value) => {
                        handleValue(data.value, value)
                        setFormData((prevState) => ({ ...prevState, [data.value]: value }))
                      }}
                    />
                  ) : data.type === 'radio' ? (
                    <ToggleToken
                      toStr={true}
                      title={data.label}
                      max={1}
                      options={data.items}
                      values={formData[data.value]}
                      extraInputs={data.extraInputs ? data.extraInputs : false}
                      setValues={(value) => {
                        setFormData((prevState) => ({ ...prevState, [data.value]: value }))
                        handleValue(data.value, value)
                      }}
                    />
                  ) : data.type === 'checkbox' ? (
                    <ToggleToken
                      title={data.label}
                      options={data.items}
                      values={formData[data.value]}
                      setValues={(value) => {
                        setFormData((prevState) => ({ ...prevState, [data.value]: value }))
                        handleValue(data.value, value)
                      }}
                    />
                  ) : data.type === 'text' ? (
                    <TextInput
                      label={data.label}
                      onChange={(event) => {
                        setFormData((prevState) => ({ ...prevState, [data.value]: event.target.value }))
                        handleValue(data.value, event.target.value)
                      }}
                    />
                  ) : data.type === 'dropdown' ? (
                    <FormBox justify="start">
                      <Text>{data.label}</Text>
                      <Select showSearch allowClear optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder={data.placeholder ? data.placeholder : ''}>
                        {handleOptionDropdown(data.items ? data.items : [])}
                      </Select>
                    </FormBox>
                  ) : (
                    data.type === 'cascader' && (
                      <FormBox justify="start">
                        <Text>{data.label}</Text>
                        <Cascader
                          value={formData[data.value]}
                          placeholder={data.placeholder ? data.placeholder : ''}
                          expandTrigger="hover"
                          options={data.items}
                          onChange={(value) => {
                            setFormData((prevState) => ({ ...prevState, [data.value]: value }))
                            handleValue(data.value, value)
                          }}
                        />
                      </FormBox>
                    )
                  )}
                </Form.Item>
              ),
          )}
        </div>
      ))}
    </Form>
  )
}

export default CardiacArrestForm

import React from 'react'
import styled from 'styled-components'
import Barcode from 'react-hooks-barcode'

const ContainerSticker = styled.div`
  width: 485px;
  padding: 20px 20px 0px 20px;
  margin-top: 40px;
`

const Text = styled.p`
  font-size: 18px;
  width: 100%;
  white-space: wrap;
  text-overflow: ellipsis;
  word-break: keep-all;
`

const TextNote = styled.p`
  font-size: 16px;
  width: 100%;
  white-space: wrap;
  text-overflow: ellipsis;
  word-break: keep-all;
`

const Header = styled.p`
  font-size: 20px;
  font-weight: bold;
`

class ComponentToPrint extends React.Component {
  constructor() {
    super()
    this.state = {
      patient: null,
      trackingNumber: '',
      prescription: '',
    }
  }

  componentDidUpdate() {
    const { isSticker, setIsSticker, stickers, thaiPost } = this.props
    if (isSticker) {
      stickers.map((sticker) => {
        thaiPost(sticker.trackingNumberId.barcode)
      })
      setIsSticker(false)
    }
  }

  checkDetailAddress() {
    const { booking, pharmacyStore } = this.props
    if (pharmacyStore) {
      return <Text>{`ที่อยู่ : ร้าน ${pharmacyStore.name}, ${pharmacyStore.address}`}</Text>
    }
    if (booking && booking.prescription && booking.prescription.address) {
      return (
        <>
          <Text>{booking.prescription.address}</Text>
          <TextNote>หมายเหตุ: {booking.prescription.note || '-'}</TextNote>
        </>
      )
    }
    return <p />
  }

  render() {
    const { stickers, thaiPost, booking, trackingNumber } = this.props
    return stickers && stickers.length ? (
      stickers.map((sticker, index) => (
        <ContainerSticker key={sticker.id + index}>
          <div>
            <Header className="mb-1">กรุณาส่ง</Header>
            <Text className="mb-2">
              <b>{booking.patient && booking.patient.fullname ? booking.patient.fullname : ''}</b>
            </Text>
            <Text>เบอร์โทร : {booking.prescription && booking.prescription.mobileNumber ? booking.prescription.mobileNumber : ''}</Text>
            <div style={{ width: '445px' }}>{this.checkDetailAddress()}</div>
            <div style={{position: 'absolute',top: '70%', left: '60px', zIndex: '99'}}>
            <Text className="text-start">EMS:</Text>
            <Barcode value={sticker.trackingNumberId.barcode} height={25} />
            </div>
          </div>
        </ContainerSticker>
      ))
    ) : (
      <ContainerSticker>ไม่มีข้อมูล</ContainerSticker>
    )
  }
}
export default ComponentToPrint

import { Button } from "antd";
import React, { useRef } from "react";
import CanvasDraw from "react-canvas-draw";
import './style.css'

const index = (props) => {
    const canvasRef = useRef(null)
    const { signature, isOn, title, close } = props
    const handleDoneClicked = () => {
        const base64 = canvasRef.current.canvasContainer.childNodes[1].toDataURL();
        signature(base64)
        close(false)
    }
    const clearCanvas = () => {
        canvasRef.current.eraseAll()
    }
    return (
        <div className={`partition${isOn ? " modal-active" : ""}`} onClick={() => close(false)}>
            <div className="sheet-modal" onClick={(e) => e.stopPropagation()}>
                <h2>{title}</h2>
                <CanvasDraw
                    className="signature-area"
                    canvasWidth={400}
                    canvasHeight={240}
                    lazyRadius={2}
                    brushRadius={2}
                    hideInterface
                    hideGrid
                    ref={ canvasRef }
                />
                <div className="modal-actions">
                    <Button onClick={clearCanvas}>Clear</Button>
                    <Button type="primary" onClick={handleDoneClicked}>Done</Button>
                </div>
            </div>
        </div>
    )
}
export default index
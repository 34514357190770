import React from 'react'
import { Card, Row, Col } from 'antd'
import Avatar from '../../images/default_avatar.png'
function PatientProfileCard(props) {
  const { patient, hie } = props
  let fullname = '-'
  if (hie.fullname && hie.fullname.title) {
    const { title, firstName, lastName } = hie.fullname
    fullname = `${title} ${firstName} ${lastName}`
  }
  return (
    <Card>
      {hie.imageBase64 ? <img src={`data:image/png;base64,${hie.imageBase64}`} alt="" style={{ maxWidth: '250px' }} /> : <img src={Avatar} style={{ maxWidth: '250px' }} alt="" />}
      <Row className="mt-2">
        <Col span="8">ชื่อ: </Col>
        <Col span="16">{fullname}</Col>
        <Col span="8">HN: </Col>
        <Col span="16">{patient.hnId}</Col>
      </Row>
    </Card>
  )
}

export default PatientProfileCard

import React, { useEffect, useState, useRef } from 'react'
import { Button, Divider, Space, Form, Input, Radio, Row, Col, DatePicker, Modal, message, Select, ConfigProvider } from 'antd'
import { RightCircleOutlined, LeftCircleOutlined, SaveOutlined, PlusOutlined, ScanOutlined } from '@ant-design/icons'
import BarcodeReader from '../../components/BarcodeReader'
import { cloneDeep, isEmpty } from 'lodash'
import moment from 'moment'
import 'moment/locale/th'
import thTH from 'antd/es/locale/th_TH'
import { hooks } from './hooks'
import { STAFF_NAME } from './constant'
import { pdf } from '@react-pdf/renderer'
import IdentifyDonorSticker from '../../components/IdentifyDonorSticker'
const { Option } = Select

const DonationForm = ({ clearData, patientInfo }) => {
  const { events, params } = hooks()
  const [form] = Form.useForm()
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleManualInput, setVisibleManualInput] = useState(false)
  const [visibleRejectReason, setVisibleRejectReason] = useState(false)
  const [unitNumber, setUnitNumber] = useState('')
  const [showForm, setShowForm] = useState(true)
  const [donationPersonId, setDonationPersonId] = useState(null)
  const [formData, setFormData] = useState({})
  const ref = useRef(null)
  const [reasonsToReject, setReasonsToReject] = useState(['กินยาที่มีผลต่อเกล็ดเลือด', 'Under volumn', 'High volume', 'Discarded', 'ความดันต่ำกว่าปกติ', 'ความดันสูงกว่าปกติ', 'Hb ต่ำกว่าปกติ', 'Hb สูงกว่าปกติ'])
  const [selectedReasons, setSelectedReasons] = useState([])
  const [extendedReason, setExtendedReason] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    form.setFieldsValue({ unitNumber })
  }, [unitNumber])

  useEffect(() => {
    if (visibleModal) {
      setTimeout(() => {
        if (ref?.current) {
          ref?.current?.focus({
            cursor: 'end',
          })
        }
      }, 500)
    }
  }, [visibleModal])

  useEffect(() => {
    if (params.donorLatestForm?.bloodDonationBooking?.screenStatus === 'screenPass') {
      setShowForm(false)
    }
  }, [params.donorLatestForm?.bloodDonationBooking?.screenStatus])

  useEffect(() => {
    if (!isEmpty(params.donorLatestForm)) {
      const { donorLatestForm } = params
      setInitialFormData(donorLatestForm)
    }
  }, [params.donorLatestForm])

  const setInitialFormData = (donorLatestForm) => {
    setFormData(donorLatestForm)
    setDonationPersonId(donorLatestForm?.donationPersonId ?? donorLatestForm.cId)
    setUnitNumber(donorLatestForm?.unitNumber)
    setSelectedReasons(donorLatestForm?.reasonToReject ?? [])
    form.setFieldsValue({
      donationPersonId: donorLatestForm?.donationPersonId ?? donorLatestForm.cId,
      donatedCount: donorLatestForm.previousDonatedCount ? donorLatestForm?.previousDonatedCount + 1 : donorLatestForm?.donatedCount ?? 1,
      aboGroup: donorLatestForm?.aboGroup,
      rhGroup: donorLatestForm?.rhGroup,
      firstDonationPlace: donorLatestForm?.firstDonationPlace,
      firstDonationDate: donorLatestForm?.firstDonationDate,
      latestDonatePlace: donorLatestForm?.latestDonatePlace,
      latestDonateDate: donorLatestForm?.latestDonateDate,
      sbp: donorLatestForm?.sbp,
      dbp: donorLatestForm?.dbp,
      isBloodPressureNormal: donorLatestForm?.isBloodPressureNormal ?? true,
      pulse: donorLatestForm?.pulse,
      isPulseNormal: donorLatestForm?.isPulseNormal ?? true,
      hb: donorLatestForm?.hb,
      isHbNormal: donorLatestForm?.isHbNormal ?? true,
      unitNumber: donorLatestForm?.unitNumber,
      bagType: donorLatestForm?.donateType === 'bloodPlatelet' ? 'SDP' : donorLatestForm?.bagType ?? '450ccQB',
      registrarName: donorLatestForm?.registrarName,
      weight: donorLatestForm?.weight,
      bloodPrepareStaffName: donorLatestForm?.bloodPrepareStaffName,
      bloodNeedlerName: donorLatestForm?.bloodNeedlerName,
      collectSamplerName: donorLatestForm?.collectSamplerName,
      recorderName: donorLatestForm?.recorderName,
      screenStatus: donorLatestForm.bloodDonationBooking?.screenStatus,
    })
  }
  const handleInput = () => {
    setUnitNumber((latestUnitNumber) => {
      const isNotValidFormat = !events.validateTextFormat(latestUnitNumber, false)
      if (isNotValidFormat) {
        message.error('กรุณาเปลี่ยนภาษาคีย์บอร์ดเป็นภาษาอังกฤษ แล้วสแกนใหม่อีกครั้ง')
        return ''
      }
      setVisibleModal(false)
      return latestUnitNumber
    })
  }
  const updateDonationForm = (newValues) => {
    const currentForm = cloneDeep(params.donorLatestForm)
    const currentBooking = currentForm.bloodDonationBooking
    const formId = currentForm.id
    const bookingId = currentBooking.id

    const latestDonorFormData = {
      donationPersonId: newValues?.donationPersonId,
      donatedCount: parseInt(newValues?.donatedCount),
      aboGroup: newValues?.aboGroup,
      rhGroup: newValues?.rhGroup,
      firstDonationPlace: newValues?.firstDonationPlace,
      firstDonationDate: newValues?.firstDonationDate,
      latestDonatePlace: newValues?.latestDonatePlace,
      latestDonateDate: newValues?.latestDonateDate,
      sbp: newValues?.sbp,
      dbp: newValues?.dbp,
      isBloodPressureNormal: newValues?.isBloodPressureNormal,
      pulse: newValues?.pulse,
      isPulseNormal: newValues?.isPulseNormal,
      hb: newValues?.hb,
      isHbNormal: newValues?.isHbNormal,
      weight: newValues?.weight,
      registrarName: newValues?.registrarName,
      reasonToReject: newValues?.reasonToReject,
    }
    const latestDonorBookingData = {
      screenStatus: newValues?.screenStatus,
      donateStatus: 'waitingDonation',
    }
    const isScreenFailed = newValues?.screenStatus === 'screenFailed'
    if (isScreenFailed) {
      latestDonorBookingData.nextAvailableTime = null
    }

    events.updateFormAndBooking(formId, latestDonorFormData, bookingId, latestDonorBookingData)
    events.getPatientInfo(currentForm.cId)

    const isNeedToGoSearchPage = (currentBooking?.screenStatus === 'screenPass' && latestDonorBookingData?.donateStatus === 'donationCompleted') || newValues?.screenStatus === 'screenFailed'
    if (isNeedToGoSearchPage) {
      clearData()
    }
  }

  const updateFormSection2 = () => {
    const currentForm = cloneDeep(params.donorLatestForm)
    const currentBooking = currentForm.bloodDonationBooking
    const formId = currentForm.id
    const bookingId = currentBooking.id
    const newValues = form.getFieldsValue()
    const latestDonorFormData = {
      bloodPrepareStaffName: newValues?.bloodPrepareStaffName,
      bloodNeedlerName: newValues?.bloodNeedlerName,
      collectSamplerName: newValues?.collectSamplerName,
      recorderName: newValues?.recorderName,
      unitNumber: newValues?.unitNumber,
      bagType: newValues?.bagType,
    }

    const nextAvailableTime = handleNextAvailableTime(currentForm?.donateType, currentForm?.donationDate)

    const latestDonorBookingData = {
      screenStatus: 'screenPass',
      donateStatus: 'donationCompleted',
      nextAvailableTime,
      donateCompletedDateTime: new Date(),
    }
    events.sendNotificationNextAvailableTime(nextAvailableTime, currentBooking.appUserId)
    events.updateFormAndBooking(formId, latestDonorFormData, bookingId, latestDonorBookingData)
    // events.addToEphis({ ...currentForm, ...latestDonorFormData }, { ...currentBooking, ...latestDonorBookingData }, patientInfo)
    events.getPatientInfo(latestDonorFormData.cId)
    genPDF({ ...params.donorLatestForm, hn: events.convertHn(patientInfo?.hn[0]?.hn), donateCompletedDateTime: latestDonorBookingData?.donateCompletedDateTime })
    clearData()
  }

  const genPDF = async (data) => {
    const blob = await pdf(<IdentifyDonorSticker data={data} />).toBlob()
    const url = URL.createObjectURL(blob)
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const handleNextAvailableTime = (donateType, donationDate) => {
    if (donateType === 'bloodPlatelet') {
      return moment(donationDate)
        .set({ hour: 0, minute: 0, second: 0 })
        .add(21, 'days')
    } else if (donateType === 'blood') {
      return moment(donationDate)
        .set({ hour: 0, minute: 0, second: 0 })
        .add(events.calculateNextAvailableTime(patientInfo?.DOB), 'months')
        .subtract(6, 'days')
    }
    return null
  }

  const addItem = (e) => {
    e.preventDefault()
    setReasonsToReject([...reasonsToReject, extendedReason])
    setExtendedReason('')
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  return (
    <>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        initialValues={{
          unitNumber: null,
          screenStatus: null,
          bloodPrepareStaffName: null,
          bloodNeedlerName: null,
          bagType: null,
          collectSamplerName: null,
          recorderName: null,
          isBloodPressureNormal: true,
          isPulseNormal: true,
          isHeartLungNormal: true,
          isHbNormal: true,
        }}
        form={form}
        onFinish={updateDonationForm}
      >
        <Row className="w-100 py-3 donationFormBox" justify="end" gutter={[16, 16]}>
          {params.donorLatestForm?.bloodDonationBooking?.screenStatus === 'screenPass' && !showForm ? (
            <>
              <Button onClick={() => setShowForm(true)} className="mt-2 mb-4 d-flex align-items-center justify-content-center" icon={<LeftCircleOutlined />}>
                ย้อนกลับไปดูแบบฟอร์ม
              </Button>
              <Col xs={24} md={24}>
                <Form.Item label="Unit Number" name="unitNumber" wrapperCol={{ span: 18 }}>
                  <Input
                    onClick={() => {
                      setVisibleModal(true)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'กรุณาระบุชนิดถุงเลือด',
                    },
                  ]}
                  label="ชนิดถุงเลือด"
                  name="bagType"
                >
                  <Select className="w-100">
                    <Option value="450ccQB">450 cc QB</Option>
                    <Option value="350ccDB">350 cc DB</Option>
                    <Option value="SDP">SDP</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="ชื่อเจ้าหน้าที่เตรียมถุง" name="bloodPrepareStaffName">
                  <Select className="w-100">
                    {STAFF_NAME.map((item, index) => (
                      <Option key={index} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="ชื่อเจ้าหน้าที่ผู้เจาะเก็บ" name="bloodNeedlerName">
                  <Select className="w-100">
                    {STAFF_NAME.map((item, index) => (
                      <Option key={index} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="ชื่อเจ้าหน้าที่เก็บตัวอย่างเลือด" name="collectSamplerName">
                  <Select className="w-100">
                    {STAFF_NAME.map((item, index) => (
                      <Option key={index} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="ชื่อเจ้าหน้าที่บันทึกข้อมูล/ผู้ตรวจสอบ" name="recorderName">
                  <Select className="w-100">
                    {STAFF_NAME.map((item, index) => (
                      <Option key={index} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Button type="primary" size="large" block onClick={() => updateFormSection2()} className="my-3">
                บันทึกข้อมูลแบบฟอร์ม
              </Button>
            </>
          ) : (
            showForm && (
              <>
                {params.donorLatestForm?.bloodDonationBooking?.screenStatus === 'screenPass' && (
                  <Button onClick={() => setShowForm(false)} className="mt-2 mb-4 d-flex align-items-center justify-content-center" icon={<RightCircleOutlined />}>
                    กลับไปกรอก Unit Number
                  </Button>
                )}
                <Col xs={24} md={24}>
                  <Form.Item label="เลขประจำตัวผู้บริจาคเลือด/เลขบัตรประชาชน" name="donationPersonId">
                    <Input onChange={(e) => setDonationPersonId(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="บริจาคครั้งที่" name="donatedCount">
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'กรุณากรอกหมู่เลือด',
                      },
                    ]}
                    label="หมู่เลือด ABO"
                    name="aboGroup"
                  >
                    <Select className="w-100">
                      <Option value="A">A</Option>
                      <Option value="B">B</Option>
                      <Option value="O">O</Option>
                      <Option value="AB">AB</Option>
                      <Option value="ไม่ระบุ">ไม่ระบุ</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'กรุณากรอกค่า Rh',
                      },
                    ]}
                    label="หมู่เลือด Rh"
                    wrapperCol={{ span: 10 }}
                    name="rhGroup"
                  >
                    <Select className="w-100">
                      <Option value="+">+</Option>
                      <Option value="-">-</Option>
                      <Option value="ไม่ระบุ">ไม่ระบุ</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="น้ำหนัก" name="weight">
                    <Input />
                  </Form.Item>
                </Col>
                {!donationPersonId?.length && (
                  <>
                    <Col xs={24} md={12}>
                      <Form.Item label="บริจาคครั้งแรกเมื่อ" name="firstDonationDate">
                        <ConfigProvider locale={thTH}>
                          <DatePicker
                            className="w-100"
                            placeholder="เลือกวันที่"
                            value={formData['firstDonationDate'] ? moment(formData['firstDonationDate']) : null}
                            onChange={(date) => {
                              if (date) {
                                setFormData((prev) => ({ ...prev, firstDonationDate: date }))
                                form.setFieldsValue({ firstDonationDate: date })
                              } else {
                                setFormData((prev) => ({ ...prev, firstDonationDate: null }))
                                form.setFieldsValue({ firstDonationDate: null })
                              }
                            }}
                            format={(value) =>
                              moment(value)
                                .add(543, 'years')
                                .format('LL')
                            }
                          />
                        </ConfigProvider>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item label="สถานที่บริจาค" name="firstDonationPlace">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item label="บริจาคครั้งสุดท้ายเมื่อ" name="latestDonateDate">
                        <ConfigProvider locale={thTH}>
                          <DatePicker
                            className="w-100"
                            placeholder="เลือกวันที่"
                            value={formData['latestDonateDate'] ? moment(formData['latestDonateDate']) : null}
                            onChange={(date) => {
                              if (date) {
                                setFormData((prev) => ({ ...prev, latestDonateDate: date }))
                                form.setFieldsValue({ latestDonateDate: date })
                              } else {
                                setFormData((prev) => ({ ...prev, latestDonateDate: null }))
                                form.setFieldsValue({ latestDonateDate: null })
                              }
                            }}
                            format={(value) =>
                              moment(value)
                                .add(543, 'years')
                                .format('LL')
                            }
                          />
                        </ConfigProvider>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item label="สถานที่บริจาค" name="latestDonatePlace">
                        <Input />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col xs={24} md={12}>
                  <Row justify="space-between" align="bottom">
                    <Col xs={24} md={11}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'กรุณากรอกความดันเลือด SBP',
                          },
                        ]}
                        label="ความดันเลือด SBP"
                        name="sbp"
                      >
                        <Input style={{ width: '95%' }} />
                      </Form.Item>
                    </Col>
                    <Col xs={0} md={1} style={{ marginBottom: '24px', fontSize: '20px' }}>
                      /
                    </Col>
                    <Col xs={24} md={11}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'กรุณากรอกความดันเลือด DBP',
                          },
                        ]}
                        label="ความดันเลือด DBP"
                        name="dbp"
                      >
                        <Input style={{ width: '95%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="ความดันเลือดปกติหรือไม่" name="isBloodPressureNormal">
                    <Radio.Group>
                      <Radio.Button value={true}>ปกติ</Radio.Button>
                      <Radio.Button value={false}>ไม่ปกติ</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'กรุณากรอกค่าชีพจร',
                      },
                    ]}
                    label="ชีพจร"
                    name="pulse"
                  >
                    <Input addonAfter="ครั้ง/นาที" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="ชีพจรปกติหรือไม่" name="isPulseNormal">
                    <Radio.Group>
                      <Radio.Button value={true}>ปกติ</Radio.Button>
                      <Radio.Button value={false}>ไม่ปกติ</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'กรุณากรอกค่า Hb',
                      },
                    ]}
                    label="Hb"
                    name="hb"
                  >
                    <Input addonAfter="ก/ดล." />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Hb ปกติหรือไม่" name="isHbNormal">
                    <Radio.Group>
                      <Radio.Button value={true}>ปกติ</Radio.Button>
                      <Radio.Button value={false}>ไม่ปกติ</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="เจ้าหน้าที่ผู้ลงทะเบียน" name="registrarName">
                    <Select className="w-100">
                      {STAFF_NAME.map((item, index) => (
                        <Option key={index} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Form.Item name="reasonToReject" className="m-0"></Form.Item>
                <Col xs={24} md={24}>
                  <Form.Item wrapperCol={{ span: 24 }} name="screenStatus">
                    <Button type="primary" size="large" block htmlType="submit" className="my-3" onClick={() => form.setFieldsValue({ screenStatus: 'screenPass' })}>
                      ผ่านการคัดกรอง
                    </Button>
                    <Button type="danger" size="large" block className="my-3" onClick={() => setVisibleRejectReason(true)}>
                      ไม่ผ่านการคัดกรอง
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )
          )}
        </Row>
      </Form>
      <Modal
        visible={visibleModal}
        destroyOnClose={true}
        onCancel={() => setVisibleModal(false)}
        footer={
          <Button type="primary" onClick={() => setVisibleManualInput(!visibleManualInput)} className="my-3 d-flex align-items-center" icon={visibleManualInput ? <LeftCircleOutlined /> : <RightCircleOutlined />}>
            {visibleManualInput ? `เครื่องสแกนบาร์โค้ด` : `กล้องสแกนบาร์โค้ด`}
          </Button>
        }
      >
        <Row justify="center">
          {visibleManualInput && visibleModal && (
            <Col span={24} className="my-4">
              <BarcodeReader
                data={unitNumber}
                setData={(value) => {
                  setUnitNumber(value)
                  if (value) {
                    setVisibleModal(false)
                  }
                }}
              />
            </Col>
          )}
          {!visibleManualInput && visibleModal && (
            <>
              <p className="h5 text-center d-flex align-items-center">
                <ScanOutlined className="mr-1" />
                สแกนบาร์โค้ด
              </p>
              <Input
                ref={ref}
                addonBefore="Unit Number"
                onChange={(event) => {
                  setUnitNumber(event.target.value)
                }}
                value={unitNumber}
                onPressEnter={() => {
                  setTimeout(() => handleInput(), 600)
                }}
                className="mb-5 mt-1"
              />
              <Button
                onClick={() => {
                  setTimeout(() => handleInput(), 600)
                }}
                type="primary"
                size="large"
                className="my-3 d-flex align-items-center"
                icon={<SaveOutlined />}
              >{`บันทึก Unit Number และ ปิดหน้าต่าง(กรณีกรอกค่าด้วยตนเอง)`}</Button>
            </>
          )}
        </Row>
      </Modal>
      <Modal
        title="เลือกเหตุผลที่ไม่ผ่านการคัดกรอง"
        visible={visibleRejectReason}
        onCancel={() => setVisibleRejectReason(false)}
        onOk={() => {
          form.setFieldsValue({ screenStatus: 'screenFailed', reasonToReject: selectedReasons })
          updateDonationForm(form.getFieldsValue())
        }}
        cancelText="ยกเลิกการส่งข้อมูล"
        okText="ส่งข้อมูลไม่ผ่านการคัดกรอง"
        okButtonProps={{ type: 'danger' }}
      >
        <Select
          className="w-100"
          mode="multiple"
          size="large"
          placeholder="เลือกได้หลายข้อ"
          defaultValue={selectedReasons}
          onChange={(val) => setSelectedReasons(val)}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: '8px 0',
                }}
              />
              <Space
                style={{
                  padding: '0 8px 4px',
                }}
              >
                <Input placeholder="Ex.Deferred due to ..." ref={inputRef} value={extendedReason} onChange={(e) => setExtendedReason(e.target.value)} />
                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                  เพิ่มเติมเลือก
                </Button>
              </Space>
            </>
          )}
        >
          {reasonsToReject.map((item, index) => (
            <Option key={index} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  )
}

export default DonationForm

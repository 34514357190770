import React, { useEffect, useState } from 'react'
import { Popconfirm, Card, Row, Col, Switch, DatePicker, Input, Form, Alert } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import Button from '../Button'
import { last } from 'lodash-es'
const CardYearly = styled(Card)`
  .ant-card-head {
    background-color: #1b4b63;
    color: #ffffff;
  }
  .ant-card-body {
    padding: 0px;
  }
`

function YearlyAssessmentModal({ patient, updateYearlyAssesmentData }) {
  const [form] = Form.useForm()
  const [patientData, setPatientData] = useState({})
  const [formValues, setFormValues] = useState({})

  useEffect(() => {
    setPatientData(patient)
    if (patient.yearlyAssessment) {
      setFormYearlyAssessment()
    }
  }, [patient, patient.yearlyAssessment])
  const setFormYearlyAssessment = () => {
    const assessmentData = patient.yearlyAssessment
    const peripheralArterialDisease = assessmentData.peripheralArterialDisease
    const diabeticRetinopathy = assessmentData.diabeticRetinopathy
    const diabeticNeuropathy = assessmentData.diabeticNeuropathy
    const formData = {
      ABIRight: peripheralArterialDisease.ABIRight,
      ABILeft: peripheralArterialDisease.ABILeft,
      cataractRight: diabeticRetinopathy.cataractRight,
      cataractLeft: diabeticRetinopathy.cataractLeft,
      BDRRight: diabeticRetinopathy.BDRRight,
      BDRLeft: diabeticRetinopathy.BDRLeft,
      PDRRight: diabeticRetinopathy.PDRRight,
      PDRLeft: diabeticRetinopathy.PDRLeft,
      LOPSRight: diabeticNeuropathy.LOPSRight,
      LOPSLeft: diabeticNeuropathy.LOPSLeft
    }
    form.setFieldsValue(formData)
    setFormValues(formData)
  }
  const submitYearlyAssessment = async (val) => {
    const data = {
      peripheralArterialDisease: {
        ABIRight: val.ABIRight,
        ABILeft: val.ABILeft
      },
      diabeticRetinopathy: {
        cataractRight: val.cataractRight,
        cataractLeft: val.cataractLeft,
        BDRRight: val.BDRRight,
        BDRLeft: val.BDRLeft,
        PDRRight: val.PDRRight,
        PDRLeft: val.PDRLeft
      },
      diabeticNeuropathy: {
        LOPSRight: val.LOPSRight,
        LOPSLeft: val.LOPSLeft
      },
      assessmentDate: moment(val.currentDateAssessment).toISOString()
    }
    await updateYearlyAssesmentData(data)
  }
  const cards = [
    {
      label: 'Yearly Assessment',
      value: [
        {
          label: 'Current Date of Yearly Assessment',
          value: 'currentDateAssessment',
          disabled: false,
          type: 'date'
        }
      ]
    },
    {
      label: 'Peripheral Arterial Disease',
      value: [
        {
          label: 'ABI Right',
          value: 'ABIRight',
          disabled: false,
          type: 'input'
        },
        {
          label: 'ABI Left',
          value: 'ABILeft',
          disabled: false,
          type: 'input'
        }
      ]
    },
    {
      label: 'Diabetic Retinopathy',
      value: [
        {
          label: 'Cataract Right',
          value: 'cataractRight',
          disabled: false,
          type: 'switch'
        },
        {
          label: 'Cataract Left',
          value: 'cataractLeft',
          disabled: false,
          type: 'switch'
        },
        {
          label: 'BDR Right',
          value: 'BDRRight',
          disabled: false,
          type: 'switch'
        },
        {
          label: 'BDR Left',
          value: 'BDRLeft',
          disabled: false,
          type: 'switch'
        },
        {
          label: 'PDR Right',
          value: 'PDRRight',
          disabled: false,
          type: 'switch'
        },
        {
          label: 'PDR Left',
          value: 'PDRLeft',
          disabled: false,
          type: 'switch'
        }
      ]
    },
    {
      label: 'Diabetic Neuropathy',
      value: [
        {
          label: 'LOPS Right',
          value: 'LOPSRight',
          disabled: false,
          type: 'switch'
        },
        {
          label: 'LOPS Left',
          value: 'LOPSLeft',
          disabled: false,
          type: 'switch'
        }
      ]
    }
  ]

  const initial = {
    currentDateAssessment: moment(),
    ABIRight: '0',
    ABILeft: '0',
    cataractRight: null,
    cataractLeft: null,
    BDRRight: null,
    BDRLeft: null,
    PDRRight: null,
    PDRLeft: null,
    LOPSRight: null,
    LOPSLeft: null
  }

  const yearlyAssessmentAlert = () => {
    const MESSAGES = {
      threeMonthBirthDay: { label: 'อีก 3 เดือนจะถึงเดือนเกิดคนไข้ แนะนำให้ทำการนัดผู้ป่วยเพื่อทำ Yearly Assessment', type: 'warning' },
      overYear: { label: 'ผู้ป่วยทำ Yearly Assessment ครั้งล่าสุด เกิน 1 ปีแล้ว แนะนำให้ทำการนัดผู้ป่วย', type: 'error' }
    }
    const birthMonth = moment(patient.birthDate).set({ year: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
    const currentMonth = moment().set({ year: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
    const currentDate = moment().toISOString()
    const lastDate = patient && patient.yearlyAssessment ? moment(patient.yearlyAssessment.assessmentDate).toISOString() : moment().toISOString()

    const checkThreeMonthBirthDay = moment(currentMonth).diff(birthMonth, 'months', true)
    const checkOverYear = moment(currentDate).diff(moment(lastDate), 'years', true)

    if (checkThreeMonthBirthDay - 12 === -3) {
      return <Alert message={MESSAGES['threeMonthBirthDay'].label} type={MESSAGES['threeMonthBirthDay'].type} />
    }
    if (checkOverYear >= 1) {
      return <Alert message={MESSAGES['overYear'].label} type={MESSAGES['overYear'].type} />
    }
  }

  const resetForm = () => {
    if (patient.yearlyAssessment) {
      location.reload()
    } else {
      form.resetFields()
    }
  }

  return (
    <Form form={form} initialValues={initial} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} name="form" style={{ maxWidth: '100%' }} onFinish={submitYearlyAssessment}>
      {cards.map((card, index) => (
        <CardYearly title={card.label} key={index}>
          <Row justify="start" className="p-3">
            {card.value.map((formItem, itemIndex) =>
              card.label === 'Yearly Assessment' ? (
                <Row gutter={[0, 24]}>
                  <Col span={24}>{yearlyAssessmentAlert()}</Col>
                  <Col span={12}>Birth Month</Col>
                  <Col span={12}>{moment(patientData && patientData.birthDate).format('MMMM')}</Col>
                  <Col span={12}>Last Date of Yearly Assessment</Col>
                  <Col span={12}>{patientData && patientData.yearlyAssessment && moment(patientData.yearlyAssessment.assessmentDate).format('DD/MM/YYYY')}</Col>
                  <Col span={24}>
                    <Form.Item label={formItem.label} name={formItem.value} key={itemIndex} labelAlign="left">
                      <DatePicker format="DD/MM/YYYY" disabled={formItem.disabled} />
                    </Form.Item>
                  </Col>
                </Row>
              ) : formItem.type === 'input' ? (
                <Col span={24}>
                  <Form.Item label={formItem.label} name={formItem.value} key={itemIndex} labelAlign="left">
                    <Input style={{ width: '100%' }} disabled={formItem.disabled} />
                  </Form.Item>
                </Col>
              ) : (
                formItem.type === 'switch' && (
                  <>
                    {formValues && formValues[formItem.value] && (
                      <Col span={24}>
                        <Form.Item label={formItem.label} name={formItem.value} key={itemIndex} labelAlign="left">
                          <Switch defaultChecked={formValues[formItem.value] || false} checkedChildren="ใช่" unCheckedChildren="ไม่" />
                        </Form.Item>
                      </Col>
                    )}
                    {!formValues ||
                      (!formValues[formItem.value] && (
                        <Col span={24}>
                          <Form.Item label={formItem.label} name={formItem.value} key={itemIndex} labelAlign="left">
                            <Switch checkedChildren="ใช่" unCheckedChildren="ไม่" />
                          </Form.Item>
                        </Col>
                      ))}
                  </>
                )
              )
            )}
          </Row>
        </CardYearly>
      ))}
      <CardYearly>
        <Row justify="end">
          <Col style={{ padding: '15px 0px 15px 15px', display: 'flex', justifyContent: 'flex-end' }}>
            <Form.Item style={{ margin: '0px', width: '100%' }}>
              <Popconfirm title="กรุณายืนยันการบันทึกผลการตรวจ" onConfirm={() => form.submit()} okText="ยืนยัน" cancelText="ยกเลิก">
                <Button type="primary">บันทึกผลการตรวจ</Button>
              </Popconfirm>
            </Form.Item>
          </Col>
          <Col style={{ padding: '15px', display: 'flex', justifyContent: 'flex-end' }}>
            <Form.Item style={{ margin: '0px', width: '100%' }}>
              <Button type="primary" onClick={() => resetForm()} danger>
                ยกเลิก
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </CardYearly>
    </Form>
  )
}

export default YearlyAssessmentModal

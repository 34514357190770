import styled from 'styled-components'
import TextBase from '../../components/Text'
import { Input, Row, Col } from 'antd'

const { Search: SearchAnt } = Input

export const Container = styled.div`
  width: 100%;
  padding: 20px;
`
export const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`
export const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
export const Text = styled(TextBase)`
  font-weight: bold;
`
export const EditorBox = styled(Row)`
  padding: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  background: #fff;
  min-width: 100px;
  position: relative;
  border-radius: 12px;
  // border: 2px solid #1b4b63;
  background-image: repeating-linear-gradient(60deg, ${(props) => props.borderColor ?? '#dbdbdb'}, ${(props) => props.borderColor ?? '#dbdbdb'} 5px, transparent 5px, transparent 9px, ${(props) => props.borderColor ?? '#dbdbdb'} 9px),
    repeating-linear-gradient(150deg, ${(props) => props.borderColor ?? '#dbdbdb'}, ${(props) => props.borderColor ?? '#dbdbdb'} 5px, transparent 5px, transparent 9px, ${(props) => props.borderColor ?? '#dbdbdb'} 9px),
    repeating-linear-gradient(240deg, ${(props) => props.borderColor ?? '#dbdbdb'}, ${(props) => props.borderColor ?? '#dbdbdb'} 5px, transparent 5px, transparent 9px, ${(props) => props.borderColor ?? '#dbdbdb'} 9px),
    repeating-linear-gradient(330deg, ${(props) => props.borderColor ?? '#dbdbdb'}, ${(props) => props.borderColor ?? '#dbdbdb'} 5px, transparent 5px, transparent 9px, ${(props) => props.borderColor ?? '#dbdbdb'} 9px);
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
`

export const ColFlexCenter = styled(Col)`
  display: flex;
  justify-content: center;
`

export const FloatTitleBox = styled(`div`)`
  position: absolute;
  top: -10px;
  left: -2px;
`
export const FloatTitleText = styled(`div`)`
  background: ${(props) => props.backgroundColor ?? '#45c0bb'};
  padding: 0px 24px 0px 12px;
  color: ${(props) => (props.titleColor === 'dark' ? '#212121' : '#fff')};
  border-radius: 0px 20px 20px 0px;
  border-top: 2px solid #1b4b63;
  border-bottom: 2px solid #1b4b63;
  border-left: 2px solid #1b4b63;
  border-color: ${(props) => props.borderColor ?? '#1b4b63'};
  font-weight: bold;
`

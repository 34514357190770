/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react'
import Layout from '../Home'
import { observer, inject } from 'mobx-react'
import { Tabs } from 'antd'
import SurveysChart from '../../components/SurveysChart'
import { message } from 'antd'
import BookingPrescriptionChart from './BookingPrescriptionChart'
import StatisticTab from './StatisticTab'
import jwtDecode from 'jwt-decode'
import ChatbotHistoryTable from './ChatbotHistoryTable'
import ReferStatistic from './ReferStatistic'
import ReportEmsTab from './ReportEmsTab'
import ReportSmbgTab from './ReportSmbgTab'
import ReportHbpmTab from './ReportHbpmTab'
import ReportRegistration from './ReportRegistration'
import ReportTelemedicine from './ReportTelemedicine'
import ReportSubmission from './ReportSubmission'
import UsersByAreaTAb from './UsersByAreaTab'
import moment from 'moment'
const { TabPane } = Tabs

@inject('SurveyForm')
@inject('Booking')
@inject('User')
@inject('DashboardData')
@inject('AppUserIdentity')
@inject('UserInfo')
@inject('Report')
@inject('MonitoringReport')
@inject('Submission')
@inject('Survey')
@observer
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userArea: {},
      isLoading: true,
      surveyFormsData: [],
      bookingPrescriptions: [],
      doctorTypes: [],
      otherTypes: [],
      doctorList: [],
      statisticsData: [],
      referData: [],
      reportEmsData: [],
      reportSmbgData: [],
      reportHbpmData: [],
      roles: jwtDecode(localStorage.getItem('token')).roles,
      user: {
        data: [],
        isLoading: true
      },
      submission: {
        data: [],
        isLoading: true
      },
      survey: {
        data: [],
        isLoading: true
      }
    }
  }
  componentDidMount() {
    this.fetchSurveyFormsData()
    this.fetchBookingPrescriptions()
    this.fetchDoctorTypes()
    this.fetchStatisticsData()
    this.fetchUserList()
    this.fetchSubmissionAndSurvey()
  }

  calculatePercentageIncrease(currentMonth, previousTotal) {
    if (typeof currentMonth !== 'number' || typeof previousTotal !== 'number') {
      return 0; // If either value is undefined or not a number, return 0
    }
    if (previousTotal === 0) {
      return currentMonth > 0 ? 100 : 0; // If there was no previous total, any current value represents a 100% increase
    }
    return (currentMonth / previousTotal) * 100;
  }



  fetchUserList = async (startDate, endDate) => {
    const { UserInfo } = this.props;
    const response = await UserInfo.fetchUserInfos();

    if (response) {
      const filterResponse = response.filter(
        (item) => !item.kycProblem || !item.kycProblem.status
      ) || [];

      const userArea = {
        allArea: { users: [], count: 0, percentage: 0, monthlyIncrease: 0, monthlyCount: 0 },
        otherArea: { users: [], count: 0, percentage: 0, monthlyIncrease: 0, monthlyCount: 0 },
        areaByFilterDusit: { users: [], count: 0, percentage: 0, monthlyIncrease: 0, monthlyCount: 0 },
        areaByFilterBangsue: { users: [], count: 0, percentage: 0, monthlyIncrease: 0, monthlyCount: 0 },
        areaByFilterBangphlat: { users: [], count: 0, percentage: 0, monthlyIncrease: 0, monthlyCount: 0 },
        areaByFilterPhranakhon: { users: [], count: 0, percentage: 0, monthlyIncrease: 0, monthlyCount: 0 },
      };

      const areaCounts = {
        currentRange: { dusit: 0, bangsue: 0, bangphlat: 0, phranakhon: 0, other: 0 },
        previousTotal: { dusit: 0, bangsue: 0, bangphlat: 0, phranakhon: 0, other: 0 },
      };

      filterResponse.forEach((user) => {
        const userDate = moment(user.created_at);

        // Check if the user is in the selected range
        if (userDate.isBetween(startDate, endDate, null, '[]')) {
          if (user?.address?.area && ["ดุสิต", "บางซื่อ", "บางพลัด", "พระนคร"].includes(user.address.area)) {
            userArea.allArea.count += 1;
            userArea.allArea.users.push(user);

            // Increment for the selected date range
            if (user.address.area === "ดุสิต") {
              userArea.areaByFilterDusit.count += 1;
              areaCounts.currentRange.dusit += 1;
            } else if (user.address.area === "บางซื่อ") {
              userArea.areaByFilterBangsue.count += 1;
              areaCounts.currentRange.bangsue += 1;
            } else if (user.address.area === "บางพลัด") {
              userArea.areaByFilterBangphlat.count += 1;
              areaCounts.currentRange.bangphlat += 1;
            } else if (user.address.area === "พระนคร") {
              userArea.areaByFilterPhranakhon.count += 1;
              areaCounts.currentRange.phranakhon += 1;
            }
          } else {
            userArea.otherArea.count += 1;
            userArea.otherArea.users.push(user);
            areaCounts.currentRange.other += 1;
          }
        } else {
          // Increment for users outside the selected range (previous total)
          if (user?.address?.area && ["ดุสิต", "บางซื่อ", "บางพลัด", "พระนคร"].includes(user.address.area)) {
            if (user.address.area === "ดุสิต") {
              areaCounts.previousTotal.dusit += 1;
            } else if (user.address.area === "บางซื่อ") {
              areaCounts.previousTotal.bangsue += 1;
            } else if (user.address.area === "บางพลัด") {
              areaCounts.previousTotal.bangphlat += 1;
            } else if (user.address.area === "พระนคร") {
              areaCounts.previousTotal.phranakhon += 1;
            }
          } else {
            areaCounts.previousTotal.other += 1;
          }
        }
      });

      const totalUsers = filterResponse.length;
      userArea.allArea.percentage = parseFloat((userArea.allArea.count / totalUsers) * 100).toFixed(2);
      userArea.allArea.monthlyCount = userArea.allArea.count;

      userArea.areaByFilterDusit.percentage = parseFloat((userArea.areaByFilterDusit.count / totalUsers) * 100).toFixed(2);
      userArea.areaByFilterDusit.monthlyCount = areaCounts.currentRange.dusit;

      userArea.areaByFilterBangsue.percentage = parseFloat((userArea.areaByFilterBangsue.count / totalUsers) * 100).toFixed(2);
      userArea.areaByFilterBangsue.monthlyCount = areaCounts.currentRange.bangsue;

      userArea.areaByFilterBangphlat.percentage = parseFloat((userArea.areaByFilterBangphlat.count / totalUsers) * 100).toFixed(2);
      userArea.areaByFilterBangphlat.monthlyCount = areaCounts.currentRange.bangphlat;

      userArea.areaByFilterPhranakhon.percentage = parseFloat((userArea.areaByFilterPhranakhon.count / totalUsers) * 100).toFixed(2);
      userArea.areaByFilterPhranakhon.monthlyCount = areaCounts.currentRange.phranakhon;

      userArea.otherArea.percentage = parseFloat((userArea.otherArea.count / totalUsers) * 100).toFixed(2);
      userArea.otherArea.monthlyCount = areaCounts.currentRange.other;

      // Calculate monthly increase as a percentage of the previous total
      userArea.areaByFilterDusit.monthlyIncrease = this.calculatePercentageIncrease(
        areaCounts.currentRange.dusit, areaCounts.previousTotal.dusit
      );
      userArea.areaByFilterBangsue.monthlyIncrease = this.calculatePercentageIncrease(
        areaCounts.currentRange.bangsue, areaCounts.previousTotal.bangsue
      );
      userArea.areaByFilterBangphlat.monthlyIncrease = this.calculatePercentageIncrease(
        areaCounts.currentRange.bangphlat, areaCounts.previousTotal.bangphlat
      );
      userArea.areaByFilterPhranakhon.monthlyIncrease = this.calculatePercentageIncrease(
        areaCounts.currentRange.phranakhon, areaCounts.previousTotal.phranakhon
      );
      userArea.otherArea.monthlyIncrease = this.calculatePercentageIncrease(
        areaCounts.currentRange.other, areaCounts.previousTotal.other
      );

      this.setState({
        user: {
          data: filterResponse,
          isLoading: false,
        },
        userArea: userArea,
      });
    } else {
      message.error('เกิดข้อผิดพลาดในการเชื่อมต่อข้อมูลประวัตืการแชทกับบอท');
    }
  }



  async fetchSubmissionAndSurvey() {
    const { Submission, Survey } = this.props
    const responseSubmission = await Submission.fetchSubmissions()

    if (responseSubmission) {
      this.setState({
        submission: {
          data: responseSubmission,
          isLoading: false
        }
      })
    }
    const responseSurvey = await Survey.fetchSurveys()
    if (responseSurvey) {
      this.setState({
        survey: {
          data: responseSurvey,
          isLoading: false
        }
      })
    }
  }



  async fetchBookingPrescriptions() {
    const { Booking } = this.props
    const response = await Booking.fetchBookingFilterByPrescription()
    if (response) {
      this.setState({
        bookingPrescriptions: response,
        isLoading: false,
      })
    } else {
      message.error('เกิดข้อผิดพลาดในการเชื่อมต่อข้อมูลรายการนัดหมาย')
    }
  }

  async fetchDoctorTypes() {
    const { User } = this.props
    await User.fetchDoctorTypes()
    await User.fetchOtherTypes()
    const types = User.getDoctorTypes
    const otherTypesData = User.getOtherTypes
    this.setState({
      doctorTypes: types,
      otherTypes: otherTypesData,
      isLoading: false,
    })
  }
  async fetchDoctorTypeUsers(doctorTypeId) {
    const { User } = this.props
    const response = await User.fetchDoctorTypeUsers(doctorTypeId)
    if (response) {
      return response
    } else {
      message.error('เกิดข้อผิดพลาดในการเชื่อมต่อข้อมูลแผนก')
    }
  }

  async fetchSurveyFormsData() {
    const { SurveyForm, Booking } = this.props
    const response = await SurveyForm.fetchSurveyFormsData()
    // BOOKING WITH NPS SCORE BY MONTH
    // const bookings = await Booking.fetchBooking()
    // const npsByMonth = {};

    // // Loop through all months from January (0) to December (11)
    // for (let month = 0; month < 12; month++) {
    //   let promoters = 0;
    //   let detractors = 0;

    //   response.forEach((res) => {
    //     if (res && res.bookingId) {
    //       const booking = bookings.find((booking) => booking.id === res.bookingId);
    //       if (res.createdAt || (booking && (booking.timestamp || booking.admitTime))) {
    //         const date = res.createdAt || booking.admitTime || booking.timestamp
    //         const bookingTimestamp = new Date(date);
    //         if (
    //           bookingTimestamp.getFullYear() === 2023 && // Check if the year is 2023
    //           bookingTimestamp.getMonth() === month && // Check the current month
    //           res.detail.length > 0
    //         ) {
    //           const recommend = parseInt(res.detail[0].recommendApp || 0);


    //           if (recommend >= 9 && recommend <= 10) {
    //             promoters++;
    //           } else if (recommend >= 0 && recommend <= 6) {
    //             detractors++;
    //           }
    //         }
    //       }
    //     }
    //   });

    //   // Calculate NPS for the current month
    //   const totalResponses = promoters + detractors;
    //   const nps = ((promoters - detractors) / totalResponses) * 100;

    //   // Store the NPS score for the current month
    //   npsByMonth[month] = `เดือน ${month + 1}, คะแนน 9-10: ${promoters} คน, คะแนน 0-6: ${detractors} คน, NPS Score = ((${promoters}-${detractors})/${totalResponses})*100 = ${nps}`;
    // }

    // console.log('NPS scores by month:', npsByMonth);
    this.setState({
      surveyFormsData: response || [],
      isLoading: false,
    })
  }


  async fetchStatisticsData() {
    const { DashboardData, UserInfo } = this.props
    const response = await DashboardData.fetchDashboard()
    if (response && response.length) {
      const responseUser = response.filter((item) => item.user?.roles[0]?.name === 'user')
      this.setState({
        statisticsData: responseUser,
      })
    }
    this.setState({
      isLoading: false,
    })
  }

  fetchAppUserIdentity = async (id) => {
    const { AppUserIdentity } = this.props
    const response = await AppUserIdentity.fetchAppUserIdentity(id)
    if (response && response.length) {
      return response[response.length - 1].everOmaId
    }
    return false
  }

  fetchReports = async (startDateTime, endDateTime, referType) => {
    this.setState({
      isLoading: true,
    })
    const { Report } = this.props
    const response = await Report.fetchReports(startDateTime, endDateTime, referType)
    this.setState({
      referData: response || [],
      isLoading: false,
    })
  }

  fetchEmsReports = async (startDateTime, endDateTime) => {
    this.setState({
      isLoading: true,
    })
    const { Report } = this.props
    const response = await Report.fetchEmsReports(startDateTime, endDateTime)
    const filterData = response.filter((item) => item.district !== 'Unknown')
    this.setState({
      reportEmsData: filterData || [],
      isLoading: false,
    })
  }

  fetchSmbgReports = async (page, limit) => {
    this.setState({
      isLoading: true,
    })
    const { MonitoringReport } = this.props
    const response = await MonitoringReport.fetchSmbgReports(page, limit)
    this.setState({
      reportSmbgData: response || [],
      isLoading: false,
    })
  }
  fetchHbpmReports = async (page, limit) => {
    this.setState({
      isLoading: true,
    })
    const { MonitoringReport } = this.props
    const response = await MonitoringReport.fetchHbpmReports(page, limit)
    this.setState({
      reportHbpmData: response || [],
      isLoading: false,
    })
  }

  render() {
    return (
      <Layout
        className="h-100"
        {...this.props}
        render={(
          props, //eslint-disable-line
        ) => (
          <div className="w-100 p-4 h-100">
            <h3 className="h3" style={{ fontWeight: 'bold' }}>
              ระบบบริหารข้อมูล
            </h3>
            <Tabs defaultActiveKey="1" >
              {this.state.roles.includes('callCenter') && (
                <TabPane tab="แบบสอบถาม" key="1">
                  <SurveysChart className="w-100" dataSource={this.state.surveyFormsData} isLoading={this.state.isLoading} props={props}></SurveysChart>
                </TabPane>
              )}
              <TabPane tab="ข้อมูลการจัดส่งยา" key="2">
                <BookingPrescriptionChart
                  className="w-100"
                  dataSource={this.state.bookingPrescriptions}
                  isLoading={this.state.isLoading}
                  setIsloading={(status) => {
                    this.setState({ isLoading: status })
                  }}
                  doctorTypes={this.state.doctorTypes}
                  fetchDoctorTypeUsers={(doctorTypeId) => this.fetchDoctorTypeUsers(doctorTypeId)}
                  props={props}
                ></BookingPrescriptionChart>
              </TabPane>
              <TabPane tab="ข้อมูลสถิติ" key="3">
                <StatisticTab
                  className="w-100"
                  dataSource={this.state.statisticsData}
                  isLoading={this.state.isLoading}
                  setIsloading={(status) => {
                    this.setState({ isLoading: status })
                  }}
                  props={props}
                ></StatisticTab>
              </TabPane>
              {this.state.roles.includes('callCenter') && (
                <TabPane tab="ประวัติการใช้งานแชทบอท" key="4">
                  <ChatbotHistoryTable userList={this.state.user.data} />
                </TabPane>
              )}
              <TabPane tab="V-Refer" key="5">
                <ReferStatistic referData={this.state.referData} fetchReports={this.fetchReports} isLoading={this.state.isLoading} />
              </TabPane>
              <TabPane tab="รายงานข้อมูลการใช้งาน ems" key="6">
                <ReportEmsTab reportEmsData={this.state.reportEmsData} fetchEmsReports={this.fetchEmsReports} isLoading={this.state.isLoading} />
              </TabPane>
              <TabPane tab="รายงานข้อมูลผู้ใช้ที่มีผล SMBG" key="7">
                <ReportSmbgTab reportSmbgData={this.state.reportSmbgData} fetchSmbgReports={this.fetchSmbgReports} isLoading={this.state.isLoading} />
              </TabPane>
              <TabPane tab="รายงานข้อมูลผู้ใช้ที่มีผล HBPM" key="8">
                <ReportHbpmTab reportHbpmData={this.state.reportHbpmData} fetchHbpmReports={this.fetchHbpmReports} isLoading={this.state.isLoading} />
              </TabPane>
              <TabPane tab="การทำ Telemedicine" key="9">
                <ReportTelemedicine bookings={this.state.bookingPrescriptions} doctorTypes={this.state.doctorTypes} otherTypes={this.state.otherTypes} />
              </TabPane>
              <TabPane tab="การลงทะเบียน" key="10">
                <ReportRegistration users={this.state.user.data} />
              </TabPane>
              <TabPane tab="แบบสอบถาม (ใหม่)" key="11">
                <ReportSubmission submissions={this.state.submission.data} surveys={this.state.survey.data} />
              </TabPane>
              <TabPane tab="4 เขตดุสิต" key="12">
                <UsersByAreaTAb userArea={this.state.userArea} isLoading={this.state.user.isLoading} fetchDataForRange={this.fetchUserList} />
              </TabPane>
            </Tabs>
          </div>
        )}
      />
    )
  }
}

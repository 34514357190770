
import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import Layout from '../Home'
import SymptomForm from '../../components/SymptomForm'


@inject('Symptoms')
@inject('DoctorTypes')
@observer
class CreateSymptom extends Component {
  state = {
    hasSubmitted: false,
    doctorTypes: [],
  }

  async componentDidMount() {
    const { DoctorTypes } = this.props
    const doctorTypes = await DoctorTypes.fetchDoctorTypes()
    this.setState({ doctorTypes })
  }

  handleSubmit = async (values) => {
    const { Symptoms, history } = this.props
    const response = await Symptoms.createSymptom(values)
    if (response) {
      this.setState({ hasSubmitted: true })
    }
    history.goBack()
  }

  render() {
    const { hasSubmitted, doctorTypes } = this.state

    return (
      <Layout
        {...this.props}
        render={(props) => (
          <SymptomForm
            onSubmit={this.handleSubmit}
            hasSubmitted={hasSubmitted}
            doctorTypes={doctorTypes}
            title="Create Symptom"
            {...props}
            style={{ width: '100%' }}
          />
        )}
      />
    )
  }
}

CreateSymptom.propTypes = {
}

CreateSymptom.defaultProps = {
}

export default CreateSymptom

import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/prescriptions'
const apiEndpointRelatedTrackingNumber = '/prescriptionRelatedTrackingNumbers'

class Prescription {
  @observable info = {
    data: [],
    error: null
  }

  @action createPrescription = async (patientId) => {
    try {
      const { data } = await http.post(apiEndpoint, {
        patientId
      })
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action updatePrescription = async (prescriptionId, medicines) => {
    try {
      const sumprice = medicines.reduce((acc, cur) => {
        const sum = acc + cur.price * cur.quantity
        return sum
      }, 0)
      const { data } = await http.patch(`${apiEndpoint}/${prescriptionId}`, {
        sumprice,
        medicines
      })
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action confirmOrderPrescription = async (prescriptionId) => {
    try {
      const { id } = jwtDecode(localStorage.getItem('token'))
      const { data } = await http.patch(`${apiEndpoint}/${prescriptionId}`, {
        pharmacyId: id
      })
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action fetchFilterByPhamacyStores = async (pharmacyStoreId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/?filter[include]=patient&filter[where][pharmacyStoreId]=${pharmacyStoreId}&filter[include]=logistic&filter[include]=pharmacy`)
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action updateStatus = async (id, status) => {
    let detail
    if (status === 'PHARMACY_PENDING_RX') {
      detail = {
        status,
        paymentStatus: 'PATIENT_PENDING_PAYMENT'
      }
    } else if (['PATIENT_PENDING_PAYMENT', 'PATIENT_SUCCESS_PAYMENT'].includes(status)) {
      detail = {
        paymentStatus: status
      }
    } else {
      detail = {
        status
      }
    }
    try {
      const { data } = await http.patch(`${apiEndpoint}/${id}`, detail)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action addTrackingNumber = async (id, quantity) => {
    try {
      const { data } = await http.post(`${apiEndpointRelatedTrackingNumber}/addTrackingNumber`, {
        prescriptionId: id,
        quantity
      })
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchDetailTrackingNumber = async (prescriptionId) => {
    try {
      const { data } = await http.get(`${apiEndpointRelatedTrackingNumber}?filter[where][prescriptionTrackingId]=${prescriptionId}&filter[include]=trackingNumberId`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @computed get getMedicine() {
    return toJS(this.medicine.data)
  }
}

export default new Prescription()

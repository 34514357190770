import React, { Component } from 'react'
import { Select } from 'antd'
import AvailableTimes from 'react-available-times'
import momentTimezone from 'moment-timezone'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

import styles from './test.css'
import './reset.css'

import Layout from '../Home'
import { TABLE_TYPES } from '../../constant'
import Sidebar from '../../components/Sidebar'
import { inject, observer } from 'mobx-react'

function dateAt(dayInWeek, hours, minutes) {
  const date = new Date()
  while (date.getDay() > 0) {
    // reset to sunday
    date.setDate(date.getDate() - 1)
  }
  for (let i = 0; i < dayInWeek; i++) {
    date.setDate(date.getDate() + 1)
  }
  date.setHours(hours, minutes, 0, 0)
  return date
}

const TIME_ZONE = 'America/Los_Angeles'

@inject('DoctorAvailableTime')
@inject('PharmacyAvailableTime')
@observer
export default class Calendar extends Component {
  constructor() {
    super()
    this.state = {
      selections: [],
      initialSelections: [],
      minute: 15,
      fullscreen: false,
      loading: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.selectMinute = this.selectMinute.bind(this)
  }

  componentDidMount() {
    const { DoctorAvailableTime, PharmacyAvailableTime } = this.props
    const { id, roles } = jwtDecode(localStorage.getItem('token'))
    this.setState(
      {
        loading: true
      },
      async () => {
        const response = await DoctorAvailableTime.fetchfilterType(id)
        console.log('RESPONSE', response)
        if (response) {
          this.selectMinute(response.minute)
          this.checkTime(response.time)
        }
        this.setState({ loading: false })
      }
    )
  }

  selectMinute(select) {
    this.setState({ minute: select })
    this.props.selectMinute(select)
  }

  handleChange(selections) {
    this.setState({ selections })
    const data = selections.filter((o) => !this.state.initialSelections.find((o2) => o.start === o2.start || o.end === o2.end))
    this.props.handleTime(this.state.selections)
  }
  componentDidUpdate(prevProps) {
    if (prevProps.handleTime) {
      this.props.handleTime(this.state.selections)
    }
  }

  checkTime(times) {
    const timeSelections = times.map((t) => {
      return { start: t[0], end: t[1] }
    })
    this.setState({
      selections: timeSelections,
      initialSelections: timeSelections
    })
    const { roles } = jwtDecode(localStorage.getItem('token'))
  }

  render() {
    return (
      <div className={styles.example}>
        <div className={styles.main}>
          {!this.state.loading ? (
            <div>
              <p>ระยะเวลาในการตรวจ</p>
              <Select defaultValue={this.state.minute} style={{ width: 120 }} onChange={this.selectMinute}>
                <Option value="5">5 นาที</Option>
                <Option value="10">10 นาที</Option>
                <Option value="15">15 นาที</Option>
                <Option value="20">20 นาที</Option>
                <Option value="25">25 นาที</Option>
                <Option value="30">30 นาที</Option>
                <Option value="45">45 นาที</Option>
                <Option value="60">60 นาที</Option>
              </Select>
              <AvailableTimes
                timeConvention="24h"
                timeZone={TIME_ZONE}
                height={this.state.fullscreen ? undefined : 600}
                weekStartsOn="monday"
                start={new Date()}
                onChange={this.handleChange}
                initialSelections={this.state.initialSelections}
                recurring={true}
              />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
// {!this.state.fullscreen && (
//   <div className={styles.intro}>
//     <h1>Select available day</h1>
//     <p></p>
//     {selections.length > 0 && (
//       <div>
//         <h2>Selected ({this.state.selections.length})</h2>
//         <ul className={styles.selected}>
//           {selections.map(({ start, end }) => (
//             <li key={start}>
//               {start.toString()} - {end.toString()}
//             </li>
//           ))}
//         </ul>
//       </div>
//     )}
//     <a href="/?fullscreen">Go full screen</a>
//     <br />
//     <br />
//     <button
//       style={{
//         backgroundColor: 'white',
//         padding: '10px 10px',
//         borderRadius: 15,
//         border: 'none'
//       }}
//     >
//       Schedule Weekly
//     </button>
//   </div>
// )}

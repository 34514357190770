import { observable, action, computed, toJS } from 'mobx'
import http from '../../services/httpServiceHealthCare'

const healthCheckUpsEndpoint = '/HealthCheckUps'
const healthCheckUpNoteEndpoint = '/HealthCheckUpNotes'
const doctorAdvicesEndpoint = '/DoctorAdvices'

class HealthCheckUpNote {
  @observable info = {
    data: [],
    error: null,
  }

  @action fetchLastestHealthCheckUp = async (cId) => {
    try {
      const { data } = await http.get(`${healthCheckUpsEndpoint}/latest/summary?cId=${cId}`)
      return data
    } catch (e) {
      console.log(e)
    }
  }

  @action fetchAllHealthCheckUpNotes = async () => {
    try {
      const { data } = await http.get(healthCheckUpNoteEndpoint)
      return data
    } catch (e) {
      console.log(e)
    }
  }

  @action fetchHealthCheckUpNotes = async (keyword, page, limit) => {
    try {
      const keywordSearch = keyword && keyword.length > 1 ? `&keyword=${keyword}` : ''
      const { data } = await http.get(`${healthCheckUpNoteEndpoint}/search?page=${page}&limit=${limit}${keywordSearch}`)
      return data
    } catch (e) {
      console.log(e)
    }
  }

  @action fetchDoctorAdvices = async () => {
    try {
      const { data } = await http.get(`${doctorAdvicesEndpoint}`)
      return data
    } catch (e) {
      console.log(e)
    }
  }

  @action updateHealthCheckUpNote = async (healthCheckUpNote, id) => {
    try {
      const url = id ? `${healthCheckUpNoteEndpoint}/${id}` : healthCheckUpNoteEndpoint
      const { data } = await http.patch(url, healthCheckUpNote)
      return data
    } catch (e) {
      console.log(e)
    }
  }

  @computed get getHealthCheckUpLists() {
    return toJS(this.info.data)
  }
}

export default new HealthCheckUpNote()

import { observable, action, computed, toJS } from 'mobx'
import http from '../../services/httpServiceEver'
import { getRole } from '../../constant'

const apiEndpoint = '/kycPatientImages'

class KycPatientImage {
  @observable info = {
    data: [],
    error: null
  }

  @action fetchPatientImage = async patientId => {
    try {
      const { data } = await http.get(`${apiEndpoint}/filterByPatientId?patientId=${patientId}`)
      return data[0]
    } catch (error) {
      console.warn(error)
    }
  }

  @computed get getKycPatientImage() {
    return toJS(this.logistic.data)
  }
}

export default new KycPatientImage()

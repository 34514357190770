import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles, { Row, Column } from '../styles'
import { get, range, isArray, concat } from 'lodash'

export default function VitalSignTable({ data }) {
  const recordList = isArray(get(data, "timeLog", [{}, {}, {}])) ? get(data, "timeLog", [{}, {}, {}]) : [{}, {}, {}]
  const recordSlice = recordList.length >= 3 ? recordList.slice(0, 3) : concat(recordList, range(3 - recordList.length).map(() => ({})))

  return (
    <View style={styles.borderedContainer}>
      <Row key="header">
        <Column borderBottom borderRight justifyCenter itemsCenter width={30}>
          <Text style={styles.normalText}>Time</Text>
        </Column>
        <View>
          <Row>
            <Column borderBottom borderRight itemsCenter flexGrow={1}>
              <Text style={styles.normalText}>Vital signs</Text>
            </Column>
          </Row>
          <Row>
            <Column borderBottom borderRight itemsCenter justifyCenter width={30}>
              <Text style={styles.normalText}>BT</Text>
            </Column>
            <SqaureWithDiagonal
              content1={<Text style={[styles.smallLabel, { marginLeft: 3 }]}>SBP</Text>}
              content2={<Text style={styles.smallLabel}>DBP</Text>}
              width={40}
              height={26}
              borderBottom
              borderRight />
            <Column borderBottom borderRight itemsCenter justifyCenter width={20}>
              <Text style={styles.normalText}>PR</Text>
            </Column>
            <SqaureWithDiagonal
              content1={<Text style={[styles.smallLabel, { marginLeft: 3 }]}>RR</Text>}
              content2={<Text style={styles.smallLabel}>SpO2</Text>}
              width={40}
              height={26}
              borderBottom
              borderRight />
          </Row>
        </View>
        <View>
          <Row>
            <Column borderBottom borderRight itemsCenter flexGrow={1}>
              <Text style={styles.normalText}>Neuro sign</Text>
            </Column>
          </Row>
          <Row>
            <Column borderBottom borderRight itemsCenter justifyCenter width={20} height={26}>
              <Text style={styles.normalText}>E</Text>
            </Column>
            <Column borderBottom borderRight itemsCenter justifyCenter width={20}>
              <Text style={styles.normalText}>V</Text>
            </Column>
            <Column borderBottom borderRight itemsCenter justifyCenter width={20}>
              <Text style={styles.normalText}>M</Text>
            </Column>
          </Row>
        </View>
        <Column borderBottom itemsCenter justifyCenter width={25}>
          <Text style={styles.normalText}>DTX</Text>
        </Column>
      </Row>
      {recordSlice.map((item, index) => {
        return (
          <Row key={index}>
            <Column itemsCenter key="time" borderBottom={index !== 2} borderRight width={30}>
              <Text style={styles.smallerText}>{get(item, "time", "")}</Text>
            </Column>
            <Column itemsCenter key="bt" borderBottom={index !== 2} borderRight width={30}>
              <Text style={styles.smallerText}>{get(item.vital, "bt", "")}</Text>
            </Column>
            <SqaureWithDiagonal
              key="bp"
              content1={<Text style={styles.smallerText}>{get(item.vital, "sbp", "")}</Text>}
              content2={<Text style={styles.smallerText}>{get(item.vital, "dbp", "")}</Text>}
              width={40}
              height={24}
              borderBottom={index !== 2}
              borderRight />
            <Column itemsCenter key="pr" borderBottom={index !== 2} borderRight width={20}>
              <Text style={styles.smallerText}>{get(item.vital, "pr", "")}</Text>
            </Column>
            <SqaureWithDiagonal
              key="rr"
              content1={<Text style={styles.smallerText}>{get(item.vital, "rr", "")}</Text>}
              content2={<Text style={styles.smallerText}>{get(item.vital, "spo2", "")}</Text>}
              width={40}
              height={24}
              borderBottom={index !== 2}
              borderRight />
            <Column itemsCenter key="e" borderBottom={index !== 2} borderRight width={20}>
              <Text style={styles.normalText}>{get(item.neuro, "e", "")}</Text>
            </Column>
            <Column itemsCenter key="v" borderBottom={index !== 2} borderRight width={20}>
              <Text style={styles.normalText}>{get(item.neuro, "v", "")}</Text>
            </Column>
            <Column itemsCenter key="m" borderBottom={index !== 2} borderRight width={20}>
              <Text style={styles.normalText}>{get(item.neuro, "m", "")}</Text>
            </Column>
            <Column itemsCenter key="dtx" borderBottom={index !== 2} width={25}>
              <Text style={styles.smallerText}>{get(item, "dtx", "")}</Text>
            </Column>
          </Row>
        )
      })}
    </View>
  )
}

const calcAngleDegrees = (x, y) => {
  return Math.floor(Math.atan2(y, x) * 180 / Math.PI);
}

const SqaureWithDiagonal = ({ content1, content2, width, height, borderRight, borderBottom, borderColor = "black" }) => {
  const angle = calcAngleDegrees(height, width)
  const diagonalWidth = Math.sqrt((Math.pow(width, 2) + Math.pow(height, 2)))
  const bottomPosition = (diagonalWidth - height) / 2
  return (
    <View style={{
      height,
      width,
      overflow: 'hidden',
      borderBottomWidth: borderBottom ? 1 : 0,
      borderRightWidth: borderRight ? 1 : 0,
      borderColor
    }}>
      <View style={{ position: 'relative' }}>
        <View style={{ height: height / 2, width: '75%', position: 'absolute', top: 0, left: 0, overflow: 'hidden' }}>
          {content1}
        </View>
        <View style={{ height: height / 2, width: '75%', position: 'absolute', right: 0, top: height / 2, overflow: 'hidden', alignItems: 'flex-end' }}>
          {content2}
        </View>
      </View>
      <View style={{ height: diagonalWidth, width: 1, backgroundColor: 'black', transform: `rotate(${angle}deg)`, position: 'absolute', bottom: -bottomPosition, left: "50%" }} />
    </View>
  )
}

import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/soundRecorders'

class SoundRecorder {
  @observable info = {
    data: [],
    error: null
  }

  @action createSoundRecorder = async (detail) => {
    try {
      const { data } = await http.post(apiEndpoint,detail)
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action fetchSoundRecorder = async (id) => {
    try {
      const { data } = await http.get(`${apiEndpoint}?filter[where][bookingId]=${id}`)
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @computed get getMedicine() {
    return toJS(this.medicine.data)
  }
}

export default new SoundRecorder()

import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles, { Row } from '../styles'
import { get } from 'lodash'

export default function HeaderSection({ data }) {
  const formId = get(data, "emsUniqueId", "____")
  return (
    <View>
      <Text style={styles.titleText}>แบบบันทึกการปฏิบัติการฉุกเฉินการแพทย์ขั้นสูง</Text>
      <Text style={{
        ...styles.titleText,
        position: 'absolute',
        left: 0,
        bottom: 0,
        textAlign: 'left',
      }}>เลขที่ {formId}</Text>
      <Text style={styles.titleText}>ฝ่ายบริการการแพทย์ฉุกเฉินและสาธารณภัย หน่วยแพทย์กู้ชีวิตวชิรพยาบาล</Text>
    </View>
  )
}

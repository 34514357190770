export const initialValues = {
  obviousDeath: {},
  cardiacArrest: {},
  emsImpression: [],
  emsTreatment: {},
}
export const initiaCardiacValues = {
  lead1: '',
  lead2: '',
  lead3: '',
  cpr: 'ไม่',
  reasonToStopCpr: '',
  witnessedUnwitnessedArrest: [],
  layRescuerCpr: '',
  layRescuerCprTime: '',
  mechanicalCprDevice: '',
  initialRhythm: '',
  lastRhythm: '',
  rosc: '',
  roscTime: '',
  roEtiology: '',
  finalStatusAtScene: '',
  ptStatusAtEdArrival: '',
  biologicalDeath: [],
  forensicSigns: [],
  declarationOfDeath: [],
}
export const initiaObviousDeathValues = {
  lead1: '',
  lead2: '',
  lead3: '',
  biologicalDeath: [],
  forensicSigns: [],
}
export const initiaRespiratoryValues = {
  endotrachealIntubation: '',
  rateOfAttempts: '',
  complication: '',
  alternativeAirway: '',
}
export const initiaSepsisValues = {
  activateFastTrack: '',
  esl1: [],
  esl2: [],
}
export const initiaTraumaticInjuryValues = {
  emsActivateTrauma: '',
  mechanismOfInjury: [],
  traumaLevel1: [],
}

export const emsImpression = [
  { label: 'Abdominal pain' },
  { label: 'Airway Obstruction' },
  { label: 'Altered L.O.C' },
  { label: 'Anaphylaxis' },
  { label: 'Animal bites' },
  { label: 'Assault' },
  { label: 'Asthma' },
  { label: 'Behavioral / psych' },
  { label: 'Bleeding per vagina' },
  { label: 'Burn' },
  { label: 'Cardiac Arrest', expandable: true },
  { label: 'Cardiac Arrhythmia', expandable: true },
  { label: 'Chest pain/Discomfort', expandable: true },
  { label: 'Drowning' },
  { label: 'Eclampsia' },
  { label: 'Electrical injury' },
  { label: 'Environment injury' },
  { label: 'Fall' },
  { label: 'Gl bleed' },
  { label: 'Hanging' },
  { label: 'Headache' },
  { label: 'High risk pregnancy' },
  { label: 'Hyperglycemia' },
  { label: 'Hypertension' },
  { label: 'Hypoglycemia' },
  { label: 'Hypotension' },
  { label: 'Hypovolemia' },
  { label: 'Intoxication/Alcohol ingestion' },
  { label: 'Obvious death', expandable: true },
  { label: 'Pediatrics problem' },
  { label: 'Poison/Drug ingestion' },
  { label: 'Pregnancy/Ob Delivery' },
  { label: 'Rape' },
  { label: 'Respiratory distress', expandable: true },
  { label: 'Respiratory failure', expandable: true },
  { label: 'Seizure' },
  { label: 'Sepsis', expandable: true },
  { label: 'Shock' },
  { label: 'Stroke/TIA/CVA', expandable: true },
  { label: 'Syncope' },
  { label: 'Toxic Inhalation' },
  { label: 'Traumatic injury', expandable: true },
  { label: 'Unknow' },
  { label: 'Vaginal hemorrhage' },
  { label: 'Other' },
]

export const formTemplate = [
  {
    cols: 12,
    data: [
      {
        label: 'CPR',
        value: 'cpr',
        disabled: false,
        required: false,
        requiredMessage: '',
        type: 'radio',
        items: ['ใช่', 'ไม่'],
      },
      {
        label: 'BIOLOGICAL DEATH',
        value: 'biologicalDeath',
        type: 'checkbox',
        showByCpr: 'ไม่',
        items: [
          {
            label: 'Cranial destruction',
          },
          {
            label: 'Transection',
          },
          {
            label: 'Third degree burn TBSA>95%',
          },
        ],
      },
      {
        label: 'FORENSIC SIGNS',
        value: 'forensicSigns',
        type: 'checkbox',
        showByCpr: 'ไม่',
        extraInputs: ['Livor mortis', 'Rigor mortis'],
        items: [
          {
            label: 'Livor mortis',
          },
          {
            label: 'Rigor mortis',
          },
          {
            label: 'Decomposition',
          },
        ],
      },
      {
        label: 'DECLARATION OF DEATH',
        value: 'declarationOfDeath',
        type: 'checkbox',
        showByCpr: 'ไม่',
        extraInputs: ['Termination of resuscitation'],
        items: [
          {
            label: 'Do not attempt resuscitation',
          },
          {
            label: 'Termination of resuscitation',
            description: 'Unwitnessed arrest and lay rescuer CPR Never received rescue shock Never Had pulse in 20min',
          },
        ],
      },
      {
        label: 'เหตุผลการไม่หรือหยุด CPR',
        value: 'reasonToStopCpr',
        type: 'radio',
        showByCpr: 'ใช่',
        items: ['ROSC', 'DNAR', 'Consider Futile', 'Transfer to professional medical'],
      },
      {
        label: 'Witnessed/Unwitnessed arrest',
        value: 'witnessedUnwitnessedArrest',
        type: 'cascader',
        showByCpr: 'ใช่',
        items: [
          {
            value: 'Unwitnessed arrest',
            label: 'Unwitnessed arrest',
          },
          {
            value: 'Witnessed arrest',
            label: 'Witnessed arrest',
            children: [
              {
                value: 'First responder',
                label: 'First responder',
              },
              {
                value: 'ALS',
                label: 'ALS',
              },
              {
                value: 'Family',
                label: 'Family',
              },
              {
                value: 'Lay person',
                label: 'Lay person',
              },
              {
                value: 'Healthcare personal',
                label: 'Healthcare personal',
              },
            ],
          },
        ],
      },
      {
        label: 'Lay rescuer CPR',
        value: 'layRescuerCpr',
        type: 'radio',
        showByCpr: 'ใช่',
        items: ['Yes', 'No'],
      },
      {
        label: 'Lay rescuer CPR time',
        value: 'layRescuerCprTime',
        type: 'text',
        showByCpr: 'ใช่',
      },
      {
        label: 'Mechanical CPR device',
        value: 'mechanicalCprDevice',
        type: 'radio',
        showByCpr: 'ใช่',
        items: ['No', 'Load-Distribution Band Devices-Autopuls', 'Pneumatic piston device-LUCUS', 'Easy Pulse'],
      },
      {
        label: 'Initial rhythm',
        value: 'initialRhythm',
        type: 'radio',
        showByCpr: 'ใช่',
        items: ['Asystole', 'PEA', 'pVT', 'VF'],
      },
      {
        label: 'Last rhythm',
        value: 'lastRhythm',
        type: 'radio',
        extraInputs: ['Other'],
        max: 1,
        showByCpr: 'ใช่',
        items: ['ASYSTOLE', 'PEA', 'pVT', 'VF', 'NSR', 'Sinus Tachycardia', 'Synus Bradycardia', 'VT with pulse', 'Complete Heart Block', 'ST Elevation', 'Hyper acute T', 'SVT', 'Other'],
      },
      {
        label: 'ROSC',
        value: 'rosc',
        type: 'radio',
        showByCpr: 'ใช่',
        items: ['Yes', 'No'],
      },
      {
        label: 'ROSC time',
        value: 'roscTime',
        type: 'text',
        showByCpr: 'ใช่',
      },
      {
        label: 'R/O Etiology',
        value: 'roEtiology',
        type: 'text',
        showByCpr: 'ใช่',
      },
      {
        label: 'Final status at scene',
        value: 'finalStatusAtScene',
        type: 'radio',
        showByCpr: 'ใช่',
        items: ['Transport to ED', 'Dead at scene'],
      },
      {
        label: 'Pt.Status at ED arrival',
        value: 'ptStatusAtEdArrival',
        type: 'radio',
        showByCpr: 'ใช่',
        items: ['ROSC', 'Ongoing resuscitation', 'Transports without resuscitation'],
      },
    ],
  },
  {
    cols: 12,
    header: 'อัพโหลดรูปแผ่น ECG',
    data: [
      {
        label: 'Cardiac Arrest Lead I',
        value: 'lead1',
        disabled: false,
        required: false,
        requiredMessage: '',
        type: 'upload',
        cols: 12,
      },
      {
        label: 'Cardiac Arrest Lead II',
        value: 'lead2',
        disabled: false,
        required: false,
        requiredMessage: '',
        type: 'upload',
        cols: 12,
      },
      {
        label: 'Cardiac Arrest Lead III',
        value: 'lead3',
        disabled: false,
        required: false,
        requiredMessage: '',
        type: 'upload',
        cols: 12,
      },
    ],
  },
]
export const obviousDeathFormTemplate = [
  {
    cols: 12,
    data: [
      {
        label: 'BIOLOGICAL DEATH',
        value: 'biologicalDeath',
        type: 'checkbox',
        items: [
          {
            label: 'Cranial destruction',
          },
          {
            label: 'Transection',
          },
          {
            label: 'Third degree burn TBSA>95%',
          },
        ],
      },
      {
        label: 'FORENSIC SIGNS',
        value: 'forensicSigns',
        type: 'checkbox',
        extraInputs: ['Livor mortis', 'Rigor mortis'],
        items: [
          {
            label: 'Livor mortis',
          },
          {
            label: 'Rigor mortis',
          },
          {
            label: 'Decomposition',
          },
        ],
      },
    ],
  },
  {
    cols: 12,
    header: 'อัพโหลดรูปแผ่น ECG',
    data: [
      {
        label: 'Obvious Death Lead I',
        value: 'lead1',
        disabled: false,
        required: false,
        requiredMessage: '',
        type: 'upload',
        cols: 12,
      },
      {
        label: 'Obvious Death Lead II',
        value: 'lead2',
        disabled: false,
        required: false,
        requiredMessage: '',
        type: 'upload',
        cols: 12,
      },
      {
        label: 'Obvious Death Lead III',
        value: 'lead3',
        disabled: false,
        required: false,
        requiredMessage: '',
        type: 'upload',
        cols: 12,
      },
    ],
  },
]
export const respiratoryFormTemplate = [
  {
    label: 'Endotracheal Intubation outcome',
    value: 'endotrachealIntubation',
    type: 'checkbox',
    items: [
      {
        label: 'Success',
      },
      {
        label: 'Failure',
      },
    ],
  },
  {
    label: 'Rate of attempts',
    value: 'rateOfAttempts',
    type: 'text',
  },
  {
    label: 'Complication',
    value: 'complication',
    type: 'text',
  },
  {
    label: 'Alternative airway',
    value: 'alternativeAirway',
    type: 'text',
  },
]
export const sepsisFormTemplate = [
  {
    data: [
      {
        label: 'EMS activate sepsis Fast Track (Mins)',
        value: 'activateFastTrack',
        type: 'text',
      },
    ],
  },
  {
    header: '*Suspected infection patient*',
    data: [
      {
        label: 'ESL 1 - ประเมินตั้งแต่ Initial assesment',
        value: 'esl1',
        type: 'checkbox',
        items: [
          {
            label: 'SBP < 90 mmHg.',
          },
          {
            label: 'SBP < 40 mmHg. จาก base line เดิม',
          },
          {
            label: 'Capillary Refill > 2 sec. (มือเท้าเย็น)',
          },
          {
            label: 'GCS < 8',
          },
          {
            label: 'SpO2 < 90 % RA',
          },
        ],
      },
      {
        label: 'ESL 2 - ประเมินตั้งแต่ Initial assesment',
        value: 'esl2',
        type: 'checkbox',
        items: [
          {
            label: 'RR > 30 bpm.',
          },
          {
            label: 'PR > 130 bpm.',
          },
          {
            label: 'GCS 9-14',
          },
          {
            label: 'Shock index (HR/SBP) ≥ 1',
          },
          {
            label: '2 เกณฑ์ qSOFA (SBP 90-100 mmHg, RR 22-30 bpm)',
          },
          {
            label: 'SIRS 2/3 (อายุ > 65 ปี, On venous/artery catheter, Immunocompromised)',
          },
        ],
      },
    ],
  },
]
export const traumaInjuryFormTemplate = [
  {
    cols: 12,
    data: [
      {
        label: 'อัพโหลดรูปภาพ เพื่อประเมิน Mechanism of injury',
        value: 'mechanismOfInjury',
        disabled: false,
        required: false,
        requiredMessage: '',
        type: 'upload',
        cols: 12,
      },
    ],
  },
  {
    cols: 12,
    data: [
      {
        header: 'Trauma level 1 : VAJIRA ONLY',
        label: 'EMS activate trauma level 1 ER vajira (Mins)',
        value: 'emsActivateTrauma',
        type: 'text',
      },
      {
        value: 'traumaLevel1',
        type: 'checkbox',
        items: [
          {
            label: 'Arrest / Post Cardiac arrest',
          },
          {
            label: 'Respiratory Compromise (RR<10>29) or Airway obstruction and/or Intubated patient from the scene',
          },
          {
            label: 'Difficult airway problem or need surgical airway management',
          },
          {
            label: 'Shock (SBP ≤ mmHg) on adults, or SBP decrease 40 mmHg from baseline, HR ≥ 130 bpm or Shock index ≥ 1',
          },
          {
            label: 'Glasgow coma scale ≤ 8',
          },
          {
            label: 'amputated limb (Above wrist joint/ankle joint) or nearly amputated limb',
          },
          {
            label: 'Penetrated injury to nech chest/abdomen/pelvis/perineum/back',
          },
          {
            label: 'Positive fast exam with PR ≥ 100',
          },
        ],
      },
    ],
  },
]

export const fastedChoices = [
  {
    label: '0',
  },
  {
    label: '1',
  },
  {
    label: '2',
  },
]
export const itemChoices = [
  {
    label: 'Left',
  },
  {
    label: 'Right',
  },
]

export const emsTreatment = [
  {
    name: 'ทางเดินหายใจ/การหายใจ',
    value: 'breathing',
    type: 'checkbox',
    extraInputs: ['ET No', 'LMA No.', 'Other'],
    data: [
      {
        label: 'No',
      },
      {
        label: 'Clear airway',
      },
      {
        label: 'Suction',
      },
      {
        label: 'OPA',
      },
      {
        label: 'NPA',
      },
      {
        label: 'O2 cannular',
      },
      {
        label: 'Mask with reservoir bag',
      },
      {
        label: 'Bag valve mask',
      },
      {
        label: 'ET No',
      },
      {
        label: 'LMA No.',
      },
      {
        label: 'Other',
      },
    ],
  },
  {
    name: 'บาดแผล/การห้ามเลือด',
    value: 'contusion',
    type: 'checkbox',
    extraInputs: ['Other'],
    data: [
      {
        label: 'No',
      },
      {
        label: 'Dressing',
      },
      {
        label: 'Pressure Dressing',
      },
      {
        label: 'T-side Dressing',
      },
      {
        label: 'Uterine massage',
      },
      {
        label: 'Tourniquet',
      },
      {
        label: 'Other',
      },
    ],
  },
  {
    name: 'การให้สารน้ำ',
    value: 'waterSupply',
    type: 'checkbox',
    extraInputs: ['Other', 'Rate', 'Time', 'Total at ER'],
    data: [
      {
        label: 'No',
      },
      {
        label: 'NSS lock',
      },
      {
        label: 'NSS',
      },
      {
        label: 'RLS',
      },
      {
        label: 'Acetar',
      },
      {
        label: '10% D/N/2',
      },
      {
        label: 'Other',
      },
      {
        label: 'Rate',
      },
      {
        label: 'Time',
      },
      {
        label: 'Total at ER',
      },
    ],
  },
  {
    name: 'การดามกระดูก',
    value: 'boneSplint',
    type: 'checkbox',
    data: [
      {
        label: 'No',
      },
      {
        label: 'Vacuum Splint',
      },
      {
        label: 'ไม้ดาม',
      },
      {
        label: 'Cervical hard collar',
      },
      {
        label: 'Long spinal board',
      },
      {
        label: 'Head Immobilizer',
      },
      {
        label: 'KED',
      },
      {
        label: 'Traction splint',
      },
    ],
  },
  {
    name: 'การทำ CPR',
    value: 'cpr',
    type: 'checkbox',
    data: [
      {
        label: 'No',
      },
      {
        label: 'Yes',
      },
      {
        label: 'AED/DF',
      },
      {
        label: 'Synchronize cardioversion',
      },
      {
        label: 'Transcutaneous pacing',
      },
    ],
  },
  {
    name: 'ยา(วิธีใช้ และขนาดการให้)',
    value: 'medicine',
    type: 'text',
  },
]

import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/dashboardData'

class DashboardData {
  @observable info = {
    data: [],
    error: null,
  }

  @action fetchDashboard = async () => {
    try {
      const { data } = await http.get(`${apiEndpoint}/?filter[include]=user`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action createDashboard = async (userId, telemedicineCount, chatbotAiMessageCount, telemedicineTimeCount, chatbotMessageFromUserCount, emergencyCount) => {
    try {
      await http.patch(apiEndpoint, {
        userId,
        telemedicineCount,
        chatbotAiMessageCount,
        telemedicineTimeCount,
        chatbotMessageFromUserCount,
        emergencyCount,
      })
    } catch (error) {
      console.log(error)
    }
  }

  @action updateDashboard = async (id, detail) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${id}`, detail)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action findDashboardByUserId = async (userId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/?filter[where][userId]=${userId}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  // @computed get getDashboard() {
  //   return toJS(this.booking.data)
  // }
}

export default new DashboardData()

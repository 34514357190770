
import { observable, action, computed, toJS } from 'mobx'
// import jwtDecode from 'jwt-decode'

import http from '../../services/httpService'

const apiEndpoint = '/Communications'
// const tokenKey = 'token'

class DoctorLive {
  @observable liveStatus = {
    status: false,
  }

  @action updateStatus = (value) => {
    try {
      this.liveStatus.status = value
    } catch (error) {
      console.log(error)
    }
  }

  @computed get getStatus() {
    return toJS(this.liveStatus.status)
  }
}

export default new DoctorLive()

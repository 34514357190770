import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Spin, Row, Col, Card } from 'antd'
import styled from 'styled-components'

const DataCard = styled(Card)`
  height: 100%;
  width: '100%';
  cursor: pointer;
  &:hover {
    transform: scale(1.08);
    z-index: 999;
  }
  transition: 0.5s;
`
const Mention = styled.small`
  font-size: 10px;
`
const Description = styled.p`
  font-size: 14px;
  color: #6e6e6e;
`
const Title = styled.h5`
  font-size: 16px;
  font-weight: bold;
`
const index = ({ dataSource, isLoading }) => {
  const [series, setSeries] = useState([])
  const [sumOfUsers, setSumOfUsers] = useState(0)
  useEffect(() => {
    handleSeriesData()
  }, [dataSource])

  const handleSeriesData = () => {
    let newSeriesData = []
    for (let index = 0; index < 7; index++) {
      let key = ['admitTimeApp', 'appointmentStatApp', 'healCostApp', 'ratingApp', 'recommendApp', 'transportCostApp', 'waitingTimeApp']
      let summaryPointSelected = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      const filterData = dataSource.filter((data) => Object.keys(data.detail[0]).length === 7)
      const uniqueFilterData = uniqueArray(filterData)
      setSumOfUsers(uniqueFilterData?.length)
      uniqueFilterData.map((data) => {
        let point = data.detail && data.detail[0] && data.detail[0].hasOwnProperty(key[index]) && (parseInt(data.detail[0][key[index]]) > 0 || data.detail[0][key[index]].length !== 0) ? parseInt(data.detail[0][key[index]]) : 0
        if (point > 0) {
          summaryPointSelected[point - 1] += 1
        }
      })
      newSeriesData.push({
        name: 'Question ' + (index + 1),
        data: summaryPointSelected,
      })
    }
    setSeries(newSeriesData)
  }

  const uniqueArray = (filterData) => {
    let res = []
    filterData.forEach((item) => {
      const check = res.findIndex((x) => x.surveyUserId == item.surveyUserId)
      if (check <= -1) res.push(item)
    })

    return res
  }

  let options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#5db1d0', '#d05d91'],
    title: {
      text: undefined,
    },
    xaxis: {
      categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      labels: {
        formatter: function(val) {
          return val + ' Person'
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function(val) {
          return val + ' Points'
        },
      },
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val + ' Person'
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },
  }

  let dataDescription = [
    {
      title: 'ข้อที่ 1:',
      description: 'ความพึงพอใจในการรับบริการจากโรงพยาบาลและแอปพลิเคชัน',
      mention: `"หลัง" ที่จะมีแอปพลิเคชัน`,
      color: '#008FFB',
    },
    {
      title: 'ข้อที่ 2:',
      description: 'ความพึงพอใจในระยะเวลารอตรวจทั้งก่อนพบแพทย์และเวลาตั้งแต่มาถึงโรงพยาบาลจนรับยา',
      mention: `"หลัง" ที่จะมีแอปพลิเคชัน`,
      color: '#00E396',
    },
    {
      title: 'ข้อที่ 3:',
      description: 'ความพึงพอใจที่ท่านสะดวกจะมาตรวจกับแพทย์ตามนัด',
      mention: `"หลัง" ที่จะมีแอปพลิเคชัน`,
      color: '#FEB019',
    },
    {
      title: 'ข้อที่ 4:',
      description: 'ความพึงพอใจต่อค่าใช้จ่ายสำหรับการเดินทางเพื่อเข้ารับบริการจากโรงพยาบาล',
      mention: `"หลัง" ที่จะมีแอปพลิเคชัน`,
      color: '#FF4560',
    },
    {
      title: 'ข้อที่ 5:',
      description: 'ความพึงพอใจต่อค่าใช้จ่ายในการรักษาในระบบกับโรงพยาบาล',
      mention: `"หลัง" ที่จะมีแอปพลิเคชัน`,
      color: '#775DD0',
    },
    {
      title: 'ข้อที่ 6:',
      description: 'ความพึงพอใจต่อโรงพยาบาลที่คาดว่าจะช่วยลดจำนวนครั้งในการต้องมานอนโรงพยาบาลของท่าน',
      mention: `"หลัง" ที่จะมีแอปพลิเคชัน`,
      color: '#5db1d0',
    },
    {
      title: 'ข้อที่ 7:',
      description: 'ท่านอยากแนะนำให้คนที่ท่านรู้จักมาใช้งานแอปพลิเคชันนี้มากน้อยแค่ไหน',
      color: '#d05d91',
    },
  ]

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Spin className="text-center" />
        </div>
      ) : (
        <div className="w-100 p-4">
          <Row className="pb-4" gutter={[6, 8]} justify="center" wrap={true}>
            {dataDescription.map((data) => {
              return (
                <Col xs={24} md={12} lg={6} xxl={3} className="w-100" style={{ height: 'auto', width: 'auto' }}>
                  <DataCard bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <Title className="pb-1">{data.title}</Title>
                      <Description>{data.description}</Description>
                    </div>
                    <div className="d-flex justify-content-between align-items-end mt-3" style={{ height: '100%' }}>
                      <Mention>{data.mention && data.mention}</Mention>
                      <div style={{ width: '40px', height: '30px', background: data.color, border: '1px solid #838383' }}></div>
                    </div>
                  </DataCard>
                </Col>
              )
            })}
            <Col xs={24} className="text-center">
              <h2>{`จำนวนผู้ทำแบบประเมินทั้งหมด ${numberWithCommas(sumOfUsers)} คน`}</h2>
            </Col>
          </Row>
          <div id="chart">
            <Chart options={options} series={series} type="bar" height={800} className="w-100"></Chart>
          </div>
        </div>
      )}
    </>
  )
}

export default index

/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react'
import Layout from '../Home'
import StoreTable from '../../components/StoreTable'
import { observer, inject } from 'mobx-react'
import jwtDecode from 'jwt-decode'
import { message } from 'antd'

@inject('Store')
@inject('Prescription')
@observer
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataSource: []
    }
  }

  async componentDidMount() {
    const { Store, Prescription } = this.props
    const { id } = jwtDecode(localStorage.getItem('token'))
    const resStore = await Store.fetchAppUserAndPharmacyStores(id)
    if (resStore[0]) {
      const resPrescription = await Prescription.fetchFilterByPhamacyStores(
        resStore[0].pharmacyStoreId
      )
      this.setState({
        dataSource: resPrescription.filter(res => {
          return [
            'WAIT_FOR_PHARMACYSTORE_NOTIFY',
            'WAIT_FOR_PATIENT',
            'SUCCESS_BY_PHARMACYSTORE',
            'CANCELED_BY_PATIENT',
            'CANCELED_BY_PHARMACYSTORE'
          ].includes(res.status)
        })
      })
    }
  }
  updateStatus = async (id, status) => {
    const { Prescription } = this.props
    const response = await Prescription.updateStatus(id, status)
    if (response) {
      message.success('ทำการเปลี่ยนสถานะเรียบร้อยแล้ว')
      this.updateDataSource(response, id)
    }
  }

  updateDataSource = (response, id) => {
    let updateItems = this.state.dataSource.map(data => {
      if (data.id === id) {
        return {
          ...data,
          status: response.status
        }
      }
      return data
    })
    this.setState({
      dataSource: updateItems
    })
  }

  render() {
    return (
      <Layout
        {...this.props}
        render={(
          props //eslint-disable-line
        ) => (
          <StoreTable
            {...props}
            state={this.state}
            updateStatus={this.updateStatus}
            style={{ width: '100%' }}
          /> //eslint-disable-line
        )}
      />
    )
  }
}

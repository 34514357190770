import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles, { Column, Row } from '../styles'
import moment from 'moment'
import { get } from 'lodash'

export default function DetailTable({ data }) {

  const operationDateTime = get(data, "operationDateTime", "")
  const operationNo = get(data, "operationNo", "")
  const sceneSite = get(data, "sceneSite", "")
  const policeStationArea = get(data, "policeStationArea", "")
  const areaOfResponsibility = get(data, "areaOfResponsibility", "")
  // const paramedicDoctor = get(data,"paramedicDoctor","")
  // const emergencyStaff = get(data, "emergencyStaff","")
  let idcCode = get(data, "idcCode", "")
  let rcCode = get(data, "rcCode", "")
  let firstParamedic = get(data, "paramedic[0]", "")
  let secondParamedic = get(data, "paramedic[1]", "")
  let thirdParamedic = get(data, "medicalEmergencyStaff[0]", "")
  let fourthParamedic = get(data, "medicalEmergencyStaff[1]", "")
  let fifthParamedic = get(data, "medicalEmergencyStaff[2]", "")


  if(typeof idcCode === "string") {
    idcCode = idcCode.split("-").length > 0 ? idcCode.split("-")[0] : idcCode
    idcCode = idcCode.split("–").length > 0 ? idcCode.split("–")[0] : idcCode
    idcCode = idcCode.split(":").length > 0 ? idcCode.split(":")[0] : idcCode
  }
  if(typeof rcCode === "string") {
    rcCode = rcCode.split("-").length > 0 ? rcCode.split("-")[0] : rcCode
    rcCode = rcCode.split("–").length > 0 ? rcCode.split("–")[0] : rcCode
    rcCode = rcCode.split(":").length > 0 ? rcCode.split(":")[0] : rcCode
  }

  if(typeof firstParamedic === "string") {
    firstParamedic = firstParamedic.split("-").length > 1 ? firstParamedic.split("-")[1] : firstParamedic
    firstParamedic = firstParamedic.split("–").length > 1 ? firstParamedic.split("–")[1] : firstParamedic
  }
  if(typeof secondParamedic === "string") {
    secondParamedic = secondParamedic.split("-").length > 1 ? secondParamedic.split("-")[1] : secondParamedic
    secondParamedic = secondParamedic.split("–").length > 1 ? secondParamedic.split("–")[1] : secondParamedic
  }
  if(typeof thirdParamedic === "string") {
    thirdParamedic = thirdParamedic.split("-").length > 1 ? thirdParamedic.split("-")[1] : thirdParamedic
    thirdParamedic = thirdParamedic.split("–").length > 1 ? thirdParamedic.split("–")[1] : thirdParamedic
  }
  if(typeof fourthParamedic === "string") {
    fourthParamedic = fourthParamedic.split("-").length > 1 ? fourthParamedic.split("-")[1] : fourthParamedic
    fourthParamedic = fourthParamedic.split("–").length > 1 ? fourthParamedic.split("–")[1] : fourthParamedic
  }
  if(typeof fifthParamedic === "string") {
    fifthParamedic = fifthParamedic.split("-").length > 1 ? fifthParamedic.split("-")[1] : fifthParamedic
    fifthParamedic = fifthParamedic.split("–").length > 1 ? fifthParamedic.split("–")[1] : fifthParamedic
  }

  return (
    <View style={[styles.borderedContainer, { marginBottom: 2 }]}>
      <Row>
        <Column borderBottom borderRight>
          <Text style={styles.normalText}>1.วันที่ออกปฏิบัติการ</Text>
          <Text style={styles.normalText}>{moment(operationDateTime).isValid() ? moment(operationDateTime).add(543,'years').format("DD MMM YYYY") : ""}</Text>
        </Column>
        <Column borderBottom borderRight>
          <Text style={styles.normalText}>2.พื้นที่โซน</Text>
          <Text style={styles.normalText}>{areaOfResponsibility}</Text>
        </Column>
        <Column borderBottom borderRight>
          <Text style={styles.normalText}>3.เลขปฏิบัติการ</Text>
          <Text style={styles.normalText}>{operationNo}</Text>
        </Column>
        <Column borderBottom borderRight flex={1}>
          <Text style={styles.normalText}>4.สถานที่เกิดเหตุ</Text>
          <Text style={styles.normalText}>{sceneSite}</Text>
        </Column>
        <Column borderBottom borderRight>
          <Text style={styles.normalText}>5.ขอบเขตสน.</Text>
          <Text style={styles.normalText}>{policeStationArea}</Text>
        </Column>
        <Column borderBottom borderRight>
          <Text style={styles.normalText}>6.ปฏิบัติการที่</Text>
          <Text style={styles.normalText}>{operationNo}</Text>
        </Column>
        <Column borderBottom borderRight width={55}>
          <Text style={styles.normalText}>7.IDC code</Text>
          <Text style={styles.normalText}>{idcCode}</Text>
        </Column>
        <Column borderBottom width={55}>
          <Text style={styles.normalText}>8.RC code</Text>
          <Text style={styles.normalText}>{rcCode}</Text>
        </Column>
      </Row>
      <Row>
        <Column borderBottom flex={1}>
          <Text style={styles.normalText}>9.รายชื่อผู้ออกปฏิบัติการ</Text>
        </Column>
      </Row>
      <Row>
        <Column borderRight flex={1}>
          <Text style={styles.normalText}>1. {firstParamedic}</Text>
        </Column>
        <Column borderRight flex={1}>
          <Text style={styles.normalText}>2. {secondParamedic}</Text>
        </Column>
        <Column borderRight flex={1}>
          <Text style={styles.normalText}>3. {thirdParamedic}</Text>
        </Column>
        <Column borderRight flex={1}>
          <Text style={styles.normalText}>4. {fourthParamedic}</Text>
        </Column>
        <Column flex={1}>
          <Text style={styles.normalText}>5. {fifthParamedic}</Text>
        </Column>
      </Row>
    </View>
  )
}

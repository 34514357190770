import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register required chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const AreaPieChart = ({ userArea }) => {
    // Prepare the data for the pie chart
    const data = {
        labels: ['ดุสิต', 'บางซื่อ', 'บางพลัด', 'พระนคร', 'พื้นที่อื่นๆ'],
        datasets: [
            {
                label: '% ผู้ใช้ในพื้นที่',
                data: [
                    userArea.areaByFilterDusit.percentage,
                    userArea.areaByFilterBangsue.percentage,
                    userArea.areaByFilterBangphlat.percentage,
                    userArea.areaByFilterPhranakhon.percentage,
                    userArea.otherArea.percentage,
                ],
                backgroundColor: [
                    '#FF6384', // Dusit
                    '#36A2EB', // Bangsue
                    '#FFCE56', // Bangphlat
                    '#4BC0C0', // Phranakhon
                    '#9966FF', // Other areas
                ],
                hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                ],
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Disable the aspect ratio to customize size freely
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw.toFixed(2)}%`;
                    },
                },
            },
        },
    };

    return (
        <div style={{ width: '400px', height: '400px', margin: '0 auto' }}>
            <Pie data={data} options={options} />
        </div>
    );
};

export default AreaPieChart;

import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import moment from 'moment'
const DateTable = ({ statisticData }) => {
  const [dataSource, setDataSource] = useState(false)
  useEffect(() => {
    if (statisticData) {
      let tableData = []
      for (let i = 0; i < statisticData.length; i++) {
        tableData.push({ data: statisticData[i], id: i + 1 })
      }
      setDataSource(tableData)
    }
  }, [statisticData])

  const columns = [
    {
      title: 'วันที่ใช้งานระบบ',
      key: 'date',
      render: (record) => <p>{moment(record.data).format('DD/MM/YYYY HH:mm')}</p>,
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      align: 'center',
    },
  ]
  return <Table columns={columns} dataSource={dataSource} pagination={{ pageSize: 7 }}></Table>
}

export default DateTable

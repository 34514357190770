import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceHealthCare'
const apiEndpoint = '/UserInfos'

class UserInfo {
  @observable info = {
    data: [],
    error: null,
  }

  @action fetchUserInfos = async (isVerifyOnly = false) => {
    try {
      const filterVerifyOnly = '&filter[where][verifyId]=true'
      const filterOrder = '?filter[order]=userId%20DESC'
      const filter = isVerifyOnly ? `${filterOrder}${filterVerifyOnly}` : filterOrder
      const { data } = await http.get(`${apiEndpoint}${filter}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchUserInfoByPatientId = async (patientId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/userInfoByPatientId`, {
        params: {
          patientId,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchUserInfoByPatientCid = async (cId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/userInfoByPatientCid`, {
        params: {
          cId,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fecthUserInfoByCId = async (cId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/hiePatientInfo?cId=${cId}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchUserPaymentStatus = async (cid, vnNumber) => {
    try {
      const { data } = await http.get(`/labResultHies/getPaymentStatus?cid=${cid}&vnNumber=${vnNumber}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchUserVisitedByVerifiedFromHIE = async (patientId, vnNumber) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/checkUserVisitedByVerifiedFromHIE`, {
        params: {
          patientId,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action updateUserInfo = async (id, info) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${id}`, info)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action checkUserDetailByVerifiedFromHIE = async (patientId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/checkUserDetailByVerifiedFromHIE`, {
        params: { patientId },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }
  @action checkUserDetailFromHIEByUsingCid = async (cId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/checkUserDetailFromHIEByUsingCid`, {
        params: { cId },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action checkUserDetail = async (id) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/checkUserDetailFromHIE`, {
        params: {
          patientId: id,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action checkMedicalBenefit = async (patientId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/checkMedicalBenefit`, {
        params: {
          patientId,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action checkLatestMedicalBenefit = async (patientId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/checkLatestMedicalBenefit`, {
        params: {
          patientId,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action checkLatestMedicalBenefitByUsingCid = async (cId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/checkLatestMedicalBenefitByUsingCid`, {
        params: {
          cId,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action checkVisitNumber = async (patientId, selectedDate) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/checkVisitNumber`, {
        params: {
          patientId,
          selectedDate,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action checkUserVisitNumber = async (patientId, startDate, endDate) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/newCheckUserVisitedByVerifiedFromHIE`, {
        params: {
          patientId,
          startDate,
          endDate,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action newCheckUserVisitNumber = async (patientId, startDate, endDate) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/newCheckUserVisitedByVerifiedFromHIEFilterDiagnoses`, {
        params: {
          patientId,
          startDate,
          endDate,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action deleteUserInfo = async (id) => {
    try {
      const { data } = await http.delete(`${apiEndpoint}/${id}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchDetailByVisitNumber = async (patientId, visitNumber) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/checkDetailByVisitNumber`, {
        params: {
          patientId,
          visitNumber,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action checkDrugsDetailByOrderNo = async (patientId, orderNumber) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/checkDrugsDetailByOrderNo`, {
        params: {
          patientId,
          orderNumber,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action selectOrderNumber = async (patientId, selectedDate, employeeId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/selectOrderNumber`, {
        params: {
          patientId,
          selectedDate,
          employeeId,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action triggerUpdateData = async (patientId, vstDate) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/newTriggerUpdateData`, {
        params: {
          patientId,
          vstDate,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action triggerUpdateDataWithCid = async (cId, vstDate) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/newTriggerUpdateDataWithCid`, {
        params: {
          cId,
          vstDate,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action triggerUpdateDataCidDateRange = async (cId, vstDateFrom, vstDateTo) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/triggerUpdateDataByCId`, {
        params: {
          cId,
          vstDateFrom,
          vstDateTo,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action triggerUpdateDataWithPatientInfo = async (patientId, vstDate) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/TriggerUpdateData`, {
        params: {
          patientId,
          vstDate,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action cancelDeleteAccount = async (id) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${id}`, {
        toBeDeleted: false,
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }
  @computed get getUserInfo() {
    return toJS(this.info.data)
  }
}

export default new UserInfo()

import React, { useState, useEffect } from 'react'
import http from '../../services/httpServiceHealthCare'
import { Modal, Spin } from 'antd'
import PatientProfile from '../PatientProfile'
import moment from 'moment'
import { message } from 'antd'

const Telemonitoring = ({ visible, setVisible, isLoading, patientInfo, setIsLoading }) => {
  const [risk, setRisk] = useState('-')
  const [reports, setReports] = useState([])
  const [patientHie, setPatientHie] = useState(false)
  const [patient, setPatient] = useState(false)
  const [labs, setLabs] = useState([])

  useEffect(() => {
    mount()
    fetchUserVisitedByVerifiedFromHIE(patientInfo.patientId)
  }, [])

  const mount = () => {
    fetchUserDetail(patientInfo.user.id)
    fetchUserDetailFromHie(patientInfo.patientId)
    fetchMonitoringReport(patientInfo.patientId)
  }

  const fetchUserDetail = async (id) => {
    const { data } = await http.get(`/UserInfos/${id}`)
    if (data.thaiCVRick && data.thaiCVRick.result) {
      setRisk(data.thaiCVRick.result)
    }
    setPatient(data)
  }

  const fetchUserDetailFromHie = async (id) => {
    const response = await http.get(`/UserInfos/checkUserDetailFromHIE?patientId=${id}`)
    setPatientHie(response.data)
  }

  const fetchUserVisitedByVerifiedFromHIE = async (patientId) => {
    setIsLoading(true)
    const response = await http.get('/UserInfos/newCheckUserVisitedByVerifiedFromHIE', {
      params: { patientId },
    })
    if (response && response.data) {
      const filterLabData = filterLab(response)
      setLabs(filterLabData)
    }
    setIsLoading(false)
  }

  const triggerUpdateData = async (patientId) => {
    const currentDate = moment().format('YYYY-MM-DD')
    const lastYearDate = moment()
      .subtract(1, 'years')
      .format('YYYY-MM-DD')

    await http.get('/UserInfos/triggerUpdateData', {
      params: {
        patientId,
        vstDate_from: lastYearDate,
        vstDate_to: currentDate,
      },
    })
  }

  const filterLab = (labs) => {
    const filterLab = labs.data.docs.filter((doc) => doc.labResults.length)
    const labResults = filterLab.map((fil) => fil.labResults)
    const concatLabResults = [].concat.apply([], labResults)
    const labData = concatLabResults.map((result) => result.labReportData)
    const concatLabData = [].concat.apply([], labData)
    const filterLabData = concatLabData.filter((data) => new Date(data.orderDateTime) >= new Date(new Date().setFullYear(new Date().getFullYear() - 1)))
    return filterLabData
  }

  const fetchMonitoringReport = async (id) => {
    const response = await http.get(`/monitoringReports?filter[where][appUserId]=${id}`)
    if (response.data) {
      setReports(response.data)
    }
  }

  const updateRisk = async (result) => {
    if (patientId) {
      await http.patch(`/UserInfos/${patientId}`, {
        thaiCVRick: result,
      })
    }
  }
  const updateDiagnosisStartDate = async (data) => {
    if (patientInfo) {
      const response = await http.patch(`/UserInfos/${patientInfo.user.id}`, {
        diagnosisStartDate: data,
      })
      if (response) {
        message.success('กำหนดวันที่เริ่มวินิจฉัยสำเร็จ')
        mount()
      } else {
        message.error('เกิดข้อผิดพลาดในการส่งข้อมูล')
      }
    }
  }

  const updateYearlyAssesmentData = async (data) => {
    if (patientInfo) {
      const response = await http.patch(`/UserInfos/${patientInfo.user.id}`, {
        yearlyAssessment: data,
      })
      if (response) {
        message.success('บันทึกข้อมูลสำเร็จ')
        mount()
      } else {
        message.error('เกิดข้อผิดพลาดในการส่งข้อมูล')
      }
    }
  }

  return (
    <div>
      <Modal
        title="Telemonitoring"
        visible={visible}
        onOk={() => {
          setVisible(false)
        }}
        onCancel={() => {
          setVisible(false)
        }}
        footer={null}
        width="80vw"
      >
        <Spin spinning={isLoading} style={{ width: '100%' }}>
          <PatientProfile
            patient={patient}
            reports={reports}
            hie={patientHie}
            risk={risk}
            setRisk={(data) => setRisk(data)}
            updateRisk={(riskResult) => updateRisk(riskResult)}
            updateDiagnosisStartDate={(data) => updateDiagnosisStartDate(data)}
            updateYearlyAssesmentData={(data) => updateYearlyAssesmentData(data)}
            labs={labs}
            isLoading={isLoading}
          />
        </Spin>
      </Modal>
    </div>
  )
}

export default Telemonitoring

import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Typography, Modal, Avatar, message, Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import moment from 'moment'
import styled from 'styled-components'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'
import Chat from './Chat'
import ActionButtonSection from '../../components/ActionButtonSection'
import ReportProblemForm from '../../components/ReportProblemForm'
import httpEver from '../../services/httpServiceEver'
import { Data } from '@react-google-maps/api'
import Note from './Note'
const apiEndpoint = '/dashboardData'
const { Title } = Typography
const Container = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
`
function Summary(props) {
  const { date, note, patient, roomName, closeSummary, booking, history, updateBookingNote, roomNameWithOutDate, isTestVideoCall } = props
  const [duration, setDuration] = useState(0)
  const [doctor, setDoctor] = useState(false)
  const [actionStatus, setActionStatus] = useState(false)
  const [bookingId, setBookingId] = useState(false)
  const [visibleReportProblemModal, setVisibleReportProblemModal] = useState(false)
  const [bookingNotes, setBookingNotes] = useState([])
  const [clearNotes, setClearNotes] = useState(false)
  useEffect(() => {
    if (date) {
      createTime()
    }
  }, [date])

  useEffect(() => {
    fetchUser()
  }, [])

  const fetchUser = async () => {
    const data = jwtDecode(localStorage.getItem('token'))
    const response = await http.get(`users/${data.id}`)
    if (response && response.data) {
      setDoctor(response.data)
    }
  }

  const convertImage = (base64) => {
    if (base64) {
      return `data:image/png;base64,${base64}`
    }
    return 'https://thumbs.dreamstime.com/b/default-avatar-photo-placeholder-profile-picture-default-avatar-photo-placeholder-profile-picture-eps-file-easy-to-edit-125707135.jpg'
  }

  const createTime = () => {
    const diff = moment.duration(moment(Date.now()).diff(moment(date)))
    const hours = filterTime(diff.hours())
    const minutes = filterTime(diff.minutes())
    const seconds = filterTime(diff.seconds())
    setDuration(`${hours}:${minutes}:${seconds}`)
    updateVideocallTimeCount(patient, seconds, minutes, hours)
  }

  const updateVideocallTimeCount = async (patient, seconds, minutes, hours) => {
    let time = +seconds + +minutes * 60 + +hours * 3600
    const response = await httpEver.get(`${apiEndpoint}/?filter[where][userId]=${patient.id}`)
    if (response.data.length != 0) {
      const dashboardId = response.data[0].id
      const telemedicineTimeCount = response.data[0].telemedicineTimeCount + time
      const data = {
        telemedicineTimeCount,
      }
      await updateDashboard(dashboardId, data)
    } else {
      await createDashboard(patient.id, 0, 0, time, 0, 0)
    }
  }

  const createDashboard = async (userId, telemedicineCount, chatbotAiMessageCount, telemedicineTimeCount, chatbotMessageFromUserCount, emergencyCount) => {
    await httpEver.patch(apiEndpoint, {
      userId,
      telemedicineCount,
      chatbotAiMessageCount,
      telemedicineTimeCount,
      chatbotMessageFromUserCount,
      emergencyCount,
      gameCount: 0,
      diaryCount: 0,
      pickupByEmsCount: 0,
      smbgCount: 0,
      hbpmCount: 0,
      bookingStatusCount: 0,
      drugTakingCount: 0,
      drugTrackingCount: 0,
    })
  }

  const updateDashboard = async (dashboardId, data) => {
    await httpEver.patch(`${apiEndpoint}/${dashboardId}`, data)
  }

  const filterTime = (time) => {
    let text = '00'
    if (time) {
      text = `0${time}`
      if (time > 9) {
        text = time
      }
    }
    return text
  }

  const submitReportProblemForm = async (value) => {
    const res = await httpEver.patch(`/Bookings/${bookingId}`, {
      noteProblems: value,
      status: actionStatus,
    })
    if (res.status === 200) {
      message.success('ส่งเรื่องไปยังคอลเซ็นเตอร์เรียบร้อยแล้ว')
    }
    setVisibleReportProblemModal(false)
    closeSummary()
  }

  const clearReportProblems = async (id) => {
    const res = await httpEver.patch(`/Bookings/${id}`, {
      noteProblem: {},
    })
    setVisibleReportProblemModal(false)
    closeSummary()
  }

  const handleUpdateNote = () => {
    updateBookingNote(booking.id, bookingNotes)
  }

  return (
    <Container>
      <Row gutter={24}>
        <Col span={24}>
          <Row gutter={12}>
            <Col>
              <CloseOutlined style={{ cursor: 'pointer', fontSize: 24 }} onClick={closeSummary} />
            </Col>
            <Col>
              <Title level={3}>Summary</Title>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Card title="Info">
            <Row>
              <Col span={6}>
                <Title level={5}>Room: </Title>
              </Col>
              <Col span={18}>
                <p>{roomNameWithOutDate}</p>
              </Col>
              <Col span={6}>
                <Title level={5}>Duration: </Title>
              </Col>
              <Col span={18}>
                <p>{duration}</p>
              </Col>
              <Col span={6}>
                <Title level={5}>Created: </Title>
              </Col>
              <Col span={18}>
                <p>{date && moment(date).fromNow()}</p>
              </Col>
            </Row>
          </Card>
          <Card title="Participants (2)" style={{ marginTop: 20 }}>
            <Row gutter={24} style={{ marginTop: 20 }}>
              <Col>
                <Avatar size={64} src={convertImage(patient.imageBase64)} />
              </Col>
              <Col style={{ alignSelf: 'center' }}>
                <Title level={5}>
                  {patient && patient?.fullname ? `${patient?.fullname?.title ?? ''} ${patient?.fullname?.firstName ?? ''} ${patient?.fullname?.lastName ?? ''}` : `${patient?.user?.firstname ?? ''} ${patient?.user?.lastname ?? ''}`}
                </Title>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: 20 }}>
              <Col>
                <Avatar size={64} src={doctor?.profileImage} />
              </Col>
              <Col style={{ alignSelf: 'center' }}>
                <Title level={5}>{doctor?.fullname ?? ''}</Title>
              </Col>
            </Row>
          </Card>
          {!isTestVideoCall && (
            <Row justify="center" className="mt-4">
              <Col>
                <ActionButtonSection
                  history={history}
                  booking={booking}
                  setVisibleReportProblemModal={(status) => setVisibleReportProblemModal(status)}
                  setBookingId={(bookingId) => setBookingId(bookingId)}
                  setActionStatus={(status) => setActionStatus(status)}
                  clearReportProblems={(bookingId) => clearReportProblems(bookingId)}
                  refreshData={closeSummary}
                ></ActionButtonSection>
              </Col>
            </Row>
          )}
        </Col>
        <Col span={12}>
          {!isTestVideoCall && (
            <Card title="Notes" bodyStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <Note emitNote={(note) => setBookingNotes(note)} clearNotes={clearNotes} setClearNotes={(status) => setClearNotes(status)} bookingNotes={note} className="w-100" />
              <div className="p-3 pt-0 text-center">
                <Button type="primary" onClick={handleUpdateNote}>
                  บันทึกโน๊ต
                </Button>
              </div>
            </Card>
          )}
          <Card title="Chat" style={{ marginTop: 20 }}>
            <Chat patient={patient} booking={booking} isShow />
          </Card>
        </Col>
      </Row>
      <Modal
        visible={visibleReportProblemModal}
        onOk={() => {
          setVisibleReportProblemModal(false)
        }}
        onCancel={() => {
          setVisibleReportProblemModal(false)
        }}
        title="แจ้งปัญหา"
        footer={null}
      >
        <ReportProblemForm onSubmit={submitReportProblemForm}></ReportProblemForm>
      </Modal>
    </Container>
  )
}

export default Summary

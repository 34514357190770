import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Checkbox } from 'antd'
import { initialTable } from './constant'
import ToggleToken from '../../ToggleToken'
import './style.css'
import styled from 'styled-components'
const Text = styled('p')`
  font-weight: bold;
`
const TableHead = styled('th')`
  width: 100px;
`
const InjuryTable = ({ setFormValues, formValues, initialForm }) => {
  const [tableValues, setTableValues] = useState(initialTable)

  useEffect(() => {
    if (initialForm && initialForm.injuryPain) {
      const { injuryPain } = initialForm
      setTableValues((prev) => ({ ...prev, ...injuryPain }))
    }
  }, [initialForm])

  const renderInput = (bodyPart) => {
    const injuryType = ['deformity', 'disFx', 'contusion', 'abrasion', 'penetratingPuncture', 'laceration', 'swelling', 'tenderness', 'instability', 'crepitus', 'gunshot', 'blunt', 'burns', 'other']
    return injuryType.map((item) =>
      ['burns', 'other'].includes(item) ? (
        <td key={`${bodyPart}-${item}`}>
          <Input value={tableValues[item][bodyPart]} className="inj-table-add-txt-box" onChange={(e) => handleInputText(item, bodyPart, e.target.value)} />
        </td>
      ) : (
        <td
          key={`${bodyPart}-${item}`}
          role="checkbox"
          tabIndex="0"
          className="table-checkbox"
          data-checked={tableValues[item][bodyPart]}
          onKeyPress={(e) => handleTabCheck(e, item, bodyPart)}
          onClick={(e) => handleCheckbox(e.target, item, bodyPart)}
        ></td>
      ),
    )
  }
  const bool = (str) => str === 'true'
  const handleTabCheck = (e, injuryType, bodyPart) => {
    if (e.key == 'Enter') {
      handleCheckbox(e.target, injuryType, bodyPart)
      setTableValues((prevState) => ({ ...prevState, [injuryType]: { ...prevState[injuryType], [bodyPart]: didCheck } }))
    }
  }
  const handleCheckbox = (target, injuryType, bodyPart) => {
    const didCheck = !bool(target.dataset.checked)
    target.dataset.checked = `${didCheck}`
    setFormValues({ injuryPain: { ...formValues['injuryPain'], [injuryType]: { ...formValues['injuryPain'][injuryType], [bodyPart]: didCheck } } })
    setTableValues((prevState) => ({ ...prevState, [injuryType]: { ...prevState[injuryType], [bodyPart]: didCheck } }))
  }
  const handleInputText = (injuryType, bodyPart, value) => {
    setFormValues({ injuryPain: { ...formValues['injuryPain'], [injuryType]: { ...formValues['injuryPain'][injuryType], [bodyPart]: value } } })
    setTableValues((prevState) => ({ ...prevState, [injuryType]: { ...prevState[injuryType], [bodyPart]: value } }))
  }

  return (
    <div className="injury-table">
      <table className="mb-3 w-100">
        <thead>
          <tr className="head-section">
            <TableHead>Injury/Pain</TableHead>
            <td>
              <p>Deformity</p>
            </td>
            <td>
              <p>Dis/Fx.</p>
            </td>
            <td>
              <p>Contusion</p>
            </td>
            <td>
              <p>Abrasion</p>
            </td>
            <td>
              <p>Penetrating/puncture</p>
            </td>

            <td>
              <p>Laceration</p>
            </td>
            <td>
              <p>Swelling</p>
            </td>
            <td>
              <p>Tenderness</p>
            </td>
            <td>
              <p>Instability</p>
            </td>
            <td>
              <p>Crepitus</p>
            </td>
            <td>
              <p>Gunshot</p>
            </td>
            <td>
              <p>Blunt</p>
            </td>
            <td className="force-untilt">
              <p>Burns</p>
            </td>
            <td>
              <Input value={tableValues['other']['name']} className="inj-table-add-txt-box" onChange={(e) => handleInputText('other', 'name', e.target.value)} />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableHead>Head</TableHead>
            {renderInput('head')}
          </tr>
          <tr>
            <TableHead>Face</TableHead>
            {renderInput('face')}
          </tr>
          <tr>
            <TableHead>Neck</TableHead>
            {renderInput('neck')}
          </tr>
          <tr>
            <TableHead>Chest</TableHead>
            {renderInput('chest')}
          </tr>
          <tr>
            <TableHead>Abdomen</TableHead>
            {renderInput('abdomen')}
          </tr>
          <tr>
            <TableHead>Back/Frank</TableHead>
            {renderInput('backOrFrank')}
          </tr>
          <tr>
            <TableHead>Pelvis/Hip</TableHead>
            {renderInput('pelvisOrHip')}
          </tr>
          <tr>
            <TableHead>L Arm</TableHead>
            {renderInput('leftArm')}
          </tr>
          <tr>
            <TableHead>R Arm</TableHead>
            {renderInput('RightArm')}
          </tr>
          <tr>
            <TableHead>L Leg</TableHead>
            {renderInput('leftLeg')}
          </tr>
          <tr>
            <TableHead>R Leg</TableHead>
            {renderInput('RightLeg')}
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default InjuryTable

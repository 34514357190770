import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form, Input, Checkbox, Spin, InputNumber, Slider } from 'antd'
import ToggleToken from '../../ToggleToken'
import TimeInput from '../../TimeInput'
const Pain = ({ formValues, handlePainValue }) => {
  return (
    <Row tpye="flex">
      <Col xs={24} md={8}>
        <div className="form-panel p-3">
          <div>
            <label htmlFor="onset">Onset</label>
            <Input
              value={formValues['pain']['onSet']}
              onChange={(e) => {
                handlePainValue('onSet', e.target.value)
              }}
            />
          </div>
          <div>
            <label htmlFor="provoke">Provoke</label>
            <Input
              value={formValues['pain']['provoke']}
              onChange={(e) => {
                handlePainValue('provoke', e.target.value)
              }}
            />
          </div>
        </div>
      </Col>
      <Col xs={24} md={8}>
        <div className="form-panel p-3">
          <ToggleToken
            title="Quality"
            options={['Sharp', 'Dull', 'Cramp']}
            values={formValues['pain']['quality']}
            setValues={(val) => {
              handlePainValue('quality', val)
            }}
            noData="N/A"
          />
        </div>
      </Col>
      <Col xs={24} md={8}>
        <div className="form-panel p-3">
          <ToggleToken
            title="Radiate"
            toStr={true}
            max={1}
            options={['No', 'Yes']}
            extraInput="Yes"
            values={formValues['pain']['radiate']}
            setValues={(val) => {
              handlePainValue('radiate', val)
            }}
          />
        </div>
      </Col>
      <Col xs={24} md={8}>
        <div className="form-panel p-3">
          <h4>Severity</h4>
          <div className="flex">
            <Slider
              min={0}
              max={10}
              onChange={(val) => {
                handlePainValue('severity', val)
              }}
              value={typeof formValues['pain']['severity'] === 'number' ? formValues['pain']['severity'] : 0}
            />
            <InputNumber
              min={0}
              max={10}
              style={{ margin: '0 16px' }}
              value={formValues['pain']['severity']}
              onChange={(val) => {
                handlePainValue('severity', val)
              }}
            />
          </div>
        </div>
      </Col>
      <Col xs={24} md={8}>
        <div className="form-panel p-3">
          <h4>Time</h4>
          <TimeInput value={formValues['pain']['time']} onInput={(e) => handlePainValue('time', e.target.value)} />
        </div>
      </Col>
    </Row>
  )
}

export default Pain

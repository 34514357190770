import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Divider, Card, Typography, Form, Input, Radio, InputNumber, Modal, Spin, Table, Popconfirm, Select, Checkbox, message } from 'antd'
import { initialValues, formTemplate } from './constant'
import TimeTable from './TimeTable'
import ToggleToken from '../../ToggleToken'
import moment from 'moment'
import { get } from 'lodash'
import { DatePicker } from '../../Neo'
const index = ({ ambulanceList, emsTypologiesData = [], formDetail, updateEmsForm, formValues, setFormValues }) => {
  const [form] = Form.useForm()
  const [IDC_RC_CODE, SET_IDC_RC_CODE] = useState([])
  const [MEDICAL_EMERGENCY_STAFF, SET_MEDICAL_EMERGENCY_STAFF] = useState([])
  const [PARAMEDIC_NURSE, SET_PARAMEDIC_NURSE] = useState([])
  const [initialForm, setInitialForm] = useState({})
  const [newStaff, setNewStaff] = useState({ paramedicDoctor: { number: '701', name: '' }, emergencyStaff: { number: '', name: '' } })
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (emsTypologiesData.length > 0) {
      handleEmsTypologies(emsTypologiesData)
    }
  }, [emsTypologiesData])

  useEffect(() => {
    if (Object.keys(formDetail).length !== 0) {
      setDefaultValue()
    }
  }, [formDetail])

  useEffect(() => {
    handleStaffText()
  }, [newStaff])

  const setDefaultValue = () => {
    const { responseData } = formDetail
    let initial = {
      areaOfResponsibility: get(responseData, 'areaOfResponsibility', ''),
      idcCode: get(responseData, 'idcCode', ''),
      injuryByOperation: get(responseData, 'injuryByOperation', ''),
      medicalEmergencyStaff: get(responseData, 'medicalEmergencyStaff', []),
      paramedic: get(responseData, 'paramedic', []),
      notifiedSymptoms: get(responseData, 'notifiedSymptoms', ''),
      operationDateTime: responseData && responseData.operationDateTime ? moment(responseData.operationDateTime) : null,
      operationNo: get(responseData, 'operationNo', ''),
      policeStationArea: get(responseData, 'policeStationArea', ''),
      rcCode: get(responseData, 'rcCode', ''),
      sceneDescription: get(responseData, 'sceneDescription', ''),
      sceneSite: get(responseData, 'sceneSite', ''),
      vehicle: get(responseData, 'vehicle', ''),
      vehicleType: get(responseData, 'vehicleType', ''),
      paramedicDoctor: get(responseData, 'paramedicDoctor', ''),
      emergencyStaff: get(responseData, 'emergencyStaff', ''),
      travelLog: {
        time: {
          erawanReceiveCase: handleTime('travelLog.time.erawanReceiveCase'),
          vajiraReceiveCase: handleTime('travelLog.time.vajiraReceiveCase', 'actionWaiting'),
          ordered: handleTime('travelLog.time.ordered', 'exitWaiting'),
          leaveBase: handleTime('travelLog.time.leaveBase', 'moving'),
          arrivedScene: handleTime('travelLog.time.arrivedScene', 'arrived'),
          leaveScene: handleTime('travelLog.time.leaveScene', 'completedSending'),
          arrivedHospital: handleTime('travelLog.time.arrivedHospital', 'inHospital'),
          arrivedBase: handleTime('travelLog.time.arrivedBase', 'completed'),
        },
        totalTime: {
          activateTime: get(responseData, 'travelLog.totalTime.activateTime', 0),
          responseTime: get(responseData, 'travelLog.totalTime.responseTime', 0),
          onSceneTime: get(responseData, 'travelLog.totalTime.onSceneTime', 0),
          leaveSceneToHospitalTime: get(responseData, 'travelLog.totalTime.leaveSceneToHospitalTime', 0),
          hospitalToBaseTime: get(responseData, 'travelLog.totalTime.hospitalToBaseTime', 0),
        },
        kilometers: {
          beforeLeaveBase: get(responseData, 'travelLog.kilometers.beforeLeaveBase', ''),
          beforeLeaveScene: get(responseData, 'travelLog.kilometers.beforeLeaveScene', ''),
          arrivedHospital: get(responseData, 'travelLog.kilometers.arrivedHospital', ''),
          arrivedBase: get(responseData, 'travelLog.kilometers.arrivedBase', ''),
        },
        distance: {
          fromBaseToScene: get(responseData, 'travelLog.distance.fromBaseToScene', ''),
          fromSceneToHospital: get(responseData, 'travelLog.distance.fromSceneToHospital', ''),
          fromHospitalToBase: get(responseData, 'travelLog.distance.fromHospitalToBase', ''),
        },
        atReason: get(responseData, 'travelLog.atReason', ''),
        rtReason: get(responseData, 'travelLog.rtReason', ''),
        ostReason: get(responseData, 'travelLog.ostReason', ''),
      },
    }
    setFormValues(initial)
    handleSetInitialStaff(initial.paramedicDoctor, initial.emergencyStaff)
    setInitialForm(initial)
    setLoading(false)
  }

  const handleTime = (field, status) => {
    const { responseData } = formDetail
    if (status) {
      const time = get(responseData, field, null)
      return time ? time : filterDate(status) ? filterDate(status).toISOString() : null
    }
    const time = get(responseData, field, null)
    return time
  }
  const handleSetInitialStaff = (doctor, staff) => {
    let paramedicDoctor = {}
    let emergencyStaff = {}
    if (doctor) {
      paramedicDoctor['number'] = doctor.split(' - ')[0]
      paramedicDoctor['name'] = doctor.split(' - ')[1]
      setNewStaff((prevState) => ({ ...prevState, ['paramedicDoctor']: { number: paramedicDoctor['number'], name: paramedicDoctor['name'] } }))
    }
    if (staff) {
      emergencyStaff['number'] = staff.split(' - ')[0]
      emergencyStaff['name'] = staff.split(' - ')[1]
      setNewStaff((prevState) => ({ ...prevState, ['emergencyStaff']: { number: emergencyStaff['number'], name: emergencyStaff['name'] } }))
    }
  }

  const filterDate = (status) => {
    const detailData = formDetail.stateTimeStamp.filter((item) => item.status === status && item)[0]
    const dateTime = detailData && detailData.date ? moment(detailData.date) : null
    return dateTime
  }

  const handleEmsTypologies = (emsTypologiesData) => {
    const IDC = emsTypologiesData.filter((item) => item.field === 'IDC_RC_CODE')
    const STAFF = emsTypologiesData.filter((item) => item.field === 'MEDICAL_EMERGENCY_STAFF')
    const NURSE = emsTypologiesData.filter((item) => item.field === 'PARAMEDIC_NURSE')
    SET_IDC_RC_CODE(IDC)
    SET_MEDICAL_EMERGENCY_STAFF(STAFF)
    SET_PARAMEDIC_NURSE(NURSE)
  }

  const handleOptionDropdown = (type, items) => {
    let data = []
    const ambulanceListSorting = [...ambulanceList.filter((item) => [25, 26, 27, 29].includes(item.no)), ...ambulanceList.filter((item) => ![25, 26, 27, 29].includes(item.no))]
    switch (type) {
      case 'idcCode':
      case 'rcCode':
        data = IDC_RC_CODE
        break
      case 'vehicle':
        data = ambulanceListSorting
        break
      default:
        data = items
        break
    }
    return data.map((item, index) =>
      type === 'vehicle' ? (
        <Select.Option key={index} value={`${item.key}`}>
          {`${item.key}${item.licensePlate ? ` - ${item.licensePlate}` : ''}`}
        </Select.Option>
      ) : (
        <Select.Option key={index} value={item.label}>
          {item.label}
        </Select.Option>
      ),
    )
  }

  const onFinish = (value) => {
    console.log(value)
    // updateEmsForm('responseData', { responseData: value, operationDateTime: initialForm.operationDateTime })
  }

  const handleMultipleDropdownOption = (value, items) => {
    let data = []
    switch (value) {
      case 'paramedic':
        data = PARAMEDIC_NURSE
        break
      case 'medicalEmergencyStaff':
        data = MEDICAL_EMERGENCY_STAFF
        break
      default:
        data = items
        break
    }
    return data.map((item, index) => (
      <Select.Option value={item.label} label={item.label} key={item.id}>
        {item.label}
      </Select.Option>
    ))
  }

  const handleStaffText = () => {
    if (!newStaff['paramedicDoctor']['name']) {
      setFormValues({ paramedicDoctor: '' })
    } else {
      setFormValues({ paramedicDoctor: `${'701 - '}${newStaff['paramedicDoctor']['name']}`, emergencyStaff: `${newStaff['emergencyStaff']['number'] + ' - '}${newStaff['emergencyStaff']['name']}` })
    }
  }

  return loading ? (
    <div className="w-100 d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
      <Spin></Spin>
    </div>
  ) : (
    <Form form={form} name="ems_section1" initialValues={initialForm ? initialForm : initialValues} layout="vertical" onFinish={onFinish}>
      {formTemplate.map((template, templateIndex) => (
        <>
          {template.header}
          <Row className="mt-4" gutter={[25, 1]} key={templateIndex}>
            {template.data.map((data) => (
              <Col xs={24} md={12} key={data.label}>
                <Form.Item
                  name={data.value}
                  label={data.label}
                  rules={[
                    {
                      required: data.required,
                      message: `${data.requiredMessage}`,
                    },
                  ]}
                  style={{ width: '100%' }}
                >
                  {data.type === 'input' ? (
                    <Input onChange={(event) => setFormValues({ [data.value]: event.target.value })} style={{ width: '100%' }} placeholder={data.placeholder ? data.placeholder : ''} />
                  ) : data.type === 'dropdown' ? (
                    <Select
                      onChange={(value) => setFormValues({ [data.value]: value })}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      placeholder={data.placeholder ? data.placeholder : ''}
                      dropdownMatchSelectWidth
                      style={{ width: '100%' }}
                    >
                      {handleOptionDropdown(data.value, data.items ? data.items : [])}
                    </Select>
                  ) : data.type === 'radio' ? (
                    <ToggleToken
                      toStr={true}
                      max={1}
                      options={data.items}
                      values={formValues[data.value]}
                      extraInputs={['อื่นๆ']}
                      setValues={(value) => {
                        setFormValues({ [data.value]: value })
                      }}
                    />
                  ) : data.type === 'date' ? (
                    <DatePicker
                      value={formValues[data.value]}
                      mode="datetime"
                      calendar="BE"
                      onChange={(value) => {
                        setFormValues({ [data.value]: value })
                      }}
                    />
                  ) : data.type === 'multipleDropdown' ? (
                    <Select
                      onChange={(value) => setFormValues({ [data.value]: value })}
                      mode="multiple"
                      style={{
                        width: '100%',
                      }}
                      placeholder={data.placeholder ? data.placeholder : ''}
                      optionLabelProp="label"
                    >
                      {handleMultipleDropdownOption(data.value, data.items ? data.items : [])}
                    </Select>
                  ) : (
                    data.type === 'staffText' && (
                      <Row gutter={[10, 0]}>
                        <Col span={6}>
                          {data.value === 'paramedicDoctor' ? (
                            <Input value={newStaff[data.value]['number']} disabled />
                          ) : (
                            <Input
                              value={newStaff[data.value]['number']}
                              onChange={(e) => {
                                setNewStaff((prevState) => ({ ...prevState, [data.value]: { ...prevState[data.value], number: e.target.value } }))
                              }}
                            />
                          )}
                        </Col>
                        <Col span={16}>
                          <Input
                            placeholder={data.placeholder ? data.placeholder : ''}
                            value={newStaff[data.value]['name']}
                            onChange={(e) => {
                              setNewStaff((prevState) => ({ ...prevState, [data.value]: { ...prevState[data.value], name: e.target.value } }))
                            }}
                          />
                        </Col>
                      </Row>
                    )
                  )}
                </Form.Item>
              </Col>
            ))}
          </Row>
        </>
      ))}
      <Form.Item name={'travelLog'} label={''}>
        <TimeTable setFormValue={setFormValues} formValues={initialForm} />
      </Form.Item>
    </Form>
  )
}

export default index

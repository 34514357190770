export const infoForm = [
    {
      name : 'idcCode',
      label : 'IDC Code',
      dataValue : (val,val2,val3) => {
        val.setFieldsValue({ idcCode: val2 })
        val.setFieldsValue({ idcLabel: val3 })
      },
      options : [
        { 
          value : 'test',
          text : 'test1'
        },
        { 
          value : 'test2',
          text : 'test2'
        }
      ]
    },
    {
      name : 'emsType',
      label : 'การรับแจ้ง',
      dataValue : (val,val2) => val.setFieldsValue({ emsType: val2 }),
      options : [
        { 
          value : 'erawan',
          text : 'ศูนย์เอราวัณ'
        },
        { 
          value : '1554',
          text : '1554'
        }
      ]
    },
    {
      name : 'emsCar',
      label : 'รถพยาบาล',
      dataValue : (val) => val,
    }
  ]
import React, { Component } from 'react'
import Layout from '../Home'
import ChangePasswordForm from '../../components/ChangePasswordForm'
import { FileImageOutlined, LeftOutlined } from '@ant-design/icons'
import { Menu, Popconfirm, Row, Col, Modal, Tooltip, Input, message, Alert, Spin, Tag, Tabs, Select } from 'antd'
import { Icon } from '@ant-design/compatible'
import Button from '../../components/Button'
import jwtDecode from 'jwt-decode'
import 'react-html5-camera-photo/build/css/index.css'
import { inject, observer } from 'mobx-react'
import firebase from 'firebase'
import AddDepartmentModal from '../../components/AddDepartmentModal'
import httpEver from '../../services/httpServiceEver'
import { PRACTITIONER_ROLES } from '../../constant'
import TextBase from '../../components/Text'
import styled from 'styled-components'
const Text = styled(TextBase)`
  font-weight: bold;
`

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}

@inject('DoctorLive')
@inject('Booking')
@inject('User')
@inject('Store')
@inject('PharmacyStore')
@inject('TrackingData')
@inject('UserInfo')
@inject('AppUserAndHospital')
@inject('AppUserAndOtherType')
@observer
export default class PatientBooking extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.onClickOutSide = this.onClickOutSide.bind(this)
    this.state = {
      dataSource: [],
      visibleModalProfileDetail: false,
      user: {},
      detailHeaders: [],
      detail: {},
      showDetail: [],
      fullname: '',
      roles: [],
      changeProfileName: false,
      pharmacyStore: {},
      dashboard: true,
      visibleModalChangePassword: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      isDashboard: true,
      isLoading: false,
      newAlertStatus: 0,
      showChangePassword: false,
      doctorTypes: [],
      otherTypes: [],
      departmentId: null,
      isShowDepartmentModal: false,
    }
  }

  componentDidMount() {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        document.addEventListener('mousedown', this.onClickOutSide)
        const { User } = this.props
        const user = jwtDecode(localStorage.getItem('token'))
        const userRes = await User.fetchUserInfo(user.id)
        const profileDetail = {
          no: (userRes && userRes.detail && userRes.detail.no) || '',
          educational: (userRes && userRes.detail && userRes.detail.educational) || '',
          hospital: (userRes && userRes.detail && userRes.detail.hospital) || '',
          department: (userRes && userRes.detail && userRes.detail.department) || '',
          specialist: (userRes && userRes.detail && userRes.detail.specialist) || '',
          employeeId: (userRes && userRes.employeeId) || '',
          tel: (userRes && userRes.tel) || '',
        }
        this.setDetailHeader(user.roles[0])
        this.setState({
          // dataSource: response,
          roles: user.roles,
          fullname: userRes ? userRes.fullname : null,
          detail: profileDetail,
          showDetail: this.setShowDetail(userRes),
          user: userRes ? userRes : null,
          isLoading: false,
        })
      },
    )
  }

  setDetailHeader = (roles) => {
    const telLabel = roles === 'doctor' ? 'เบอร์ติดต่อแพทย์' : 'เบอร์ติดต่อ'
    let details = [
      { label: 'เลขที่ใบประกอบวิชาชีพ', value: 'no' },
      { label: 'วุฒิการศึกษา', value: 'educational' },
      { label: 'โรงพยาบาลประจำ', value: 'hospital' },
      { label: 'แผนก', value: 'department' },
      { label: 'เชี่ยวชาญ', value: 'specialist' },
      { label: telLabel, value: 'tel' },
    ]
    if (roles === 'doctor') {
      details = [...details, { label: 'หมายเลขหมอ', value: 'employeeId' }]
    }
    this.setState({
      detailHeaders: details,
    })
  }

  setShowDetail = (resData) => {
    const roles = resData ? resData.roles[0].name : 'doctor'
    const telLabel = roles === 'doctor' ? 'เบอร์ติดต่อแพทย์' : 'เบอร์ติดต่อ'
    let details = [
      { icon: 'info-circle', label: 'เลขที่ใบประกอบวิชาชีพ', value: (resData && resData.detail && resData.detail.no) || '' },
      { icon: 'info-circle', label: 'วุฒิการศึกษา', value: (resData && resData.detail && resData.detail.educational) || '' },
      { icon: 'info-circle', label: 'โรงพยาบาลประจำ', value: (resData && resData.detail && resData.detail.hospital) || '' },
      { icon: 'info-circle', label: 'แผนก', value: (resData && resData.detail && resData.detail.department) || '' },
      { icon: 'info-circle', label: 'เชี่ยวชาญ', value: (resData && resData.detail && resData.detail.specialist) || '' },
      { icon: 'phone', label: telLabel, value: (resData && resData.tel) || '' },
    ]
    if (roles.includes('doctor')) {
      details = [...details, { icon: 'info-circle', label: 'หมายเลขหมอ', value: (resData && resData.employeeId) || '' }]
    }
    return details
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutSide)
  }

  async onClickOutSide(e) {
    const { User } = this.props
    if (this.inputRef.current && !this.inputRef.current.contains(e.target)) {
      this.setState({ changeProfileName: false })
      if (this.state.fullname !== this.state.user.fullname) {
        const { id } = jwtDecode(localStorage.getItem('token'))
        const userRes = await User.updateName(id, this.state.fullname)
        if (userRes) {
          message.success('แก้ไขชื่อสำเร็จ')
          this.setState({
            user: userRes,
          })
        }
      }
    }
  }

  handleChange = (e, isDepartmentData) => {
    let detailName = null
    let detailValue = null
    if (isDepartmentData) {
      const departmentId = parseInt(e.split(',')[0])
      detailName = 'department'
      detailValue = e.split(',')[1]
      this.setState({
        departmentId: departmentId,
      })
      this.setState((prevState) => ({
        detail: {
          ...prevState.detail,
          [detailName]: detailValue,
          typeId: departmentId,
        },
      }))
    } else {
      const { name, value } = e.target
      detailName = name
      detailValue = value
      this.setState((prevState) => ({
        detail: {
          ...prevState.detail,
          [detailName]: detailValue,
        },
      }))
    }
  }

  handleSubmit = async () => {
    const { User } = this.props
    const { id, roles } = jwtDecode(localStorage.getItem('token'))
    const { detail } = this.state
    let info = {
      employeeId: detail.employeeId,
      detail: {
        no: detail.no,
        educational: detail.educational,
        hospital: detail.hospital,
        department: detail.department,
        specialist: detail.specialist,
      },
      tel: detail.tel,
    }
    if (roles[0] === 'doctor') {
      info.doctorTypeId = detail.typeId
    } else {
      info.otherTypeId = detail.typeId
    }
    const response = await User.updateProfileDetail(id, info)

    if (response) {
      if (this.state.departmentId) {
        await this.updateInfoAppUserType()
      }
      message.success('แก้ไขข้อมูลสำเร็จ')
      this.setState({
        detail: response.detail,
        showDetail: this.setShowDetail(response),
        visibleModalProfileDetail: false,
      })
    } else {
      message.error('แก้ไขข้อมูลไม่สำเร็จ')
    }
  }

  updateInfoAppUserType = async () => {
    const { AppUserAndHospital, AppUserAndOtherType } = this.props
    const { id, roles } = jwtDecode(localStorage.getItem('token'))
    let response
    if (roles[0] === 'doctor') {
      const appUserId = await AppUserAndHospital.getUserDoctorTypeId(id)
      if (appUserId) {
        response = await AppUserAndHospital.updateDoctorType(appUserId, this.state.departmentId)
        message.success('ย้ายแผนกสำเร็จ')
      } else {
        message.error('ย้ายแผนกล้มเหลว')
      }
    } else {
      const appUserId = await AppUserAndOtherType.getUserOtherTypeId(id)
      if (appUserId) {
        response = await AppUserAndOtherType.updateOtherType(appUserId, this.state.departmentId)
        message.success('ย้ายแผนกสำเร็จ')
      } else {
        message.error('ย้ายแผนกล้มเหลว')
      }
    }
  }

  modalEditUserDetail = () => {
    const { userInfo } = this.props.props
    const roles = this.state.roles[0]
    return this.state.detailHeaders.map((detailHeader, index) => (
      <Row key={index} style={{ padding: '5px' }}>
        {detailHeader.label === 'แผนก' ? (
          <>
            <Col className="gutter-row" span={6}>
              {detailHeader.label}
            </Col>
            <Col className="gutter-row" span={18}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                style={{ width: '100%' }}
                name={detailHeader.value}
                defaultValue={userInfo && userInfo.detail ? userInfo.detail[detailHeader.value] : ''}
                onChange={(value) => this.handleChange(value, true)}
              >
                {roles === 'doctor'
                  ? this.state.doctorTypes.map((type) => (
                      <Option key={type.id} value={`${type.id},${type.name}`}>
                        {type.name}
                      </Option>
                    ))
                  : this.state.otherTypes.map((type) => (
                      <Option key={type.id} value={`${type.id},${type.name}`}>
                        {type.name}
                      </Option>
                    ))}
              </Select>
            </Col>
          </>
        ) : (
          <>
            <Col className="gutter-row" span={6}>
              {detailHeader.label}
            </Col>
            {['หมายเลขหมอ', 'เบอร์ติดต่อแพทย์', 'เบอร์ติดต่อ'].includes(detailHeader.label) ? (
              <Col className="gutter-row" span={18}>
                <Tooltip>
                  <Input defaultValue={(userInfo && userInfo[detailHeader.value]) || ''} onChange={this.handleChange} name={detailHeader.value} />
                </Tooltip>
              </Col>
            ) : this.state.detail ? (
              <Col className="gutter-row" span={18}>
                <Tooltip>
                  <Input defaultValue={userInfo && userInfo.detail ? userInfo.detail[detailHeader.value] : ''} onChange={this.handleChange} name={detailHeader.value} />
                </Tooltip>
              </Col>
            ) : null}
          </>
        )}
      </Row>
    ))
  }

  onSubmitChangePassword = async (value) => {
    const { User } = this.props
    const response = await User.changePassword(value.oldPassword, value.newPassword)
    if (response.statusCode === 400) {
      message.error(`${response.name}: ${response.message}`)
    } else {
      message.success('เปลี่ยนรหัสผ่านสำเร็จ')
    }
    this.setState({
      visibleModalChangePassword: false,
    })
  }

  handleChangeProfileImage = (e) => {
    const { User } = this.props
    const { id } = jwtDecode(localStorage.getItem('token'))
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > 2097152) {
        message.error('เกิดข้อผิดพลาด กรุณาอัปโหลดรูปภาพที่มีขนาดไม่เกิน 2MB')
      } else {
        const fileUpload = e.target.files[0]
        const fileName = e.target.files[0].lastModified.toString()
        const storageRef = firebase.storage().ref(fileName)
        const task = storageRef.put(fileUpload)
        message.warning('กำลังอัปโหลดรูปภาพ', 1)
        task.on(
          `state_changed`,
          (snapshort) => {
            // let percentage = (snapshort.bytesTransferred / snapshort.totalBytes) * 100
            //Process
          },
          (error) => {
            //Error
            message.error(error.message)
          },
          async () => {
            //Success
            message.success('อัปโหลดรูปสำเร็จ')
            const image = await storageRef.getDownloadURL()
            const response = await User.updateProfileImage(id, image)
            this.setState((prevState) => ({
              user: {
                ...prevState.user,
                profileImage: image,
              },
            }))
          },
        )
      }
    }
  }

  addDepartmentForm = async (department) => {
    const role = this.state.roles[0]
    const apiEndpoint = role.includes('doctor') ? '/DoctorTypes' : 'OtherTypes'
    const data = {
      role: role,
      name: department.name,
    }
    const res = await httpEver.post(apiEndpoint, data)
    if (res.status === 200) {
      message.success(`เพิ่มแผนก ${res.data.name} เรียบร้อยแล้ว`)
    } else {
      message.error('เพิ่มแผนกไม่สำเร็จ')
    }
    this.setState({
      isShowDepartmentModal: false,
    })
  }

  render() {
    const { userInfo, history } = this.props.props
    const { isLoading, roles, visibleModalCreateStore } = this.state
    return (
      <div style={{ width: '100%' }}>
        <Row>
          {PRACTITIONER_ROLES.includes(roles[0]) && (
            <Col lg={24} xl={6} style={{ backgroundColor: '#ffffff', height: '100%' }}>
              <Row
                gutter={16}
                style={{
                  borderBottom: '1px solid #d3d3d3',
                  borderTop: '1px solid #d3d3d3',
                  padding: '30px 15px',
                  margin: '0px',
                }}
              >
                <Col xs={{ span: 6 }}>
                  <div>
                    <label htmlFor="upload-button">{this.state.user && this.state.user.profileImage ? <img src={this.state.user.profileImage} style={{ cursor: 'pointer' }} width="100" height="100" /> : <FileImageOutlined />}</label>
                    <input type="file" accept="image/png, image/jpeg" id="upload-button" style={{ display: 'none' }} onChange={this.handleChangeProfileImage} />
                    <br />
                  </div>
                </Col>
                <Col style={{ overflowWrap: 'break-word' }} xs={{ span: 18 }}>
                  <div style={{ fontWeight: 'bold', fontSize: 18 }}>
                    {this.state.changeProfileName ? (
                      <input
                        ref={this.inputRef}
                        value={this.state.fullname}
                        onChange={(e) => {
                          this.setState({ fullname: e.target.value })
                        }}
                      />
                    ) : this.state.fullname ? (
                      <span onClick={() => this.setState({ changeProfileName: true })}>{this.state.fullname}</span>
                    ) : null}
                  </div>
                  <div>{userInfo && userInfo.email}</div>
                </Col>
                <Col span={24}>
                  <p style={{ color: 'red', fontSize: '12px' }}>* สูงสุด 2MB. ประเภท .png .jpeg</p>
                </Col>
                <Col span={24} className="text-right">
                  <a
                    href="#"
                    style={{ fontSize: '12px' }}
                    onClick={() =>
                      this.setState({
                        visibleModalChangePassword: true,
                      })
                    }
                  >
                    เปลี่ยนรหัสผ่าน
                  </a>
                </Col>
              </Row>
              {PRACTITIONER_ROLES.includes(roles[0]) && (
                <Menu theme="light" mode="inline" defaultSelectedKeys={['1']}>
                  {this.state.showDetail &&
                    this.state.showDetail.map((item, index) => {
                      if (item !== '') {
                        return (
                          <Menu.Item key={`nav-user-${index}`}>
                            <Tooltip title={`${item.label} : ${item.value}`}>
                              <Icon type={item.icon} />
                              <span className="font-weight-bold">{`${item.label} : `}</span>
                              <span>{item.value}</span>
                            </Tooltip>
                          </Menu.Item>
                        )
                      }
                    })}
                  <div className="text-center" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <Button
                      type="primary"
                      ghost
                      onClick={async () => {
                        const { User } = this.props
                        const roles = this.state.roles[0]
                        await User.fetchDoctorTypes()
                        await User.fetchOtherTypes()
                        const doctorTypes = User.getDoctorTypes
                        const otherTypesData = User.getOtherTypes.filter((item) => item.role === roles)
                        this.setState({ visibleModalProfileDetail: true, doctorTypes: doctorTypes, otherTypes: otherTypesData })
                      }}
                    >
                      แก้ไขข้อมูล
                    </Button>
                    <Button
                      type="primary"
                      className="ml-3"
                      onClick={() => {
                        this.setState({
                          isShowDepartmentModal: true,
                        })
                      }}
                    >
                      เพิ่มแผนกใหม่
                    </Button>
                  </div>
                </Menu>
              )}
            </Col>
          )}
        </Row>
        <Modal
          visible={this.state.isShowDepartmentModal}
          onOk={() => {
            this.setState({
              isShowDepartmentModal: false,
            })
          }}
          onCancel={() => {
            this.setState({
              isShowDepartmentModal: false,
            })
          }}
          title="เพิ่มแผนก"
          footer={null}
        >
          <AddDepartmentModal onSubmit={this.addDepartmentForm}></AddDepartmentModal>
        </Modal>
        <Modal visible={this.state.visibleModalChangePassword} onOk={() => this.setState({ visibleModalChangePassword: false })} onCancel={() => this.setState({ visibleModalChangePassword: false })} title="เปลี่ยนรหัสผ่าน" footer={null}>
          <ChangePasswordForm onSubmit={this.onSubmitChangePassword} />
        </Modal>
        <Modal
          visible={this.props.visibleModalProfile}
          onOk={() => {
            this.props.setVisibleModalProfile(false)
            this.setState({ showChangePassword: false })
          }}
          onCancel={() => {
            this.props.setVisibleModalProfile(false)
            this.setState({ showChangePassword: false })
          }}
          title={
            <Row gutter={24}>
              {this.state.showChangePassword && (
                <Col>
                  <LeftOutlined style={{ cursor: 'pointer' }} onClick={() => this.setState({ showChangePassword: false })} />
                </Col>
              )}
              <Col style={{ marginTop: 3 }}>{this.state.showChangePassword ? 'เปลี่ยนรหัสผ่าน' : 'โปรไฟล์'}</Col>
            </Row>
          }
          footer={null}
        >
          {!this.state.showChangePassword ? (
            <>
              <Row
                gutter={16}
                style={{
                  padding: '30px 15px',
                  margin: '0px',
                }}
              >
                <Col xs={{ span: 6 }}>
                  <div>
                    <label htmlFor="upload-button">{this.state.user && this.state.user.profileImage ? <img src={this.state.user.profileImage} style={{ cursor: 'pointer' }} width="100" height="100" /> : <FileImageOutlined />}</label>
                    <input type="file" accept="image/png, image/jpeg" id="upload-button" style={{ display: 'none' }} onChange={this.handleChangeProfileImage} />
                    <br />
                  </div>
                </Col>
                <Col style={{ overflowWrap: 'break-word' }} xs={{ span: 18 }}>
                  <div style={{ fontWeight: 'bold', fontSize: 18 }}>
                    {this.state.changeProfileName ? (
                      <input
                        ref={this.inputRef}
                        value={this.state.fullname}
                        onChange={(e) => {
                          this.setState({ fullname: e.target.value })
                        }}
                      />
                    ) : this.state.fullname ? (
                      <span onClick={() => this.setState({ changeProfileName: true })}>{this.state.fullname}</span>
                    ) : null}
                  </div>
                  <div>{userInfo && userInfo.email}</div>
                </Col>
                <Col span={24}>
                  <p style={{ color: 'red', fontSize: '12px' }}>* สูงสุด 2MB. ประเภท .png .jpeg</p>
                </Col>
                <Col span={24} className="text-right">
                  <a
                    href="#"
                    style={{ fontSize: '12px' }}
                    onClick={() =>
                      this.setState({
                        showChangePassword: true,
                      })
                    }
                  >
                    เปลี่ยนรหัสผ่าน
                  </a>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <ChangePasswordForm onSubmit={this.onSubmitChangePassword} />
            </>
          )}
        </Modal>
        <Modal
          visible={this.state.visibleModalProfileDetail}
          onOk={() => this.setState({ visibleModalProfileDetail: false })}
          onCancel={() => this.setState({ visibleModalProfileDetail: false })}
          title="แก้ไขข้อมูลส่วนตัว"
          width="800px"
          footer={
            <div>
              <Button onClick={() => this.setState({ visibleModalProfileDetail: false })} type="danger">
                ยกเลิก
              </Button>
              <Button type="primary" onClick={this.handleSubmit}>
                บันทึก
              </Button>
            </div>
          }
        >
          {this.modalEditUserDetail()}
        </Modal>
      </div>
    )
  }
}

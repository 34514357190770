import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles, { CheckboxWithLabel, Column, Row } from '../styles'
import {
  ACTION_AIRWAY,
  ACTION_BONE,
  ACTION_CPR,
  ACTION_PERIPHERAL,
  ACTION_WOUND
} from '../constant'
import { get, map, reduce } from 'lodash'

export default function ActionTable({ data }) {

  const breathing = get(data, "emsTreatment.breathing", [])
  const breathingArray = map(breathing, (item) => item.includes("→") ? item.split("→")[0] : item)
  const breathingMoreContent = reduce(breathing, (acc, element) => {
    return element.includes("→") ? {
      ...acc, [element.split("→")[0]]: element.split("→").length > 1 ? element.split("→")[1] : ""
    }
      : { ...acc }
  }, {})
  let EtNoMoreContent = ""
  if (breathingArray.includes("ET No")) {
    const index = breathing.findIndex((item) => item.includes("ET No"))
    const textArray = (index > 0 ? breathing[index] : "").split("→")
    EtNoMoreContent = textArray.length > 2 ? `${textArray[1]} M ${textArray[2]}` : textArray.length > 1 ? `${textArray[1]} M` : ""
  }
  const contusion = get(data, "emsTreatment.contusion", [])
  const contusionArray = map(contusion, (item) => item.includes("→") ? item.split("→")[0] : item)
  const contusionMoreContent = reduce(contusion, (acc, element) => {
    return element.includes("→") ? {
      ...acc, [element.split("→")[0]]: element.split("→").length > 1 ? element.split("→")[1] : ""
    }
      : { ...acc }
  }, {})
  const waterSupply = get(data, "emsTreatment.waterSupply", [])
  const waterSupplyArray = map(waterSupply, (item) => item.includes("→") ? item.split("→")[0] : item)
  const waterSupplyMoreContent = reduce(waterSupply, (acc, element) => {
    return element.includes("→") ? {
      ...acc, [element.split("→")[0]]: element.split("→").length > 1 ? element.split("→")[1] : ""
    }
      : { ...acc }
  }, {})
  const boneSplint = get(data, "emsTreatment.boneSplint", [])
  const cpr = get(data, "emsTreatment.cpr", [])
  const medicineText = get(data, "emsTreatment.medicine", "")

  return (
    <View style={styles.borderedContainerGrey}>
      <Row>
        <Column borderBottom borderRight grey flex={2}>
          <Text style={styles.smallerText}>ทางเดินหายใจ/การหายใจ</Text>
        </Column>
        <Column borderBottom grey flex={12}>
          <Row warp paddingHorizontal={3}>
            {ACTION_AIRWAY.map((e) => {
              return <CheckboxWithLabel key={e.key} small title={e.label} checked={breathingArray.includes(e.key)} paddingHorizontal={1} more={e.key === "ET No" ? EtNoMoreContent : breathingMoreContent[e.key]} />
            })}
          </Row>
        </Column>
      </Row>
      <Row>
        <Column borderBottom borderRight grey flex={2}>
          <Text style={styles.smallerText}>บาดแผล/การห้ามเลือด</Text>
        </Column>
        <Column borderBottom grey flex={12}>
          <Row warp paddingHorizontal={3}>
            {ACTION_WOUND.map((e) => {
              return <CheckboxWithLabel key={e.key} small title={e.label} checked={contusionArray.includes(e.key)} paddingHorizontal={1} more={contusionMoreContent[e.key]} />
            })}
          </Row>
        </Column>
      </Row>
      <Row>
        <Column borderBottom borderRight grey flex={2}>
          <Text style={styles.smallerText}>การให้สารน้ำ </Text>
        </Column>
        <Column borderBottom grey flex={12}>
          <Row warp paddingHorizontal={3}>
            {ACTION_PERIPHERAL.map((e) => {
              return <CheckboxWithLabel key={e.key} small title={e.label} checked={waterSupplyArray.includes(e.key)} paddingHorizontal={1} more={waterSupplyMoreContent[e.key]} />
            })}
          </Row>
        </Column>
      </Row>
      <Row>
        <Column borderBottom borderRight grey flex={2}>
          <Text style={styles.smallerText}>การดามกระดูก</Text>
        </Column>
        <Column borderBottom grey flex={12}>
          <Row warp paddingHorizontal={3}>
            {ACTION_BONE.map((e) => {
              return <CheckboxWithLabel key={e.key} small title={e.label} checked={boneSplint.includes(e.key)} paddingHorizontal={1} />
            })}
          </Row>
        </Column>
      </Row>
      <Row>
        <Column borderBottom borderRight grey flex={2}>
          <Text style={styles.smallerText}>การทํา CPR</Text>
        </Column>
        <Column borderBottom grey flex={12}>
          <Row warp paddingHorizontal={3}>
            {ACTION_CPR.map((e) => {
              return <CheckboxWithLabel key={e.key} small title={e.label} checked={cpr.includes(e.key)} paddingHorizontal={1} />
            })}
          </Row>
        </Column>
      </Row>
      <Row>
        <Column borderRight grey flex={2}>
          <Text style={styles.smallerText}>ยา(วิธิใช้ และขนาดการใช้)</Text>
        </Column>
        <Column flex={12}>
          <Text style={styles.smallerText}>{medicineText}</Text>
        </Column>
      </Row>
    </View>
  )
}

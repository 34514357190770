import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/bookingGalleries'

class BookingGallery {
  @observable info = {
    data: [],
    error: null
  }

  @action createBookingGallery = async (images, bookingId) => {
    try {
      await http.post(`${apiEndpoint}`, {
        image: images,
        bookingId
      })
    } catch (error) {
      console.log(error)
    }
  }


  @action getBookingGallery = async (bookingId) => {
    try {
      const {data} = await http.get(`${apiEndpoint}?filter[where][bookingId]=${bookingId}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action deleteBookingGallery = async (Id) => {
    try {
      await http.delete(`${apiEndpoint}/${Id}`, {
      })
    } catch (error) {
      console.log(error)
    }
  }

  @computed get getBookingGallert() {
    return toJS(this.info.data)
  }
}

export default new BookingGallery()

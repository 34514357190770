import React, { useEffect } from 'react'
import { Button, Tag } from 'antd'
import Table from '../Table'
import moment from 'moment'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import 'moment/locale/th'
const Text = styled('p')`
  font-weight: bold;
`
const index = (props) => {
  const { setCount, convertHn, keyword, page, limit, setPage, setLimit, fetchAllCheckUpNotes } = props
  const [healthCheckUpNotes, isLoadingData] = useSelector((state) => {
    const { notes, isLoadingData } = state.healthCheckUp
    return [notes, isLoadingData]
  })

  useEffect(() => {
    setCount(healthCheckUpNotes?.totalDocs ?? 0)
  }, [healthCheckUpNotes])

  const columns = [
    {
      title: 'ชื่อ',
      key: 'patientName',
      render: (record) => <Text>{`${record?.title ?? ''}${record?.firstName ?? '-'} ${record?.lastName ?? '-'}`}</Text>,
      align: 'center',
    },
    {
      title: 'HN',
      key: 'hn',
      render: (record) => <Text onClick={() => console.log(record)}>{convertHn(record?.hn)}</Text>,
      align: 'center',
    },
    {
      title: 'เลขบัตรประชาชน',
      key: 'cId',
      render: (record) => <Text>{record.cId}</Text>,
      align: 'center',
    },
    {
      title: 'วันที่ตรวจรักษา',
      key: 'orderDate',
      render: (record) => <Text>{record?.orderDateTime ? moment(record?.orderDateTime).format('LL') : '-'}</Text>,
      align: 'center',
    },
    {
      title: 'ผลตรวจ Lab',
      key: 'labStatus',
      render: (record) => <Tag color={record?.labStatus === true ? 'green' : record?.labStatus === false ? 'red' : '#ccc'}>{record?.labStatus === true ? 'ไม่ผิดปกติ' : record?.labStatus === false ? 'มีบางอย่างผิดปกติ' : '-'}</Tag>,
      align: 'center',
    },
    {
      title: 'ดู / แก้ไข',
      key: 'viewAndEdit',
      render: (record) => (
        <Link to={`/health-checkup-note/${record.id}`} target="_blank" rel="noopener noreferrer">
          <Button type="primary">ดูข้อมูล/แก้ไข</Button>
        </Link>
      ),
      align: 'center',
    },
    {
      title: 'วันที่สร้างข้อมูล',
      key: 'createdAt',
      render: (record) => <Text>{record?.createdAt ? moment(record?.createdAt).format('LL') : '-'}</Text>,
      align: 'center',
    },
  ]
  return (
    <Table
      loading={isLoadingData}
      columns={columns}
      dataSource={healthCheckUpNotes?.docs}
      onChange={(val) => {
        if (val?.pageSize) {
          setLimit(val.pageSize)
        }
        if (val?.current) {
          setPage(val.current)
        }
        fetchAllCheckUpNotes(val.current, val.pageSize, keyword)
      }}
      pagination={{ current: page, total: healthCheckUpNotes?.totalDocs, pageSize: limit, showSizeChanger: true }}
    ></Table>
  )
}

export default index

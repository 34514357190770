import React from 'react'
import { Spin, Card } from 'antd'
import styled from 'styled-components'

export const CardMiddle = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`

const Loading = () => (
  <CardMiddle>
    <Spin size="large" />
  </CardMiddle>
)

export default Loading

import React from 'react'
import ReactQuill from 'react-quill'
import { TOOLBAR_OPTIONS } from './constant'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'

const HeaderText = styled('p')`
  font-size: 18px;
`
const PlainTextEditor = (props) => {
  const { title, value, setValue, readOnly = false } = props
  return (
    <div className="py-2">
      <HeaderText>{title}</HeaderText>
      <ReactQuill modules={TOOLBAR_OPTIONS} theme="snow" value={value} onChange={setValue} readOnly={readOnly} />
    </div>
  )
}

export default PlainTextEditor

import React, { useContext, useState, useEffect, useRef } from 'react'
import { Table, Input, Button, Popconfirm, Form, Row, Col, message, TimePicker, Select, InputNumber } from 'antd'
import { CloseCircleOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons'
import moment from 'moment'
import './style.css'
const { Option } = Select

class EditableTable extends React.Component {
  constructor(props) {
    super(props)

    this.columns = [
      {
        title: 'เหตุผล AT มากกว่า 2 นาที',
        dataIndex: 'reasonAT',
        key: 'reasonAT',
        align: 'center',
        render: (record, data) => (
          <Input
            disabled={this.props.showDataPage}
            defaultValue={data && data.reasonAT ? data.reasonAT : ''}
            onChange={(value) => {
              data.reasonAT = value.target.value
            }}
          />
        )
      },
      {
        title: 'เหตุผล RT มากกว่า 8 นาที',
        dataIndex: 'reasonRT',
        key: 'reasonRT',
        align: 'center',
        render: (record, data) => (
          <Input
            disabled={this.props.showDataPage}
            defaultValue={data && data.reasonRT ? data.reasonRT : ''}
            onChange={(value) => {
              data.reasonRT = value.target.value
            }}
          />
        )
      },
      {
        title: 'เหตุผล OST trauma มากกว่า 10 นาที',
        dataIndex: 'reasonOstTrauma',
        key: 'reasonOstTrauma',
        align: 'center',
        render: (record, data) => (
          <Input
            disabled={this.props.showDataPage}
            defaultValue={data && data.reasonOstTrauma ? data.reasonOstTrauma : ''}
            onChange={(value) => {
              data.reasonOstTrauma = value.target.value
            }}
          />
        )
      }
    ]

    this.state = {
      dataSource: [
        {
          key: 0,
          reasonAT: '',
          reasonRT: '',
          reasonOstTrauma: ''
        }
      ],
      count: 1
    }
  }

  handleDelete = (key) => {
    const dataSource = [...this.state.dataSource]
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key)
    })
  }
  handleAdd = () => {
    const { count, dataSource } = this.state
    const newData = {
      key: count,
      reasonAT: '',
      reasonRT: '',
      reasonOstTrauma: ''
    }
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1
    })
  }
  handleSave = (row) => {
    const newData = [...this.state.dataSource]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...row })
    this.setState({
      dataSource: newData
    })
  }

  render() {
    const { dataSource } = this.state
    const columns = this.columns
    return (
      <>
        {!this.props.showDataPage && (
          <Row gutter={[8, 8]}>
            <Col>
              <p style={{ fontSize: '18px' }}>เหตุผลเวลาเกิน</p>
            </Col>
            <Col>
              <Button
                className="d-flex align-items-center"
                icon={<SaveOutlined />}
                onClick={() => {
                  const sortedData = {
                    reasonAT: dataSource[0].reasonAT,
                    reasonRT: dataSource[0].reasonRT,
                    reasonOstTrauma: dataSource[0].reasonOstTrauma
                  }
                  this.props.setReasonLateTable(sortedData)
                  message.success('ข้อมูลในตารางได้รับการอัพเดทเรียบร้อยแล้ว')
                }}
                type="primary"
                style={{
                  marginBottom: 4
                }}
              >
                บันทึกข้อมูลในตาราง
              </Button>
            </Col>
          </Row>
        )}
        <Table
          style={{ width: '100%' }}
          scroll={{ x: 'max-content' }}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={this.props.dataToShow ? this.props.dataToShow : dataSource}
          columns={columns}
          pagination={false}
        />
      </>
    )
  }
}

export default EditableTable

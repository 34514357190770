import React, { useEffect, useState } from 'react'
import { Modal, Table, Button } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import PlainTextEditor from '../TextEditor/PlainTextEditor'
const Text = styled('p')`
  font-weight: bold;
`
const CheckUpLogsModal = (props) => {
  const { visibleModal, setVisibleModal, healthCheckUpData } = props
  const [visibleData, setVisibleData] = useState(false)
  const [summaryNote, setSummaryNote] = useState(null)
  const [xrayNote, setXrayNote] = useState(null)
  const [labNote, setLabNote] = useState(null)
  const columns = [
    {
      title: 'วันที่',
      key: 'date',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (record) => <Text>{moment(record.date).format('DD/MM/YYYY HH:mm')}</Text>,
    },
    {
      title: 'ผู้แก้ไข',
      key: 'doctor',
      render: (record) => <Text>{record.doctorName}</Text>,
    },
    {
      title: 'รายละเอียด',
      key: 'data',
      align: 'center',
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            console.log(record)
            setSummaryNote(record?.data?.summaryNote)
            setLabNote(record?.data?.labNote)
            setXrayNote(record?.data?.xrayNote)
            setVisibleData(true)
          }}
        >
          ดูเนื้อหา
        </Button>
      ),
    },
  ]
  return (
    <Modal visible={visibleModal} onCancel={() => setVisibleModal(false)} footer={null} width={1000}>
      {!visibleData ? (
        <Table columns={columns} dataSource={healthCheckUpData?.modifiedLogs} pagination={{ pageSize: 10 }} />
      ) : (
        <div>
          <Button
            type="primary"
            onClick={() => {
              setSummaryNote(null)
              setLabNote(null)
              setXrayNote(null)
              setVisibleData(false)
            }}
          >
            ย้อนกลับ
          </Button>
          <PlainTextEditor key={'summaryNote'} value={summaryNote} setValue={(val) => setSummaryNote(val)} title="สรุปการตรวจร่างกายทั่วไป" readOnly={true} />
          <PlainTextEditor key={'labNote'} value={labNote} setValue={(val) => setLabNote(val)} title="สรุปผลแลป" readOnly={true} />
          <PlainTextEditor key={'xrayNote'} value={xrayNote} setValue={(val) => setXrayNote(val)} title="สรุปผลตรวจ X-Ray" readOnly={true} />
        </div>
      )}
    </Modal>
  )
}

export default CheckUpLogsModal

import React, { useState, useEffect, useRef } from 'react'
import { Button, Row, Col, Divider, DatePicker, Card, Typography, Form, Input, Radio, InputNumber, Modal, Spin, Table, Popconfirm, Select, Checkbox, message, Slider } from 'antd'
// import { initialValues, sceneDescription, formTemplate } from './constant'
// import TimeTable from './TimeTable'
// import http from '../../../services/httpServiceEver'
const { Option } = Select
import ToggleToken from '../../ToggleToken'
import TimeInput from '../../TimeInput'
import { FiTrash2, FiArrowRight } from 'react-icons/fi'
import './style.css'
const timeLogTemplate = {
  time: '',
  vital: {
    bt: '',
    sbp: '',
    dbp: '',
    pr: '',
    rr: '',
    spo2: '',
  },
  neuro: {
    e: '',
    v: '',
    m: '',
  },
  pupils: {
    lt: '',
    ltRtl: 'reactive',
    rt: '',
    rtRtl: 'reactive',
  },
  dtx: '',
}
const index = ({ updateEmsForm, formDetail, formValues, setFormValues, assessmentPickedRow, setAssessmentPickedRow }) => {
  useEffect(() => {
    console.log(formDetail.assessmentData)
    if (formDetail.assessmentData) {
      let pickedRowFromForm = 0
      formDetail.assessmentData.timeLog.forEach((item, i) => {
        if (item.time == formDetail.assessmentData.record.time) {
          pickedRowFromForm = i
        }
      })
      const newTimeLog = structuredClone(formDetail.assessmentData.timeLog)
      newTimeLog.push(structuredClone(timeLogTemplate))
      setFormValues({ ...formDetail.assessmentData, timeLog: newTimeLog })
      setAssessmentPickedRow(pickedRowFromForm)
    }
    setFormLoaded(true)
  }, [formDetail])
  const [isFormLoaded, setFormLoaded] = useState(false)
  const airways = ['Patency', 'Secretion', 'Blood', 'Mass', 'Stridor', 'Apnea']
  const chestMovements = ['Normal', 'Unequal', 'Accessory Muscle Used']
  const percussions = ['Normal', 'Hyperresonance', 'Dullness']
  const breathSounds = ['Clear', 'Crepitation', 'Wheezing', 'Rhonchi', 'Decreased', 'Absent']
  const capillaryRefills = ['Normal', 'Delay']
  const skinTemps = ['Normal', 'Cool/Cold', 'Warm/Hot']
  const moistures = ['Normal', 'Dry', 'Moist', 'Diaphoresis']
  const colors = ['Normal', 'Cyanosis', 'Pale', 'Cherry', 'Flushed', 'Juandice']
  const handleTableInput = (i, cate, field, val) => {
    let newLog = structuredClone(formValues['timeLog'])
    if (!newLog[i].hasOwnProperty(cate)) {
      newLog[i][cate] = {}
    }
    newLog[i][cate][field] = val
    if (i == newLog.length - 1) {
      if (val != '') {
        newLog.push(structuredClone(timeLogTemplate))
      }
    } else if (i == newLog.length - 2) {
      if (isRowEmpty(newLog[newLog.length - 2])) {
        newLog.pop()
      }
    }
    setFormValues({ timeLog: newLog })
  }
  const handleSelectorLeft = (i, val) => {
    let newLog = structuredClone(formValues['timeLog'])
    newLog[i].pupils.ltRtl = val
    setFormValues({ timeLog: newLog })
  }
  const handleSelectorRight = (i, val) => {
    let newLog = structuredClone(formValues['timeLog'])
    newLog[i].pupils.rtRtl = val
    setFormValues({ timeLog: newLog })
  }
  const setDTX = (i, val) => {
    let newLog = structuredClone(formValues['timeLog'])
    newLog[i].dtx = val
    setFormValues({ timeLog: newLog })
  }
  const isRowEmpty = (log) =>
    Object.entries(log).every((val) => {
      if (typeof val[1] == 'object') {
        return Object.entries(val[1]).every((v) => v[1] == '' || ((v[0] == 'ltRtl' || v[0] == 'rtRtl') && v[1] == 'reactive'))
      } else {
        return val[1] == ''
      }
    })
  const shouldCreateRow = (i, val) => {
    let newLog = structuredClone(formValues['timeLog'])
    newLog[i].time = val
    if (i == newLog.length - 1) {
      if (val != '') {
        newLog.push(structuredClone(timeLogTemplate))
      }
    } else if (i == newLog.length - 2) {
      if (val == '') {
        if (isRowEmpty(newLog[newLog.length - 1])) {
          if (isRowEmpty(newLog[newLog.length - 2])) {
            newLog.pop()
          }
        }
      }
    }
    setFormValues({ timeLog: newLog })
  }
  const throwAway = (index) => {
    let row = formValues['timeLog'].filter((item, i) => i != index)
    setFormValues({ timeLog: row })
    if (assessmentPickedRow >= formValues['timeLog'].length - 2) {
      setAssessmentPickedRow(0)
    }
  }

  return (
    <Spin spinning={!isFormLoaded}>
      {isFormLoaded ? (
        <div id="assessment">
          <div className="time-table">
            <table>
              <thead>
                <tr className="table-halo">
                  <th rowSpan={2}>
                    <div style={{ width: '3em' }}></div>
                  </th>
                  <th className="time" rowSpan={2}>
                    Time
                  </th>
                  <td colSpan={6} data-group="Vital Signs">
                    &ensp;
                  </td>
                  <td colSpan={3} data-group="Neuro Signs">
                    &ensp;
                  </td>
                  <td colSpan={4} data-group="Pupils">
                    &ensp;
                  </td>
                  <th className="dtx" rowSpan={2}>
                    DTX
                  </th>
                </tr>
                <tr className="table-header">
                  <th>BT</th>
                  <th>SBP</th>
                  <th>DBP</th>
                  <th>PR</th>
                  <th>RR</th>
                  <th>
                    SpO<sub>2</sub>
                  </th>
                  <th>E</th>
                  <th>V</th>
                  <th>M</th>
                  <th>Lt</th>
                  <th>RTL</th>
                  <th>Rt</th>
                  <th>RTL</th>
                </tr>
              </thead>
              <tbody>
                {formValues['timeLog'].map((item, i) => {
                  return (
                    <tr key={i} className={`table-record${assessmentPickedRow == i ? ' picked' : ''}`}>
                      <td>
                        {item.time ? (
                          <>
                            <Popconfirm placement="bottomLeft" title="This Cannot Be Undone" onConfirm={() => throwAway(i)} okText="Delete" okType="primary" cancelText="No">
                              <FiTrash2 className="remove" />
                            </Popconfirm>
                            <FiArrowRight className="picker" onClick={() => setAssessmentPickedRow(i)} />
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        <TimeInput value={item.time} onInput={(e) => shouldCreateRow(i, e.target.value)} />
                      </td>
                      <td>
                        <Input value={item.vital.bt} onInput={(e) => handleTableInput(i, 'vital', 'bt', e.target.value)} />
                      </td>
                      <td>
                        <Input value={item.vital.sbp} onInput={(e) => handleTableInput(i, 'vital', 'sbp', e.target.value)} />
                      </td>
                      <td>
                        <Input value={item.vital.dbp} onInput={(e) => handleTableInput(i, 'vital', 'dbp', e.target.value)} />
                      </td>
                      <td>
                        <Input value={item.vital.pr} onInput={(e) => handleTableInput(i, 'vital', 'pr', e.target.value)} />
                      </td>
                      <td>
                        <Input value={item.vital.rr} onInput={(e) => handleTableInput(i, 'vital', 'rr', e.target.value)} />
                      </td>
                      <td>
                        <Input value={item.vital.spo2} onInput={(e) => handleTableInput(i, 'vital', 'spo2', e.target.value)} />
                      </td>
                      <td>
                        <Input value={item.neuro.e} onInput={(e) => handleTableInput(i, 'neuro', 'e', e.target.value)} />
                      </td>
                      <td>
                        <Input value={item.neuro.v} onInput={(e) => handleTableInput(i, 'neuro', 'v', e.target.value)} />
                      </td>
                      <td>
                        <Input value={item.neuro.m} onInput={(e) => handleTableInput(i, 'neuro', 'm', e.target.value)} />
                      </td>
                      <td>
                        <Input value={item.pupils.lt} onInput={(e) => handleTableInput(i, 'pupils', 'lt', e.target.value)} />
                      </td>
                      <td>
                        <Select defaultValue="reactive" value={item.pupils.ltRtl} onChange={(val) => handleSelectorLeft(i, val)}>
                          <Option value="reactive">Reactive</Option>
                          <Option value="nonreactive">Nonreactive</Option>
                          <Option value="constricted">Constricted</Option>
                          <Option value="dilated">Dilated</Option>
                          <Option value="blind">Blind</Option>
                          <Option value="cataracts">Cataracts</Option>
                          <Option value="glaucoma">Glaucoma</Option>
                        </Select>
                      </td>
                      <td>
                        <Input value={item.pupils.rt} onInput={(e) => handleTableInput(i, 'pupils', 'rt', e.target.value)} />
                      </td>
                      <td>
                        <Select defaultValue="reactive" value={item.pupils.rtRtl} onChange={(val) => handleSelectorRight(i, val)}>
                          <Option value="reactive">Reactive</Option>
                          <Option value="nonreactive">Nonreactive</Option>
                          <Option value="constricted">Constricted</Option>
                          <Option value="dilated">Dilated</Option>
                          <Option value="blind">Blind</Option>
                          <Option value="cataracts">Cataracts</Option>
                          <Option value="glaucoma">Glaucoma</Option>
                        </Select>
                      </td>
                      <td>
                        <Input value={item.dtx} onInput={(e) => setDTX(i, e.target.value)} />
                      </td>
                    </tr>
                  )
                })}
                <tr className="end-cap">
                  <td colSpan={16}></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="form-layout">
            <div id="respiratory" className="section">
              <h1>Respiratory</h1>
              <ToggleToken
                title="Airway"
                options={airways}
                values={formValues['respiratory']['airway']}
                setValues={(value) => setFormValues({ respiratory: { ...formValues['respiratory'], ['airway']: value } })}
                extraInputs={['Secretion']}
                noData="N/A"
              />
              <ToggleToken
                title="Chest Movement"
                options={chestMovements}
                values={formValues['respiratory']['chestMovement']}
                setValues={(value) => setFormValues({ respiratory: { ...formValues['respiratory'], ['chestMovement']: value } })}
                noData="N/A"
              />
              <ToggleToken
                title="Percussion"
                options={percussions}
                values={formValues['respiratory']['percussion']}
                setValues={(value) => setFormValues({ respiratory: { ...formValues['respiratory'], ['percussion']: value } })}
                noData="N/A"
              />
            </div>
            <div id="breath-sound" className="section">
              <h1>Breath Sound</h1>
              <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '1em' }}>
                <ToggleToken
                  title="Right"
                  options={breathSounds}
                  values={formValues['respiratory']['breathSoundR']}
                  setValues={(value) => setFormValues({ respiratory: { ...formValues['respiratory'], ['breathSoundR']: value } })}
                  layout="start"
                  noData="N/A"
                />
                <ToggleToken
                  title="Left"
                  options={breathSounds}
                  values={formValues['respiratory']['breathSoundL']}
                  setValues={(value) => setFormValues({ respiratory: { ...formValues['respiratory'], ['breathSoundL']: value } })}
                  layout="end"
                  noData="N/A"
                />
              </div>
            </div>
            <div id="circulation" className="section">
              <h1>Circulation</h1>
              <ToggleToken
                title="Pulse"
                max={1}
                options={['Regular', 'Irregular']}
                values={formValues['circulation']['pulse']}
                setValues={(value) => setFormValues({ circulation: { ...formValues['circulation'], ['pulse']: value } })}
                noData="N/A"
              />
              <ToggleToken
                title="Capillary Refill"
                max={1}
                options={capillaryRefills}
                extraInput="Delay"
                values={formValues['circulation']['capillaryRefill']}
                setValues={(value) => setFormValues({ circulation: { ...formValues['circulation'], ['capillaryRefill']: value } })}
                noData="N/A"
              />
              <ToggleToken
                title="Skin Temperature"
                max={1}
                options={skinTemps}
                values={formValues['circulation']['skinTemp']}
                setValues={(value) => setFormValues({ circulation: { ...formValues['circulation'], ['skinTemp']: value } })}
                noData="N/A"
              />
              <ToggleToken title="Moisture" options={moistures} values={formValues['circulation']['moisture']} setValues={(value) => setFormValues({ circulation: { ...formValues['circulation'], ['moisture']: value } })} noData="N/A" />
              <ToggleToken title="Color" max={1} options={colors} values={formValues['circulation']['color']} setValues={(value) => setFormValues({ circulation: { ...formValues['circulation'], ['color']: value } })} noData="N/A" />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: 800 }}></div>
      )}
    </Spin>
  )
}

export default index

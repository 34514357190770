import React, { useState, useEffect } from 'react'
import { FileOutlined } from '@ant-design/icons'
import { Input, Tag, Popconfirm, Row, Col, Modal, Select, message } from 'antd'
import styled from 'styled-components'
import { Formik, ErrorMessage } from 'formik'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import firebase from 'firebase'
import * as Yup from 'yup'
import { InputSizes } from 'antd/lib/input/Input'
import axios from 'axios'
import Table from '../Table'
import TextBase from '../Text'
import Button from '../Button'
import { BOOKING_DOCTOR_STATUS } from '../../constant'
import { ModalSummaryOrder } from '../../containers/Home/ModalSummaryOrder'
import http from '../../services/httpServiceEver'

const { Option } = Select

const Text = styled(TextBase)`
  font-weight: bold;
`

const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
const Container = styled.div`
  width: 100%;
  padding: 20px;
`

const apiEndpoint = '/trackingNumbers'

const TrackingTable = ({ state, handler, setStatus, createPrescription, updatePrescription, updateVisitNumber, fetchPatient, fetchMedicalBenefit, fetchTrackingDataNumber, fetchVisitNumber, ...rest }) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [patient, setPatient] = useState()
  const [medicines, setMedicines] = useState([])
  const [selectOptions, setSelectOptions] = useState([])
  const [prescriptionId, setPrescriptionId] = useState()
  const [bookingId, setBookingId] = useState()
  const [patientStatus, setPatientStatus] = useState([])
  const [loading, setLoading] = useState(true)
  const [visibleModalHistory, setVisibleModalHistory] = useState(false)
  const [visibleVisitNumber, setVisibleVisitNumber] = useState(false)
  const [vnNumber, setVnNumber] = useState('')
  const [visibleMedicalBenefit, setVisibleMedicalBenefit] = useState(false)
  const [trackingData, setTrackingData] = useState([])

  useEffect(() => {
    if (state.dataSource) {
      setTrackingData(state.dataSource)
    }
    setLoading(true)

    setLoading(false)
  }, [trackingData])

  async function addNewTrackingNumbers(values) {
    message.warning('กำลัง generate หมายเลข EMS')
    setLoading(true)
    const { data } = await http.post(`${apiEndpoint}/insertTrackingNumber`, {
      prefixCharacter: values.prefixCharacter,
      startTrackingNumber: values.startingNo,
      endTrackingNumber: values.endingNo,
    })
    if (data.status === 'Created Tracking Number Success') {
      message.success(data.status)
      fetchTrackingDataNumber()
    } else {
      message.error(data.status)
    }
    setLoading(false)
  }

  let columns

  columns = [
    {
      title: 'รายการ',
      key: 'trackingNo',
      align: 'center',
      render: (record) => (record.barcode ? <Text>{record.barcode}</Text> : '-'),
    },
    {
      title: 'สถานะ',
      key: 'statusDescription',
      render: (record) => (record.status_description ? <Text>{record.status_description}</Text> : '-'),
    },
    {
      title: 'สถานที่',
      key: 'location',
      render: (record) => (record.location ? <Text>{record.location}</Text> : '-'),
      align: 'center',
    },
    {
      title: 'รหัสไปรษณีย์',
      key: 'postcode',
      render: (record) => (record.postcode ? <Text>{record.postcode}</Text> : '-'),
      align: 'center',
    },
    {
      title: 'วันที่อัพเดต',
      key: 'date',
      render: (record) => (record.status_date ? <Text>{moment(record.status_date).format('DD/MM/YYYY')}</Text> : '-'),
      align: 'center',
    },
    {
      title: 'สถานะการติดตาม',
      key: 'trackingStatus',
      render: (record) => (record.trackingStatus ? <Text style={{ color: 'green' }}>ติดตามสถานะ</Text> : <Text style={{ color: 'red' }}>ไม่ได้ติดตามสถานะ</Text>),
      align: 'center',
    },
  ]

  const validationSchema = Yup.object().shape({
    prefixCharacter: Yup.string()
      .required('กรุณากรอกอักษรเริ่มต้น')
      .min(4, 'กรุณากรอกตัวอักษร 4 ตัว'),
    startingNo: Yup.string()
      .required('กรุณากรอกตัวเลขเริ่มต้น')
      .min(6, 'กรุณากรอกตัวเลขเริ่มต้น 6 ตัว')
      .test('match', 'หมายเลขเริ่มต้นและสิ้นสุดไม่ควรห่างกันเกิน 100', function() {
        return this.parent.endingNo - this.parent.startingNo <= 100
      }),
    endingNo: Yup.string()
      .required('กรุณากรอกตัวเลขสิ้นสุด')
      .min(6, 'กรุณากรอกตัวเลขสิ้นสุด 6 ตัว')
      .test('match', 'หมายเลขเริ่มต้นและสิ้นสุดไม่ควรห่างกันเกิน 100', function() {
        return this.parent.endingNo - this.parent.startingNo <= 100
      }),
  })

  return (
    <Formik
      initialValues={{ prefixCharacter: '', startingNo: '', endingNo: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        addNewTrackingNumbers(values)
        setSubmitting(false)
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Container>
          <HeaderWrapper>
            <Row justify="start" gutter={24} style={{ width: 230 }}>
              <Col span={2}>
                <FileOutlined />
              </Col>
              <Col span={21}>
                <Text bold>Tracking Number</Text>
              </Col>
            </Row>
            <form onSubmit={handleSubmit} style={{ maxWidth: '100%', width: '100%', margin: 0 }}>
              <Row gutter={12} justify="end" style={{ padding: 0, margin: 0 }}>
                <Col span={8}>
                  <Input
                    type="text"
                    value={values.prefixCharacter}
                    onChange={handleChange}
                    name="prefixCharacter"
                    placeholder="กรอกอักษรเริ่มต้น 4 ตำแหน่ง (ตัวอย่าง EB21)"
                    autoFocus
                    style={{ fontSize: '14px' }}
                    className={`form-control${errors.prefixCharacter && touched.prefixCharacter ? ' is-invalid' : ''}`}
                  />
                  <ErrorMessage name="prefixCharacter" component="div" className="invalid-feedback" />
                </Col>
                <Col span={7}>
                  <Input
                    type="text"
                    value={values.startingNo}
                    onChange={handleChange}
                    name="startingNo"
                    placeholder="กรอกตัวเลขเริ่มต้น (ตัวอย่าง 999770)"
                    autoFocus
                    className={`form-control${errors.startingNo && touched.startingNo ? ' is-invalid' : ''}`}
                    style={{ fontSize: '14px' }}
                  />
                  <ErrorMessage name="startingNo" component="div" className="invalid-feedback" />
                </Col>
                <Col span={7}>
                  <Input
                    type="text"
                    value={values.endingNo}
                    onChange={handleChange}
                    name="endingNo"
                    placeholder="กรอกตัวเลขสิ้นสุด (ตัวอย่าง 999799)"
                    className={`form-control${errors.endingNo && touched.endingNo ? ' is-invalid' : ''}`}
                    style={{ fontSize: '14px' }}
                  />
                  <ErrorMessage name="endingNo" component="div" className="invalid-feedback" />
                </Col>
                <Col span={2}>
                  <button type="submit" disabled={isSubmitting}>
                    ยืนยัน
                  </button>
                </Col>
              </Row>
            </form>
          </HeaderWrapper>
          <div
            style={{
              backgroundColor: `${state.emsAvailable < 5 ? 'red' : state.emsAvailable < 10 ? 'orange' : 'green'}`,
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              padding: '5px',
              color: '#ffffff',
            }}
          >
            เหลือหมายเลข Tracking {state.emsAvailable} เลข
          </div>
          {!loading ? (
            <Table
              scroll={{
                x: state.dashboard ? '50vw' : '100%',
              }}
              columns={columns}
              dataSource={state.dataSource}
              {...rest}
            />
          ) : null}
        </Container>
      )}
    </Formik>
  )
}
export default TrackingTable

import { observable, action, computed, toJS } from 'mobx'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/AppUserIdentities'

class AppUserIdentity {
  @observable info = {
    data: [],
    error: null
  }

  @action fetchAppUserIdentity = async (id) => {
    try {
      const { data } = await http.get(`${apiEndpoint}?filter[where][appUserId]=${id}`)
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action getAppUserIdentity = async (id) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/${id}`)
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @computed get getAppUserIdentity() {
    return toJS(this.AppUserIdentity.data)
  }
}

export default new AppUserIdentity()

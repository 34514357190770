import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Row, Col, Radio, Select, DatePicker, Divider, Space, Button, message, ConfigProvider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { cloneDeep, isEmpty } from 'lodash'
import { hooks } from './hooks'
import moment from 'moment'
import 'moment/locale/th'
import thTH from 'antd/es/locale/th_TH'
import { STAFF_NAME } from './constant'
const DonationEditForm = ({ formData, bookingData }) => {
  moment.locale('th')
  const [form] = Form.useForm()
  const [data, setData] = useState({})
  const [reasonsToReject, setReasonsToReject] = useState(['กินยาที่มีผลต่อเกล็ดเลือด', 'Under volumn', 'High volume', 'Discarded', 'ความดันต่ำกว่าปกติ', 'ความดันสูงกว่าปกติ', 'Hb ต่ำกว่าปกติ', 'Hb สูงกว่าปกติ'])
  const [selectedReasons, setSelectedReasons] = useState([])
  const [extendedReason, setExtendedReason] = useState('')
  const inputRef = useRef(null)
  const { events, params } = hooks()
  useEffect(() => {
    if (!isEmpty(formData)) {
      setData(formData)
      setSelectedReasons(formData?.reasonToReject ?? [])
      const dataToSet = cloneDeep(formData)
      delete dataToSet.id
      delete dataToSet.bloodDonationBookingId
      delete dataToSet.createdAt
      delete dataToSet.updatedAt

      form.setFieldsValue({
        ...dataToSet,
        donationPersonId: formData?.donationPersonId ?? formData?.cId,
        donatedCount: formData?.donatedCount ? formData?.donatedCount : formData?.previousDonatedCount ? formData?.previousDonatedCount + 1 : 1,
        firstDonationDate: formData?.firstDonationDate ? moment(formData?.firstDonationDate) : null,
        latestDonateDate: formData?.latestDonateDate ? moment(formData?.latestDonateDate) : null,
        reasonsToReject: formData?.reasonToReject ?? [],
        bloodForSurgeryDate: formData?.bloodForSurgeryDate ? moment(formData?.bloodForSurgeryDate) : null,
      })
    }
  }, [formData])

  const addItem = (e) => {
    e.preventDefault()
    setReasonsToReject([...reasonsToReject, extendedReason])
    setExtendedReason('')
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  const updateDonationForm = (formValues) => {
    const reasonToEdit = formValues?.reasonToEdit
    const staffName = formValues?.staffName
    const formId = data?.id ?? null
    delete formValues?.reasonToEdit
    const latestDonorFormData = formValues
    events.dispatch(
      events.updateDonorForm({
        formId,
        latestDonorFormData,
        callback: (isFormUpdateSuccess) => {
          if (isFormUpdateSuccess) {
            message.success('บันทึกข้อมูลสำเร็จ')
            events.addFormEditLog(staffName, reasonToEdit, formId)
          } else {
            message.error('บันทึกข้อมูลล้มเหลว เบื้องต้นกรุณาตรวจสอบข้อมูล หรือติดต่อเจ้าหน้าที่', [3])
          }
        },
      }),
    )
  }

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      initialValues={{
        unitNumber: null,
        screenStatus: null,
        bloodPrepareStaffName: null,
        bloodNeedlerName: null,
        collectSamplerName: null,
        recorderName: null,
        firstDonationDate: null,
        latestDonateDate: null,
        isBloodPressureNormal: true,
        isPulseNormal: true,
        isHeartLungNormal: true,
        isHbNormal: true,
        isTemperatureNormal: true,
        reasonsToReject: selectedReasons,
      }}
      validateMessages={{
        required: 'กรุณากรอก ${name}',
        pattern: {
          mismatch: 'กรุณาระบุ ${name} เป็นจำนวนเต็ม',
        },
      }}
      form={form}
      onFinish={updateDonationForm}
    >
      <Row gutter={[16, 16]} className="donationFormBox">
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="เคยบริจาคเลือดหรือไม่" name="haveDonatedBlood">
            <p>{data?.haveDonatedBlood ?? '-'}</p>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="ประเภทการบริจาค" name="donateType">
            <p>{data?.donateType === 'blood' ? 'เลือด' : data?.donateType ? 'เกล็ดเลือด' : ''}</p>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="วัตถุประสงค์การบริจาค" name="donateFor">
            <p>{data?.donateFor ?? '-'}</p>
          </Form.Item>
        </Col>
        {data?.donateFor === 'บริจาคให้ตนเองเพื่อการผ่าตัด' && (
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item label="วันที่ผ่าตัด" name="bloodForSurgeryDate">
              <p>
                {moment(data?.bloodForSurgeryDate)
                  .add(543, 'years')
                  .format('LL')}
              </p>
            </Form.Item>
          </Col>
        )}
        {data?.donateFor === 'บริจาคเลือดโดยระบุชื่อผู้รับเลือด' && (
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item label="ผู้ที่รับเลือด" name="bloodReceiver">
              <p>{data?.bloodReceiver ?? '-'}</p>
            </Form.Item>
          </Col>
        )}
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="บริจาคครั้งที่" name="donatedCount">
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="เลขประจำตัวผู้บริจาคเลือด/เลขบัตรประชาชน" name="donationPersonId">
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'กรุณากรอกหมู่เลือด',
              },
            ]}
            label="หมู่เลือด ABO"
            name="aboGroup"
          >
            <Select className="w-100">
              <Select.Option value="A">A</Select.Option>
              <Select.Option value="B">B</Select.Option>
              <Select.Option value="O">O</Select.Option>
              <Select.Option value="AB">AB</Select.Option>
              <Select.Option value="ไม่ระบุ">ไม่ระบุ</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'กรุณากรอกค่า Rh',
              },
            ]}
            label="หมู่เลือด Rh"
            name="rhGroup"
          >
            <Select className="w-100">
              <Select.Option value="+">+</Select.Option>
              <Select.Option value="-">-</Select.Option>
              <Select.Option value="ไม่ระบุ">ไม่ระบุ</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="น้ำหนัก" name="weight">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item 
          label="Unit Number" 
          name="unitNumber"
          rules={[{ 
              required: true, 
              message: "ต้องเป็นตัวเลข 0-9 หรือ A-Z , a-z เท่านั้น",
              pattern: new RegExp(/[A-Z,0-9,a-z]+$/)
          }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'กรุณาระบุชนิดถุงเลือด',
              },
            ]}
            label="ชนิดถุงเลือด"
            name="bagType"
          >
            <Select className="w-100">
              <Select.Option value="450ccQB">450 cc QB</Select.Option>
              <Select.Option value="350ccDB">350 cc DB</Select.Option>
              <Select.Option value="SDP">SDP</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="วันที่บริจาคเลือดครั้งแรก" name="firstDonationDate">
            <ConfigProvider locale={thTH}>
              <DatePicker
                className="w-100"
                placeholder="เลือกวันที่"
                value={data['firstDonationDate'] ? moment(data['firstDonationDate']) : null}
                onChange={(date) => {
                  if (date) {
                    setData((prev) => ({ ...prev, firstDonationDate: date }))
                    form.setFieldsValue({ firstDonationDate: date })
                  } else {
                    setData((prev) => ({ ...prev, firstDonationDate: null }))
                    form.setFieldsValue({ firstDonationDate: null })
                  }
                }}
                format={(value) =>
                  moment(value)
                    .add(543, 'years')
                    .format('LL')
                }
              />
            </ConfigProvider>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="สถานที่บริจาคเลือดครั้งแรก" name="firstDonationPlace">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="วันที่บริจาคเลือดครั้งล่าสุด" name="latestDonateDate">
            <ConfigProvider locale={thTH}>
              <DatePicker
                className="w-100"
                placeholder="เลือกวันที่"
                value={data['latestDonateDate'] ? moment(data['latestDonateDate']) : null}
                onChange={(date) => {
                  if (date) {
                    setData((prev) => ({ ...prev, latestDonateDate: date }))
                    form.setFieldsValue({ latestDonateDate: date })
                  } else {
                    setData((prev) => ({ ...prev, latestDonateDate: null }))
                    form.setFieldsValue({ latestDonateDate: null })
                  }
                }}
                format={(value) =>
                  moment(value)
                    .add(543, 'years')
                    .format('LL')
                }
              />
            </ConfigProvider>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="สถานที่บริจาคเลือดครั้งล่าสุด" name="latestDonatePlace">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Row justify="space-between" align="bottom">
            <Col xs={24} md={11}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    pattern: '^[^.]+$',
                  },
                ]}
                label="ความดัน SBP"
                name="sbp"
              >
                <Input style={{ width: '95%' }} type="number" />
              </Form.Item>
            </Col>
            <Col xs={0} md={1} style={{ marginBottom: '24px', fontSize: '20px' }}>
              /
            </Col>
            <Col xs={24} md={11}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    pattern: '^[^.]+$',
                  },
                ]}
                label="ความดัน DBP"
                name="dbp"
              >
                <Input style={{ width: '95%' }} type="number" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                pattern: '^[^.]+$',
              },
            ]}
            label="ชีพจร"
            name="pulse"
          >
            <Input addonAfter="ครั้ง/นาที" type="number" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="ชีพจรปกติหรือไม่" name="isPulseNormal">
            <Radio.Group>
              <Radio.Button value={true}>ปกติ</Radio.Button>
              <Radio.Button value={false}>ไม่ปกติ</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'กรุณากรอกค่า Hb',
              },
            ]}
            label="Hb"
            name="hb"
          >
            <Input addonAfter="ก/ดล." type="number" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="Hb ปกติหรือไม่" name="isHbNormal">
            <Radio.Group>
              <Radio.Button value={true}>ปกติ</Radio.Button>
              <Radio.Button value={false}>ไม่ปกติ</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="เจ้าหน้าที่ผู้ลงทะเบียน" name="registrarName">
            <Select className="w-100">
              {STAFF_NAME.map((item, index) => (
                <Select.Option key={index} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="ชื่อเจ้าหน้าที่เตรียมถุง" name="bloodPrepareStaffName">
            <Select className="w-100">
              {STAFF_NAME.map((item, index) => (
                <Select.Option key={index} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="ชื่อเจ้าหน้าที่ผู้เจาะเก็บ" name="bloodNeedlerName">
            <Select className="w-100">
              {STAFF_NAME.map((item, index) => (
                <Select.Option key={index} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="ชื่อเจ้าหน้าที่เก็บตัวอย่างเลือด" name="collectSamplerName">
            <Select className="w-100">
              {STAFF_NAME.map((item, index) => (
                <Select.Option key={index} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item label="ชื่อเจ้าหน้าที่บันทึกข้อมูล/ผู้ตรวจสอบ" name="recorderName">
            <Select className="w-100">
              {STAFF_NAME.map((item, index) => (
                <Select.Option key={index} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {bookingData?.screenStatus === 'screenFailed' && (
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item label="เหตุผลที่ไม่ผ่านการคัดกรอง" name="reasonToReject">
              <Select
                className="w-100"
                mode="multiple"
                size="large"
                placeholder="เลือกได้หลายข้อ"
                onChange={(val) => {
                  setSelectedReasons(val)
                  form.setFieldsValue({
                    selectedReasons: val,
                  })
                }}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: '8px 0',
                      }}
                    />
                    <Space
                      style={{
                        padding: '0 8px 4px',
                      }}
                    >
                      <Input placeholder="Ex.Deferred due to ..." ref={inputRef} value={extendedReason} onChange={(e) => setExtendedReason(e.target.value)} />
                      <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                        เพิ่มเติมเลือก
                      </Button>
                    </Space>
                  </>
                )}
              >
                {reasonsToReject.map((item, index) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item
            label="ชื่อผู้แก้ไขแบบฟอร์ม"
            name="staffName"
            rules={[
              {
                required: true,
                message: 'กรุณากรอกชื่อผู้แก้ไขแบบฟอร์ม',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Form.Item
            label="เหตุผลที่แก้ไขแบบฟอร์ม"
            name="reasonToEdit"
            rules={[
              {
                required: true,
                message: 'กรุณากรอกเหตุผลที่แก้ไขแบบฟอร์ม',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Button type="primary" block size="large" htmlType="submit">
            บันทึกข้อมูลแบบฟอร์ม
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default DonationEditForm

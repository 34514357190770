import React, { useState, useRef, useEffect, useMemo } from 'react'
import { PropTypes } from 'prop-types'
import { FileOutlined, PrinterOutlined } from '@ant-design/icons'
import { Input, Tag, Modal, Typography, Popconfirm, Row, Col, Card, message, Spin } from 'antd'
import styled from 'styled-components'
import { Formik } from 'formik'
import moment from 'moment'
import ReactToPrint, { useReactToPrint } from 'react-to-print'
import jwtDecode from 'jwt-decode'
import Table from '../Table'
import TextBase from '../Text'
import Button from '../Button'
import './index.css'
import { TABLE_TYPES, BOOKING_DOCTOR_STATUS, LOGISTIC, PAYMENT_STATUS } from '../../constant'
import ModalPatientHistory from '../Modal/PatientHistory'
import ModalMedicineEphis from '../Modal/MedicineEphis'
import ModalPrescriptionTrackingNumber from '../Modal/PrescriptionTrackingNumber'
import ComponentToPrint from '../ComponentToPrint'
import http from '../../services/httpServiceEver'
import httpOma from '../../services/httpServiceHealthCare'
import DrugCompliance from '../PatientProfile/DrugCompliance'
import DrugImage from '../../images/drugs.png'
import CheckHnnumber from '../CheckHnNumber'

const { Title } = Typography
// if necessary

const { Search: SearchAnt } = Input
const Text = styled(TextBase)`
  font-weight: bold;
`
const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`

const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
const Container = styled.div`
  width: 100%;
  padding: 20px;
`

const formatDate = (dateText) => {
  if (dateText) {
    const date = new Date(dateText)
    return date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })
  }
  return ''
}

const ConsultCaseTable = ({
  Cases,
  tableType,
  confirmOrder,
  onViewDetailCase,
  history,
  getTracking,
  state,
  fetchPatient,
  fetchUserVisitedByVerifiedFromHIE,
  setStatus,
  addTrackingNumber,
  setStatusPrescription,
  fetchDetailTrackingNumber,
  setIsSticker,
  fetchUserFcmToken,
  refreshData,
  thaiPost,
  setPrint,
  setPrintTime,
  setPharmacyStore,
  fetchPharmacyStore,
  trackingAvailable,
  fetchUserImage,
  fetchAppUserIdentity,
  isHistoryPage,
  isLoading,
  setIsLoading,
  ...rest
}) => {
  const [visibleModalMedicine, setVisibleModalMedicine] = useState(false)
  const [visibleModalPrinter, setVisibleModalPrinter] = useState(false)
  const [bookingId, setBookingId] = useState()
  const [booking, setBooking] = useState(false)
  const [doctor, setDoctor] = useState(false)
  const [patient, setPatient] = useState(false)
  const [prescription, setPrescription] = useState(false)
  const [prescriptionId, setPrescriptionId] = useState(false)
  const [selectOptions, setSelectOptions] = useState([])
  const [bookingStatus, setBookingStatus] = useState('')
  const [qrcode, setQrcode] = useState('')
  const [visibleModalPatientHistory, setVisibleModalPatientHistory] = useState(false)
  const [visibleModalPrescription, setVisibleModalPrescription] = useState(false)
  const [visibleModalEms, setVisibleModalEms] = useState(false)
  const [visibleDrugCompliance, setVisibleDrugCompliance] = useState(false)
  const [patientId, setPatientId] = useState(false)
  const [omaId, setOmaId] = useState(false)
  const [vstDate, setVstDate] = useState(false)
  const [orderNumber, setOrderNumber] = useState(false)
  const [ems, setEms] = useState(false)
  const [filterData, setFilterData] = useState(false)
  const [searchText, setSearchText] = useState(false)
  const { roles } = jwtDecode(localStorage.getItem('token'))
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const setColor = (status) => {
    if (status.includes('PENDING')) {
      return 'orange'
    }
    if (status.includes('SUCCESS') || status.includes('PAID') || status.includes('COMPLETED')) {
      return 'green'
    }
    if (status.includes('DECLINE') || status.includes('CANCEL')) {
      return 'red'
    }
    return 'blue'
  }

  const drugComplianceColumn = useMemo(() => {
    return isHistoryPage
      ? [
          {
            title: 'Drug Compliance',
            key: 'drugCompliance',
            align: 'center',
            width: 115,
            render: (text, record, index) => (
              <Button
                type="primary"
                onClick={async () => {
                  const everOmaId = await fetchAppUserIdentity(text.patientId)
                  await fetchUserDetail(everOmaId)
                  setOmaId(everOmaId)
                  setVisibleDrugCompliance(true)
                }}
              >
                <img src={DrugImage} width={20} height={20}></img>
              </Button>
            ),
          },
        ]
      : []
  }, [isHistoryPage])

  const fetchUserDetail = async (id) => {
    setIsLoading(true)
    const { data } = await httpOma.get(`/UserInfos/userInfoByPatientId`, { params: { patientId: id } })
    setPatient(data)
    setIsLoading(false)
  }

  const statusPaymentTag = (billings) => {
    let statusPayment = {}
    const paidStatus = ['ชำระเงินแล้ว', 'ปิดบัญชี']
    const unPaidStatus = ['ยังไม่ชำระเงิน']
    billings.map((billing) => {
      if (billing.payments && billing.payments.length) {
        const checkPaid = billing.payments.every((pm) => paidStatus.includes(pm.paidstName) || (pm.itemno && pm.itemno === -1 && pm.incamt === 0))
        const checkUnPaid = billing.payments.every((pm) => unPaidStatus.includes(pm.paidstName) || (pm.itemno && pm.itemno !== -1 && pm.incamt !== 0))
        statusPayment = checkPaid ? { color: 'green', title: 'ชำระเงินแล้ว' } : checkUnPaid ? { color: 'red', title: 'ยังไม่ชำระเงิน' } : { color: 'orange', title: 'ชำระเงินบางส่วน' }
      }
    })
    return (
      <Tag
        onClick={() => {
          console.log(billings[0].payments)
        }}
        color={statusPayment.color}
      >
        {statusPayment.title}
      </Tag>
    )
  }

  useEffect(() => {
    if (state.print && booking.prescription && ((booking.prescription.logisticId === 1 && state.pharmacyStore) || booking.prescription.logisticId === 2)) {
      handlePrint()
    }
  }, [state.print, state.pharmacyStore])

  useEffect(() => {
    if (searchText) {
      onSearch(searchText)
    }
  }, [state.prescriptions])

  const isPharmacyStore = (prescription) => {
    console.log('PRESCRIPTION', prescription)

    let status = 'WAIT_FOR_PATIENT_EMS'
    fetchDetailTrackingNumber(prescription.id, true)
    if (prescription.logisticId === 1) {
      status = 'WAIT_FOR_PHARMACYSTORE_NOTIFY'
      fetchPharmacyStore(prescription.pharmacyStoreId)
    } else {
      setPharmacyStore(false)
      setTimeout(() => setPrintTime(), 1000)
    }
    if (prescription.status === 'PHARMACY_CONFIRM_RX') {
      setStatusPrescription(prescription.id, status)
    }
  }

  const convertHn = (hn) => {
    const second = hn.substring(0, 2)
    const past = hn.substring(2).split('')
    let pass = 0
    const final = past.map((string) => {
      if (pass || string !== '0') {
        pass = 1
        return string
      }
    })
    return final.join('').concat('/', second)
  }

  const addPrescriptionPickup = async (data) => {
    const response = await http.post(`/PrescriptionPickups`, data)
    if (response && response.status === 200) {
      message.success('บันทึกข้อมูลสำเร็จ')
    } else {
      message.error('เกิดข้อผิดพลาดบางอย่าง')
    }
  }

  let columns
  if (state.roles.includes('pharmacy')) {
    columns = [
      {
        title: 'ชื่อผู้ป่วย',
        key: 'patientName',
        dataIndex: 'patient',
        width: 200,
        render: (text) => {
          if (text && text.fullnameHIE) {
            return <Text>{text.fullnameHIE}</Text>
          }
          if (text && text.fullname) {
            return (
              <div>
                <Text>{text.fullname}</Text>
                <Text>(ชื่อจาก App)</Text>
              </div>
            )
          }
          return <div />
        },
        align: 'center',
      },
      {
        title: 'HN',
        key: 'hnId',
        width: 50,
        render: (record) => (
          <Text>
            <CheckHnnumber key={record} data={record} loading={<Spin />} />
          </Text>
        ),
      },
      {
        title: 'ประวัติ',
        key: 'patientHistory',
        align: 'center',
        width: 100,
        render: (record) => (
          <Button
            primary={1}
            onClick={() => {
              fetchPatient(record.patientId)
              setPatient(record.patient)
              setVisibleModalPatientHistory(true)
              fetchUserImage(record.patientId)
            }}
          >
            ประวัติผู้ป่วย
          </Button>
        ),
      },
      {
        title: 'บิลรวม',
        key: 'totalBill',
        width: 150,
        render: (record) => (
          <div className="site-button-ghost-wrapper">
            <Button
              primary={1}
              onClick={() => {
                setVisibleModalMedicine(true)
                setPrescription(record.prescription)
                fetchUserVisitedByVerifiedFromHIE(record.patientId, record.vnNumber)
              }}
            >
              รายการยา E-PHIS
            </Button>
          </div>
        ),
        align: 'center',
      },
      {
        title: 'แพทย์ผู้สั่งยา',
        key: 'consultWith',
        align: 'center',
        width: 120,
        render: (record) => (
          <Row>
            <Col span={24}>{record.doctor && record.doctor.fullname ? record.doctor.fullname : null}</Col>
          </Row>
        ),
      },
      {
        title: 'วันที่',
        key: 'date',
        align: 'center',
        width: 100,
        defaultSortOrder: 'ascend',
        render: (record) => (
          <Text
            onClick={() => {
              console.log(record)
            }}
          >
            {moment(record.admitTime).format('DD/MM/YYYY')}
          </Text>
        ),
        sorter: (a, b) =>
          moment(a.admitTime)
            .add(a.bookingTime, 'minutes')
            .unix() -
          moment(b.admitTime)
            .add(b.bookingTime, 'minutes')
            .unix(),
      },
      {
        title: 'เวลาที่พบแพทย์',
        key: 'time',
        align: 'center',
        render: (record) => (
          <Text>
            {moment()
              .startOf('day')
              .add(record.bookingTime, 'minutes')
              .format('HH:mm')}
          </Text>
        ),
      },
      {
        title: 'ยืนยันรายการยา',
        key: 'rxStatus',
        align: 'center',
        width: 130,
        sorter: (a, b) => {
          let statusA = ['PHARMACY_PENDING_RX'].includes(a.prescription.status) ? true : false
          let statusB = ['PHARMACY_PENDING_RX'].includes(b.prescription.status) ? true : false
          if ((statusA && statusB) || (!statusA && !statusB)) {
            return 0
          } else if (statusA && !statusB) {
            return -1
          } else if (!statusA && statusB) {
            return 1
          }
        },
        render: (record) =>
          BOOKING_DOCTOR_STATUS[record.prescription.status] === 'รอเภสัชยืนยันยาใน E-PHIS' ? (
            <div className="site-button-ghost-wrapper">
              <Button
                onClick={async () => {
                  const everOmaId = await fetchAppUserIdentity(record.patientId)
                  setDoctor(record.doctor)
                  trackingAvailable()
                  setBooking(record)
                  setPrescription(record.prescription)
                  setPrescriptionId(record.prescription.id)
                  fetchUserVisitedByVerifiedFromHIE(record.patientId, record.vnNumber, moment(record.admitTime).format('YYYY-MM-DD'))
                  setOmaId(everOmaId)
                  setVstDate(moment(record.admitTime).format('YYYY-MM-DD'))
                  setPatientId(record.patientId)
                  setOrderNumber(record.vnNumber)
                  setVisibleModalMedicine(true)
                  setEms(true)
                }}
                primary={1}
              >
                ยืนยันรายการยา
              </Button>
            </div>
          ) : record.prescription.logisticId === 3 && record.prescription.status !== 'SUCCESS_BY_PHARMACY' ? (
            <Popconfirm title="ต้องการยืนยันการรับยาของผู้ป่วยคนนี้ใช่หรือไม่" onConfirm={() => setStatusPrescription(record.prescriptionId, 'SUCCESS_BY_PHARMACY')} okText="ใช่" cancelText="ไม่">
              <div className="site-button-ghost-wrapper">
                <Button primary={1}>ยืนยันการรับยา</Button>
              </div>
            </Popconfirm>
          ) : null,
      },
      {
        title: 'การจัดส่ง',
        key: 'logisticId',
        dataIndex: 'prescription',
        render: (text) => <Text>{LOGISTIC[text.logisticId]}</Text>,
      },
      {
        title: 'เลข EMS',
        key: 'recieveOption',
        align: 'center',
        width: 100,
        render: (record) =>
          (record.prescription.logisticId === 1 || record.prescription.logisticId === 2) && record.prescription.status !== 'PHARMACY_PENDING_RX' ? (
            <div className="site-button-ghost-wrapper">
              <Button
                onClick={() => {
                  setVisibleModalEms(true)
                  fetchDetailTrackingNumber(record.prescription.id)
                }}
                primary={1}
              >
                EMS
              </Button>
            </div>
          ) : (
            '-'
          ),
      },
      {
        title: 'สถานะ',
        key: 'status',
        align: 'center',
        width: 150,
        render: (record) => (
          <Tag color={setColor(record.prescription.status)} style={{ width: '180px' }}>
            {BOOKING_DOCTOR_STATUS[record.prescription.status]}
          </Tag>
        ),
      },
      {
        title: 'สถานะการชำระเงิน',
        key: 'statusPayment',
        align: 'center',
        width: 150,
        render: (record) => (record.billings && record.billings.length ? statusPaymentTag(record.billings) : '-'),
      },
      {
        title: 'พิมพ์สติ๊กเกอร์',
        key: 'print',
        align: 'center',
        width: 115,
        render: (text, record, index) =>
          (record.prescription.logisticId === 1 || record.prescription.logisticId === 2) &&
          record.prescription.status !== 'PHARMACY_PENDING_RX' && (
            <div className="center-printer">
              <PrinterOutlined
                id={`print-button-${index}`}
                style={{ fontSize: '20px', cursor: 'pointer' }}
                onClick={() => {
                  setBooking(record)
                  isPharmacyStore(record.prescription)
                }}
              />
              {state.print && (
                <div className="print-container" style={{ display: 'none' }}>
                  <ComponentToPrint
                    trackingNumber={state.trackingNumber}
                    booking={booking}
                    pharmacyStore={state.pharmacyStore}
                    isSticker={state.isSticker}
                    setIsSticker={(condition) => setIsSticker(condition)}
                    thaiPost={(barcode) => thaiPost(barcode)}
                    stickers={state.stickers}
                    ref={componentRef}
                  />
                </div>
              )}
            </div>
          ),
      },
      ...drugComplianceColumn,
    ]
  } else if (state.roles.includes('pharmacyStore')) {
    columns = [
      {
        title: 'ชื่อผู้ป่วย',
        key: 'patientName',
        dataIndex: 'patient',
        width: 200,
        render: (text) => <Text>{text.fullname}</Text>,
        align: 'center',
      },
      {
        key: 'patientHistory',
        align: 'center',
        width: 150,
        render: (record) => (
          <Button
            primary={1}
            onClick={() => {
              fetchPatient(record.patientId)
              setPatient(record.patient)
              setVisibleModalPatientHistory(true)
            }}
          >
            ประวัติผู้ป่วย
          </Button>
        ),
      },
      {
        title: 'รายการ',
        key: 'totalBill',
        width: 150,
        render: (record) => (
          <div className="site-button-ghost-wrapper">
            <Button
              primary={1}
              onClick={() => {
                setVisibleModalMedicine(true)
                setPrescription(record.prescription)
                fetchUserVisitedByVerifiedFromHIE(record.patientId, record.vnNumber)
              }}
            >
              รายการยา
            </Button>
          </div>
        ),
        align: 'center',
      },
      {
        title: 'วันที่',
        key: 'date',
        align: 'center',
        width: 150,
        render: (record) => <Text>{moment(record.admitTime).format('DD/MM/YYYY')}</Text>,
        sorter: (a, b) => moment(b.admitTime).unix() - moment(a.admitTime).unix(),
      },
      {
        title: 'คำสั่ง',
        key: 'rxStatus',
        align: 'center',
        width: 150,
        render: (record) =>
          BOOKING_DOCTOR_STATUS[record.prescription.status] === 'รอร้านยากดแจ้งผู้ป่วย' ? (
            <Popconfirm
              title="ต้องการแจ้งเตือนการมาถึงของยาให้ผู้ป่วยรับทราบใช่หรือไม่"
              onConfirm={() => {
                setStatusPrescription(record.prescriptionId, 'WAIT_FOR_PATIENT_PHAMACYSTORE')
                fetchUserFcmToken(record.patientId, 'รายการยาของคุณมาถึงร้านยาแล้ว', 'MyBookingsUI')
              }}
              okText="ใช่"
              cancelText="ไม่"
            >
              <div className="site-button-ghost-wrapper">
                <Button primary={1}>กดแจ้งเตือนผู้ป่วย</Button>
              </div>
            </Popconfirm>
          ) : BOOKING_DOCTOR_STATUS[record.prescription.status] === 'รอผู้ป่วยมารับยา' ? (
            <Popconfirm title="ต้องการยืนยันการรับยาของผู้ป่วยใช่หรือไม่" onConfirm={() => setStatusPrescription(record.prescriptionId, 'SUCCESS_BY_PHARMACYSTORE')} okText="ใช่" cancelText="ไม่">
              <div className="site-button-ghost-wrapper">
                <Button primary={1}>ผู้ป่วยรับยาแล้ว</Button>
              </div>
            </Popconfirm>
          ) : (
            ''
          ),
      },
      {
        title: 'วิธีการจัดส่ง',
        key: 'recieveOption',
        align: 'center',
        width: 120,
        render: (record) =>
          (record.prescription.logisticId === 1 || record.prescription.logisticId === 2) && (
            <div className="site-button-ghost-wrapper">
              <Button
                onClick={() => {
                  setVisibleModalEms(true)
                  fetchDetailTrackingNumber(record.prescription.id)
                }}
                primary={1}
              >
                EMS
              </Button>
            </div>
          ),
      },
      {
        title: 'สถานะ',
        key: 'status',
        align: 'center',
        width: 150,
        render: (record) => (
          <div>
            <Tag color={setColor(record.prescription.status)} style={{ width: '200px' }}>
              {BOOKING_DOCTOR_STATUS[record.prescription.status]}
            </Tag>
          </div>
        ),
      },
    ]
  }

  if (history.location.pathname.includes('history') && state.roles.includes('pharmacy')) {
    columns.splice(7, 1)
    columns.splice(10, 2)
    //splice confirm drug, payment status, print sticker columns
  }

  const onSearch = (text) => {
    const filter = state.prescriptions.filter(
      (data) =>
        (data.patient && data.patient.fullnameHIE && data.patient.fullnameHIE.includes(text)) ||
        (data.patient && data.patient.fullname && data.patient.fullname.includes(text)) ||
        (data.patient && data.patient.hnId && data.patient.hnId.includes(text))
    )
    setSearchText(text.length ? text : false)
    setFilterData(text.length ? filter : false)
  }

  const headerText = tableType === TABLE_TYPES.history ? 'ประวัติผู้ป่วย' : 'รายการสั่งยา'

  return (
    <Formik initialValues={{ filter: '' }}>
      {(props) => {
        const { values, handleChange } = props
        return (
          <Container>
            <HeaderWrapper>
              <FileOutlined />
              <Text bold>{headerText}</Text>
              {!history.location.pathname.includes('history') && <Text> ({state.time})</Text>}
              <Button className="ml-2" onClick={() => refreshData()}>
                รีโหลด
              </Button>
              <Search onSearch={onSearch} style={{ width: 200, marginLeft: 'auto' }} />
            </HeaderWrapper>
            <Table columns={columns} scroll={{ x: 'max-content' }} dataSource={filterData || state.prescriptions} loading={state.isLoading} {...rest} />

            <Modal visible={visibleDrugCompliance} onOk={() => setVisibleDrugCompliance(false)} onCancel={() => setVisibleDrugCompliance(false)} width={960}>
              <DrugCompliance patient={{ ...patient, userId: omaId }}></DrugCompliance>
            </Modal>

            <Modal visible={visibleModalEms} title="EMS" footer={null} onOk={() => setVisibleModalEms(false)} onCancel={() => setVisibleModalEms(false)}>
              {state.ems.map((x, index) => (
                <Card className={index !== 0 && 'mt-4'} title={x.trackingNumberId.barcode}>
                  <Row>
                    <Col span={12}>สถานที่</Col>
                    <Col span={12}>{x.trackingNumberId.location && x.trackingNumberId.postcode && `${x.trackingNumberId.location}, ${x.trackingNumberId.postcode}`}</Col>
                    <Col span={12}>สถานะ</Col>
                    <Col span={12}>{x.trackingNumberId.status}</Col>
                  </Row>
                </Card>
              ))}
            </Modal>
            <ModalPrescriptionTrackingNumber
              visible={visibleModalPrescription}
              setVisible={(visible) => setVisibleModalPrescription(visible)}
              prescriptionId={prescriptionId}
              isSuccess={() => setStatusPrescription(prescriptionId, 'PATIENT_PENDING_PAYMENT')}
            />
            <ModalPatientHistory
              isLoading={state.isLoading}
              hieData={state.hieData}
              patient={state.patientData}
              visible={visibleModalPatientHistory}
              role={roles[0]}
              setVisible={(visible) => setVisibleModalPatientHistory(visible)}
              image={state.image}
            />
            <ModalMedicineEphis
              booking={booking}
              addTrackingNumber={(quantity) => {
                addTrackingNumber(prescriptionId, quantity)
              }}
              isLoading={state.isLoading}
              emsAvailable={state.emsAvailable}
              visible={visibleModalMedicine}
              ems={ems}
              setEms={(ems) => setEms(ems)}
              setVisible={(visible) => setVisibleModalMedicine(visible)}
              isSuccess={(status) => {
                setStatusPrescription(prescriptionId, status)
                fetchUserFcmToken(patientId, 'เภสัชกรยืนยันรายการยาเรียบร้อย', 'TelePharmacist')
              }}
              addPrescriptionPickup={addPrescriptionPickup}
              drugsDetail={state.drugsDetail}
              prescription={prescription}
              fetchUserVisitedByVerifiedFromHIE={() => fetchUserVisitedByVerifiedFromHIE(patientId, orderNumber, vstDate)}
              items={state.billingItems}
              fetchUserFcmToken={fetchUserFcmToken}
              patientId={patientId}
              prescriptionId={prescriptionId}
              doctor={doctor}
            />
          </Container>
        )
      }}
    </Formik>
  )
}
ConsultCaseTable.defaultProps = {
  onViewDetailCase: () => {},
}
ConsultCaseTable.propTypes = {
  Cases: PropTypes.array.isRequired,
  tableType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
}
export default ConsultCaseTable

import React, { useEffect, useState, useRef } from 'react'
import { Card, Row, Col, Button, DatePicker } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import moment from 'moment'
import { message } from 'antd'
function RiskCard(props) {
  const { setVisibleRiskModal, setVisibleYearlyAssessment, risk, updateDiagnosisStartDate, patient, editable } = props
  const [changeDetail, setChangeDetail] = useState(false)
  const [detail, setDetail] = useState('')
  const [newDetail, setNewDetail] = useState('')
  useEffect(() => {
    if (patient && patient.diagnosisStartDate) {
      setDetail(patient.diagnosisStartDate)
    } else {
      setDetail(moment())
    }
  }, [patient])

  const changeDiagnosisStartDate = async () => {
    const date = newDetail ? newDetail : detail
    await updateDiagnosisStartDate(moment(date))
    setChangeDetail(false)
  }
  return (
    <Card className="mt-2">
      <Row align="middle" justify="center">
        <Col span={12} className="px-2">
          {` เริ่มวินิจฉัยเบาหวาน: `}
          {editable ? (
            changeDetail ? (
              <div>
                <DatePicker onChange={(date) => setNewDetail(date)} defaultValue={moment(detail)} format="DD/MM/YYYY" />
                <Row gutter={[4, 4]}>
                  <Col>
                    <Button
                      className="mt-2"
                      onClick={() => {
                        setChangeDetail(false)
                      }}
                    >
                      ยกเลิก
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="mt-2"
                      type="primary"
                      onClick={() => {
                        changeDiagnosisStartDate()
                      }}
                    >
                      บันทึก
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <Button onClick={() => setChangeDetail(true)}>
                {moment(detail).format('DD/MM/YYYY')} <EditOutlined />
              </Button>
            )
          ) : (
            <b>{moment(detail).format('DD/MM/YYYY')}</b>
          )}
        </Col>
        <Col span={12}>
          {`Thai CV Risk Score: `}
          {editable ? (
            <Button type="primary" onClick={() => setVisibleRiskModal(true)}>
              {risk} %
            </Button>
          ) : (
            <b>{risk}%</b>
          )}
        </Col>
      </Row>
    </Card>
  )
}
export default RiskCard

import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Select, Cascader } from 'antd'
import { initiaObviousDeathValues, obviousDeathFormTemplate } from './constant'
import ToggleToken from '../../ToggleToken'
import styled from 'styled-components'
import UploadImage from './UploadImage'
import TextInput from '../../TextInput'
const Text = styled('p')`
  font-size: 18px;
  padding-right: 10px;
  font-weight: bold;
`
const FormBox = styled(Row)`
  padding: 10px;
`

const ObviousDeath = ({ setFormValue, initialValues, formId }) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({ biologicalDeath: [], forensicSigns: [], lead1: '', lead2: '', lead3: '' })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (initialValues && initialValues.obviousDeath) {
      setFormData((prev) => ({ ...prev, ...initialValues.obviousDeath }))
      form.setFieldsValue(initialValues.obviousDeath)
    }
    setLoading(false)
  }, [initialValues])

  const handleValue = (field, value) => {
    form.setFieldsValue({ [field]: value })
    const allData = form.getFieldsValue(true)
    setFormValue({ ['obviousDeath']: allData })
  }

  const handleOptionDropdown = (items) => {
    return items.map((item, index) => (
      <Option key={index} value={item}>
        {item}
      </Option>
    ))
  }
  return loading ? (
    <></>
  ) : (
    <Form form={form} initialValues={initiaObviousDeathValues} name="cardiacArrest" className="additional-form-section">
      <div>
        <h2>Obvious Death</h2>
        {obviousDeathFormTemplate.map((template, index) => (
          <div className="additional-form-subsection" key={index}>
            {template.header && <p className="h5">{template.header}</p>}
            {template.data.map((data) => (
              <Form.Item name={data.value}>
                {data.type === 'upload' ? (
                  <UploadImage
                    label={data.label}
                    preview={formData[data.value]}
                    path={`EmsImages/${formId}/ObviousDeath`}
                    handleValue={(value) => {
                      handleValue(data.value, value)
                      setFormData((prevState) => ({ ...prevState, [data.value]: value }))
                    }}
                  />
                ) : data.type === 'radio' ? (
                  <ToggleToken
                    toStr={true}
                    title={data.label}
                    max={1}
                    options={data.items}
                    values={formData[data.value]}
                    extraInputs={data.extraInputs ? data.extraInputs : false}
                    setValues={(value) => {
                      setFormData((prevState) => ({ ...prevState, [data.value]: value }))
                      handleValue(data.value, value)
                    }}
                  />
                ) : data.type === 'checkbox' ? (
                  <ToggleToken
                    title={data.label}
                    options={data.items}
                    values={formData[data.value]}
                    extraInputs={data.extraInputs ? data.extraInputs : false}
                    setValues={(value) => {
                      setFormData((prevState) => ({ ...prevState, [data.value]: value }))
                      handleValue(data.value, value)
                    }}
                  />
                ) : data.type === 'text' ? (
                  <TextInput
                    label={data.label}
                    onChange={(event) => {
                      setFormData((prevState) => ({ ...prevState, [data.value]: event.target.value }))
                      handleValue(data.value, event.target.value)
                    }}
                  />
                ) : data.type === 'dropdown' ? (
                  <FormBox justify="start">
                    <Col span={12}>
                      <Text>{data.label}</Text>
                    </Col>
                    <Col span={12}>
                      <Select showSearch allowClear optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder={data.placeholder ? data.placeholder : ''}>
                        {handleOptionDropdown(data.items ? data.items : [])}
                      </Select>
                    </Col>
                  </FormBox>
                ) : (
                  data.type === 'cascader' && (
                    <FormBox justify="start">
                      <Text>{data.label}</Text>
                      <Cascader
                        placeholder={data.placeholder ? data.placeholder : ''}
                        expandTrigger="hover"
                        options={data.items}
                        onChange={(value) => {
                          setFormData((prevState) => ({ ...prevState, [data.value]: value }))
                          handleValue(data.value, value)
                        }}
                      />
                    </FormBox>
                  )
                )}
              </Form.Item>
            ))}
          </div>
        ))}
      </div>
    </Form>
  )
}

export default ObviousDeath

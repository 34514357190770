import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceHealthCare'

const apiEndpoint = '/monitoringReports'

class MonitoringReport {
  @observable info = {
    data: [],
    error: null,
  }

  @action fetchData = async (userId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}?filter[where][appUserId]=${userId}`)
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action fetchSmbgReports = async (page, limit) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/smbg?page=${page}&limit=${limit}`)
      this.data = data
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action fetchHbpmReports = async (page, limit) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/hbpm?page=${page}&limit=${limit}`)
      this.data = data
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @computed get getMonitoringReport() {
    return toJS(this.logistic.data)
  }
}

export default new MonitoringReport()

/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react'
import Layout from '../Home'
import { observer, inject } from 'mobx-react'
import FollowUpBookingTable from '../../components/FollowUpBookingTable'
import { message } from 'antd'
@inject('Appointment')
@inject('Booking')
@observer
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableLoading: true,
      buttonLoading: {},
      followUpBookings: [],
      filterBookings: [],
    }
  }
  componentDidMount() {
    this.fetchFollowUpBooking()
  }

  fetchFollowUpBooking = async () => {
    this.setState({
      tableLoading: true,
    })
    const { Booking } = this.props
    const response = await Booking.fetchFollowUpBooking()
    this.setState({
      followUpBookings: response,
      filterBookings: [],
      tableLoading: false,
    })
  }

  fetchNextAppointment = async (bookingId) => {
    console.log(this.state.buttonLoading)
    let buttonLoad = this.state.buttonLoading
    buttonLoad[bookingId] = true
    this.setState({
      buttonLoading: buttonLoad,
    })
    const { Appointment, Booking } = this.props
    const nextAppointmentData = await Appointment.getNextAppointment(bookingId)
    if (nextAppointmentData) {
      await Booking.updateBooking(bookingId, { nextAppointment: nextAppointmentData.appointmentDateTime })
      this.fetchFollowUpBooking()
      message.success('โหลดข้อมูลนัดหมายสำเร็จ')
    } else {
      message.error('ไม่พบข้อมูลนัดหมาย')
    }
    console.log('nextAppointmentData')
    console.log(nextAppointmentData)
    buttonLoad[bookingId] = false
    this.setState({
      buttonLoading: buttonLoad,
    })
  }

  handleUpdateBooking = async (bookingId) => {
    const { Booking } = this.props
    this.setState({
      tableLoading: true,
    })
    const response = await Booking.updateBooking(bookingId, { followUp: false })
    if (response) {
      message.success('เปลี่ยนสถานะการนัดหมายสำเร็จ')
      this.fetchFollowUpBooking()
    }
    this.setState({
      tableLoading: false,
    })
  }

  onSearch = (patientName) => {
    const filterByPatientName = this.state.followUpBookings.filter((item) => item.patient.fullnameHIE.includes(patientName) || item.patient.fullname.includes(patientName))
    this.setState({
      filterBookings: filterByPatientName,
    })
  }

  render() {
    return (
      <Layout
        {...this.props}
        render={(
          props, //eslint-disable-line
        ) => (
          <div className="w-100">
            <FollowUpBookingTable
              followUpBookings={this.state.filterBookings.length ? this.state.filterBookings : this.state.followUpBookings}
              loading={this.state.tableLoading}
              buttonLoading={this.state.buttonLoading}
              onSearch={this.onSearch}
              fetchNextAppointment={this.fetchNextAppointment}
              handleUpdateBooking={this.handleUpdateBooking}
            />
          </div>
        )}
      />
    )
  }
}

import React, { useEffect, useState } from 'react'
import Layout from '../Home'
import HistoryTable from '../../components/BloodDonorTable/HistoryDonationTable'
import { hooks } from './hooks'
import { filterBookingsByType } from './utils'
const BloodDonationHistory = (props) => {
  const [bookings, setBookings] = useState([])
  const { history } = props
  const { events, params } = hooks()
  const donationOperationType = localStorage.getItem('donationOperationType') ?? 'default'
  useEffect(() => {
    events.dispatch(events.fetchBookings())
    events.dispatch(events.fetchExcelDonorForm())
  }, [])

  useEffect(() => {
    if (params.donorBookingLists?.length) {
      // const filteredBookings = filterBookingsByType(params.donorBookingLists, donationOperationType)
      const filteredBookings = params.donorBookingLists
      setBookings(filteredBookings)
    }
  }, [params.donorBookingLists])

  return (
    <Layout
      history={history}
      render={() => {
        return (
          <div className="w-100">
            <HistoryTable dataSource={bookings} dataExport={params.donorExcelBookingForm} convertTime={events.convertTime} loading={params.isDonorBookingLoading} isExcelLoading={params.isDonorExcelBookingFormLoading} />
          </div>
        )
      }}
    />
  )
}

export default BloodDonationHistory

import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Checkbox, Input } from 'antd'
import { emsTreatment } from './constant'
import ToggleToken, { TTAttch } from '../../ToggleToken'
import styled from 'styled-components'
import './style.css'
const Text = styled('p')`
  font-size: 16px;
  font-weight: bold;
`
const EmsTreatment = ({ setFormValue, formValues, initialValues }) => {
  const [formData, setFormData] = useState({
    breathing: [],
    contusion: [],
    waterSupply: [],
    boneSplint: [],
    cpr: [],
    medicine: '',
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    console.log(initialValues)
    if (initialValues && initialValues.emsTreatment && Object.keys(initialValues.emsTreatment).length > 0) {
      setFormData((prevState) => ({ ...prevState, ...initialValues.emsTreatment }))
      if (initialValues.emsTreatment.breathing) {
        const etNo = initialValues.emsTreatment.breathing.find((entry) => entry.split('→')[0] == 'ET No')
        etNo && setEtDepth(etNo.split('→')[2])
      }
      setFormValue({ ['emsTreatment']: initialValues.emsTreatment })
    }
    setLoading(false)
  }, [initialValues])

  const [etDepth, setEtDepth] = useState('')
  const [txtAreaSize, setTxtAreaSize] = useState()
  const handleValue = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }))
    setFormValue({ ['emsTreatment']: { ...formValues['emsTreatment'], [field]: value } })
  }
  const autoResize = (target) => {
    target.style.height = 'auto'
    setTxtAreaSize(target.scrollHeight)
  }
  return loading ? (
    <></>
  ) : (
    <div className="treatment-table w-100">
      <h1 style={{ margin: '0 0 0.5em 0' }}>EmsTreatment</h1>
      <div className="ems-treatment-layout">
        {emsTreatment.map((item) =>
          item.type === 'checkbox' ? (
            <ToggleToken
              key={item.name}
              title={item.name}
              extraInputs={item.extraInputs}
              options={item.data}
              values={formData[item.value]}
              setValues={(value) => {
                handleValue([item.value], value)
              }}
            >
              <TTAttch attachTo="ET No" attachmentVal={etDepth}>
                <div className="label">M</div>
                <input
                  onClick={(e) => e.stopPropagation()}
                  value={etDepth}
                  onInput={({ target }) => {
                    setEtDepth(target.value)
                    const fdBreathing = formData.breathing.map((symptom) => {
                      if (symptom.includes('ET No')) {
                        const stBreakdown = symptom.split('→')
                        stBreakdown[2] = target.value
                        return stBreakdown.reduce((a, b) => `${a}→${b}`)
                      }
                      return symptom
                    })
                    setFormData((prevState) => ({ ...prevState, ['breathing']: fdBreathing }))
                    setFormValue({ ['emsTreatment']: { ['breathing']: fdBreathing } })
                    // setFormValue('emsTreatment', { breathing: `${target.value}` })
                  }}
                ></input>
              </TTAttch>
            </ToggleToken>
          ) : (
            item.type === 'text' && (
              <div className="toggle-token-panel" key={item.name}>
                <div className="toggle-token-title">
                  <h2>{item.name}</h2>
                </div>
                <Input.TextArea
                  value={formData[item.value]}
                  style={{ height: `${txtAreaSize}px` }}
                  onChange={(e) => {
                    handleValue([item.value], e.target.value)
                    autoResize(e.target)
                  }}
                  placeholder="กล่องข้อความ"
                />
              </div>
            )
          ),
        )}
      </div>
    </div>
  )
}

export default EmsTreatment

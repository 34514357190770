import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/doctorAvailableTimes'

class DoctorAvailableTime {
  @observable info = {
    data: [],
    error: null
  }

  @action fetchfilterType = async (id, type) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/filterType`, {
        params: {
          doctorId: id,
          type
        }
      })
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action updateDoctorAvailableTime = async (id, info) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/updateAttributeByDoctorId`, {
        doctorId: id,
        time: info.time,
        minute: info.minute ? parseInt(info.minute) : 15,
        type: 'weekly'
      })
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @computed get getDoctorAvailableTime() {
    return toJS(this.info.data)
  }
}

export default new DoctorAvailableTime()

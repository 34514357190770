import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles, { Column, Row, CheckboxWithLabel } from '../styles'
import { BODY_SECTION, BODY_SECTION_HEADER } from '../constant'
import { get, keys, intersection } from 'lodash'
import CheckIcon from '../widgets/CheckIcon'

export default function BodySectionTable({ data }) {
  const trauma = get(data, "trauma", "")
  const deformit = keys(get(data, "injuryPain.deformity", {}))
  const disfix = keys(get(data, "injuryPain.disFx", {}))
  const contusio = keys(get(data, "injuryPain.contusion", {}))
  const abrasion = keys(get(data, "injuryPain.abrasion", {}))
  const penetratingPuncture = keys(get(data, "injuryPain.penetratingPuncture", {}))
  const blunt = keys(get(data, "injuryPain.blunt", {}))
  const laceration = keys(get(data, "injuryPain.laceration", {}))
  const swelling = keys(get(data, "injuryPain.swelling", {}))
  const tenderness = keys(get(data, "injuryPain.tenderness", {}))
  const gunshot = keys(get(data, "injuryPain.gunshot", {}))
  const burnObject = get(data, "injuryPain.burns", {})
  const burnArray = keys(burnObject)
  const other = get(data, "injuryPain.other", {})
  const otherArray = keys(other)

  return (
    <View style={styles.borderedContainer}>
      <Row key="header">
        <Column borderRight borderBottom width={70} paddingHorizontal={3}>
          <CheckboxWithLabel small title="Non-trauma pt." checked={trauma === "Non-trauma pt."} />
          <View style={{ height: 3 }} />
          <CheckboxWithLabel small title="Trauma pt." checked={trauma === "Trauma pt."} />
        </Column>
        {
          BODY_SECTION_HEADER.map((e, index) => {
            return (
              <Column key={index} borderBottom borderRight={index !== BODY_SECTION_HEADER.length - 1} width={e === "Burns" || e === "" ? 30 : 18} height={45}>
                <Text style={{
                  top: 25,
                  left: e === "Burns" || e === "" ? -13 : -16,
                  fontSize: 7,
                  textAlign: 'right',
                  width: 50,
                  position: 'absolute',
                  transform: 'rotate(-90deg)'
                }}>{e === "" && other["name"] ? other["name"] : e}</Text>
              </Column>
            )
          })
        }
      </Row>
      <View>
        {BODY_SECTION.map((e, index) => {
          const isBorderBottom = index !== BODY_SECTION.length - 1
          return (
            <Row key={e.label}>
              <Column borderBottom={isBorderBottom} borderRight width={70}>
                <Text style={styles.normalText}>{e.label}</Text></Column>
              <Column itemsCenter key="deformit" borderBottom={isBorderBottom} borderRight width={18}>
                {intersection(e.key, deformit).length > 0 && <CheckIcon width={10} height={10} />}
              </Column>
              <Column itemsCenter key="dis" borderBottom={isBorderBottom} borderRight width={18}>
                {intersection(e.key, disfix).length > 0 && <CheckIcon width={10} height={10} />}
              </Column>
              <Column itemsCenter key="contusio" borderBottom={isBorderBottom} borderRight width={18}>
                {intersection(e.key, contusio).length > 0 && <CheckIcon width={10} height={10} />}
              </Column>
              <Column itemsCenter key="abrasion" borderBottom={isBorderBottom} borderRight width={18}>
                {intersection(e.key, abrasion).length > 0 && <CheckIcon width={10} height={10} />}
              </Column>
              <Column itemsCenter key="penetratingPuncture" borderBottom={isBorderBottom} borderRight width={18}>
                {intersection(e.key, penetratingPuncture).length > 0 && <CheckIcon width={10} height={10} />}
              </Column>
              <Column itemsCenter key="blunt" borderBottom={isBorderBottom} borderRight width={18}>
                {intersection(e.key, blunt).length > 0 && <CheckIcon width={10} height={10} />}
              </Column>
              <Column itemsCenter key="laceration" borderBottom={isBorderBottom} borderRight width={18}>
                {intersection(e.key, laceration).length > 0 && <CheckIcon width={10} height={10} />}
              </Column>
              <Column itemsCenter key="swelling" borderBottom={isBorderBottom} borderRight width={18}>
                {intersection(e.key, swelling).length > 0 && <CheckIcon width={10} height={10} />}
              </Column>
              <Column itemsCenter key="tenderness" borderBottom={isBorderBottom} borderRight width={18}>
                {intersection(e.key, tenderness).length > 0 && <CheckIcon width={10} height={10} />}
              </Column>
              <Column itemsCenter key="gunshot" borderBottom={isBorderBottom} borderRight width={18}>
                {intersection(e.key, gunshot).length > 0 && <CheckIcon width={10} height={10} />}
              </Column>
              <Column itemsCenter key="burn" borderBottom={isBorderBottom} borderRight width={30}>
                <Text style={styles.smallerText}>
                  {intersection(e.key, burnArray).length > 0 && e.key.map((item, i) => {
                    return get(burnObject, item, "") ?
                      (e.key.length !== i && i !== 0 ? "/" : "") + burnObject[item] : ""
                  })}
                </Text>
              </Column>
              <Column itemsCenter borderBottom={isBorderBottom} width={30}>
                <Text style={styles.smallerText}>
                  {intersection(e.key, otherArray).length > 0 && e.key.map((item,i) => {
                    return get(other, item, "") ?
                      (e.key.length !== i && i !== 0 ? "/" : "") + other[item] : ""
                  })}
                </Text>
              </Column>
            </Row>
          )
        })}
      </View>
    </View>
  )
}

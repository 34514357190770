export const Days = Object.freeze([
    Object.freeze({
        en: Object.freeze({
            full: "Sunday",
            shrt: "Sun",
            abbr: "S"
        }),
        th: Object.freeze({
            full: "อาทิตย์",
            abbr: "อา"
        })
    }),
    Object.freeze({
        en: Object.freeze({
            full: "Monday",
            shrt: "Mon",
            abbr: "M"
        }),
        th: Object.freeze({
            full: "จันทร์",
            abbr: "จ"
        })
    }),
    Object.freeze({
        en: Object.freeze({
            full: "Tuesday",
            shrt: "Tue",
            abbr: "T"
        }),
        th: Object.freeze({
            full: "อังคาร",
            abbr: "อ"
        })
    }),
    Object.freeze({
        en: Object.freeze({
            full: "Wednesday",
            shrt: "Wed",
            abbr: "W"
        }),
        th: Object.freeze({
            full: "พุธ",
            abbr: "พ"
        })
    }),
    Object.freeze({
        en: Object.freeze({
            full: "Thursday",
            shrt: "Thu",
            abbr: "Th"
        }),
        th: Object.freeze({
            full: "พฤหัสบดี",
            abbr: "พฤ"
        })
    }),
    Object.freeze({
        en: Object.freeze({
            full: "Friday",
            shrt: "Fri",
            abbr: "F"
        }),
        th: Object.freeze({
            full: "ศุกร์",
            abbr: "ศ"
        })
    }),
    Object.freeze({
        en: Object.freeze({
            full: "Saturday",
            shrt: "Sat",
            abbr: "S"
        }),
        th: Object.freeze({
            full: "เสาร์",
            abbr: "ส"
        })
    }),
])
export const Months = Object.freeze([
    Object.freeze({
        en: Object.freeze({
            full: "January",
            abbr: "Jan"
        }),
        th: Object.freeze({
            full: "มกราคม",
            abbr: "ม.ค."
        })
    }), 
    Object.freeze({
        en: Object.freeze({
            full: "February",
            abbr: "Feb"
        }),
        th: Object.freeze({
            full: "กุมภาพันธ์",
            abbr: "ก.พ."
        })
    }), 
    Object.freeze({
        en: Object.freeze({
            full: "March",
            abbr: "Mar"
        }),
        th: Object.freeze({
            full: "มีนาคม",
            abbr: "มี.ค."
        })
    }), 
    Object.freeze({
        en: Object.freeze({
            full: "April",
            abbr: "Apr"
        }),
        th: Object.freeze({
            full: "เมษายน",
            abbr: "เม.ย."
        })
    }), 
    Object.freeze({
        en: Object.freeze({
            full: "May",
            abbr: "May"
        }),
        th: Object.freeze({
            full: "พฤษภาคม",
            abbr: "พ.ค."
        })
    }), 
    Object.freeze({
        en: Object.freeze({
            full: "June",
            abbr: "Jun"
        }),
        th: Object.freeze({
            full: "มิถุนายน",
            abbr: "มิ.ย."
        })
    }), 
    Object.freeze({
        en: Object.freeze({
            full: "July",
            abbr: "Jul"
        }),
        th: Object.freeze({
            full: "กรกฎาคม",
            abbr: "ก.ค."
        })
    }), 
    Object.freeze({
        en: Object.freeze({
            full: "August",
            abbr: "Aug"
        }),
        th: Object.freeze({
            full: "สิงหาคม",
            abbr: "ส.ค."
        })
    }), 
    Object.freeze({
        en: Object.freeze({
            full: "September",
            abbr: "Sep"
        }),
        th: Object.freeze({
            full: "กันยายน",
            abbr: "ก.ย."
        })
    }), 
    Object.freeze({
        en: Object.freeze({
            full: "October",
            abbr: "Oct"
        }),
        th: Object.freeze({
            full: "ตุลาคม",
            abbr: "ต.ค."
        })
    }), 
    Object.freeze({
        en: Object.freeze({
            full: "November",
            abbr: "Nov"
        }),
        th: Object.freeze({
            full: "พฤศจิกายน",
            abbr: "พ.ย."
        })
    }), 
    Object.freeze({
        en: Object.freeze({
            full: "December",
            abbr: "Dec"
        }),
        th: Object.freeze({
            full: "ธันวาคม",
            abbr: "ธ.ค."
        })
    }), 
])

import styled from 'styled-components'
import { Layout, Card, Typography, Button, Col, Tabs, Tag, List } from 'antd'

const { Sider } = Layout
const { Title } = Typography

export const ChatButton = styled(Button)`
  background-color: #4b7cf3;
  display: flex;
  align-items: center;
  color: white;
  .anticon {
    line-height: 0px;
  }
  &:hover {
    background-color: #4b7cf3;
    border-color: #4b7cf3
    color: white;
  }
  &:focus {
    background-color: #6eab57;
    border-color: #6eab57
    color: white;
  }
  margin: auto ;
`

export const HoverableListItem = styled(List.Item)`
  cursor:pointer;
  &:hover {
    background-color: #f0f0f0;
  }
    
`;


export const ObserverButton = styled(Button)`
  background-color: #4b7cf3;
  display: flex;
  align-items: center;
  color: white;
  .anticon {
    line-height: 0px;
  }
  &:hover {
    background-color: #4b7cf3;
    border-color: #4b7cf3
    color: white;
  }
  &:focus {
    background-color: #6eab57;
    border-color: #6eab57
    color: white;
  }
  margin: auto ;
`

export const VideoCallButton = styled(ChatButton)`
  background-color: #6eab57;
  border-color: #6eab57
  color: white;
  &:hover {
    background-color: #588a45;
    border-color: #588a45
    color: white;
  }
  &:focus {
    background-color: #6eab57;
    border-color: #6eab57
    color: white;
  }
  margin : auto ;
`

export const DeclineButton = styled(ChatButton)`
  background-color: #ff5a4e;
  border-color: #ff1100
  color: white;
  margin: auto;
  &:hover {
    background-color: #ff1100;
    border-color: #ff1100
    color: white;
  }
  &:focus {
    background-color: #ff1100;
    border-color: #ff1100
    color: white;
  }
`

export const Container = styled.div`
  padding: 20px;
  position: fixed;
`

export const TitleName = styled(Title)`
  font-weight: 200 !important;
`

export const CardRoom = styled.div`
  display: block;
  top: 0px;
  position: relative;
  min-width : 350px;
  max-width: 768px;
  background-color: white;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  padding: 10px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.3) 0px 4px 10px;
  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: ${(props) => (props.status === 'inWaitingRoom' ? '3px solid #edf7ee' : '3px solid #f3f3f3')};
    background-color: white;
  }
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #1b4b63;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
  .response_img {
    border-radius: 50%;
  }
  @media only screen and (min-width:768px){
    .col{
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
  }
  @media only screen and (max-width: 576px) {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    min-width : 300px;
    .room_info{
      display : none  !important;
    }
    .ant-col-6{
      max-width: 40% !important;
      flex : 100% !important;
    }
    .on_row{
      flex-wrap: nowrap;
    }
    .name_center{
      margin : auto;
      padding-left: 0px !important;
    }
  }
  @media only screen and (max-width: 768px) and (min-width: 576px) {
    border-radius: 8px;
    .ant-col-6{
      max-width: 100% !important;
      flex : 100% !important;
      padding-bottom : 20px ;
    }
    .response_img {
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      margin-right: 20px;
      overflow: hidden;
      position: relative;
      vertical-align: middle;
      height: 100px;
    }
  }
`
export const CardRoomHeader = styled.h3`
  color: #262626;
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 4px;
`
export const CardRoomText = styled.h3`
  color: ${(props) => (props.color ? props.color : '#262626')};
  font-size: 17px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 4px;
`

export const CardCorner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 50px;
  height: 100%;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: ${(props) => (props.status === 'inWaitingRoom' ? '#edf7ee' : '#f3f3f3')};
  .anticon {
    font-size: 20px;
    color: white;
    font-family: courier, sans;
    color: ${(props) => (props.status === 'inWaitingRoom' ? '#0f8e59' : '#9a9a9a')};
  }
`

export const CloseIcon = styled.div`
  position: absolute;
  z-index: 1;
  line-height: 0;
  margin: 20px
  right: 0;
`

export const CardCalling = styled(Card)`
  width: 50%;
  min-width: 500px;
  border-radius: 5px;
  .ant-card-body {
    text-align: center;
  }
`

export const CardCenter = styled.div`
  display: flex;
  justify-content: center;
`

export const Image = styled.img`
  box-shadow: '0px 0px 5px #e4e4e4';
  width : 100% ;
  @media only screen and (min-width: 768px) {
    width : 150px !important;
    height: 150px !important;
  }
`

export const SideBar = styled(Sider)`
  .ant-layout-sider-zero-width-trigger {
    color: #000000;
    blackground: #ffffff;
    top: unset;
    border-radius: 50%;
    bottom: 10px;
    right: -72px;
    height: 60px;
    width: 60px;
    line-height: 48px;
  }
`
export const RightSideBar = styled(Sider)`
  .ant-layout-sider-zero-width-trigger {
    color: #000000;
    top: unset;
    bottom: 15px;
    border-radius: 50px;
    left: -62px;
    height: 55px;
    width: 55px;
    line-height: 48px;
  }
`
export const CircleButtonNormal = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #000000;
  cursor: pointer;
`

export const CardMiddle = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const CircleButtonAlert = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #da0063;
  color: #ffffff;
  cursor: pointer;
`

export const TabSidebar = styled(Tabs)`
  height: 100%;
  display: flex;
  justifycontent: space-around;
  width: 100%;
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    width: 100%;
    justify-content: center;
  }
  .ant-tabs-ink-bar {
    width: 50% !important;
  }
`

export const Video = styled.div`
  padding: 40px;
  display: flex;
  justify-content: flex-end;
  width: ${(prop) => prop.width}px;
  height: ${(prop) => prop.width}px;
`

export const TimeCard = styled.div`
  padding: 20px;
  color: #ffffff;
  background-color: #da0063;
  text-align: center;
  width: 300px;
`

export const StatusTag = styled(Tag)`
  background-color: ${(props) => (props.status === 'inWaitingRoom' ? '#edf7ee' : '#fff4e5')};
  color: ${(props) => (props.status === 'inWaitingRoom' ? '#57b65b' : '#ffa31c')};
  border-color: ${(props) => (props.status === 'inWaitingRoom' ? '#57b65b' : '#ffa31c')};
  border-radius: 15px;
  text-align: center;
`

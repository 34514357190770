import React, { useContext, useState, useEffect, useRef } from 'react'
import { Table, Input, Button, Popconfirm, Form, Row, Col, message, TimePicker, Select, InputNumber } from 'antd'
import { CloseCircleOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons'
import moment from 'moment'
import './style.css'
const { Option } = Select

class EditableTable extends React.Component {
  constructor(props) {
    super(props)

    this.columns = [
      {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        width: '120px',
        align: 'center',
        render: (record, data) =>
          this.props.showDataPage && data.time ? (
            `${data.time} น.`
          ) : (
            <TimePicker
              disabled={this.props.showDataPage}
              allowClear={false}
              format="HH:mm"
              defaultValue={() => moment()}
              onChange={(time) => {
                data.time = moment(time ? time : moment()).format('HH:mm')
              }}
            />
          )
      },
      {
        title: 'Vital signs',
        dataIndex: 'vitalSigns',
        key: 'vitalSigns',
        align: 'center',
        children: [
          {
            title: 'T',
            dataIndex: 'vitalSigns_t',
            key: 'vitalSigns_t',
            width: '50',
            align: 'center',
            render: (record, data) => (
              <InputNumber
                disabled={this.props.showDataPage}
                style={{ width: '60px' }}
                min={0}
                defaultValue={data.vitalSigns ? data.vitalSigns.T : 0}
                onChange={(number) => {
                  data.vitalSigns_t = number
                }}
              />
            )
          },
          {
            title: 'SBP',
            dataIndex: 'vitalSigns_bp',
            key: 'vitalSigns_bp',
            align: 'center',
            width: '80',
            render: (record, data) => (
              <InputNumber
                disabled={this.props.showDataPage}
                min={0}
                style={{ width: '60px' }}
                defaultValue={data.vitalSigns ? data.vitalSigns.SBP : 0}
                onChange={(number) => {
                  data.vitalSigns_sbp = number
                }}
              />
            )
          },
          {
            title: 'DBP',
            dataIndex: 'vitalSigns_bp',
            key: 'vitalSigns_bp',
            align: 'center',
            width: '80',
            render: (record, data) => (
              <InputNumber
                disabled={this.props.showDataPage}
                min={0}
                style={{ width: '60px' }}
                defaultValue={data.vitalSigns ? data.vitalSigns.DBP : 0}
                onChange={(number) => {
                  data.vitalSigns_dbp = number
                }}
              />
            )
          },
          {
            title: 'PR',
            dataIndex: 'vitalSigns_pr',
            key: 'vitalSigns_pr',
            align: 'center',
            width: '50',
            render: (record, data) => (
              <InputNumber
                disabled={this.props.showDataPage}
                style={{ width: '60px' }}
                min={0}
                defaultValue={data.vitalSigns ? data.vitalSigns.PR : 0}
                onChange={(number) => {
                  data.vitalSigns_pr = number
                }}
              />
            )
          },
          {
            title: 'RR',
            dataIndex: 'vitalSigns_rr',
            key: 'vitalSigns_rr',
            align: 'center',
            width: '50',
            render: (record, data) => (
              <InputNumber
                disabled={this.props.showDataPage}
                style={{ width: '60px' }}
                min={0}
                defaultValue={data.vitalSigns ? data.vitalSigns.RR : 0}
                onChange={(number) => {
                  data.vitalSigns_rr = number
                }}
              />
            )
          }
        ]
      },
      {
        title: 'Neuro signs',
        dataIndex: 'neuroSigns',
        key: 'neuroSigns',
        align: 'center',
        children: [
          {
            title: 'E',
            dataIndex: 'neuroSigns_e',
            key: 'neuroSigns_e',
            width: '50',
            align: 'center',
            render: (record, data) => (
              <InputNumber
                disabled={this.props.showDataPage}
                style={{ width: '60px' }}
                min={0}
                defaultValue={data.neuroSigns ? data.neuroSigns.E : 0}
                onChange={(number) => {
                  data.neuroSigns_e = number
                }}
              />
            )
          },
          {
            title: 'V',
            dataIndex: 'neuroSigns_v',
            key: 'neuroSigns_v',
            align: 'center',
            width: '50',
            render: (record, data) => (
              <InputNumber
                disabled={this.props.showDataPage}
                style={{ width: '60px' }}
                min={0}
                defaultValue={data.neuroSigns ? data.neuroSigns.V : 0}
                onChange={(number) => {
                  data.neuroSigns_v = number
                }}
              />
            )
          },
          {
            title: 'M',
            dataIndex: 'neuroSigns_m',
            key: 'neuroSigns_m',
            align: 'center',
            width: '50',
            render: (record, data) => (
              <InputNumber
                disabled={this.props.showDataPage}
                style={{ width: '60px' }}
                min={0}
                defaultValue={data.neuroSigns ? data.neuroSigns.M : 0}
                onChange={(number) => {
                  data.neuroSigns_m = number
                }}
              />
            )
          }
        ]
      },
      {
        title: 'Pupils',
        dataIndex: 'pupils',
        key: 'pupils',
        align: 'center',
        children: [
          {
            title: 'Rt',
            dataIndex: 'pupils_rt',
            key: 'pupils_rt',
            width: '50',
            align: 'center',
            render: (record, data) => (
              <InputNumber
                disabled={this.props.showDataPage}
                style={{ width: '60px' }}
                min={0}
                defaultValue={data.pupils ? data.pupils.Rt : 0}
                onChange={(number) => {
                  data.pupils_rt = number
                }}
              />
            )
          },
          {
            title: 'RTL',
            dataIndex: 'pupils_rtl',
            key: 'pupils_rtl',
            align: 'center',
            width: '50',
            render: (record, data) => (
              <>
                <Select
                  disabled={this.props.showDataPage}
                  defaultValue={data.pupils ? data.pupils.Rtl : data.pupils_rtl}
                  style={{ width: 120 }}
                  onChange={(value) => {
                    data.pupils_rtl = value
                  }}
                >
                  <Option value="reaction">R (Reaction)</Option>
                  <Option value="sluggish">S (Sluggish)</Option>
                  <Option value="noReaction">N (No Reaction)</Option>
                  <Option value="close">C (Close)</Option>
                </Select>
              </>
            )
          },

          {
            title: 'Lt',
            dataIndex: 'pupils_lt',
            key: 'pupils_lt',
            align: 'center',
            width: '50',
            render: (record, data) => (
              <InputNumber
                disabled={this.props.showDataPage}
                style={{ width: '60px' }}
                min={0}
                defaultValue={data.pupils ? data.pupils.Lt : 0}
                onChange={(number) => {
                  data.pupils_lt = number
                }}
              />
            )
          },
          {
            title: 'RTL',
            dataIndex: 'pupils_rtl2',
            key: 'pupils_rtl2',
            align: 'center',
            width: '50',
            render: (record, data) => (
              <>
                <Select
                  disabled={this.props.showDataPage}
                  defaultValue={data.pupils ? data.pupils.Rtl2 : data.pupils_rtl2}
                  style={{ width: 120 }}
                  onChange={(value) => {
                    data.pupils_rtl2 = value
                  }}
                >
                  <Option value="reaction">R (Reaction)</Option>
                  <Option value="sluggish">S (Sluggish)</Option>
                  <Option value="noReaction">N (No Reaction)</Option>
                  <Option value="close">C (Close)</Option>
                </Select>
              </>
            )
          }
        ]
      },
      {
        title: 'O2 Sat',
        dataIndex: 'o2sat',
        key: 'o2sat',
        width: '200',
        align: 'center',
        render: (record, data) => (
          <InputNumber
            disabled={this.props.showDataPage}
            style={{ width: '60px' }}
            min={0}
            defaultValue={data.o2Sat ? data.o2Sat : 0}
            onChange={(number) => {
              data.o2sat = number
            }}
          />
        )
      },
      {
        title: 'DTX',
        dataIndex: 'dtx',
        key: 'dtx',
        width: '200',
        align: 'center',
        render: (record, data) => (
          <InputNumber
            disabled={this.props.showDataPage}
            style={{ width: '60px' }}
            min={0}
            defaultValue={data.dtx ? data.dtx : 0}
            onChange={(number) => {
              data.dtx = number
            }}
          />
        )
      }
    ]

    if (!this.props.showDataPage || this.props.showDataPage === false) {
      this.columns = [
        ...this.columns,
        {
          title: 'Manage',
          dataIndex: 'Manage',
          width: '20',
          align: 'center',
          render: (_, record) =>
            this.state.dataSource.length >= 1 ? (
              <div className="w-100 pt-1" style={{ height: '100%' }}>
                <Popconfirm title="แน่ใจที่จะลบหรือไม่?" onConfirm={() => this.handleDelete(record.key)}>
                  <CloseCircleOutlined style={{ color: '#ff0000', fontSize: '17px' }} />
                </Popconfirm>
              </div>
            ) : null
        }
      ]
    }

    this.state = {
      dataSource: [
        {
          key: 0,
          time: moment().format('HH:mm'),
          vitalSigns_t: 0,
          vitalSigns_sbp: 0,
          vitalSigns_dbp: 0,
          vitalSigns_pr: 0,
          vitalSigns_rr: 0,
          neuroSigns_e: 0,
          neuroSigns_v: 0,
          neuroSigns_m: 0,
          pupils_rt: 0,
          pupils_rtl: 'reaction',
          pupils_lt: 0,
          pupils_rtl2: 'reaction',
          o2sat: 0,
          dtx: 0
        }
      ],
      count: 1
    }
  }

  handleDelete = (key) => {
    const dataSource = [...this.state.dataSource]
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key)
    })
  }
  handleAdd = () => {
    const { count, dataSource } = this.state
    const newData = {
      key: count,
      time: moment().format('HH:mm'),
      vitalSigns_t: 0,
      vitalSigns_sbp: 0,
      vitalSigns_dbp: 0,
      vitalSigns_pr: 0,
      vitalSigns_rr: 0,
      neuroSigns_e: 0,
      neuroSigns_v: 0,
      neuroSigns_m: 0,
      pupils_rt: 0,
      pupils_rtl: 'reaction',
      pupils_lt: 0,
      pupils_rtl2: 'reaction',
      o2sat: 0,
      dtx: 0
    }
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1
    })
  }
  handleSave = (row) => {
    const newData = [...this.state.dataSource]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...row })
    this.setState({
      dataSource: newData
    })
  }

  render() {
    const { dataSource } = this.state
    const columns = this.columns
    return (
      <>
        {!this.props.showDataPage && (
          <Row gutter={[4, 8]}>
            <Col>
              <Button
                className="d-flex align-items-center"
                icon={<PlusCircleOutlined />}
                onClick={this.handleAdd}
                type="primary"
                style={{
                  marginBottom: 16
                }}
              >
                เพิ่มข้อมูล
              </Button>
            </Col>
            <Col>
              <Button
                className="d-flex align-items-center"
                icon={<SaveOutlined />}
                onClick={() => {
                  const sortedData = dataSource.map((data) => {
                    return {
                      key: data.key,
                      time: data.time,
                      vitalSigns: {
                        T: data.vitalSigns_t,
                        SBP: data.vitalSigns_sbp,
                        DBP: data.vitalSigns_dbp,
                        PR: data.vitalSigns_pr,
                        RR: data.vitalSigns_rr
                      },
                      neuroSigns: {
                        E: data.neuroSigns_e,
                        V: data.neuroSigns_v,
                        M: data.neuroSigns_m
                      },
                      pupils: {
                        Rt: data.pupils_rt,
                        Rtl: data.pupils_rtl,
                        Lt: data.pupils_lt,
                        Rtl2: data.pupils_rtl2
                      },
                      o2Sat: data.o2sat,
                      dtx: data.dtx
                    }
                  })
                  this.props.setEmergencyTable(sortedData)
                  message.success('ข้อมูลในตารางได้รับการอัพเดทเรียบร้อยแล้ว')
                }}
                type="primary"
                style={{
                  marginBottom: 16
                }}
              >
                บันทึกข้อมูลในตาราง
              </Button>
            </Col>
          </Row>
        )}

        <Table
          style={{ width: '100%' }}
          scroll={{ x: 'max-content' }}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={this.props.dataToShow ? this.props.dataToShow : dataSource}
          columns={columns}
        />
      </>
    )
  }
}

export default EditableTable

import { get } from 'lodash'

export const SidebarOptions = (history, userInfo) => {
  const goToPage = (url) => {
    history.push(`/${url}`)
  }
  const options = [
    {
      label: 'หน้าหลัก',
      icon: 'home',
      value: 'your-settings',
      onClick: () => goToPage('your-settings'),
    },
    {
      label: 'บันทึกผลตรวจ',
      icon: 'solution',
      value: 'health-checkup',
      onClick: () => goToPage('health-checkup'),
    },
    {
      label: 'Telemonitoring',
      icon: 'line-chart',
      value: 'telemonitoring',
      onClick: () => goToPage('telemonitoring'),
    },
    {
      label: 'ประวัติผู้ป่วย',
      icon: 'inbox',
      value: 'history',
      onClick: () => goToPage('history'),
    },
    {
      label: 'ร้านค้า',
      icon: 'inbox',
      value: 'store',
      onClick: () => goToPage('store'),
    },
    {
      label: 'รายงาน',
      icon: 'inbox',
      value: 'emsform',
      onClick: () => goToPage('emsform'),
    },
    {
      label: 'การนัดหมาย',
      icon: 'calendar',
      value: 'bookingpatient',
      onClick: () => goToPage('bookingpatient'),
    },
    {
      label: 'นัดหมายต่อเนื่อง',
      icon: 'sync',
      value: 'follow-up',
      onClick: () => goToPage('follow-up'),
    },
    {
      label: 'ล็อบบี้',
      icon: 'video-camera',
      value: 'telemed-lobby',
      onClick: () => goToPage('telemed-lobby'),
    },
    {
      label: 'รายการทั้งหมด',
      icon: 'inbox',
      value: 'list',
      onClick: () => goToPage('list'),
    },
    {
      label: 'รายการสั่งยา',
      icon: 'file-text',
      value: 'prescription',
      onClick: () => goToPage('prescription'),
    },
    {
      label: 'ประวัติการนัดหมาย',
      icon: 'history',
      value: 'history-booking',
      onClick: () => goToPage('history-booking'),
    },
    {
      label: 'ประวัติรายการยา',
      icon: 'history',
      value: 'history-prescription',
      onClick: () => goToPage('history-prescription'),
    },
    {
      label: 'รายชื่อผู้ใช้',
      icon: 'user',
      value: 'userlist',
      onClick: () => goToPage('userlist'),
    },
    {
      label: 'รายชื่อแพทย์',
      icon: 'user',
      value: 'doctorlist',
      onClick: () => goToPage('doctorlist'),
    },
    {
      label: 'รายการแจ้งปัญหา',
      icon: 'exclamation-circle',
      value: 'user-report',
      onClick: () => goToPage('user-report'),
    },
    {
      label: 'ลงทะเบียนเจ้าหน้าที่',
      icon: 'plus-circle',
      value: 'register',
      onClick: () => goToPage('register'),
    },
    {
      label: 'วชิร KYC',
      icon: 'inbox',
      value: 'vajira-kyc',
      onClick: () => goToPage('vajira-kyc'),
    },
    {
      label: 'ระบบบริหารข้อมูล',
      icon: 'bar-chart',
      value: 'data-management',
      onClick: () => goToPage('data-management'),
    },
    {
      label: 'รายการผู้บริจาคเลือด',
      icon: 'home',
      value: 'blood-donor-list',
      onClick: () => goToPage('blood-donor-list'),
    },
    {
      label: 'กรอกแบบฟอร์ม',
      icon: 'form',
      value: 'blood-donation-form',
      onClick: () => goToPage('blood-donation-form'),
    },
    {
      label: 'ประวัติการบริจาคเลือด',
      icon: 'history',
      value: 'blood-donation-history',
      onClick: () => goToPage('blood-donation-history'),
    },
    {
      label: 'รายงานการจองที่จอดรถ',
      icon: 'car',
      value: 'car-park-report',
      onClick: () => goToPage('car-park-report'),
    },
    {
      label: 'ตั้งค่า',
      icon: 'setting',
      value: 'blood-donation-setting',
      onClick: () => goToPage('blood-donation-setting'),
    },

    {
      label: 'ออกจากระบบ',
      icon: 'logout',
      onClick: () => {
        history.push('/logout')
      },
    },
  ]
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('doctor')
  ) {
    if (userInfo?.detail?.department === 'คลินิกตรวจสุขภาพ') {
      return options.filter((option) => ['หน้าหลัก', 'บันทึกผลตรวจ', 'การนัดหมาย', 'Telemonitoring', 'ล็อบบี้', 'ประวัติการนัดหมาย', 'ออกจากระบบ'].includes(option.label))
    }
    return options.filter((option) => ['หน้าหลัก', 'การนัดหมาย', 'Telemonitoring', 'ล็อบบี้', 'ประวัติการนัดหมาย', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('healthCheckUpDoctorOffSite')
  ) {
    return options.filter((option) => ['บันทึกผลตรวจ', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('observerStaff')
  ) {
    return options.filter((option) => ['ล็อบบี้', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('nurse')
  ) {
    return options.filter((option) => ['หน้าหลัก', 'การนัดหมาย', 'Telemonitoring', 'ล็อบบี้', 'ประวัติการนัดหมาย', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('pharmacySchedule')
  ) {
    return options.filter((option) => ['หน้าหลัก', 'การนัดหมาย', 'Telemonitoring', 'ล็อบบี้', 'ประวัติการนัดหมาย', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('nutritionist')
  ) {
    return options.filter((option) => ['หน้าหลัก', 'การนัดหมาย', 'Telemonitoring', 'ล็อบบี้', 'ประวัติการนัดหมาย', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('physiotherapist')
  ) {
    return options.filter((option) => ['หน้าหลัก', 'การนัดหมาย', 'ล็อบบี้', 'ประวัติการนัดหมาย', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('speechCorrectionSpecialist')
  ) {
    return options.filter((option) => ['หน้าหลัก', 'การนัดหมาย', 'ล็อบบี้', 'ประวัติการนัดหมาย', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('callCenter')
  ) {
    return options.filter((option) => ['หน้าหลัก', 'การนัดหมาย', 'นัดหมายต่อเนื่อง', 'รายชื่อผู้ใช้', 'รายชื่อแพทย์', 'ล็อบบี้', 'รายการแจ้งปัญหา', 'ลงทะเบียนเจ้าหน้าที่', 'ระบบบริหารข้อมูล', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('pharmacy')
  ) {
    return options.filter((option) => ['หน้าหลัก', 'การนัดหมาย', 'รายการสั่งยา', 'ประวัติการนัดหมาย', 'ประวัติรายการยา', 'ล็อบบี้', 'ระบบบริหารข้อมูล', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('pharmacyStore')
  ) {
    return options.filter((option) => ['หน้าหลัก', 'รายการสั่งยา', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('staff')
  ) {
    return options.filter((option) => ['หน้าหลัก', 'รายงาน', 'แบบฟอร์ม(DEV)', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('bloodBankTechnician')
  ) {
    return options.filter((option) => ['รายการผู้บริจาคเลือด', 'รายงานการจองที่จอดรถ', 'กรอกแบบฟอร์ม', 'ประวัติการบริจาคเลือด', 'รายชื่อผู้ใช้', 'ตั้งค่า', 'ออกจากระบบ'].includes(option.label))
  }
  if (
    get(userInfo, 'roles', [])
      .map((item) => item.name)
      .includes('user')
  ) {
    return options.filter((option) => ['ออกจากระบบ'].includes(option.label))
  }
  return options.filter((option) => ['ออกจากระบบ'].includes(option.label))
}

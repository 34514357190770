import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { Layout, Button, Select } from 'antd'
import jwtDecode from 'jwt-decode'
import Sidebar from '../../components/Sidebar'
import { Container, TopHeaderSection, UserIcon } from './styles'
import './style.css'
import { ApiOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInfo } from '../../store/userSlice'
import { SidebarOptions } from './constant'
import styled from 'styled-components'
import ModalProfile from '../DoctorSetting/ModalProfile'
const ProfileImage = styled('div')`
  background: white;
  border-radius: 50%;
  padding: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const index = (props) => {
  const theme = localStorage.getItem('theme')
  const { history } = props
  const [isOpenSidebar, setIsOpenSidebar] = useState(true)
  const [isUserOnline, setIsUserOnline] = useState(true)

  const [visibleModalProfile, setVisibleModalProfile] = useState(false)

  const [roles, setRoles] = useState(true)
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state.user.userInfo)
  useEffect(() => {
    mount()
  }, [])
  const mount = () => {
    const { roles } = jwtDecode(localStorage.getItem('token'))
    dispatch(
      fetchUserInfo((res) => {
        setRoles(roles)
        if (window.navigator.onLine && res?.status === 401) {
          setIsUserOnline(false)
          location.reload()
          history.push('/logout')
        } else if (window.navigator.onLine && res?.status === 200) {
          setIsUserOnline(true)
        } else {
          setIsUserOnline(false)
          location.reload()
          history.push('/logout')
        }
        window.addEventListener('online', () => {
          setIsUserOnline(true)
        })
        window.addEventListener('offline', () => {
          setIsUserOnline(false)
        })
      }),
    )
  }
  return (
    <Layout>
      {isOpenSidebar && userInfo && <Sidebar options={SidebarOptions(history, userInfo)} history={history} />}
      <Layout style={{ maxHeight: "100vh" }}>
        <>
          <TopHeaderSection className="top-header-bar d-flex align-items-center justify-content-end pr-4 ">
            {/* {roles[0] === 'bloodBankTechnician' && (
              <div className="d-flex align-items-center ">
                <p style={{ color: '#fff' }}>{`โหมดการจัดการ: `}</p>
                <Select
                  className="mx-2"
                  defaultValue={theme ? theme : 'defaultTheme'}
                  style={{
                    width: 250,
                  }}
                  onChange={(value) => {
                    localStorage.setItem('theme', value)
                    if (value === 'defaultTheme') {
                      localStorage.setItem('donationOperationType', 'default')
                    } else {
                      localStorage.setItem('donationOperationType', 'isOutside')
                    }
                    location.reload()
                  }}
                  options={[
                    {
                      value: 'defaultTheme',
                      label: 'หน่วยบริจาคเลือดในโรงพยาบาล',
                    },
                    {
                      value: 'onSiteBloodDonationTheme',
                      label: 'หน่วยบริจาคเลือดนอกสถานที่',
                    },
                  ]}
                />
              </div>
            )} */}
            <div
              onClick={() => {
                setVisibleModalProfile(true)
              }}
            >
              {userInfo.profileImage ? (
                <ProfileImage>
                  <img src={userInfo.profileImage} style={{ cursor: 'pointer' }} width="28" height="28" />
                </ProfileImage>
              ) : (
                <UserIcon className="d-flex align-items-center justify-content-center" />
              )}
            </div>
          </TopHeaderSection>
        </>
        <Container className='h-100'>
          {props.render({
            userInfo,
            history,
          })}
        </Container>
        <ModalProfile props={props} visibleModalProfile={visibleModalProfile} setVisibleModalProfile={(status) => setVisibleModalProfile(status)} />
      </Layout>
    </Layout>
  )
}

export default index

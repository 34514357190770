import logo from '../../images/VajiraIcon.png'
import ambulanceIcon from '../../images/AmbulanceIcon.png'
export const Polygons = {
  bangPhlat: [
    // Bang Phlat District Polygon Overlay
    {
      lng: 100.4663093,
      lat: 13.7886172,
    },
    {
      lng: 100.465966,
      lat: 13.7872418,
    },
    {
      lng: 100.4677255,
      lat: 13.7877836,
    },
    {
      lng: 100.4692705,
      lat: 13.7872835,
    },
    {
      lng: 100.4818135,
      lat: 13.7739739,
    },
    {
      lng: 100.4904824,
      lat: 13.7631365,
    },
    {
      lng: 100.4931431,
      lat: 13.7651373,
    },
    {
      lng: 100.4945164,
      lat: 13.7667212,
    },
    {
      lng: 100.4957615,
      lat: 13.7692962,
    },
    {
      lng: 100.4968344,
      lat: 13.771422,
    },
    {
      lng: 100.5008684,
      lat: 13.7814254,
    },
    {
      lng: 100.5032902,
      lat: 13.7855339,
    },
    {
      lng: 100.5053072,
      lat: 13.7878262,
    },
    {
      lng: 100.508483,
      lat: 13.7909521,
    },
    {
      lng: 100.5102854,
      lat: 13.7928693,
    },
    {
      lng: 100.5123883,
      lat: 13.7954533,
    },
    {
      lng: 100.5138722,
      lat: 13.7977295,
    },
    {
      lng: 100.5151597,
      lat: 13.79998,
    },
    {
      lng: 100.5158463,
      lat: 13.8022305,
    },
    {
      lng: 100.5162755,
      lat: 13.8050645,
    },
    {
      lng: 100.5164471,
      lat: 13.8068149,
    },
    {
      lng: 100.5163086,
      lat: 13.808188,
    },
    {
      lng: 100.515858,
      lat: 13.8102926,
    },
    {
      lng: 100.5146778,
      lat: 13.8120846,
    },
    {
      lng: 100.5139053,
      lat: 13.8124596,
    },
    {
      lng: 100.5027688,
      lat: 13.8019992,
    },
    {
      lng: 100.5015243,
      lat: 13.8011448,
    },
    {
      lng: 100.5005801,
      lat: 13.800603,
    },
    {
      lng: 100.4994214,
      lat: 13.8001237,
    },
    {
      lng: 100.4739726,
      lat: 13.7909547,
    },
    {
      lng: 100.4663766,
      lat: 13.7890375,
    },
    {
      lng: 100.4663093,
      lat: 13.7886172,
    },
  ],
  bangSue: [
    // Bang Sue District Polygon Overlay
    {
      lng: 100.5068237,
      lat: 13.8175575,
    },
    {
      lng: 100.5079614,
      lat: 13.8171128,
    },
    {
      lng: 100.5095922,
      lat: 13.8164877,
    },
    {
      lng: 100.5098068,
      lat: 13.8164877,
    },
    {
      lng: 100.5100643,
      lat: 13.8166752,
    },
    {
      lng: 100.510472,
      lat: 13.8164669,
    },
    {
      lng: 100.5110299,
      lat: 13.8164877,
    },
    {
      lng: 100.5119955,
      lat: 13.8160085,
    },
    {
      lng: 100.5126606,
      lat: 13.815946,
    },
    {
      lng: 100.5131113,
      lat: 13.8155709,
    },
    {
      lng: 100.5138623,
      lat: 13.8155501,
    },
    {
      lng: 100.5153858,
      lat: 13.8143415,
    },
    {
      lng: 100.5159008,
      lat: 13.8141957,
    },
    {
      lng: 100.5168449,
      lat: 13.8137164,
    },
    {
      lng: 100.5168878,
      lat: 13.8130288,
    },
    {
      lng: 100.5174028,
      lat: 13.8126954,
    },
    {
      lng: 100.5177032,
      lat: 13.8119244,
    },
    {
      lng: 100.5181753,
      lat: 13.8106533,
    },
    {
      lng: 100.5186688,
      lat: 13.8090489,
    },
    {
      lng: 100.5186473,
      lat: 13.8085904,
    },
    {
      lng: 100.5187975,
      lat: 13.8077361,
    },
    {
      lng: 100.518819,
      lat: 13.80659,
    },
    {
      lng: 100.5190121,
      lat: 13.8061733,
    },
    {
      lng: 100.519055,
      lat: 13.8045896,
    },
    {
      lng: 100.5187975,
      lat: 13.8046729,
    },
    {
      lng: 100.5186671,
      lat: 13.8027565,
    },
    {
      lng: 100.5178732,
      lat: 13.8002768,
    },
    {
      lng: 100.518474,
      lat: 13.8002976,
    },
    {
      lng: 100.5192679,
      lat: 13.8001934,
    },
    {
      lng: 100.5201048,
      lat: 13.8000267,
    },
    {
      lng: 100.5214781,
      lat: 13.7999851,
    },
    {
      lng: 100.5221218,
      lat: 13.8000476,
    },
    {
      lng: 100.5239028,
      lat: 13.7996516,
    },
    {
      lng: 100.5249113,
      lat: 13.7992557,
    },
    {
      lng: 100.5253405,
      lat: 13.7993182,
    },
    {
      lng: 100.5258984,
      lat: 13.799214,
    },
    {
      lng: 100.526585,
      lat: 13.7987973,
    },
    {
      lng: 100.5270356,
      lat: 13.7986097,
    },
    {
      lng: 100.5277652,
      lat: 13.7988598,
    },
    {
      lng: 100.5286235,
      lat: 13.7989223,
    },
    {
      lng: 100.5302972,
      lat: 13.7985055,
    },
    {
      lng: 100.5375713,
      lat: 13.7978179,
    },
    {
      lng: 100.5440204,
      lat: 13.8150676,
    },
    {
      lng: 100.5445783,
      lat: 13.8164637,
    },
    {
      lng: 100.544986,
      lat: 13.8177764,
    },
    {
      lng: 100.5449216,
      lat: 13.8188599,
    },
    {
      lng: 100.5440633,
      lat: 13.8201101,
    },
    {
      lng: 100.5429046,
      lat: 13.8208394,
    },
    {
      lng: 100.5418746,
      lat: 13.8211103,
    },
    {
      lng: 100.5370914,
      lat: 13.8216198,
    },
    {
      lng: 100.543686,
      lat: 13.8495712,
    },
    {
      lng: 100.5419908,
      lat: 13.8489254,
    },
    {
      lng: 100.5396305,
      lat: 13.8478212,
    },
    {
      lng: 100.538107,
      lat: 13.8472378,
    },
    {
      lng: 100.537592,
      lat: 13.846717,
    },
    {
      lng: 100.5362402,
      lat: 13.846217,
    },
    {
      lng: 100.5350815,
      lat: 13.8450711,
    },
    {
      lng: 100.5340086,
      lat: 13.8443835,
    },
    {
      lng: 100.533279,
      lat: 13.8434877,
    },
    {
      lng: 100.5325066,
      lat: 13.8428626,
    },
    {
      lng: 100.5319916,
      lat: 13.8423209,
    },
    {
      lng: 100.5310149,
      lat: 13.841633,
    },
    {
      lng: 100.5300815,
      lat: 13.8407892,
    },
    {
      lng: 100.5298133,
      lat: 13.8404558,
    },
    {
      lng: 100.5258114,
      lat: 13.836966,
    },
    {
      lng: 100.5255003,
      lat: 13.8368097,
    },
    {
      lng: 100.5246527,
      lat: 13.8359451,
    },
    {
      lng: 100.5235799,
      lat: 13.8351429,
    },
    {
      lng: 100.5233224,
      lat: 13.8347783,
    },
    {
      lng: 100.5228594,
      lat: 13.8344966,
    },
    {
      lng: 100.5226877,
      lat: 13.8340799,
    },
    {
      lng: 100.522044,
      lat: 13.8336216,
    },
    {
      lng: 100.5210355,
      lat: 13.8328298,
    },
    {
      lng: 100.5202416,
      lat: 13.8319339,
    },
    {
      lng: 100.5193189,
      lat: 13.8313297,
    },
    {
      lng: 100.5188683,
      lat: 13.8307463,
    },
    {
      lng: 100.5174735,
      lat: 13.8296837,
    },
    {
      lng: 100.516701,
      lat: 13.8290169,
    },
    {
      lng: 100.5162933,
      lat: 13.8288086,
    },
    {
      lng: 100.5158642,
      lat: 13.8284127,
    },
    {
      lng: 100.5158213,
      lat: 13.8275584,
    },
    {
      lng: 100.5153492,
      lat: 13.8270375,
    },
    {
      lng: 100.5145767,
      lat: 13.8267875,
    },
    {
      lng: 100.5143407,
      lat: 13.8265791,
    },
    {
      lng: 100.5134756,
      lat: 13.826215,
    },
    {
      lng: 100.5131966,
      lat: 13.8261213,
    },
    {
      lng: 100.513143,
      lat: 13.8259025,
    },
    {
      lng: 100.5131752,
      lat: 13.8248398,
    },
    {
      lng: 100.5130679,
      lat: 13.8246836,
    },
    {
      lng: 100.5128533,
      lat: 13.8246836,
    },
    {
      lng: 100.5126709,
      lat: 13.8248086,
    },
    {
      lng: 100.512113,
      lat: 13.8258816,
    },
    {
      lng: 100.5119414,
      lat: 13.8260171,
    },
    {
      lng: 100.511126,
      lat: 13.8261942,
    },
    {
      lng: 100.5109972,
      lat: 13.8261317,
    },
    {
      lng: 100.5109329,
      lat: 13.8259442,
    },
    {
      lng: 100.5108685,
      lat: 13.8254337,
    },
    {
      lng: 100.5107934,
      lat: 13.825267,
    },
    {
      lng: 100.5105895,
      lat: 13.8251628,
    },
    {
      lng: 100.5102784,
      lat: 13.8252253,
    },
    {
      lng: 100.5096776,
      lat: 13.8255274,
    },
    {
      lng: 100.5094201,
      lat: 13.8256004,
    },
    {
      lng: 100.5092592,
      lat: 13.8254128,
    },
    {
      lng: 100.5091733,
      lat: 13.825069,
    },
    {
      lng: 100.5093664,
      lat: 13.8247252,
    },
    {
      lng: 100.5096561,
      lat: 13.8245273,
    },
    {
      lng: 100.5099673,
      lat: 13.8245065,
    },
    {
      lng: 100.5101067,
      lat: 13.8243815,
    },
    {
      lng: 100.5101496,
      lat: 13.8242043,
    },
    {
      lng: 100.509978,
      lat: 13.8239126,
    },
    {
      lng: 100.5097634,
      lat: 13.8236834,
    },
    {
      lng: 100.5092699,
      lat: 13.8235376,
    },
    {
      lng: 100.5086905,
      lat: 13.8234334,
    },
    {
      lng: 100.5083043,
      lat: 13.8231,
    },
    {
      lng: 100.5077249,
      lat: 13.8228708,
    },
    {
      lng: 100.5076176,
      lat: 13.8226416,
    },
    {
      lng: 100.5075533,
      lat: 13.8215581,
    },
    {
      lng: 100.5071027,
      lat: 13.8211414,
    },
    {
      lng: 100.5068237,
      lat: 13.8202871,
    },
    {
      lng: 100.506416,
      lat: 13.8195162,
    },
    {
      lng: 100.5063087,
      lat: 13.8190994,
    },
    {
      lng: 100.5068237,
      lat: 13.8175575,
    },
  ],
  dusit: [
    // Dusit District Polygon Overlay
    {
      lng: 100.5372631,
      lat: 13.7973849,
    },
    {
      lng: 100.5360829,
      lat: 13.797885,
    },
    {
      lng: 100.5344521,
      lat: 13.797885,
    },
    {
      lng: 100.5326282,
      lat: 13.7981976,
    },
    {
      lng: 100.5303108,
      lat: 13.7983852,
    },
    {
      lng: 100.5291199,
      lat: 13.7986769,
    },
    {
      lng: 100.5282509,
      lat: 13.7987394,
    },
    {
      lng: 100.5278217,
      lat: 13.7986769,
    },
    {
      lng: 100.5273818,
      lat: 13.7985206,
    },
    {
      lng: 100.5269634,
      lat: 13.7985102,
    },
    {
      lng: 100.5264484,
      lat: 13.7986665,
    },
    {
      lng: 100.5259334,
      lat: 13.7990103,
    },
    {
      lng: 100.5257189,
      lat: 13.7991354,
    },
    {
      lng: 100.5251932,
      lat: 13.7991458,
    },
    {
      lng: 100.5249679,
      lat: 13.7990833,
    },
    {
      lng: 100.5236589,
      lat: 13.7995313,
    },
    {
      lng: 100.5227148,
      lat: 13.7996563,
    },
    {
      lng: 100.5226612,
      lat: 13.7997501,
    },
    {
      lng: 100.5219638,
      lat: 13.7998855,
    },
    {
      lng: 100.5212557,
      lat: 13.7998126,
    },
    {
      lng: 100.5201184,
      lat: 13.7999168,
    },
    {
      lng: 100.5193567,
      lat: 13.8000522,
    },
    {
      lng: 100.5188095,
      lat: 13.8000835,
    },
    {
      lng: 100.5183696,
      lat: 13.8000314,
    },
    {
      lng: 100.5181443,
      lat: 13.8000731,
    },
    {
      lng: 100.5176937,
      lat: 13.7999793,
    },
    {
      lng: 100.5175328,
      lat: 13.7997397,
    },
    {
      lng: 100.5171894,
      lat: 13.7987811,
    },
    {
      lng: 100.5169641,
      lat: 13.7980413,
    },
    {
      lng: 100.5162775,
      lat: 13.7965618,
    },
    {
      lng: 100.5150866,
      lat: 13.7946134,
    },
    {
      lng: 100.5137133,
      lat: 13.7931442,
    },
    {
      lng: 100.5132627,
      lat: 13.792592,
    },
    {
      lng: 100.5129837,
      lat: 13.7923107,
    },
    {
      lng: 100.5127477,
      lat: 13.7921752,
    },
    {
      lng: 100.5121683,
      lat: 13.7915188,
    },
    {
      lng: 100.511707,
      lat: 13.7911437,
    },
    {
      lng: 100.5114602,
      lat: 13.7908311,
    },
    {
      lng: 100.5111813,
      lat: 13.7903101,
    },
    {
      lng: 100.5106878,
      lat: 13.7897579,
    },
    {
      lng: 100.510044,
      lat: 13.7891327,
    },
    {
      lng: 100.5092608,
      lat: 13.7882158,
    },
    {
      lng: 100.508542,
      lat: 13.7877573,
    },
    {
      lng: 100.5082201,
      lat: 13.7873822,
    },
    {
      lng: 100.5071473,
      lat: 13.7861214,
    },
    {
      lng: 100.5062031,
      lat: 13.7851211,
    },
    {
      lng: 100.5051517,
      lat: 13.783256,
    },
    {
      lng: 100.5046796,
      lat: 13.7825474,
    },
    {
      lng: 100.504465,
      lat: 13.7821202,
    },
    {
      lng: 100.5046045,
      lat: 13.7820264,
    },
    {
      lng: 100.504465,
      lat: 13.7817034,
    },
    {
      lng: 100.5040252,
      lat: 13.7809219,
    },
    {
      lng: 100.5035102,
      lat: 13.7804634,
    },
    {
      lng: 100.5034136,
      lat: 13.7802446,
    },
    {
      lng: 100.502963,
      lat: 13.7793485,
    },
    {
      lng: 100.5025982,
      lat: 13.7785149,
    },
    {
      lng: 100.5026197,
      lat: 13.7781919,
    },
    {
      lng: 100.5025124,
      lat: 13.7779209,
    },
    {
      lng: 100.5023837,
      lat: 13.7775146,
    },
    {
      lng: 100.5026197,
      lat: 13.7773999,
    },
    {
      lng: 100.5025124,
      lat: 13.7770352,
    },
    {
      lng: 100.5019867,
      lat: 13.7753888,
    },
    {
      lng: 100.501064,
      lat: 13.773732,
    },
    {
      lng: 100.5008709,
      lat: 13.7733569,
    },
    {
      lng: 100.5007743,
      lat: 13.7730651,
    },
    {
      lng: 100.5005919,
      lat: 13.772815,
    },
    {
      lng: 100.5004739,
      lat: 13.772294,
    },
    {
      lng: 100.5014932,
      lat: 13.7717521,
    },
    {
      lng: 100.5030703,
      lat: 13.7704704,
    },
    {
      lng: 100.5039071,
      lat: 13.7698244,
    },
    {
      lng: 100.5067717,
      lat: 13.7666565,
    },
    {
      lng: 100.5086278,
      lat: 13.7645828,
    },
    {
      lng: 100.5101942,
      lat: 13.7629051,
    },
    {
      lng: 100.5128979,
      lat: 13.7602894,
    },
    {
      lng: 100.5157411,
      lat: 13.7575695,
    },
    {
      lng: 100.5163848,
      lat: 13.7568297,
    },
    {
      lng: 100.5169749,
      lat: 13.7555479,
    },
    {
      lng: 100.5173504,
      lat: 13.7546829,
    },
    {
      lng: 100.5174362,
      lat: 13.7531614,
    },
    {
      lng: 100.5191528,
      lat: 13.7526612,
    },
    {
      lng: 100.520848,
      lat: 13.7568713,
    },
    {
      lng: 100.5222105,
      lat: 13.7603624,
    },
    {
      lng: 100.523171,
      lat: 13.7627847,
    },
    {
      lng: 100.5263467,
      lat: 13.7704959,
    },
    {
      lng: 100.5304237,
      lat: 13.7805619,
    },
    {
      lng: 100.5333419,
      lat: 13.7876682,
    },
    {
      lng: 100.5372631,
      lat: 13.7973849,
    },
  ],
  phraNakhon: [
    // Phra Nakhon District Polygon Overlay
    {
      lng: 100.5095218,
      lat: 13.7633587,
    },
    {
      lng: 100.5084543,
      lat: 13.7645311,
    },
    {
      lng: 100.5066412,
      lat: 13.7664902,
    },
    {
      lng: 100.503916,
      lat: 13.7696372,
    },
    {
      lng: 100.5031436,
      lat: 13.7702624,
    },
    {
      lng: 100.5018883,
      lat: 13.7712315,
    },
    {
      lng: 100.5008583,
      lat: 13.7718776,
    },
    {
      lng: 100.5004613,
      lat: 13.7720026,
    },
    {
      lng: 100.5002039,
      lat: 13.7721485,
    },
    {
      lng: 100.4999249,
      lat: 13.7714399,
    },
    {
      lng: 100.499013,
      lat: 13.7696267,
    },
    {
      lng: 100.4983263,
      lat: 13.7684805,
    },
    {
      lng: 100.4975216,
      lat: 13.7674905,
    },
    {
      lng: 100.4967492,
      lat: 13.7665318,
    },
    {
      lng: 100.4958479,
      lat: 13.7655419,
    },
    {
      lng: 100.4956226,
      lat: 13.7649375,
    },
    {
      lng: 100.4947214,
      lat: 13.7640726,
    },
    {
      lng: 100.4940133,
      lat: 13.7635724,
    },
    {
      lng: 100.4929941,
      lat: 13.7626136,
    },
    {
      lng: 100.4925005,
      lat: 13.7619884,
    },
    {
      lng: 100.4914491,
      lat: 13.7610193,
    },
    {
      lng: 100.4904299,
      lat: 13.7599042,
    },
    {
      lng: 100.4900329,
      lat: 13.7593206,
    },
    {
      lng: 100.4898291,
      lat: 13.7591435,
    },
    {
      lng: 100.4888527,
      lat: 13.7565903,
    },
    {
      lng: 100.4885845,
      lat: 13.7557671,
    },
    {
      lng: 100.4883699,
      lat: 13.7534432,
    },
    {
      lng: 100.4883699,
      lat: 13.7529013,
    },
    {
      lng: 100.4882197,
      lat: 13.7523593,
    },
    {
      lng: 100.4884236,
      lat: 13.7514944,
    },
    {
      lng: 100.4886167,
      lat: 13.7513589,
    },
    {
      lng: 100.4888635,
      lat: 13.7495247,
    },
    {
      lng: 100.4893034,
      lat: 13.7478677,
    },
    {
      lng: 100.4898613,
      lat: 13.7464192,
    },
    {
      lng: 100.4910092,
      lat: 13.7446892,
    },
    {
      lng: 100.4923933,
      lat: 13.7431363,
    },
    {
      lng: 100.4932623,
      lat: 13.7423547,
    },
    {
      lng: 100.4941635,
      lat: 13.741719,
    },
    {
      lng: 100.4942815,
      lat: 13.7414272,
    },
    {
      lng: 100.4952793,
      lat: 13.7408748,
    },
    {
      lng: 100.4972427,
      lat: 13.7401662,
    },
    {
      lng: 100.4999464,
      lat: 13.7394783,
    },
    {
      lng: 100.5001287,
      lat: 13.7395617,
    },
    {
      lng: 100.5006437,
      lat: 13.7410624,
    },
    {
      lng: 100.5034332,
      lat: 13.7452519,
    },
    {
      lng: 100.5043988,
      lat: 13.7490558,
    },
    {
      lng: 100.5058901,
      lat: 13.7555065,
    },
    {
      lng: 100.5058794,
      lat: 13.7560484,
    },
    {
      lng: 100.5065339,
      lat: 13.7572781,
    },
    {
      lng: 100.509039,
      lat: 13.7624782,
    },
    {
      lng: 100.5092375,
      lat: 13.7629836,
    },
    {
      lng: 100.5095218,
      lat: 13.7633587,
    },
  ],
  zone1: [
    { lng: 100.512786, lat: 13.76024 },
    { lng: 100.514065, lat: 13.761019 },
    { lng: 100.51543, lat: 13.761715 },
    { lng: 100.516743, lat: 13.760598 },
    { lng: 100.518045, lat: 13.759476 },
    { lng: 100.519749, lat: 13.758031 },
    { lng: 100.520624, lat: 13.757275 },
    { lng: 100.520839, lat: 13.75717 },
    { lng: 100.52086, lat: 13.757246 },
    { lng: 100.521117, lat: 13.757755 },
    { lng: 100.521189, lat: 13.75794 },
    { lng: 100.521344, lat: 13.758314 },
    { lng: 100.521469, lat: 13.75862 },
    { lng: 100.521561, lat: 13.758855 },
    { lng: 100.521606, lat: 13.758953 },
    { lng: 100.521756, lat: 13.75933 },
    { lng: 100.5219, lat: 13.759683 },
    { lng: 100.522138, lat: 13.76027 },
    { lng: 100.522226, lat: 13.7605 },
    { lng: 100.522392, lat: 13.7609 },
    { lng: 100.522549, lat: 13.761281 },
    { lng: 100.522605, lat: 13.761429 },
    { lng: 100.522802, lat: 13.761932 },
    { lng: 100.522941, lat: 13.762285 },
    { lng: 100.523281, lat: 13.763065 },
    { lng: 100.52366, lat: 13.76397 },
    { lng: 100.524397, lat: 13.765786 },
    { lng: 100.524844, lat: 13.766908 },
    { lng: 100.525011, lat: 13.767312 },
    { lng: 100.525132, lat: 13.767604 },
    { lng: 100.525239, lat: 13.76787 },
    { lng: 100.525404, lat: 13.76829 },
    { lng: 100.525542, lat: 13.768624 },
    { lng: 100.525685, lat: 13.769006 },
    { lng: 100.525816, lat: 13.769302 },
    { lng: 100.52594, lat: 13.769632 },
    { lng: 100.526101, lat: 13.770037 },
    { lng: 100.526245, lat: 13.770388 },
    { lng: 100.52641, lat: 13.770778 },
    { lng: 100.526548, lat: 13.77115 },
    { lng: 100.526752, lat: 13.77164 },
    { lng: 100.526879, lat: 13.771961 },
    { lng: 100.526935, lat: 13.772093 },
    { lng: 100.527103, lat: 13.772488 },
    { lng: 100.527153, lat: 13.772611 },
    { lng: 100.527313, lat: 13.773008 },
    { lng: 100.52752, lat: 13.773516 },
    { lng: 100.527558, lat: 13.773613 },
    { lng: 100.52783, lat: 13.774253 },
    { lng: 100.528038, lat: 13.774774 },
    { lng: 100.52842, lat: 13.775705 },
    { lng: 100.528747, lat: 13.776532 },
    { lng: 100.529143, lat: 13.777568 },
    { lng: 100.529476, lat: 13.778405 },
    { lng: 100.529936, lat: 13.77947 },
    { lng: 100.530208, lat: 13.780149 },
    { lng: 100.530626, lat: 13.781203 },
    { lng: 100.530677, lat: 13.781331 },
    { lng: 100.530749, lat: 13.781502 },
    { lng: 100.53096, lat: 13.782003 },
    { lng: 100.531108, lat: 13.782353 },
    { lng: 100.531177, lat: 13.78253 },
    { lng: 100.531262, lat: 13.782734 },
    { lng: 100.531301, lat: 13.782825 },
    { lng: 100.531346, lat: 13.782934 },
    { lng: 100.531394, lat: 13.783055 },
    { lng: 100.531434, lat: 13.783154 },
    { lng: 100.53148, lat: 13.783274 },
    { lng: 100.531581, lat: 13.783527 },
    { lng: 100.531875, lat: 13.78426 },
    { lng: 100.532429, lat: 13.785641 },
    { lng: 100.532499, lat: 13.785811 },
    { lng: 100.532595, lat: 13.786054 },
    { lng: 100.532766, lat: 13.786484 },
    { lng: 100.533066, lat: 13.78722 },
    { lng: 100.533674, lat: 13.78869 },
    { lng: 100.53532, lat: 13.79285 },
    { lng: 100.535793, lat: 13.794013 },
    { lng: 100.536017, lat: 13.794572 },
    { lng: 100.536128, lat: 13.794847 },
    { lng: 100.536247, lat: 13.795138 },
    { lng: 100.53636, lat: 13.795424 },
    { lng: 100.536428, lat: 13.795594 },
    { lng: 100.536501, lat: 13.795774 },
    { lng: 100.53655, lat: 13.7959 },
    { lng: 100.536629, lat: 13.796087 },
    { lng: 100.536735, lat: 13.796344 },
    { lng: 100.536813, lat: 13.796522 },
    { lng: 100.536919, lat: 13.796761 },
    { lng: 100.537031, lat: 13.796989 },
    { lng: 100.537336, lat: 13.797618 },
    { lng: 100.535837, lat: 13.798326 },
    { lng: 100.533875, lat: 13.799261 },
    { lng: 100.532856, lat: 13.799713 },
    { lng: 100.5323, lat: 13.80012 },
    { lng: 100.532242, lat: 13.800278 },
    { lng: 100.532217, lat: 13.800347 },
    { lng: 100.532245, lat: 13.800467 },
    { lng: 100.532276, lat: 13.800603 },
    { lng: 100.532338, lat: 13.800874 },
    { lng: 100.532463, lat: 13.801412 },
    { lng: 100.532711, lat: 13.802494 },
    { lng: 100.53274, lat: 13.802616 },
    { lng: 100.532776, lat: 13.802774 },
    { lng: 100.53283, lat: 13.803011 },
    { lng: 100.532939, lat: 13.803483 },
    { lng: 100.533133, lat: 13.80433 },
    { lng: 100.533167, lat: 13.804477 },
    { lng: 100.533205, lat: 13.804638 },
    { lng: 100.533277, lat: 13.804954 },
    { lng: 100.533422, lat: 13.805585 },
    { lng: 100.533436, lat: 13.805644 },
    { lng: 100.533464, lat: 13.805765 },
    { lng: 100.533493, lat: 13.805892 },
    { lng: 100.533582, lat: 13.806276 },
    { lng: 100.533727, lat: 13.806913 },
    { lng: 100.534104, lat: 13.808542 },
    { lng: 100.534273, lat: 13.809282 },
    { lng: 100.53445, lat: 13.81004 },
    { lng: 100.535788, lat: 13.815881 },
    { lng: 100.537782, lat: 13.824122 },
    { lng: 100.538295, lat: 13.826378 },
    { lng: 100.538511, lat: 13.827283 },
    { lng: 100.53932, lat: 13.830675 },
    { lng: 100.539458, lat: 13.831255 },
    { lng: 100.539505, lat: 13.83145 },
    { lng: 100.539572, lat: 13.831734 },
    { lng: 100.539652, lat: 13.832068 },
    { lng: 100.539706, lat: 13.832294 },
    { lng: 100.539812, lat: 13.832746 },
    { lng: 100.53994, lat: 13.833281 },
    { lng: 100.540006, lat: 13.833559 },
    { lng: 100.540099, lat: 13.833947 },
    { lng: 100.540237, lat: 13.834529 },
    { lng: 100.540511, lat: 13.835679 },
    { lng: 100.540561, lat: 13.835896 },
    { lng: 100.540611, lat: 13.836111 },
    { lng: 100.540764, lat: 13.836775 },
    { lng: 100.54102, lat: 13.837877 },
    { lng: 100.541519, lat: 13.840047 },
    { lng: 100.541598, lat: 13.840383 },
    { lng: 100.541681, lat: 13.840749 },
    { lng: 100.541821, lat: 13.841355 },
    { lng: 100.542056, lat: 13.842376 },
    { lng: 100.542669, lat: 13.845026 },
    { lng: 100.542738, lat: 13.845324 },
    { lng: 100.542804, lat: 13.84561 },
    { lng: 100.542934, lat: 13.846175 },
    { lng: 100.542999, lat: 13.846455 },
    { lng: 100.54307, lat: 13.846762 },
    { lng: 100.5432, lat: 13.847321 },
    { lng: 100.543473, lat: 13.848497 },
    { lng: 100.543736, lat: 13.849646 },
    { lng: 100.543468, lat: 13.849466 },
    { lng: 100.542841, lat: 13.849264 },
    { lng: 100.542491, lat: 13.849155 },
    { lng: 100.542251, lat: 13.848986 },
    { lng: 100.542103, lat: 13.848926 },
    { lng: 100.541855, lat: 13.848898 },
    { lng: 100.54164, lat: 13.848798 },
    { lng: 100.541162, lat: 13.848542 },
    { lng: 100.540842, lat: 13.848392 },
    { lng: 100.540231, lat: 13.848121 },
    { lng: 100.539782, lat: 13.847956 },
    { lng: 100.53952, lat: 13.847784 },
    { lng: 100.539344, lat: 13.847711 },
    { lng: 100.538951, lat: 13.847576 },
    { lng: 100.53865, lat: 13.847434 },
    { lng: 100.538493, lat: 13.847392 },
    { lng: 100.538254, lat: 13.847294 },
    { lng: 100.53802, lat: 13.847159 },
    { lng: 100.537766, lat: 13.84687 },
    { lng: 100.53759, lat: 13.846691 },
    { lng: 100.537396, lat: 13.84662 },
    { lng: 100.537137, lat: 13.84656 },
    { lng: 100.536918, lat: 13.846494 },
    { lng: 100.536804, lat: 13.846403 },
    { lng: 100.536546, lat: 13.846263 },
    { lng: 100.53631, lat: 13.846244 },
    { lng: 100.536221, lat: 13.846214 },
    { lng: 100.536095, lat: 13.846119 },
    { lng: 100.535684, lat: 13.845746 },
    { lng: 100.535297, lat: 13.845375 },
    { lng: 100.535076, lat: 13.845108 },
    { lng: 100.535005, lat: 13.845043 },
    { lng: 100.534828, lat: 13.844931 },
    { lng: 100.534514, lat: 13.844683 },
    { lng: 100.534443, lat: 13.844625 },
    { lng: 100.534195, lat: 13.844484 },
    { lng: 100.533979, lat: 13.844312 },
    { lng: 100.53371, lat: 13.844045 },
    { lng: 100.533549, lat: 13.84386 },
    { lng: 100.533316, lat: 13.843571 },
    { lng: 100.533217, lat: 13.843448 },
    { lng: 100.53295, lat: 13.843298 },
    { lng: 100.532764, lat: 13.843181 },
    { lng: 100.53258, lat: 13.843 },
    { lng: 100.532505, lat: 13.84288 },
    { lng: 100.532263, lat: 13.842696 },
    { lng: 100.53208, lat: 13.842507 },
    { lng: 100.53201, lat: 13.842376 },
    { lng: 100.531889, lat: 13.842254 },
    { lng: 100.53164, lat: 13.842069 },
    { lng: 100.531357, lat: 13.841907 },
    { lng: 100.531194, lat: 13.841803 },
    { lng: 100.530957, lat: 13.841625 },
    { lng: 100.530635, lat: 13.841246 },
    { lng: 100.530406, lat: 13.841042 },
    { lng: 100.530001, lat: 13.840716 },
    { lng: 100.529754, lat: 13.840439 },
    { lng: 100.529405, lat: 13.840117 },
    { lng: 100.52888, lat: 13.839644 },
    { lng: 100.528528, lat: 13.839359 },
    { lng: 100.527904, lat: 13.83883 },
    { lng: 100.527747, lat: 13.838657 },
    { lng: 100.52728, lat: 13.838234 },
    { lng: 100.526776, lat: 13.837787 },
    { lng: 100.526126, lat: 13.837285 },
    { lng: 100.525915, lat: 13.837047 },
    { lng: 100.52573, lat: 13.836924 },
    { lng: 100.525451, lat: 13.836758 },
    { lng: 100.525353, lat: 13.836672 },
    { lng: 100.525177, lat: 13.836527 },
    { lng: 100.525071, lat: 13.836418 },
    { lng: 100.524885, lat: 13.836234 },
    { lng: 100.524655, lat: 13.835953 },
    { lng: 100.524407, lat: 13.835768 },
    { lng: 100.523934, lat: 13.83546 },
    { lng: 100.523596, lat: 13.835183 },
    { lng: 100.523411, lat: 13.834894 },
    { lng: 100.52332, lat: 13.834804 },
    { lng: 100.523232, lat: 13.834734 },
    { lng: 100.523172, lat: 13.834704 },
    { lng: 100.523084, lat: 13.834661 },
    { lng: 100.522866, lat: 13.83453 },
    { lng: 100.522752, lat: 13.834381 },
    { lng: 100.52262, lat: 13.834155 },
    { lng: 100.522441, lat: 13.833942 },
    { lng: 100.522283, lat: 13.833847 },
    { lng: 100.521962, lat: 13.833588 },
    { lng: 100.521567, lat: 13.833205 },
    { lng: 100.521053, lat: 13.832837 },
    { lng: 100.520656, lat: 13.832419 },
    { lng: 100.520246, lat: 13.832004 },
    { lng: 100.519889, lat: 13.831722 },
    { lng: 100.519394, lat: 13.831363 },
    { lng: 100.519326, lat: 13.831301 },
    { lng: 100.519187, lat: 13.831124 },
    { lng: 100.518824, lat: 13.83071 },
    { lng: 100.5187, lat: 13.830609 },
    { lng: 100.518587, lat: 13.830528 },
    { lng: 100.518461, lat: 13.830484 },
    { lng: 100.518176, lat: 13.830282 },
    { lng: 100.517875, lat: 13.830071 },
    { lng: 100.517574, lat: 13.82985 },
    { lng: 100.517537, lat: 13.829807 },
    { lng: 100.517283, lat: 13.829575 },
    { lng: 100.517026, lat: 13.829312 },
    { lng: 100.516438, lat: 13.828857 },
    { lng: 100.516247, lat: 13.828763 },
    { lng: 100.516095, lat: 13.828707 },
    { lng: 100.515976, lat: 13.828616 },
    { lng: 100.515875, lat: 13.828543 },
    { lng: 100.51583, lat: 13.828461 },
    { lng: 100.515805, lat: 13.828357 },
    { lng: 100.515819, lat: 13.827948 },
    { lng: 100.51583, lat: 13.827681 },
    { lng: 100.515784, lat: 13.82755 },
    { lng: 100.515606, lat: 13.827285 },
    { lng: 100.515488, lat: 13.827149 },
    { lng: 100.515383, lat: 13.827054 },
    { lng: 100.515266, lat: 13.826996 },
    { lng: 100.515217, lat: 13.826992 },
    { lng: 100.51461, lat: 13.826774 },
    { lng: 100.514365, lat: 13.826621 },
    { lng: 100.514161, lat: 13.826508 },
    { lng: 100.513796, lat: 13.826392 },
    { lng: 100.513572, lat: 13.82634 },
    { lng: 100.513485, lat: 13.826288 },
    { lng: 100.51344, lat: 13.826218 },
    { lng: 100.513245, lat: 13.826141 },
    { lng: 100.513182, lat: 13.826072 },
    { lng: 100.513141, lat: 13.825908 },
    { lng: 100.51313, lat: 13.8257 },
    { lng: 100.513147, lat: 13.825362 },
    { lng: 100.51315, lat: 13.825168 },
    { lng: 100.513177, lat: 13.824855 },
    { lng: 100.513104, lat: 13.824698 },
    { lng: 100.512989, lat: 13.824665 },
    { lng: 100.512742, lat: 13.824762 },
    { lng: 100.512532, lat: 13.825051 },
    { lng: 100.512225, lat: 13.825621 },
    { lng: 100.511997, lat: 13.825991 },
    { lng: 100.511651, lat: 13.826113 },
    { lng: 100.511181, lat: 13.826152 },
    { lng: 100.510983, lat: 13.826095 },
    { lng: 100.510918, lat: 13.825527 },
    { lng: 100.510747, lat: 13.825363 },
    { lng: 100.510575, lat: 13.825178 },
    { lng: 100.510068, lat: 13.82534 },
    { lng: 100.509358, lat: 13.825611 },
    { lng: 100.509298, lat: 13.825486 },
    { lng: 100.50925, lat: 13.82525 },
    { lng: 100.509196, lat: 13.825066 },
    { lng: 100.509497, lat: 13.824616 },
    { lng: 100.510118, lat: 13.824514 },
    { lng: 100.510264, lat: 13.824324 },
    { lng: 100.509996, lat: 13.823991 },
    { lng: 100.509695, lat: 13.823785 },
    { lng: 100.508735, lat: 13.823454 },
    { lng: 100.508328, lat: 13.823272 },
    { lng: 100.507877, lat: 13.823058 },
    { lng: 100.507705, lat: 13.822923 },
    { lng: 100.507662, lat: 13.822704 },
    { lng: 100.507568, lat: 13.822277 },
    { lng: 100.507668, lat: 13.821829 },
    { lng: 100.507298, lat: 13.821475 },
    { lng: 100.507043, lat: 13.821032 },
    { lng: 100.507056, lat: 13.82086 },
    { lng: 100.506477, lat: 13.819678 },
    { lng: 100.506359, lat: 13.819219 },
    { lng: 100.506579, lat: 13.818414 },
    { lng: 100.506917, lat: 13.817865 },
    { lng: 100.507676, lat: 13.817412 },
    { lng: 100.508889, lat: 13.817336 },
    { lng: 100.509223, lat: 13.817208 },
    { lng: 100.51064, lat: 13.816711 },
    { lng: 100.512158, lat: 13.81626 },
    { lng: 100.512855, lat: 13.816068 },
    { lng: 100.513284, lat: 13.815698 },
    { lng: 100.513923, lat: 13.815724 },
    { lng: 100.514641, lat: 13.81526 },
    { lng: 100.513146, lat: 13.813334 },
    { lng: 100.511511, lat: 13.81122 },
    { lng: 100.510902, lat: 13.810306 },
    { lng: 100.510742, lat: 13.809785 },
    { lng: 100.51054, lat: 13.809028 },
    { lng: 100.50727, lat: 13.806146 },
    { lng: 100.503469, lat: 13.802631 },
    { lng: 100.501525, lat: 13.801127 },
    { lng: 100.498087, lat: 13.79972 },
    { lng: 100.492323, lat: 13.797655 },
    { lng: 100.489258, lat: 13.796534 },
    { lng: 100.48407, lat: 13.79461 },
    { lng: 100.477777, lat: 13.792437 },
    { lng: 100.475098, lat: 13.79151 },
    { lng: 100.47318, lat: 13.790614 },
    { lng: 100.46612, lat: 13.788874 },
    { lng: 100.467888, lat: 13.791966 },
    { lng: 100.469773, lat: 13.794714 },
    { lng: 100.466335, lat: 13.79623 },
    { lng: 100.46391, lat: 13.797267 },
    { lng: 100.460914, lat: 13.797921 },
    { lng: 100.456308, lat: 13.798449 },
    { lng: 100.450767, lat: 13.798824 },
    { lng: 100.446446, lat: 13.799069 },
    { lng: 100.441052, lat: 13.799439 },
    { lng: 100.42627, lat: 13.800487 },
    { lng: 100.420016, lat: 13.800928 },
    { lng: 100.413633, lat: 13.801243 },
    { lng: 100.39944, lat: 13.801768 },
    { lng: 100.39612, lat: 13.801887 },
    { lng: 100.392778, lat: 13.801985 },
    { lng: 100.391845, lat: 13.802057 },
    { lng: 100.389512, lat: 13.802117 },
    { lng: 100.385548, lat: 13.802303 },
    { lng: 100.37671, lat: 13.802505 },
    { lng: 100.370298, lat: 13.802749 },
    { lng: 100.355499, lat: 13.803152 },
    { lng: 100.335786, lat: 13.8037 },
    { lng: 100.327789, lat: 13.804123 },
    { lng: 100.328888, lat: 13.802153 },
    { lng: 100.33137, lat: 13.796842 },
    { lng: 100.331061, lat: 13.793813 },
    { lng: 100.331882, lat: 13.79026 },
    { lng: 100.332784, lat: 13.786854 },
    { lng: 100.333286, lat: 13.785124 },
    { lng: 100.333511, lat: 13.784369 },
    { lng: 100.333687, lat: 13.783375 },
    { lng: 100.333162, lat: 13.783227 },
    { lng: 100.333312, lat: 13.782722 },
    { lng: 100.333331, lat: 13.782103 },
    { lng: 100.333182, lat: 13.78211 },
    { lng: 100.333168, lat: 13.781338 },
    { lng: 100.332378, lat: 13.781325 },
    { lng: 100.332484, lat: 13.779695 },
    { lng: 100.33101, lat: 13.776278 },
    { lng: 100.330358, lat: 13.773739 },
    { lng: 100.330124, lat: 13.773026 },
    { lng: 100.330025, lat: 13.771402 },
    { lng: 100.330299, lat: 13.768695 },
    { lng: 100.330009, lat: 13.765814 },
    { lng: 100.330166, lat: 13.764968 },
    { lng: 100.330366, lat: 13.764142 },
    { lng: 100.330304, lat: 13.762398 },
    { lng: 100.330362, lat: 13.761379 },
    { lng: 100.331641, lat: 13.76128 },
    { lng: 100.331717, lat: 13.760977 },
    { lng: 100.331788, lat: 13.760684 },
    { lng: 100.331938, lat: 13.760073 },
    { lng: 100.333058, lat: 13.76007 },
    { lng: 100.333048, lat: 13.759599 },
    { lng: 100.333042, lat: 13.758848 },
    { lng: 100.33302, lat: 13.757585 },
    { lng: 100.332855, lat: 13.755371 },
    { lng: 100.334022, lat: 13.755244 },
    { lng: 100.334413, lat: 13.753313 },
    { lng: 100.335535, lat: 13.753446 },
    { lng: 100.335618, lat: 13.749104 },
    { lng: 100.335572, lat: 13.7478 },
    { lng: 100.33557, lat: 13.747388 },
    { lng: 100.335638, lat: 13.747085 },
    { lng: 100.335817, lat: 13.746416 },
    { lng: 100.336013, lat: 13.745822 },
    { lng: 100.3358, lat: 13.745631 },
    { lng: 100.335753, lat: 13.745647 },
    { lng: 100.335302, lat: 13.74558 },
    { lng: 100.335445, lat: 13.744814 },
    { lng: 100.335636, lat: 13.743802 },
    { lng: 100.335834, lat: 13.742795 },
    { lng: 100.335978, lat: 13.742124 },
    { lng: 100.336064, lat: 13.741728 },
    { lng: 100.336114, lat: 13.741519 },
    { lng: 100.336166, lat: 13.741291 },
    { lng: 100.33576, lat: 13.741196 },
    { lng: 100.335878, lat: 13.740471 },
    { lng: 100.336275, lat: 13.740476 },
    { lng: 100.336356, lat: 13.739999 },
    { lng: 100.336656, lat: 13.738871 },
    { lng: 100.337102, lat: 13.737291 },
    { lng: 100.337114, lat: 13.736977 },
    { lng: 100.337385, lat: 13.736506 },
    { lng: 100.337492, lat: 13.736222 },
    { lng: 100.337388, lat: 13.735844 },
    { lng: 100.337308, lat: 13.735241 },
    { lng: 100.337341, lat: 13.734864 },
    { lng: 100.337423, lat: 13.734184 },
    { lng: 100.337511, lat: 13.732751 },
    { lng: 100.336728, lat: 13.732584 },
    { lng: 100.335578, lat: 13.7324 },
    { lng: 100.334931, lat: 13.732155 },
    { lng: 100.33515, lat: 13.731653 },
    { lng: 100.335543, lat: 13.730873 },
    { lng: 100.334582, lat: 13.730401 },
    { lng: 100.334794, lat: 13.730057 },
    { lng: 100.335071, lat: 13.729609 },
    { lng: 100.335462, lat: 13.729124 },
    { lng: 100.33599, lat: 13.728106 },
    { lng: 100.336087, lat: 13.727978 },
    { lng: 100.335902, lat: 13.727889 },
    { lng: 100.33642, lat: 13.726654 },
    { lng: 100.336586, lat: 13.726296 },
    { lng: 100.336586, lat: 13.726013 },
    { lng: 100.336154, lat: 13.725852 },
    { lng: 100.336213, lat: 13.724816 },
    { lng: 100.33632, lat: 13.723293 },
    { lng: 100.336312, lat: 13.722954 },
    { lng: 100.335859, lat: 13.72288 },
    { lng: 100.336068, lat: 13.722334 },
    { lng: 100.334569, lat: 13.721766 },
    { lng: 100.33293, lat: 13.721128 },
    { lng: 100.333646, lat: 13.719955 },
    { lng: 100.333959, lat: 13.719476 },
    { lng: 100.33426, lat: 13.719054 },
    { lng: 100.33447, lat: 13.718759 },
    { lng: 100.334784, lat: 13.718368 },
    { lng: 100.334961, lat: 13.718254 },
    { lng: 100.335422, lat: 13.718039 },
    { lng: 100.335578, lat: 13.717852 },
    { lng: 100.335802, lat: 13.717549 },
    { lng: 100.335958, lat: 13.71734 },
    { lng: 100.3361, lat: 13.717167 },
    { lng: 100.336699, lat: 13.71658 },
    { lng: 100.337664, lat: 13.718026 },
    { lng: 100.33834, lat: 13.718355 },
    { lng: 100.338703, lat: 13.718535 },
    { lng: 100.339062, lat: 13.718717 },
    { lng: 100.339441, lat: 13.718944 },
    { lng: 100.339919, lat: 13.7192 },
    { lng: 100.340346, lat: 13.719561 },
    { lng: 100.340664, lat: 13.71973 },
    { lng: 100.340904, lat: 13.71986 },
    { lng: 100.341517, lat: 13.720189 },
    { lng: 100.341746, lat: 13.720314 },
    { lng: 100.341978, lat: 13.720438 },
    { lng: 100.342531, lat: 13.720715 },
    { lng: 100.343074, lat: 13.721043 },
    { lng: 100.343616, lat: 13.721372 },
    { lng: 100.344176, lat: 13.721649 },
    { lng: 100.344704, lat: 13.721954 },
    { lng: 100.345106, lat: 13.722145 },
    { lng: 100.345954, lat: 13.72245 },
    { lng: 100.346194, lat: 13.72254 },
    { lng: 100.346313, lat: 13.722586 },
    { lng: 100.346685, lat: 13.722737 },
    { lng: 100.346895, lat: 13.722824 },
    { lng: 100.347288, lat: 13.722976 },
    { lng: 100.348682, lat: 13.723477 },
    { lng: 100.349114, lat: 13.723628 },
    { lng: 100.349922, lat: 13.72392 },
    { lng: 100.351483, lat: 13.724153 },
    { lng: 100.353022, lat: 13.724635 },
    { lng: 100.354661, lat: 13.725008 },
    { lng: 100.356315, lat: 13.725382 },
    { lng: 100.358547, lat: 13.725623 },
    { lng: 100.360804, lat: 13.725871 },
    { lng: 100.365097, lat: 13.726419 },
    { lng: 100.365863, lat: 13.724755 },
    { lng: 100.366687, lat: 13.72296 },
    { lng: 100.36834, lat: 13.719351 },
    { lng: 100.369263, lat: 13.71735 },
    { lng: 100.369817, lat: 13.71622 },
    { lng: 100.371307, lat: 13.713146 },
    { lng: 100.372275, lat: 13.711166 },
    { lng: 100.372979, lat: 13.709728 },
    { lng: 100.373671, lat: 13.708306 },
    { lng: 100.377862, lat: 13.70858 },
    { lng: 100.382006, lat: 13.708851 },
    { lng: 100.39028, lat: 13.709361 },
    { lng: 100.392024, lat: 13.709527 },
    { lng: 100.393305, lat: 13.70965 },
    { lng: 100.395846, lat: 13.709895 },
    { lng: 100.395838, lat: 13.710228 },
    { lng: 100.395894, lat: 13.712057 },
    { lng: 100.395826, lat: 13.712775 },
    { lng: 100.395812, lat: 13.713917 },
    { lng: 100.395794, lat: 13.715237 },
    { lng: 100.395771, lat: 13.716502 },
    { lng: 100.395738, lat: 13.718646 },
    { lng: 100.395722, lat: 13.720068 },
    { lng: 100.39569, lat: 13.721409 },
    { lng: 100.39562, lat: 13.723924 },
    { lng: 100.39556, lat: 13.72736 },
    { lng: 100.395539, lat: 13.729241 },
    { lng: 100.395511, lat: 13.731113 },
    { lng: 100.39549, lat: 13.732534 },
    { lng: 100.395462, lat: 13.734169 },
    { lng: 100.39541, lat: 13.736922 },
    { lng: 100.395417, lat: 13.737789 },
    { lng: 100.395434, lat: 13.739564 },
    { lng: 100.395421, lat: 13.741611 },
    { lng: 100.395376, lat: 13.743065 },
    { lng: 100.395352, lat: 13.744675 },
    { lng: 100.397275, lat: 13.744611 },
    { lng: 100.398743, lat: 13.744524 },
    { lng: 100.400554, lat: 13.744432 },
    { lng: 100.404124, lat: 13.74434 },
    { lng: 100.406179, lat: 13.744321 },
    { lng: 100.406264, lat: 13.730241 },
    { lng: 100.422227, lat: 13.731668 },
    { lng: 100.427489, lat: 13.73269 },
    { lng: 100.429699, lat: 13.733116 },
    { lng: 100.432058, lat: 13.733426 },
    { lng: 100.43343, lat: 13.733608 },
    { lng: 100.436636, lat: 13.733914 },
    { lng: 100.437559, lat: 13.734041 },
    { lng: 100.438402, lat: 13.73414 },
    { lng: 100.438761, lat: 13.734206 },
    { lng: 100.440983, lat: 13.734465 },
    { lng: 100.444474, lat: 13.734691 },
    { lng: 100.444756, lat: 13.734733 },
    { lng: 100.445442, lat: 13.73485 },
    { lng: 100.445741, lat: 13.734898 },
    { lng: 100.447347, lat: 13.735168 },
    { lng: 100.448307, lat: 13.735324 },
    { lng: 100.449255, lat: 13.735557 },
    { lng: 100.450232, lat: 13.735797 },
    { lng: 100.450966, lat: 13.735978 },
    { lng: 100.451724, lat: 13.736166 },
    { lng: 100.45215, lat: 13.73635 },
    { lng: 100.452609, lat: 13.736532 },
    { lng: 100.453098, lat: 13.736686 },
    { lng: 100.453542, lat: 13.736756 },
    { lng: 100.453999, lat: 13.736806 },
    { lng: 100.454594, lat: 13.736812 },
    { lng: 100.455405, lat: 13.736889 },
    { lng: 100.455555, lat: 13.73689 },
    { lng: 100.455752, lat: 13.73691 },
    { lng: 100.45586, lat: 13.736981 },
    { lng: 100.456037, lat: 13.737356 },
    { lng: 100.456215, lat: 13.737463 },
    { lng: 100.456432, lat: 13.737528 },
    { lng: 100.4567, lat: 13.737539 },
    { lng: 100.456879, lat: 13.737609 },
    { lng: 100.457118, lat: 13.737676 },
    { lng: 100.457438, lat: 13.737742 },
    { lng: 100.458425, lat: 13.738116 },
    { lng: 100.458653, lat: 13.738153 },
    { lng: 100.459165, lat: 13.738251 },
    { lng: 100.459365, lat: 13.738392 },
    { lng: 100.459565, lat: 13.738488 },
    { lng: 100.459876, lat: 13.738513 },
    { lng: 100.460198, lat: 13.738504 },
    { lng: 100.460557, lat: 13.738503 },
    { lng: 100.460759, lat: 13.738464 },
    { lng: 100.460897, lat: 13.738369 },
    { lng: 100.461029, lat: 13.738221 },
    { lng: 100.461167, lat: 13.738088 },
    { lng: 100.461512, lat: 13.738066 },
    { lng: 100.461718, lat: 13.738153 },
    { lng: 100.461764, lat: 13.737424 },
    { lng: 100.461961, lat: 13.736773 },
    { lng: 100.462189, lat: 13.734712 },
    { lng: 100.462404, lat: 13.734059 },
    { lng: 100.462473, lat: 13.733663 },
    { lng: 100.462706, lat: 13.732504 },
    { lng: 100.463027, lat: 13.731921 },
    { lng: 100.463777, lat: 13.730657 },
    { lng: 100.464056, lat: 13.730147 },
    { lng: 100.465384, lat: 13.727683 },
    { lng: 100.465769, lat: 13.727154 },
    { lng: 100.466192, lat: 13.726592 },
    { lng: 100.466633, lat: 13.725903 },
    { lng: 100.466838, lat: 13.725651 },
    { lng: 100.467025, lat: 13.725766 },
    { lng: 100.467218, lat: 13.72588 },
    { lng: 100.467608, lat: 13.726102 },
    { lng: 100.468018, lat: 13.726333 },
    { lng: 100.468415, lat: 13.726539 },
    { lng: 100.468557, lat: 13.726618 },
    { lng: 100.468856, lat: 13.726785 },
    { lng: 100.469305, lat: 13.72701 },
    { lng: 100.469335, lat: 13.727026 },
    { lng: 100.469531, lat: 13.72713 },
    { lng: 100.469865, lat: 13.727308 },
    { lng: 100.472715, lat: 13.728865 },
    { lng: 100.473603, lat: 13.729342 },
    { lng: 100.47387, lat: 13.729446 },
    { lng: 100.474201, lat: 13.729545 },
    { lng: 100.474652, lat: 13.729529 },
    { lng: 100.475539, lat: 13.729381 },
    { lng: 100.476446, lat: 13.729233 },
    { lng: 100.478119, lat: 13.728944 },
    { lng: 100.479663, lat: 13.728677 },
    { lng: 100.480386, lat: 13.728537 },
    { lng: 100.481545, lat: 13.728325 },
    { lng: 100.481897, lat: 13.728265 },
    { lng: 100.482331, lat: 13.728193 },
    { lng: 100.482859, lat: 13.728102 },
    { lng: 100.483114, lat: 13.728067 },
    { lng: 100.483523, lat: 13.72801 },
    { lng: 100.483713, lat: 13.727983 },
    { lng: 100.483908, lat: 13.727955 },
    { lng: 100.483998, lat: 13.727939 },
    { lng: 100.484214, lat: 13.727903 },
    { lng: 100.484427, lat: 13.727872 },
    { lng: 100.484674, lat: 13.727836 },
    { lng: 100.484975, lat: 13.727778 },
    { lng: 100.485161, lat: 13.727738 },
    { lng: 100.485816, lat: 13.727619 },
    { lng: 100.486269, lat: 13.72753 },
    { lng: 100.486516, lat: 13.727487 },
    { lng: 100.486691, lat: 13.727468 },
    { lng: 100.48713, lat: 13.727416 },
    { lng: 100.487716, lat: 13.727313 },
    { lng: 100.48787, lat: 13.727286 },
    { lng: 100.488046, lat: 13.727256 },
    { lng: 100.488641, lat: 13.727157 },
    { lng: 100.48898, lat: 13.727102 },
    { lng: 100.48937, lat: 13.727035 },
    { lng: 100.489876, lat: 13.726932 },
    { lng: 100.490326, lat: 13.726842 },
    { lng: 100.490779, lat: 13.726749 },
    { lng: 100.491268, lat: 13.726674 },
    { lng: 100.49163, lat: 13.72662 },
    { lng: 100.491929, lat: 13.726573 },
    { lng: 100.49222, lat: 13.726536 },
    { lng: 100.492329, lat: 13.726547 },
    { lng: 100.492448, lat: 13.726595 },
    { lng: 100.492562, lat: 13.726759 },
    { lng: 100.49264, lat: 13.726832 },
    { lng: 100.492749, lat: 13.726906 },
    { lng: 100.492865, lat: 13.726954 },
    { lng: 100.493008, lat: 13.726997 },
    { lng: 100.493144, lat: 13.727009 },
    { lng: 100.493272, lat: 13.727137 },
    { lng: 100.49332, lat: 13.727214 },
    { lng: 100.493364, lat: 13.727341 },
    { lng: 100.493413, lat: 13.727491 },
    { lng: 100.493499, lat: 13.727748 },
    { lng: 100.493544, lat: 13.727879 },
    { lng: 100.493573, lat: 13.727965 },
    { lng: 100.493627, lat: 13.72812 },
    { lng: 100.493733, lat: 13.728424 },
    { lng: 100.493964, lat: 13.729088 },
    { lng: 100.494012, lat: 13.729227 },
    { lng: 100.494065, lat: 13.729389 },
    { lng: 100.494172, lat: 13.729718 },
    { lng: 100.494387, lat: 13.730348 },
    { lng: 100.49442, lat: 13.730446 },
    { lng: 100.494485, lat: 13.730635 },
    { lng: 100.49456, lat: 13.730842 },
    { lng: 100.494705, lat: 13.731245 },
    { lng: 100.49474, lat: 13.731344 },
    { lng: 100.494773, lat: 13.731442 },
    { lng: 100.494817, lat: 13.731579 },
    { lng: 100.494866, lat: 13.731728 },
    { lng: 100.494889, lat: 13.731795 },
    { lng: 100.494967, lat: 13.732008 },
    { lng: 100.495047, lat: 13.732221 },
    { lng: 100.495109, lat: 13.732394 },
    { lng: 100.495204, lat: 13.732677 },
    { lng: 100.495252, lat: 13.732825 },
    { lng: 100.495296, lat: 13.732963 },
    { lng: 100.495348, lat: 13.733126 },
    { lng: 100.495402, lat: 13.733299 },
    { lng: 100.495468, lat: 13.733505 },
    { lng: 100.495534, lat: 13.73371 },
    { lng: 100.49561, lat: 13.733949 },
    { lng: 100.495656, lat: 13.734071 },
    { lng: 100.495757, lat: 13.734328 },
    { lng: 100.495794, lat: 13.734464 },
    { lng: 100.495827, lat: 13.734588 },
    { lng: 100.49585, lat: 13.73467 },
    { lng: 100.495934, lat: 13.734983 },
    { lng: 100.496087, lat: 13.735402 },
    { lng: 100.496283, lat: 13.735893 },
    { lng: 100.496306, lat: 13.735948 },
    { lng: 100.496326, lat: 13.736 },
    { lng: 100.496368, lat: 13.736106 },
    { lng: 100.496406, lat: 13.736195 },
    { lng: 100.496436, lat: 13.736273 },
    { lng: 100.496527, lat: 13.736443 },
    { lng: 100.496628, lat: 13.736631 },
    { lng: 100.496639, lat: 13.736655 },
    { lng: 100.496711, lat: 13.736772 },
    { lng: 100.496777, lat: 13.736882 },
    { lng: 100.496921, lat: 13.737122 },
    { lng: 100.497078, lat: 13.737295 },
    { lng: 100.497168, lat: 13.737393 },
    { lng: 100.497324, lat: 13.737576 },
    { lng: 100.497476, lat: 13.737751 },
    { lng: 100.497582, lat: 13.737875 },
    { lng: 100.497813, lat: 13.738141 },
    { lng: 100.497895, lat: 13.738235 },
    { lng: 100.498045, lat: 13.73843 },
    { lng: 100.498461, lat: 13.738966 },
    { lng: 100.498679, lat: 13.739248 },
    { lng: 100.49876, lat: 13.739352 },
    { lng: 100.496078, lat: 13.74041 },
    { lng: 100.493349, lat: 13.741475 },
    { lng: 100.490763, lat: 13.744847 },
    { lng: 100.488923, lat: 13.747248 },
    { lng: 100.488321, lat: 13.750703 },
    { lng: 100.488025, lat: 13.752467 },
    { lng: 100.489288, lat: 13.752431 },
    { lng: 100.490191, lat: 13.752428 },
    { lng: 100.491262, lat: 13.752451 },
    { lng: 100.492127, lat: 13.752474 },
    { lng: 100.492317, lat: 13.752486 },
    { lng: 100.49251, lat: 13.75249 },
    { lng: 100.492519, lat: 13.752486 },
    { lng: 100.49292, lat: 13.752503 },
    { lng: 100.493089, lat: 13.752499 },
    { lng: 100.493299, lat: 13.752509 },
    { lng: 100.493446, lat: 13.752506 },
    { lng: 100.49353, lat: 13.752802 },
    { lng: 100.493584, lat: 13.752951 },
    { lng: 100.493606, lat: 13.753044 },
    { lng: 100.493868, lat: 13.754026 },
    { lng: 100.494104, lat: 13.754915 },
    { lng: 100.494583, lat: 13.756652 },
    { lng: 100.494691, lat: 13.756828 },
    { lng: 100.494821, lat: 13.757006 },
    { lng: 100.494942, lat: 13.757129 },
    { lng: 100.495119, lat: 13.757266 },
    { lng: 100.495074, lat: 13.757383 },
    { lng: 100.495501, lat: 13.757639 },
    { lng: 100.495958, lat: 13.757795 },
    { lng: 100.49639, lat: 13.757795 },
    { lng: 100.49758, lat: 13.757512 },
    { lng: 100.499426, lat: 13.757165 },
    { lng: 100.501366, lat: 13.756721 },
    { lng: 100.502522, lat: 13.756501 },
    { lng: 100.504003, lat: 13.756203 },
    { lng: 100.504961, lat: 13.755987 },
    { lng: 100.505343, lat: 13.755989 },
    { lng: 100.505654, lat: 13.756082 },
    { lng: 100.505982, lat: 13.756294 },
    { lng: 100.506022, lat: 13.756472 },
    { lng: 100.506127, lat: 13.756737 },
    { lng: 100.506512, lat: 13.757549 },
    { lng: 100.506704, lat: 13.757953 },
    { lng: 100.506843, lat: 13.758256 },
    { lng: 100.507062, lat: 13.758831 },
    { lng: 100.507128, lat: 13.758966 },
    { lng: 100.507406, lat: 13.759537 },
    { lng: 100.507534, lat: 13.759774 },
    { lng: 100.507664, lat: 13.76003 },
    { lng: 100.507835, lat: 13.76039 },
    { lng: 100.507921, lat: 13.760575 },
    { lng: 100.508039, lat: 13.760832 },
    { lng: 100.508178, lat: 13.761126 },
    { lng: 100.508246, lat: 13.761264 },
    { lng: 100.508344, lat: 13.761481 },
    { lng: 100.508489, lat: 13.76179 },
    { lng: 100.508609, lat: 13.76204 },
    { lng: 100.508754, lat: 13.762352 },
    { lng: 100.508884, lat: 13.762635 },
    { lng: 100.509065, lat: 13.763009 },
    { lng: 100.509214, lat: 13.763309 },
    { lng: 100.509263, lat: 13.763391 },
    { lng: 100.512786, lat: 13.76024 },
  ],
  zone2: [
    { lng: 100.51541, lat: 13.76172 },
    { lng: 100.514025, lat: 13.760984 },
    { lng: 100.512787, lat: 13.760225 },
    { lng: 100.509263, lat: 13.763391 },
    { lng: 100.509162, lat: 13.763159 },
    { lng: 100.509045, lat: 13.762938 },
    { lng: 100.508841, lat: 13.762522 },
    { lng: 100.508569, lat: 13.761896 },
    { lng: 100.508274, lat: 13.761285 },
    { lng: 100.507975, lat: 13.760636 },
    { lng: 100.507649, lat: 13.75995 },
    { lng: 100.5073, lat: 13.759227 },
    { lng: 100.507073, lat: 13.75877 },
    { lng: 100.506778, lat: 13.758129 },
    { lng: 100.506512, lat: 13.757549 },
    { lng: 100.506241, lat: 13.756992 },
    { lng: 100.506127, lat: 13.756737 },
    { lng: 100.506022, lat: 13.756472 },
    { lng: 100.505982, lat: 13.756294 },
    { lng: 100.505769, lat: 13.756162 },
    { lng: 100.505654, lat: 13.756082 },
    { lng: 100.505343, lat: 13.755989 },
    { lng: 100.505237, lat: 13.755975 },
    { lng: 100.505134, lat: 13.755971 },
    { lng: 100.504961, lat: 13.755987 },
    { lng: 100.504681, lat: 13.756041 },
    { lng: 100.504394, lat: 13.756107 },
    { lng: 100.503618, lat: 13.756262 },
    { lng: 100.502868, lat: 13.756425 },
    { lng: 100.502439, lat: 13.756526 },
    { lng: 100.502344, lat: 13.756539 },
    { lng: 100.502268, lat: 13.756535 },
    { lng: 100.502157, lat: 13.756603 },
    { lng: 100.501815, lat: 13.756662 },
    { lng: 100.501366, lat: 13.756721 },
    { lng: 100.501181, lat: 13.756783 },
    { lng: 100.500351, lat: 13.756969 },
    { lng: 100.499928, lat: 13.757059 },
    { lng: 100.499426, lat: 13.757165 },
    { lng: 100.498903, lat: 13.757286 },
    { lng: 100.49749, lat: 13.757547 },
    { lng: 100.49639, lat: 13.757795 },
    { lng: 100.496139, lat: 13.75781 },
    { lng: 100.495958, lat: 13.757795 },
    { lng: 100.495756, lat: 13.757753 },
    { lng: 100.495501, lat: 13.757639 },
    { lng: 100.495074, lat: 13.757383 },
    { lng: 100.495119, lat: 13.757266 },
    { lng: 100.494942, lat: 13.757129 },
    { lng: 100.494848, lat: 13.757029 },
    { lng: 100.49477, lat: 13.756938 },
    { lng: 100.494691, lat: 13.756828 },
    { lng: 100.494583, lat: 13.756652 },
    { lng: 100.494392, lat: 13.755944 },
    { lng: 100.494215, lat: 13.755313 },
    { lng: 100.494104, lat: 13.754915 },
    { lng: 100.493956, lat: 13.754349 },
    { lng: 100.493787, lat: 13.753721 },
    { lng: 100.493606, lat: 13.753044 },
    { lng: 100.493446, lat: 13.752506 },
    { lng: 100.493152, lat: 13.752495 },
    { lng: 100.492897, lat: 13.75249 },
    { lng: 100.491262, lat: 13.752451 },
    { lng: 100.48986, lat: 13.752436 },
    { lng: 100.489206, lat: 13.752436 },
    { lng: 100.488691, lat: 13.752453 },
    { lng: 100.488352, lat: 13.752449 },
    { lng: 100.488025, lat: 13.752467 },
    { lng: 100.488195, lat: 13.75148 },
    { lng: 100.488903, lat: 13.747253 },
    { lng: 100.493329, lat: 13.74148 },
    { lng: 100.495751, lat: 13.740524 },
    { lng: 100.498756, lat: 13.739373 },
    { lng: 100.502714, lat: 13.738502 },
    { lng: 100.505245, lat: 13.737867 },
    { lng: 100.507408, lat: 13.736958 },
    { lng: 100.509096, lat: 13.735899 },
    { lng: 100.510612, lat: 13.734277 },
    { lng: 100.512003, lat: 13.731373 },
    { lng: 100.512497, lat: 13.73021 },
    { lng: 100.51283, lat: 13.72943 },
    { lng: 100.512915, lat: 13.729196 },
    { lng: 100.513041, lat: 13.729249 },
    { lng: 100.513083, lat: 13.729302 },
    { lng: 100.513147, lat: 13.729356 },
    { lng: 100.513271, lat: 13.729408 },
    { lng: 100.513679, lat: 13.729753 },
    { lng: 100.513889, lat: 13.729951 },
    { lng: 100.514135, lat: 13.730191 },
    { lng: 100.514302, lat: 13.730355 },
    { lng: 100.514544, lat: 13.730703 },
    { lng: 100.514838, lat: 13.73105 },
    { lng: 100.514966, lat: 13.731221 },
    { lng: 100.515182, lat: 13.73158 },
    { lng: 100.515357, lat: 13.731973 },
    { lng: 100.515468, lat: 13.732279 },
    { lng: 100.515651, lat: 13.732789 },
    { lng: 100.515817, lat: 13.733643 },
    { lng: 100.515898, lat: 13.734269 },
    { lng: 100.515945, lat: 13.734976 },
    { lng: 100.515852, lat: 13.736507 },
    { lng: 100.515785, lat: 13.73799 },
    { lng: 100.516284, lat: 13.737842 },
    { lng: 100.516768, lat: 13.737699 },
    { lng: 100.5176873, lat: 13.7373799 },
    { lng: 100.5179572, lat: 13.7372663 },
    { lng: 100.5185491, lat: 13.7370075 },
    { lng: 100.5187414, lat: 13.7369063 },
    { lng: 100.521597, lat: 13.735756 },
    { lng: 100.521745, lat: 13.736764 },
    { lng: 100.521919, lat: 13.737788 },
    { lng: 100.522222, lat: 13.739343 },
    { lng: 100.522786, lat: 13.742798 },
    { lng: 100.523392, lat: 13.745539 },
    { lng: 100.523702, lat: 13.747574 },
    { lng: 100.524097, lat: 13.749319 },
    { lng: 100.524396, lat: 13.749833 },
    { lng: 100.522791, lat: 13.750766 },
    { lng: 100.521978, lat: 13.751178 },
    { lng: 100.521199, lat: 13.751673 },
    { lng: 100.520355, lat: 13.752144 },
    { lng: 100.519944, lat: 13.752475 },
    { lng: 100.519052, lat: 13.752706 },
    { lng: 100.519562, lat: 13.753864 },
    { lng: 100.520052, lat: 13.755105 },
    { lng: 100.520455, lat: 13.756096 },
    { lng: 100.520704, lat: 13.75688 },
    { lng: 100.52083, lat: 13.757196 },
    { lng: 100.520615, lat: 13.757301 },
    { lng: 100.520335, lat: 13.757538 },
    { lng: 100.52023, lat: 13.757633 },
    { lng: 100.51541, lat: 13.76172 },
  ],
  zone3: [
    { lng: 100.465237, lat: 13.702075 },
    { lng: 100.465432, lat: 13.702005 },
    { lng: 100.46657, lat: 13.702446 },
    { lng: 100.467008, lat: 13.702607 },
    { lng: 100.467234, lat: 13.702414 },
    { lng: 100.4675, lat: 13.702185 },
    { lng: 100.467799, lat: 13.702147 },
    { lng: 100.46789, lat: 13.702122 },
    { lng: 100.468347, lat: 13.701811 },
    { lng: 100.468447, lat: 13.701762 },
    { lng: 100.467378, lat: 13.699516 },
    { lng: 100.467319, lat: 13.69924 },
    { lng: 100.469325, lat: 13.699219 },
    { lng: 100.470398, lat: 13.698964 },
    { lng: 100.471348, lat: 13.698526 },
    { lng: 100.472082, lat: 13.699704 },
    { lng: 100.473246, lat: 13.702336 },
    { lng: 100.472935, lat: 13.702758 },
    { lng: 100.472844, lat: 13.702523 },
    { lng: 100.472753, lat: 13.702596 },
    { lng: 100.472914, lat: 13.70294 },
    { lng: 100.477978, lat: 13.70466 },
    { lng: 100.478838, lat: 13.701613 },
    { lng: 100.480881, lat: 13.704521 },
    { lng: 100.482905, lat: 13.706357 },
    { lng: 100.48296, lat: 13.706419 },
    { lng: 100.488449, lat: 13.70343 },
    { lng: 100.49309, lat: 13.714331 },
    { lng: 100.492467, lat: 13.719306 },
    { lng: 100.503632, lat: 13.720333 },
    { lng: 100.50624, lat: 13.719034 },
    { lng: 100.506908, lat: 13.718837 },
    { lng: 100.507545, lat: 13.718755 },
    { lng: 100.508882, lat: 13.718272 },
    { lng: 100.511456, lat: 13.717177 },
    { lng: 100.512525, lat: 13.719922 },
    { lng: 100.512672, lat: 13.720869 },
    { lng: 100.512953, lat: 13.722692 },
    { lng: 100.512952, lat: 13.72408 },
    { lng: 100.512953, lat: 13.724173 },
    { lng: 100.512953, lat: 13.724356 },
    { lng: 100.512938, lat: 13.7245 },
    { lng: 100.512862, lat: 13.725222 },
    { lng: 100.512848, lat: 13.72536 },
    { lng: 100.512825, lat: 13.72558 },
    { lng: 100.512787, lat: 13.725935 },
    { lng: 100.512779, lat: 13.726017 },
    { lng: 100.512769, lat: 13.726112 },
    { lng: 100.512752, lat: 13.726268 },
    { lng: 100.51273, lat: 13.726483 },
    { lng: 100.512702, lat: 13.72675 },
    { lng: 100.51265, lat: 13.727242 },
    { lng: 100.512716, lat: 13.72773 },
    { lng: 100.512748, lat: 13.727969 },
    { lng: 100.512767, lat: 13.728098 },
    { lng: 100.512782, lat: 13.728211 },
    { lng: 100.512843, lat: 13.728669 },
    { lng: 100.512885, lat: 13.728981 },
    { lng: 100.512906, lat: 13.729135 },
    { lng: 100.512915, lat: 13.729196 },
    { lng: 100.51284, lat: 13.729404 },
    { lng: 100.512045, lat: 13.731269 },
    { lng: 100.512017, lat: 13.731336 },
    { lng: 100.511977, lat: 13.731419 },
    { lng: 100.511933, lat: 13.731513 },
    { lng: 100.511847, lat: 13.731692 },
    { lng: 100.511681, lat: 13.73204 },
    { lng: 100.511345, lat: 13.732741 },
    { lng: 100.510674, lat: 13.734143 },
    { lng: 100.510622, lat: 13.734251 },
    { lng: 100.510526, lat: 13.734354 },
    { lng: 100.51043, lat: 13.734457 },
    { lng: 100.510245, lat: 13.734654 },
    { lng: 100.509865, lat: 13.735061 },
    { lng: 100.509105, lat: 13.735873 },
    { lng: 100.508682, lat: 13.73614 },
    { lng: 100.50825, lat: 13.73641 },
    { lng: 100.507417, lat: 13.736932 },
    { lng: 100.507148, lat: 13.737045 },
    { lng: 100.506878, lat: 13.737159 },
    { lng: 100.506338, lat: 13.737385 },
    { lng: 100.505254, lat: 13.737841 },
    { lng: 100.504889, lat: 13.737933 },
    { lng: 100.504516, lat: 13.738026 },
    { lng: 100.503779, lat: 13.738211 },
    { lng: 100.503596, lat: 13.738257 },
    { lng: 100.503413, lat: 13.738303 },
    { lng: 100.503047, lat: 13.738394 },
    { lng: 100.502717, lat: 13.738476 },
    { lng: 100.502302, lat: 13.738568 },
    { lng: 100.502046, lat: 13.738625 },
    { lng: 100.501801, lat: 13.738679 },
    { lng: 100.501342, lat: 13.738779 },
    { lng: 100.500511, lat: 13.738963 },
    { lng: 100.498765, lat: 13.739347 },
    { lng: 100.4979, lat: 13.73823 },
    { lng: 100.497408, lat: 13.737661 },
    { lng: 100.497173, lat: 13.737388 },
    { lng: 100.497083, lat: 13.73729 },
    { lng: 100.496926, lat: 13.737117 },
    { lng: 100.496716, lat: 13.736766 },
    { lng: 100.496643, lat: 13.736645 },
    { lng: 100.496532, lat: 13.736437 },
    { lng: 100.496442, lat: 13.736267 },
    { lng: 100.49622, lat: 13.735717 },
    { lng: 100.496095, lat: 13.735405 },
    { lng: 100.495939, lat: 13.734978 },
    { lng: 100.495853, lat: 13.734657 },
    { lng: 100.495763, lat: 13.734323 },
    { lng: 100.495661, lat: 13.734065 },
    { lng: 100.495615, lat: 13.733944 },
    { lng: 100.495274, lat: 13.732871 },
    { lng: 100.495192, lat: 13.732619 },
    { lng: 100.495108, lat: 13.732371 },
    { lng: 100.494891, lat: 13.73178 },
    { lng: 100.494726, lat: 13.731282 },
    { lng: 100.494477, lat: 13.730592 },
    { lng: 100.494177, lat: 13.729713 },
    { lng: 100.494035, lat: 13.729274 },
    { lng: 100.493883, lat: 13.728835 },
    { lng: 100.493579, lat: 13.72796 },
    { lng: 100.493447, lat: 13.727572 },
    { lng: 100.493369, lat: 13.727336 },
    { lng: 100.493325, lat: 13.727209 },
    { lng: 100.493277, lat: 13.727132 },
    { lng: 100.49315, lat: 13.727004 },
    { lng: 100.493014, lat: 13.726992 },
    { lng: 100.49287, lat: 13.726949 },
    { lng: 100.492755, lat: 13.7269 },
    { lng: 100.492646, lat: 13.726827 },
    { lng: 100.492567, lat: 13.726754 },
    { lng: 100.492454, lat: 13.72659 },
    { lng: 100.492334, lat: 13.726542 },
    { lng: 100.492226, lat: 13.726531 },
    { lng: 100.491934, lat: 13.726568 },
    { lng: 100.491628, lat: 13.726616 },
    { lng: 100.491361, lat: 13.726654 },
    { lng: 100.490784, lat: 13.726744 },
    { lng: 100.489342, lat: 13.727036 },
    { lng: 100.487926, lat: 13.727272 },
    { lng: 100.487135, lat: 13.727411 },
    { lng: 100.48669, lat: 13.727464 },
    { lng: 100.486521, lat: 13.727482 },
    { lng: 100.485284, lat: 13.72771 },
    { lng: 100.484667, lat: 13.727833 },
    { lng: 100.484219, lat: 13.727898 },
    { lng: 100.483916, lat: 13.72795 },
    { lng: 100.482864, lat: 13.728097 },
    { lng: 100.482374, lat: 13.728183 },
    { lng: 100.481894, lat: 13.728259 },
    { lng: 100.480372, lat: 13.728535 },
    { lng: 100.479858, lat: 13.72864 },
    { lng: 100.479331, lat: 13.728734 },
    { lng: 100.478809, lat: 13.728819 },
    { lng: 100.47658, lat: 13.729206 },
    { lng: 100.474657, lat: 13.729524 },
    { lng: 100.474206, lat: 13.72954 },
    { lng: 100.473875, lat: 13.729441 },
    { lng: 100.473608, lat: 13.729336 },
    { lng: 100.47308, lat: 13.729052 },
    { lng: 100.472804, lat: 13.728904 },
    { lng: 100.472558, lat: 13.728771 },
    { lng: 100.472373, lat: 13.72867 },
    { lng: 100.47212, lat: 13.728533 },
    { lng: 100.471922, lat: 13.728424 },
    { lng: 100.471597, lat: 13.728246 },
    { lng: 100.47139, lat: 13.728134 },
    { lng: 100.471127, lat: 13.727989 },
    { lng: 100.470886, lat: 13.727857 },
    { lng: 100.46987, lat: 13.727303 },
    { lng: 100.469314, lat: 13.727006 },
    { lng: 100.468887, lat: 13.726794 },
    { lng: 100.468455, lat: 13.726552 },
    { lng: 100.468005, lat: 13.726318 },
    { lng: 100.467613, lat: 13.726097 },
    { lng: 100.467223, lat: 13.725875 },
    { lng: 100.46703, lat: 13.725761 },
    { lng: 100.466843, lat: 13.725646 },
    { lng: 100.466638, lat: 13.725898 },
    { lng: 100.466197, lat: 13.726587 },
    { lng: 100.46539, lat: 13.727678 },
    { lng: 100.463782, lat: 13.730652 },
    { lng: 100.463033, lat: 13.731916 },
    { lng: 100.462712, lat: 13.732498 },
    { lng: 100.462558, lat: 13.733251 },
    { lng: 100.46241, lat: 13.734054 },
    { lng: 100.462194, lat: 13.734707 },
    { lng: 100.46214, lat: 13.735246 },
    { lng: 100.462112, lat: 13.735488 },
    { lng: 100.461966, lat: 13.736768 },
    { lng: 100.46177, lat: 13.737419 },
    { lng: 100.461723, lat: 13.738148 },
    { lng: 100.461518, lat: 13.73806 },
    { lng: 100.461172, lat: 13.738082 },
    { lng: 100.461035, lat: 13.738216 },
    { lng: 100.460903, lat: 13.738364 },
    { lng: 100.460764, lat: 13.738459 },
    { lng: 100.46055, lat: 13.7385 },
    { lng: 100.460204, lat: 13.738498 },
    { lng: 100.459881, lat: 13.738507 },
    { lng: 100.45957, lat: 13.738483 },
    { lng: 100.459371, lat: 13.738387 },
    { lng: 100.45917, lat: 13.738245 },
    { lng: 100.458648, lat: 13.738147 },
    { lng: 100.45843, lat: 13.738111 },
    { lng: 100.457846, lat: 13.737887 },
    { lng: 100.457444, lat: 13.737736 },
    { lng: 100.457123, lat: 13.737671 },
    { lng: 100.456884, lat: 13.737604 },
    { lng: 100.456706, lat: 13.737533 },
    { lng: 100.456437, lat: 13.737523 },
    { lng: 100.45622, lat: 13.737458 },
    { lng: 100.456042, lat: 13.737351 },
    { lng: 100.455865, lat: 13.736976 },
    { lng: 100.455758, lat: 13.736905 },
    { lng: 100.45557, lat: 13.736888 },
    { lng: 100.45541, lat: 13.736884 },
    { lng: 100.454599, lat: 13.736807 },
    { lng: 100.454004, lat: 13.7368 },
    { lng: 100.453548, lat: 13.73675 },
    { lng: 100.453103, lat: 13.73668 },
    { lng: 100.452615, lat: 13.736526 },
    { lng: 100.452179, lat: 13.736355 },
    { lng: 100.451729, lat: 13.73616 },
    { lng: 100.448312, lat: 13.735319 },
    { lng: 100.44448, lat: 13.734686 },
    { lng: 100.440952, lat: 13.734458 },
    { lng: 100.438766, lat: 13.734201 },
    { lng: 100.438407, lat: 13.734135 },
    { lng: 100.43752, lat: 13.734034 },
    { lng: 100.436641, lat: 13.733909 },
    { lng: 100.436263, lat: 13.733873 },
    { lng: 100.435664, lat: 13.73382 },
    { lng: 100.433391, lat: 13.733596 },
    { lng: 100.431522, lat: 13.73335 },
    { lng: 100.430587, lat: 13.733227 },
    { lng: 100.430122, lat: 13.733166 },
    { lng: 100.42987, lat: 13.733132 },
    { lng: 100.429704, lat: 13.73311 },
    { lng: 100.42596, lat: 13.732388 },
    { lng: 100.424088, lat: 13.732026 },
    { lng: 100.423152, lat: 13.731846 },
    { lng: 100.422683, lat: 13.731756 },
    { lng: 100.422227, lat: 13.731668 },
    { lng: 100.41424, lat: 13.730953 },
    { lng: 100.410246, lat: 13.730596 },
    { lng: 100.408249, lat: 13.730418 },
    { lng: 100.40725, lat: 13.730328 },
    { lng: 100.406748, lat: 13.730284 },
    { lng: 100.406264, lat: 13.730241 },
    { lng: 100.406179, lat: 13.744321 },
    { lng: 100.402978, lat: 13.744359 },
    { lng: 100.399768, lat: 13.744477 },
    { lng: 100.397275, lat: 13.744611 },
    { lng: 100.395352, lat: 13.744675 },
    { lng: 100.395372, lat: 13.743212 },
    { lng: 100.395421, lat: 13.741544 },
    { lng: 100.395429, lat: 13.740451 },
    { lng: 100.395435, lat: 13.739232 },
    { lng: 100.395408, lat: 13.736952 },
    { lng: 100.395444, lat: 13.734929 },
    { lng: 100.395489, lat: 13.732813 },
    { lng: 100.395545, lat: 13.728685 },
    { lng: 100.395601, lat: 13.724693 },
    { lng: 100.395708, lat: 13.720717 },
    { lng: 100.395826, lat: 13.712775 },
    { lng: 100.395894, lat: 13.712057 },
    { lng: 100.395881, lat: 13.711338 },
    { lng: 100.395834, lat: 13.710187 },
    { lng: 100.395846, lat: 13.709895 },
    { lng: 100.39028, lat: 13.709361 },
    { lng: 100.38196, lat: 13.708851 },
    { lng: 100.373671, lat: 13.708306 },
    { lng: 100.369298, lat: 13.717279 },
    { lng: 100.365097, lat: 13.726419 },
    { lng: 100.360706, lat: 13.725859 },
    { lng: 100.356315, lat: 13.725382 },
    { lng: 100.353022, lat: 13.724635 },
    { lng: 100.351483, lat: 13.724153 },
    { lng: 100.350708, lat: 13.724034 },
    { lng: 100.349922, lat: 13.72392 },
    { lng: 100.348584, lat: 13.723441 },
    { lng: 100.34719, lat: 13.722943 },
    { lng: 100.346418, lat: 13.72263 },
    { lng: 100.34607, lat: 13.722491 },
    { lng: 100.345852, lat: 13.722411 },
    { lng: 100.345477, lat: 13.722275 },
    { lng: 100.345085, lat: 13.722133 },
    { lng: 100.344704, lat: 13.721954 },
    { lng: 100.344358, lat: 13.721754 },
    { lng: 100.344157, lat: 13.721637 },
    { lng: 100.34389, lat: 13.721506 },
    { lng: 100.343616, lat: 13.721372 },
    { lng: 100.343309, lat: 13.721189 },
    { lng: 100.343138, lat: 13.721084 },
    { lng: 100.342872, lat: 13.720916 },
    { lng: 100.342604, lat: 13.720758 },
    { lng: 100.342052, lat: 13.720476 },
    { lng: 100.341419, lat: 13.720141 },
    { lng: 100.341044, lat: 13.719936 },
    { lng: 100.340346, lat: 13.719561 },
    { lng: 100.339919, lat: 13.7192 },
    { lng: 100.339441, lat: 13.718944 },
    { lng: 100.339062, lat: 13.718717 },
    { lng: 100.338302, lat: 13.71834 },
    { lng: 100.337664, lat: 13.718026 },
    { lng: 100.337171, lat: 13.717288 },
    { lng: 100.336729, lat: 13.716625 },
    { lng: 100.336699, lat: 13.71658 },
    { lng: 100.336298, lat: 13.715832 },
    { lng: 100.336153, lat: 13.715576 },
    { lng: 100.33597, lat: 13.715321 },
    { lng: 100.337011, lat: 13.71499 },
    { lng: 100.337513, lat: 13.714834 },
    { lng: 100.337749, lat: 13.71452 },
    { lng: 100.338117, lat: 13.714034 },
    { lng: 100.338317, lat: 13.713664 },
    { lng: 100.338539, lat: 13.713221 },
    { lng: 100.338775, lat: 13.712762 },
    { lng: 100.338034, lat: 13.712301 },
    { lng: 100.337261, lat: 13.711826 },
    { lng: 100.336906, lat: 13.711533 },
    { lng: 100.336573, lat: 13.711254 },
    { lng: 100.33552, lat: 13.711026 },
    { lng: 100.334775, lat: 13.712062 },
    { lng: 100.334392, lat: 13.712589 },
    { lng: 100.334064, lat: 13.71305 },
    { lng: 100.332264, lat: 13.712442 },
    { lng: 100.33288, lat: 13.711446 },
    { lng: 100.332976, lat: 13.711191 },
    { lng: 100.333053, lat: 13.710978 },
    { lng: 100.333067, lat: 13.71068 },
    { lng: 100.333082, lat: 13.710562 },
    { lng: 100.333357, lat: 13.709717 },
    { lng: 100.333443, lat: 13.709197 },
    { lng: 100.333461, lat: 13.708575 },
    { lng: 100.333451, lat: 13.708323 },
    { lng: 100.333329, lat: 13.707683 },
    { lng: 100.333132, lat: 13.707056 },
    { lng: 100.332925, lat: 13.70665 },
    { lng: 100.332698, lat: 13.706272 },
    { lng: 100.332274, lat: 13.705571 },
    { lng: 100.332053, lat: 13.705201 },
    { lng: 100.331792, lat: 13.704777 },
    { lng: 100.331072, lat: 13.7042 },
    { lng: 100.330733, lat: 13.703893 },
    { lng: 100.330425, lat: 13.703544 },
    { lng: 100.330132, lat: 13.702994 },
    { lng: 100.330147, lat: 13.702606 },
    { lng: 100.330264, lat: 13.701641 },
    { lng: 100.330244, lat: 13.700737 },
    { lng: 100.330236, lat: 13.699953 },
    { lng: 100.330252, lat: 13.699765 },
    { lng: 100.330322, lat: 13.699481 },
    { lng: 100.330547, lat: 13.699136 },
    { lng: 100.330902, lat: 13.69835 },
    { lng: 100.331075, lat: 13.69793 },
    { lng: 100.331206, lat: 13.697618 },
    { lng: 100.331311, lat: 13.697231 },
    { lng: 100.331388, lat: 13.696559 },
    { lng: 100.331464, lat: 13.695943 },
    { lng: 100.331512, lat: 13.695549 },
    { lng: 100.331552, lat: 13.695246 },
    { lng: 100.331671, lat: 13.694289 },
    { lng: 100.331822, lat: 13.693365 },
    { lng: 100.33195, lat: 13.692883 },
    { lng: 100.332029, lat: 13.692747 },
    { lng: 100.332086, lat: 13.692612 },
    { lng: 100.332048, lat: 13.691959 },
    { lng: 100.332024, lat: 13.691468 },
    { lng: 100.332027, lat: 13.69118 },
    { lng: 100.332065, lat: 13.690771 },
    { lng: 100.332094, lat: 13.690535 },
    { lng: 100.332267, lat: 13.689598 },
    { lng: 100.332315, lat: 13.689196 },
    { lng: 100.332429, lat: 13.688437 },
    { lng: 100.332479, lat: 13.688223 },
    { lng: 100.332558, lat: 13.687906 },
    { lng: 100.332672, lat: 13.687419 },
    { lng: 100.332865, lat: 13.686826 },
    { lng: 100.333034, lat: 13.686389 },
    { lng: 100.333139, lat: 13.685979 },
    { lng: 100.333459, lat: 13.684914 },
    { lng: 100.333668, lat: 13.684256 },
    { lng: 100.333848, lat: 13.683691 },
    { lng: 100.333856, lat: 13.683457 },
    { lng: 100.333834, lat: 13.68322 },
    { lng: 100.333726, lat: 13.682764 },
    { lng: 100.333701, lat: 13.682627 },
    { lng: 100.333599, lat: 13.68211 },
    { lng: 100.333578, lat: 13.682019 },
    { lng: 100.333577, lat: 13.681597 },
    { lng: 100.333589, lat: 13.681319 },
    { lng: 100.333594, lat: 13.6813 },
    { lng: 100.333612, lat: 13.681261 },
    { lng: 100.334788, lat: 13.679316 },
    { lng: 100.335058, lat: 13.678852 },
    { lng: 100.335348, lat: 13.678353 },
    { lng: 100.335886, lat: 13.677519 },
    { lng: 100.336026, lat: 13.677128 },
    { lng: 100.336059, lat: 13.67693 },
    { lng: 100.336123, lat: 13.676938 },
    { lng: 100.336873, lat: 13.677138 },
    { lng: 100.337601, lat: 13.67734 },
    { lng: 100.337716, lat: 13.676937 },
    { lng: 100.337878, lat: 13.676252 },
    { lng: 100.33795, lat: 13.676022 },
    { lng: 100.338122, lat: 13.675469 },
    { lng: 100.338306, lat: 13.674816 },
    { lng: 100.338356, lat: 13.674161 },
    { lng: 100.338359, lat: 13.673962 },
    { lng: 100.338378, lat: 13.673797 },
    { lng: 100.338547, lat: 13.672201 },
    { lng: 100.33871, lat: 13.670817 },
    { lng: 100.338871, lat: 13.669453 },
    { lng: 100.339065, lat: 13.668989 },
    { lng: 100.339208, lat: 13.668655 },
    { lng: 100.339328, lat: 13.668328 },
    { lng: 100.339352, lat: 13.667769 },
    { lng: 100.339397, lat: 13.667627 },
    { lng: 100.339711, lat: 13.667235 },
    { lng: 100.340041, lat: 13.666892 },
    { lng: 100.340164, lat: 13.666496 },
    { lng: 100.340195, lat: 13.665928 },
    { lng: 100.340158, lat: 13.665606 },
    { lng: 100.340155, lat: 13.665232 },
    { lng: 100.340174, lat: 13.665178 },
    { lng: 100.340311, lat: 13.664714 },
    { lng: 100.340327, lat: 13.664568 },
    { lng: 100.340367, lat: 13.664326 },
    { lng: 100.340535, lat: 13.663862 },
    { lng: 100.340613, lat: 13.663548 },
    { lng: 100.340729, lat: 13.663168 },
    { lng: 100.340777, lat: 13.662868 },
    { lng: 100.340785, lat: 13.662805 },
    { lng: 100.340935, lat: 13.662442 },
    { lng: 100.341078, lat: 13.662044 },
    { lng: 100.34121, lat: 13.661619 },
    { lng: 100.341282, lat: 13.661184 },
    { lng: 100.341334, lat: 13.66096 },
    { lng: 100.341308, lat: 13.660656 },
    { lng: 100.341143, lat: 13.660362 },
    { lng: 100.341089, lat: 13.660212 },
    { lng: 100.341014, lat: 13.659877 },
    { lng: 100.340992, lat: 13.659292 },
    { lng: 100.340936, lat: 13.658996 },
    { lng: 100.340863, lat: 13.658848 },
    { lng: 100.340826, lat: 13.658716 },
    { lng: 100.340681, lat: 13.658518 },
    { lng: 100.340343, lat: 13.658247 },
    { lng: 100.340128, lat: 13.658106 },
    { lng: 100.339992, lat: 13.657974 },
    { lng: 100.339877, lat: 13.657771 },
    { lng: 100.339812, lat: 13.65759 },
    { lng: 100.339649, lat: 13.656987 },
    { lng: 100.33953, lat: 13.656536 },
    { lng: 100.339483, lat: 13.656416 },
    { lng: 100.339416, lat: 13.655961 },
    { lng: 100.339405, lat: 13.655704 },
    { lng: 100.339414, lat: 13.655269 },
    { lng: 100.339448, lat: 13.654899 },
    { lng: 100.339824, lat: 13.654906 },
    { lng: 100.340705, lat: 13.65487 },
    { lng: 100.341351, lat: 13.654892 },
    { lng: 100.341834, lat: 13.654858 },
    { lng: 100.34199, lat: 13.654863 },
    { lng: 100.342919, lat: 13.654755 },
    { lng: 100.344248, lat: 13.654673 },
    { lng: 100.344733, lat: 13.654666 },
    { lng: 100.345097, lat: 13.654657 },
    { lng: 100.345321, lat: 13.65467 },
    { lng: 100.345627, lat: 13.65477 },
    { lng: 100.346297, lat: 13.655097 },
    { lng: 100.346647, lat: 13.655239 },
    { lng: 100.3467, lat: 13.655181 },
    { lng: 100.346708, lat: 13.655102 },
    { lng: 100.346815, lat: 13.653684 },
    { lng: 100.346786, lat: 13.652334 },
    { lng: 100.346796, lat: 13.652265 },
    { lng: 100.346863, lat: 13.65217 },
    { lng: 100.346558, lat: 13.650786 },
    { lng: 100.346505, lat: 13.650067 },
    { lng: 100.346458, lat: 13.649243 },
    { lng: 100.345758, lat: 13.649272 },
    { lng: 100.345328, lat: 13.64929 },
    { lng: 100.344897, lat: 13.649306 },
    { lng: 100.344931, lat: 13.648556 },
    { lng: 100.345002, lat: 13.647812 },
    { lng: 100.345223, lat: 13.646745 },
    { lng: 100.345473, lat: 13.645789 },
    { lng: 100.345762, lat: 13.644664 },
    { lng: 100.346059, lat: 13.643571 },
    { lng: 100.347541, lat: 13.643627 },
    { lng: 100.348222, lat: 13.643672 },
    { lng: 100.348584, lat: 13.643672 },
    { lng: 100.348687, lat: 13.643626 },
    { lng: 100.348642, lat: 13.643359 },
    { lng: 100.348628, lat: 13.64312 },
    { lng: 100.349519, lat: 13.643016 },
    { lng: 100.349836, lat: 13.642097 },
    { lng: 100.349935, lat: 13.641958 },
    { lng: 100.350042, lat: 13.641794 },
    { lng: 100.35014, lat: 13.641606 },
    { lng: 100.35017, lat: 13.641353 },
    { lng: 100.351262, lat: 13.641903 },
    { lng: 100.351501, lat: 13.64202 },
    { lng: 100.351765, lat: 13.641369 },
    { lng: 100.351962, lat: 13.640826 },
    { lng: 100.353166, lat: 13.640847 },
    { lng: 100.354333, lat: 13.640871 },
    { lng: 100.354556, lat: 13.639381 },
    { lng: 100.35461, lat: 13.639005 },
    { lng: 100.354749, lat: 13.638348 },
    { lng: 100.355342, lat: 13.636539 },
    { lng: 100.356651, lat: 13.636734 },
    { lng: 100.357836, lat: 13.636995 },
    { lng: 100.357977, lat: 13.635558 },
    { lng: 100.358118, lat: 13.634234 },
    { lng: 100.359958, lat: 13.634477 },
    { lng: 100.3601, lat: 13.633437 },
    { lng: 100.362344, lat: 13.633291 },
    { lng: 100.363361, lat: 13.633223 },
    { lng: 100.364394, lat: 13.633132 },
    { lng: 100.364657, lat: 13.633327 },
    { lng: 100.364775, lat: 13.633189 },
    { lng: 100.364877, lat: 13.633254 },
    { lng: 100.365148, lat: 13.63294 },
    { lng: 100.365215, lat: 13.632876 },
    { lng: 100.365475, lat: 13.6331 },
    { lng: 100.365766, lat: 13.633343 },
    { lng: 100.366033, lat: 13.633528 },
    { lng: 100.366466, lat: 13.632815 },
    { lng: 100.366803, lat: 13.632251 },
    { lng: 100.367015, lat: 13.632282 },
    { lng: 100.367795, lat: 13.630009 },
    { lng: 100.368228, lat: 13.630052 },
    { lng: 100.368639, lat: 13.630061 },
    { lng: 100.368725, lat: 13.62941 },
    { lng: 100.368746, lat: 13.62926 },
    { lng: 100.368842, lat: 13.628948 },
    { lng: 100.369363, lat: 13.627608 },
    { lng: 100.369914, lat: 13.626764 },
    { lng: 100.371003, lat: 13.625583 },
    { lng: 100.371347, lat: 13.624206 },
    { lng: 100.371956, lat: 13.621829 },
    { lng: 100.374682, lat: 13.622315 },
    { lng: 100.376808, lat: 13.622762 },
    { lng: 100.377988, lat: 13.62302 },
    { lng: 100.378787, lat: 13.623208 },
    { lng: 100.379424, lat: 13.622262 },
    { lng: 100.413159, lat: 13.652506 },
    { lng: 100.429702, lat: 13.667305 },
    { lng: 100.430751, lat: 13.668247 },
    { lng: 100.431828, lat: 13.669205 },
    { lng: 100.433975, lat: 13.671136 },
    { lng: 100.438307, lat: 13.675009 },
    { lng: 100.446934, lat: 13.682718 },
    { lng: 100.450736, lat: 13.685716 },
    { lng: 100.454624, lat: 13.688864 },
    { lng: 100.457092, lat: 13.690939 },
    { lng: 100.457319, lat: 13.691225 },
    { lng: 100.457821, lat: 13.691988 },
    { lng: 100.458602, lat: 13.693325 },
    { lng: 100.461721, lat: 13.698641 },
    { lng: 100.463234, lat: 13.697908 },
    { lng: 100.465023, lat: 13.70134 },
    { lng: 100.465237, lat: 13.702075 },
  ],
  zone4: [
    { lng: 100.511456, lat: 13.717187 },
    { lng: 100.508882, lat: 13.718282 },
    { lng: 100.507545, lat: 13.718765 },
    { lng: 100.506908, lat: 13.718848 },
    { lng: 100.50624, lat: 13.719045 },
    { lng: 100.503632, lat: 13.720343 },
    { lng: 100.492467, lat: 13.719316 },
    { lng: 100.49309, lat: 13.714341 },
    { lng: 100.488449, lat: 13.703441 },
    { lng: 100.48296, lat: 13.70643 },
    { lng: 100.480881, lat: 13.704532 },
    { lng: 100.478838, lat: 13.701623 },
    { lng: 100.477978, lat: 13.704671 },
    { lng: 100.472914, lat: 13.702951 },
    { lng: 100.472753, lat: 13.702607 },
    { lng: 100.472844, lat: 13.702534 },
    { lng: 100.472935, lat: 13.702768 },
    { lng: 100.473246, lat: 13.702346 },
    { lng: 100.472082, lat: 13.699714 },
    { lng: 100.471348, lat: 13.698536 },
    { lng: 100.470398, lat: 13.698974 },
    { lng: 100.469325, lat: 13.69923 },
    { lng: 100.467319, lat: 13.69925 },
    { lng: 100.467378, lat: 13.699527 },
    { lng: 100.468447, lat: 13.701773 },
    { lng: 100.468347, lat: 13.701821 },
    { lng: 100.46789, lat: 13.702132 },
    { lng: 100.467799, lat: 13.702157 },
    { lng: 100.4675, lat: 13.702195 },
    { lng: 100.467008, lat: 13.702617 },
    { lng: 100.465432, lat: 13.702015 },
    { lng: 100.465237, lat: 13.702086 },
    { lng: 100.465023, lat: 13.701351 },
    { lng: 100.463234, lat: 13.697919 },
    { lng: 100.461719, lat: 13.698655 },
    { lng: 100.459748, lat: 13.69529 },
    { lng: 100.457865, lat: 13.692069 },
    { lng: 100.457319, lat: 13.691225 },
    { lng: 100.457038, lat: 13.690905 },
    { lng: 100.45504, lat: 13.689215 },
    { lng: 100.454405, lat: 13.688692 },
    { lng: 100.452812, lat: 13.68742 },
    { lng: 100.450736, lat: 13.685716 },
    { lng: 100.446934, lat: 13.682728 },
    { lng: 100.438586, lat: 13.67526 },
    { lng: 100.412097, lat: 13.651555 },
    { lng: 100.405799, lat: 13.645873 },
    { lng: 100.396186, lat: 13.637281 },
    { lng: 100.387804, lat: 13.629779 },
    { lng: 100.379424, lat: 13.622272 },
    { lng: 100.3774, lat: 13.620463 },
    { lng: 100.376461, lat: 13.619608 },
    { lng: 100.376241, lat: 13.618096 },
    { lng: 100.376799, lat: 13.606647 },
    { lng: 100.377164, lat: 13.606459 },
    { lng: 100.378054, lat: 13.603341 },
    { lng: 100.378489, lat: 13.601907 },
    { lng: 100.378478, lat: 13.601751 },
    { lng: 100.378376, lat: 13.601448 },
    { lng: 100.378387, lat: 13.601344 },
    { lng: 100.378478, lat: 13.60123 },
    { lng: 100.378532, lat: 13.601104 },
    { lng: 100.378516, lat: 13.601 },
    { lng: 100.378494, lat: 13.600745 },
    { lng: 100.378516, lat: 13.600552 },
    { lng: 100.378553, lat: 13.600333 },
    { lng: 100.378532, lat: 13.600187 },
    { lng: 100.378537, lat: 13.599931 },
    { lng: 100.378569, lat: 13.599134 },
    { lng: 100.378607, lat: 13.598972 },
    { lng: 100.378558, lat: 13.598852 },
    { lng: 100.378558, lat: 13.598722 },
    { lng: 100.378698, lat: 13.598518 },
    { lng: 100.378778, lat: 13.598273 },
    { lng: 100.378736, lat: 13.598158 },
    { lng: 100.37873, lat: 13.598086 },
    { lng: 100.378773, lat: 13.597997 },
    { lng: 100.3788, lat: 13.597872 },
    { lng: 100.378805, lat: 13.597741 },
    { lng: 100.378912, lat: 13.597564 },
    { lng: 100.37903, lat: 13.597486 },
    { lng: 100.37903, lat: 13.597439 },
    { lng: 100.378955, lat: 13.597309 },
    { lng: 100.378923, lat: 13.597147 },
    { lng: 100.378961, lat: 13.597074 },
    { lng: 100.379047, lat: 13.596959 },
    { lng: 100.379052, lat: 13.59686 },
    { lng: 100.378982, lat: 13.596745 },
    { lng: 100.378982, lat: 13.596688 },
    { lng: 100.3791, lat: 13.596506 },
    { lng: 100.379116, lat: 13.596453 },
    { lng: 100.379063, lat: 13.59637 },
    { lng: 100.379014, lat: 13.596313 },
    { lng: 100.378993, lat: 13.596193 },
    { lng: 100.378998, lat: 13.595968 },
    { lng: 100.3791, lat: 13.595864 },
    { lng: 100.378934, lat: 13.595254 },
    { lng: 100.378854, lat: 13.595202 },
    { lng: 100.378784, lat: 13.595129 },
    { lng: 100.378725, lat: 13.595082 },
    { lng: 100.378725, lat: 13.59502 },
    { lng: 100.378902, lat: 13.594863 },
    { lng: 100.378918, lat: 13.594806 },
    { lng: 100.378879, lat: 13.594715 },
    { lng: 100.378809, lat: 13.594692 },
    { lng: 100.378785, lat: 13.594672 },
    { lng: 100.378786, lat: 13.594579 },
    { lng: 100.378806, lat: 13.594543 },
    { lng: 100.378863, lat: 13.594486 },
    { lng: 100.378865, lat: 13.594456 },
    { lng: 100.378857, lat: 13.594432 },
    { lng: 100.37882, lat: 13.594389 },
    { lng: 100.378759, lat: 13.594374 },
    { lng: 100.378573, lat: 13.594375 },
    { lng: 100.378534, lat: 13.594354 },
    { lng: 100.378517, lat: 13.594294 },
    { lng: 100.378521, lat: 13.594252 },
    { lng: 100.378542, lat: 13.594207 },
    { lng: 100.378609, lat: 13.594117 },
    { lng: 100.378662, lat: 13.594071 },
    { lng: 100.378694, lat: 13.593971 },
    { lng: 100.378715, lat: 13.593907 },
    { lng: 100.378754, lat: 13.593838 },
    { lng: 100.378778, lat: 13.593758 },
    { lng: 100.378812, lat: 13.593588 },
    { lng: 100.37883, lat: 13.593547 },
    { lng: 100.378855, lat: 13.593508 },
    { lng: 100.378892, lat: 13.593428 },
    { lng: 100.378958, lat: 13.593318 },
    { lng: 100.378993, lat: 13.593281 },
    { lng: 100.379057, lat: 13.593266 },
    { lng: 100.379116, lat: 13.593278 },
    { lng: 100.37914, lat: 13.593296 },
    { lng: 100.379237, lat: 13.593324 },
    { lng: 100.379285, lat: 13.593323 },
    { lng: 100.379335, lat: 13.593285 },
    { lng: 100.379451, lat: 13.59311 },
    { lng: 100.379497, lat: 13.59307 },
    { lng: 100.379591, lat: 13.593009 },
    { lng: 100.379638, lat: 13.592955 },
    { lng: 100.379667, lat: 13.592876 },
    { lng: 100.379693, lat: 13.592749 },
    { lng: 100.379713, lat: 13.592723 },
    { lng: 100.379953, lat: 13.592667 },
    { lng: 100.379984, lat: 13.592641 },
    { lng: 100.380008, lat: 13.592598 },
    { lng: 100.380005, lat: 13.592374 },
    { lng: 100.380019, lat: 13.59233 },
    { lng: 100.380025, lat: 13.592302 },
    { lng: 100.380071, lat: 13.592263 },
    { lng: 100.380141, lat: 13.592229 },
    { lng: 100.380363, lat: 13.592057 },
    { lng: 100.380587, lat: 13.591919 },
    { lng: 100.380726, lat: 13.59173 },
    { lng: 100.380764, lat: 13.591699 },
    { lng: 100.380803, lat: 13.591691 },
    { lng: 100.380873, lat: 13.591693 },
    { lng: 100.380928, lat: 13.591702 },
    { lng: 100.38099, lat: 13.591704 },
    { lng: 100.381035, lat: 13.591691 },
    { lng: 100.381054, lat: 13.591671 },
    { lng: 100.381058, lat: 13.591658 },
    { lng: 100.381057, lat: 13.59161 },
    { lng: 100.381006, lat: 13.591443 },
    { lng: 100.381019, lat: 13.591364 },
    { lng: 100.381061, lat: 13.591313 },
    { lng: 100.38109, lat: 13.591304 },
    { lng: 100.381169, lat: 13.591317 },
    { lng: 100.381199, lat: 13.591336 },
    { lng: 100.381265, lat: 13.591347 },
    { lng: 100.381291, lat: 13.591322 },
    { lng: 100.381328, lat: 13.591287 },
    { lng: 100.381377, lat: 13.591252 },
    { lng: 100.381483, lat: 13.591206 },
    { lng: 100.381594, lat: 13.591155 },
    { lng: 100.381617, lat: 13.591126 },
    { lng: 100.381691, lat: 13.590951 },
    { lng: 100.381804, lat: 13.590883 },
    { lng: 100.3819, lat: 13.590846 },
    { lng: 100.382088, lat: 13.590789 },
    { lng: 100.382158, lat: 13.590727 },
    { lng: 100.38219, lat: 13.590575 },
    { lng: 100.38242, lat: 13.590377 },
    { lng: 100.382474, lat: 13.590273 },
    { lng: 100.382598, lat: 13.59019 },
    { lng: 100.382769, lat: 13.590242 },
    { lng: 100.383107, lat: 13.590111 },
    { lng: 100.383166, lat: 13.590038 },
    { lng: 100.38322, lat: 13.589898 },
    { lng: 100.383241, lat: 13.589783 },
    { lng: 100.383542, lat: 13.589616 },
    { lng: 100.383584, lat: 13.589543 },
    { lng: 100.383724, lat: 13.589501 },
    { lng: 100.383933, lat: 13.589579 },
    { lng: 100.384008, lat: 13.589564 },
    { lng: 100.384185, lat: 13.589522 },
    { lng: 100.384389, lat: 13.589626 },
    { lng: 100.384416, lat: 13.589585 },
    { lng: 100.384424, lat: 13.58954 },
    { lng: 100.384397, lat: 13.589413 },
    { lng: 100.384242, lat: 13.589342 },
    { lng: 100.384234, lat: 13.589295 },
    { lng: 100.38426, lat: 13.589272 },
    { lng: 100.384381, lat: 13.589277 },
    { lng: 100.384381, lat: 13.589255 },
    { lng: 100.384322, lat: 13.589168 },
    { lng: 100.384326, lat: 13.589148 },
    { lng: 100.384374, lat: 13.589097 },
    { lng: 100.384385, lat: 13.588966 },
    { lng: 100.384344, lat: 13.588745 },
    { lng: 100.384345, lat: 13.588677 },
    { lng: 100.384447, lat: 13.588509 },
    { lng: 100.384503, lat: 13.58828 },
    { lng: 100.384493, lat: 13.588212 },
    { lng: 100.384396, lat: 13.588093 },
    { lng: 100.384379, lat: 13.588051 },
    { lng: 100.384314, lat: 13.588008 },
    { lng: 100.384235, lat: 13.587969 },
    { lng: 100.384207, lat: 13.587903 },
    { lng: 100.384188, lat: 13.587796 },
    { lng: 100.384204, lat: 13.58771 },
    { lng: 100.384227, lat: 13.587654 },
    { lng: 100.384371, lat: 13.587527 },
    { lng: 100.384492, lat: 13.587451 },
    { lng: 100.384562, lat: 13.587399 },
    { lng: 100.384599, lat: 13.587398 },
    { lng: 100.384705, lat: 13.587443 },
    { lng: 100.384734, lat: 13.58748 },
    { lng: 100.384829, lat: 13.587525 },
    { lng: 100.384914, lat: 13.58755 },
    { lng: 100.385013, lat: 13.587553 },
    { lng: 100.385103, lat: 13.587515 },
    { lng: 100.385138, lat: 13.587484 },
    { lng: 100.385151, lat: 13.587465 },
    { lng: 100.385162, lat: 13.587434 },
    { lng: 100.385166, lat: 13.587409 },
    { lng: 100.385167, lat: 13.587388 },
    { lng: 100.385145, lat: 13.587344 },
    { lng: 100.385098, lat: 13.587325 },
    { lng: 100.385016, lat: 13.5873 },
    { lng: 100.384906, lat: 13.587237 },
    { lng: 100.384872, lat: 13.587216 },
    { lng: 100.384808, lat: 13.58716 },
    { lng: 100.384782, lat: 13.587099 },
    { lng: 100.384786, lat: 13.587035 },
    { lng: 100.384792, lat: 13.587001 },
    { lng: 100.384856, lat: 13.586889 },
    { lng: 100.384927, lat: 13.586816 },
    { lng: 100.385167, lat: 13.586638 },
    { lng: 100.38532, lat: 13.586558 },
    { lng: 100.385358, lat: 13.586518 },
    { lng: 100.385374, lat: 13.586488 },
    { lng: 100.385366, lat: 13.586459 },
    { lng: 100.385319, lat: 13.586383 },
    { lng: 100.385284, lat: 13.58636 },
    { lng: 100.385008, lat: 13.586321 },
    { lng: 100.38491, lat: 13.586253 },
    { lng: 100.384884, lat: 13.586184 },
    { lng: 100.384875, lat: 13.586116 },
    { lng: 100.38489, lat: 13.586068 },
    { lng: 100.384965, lat: 13.586051 },
    { lng: 100.385063, lat: 13.586046 },
    { lng: 100.385185, lat: 13.586046 },
    { lng: 100.385259, lat: 13.586047 },
    { lng: 100.385361, lat: 13.586036 },
    { lng: 100.38546, lat: 13.586018 },
    { lng: 100.385547, lat: 13.585998 },
    { lng: 100.385628, lat: 13.585959 },
    { lng: 100.385686, lat: 13.585926 },
    { lng: 100.385726, lat: 13.58588 },
    { lng: 100.385735, lat: 13.585851 },
    { lng: 100.385722, lat: 13.585816 },
    { lng: 100.385693, lat: 13.585794 },
    { lng: 100.385389, lat: 13.58568 },
    { lng: 100.385227, lat: 13.585595 },
    { lng: 100.385198, lat: 13.585564 },
    { lng: 100.385183, lat: 13.585531 },
    { lng: 100.385165, lat: 13.585452 },
    { lng: 100.385169, lat: 13.585425 },
    { lng: 100.385193, lat: 13.585389 },
    { lng: 100.385224, lat: 13.585363 },
    { lng: 100.385292, lat: 13.585314 },
    { lng: 100.38534, lat: 13.585299 },
    { lng: 100.3854, lat: 13.585296 },
    { lng: 100.385434, lat: 13.585311 },
    { lng: 100.385457, lat: 13.585331 },
    { lng: 100.385514, lat: 13.585402 },
    { lng: 100.385538, lat: 13.585421 },
    { lng: 100.385569, lat: 13.585447 },
    { lng: 100.385624, lat: 13.585477 },
    { lng: 100.385676, lat: 13.585494 },
    { lng: 100.385724, lat: 13.585497 },
    { lng: 100.385761, lat: 13.585496 },
    { lng: 100.38582, lat: 13.585484 },
    { lng: 100.385851, lat: 13.585461 },
    { lng: 100.385861, lat: 13.585404 },
    { lng: 100.385836, lat: 13.585352 },
    { lng: 100.385786, lat: 13.585317 },
    { lng: 100.385734, lat: 13.585271 },
    { lng: 100.38568, lat: 13.585176 },
    { lng: 100.385665, lat: 13.585086 },
    { lng: 100.385689, lat: 13.58506 },
    { lng: 100.385971, lat: 13.585051 },
    { lng: 100.385988, lat: 13.585036 },
    { lng: 100.386005, lat: 13.584984 },
    { lng: 100.385997, lat: 13.584941 },
    { lng: 100.385794, lat: 13.58458 },
    { lng: 100.385757, lat: 13.584496 },
    { lng: 100.385576, lat: 13.584302 },
    { lng: 100.385544, lat: 13.584262 },
    { lng: 100.385479, lat: 13.584075 },
    { lng: 100.385462, lat: 13.583975 },
    { lng: 100.385451, lat: 13.583941 },
    { lng: 100.385416, lat: 13.583904 },
    { lng: 100.385242, lat: 13.583828 },
    { lng: 100.385217, lat: 13.583788 },
    { lng: 100.385211, lat: 13.583756 },
    { lng: 100.385225, lat: 13.583712 },
    { lng: 100.385263, lat: 13.583649 },
    { lng: 100.385412, lat: 13.583219 },
    { lng: 100.385409, lat: 13.583107 },
    { lng: 100.385431, lat: 13.583017 },
    { lng: 100.385416, lat: 13.582893 },
    { lng: 100.385209, lat: 13.58271 },
    { lng: 100.385174, lat: 13.582671 },
    { lng: 100.385176, lat: 13.58262 },
    { lng: 100.385194, lat: 13.582585 },
    { lng: 100.385222, lat: 13.58256 },
    { lng: 100.385276, lat: 13.582527 },
    { lng: 100.385537, lat: 13.58246 },
    { lng: 100.385564, lat: 13.582448 },
    { lng: 100.385595, lat: 13.582364 },
    { lng: 100.385677, lat: 13.582058 },
    { lng: 100.385643, lat: 13.581992 },
    { lng: 100.38559, lat: 13.581942 },
    { lng: 100.385535, lat: 13.581866 },
    { lng: 100.385533, lat: 13.581824 },
    { lng: 100.385548, lat: 13.581789 },
    { lng: 100.385704, lat: 13.581672 },
    { lng: 100.38575, lat: 13.581618 },
    { lng: 100.385765, lat: 13.581538 },
    { lng: 100.385762, lat: 13.581502 },
    { lng: 100.385753, lat: 13.581468 },
    { lng: 100.385719, lat: 13.581437 },
    { lng: 100.38566, lat: 13.581406 },
    { lng: 100.385556, lat: 13.581369 },
    { lng: 100.385525, lat: 13.581355 },
    { lng: 100.38549, lat: 13.581324 },
    { lng: 100.385474, lat: 13.581285 },
    { lng: 100.385413, lat: 13.581095 },
    { lng: 100.38535, lat: 13.581019 },
    { lng: 100.385307, lat: 13.580989 },
    { lng: 100.385249, lat: 13.580965 },
    { lng: 100.385169, lat: 13.580948 },
    { lng: 100.385065, lat: 13.580913 },
    { lng: 100.385053, lat: 13.580876 },
    { lng: 100.385072, lat: 13.580795 },
    { lng: 100.385119, lat: 13.580712 },
    { lng: 100.385154, lat: 13.580525 },
    { lng: 100.385154, lat: 13.580451 },
    { lng: 100.385138, lat: 13.580338 },
    { lng: 100.385156, lat: 13.580304 },
    { lng: 100.385179, lat: 13.580287 },
    { lng: 100.385432, lat: 13.580226 },
    { lng: 100.385561, lat: 13.580157 },
    { lng: 100.385575, lat: 13.580128 },
    { lng: 100.385582, lat: 13.580076 },
    { lng: 100.385574, lat: 13.580044 },
    { lng: 100.385558, lat: 13.580004 },
    { lng: 100.385522, lat: 13.579967 },
    { lng: 100.385463, lat: 13.579932 },
    { lng: 100.385407, lat: 13.579907 },
    { lng: 100.385372, lat: 13.57988 },
    { lng: 100.385318, lat: 13.579791 },
    { lng: 100.38519, lat: 13.579436 },
    { lng: 100.385144, lat: 13.579385 },
    { lng: 100.385048, lat: 13.579323 },
    { lng: 100.384995, lat: 13.579198 },
    { lng: 100.385, lat: 13.579109 },
    { lng: 100.385097, lat: 13.578914 },
    { lng: 100.385287, lat: 13.578653 },
    { lng: 100.385349, lat: 13.578624 },
    { lng: 100.385443, lat: 13.578619 },
    { lng: 100.385491, lat: 13.578601 },
    { lng: 100.385622, lat: 13.578387 },
    { lng: 100.385708, lat: 13.578364 },
    { lng: 100.385885, lat: 13.578387 },
    { lng: 100.385993, lat: 13.578345 },
    { lng: 100.38607, lat: 13.578197 },
    { lng: 100.386151, lat: 13.57815 },
    { lng: 100.386239, lat: 13.57815 },
    { lng: 100.386325, lat: 13.578116 },
    { lng: 100.386465, lat: 13.578058 },
    { lng: 100.386636, lat: 13.57786 },
    { lng: 100.386655, lat: 13.577759 },
    { lng: 100.386655, lat: 13.577597 },
    { lng: 100.386687, lat: 13.577339 },
    { lng: 100.386797, lat: 13.577018 },
    { lng: 100.386942, lat: 13.576684 },
    { lng: 100.386926, lat: 13.576609 },
    { lng: 100.38688, lat: 13.57658 },
    { lng: 100.386669, lat: 13.576455 },
    { lng: 100.386462, lat: 13.576385 },
    { lng: 100.386256, lat: 13.576267 },
    { lng: 100.386218, lat: 13.576189 },
    { lng: 100.38621, lat: 13.576126 },
    { lng: 100.386218, lat: 13.576004 },
    { lng: 100.386344, lat: 13.575774 },
    { lng: 100.386451, lat: 13.575636 },
    { lng: 100.3865, lat: 13.575576 },
    { lng: 100.386567, lat: 13.575542 },
    { lng: 100.38665, lat: 13.575553 },
    { lng: 100.386864, lat: 13.575516 },
    { lng: 100.387004, lat: 13.575464 },
    { lng: 100.387071, lat: 13.575362 },
    { lng: 100.387068, lat: 13.575276 },
    { lng: 100.386797, lat: 13.574951 },
    { lng: 100.386722, lat: 13.57488 },
    { lng: 100.38665, lat: 13.574786 },
    { lng: 100.38665, lat: 13.574614 },
    { lng: 100.386717, lat: 13.574494 },
    { lng: 100.386832, lat: 13.574369 },
    { lng: 100.386848, lat: 13.574338 },
    { lng: 100.38687, lat: 13.574176 },
    { lng: 100.386905, lat: 13.574127 },
    { lng: 100.38698, lat: 13.574106 },
    { lng: 100.387087, lat: 13.574129 },
    { lng: 100.387146, lat: 13.574171 },
    { lng: 100.387245, lat: 13.57426 },
    { lng: 100.387379, lat: 13.574275 },
    { lng: 100.38798, lat: 13.57427 },
    { lng: 100.388015, lat: 13.574239 },
    { lng: 100.388018, lat: 13.574148 },
    { lng: 100.387959, lat: 13.574069 },
    { lng: 100.387661, lat: 13.573801 },
    { lng: 100.387532, lat: 13.573657 },
    { lng: 100.387511, lat: 13.573504 },
    { lng: 100.387707, lat: 13.573172 },
    { lng: 100.387766, lat: 13.572912 },
    { lng: 100.387943, lat: 13.572565 },
    { lng: 100.388022, lat: 13.572466 },
    { lng: 100.388054, lat: 13.572458 },
    { lng: 100.388135, lat: 13.572451 },
    { lng: 100.388295, lat: 13.572485 },
    { lng: 100.38836, lat: 13.572485 },
    { lng: 100.388557, lat: 13.572503 },
    { lng: 100.388909, lat: 13.5725 },
    { lng: 100.388984, lat: 13.57213 },
    { lng: 100.389009, lat: 13.571995 },
    { lng: 100.389099, lat: 13.571881 },
    { lng: 100.389179, lat: 13.571782 },
    { lng: 100.389237, lat: 13.571712 },
    { lng: 100.389306, lat: 13.571608 },
    { lng: 100.389332, lat: 13.571572 },
    { lng: 100.389467, lat: 13.571365 },
    { lng: 100.389495, lat: 13.571205 },
    { lng: 100.389495, lat: 13.570939 },
    { lng: 100.389438, lat: 13.570799 },
    { lng: 100.389388, lat: 13.570729 },
    { lng: 100.389322, lat: 13.570653 },
    { lng: 100.389286, lat: 13.570571 },
    { lng: 100.389271, lat: 13.570516 },
    { lng: 100.38926, lat: 13.570466 },
    { lng: 100.389283, lat: 13.570335 },
    { lng: 100.389332, lat: 13.570155 },
    { lng: 100.389366, lat: 13.56993 },
    { lng: 100.389369, lat: 13.569907 },
    { lng: 100.389398, lat: 13.569823 },
    { lng: 100.389436, lat: 13.569748 },
    { lng: 100.389509, lat: 13.569636 },
    { lng: 100.389577, lat: 13.569584 },
    { lng: 100.389701, lat: 13.569528 },
    { lng: 100.389782, lat: 13.569502 },
    { lng: 100.39033, lat: 13.569467 },
    { lng: 100.390382, lat: 13.569462 },
    { lng: 100.390421, lat: 13.56945 },
    { lng: 100.390466, lat: 13.569409 },
    { lng: 100.390497, lat: 13.56936 },
    { lng: 100.390514, lat: 13.569306 },
    { lng: 100.390544, lat: 13.569157 },
    { lng: 100.390576, lat: 13.569037 },
    { lng: 100.390602, lat: 13.568988 },
    { lng: 100.390636, lat: 13.568954 },
    { lng: 100.390706, lat: 13.568937 },
    { lng: 100.39078, lat: 13.56893 },
    { lng: 100.391047, lat: 13.568934 },
    { lng: 100.391299, lat: 13.568968 },
    { lng: 100.391376, lat: 13.568971 },
    { lng: 100.39141, lat: 13.568965 },
    { lng: 100.391459, lat: 13.568928 },
    { lng: 100.391522, lat: 13.568835 },
    { lng: 100.39154, lat: 13.568776 },
    { lng: 100.391542, lat: 13.568537 },
    { lng: 100.391533, lat: 13.568333 },
    { lng: 100.391524, lat: 13.568139 },
    { lng: 100.391457, lat: 13.568024 },
    { lng: 100.391331, lat: 13.567896 },
    { lng: 100.391245, lat: 13.567847 },
    { lng: 100.390773, lat: 13.567704 },
    { lng: 100.390661, lat: 13.567618 },
    { lng: 100.390645, lat: 13.567521 },
    { lng: 100.390811, lat: 13.566906 },
    { lng: 100.390819, lat: 13.566854 },
    { lng: 100.390979, lat: 13.566358 },
    { lng: 100.390992, lat: 13.566275 },
    { lng: 100.390992, lat: 13.566223 },
    { lng: 100.390976, lat: 13.566049 },
    { lng: 100.390844, lat: 13.565546 },
    { lng: 100.390839, lat: 13.565496 },
    { lng: 100.390852, lat: 13.565391 },
    { lng: 100.390961, lat: 13.565322 },
    { lng: 100.39103, lat: 13.565292 },
    { lng: 100.391164, lat: 13.565172 },
    { lng: 100.391189, lat: 13.56512 },
    { lng: 100.391205, lat: 13.564992 },
    { lng: 100.391208, lat: 13.564862 },
    { lng: 100.391202, lat: 13.564809 },
    { lng: 100.391216, lat: 13.564743 },
    { lng: 100.391266, lat: 13.564538 },
    { lng: 100.39133, lat: 13.564367 },
    { lng: 100.391366, lat: 13.564292 },
    { lng: 100.391376, lat: 13.56416 },
    { lng: 100.39126, lat: 13.563422 },
    { lng: 100.391266, lat: 13.563224 },
    { lng: 100.391343, lat: 13.563159 },
    { lng: 100.392092, lat: 13.563133 },
    { lng: 100.392178, lat: 13.563102 },
    { lng: 100.392234, lat: 13.563039 },
    { lng: 100.392354, lat: 13.562557 },
    { lng: 100.393217, lat: 13.561318 },
    { lng: 100.392622, lat: 13.560979 },
    { lng: 100.393115, lat: 13.559164 },
    { lng: 100.393437, lat: 13.558517 },
    { lng: 100.394306, lat: 13.558048 },
    { lng: 100.394013, lat: 13.557358 },
    { lng: 100.39402, lat: 13.556648 },
    { lng: 100.394338, lat: 13.555844 },
    { lng: 100.394787, lat: 13.555671 },
    { lng: 100.394626, lat: 13.555042 },
    { lng: 100.395167, lat: 13.554551 },
    { lng: 100.395279, lat: 13.55383 },
    { lng: 100.395869, lat: 13.553475 },
    { lng: 100.396626, lat: 13.553402 },
    { lng: 100.39709, lat: 13.553063 },
    { lng: 100.398218, lat: 13.552993 },
    { lng: 100.398787, lat: 13.553193 },
    { lng: 100.399082, lat: 13.552917 },
    { lng: 100.399498, lat: 13.552758 },
    { lng: 100.400135, lat: 13.553023 },
    { lng: 100.40098, lat: 13.552852 },
    { lng: 100.401766, lat: 13.553195 },
    { lng: 100.402403, lat: 13.553079 },
    { lng: 100.403476, lat: 13.553506 },
    { lng: 100.404088, lat: 13.554325 },
    { lng: 100.404248, lat: 13.554956 },
    { lng: 100.405182, lat: 13.554987 },
    { lng: 100.405423, lat: 13.554617 },
    { lng: 100.406759, lat: 13.554414 },
    { lng: 100.407446, lat: 13.554748 },
    { lng: 100.408561, lat: 13.554518 },
    { lng: 100.408819, lat: 13.554622 },
    { lng: 100.409784, lat: 13.555092 },
    { lng: 100.410686, lat: 13.555013 },
    { lng: 100.411308, lat: 13.555475 },
    { lng: 100.411952, lat: 13.555467 },
    { lng: 100.412252, lat: 13.556036 },
    { lng: 100.412274, lat: 13.556716 },
    { lng: 100.412424, lat: 13.557209 },
    { lng: 100.413057, lat: 13.557063 },
    { lng: 100.413609, lat: 13.55724 },
    { lng: 100.414272, lat: 13.556922 },
    { lng: 100.415063, lat: 13.557126 },
    { lng: 100.415235, lat: 13.557084 },
    { lng: 100.415353, lat: 13.556468 },
    { lng: 100.415111, lat: 13.555806 },
    { lng: 100.414741, lat: 13.551368 },
    { lng: 100.416672, lat: 13.550283 },
    { lng: 100.419591, lat: 13.546737 },
    { lng: 100.420277, lat: 13.545026 },
    { lng: 100.419162, lat: 13.542064 },
    { lng: 100.41855, lat: 13.540771 },
    { lng: 100.418003, lat: 13.540145 },
    { lng: 100.416072, lat: 13.539603 },
    { lng: 100.415321, lat: 13.539154 },
    { lng: 100.414698, lat: 13.538518 },
    { lng: 100.414955, lat: 13.537882 },
    { lng: 100.414022, lat: 13.535754 },
    { lng: 100.407124, lat: 13.53576 },
    { lng: 100.406965, lat: 13.524311 },
    { lng: 100.406651, lat: 13.519164 },
    { lng: 100.406574, lat: 13.516187 },
    { lng: 100.406593, lat: 13.51175 },
    { lng: 100.40658, lat: 13.508502 },
    { lng: 100.406317, lat: 13.508345 },
    { lng: 100.406614, lat: 13.507839 },
    { lng: 100.407675, lat: 13.503278 },
    { lng: 100.409326, lat: 13.495597 },
    { lng: 100.431357, lat: 13.497408 },
    { lng: 100.442309, lat: 13.499825 },
    { lng: 100.453314, lat: 13.503828 },
    { lng: 100.448718, lat: 13.528779 },
    { lng: 100.446601, lat: 13.540629 },
    { lng: 100.444528, lat: 13.553824 },
    { lng: 100.44596, lat: 13.573395 },
    { lng: 100.446676, lat: 13.583014 },
    { lng: 100.446276, lat: 13.584956 },
    { lng: 100.44664, lat: 13.587017 },
    { lng: 100.447756, lat: 13.587685 },
    { lng: 100.447413, lat: 13.588853 },
    { lng: 100.449387, lat: 13.590521 },
    { lng: 100.449044, lat: 13.592106 },
    { lng: 100.449902, lat: 13.592524 },
    { lng: 100.451275, lat: 13.595861 },
    { lng: 100.450932, lat: 13.596778 },
    { lng: 100.452048, lat: 13.598197 },
    { lng: 100.452649, lat: 13.602368 },
    { lng: 100.453335, lat: 13.603077 },
    { lng: 100.454194, lat: 13.605621 },
    { lng: 100.457541, lat: 13.604579 },
    { lng: 100.463335, lat: 13.605976 },
    { lng: 100.466296, lat: 13.603953 },
    { lng: 100.465781, lat: 13.601826 },
    { lng: 100.467154, lat: 13.59803 },
    { lng: 100.467154, lat: 13.596987 },
    { lng: 100.469643, lat: 13.592774 },
    { lng: 100.473591, lat: 13.593775 },
    { lng: 100.473162, lat: 13.599114 },
    { lng: 100.475008, lat: 13.601575 },
    { lng: 100.476853, lat: 13.602868 },
    { lng: 100.477797, lat: 13.601617 },
    { lng: 100.476939, lat: 13.599281 },
    { lng: 100.478827, lat: 13.59828 },
    { lng: 100.480715, lat: 13.598197 },
    { lng: 100.482003, lat: 13.59269 },
    { lng: 100.48844, lat: 13.58927 },
    { lng: 100.490929, lat: 13.586267 },
    { lng: 100.49382, lat: 13.58733 },
    { lng: 100.493992, lat: 13.587789 },
    { lng: 100.495022, lat: 13.587726 },
    { lng: 100.496224, lat: 13.588268 },
    { lng: 100.499056, lat: 13.591877 },
    { lng: 100.499185, lat: 13.593566 },
    { lng: 100.499292, lat: 13.594817 },
    { lng: 100.499678, lat: 13.595589 },
    { lng: 100.500644, lat: 13.595422 },
    { lng: 100.502897, lat: 13.596528 },
    { lng: 100.504549, lat: 13.597696 },
    { lng: 100.50603, lat: 13.597716 },
    { lng: 100.507146, lat: 13.597383 },
    { lng: 100.507854, lat: 13.597612 },
    { lng: 100.511501, lat: 13.596882 },
    { lng: 100.513964, lat: 13.602785 },
    { lng: 100.520401, lat: 13.605079 },
    { lng: 100.520916, lat: 13.605454 },
    { lng: 100.520358, lat: 13.609042 },
    { lng: 100.518663, lat: 13.610251 },
    { lng: 100.517859, lat: 13.611774 },
    { lng: 100.516367, lat: 13.612796 },
    { lng: 100.516925, lat: 13.61413 },
    { lng: 100.516206, lat: 13.619719 },
    { lng: 100.515058, lat: 13.62439 },
    { lng: 100.514865, lat: 13.62927 },
    { lng: 100.518084, lat: 13.63465 },
    { lng: 100.517054, lat: 13.635902 },
    { lng: 100.516711, lat: 13.637653 },
    { lng: 100.515552, lat: 13.638904 },
    { lng: 100.515337, lat: 13.640156 },
    { lng: 100.517011, lat: 13.647746 },
    { lng: 100.516818, lat: 13.653417 },
    { lng: 100.517612, lat: 13.667179 },
    { lng: 100.520009, lat: 13.668236 },
    { lng: 100.520494, lat: 13.668337 },
    { lng: 100.520517, lat: 13.668599 },
    { lng: 100.520597, lat: 13.66872 },
    { lng: 100.520729, lat: 13.668849 },
    { lng: 100.520761, lat: 13.669028 },
    { lng: 100.520809, lat: 13.66909 },
    { lng: 100.521099, lat: 13.66913 },
    { lng: 100.521093, lat: 13.669388 },
    { lng: 100.520954, lat: 13.669475 },
    { lng: 100.520841, lat: 13.669472 },
    { lng: 100.520726, lat: 13.66952 },
    { lng: 100.52066, lat: 13.6696 },
    { lng: 100.52073, lat: 13.669894 },
    { lng: 100.520792, lat: 13.669982 },
    { lng: 100.520768, lat: 13.670084 },
    { lng: 100.520648, lat: 13.670134 },
    { lng: 100.520604, lat: 13.670237 },
    { lng: 100.520603, lat: 13.670341 },
    { lng: 100.520629, lat: 13.670419 },
    { lng: 100.520696, lat: 13.670578 },
    { lng: 100.520691, lat: 13.670688 },
    { lng: 100.520643, lat: 13.670758 },
    { lng: 100.520445, lat: 13.670938 },
    { lng: 100.52043, lat: 13.671018 },
    { lng: 100.520469, lat: 13.671156 },
    { lng: 100.5206, lat: 13.671281 },
    { lng: 100.520697, lat: 13.671297 },
    { lng: 100.52095, lat: 13.671412 },
    { lng: 100.521022, lat: 13.671522 },
    { lng: 100.52103, lat: 13.671727 },
    { lng: 100.521085, lat: 13.671839 },
    { lng: 100.521174, lat: 13.671932 },
    { lng: 100.521458, lat: 13.671948 },
    { lng: 100.521555, lat: 13.672 },
    { lng: 100.521603, lat: 13.672178 },
    { lng: 100.521565, lat: 13.672419 },
    { lng: 100.521537, lat: 13.672457 },
    { lng: 100.521525, lat: 13.672564 },
    { lng: 100.521586, lat: 13.672684 },
    { lng: 100.521616, lat: 13.672889 },
    { lng: 100.521603, lat: 13.6731 },
    { lng: 100.52168, lat: 13.673282 },
    { lng: 100.521742, lat: 13.673355 },
    { lng: 100.521902, lat: 13.673428 },
    { lng: 100.521982, lat: 13.673506 },
    { lng: 100.522023, lat: 13.673615 },
    { lng: 100.521997, lat: 13.673738 },
    { lng: 100.521914, lat: 13.673788 },
    { lng: 100.521882, lat: 13.673917 },
    { lng: 100.521984, lat: 13.674223 },
    { lng: 100.521987, lat: 13.674428 },
    { lng: 100.521887, lat: 13.674692 },
    { lng: 100.52185, lat: 13.674945 },
    { lng: 100.521726, lat: 13.675091 },
    { lng: 100.521543, lat: 13.675122 },
    { lng: 100.521167, lat: 13.675022 },
    { lng: 100.520914, lat: 13.675061 },
    { lng: 100.520455, lat: 13.675156 },
    { lng: 100.520381, lat: 13.675547 },
    { lng: 100.520409, lat: 13.675709 },
    { lng: 100.520487, lat: 13.67581 },
    { lng: 100.522976, lat: 13.67777 },
    { lng: 100.526152, lat: 13.674768 },
    { lng: 100.531602, lat: 13.671098 },
    { lng: 100.537052, lat: 13.669222 },
    { lng: 100.541387, lat: 13.669264 },
    { lng: 100.546837, lat: 13.671641 },
    { lng: 100.549369, lat: 13.67456 },
    { lng: 100.5507, lat: 13.678104 },
    { lng: 100.551687, lat: 13.682899 },
    { lng: 100.55203, lat: 13.68686 },
    { lng: 100.551998, lat: 13.688867 },
    { lng: 100.551858, lat: 13.69054 },
    { lng: 100.551169, lat: 13.694796 },
    { lng: 100.548578, lat: 13.6949 },
    { lng: 100.547319, lat: 13.695101 },
    { lng: 100.546748, lat: 13.695468 },
    { lng: 100.546174, lat: 13.695706 },
    { lng: 100.544603, lat: 13.696898 },
    { lng: 100.54299, lat: 13.69855 },
    { lng: 100.541674, lat: 13.70023 },
    { lng: 100.539758, lat: 13.702997 },
    { lng: 100.537502, lat: 13.707158 },
    { lng: 100.536263, lat: 13.706455 },
    { lng: 100.535911, lat: 13.706253 },
    { lng: 100.535636, lat: 13.7061 },
    { lng: 100.535007, lat: 13.705766 },
    { lng: 100.534072, lat: 13.705539 },
    { lng: 100.533147, lat: 13.705401 },
    { lng: 100.53128, lat: 13.705527 },
    { lng: 100.530441, lat: 13.705623 },
    { lng: 100.527601, lat: 13.706134 },
    { lng: 100.526972, lat: 13.706246 },
    { lng: 100.526723, lat: 13.706291 },
    { lng: 100.526333, lat: 13.706368 },
    { lng: 100.525562, lat: 13.706513 },
    { lng: 100.524985, lat: 13.706624 },
    { lng: 100.524706, lat: 13.706677 },
    { lng: 100.517862, lat: 13.707988 },
    { lng: 100.513898, lat: 13.709146 },
    { lng: 100.512645, lat: 13.709629 },
    { lng: 100.512014, lat: 13.709874 },
    { lng: 100.511701, lat: 13.709992 },
    { lng: 100.511388, lat: 13.710118 },
    { lng: 100.511221, lat: 13.710119 },
    { lng: 100.510972, lat: 13.71019 },
    { lng: 100.510844, lat: 13.710267 },
    { lng: 100.510712, lat: 13.710348 },
    { lng: 100.510889, lat: 13.710857 },
    { lng: 100.510394, lat: 13.711058 },
    { lng: 100.509748, lat: 13.711216 },
    { lng: 100.5093, lat: 13.711405 },
    { lng: 100.507946, lat: 13.712114 },
    { lng: 100.511456, lat: 13.717187 },
  ],
  zone5: [
    { lng: 100.584457, lat: 13.6665327 },
    { lng: 100.592188, lat: 13.6637107 },
    { lng: 100.593128, lat: 13.6637947 },
    { lng: 100.593204, lat: 13.6634717 },
    { lng: 100.595758, lat: 13.6624087 },
    { lng: 100.595483, lat: 13.6610817 },
    { lng: 100.596206, lat: 13.6607017 },
    { lng: 100.597234, lat: 13.6605377 },
    { lng: 100.598402, lat: 13.6600497 },
    { lng: 100.599289, lat: 13.6597297 },
    { lng: 100.600497, lat: 13.6594517 },
    { lng: 100.600236, lat: 13.6581777 },
    { lng: 100.60094, lat: 13.6578947 },
    { lng: 100.600772, lat: 13.6574487 },
    { lng: 100.601433, lat: 13.6571817 },
    { lng: 100.602057, lat: 13.6587227 },
    { lng: 100.604216, lat: 13.6579387 },
    { lng: 100.604437, lat: 13.6582557 },
    { lng: 100.604805, lat: 13.6581427 },
    { lng: 100.605225, lat: 13.6592327 },
    { lng: 100.607491, lat: 13.6585717 },
    { lng: 100.607158, lat: 13.6570297 },
    { lng: 100.61013, lat: 13.6555277 },
    { lng: 100.613777, lat: 13.6541317 },
    { lng: 100.614212, lat: 13.6543637 },
    { lng: 100.615848, lat: 13.6539277 },
    { lng: 100.616116, lat: 13.6553507 },
    { lng: 100.617039, lat: 13.6572797 },
    { lng: 100.619142, lat: 13.6565607 },
    { lng: 100.618712, lat: 13.6550077 },
    { lng: 100.621523, lat: 13.6541557 },
    { lng: 100.625244, lat: 13.6527217 },
    { lng: 100.630589, lat: 13.6510257 },
    { lng: 100.630096, lat: 13.6494127 },
    { lng: 100.635169, lat: 13.6476407 },
    { lng: 100.640587, lat: 13.6548347 },
    { lng: 100.646102, lat: 13.6533728 },
    { lng: 100.6487823, lat: 13.6526628 },
    { lng: 100.6500785, lat: 13.652581 },
    { lng: 100.6503818, lat: 13.6566659 },
    { lng: 100.6506072, lat: 13.6586497 },
    { lng: 100.6508756, lat: 13.6605501 },
    { lng: 100.6515328, lat: 13.6621953 },
    { lng: 100.6522317, lat: 13.6637558 },
    { lng: 100.652333, lat: 13.6642263 },
    { lng: 100.6524142, lat: 13.6646276 },
    { lng: 100.6527424, lat: 13.665784 },
    { lng: 100.6530868, lat: 13.6668727 },
    { lng: 100.6532018, lat: 13.6673889 },
    { lng: 100.6535635, lat: 13.668145 },
    { lng: 100.6539545, lat: 13.6693964 },
    { lng: 100.6541657, lat: 13.6698959 },
    { lng: 100.6548812, lat: 13.6711043 },
    { lng: 100.6554108, lat: 13.6722805 },
    { lng: 100.6555109, lat: 13.6722736 },
    { lng: 100.6563136, lat: 13.6720268 },
    { lng: 100.6572049, lat: 13.671655 },
    { lng: 100.6575164, lat: 13.6715525 },
    { lng: 100.6584232, lat: 13.6709696 },
    { lng: 100.6599012, lat: 13.670123 },
    { lng: 100.6604933, lat: 13.6709506 },
    { lng: 100.6608256, lat: 13.6714308 },
    { lng: 100.6610827, lat: 13.6716908 },
    { lng: 100.6611613, lat: 13.6716917 },
    { lng: 100.6614634, lat: 13.6714798 },
    { lng: 100.6616399, lat: 13.6713842 },
    { lng: 100.6618192, lat: 13.6713017 },
    { lng: 100.6619842, lat: 13.6712994 },
    { lng: 100.6622163, lat: 13.6713335 },
    { lng: 100.6622603, lat: 13.6713237 },
    { lng: 100.6626196, lat: 13.6709799 },
    { lng: 100.662991, lat: 13.6706101 },
    { lng: 100.6631671, lat: 13.6704524 },
    { lng: 100.6634161, lat: 13.6702732 },
    { lng: 100.6636369, lat: 13.6701448 },
    { lng: 100.6646361, lat: 13.6695847 },
    { lng: 100.664724, lat: 13.6695106 },
    { lng: 100.6654341, lat: 13.6690637 },
    { lng: 100.66574, lat: 13.6688359 },
    { lng: 100.6661638, lat: 13.6685513 },
    { lng: 100.6667003, lat: 13.6682578 },
    { lng: 100.666888, lat: 13.6682042 },
    { lng: 100.6668797, lat: 13.6681365 },
    { lng: 100.6668338, lat: 13.6679959 },
    { lng: 100.6666857, lat: 13.6676782 },
    { lng: 100.6664217, lat: 13.6671444 },
    { lng: 100.666807, lat: 13.6670579 },
    { lng: 100.6669979, lat: 13.6669909 },
    { lng: 100.6672759, lat: 13.6668907 },
    { lng: 100.6675553, lat: 13.6667774 },
    { lng: 100.6679183, lat: 13.6666252 },
    { lng: 100.6681642, lat: 13.6665276 },
    { lng: 100.6683803, lat: 13.6664351 },
    { lng: 100.6686219, lat: 13.6663713 },
    { lng: 100.6704051, lat: 13.665649 },
    { lng: 100.6711102, lat: 13.6653829 },
    { lng: 100.6722794, lat: 13.6649657 },
    { lng: 100.6723047, lat: 13.6649045 },
    { lng: 100.6722334, lat: 13.6646086 },
    { lng: 100.6723323, lat: 13.6645226 },
    { lng: 100.6733239, lat: 13.6641002 },
    { lng: 100.6738748, lat: 13.6639749 },
    { lng: 100.6745067, lat: 13.6638803 },
    { lng: 100.6753537, lat: 13.6634889 },
    { lng: 100.6758309, lat: 13.6634027 },
    { lng: 100.67592, lat: 13.663327 },
    { lng: 100.6759622, lat: 13.6632709 },
    { lng: 100.6760714, lat: 13.6631385 },
    { lng: 100.6763899, lat: 13.662808 },
    { lng: 100.6767111, lat: 13.6624931 },
    { lng: 100.6768697, lat: 13.6623382 },
    { lng: 100.6770578, lat: 13.6621886 },
    { lng: 100.6786744, lat: 13.661336 },
    { lng: 100.6813827, lat: 13.6604807 },
    { lng: 100.6821447, lat: 13.6602214 },
    { lng: 100.6828181, lat: 13.6599725 },
    { lng: 100.6837657, lat: 13.6596937 },
    { lng: 100.6847965, lat: 13.6593692 },
    { lng: 100.6861779, lat: 13.6589215 },
    { lng: 100.6876331, lat: 13.6584426 },
    { lng: 100.6880588, lat: 13.6583077 },
    { lng: 100.6882918, lat: 13.658239 },
    { lng: 100.6884644, lat: 13.6582015 },
    { lng: 100.6893199, lat: 13.6580243 },
    { lng: 100.6911923, lat: 13.6576682 },
    { lng: 100.6924587, lat: 13.6574461 },
    { lng: 100.6931804, lat: 13.6572752 },
    { lng: 100.6935213, lat: 13.6571563 },
    { lng: 100.6983024, lat: 13.6561891 },
    { lng: 100.6985134, lat: 13.6583857 },
    { lng: 100.6989042, lat: 13.6606325 },
    { lng: 100.6993692, lat: 13.6626657 },
    { lng: 100.7000416, lat: 13.6644178 },
    { lng: 100.7006391, lat: 13.6654062 },
    { lng: 100.7020627, lat: 13.6681564 },
    { lng: 100.7026579, lat: 13.6691836 },
    { lng: 100.7038002, lat: 13.6710865 },
    { lng: 100.7043253, lat: 13.6721504 },
    { lng: 100.7045873, lat: 13.6726855 },
    { lng: 100.7048064, lat: 13.6732623 },
    { lng: 100.7052232, lat: 13.674395 },
    { lng: 100.7051341, lat: 13.6745338 },
    { lng: 100.7047091, lat: 13.6746967 },
    { lng: 100.7047661, lat: 13.6751625 },
    { lng: 100.7051343, lat: 13.6769523 },
    { lng: 100.7055596, lat: 13.678791 },
    { lng: 100.7064531, lat: 13.6786112 },
    { lng: 100.706936, lat: 13.6825851 },
    { lng: 100.7072687, lat: 13.6844261 },
    { lng: 100.7075369, lat: 13.6850997 },
    { lng: 100.7088889, lat: 13.6881354 },
    { lng: 100.7098976, lat: 13.6910878 },
    { lng: 100.7103057, lat: 13.6950119 },
    { lng: 100.7098554, lat: 13.6951834 },
    { lng: 100.7110363, lat: 13.7012178 },
    { lng: 100.7123031, lat: 13.7013316 },
    { lng: 100.7099673, lat: 13.7167713 },
    { lng: 100.7030821, lat: 13.7166181 },
    { lng: 100.7023429, lat: 13.7240379 },
    { lng: 100.673945, lat: 13.7239547 },
    { lng: 100.638088, lat: 13.7147017 },
    { lng: 100.637551, lat: 13.7146637 },
    { lng: 100.636705, lat: 13.7146957 },
    { lng: 100.635547, lat: 13.7145367 },
    { lng: 100.634877, lat: 13.7147057 },
    { lng: 100.633922, lat: 13.7149807 },
    { lng: 100.632593, lat: 13.7145727 },
    { lng: 100.632081, lat: 13.7148617 },
    { lng: 100.630777, lat: 13.7151687 },
    { lng: 100.629479, lat: 13.7154487 },
    { lng: 100.629028, lat: 13.7151747 },
    { lng: 100.62847, lat: 13.7150847 },
    { lng: 100.62819, lat: 13.7149477 },
    { lng: 100.628103, lat: 13.7144017 },
    { lng: 100.627027, lat: 13.7134547 },
    { lng: 100.626335, lat: 13.7129387 },
    { lng: 100.626024, lat: 13.7124267 },
    { lng: 100.625744, lat: 13.7123197 },
    { lng: 100.625357, lat: 13.7127107 },
    { lng: 100.624796, lat: 13.7129077 },
    { lng: 100.624748, lat: 13.7130537 },
    { lng: 100.624567, lat: 13.7137407 },
    { lng: 100.624364, lat: 13.7145317 },
    { lng: 100.62397, lat: 13.7147367 },
    { lng: 100.623203, lat: 13.7144387 },
    { lng: 100.622442, lat: 13.7145087 },
    { lng: 100.622207, lat: 13.7132327 },
    { lng: 100.621995, lat: 13.7130137 },
    { lng: 100.621484, lat: 13.7123277 },
    { lng: 100.621365, lat: 13.7102877 },
    { lng: 100.620953, lat: 13.7103337 },
    { lng: 100.620646, lat: 13.7108447 },
    { lng: 100.619989, lat: 13.7114907 },
    { lng: 100.619018, lat: 13.7115307 },
    { lng: 100.618534, lat: 13.7107367 },
    { lng: 100.617863, lat: 13.7102977 },
    { lng: 100.617168, lat: 13.7100167 },
    { lng: 100.61652, lat: 13.7098287 },
    { lng: 100.616028, lat: 13.7099997 },
    { lng: 100.615857, lat: 13.7103257 },
    { lng: 100.615852, lat: 13.7107167 },
    { lng: 100.616558, lat: 13.7113467 },
    { lng: 100.615975, lat: 13.7124767 },
    { lng: 100.615872, lat: 13.7128637 },
    { lng: 100.615386, lat: 13.7132037 },
    { lng: 100.614586, lat: 13.7132597 },
    { lng: 100.614274, lat: 13.7129127 },
    { lng: 100.613934, lat: 13.7126607 },
    { lng: 100.613393, lat: 13.7125177 },
    { lng: 100.612878, lat: 13.7127627 },
    { lng: 100.612636, lat: 13.7128607 },
    { lng: 100.612497, lat: 13.7130727 },
    { lng: 100.612486, lat: 13.7139197 },
    { lng: 100.61233, lat: 13.7144827 },
    { lng: 100.612327, lat: 13.7153757 },
    { lng: 100.612028, lat: 13.7156067 },
    { lng: 100.610688, lat: 13.7155757 },
    { lng: 100.60863, lat: 13.7159017 },
    { lng: 100.607521, lat: 13.7158917 },
    { lng: 100.607194, lat: 13.7157987 },
    { lng: 100.606549, lat: 13.7154057 },
    { lng: 100.605723, lat: 13.7142097 },
    { lng: 100.605342, lat: 13.7141727 },
    { lng: 100.603085, lat: 13.7137337 },
    { lng: 100.602416, lat: 13.7133947 },
    { lng: 100.602048, lat: 13.7129307 },
    { lng: 100.601532, lat: 13.7112087 },
    { lng: 100.599663, lat: 13.7121767 },
    { lng: 100.598717, lat: 13.7122317 },
    { lng: 100.598608, lat: 13.7120467 },
    { lng: 100.597651, lat: 13.7106357 },
    { lng: 100.597161, lat: 13.7109707 },
    { lng: 100.596997, lat: 13.7110877 },
    { lng: 100.596228, lat: 13.7116347 },
    { lng: 100.595559, lat: 13.7121117 },
    { lng: 100.595317, lat: 13.7122857 },
    { lng: 100.594829, lat: 13.7126547 },
    { lng: 100.594372, lat: 13.7129927 },
    { lng: 100.594154, lat: 13.7131537 },
    { lng: 100.593863, lat: 13.7133607 },
    { lng: 100.592601, lat: 13.7142657 },
    { lng: 100.59189, lat: 13.7134077 },
    { lng: 100.59161, lat: 13.7132537 },
    { lng: 100.590422, lat: 13.7129137 },
    { lng: 100.589824, lat: 13.7127477 },
    { lng: 100.588609, lat: 13.7127907 },
    { lng: 100.587428, lat: 13.7128347 },
    { lng: 100.585454, lat: 13.7129067 },
    { lng: 100.581101, lat: 13.7130707 },
    { lng: 100.579222, lat: 13.7138337 },
    { lng: 100.577997, lat: 13.7143557 },
    { lng: 100.576294, lat: 13.7150817 },
    { lng: 100.573681, lat: 13.7162117 },
    { lng: 100.572702, lat: 13.7166317 },
    { lng: 100.570615, lat: 13.7175057 },
    { lng: 100.567562, lat: 13.7187957 },
    { lng: 100.566316, lat: 13.7193277 },
    { lng: 100.565625, lat: 13.7195907 },
    { lng: 100.565304, lat: 13.7196587 },
    { lng: 100.563302, lat: 13.7189047 },
    { lng: 100.562553, lat: 13.7188857 },
    { lng: 100.562285, lat: 13.7189117 },
    { lng: 100.555622, lat: 13.7216527 },
    { lng: 100.548133, lat: 13.7247797 },
    { lng: 100.542501, lat: 13.7271137 },
    { lng: 100.53064, lat: 13.7320787 },
    { lng: 100.529191, lat: 13.7326687 },
    { lng: 100.52659, lat: 13.7337327 },
    { lng: 100.524495, lat: 13.7346137 },
    { lng: 100.522999, lat: 13.7352107 },
    { lng: 100.522477, lat: 13.7354177 },
    { lng: 100.522224, lat: 13.7355187 },
    { lng: 100.521929, lat: 13.7356357 },
    { lng: 100.52166, lat: 13.7357417 },
    { lng: 100.521212, lat: 13.7359237 },
    { lng: 100.520581, lat: 13.7361767 },
    { lng: 100.519892, lat: 13.7364547 },
    { lng: 100.519649, lat: 13.7365517 },
    { lng: 100.51936, lat: 13.7366697 },
    { lng: 100.51912, lat: 13.7367647 },
    { lng: 100.519007, lat: 13.7368117 },
    { lng: 100.518804, lat: 13.7368917 },
    { lng: 100.518612, lat: 13.7369937 },
    { lng: 100.518385, lat: 13.7370927 },
    { lng: 100.51802, lat: 13.7372517 },
    { lng: 100.51775, lat: 13.7373657 },
    { lng: 100.517067, lat: 13.7376037 },
    { lng: 100.516831, lat: 13.7376847 },
    { lng: 100.516733, lat: 13.7377147 },
    { lng: 100.516374, lat: 13.7378207 },
    { lng: 100.516296, lat: 13.7378427 },
    { lng: 100.51585, lat: 13.7379757 },
    { lng: 100.515855, lat: 13.7376917 },
    { lng: 100.515861, lat: 13.7372807 },
    { lng: 100.515895, lat: 13.7368637 },
    { lng: 100.515963, lat: 13.7357737 },
    { lng: 100.51601, lat: 13.7349617 },
    { lng: 100.515963, lat: 13.7342547 },
    { lng: 100.515882, lat: 13.7336287 },
    { lng: 100.515716, lat: 13.7327747 },
    { lng: 100.515533, lat: 13.7322647 },
    { lng: 100.515247, lat: 13.7315657 },
    { lng: 100.514903, lat: 13.7310357 },
    { lng: 100.514609, lat: 13.7306887 },
    { lng: 100.514367, lat: 13.7303407 },
    { lng: 100.514003, lat: 13.7299787 },
    { lng: 100.513888, lat: 13.7298677 },
    { lng: 100.513336, lat: 13.7293937 },
    { lng: 100.513209, lat: 13.7293267 },
    { lng: 100.51298, lat: 13.7291817 },
    { lng: 100.512715, lat: 13.7272277 },
    { lng: 100.513018, lat: 13.7243417 },
    { lng: 100.513018, lat: 13.7226877 },
    { lng: 100.51259, lat: 13.7199177 },
    { lng: 100.511521, lat: 13.7171727 },
    { lng: 100.508011, lat: 13.7120997 },
    { lng: 100.509365, lat: 13.7113907 },
    { lng: 100.509813, lat: 13.7112017 },
    { lng: 100.510459, lat: 13.7110437 },
    { lng: 100.510954, lat: 13.7108427 },
    { lng: 100.510777, lat: 13.7103337 },
    { lng: 100.511037, lat: 13.7101757 },
    { lng: 100.511286, lat: 13.7101047 },
    { lng: 100.511453, lat: 13.7101037 },
    { lng: 100.511666, lat: 13.7100177 },
    { lng: 100.513963, lat: 13.7091317 },
    { lng: 100.517927, lat: 13.7079737 },
    { lng: 100.521762, lat: 13.7072377 },
    { lng: 100.526922, lat: 13.7062517 },
    { lng: 100.530506, lat: 13.7056087 },
    { lng: 100.531345, lat: 13.7055127 },
    { lng: 100.532292, lat: 13.7054487 },
    { lng: 100.533212, lat: 13.7053867 },
    { lng: 100.534121, lat: 13.7055237 },
    { lng: 100.535072, lat: 13.7057517 },
    { lng: 100.535945, lat: 13.7062187 },
    { lng: 100.536914, lat: 13.7067777 },
    { lng: 100.537478, lat: 13.7070907 },
    { lng: 100.537567, lat: 13.7071437 },
    { lng: 100.538086, lat: 13.7061847 },
    { lng: 100.539003, lat: 13.7044987 },
    { lng: 100.539199, lat: 13.7041307 },
    { lng: 100.539413, lat: 13.7037377 },
    { lng: 100.539823, lat: 13.7029827 },
    { lng: 100.540873, lat: 13.7014667 },
    { lng: 100.541347, lat: 13.7007787 },
    { lng: 100.541583, lat: 13.7004407 },
    { lng: 100.541739, lat: 13.7002157 },
    { lng: 100.541821, lat: 13.7001097 },
    { lng: 100.543055, lat: 13.6985357 },
    { lng: 100.544668, lat: 13.6968837 },
    { lng: 100.545722, lat: 13.6960827 },
    { lng: 100.545986, lat: 13.6958837 },
    { lng: 100.546239, lat: 13.6956917 },
    { lng: 100.546813, lat: 13.6954537 },
    { lng: 100.547384, lat: 13.6950867 },
    { lng: 100.548643, lat: 13.6948857 },
    { lng: 100.551234, lat: 13.6947817 },
    { lng: 100.551101, lat: 13.6956517 },
    { lng: 100.551086, lat: 13.6960897 },
    { lng: 100.551037, lat: 13.6969137 },
    { lng: 100.551051, lat: 13.6973247 },
    { lng: 100.55133, lat: 13.7005457 },
    { lng: 100.551349, lat: 13.7009947 },
    { lng: 100.551368, lat: 13.7014657 },
    { lng: 100.55151, lat: 13.7020857 },
    { lng: 100.551835, lat: 13.7026417 },
    { lng: 100.552199, lat: 13.7033287 },
    { lng: 100.552604, lat: 13.7038607 },
    { lng: 100.553069, lat: 13.7043927 },
    { lng: 100.553755, lat: 13.7049737 },
    { lng: 100.553873, lat: 13.7050827 },
    { lng: 100.555139, lat: 13.7057157 },
    { lng: 100.555635, lat: 13.7058827 },
    { lng: 100.556244, lat: 13.7060287 },
    { lng: 100.556511, lat: 13.7061117 },
    { lng: 100.557234, lat: 13.7062637 },
    { lng: 100.558669, lat: 13.7062687 },
    { lng: 100.561077, lat: 13.7059457 },
    { lng: 100.561695, lat: 13.7058517 },
    { lng: 100.563958, lat: 13.7052057 },
    { lng: 100.567003, lat: 13.7040527 },
    { lng: 100.569282, lat: 13.7032537 },
    { lng: 100.571933, lat: 13.7023277 },
    { lng: 100.575007, lat: 13.7012087 },
    { lng: 100.576616, lat: 13.7005617 },
    { lng: 100.579188, lat: 13.6995057 },
    { lng: 100.580179, lat: 13.6987697 },
    { lng: 100.581463, lat: 13.6978257 },
    { lng: 100.582118, lat: 13.6973417 },
    { lng: 100.582789, lat: 13.6968787 },
    { lng: 100.584092, lat: 13.6955697 },
    { lng: 100.585668, lat: 13.6944737 },
    { lng: 100.586373, lat: 13.6936777 },
    { lng: 100.586731, lat: 13.6932847 },
    { lng: 100.58678, lat: 13.6931787 },
    { lng: 100.58723, lat: 13.6924887 },
    { lng: 100.587753, lat: 13.6917937 },
    { lng: 100.588052, lat: 13.6911917 },
    { lng: 100.588499, lat: 13.6905057 },
    { lng: 100.5886, lat: 13.6902537 },
    { lng: 100.588771, lat: 13.6898237 },
    { lng: 100.58892, lat: 13.6892657 },
    { lng: 100.589033, lat: 13.6887447 },
    { lng: 100.58907, lat: 13.6881197 },
    { lng: 100.589338, lat: 13.6871157 },
    { lng: 100.589408, lat: 13.6864717 },
    { lng: 100.589349, lat: 13.6855997 },
    { lng: 100.589302, lat: 13.6853377 },
    { lng: 100.589168, lat: 13.6845947 },
    { lng: 100.588913, lat: 13.6837397 },
    { lng: 100.588775, lat: 13.6832767 },
    { lng: 100.588723, lat: 13.6829497 },
    { lng: 100.588589, lat: 13.6825977 },
    { lng: 100.588388, lat: 13.6817497 },
    { lng: 100.588129, lat: 13.6807717 },
    { lng: 100.587649, lat: 13.6796397 },
    { lng: 100.587016, lat: 13.6785587 },
    { lng: 100.585953, lat: 13.6771547 },
    { lng: 100.585169, lat: 13.6761957 },
    { lng: 100.584218, lat: 13.6750567 },
    { lng: 100.583035, lat: 13.6734817 },
    { lng: 100.582487, lat: 13.6728507 },
    { lng: 100.580336, lat: 13.6703997 },
    { lng: 100.579308, lat: 13.6691377 },
    { lng: 100.584457, lat: 13.6665327 },
  ],
  zone6: [
    { lng: 100.662245, lat: 13.88307 },
    { lng: 100.662043, lat: 13.882971 },
    { lng: 100.661894, lat: 13.882898 },
    { lng: 100.661801, lat: 13.882853 },
    { lng: 100.661614, lat: 13.882762 },
    { lng: 100.661415, lat: 13.882665 },
    { lng: 100.661272, lat: 13.882595 },
    { lng: 100.66113, lat: 13.882526 },
    { lng: 100.660916, lat: 13.882421 },
    { lng: 100.660851, lat: 13.882389 },
    { lng: 100.660735, lat: 13.882332 },
    { lng: 100.66052, lat: 13.882227 },
    { lng: 100.66041, lat: 13.882174 },
    { lng: 100.660283, lat: 13.882113 },
    { lng: 100.660057, lat: 13.882002 },
    { lng: 100.659878, lat: 13.881915 },
    { lng: 100.65966, lat: 13.881808 },
    { lng: 100.659226, lat: 13.881596 },
    { lng: 100.659076, lat: 13.881524 },
    { lng: 100.658868, lat: 13.881422 },
    { lng: 100.658474, lat: 13.88123 },
    { lng: 100.65777, lat: 13.880886 },
    { lng: 100.657553, lat: 13.880781 },
    { lng: 100.657327, lat: 13.88067 },
    { lng: 100.657152, lat: 13.880584 },
    { lng: 100.65699, lat: 13.880506 },
    { lng: 100.656752, lat: 13.88039 },
    { lng: 100.656526, lat: 13.880279 },
    { lng: 100.656158, lat: 13.880099 },
    { lng: 100.656046, lat: 13.880045 },
    { lng: 100.655908, lat: 13.879978 },
    { lng: 100.655802, lat: 13.879926 },
    { lng: 100.655688, lat: 13.879871 },
    { lng: 100.655473, lat: 13.879765 },
    { lng: 100.655071, lat: 13.87957 },
    { lng: 100.654533, lat: 13.879307 },
    { lng: 100.652664, lat: 13.878395 },
    { lng: 100.652385, lat: 13.878259 },
    { lng: 100.652022, lat: 13.878084 },
    { lng: 100.651385, lat: 13.877769 },
    { lng: 100.649946, lat: 13.877069 },
    { lng: 100.648747, lat: 13.876484 },
    { lng: 100.648397, lat: 13.875251 },
    { lng: 100.64815, lat: 13.874317 },
    { lng: 100.647841, lat: 13.873232 },
    { lng: 100.647309, lat: 13.871318 },
    { lng: 100.647265, lat: 13.870932 },
    { lng: 100.647032, lat: 13.86935 },
    { lng: 100.646846, lat: 13.867991 },
    { lng: 100.646729, lat: 13.867392 },
    { lng: 100.646548, lat: 13.867097 },
    { lng: 100.646446, lat: 13.866861 },
    { lng: 100.646428, lat: 13.866822 },
    { lng: 100.646358, lat: 13.866712 },
    { lng: 100.646271, lat: 13.866573 },
    { lng: 100.646248, lat: 13.866538 },
    { lng: 100.646158, lat: 13.8664 },
    { lng: 100.646099, lat: 13.866316 },
    { lng: 100.645994, lat: 13.866165 },
    { lng: 100.645865, lat: 13.865986 },
    { lng: 100.645763, lat: 13.865843 },
    { lng: 100.645629, lat: 13.865657 },
    { lng: 100.645416, lat: 13.8654 },
    { lng: 100.645378, lat: 13.865087 },
    { lng: 100.645305, lat: 13.864544 },
    { lng: 100.645269, lat: 13.864278 },
    { lng: 100.645226, lat: 13.863956 },
    { lng: 100.645212, lat: 13.863863 },
    { lng: 100.645205, lat: 13.863828 },
    { lng: 100.645142, lat: 13.863498 },
    { lng: 100.644992, lat: 13.862927 },
    { lng: 100.644848, lat: 13.862372 },
    { lng: 100.644707, lat: 13.861966 },
    { lng: 100.644522, lat: 13.861542 },
    { lng: 100.644289, lat: 13.86114 },
    { lng: 100.644051, lat: 13.860747 },
    { lng: 100.6437, lat: 13.86025 },
    { lng: 100.64337, lat: 13.859807 },
    { lng: 100.643144, lat: 13.859527 },
    { lng: 100.642931, lat: 13.859152 },
    { lng: 100.642726, lat: 13.858808 },
    { lng: 100.642599, lat: 13.858586 },
    { lng: 100.642512, lat: 13.858383 },
    { lng: 100.642435, lat: 13.858198 },
    { lng: 100.642334, lat: 13.857956 },
    { lng: 100.642136, lat: 13.857489 },
    { lng: 100.641831, lat: 13.854915 },
    { lng: 100.641611, lat: 13.853954 },
    { lng: 100.641257, lat: 13.853035 },
    { lng: 100.640516, lat: 13.851781 },
    { lng: 100.640324, lat: 13.851378 },
    { lng: 100.639712, lat: 13.850208 },
    { lng: 100.6307, lat: 13.833087 },
    { lng: 100.629401, lat: 13.827948 },
    { lng: 100.62938, lat: 13.827867 },
    { lng: 100.629352, lat: 13.827758 },
    { lng: 100.629304, lat: 13.827563 },
    { lng: 100.629209, lat: 13.82719 },
    { lng: 100.629031, lat: 13.826785 },
    { lng: 100.627656, lat: 13.823433 },
    { lng: 100.627018, lat: 13.822314 },
    { lng: 100.625786, lat: 13.820138 },
    { lng: 100.6242627, lat: 13.8172178 },
    { lng: 100.6236813, lat: 13.8161418 },
    { lng: 100.6221236, lat: 13.8137217 },
    { lng: 100.6208824, lat: 13.8116924 },
    { lng: 100.619534, lat: 13.809788 },
    { lng: 100.618815, lat: 13.808305 },
    { lng: 100.617629, lat: 13.805564 },
    { lng: 100.616614, lat: 13.803322 },
    { lng: 100.618746, lat: 13.802324 },
    { lng: 100.622429, lat: 13.802493 },
    { lng: 100.623254, lat: 13.802204 },
    { lng: 100.623663, lat: 13.800023 },
    { lng: 100.624058, lat: 13.797838 },
    { lng: 100.624009, lat: 13.797571 },
    { lng: 100.624161, lat: 13.79684 },
    { lng: 100.624317, lat: 13.796387 },
    { lng: 100.624424, lat: 13.79582 },
    { lng: 100.624514, lat: 13.79546 },
    { lng: 100.62459, lat: 13.795266 },
    { lng: 100.624667, lat: 13.794694 },
    { lng: 100.62475, lat: 13.794224 },
    { lng: 100.624777, lat: 13.794042 },
    { lng: 100.624857, lat: 13.793598 },
    { lng: 100.624973, lat: 13.792983 },
    { lng: 100.625097, lat: 13.792404 },
    { lng: 100.625197, lat: 13.791813 },
    { lng: 100.625316, lat: 13.791058 },
    { lng: 100.625401, lat: 13.790577 },
    { lng: 100.625474, lat: 13.790119 },
    { lng: 100.625524, lat: 13.78985 },
    { lng: 100.625567, lat: 13.789613 },
    { lng: 100.625626, lat: 13.789369 },
    { lng: 100.625686, lat: 13.789109 },
    { lng: 100.625891, lat: 13.788155 },
    { lng: 100.625934, lat: 13.787929 },
    { lng: 100.625973, lat: 13.787738 },
    { lng: 100.626008, lat: 13.78757 },
    { lng: 100.626055, lat: 13.787334 },
    { lng: 100.62609, lat: 13.787164 },
    { lng: 100.626093, lat: 13.787148 },
    { lng: 100.626115, lat: 13.787035 },
    { lng: 100.626194, lat: 13.786742 },
    { lng: 100.626255, lat: 13.786443 },
    { lng: 100.626426, lat: 13.785272 },
    { lng: 100.626789, lat: 13.783334 },
    { lng: 100.626865, lat: 13.782739 },
    { lng: 100.626993, lat: 13.782183 },
    { lng: 100.62708, lat: 13.781739 },
    { lng: 100.627188, lat: 13.781183 },
    { lng: 100.627439, lat: 13.780253 },
    { lng: 100.627536, lat: 13.779451 },
    { lng: 100.627629, lat: 13.778988 },
    { lng: 100.627692, lat: 13.778518 },
    { lng: 100.627751, lat: 13.77822 },
    { lng: 100.627806, lat: 13.777832 },
    { lng: 100.627829, lat: 13.777687 },
    { lng: 100.627867, lat: 13.777494 },
    { lng: 100.627931, lat: 13.777137 },
    { lng: 100.627934, lat: 13.776998 },
    { lng: 100.627948, lat: 13.776833 },
    { lng: 100.627974, lat: 13.776577 },
    { lng: 100.627996, lat: 13.776476 },
    { lng: 100.628047, lat: 13.776326 },
    { lng: 100.628111, lat: 13.776096 },
    { lng: 100.628168, lat: 13.775864 },
    { lng: 100.628197, lat: 13.775632 },
    { lng: 100.628413, lat: 13.774573 },
    { lng: 100.628538, lat: 13.774055 },
    { lng: 100.62871, lat: 13.773289 },
    { lng: 100.628782, lat: 13.773082 },
    { lng: 100.628839, lat: 13.772876 },
    { lng: 100.628855, lat: 13.77282 },
    { lng: 100.628885, lat: 13.772688 },
    { lng: 100.6289, lat: 13.772567 },
    { lng: 100.628875, lat: 13.772411 },
    { lng: 100.628839, lat: 13.772209 },
    { lng: 100.628832, lat: 13.77198 },
    { lng: 100.628876, lat: 13.7717 },
    { lng: 100.629182, lat: 13.770144 },
    { lng: 100.629326, lat: 13.769459 },
    { lng: 100.629666, lat: 13.767608 },
    { lng: 100.630086, lat: 13.765321 },
    { lng: 100.630316, lat: 13.764104 },
    { lng: 100.630439, lat: 13.763071 },
    { lng: 100.63278, lat: 13.763316 },
    { lng: 100.633446, lat: 13.763388 },
    { lng: 100.633768, lat: 13.763408 },
    { lng: 100.63424, lat: 13.763466 },
    { lng: 100.635319, lat: 13.763591 },
    { lng: 100.636644, lat: 13.763778 },
    { lng: 100.638122, lat: 13.763926 },
    { lng: 100.639673, lat: 13.764117 },
    { lng: 100.641697, lat: 13.764392 },
    { lng: 100.645007, lat: 13.764746 },
    { lng: 100.645884, lat: 13.764833 },
    { lng: 100.64693, lat: 13.764934 },
    { lng: 100.647555, lat: 13.76498 },
    { lng: 100.648336, lat: 13.765206 },
    { lng: 100.649316, lat: 13.765574 },
    { lng: 100.649733, lat: 13.765862 },
    { lng: 100.651436, lat: 13.76731 },
    { lng: 100.653113, lat: 13.768612 },
    { lng: 100.654586, lat: 13.769768 },
    { lng: 100.655967, lat: 13.770556 },
    { lng: 100.657801, lat: 13.771538 },
    { lng: 100.658563, lat: 13.771905 },
    { lng: 100.658826, lat: 13.772062 },
    { lng: 100.65919, lat: 13.772274 },
    { lng: 100.659487, lat: 13.772457 },
    { lng: 100.66066, lat: 13.773037 },
    { lng: 100.662004, lat: 13.773819 },
    { lng: 100.662867, lat: 13.774296 },
    { lng: 100.664071, lat: 13.774962 },
    { lng: 100.664752, lat: 13.775336 },
    { lng: 100.665525, lat: 13.775765 },
    { lng: 100.66587, lat: 13.775958 },
    { lng: 100.66622, lat: 13.776166 },
    { lng: 100.667746, lat: 13.777037 },
    { lng: 100.669304, lat: 13.777928 },
    { lng: 100.673687, lat: 13.780322 },
    { lng: 100.673956, lat: 13.780466 },
    { lng: 100.674233, lat: 13.780615 },
    { lng: 100.674771, lat: 13.780902 },
    { lng: 100.675857, lat: 13.781483 },
    { lng: 100.678031, lat: 13.78269 },
    { lng: 100.682736, lat: 13.785274 },
    { lng: 100.683598, lat: 13.785728 },
    { lng: 100.683742, lat: 13.785802 },
    { lng: 100.683864, lat: 13.785871 },
    { lng: 100.684916, lat: 13.786458 },
    { lng: 100.686184, lat: 13.787166 },
    { lng: 100.688009, lat: 13.788104 },
    { lng: 100.68836, lat: 13.788309 },
    { lng: 100.689138, lat: 13.788755 },
    { lng: 100.690631, lat: 13.78961 },
    { lng: 100.69116, lat: 13.789913 },
    { lng: 100.691331, lat: 13.790014 },
    { lng: 100.691936, lat: 13.790355 },
    { lng: 100.692586, lat: 13.790726 },
    { lng: 100.692943, lat: 13.790932 },
    { lng: 100.693118, lat: 13.791035 },
    { lng: 100.693294, lat: 13.791131 },
    { lng: 100.693584, lat: 13.791291 },
    { lng: 100.693948, lat: 13.791492 },
    { lng: 100.694529, lat: 13.791812 },
    { lng: 100.695181, lat: 13.792172 },
    { lng: 100.695481, lat: 13.792336 },
    { lng: 100.695543, lat: 13.792372 },
    { lng: 100.695794, lat: 13.792505 },
    { lng: 100.696714, lat: 13.792996 },
    { lng: 100.697624, lat: 13.79348 },
    { lng: 100.698346, lat: 13.79389 },
    { lng: 100.699072, lat: 13.794305 },
    { lng: 100.699788, lat: 13.794711 },
    { lng: 100.700528, lat: 13.795117 },
    { lng: 100.703295, lat: 13.796558 },
    { lng: 100.704577, lat: 13.794099 },
    { lng: 100.705543, lat: 13.794571 },
    { lng: 100.706471, lat: 13.795062 },
    { lng: 100.707578, lat: 13.795584 },
    { lng: 100.707983, lat: 13.794909 },
    { lng: 100.708087, lat: 13.794707 },
    { lng: 100.708225, lat: 13.794472 },
    { lng: 100.708476, lat: 13.794047 },
    { lng: 100.708917, lat: 13.793282 },
    { lng: 100.709302, lat: 13.792612 },
    { lng: 100.7095, lat: 13.792262 },
    { lng: 100.710005, lat: 13.791377 },
    { lng: 100.710186, lat: 13.791059 },
    { lng: 100.710502, lat: 13.790492 },
    { lng: 100.710731, lat: 13.79008 },
    { lng: 100.711037, lat: 13.789529 },
    { lng: 100.711498, lat: 13.788705 },
    { lng: 100.712365, lat: 13.787173 },
    { lng: 100.713288, lat: 13.78561 },
    { lng: 100.713519, lat: 13.785216 },
    { lng: 100.713736, lat: 13.784847 },
    { lng: 100.713821, lat: 13.784702 },
    { lng: 100.714144, lat: 13.784038 },
    { lng: 100.714264, lat: 13.783789 },
    { lng: 100.71443, lat: 13.783479 },
    { lng: 100.714724, lat: 13.782932 },
    { lng: 100.715308, lat: 13.78184 },
    { lng: 100.715464, lat: 13.780436 },
    { lng: 100.716462, lat: 13.777776 },
    { lng: 100.716251, lat: 13.772124 },
    { lng: 100.712176, lat: 13.765159 },
    { lng: 100.709351, lat: 13.76141 },
    { lng: 100.706473, lat: 13.756726 },
    { lng: 100.708251, lat: 13.756286 },
    { lng: 100.709728, lat: 13.756431 },
    { lng: 100.709129, lat: 13.74553 },
    { lng: 100.709083, lat: 13.744256 },
    { lng: 100.708958, lat: 13.743096 },
    { lng: 100.708758, lat: 13.740055 },
    { lng: 100.70832, lat: 13.732728 },
    { lng: 100.708252, lat: 13.731846 },
    { lng: 100.708334, lat: 13.730986 },
    { lng: 100.708333, lat: 13.730163 },
    { lng: 100.708333, lat: 13.730027 },
    { lng: 100.708342, lat: 13.729858 },
    { lng: 100.708368, lat: 13.72939 },
    { lng: 100.70852, lat: 13.727668 },
    { lng: 100.708943, lat: 13.723951 },
    { lng: 100.7023429, lat: 13.7240379 },
    { lng: 100.7023938, lat: 13.7235515 },
    { lng: 100.7024568, lat: 13.7229597 },
    { lng: 100.7025639, lat: 13.7217578 },
    { lng: 100.7027836, lat: 13.7194529 },
    { lng: 100.7030191, lat: 13.7166324 },
    { lng: 100.7109129, lat: 13.7168126 },
    { lng: 100.7197293, lat: 13.7168258 },
    { lng: 100.736504, lat: 13.716768 },
    { lng: 100.745773, lat: 13.716959 },
    { lng: 100.746401, lat: 13.716623 },
    { lng: 100.750086, lat: 13.716589 },
    { lng: 100.750824, lat: 13.716469 },
    { lng: 100.75196, lat: 13.715994 },
    { lng: 100.752117, lat: 13.716014 },
    { lng: 100.752775, lat: 13.716523 },
    { lng: 100.753147, lat: 13.716707 },
    { lng: 100.75358, lat: 13.716709 },
    { lng: 100.755389, lat: 13.716663 },
    { lng: 100.759714, lat: 13.716538 },
    { lng: 100.760326, lat: 13.716533 },
    { lng: 100.760584, lat: 13.716444 },
    { lng: 100.760899, lat: 13.716276 },
    { lng: 100.761213, lat: 13.716232 },
    { lng: 100.761728, lat: 13.716333 },
    { lng: 100.761912, lat: 13.716493 },
    { lng: 100.762044, lat: 13.716521 },
    { lng: 100.763036, lat: 13.716556 },
    { lng: 100.764452, lat: 13.716565 },
    { lng: 100.764838, lat: 13.716542 },
    { lng: 100.765409, lat: 13.716288 },
    { lng: 100.766346, lat: 13.715513 },
    { lng: 100.766682, lat: 13.715414 },
    { lng: 100.767244, lat: 13.715362 },
    { lng: 100.767563, lat: 13.715415 },
    { lng: 100.769715, lat: 13.715532 },
    { lng: 100.770744, lat: 13.71558 },
    { lng: 100.771093, lat: 13.715726 },
    { lng: 100.771457, lat: 13.716041 },
    { lng: 100.771724, lat: 13.716222 },
    { lng: 100.772204, lat: 13.716418 },
    { lng: 100.774494, lat: 13.716289 },
    { lng: 100.778517, lat: 13.716082 },
    { lng: 100.779684, lat: 13.71608 },
    { lng: 100.780164, lat: 13.716355 },
    { lng: 100.781039, lat: 13.716612 },
    { lng: 100.783212, lat: 13.716483 },
    { lng: 100.787734, lat: 13.716868 },
    { lng: 100.78847, lat: 13.716884 },
    { lng: 100.789259, lat: 13.716588 },
    { lng: 100.790645, lat: 13.71614 },
    { lng: 100.794211, lat: 13.714839 },
    { lng: 100.796483, lat: 13.713924 },
    { lng: 100.800512, lat: 13.712512 },
    { lng: 100.810697, lat: 13.708722 },
    { lng: 100.811497, lat: 13.708412 },
    { lng: 100.813006, lat: 13.707976 },
    { lng: 100.815114, lat: 13.707098 },
    { lng: 100.820468, lat: 13.705114 },
    { lng: 100.824384, lat: 13.703751 },
    { lng: 100.826218, lat: 13.703006 },
    { lng: 100.828697, lat: 13.702089 },
    { lng: 100.831659, lat: 13.700974 },
    { lng: 100.832978, lat: 13.700468 },
    { lng: 100.83449, lat: 13.69991 },
    { lng: 100.837483, lat: 13.698731 },
    { lng: 100.842207, lat: 13.696808 },
    { lng: 100.847081, lat: 13.694843 },
    { lng: 100.8531, lat: 13.692388 },
    { lng: 100.855669, lat: 13.691326 },
    { lng: 100.859014, lat: 13.689616 },
    { lng: 100.859274, lat: 13.689324 },
    { lng: 100.859917, lat: 13.692883 },
    { lng: 100.860695, lat: 13.697157 },
    { lng: 100.855111, lat: 13.699437 },
    { lng: 100.857537, lat: 13.703403 },
    { lng: 100.859095, lat: 13.705887 },
    { lng: 100.860778, lat: 13.708662 },
    { lng: 100.862932, lat: 13.71216 },
    { lng: 100.864119, lat: 13.71425 },
    { lng: 100.865368, lat: 13.716145 },
    { lng: 100.86653, lat: 13.718123 },
    { lng: 100.86822, lat: 13.720836 },
    { lng: 100.869401, lat: 13.722745 },
    { lng: 100.871397, lat: 13.725873 },
    { lng: 100.873999, lat: 13.730173 },
    { lng: 100.876677, lat: 13.734489 },
    { lng: 100.879218, lat: 13.738417 },
    { lng: 100.879925, lat: 13.739421 },
    { lng: 100.880589, lat: 13.740456 },
    { lng: 100.881274, lat: 13.741635 },
    { lng: 100.881954, lat: 13.742741 },
    { lng: 100.883267, lat: 13.744621 },
    { lng: 100.884572, lat: 13.746673 },
    { lng: 100.885223, lat: 13.747748 },
    { lng: 100.885591, lat: 13.748457 },
    { lng: 100.886648, lat: 13.750116 },
    { lng: 100.887519, lat: 13.751513 },
    { lng: 100.888272, lat: 13.752726 },
    { lng: 100.890593, lat: 13.756378 },
    { lng: 100.894379, lat: 13.76299 },
    { lng: 100.894768, lat: 13.763817 },
    { lng: 100.895414, lat: 13.764654 },
    { lng: 100.89603, lat: 13.765787 },
    { lng: 100.89767, lat: 13.768648 },
    { lng: 100.898742, lat: 13.770654 },
    { lng: 100.899722, lat: 13.772386 },
    { lng: 100.900013, lat: 13.772957 },
    { lng: 100.900304, lat: 13.773513 },
    { lng: 100.90052, lat: 13.773899 },
    { lng: 100.900598, lat: 13.773995 },
    { lng: 100.900675, lat: 13.774126 },
    { lng: 100.900705, lat: 13.774247 },
    { lng: 100.901455, lat: 13.775503 },
    { lng: 100.902152, lat: 13.776748 },
    { lng: 100.902414, lat: 13.777183 },
    { lng: 100.903245, lat: 13.778713 },
    { lng: 100.90406, lat: 13.780109 },
    { lng: 100.904188, lat: 13.780453 },
    { lng: 100.904701, lat: 13.781329 },
    { lng: 100.905719, lat: 13.783014 },
    { lng: 100.906392, lat: 13.78435 },
    { lng: 100.907491, lat: 13.78644 },
    { lng: 100.909491, lat: 13.78978 },
    { lng: 100.90971, lat: 13.790288 },
    { lng: 100.910062, lat: 13.790645 },
    { lng: 100.910431, lat: 13.790835 },
    { lng: 100.910639, lat: 13.791077 },
    { lng: 100.911795, lat: 13.791726 },
    { lng: 100.912512, lat: 13.792043 },
    { lng: 100.913229, lat: 13.792505 },
    { lng: 100.91737, lat: 13.794802 },
    { lng: 100.91927, lat: 13.79591 },
    { lng: 100.921063, lat: 13.797003 },
    { lng: 100.924263, lat: 13.798939 },
    { lng: 100.925728, lat: 13.799798 },
    { lng: 100.927959, lat: 13.800934 },
    { lng: 100.928643, lat: 13.801374 },
    { lng: 100.929048, lat: 13.801804 },
    { lng: 100.92948, lat: 13.802666 },
    { lng: 100.930374, lat: 13.803736 },
    { lng: 100.931812, lat: 13.805721 },
    { lng: 100.933388, lat: 13.807789 },
    { lng: 100.93551, lat: 13.81061 },
    { lng: 100.937664, lat: 13.813296 },
    { lng: 100.938137, lat: 13.813854 },
    { lng: 100.938396, lat: 13.814455 },
    { lng: 100.937856, lat: 13.814931 },
    { lng: 100.936768, lat: 13.815739 },
    { lng: 100.928696, lat: 13.821929 },
    { lng: 100.919331, lat: 13.829249 },
    { lng: 100.917669, lat: 13.830496 },
    { lng: 100.916506, lat: 13.831447 },
    { lng: 100.915725, lat: 13.832014 },
    { lng: 100.91028, lat: 13.836191 },
    { lng: 100.909581, lat: 13.836789 },
    { lng: 100.909332, lat: 13.836896 },
    { lng: 100.908801, lat: 13.837203 },
    { lng: 100.907771, lat: 13.838099 },
    { lng: 100.9082, lat: 13.838781 },
    { lng: 100.9085, lat: 13.839744 },
    { lng: 100.909048, lat: 13.84111 },
    { lng: 100.910096, lat: 13.842823 },
    { lng: 100.911177, lat: 13.844151 },
    { lng: 100.911798, lat: 13.844924 },
    { lng: 100.912119, lat: 13.845662 },
    { lng: 100.912418, lat: 13.846075 },
    { lng: 100.91276, lat: 13.846745 },
    { lng: 100.90834, lat: 13.847662 },
    { lng: 100.905277, lat: 13.848237 },
    { lng: 100.902267, lat: 13.848824 },
    { lng: 100.902544, lat: 13.849636 },
    { lng: 100.902553, lat: 13.850038 },
    { lng: 100.90272, lat: 13.85108 },
    { lng: 100.902959, lat: 13.851821 },
    { lng: 100.90349, lat: 13.85424 },
    { lng: 100.903887, lat: 13.855662 },
    { lng: 100.904316, lat: 13.857302 },
    { lng: 100.904605, lat: 13.85863 },
    { lng: 100.904746, lat: 13.858875 },
    { lng: 100.904886, lat: 13.858932 },
    { lng: 100.905371, lat: 13.859025 },
    { lng: 100.90559, lat: 13.8592 },
    { lng: 100.90569, lat: 13.859573 },
    { lng: 100.90592, lat: 13.859969 },
    { lng: 100.906231, lat: 13.860274 },
    { lng: 100.906338, lat: 13.860433 },
    { lng: 100.906424, lat: 13.86237 },
    { lng: 100.906436, lat: 13.866407 },
    { lng: 100.906378, lat: 13.866631 },
    { lng: 100.906302, lat: 13.867951 },
    { lng: 100.906297, lat: 13.868573 },
    { lng: 100.906307, lat: 13.870094 },
    { lng: 100.906314, lat: 13.872443 },
    { lng: 100.9064, lat: 13.873986 },
    { lng: 100.906375, lat: 13.875136 },
    { lng: 100.906379, lat: 13.875491 },
    { lng: 100.906409, lat: 13.875717 },
    { lng: 100.906437, lat: 13.876612 },
    { lng: 100.906509, lat: 13.876822 },
    { lng: 100.906502, lat: 13.878098 },
    { lng: 100.906516, lat: 13.879172 },
    { lng: 100.906558, lat: 13.880928 },
    { lng: 100.906714, lat: 13.883262 },
    { lng: 100.906797, lat: 13.883955 },
    { lng: 100.906909, lat: 13.885268 },
    { lng: 100.907153, lat: 13.887159 },
    { lng: 100.907188, lat: 13.887718 },
    { lng: 100.907324, lat: 13.888766 },
    { lng: 100.907326, lat: 13.889055 },
    { lng: 100.907426, lat: 13.890265 },
    { lng: 100.907583, lat: 13.891376 },
    { lng: 100.907635, lat: 13.892138 },
    { lng: 100.90774, lat: 13.893224 },
    { lng: 100.907787, lat: 13.893486 },
    { lng: 100.907822, lat: 13.894013 },
    { lng: 100.907831, lat: 13.894218 },
    { lng: 100.907988, lat: 13.895725 },
    { lng: 100.90801, lat: 13.896049 },
    { lng: 100.908183, lat: 13.898224 },
    { lng: 100.908344, lat: 13.898938 },
    { lng: 100.908554, lat: 13.901475 },
    { lng: 100.908838, lat: 13.90395 },
    { lng: 100.908872, lat: 13.90439 },
    { lng: 100.908897, lat: 13.904604 },
    { lng: 100.908927, lat: 13.904869 },
    { lng: 100.908987, lat: 13.905307 },
    { lng: 100.909106, lat: 13.906188 },
    { lng: 100.909147, lat: 13.906369 },
    { lng: 100.909185, lat: 13.906692 },
    { lng: 100.909228, lat: 13.907115 },
    { lng: 100.909283, lat: 13.907577 },
    { lng: 100.909338, lat: 13.907951 },
    { lng: 100.909376, lat: 13.908181 },
    { lng: 100.909434, lat: 13.908531 },
    { lng: 100.909481, lat: 13.909103 },
    { lng: 100.909516, lat: 13.90984 },
    { lng: 100.909542, lat: 13.910024 },
    { lng: 100.90967, lat: 13.910358 },
    { lng: 100.909671, lat: 13.910551 },
    { lng: 100.909817, lat: 13.911346 },
    { lng: 100.909868, lat: 13.911683 },
    { lng: 100.909924, lat: 13.912706 },
    { lng: 100.909969, lat: 13.912821 },
    { lng: 100.91008, lat: 13.913731 },
    { lng: 100.910138, lat: 13.914504 },
    { lng: 100.910202, lat: 13.914677 },
    { lng: 100.910194, lat: 13.914779 },
    { lng: 100.910308, lat: 13.915997 },
    { lng: 100.910348, lat: 13.916374 },
    { lng: 100.910446, lat: 13.916676 },
    { lng: 100.910514, lat: 13.917236 },
    { lng: 100.910552, lat: 13.917649 },
    { lng: 100.910602, lat: 13.917835 },
    { lng: 100.910667, lat: 13.918273 },
    { lng: 100.910697, lat: 13.918743 },
    { lng: 100.910753, lat: 13.919377 },
    { lng: 100.910856, lat: 13.919959 },
    { lng: 100.910839, lat: 13.920267 },
    { lng: 100.910843, lat: 13.920389 },
    { lng: 100.910874, lat: 13.920647 },
    { lng: 100.910935, lat: 13.920953 },
    { lng: 100.910999, lat: 13.921144 },
    { lng: 100.911048, lat: 13.921482 },
    { lng: 100.911143, lat: 13.922109 },
    { lng: 100.911215, lat: 13.92262 },
    { lng: 100.911272, lat: 13.923028 },
    { lng: 100.911266, lat: 13.92335 },
    { lng: 100.911351, lat: 13.923974 },
    { lng: 100.911464, lat: 13.924811 },
    { lng: 100.911593, lat: 13.925648 },
    { lng: 100.911684, lat: 13.926294 },
    { lng: 100.911737, lat: 13.926869 },
    { lng: 100.911783, lat: 13.927091 },
    { lng: 100.91186, lat: 13.927745 },
    { lng: 100.911948, lat: 13.928898 },
    { lng: 100.912161, lat: 13.930431 },
    { lng: 100.912262, lat: 13.931235 },
    { lng: 100.912349, lat: 13.932039 },
    { lng: 100.912443, lat: 13.932407 },
    { lng: 100.912553, lat: 13.933263 },
    { lng: 100.912647, lat: 13.93365 },
    { lng: 100.912684, lat: 13.934065 },
    { lng: 100.912716, lat: 13.934835 },
    { lng: 100.913038, lat: 13.936799 },
    { lng: 100.913178, lat: 13.938751 },
    { lng: 100.913405, lat: 13.940397 },
    { lng: 100.913621, lat: 13.942238 },
    { lng: 100.913751, lat: 13.942902 },
    { lng: 100.91413, lat: 13.946417 },
    { lng: 100.909373, lat: 13.945998 },
    { lng: 100.906418, lat: 13.945609 },
    { lng: 100.902226, lat: 13.944994 },
    { lng: 100.898265, lat: 13.944332 },
    { lng: 100.887514, lat: 13.942958 },
    { lng: 100.876764, lat: 13.941583 },
    { lng: 100.860521, lat: 13.939501 },
    { lng: 100.84968, lat: 13.938012 },
    { lng: 100.845699, lat: 13.937484 },
    { lng: 100.841767, lat: 13.936976 },
    { lng: 100.833892, lat: 13.936075 },
    { lng: 100.829277, lat: 13.935582 },
    { lng: 100.822978, lat: 13.93488 },
    { lng: 100.815508, lat: 13.934112 },
    { lng: 100.805482, lat: 13.933149 },
    { lng: 100.797253, lat: 13.93228 },
    { lng: 100.794048, lat: 13.931938 },
    { lng: 100.790885, lat: 13.931602 },
    { lng: 100.786701, lat: 13.930295 },
    { lng: 100.780083, lat: 13.92822 },
    { lng: 100.77834, lat: 13.927593 },
    { lng: 100.77679, lat: 13.927023 },
    { lng: 100.775076, lat: 13.926396 },
    { lng: 100.771836, lat: 13.925214 },
    { lng: 100.767388, lat: 13.923566 },
    { lng: 100.764334, lat: 13.922376 },
    { lng: 100.762309, lat: 13.921582 },
    { lng: 100.761798, lat: 13.921433 },
    { lng: 100.75963, lat: 13.920797 },
    { lng: 100.758676, lat: 13.920492 },
    { lng: 100.756076, lat: 13.919598 },
    { lng: 100.754535, lat: 13.919271 },
    { lng: 100.753818, lat: 13.919182 },
    { lng: 100.753338, lat: 13.919093 },
    { lng: 100.750113, lat: 13.91887 },
    { lng: 100.747305, lat: 13.918649 },
    { lng: 100.743821, lat: 13.918401 },
    { lng: 100.741174, lat: 13.91831 },
    { lng: 100.739629, lat: 13.918258 },
    { lng: 100.737768, lat: 13.918186 },
    { lng: 100.735865, lat: 13.918089 },
    { lng: 100.731678, lat: 13.917892 },
    { lng: 100.730193, lat: 13.917853 },
    { lng: 100.729476, lat: 13.917835 },
    { lng: 100.728819, lat: 13.917817 },
    { lng: 100.72776, lat: 13.917791 },
    { lng: 100.726502, lat: 13.917759 },
    { lng: 100.725402, lat: 13.917696 },
    { lng: 100.723006, lat: 13.917574 },
    { lng: 100.720674, lat: 13.917434 },
    { lng: 100.720149, lat: 13.917408 },
    { lng: 100.719558, lat: 13.917374 },
    { lng: 100.719059, lat: 13.917352 },
    { lng: 100.71835, lat: 13.91733 },
    { lng: 100.71806, lat: 13.917308 },
    { lng: 100.71903, lat: 13.916737 },
    { lng: 100.719329, lat: 13.916562 },
    { lng: 100.719855, lat: 13.916202 },
    { lng: 100.720371, lat: 13.915726 },
    { lng: 100.720653, lat: 13.915426 },
    { lng: 100.720912, lat: 13.915023 },
    { lng: 100.721188, lat: 13.914433 },
    { lng: 100.721359, lat: 13.913973 },
    { lng: 100.72145, lat: 13.913638 },
    { lng: 100.721557, lat: 13.912783 },
    { lng: 100.721622, lat: 13.912222 },
    { lng: 100.721632, lat: 13.911615 },
    { lng: 100.721663, lat: 13.910766 },
    { lng: 100.721651, lat: 13.909917 },
    { lng: 100.721664, lat: 13.909159 },
    { lng: 100.721657, lat: 13.908382 },
    { lng: 100.721661, lat: 13.9078 },
    { lng: 100.721698, lat: 13.906688 },
    { lng: 100.721791, lat: 13.902992 },
    { lng: 100.721836, lat: 13.902739 },
    { lng: 100.72185, lat: 13.902066 },
    { lng: 100.721862, lat: 13.901559 },
    { lng: 100.721876, lat: 13.900695 },
    { lng: 100.721878, lat: 13.900134 },
    { lng: 100.721866, lat: 13.899094 },
    { lng: 100.721927, lat: 13.897042 },
    { lng: 100.721936, lat: 13.896911 },
    { lng: 100.721941, lat: 13.896816 },
    { lng: 100.721959, lat: 13.896263 },
    { lng: 100.72198, lat: 13.895743 },
    { lng: 100.716887, lat: 13.894726 },
    { lng: 100.714339, lat: 13.894213 },
    { lng: 100.713065, lat: 13.893963 },
    { lng: 100.712798, lat: 13.893911 },
    { lng: 100.711791, lat: 13.89371 },
    { lng: 100.706751, lat: 13.892728 },
    { lng: 100.70374, lat: 13.892158 },
    { lng: 100.70261, lat: 13.891941 },
    { lng: 100.701666, lat: 13.891761 },
    { lng: 100.701252, lat: 13.891685 },
    { lng: 100.700972, lat: 13.891633 },
    { lng: 100.700341, lat: 13.891524 },
    { lng: 100.699378, lat: 13.891358 },
    { lng: 100.698831, lat: 13.891264 },
    { lng: 100.69769, lat: 13.891064 },
    { lng: 100.696539, lat: 13.890865 },
    { lng: 100.691466, lat: 13.890003 },
    { lng: 100.686984, lat: 13.888878 },
    { lng: 100.685936, lat: 13.889503 },
    { lng: 100.683853, lat: 13.890474 },
    { lng: 100.681898, lat: 13.891379 },
    { lng: 100.681324, lat: 13.89165 },
    { lng: 100.680558, lat: 13.892003 },
    { lng: 100.68041, lat: 13.891931 },
    { lng: 100.678636, lat: 13.891065 },
    { lng: 100.677666, lat: 13.890592 },
    { lng: 100.676713, lat: 13.890127 },
    { lng: 100.674794, lat: 13.889193 },
    { lng: 100.674641, lat: 13.889117 },
    { lng: 100.674396, lat: 13.888997 },
    { lng: 100.6741, lat: 13.888854 },
    { lng: 100.67373, lat: 13.888673 },
    { lng: 100.673468, lat: 13.888544 },
    { lng: 100.673198, lat: 13.888414 },
    { lng: 100.67266, lat: 13.88815 },
    { lng: 100.672108, lat: 13.88788 },
    { lng: 100.671837, lat: 13.887749 },
    { lng: 100.671663, lat: 13.887665 },
    { lng: 100.671574, lat: 13.887621 },
    { lng: 100.670844, lat: 13.887264 },
    { lng: 100.670512, lat: 13.887102 },
    { lng: 100.670152, lat: 13.886927 },
    { lng: 100.669495, lat: 13.886609 },
    { lng: 100.668817, lat: 13.886275 },
    { lng: 100.666726, lat: 13.885264 },
    { lng: 100.664243, lat: 13.884044 },
    { lng: 100.662856, lat: 13.883368 },
    { lng: 100.662245, lat: 13.88307 },
  ],
  zone7: [
    { lng: 100.543742, lat: 13.84964 },
    { lng: 100.540505, lat: 13.83562 },
    { lng: 100.538819, lat: 13.82854 },
    { lng: 100.538395, lat: 13.826767 },
    { lng: 100.538188, lat: 13.825883 },
    { lng: 100.537988, lat: 13.824997 },
    { lng: 100.537776, lat: 13.824068 },
    { lng: 100.537675, lat: 13.823649 },
    { lng: 100.53762, lat: 13.823424 },
    { lng: 100.537589, lat: 13.823297 },
    { lng: 100.537564, lat: 13.823192 },
    { lng: 100.537356, lat: 13.822332 },
    { lng: 100.537253, lat: 13.821903 },
    { lng: 100.537196, lat: 13.821669 },
    { lng: 100.537155, lat: 13.8215 },
    { lng: 100.537133, lat: 13.821407 },
    { lng: 100.536979, lat: 13.820772 },
    { lng: 100.536902, lat: 13.820458 },
    { lng: 100.536866, lat: 13.820307 },
    { lng: 100.53682, lat: 13.820116 },
    { lng: 100.53671, lat: 13.819662 },
    { lng: 100.536638, lat: 13.819363 },
    { lng: 100.536605, lat: 13.819228 },
    { lng: 100.536577, lat: 13.819108 },
    { lng: 100.536503, lat: 13.818808 },
    { lng: 100.536455, lat: 13.818609 },
    { lng: 100.536434, lat: 13.81852 },
    { lng: 100.536424, lat: 13.818478 },
    { lng: 100.536085, lat: 13.817077 },
    { lng: 100.535917, lat: 13.816381 },
    { lng: 100.535829, lat: 13.816016 },
    { lng: 100.535799, lat: 13.815894 },
    { lng: 100.53578, lat: 13.815806 },
    { lng: 100.535748, lat: 13.81566 },
    { lng: 100.532224, lat: 13.800342 },
    { lng: 100.532306, lat: 13.800115 },
    { lng: 100.532862, lat: 13.799708 },
    { lng: 100.533881, lat: 13.799256 },
    { lng: 100.534918, lat: 13.798762 },
    { lng: 100.538319, lat: 13.797154 },
    { lng: 100.538485, lat: 13.797083 },
    { lng: 100.53862, lat: 13.79724 },
    { lng: 100.538825, lat: 13.797467 },
    { lng: 100.539013, lat: 13.797612 },
    { lng: 100.539207, lat: 13.797761 },
    { lng: 100.539395, lat: 13.797823 },
    { lng: 100.53964, lat: 13.79789 },
    { lng: 100.539784, lat: 13.797929 },
    { lng: 100.539957, lat: 13.797941 },
    { lng: 100.540139, lat: 13.797954 },
    { lng: 100.540651, lat: 13.797878 },
    { lng: 100.540905, lat: 13.797848 },
    { lng: 100.54117, lat: 13.797818 },
    { lng: 100.544002, lat: 13.797564 },
    { lng: 100.545007, lat: 13.797588 },
    { lng: 100.54537, lat: 13.797596 },
    { lng: 100.545631, lat: 13.797617 },
    { lng: 100.545771, lat: 13.797629 },
    { lng: 100.545914, lat: 13.797637 },
    { lng: 100.546104, lat: 13.797646 },
    { lng: 100.546926, lat: 13.797675 },
    { lng: 100.547225, lat: 13.797677 },
    { lng: 100.547803, lat: 13.797695 },
    { lng: 100.548965, lat: 13.79774 },
    { lng: 100.549528, lat: 13.797753 },
    { lng: 100.549869, lat: 13.797766 },
    { lng: 100.550046, lat: 13.797774 },
    { lng: 100.550564, lat: 13.797804 },
    { lng: 100.550834, lat: 13.797878 },
    { lng: 100.55109, lat: 13.798042 },
    { lng: 100.556106, lat: 13.806728 },
    { lng: 100.55918, lat: 13.811841 },
    { lng: 100.559357, lat: 13.812078 },
    { lng: 100.559676, lat: 13.812722 },
    { lng: 100.559752, lat: 13.812826 },
    { lng: 100.559935, lat: 13.813073 },
    { lng: 100.560211, lat: 13.813546 },
    { lng: 100.561678, lat: 13.812753 },
    { lng: 100.563254, lat: 13.811863 },
    { lng: 100.566153, lat: 13.810273 },
    { lng: 100.571615, lat: 13.807262 },
    { lng: 100.57818, lat: 13.80351 },
    { lng: 100.5815, lat: 13.801683 },
    { lng: 100.583348, lat: 13.800649 },
    { lng: 100.584688, lat: 13.799889 },
    { lng: 100.58478, lat: 13.79996 },
    { lng: 100.584846, lat: 13.800041 },
    { lng: 100.584788, lat: 13.800694 },
    { lng: 100.585626, lat: 13.800697 },
    { lng: 100.586301, lat: 13.800789 },
    { lng: 100.58685, lat: 13.800865 },
    { lng: 100.587322, lat: 13.800931 },
    { lng: 100.588176, lat: 13.801049 },
    { lng: 100.588834, lat: 13.801171 },
    { lng: 100.588627, lat: 13.80199 },
    { lng: 100.588215, lat: 13.803023 },
    { lng: 100.588293, lat: 13.803139 },
    { lng: 100.58851, lat: 13.8037 },
    { lng: 100.58865, lat: 13.804101 },
    { lng: 100.5887, lat: 13.804329 },
    { lng: 100.588678, lat: 13.806393 },
    { lng: 100.588706, lat: 13.808457 },
    { lng: 100.588859, lat: 13.809339 },
    { lng: 100.589042, lat: 13.810285 },
    { lng: 100.588728, lat: 13.811392 },
    { lng: 100.588333, lat: 13.812309 },
    { lng: 100.58861, lat: 13.81287 },
    { lng: 100.588865, lat: 13.813551 },
    { lng: 100.588927, lat: 13.814715 },
    { lng: 100.588951, lat: 13.815163 },
    { lng: 100.58923, lat: 13.815869 },
    { lng: 100.589401, lat: 13.816836 },
    { lng: 100.589476, lat: 13.818049 },
    { lng: 100.589285, lat: 13.818734 },
    { lng: 100.589219, lat: 13.819001 },
    { lng: 100.58914, lat: 13.819545 },
    { lng: 100.589116, lat: 13.819702 },
    { lng: 100.589223, lat: 13.821041 },
    { lng: 100.58927, lat: 13.821811 },
    { lng: 100.589245, lat: 13.82288 },
    { lng: 100.589742, lat: 13.826729 },
    { lng: 100.589785, lat: 13.83184 },
    { lng: 100.590901, lat: 13.836657 },
    { lng: 100.590375, lat: 13.844414 },
    { lng: 100.593343, lat: 13.845021 },
    { lng: 100.596281, lat: 13.845686 },
    { lng: 100.602511, lat: 13.846729 },
    { lng: 100.604484, lat: 13.848136 },
    { lng: 100.606544, lat: 13.849043 },
    { lng: 100.611481, lat: 13.849101 },
    { lng: 100.619038, lat: 13.848544 },
    { lng: 100.62299, lat: 13.84631 },
    { lng: 100.625551, lat: 13.844673 },
    { lng: 100.627894, lat: 13.843376 },
    { lng: 100.628628, lat: 13.842663 },
    { lng: 100.630773, lat: 13.842685 },
    { lng: 100.633274, lat: 13.842558 },
    { lng: 100.636422, lat: 13.843929 },
    { lng: 100.638074, lat: 13.847017 },
    { lng: 100.639726, lat: 13.850178 },
    { lng: 100.64052, lat: 13.851759 },
    { lng: 100.641271, lat: 13.853006 },
    { lng: 100.641625, lat: 13.853925 },
    { lng: 100.641845, lat: 13.854885 },
    { lng: 100.64214, lat: 13.857467 },
    { lng: 100.642614, lat: 13.858582 },
    { lng: 100.642906, lat: 13.85908 },
    { lng: 100.643149, lat: 13.859505 },
    { lng: 100.643375, lat: 13.859786 },
    { lng: 100.643705, lat: 13.860228 },
    { lng: 100.644056, lat: 13.860726 },
    { lng: 100.644294, lat: 13.861118 },
    { lng: 100.644526, lat: 13.86152 },
    { lng: 100.644711, lat: 13.861944 },
    { lng: 100.644852, lat: 13.86235 },
    { lng: 100.645035, lat: 13.863042 },
    { lng: 100.645131, lat: 13.863411 },
    { lng: 100.645148, lat: 13.863479 },
    { lng: 100.645176, lat: 13.863614 },
    { lng: 100.645212, lat: 13.863808 },
    { lng: 100.64522, lat: 13.863844 },
    { lng: 100.645345, lat: 13.864763 },
    { lng: 100.645383, lat: 13.865054 },
    { lng: 100.645423, lat: 13.865381 },
    { lng: 100.645637, lat: 13.865637 },
    { lng: 100.645802, lat: 13.865868 },
    { lng: 100.645904, lat: 13.86601 },
    { lng: 100.646016, lat: 13.866167 },
    { lng: 100.646169, lat: 13.866386 },
    { lng: 100.646279, lat: 13.866556 },
    { lng: 100.646435, lat: 13.866803 },
    { lng: 100.646471, lat: 13.866882 },
    { lng: 100.646532, lat: 13.867023 },
    { lng: 100.646556, lat: 13.867078 },
    { lng: 100.64686, lat: 13.867961 },
    { lng: 100.647312, lat: 13.871277 },
    { lng: 100.64875, lat: 13.876442 },
    { lng: 100.680561, lat: 13.891961 },
    { lng: 100.68594, lat: 13.889462 },
    { lng: 100.686988, lat: 13.888837 },
    { lng: 100.691469, lat: 13.889962 },
    { lng: 100.701229, lat: 13.891659 },
    { lng: 100.707674, lat: 13.892882 },
    { lng: 100.713171, lat: 13.893962 },
    { lng: 100.721984, lat: 13.895721 },
    { lng: 100.721959, lat: 13.896242 },
    { lng: 100.721937, lat: 13.896891 },
    { lng: 100.721928, lat: 13.897007 },
    { lng: 100.721892, lat: 13.898201 },
    { lng: 100.72187, lat: 13.898937 },
    { lng: 100.721866, lat: 13.899074 },
    { lng: 100.721879, lat: 13.900114 },
    { lng: 100.721875, lat: 13.900806 },
    { lng: 100.721863, lat: 13.901538 },
    { lng: 100.721848, lat: 13.902149 },
    { lng: 100.721837, lat: 13.902718 },
    { lng: 100.721793, lat: 13.902986 },
    { lng: 100.721771, lat: 13.903799 },
    { lng: 100.721733, lat: 13.905284 },
    { lng: 100.721637, lat: 13.911593 },
    { lng: 100.721629, lat: 13.912203 },
    { lng: 100.721562, lat: 13.912761 },
    { lng: 100.721455, lat: 13.913616 },
    { lng: 100.721366, lat: 13.913954 },
    { lng: 100.721192, lat: 13.914411 },
    { lng: 100.72092, lat: 13.915004 },
    { lng: 100.720661, lat: 13.915406 },
    { lng: 100.720376, lat: 13.915704 },
    { lng: 100.71986, lat: 13.91618 },
    { lng: 100.719333, lat: 13.91654 },
    { lng: 100.718064, lat: 13.917286 },
    { lng: 100.704766, lat: 13.916683 },
    { lng: 100.690645, lat: 13.916193 },
    { lng: 100.6894, lat: 13.916193 },
    { lng: 100.688285, lat: 13.916068 },
    { lng: 100.687813, lat: 13.916152 },
    { lng: 100.686829, lat: 13.916199 },
    { lng: 100.687563, lat: 13.917354 },
    { lng: 100.687181, lat: 13.918716 },
    { lng: 100.687186, lat: 13.920621 },
    { lng: 100.687295, lat: 13.921469 },
    { lng: 100.687576, lat: 13.921983 },
    { lng: 100.687895, lat: 13.923014 },
    { lng: 100.688472, lat: 13.923753 },
    { lng: 100.688638, lat: 13.924857 },
    { lng: 100.689222, lat: 13.925689 },
    { lng: 100.689334, lat: 13.926314 },
    { lng: 100.689806, lat: 13.92986 },
    { lng: 100.689742, lat: 13.930948 },
    { lng: 100.66717, lat: 13.930314 },
    { lng: 100.653328, lat: 13.93076 },
    { lng: 100.6513213, lat: 13.9308441 },
    { lng: 100.6499262, lat: 13.9309699 },
    { lng: 100.6493143, lat: 13.9309134 },
    { lng: 100.646385, lat: 13.931086 },
    { lng: 100.6448599, lat: 13.9310931 },
    { lng: 100.6434314, lat: 13.9312355 },
    { lng: 100.642484, lat: 13.931208 },
    { lng: 100.640791, lat: 13.931394 },
    { lng: 100.640116, lat: 13.931736 },
    { lng: 100.6399235, lat: 13.9319438 },
    { lng: 100.6396291, lat: 13.9321932 },
    { lng: 100.6395467, lat: 13.9328057 },
    { lng: 100.639577, lat: 13.9333869 },
    { lng: 100.6395867, lat: 13.9343439 },
    { lng: 100.6396823, lat: 13.9352385 },
    { lng: 100.6397555, lat: 13.9375275 },
    { lng: 100.6313685, lat: 13.937884 },
    { lng: 100.6255563, lat: 13.9382092 },
    { lng: 100.6254059, lat: 13.9441133 },
    { lng: 100.6253314, lat: 13.9460086 },
    { lng: 100.6254064, lat: 13.9476018 },
    { lng: 100.625038, lat: 13.948646 },
    { lng: 100.623944, lat: 13.949428 },
    { lng: 100.619109, lat: 13.95199 },
    { lng: 100.617424, lat: 13.952854 },
    { lng: 100.616416, lat: 13.953249 },
    { lng: 100.613187, lat: 13.954999 },
    { lng: 100.612865, lat: 13.955103 },
    { lng: 100.607227, lat: 13.954723 },
    { lng: 100.607425, lat: 13.953473 },
    { lng: 100.604472, lat: 13.953343 },
    { lng: 100.604532, lat: 13.95282 },
    { lng: 100.604587, lat: 13.95231 },
    { lng: 100.604636, lat: 13.951942 },
    { lng: 100.604666, lat: 13.951686 },
    { lng: 100.60476, lat: 13.95116 },
    { lng: 100.604833, lat: 13.950747 },
    { lng: 100.604864, lat: 13.95056 },
    { lng: 100.604877, lat: 13.950446 },
    { lng: 100.604936, lat: 13.949927 },
    { lng: 100.604962, lat: 13.949722 },
    { lng: 100.604983, lat: 13.949527 },
    { lng: 100.605069, lat: 13.949034 },
    { lng: 100.605161, lat: 13.94841 },
    { lng: 100.605194, lat: 13.948162 },
    { lng: 100.605259, lat: 13.947683 },
    { lng: 100.605294, lat: 13.947446 },
    { lng: 100.605322, lat: 13.947244 },
    { lng: 100.605394, lat: 13.946902 },
    { lng: 100.605407, lat: 13.946799 },
    { lng: 100.605428, lat: 13.946625 },
    { lng: 100.605439, lat: 13.946541 },
    { lng: 100.605454, lat: 13.946408 },
    { lng: 100.60548, lat: 13.946214 },
    { lng: 100.60551, lat: 13.945997 },
    { lng: 100.605526, lat: 13.945901 },
    { lng: 100.605553, lat: 13.94577 },
    { lng: 100.605568, lat: 13.945698 },
    { lng: 100.605623, lat: 13.945438 },
    { lng: 100.605644, lat: 13.945348 },
    { lng: 100.605653, lat: 13.945277 },
    { lng: 100.6057, lat: 13.944924 },
    { lng: 100.605725, lat: 13.944573 },
    { lng: 100.605731, lat: 13.944431 },
    { lng: 100.605744, lat: 13.944344 },
    { lng: 100.605774, lat: 13.944087 },
    { lng: 100.605758, lat: 13.944032 },
    { lng: 100.605638, lat: 13.944045 },
    { lng: 100.60508, lat: 13.944233 },
    { lng: 100.601089, lat: 13.946045 },
    { lng: 100.598364, lat: 13.947242 },
    { lng: 100.596025, lat: 13.948273 },
    { lng: 100.593453, lat: 13.949412 },
    { lng: 100.591895, lat: 13.950199 },
    { lng: 100.589062, lat: 13.951095 },
    { lng: 100.585683, lat: 13.951803 },
    { lng: 100.582977, lat: 13.952449 },
    { lng: 100.582738, lat: 13.952469 },
    { lng: 100.582427, lat: 13.952519 },
    { lng: 100.582158, lat: 13.952572 },
    { lng: 100.581767, lat: 13.952662 },
    { lng: 100.581396, lat: 13.95276 },
    { lng: 100.581066, lat: 13.95284 },
    { lng: 100.58036, lat: 13.953006 },
    { lng: 100.579986, lat: 13.953078 },
    { lng: 100.579843, lat: 13.953114 },
    { lng: 100.57956, lat: 13.953176 },
    { lng: 100.579398, lat: 13.953219 },
    { lng: 100.579132, lat: 13.953289 },
    { lng: 100.578971, lat: 13.953326 },
    { lng: 100.578668, lat: 13.953391 },
    { lng: 100.57855, lat: 13.953417 },
    { lng: 100.578322, lat: 13.953453 },
    { lng: 100.578153, lat: 13.953503 },
    { lng: 100.577719, lat: 13.953623 },
    { lng: 100.577466, lat: 13.953654 },
    { lng: 100.577322, lat: 13.953698 },
    { lng: 100.576678, lat: 13.953826 },
    { lng: 100.576053, lat: 13.953977 },
    { lng: 100.575854, lat: 13.954057 },
    { lng: 100.575736, lat: 13.954107 },
    { lng: 100.575659, lat: 13.954102 },
    { lng: 100.575567, lat: 13.954135 },
    { lng: 100.575388, lat: 13.954169 },
    { lng: 100.575291, lat: 13.95419 },
    { lng: 100.574953, lat: 13.954281 },
    { lng: 100.574299, lat: 13.954461 },
    { lng: 100.574175, lat: 13.954521 },
    { lng: 100.574017, lat: 13.954552 },
    { lng: 100.573733, lat: 13.95445 },
    { lng: 100.573306, lat: 13.954333 },
    { lng: 100.573127, lat: 13.954258 },
    { lng: 100.57303, lat: 13.954185 },
    { lng: 100.572652, lat: 13.95394 },
    { lng: 100.572201, lat: 13.953667 },
    { lng: 100.572008, lat: 13.953573 },
    { lng: 100.571745, lat: 13.953435 },
    { lng: 100.571429, lat: 13.953292 },
    { lng: 100.571139, lat: 13.953105 },
    { lng: 100.570959, lat: 13.952995 },
    { lng: 100.570694, lat: 13.952883 },
    { lng: 100.570399, lat: 13.952758 },
    { lng: 100.570072, lat: 13.952571 },
    { lng: 100.569841, lat: 13.952394 },
    { lng: 100.569592, lat: 13.952272 },
    { lng: 100.569206, lat: 13.952099 },
    { lng: 100.568752, lat: 13.951735 },
    { lng: 100.568543, lat: 13.951655 },
    { lng: 100.568317, lat: 13.951584 },
    { lng: 100.568164, lat: 13.951545 },
    { lng: 100.568041, lat: 13.951519 },
    { lng: 100.567794, lat: 13.95147 },
    { lng: 100.567591, lat: 13.950613 },
    { lng: 100.567119, lat: 13.948682 },
    { lng: 100.566331, lat: 13.945467 },
    { lng: 100.565982, lat: 13.943962 },
    { lng: 100.565641, lat: 13.94249 },
    { lng: 100.565303, lat: 13.941046 },
    { lng: 100.565002, lat: 13.93975 },
    { lng: 100.564796, lat: 13.938856 },
    { lng: 100.564473, lat: 13.93742 },
    { lng: 100.56408, lat: 13.935787 },
    { lng: 100.563745, lat: 13.934333 },
    { lng: 100.563469, lat: 13.933148 },
    { lng: 100.563078, lat: 13.931506 },
    { lng: 100.562671, lat: 13.929781 },
    { lng: 100.562494, lat: 13.92904 },
    { lng: 100.562272, lat: 13.928118 },
    { lng: 100.562095, lat: 13.927354 },
    { lng: 100.561972, lat: 13.926828 },
    { lng: 100.561768, lat: 13.925988 },
    { lng: 100.561485, lat: 13.924781 },
    { lng: 100.5613, lat: 13.924015 },
    { lng: 100.561143, lat: 13.923368 },
    { lng: 100.560888, lat: 13.922304 },
    { lng: 100.560639, lat: 13.921236 },
    { lng: 100.560367, lat: 13.920056 },
    { lng: 100.560144, lat: 13.919094 },
    { lng: 100.559854, lat: 13.917862 },
    { lng: 100.559681, lat: 13.917148 },
    { lng: 100.550392, lat: 13.877625 },
    { lng: 100.5502, lat: 13.876823 },
    { lng: 100.549949, lat: 13.875737 },
    { lng: 100.549752, lat: 13.874904 },
    { lng: 100.549393, lat: 13.873402 },
    { lng: 100.54906, lat: 13.871949 },
    { lng: 100.548842, lat: 13.871035 },
    { lng: 100.548679, lat: 13.870322 },
    { lng: 100.548392, lat: 13.86915 },
    { lng: 100.548054, lat: 13.867728 },
    { lng: 100.547906, lat: 13.867089 },
    { lng: 100.547735, lat: 13.866336 },
    { lng: 100.547565, lat: 13.865592 },
    { lng: 100.547305, lat: 13.864481 },
    { lng: 100.547138, lat: 13.863758 },
    { lng: 100.546934, lat: 13.86289 },
    { lng: 100.546558, lat: 13.861296 },
    { lng: 100.546076, lat: 13.859265 },
    { lng: 100.545746, lat: 13.857907 },
    { lng: 100.545563, lat: 13.85715 },
    { lng: 100.5454, lat: 13.856485 },
    { lng: 100.545332, lat: 13.856183 },
    { lng: 100.545235, lat: 13.855766 },
    { lng: 100.54501, lat: 13.854816 },
    { lng: 100.544869, lat: 13.854225 },
    { lng: 100.544724, lat: 13.853593 },
    { lng: 100.544307, lat: 13.85181 },
    { lng: 100.544145, lat: 13.85113 },
    { lng: 100.543952, lat: 13.850325 },
    { lng: 100.543877, lat: 13.849985 },
    { lng: 100.543802, lat: 13.849702 },
    { lng: 100.543788, lat: 13.849675 },
    { lng: 100.543742, lat: 13.84964 },
  ],
  zone8: [
    { lng: 100.596622, lat: 13.741024 },
    { lng: 100.597484, lat: 13.740945 },
    { lng: 100.597922, lat: 13.741042 },
    { lng: 100.598213, lat: 13.741622 },
    { lng: 100.598305, lat: 13.741902 },
    { lng: 100.598776, lat: 13.74345 },
    { lng: 100.599232, lat: 13.744419 },
    { lng: 100.59965, lat: 13.745805 },
    { lng: 100.599866, lat: 13.746521 },
    { lng: 100.600074, lat: 13.747188 },
    { lng: 100.600444, lat: 13.747629 },
    { lng: 100.601004, lat: 13.748058 },
    { lng: 100.60186, lat: 13.74882 },
    { lng: 100.602349, lat: 13.749325 },
    { lng: 100.602594, lat: 13.749582 },
    { lng: 100.602825, lat: 13.749819 },
    { lng: 100.603137, lat: 13.75013 },
    { lng: 100.603431, lat: 13.750425 },
    { lng: 100.60359, lat: 13.750586 },
    { lng: 100.604175, lat: 13.751149 },
    { lng: 100.604488, lat: 13.751459 },
    { lng: 100.604676, lat: 13.751645 },
    { lng: 100.604843, lat: 13.751816 },
    { lng: 100.60514, lat: 13.75211 },
    { lng: 100.605219, lat: 13.752162 },
    { lng: 100.605426, lat: 13.752348 },
    { lng: 100.605651, lat: 13.752546 },
    { lng: 100.606081, lat: 13.752928 },
    { lng: 100.606516, lat: 13.753318 },
    { lng: 100.606737, lat: 13.753513 },
    { lng: 100.606867, lat: 13.753628 },
    { lng: 100.606964, lat: 13.753713 },
    { lng: 100.607054, lat: 13.753794 },
    { lng: 100.607251, lat: 13.753971 },
    { lng: 100.607376, lat: 13.754075 },
    { lng: 100.607772, lat: 13.754411 },
    { lng: 100.608039, lat: 13.754645 },
    { lng: 100.608449, lat: 13.755042 },
    { lng: 100.60866, lat: 13.755248 },
    { lng: 100.608755, lat: 13.755338 },
    { lng: 100.609157, lat: 13.755717 },
    { lng: 100.610241, lat: 13.756791 },
    { lng: 100.611392, lat: 13.757766 },
    { lng: 100.612326, lat: 13.758684 },
    { lng: 100.612861, lat: 13.759203 },
    { lng: 100.613123, lat: 13.759457 },
    { lng: 100.613265, lat: 13.759595 },
    { lng: 100.613408, lat: 13.759723 },
    { lng: 100.613865, lat: 13.760128 },
    { lng: 100.614072, lat: 13.760314 },
    { lng: 100.614326, lat: 13.760538 },
    { lng: 100.614396, lat: 13.760601 },
    { lng: 100.614753, lat: 13.760783 },
    { lng: 100.615089, lat: 13.760955 },
    { lng: 100.615507, lat: 13.761114 },
    { lng: 100.61586, lat: 13.761246 },
    { lng: 100.616074, lat: 13.761327 },
    { lng: 100.616572, lat: 13.761428 },
    { lng: 100.616862, lat: 13.761486 },
    { lng: 100.61712, lat: 13.761539 },
    { lng: 100.617752, lat: 13.761586 },
    { lng: 100.618284, lat: 13.761626 },
    { lng: 100.618366, lat: 13.761633 },
    { lng: 100.618987, lat: 13.761717 },
    { lng: 100.619326, lat: 13.761764 },
    { lng: 100.619674, lat: 13.761812 },
    { lng: 100.620044, lat: 13.761867 },
    { lng: 100.62022, lat: 13.761894 },
    { lng: 100.620351, lat: 13.761914 },
    { lng: 100.620495, lat: 13.761939 },
    { lng: 100.620859, lat: 13.762003 },
    { lng: 100.62115, lat: 13.762054 },
    { lng: 100.621435, lat: 13.762105 },
    { lng: 100.621623, lat: 13.762138 },
    { lng: 100.621902, lat: 13.762206 },
    { lng: 100.622132, lat: 13.762262 },
    { lng: 100.622485, lat: 13.76227 },
    { lng: 100.622717, lat: 13.762276 },
    { lng: 100.622916, lat: 13.76228 },
    { lng: 100.623461, lat: 13.762291 },
    { lng: 100.623742, lat: 13.762297 },
    { lng: 100.624017, lat: 13.762304 },
    { lng: 100.624539, lat: 13.762364 },
    { lng: 100.624856, lat: 13.7624 },
    { lng: 100.625085, lat: 13.762426 },
    { lng: 100.62565, lat: 13.762493 },
    { lng: 100.62592, lat: 13.762524 },
    { lng: 100.626266, lat: 13.762555 },
    { lng: 100.626653, lat: 13.76259 },
    { lng: 100.626918, lat: 13.762613 },
    { lng: 100.62728, lat: 13.762647 },
    { lng: 100.627599, lat: 13.762675 },
    { lng: 100.628014, lat: 13.762729 },
    { lng: 100.628249, lat: 13.76276 },
    { lng: 100.628712, lat: 13.762821 },
    { lng: 100.629149, lat: 13.762878 },
    { lng: 100.62973, lat: 13.762955 },
    { lng: 100.630021, lat: 13.762997 },
    { lng: 100.630442, lat: 13.763061 },
    { lng: 100.630406, lat: 13.763354 },
    { lng: 100.630357, lat: 13.763767 },
    { lng: 100.63032, lat: 13.76409 },
    { lng: 100.630243, lat: 13.764493 },
    { lng: 100.630211, lat: 13.764666 },
    { lng: 100.630174, lat: 13.764854 },
    { lng: 100.630117, lat: 13.765157 },
    { lng: 100.63009, lat: 13.765303 },
    { lng: 100.630028, lat: 13.765639 },
    { lng: 100.629995, lat: 13.76582 },
    { lng: 100.629964, lat: 13.765987 },
    { lng: 100.629934, lat: 13.766152 },
    { lng: 100.629896, lat: 13.76636 },
    { lng: 100.629865, lat: 13.76653 },
    { lng: 100.629831, lat: 13.766711 },
    { lng: 100.629772, lat: 13.767035 },
    { lng: 100.629712, lat: 13.767365 },
    { lng: 100.629696, lat: 13.767453 },
    { lng: 100.629669, lat: 13.767597 },
    { lng: 100.629646, lat: 13.767722 },
    { lng: 100.629586, lat: 13.768048 },
    { lng: 100.629549, lat: 13.768251 },
    { lng: 100.629528, lat: 13.768367 },
    { lng: 100.629489, lat: 13.768576 },
    { lng: 100.629457, lat: 13.76875 },
    { lng: 100.629423, lat: 13.768936 },
    { lng: 100.62937, lat: 13.769223 },
    { lng: 100.629328, lat: 13.76945 },
    { lng: 100.629272, lat: 13.769716 },
    { lng: 100.62926, lat: 13.769775 },
    { lng: 100.629242, lat: 13.769861 },
    { lng: 100.629206, lat: 13.770033 },
    { lng: 100.629168, lat: 13.77022 },
    { lng: 100.629137, lat: 13.770377 },
    { lng: 100.629107, lat: 13.770528 },
    { lng: 100.629053, lat: 13.770805 },
    { lng: 100.629004, lat: 13.771055 },
    { lng: 100.628965, lat: 13.771252 },
    { lng: 100.628929, lat: 13.771436 },
    { lng: 100.628898, lat: 13.771591 },
    { lng: 100.628879, lat: 13.77169 },
    { lng: 100.628869, lat: 13.771752 },
    { lng: 100.628849, lat: 13.77188 },
    { lng: 100.62884, lat: 13.771935 },
    { lng: 100.628835, lat: 13.771969 },
    { lng: 100.628842, lat: 13.772198 },
    { lng: 100.628879, lat: 13.772402 },
    { lng: 100.628893, lat: 13.772494 },
    { lng: 100.628903, lat: 13.772556 },
    { lng: 100.62889, lat: 13.77266 },
    { lng: 100.628888, lat: 13.772678 },
    { lng: 100.628858, lat: 13.77281 },
    { lng: 100.628798, lat: 13.773023 },
    { lng: 100.628785, lat: 13.773071 },
    { lng: 100.628775, lat: 13.773099 },
    { lng: 100.628763, lat: 13.773135 },
    { lng: 100.628713, lat: 13.773278 },
    { lng: 100.628688, lat: 13.773389 },
    { lng: 100.628642, lat: 13.773593 },
    { lng: 100.628602, lat: 13.773772 },
    { lng: 100.628564, lat: 13.773937 },
    { lng: 100.628538, lat: 13.774057 },
    { lng: 100.628486, lat: 13.774271 },
    { lng: 100.628437, lat: 13.774474 },
    { lng: 100.628416, lat: 13.774563 },
    { lng: 100.628382, lat: 13.774726 },
    { lng: 100.628353, lat: 13.774871 },
    { lng: 100.628322, lat: 13.775025 },
    { lng: 100.628294, lat: 13.77516 },
    { lng: 100.628262, lat: 13.775314 },
    { lng: 100.628222, lat: 13.775514 },
    { lng: 100.628199, lat: 13.775622 },
    { lng: 100.628171, lat: 13.775854 },
    { lng: 100.628136, lat: 13.775996 },
    { lng: 100.62811, lat: 13.776102 },
    { lng: 100.628075, lat: 13.776224 },
    { lng: 100.62805, lat: 13.776316 },
    { lng: 100.627999, lat: 13.776465 },
    { lng: 100.627977, lat: 13.776567 },
    { lng: 100.627952, lat: 13.776816 },
    { lng: 100.627942, lat: 13.776926 },
    { lng: 100.627936, lat: 13.776987 },
    { lng: 100.627933, lat: 13.777127 },
    { lng: 100.62792, lat: 13.777197 },
    { lng: 100.627896, lat: 13.777335 },
    { lng: 100.627868, lat: 13.777497 },
    { lng: 100.627833, lat: 13.777668 },
    { lng: 100.627812, lat: 13.7778 },
    { lng: 100.627786, lat: 13.777984 },
    { lng: 100.627774, lat: 13.77806 },
    { lng: 100.627758, lat: 13.778176 },
    { lng: 100.627753, lat: 13.77821 },
    { lng: 100.627747, lat: 13.778242 },
    { lng: 100.627724, lat: 13.778355 },
    { lng: 100.627694, lat: 13.778508 },
    { lng: 100.627663, lat: 13.778745 },
    { lng: 100.627631, lat: 13.778978 },
    { lng: 100.62759, lat: 13.779186 },
    { lng: 100.627539, lat: 13.779441 },
    { lng: 100.627502, lat: 13.77974 },
    { lng: 100.627477, lat: 13.779952 },
    { lng: 100.627443, lat: 13.780226 },
    { lng: 100.627442, lat: 13.780243 },
    { lng: 100.62739, lat: 13.780432 },
    { lng: 100.627336, lat: 13.78063 },
    { lng: 100.62727, lat: 13.780876 },
    { lng: 100.627232, lat: 13.781017 },
    { lng: 100.62719, lat: 13.781172 },
    { lng: 100.627084, lat: 13.781721 },
    { lng: 100.627034, lat: 13.781975 },
    { lng: 100.626996, lat: 13.782172 },
    { lng: 100.626971, lat: 13.782278 },
    { lng: 100.626932, lat: 13.782451 },
    { lng: 100.62689, lat: 13.782631 },
    { lng: 100.626868, lat: 13.782729 },
    { lng: 100.626847, lat: 13.782888 },
    { lng: 100.626837, lat: 13.782973 },
    { lng: 100.62682, lat: 13.783102 },
    { lng: 100.626808, lat: 13.783197 },
    { lng: 100.626798, lat: 13.783272 },
    { lng: 100.626792, lat: 13.783324 },
    { lng: 100.626751, lat: 13.783539 },
    { lng: 100.626717, lat: 13.783721 },
    { lng: 100.626689, lat: 13.783872 },
    { lng: 100.62666, lat: 13.784024 },
    { lng: 100.626628, lat: 13.784203 },
    { lng: 100.62661, lat: 13.784293 },
    { lng: 100.626581, lat: 13.784447 },
    { lng: 100.626516, lat: 13.784792 },
    { lng: 100.626475, lat: 13.785012 },
    { lng: 100.62645, lat: 13.785144 },
    { lng: 100.626428, lat: 13.785266 },
    { lng: 100.626405, lat: 13.785418 },
    { lng: 100.626392, lat: 13.785516 },
    { lng: 100.62637, lat: 13.785662 },
    { lng: 100.62635, lat: 13.785793 },
    { lng: 100.626328, lat: 13.785952 },
    { lng: 100.626312, lat: 13.78606 },
    { lng: 100.626291, lat: 13.786202 },
    { lng: 100.626276, lat: 13.78631 },
    { lng: 100.626263, lat: 13.786393 },
    { lng: 100.626253, lat: 13.786458 },
    { lng: 100.626233, lat: 13.786552 },
    { lng: 100.626218, lat: 13.786629 },
    { lng: 100.626197, lat: 13.786731 },
    { lng: 100.62616, lat: 13.786865 },
    { lng: 100.626138, lat: 13.786951 },
    { lng: 100.626117, lat: 13.787025 },
    { lng: 100.626109, lat: 13.787067 },
    { lng: 100.626097, lat: 13.787128 },
    { lng: 100.626054, lat: 13.787342 },
    { lng: 100.62603, lat: 13.787464 },
    { lng: 100.626011, lat: 13.787559 },
    { lng: 100.625987, lat: 13.787675 },
    { lng: 100.625977, lat: 13.787725 },
    { lng: 100.625948, lat: 13.787859 },
    { lng: 100.625927, lat: 13.787967 },
    { lng: 100.625905, lat: 13.788084 },
    { lng: 100.625888, lat: 13.788171 },
    { lng: 100.625863, lat: 13.788284 },
    { lng: 100.625839, lat: 13.788401 },
    { lng: 100.62582, lat: 13.788487 },
    { lng: 100.625802, lat: 13.788574 },
    { lng: 100.625765, lat: 13.78874 },
    { lng: 100.625742, lat: 13.788854 },
    { lng: 100.625694, lat: 13.789079 },
    { lng: 100.62566, lat: 13.789223 },
    { lng: 100.62561, lat: 13.789434 },
    { lng: 100.62557, lat: 13.789602 },
    { lng: 100.62555, lat: 13.789716 },
    { lng: 100.625519, lat: 13.789883 },
    { lng: 100.62549, lat: 13.790035 },
    { lng: 100.625476, lat: 13.790109 },
    { lng: 100.625458, lat: 13.790221 },
    { lng: 100.625439, lat: 13.790346 },
    { lng: 100.625413, lat: 13.790512 },
    { lng: 100.625404, lat: 13.790567 },
    { lng: 100.625362, lat: 13.790798 },
    { lng: 100.625339, lat: 13.790931 },
    { lng: 100.625323, lat: 13.791019 },
    { lng: 100.625307, lat: 13.791121 },
    { lng: 100.625281, lat: 13.791277 },
    { lng: 100.62526, lat: 13.79142 },
    { lng: 100.625238, lat: 13.791558 },
    { lng: 100.625215, lat: 13.791701 },
    { lng: 100.625195, lat: 13.791828 },
    { lng: 100.625142, lat: 13.792143 },
    { lng: 100.625118, lat: 13.792283 },
    { lng: 100.6251, lat: 13.792394 },
    { lng: 100.625089, lat: 13.792438 },
    { lng: 100.625059, lat: 13.792583 },
    { lng: 100.625032, lat: 13.79271 },
    { lng: 100.625005, lat: 13.792839 },
    { lng: 100.624977, lat: 13.792966 },
    { lng: 100.624953, lat: 13.793092 },
    { lng: 100.6249, lat: 13.793377 },
    { lng: 100.624871, lat: 13.793533 },
    { lng: 100.624849, lat: 13.793646 },
    { lng: 100.624809, lat: 13.793867 },
    { lng: 100.624788, lat: 13.793983 },
    { lng: 100.62478, lat: 13.794035 },
    { lng: 100.624763, lat: 13.794147 },
    { lng: 100.624751, lat: 13.794222 },
    { lng: 100.624729, lat: 13.794351 },
    { lng: 100.624704, lat: 13.794495 },
    { lng: 100.624682, lat: 13.794614 },
    { lng: 100.62467, lat: 13.794684 },
    { lng: 100.624633, lat: 13.79496 },
    { lng: 100.624603, lat: 13.795171 },
    { lng: 100.624593, lat: 13.795255 },
    { lng: 100.624517, lat: 13.79545 },
    { lng: 100.624488, lat: 13.795563 },
    { lng: 100.624454, lat: 13.795706 },
    { lng: 100.62444, lat: 13.79576 },
    { lng: 100.624427, lat: 13.79581 },
    { lng: 100.624406, lat: 13.795921 },
    { lng: 100.624383, lat: 13.796047 },
    { lng: 100.624345, lat: 13.796243 },
    { lng: 100.62432, lat: 13.796376 },
    { lng: 100.624271, lat: 13.796518 },
    { lng: 100.624211, lat: 13.796694 },
    { lng: 100.624164, lat: 13.796829 },
    { lng: 100.624081, lat: 13.797228 },
    { lng: 100.624039, lat: 13.79743 },
    { lng: 100.624012, lat: 13.797561 },
    { lng: 100.624061, lat: 13.797828 },
    { lng: 100.623975, lat: 13.798301 },
    { lng: 100.623932, lat: 13.798537 },
    { lng: 100.623881, lat: 13.798818 },
    { lng: 100.623821, lat: 13.799153 },
    { lng: 100.623785, lat: 13.799353 },
    { lng: 100.623759, lat: 13.799501 },
    { lng: 100.623723, lat: 13.799692 },
    { lng: 100.623694, lat: 13.799858 },
    { lng: 100.623656, lat: 13.800066 },
    { lng: 100.623621, lat: 13.800249 },
    { lng: 100.623575, lat: 13.800492 },
    { lng: 100.623552, lat: 13.800619 },
    { lng: 100.62352, lat: 13.800791 },
    { lng: 100.623493, lat: 13.800935 },
    { lng: 100.623458, lat: 13.80112 },
    { lng: 100.623389, lat: 13.801479 },
    { lng: 100.623364, lat: 13.801619 },
    { lng: 100.623348, lat: 13.801703 },
    { lng: 100.623314, lat: 13.801886 },
    { lng: 100.623291, lat: 13.802008 },
    { lng: 100.623256, lat: 13.802194 },
    { lng: 100.622826, lat: 13.802344 },
    { lng: 100.622432, lat: 13.802482 },
    { lng: 100.620567, lat: 13.802397 },
    { lng: 100.619666, lat: 13.802356 },
    { lng: 100.619195, lat: 13.802334 },
    { lng: 100.618749, lat: 13.802314 },
    { lng: 100.617676, lat: 13.802815 },
    { lng: 100.617124, lat: 13.803073 },
    { lng: 100.61685, lat: 13.803202 },
    { lng: 100.616624, lat: 13.803307 },
    { lng: 100.617639, lat: 13.805549 },
    { lng: 100.618792, lat: 13.808217 },
    { lng: 100.619544, lat: 13.809773 },
    { lng: 100.6203928, lat: 13.8109659 },
    { lng: 100.6239559, lat: 13.8165784 },
    { lng: 100.625796, lat: 13.820124 },
    { lng: 100.627118, lat: 13.822469 },
    { lng: 100.627666, lat: 13.823419 },
    { lng: 100.629229, lat: 13.827178 },
    { lng: 100.63072, lat: 13.833074 },
    { lng: 100.636427, lat: 13.843926 },
    { lng: 100.635905, lat: 13.843701 },
    { lng: 100.63328, lat: 13.842555 },
    { lng: 100.630779, lat: 13.842682 },
    { lng: 100.630294, lat: 13.842684 },
    { lng: 100.629954, lat: 13.84259 },
    { lng: 100.629453, lat: 13.842584 },
    { lng: 100.628633, lat: 13.84266 },
    { lng: 100.627899, lat: 13.843373 },
    { lng: 100.626869, lat: 13.843956 },
    { lng: 100.623368, lat: 13.845798 },
    { lng: 100.622995, lat: 13.846306 },
    { lng: 100.620862, lat: 13.847899 },
    { lng: 100.619043, lat: 13.848541 },
    { lng: 100.61549, lat: 13.848889 },
    { lng: 100.610323, lat: 13.849169 },
    { lng: 100.606549, lat: 13.84904 },
    { lng: 100.60449, lat: 13.848133 },
    { lng: 100.602516, lat: 13.846726 },
    { lng: 100.599308, lat: 13.846314 },
    { lng: 100.594642, lat: 13.845318 },
    { lng: 100.590437, lat: 13.844223 },
    { lng: 100.590206, lat: 13.844162 },
    { lng: 100.589974, lat: 13.843642 },
    { lng: 100.590456, lat: 13.840352 },
    { lng: 100.590892, lat: 13.83799 },
    { lng: 100.590786, lat: 13.836129 },
    { lng: 100.589994, lat: 13.833267 },
    { lng: 100.589791, lat: 13.831836 },
    { lng: 100.589904, lat: 13.829954 },
    { lng: 100.58976, lat: 13.828697 },
    { lng: 100.589535, lat: 13.825944 },
    { lng: 100.58908, lat: 13.824374 },
    { lng: 100.589045, lat: 13.823751 },
    { lng: 100.58921, lat: 13.823116 },
    { lng: 100.589251, lat: 13.822877 },
    { lng: 100.589275, lat: 13.822028 },
    { lng: 100.589256, lat: 13.821411 },
    { lng: 100.589122, lat: 13.819699 },
    { lng: 100.589224, lat: 13.818998 },
    { lng: 100.589482, lat: 13.818046 },
    { lng: 100.589407, lat: 13.816833 },
    { lng: 100.589236, lat: 13.815866 },
    { lng: 100.588956, lat: 13.81516 },
    { lng: 100.58887, lat: 13.813548 },
    { lng: 100.588615, lat: 13.812867 },
    { lng: 100.588338, lat: 13.812306 },
    { lng: 100.588733, lat: 13.811389 },
    { lng: 100.589048, lat: 13.810282 },
    { lng: 100.588802, lat: 13.808988 },
    { lng: 100.58871, lat: 13.808448 },
    { lng: 100.588705, lat: 13.806355 },
    { lng: 100.588702, lat: 13.804635 },
    { lng: 100.588705, lat: 13.804326 },
    { lng: 100.588656, lat: 13.804098 },
    { lng: 100.588438, lat: 13.803504 },
    { lng: 100.588359, lat: 13.803293 },
    { lng: 100.588298, lat: 13.803136 },
    { lng: 100.588221, lat: 13.80302 },
    { lng: 100.58831, lat: 13.802794 },
    { lng: 100.588465, lat: 13.802412 },
    { lng: 100.588578, lat: 13.802124 },
    { lng: 100.588631, lat: 13.801989 },
    { lng: 100.588656, lat: 13.801904 },
    { lng: 100.588691, lat: 13.801778 },
    { lng: 100.588735, lat: 13.801628 },
    { lng: 100.58884, lat: 13.801168 },
    { lng: 100.588181, lat: 13.801046 },
    { lng: 100.585631, lat: 13.800694 },
    { lng: 100.584793, lat: 13.800691 },
    { lng: 100.584851, lat: 13.800038 },
    { lng: 100.584786, lat: 13.799957 },
    { lng: 100.584694, lat: 13.799886 },
    { lng: 100.582201, lat: 13.801296 },
    { lng: 100.579682, lat: 13.802679 },
    { lng: 100.578951, lat: 13.803088 },
    { lng: 100.577488, lat: 13.803886 },
    { lng: 100.574676, lat: 13.805472 },
    { lng: 100.57347, lat: 13.806176 },
    { lng: 100.572262, lat: 13.806864 },
    { lng: 100.569836, lat: 13.80821 },
    { lng: 100.568528, lat: 13.808944 },
    { lng: 100.567219, lat: 13.809686 },
    { lng: 100.565898, lat: 13.810407 },
    { lng: 100.564561, lat: 13.811143 },
    { lng: 100.563633, lat: 13.811648 },
    { lng: 100.562714, lat: 13.812171 },
    { lng: 100.561875, lat: 13.812643 },
    { lng: 100.561047, lat: 13.813107 },
    { lng: 100.560901, lat: 13.813176 },
    { lng: 100.560691, lat: 13.813288 },
    { lng: 100.560365, lat: 13.813465 },
    { lng: 100.560289, lat: 13.813506 },
    { lng: 100.560216, lat: 13.813543 },
    { lng: 100.559941, lat: 13.81307 },
    { lng: 100.559681, lat: 13.812719 },
    { lng: 100.559363, lat: 13.812074 },
    { lng: 100.559186, lat: 13.811837 },
    { lng: 100.559068, lat: 13.811643 },
    { lng: 100.55901, lat: 13.811548 },
    { lng: 100.558812, lat: 13.811217 },
    { lng: 100.558584, lat: 13.810837 },
    { lng: 100.558502, lat: 13.810701 },
    { lng: 100.558315, lat: 13.810389 },
    { lng: 100.558161, lat: 13.810133 },
    { lng: 100.558007, lat: 13.809878 },
    { lng: 100.557932, lat: 13.809752 },
    { lng: 100.557848, lat: 13.809613 },
    { lng: 100.557689, lat: 13.809349 },
    { lng: 100.557609, lat: 13.809216 },
    { lng: 100.557519, lat: 13.809065 },
    { lng: 100.55738, lat: 13.808833 },
    { lng: 100.557272, lat: 13.808654 },
    { lng: 100.557184, lat: 13.808507 },
    { lng: 100.55703, lat: 13.808252 },
    { lng: 100.556932, lat: 13.808089 },
    { lng: 100.556869, lat: 13.807984 },
    { lng: 100.556688, lat: 13.807683 },
    { lng: 100.556571, lat: 13.807487 },
    { lng: 100.556478, lat: 13.807334 },
    { lng: 100.556282, lat: 13.807008 },
    { lng: 100.556143, lat: 13.806777 },
    { lng: 100.556075, lat: 13.80666 },
    { lng: 100.555939, lat: 13.806424 },
    { lng: 100.55588, lat: 13.806324 },
    { lng: 100.555756, lat: 13.806108 },
    { lng: 100.555645, lat: 13.805915 },
    { lng: 100.555511, lat: 13.805685 },
    { lng: 100.555436, lat: 13.805554 },
    { lng: 100.555381, lat: 13.805458 },
    { lng: 100.555136, lat: 13.805035 },
    { lng: 100.555006, lat: 13.80481 },
    { lng: 100.554887, lat: 13.804605 },
    { lng: 100.554595, lat: 13.804097 },
    { lng: 100.554462, lat: 13.803866 },
    { lng: 100.554298, lat: 13.803583 },
    { lng: 100.553856, lat: 13.802819 },
    { lng: 100.553262, lat: 13.801789 },
    { lng: 100.552629, lat: 13.800694 },
    { lng: 100.552399, lat: 13.800295 },
    { lng: 100.55227, lat: 13.800071 },
    { lng: 100.552148, lat: 13.799861 },
    { lng: 100.551096, lat: 13.798039 },
    { lng: 100.550839, lat: 13.797874 },
    { lng: 100.55057, lat: 13.7978 },
    { lng: 100.549916, lat: 13.797763 },
    { lng: 100.547843, lat: 13.797693 },
    { lng: 100.547379, lat: 13.797679 },
    { lng: 100.547232, lat: 13.797675 },
    { lng: 100.546931, lat: 13.797672 },
    { lng: 100.546376, lat: 13.797652 },
    { lng: 100.546103, lat: 13.797644 },
    { lng: 100.54578, lat: 13.797627 },
    { lng: 100.545375, lat: 13.797593 },
    { lng: 100.544007, lat: 13.797561 },
    { lng: 100.542656, lat: 13.797684 },
    { lng: 100.541974, lat: 13.797743 },
    { lng: 100.541595, lat: 13.797779 },
    { lng: 100.541274, lat: 13.797807 },
    { lng: 100.541175, lat: 13.797816 },
    { lng: 100.540996, lat: 13.797835 },
    { lng: 100.540681, lat: 13.797872 },
    { lng: 100.540144, lat: 13.797951 },
    { lng: 100.53979, lat: 13.797926 },
    { lng: 100.53939, lat: 13.797817 },
    { lng: 100.539213, lat: 13.797758 },
    { lng: 100.53883, lat: 13.797464 },
    { lng: 100.538755, lat: 13.7974 },
    { lng: 100.538537, lat: 13.797158 },
    { lng: 100.538485, lat: 13.797083 },
    { lng: 100.538319, lat: 13.797154 },
    { lng: 100.537336, lat: 13.797618 },
    { lng: 100.536919, lat: 13.796761 },
    { lng: 100.536747, lat: 13.796373 },
    { lng: 100.536579, lat: 13.79597 },
    { lng: 100.536491, lat: 13.795751 },
    { lng: 100.536384, lat: 13.795485 },
    { lng: 100.53626, lat: 13.795171 },
    { lng: 100.536114, lat: 13.794812 },
    { lng: 100.535981, lat: 13.794481 },
    { lng: 100.535868, lat: 13.794202 },
    { lng: 100.535714, lat: 13.79382 },
    { lng: 100.53559, lat: 13.793512 },
    { lng: 100.535489, lat: 13.793265 },
    { lng: 100.535414, lat: 13.793081 },
    { lng: 100.535316, lat: 13.792838 },
    { lng: 100.535179, lat: 13.792493 },
    { lng: 100.535107, lat: 13.792311 },
    { lng: 100.53507, lat: 13.792217 },
    { lng: 100.534971, lat: 13.791968 },
    { lng: 100.534914, lat: 13.791825 },
    { lng: 100.534845, lat: 13.791648 },
    { lng: 100.534782, lat: 13.791491 },
    { lng: 100.534681, lat: 13.791232 },
    { lng: 100.534587, lat: 13.790999 },
    { lng: 100.534537, lat: 13.790871 },
    { lng: 100.534487, lat: 13.790744 },
    { lng: 100.534418, lat: 13.79057 },
    { lng: 100.534352, lat: 13.790404 },
    { lng: 100.534235, lat: 13.79011 },
    { lng: 100.534141, lat: 13.789871 },
    { lng: 100.534055, lat: 13.789653 },
    { lng: 100.533968, lat: 13.789436 },
    { lng: 100.533886, lat: 13.789226 },
    { lng: 100.533763, lat: 13.788914 },
    { lng: 100.533674, lat: 13.78869 },
    { lng: 100.533581, lat: 13.788467 },
    { lng: 100.53348, lat: 13.788221 },
    { lng: 100.533402, lat: 13.788033 },
    { lng: 100.533275, lat: 13.787726 },
    { lng: 100.533152, lat: 13.787429 },
    { lng: 100.533049, lat: 13.787178 },
    { lng: 100.532969, lat: 13.786985 },
    { lng: 100.53286, lat: 13.786715 },
    { lng: 100.53278, lat: 13.786521 },
    { lng: 100.532701, lat: 13.78632 },
    { lng: 100.532615, lat: 13.786105 },
    { lng: 100.532559, lat: 13.785963 },
    { lng: 100.53249, lat: 13.78579 },
    { lng: 100.532401, lat: 13.785571 },
    { lng: 100.532274, lat: 13.78525 },
    { lng: 100.532166, lat: 13.784986 },
    { lng: 100.531996, lat: 13.784564 },
    { lng: 100.531875, lat: 13.78426 },
    { lng: 100.531806, lat: 13.784089 },
    { lng: 100.531647, lat: 13.783694 },
    { lng: 100.53148, lat: 13.783274 },
    { lng: 100.531432, lat: 13.783152 },
    { lng: 100.531342, lat: 13.782924 },
    { lng: 100.531301, lat: 13.782825 },
    { lng: 100.531259, lat: 13.78273 },
    { lng: 100.531227, lat: 13.782651 },
    { lng: 100.5312, lat: 13.782587 },
    { lng: 100.531169, lat: 13.782508 },
    { lng: 100.531105, lat: 13.782348 },
    { lng: 100.531018, lat: 13.78214 },
    { lng: 100.530911, lat: 13.781886 },
    { lng: 100.530794, lat: 13.781608 },
    { lng: 100.530672, lat: 13.78132 },
    { lng: 100.530554, lat: 13.781022 },
    { lng: 100.53044, lat: 13.780734 },
    { lng: 100.530387, lat: 13.780602 },
    { lng: 100.530275, lat: 13.78032 },
    { lng: 100.530198, lat: 13.780125 },
    { lng: 100.530109, lat: 13.779902 },
    { lng: 100.529961, lat: 13.779534 },
    { lng: 100.529933, lat: 13.779464 },
    { lng: 100.529878, lat: 13.779336 },
    { lng: 100.529798, lat: 13.779149 },
    { lng: 100.529698, lat: 13.77892 },
    { lng: 100.529601, lat: 13.778695 },
    { lng: 100.52951, lat: 13.778486 },
    { lng: 100.529467, lat: 13.778385 },
    { lng: 100.52933, lat: 13.778125 },
    { lng: 100.529273, lat: 13.777983 },
    { lng: 100.52921, lat: 13.777823 },
    { lng: 100.529093, lat: 13.777528 },
    { lng: 100.529023, lat: 13.777348 },
    { lng: 100.528937, lat: 13.777122 },
    { lng: 100.528876, lat: 13.776965 },
    { lng: 100.528818, lat: 13.776811 },
    { lng: 100.528746, lat: 13.776624 },
    { lng: 100.528692, lat: 13.776479 },
    { lng: 100.528605, lat: 13.776262 },
    { lng: 100.52846, lat: 13.775894 },
    { lng: 100.528355, lat: 13.77563 },
    { lng: 100.528303, lat: 13.775507 },
    { lng: 100.528034, lat: 13.774851 },
    { lng: 100.527883, lat: 13.774477 },
    { lng: 100.527766, lat: 13.77418 },
    { lng: 100.527548, lat: 13.773667 },
    { lng: 100.527456, lat: 13.773443 },
    { lng: 100.527039, lat: 13.772415 },
    { lng: 100.526826, lat: 13.771914 },
    { lng: 100.526705, lat: 13.771605 },
    { lng: 100.526628, lat: 13.771418 },
    { lng: 100.526484, lat: 13.771078 },
    { lng: 100.526345, lat: 13.770705 },
    { lng: 100.52618, lat: 13.770315 },
    { lng: 100.526045, lat: 13.769987 },
    { lng: 100.525876, lat: 13.769559 },
    { lng: 100.525751, lat: 13.769229 },
    { lng: 100.525621, lat: 13.768934 },
    { lng: 100.525508, lat: 13.768633 },
    { lng: 100.52539, lat: 13.768336 },
    { lng: 100.52515, lat: 13.767738 },
    { lng: 100.525068, lat: 13.767531 },
    { lng: 100.524947, lat: 13.767239 },
    { lng: 100.52478, lat: 13.766834 },
    { lng: 100.524333, lat: 13.765713 },
    { lng: 100.523983, lat: 13.764873 },
    { lng: 100.523854, lat: 13.764535 },
    { lng: 100.523596, lat: 13.763897 },
    { lng: 100.523385, lat: 13.763392 },
    { lng: 100.523188, lat: 13.762932 },
    { lng: 100.523063, lat: 13.76263 },
    { lng: 100.522854, lat: 13.762161 },
    { lng: 100.522738, lat: 13.761859 },
    { lng: 100.522626, lat: 13.761573 },
    { lng: 100.522576, lat: 13.761446 },
    { lng: 100.522485, lat: 13.761208 },
    { lng: 100.522324, lat: 13.760814 },
    { lng: 100.522141, lat: 13.760379 },
    { lng: 100.522074, lat: 13.760202 },
    { lng: 100.521945, lat: 13.759876 },
    { lng: 100.521835, lat: 13.75961 },
    { lng: 100.521696, lat: 13.759268 },
    { lng: 100.521542, lat: 13.75888 },
    { lng: 100.521492, lat: 13.758773 },
    { lng: 100.521404, lat: 13.758547 },
    { lng: 100.52128, lat: 13.758241 },
    { lng: 100.521125, lat: 13.757867 },
    { lng: 100.521052, lat: 13.757682 },
    { lng: 100.521033, lat: 13.757646 },
    { lng: 100.520983, lat: 13.757548 },
    { lng: 100.520916, lat: 13.757412 },
    { lng: 100.520791, lat: 13.757178 },
    { lng: 100.520638, lat: 13.756786 },
    { lng: 100.520389, lat: 13.756003 },
    { lng: 100.519928, lat: 13.754868 },
    { lng: 100.519704, lat: 13.754298 },
    { lng: 100.519506, lat: 13.753803 },
    { lng: 100.519204, lat: 13.753096 },
    { lng: 100.518986, lat: 13.752612 },
    { lng: 100.519878, lat: 13.752382 },
    { lng: 100.52029, lat: 13.75205 },
    { lng: 100.521133, lat: 13.75158 },
    { lng: 100.521912, lat: 13.751084 },
    { lng: 100.522725, lat: 13.750672 },
    { lng: 100.524427, lat: 13.749687 },
    { lng: 100.524906, lat: 13.749353 },
    { lng: 100.525252, lat: 13.749134 },
    { lng: 100.525535, lat: 13.749111 },
    { lng: 100.526054, lat: 13.749244 },
    { lng: 100.526566, lat: 13.749378 },
    { lng: 100.527079, lat: 13.749491 },
    { lng: 100.527661, lat: 13.749552 },
    { lng: 100.527937, lat: 13.749526 },
    { lng: 100.528233, lat: 13.749478 },
    { lng: 100.529169, lat: 13.749303 },
    { lng: 100.529638, lat: 13.749226 },
    { lng: 100.529916, lat: 13.74919 },
    { lng: 100.530112, lat: 13.74918 },
    { lng: 100.530324, lat: 13.749192 },
    { lng: 100.530585, lat: 13.749184 },
    { lng: 100.530807, lat: 13.749132 },
    { lng: 100.530936, lat: 13.749146 },
    { lng: 100.53173, lat: 13.749097 },
    { lng: 100.533474, lat: 13.749026 },
    { lng: 100.535294, lat: 13.749213 },
    { lng: 100.536269, lat: 13.74952 },
    { lng: 100.538082, lat: 13.749513 },
    { lng: 100.539053, lat: 13.749404 },
    { lng: 100.539839, lat: 13.749323 },
    { lng: 100.541096, lat: 13.749254 },
    { lng: 100.541825, lat: 13.749153 },
    { lng: 100.542842, lat: 13.748816 },
    { lng: 100.545127, lat: 13.748502 },
    { lng: 100.546211, lat: 13.74841 },
    { lng: 100.54759, lat: 13.748212 },
    { lng: 100.550561, lat: 13.748308 },
    { lng: 100.553348, lat: 13.748136 },
    { lng: 100.5557, lat: 13.748111 },
    { lng: 100.55896, lat: 13.748382 },
    { lng: 100.559785, lat: 13.748257 },
    { lng: 100.560748, lat: 13.748176 },
    { lng: 100.560986, lat: 13.748142 },
    { lng: 100.562549, lat: 13.747792 },
    { lng: 100.563319, lat: 13.747682 },
    { lng: 100.56496, lat: 13.74752 },
    { lng: 100.566064, lat: 13.747235 },
    { lng: 100.568101, lat: 13.746795 },
    { lng: 100.568288, lat: 13.746761 },
    { lng: 100.56845, lat: 13.74674 },
    { lng: 100.571112, lat: 13.74629 },
    { lng: 100.572415, lat: 13.746081 },
    { lng: 100.573718, lat: 13.745711 },
    { lng: 100.575346, lat: 13.745392 },
    { lng: 100.576346, lat: 13.745224 },
    { lng: 100.578653, lat: 13.744729 },
    { lng: 100.579779, lat: 13.744516 },
    { lng: 100.58051, lat: 13.744338 },
    { lng: 100.58121, lat: 13.74422 },
    { lng: 100.582592, lat: 13.743846 },
    { lng: 100.584287, lat: 13.743431 },
    { lng: 100.585288, lat: 13.743169 },
    { lng: 100.585878, lat: 13.743048 },
    { lng: 100.587649, lat: 13.742731 },
    { lng: 100.588508, lat: 13.742609 },
    { lng: 100.589042, lat: 13.742484 },
    { lng: 100.591009, lat: 13.742093 },
    { lng: 100.592814, lat: 13.741737 },
    { lng: 100.594528, lat: 13.741412 },
    { lng: 100.596622, lat: 13.741024 },
  ],
  zone9: [
    { lng: 100.708943, lat: 13.723951 },
    { lng: 100.708741, lat: 13.725744 },
    { lng: 100.708526, lat: 13.727616 },
    { lng: 100.7084, lat: 13.729048 },
    { lng: 100.708368, lat: 13.729394 },
    { lng: 100.708361, lat: 13.729548 },
    { lng: 100.708334, lat: 13.730024 },
    { lng: 100.708338, lat: 13.730964 },
    { lng: 100.708256, lat: 13.731824 },
    { lng: 100.708272, lat: 13.731979 },
    { lng: 100.708451, lat: 13.734746 },
    { lng: 100.708614, lat: 13.737608 },
    { lng: 100.708967, lat: 13.743116 },
    { lng: 100.709087, lat: 13.744234 },
    { lng: 100.709181, lat: 13.74637 },
    { lng: 100.709239, lat: 13.747438 },
    { lng: 100.709268, lat: 13.747984 },
    { lng: 100.709299, lat: 13.748502 },
    { lng: 100.709732, lat: 13.756409 },
    { lng: 100.708255, lat: 13.756264 },
    { lng: 100.706477, lat: 13.756703 },
    { lng: 100.709355, lat: 13.761388 },
    { lng: 100.71218, lat: 13.765137 },
    { lng: 100.716262, lat: 13.772135 },
    { lng: 100.716473, lat: 13.777786 },
    { lng: 100.715465, lat: 13.780433 },
    { lng: 100.715309, lat: 13.781837 },
    { lng: 100.714269, lat: 13.783779 },
    { lng: 100.713822, lat: 13.7847 },
    { lng: 100.712585, lat: 13.7868 },
    { lng: 100.711897, lat: 13.787996 },
    { lng: 100.711236, lat: 13.78917 },
    { lng: 100.71016, lat: 13.791108 },
    { lng: 100.708595, lat: 13.793843 },
    { lng: 100.708092, lat: 13.794698 },
    { lng: 100.707985, lat: 13.794906 },
    { lng: 100.707816, lat: 13.795188 },
    { lng: 100.707696, lat: 13.795388 },
    { lng: 100.70758, lat: 13.795582 },
    { lng: 100.706472, lat: 13.79506 },
    { lng: 100.705544, lat: 13.794569 },
    { lng: 100.704579, lat: 13.794096 },
    { lng: 100.704188, lat: 13.794832 },
    { lng: 100.703861, lat: 13.795479 },
    { lng: 100.703585, lat: 13.796007 },
    { lng: 100.703297, lat: 13.796555 },
    { lng: 100.702528, lat: 13.796152 },
    { lng: 100.70169, lat: 13.795714 },
    { lng: 100.700549, lat: 13.795126 },
    { lng: 100.699735, lat: 13.794678 },
    { lng: 100.697703, lat: 13.79352 },
    { lng: 100.695525, lat: 13.792358 },
    { lng: 100.69312, lat: 13.791032 },
    { lng: 100.689031, lat: 13.788692 },
    { lng: 100.687972, lat: 13.788081 },
    { lng: 100.686185, lat: 13.787164 },
    { lng: 100.683723, lat: 13.785788 },
    { lng: 100.682677, lat: 13.78524 },
    { lng: 100.682021, lat: 13.784877 },
    { lng: 100.681385, lat: 13.784528 },
    { lng: 100.680707, lat: 13.784158 },
    { lng: 100.680391, lat: 13.783982 },
    { lng: 100.680073, lat: 13.783809 },
    { lng: 100.67879, lat: 13.783104 },
    { lng: 100.678145, lat: 13.782749 },
    { lng: 100.67782, lat: 13.782569 },
    { lng: 100.677657, lat: 13.782478 },
    { lng: 100.677415, lat: 13.782344 },
    { lng: 100.676581, lat: 13.781881 },
    { lng: 100.676174, lat: 13.781656 },
    { lng: 100.675941, lat: 13.781527 },
    { lng: 100.675853, lat: 13.781479 },
    { lng: 100.675714, lat: 13.781405 },
    { lng: 100.674484, lat: 13.780746 },
    { lng: 100.67387, lat: 13.780418 },
    { lng: 100.673263, lat: 13.780088 },
    { lng: 100.672207, lat: 13.77951 },
    { lng: 100.671732, lat: 13.77925 },
    { lng: 100.671424, lat: 13.779083 },
    { lng: 100.671111, lat: 13.778912 },
    { lng: 100.670078, lat: 13.778347 },
    { lng: 100.669556, lat: 13.778061 },
    { lng: 100.669286, lat: 13.777914 },
    { lng: 100.669037, lat: 13.777771 },
    { lng: 100.6682, lat: 13.777294 },
    { lng: 100.667753, lat: 13.777038 },
    { lng: 100.667519, lat: 13.776908 },
    { lng: 100.667319, lat: 13.776765 },
    { lng: 100.665645, lat: 13.775827 },
    { lng: 100.662056, lat: 13.773847 },
    { lng: 100.660661, lat: 13.773034 },
    { lng: 100.659481, lat: 13.772446 },
    { lng: 100.658553, lat: 13.771898 },
    { lng: 100.657802, lat: 13.771535 },
    { lng: 100.657082, lat: 13.771149 },
    { lng: 100.656736, lat: 13.770964 },
    { lng: 100.656444, lat: 13.770808 },
    { lng: 100.656118, lat: 13.770633 },
    { lng: 100.65601, lat: 13.770575 },
    { lng: 100.655913, lat: 13.770522 },
    { lng: 100.655696, lat: 13.770398 },
    { lng: 100.655499, lat: 13.770285 },
    { lng: 100.655332, lat: 13.770191 },
    { lng: 100.655156, lat: 13.770089 },
    { lng: 100.654867, lat: 13.769925 },
    { lng: 100.654588, lat: 13.769766 },
    { lng: 100.654284, lat: 13.769528 },
    { lng: 100.654009, lat: 13.769312 },
    { lng: 100.65349, lat: 13.768905 },
    { lng: 100.653249, lat: 13.768715 },
    { lng: 100.653, lat: 13.76852 },
    { lng: 100.652525, lat: 13.768152 },
    { lng: 100.652384, lat: 13.768043 },
    { lng: 100.652239, lat: 13.76793 },
    { lng: 100.65196, lat: 13.767713 },
    { lng: 100.651427, lat: 13.767299 },
    { lng: 100.651291, lat: 13.767185 },
    { lng: 100.651185, lat: 13.767094 },
    { lng: 100.650989, lat: 13.766927 },
    { lng: 100.650865, lat: 13.76682 },
    { lng: 100.650758, lat: 13.76673 },
    { lng: 100.650555, lat: 13.766558 },
    { lng: 100.650172, lat: 13.766231 },
    { lng: 100.650059, lat: 13.766136 },
    { lng: 100.649947, lat: 13.766042 },
    { lng: 100.649734, lat: 13.76586 },
    { lng: 100.649318, lat: 13.765571 },
    { lng: 100.648954, lat: 13.765435 },
    { lng: 100.648746, lat: 13.765357 },
    { lng: 100.648337, lat: 13.765203 },
    { lng: 100.648131, lat: 13.765144 },
    { lng: 100.647843, lat: 13.76506 },
    { lng: 100.647556, lat: 13.764977 },
    { lng: 100.646931, lat: 13.764931 },
    { lng: 100.646403, lat: 13.764881 },
    { lng: 100.645886, lat: 13.76483 },
    { lng: 100.645432, lat: 13.764786 },
    { lng: 100.64492, lat: 13.764735 },
    { lng: 100.644288, lat: 13.764667 },
    { lng: 100.643404, lat: 13.764573 },
    { lng: 100.642842, lat: 13.764512 },
    { lng: 100.642328, lat: 13.764458 },
    { lng: 100.641955, lat: 13.764418 },
    { lng: 100.641673, lat: 13.764387 },
    { lng: 100.641277, lat: 13.764332 },
    { lng: 100.64099, lat: 13.764294 },
    { lng: 100.640626, lat: 13.764244 },
    { lng: 100.640314, lat: 13.764202 },
    { lng: 100.640151, lat: 13.76418 },
    { lng: 100.63999, lat: 13.764158 },
    { lng: 100.639816, lat: 13.764134 },
    { lng: 100.639587, lat: 13.764104 },
    { lng: 100.639367, lat: 13.764077 },
    { lng: 100.639146, lat: 13.76405 },
    { lng: 100.638872, lat: 13.764016 },
    { lng: 100.638719, lat: 13.763997 },
    { lng: 100.638574, lat: 13.76398 },
    { lng: 100.638316, lat: 13.763948 },
    { lng: 100.63812, lat: 13.763923 },
    { lng: 100.636659, lat: 13.763778 },
    { lng: 100.635333, lat: 13.76359 },
    { lng: 100.63425, lat: 13.763465 },
    { lng: 100.633774, lat: 13.763407 },
    { lng: 100.633645, lat: 13.763398 },
    { lng: 100.633448, lat: 13.763385 },
    { lng: 100.633016, lat: 13.76334 },
    { lng: 100.632822, lat: 13.763318 },
    { lng: 100.632638, lat: 13.763299 },
    { lng: 100.632248, lat: 13.763258 },
    { lng: 100.63189, lat: 13.763221 },
    { lng: 100.631523, lat: 13.763182 },
    { lng: 100.6314, lat: 13.76317 },
    { lng: 100.631272, lat: 13.763156 },
    { lng: 100.631092, lat: 13.763137 },
    { lng: 100.630934, lat: 13.763121 },
    { lng: 100.630366, lat: 13.763061 },
    { lng: 100.629725, lat: 13.762968 },
    { lng: 100.627616, lat: 13.76269 },
    { lng: 100.625911, lat: 13.762536 },
    { lng: 100.624009, lat: 13.762316 },
    { lng: 100.622123, lat: 13.762274 },
    { lng: 100.621614, lat: 13.76215 },
    { lng: 100.620342, lat: 13.761926 },
    { lng: 100.619613, lat: 13.761816 },
    { lng: 100.618358, lat: 13.761644 },
    { lng: 100.617111, lat: 13.761551 },
    { lng: 100.616066, lat: 13.761339 },
    { lng: 100.61508, lat: 13.760967 },
    { lng: 100.614388, lat: 13.760613 },
    { lng: 100.613256, lat: 13.759607 },
    { lng: 100.612299, lat: 13.758679 },
    { lng: 100.61215, lat: 13.758533 },
    { lng: 100.611982, lat: 13.758367 },
    { lng: 100.611474, lat: 13.757867 },
    { lng: 100.611386, lat: 13.757781 },
    { lng: 100.611321, lat: 13.757725 },
    { lng: 100.61123, lat: 13.757648 },
    { lng: 100.611154, lat: 13.757583 },
    { lng: 100.611007, lat: 13.75746 },
    { lng: 100.610704, lat: 13.757202 },
    { lng: 100.610584, lat: 13.7571 },
    { lng: 100.610462, lat: 13.756998 },
    { lng: 100.610232, lat: 13.756803 },
    { lng: 100.610202, lat: 13.756773 },
    { lng: 100.6101, lat: 13.756672 },
    { lng: 100.609881, lat: 13.756454 },
    { lng: 100.609774, lat: 13.756349 },
    { lng: 100.609651, lat: 13.756228 },
    { lng: 100.609496, lat: 13.756074 },
    { lng: 100.609283, lat: 13.755861 },
    { lng: 100.609108, lat: 13.755691 },
    { lng: 100.608654, lat: 13.755262 },
    { lng: 100.608366, lat: 13.754983 },
    { lng: 100.60803, lat: 13.754657 },
    { lng: 100.607731, lat: 13.754394 },
    { lng: 100.607243, lat: 13.753982 },
    { lng: 100.606875, lat: 13.753654 },
    { lng: 100.606526, lat: 13.753347 },
    { lng: 100.605975, lat: 13.752854 },
    { lng: 100.605704, lat: 13.752613 },
    { lng: 100.605421, lat: 13.752362 },
    { lng: 100.605324, lat: 13.752275 },
    { lng: 100.605215, lat: 13.752178 },
    { lng: 100.605094, lat: 13.752058 },
    { lng: 100.604952, lat: 13.751916 },
    { lng: 100.604843, lat: 13.75181 },
    { lng: 100.60474, lat: 13.751702 },
    { lng: 100.60414, lat: 13.751111 },
    { lng: 100.603506, lat: 13.750493 },
    { lng: 100.602883, lat: 13.749868 },
    { lng: 100.601935, lat: 13.748888 },
    { lng: 100.601079, lat: 13.748126 },
    { lng: 100.600519, lat: 13.747696 },
    { lng: 100.600149, lat: 13.747255 },
    { lng: 100.600019, lat: 13.746835 },
    { lng: 100.599307, lat: 13.744486 },
    { lng: 100.598851, lat: 13.743517 },
    { lng: 100.598288, lat: 13.741689 },
    { lng: 100.597997, lat: 13.74111 },
    { lng: 100.59756, lat: 13.741013 },
    { lng: 100.596739, lat: 13.741086 },
    { lng: 100.592991, lat: 13.741789 },
    { lng: 100.590347, lat: 13.742306 },
    { lng: 100.588584, lat: 13.742676 },
    { lng: 100.587724, lat: 13.742799 },
    { lng: 100.585363, lat: 13.743237 },
    { lng: 100.584556, lat: 13.743447 },
    { lng: 100.583651, lat: 13.743675 },
    { lng: 100.582696, lat: 13.743908 },
    { lng: 100.582214, lat: 13.744042 },
    { lng: 100.581285, lat: 13.744288 },
    { lng: 100.580585, lat: 13.744406 },
    { lng: 100.58037, lat: 13.744457 },
    { lng: 100.57971, lat: 13.744614 },
    { lng: 100.578469, lat: 13.744851 },
    { lng: 100.577387, lat: 13.745084 },
    { lng: 100.576421, lat: 13.745292 },
    { lng: 100.57541, lat: 13.745461 },
    { lng: 100.575063, lat: 13.745531 },
    { lng: 100.573793, lat: 13.745779 },
    { lng: 100.57249, lat: 13.746149 },
    { lng: 100.571694, lat: 13.746276 },
    { lng: 100.571288, lat: 13.746341 },
    { lng: 100.57091, lat: 13.746404 },
    { lng: 100.570126, lat: 13.746537 },
    { lng: 100.568525, lat: 13.746808 },
    { lng: 100.568363, lat: 13.746829 },
    { lng: 100.568176, lat: 13.746862 },
    { lng: 100.566801, lat: 13.747162 },
    { lng: 100.566139, lat: 13.747302 },
    { lng: 100.565035, lat: 13.747588 },
    { lng: 100.563394, lat: 13.747749 },
    { lng: 100.562658, lat: 13.747849 },
    { lng: 100.562094, lat: 13.747978 },
    { lng: 100.561062, lat: 13.74821 },
    { lng: 100.560823, lat: 13.748243 },
    { lng: 100.55986, lat: 13.748324 },
    { lng: 100.559035, lat: 13.74845 },
    { lng: 100.558555, lat: 13.748409 },
    { lng: 100.556554, lat: 13.748247 },
    { lng: 100.555736, lat: 13.748178 },
    { lng: 100.554131, lat: 13.748194 },
    { lng: 100.553423, lat: 13.748204 },
    { lng: 100.553015, lat: 13.748226 },
    { lng: 100.552344, lat: 13.748274 },
    { lng: 100.550817, lat: 13.748372 },
    { lng: 100.550636, lat: 13.748376 },
    { lng: 100.550493, lat: 13.748379 },
    { lng: 100.547665, lat: 13.74828 },
    { lng: 100.546342, lat: 13.748475 },
    { lng: 100.545505, lat: 13.748546 },
    { lng: 100.545202, lat: 13.74857 },
    { lng: 100.544937, lat: 13.748608 },
    { lng: 100.544661, lat: 13.748643 },
    { lng: 100.544432, lat: 13.74868 },
    { lng: 100.543657, lat: 13.748781 },
    { lng: 100.542917, lat: 13.748883 },
    { lng: 100.542416, lat: 13.749048 },
    { lng: 100.5419, lat: 13.749221 },
    { lng: 100.541171, lat: 13.749322 },
    { lng: 100.539787, lat: 13.749402 },
    { lng: 100.538158, lat: 13.74958 },
    { lng: 100.536344, lat: 13.749588 },
    { lng: 100.535369, lat: 13.749281 },
    { lng: 100.533549, lat: 13.749094 },
    { lng: 100.531805, lat: 13.749165 },
    { lng: 100.531011, lat: 13.749214 },
    { lng: 100.530882, lat: 13.7492 },
    { lng: 100.53066, lat: 13.749251 },
    { lng: 100.5304, lat: 13.74926 },
    { lng: 100.530187, lat: 13.749248 },
    { lng: 100.529992, lat: 13.749258 },
    { lng: 100.529713, lat: 13.749294 },
    { lng: 100.529235, lat: 13.749372 },
    { lng: 100.528308, lat: 13.749546 },
    { lng: 100.528012, lat: 13.749594 },
    { lng: 100.527736, lat: 13.749619 },
    { lng: 100.527154, lat: 13.749558 },
    { lng: 100.52561, lat: 13.749178 },
    { lng: 100.525327, lat: 13.749202 },
    { lng: 100.524406, lat: 13.749807 },
    { lng: 100.524106, lat: 13.749293 },
    { lng: 100.523973, lat: 13.748737 },
    { lng: 100.523711, lat: 13.747548 },
    { lng: 100.523402, lat: 13.745512 },
    { lng: 100.522796, lat: 13.742772 },
    { lng: 100.522553, lat: 13.741172 },
    { lng: 100.522287, lat: 13.739599 },
    { lng: 100.522256, lat: 13.739434 },
    { lng: 100.521929, lat: 13.737761 },
    { lng: 100.521743, lat: 13.736677 },
    { lng: 100.521661, lat: 13.736143 },
    { lng: 100.521597, lat: 13.735756 },
    { lng: 100.522062, lat: 13.735572 },
    { lng: 100.523292, lat: 13.735079 },
    { lng: 100.524454, lat: 13.734618 },
    { lng: 100.525677, lat: 13.734104 },
    { lng: 100.526333, lat: 13.733824 },
    { lng: 100.526929, lat: 13.733582 },
    { lng: 100.52823, lat: 13.733053 },
    { lng: 100.529375, lat: 13.732582 },
    { lng: 100.530461, lat: 13.732142 },
    { lng: 100.531287, lat: 13.731797 },
    { lng: 100.532828, lat: 13.731149 },
    { lng: 100.53522, lat: 13.73015 },
    { lng: 100.537132, lat: 13.729348 },
    { lng: 100.539057, lat: 13.728541 },
    { lng: 100.540029, lat: 13.728134 },
    { lng: 100.541012, lat: 13.727723 },
    { lng: 100.542512, lat: 13.727096 },
    { lng: 100.545512, lat: 13.725853 },
    { lng: 100.54918, lat: 13.72433 },
    { lng: 100.552851, lat: 13.722799 },
    { lng: 100.554784, lat: 13.721991 },
    { lng: 100.555734, lat: 13.721594 },
    { lng: 100.556672, lat: 13.721208 },
    { lng: 100.559446, lat: 13.720066 },
    { lng: 100.56222, lat: 13.718926 },
    { lng: 100.562488, lat: 13.7189 },
    { lng: 100.563237, lat: 13.718919 },
    { lng: 100.564229, lat: 13.719294 },
    { lng: 100.565239, lat: 13.719673 },
    { lng: 100.56556, lat: 13.719605 },
    { lng: 100.566238, lat: 13.719346 },
    { lng: 100.567186, lat: 13.71894 },
    { lng: 100.569098, lat: 13.718133 },
    { lng: 100.574075, lat: 13.716028 },
    { lng: 100.576434, lat: 13.71501 },
    { lng: 100.579022, lat: 13.7139 },
    { lng: 100.581036, lat: 13.713085 },
    { lng: 100.589759, lat: 13.712762 },
    { lng: 100.591545, lat: 13.713268 },
    { lng: 100.591825, lat: 13.713422 },
    { lng: 100.592051, lat: 13.713692 },
    { lng: 100.592536, lat: 13.71428 },
    { lng: 100.59419, lat: 13.713096 },
    { lng: 100.59523, lat: 13.712314 },
    { lng: 100.596429, lat: 13.711462 },
    { lng: 100.597096, lat: 13.710985 },
    { lng: 100.597586, lat: 13.71065 },
    { lng: 100.598652, lat: 13.712246 },
    { lng: 100.599598, lat: 13.712191 },
    { lng: 100.601467, lat: 13.711223 },
    { lng: 100.601983, lat: 13.712945 },
    { lng: 100.602351, lat: 13.713409 },
    { lng: 100.60302, lat: 13.713748 },
    { lng: 100.605241, lat: 13.714176 },
    { lng: 100.605658, lat: 13.714224 },
    { lng: 100.606484, lat: 13.71542 },
    { lng: 100.607129, lat: 13.715813 },
    { lng: 100.607456, lat: 13.715906 },
    { lng: 100.608565, lat: 13.715916 },
    { lng: 100.610623, lat: 13.71559 },
    { lng: 100.611963, lat: 13.715621 },
    { lng: 100.612262, lat: 13.71539 },
    { lng: 100.612265, lat: 13.714497 },
    { lng: 100.612421, lat: 13.713934 },
    { lng: 100.612432, lat: 13.713087 },
    { lng: 100.612571, lat: 13.712875 },
    { lng: 100.612813, lat: 13.712777 },
    { lng: 100.613328, lat: 13.712532 },
    { lng: 100.613869, lat: 13.712675 },
    { lng: 100.614209, lat: 13.712927 },
    { lng: 100.614521, lat: 13.713274 },
    { lng: 100.614918, lat: 13.713245 },
    { lng: 100.615321, lat: 13.713218 },
    { lng: 100.615557, lat: 13.713052 },
    { lng: 100.615807, lat: 13.712878 },
    { lng: 100.61591, lat: 13.712491 },
    { lng: 100.616101, lat: 13.712106 },
    { lng: 100.616493, lat: 13.711361 },
    { lng: 100.615787, lat: 13.710731 },
    { lng: 100.615792, lat: 13.71034 },
    { lng: 100.615963, lat: 13.710014 },
    { lng: 100.616455, lat: 13.709843 },
    { lng: 100.617103, lat: 13.710031 },
    { lng: 100.617798, lat: 13.710312 },
    { lng: 100.618469, lat: 13.710751 },
    { lng: 100.618953, lat: 13.711545 },
    { lng: 100.619924, lat: 13.711505 },
    { lng: 100.620581, lat: 13.710859 },
    { lng: 100.620888, lat: 13.710348 },
    { lng: 100.6213, lat: 13.710302 },
    { lng: 100.621419, lat: 13.712342 },
    { lng: 100.621672, lat: 13.712679 },
    { lng: 100.62193, lat: 13.713028 },
    { lng: 100.622142, lat: 13.713247 },
    { lng: 100.622162, lat: 13.713357 },
    { lng: 100.622197, lat: 13.713543 },
    { lng: 100.62226, lat: 13.713889 },
    { lng: 100.622377, lat: 13.714523 },
    { lng: 100.623138, lat: 13.714453 },
    { lng: 100.623905, lat: 13.714751 },
    { lng: 100.624299, lat: 13.714546 },
    { lng: 100.624507, lat: 13.713732 },
    { lng: 100.624693, lat: 13.713034 },
    { lng: 100.624731, lat: 13.712922 },
    { lng: 100.625292, lat: 13.712725 },
    { lng: 100.625679, lat: 13.712334 },
    { lng: 100.625959, lat: 13.712441 },
    { lng: 100.62627, lat: 13.712953 },
    { lng: 100.626459, lat: 13.71309 },
    { lng: 100.626962, lat: 13.713469 },
    { lng: 100.62749, lat: 13.713935 },
    { lng: 100.628038, lat: 13.714416 },
    { lng: 100.628125, lat: 13.714962 },
    { lng: 100.628405, lat: 13.715099 },
    { lng: 100.628963, lat: 13.715189 },
    { lng: 100.629414, lat: 13.715463 },
    { lng: 100.630712, lat: 13.715183 },
    { lng: 100.632016, lat: 13.714876 },
    { lng: 100.632528, lat: 13.714587 },
    { lng: 100.633049, lat: 13.714746 },
    { lng: 100.633857, lat: 13.714995 },
    { lng: 100.634812, lat: 13.71472 },
    { lng: 100.635482, lat: 13.714551 },
    { lng: 100.63664, lat: 13.71471 },
    { lng: 100.637435, lat: 13.714678 },
    { lng: 100.637494, lat: 13.714677 },
    { lng: 100.638023, lat: 13.714716 },
    { lng: 100.639288, lat: 13.715026 },
    { lng: 100.640424, lat: 13.715307 },
    { lng: 100.642982, lat: 13.715928 },
    { lng: 100.644264, lat: 13.716255 },
    { lng: 100.645298, lat: 13.716503 },
    { lng: 100.64715, lat: 13.716968 },
    { lng: 100.653747, lat: 13.71878 },
    { lng: 100.656651, lat: 13.719476 },
    { lng: 100.657513, lat: 13.719692 },
    { lng: 100.659553, lat: 13.720245 },
    { lng: 100.660309, lat: 13.720452 },
    { lng: 100.666088, lat: 13.721943 },
    { lng: 100.672342, lat: 13.723578 },
    { lng: 100.673882, lat: 13.723969 },
    { lng: 100.674512, lat: 13.723963 },
    { lng: 100.675142, lat: 13.723976 },
    { lng: 100.675792, lat: 13.723991 },
    { lng: 100.676131, lat: 13.723999 },
    { lng: 100.676291, lat: 13.724002 },
    { lng: 100.676435, lat: 13.724002 },
    { lng: 100.678998, lat: 13.723992 },
    { lng: 100.684112, lat: 13.723975 },
    { lng: 100.684482, lat: 13.723974 },
    { lng: 100.685081, lat: 13.723972 },
    { lng: 100.685406, lat: 13.72397 },
    { lng: 100.686935, lat: 13.723971 },
    { lng: 100.687943, lat: 13.723968 },
    { lng: 100.689673, lat: 13.723968 },
    { lng: 100.691403, lat: 13.723967 },
    { lng: 100.692507, lat: 13.723977 },
    { lng: 100.694194, lat: 13.723994 },
    { lng: 100.696859, lat: 13.724024 },
    { lng: 100.69777, lat: 13.724018 },
    { lng: 100.698838, lat: 13.724017 },
    { lng: 100.700772, lat: 13.724022 },
    { lng: 100.704654, lat: 13.724028 },
    { lng: 100.70679, lat: 13.723976 },
    { lng: 100.707901, lat: 13.723948 },
    { lng: 100.708943, lat: 13.723951 },
  ],
  zone1za: [
    { lng: 100.512786, lat: 13.76024 },
    { lng: 100.514065, lat: 13.761019 },
    { lng: 100.51543, lat: 13.761715 },
    { lng: 100.516743, lat: 13.760598 },
    { lng: 100.518045, lat: 13.759476 },
    { lng: 100.519749, lat: 13.758031 },
    { lng: 100.520624, lat: 13.757275 },
    { lng: 100.520839, lat: 13.75717 },
    { lng: 100.52086, lat: 13.757246 },
    { lng: 100.521117, lat: 13.757755 },
    { lng: 100.521189, lat: 13.75794 },
    { lng: 100.521344, lat: 13.758314 },
    { lng: 100.521469, lat: 13.75862 },
    { lng: 100.521561, lat: 13.758855 },
    { lng: 100.521606, lat: 13.758953 },
    { lng: 100.521756, lat: 13.75933 },
    { lng: 100.5219, lat: 13.759683 },
    { lng: 100.522138, lat: 13.760276 },
    { lng: 100.522226, lat: 13.7605 },
    { lng: 100.522392, lat: 13.7609 },
    { lng: 100.522549, lat: 13.761281 },
    { lng: 100.522605, lat: 13.761429 },
    { lng: 100.522802, lat: 13.761932 },
    { lng: 100.522941, lat: 13.762285 },
    { lng: 100.523281, lat: 13.763065 },
    { lng: 100.52366, lat: 13.76397 },
    { lng: 100.524397, lat: 13.765786 },
    { lng: 100.524844, lat: 13.766908 },
    { lng: 100.525011, lat: 13.767312 },
    { lng: 100.525132, lat: 13.767604 },
    { lng: 100.525239, lat: 13.76787 },
    { lng: 100.525404, lat: 13.76829 },
    { lng: 100.525542, lat: 13.768624 },
    { lng: 100.525685, lat: 13.769006 },
    { lng: 100.525816, lat: 13.769302 },
    { lng: 100.52594, lat: 13.769632 },
    { lng: 100.526101, lat: 13.770037 },
    { lng: 100.526245, lat: 13.770388 },
    { lng: 100.52641, lat: 13.770778 },
    { lng: 100.526548, lat: 13.77115 },
    { lng: 100.526752, lat: 13.77164 },
    { lng: 100.526879, lat: 13.771961 },
    { lng: 100.526935, lat: 13.772093 },
    { lng: 100.527103, lat: 13.772488 },
    { lng: 100.527153, lat: 13.772611 },
    { lng: 100.527313, lat: 13.773008 },
    { lng: 100.52752, lat: 13.773516 },
    { lng: 100.527558, lat: 13.773613 },
    { lng: 100.52783, lat: 13.774253 },
    { lng: 100.528038, lat: 13.774774 },
    { lng: 100.52842, lat: 13.775705 },
    { lng: 100.528747, lat: 13.776532 },
    { lng: 100.529143, lat: 13.777568 },
    { lng: 100.529476, lat: 13.778405 },
    { lng: 100.529936, lat: 13.77947 },
    { lng: 100.530208, lat: 13.780149 },
    { lng: 100.530626, lat: 13.781203 },
    { lng: 100.530677, lat: 13.781331 },
    { lng: 100.530749, lat: 13.781502 },
    { lng: 100.53096, lat: 13.782003 },
    { lng: 100.531108, lat: 13.782353 },
    { lng: 100.531177, lat: 13.78253 },
    { lng: 100.531262, lat: 13.782734 },
    { lng: 100.531301, lat: 13.782825 },
    { lng: 100.531346, lat: 13.782934 },
    { lng: 100.531394, lat: 13.783055 },
    { lng: 100.531434, lat: 13.783154 },
    { lng: 100.53148, lat: 13.783274 },
    { lng: 100.531581, lat: 13.783527 },
    { lng: 100.531875, lat: 13.78426 },
    { lng: 100.532429, lat: 13.785641 },
    { lng: 100.532499, lat: 13.785811 },
    { lng: 100.532595, lat: 13.786054 },
    { lng: 100.532766, lat: 13.786484 },
    { lng: 100.533066, lat: 13.78722 },
    { lng: 100.533674, lat: 13.78869 },
    { lng: 100.53532, lat: 13.79285 },
    { lng: 100.535793, lat: 13.794013 },
    { lng: 100.536017, lat: 13.794572 },
    { lng: 100.536128, lat: 13.794847 },
    { lng: 100.536247, lat: 13.795138 },
    { lng: 100.53636, lat: 13.795424 },
    { lng: 100.536428, lat: 13.795594 },
    { lng: 100.536501, lat: 13.795774 },
    { lng: 100.53655, lat: 13.7959 },
    { lng: 100.536629, lat: 13.796087 },
    { lng: 100.536735, lat: 13.796344 },
    { lng: 100.536813, lat: 13.796522 },
    { lng: 100.536919, lat: 13.796761 },
    { lng: 100.537031, lat: 13.796989 },
    { lng: 100.537336, lat: 13.797618 },
    { lng: 100.535837, lat: 13.798326 },
    { lng: 100.533875, lat: 13.799261 },
    { lng: 100.532856, lat: 13.799713 },
    { lng: 100.5323, lat: 13.80012 },
    { lng: 100.532242, lat: 13.800278 },
    { lng: 100.532217, lat: 13.800347 },
    { lng: 100.532245, lat: 13.800467 },
    { lng: 100.532276, lat: 13.800603 },
    { lng: 100.532338, lat: 13.800874 },
    { lng: 100.532463, lat: 13.801412 },
    { lng: 100.532711, lat: 13.802494 },
    { lng: 100.53274, lat: 13.802616 },
    { lng: 100.532776, lat: 13.802774 },
    { lng: 100.53283, lat: 13.803011 },
    { lng: 100.532939, lat: 13.803483 },
    { lng: 100.533133, lat: 13.80433 },
    { lng: 100.533167, lat: 13.804477 },
    { lng: 100.533205, lat: 13.804638 },
    { lng: 100.533277, lat: 13.804954 },
    { lng: 100.533422, lat: 13.805585 },
    { lng: 100.533436, lat: 13.805644 },
    { lng: 100.533464, lat: 13.805765 },
    { lng: 100.533493, lat: 13.805892 },
    { lng: 100.533582, lat: 13.806276 },
    { lng: 100.533727, lat: 13.806913 },
    { lng: 100.534104, lat: 13.808542 },
    { lng: 100.534273, lat: 13.809282 },
    { lng: 100.53445, lat: 13.81004 },
    { lng: 100.535788, lat: 13.815881 },
    { lng: 100.537782, lat: 13.824122 },
    { lng: 100.538295, lat: 13.826378 },
    { lng: 100.538511, lat: 13.827283 },
    { lng: 100.53932, lat: 13.830675 },
    { lng: 100.539458, lat: 13.831255 },
    { lng: 100.539505, lat: 13.83145 },
    { lng: 100.539572, lat: 13.831734 },
    { lng: 100.539652, lat: 13.832068 },
    { lng: 100.539706, lat: 13.832294 },
    { lng: 100.539812, lat: 13.832746 },
    { lng: 100.53994, lat: 13.833281 },
    { lng: 100.540006, lat: 13.833559 },
    { lng: 100.540099, lat: 13.833947 },
    { lng: 100.540237, lat: 13.834529 },
    { lng: 100.540511, lat: 13.835679 },
    { lng: 100.540561, lat: 13.835896 },
    { lng: 100.540611, lat: 13.836111 },
    { lng: 100.540764, lat: 13.836775 },
    { lng: 100.54102, lat: 13.837877 },
    { lng: 100.541519, lat: 13.840047 },
    { lng: 100.541598, lat: 13.840383 },
    { lng: 100.541681, lat: 13.840749 },
    { lng: 100.541821, lat: 13.841355 },
    { lng: 100.542056, lat: 13.842376 },
    { lng: 100.542669, lat: 13.845026 },
    { lng: 100.542738, lat: 13.845324 },
    { lng: 100.542804, lat: 13.84561 },
    { lng: 100.542934, lat: 13.846175 },
    { lng: 100.542999, lat: 13.846455 },
    { lng: 100.54307, lat: 13.846762 },
    { lng: 100.5432, lat: 13.847321 },
    { lng: 100.543473, lat: 13.848497 },
    { lng: 100.543736, lat: 13.849646 },
    { lng: 100.543468, lat: 13.849466 },
    { lng: 100.542841, lat: 13.849264 },
    { lng: 100.542491, lat: 13.849155 },
    { lng: 100.542251, lat: 13.848986 },
    { lng: 100.542103, lat: 13.848926 },
    { lng: 100.541855, lat: 13.848898 },
    { lng: 100.54164, lat: 13.848798 },
    { lng: 100.541162, lat: 13.848542 },
    { lng: 100.540842, lat: 13.848392 },
    { lng: 100.540231, lat: 13.848121 },
    { lng: 100.539782, lat: 13.847956 },
    { lng: 100.53952, lat: 13.847784 },
    { lng: 100.539344, lat: 13.847711 },
    { lng: 100.538951, lat: 13.847576 },
    { lng: 100.53865, lat: 13.847434 },
    { lng: 100.538493, lat: 13.847392 },
    { lng: 100.538254, lat: 13.847294 },
    { lng: 100.53802, lat: 13.847159 },
    { lng: 100.537766, lat: 13.84687 },
    { lng: 100.53759, lat: 13.846691 },
    { lng: 100.537396, lat: 13.84662 },
    { lng: 100.537137, lat: 13.84656 },
    { lng: 100.536918, lat: 13.846494 },
    { lng: 100.536804, lat: 13.846403 },
    { lng: 100.536546, lat: 13.846263 },
    { lng: 100.53631, lat: 13.846244 },
    { lng: 100.536221, lat: 13.846214 },
    { lng: 100.536095, lat: 13.846119 },
    { lng: 100.535684, lat: 13.845746 },
    { lng: 100.535297, lat: 13.845375 },
    { lng: 100.535076, lat: 13.845108 },
    { lng: 100.535005, lat: 13.845043 },
    { lng: 100.534828, lat: 13.844931 },
    { lng: 100.534514, lat: 13.844683 },
    { lng: 100.534443, lat: 13.844625 },
    { lng: 100.534195, lat: 13.844484 },
    { lng: 100.533979, lat: 13.844312 },
    { lng: 100.53371, lat: 13.844045 },
    { lng: 100.533549, lat: 13.84386 },
    { lng: 100.533316, lat: 13.843571 },
    { lng: 100.533217, lat: 13.843448 },
    { lng: 100.53295, lat: 13.843298 },
    { lng: 100.532764, lat: 13.843181 },
    { lng: 100.53258, lat: 13.843 },
    { lng: 100.532505, lat: 13.84288 },
    { lng: 100.532263, lat: 13.842696 },
    { lng: 100.53208, lat: 13.842507 },
    { lng: 100.53201, lat: 13.842376 },
    { lng: 100.531889, lat: 13.842254 },
    { lng: 100.53164, lat: 13.842069 },
    { lng: 100.531357, lat: 13.841907 },
    { lng: 100.531194, lat: 13.841803 },
    { lng: 100.530957, lat: 13.841625 },
    { lng: 100.530635, lat: 13.841246 },
    { lng: 100.530406, lat: 13.841042 },
    { lng: 100.530001, lat: 13.840716 },
    { lng: 100.529754, lat: 13.840439 },
    { lng: 100.529405, lat: 13.840117 },
    { lng: 100.52888, lat: 13.839644 },
    { lng: 100.528528, lat: 13.839359 },
    { lng: 100.527904, lat: 13.83883 },
    { lng: 100.527747, lat: 13.838657 },
    { lng: 100.52728, lat: 13.838234 },
    { lng: 100.526776, lat: 13.837787 },
    { lng: 100.526126, lat: 13.837285 },
    { lng: 100.525915, lat: 13.837047 },
    { lng: 100.52573, lat: 13.836924 },
    { lng: 100.525451, lat: 13.836758 },
    { lng: 100.525353, lat: 13.836672 },
    { lng: 100.525177, lat: 13.836527 },
    { lng: 100.525071, lat: 13.836418 },
    { lng: 100.524885, lat: 13.836234 },
    { lng: 100.524655, lat: 13.835953 },
    { lng: 100.524407, lat: 13.835768 },
    { lng: 100.523934, lat: 13.83546 },
    { lng: 100.523596, lat: 13.835183 },
    { lng: 100.523411, lat: 13.834894 },
    { lng: 100.52332, lat: 13.834804 },
    { lng: 100.523232, lat: 13.834734 },
    { lng: 100.523172, lat: 13.834704 },
    { lng: 100.523084, lat: 13.834661 },
    { lng: 100.522866, lat: 13.83453 },
    { lng: 100.522752, lat: 13.834381 },
    { lng: 100.52262, lat: 13.834155 },
    { lng: 100.522441, lat: 13.833942 },
    { lng: 100.522283, lat: 13.833847 },
    { lng: 100.521962, lat: 13.833588 },
    { lng: 100.521567, lat: 13.833205 },
    { lng: 100.521053, lat: 13.832837 },
    { lng: 100.520656, lat: 13.832419 },
    { lng: 100.520246, lat: 13.832004 },
    { lng: 100.519889, lat: 13.831722 },
    { lng: 100.519394, lat: 13.831363 },
    { lng: 100.519326, lat: 13.831301 },
    { lng: 100.519187, lat: 13.831124 },
    { lng: 100.518824, lat: 13.83071 },
    { lng: 100.5187, lat: 13.830609 },
    { lng: 100.518587, lat: 13.830528 },
    { lng: 100.518461, lat: 13.830484 },
    { lng: 100.518176, lat: 13.830282 },
    { lng: 100.517875, lat: 13.830071 },
    { lng: 100.517574, lat: 13.82985 },
    { lng: 100.517537, lat: 13.829807 },
    { lng: 100.517283, lat: 13.829575 },
    { lng: 100.517026, lat: 13.829312 },
    { lng: 100.516438, lat: 13.828857 },
    { lng: 100.516247, lat: 13.828763 },
    { lng: 100.516095, lat: 13.828707 },
    { lng: 100.515976, lat: 13.828616 },
    { lng: 100.515875, lat: 13.828543 },
    { lng: 100.51583, lat: 13.828461 },
    { lng: 100.515805, lat: 13.828357 },
    { lng: 100.515819, lat: 13.827948 },
    { lng: 100.51583, lat: 13.827681 },
    { lng: 100.515784, lat: 13.82755 },
    { lng: 100.515606, lat: 13.827285 },
    { lng: 100.515488, lat: 13.827149 },
    { lng: 100.515383, lat: 13.827054 },
    { lng: 100.515266, lat: 13.826996 },
    { lng: 100.515217, lat: 13.826992 },
    { lng: 100.51461, lat: 13.826774 },
    { lng: 100.514365, lat: 13.826621 },
    { lng: 100.514161, lat: 13.826508 },
    { lng: 100.513796, lat: 13.826392 },
    { lng: 100.513572, lat: 13.82634 },
    { lng: 100.513485, lat: 13.826288 },
    { lng: 100.51344, lat: 13.826218 },
    { lng: 100.513245, lat: 13.826141 },
    { lng: 100.513182, lat: 13.826072 },
    { lng: 100.513141, lat: 13.825908 },
    { lng: 100.51313, lat: 13.8257 },
    { lng: 100.513147, lat: 13.825362 },
    { lng: 100.51315, lat: 13.825168 },
    { lng: 100.513177, lat: 13.824855 },
    { lng: 100.513104, lat: 13.824698 },
    { lng: 100.512989, lat: 13.824665 },
    { lng: 100.512742, lat: 13.824762 },
    { lng: 100.512532, lat: 13.825051 },
    { lng: 100.512225, lat: 13.825621 },
    { lng: 100.511997, lat: 13.825991 },
    { lng: 100.511651, lat: 13.826113 },
    { lng: 100.511181, lat: 13.826152 },
    { lng: 100.510983, lat: 13.826095 },
    { lng: 100.510918, lat: 13.825527 },
    { lng: 100.510747, lat: 13.825363 },
    { lng: 100.510575, lat: 13.825178 },
    { lng: 100.510068, lat: 13.82534 },
    { lng: 100.509358, lat: 13.825611 },
    { lng: 100.509298, lat: 13.825486 },
    { lng: 100.50925, lat: 13.82525 },
    { lng: 100.509196, lat: 13.825066 },
    { lng: 100.509497, lat: 13.824616 },
    { lng: 100.510118, lat: 13.824514 },
    { lng: 100.510264, lat: 13.824324 },
    { lng: 100.509996, lat: 13.823991 },
    { lng: 100.509695, lat: 13.823785 },
    { lng: 100.508735, lat: 13.823454 },
    { lng: 100.508328, lat: 13.823272 },
    { lng: 100.507877, lat: 13.823058 },
    { lng: 100.507705, lat: 13.822923 },
    { lng: 100.507662, lat: 13.822704 },
    { lng: 100.507568, lat: 13.822277 },
    { lng: 100.507668, lat: 13.821829 },
    { lng: 100.507298, lat: 13.821475 },
    { lng: 100.507043, lat: 13.821032 },
    { lng: 100.507056, lat: 13.82086 },
    { lng: 100.506477, lat: 13.819678 },
    { lng: 100.506359, lat: 13.819219 },
    { lng: 100.506579, lat: 13.818414 },
    { lng: 100.506917, lat: 13.817865 },
    { lng: 100.507676, lat: 13.817412 },
    { lng: 100.508889, lat: 13.817336 },
    { lng: 100.509223, lat: 13.817208 },
    { lng: 100.51064, lat: 13.816711 },
    { lng: 100.512158, lat: 13.81626 },
    { lng: 100.512855, lat: 13.816068 },
    { lng: 100.513284, lat: 13.815698 },
    { lng: 100.513923, lat: 13.815724 },
    { lng: 100.514641, lat: 13.81526 },
    { lng: 100.513146, lat: 13.813334 },
    { lng: 100.511511, lat: 13.81122 },
    { lng: 100.510902, lat: 13.810306 },
    { lng: 100.510742, lat: 13.809785 },
    { lng: 100.51054, lat: 13.809028 },
    { lng: 100.50727, lat: 13.806146 },
    { lng: 100.503469, lat: 13.802631 },
    { lng: 100.501525, lat: 13.801127 },
    { lng: 100.498087, lat: 13.79972 },
    { lng: 100.492323, lat: 13.797655 },
    { lng: 100.489258, lat: 13.796534 },
    { lng: 100.48407, lat: 13.79461 },
    { lng: 100.477777, lat: 13.792437 },
    { lng: 100.475098, lat: 13.79151 },
    { lng: 100.47318, lat: 13.790614 },
    { lng: 100.46612, lat: 13.788874 },
    { lng: 100.467888, lat: 13.791966 },
    { lng: 100.469773, lat: 13.794714 },
    { lng: 100.466335, lat: 13.79623 },
    { lng: 100.46391, lat: 13.797267 },
    { lng: 100.460914, lat: 13.797921 },
    { lng: 100.456308, lat: 13.798449 },
    { lng: 100.450767, lat: 13.798824 },
    { lng: 100.446446, lat: 13.799069 },
    { lng: 100.441052, lat: 13.799439 },
    { lng: 100.42627, lat: 13.800487 },
    { lng: 100.420016, lat: 13.800928 },
    { lng: 100.413633, lat: 13.801243 },
    { lng: 100.39944, lat: 13.801768 },
    { lng: 100.39612, lat: 13.801887 },
    { lng: 100.392778, lat: 13.801985 },
    { lng: 100.391845, lat: 13.802057 },
    { lng: 100.389512, lat: 13.802117 },
    { lng: 100.385548, lat: 13.802303 },
    { lng: 100.37671, lat: 13.802505 },
    { lng: 100.370298, lat: 13.802749 },
    { lng: 100.355499, lat: 13.803152 },
    { lng: 100.335786, lat: 13.8037 },
    { lng: 100.327789, lat: 13.804123 },
    { lng: 100.328888, lat: 13.802153 },
    { lng: 100.33137, lat: 13.796842 },
    { lng: 100.331061, lat: 13.793813 },
    { lng: 100.331882, lat: 13.79026 },
    { lng: 100.332784, lat: 13.786854 },
    { lng: 100.333286, lat: 13.785124 },
    { lng: 100.333511, lat: 13.784369 },
    { lng: 100.333687, lat: 13.783375 },
    { lng: 100.333162, lat: 13.783227 },
    { lng: 100.333312, lat: 13.782722 },
    { lng: 100.333331, lat: 13.782103 },
    { lng: 100.333182, lat: 13.78211 },
    { lng: 100.333168, lat: 13.781338 },
    { lng: 100.332378, lat: 13.781325 },
    { lng: 100.332484, lat: 13.779695 },
    { lng: 100.33101, lat: 13.776278 },
    { lng: 100.330358, lat: 13.773739 },
    { lng: 100.330124, lat: 13.773026 },
    { lng: 100.330025, lat: 13.771402 },
    { lng: 100.330299, lat: 13.768695 },
    { lng: 100.330009, lat: 13.765814 },
    { lng: 100.330166, lat: 13.764968 },
    { lng: 100.330366, lat: 13.764142 },
    { lng: 100.330304, lat: 13.762398 },
    { lng: 100.330362, lat: 13.761379 },
    { lng: 100.331641, lat: 13.76128 },
    { lng: 100.331717, lat: 13.760977 },
    { lng: 100.331788, lat: 13.760684 },
    { lng: 100.331938, lat: 13.760073 },
    { lng: 100.333058, lat: 13.76007 },
    { lng: 100.333048, lat: 13.759599 },
    { lng: 100.333042, lat: 13.758848 },
    { lng: 100.33302, lat: 13.757585 },
    { lng: 100.332855, lat: 13.755371 },
    { lng: 100.334022, lat: 13.755244 },
    { lng: 100.334413, lat: 13.753313 },
    { lng: 100.335535, lat: 13.753446 },
    { lng: 100.335618, lat: 13.749104 },
    { lng: 100.335572, lat: 13.7478 },
    { lng: 100.33557, lat: 13.747388 },
    { lng: 100.335638, lat: 13.747085 },
    { lng: 100.335817, lat: 13.746416 },
    { lng: 100.336013, lat: 13.745822 },
    { lng: 100.3358, lat: 13.745631 },
    { lng: 100.335753, lat: 13.745647 },
    { lng: 100.335302, lat: 13.74558 },
    { lng: 100.335445, lat: 13.744814 },
    { lng: 100.335636, lat: 13.743802 },
    { lng: 100.335834, lat: 13.742795 },
    { lng: 100.335978, lat: 13.742124 },
    { lng: 100.336064, lat: 13.741728 },
    { lng: 100.336114, lat: 13.741519 },
    { lng: 100.336166, lat: 13.741291 },
    { lng: 100.33576, lat: 13.741196 },
    { lng: 100.335878, lat: 13.740471 },
    { lng: 100.336275, lat: 13.740476 },
    { lng: 100.336356, lat: 13.739999 },
    { lng: 100.336656, lat: 13.738871 },
    { lng: 100.337102, lat: 13.737291 },
    { lng: 100.337114, lat: 13.736977 },
    { lng: 100.337385, lat: 13.736506 },
    { lng: 100.337492, lat: 13.736222 },
    { lng: 100.337388, lat: 13.735844 },
    { lng: 100.337308, lat: 13.735241 },
    { lng: 100.337341, lat: 13.734864 },
    { lng: 100.337423, lat: 13.734184 },
    { lng: 100.337511, lat: 13.732751 },
    { lng: 100.336728, lat: 13.732584 },
    { lng: 100.335578, lat: 13.7324 },
    { lng: 100.334931, lat: 13.732155 },
    { lng: 100.33515, lat: 13.731653 },
    { lng: 100.335543, lat: 13.730873 },
    { lng: 100.334582, lat: 13.730401 },
    { lng: 100.334794, lat: 13.730057 },
    { lng: 100.335071, lat: 13.729609 },
    { lng: 100.335462, lat: 13.729124 },
    { lng: 100.33599, lat: 13.728106 },
    { lng: 100.336087, lat: 13.727978 },
    { lng: 100.335902, lat: 13.727889 },
    { lng: 100.33642, lat: 13.726654 },
    { lng: 100.336586, lat: 13.726296 },
    { lng: 100.336586, lat: 13.726013 },
    { lng: 100.336154, lat: 13.725852 },
    { lng: 100.336213, lat: 13.724816 },
    { lng: 100.33632, lat: 13.723293 },
    { lng: 100.336312, lat: 13.722954 },
    { lng: 100.335859, lat: 13.72288 },
    { lng: 100.336068, lat: 13.722334 },
    { lng: 100.334569, lat: 13.721766 },
    { lng: 100.33293, lat: 13.721128 },
    { lng: 100.333646, lat: 13.719955 },
    { lng: 100.333959, lat: 13.719476 },
    { lng: 100.33426, lat: 13.719054 },
    { lng: 100.33447, lat: 13.718759 },
    { lng: 100.334784, lat: 13.718368 },
    { lng: 100.334961, lat: 13.718254 },
    { lng: 100.335422, lat: 13.718039 },
    { lng: 100.335578, lat: 13.717852 },
    { lng: 100.335802, lat: 13.717549 },
    { lng: 100.335958, lat: 13.71734 },
    { lng: 100.3361, lat: 13.717167 },
    { lng: 100.336699, lat: 13.71658 },
    { lng: 100.337664, lat: 13.718026 },
    { lng: 100.33834, lat: 13.718355 },
    { lng: 100.338703, lat: 13.718535 },
    { lng: 100.339062, lat: 13.718717 },
    { lng: 100.339441, lat: 13.718944 },
    { lng: 100.339919, lat: 13.7192 },
    { lng: 100.340346, lat: 13.719561 },
    { lng: 100.340664, lat: 13.71973 },
    { lng: 100.340904, lat: 13.71986 },
    { lng: 100.341517, lat: 13.720189 },
    { lng: 100.341746, lat: 13.720314 },
    { lng: 100.341978, lat: 13.720438 },
    { lng: 100.342531, lat: 13.720715 },
    { lng: 100.343074, lat: 13.721043 },
    { lng: 100.343616, lat: 13.721372 },
    { lng: 100.344176, lat: 13.721649 },
    { lng: 100.344704, lat: 13.721954 },
    { lng: 100.345106, lat: 13.722145 },
    { lng: 100.345954, lat: 13.72245 },
    { lng: 100.346194, lat: 13.72254 },
    { lng: 100.346313, lat: 13.722586 },
    { lng: 100.346685, lat: 13.722737 },
    { lng: 100.346895, lat: 13.722824 },
    { lng: 100.347288, lat: 13.722976 },
    { lng: 100.348682, lat: 13.723477 },
    { lng: 100.349114, lat: 13.723628 },
    { lng: 100.349922, lat: 13.72392 },
    { lng: 100.351483, lat: 13.724153 },
    { lng: 100.353022, lat: 13.724635 },
    { lng: 100.354661, lat: 13.725008 },
    { lng: 100.356315, lat: 13.725382 },
    { lng: 100.358547, lat: 13.725623 },
    { lng: 100.360804, lat: 13.725871 },
    { lng: 100.365097, lat: 13.726419 },
    { lng: 100.365863, lat: 13.724755 },
    { lng: 100.366687, lat: 13.72296 },
    { lng: 100.36834, lat: 13.719351 },
    { lng: 100.369263, lat: 13.71735 },
    { lng: 100.369817, lat: 13.71622 },
    { lng: 100.371307, lat: 13.713146 },
    { lng: 100.372275, lat: 13.711166 },
    { lng: 100.372979, lat: 13.709728 },
    { lng: 100.373671, lat: 13.708306 },
    { lng: 100.377862, lat: 13.70858 },
    { lng: 100.382006, lat: 13.708851 },
    { lng: 100.39028, lat: 13.709361 },
    { lng: 100.392024, lat: 13.709527 },
    { lng: 100.393305, lat: 13.70965 },
    { lng: 100.395846, lat: 13.709895 },
    { lng: 100.395838, lat: 13.710228 },
    { lng: 100.395894, lat: 13.712057 },
    { lng: 100.395826, lat: 13.712775 },
    { lng: 100.395812, lat: 13.713917 },
    { lng: 100.395794, lat: 13.715237 },
    { lng: 100.395771, lat: 13.716502 },
    { lng: 100.395738, lat: 13.718646 },
    { lng: 100.395722, lat: 13.720068 },
    { lng: 100.39569, lat: 13.721409 },
    { lng: 100.39562, lat: 13.723924 },
    { lng: 100.39556, lat: 13.72736 },
    { lng: 100.395539, lat: 13.729241 },
    { lng: 100.395511, lat: 13.731113 },
    { lng: 100.39549, lat: 13.732534 },
    { lng: 100.395462, lat: 13.734169 },
    { lng: 100.39541, lat: 13.736922 },
    { lng: 100.395417, lat: 13.737789 },
    { lng: 100.395434, lat: 13.739564 },
    { lng: 100.395421, lat: 13.741611 },
    { lng: 100.395376, lat: 13.743065 },
    { lng: 100.395352, lat: 13.744675 },
    { lng: 100.397275, lat: 13.744611 },
    { lng: 100.398743, lat: 13.744524 },
    { lng: 100.400554, lat: 13.744432 },
    { lng: 100.404124, lat: 13.74434 },
    { lng: 100.406179, lat: 13.744321 },
    { lng: 100.406264, lat: 13.730241 },
    { lng: 100.422227, lat: 13.731668 },
    { lng: 100.427489, lat: 13.73269 },
    { lng: 100.429699, lat: 13.733116 },
    { lng: 100.432058, lat: 13.733426 },
    { lng: 100.43343, lat: 13.733608 },
    { lng: 100.436636, lat: 13.733914 },
    { lng: 100.437559, lat: 13.734041 },
    { lng: 100.438402, lat: 13.73414 },
    { lng: 100.438761, lat: 13.734206 },
    { lng: 100.440983, lat: 13.734465 },
    { lng: 100.444474, lat: 13.734691 },
    { lng: 100.444756, lat: 13.734733 },
    { lng: 100.445442, lat: 13.73485 },
    { lng: 100.445741, lat: 13.734898 },
    { lng: 100.447347, lat: 13.735168 },
    { lng: 100.448307, lat: 13.735324 },
    { lng: 100.449255, lat: 13.735557 },
    { lng: 100.450232, lat: 13.735797 },
    { lng: 100.450966, lat: 13.735978 },
    { lng: 100.451724, lat: 13.736166 },
    { lng: 100.45215, lat: 13.73635 },
    { lng: 100.452609, lat: 13.736532 },
    { lng: 100.453098, lat: 13.736686 },
    { lng: 100.453542, lat: 13.736756 },
    { lng: 100.453999, lat: 13.736806 },
    { lng: 100.454594, lat: 13.736812 },
    { lng: 100.455405, lat: 13.736889 },
    { lng: 100.455555, lat: 13.73689 },
    { lng: 100.455752, lat: 13.73691 },
    { lng: 100.45586, lat: 13.736981 },
    { lng: 100.456037, lat: 13.737356 },
    { lng: 100.456215, lat: 13.737463 },
    { lng: 100.456432, lat: 13.737528 },
    { lng: 100.4567, lat: 13.737539 },
    { lng: 100.456879, lat: 13.737609 },
    { lng: 100.457118, lat: 13.737676 },
    { lng: 100.457438, lat: 13.737742 },
    { lng: 100.458425, lat: 13.738116 },
    { lng: 100.458653, lat: 13.738153 },
    { lng: 100.459165, lat: 13.738251 },
    { lng: 100.459365, lat: 13.738392 },
    { lng: 100.459565, lat: 13.738488 },
    { lng: 100.459876, lat: 13.738513 },
    { lng: 100.460198, lat: 13.738504 },
    { lng: 100.460557, lat: 13.738503 },
    { lng: 100.460759, lat: 13.738464 },
    { lng: 100.460897, lat: 13.738369 },
    { lng: 100.461029, lat: 13.738221 },
    { lng: 100.461167, lat: 13.738088 },
    { lng: 100.461512, lat: 13.738066 },
    { lng: 100.461718, lat: 13.738153 },
    { lng: 100.461764, lat: 13.737424 },
    { lng: 100.461961, lat: 13.736773 },
    { lng: 100.462189, lat: 13.734712 },
    { lng: 100.462404, lat: 13.734059 },
    { lng: 100.462473, lat: 13.733663 },
    { lng: 100.462706, lat: 13.732504 },
    { lng: 100.463027, lat: 13.731921 },
    { lng: 100.463777, lat: 13.730657 },
    { lng: 100.464056, lat: 13.730147 },
    { lng: 100.465384, lat: 13.727683 },
    { lng: 100.465769, lat: 13.727154 },
    { lng: 100.466192, lat: 13.726592 },
    { lng: 100.466633, lat: 13.725903 },
    { lng: 100.466838, lat: 13.725651 },
    { lng: 100.467025, lat: 13.725766 },
    { lng: 100.467218, lat: 13.72588 },
    { lng: 100.467608, lat: 13.726102 },
    { lng: 100.468018, lat: 13.726333 },
    { lng: 100.468415, lat: 13.726539 },
    { lng: 100.468557, lat: 13.726618 },
    { lng: 100.468856, lat: 13.726785 },
    { lng: 100.469305, lat: 13.72701 },
    { lng: 100.469335, lat: 13.727026 },
    { lng: 100.469531, lat: 13.72713 },
    { lng: 100.469865, lat: 13.727308 },
    { lng: 100.472715, lat: 13.728865 },
    { lng: 100.473603, lat: 13.729342 },
    { lng: 100.47387, lat: 13.729446 },
    { lng: 100.474201, lat: 13.729545 },
    { lng: 100.474652, lat: 13.729529 },
    { lng: 100.475539, lat: 13.729381 },
    { lng: 100.476446, lat: 13.729233 },
    { lng: 100.478119, lat: 13.728944 },
    { lng: 100.479663, lat: 13.728677 },
    { lng: 100.480386, lat: 13.728537 },
    { lng: 100.481545, lat: 13.728325 },
    { lng: 100.481897, lat: 13.728265 },
    { lng: 100.482331, lat: 13.728193 },
    { lng: 100.482859, lat: 13.728102 },
    { lng: 100.483114, lat: 13.728067 },
    { lng: 100.483523, lat: 13.72801 },
    { lng: 100.483713, lat: 13.727983 },
    { lng: 100.483908, lat: 13.727955 },
    { lng: 100.483998, lat: 13.727939 },
    { lng: 100.484214, lat: 13.727903 },
    { lng: 100.484427, lat: 13.727872 },
    { lng: 100.484674, lat: 13.727836 },
    { lng: 100.484975, lat: 13.727778 },
    { lng: 100.485161, lat: 13.727738 },
    { lng: 100.485816, lat: 13.727619 },
    { lng: 100.486269, lat: 13.72753 },
    { lng: 100.486516, lat: 13.727487 },
    { lng: 100.486691, lat: 13.727468 },
    { lng: 100.48713, lat: 13.727416 },
    { lng: 100.487716, lat: 13.727313 },
    { lng: 100.48787, lat: 13.727286 },
    { lng: 100.488046, lat: 13.727256 },
    { lng: 100.488641, lat: 13.727157 },
    { lng: 100.48898, lat: 13.727102 },
    { lng: 100.48937, lat: 13.727035 },
    { lng: 100.489876, lat: 13.726932 },
    { lng: 100.490326, lat: 13.726842 },
    { lng: 100.490779, lat: 13.726749 },
    { lng: 100.491268, lat: 13.726674 },
    { lng: 100.49163, lat: 13.72662 },
    { lng: 100.491929, lat: 13.726573 },
    { lng: 100.49222, lat: 13.726536 },
    { lng: 100.492329, lat: 13.726547 },
    { lng: 100.492448, lat: 13.726595 },
    { lng: 100.492562, lat: 13.726759 },
    { lng: 100.49264, lat: 13.726832 },
    { lng: 100.492749, lat: 13.726906 },
    { lng: 100.492865, lat: 13.726954 },
    { lng: 100.493008, lat: 13.726997 },
    { lng: 100.493144, lat: 13.727009 },
    { lng: 100.493272, lat: 13.727137 },
    { lng: 100.49332, lat: 13.727214 },
    { lng: 100.493364, lat: 13.727341 },
    { lng: 100.493413, lat: 13.727491 },
    { lng: 100.493499, lat: 13.727748 },
    { lng: 100.493544, lat: 13.727879 },
    { lng: 100.493573, lat: 13.727965 },
    { lng: 100.493627, lat: 13.72812 },
    { lng: 100.493733, lat: 13.728424 },
    { lng: 100.493964, lat: 13.729088 },
    { lng: 100.494012, lat: 13.729227 },
    { lng: 100.494065, lat: 13.729389 },
    { lng: 100.494172, lat: 13.729718 },
    { lng: 100.494387, lat: 13.730348 },
    { lng: 100.49442, lat: 13.730446 },
    { lng: 100.494485, lat: 13.730635 },
    { lng: 100.49456, lat: 13.730842 },
    { lng: 100.494705, lat: 13.731245 },
    { lng: 100.49474, lat: 13.731344 },
    { lng: 100.494773, lat: 13.731442 },
    { lng: 100.494817, lat: 13.731579 },
    { lng: 100.494866, lat: 13.731728 },
    { lng: 100.494889, lat: 13.731795 },
    { lng: 100.494967, lat: 13.732008 },
    { lng: 100.495047, lat: 13.732221 },
    { lng: 100.495109, lat: 13.732394 },
    { lng: 100.495204, lat: 13.732677 },
    { lng: 100.495252, lat: 13.732825 },
    { lng: 100.495296, lat: 13.732963 },
    { lng: 100.495348, lat: 13.733126 },
    { lng: 100.495402, lat: 13.733299 },
    { lng: 100.495468, lat: 13.733505 },
    { lng: 100.495534, lat: 13.73371 },
    { lng: 100.49561, lat: 13.733949 },
    { lng: 100.495656, lat: 13.734071 },
    { lng: 100.495757, lat: 13.734328 },
    { lng: 100.495794, lat: 13.734464 },
    { lng: 100.495827, lat: 13.734588 },
    { lng: 100.49585, lat: 13.73467 },
    { lng: 100.495934, lat: 13.734983 },
    { lng: 100.496087, lat: 13.735402 },
    { lng: 100.496283, lat: 13.735893 },
    { lng: 100.496306, lat: 13.735948 },
    { lng: 100.496326, lat: 13.736 },
    { lng: 100.496368, lat: 13.736106 },
    { lng: 100.496406, lat: 13.736195 },
    { lng: 100.496436, lat: 13.736273 },
    { lng: 100.496527, lat: 13.736443 },
    { lng: 100.496628, lat: 13.736631 },
    { lng: 100.496639, lat: 13.736655 },
    { lng: 100.496711, lat: 13.736772 },
    { lng: 100.496777, lat: 13.736882 },
    { lng: 100.496921, lat: 13.737122 },
    { lng: 100.497078, lat: 13.737295 },
    { lng: 100.497168, lat: 13.737393 },
    { lng: 100.497324, lat: 13.737576 },
    { lng: 100.497476, lat: 13.737751 },
    { lng: 100.497582, lat: 13.737875 },
    { lng: 100.497813, lat: 13.738141 },
    { lng: 100.497895, lat: 13.738235 },
    { lng: 100.498045, lat: 13.73843 },
    { lng: 100.498461, lat: 13.738966 },
    { lng: 100.498679, lat: 13.739248 },
    { lng: 100.49876, lat: 13.739352 },
    { lng: 100.496078, lat: 13.74041 },
    { lng: 100.493349, lat: 13.741475 },
    { lng: 100.490763, lat: 13.744847 },
    { lng: 100.488923, lat: 13.747248 },
    { lng: 100.488321, lat: 13.750703 },
    { lng: 100.488025, lat: 13.752467 },
    { lng: 100.489288, lat: 13.752431 },
    { lng: 100.490191, lat: 13.752428 },
    { lng: 100.491262, lat: 13.752451 },
    { lng: 100.492127, lat: 13.752474 },
    { lng: 100.492317, lat: 13.752486 },
    { lng: 100.49251, lat: 13.75249 },
    { lng: 100.492519, lat: 13.752486 },
    { lng: 100.49292, lat: 13.752503 },
    { lng: 100.493089, lat: 13.752499 },
    { lng: 100.493299, lat: 13.752509 },
    { lng: 100.493446, lat: 13.752506 },
    { lng: 100.49353, lat: 13.752802 },
    { lng: 100.493584, lat: 13.752951 },
    { lng: 100.493606, lat: 13.753044 },
    { lng: 100.493868, lat: 13.754026 },
    { lng: 100.494104, lat: 13.754915 },
    { lng: 100.494583, lat: 13.756652 },
    { lng: 100.494691, lat: 13.756828 },
    { lng: 100.494821, lat: 13.757006 },
    { lng: 100.494942, lat: 13.757129 },
    { lng: 100.495119, lat: 13.757266 },
    { lng: 100.495074, lat: 13.757383 },
    { lng: 100.495501, lat: 13.757639 },
    { lng: 100.495958, lat: 13.757795 },
    { lng: 100.49639, lat: 13.757795 },
    { lng: 100.49758, lat: 13.757512 },
    { lng: 100.499426, lat: 13.757165 },
    { lng: 100.501366, lat: 13.756721 },
    { lng: 100.502522, lat: 13.756501 },
    { lng: 100.504003, lat: 13.756203 },
    { lng: 100.504961, lat: 13.755987 },
    { lng: 100.505343, lat: 13.755989 },
    { lng: 100.505654, lat: 13.756082 },
    { lng: 100.505982, lat: 13.756294 },
    { lng: 100.506022, lat: 13.756472 },
    { lng: 100.506127, lat: 13.756737 },
    { lng: 100.506512, lat: 13.757549 },
    { lng: 100.506704, lat: 13.757953 },
    { lng: 100.506843, lat: 13.758256 },
    { lng: 100.507062, lat: 13.758831 },
    { lng: 100.507128, lat: 13.758966 },
    { lng: 100.507406, lat: 13.759537 },
    { lng: 100.507534, lat: 13.759774 },
    { lng: 100.507664, lat: 13.76003 },
    { lng: 100.507835, lat: 13.76039 },
    { lng: 100.507921, lat: 13.760575 },
    { lng: 100.508039, lat: 13.760832 },
    { lng: 100.508178, lat: 13.761126 },
    { lng: 100.508246, lat: 13.761264 },
    { lng: 100.508344, lat: 13.761481 },
    { lng: 100.508489, lat: 13.76179 },
    { lng: 100.508609, lat: 13.76204 },
    { lng: 100.508754, lat: 13.762352 },
    { lng: 100.508884, lat: 13.762635 },
    { lng: 100.509065, lat: 13.763009 },
    { lng: 100.509214, lat: 13.763309 },
    { lng: 100.509263, lat: 13.763391 },
    { lng: 100.512786, lat: 13.76024 },
  ],
}

export const MapMarker = [
  {
    label: 'โรงพยาบาลวชิรพยาบาล',
    lat: 13.780839144272228,
    lng: 100.50865559717268,
    icon: logo,
  },
  {
    label: 'จุดจอดโยธา',
    lat: 13.8298373,
    lng: 100.5485336,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดวัดวิมุตยาราม',
    lat: 13.816448558726725,
    lng: 100.5141453365085,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดตลิ่งชัน',
    lat: 13.7549999,
    lng: 100.407334,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดสำนักการแพทย์1',
    lat: 13.7463672,
    lng: 100.5088826,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดสำนักการแพทย์2',
    lat: 13.7463672,
    lng: 100.5091727,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดหลวงพ่อทวีศักดิ์ฯ',
    lat: 13.6563089,
    lng: 100.3452855,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดบางบอน',
    lat: 13.649908077425499,
    lng: 100.39674930271605,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดเจริญกรุง',
    lat: 13.694504,
    lng: 100.494549,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดทุ่งครุ',
    lat: 13.607373,
    lng: 100.50568,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดบางขุนเทียน',
    lat: 13.5727004,
    lng: 100.4229007,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดบางนา',
    lat: 13.6809982,
    lng: 100.5921188,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดคลองสามวา',
    lat: 13.9101974,
    lng: 100.7532804,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดรามอินทรา',
    lat: 13.8680812,
    lng: 100.6040339,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดลาดพร้าว',
    lat: 13.803524,
    lng: 100.6073461,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดวังทองหลาง',
    lat: 13.7641734,
    lng: 100.6057252,
    icon: ambulanceIcon,
  },
  {
    label: 'จุดจอดสะพานสูง',
    lat: 13.7689109,
    lng: 100.6857041,
    icon: ambulanceIcon,
  },
]

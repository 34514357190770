import React from 'react'
import { Tabs, Spin } from 'antd'
import Table from '../Table'
import { TabPanes } from './constant'

const { TabPane } = Tabs

const ContentTab = ({ columns, setTab, data, state, role, loading, ...rest }) => (
  <Tabs
    defaultActiveKey={state.currentTab}
    activeKey={state.currentTab}
    onChange={(tab) => {
      setTab(tab)
      state.hash.replace({
        hash: `${tab}`,
      })
    }}
  >
    {TabPanes.map(
      (tp) =>
        tp.roles.includes(role) && (
          <TabPane tab={`${tp.title} (${data[tp.key].length})`} key={tp.key}>
            <Spin spinning={loading}>
              <Table scroll={{ x: 'max-content' }} columns={columns} dataSource={data[tp.key]} {...rest} />
            </Spin>
          </TabPane>
        ),
    )}
  </Tabs>
)
export default ContentTab

export const initValues = {
  titleName: "",
  firstName: "",
  lastName: "",
  age: "",
  gender: "",
  weight:"",
  cid: "",
  nationality: "",
  weight: "",
  treatment: "",
}

export const TreatmentOptionToggle = [
  "ไม่มีหลักประกัน",
  "ประกันสุขภาพ",
  "ประกันสังคม",
  "ข้าราชการ",
  "เบิกจ่ายตรง",
  "แรงงานต่างด้าวขึ้นทะเบียน",
  "ประกันชีวิต",
  "ชำระเงินสด",
  "ประกันท่องเที่ยว",
  "ประกันอื่นๆ"
]

export const formTemplate = [
  {
    header: '',
    data: [
      {
        label: 'เลขบัตรประจำตัว/หนังสือเดินทาง',
        value: 'cid',
        disabled: false,
        type: 'input',
        required: false,
        requiredMessage: 'ระบุ เลขบัตรประจำตัว/หนังสือเดินทางผู้ป่วย',
        placeholder: '',
        maxLength: 13
      },
      {
        label: 'สัญชาติ',
        value: 'nationality',
        disabled: false,
        type: 'dropdown',
        required: false,
        requiredMessage: 'ระบุสัญชาติผู้ป่วย',
        placeholder: '',
      },
      {
        label: 'ชื่อผู้ป่วย',
        value: 'firstName',
        disabled: false,
        type: 'input',
        required: false,
        requiredMessage: 'ระบุชื่อผู้ป่วย',
        placeholder: '',
      },
      {
        label: 'นามสกุลผู้ป่วย',
        value: 'lastName',
        disabled: false,
        type: 'input',
        required: false,
        requiredMessage: 'ระบุนามสกุลผู้ป่วย',
        placeholder: '',
      },
      {
        label: 'คำนำหน้า',
        value: 'titleName',
        disabled: true,
        type: 'dropdown',
        required: false,
        requiredMessage: 'ระบุคำนำหน้าชื่อ',
        placeholder: '',
      },
      {
        label: 'เพศ',
        innerLabel: "",
        value: 'gender',
        disabled: false,
        type: 'toggle',
        title: "เพศ",
        required: false,
        requiredMessage: 'ระบุเพศผู้ป่วย',
        items: ["ชาย", "หญิง",],
      },
      {
        label: 'น้ำหนัก',
        value: 'weight',
        disabled: false,
        type: 'input',
        required: false,
        requiredMessage: 'ระบุน้ำหนักผู้ป่วย',
        placeholder: '',
      },
      {
        label: 'อายุ',
        value: 'age',
        disabled: false,
        type: 'input',
        required: false,
        requiredMessage: 'ระบุอายุผู้ป่วย',
        placeholder: '',
      },   
      {
        label: '',
        innerLabel: "สิทธิการรักษา",
        value: 'treatment',
        disabled: false,
        type: 'toggle',
        required: false,
        requiredMessage: 'ระบุสิทธิการรักษาผู้ป่วย',
        extraInputs: [
          "ประกันสังคม",
          "ประกันสุขภาพ",
          "ประกันท่องเที่ยว",
          "ประกันอื่นๆ",
        ],
        items: TreatmentOptionToggle
      },
    ],
  },
]

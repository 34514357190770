import React, { useEffect, useState } from 'react';
import { Form, Select, Input, Button, Row, Col, Tag, message } from 'antd'
import { infoForm } from './dropDownConstant'
import http from '../../../../services/httpServiceEver'

export default (props) => {
  const {
    formAddCase,
    onCreateAddCaseEMS,
    validateMessages,
    ambulance,
  } = props;
  const [IDC_RC_CODE, SET_IDC_RC_CODE] = useState([]);
  const [idcCode, setIdcCode] = useState('');
  const [ambulanceList, setAmbulanceList] = useState([])
  useEffect(() => {
    mount();
  }, [ambulance])
  const mount = async () => {
    SET_IDC_RC_CODE(await fetchEmsTypologies())
    setAmbulanceList(
      [
        ...ambulance.filter(item => [25, 26, 27, 29].includes(item.no)),
        ...ambulance.filter(item => ![25, 26, 27, 29].includes(item.no))
      ]
    )
  }

  const fetchEmsTypologies = async () => {
    try {
      const response = await http.get('/EmsTypologies')
      if (response && response.status === 200) {
        const IDC = response.data.filter((item) => item.field === 'IDC_RC_CODE')
        return IDC
      }
    } catch (error) {
      message.error('เกิดข้อผิดพลาดในการโหลดข้อมูล EMS Typologies')
    }
  }

  const RenderDropdownList = () => {
    return infoForm.map((item, index) => (
      <Form.Item key={item.name + index} name={item.name} label={item.label} rules={[{ required: true }]} >
        <Select
          showSearch={item.name === 'idcCode' ? true : false}
          key={item.name + index + item.label}
          onChange={(value, label) => item.dataValue(formAddCase, value, label.children)}
          placeholder={`เลือก ${item.label}`}
          filterOption={(inputValue, option) =>
            option.children.toLowerCase().includes(inputValue.toLowerCase())
            // console.log('options ===',option)
          }
        >
          {
            item.name === 'emsCar' ?
              item.dataValue(ambulanceList).map((item, index) => {
                if (item.note === 'พร้อมใช้งาน' && item.availability) {
                  return (
                    <Option
                      key={item.no}
                      value={item.key}
                      style={{ backgroundColor: index % 2 === 0 ? '#d2d2d225' : 'white' }}>
                      <Row>
                        <Col span={4} className='text-center'>{item.no}</Col>
                        <Col span={8} className='text-center'>{item.licensePlate}</Col>
                        <Col span={12} className='text-center'><Tag color={'green'}>{item.note}</Tag></Col>
                      </Row>
                    </Option>
                  )
                }
              })
              : item.name === 'idcCode' ?
                IDC_RC_CODE.map((item, index) =>
                  <Option key={item.id + index} value={item.label} >{item.label}</Option>
                ) :
                item.options.map((itemOptions, indexOptions) =>
                  <Option key={itemOptions.text + indexOptions} value={itemOptions.value}>{itemOptions.text}</Option>
                )
          }
        </Select>
      </Form.Item>
    )
    )
  }

  return (
    <Form form={formAddCase} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} name="add-case" style={{ maxWidth: '100%' }} onFinish={onCreateAddCaseEMS} validateMessages={validateMessages}>
      <Form.Item name="emergencyMessage" label="คัดกรองอาการ" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="idcLabel" label="idcLabel" rules={[{ required: false }]} style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <RenderDropdownList />
      <Form.Item wrapperCol={{ span: 24 }} className="text-center">
        <Button type="primary" htmlType="submit">
          บันทึกเหตุ
        </Button>
      </Form.Item>
    </Form>
  );
}
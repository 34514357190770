import React, { useState, useEffect } from 'react'
import { Card, Form, Row, Col, Input, Button, Checkbox, DatePicker } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import Chart from 'react-apexcharts'
import http from '../../../services/httpServiceHealthCare'
import { PressureText } from './styles'
import { FilterOptions } from '../constant'
const { RangePicker } = DatePicker
import { cloneDeep } from 'lodash'

function PressureGraphCard(props) {
  const { graphs, patient, onFinishPressure, editable, filterByDrugTime } = props
  const { defaultLowAbove, defaultHighAbove, defaultLowBelow, defaultHighBelow, lines } = graphs
  const [form] = Form.useForm()
  const options = cloneDeep(lines.options)
  const series = cloneDeep(lines.series)
  useEffect(() => {
    if (graphs) {
      form.setFieldsValue({
        defaultMinAbove: graphs.defaultLowAbove,
        defaultMaxAbove: graphs.defaultHighAbove,
        defaultMinBelow: graphs.defaultLowBelow,
        defaultMaxBelow: graphs.defaultHighBelow,
      })
    }
  }, [graphs])

  const onFinish = async (values) => {
    const { defaultMinAbove, defaultMaxAbove, defaultMinBelow, defaultMaxBelow } = values
    if (Number(defaultMaxAbove) > Number(defaultMinAbove) && Number(defaultMaxBelow) > Number(defaultMinBelow)) {
      await http.patch(`/UserInfos/${patient.id}`, {
        defaultPressureMeasurementsAbove: [defaultMinAbove, defaultMaxAbove],
        defaultPressureMeasurementsBelow: [defaultMinBelow, defaultMaxBelow],
      })
      onFinishPressure({
        above: [defaultMinAbove, defaultMaxAbove],
        below: [defaultMinBelow, defaultMaxBelow],
      })
    } else {
      alert('กรุณากรอกค่า Maximum range ปกติให้มากกว่าค่า Minimum')
    }
  }

  return (
    <Card className="mt-2" title="กราฟแนวโน้มความดันโลหิต">
      {editable ? (
        <Form
          form={form}
          layout="inline"
          onFinish={onFinish}
          style={{ maxWidth: '100%' }}
          initialValues={{
            defaultMinAbove: defaultLowAbove,
            defaultMaxAbove: defaultHighAbove,
            defaultMinBelow: defaultLowBelow,
            defaultMaxBelow: defaultHighBelow,
          }}
        >
          <Row>
            <Col span={24} className="mb-2">
              <div>ตั้งค่าค่าความดันสูง (Default)</div>
            </Col>
            <Col span={10}>
              <Form.Item
                name="defaultMinAbove"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกค่าก่อนส่งยืนยัน',
                  },
                ]}
              >
                <Input prefix={<EditOutlined className="site-form-item-icon" />} placeholder="defaultMinAbove" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="defaultMaxAbove"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกค่าก่อนส่งยืนยัน',
                  },
                ]}
              >
                <Input prefix={<EditOutlined className="site-form-item-icon" />} type="text" placeholder="defaultMaxAbove" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="mb-2">
              <div>ตั้งค่าค่าความดันต่ำ (Default)</div>
            </Col>
            <Col span={10}>
              <Form.Item
                name="defaultMinBelow"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกค่าก่อนส่งยืนยัน',
                  },
                ]}
              >
                <Input prefix={<EditOutlined className="site-form-item-icon" />} placeholder="defaultMinBelow" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="defaultMaxBelow"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกค่าก่อนส่งยืนยัน',
                  },
                ]}
              >
                <Input prefix={<EditOutlined className="site-form-item-icon" />} type="text" placeholder="defaultMaxBelow" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  ตั้งค่าข้อมูล
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="mt-4 mb-4">
          <div>
            <Row>
              <PressureText span={16}>ค่าความดันสูงที่ตั้งไว้ (Default)</PressureText>
            </Row>
            <Row>
              <PressureText span={8}>
                ต่ำสุด&nbsp; : &nbsp;<b>{defaultLowAbove}</b>
              </PressureText>
              <PressureText span={8}>
                สูงสุด&nbsp; : &nbsp;<b>{defaultHighAbove}</b>
              </PressureText>
            </Row>
          </div>
          <div>
            <Row>
              <PressureText span={16}>ค่าความดันต่ำที่ตั้งไว้ (Default)</PressureText>
            </Row>
            <Row>
              <PressureText span={8}>
                ต่ำสุด&nbsp; : &nbsp;<b>{defaultLowBelow}</b>
              </PressureText>
              <PressureText span={8}>
                สูงสุด&nbsp; : &nbsp;<b>{defaultHighBelow}</b>
              </PressureText>
            </Row>
          </div>
        </div>
      )}
      <div className="py-3">
        <Checkbox.Group
          options={FilterOptions}
          defaultValue={['beforeMeal', 'afterMeal', 'all']}
          onChange={(vals) => {
            filterByDrugTime(vals, 'pressure')
          }}
        />
      </div>
      <Chart options={options} series={series} type="line" width="600" />
    </Card>
  )
}
export default PressureGraphCard

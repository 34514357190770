import React, { useState, useEffect } from 'react'
import { FileOutlined, ExportOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Formik } from 'formik'
import { Button, Row, Col, Divider, DatePicker, Card, Typography, Form, Input, Radio, InputNumber, Modal, Spin, Popconfirm, Select, Checkbox, Tag } from 'antd'
import moment from 'moment'
import TextBase from '../Text'
import ReactExport from 'react-data-export'
import EmergencyTableForm from '../EmergencyTableForm'
import ReasonLateTable from '../ReasonLateTable'
import { DATA_SET_EXCEL, rejectStatus } from './constant'
import { setDataforExport } from './util'
import { AMBULANCE_STATUS, AMBULANCE_COLOR } from '../../constant'
import RenderTabTable from './component/RenderTabTable'
import EmsPdfForm from '../EmsPdfForm'
import ReadMoreElementExpand from '../ReadMoreElementExpand'
import { pdf } from '@react-pdf/renderer'
import { includes } from 'lodash'
moment.locale('th')
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const Text = styled(TextBase)`
  font-weight: bold;
`

const { TextArea } = Input

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
  disabled: false,
}

const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
const Container = styled.div`
  width: 100%;
  padding: 20px;
`

const EmsFormTable = (props) => {
  const { state, history, fetchEmsForm, fetchEmsFormCancel, fetchEmsFormPass, fetchEmsFormReject, ...rest } = props
  const [visibleModalDetail, setVisibleModalDetail] = useState(false)
  const [visibleModalComment, setVisibleModalComment] = useState(false)
  const [data, setData] = useState(false)
  const [comment, setComment] = useState('')
  const [reportDataSource, setReportDataSource] = useState([])
  const [cancelDataSource, setCancelDataSource] = useState([])
  const [passDataSource, setPassDataSource] = useState([])
  const [rejectDataSource, setRejectDataSource] = useState([])
  // const [dataExcel, setDataExcel] = useState([{ columns: DATA_SET_EXCEL.columns, data: [] }])
  const [tab, setTab] = useState('1')
  useEffect(() => {
    fetchData()
  }, [state, data])

  const onChangeTab = (tab) => {
    setTab(tab)
  }

  const fetchData = () => {
    const data = state?.dataSource
    const dataCancel = state?.dataSourceCancel
    const dataPass = state?.dataSourcePass
    const dataReject = state?.dataSourceReject
    const preHospitalStatus = ['completed', 'noted', 'customAdd', 'actionWaiting', 'exitWaiting', 'moving', 'arrived', 'completedSending', 'inHospital']
    const filterComplete = data?.filter((val) => preHospitalStatus.includes(val.status))
    // const filterComplete = data.filter((val) => val.id == 437)
    const filterCancel = dataCancel?.filter((val) => val.status == 'cancelled')
    const filterPass = dataPass?.filter((val) => val.status == 'pass')
    const filterReject = dataReject?.filter((val) => val)
    setReportDataSource(filterComplete)
    setCancelDataSource(filterCancel)
    setPassDataSource(filterPass)
    setRejectDataSource(filterReject)
  }
  const fetchDataPatientEmergency = (userId) => {
    const { data } = reportDataSource?.filter((value) => value.userId === userId)[0]
    setData(data)
  }
  const betweenTwoDate = (d1, d2) => {
    return moment(d1).diff(moment(d2), 'minutes')
  }

  const columns = [
    {
      title: 'วันที่ปฏิบัติการ',
      key: 'operationDate',
      sorter: (a, b) => moment(a?.operationDateTime).unix() - moment(b?.operationDateTime).unix(),
      defaultSortOrder: 'descend',
      render: (text) =>
        text && text?.operationDateTime ? (
          <Text onClick={() => console.log('log :', text)}>
            {moment(text?.operationDateTime)
              .add(543, 'years')
              .format('lll')}{' '}
            น.
          </Text>
        ) : (
          ''
        ),
    },
    {
      title: 'ชื่อผู้ป่วย',
      key: 'patientName',
      render: (text) =>
        text?.demographicData ? (
          <Text>
            {`${text?.demographicData?.titleName} 
        ${text?.demographicData?.firstName} 
        ${text?.demographicData?.lastName}`}
          </Text>
        ) : (
          ''
        ),
    },
    {
      title: 'เพศ',
      key: 'patientGender',
      render: (text) => (text && text?.patientGender ? <Text>{text?.patientGender || ''}</Text> : ''),
    },
    {
      title: 'ผลการรักษา',
      key: 'outCome',
      render: (text) => (text && text?.outCome ? <Text>{text?.outCome || ''}</Text> : ''),
    },
    {
      title: 'สถานที่เกิดเหตุ',
      key: 'sceneLocation',
      render: (text) => (text && text?.sceneLocation ? <Text>{text?.sceneLocation || ''}</Text> : ''),
    },
    {
      title: 'เช็ครายละเอียดทั้งหมด',
      key: 'action',
      render: (record, index) => (
        <Button
          type="primary"
          onClick={() => {
            setVisibleModalDetail(true)
            fetchDataPatientEmergency(record.userId)
            setData(record.data)
          }}
        >
          เช็ครายละเอียด
        </Button>
      ),
    },
  ]

  const get = (obj, key) => {
    return key.split('.').reduce(function(o, x) {
      return typeof o == 'undefined' || o === null ? o : o[x]
    }, obj)
  }
  const columnsPreHospital = [
    {
      title: 'วันที่รับแจ้งเหตุ',
      key: 'operationDate',
      sorter: (a, b) => moment(a?.operationDateTime).unix() - moment(b?.operationDateTime).unix(),
      defaultSortOrder: 'descend',
      render: (text) =>
        text && text?.operationDateTime ? (
          <Text onClick={() => console.log('log :', text)}>
            {moment(text?.operationDateTime)
              .add(543, 'years')
              .format('lll')}{' '}
            น.
          </Text>
        ) : (
          ''
        ),
    },
    {
      title: 'การรับแจ้ง',
      key: 'patientName',
      render: (text) => (text && text?.emsType ? <Text>{text?.emsType == 'erawan' ? 'ศูนย์เอราวัณ' : text?.emsType}</Text> : ''),
    },
    {
      title: 'เลขปฎิบัติการ',
      key: 'patientGender',
      render: (text) => (text && text?.responseData ? <Text>{text?.responseData?.operationNo || ''}</Text> : ''),
    },
    {
      title: 'พาหนะที่ใช้งาน',
      key: 'outCome',
      render: (text) => (text && text?.responseData ? <Text>{text?.responseData?.vehicle || ''}</Text> : ''),
    },
    {
      title: 'สถานที่เกิดเหตุ',
      key: 'sceneLocation',
      render: (text) => (text && text?.responseData ? <Text>{text?.responseData?.sceneSite || ''}</Text> : ''),
    },
    {
      title: 'คัดกรองอาการ',
      key: 'action',
      render: (text) => (text && text?.responseData ? <Text>{text?.responseData?.notifiedSymptoms || ''}</Text> : ''),
    },
    {
      title: 'อายุ/เพศ',
      key: 'sceneLocation',
      render: (text) => (text && text?.demographicData ? <Text>{`${text?.demographicData?.age || '-'} / ${text?.demographicData?.gender || '-'}`}</Text> : ''),
    },
    {
      title: 'ผู้ออกปฎิบัติการ',
      key: 'sceneLocation',
      render: (text) =>
        text?.responseData?.paramedic?.length && text?.responseData?.medicalEmergencyStaff?.length ? (
          <ReadMoreElementExpand showDefault={1}>
            {[...text?.responseData?.paramedic?.map((val) => <Text>{val.split(/–|-/)[0]}</Text>), ...text?.responseData?.medicalEmergencyStaff?.map((val) => <Text>{val.split(/–|-/)[0]}</Text>)]}
          </ReadMoreElementExpand>
        ) : (
          ''
        ),
    },
    {
      title: 'สถานะ',
      key: 'status',
      render: (text) => <Tag color={AMBULANCE_COLOR[text?.status]}>{`${AMBULANCE_STATUS[text?.status] || ''}`}</Tag>,
    },
    {
      title: '',
      key: 'action',
      width: 200,
      render: (record, index) => (
        <Row gutter={[8, 4]}>
          <Col span={10}>
            <Button type="primary" onClick={() => history.push({ pathname: `/ems-forms`, state: { key: record.id, type: 'customAdd' } })}>
              แก้ไข
            </Button>
          </Col>
          <Col span={10}>
            <Button onClick={() => openPDFHandler(record)} type="danger">
              PDF
            </Button>
          </Col>
        </Row>
      ),
    },
  ]

  const columnsMissionOverview = [
    {
      title: 'วันที่รับแจ้งเหตุ',
      key: 'operationDate',
      sorter: (a, b) => moment(a?.operationDateTime).unix() - moment(b?.operationDateTime).unix(),
      defaultSortOrder: 'descend',
      render: (text) =>
        text && text?.operationDateTime ? (
          <Text onClick={() => console.log('log :', text)}>
            {moment(text?.operationDateTime)
              .add(543, 'years')
              .format('lll')}{' '}
            น.
          </Text>
        ) : (
          ''
        ),
    },
    {
      title: 'ช่องทางการรับแจ้ง',
      key: 'patientName',
      width: 70,
      render: (text) => (text && text?.emsType ? <Text>{text?.emsType == 'erawan' ? 'ศูนย์เอราวัณ' : text?.emsType}</Text> : ''),
    },
    {
      title: 'คัดกรองอาการ',
      key: 'patientGender',
      render: (text) => (text && text?.responseData ? <Text>{text?.responseData?.notifiedSymptoms}</Text> : ''),
    },
    {
      title: 'IDC Code',
      key: 'outCome',
      render: (text) => (text && text?.responseData ? <Text>{text?.responseData?.idcCode}</Text> : ''),
    },
    {
      title: 'DT',
      key: 'sceneLocation',
      render: (text) =>
        text?.responseData?.travelLog?.time?.ordered && text?.responseData?.travelLog?.time?.vajiraReceiveCase ? (
          <Text>{betweenTwoDate(text?.responseData?.travelLog?.time?.ordered, text?.responseData?.travelLog?.time?.vajiraReceiveCase)} m</Text>
        ) : (
          ''
        ),
    },
    {
      title: 'AT',
      key: 'action',
      render: (text) =>
        text?.responseData?.travelLog?.totalTime?.activateTime ? (
          <Text>
            {moment()
              .startOf('isoWeek')
              .add(text?.responseData?.travelLog?.totalTime?.activateTime, 'minutes')
              .format('mm')}{' '}
            m
          </Text>
        ) : (
          ''
        ),
    },
    {
      title: 'RT',
      key: 'sceneLocation',
      render: (text) =>
        text?.responseData?.travelLog?.totalTime?.responseTime ? (
          <Text>
            {moment()
              .startOf('isoWeek')
              .add(text?.responseData?.travelLog?.totalTime?.responseTime, 'minutes')
              .format('mm')}{' '}
            m
          </Text>
        ) : (
          ''
        ),
    },
    {
      title: 'OST',
      key: 'sceneLocation',
      render: (text) =>
        text?.responseData?.travelLog?.totalTime?.onSceneTime ? (
          <Text>
            {moment()
              .startOf('isoWeek')
              .add(text?.responseData?.travelLog?.totalTime?.onSceneTime, 'minutes')
              .format('mm')}{' '}
            m
          </Text>
        ) : (
          ''
        ),
    },
    {
      title: 'Scene to ED',
      key: 'sceneLocation',
      width: 50,
      render: (text) => (
        <Text>
          {text?.responseData?.travelLog?.totalTime?.hospitalToBaseTime || 0 - text?.responseData?.travelLog?.totalTime?.leaveSceneToHospitalTime || 0} m {console.log('text =', text)}
        </Text>
      ),
    },
    {
      title: 'Base to scene distance',
      key: 'sceneLocation',
      width: 50,
      render: (text) => <Text>{text?.responseData?.travelLog?.distance?.fromBaseToScene || 0} km</Text>,
    },
    {
      title: 'Scene to hospital distance',
      key: 'sceneLocation',
      width: 50,
      render: (text) => <Text>{text?.responseData?.travelLog?.distance?.fromSceneToHospital || 0} km</Text>,
    },
    {
      title: 'ประเภท',
      key: 'sceneLocation',
      render: (text) => <Text>{text?.historyData?.trauma === 'Trauma pt.' ? 'Trauma' : text?.historyData?.trauma === 'Non-trauma pt.' ? 'Medicine' : ''}</Text>,
    },
    {
      title: 'EMS Impression',
      key: 'sceneLocation',
      render: (text) => <Text>{text?.impressionData?.emsImpression[0]}</Text>,
    },
    {
      title: 'นำส่งปลายทาง',
      key: 'sceneLocation',
      render: (text) => <Text>{text?.transferData?.destination?.hospital || ''}</Text>,
    },
    {
      title: 'ผลการรักษา',
      key: 'sceneLocation',
      render: (text) => <Text>{text?.transferData?.stability || ''}</Text>,
    },
    {
      title: 'สถานะ',
      key: 'status',
      render: (text) => <Tag color={AMBULANCE_COLOR[text?.status]}>{`${AMBULANCE_STATUS[text?.status] || ''}`}</Tag>,
    },
    {
      title: '',
      key: 'action',
      width: 200,
      render: (record, index) => (
        <Row gutter={[8, 4]}>
          <Col span={10}>
            <Button type="primary" onClick={() => history.push({ pathname: `/ems-forms`, state: { key: record.id, type: 'customAdd' } })}>
              แก้ไข
            </Button>
          </Col>
          <Col span={10}>
            <Button onClick={() => openPDFHandler(record)} type="danger">
              PDF
            </Button>
          </Col>
        </Row>
      ),
    },
  ]

  const openPDFHandler = React.useCallback(async (obj = {}) => {
    const blob = await pdf(<EmsPdfForm objectData={obj} />).toBlob()
    const url = URL.createObjectURL(blob)
    window.open(url, '_blank', 'noopener,noreferrer')
  }, [])

  const columnsCancel = [
    {
      title: 'วันที่รับแจ้งเหตุ',
      key: 'operationDate',
      width: 200,
      sorter: (a, b) => moment(a?.operationDateTime).unix() - moment(b?.operationDateTime).unix(),
      defaultSortOrder: 'descend',
      render: (text) =>
        text?.operationDateTime ? (
          <Text onClick={() => console.log('log :', text)}>
            {moment(text?.operationDateTime)
              .add(543, 'years')
              .format('lll')}{' '}
            น.
          </Text>
        ) : (
          ''
        ),
    },
    {
      title: 'ช่องทางการรับแจ้ง',
      key: 'patientName',
      width: 200,
      render: (text) => <Text>{text?.emsType == 'erawan' ? 'ศูนย์เอราวัณ' : text?.emsType || ''}</Text>,
    },
    {
      title: 'คัดกรองอาการ',
      key: 'action',
      width: 200,
      render: (text) => (text && text?.responseData ? <Text>{text?.responseData?.notifiedSymptoms || ''}</Text> : ''),
    },
    {
      title: 'ชื่อผู้ป่วย',
      key: 'patientName',
      render: (text) => (
        <Text>
          {`${text?.demographicData?.titleName || ''} 
        ${text?.demographicData?.firstName || ''} 
        ${text?.demographicData?.lastName || ''}`}
        </Text>
      ),
    },
    {
      title: 'เพศ',
      width: 100,
      key: 'patientGender',
      render: (text) => <Text>{`${text?.demographicData?.age || '-'} / ${text?.demographicData?.gender || '-'}`}</Text>,
    },
    {
      title: 'รายละเอียด',
      key: 'action',
      render: (text) => <Text>{text?.data?.comment || ''}</Text>,
    },
  ]

  const columnsReject = [
    {
      title: 'วันที่',
      key: 'operationDate',
      width: 200,
      sorter: (a, b) => moment(a?.operationDateTime).unix() - moment(b?.operationDateTime).unix(),
      defaultSortOrder: 'descend',
      render: (text) =>
        text?.calledDate ? (
          <Text onClick={() => console.log('log :', text)}>
            {moment(text?.calledDate)
              .add(543, 'years')
              .format('lll')}{' '}
            น.
          </Text>
        ) : (
          ''
        ),
    },
    {
      title: 'ชื่อผู้ป่วย',
      key: 'patientName',
      render: (text) => <Text>{`${text?.appUser?.fullname || 'ไม่พบข้อมูลผู้ป่วย'}`}</Text>,
    },
    {
      title: 'สาเหตุ',
      key: 'action',
      render: (text) => <Text> {rejectStatus[text.rejectType]} </Text>,
    },
  ]

  const tabTableprops = {
    onChangeTab,
    events: {
      columns,
      columnsCancel,
      columnsPreHospital,
      columnsReject,
      columnsMissionOverview,
      reportDataSource,
      cancelDataSource,
      passDataSource,
      rejectDataSource,
    },
    fetchEmsForm,
    fetchEmsFormCancel,
    fetchEmsFormPass,
    fetchEmsFormReject,
    state: state,
  }
  return (
    <Formik initialValues={{ filter: '' }}>
      {(props) => (
        <Container>
          <HeaderWrapper>
            <Row align="middle">
              <FileOutlined />
              <Text bold>รายงานทั้งหมด</Text>
            </Row>
            {/* <ExcelFile
              filename={`EMS_${new Date().getTime()}`}
              element={
                <Button style={{ display: 'flex', alignItems: 'center' }} type="primary" icon={<ExportOutlined />}>
                  Export to excel
                </Button>
              }
            > */}
            {/* <ExcelSheet dataSet={dataExcel} name="EMS" />*/}
            {/* </ExcelFile> */}
          </HeaderWrapper>
          <RenderTabTable {...tabTableprops} />
          {/* <Modal
            style={{ height: '50vh' }}
            width={1500}
            visible={visibleModalDetail}
            title="รายละเอียด"
            onOk={() => setVisibleModalDetail(false)}
            onCancel={() => setVisibleModalDetail(false)}
            footer={null}
          >
            <SetFormDetail layout={layout} data={data} cards={cards} />
          </Modal> */}
          <Modal style={{ height: '50vh' }} width={'50vw'} visible={visibleModalComment} title="รายละเอียด" onOk={() => setVisibleModalComment(false)} onCancel={() => setVisibleModalComment(false)} footer={null}>
            <Text>{comment}</Text>
          </Modal>
        </Container>
      )}
    </Formik>
  )
}
export default EmsFormTable

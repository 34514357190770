import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;

  @media (max-width: 869px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    height: auto;
  }
`;

export const Sidebar1 = styled.div`
  width: 300px;
  height: auto;
  flex: 1;
  padding: 0.5em;
  background-color: white;

  @media (max-width: 869px) {
    flex: 1;
    position: relative;
    margin-left: 0;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }
`;

export const Sidebar2 = styled.div`
  width: 540px;
  height: auto;
  flex: 3;
  padding: 0.5em;
  background-color: white;

  @media (max-width: 869px) {
    flex: 1;
    position: relative;
    margin-left: 0;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }
`;

export const Sidebar3 = styled.div`
  width: 310px;
  height: auto;
  flex: 1;
  padding: 0.5em;
  background-color: white;

  @media (max-width: 869px) {
    flex: 1;
    position: relative;
    margin-left: 0;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }
`;

export const ImageCardContainer = styled.div`
  display: flex;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;

export const CardImage2 = styled.div`
  position: relative !important;
  border-radius: 10px;
  min-width: 270px !important;

  @media (max-width: 744px) {
    padding: 0 !important;
    min-width: 200px !important;
  }
`;

export const CardImage1 = styled.div`
  position: relative !important;
  border-radius: 10px;
  width: 100% !important;
  height: 100% !important;
  top: 0px !important;
  left: 0px !important;
  box-shadow: 0 2px 2px #ccc;
  /* ... */
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 2px 2px #ccc;
  @media (max-width: 744px) {
    padding: 10px;
  }
`;

export const PatientInfoContainer = styled.div`
  box-shadow: 0 2px 2px #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
`;

export const DiagnosesContainer = styled.div`
  box-shadow: 0 2px 2px #ccc;
  border-radius: 10px;
  padding: 10px;
`;

export const Name = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
`;

export const DOBContainer = styled.div`
  color: grey;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 10px;
`;

export const PhysicalInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  line-height: 2;
`;

export const Field = styled.p`
  flex: 1;
  font-size: 12px;
  font-weight: bold;
`;

export const Value = styled.p`
  flex: 2;
  font-size: 12px;
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  line-height: 2;
`;

export const VisitContainer = styled.div`
  box-shadow: 0 2px 2px #ccc;
  border-radius: 10px;
  margin-top: 10px;
  border-radius: 10px;
`;

export const VisitHead = styled.div`
  background-color: #f0f2f5;
  border-radius: 10px 10px 0px 0px;
`;

export const VisitTitle = styled.h1`
  font-weight: bold;
  font-size: 1rem;
  padding: 10px;
`;

export const VisitContent = styled.div`
  border-radius: 0px 0px 10px 10px;
  background-color: white;
  padding: 10px;
`;

export const Box = styled.div`
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 2px 2px #ccc;
  padding: 10px;

  &:last-child {
    margin-bottom: 15px;
  }
`;

export const TimelineContainer = styled.div`
  box-shadow: 0 2px 2px #ccc;
  border-radius: 10px;
  margin-top: 10px;
  border-radius: 10px;
`;

export const TimelineHead = styled.div`
  background-color: #f0f2f5;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TimelineTitle = styled.h1`
  font-weight: bold;
  font-size: 1rem;
  padding: 10px;
  flex: 4;
`;

export const TimelineFilter = styled.p`
  font-size: 0.8rem;
  color: grey;
  padding: 10px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

export const TimelineContent = styled.div`
  border-radius: 0px 0px 10px 10px;
  background-color: white;
  padding: 10px;
`;

export const NewEncounter = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 2px 2px #ccc;

  &:last-child {
    margin-bottom: 15px;
  }
`;

export const CreateBox = styled.div`
  border: 1px solid #e3e7ed;
  border-radius: 8px 8px 8px 8px;
  margin: 10px 5px 15px 5px;
  padding: 8px;
  background-color: #e3e7ed;

  &:last-child {
    margin-bottom: 15px;
  }
`;

export const CreateBoxPlaceholder = styled.p`
  text-align: center;
  font-size: 14px;
  color: grey;
`;

export const EncounterText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 10px;
`;

export const P = styled.p`
  font-size: 0.9em;
`;

export const ItemContainer = styled.div`
  box-shadow: 0 2px 2px #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
`;

export const H3 = styled.h3`
  font-weight: bold;
`;

export const ItemSlots = styled.div`
  box-shadow: 0 4px 3px #ccc;
  border-radius: 10px;
  margin: 10px 4px 20px 30px;
  display: flex;
  flex-direction: row;
`;

export const ItemSlot = styled.div`
  border-right: 1px solid #eaedf2;
  display: flex;
  flex: 1;
  flex-direction: column;

  &:last-child {
    border-right: none;
  }
`;

export const ItemTextField = styled.p`
  font-size: 0.8em;
  color: grey;
  padding-left: 10px;
`;
export const ItemTextValue = styled.div`
  font-size: 2em;
  color: grey;
  padding-left: 10px;
  padding-right: 1px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

export const ItemTextValue2 = styled.p`
  font-size: 0.5em;
  padding-left: 8px;
  flex: 1;
  color: grey;
  display: flex;
  align-items: flex-end;
`;

export const Complaint = styled.p`
  margin-top: 10px;
  font-size: 16px;
`;

export const DoctorSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
`;

export const Avatar = styled.img`
  border-radius: 50%;
  object-fit: cover;
  width: 50px;
  height: 50px;
  margin-right: 20px;
`;

export const DoctorName = styled.div`
  font-size: 16px;
  font-weight: bold;
  flex: 1;
  display: flex;
  padding-top: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const CommentDate = styled.p`
  font-size: 10px;
  color: grey;
`;

export const DoctorComment = styled.form`
  margin-top: 20px;
  margin-left: 0;
  margin-right: 0;
  flex: 1;
`;

export const Note = styled.input`
  width: 550px;
  padding: 0.5rem;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 12px;
  background-color: #f0f2f5;
  outline: none;
  margin-bottom: 5px;

  @media (max-width: 1410px) {
    width: 500px;
  }

  @media (max-width: 1340px) {
    width: 400px;
  }

  @media (max-width: 850px) {
    width: 250px;
  }
`;

export const Button = styled.input`
  padding: 0.4rem 1.3rem;
  font-size: 0.9rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border-radius: 12px;
  background-color: #d9dde2;
`;

export const HR = styled.hr`
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const OldItemContainer = styled.div`
  box-shadow: 0 2px 2px #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  background-color: #f0f2f5;
`;

export const OldItemSlots = styled.div`
  box-shadow: 0 4px 3px #ccc;
  border-radius: 10px;
  margin: 10px 20px 20px 0px;
  display: flex;
  flex-direction: row;
  background-color: white;
`;

export const OldItemDoctorGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const Arrow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ArrowButton = styled.button`
  cursor: pointer;
  background-color: #eff2f5;
  color: #000;
  outline: 0;

  &:focus {
    outline: 0;
  }
`;

export const Comments = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShowComments = styled.div``;

export const CardTitle = styled.h1`
  font-weight: bold;
  font-size: 1.3rem;
  flex: 4;
`;

export const CardBody = styled.p`
  font-size: 0.9em;
  padding-left: 10px;
  flex: 4;
`;

import React from 'react'
import { Tag, Input } from 'antd'
import Table from '../Table'
import styled from 'styled-components'
import TextBase from '../Text'
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const { Search: SearchAnt } = Input

const Container = styled.div`
  width: 100%;
  padding: 20px;
`
const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`

const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`

const Text = styled(TextBase)`
  font-weight: bold;
`
const columns = [
  {
    title: 'UserId',
    width: '50',
    key: 'userId',
    align: 'center',
    render: (record) => <Text>{record.userId}</Text>,
  },
  {
    title: 'ชื่อ',
    width: '50',
    key: 'name',
    align: 'center',
    render: (record) => <Text>{record.name ? record.name : '-'}</Text>,
  },
  {
    title: 'เบอร์โทรศัพท์',
    width: '50',
    key: 'mobile',
    align: 'center',
    render: (record) => <Text>{record.mobile ? record.mobile : '-'}</Text>,
  },
  {
    title: 'เลขประจำตัวประชาชน',
    width: '50',
    key: 'cId',
    align: 'center',
    render: (record) => <Text>{record.cId}</Text>,
  },
  {
    title: 'รายละเอียด',
    key: 'note',
    align: 'center',
    width: '500px',
    render: (record) => <Text>{record.note}</Text>,
  },
  {
    title: 'อุปกรณ์',
    key: 'mobileDetail',
    align: 'left',
    render: (record) => (
      <>
        <Text>
          {`เวอร์ชั่น: `}
          <Tag color="blue">{record.detail.version}</Tag>
        </Text>
        <Text>
          {`ยี่ห้อ: `}
          <Tag color="blue">{record.detail.manufacturer}</Tag>
        </Text>
        <Text>
          {`รุ่น: `}
          <Tag color="blue">{record.detail.model}</Tag>
        </Text>
        <Text>
          {`ความจำทั้งหมด: `}
          <Tag color="blue">{record.detail.totalMem}</Tag>
        </Text>
        <Text>
          {`ความจำที่ใช้: `}
          <Tag color="blue">{record.detail.usedMem}</Tag>
        </Text>
      </>
    ),
  },
  {
    title: 'วันที่ส่งข้อมูล',
    key: 'timestamp',
    align: 'center',
    sorter: (a, b) => moment(a.timestamp).unix() - moment(b.timestamp).unix(),
    render: (record) => <Text>{`${moment(record.timestamp).format('DD/MM/YYYY HH:MM')}`}</Text>,
  },
]

const UserReportTable = ({ reports, isLoading, onSearch }) => {
  return (
    <>
      <Container>
        <HeaderWrapper>
          <ExclamationCircleOutlined className="mr-1" />
          <Text bold>รายการแจ้งปัญหา</Text>
          <Search onSearch={onSearch} style={{ width: 200, marginLeft: 'auto' }} placeholder="รหัสบัตรประชาชน" />
        </HeaderWrapper>
      </Container>
      <Container>
        <Table columns={columns} dataSource={reports} loading={isLoading} scroll={{ y: 700 }} />
      </Container>
    </>
  )
}

export default UserReportTable

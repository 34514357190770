import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { TimeCard } from './styles'

export default function Timer() {
  const [sec, setSec] = useState(0)
  const [min, setMin] = useState(0)
  const [hour, setHour] = useState(0)

  useEffect(() => {
    const time = setInterval(() => {
      if (sec === 59) {
        setSec(0)
        setMin(min + 1)
      } else if (min === 59) {
        setMin(0)
        setHour(hour + 1)
      } else {
        setSec(sec + 1)
      }
    }, 1000)
    return () => clearInterval(time)
  })

  const checkTime = (num) => {
    if (num < 10) {
      return `0${num}`
    }
    return num
  }

  return (
    <Row style={{ alignSelf: 'center' }}>
      <Col span={24} style={{ padding: 20 }}>
        <TimeCard>{`คุณอยู่ในห้องสนทนา ${checkTime(hour)}:${checkTime(min)}:${checkTime(sec)}`}</TimeCard>
      </Col>
    </Row>
  )
}

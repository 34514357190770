import React, { useState, useEffect, useMemo } from 'react'
import { Modal, Tag } from 'antd'
import { CheckCircleFilled, CloseCircleFilled, QuestionCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import Table from '../Table'
import TextBase from '../Text'
import moment from 'moment'
const index = (props) => {
  const { editable, dataSource = null, ...rest } = props
  const [drugCompliance, setDrugCompliance] = useState([])

  const columnSet = useMemo(() => {
    return editable ? [
      {
        title: 'แก้ไขรายการยา Drug Compliance',
        key: 'status',
        width: 100,
        align: 'center',
        render: (record) => (
          <div>
            <ActionIconTrue onClick={() => modalConfirmBox(record.id, true, record.status)} isDrugCompliance={record.status}>
              <CheckCircleFilled
                style={{
                  fontSize: '30px',
                  cursor: 'pointer',
                  marginRight: '4px'
                }}
              ></CheckCircleFilled>
            </ActionIconTrue>
            <ActionIconFalse onClick={() => modalConfirmBox(record.id, false, record.status)} isDrugCompliance={record.status}>
              <CloseCircleFilled style={{ fontSize: '30px', cursor: 'pointer' }}></CloseCircleFilled>
            </ActionIconFalse>
          </div>
        )
      },
      {
        title: 'สถานะการแก้ไข',
        key: 'statusCloseMonitor',
        width: 150,
        align: 'center',
        render: (record) => (
          <div>
            <StatusTag status={record.status ? 'true' : 'false'}>{record.status ? 'บันทึกเป็นรายการยาที่ต้อง Close Monitor' : 'ยกเลิกรายการยาที่ต้อง Close Monitor แล้ว'}</StatusTag>
          </div>
        )
      },
    ] : []
  }, [editable])

  useEffect(() => {
    setDrugCompliance(dataSource)
  }, [dataSource])
  const Text = styled(TextBase)`
    font-weight: bold;
  `
  const ActionIconTrue = styled.span`
    ${(props) => (props.isDrugCompliance ? 'color: #45AB49' : 'color: #ccc')}
    ${(props) =>
      !props.isDrugCompliance &&
      `
    &:hover {
      color: #87e18b;
    }
    `}
  `
  const ActionIconFalse = styled.span`
    ${(props) => (props.isDrugCompliance ? 'color: #ccc' : 'color: #E03F61')}
    ${(props) =>
      props.isDrugCompliance &&
      `
      &:hover {
        color: #f08da2;
      }
    `}
  `
  const StatusTag = styled(Tag)`
    padding: 2px 4px;
    border-radius: 4px;
    color: #fff;
    border: none;
    ${(props) => (props.status === 'true' ? 'background-color: #08B576' : 'background-color: #DA0061')}
  `
  const PercentComplianceTag = styled(Tag)`
    padding: 8px 18px;
    color: #fff;
    border: none;
    font-weight: bold;
    ${(props) => (props.percent >= 90 ? 'background-color: #0CA789' : props.percent >= 70 ? 'background-color: #F7BA31' : props.percent < 70 && 'background-color: #DA0063')}
  `
  const handleStatusDrugCompliance = (drugComplianceId, newStatus) => {
    props.handleStatusDrugCompliance(drugComplianceId, newStatus)
  }

  const modalConfirmBox = (drugId, newStatus, currentStatus) => {
    if (currentStatus !== newStatus) {
      Modal.confirm({
        title: 'Confirm',
        icon: <QuestionCircleOutlined />,
        content: 'คุณแน่ใจที่จะเปลี่ยนสถานะใช่หรือไม่',
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
        onOk: () => {
          handleStatusDrugCompliance(drugId, newStatus)
        }
      })
    }
  }

  const calculateDrugTaken = (record) => {
    let data = {}
    data.receivedDate = moment(record.created_at)
    data.currentDate = moment()
    const dateDiff = data.currentDate.diff(data.receivedDate, 'days')
    data.amountToTake = record.drugComplianceType.timeperday ? record.drugComplianceType.timeperday * (dateDiff + 1) : '';
    return data
  }

  const showAmountTakenDrug = (record) => {
    const data = calculateDrugTaken(record)
    return `${record.usedQty}/${data.amountToTake}`
  }

  const calculatePercentCompliance = (record) => {
    const data = calculateDrugTaken(record)
    const percent = parseInt((record.usedQty / data.amountToTake) * 100)
    return percent
  }

  const columns = [
    {
      key: 'index',
      width: 50,
      title: 'ลำดับที่',
      className: 'px-1',
      fixed: true,
      render: (text, record, index) => (
        <div style={{ cursor: 'pointer' }}>
          <Text>{index + 1}</Text>
        </div>
      ),
      align: 'center'
    },
    {
      key: 'name',
      title: 'ชื่อยา',
      width: 100,
      fixed: true,
      render: (record) => (
        <div style={{ cursor: 'pointer' }}>
          <Text>{record.name}</Text>
        </div>
      ),
      align: 'center'
    },
    {
      title: 'วิธีใช้',
      key: 'drugUsage',
      width: 200,
      render: (record) => <p>{record.drugUsage}</p>,
      align: 'center'
    },
    {
      title: 'จำนวน',
      key: 'qty',
      width: 50,
      align: 'center',
      render: (record) => <p>{record.qty}</p>
    },
    ...columnSet, // NOTE Add column that user can edit data or not
    {
      title: 'วันที่เริ่มบันทึกยา',
      key: 'receivedDate',
      width: 150,
      align: 'center',
      render: (record) => <div>{record.created_at ? <Text> {moment(record.created_at).format('DD/MM/YYYY HH:mm')}</Text> : '-'}</div>
    },
    {
      title: 'จำนวนยาที่เหลือ',
      key: 'drugId',
      width: 150,
      align: 'center',
      render: (record) => <div>{isNaN(record?.qty - record?.usedQty) ? 0 : record?.qty - record?.usedQty}</div>
    },
    {
      title: 'จำนวนที่ทานจริง/จำนวนที่ต้องทาน',
      key: 'eatTimes',
      width: 150,
      align: 'center',
      render: (record) => <div>{record.drugComplianceTypeId && record.created_at ? showAmountTakenDrug(record) : '-'}</div>
    },
    {
      title: '% Compliance',
      key: 'percentCompliance',
      width: 100,
      align: 'center',
      render: (record) => (
        <div>
          {record.drugComplianceTypeId && record.created_at ? <PercentComplianceTag percent={calculatePercentCompliance(record)}>{calculatePercentCompliance(record)} %</PercentComplianceTag> : '-'}
        </div>
      )
    }
  ]

  return <Table scroll={{ x: 'max-content' }} style={{ width: '100%' }} columns={columns} dataSource={drugCompliance} {...rest} />
}

export default index

export const DATA_SET_EXCEL = {
  columns: [
    { title: '' }, // A
    { title: 'วันที่ปฏิบัติการ', width: { wpx: 100 } }, // B
    { title: 'วชิรรับแจ้ง', width: { wpx: 100 } }, // C
    { title: 'สถานที่เกิดเหตุ', width: { wpx: 150 } }, // D
    { title: '' }, // E
    { title: 'ผู้รับแจ้ง' }, // F
    { title: 'ผลการรับแจ้ง', width: { wpx: 100 } }, // G
    { title: 'ผลการออกปฏิบัติงาน', width: { wpx: 120 } }, // H
    { title: 'ผลการนำส่ง/ยกเลิก', width: { wpx: 120 } }, // I
    { title: 'ประเภทพาหนะที่ออกปฎิบัติการ', width: { wpx: 150 } }, // J
    { title: 'พาหนะที่ออกปฏิบัติการ', width: { wpx: 120 } }, // K
    { title: 'ทะเบียน' }, // L
    { title: 'เจ้าหน้าที่ผู้ออกปฏิบัติการ', width: { wpx: 220 } }, // M
    { title: '' }, // N
    { title: 'ระดับปฏิบัติการ', width: { wpx: 150 } }, // O
    { title: 'เลขที่ปฏิบัติการ', width: { wpx: 150 } }, // P
    { title: 'รหัสความรุนแรงที่ได้รับแจ้ง', width: { wpx: 200 } }, // Q
    { title: 'อาการที่ได้รับแจ้ง', width: { wpx: 150 } }, // R
    { title: 'รหัสความรุนแรง ณ จุดเกิดเหตุ', width: { wpx: 200 } }, // S
    { title: 'อาการ ณ จุดเกิดเหตุ', width: { wpx: 150 } }, // T
    { title: '' }, // U
    { title: '' }, // V
    { title: 'เวลาสั่งการ' }, // W
    { title: 'เวลาออกจากฐาน' }, // X
    { title: 'เวลาถึงที่เกิดเหตุ' }, // Y
    { title: 'First Medical Contact', width: { wpx: 200 } }, // Z
    { title: 'เวลาออกจากที่เกิดเหตุ', width: { wpx: 100 } }, // AA
    { title: 'เวลาถึงโรงพยาบาล', width: { wpx: 100 } }, // AB
    { title: 'เวลาถึงฐาน', width: { wpx: 100 } }, // AC
    { title: 'AT' }, // AD
    { title: 'RT' }, // AE
    { title: 'OST' }, // AF
    { title: '' }, // AG
    { title: 'เวลาเหตุถึงรพ', width: { wpx: 100 } }, // AH
    { title: 'เวลารพถึงฐาน', width: { wpx: 100 } }, // AI
    // NOTE
    { title: 'เหตุผล AT มากกว่า 2 นาที', width: { wpx: 200 } }, // AJ
    { title: 'เหตุผล RT มากกว่า 8 นาที', width: { wpx: 200 } }, // AK
    { title: 'เหตุผล OST trauma มากกว่า 10 นาที', width: { wpx: 200 } }, // AL
    // NOTE
    { title: 'กิโลก่อนออก', width: { wpx: 100 } }, // AM
    { title: 'กิโลถึงที่เกิดเหตุ', width: { wpx: 100 } }, // AN
    { title: 'กิโลถึงโรงพยาบาล', width: { wpx: 100 } }, // AO
    { title: 'กิโลกลับฐาน', width: { wpx: 100 } }, // AP
    { title: 'ระยะทางถึงที่เกิดเหตุ', width: { wpx: 100 } }, // AQ
    { title: 'ระยะจากเหตุถึงโรงพยาบาล', width: { wpx: 100 } }, // AR
    { title: 'ระยะจากโรงพยาบาลถึงฐาน', width: { wpx: 100 } }, // AS
    { title: 'เพศ' }, // AT
    { title: 'อายุ' }, // AU
    { title: 'สัญชาติ' }, // AV
    { title: 'สิทธิ' }, // AW
    { title: 'รพ. ตามสิทธิ์' }, // AX
    { title: 'Arrest' }, // AY
    { title: 'CPR' }, // AZ
    { title: 'เหตุผลการหยุด/การไม่ CPR', width: { wpx: 150 } }, // BA
    { title: 'Witnessed arrest', width: { wpx: 120 } }, // BB
    { title: '' }, // BC
    { title: 'Bystander CPR time', width: { wpx: 120 } }, // BD
    { title: 'Bystander CPR', width: { wpx: 100 } }, // BE
    { title: 'Defibrillation/AED', width: { wpx: 100 } }, // BF
    { title: 'First Rhythm' }, // BG
    { title: 'ROSC' }, // BH
    { title: 'Etiology', width: { wpx: 250 } }, // BI
    { title: 'Last Rhythm' }, // BJ
    { title: 'T' }, // BK
    { title: 'SBP' }, // BL
    { title: 'DBP' }, // BM
    { title: 'PR' }, // BN
    { title: 'RR' }, // BO
    { title: 'EVM' }, // BP
    { title: 'O2 Sat' }, // BQ
    { title: 'DTX' }, // BR
    { title: 'Trauma' }, // BS
    { title: 'ผลการรักษา' }, // BT
    { title: 'ปลายทาง' }, // BU
    { title: 'ER Level' }, // BV
    { title: 'Diagnosis' }, // BW
    { title: 'แนะนำการดูแล Hypoglycemia', width: { wpx: 150 } }, // BX
    { title: 'สถานที่ไม่ถูกต้อง', width: { wpx: 100 } }, // BY
    { title: 'ชุมชนเป้าหมาย', width: { wpx: 100 } }, // BZ
    { title: 'เขตพื้นที่' }, // CA
    { title: 'หมายเหตุ' }, // CB
    { title: 'ผู้ลงข้อมูล', width: { wpx: 120 } }, // CC
  ],
}
export const rejectStatus = {
  AMBULANCE_BUSY: 'รถพยาบาลไม่พร้อม',
  OVER_CASE_LIMIT: 'เกินโควต้ารับแจ้งเรื่อง',
  OFFICER_BUSY: 'โรงพยาบาลไม่ว่าง',
}

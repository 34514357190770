import { observable, action, } from 'mobx'
import http from '../../services/httpServiceHealthCare'
const apiEndpoint = '/Submissions'

class Submission {
  @observable info = {
    data: [],
    error: null,
  }

  @action fetchSubmissions = async () => {
    try {
      const { data } = await http.get(`${apiEndpoint}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

}

export default new Submission()

import { Font, StyleSheet } from '@react-pdf/renderer'
import FontRegular from '../../asset/fonts/Sarabun-Regular.ttf'
import FontBold from '../../asset/fonts/Sarabun-Bold.ttf'
import FontItalic from '../../asset/fonts/Sarabun-Italic.ttf'

Font.register({
  family: 'Sarabun',
  fonts: [
    {
      src: FontRegular,
    },
    {
      src: FontBold,
      fontWeight: 'bold',
    },
    {
      src: FontItalic,
      fontStyle: 'italic',
    },
  ],
})

const normalFont = 'Sarabun'
const titleSize = 8

const styles = StyleSheet.create({
  titleText: {
    fontFamily: normalFont,
    fontSize: titleSize,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  mainContainer: { paddingHorizontal: '8px', paddingVertical: '3px' },
})

export default styles

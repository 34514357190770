import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register components and the plugin
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const SurveyBarChart = ({ dataSource }) => {
    const data = {
        labels: [],
        datasets: [
            {
                label: 'จำนวนคนกรอก',
                data: [],
                backgroundColor: 'rgba(75,192,192,0.6)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                yAxisID: 'y',
                datalabels: {
                    anchor: 'end',
                    align: 'start',
                    formatter: Math.round,
                    font: {
                        weight: 'bold',
                    },
                },
            },
            {
                label: 'ค่าเฉลี่ยโดยรวม',
                data: [],
                backgroundColor: 'rgba(153,102,255,0.6)',
                borderColor: 'rgba(153,102,255,1)',
                borderWidth: 1,
                yAxisID: 'y1',
                datalabels: {
                    anchor: 'end',
                    align: 'start',
                    formatter: value => `${value.toFixed(2)}%`,
                    font: {
                        weight: 'bold',
                    },
                },
            },
        ],
    };

    const MONTH_NAMES = [
        'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน',
        'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม',
        'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
    ];
    const THAI_YEAR_OFFSET = 543;

    // Process the data for the bar chart
    const dateData = {};
    dataSource.forEach((dataRow) => {
        Object.keys(dataRow).forEach((key) => {
            if (key !== 'questionTitle' && key !== 'questionId') {
                const valueMatch = dataRow[key].match(/จำนวนคน: (\d+) และ จำนวนคะแนน (\d+)\/(\d+)/);
                if (valueMatch) {
                    const countValue = parseInt(valueMatch[1], 10);
                    const pointsValue = parseInt(valueMatch[2], 10);
                    const maxPoints = parseInt(valueMatch[3], 10);
                    const avgPoints = (pointsValue / maxPoints) * 100;

                    if (!dateData[key]) {
                        dateData[key] = { count: 0, totalPoints: 0, entries: 0 };
                    }
                    dateData[key].count = countValue;
                    dateData[key].totalPoints += avgPoints;
                    dateData[key].entries += 1;
                }
            }
        });
    });

    Object.keys(dateData).forEach((date) => {
        data.labels.push(`${MONTH_NAMES[parseInt(date.split('-')[1], 10) - 1]} ${parseInt(date.split('-')[0], 10) + THAI_YEAR_OFFSET}`);
        data.datasets[0].data.push(dateData[date].count);
        data.datasets[1].data.push(dateData[date].totalPoints / dateData[date].entries);
    });

    const options = {
        plugins: {
            datalabels: {
                color: 'white',
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0; // only show labels if value is not zero
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'จำนวนคน',
                },
            },
            y1: {
                beginAtZero: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                title: {
                    display: true,
                    text: 'คะแนนเฉลี่ยโดยรวม (%)',
                },
            },
        },
    };

    return (
        <div style={{ marginBottom: 100 }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default SurveyBarChart;

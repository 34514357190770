import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'
import { DAY } from '../../constant'

const apiEndpoint = '/pharmacyAvailableTimes'

class PharmacyAvailableTime {
  @observable info = {
    data: [],
    error: null
  }

  // @action fetchfilterType = async (id, type) => {
  //   try {
  //     const { data } = await http.get(`${apiEndpoint}/filterType`, {
  //       params: {
  //         doctorId: id,
  //         type
  //       }
  //     })
  //     return data
  //   } catch (error) {
  //     console.warn(error)
  //   }
  // }
  
  @action fetchPharmacyAvailableTime = async () => {
    try {
      const time = await http.get(`${apiEndpoint}`)
      this.info.data = time.data
    } catch (error) {
      this.info.error = error.response
    }
  }

  @action fetchPharmacyAvailableTimeById = async (start, end) => {
    try {
      const { data } = await http.get(`${apiEndpoint}?filter[where][time]=[${start},${end}]`)
      return data
    } catch (error) {
      this.info.error = error.response
    }
  }

  @action addPharmacyAvailableTime = async payload => {
    try {
      const { data } = await http.post(`${apiEndpoint}`, payload)
      return data
    } catch (error) {
      console.log('adding error', error)
      return error
    }
  }

  @action deletePharmacyAvailableTime = async id => {
    try {
      const { data } = await http.delete(`${apiEndpoint}/${id}`)
      return data
    } catch (err) {
      const { response } = err
      return response.data.error
    }
  }

  @action updatePharmacyAvailableTime = async (id, info) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${id}`, {
        time: info.time,
        quantity: parseInt(info.quantity)
      })
      return data
    } catch (error) {
      console.log('patching error', error)
    }
  }

  @action checkBookingNumberPharmacy = async (info, start, end) => {
    let detail
    if (start && end) {
      detail = {
        date: info,
        startTime: start,
        endTime: end
      }
    } else {
      detail = {
        date: info.date,
        startTime: DAY[info.day][0],
        endTime: DAY[info.day][1]
      }
    }
    try {
      const { data } = await http.get(`${apiEndpoint}/checkBookingNumberPharmacy`, {
        params: detail
      })
      return data
    } catch (error) {
      console.log('error', error)
    }
  }

  @computed get getPharmacyAvailableTime() {
    return toJS(this.info.data)
  }
}

export default new PharmacyAvailableTime()

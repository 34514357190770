import { observable, action, computed, toJS } from 'mobx'
// import jwtDecode from 'jwt-decode'

import http from '../../services/httpService-miracart'

const apiEndpoint = '/products'
// const tokenKey = 'token'

class Product {
  @observable payload = {
    data: [],
    error: null,
    searchResults: [],
  }

  @action getProducts = async (filter) => {
    try {
      const URL = `${apiEndpoint}?filter=${JSON.stringify(filter)}`
      const result = await http.get(URL)
      this.payload.data = result.data
      return result.data
    } catch (e) {
      this.payload.error = e
      return []
    }
  }

  @action searchProducts = async (filter) => {
    try {
      const URL = `${apiEndpoint}?filter=${encodeURIComponent(JSON.stringify(filter))}`
      const result = await http.get(URL)
      this.payload.searchResults = result.data
      return result.data
    } catch (e) {
      this.payload.error = e
      return e
    }
  }

  @computed get getSearchResults() {
    return toJS(this.payload.searchResults)
  }


  @computed get getProductsResults() {
    return toJS(this.payload.data) || []
  }
}

export default new Product()

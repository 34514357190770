import React, { useEffect, useState } from 'react'
import { Button, Row, Col } from 'antd'
import styled from 'styled-components'
import { CalendarOutlined } from '@ant-design/icons'
const FilterButton = styled(Button)`
  background: ${({ active }) => (active === 'low' ? '#D60364' : active === 'normal' ? '#0BA989' : active === 'high' && '#424DA8')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  border-radius: 8px !important;
  &:focus {
    background: ${({ active }) => (active === 'low' ? '#D60364' : active === 'normal' ? '#0BA989' : active === 'high' && '#424DA8')};
    color: ${({ active }) => (active ? 'white' : 'black')};
    border-radius: 8px !important;
    border: 1px solid #ccc;
  }
`
const CalendarButton = styled(Button)`
  border-radius: 8px !important;
  border: ${({ active }) => (active ? '1px solid #424DA8' : '1px solid #ccc')};
  background: ${({ active }) => (active ? '#6c77d5' : 'white')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  &:focus {
    border-radius: 8px !important;
    border: ${({ active }) => (active ? '1px solid #424DA8' : '1px solid #ccc')};
    background: ${({ active }) => (active ? '#6c77d5' : 'white')};
    color: ${({ active }) => (active ? 'white' : 'black')};
  }
`
function FilterButtonGroup({ filterByLevelAndDate, bmi = false , checkAll}) {
  const [lowLevel, setLowLevel] = useState(false)
  const [normalLevel, setNormalLevel] = useState(false)
  const [highLevel, setHighLevel] = useState(false)
  const [oneMonthActive, setOneMonthActive] = useState(false)
  const [twoMonthActive, setTwoMonthActive] = useState(false)
  const [threeMonthActive, setThreeMonthActive] = useState(false)
  const [fourMonthActive, setFourMonthActive] = useState(false)
  const [selectedValues, setSelectedValue] = useState([])
  const [selectedDayRanges, setSelectedDayRanges] = useState([])

  const removeSelectedFromList = (selectedValue) => {
    const data = selectedValues.filter((value) => value !== selectedValue)
    setSelectedValue(data)
  }
  const addSelectedValue = (value) => {
    const isDuplicate = selectedValues.some((item) => (item === value ? true : false))
    if (!isDuplicate) {
      setSelectedValue([...selectedValues, value])
    }
  }
  const removeSelectedDayRange = (selectedDay) => {
    const data = selectedDayRanges.filter((value) => value !== selectedDay)
    setSelectedDayRanges(data)
  }
  const addSelectedDayRange = (dayRange) => {
    const isDuplicate = selectedDayRanges.some((item) => (item === dayRange ? true : false))
    if (!isDuplicate) {
      setSelectedDayRanges([...selectedDayRanges, dayRange])
    }
  }

  useEffect(() => {
    if(checkAll) {
      setLowLevel(false)
      setNormalLevel(false)
      setHighLevel(false)
      setOneMonthActive(false)
      setTwoMonthActive(false)
      setThreeMonthActive(false)
      setFourMonthActive(false)
      setSelectedValue([])
      setSelectedDayRanges([])
    }
  },[checkAll])

  useEffect(() => {
    filterByLevelAndDate(selectedValues, selectedDayRanges)
  }, [selectedValues, selectedDayRanges])

  const setValue = (status, value) => {
    switch (value) {
      case 'low':
        setLowLevel(status)
        break
      case 'normal':
        setNormalLevel(status)
        break
      case 'high':
        setHighLevel(status)
        break
      default:
        break
    }
    status === true ? addSelectedValue(value) : removeSelectedFromList(value)
  }

  const setDayRange = (status, dayRange) => {
    switch (dayRange) {
      case 30:
        setOneMonthActive(status)
        break
      case 60:
        setTwoMonthActive(status)
        break
      case 90:
        setThreeMonthActive(status)
        break
      case 120:
        setFourMonthActive(status)
        break
      default:
        break
    }
    status === true ? addSelectedDayRange(dayRange) : removeSelectedDayRange(dayRange)
  }

  return (
    <>
      <FilterButton
        size="small"
        className="mx-1"
        active={lowLevel && 'low'}
        onClick={() => {
          setValue(!lowLevel, 'low')
        }}
      >
        {bmi ? 'BMI Low' : 'LOW'}
      </FilterButton>
      <FilterButton
        size="small"
        className="mx-1"
        active={normalLevel && 'normal'}
        onClick={() => {
          setValue(!normalLevel, 'normal')
        }}
      >
        {bmi ? 'BMI Normal' : 'NORMAL'}
      </FilterButton>
      <FilterButton
        size="small"
        className="mx-1"
        active={highLevel && 'high'}
        onClick={() => {
          setValue(!highLevel, 'high')
        }}
      >
        {bmi ? 'BMI High' : 'HIGH'}
      </FilterButton>
      <CalendarButton
        icon={<CalendarOutlined />}
        className="d-flex align-items-center mx-1"
        size="small"
        active={oneMonthActive}
        onClick={() => {
          setDayRange(!oneMonthActive, 30)
        }}
      >
        30
      </CalendarButton>
      <CalendarButton
        icon={<CalendarOutlined />}
        className="d-flex align-items-center mx-1"
        size="small"
        active={twoMonthActive}
        onClick={() => {
          setDayRange(!twoMonthActive, 60)
        }}
      >
        60
      </CalendarButton>
      <CalendarButton
        icon={<CalendarOutlined />}
        className="d-flex align-items-center mx-1"
        size="small"
        active={threeMonthActive}
        onClick={() => {
          setDayRange(!threeMonthActive, 90)
        }}
      >
        90
      </CalendarButton>
      <CalendarButton
        icon={<CalendarOutlined />}
        className="d-flex align-items-center mx-1"
        size="small"
        active={fourMonthActive}
        onClick={() => {
          setDayRange(!fourMonthActive, 120)
        }}
      >
        120
      </CalendarButton>
    </>
  )
}

export default FilterButtonGroup

import { observable, action, computed, toJS } from 'mobx';
import jwtDecode from 'jwt-decode';
import http from '../../services/httpServiceEver';

const apiEndpoint = '/trackingNumbers';

class TrackingData {
  @observable info = {
    data: [],
    error: null,
  };

  @action fetchTrackingData = async () => {
    try {
      const { data } = await http.get(`${apiEndpoint}`);
      this.info.data = data;
      return data;
    } catch (error) {
      this.info.error = error.response;
    }
  };

  @action fetchTrackingDataAvailable = async () => {
    try {
      const { data } = await http.get(`${apiEndpoint}/checkAvailables`);
      return data.available
      return data;
    } catch (error) {
      return error
    }
  };

  @action updateThaipost = async (trackingNumber) => {
    try {
      const { data } = await http.post(`${apiEndpoint}/thaiPostHookTrack`,{
        trackingNumber
      });
      return data
      return data;
    } catch (error) {
      this.info.error = error.response;
    }
  };


  @action addTrackingData = async (payload) => {
    try {
      const { data } = await http.post(`${apiEndpoint}/insertTrackingNumber`, {
        startTrackingNumber: payload.startTrackingNumber,
        endTrackingNumber: payload.endTrackingNumber,
      });
      return data;
    } catch (error) {
      console.log('patching error', error);
    }
  };

  @computed get getTrackingData() {
    return toJS(this.info.data);
  }
}

export default new TrackingData();

import React , { useEffect , useState } from 'react';
import { render } from 'react-dom';
import Button from '../Button';
import { Spin,Tag } from 'antd';
import http from '../../services/httpServiceHealthCare';
import httpEver from '../../services/httpServiceEver';
import moment from 'moment';
import 'moment/locale/th';

const GenQrCodePayment = ({record, loading, setIsVisibleQr, setPatientHn, setPatientVn, addValueQrCode, setListVn}) => {
    const [ buttonQr , setButtonQr ] = useState(false)
    useEffect(()=>{
        setButtonQr(false)
    },[record])

    const buttonQrPayment = (record) => {
        return (
          <Button 
            onClick={()=> {
              setPatientVn(null);
              addValueQrCode('');
              setButtonQr(true);
              instantStatusPayment(record);
            }} >
              Gen QR Code
          </Button> 
        )
      }
      const fetchAppUserIdentity = async (id) => {
        const { data } = await httpEver.get(`/AppUserIdentities?filter[where][appUserId]=${id}`)
        const popData = Array.isArray(data) ? data?.pop() : ''
        console.log('fetchAppUserIdentity ',popData)
        return popData?.everOmaId || '';
      }
      const retrieveData = async (patientId,vstDate) => {
        const response = await http.get(`/UserInfos/newTriggerUpdateData?patientId=${patientId}&vstDate=${vstDate}`);
      }

      const getHnPatient = async (cId) => {
        const response = await http.get(`/UserInfos/hiePatientInfo?cId=${cId}`);
        const HnId = response?.data ? response?.data?.hn[0]?.hn : ''
        return HnId
      }

      const instantStatusPayment = async (res) => {
        const { patientId, admitTime, patient } = res;
        const startDate = moment(admitTime).subtract(1,'day').format('YYYY-MM-DD') + "T17:00:00Z"
        const endDate = moment(admitTime).format('YYYY-MM-DD') + "T17:00:00Z"
        // console.log('helloo ===',record)
        if(patientId) {
          try{
            const omaId = await fetchAppUserIdentity(patientId);
            await retrieveData(omaId,startDate);
            const { data } = await http.get(`/UserInfos/newCheckUserVisitedByVerifiedFromHIEFilterDiagnoses?patientId=${omaId}&startDate=${startDate}&endDate=${endDate}`)
            // console.log('data.docs :: ',data.docs)
            if(data?.docs){
              const filterAppointment = data?.docs?.filter((item)=> item?.paymentSummarys?.length != 0 && item?.hasOwnProperty('payments'))
              // console.log('filterAppointment :: ',filterAppointment)
              setPatientHn(filterAppointment[0]?.payments[0]?.hn || await getHnPatient(record?.patient?.cId));
              if(data?.docs > 1){
                const result = filterAppointment.reduce((unique, o) => {
                  if(!unique.some(obj => obj.vn === o.vn && obj._id !== o._id)) {
                    unique.push(o);
                  }
                  return unique;
              },[]);
              setListVn(result || []);
              // console.log('result====',result);
              // console.log('data?.docs ====',data?.docs);
              }else setListVn(filterAppointment || []);
            }else setListVn([])
          }catch(e){
            console.log('error :: ',e)
          }
        }
        setButtonQr(false)
        setIsVisibleQr(true);
      } 

      
    return buttonQr ? loading : buttonQrPayment(record)
    
}

export default GenQrCodePayment
export const STAFF_NAME = [
  {
    id: 1,
    name: 'นายสุระ ประเสริฐกุล',
  },
  {
    id: 2,
    name: 'น.ส.สุธาทิพย์ แสงสว่าง',
  },
  {
    id: 3,
    name: 'น.ส.ปิ่นปินัทธ์ ปิติสุภะโชค',
  },
  {
    id: 4,
    name: 'นางละออง ทวดสิญจน์',
  },
  {
    id: 5,
    name: 'น.ส.วนัทนันท์ สุวรรณสิทธิ์',
  },
  {
    id: 6,
    name: 'น.ส.ศลิน ทวดสิญจน์',
  },
  {
    id: 7,
    name: 'นายทรงพล บุตรสุวรรณ์',
  },
  {
    id: 8,
    name: 'นางอำพันธ์ เจนสุวรรณ์',
  },
  {
    id: 9,
    name: 'น.ส.พัชรีดา แผนกำเหนิด',
  },
  {
    id: 10,
    name: 'นายธีรภัค โชติพรม',
  },
]
export const bdrh = {
  '+': 10,
  '-': 20,
  ไม่ระบุ: 40,
}
export const gender = {
  ชาย: 10,
  หญิง: 20,
  ไม่ชัดเจน: 99,
}
export const bagType = {
  '450ccQB': 12,
  '350ccDB': 5,
  SDP: 18,
}
export const bloodgrp = {
  A: 10,
  B: 20,
  AB: 30,
  O: 40,
  ไม่ระบุ: 110,
}

export const title = {
  พลฯ: 101,
  พลฯหญิง: 102,
  'ส.ต.ต.': 103,
  'ส.ต.ต.หญิง': 104,
  'ส.ต.ท.': 105,
  'ส.ต.ท.หญิง': 106,
  'ส.ต.อ.': 107,
  'ส.ต.อ.หญิง': 108,
  'จ.ส.ต.': 109,
  'จ.ส.ต.หญิง': 110,
  'ด.ต.': 111,
  'ด.ต.หญิง': 112,
  'ร.ต.ต.': 113,
  'ร.ต.ต.หญิง': 114,
  'ร.ต.ท.': 115,
  'ร.ต.ท.หญิง': 116,
  'ร.ต.อ.': 117,
  'ร.ต.อ.หญิง': 118,
  'พ.ต.ต.': 119,
  'พ.ต.ต.หญิง': 120,
  'พ.ต.ท.': 121,
  'พ.ต.ท.หญิง': 122,
  'พ.ต.อ.': 123,
  'พ.ต.อ.หญิง': 124,
  'พ.ต.อ.พิเศษ': 125,
  'พ.ต.อ.พิเศษหญิง': 126,
  'พล.ต.ต.': 127,
  'พล.ต.ต.หญิง': 128,
  'พล.ต.ท.': 129,
  'พล.ต.ท.หญิง': 130,
  'พล.ต.อ.': 131,
  'พล.ต.อ.หญิง': 132,
  'ว่าที่ ร.ต.': 133,
  'ว่าที่ ร.ต.หญิง': 134,
  'ว่าที่ ร.ท.': 135,
  'ว่าที่ ร.ท.หญิง': 136,
  'ว่าที่ ร.อ.': 137,
  'ว่าที่ ร.อ.หญิง': 138,
  'นดย.': 139,
  'นดย.หญิง': 140,
  พลฯอาสาสมัคร: 141,
  พลฯอาสาสมัครหญิง: 142,
  'พล.ต.ต.ม.ร.ว.': 143,
  'พ.ต.ท.ม.ล.': 144,
  พลฯทหาร: 201,
  พลฯทหารหญิง: 202,
  'ส.ต.': 203,
  'ส.ต.หญิง': 204,
  'ส.ท.': 205,
  'ส.ท.หญิง': 206,
  'ส.อ.': 207,
  'ส.อ.หญิง': 208,
  'จ.ส.ต.': 209,
  'จ.ส.ต.หญิง': 210,
  'จ.ส.ท.': 211,
  'จ.ส.ท.หญิง': 212,
  'จ.ส.อ.': 213,
  'จ.ส.อ.หญิง': 214,
  'ร.ต.': 215,
  'ร.ต.หญิง': 216,
  'ร.ท.': 217,
  'ร.ท.หญิง': 218,
  'ร.อ.': 219,
  'ร.อ.หญิง': 220,
  'พ.ต.': 221,
  'พ.ต.หญิง': 222,
  'พ.ท.': 223,
  'พ.ท.หญิง': 224,
  'พ.อ.': 225,
  'พ.อ.หญิง': 226,
  'พล.ต.': 227,
  'พล.ต.หญิง': 228,
  'พล.ท.': 229,
  'พล.ท.หญิง': 230,
  'พล.อ.': 231,
  พลเอกหญิง: 232,
  จอมพล: 233,
  จอมพลหญิง: 234,
  'ว่าที่ ร.ต.': 235,
  'ว่าที่ ร.ต.หญิง': 236,
  'ว่าที่ ร.ท.': 237,
  'ว่าที่ ร.ท.หญิง': 238,
  'ว่าที่ ร.อ.': 239,
  'ว่าที่ ร.อ.หญิง': 240,
  'นดย.': 241,
  'นดย.หญิง': 242,
  พลฯอาสาสมัคร: 243,
  พลฯอาสาสมัครหญิง: 244,
  'จ.ต.': 301,
  'จ.ต.หญิง': 302,
  'จ.ท.': 303,
  'จ.ท.หญิง': 304,
  'จ.อ.': 305,
  'จ.อ.หญิง': 306,
  'พ.จ.ต.': 307,
  'พ.จ.ต.หญิง': 308,
  'พ.จ.ท.': 309,
  'พ.จ.ท.หญิง': 310,
  'พ.จ.อ.': 311,
  'พ.จ.อ.หญิง': 312,
  'ร.ต.': 313,
  'ร.ต.หญิง': 314,
  'ร.ท.': 315,
  'ร.ท.หญิง': 316,
  'ร.อ.': 317,
  'ร.อ.หญิง': 318,
  'น.ต.': 319,
  'น.ต.หญิง': 320,
  'น.ท.': 321,
  'น.ท.หญิง': 322,
  'น.อ.': 323,
  'น.อ.หญิง': 324,
  'พล.ร.ต.': 325,
  'พล.ร.ท.': 327,
  'พล.ร.ท.หญิง': 328,
  'พล.ร.อ.หญิง': 330,
  'ว่าที่ ร.ต.หญิง': 332,
  'ว่าที่ ร.ท.': 333,
  'ว่าที่ ร.อ.': 335,
  'ว่าที่ ร.อ.หญิง': 336,
  'นดย.': 337,
  'นดย.หญิง': 338,
  พลฯอาสาสมัคร: 339,
  พลฯอาสาสมัครหญิง: 340,
  'จ.ต.หญิง': 402,
  'จ.ท.': 403,
  'จ.อ.': 405,
  'จ.อ.หญิง': 406,
  'พ.อ.ต.': 407,
  'พ.อ.ท.': 409,
  'พ.อ.ท.หญิง': 410,
  'ร.ต.': 413,
  'ร.ต.หญิง': 414,
  'ร.ท.': 415,
  'ร.อ.': 417,
  'ร.อ.หญิง': 418,
  'น.ต.': 419,
  'น.ท.': 421,
  'น.ท.หญิง': 422,
  'น.อ.': 423,
  'พล.อ.ต.': 425,
  'พล.อ.ต.หญิง': 426,
  'พล.อ.ท.': 427,
  'พล.อ.อ.': 429,
  'พล.อ.อ.หญิง': 430,
  'ว่าที่ ร.ต.': 431,
  'ว่าที่ ร.ท.': 433,
  'ว่าที่ ร.ท.หญิง': 434,
  'ว่าที่ ร.อ.': 435,
  'ว่าที่ ร.อ.หญิง': 436,
  พลฯ: 437,
  'นจอ.': 439,
  'นพอ.': 440,
  'นพอ.หญิง': 441,
  'นดย.': 442,
  'นดย.หญิง': 443,
  พลฯอาสาสมัคร: 444,
  พลฯอาสาสมัครหญิง: 445,
  'ว่าที่ พ.ต.': 446,
  'ว่าที่ พล ต.ต.': 447,
  พลฯอาสาสมัคร: 501,
  พลอาสาฯ: 502,
  'พ.อ.พิเศษ': 503,
  พลฯสำรองพิเศษ: 504,
  พลฯสมัคร: 505,
  อาสาสมัครทหารพราน: 506,
  'นตท.': 507,
  'นรต.': 508,
  'นนร.': 509,
  'นนร.': 510,
  'Mr.': 52,
  'Mrs.': 53,
  'Miss.': 54,
  นาย: 12,
  'ด.ญ.': 11,
  'ด.ช.': 10,
  นาง: 13,
  พระภิกษุ: 15,
  สามเณร: 16,
  แม่ชี: 17,
  คุณหญิง: 18,
  'ม.ล.': 19,
  'ม.ล.หญิง': 20,
  'ม.ร.ว.': 21,
  'ม.ร.ว.หญิง': 22,
  'ม.จ.': 23,
  'ม.จ.หญิง': 24,
  หม่อม: 25,
  ไม่ระบุ: 99,
  คุณ: 26,
  พระมหา: 28,
  พระเจ้าหลานเธอพระองค์เจ้า: 41,
  'นพ.': 43,
  'พญ.': 44,
  'ศ.พญ.': 45,
  'ศ.นพ.': 46,
  'ผศ.ดร.': 47,
  'รศ.': 48,
  'ดร.': 49,
  'ศ.ดร.': 50,
  'ผศ.': 51,
  'พล.อ.ดร.': 511,
  'รศ.ดร.': 512,
  พระครู: 55,
  'ร.อ.ม.ล.': 518,
  'ว่าที่ ร.ต.ท.หญิง': 513,
  'มว.ต.': 514,
  'ว่าที่ ร.ต.ต.': 516,
  'Buddhist Monk': 522,
  'Buddhist Nun': 523,
  Buddhist: 524,
  ภิกษุณี: 526,
  'พ.อ.อ.': 411,
  พลฯสำรอง: 515,
  'พล.ร.ต.หญิง': 326,
  'พล.ร.อ.': 329,
  'ว่าที่ ร.ต.': 331,
  'ว่าที่ ร.ท.หญิง': 334,
  'จ.ต.': 401,
  'จ.ท.หญิง': 404,
  'พ.อ.ต.หญิง': 408,
  'พ.อ.อ.หญิง': 412,
  'ร.ท.หญิง': 416,
  'น.ต.หญิง': 420,
  'น.อ.หญิง': 424,
  'พล.อ.ท.หญิง': 428,
  'ว่าที่ ร.ต.หญิง': 432,
  'นนอ.': 438,
  พระอธิการ: 519,
  Master: 520,
  '-': 999,
  PHRAMAHA: 56,
  'อส.ทพ.หญิง': 517,
  'น.ส.': 14,
  'ว่าที่ ร.ต.ต.หญิง': 521,
  พระภิกขุณี: 525,
  พระปลัด: 527,
  'พล.อ.ม.ล': 528,
  สมเด็จพระมหา: 529,
  'Buddhist Woman': 530,
  พระใบฎีกา: 531,
  พระราชจริยาภรณ์: 532,
  'น.ท.ดร.': 533,
}

export const occptn = {
  สถาปนิก: 1,
  วิศวกร: 2,
  นักสำรวจ: 3,
  ช่างเทคนิควิศวกรรม: 4,
  นักวิทยาศาสตร์: 5,
  แพทย์: 6,
  ศัลยแพทย์: 7,
  ทันตแพทย์: 8,
  สัตว์แพทย์: 9,
  อาจารย์มหาวิทยาลัย: 10,
  อาจารย์โรงเรียน: 11,
  พยาบาล: 12,
  เภสัชกร: 13,
  ผู้ปฏิบัติงานเทคนิคการแพทย์: 14,
  'พนง.ช่วยเหลือด้านการแพทย์': 15,
  ผู้พิพากษา: 16,
  อัยการ: 17,
  ประติมากร: 18,
  จิตรกร: 19,
  ช่างศิลป์: 20,
  ช่างภาพ: 21,
  นักประพันธ์: 22,
  นักข่าว: 23,
  นักหนังสือพิมพ์: 24,
  นักแสดง: 25,
  นางแบบ: 26,
  นักร้อง: 27,
  นักดนตรี: 28,
  นักสังคมสงเคราะห์: 29,
  นักสังคมวิทยา: 30,
  นักสถิติ: 31,
  นักเศรษฐศาสตร์: 32,
  ผู้สอนศาสนา: 33,
  อนุศาสนาจารย์: 34,
  นักบัญชี: 35,
  'ผู้ปฏิบัติงานวิชาการอื่น ๆ': 36,
  ทนายความ: 37,
  นักพยากรณ์: 38,
  รับราชการ: 40,
  ทหารบก: 41,
  ทหารเรือ: 42,
  ทหารอากาศ: 43,
  ตำรวจ: 44,
  ข้าราชการการเมือง: 45,
  'พนง.หน่วยงานของรัฐอื่น ๆ': 46,
  ข้าราชการบำนาญ: 47,
  ลูกจ้างชั่วคราว: 48,
  ลูกจ้างประจำ: 49,
  กำนัน: 50,
  สารวัตรกำนัน: 51,
  ผู้ใหญ่บ้าน: 52,
  ผู้ช่วยผู้ใหญ่บ้าน: 53,
  แพทย์ประจำตำบล: 54,
  'นักการ - ภารโรง': 55,
  ผู้บริหารรัฐวิสาหกิจ: 56,
  พนักงานรัฐวิสาหกิจ: 57,
  'พนง.หน่วยงานรัฐวิสาหกิจอื่น ๆ': 58,
  เจ้าของกิจการ: 59,
  พนักงานหน่วยงานเอกชน: 60,
  รับจ้าง: 61,
  กรรมกร: 62,
  'พนง.หน่วยงานเอกชนอื่น ๆ': 63,
  เสมียน: 64,
  กสิกรรม: 65,
  เกษตรกรรม: 66,
  ทำนา: 67,
  ทำสวน: 68,
  ทำไร่: 69,
  ทำฟาร์ม: 70,
  ประมง: 71,
  ล่าสัตว์: 72,
  'กสิกรรม เลี้ยงสัตว์อื่น': 73,
  ค้าส่ง: 74,
  ค้าปลีก: 75,
  หาบเร่: 76,
  ตัวแทนจำหน่าย: 77,
  'ผู้ปฏิบัติงานการค้าอื่น ๆ': 78,
  ค้าขาย: 79,
  นายหน้า: 80,
  พนักงานขาย: 81,
  พนักงานโฆษณา: 82,
  พนักงานประชาสัมพันธ์: 83,
  พนักงานเสริฟ: 84,
  พนักงานโรงแรม: 85,
  พนักงานประจำสถานบริการ: 86,
  พนักงานขับรถเมล์: 87,
  คนขับรถรับจ้าง: 88,
  คนขับรถที่ใช้สัตว์ลากเทียม: 89,
  คนขับเรือ: 90,
  คนปรุงอาหาร: 91,
  คนเลี้ยงเด็ก: 92,
  คนรับใช้: 93,
  คนทำความสะอาด: 94,
  คนซักรีด: 95,
  ช่างตัดผม: 96,
  ช่างเสริมสวย: 97,
  'ผู้ปฏิบัติงานด้านบริการอื่น ๆ': 98,
  ผู้รักษาความปลอดภัย: 99,
  ช่างตัดเย็บเสื้อผ้า: 100,
  ช่างเย็บหนัง: 101,
  ช่างซ่อมเครื่องไฟฟ้า: 102,
  ช่างซ่อมวิทยุโทรทัศน์: 103,
  ช่างซ่อมเครื่องยนต์: 104,
  ช่างอีเลคโทรนิคส์: 105,
  ช่างเคาะพ่นสีรถยนต์: 106,
  ช่างซ่อมนาฬิกา: 107,
  ช่างทอง: 108,
  ช่างเพชรพลอย: 109,
  ช่างเหล็ก: 110,
  ช่างโลหะ: 111,
  ช่างเชื่อมโลหะ: 112,
  ช่างหลอมโลหะ: 113,
  ช่างชุบโลหะ: 114,
  ช่างไม้: 115,
  ช่างเฟอร์นิเจอร์: 116,
  ช่างปูน: 117,
  ช่างทาสี: 118,
  ช่างพิมพิ์: 119,
  'ช่างทอ ปั่น จักสาน': 120,
  ช่างถ่ายรูป: 121,
  ช่างล้างอัดรูป: 122,
  'ผู้ปฏิบัติงานด้านช่างอื่น ๆ': 123,
  นักศึกษา: 124,
  นักบวช: 126,
  ในความปกครอง: 127,
  นักเรียน: 129,
  แม่บ้าน: 131,
  พ่อบ้าน: 132,
  ธุรกิจส่วนตัว: 134,
}

export const subArea = {
  ย่านซื่อ: 150114,
  จรเข้ร้อง: 150201,
  ไชยภูมิ: 150202,
  ชัยฤทธิ์: 150203,
  เทวราช: 150204,
  ราชสถิตย์: 150205,
  ไชโย: 150206,
  หลักฟ้า: 150207,
  ชะไว: 150208,
  ตรีณรงค์: 150209,
  บางปลากด: 150301,
  ป่าโมก: 150302,
  สายทอง: 150303,
  โรงช้าง: 150304,
  บางเสด็จ: 150305,
  นรสิงห์: 150306,
  เอกราช: 150307,
  โผงเผง: 150308,
  อ่างแก้ว: 150401,
  อินทประมูล: 150402,
  บางพลับ: 150403,
  หนองแม่ไก่: 150404,
  รำมะสัก: 150405,
  บางระกำ: 150406,
  โพธิ์รังนก: 150407,
  องครักษ์: 150408,
  โคกพุทรา: 150409,
  ยางช้าย: 150410,
  บ่อแร่: 150411,
  ทางพระ: 150412,
  สามง่าม: 150413,
  บางเจ้าฉ่า: 150414,
  คำหยาด: 150415,
  แสวงหา: 150501,
  ศรีพราน: 150502,
  บ้านพราน: 150503,
  วังน้ำเย็น: 150504,
  สีบัวทอง: 150505,
  ห้วยไผ่: 150506,
  จำลอง: 150507,
  ไผ่จำศิล: 150601,
  ศาลเจ้าโรงทอง: 150602,
  ไผ่ดำพัฒนา: 150603,
  สาวร้องไห้: 150604,
  ท่าช้าง: 150605,
  ยี่ล้น: 150606,
  บางจัก: 150607,
  ห้วยคันแหลน: 150608,
  คลองขนาก: 150609,
  ไผ่วง: 150610,
  สี่ร้อย: 150611,
  ม่วงเตี้ย: 150612,
  หัวตะพาน: 150613,
  หลักแก้ว: 150614,
  ตลาดใหม่: 150615,
  สามโก้: 150701,
  ราษฎรพัฒนา: 150702,
  อบทม: 150703,
  โพธิ์ม่วงพันธ์: 150704,
  มงคลธรรมนิมิต: 150705,
  ทะเลชุบศร: 160101,
  ท่าหิน: 160102,
  กกโก: 160103,
  โก่งธนู: 160104,
  เขาพระงาม: 160105,
  เขาสามยอด: 160106,
  โคกกะเทียม: 160107,
  โคกลำพาน: 160108,
  โคกตูม: 160109,
  งิ้วราย: 160110,
  ดอนโพธิ์: 160111,
  ตะลุง: 160112,
  ท่าแค: 160114,
  ท่าศาลา: 160115,
  นิคมสร้างตนเอง: 160116,
  บางขันหมาก: 160117,
  บ้านข่อย: 160118,
  ท้ายตลาด: 160119,
  ป่าตาล: 160120,
  พรหมมาสตร์: 160121,
  โพธิ์เก้าต้น: 160122,
  โพธิ์ตรุ: 160123,
  สี่คลอง: 160124,
  ถนนใหญ่: 160125,
  พัฒนานิคม: 160201,
  ช่องสาริกา: 160202,
  มะนาวหวาน: 160203,
  ดีลัง: 160204,
  โคกสลุง: 160205,
  ชอนน้อย: 160206,
  หนองบัว: 160207,
  ห้วยขุนราม: 160208,
  น้ำสุด: 160209,
  โคกสำโรง: 160301,
  เกาะแก้ว: 160302,
  ถลุงเหล็ก: 160303,
  หลุมข้าว: 160304,
  ห้วยโป่ง: 160305,
  คลองเกตุ: 160306,
  สะแกราบ: 160307,
  เพนียด: 160308,
  วังเพลิง: 160309,
  ดงมะรุม: 160310,
  วังขอนขว้าง: 160318,
  วังจั่น: 160320,
  หนองแขม: 160322,
  ลำนารายณ์: 160401,
  ชัยนารายณ์: 160402,
  ศิลาทิพย์: 160403,
  ห้วยหิน: 160404,
  ม่วงค่อม: 160405,
  บัวชุม: 160406,
  ท่าดินดำ: 160407,
  มะกอกหวาน: 160408,
  ซับตะเคียน: 160409,
  นาโสม: 160410,
  หนองยายโต๊ะ: 160411,
  เกาะรัง: 160412,
  ท่ามะนาว: 160414,
  นิคมลำนารายณ์: 160417,
  ชัยบาดาล: 160418,
  บ้านใหม่สามัคคี: 160419,
  เขาแหลม: 160422,
  ท่าวุ้ง: 160501,
  บางคู้: 160502,
  โพตลาดแก้ว: 160503,
  บางลี่: 160504,
  บางงา: 160505,
  โคกสลุด: 160506,
  เขาสมอคอน: 160507,
  หัวสำโรง: 160508,
  ลาดสาลี่: 160509,
  บ้านเบิก: 160510,
  มุจลินท์: 160511,
  ไผ่ใหญ่: 160601,
  บ้านทราย: 160602,
  บ้านกล้วย: 160603,
  ดงพลับ: 160604,
  บ้านชี: 160605,
  พุคา: 160606,
  หินปัก: 160607,
  บางพึ่ง: 160608,
  หนองทรายขาว: 160609,
  บางกะพี้: 160610,
  หนองเต่า: 160611,
  โพนทอง: 160612,
  บางขาม: 160613,
  ดอนดึง: 160614,
  ชอนม่วง: 160615,
  หนองกระเบียน: 160616,
  สายห้วยแก้ว: 160617,
  มหาสอน: 160618,
  บ้านหมี่: 160619,
  เชียงงา: 160620,
  หนองเมือง: 160621,
  สนามแจง: 160622,
  ท่าหลวง: 160701,
  แก่งผักกูด: 160702,
  ซับจำปา: 160703,
  หนองผักแว่น: 160704,
  ทะเลวังวัด: 160705,
  หัวลำ: 160706,
  สระโบสถ์: 160801,
  มหาโพธิ: 160802,
  ทุ่งท่าช้าง: 160803,
  ห้วยใหญ่: 160804,
  นิยมชัย: 160805,
  โคกเจริญ: 160901,
  ยางราก: 160902,
  หนองมะค่า: 160903,
  วังทอง: 160904,
  โคกแสมสาร: 160905,
  ลำสนธิ: 161001,
  ซับสมบูรณ์: 161002,
  หนองรี: 161003,
  กุดตาเพชร: 161004,
  เขารวก: 161005,
  เขาน้อย: 161006,
  หนองม่วง: 161101,
  บ่อทอง: 161102,
  ดงดินแดง: 161103,
  ชอนสมบูรณ์: 161104,
  ยางโทน: 161105,
  ชอนสารเดช: 161106,
  บางพุทรา: 170101,
  บางมัญ: 170102,
  โพกรวม: 170103,
  ม่วงหมู่: 170104,
  หัวไผ่: 170105,
  ต้นโพธิ์: 170106,
  จักรสีห์: 170107,
  บางกระบือ: 170108,
  สิงห์: 170201,
  ไม้ดัด: 170202,
  เชิงกลัด: 170203,
  โพชนไก่: 170204,
  แม่ลา: 170205,
  บ้านจ่า: 170206,
  พักทัน: 170207,
  สระแจง: 170208,
  โพทะเล: 170301,
  บางระจัน: 170302,
  โพสังโฆ: 170303,
  ท่าข้าม: 170304,
  คอทราย: 170305,
  หนองกระทุ่ม: 170306,
  พระงาม: 170401,
  พรหมบุรี: 170402,
  บางน้ำเชี่ยว: 170403,
  บ้านหม้อ: 170404,
  บ้านแป้ง: 170405,
  หัวป่า: 170406,
  โรงช้าง: 170407,
  ถอนสมอ: 170501,
  โพประจักษ์: 170502,
  วิหารขาว: 170503,
  พิกุลทอง: 170504,
  อินทร์บุรี: 170601,
  ประศุก: 170602,
  ทับยา: 170603,
  งิ้วราย: 170604,
  ชีน้ำร้าย: 170605,
  ท่างาม: 170606,
  น้ำตาล: 170607,
  ทองเอน: 170608,
  ห้วยชัน: 170609,
  โพธิ์ชัย: 170610,
  ในเมือง: 180101,
  บ้านกล้วย: 180102,
  ท่าชัย: 180103,
  ชัยนาท: 180104,
  เขาท่าพระ: 180105,
  หาดท่าเสา: 180106,
  ธรรมามูล: 180107,
  เสือโฮก: 180108,
  นางลือ: 180109,
  คุ้งสำเภา: 180201,
  วัดโคก: 180202,
  ศิลาดาน: 180203,
  ท่าฉนวน: 180204,
  หางน้ำสาคร: 180205,
  ไร่พัฒนา: 180206,
  อู่ตะเภา: 180207,
  วัดสิงห์: 180301,
  มะขามเฒ่า: 180302,
  หนองน้อย: 180303,
  หนองบัว: 180304,
  หนองขุ่น: 180306,
  บ่อแร่: 180307,
  วังหมัน: 180311,
  สรรพยา: 180401,
  ตลุก: 180402,
  เขาแก้ว: 180403,
  โพนางดำตก: 180404,
  โพนางดำออก: 180405,
  บางหลวง: 180406,
  หาดอาษา: 180407,
  แพรกศรีราชา: 180501,
  เที่ยงแท้: 180502,
  ห้วยกรด: 180503,
  โพงาม: 180504,
  บางขุด: 180505,
  ดงคอน: 180506,
  ดอนกำ: 180507,
  ห้วยกรดพัฒนา: 180508,
  หันคา: 180601,
  บ้านเชี่ยน: 180602,
  ไพรนกยูง: 180605,
  หนองแซง: 180606,
  ห้วยงู: 180607,
  วังไก่เถื่อน: 180608,
  เด่นใหญ่: 180609,
  สามง่ามท่าโบสถ์: 180611,
  หนองมะโมง: 180701,
  วังตะเคียน: 180702,
  สะพานหิน: 180703,
  กุดจอก: 180704,
  เนินขาม: 180801,
  กะบกเตี้ย: 180802,
  สุขเดือนห้า: 180803,
  ปากเพรียว: 190101,
  ดาวเรือง: 190105,
  นาโฉง: 190106,
  โคกสว่าง: 190107,
  หนองโน: 190108,
  หนองยาว: 190109,
  ปากข้าวสาร: 190110,
  หนองปลาไหล: 190111,
  กุดนกเปล้า: 190112,
  ตลิ่งชัน: 190113,
  ตะกุด: 190114,
  แก่งคอย: 190201,
  ทับกวาง: 190202,
  ตาลเดี่ยว: 190203,
  ห้วยแห้ง: 190204,
  ท่าคล้อ: 190205,
  หินซ้อน: 190206,
  บ้านธาตุ: 190207,
  บ้านป่า: 190208,
  ท่าตูม: 190209,
  ชะอม: 190210,
  สองคอน: 190211,
  เตาปูน: 190212,
  ชำผักแพว: 190213,
  ท่ามะปราง: 190215,
  หนองแค: 190301,
  กุ่มหัก: 190302,
  คชสิทธิ์: 190303,
  โคกตูม: 190304,
  โคกแย้: 190305,
  บัวลอย: 190306,
  ไผ่ต่ำ: 190307,
  โพนทอง: 190308,
  ห้วยขมิ้น: 190309,
  ห้วยทราย: 190310,
  หนองไข่น้ำ: 190311,
  หนองแขม: 190312,
  หนองจิก: 190313,
  หนองจรเข้: 190314,
  หนองนาก: 190315,
  หนองปลาหมอ: 190316,
  หนองปลิง: 190317,
  หนองโรง: 190318,
  หนองหมู: 190401,
  บ้านลำ: 190402,
  คลองเรือ: 190403,
  วิหารแดง: 190404,
  หนองสรวง: 190405,
  เจริญธรรม: 190406,
  หนองแซง: 190501,
  หนองควายโซ: 190502,
  หนองหัวโพ: 190503,
  หนองสีดา: 190504,
  หนองกบ: 190505,
  ไก่เส่า: 190506,
  โคกสะอาด: 190507,
  ม่วงหวาน: 190508,
  เขาดิน: 190509,
  บ้านหมอ: 190601,
  บางโขมด: 190602,
  สร่างโศก: 190603,
  ตลาดน้อย: 190604,
  หรเทพ: 190605,
  โคกใหญ่: 190606,
  ไผ่ขวาง: 190607,
  บ้านครัว: 190608,
  หนองบัว: 190609,
  ดอนพุด: 190701,
  ไผ่หลิ่ว: 190702,
  บ้านหลวง: 190703,
  ดงตะงาว: 190704,
  หนองโดน: 190801,
  บ้านกลับ: 190802,
  ดอนทอง: 190803,
  บ้านโปร่ง: 190804,
  พระพุทธบาท: 190901,
  ขุนโขลน: 190902,
  ธารเกษม: 190903,
  นายาว: 190904,
  พุคำจาน: 190905,
  เขาวง: 190906,
  ห้วยป่าหวาย: 190907,
  พุกร่าง: 190908,
  หนองแก: 190909,
  เสาไห้: 191001,
  บ้านยาง: 191002,
  หัวปลวก: 191003,
  งิ้วงาม: 191004,
  ศาลารีไทย: 191005,
  ต้นตาล: 191006,
  ท่าช้าง: 191007,
  พระยาทด: 191008,
  ม่วงงาม: 191009,
  เริงราง: 191010,
  เมืองเก่า: 191011,
  สวนดอกไม้: 191012,
  มวกเหล็ก: 191101,
  มิตรภาพ: 191102,
  หนองย่างเสือ: 191104,
  ลำสมพุง: 191105,
  ลำพญากลาง: 191107,
  ซับสนุ่น: 191109,
  แสลงพัน: 191201,
  คำพราน: 191202,
  วังม่วง: 191203,
  เขาดินพัฒนา: 191301,
  บ้านแก้ง: 191302,
  ผึ้งรวง: 191303,
  พุแค: 191304,
  ห้วยบง: 191305,
  หน้าพระลาน: 191306,
  บางปลาสร้อย: 200101,
  มะขามหย่ง: 200102,
  บ้านโขด: 200103,
  แสนสุข: 200104,
  บ้านสวน: 200105,
  หนองรี: 200106,
  นาป่า: 200107,
  หนองข้างคอก: 200108,
  ดอนหัวฬ่อ: 200109,
  หนองไม้แดง: 200110,
  บางทราย: 200111,
  คลองตำหรุ: 200112,
  เหมือง: 200113,
  บ้านปึก: 200114,
  ห้วยกะปิ: 200115,
  เสม็ด: 200116,
  อ่างศิลา: 200117,
  สำนักบก: 200118,
  บ้านบึง: 200201,
  คลองกิ่ว: 200202,
  มาบไผ่: 200203,
  หนองซ้ำซาก: 200204,
  หนองบอนแดง: 200205,
  หนองชาก: 200206,
  หนองอิรุณ: 200207,
  หนองไผ่แก้ว: 200208,
  หนองใหญ่: 200301,
  คลองพลู: 200302,
  หนองเสือช้าง: 200303,
  ห้างสูง: 200304,
  เขาซก: 200305,
  บางละมุง: 200401,
  หนองปรือ: 200402,
  หนองปลาไหล: 200403,
  โป่ง: 200404,
  เขาไม้แก้ว: 200405,
  ห้วยใหญ่: 200406,
  ตะเคียนเตี้ย: 200407,
  นาเกลือ: 200408,
  พานทอง: 200501,
  หนองตำลึง: 200502,
  มาบโป่ง: 200503,
  หนองกะขะ: 200504,
  หนองหงษ์: 200505,
  โคกขี้หนอน: 200506,
  บ้านเก่า: 200507,
  หน้าประดู่: 200508,
  บางนาง: 200509,
  เกาะลอย: 200510,
  บางหัก: 200511,
  พนัสนิคม: 200601,
  หน้าพระธาตุ: 200602,
  วัดหลวง: 200603,
  บ้านเซิด: 200604,
  นาเริก: 200605,
  หมอนนาง: 200606,
  สระสี่เหลี่ยม: 200607,
  วัดโบสถ์: 200608,
  กุฎโง้ง: 200609,
  หัวถนน: 200610,
  ท่าข้าม: 200611,
  หนองปรือ: 200613,
  หนองขยาด: 200614,
  ทุ่งขวาง: 200615,
  หนองเหียง: 200616,
  นาวังหิน: 200617,
  บ้านช้าง: 200618,
  โคกเพลาะ: 200620,
  ไร่หลักทอง: 200621,
  นามะตูม: 200622,
  ศรีราชา: 200701,
  สุรศักดิ์: 200702,
  ทุ่งสุขลา: 200703,
  บึง: 200704,
  หนองขาม: 200705,
  เขาคันทรง: 200706,
  บางพระ: 200707,
  บ่อวิน: 200708,
  ท่าเทววงษ์: 200801,
  สัตหีบ: 200901,
  นาจอมเทียน: 200902,
  พลูตาหลวง: 200903,
  บางเสร่: 200904,
  แสมสาร: 200905,
  บ่อทอง: 201001,
  วัดสุวรรณ: 201002,
  บ่อกวางทอง: 201003,
  ธาตุทอง: 201004,
  เกษตรสุวรรณ: 201005,
  พลวงทอง: 201006,
  เกาะจันทร์: 201101,
  ท่าบุญมี: 201102,
  ท่าประดู่: 210101,
  เชิงเนิน: 210102,
  ตะพง: 210103,
  ปากน้ำ: 210104,
  เพ: 210105,
  แกลง: 210106,
  บ้านแลง: 210107,
  นาตาขวัญ: 210108,
  เนินพระ: 210109,
  กะเฉด: 210110,
  ทับมา: 210111,
  น้ำคอก: 210112,
  ห้วยโป่ง: 210113,
  มาบตาพุด: 210114,
  สำนักทอง: 210115,
  สำนักท้อน: 210201,
  พลา: 210202,
  บ้านฉาง: 210203,
  ทางเกวียน: 210301,
  วังหว้า: 210302,
  ชากโดน: 210303,
  เนินฆ้อ: 210304,
  กร่ำ: 210305,
  ชากพง: 210306,
  กระแสบน: 210307,
  บ้านนา: 210308,
  ทุ่งควายกิน: 210309,
  กองดิน: 210310,
  คลองปูน: 210311,
  พังราด: 210312,
  ปากน้ำกระแส: 210313,
  ห้วยยาง: 210317,
  สองสลึง: 210318,
  วังจันทร์: 210401,
  ชุมแสง: 210402,
  ป่ายุบใน: 210403,
  พลงตาเอี่ยม: 210404,
  บ้านค่าย: 210501,
  หนองละลอก: 210502,
  หนองตะพาน: 210503,
  ตาขัน: 210504,
  บางบุตร: 210505,
  หนองบัว: 210506,
  ชากบก: 210507,
  ปลวกแดง: 210601,
  ตาสิทธิ์: 210602,
  ละหาร: 210603,
  แม่น้ำคู้: 210604,
  มาบยางพร: 210605,
  หนองไร่: 210606,
  น้ำเป็น: 210701,
  ห้วยทับมอญ: 210702,
  ชำฆ้อ: 210703,
  เขาน้อย: 210704,
  นิคมพัฒนา: 210801,
  มาบข่า: 210802,
  พนานิคม: 210803,
  มะขามคู่: 210804,
  ตลาด: 220101,
  วัดใหม่: 220102,
  คลองนารายณ์: 220103,
  เกาะขวาง: 220104,
  คมบาง: 220105,
  ท่าช้าง: 220106,
  จันทนิมิต: 220107,
  บางกะจะ: 220108,
  แสลง: 220109,
  หนองบัว: 220110,
  พลับพลา: 220111,
  ขลุง: 220201,
  บ่อ: 220202,
  เกวียนหัก: 220203,
  ตะปอน: 220204,
  บางชัน: 220205,
  วันยาว: 220206,
  ซึ้ง: 220207,
  มาบไพ: 220208,
  วังสรรพรส: 220209,
  ตรอกนอง: 220210,
  ตกพรม: 220211,
  บ่อเวฬุ: 220212,
  ท่าใหม่: 220301,
  ยายร้า: 220302,
  สีพยา: 220303,
  บ่อพุ: 220304,
  พลอยแหวน: 220305,
  เขาวัว: 220306,
  เขาบายศรี: 220307,
  สองพี่น้อง: 220308,
  ทุ่งเบญจา: 220309,
  รำพัน: 220311,
  โขมง: 220312,
  ตะกาดเง้า: 220313,
  คลองขุด: 220314,
  เขาแก้ว: 220324,
  ทับไทร: 220401,
  โป่งน้ำร้อน: 220402,
  หนองตาคง: 220404,
  เทพนิมิต: 220409,
  คลองใหญ่: 220410,
  มะขาม: 220501,
  ท่าหลวง: 220502,
  ปัถวี: 220503,
  วังแซ้ม: 220504,
  ฉมัน: 220506,
  อ่างคีรี: 220508,
  ปากน้ำแหลมสิงห์: 220601,
  เกาะเปริด: 220602,
  หนองชิ่ม: 220603,
  พลิ้ว: 220604,
  คลองน้ำเค็ม: 220605,
  บางสระเก้า: 220606,
  บางกะไชย: 220607,
  ปะตง: 220701,
  ทุ่งขนาน: 220702,
  ทับช้าง: 220703,
  ทรายขาว: 220704,
  สะตอน: 220705,
  แก่งหางแมว: 220801,
  ขุนซ่อง: 220802,
  สามพี่น้อง: 220803,
  พวา: 220804,
  เขาวงกต: 220805,
  นายายอาม: 220901,
  วังโตนด: 220902,
  กระแจะ: 220903,
  สนามไชย: 220904,
  ช้างข้าม: 220905,
  วังใหม่: 220906,
  ชากไทย: 221001,
  พลวง: 221002,
  ตะเคียนทอง: 221003,
  คลองพลู: 221004,
  จันทเขลม: 221005,
  บางพระ: 230101,
  หนองเสม็ด: 230102,
  หนองโสน: 230103,
  หนองคันทรง: 230104,
  ห้วงน้ำขาว: 230105,
  อ่าวใหญ่: 230106,
  วังกระแจะ: 230107,
  ห้วยแร้ง: 230108,
  เนินทราย: 230109,
  ท่าพริก: 230110,
  ท่ากุ่ม: 230111,
  ตะกาง: 230112,
  ชำราก: 230113,
  แหลมกลัด: 230114,
  คลองใหญ่: 230201,
  ไม้รูด: 230202,
  หาดเล็ก: 230203,
  เขาสมิง: 230301,
  แสนตุ้ง: 230302,
  วังตะเคียน: 230303,
  ท่าโสม: 230304,
  สะตอ: 230305,
  ประณีต: 230306,
  เทพนิมิต: 230307,
  ทุ่งนนทรี: 230308,
  บ่อพลอย: 230401,
  ช้างทูน: 230402,
  ด่านชุมพล: 230403,
  หนองบอน: 230404,
  นนทรีย์: 230405,
  แหลมงอบ: 230501,
  น้ำเชี่ยว: 230502,
  บางปิด: 230503,
  คลองใหญ่: 230507,
  เกาะหมาก: 230615,
  เกาะกูด: 230616,
  เกาะช้าง: 230701,
  เกาะช้างใต้: 230702,
  หน้าเมือง: 240101,
  ท่าไข่: 240102,
  บ้านใหม่: 240103,
  คลองนา: 240104,
  บางตีนเป็ด: 240105,
  บางไผ่: 240106,
  คลองจุกกระเฌอ: 240107,
  บางแก้ว: 240108,
  บางขวัญ: 240109,
  คลองนครเนื่องเขต: 240110,
  วังตะเคียน: 240111,
  โสธร: 240112,
  บางพระ: 240113,
  บางกะไห: 240114,
  หนามแดง: 240115,
  คลองเปรง: 240116,
  คลองอุดมชลจร: 240117,
  คลองหลวงแพ่ง: 240118,
  บางเตย: 240119,
  บางคล้า: 240201,
  บางสวน: 240204,
  บางกระเจ็ด: 240208,
  ปากน้ำ: 240209,
  ท่าทองหลาง: 240210,
  สาวชะโงก: 240211,
  เสม็ดเหนือ: 240212,
  เสม็ดใต้: 240213,
  หัวไทร: 240214,
  บางน้ำเปรี้ยว: 240301,
  บางขนาก: 240302,
  สิงโตทอง: 240303,
  หมอนทอง: 240304,
  บึงน้ำรักษ์: 240305,
  ดอนเกาะกา: 240306,
  โยธะกา: 240307,
  ดอนฉิมพลี: 240308,
  ศาลาแดง: 240309,
  โพรงอากาศ: 240310,
  บางปะกง: 240401,
  ท่าสะอ้าน: 240402,
  บางวัว: 240403,
  บางสมัคร: 240404,
  บางผึ้ง: 240405,
  บางเกลือ: 240406,
  สองคลอง: 240407,
  หนองจอก: 240408,
  พิมพา: 240409,
  ท่าข้าม: 240410,
  หอมศีล: 240411,
  เขาดิน: 240412,
  บ้านโพธิ์: 240501,
  เกาะไร่: 240502,
  คลองขุด: 240503,
  คลองบ้านโพธิ์: 240504,
  คลองประเวศ: 240505,
  ดอนทราย: 240506,
  เทพราช: 240507,
  ท่าพลับ: 240508,
  หนองตีนนก: 240509,
  หนองบัว: 240510,
  บางซ่อน: 240511,
  บางกรูด: 240512,
  แหลมประดู่: 240513,
  ลาดขวาง: 240514,
  สนามจันทร์: 240515,
  แสนภูดาษ: 240516,
  สิบเอ็ดศอก: 240517,
  เกาะขนุน: 240601,
  บ้านซ่อง: 240602,
  พนมสารคาม: 240603,
  เมืองเก่า: 240604,
  หนองยาว: 240605,
  ท่าถ่าน: 240606,
  หนองแหน: 240607,
  เขาหินซ้อน: 240608,
  บางคา: 240701,
  เมืองใหม่: 240702,
  ดงน้อย: 240703,
  คู้ยายหมี: 240801,
  ท่ากระดาน: 240802,
  ทุ่งพระยา: 240803,
  ลาดกระทิง: 240805,
  แปลงยาว: 240901,
  วังเย็น: 240902,
  หัวสำโรง: 240903,
  หนองไม้แก่น: 240904,
  ท่าตะเกียบ: 241001,
  คลองตะเกรา: 241002,
  ก้อนแก้ว: 241101,
  คลองเขื่อน: 241102,
  บางเล่า: 241103,
  บางโรง: 241104,
  บางตลาด: 241105,
  หน้าเมือง: 250101,
  รอบเมือง: 250102,
  วัดโบสถ์: 250103,
  บางเดชะ: 250104,
  ท่างาม: 250105,
  บางบริบูรณ์: 250106,
  ดงพระราม: 250107,
  บ้านพระ: 250108,
  โคกไม้ลาย: 250109,
  ไม้เค็ด: 250110,
  ดงขี้เหล็ก: 250111,
  เนินหอม: 250112,
  โนนห้อม: 250113,
  กบินทร์: 250201,
  เมืองเก่า: 250202,
  วังดาล: 250203,
  นนทรี: 250204,
  ย่านรี: 250205,
  วังตะเคียน: 250206,
  หาดนางแก้ว: 250207,
  ลาดตะเคียน: 250208,
  บ้านนา: 250209,
  บ่อทอง: 250210,
  หนองกี่: 250211,
  นาแขม: 250212,
  เขาไม้แก้ว: 250213,
  วังท่าช้าง: 250214,
  นาดี: 250301,
  สำพันตา: 250302,
  สะพานหิน: 250303,
  ทุ่งโพธิ์: 250304,
  แก่งดินสอ: 250305,
  บุพราหมณ์: 250306,
  บ้านสร้าง: 250601,
  บางกระเบา: 250602,
  บางเตย: 250603,
  บางยาง: 250604,
  บางแตน: 250605,
  บางพลวง: 250606,
  บางปลาร้า: 250607,
  บางขาม: 250608,
  กระทุ่มแพ้ว: 250609,
  ประจันตคาม: 250701,
  เกาะลอย: 250702,
  บ้านหอย: 250703,
  หนองแสง: 250704,
  ดงบัง: 250705,
  คำโตนด: 250706,
  บุฝ้าย: 250707,
  หนองแก้ว: 250708,
  โพธิ์งาม: 250709,
  ศรีมหาโพธิ: 250801,
  สัมพันธ์: 250802,
  บ้านทาม: 250803,
  ท่าตูม: 250804,
  บางกุ้ง: 250805,
  ดงกระทงยาม: 250806,
  หนองโพรง: 250807,
  หัวหว้า: 250808,
  หาดยาง: 250809,
  กรอกสมบูรณ์: 250810,
  โคกปีบ: 250901,
  โคกไทย: 250902,
  คู้ลำพัน: 250903,
  ไผ่ชะเลือด: 250904,
  นครนายก: 260101,
  ท่าช้าง: 260102,
  บ้านใหญ่: 260103,
  วังกระโจม: 260104,
  ท่าทราย: 260105,
  ดอนยอ: 260106,
  ศรีจุฬา: 260107,
  ดงละคร: 260108,
  ศรีนาวา: 260109,
  สาริกา: 260110,
  หินตั้ง: 260111,
  เขาพระ: 260112,
  พรหมณี: 260113,
  เกาะหวาย: 260201,
  เกาะโพธิ์: 260202,
  ปากพลี: 260203,
  โคกกรวด: 260204,
  ท่าเรือ: 260205,
  หนองแสง: 260206,
  นาหินลาด: 260207,
  บ้านนา: 260301,
  บ้านพร้าว: 260302,
  บ้านพริก: 260303,
  อาษา: 260304,
  ทองหลาง: 260305,
  บางอ้อ: 260306,
  พิกุลออก: 260307,
  ป่าขะ: 260308,
  เขาเพิ่ม: 260309,
  ศรีกะอาง: 260310,
  พระอาจารย์: 260401,
  บึงศาล: 260402,
  ศีรษะกระบือ: 260403,
  โพธิ์แทน: 260404,
  บางสมบูรณ์: 260405,
  ทรายมูล: 260406,
  พระบรมมหาราชวัง: 100101,
  วังบูรพาภิรมย์: 100102,
  วัดราชบพิธ: 100103,
  สำราญราษฎร์: 100104,
  ศาลเจ้าพ่อเสือ: 100105,
  เสาชิงช้า: 100106,
  บวรนิเวศ: 100107,
  ตลาดยอด: 100108,
  ชนะสงคราม: 100109,
  บ้านพานถม: 100110,
  บางขุนพรหม: 100111,
  วัดสามพระยา: 100112,
  ดุสิต: 100201,
  วชิรพยาบาล: 100202,
  สวนจิตรลดา: 100203,
  สี่แยกมหานาค: 100204,
  ถนนนครไชยศรี: 100206,
  กระทุ่มราย: 100301,
  หนองจอก: 100302,
  คลองสิบ: 100303,
  คลองสิบสอง: 100304,
  โคกแฝด: 100305,
  คู้ฝั่งเหนือ: 100306,
  ลำผักชี: 100307,
  ลำต้อยติ่ง: 100308,
  มหาพฤฒาราม: 100401,
  สีลม: 100402,
  สุริยวงศ์: 100403,
  บางรัก: 100404,
  สี่พระยา: 100405,
  อนุสาวรีย์: 100502,
  ท่าแร้ง: 100508,
  คลองจั่น: 100601,
  หัวหมาก: 100608,
  รองเมือง: 100701,
  วังใหม่: 100702,
  ปทุมวัน: 100703,
  ลุมพินี: 100704,
  ป้อมปราบ: 100801,
  วัดเทพศิรินทร์: 100802,
  คลองมหานาค: 100803,
  บ้านบาตร: 100804,
  วัดโสมนัส: 100805,
  บางจาก: 100905,
  มีนบุรี: 101001,
  แสนแสบ: 101002,
  ลาดกระบัง: 101101,
  คลองสองต้นนุ่น: 101102,
  ลำปลาทิว: 101104,
  ทับยาว: 101105,
  ขุมทอง: 101106,
  ช่องนนทรี: 101203,
  บางโพงพาง: 101204,
  จักรวรรดิ: 101301,
  สัมพันธวงศ์: 101302,
  ตลาดน้อย: 101303,
  สามเสนใน: 101401,
  วัดกัลยาณ์: 101501,
  หิรัญรูจี: 101502,
  บางยี่เรือ: 101503,
  บุคคโล: 101504,
  ตลาดพลู: 101505,
  ดาวคะนอง: 101506,
  สำเหร่: 101507,
  วัดอรุณ: 101601,
  วัดท่าพระ: 101602,
  ห้วยขวาง: 101701,
  บางกะปิ: 101702,
  สามเสนนอก: 101704,
  สมเด็จเจ้าพระยา: 101801,
  คลองสาน: 101802,
  บางลำภูล่าง: 101803,
  คลองต้นไทร: 101804,
  คลองชักพระ: 101901,
  ตลิ่งชัน: 101902,
  ฉิมพลี: 101903,
  บางพรม: 101904,
  บางระมาด: 101905,
  ทวีวัฒนา: 101906,
  บางเชือกหนัง: 101907,
  ศาลาธรรมสพน์: 101908,
  ศิริราช: 102004,
  บ้านช่างหล่อ: 102005,
  บางขุนนนท์: 102006,
  บางขุนศรี: 102007,
  อรุณอมรินทร์: 102009,
  บางขุนเทียน: 102101,
  บางค้อ: 102102,
  บางมด: 102103,
  บางบอน: 102104,
  ท่าข้าม: 102105,
  แสมดำ: 102107,
  บางหว้า: 102201,
  บางด้วน: 102202,
  บางแค: 102203,
  บางแคเหนือ: 102204,
  บางไผ่: 102205,
  บางจาก: 102206,
  บางแวก: 102207,
  คลองขวาง: 102208,
  ปากคลองภาษีเจริญ: 102209,
  คูหาสวรรค์: 102210,
  หนองแขม: 102302,
  หนองค้างพลู: 102303,
  ราษฎร์บูรณะ: 102401,
  บางปะกอก: 102402,
  บางมด: 102403,
  ทุ่งครุ: 102404,
  บางพลัด: 102501,
  บางอ้อ: 102502,
  บางบำหรุ: 102503,
  บางยี่ขัน: 102504,
  ดินแดง: 102601,
  คลองกุ่ม: 102701,
  สะพานสูง: 102702,
  คันนายาว: 102703,
  ทุ่งวัดดอน: 102801,
  ยานนาวา: 102802,
  ทุ่งมหาเมฆ: 102803,
  บางซื่อ: 102901,
  ลาดยาว: 103001,
  เสนานิคม: 103002,
  จันทรเกษม: 103003,
  จอมพล: 103004,
  จตุจักร: 103005,
  บางคอแหลม: 103101,
  วัดพระยาไกร: 103102,
  บางโคล่: 103103,
  ประเวศ: 103201,
  หนองบอน: 103202,
  ดอกไม้: 103203,
  สวนหลวง: 103204,
  คลองเตย: 103301,
  คลองตัน: 103302,
  พระโขนง: 103303,
  คลองเตยเหนือ: 103304,
  คลองตันเหนือ: 103305,
  พระโขนงเหนือ: 103306,
  สวนหลวง: 103401,
  บางขุนเทียน: 103501,
  บางค้อ: 103502,
  บางมด: 103503,
  จอมทอง: 103504,
  สีกัน: 103602,
  ทุ่งพญาไท: 103701,
  ถนนพญาไท: 103702,
  ถนนเพชรบุรี: 103703,
  มักกะสัน: 103704,
  ลาดพร้าว: 103801,
  จรเข้บัว: 103802,
  คลองเตยเหนือ: 103901,
  คลองตันเหนือ: 103902,
  พระโขนงเหนือ: 103903,
  บางแค: 104001,
  บางแคเหนือ: 104002,
  บางไผ่: 104003,
  หลักสอง: 104004,
  ทุ่งสองห้อง: 104101,
  ตลาดบางเขน: 104102,
  สายไหม: 104201,
  ออเงิน: 104202,
  คลองถนน: 104203,
  คันนายาว: 104301,
  สะพานสูง: 104401,
  วังทองหลาง: 104501,
  สามวาตะวันตก: 104601,
  สามวาตะวันออก: 104602,
  บางชัน: 104603,
  ทรายกองดิน: 104604,
  ทรายกองดินใต้: 104605,
  บางนา: 104701,
  ทวีวัฒนา: 104801,
  ศาลาธรรมสพน์: 104802,
  บางมด: 104901,
  ทุ่งครุ: 104902,
  บางบอน: 104903,
  บางบอนเหนือ: 105001,
  ปากน้ำ: 110101,
  สำโรงเหนือ: 110102,
  บางเมือง: 110103,
  ท้ายบ้าน: 110104,
  บางปูใหม่: 110108,
  แพรกษา: 110110,
  บางโปรง: 110111,
  บางปู: 110112,
  บางด้วน: 110113,
  บางเมืองใหม่: 110114,
  เทพารักษ์: 110115,
  ท้ายบ้านใหม่: 110116,
  แพรกษาใหม่: 110117,
  บางบ่อ: 110201,
  บ้านระกาศ: 110202,
  บางพลีน้อย: 110203,
  บางเพรียง: 110204,
  คลองด่าน: 110205,
  คลองสวน: 110206,
  เปร็ง: 110207,
  คลองนิยมยาตรา: 110208,
  บางพลีใหญ่: 110301,
  บางแก้ว: 110302,
  บางปลา: 110303,
  บางโฉลง: 110304,
  ราชาเทวะ: 110308,
  หนองปรือ: 110309,
  ตลาด: 110401,
  บางพึ่ง: 110402,
  บางจาก: 110403,
  บางครุ: 110404,
  บางหญ้าแพรก: 110405,
  บางหัวเสือ: 110406,
  สำโรงใต้: 110407,
  บางยอ: 110408,
  บางกะเจ้า: 110409,
  บางน้ำผึ้ง: 110410,
  บางกระสอบ: 110411,
  บางกอบัว: 110412,
  ทรงคนอง: 110413,
  สำโรง: 110414,
  สำโรงกลาง: 110415,
  นาเกลือ: 110501,
  บ้านคลองสวน: 110502,
  แหลมฟ้าผ่า: 110503,
  ปากคลองบางปลากด: 110504,
  ในคลองบางปลากด: 110505,
  บางเสาธง: 110601,
  ศีรษะจรเข้น้อย: 110602,
  ศีรษะจรเข้ใหญ่: 110603,
  สวนใหญ่: 120101,
  ตลาดขวัญ: 120102,
  บางเขน: 120103,
  บางกระสอ: 120104,
  ท่าทราย: 120105,
  บางไผ่: 120106,
  บางศรีเมือง: 120107,
  บางกร่าง: 120108,
  ไทรม้า: 120109,
  บางรักน้อย: 120110,
  วัดชลอ: 120201,
  บางกรวย: 120202,
  บางสีทอง: 120203,
  บางขนุน: 120204,
  บางขุนกอง: 120205,
  บางคูเวียง: 120206,
  มหาสวัสดิ์: 120207,
  ปลายบาง: 120208,
  ศาลากลาง: 120209,
  บางม่วง: 120301,
  บางแม่นาง: 120302,
  บางเลน: 120303,
  เสาธงหิน: 120304,
  บางใหญ่: 120305,
  บ้านใหม่: 120306,
  โสนลอย: 120401,
  บางบัวทอง: 120402,
  บางรักใหญ่: 120403,
  บางคูรัด: 120404,
  ละหาร: 120405,
  ลำโพ: 120406,
  พิมลราช: 120407,
  บางรักพัฒนา: 120408,
  ไทรน้อย: 120501,
  ราษฎร์นิยม: 120502,
  หนองเพรางาย: 120503,
  ไทรใหญ่: 120504,
  ขุนศรี: 120505,
  คลองขวาง: 120506,
  ทวีวัฒนา: 120507,
  ปากเกร็ด: 120601,
  บางตลาด: 120602,
  บ้านใหม่: 120603,
  บางพูด: 120604,
  บางตะไนย์: 120605,
  คลองพระอุดม: 120606,
  ท่าอิฐ: 120607,
  เกาะเกร็ด: 120608,
  อ้อมเกร็ด: 120609,
  คลองข่อย: 120610,
  บางพลับ: 120611,
  คลองเกลือ: 120612,
  บางปรอก: 130101,
  บ้านใหม่: 130102,
  บ้านกลาง: 130103,
  บ้านฉาง: 130104,
  บ้านกระแชง: 130105,
  บางขะแยง: 130106,
  บางคูวัด: 130107,
  บางหลวง: 130108,
  บางเดื่อ: 130109,
  บางพูด: 130110,
  บางพูน: 130111,
  บางกะดี: 130112,
  สวนพริกไทย: 130113,
  หลักหก: 130114,
  คลองหนึ่ง: 130201,
  คลองสอง: 130202,
  คลองสี่: 130204,
  คลองห้า: 130205,
  คลองหก: 130206,
  คลองเจ็ด: 130207,
  ประชาธิปัตย์: 130301,
  บึงยี่โถ: 130302,
  รังสิต: 130303,
  ลำผักกูด: 130304,
  บึงสนั่น: 130305,
  บึงน้ำรักษ์: 130306,
  บึงบา: 130401,
  บึงบอน: 130402,
  บึงกาสาม: 130403,
  บึงชำอ้อ: 130404,
  หนองสามวัง: 130405,
  ศาลาครุ: 130406,
  นพรัตน์: 130407,
  ระแหง: 130501,
  ลาดหลุมแก้ว: 130502,
  คูบางหลวง: 130503,
  คูขวาง: 130504,
  คลองพระอุดม: 130505,
  บ่อเงิน: 130506,
  หน้าไม้: 130507,
  คูคต: 130601,
  ลาดสวาย: 130602,
  บึงคำพร้อย: 130603,
  ลำลูกกา: 130604,
  บึงทองหลาง: 130605,
  ลำไทร: 130606,
  บึงคอไห: 130607,
  พืชอุดม: 130608,
  บางเตย: 130701,
  คลองควาย: 130702,
  สามโคก: 130703,
  กระแชง: 130704,
  บางโพธิ์เหนือ: 130705,
  เชียงรากใหญ่: 130706,
  บ้านปทุม: 130707,
  บ้านงิ้ว: 130708,
  เชียงรากน้อย: 130709,
  บางกระบือ: 130710,
  ท้ายเกาะ: 130711,
  ประตูชัย: 140101,
  กะมัง: 140102,
  หอรัตนไชย: 140103,
  หัวรอ: 140104,
  ท่าวาสุกรี: 140105,
  ไผ่ลิง: 140106,
  ปากกราน: 140107,
  ภูเขาทอง: 140108,
  สำเภาล่ม: 140109,
  สวนพริก: 140110,
  คลองตะเคียน: 140111,
  วัดตูม: 140112,
  หันตรา: 140113,
  ลุมพลี: 140114,
  บ้านใหม่: 140115,
  บ้านเกาะ: 140116,
  คลองสวนพลู: 140117,
  คลองสระบัว: 140118,
  เกาะเรียน: 140119,
  บ้านป้อม: 140120,
  บ้านรุน: 140121,
  ท่าเรือ: 140201,
  จำปา: 140202,
  ท่าหลวง: 140203,
  บ้านร่อม: 140204,
  ศาลาลอย: 140205,
  วังแดง: 140206,
  โพธิ์เอน: 140207,
  ปากท่า: 140208,
  หนองขนาก: 140209,
  ท่าเจ้าสนุก: 140210,
  นครหลวง: 140301,
  ท่าช้าง: 140302,
  บ่อโพง: 140303,
  บ้านชุ้ง: 140304,
  ปากจั่น: 140305,
  บางระกำ: 140306,
  บางพระครู: 140307,
  แม่ลา: 140308,
  หนองปลิง: 140309,
  คลองสะแก: 140310,
  สามไถ: 140311,
  พระนอน: 140312,
  บางไทร: 140401,
  บางพลี: 140402,
  สนามชัย: 140403,
  บ้านแป้ง: 140404,
  หน้าไม้: 140405,
  บางยี่โท: 140406,
  แคออก: 140407,
  แคตก: 140408,
  ช่างเหล็ก: 140409,
  กระแชง: 140410,
  บ้านกลึง: 140411,
  ช้างน้อย: 140412,
  ห่อหมก: 140413,
  ไผ่พระ: 140414,
  กกแก้วบูรพา: 140415,
  ไม้ตรา: 140416,
  บ้านม้า: 140417,
  บ้านเกาะ: 140418,
  ราชคราม: 140419,
  ช้างใหญ่: 140420,
  โพแตง: 140421,
  เชียงรากน้อย: 140422,
  โคกช้าง: 140423,
  บางบาล: 140501,
  วัดยม: 140502,
  ไทรน้อย: 140503,
  สะพานไทย: 140504,
  มหาพราหมณ์: 140505,
  กบเจา: 140506,
  บ้านคลัง: 140507,
  พระขาว: 140508,
  น้ำเต้า: 140509,
  ทางช้าง: 140510,
  วัดตะกู: 140511,
  บางหลวง: 140512,
  บางหลวงโดด: 140513,
  บางหัก: 140514,
  บางชะนี: 140515,
  บ้านกุ่ม: 140516,
  บ้านเลน: 140601,
  เชียงรากน้อย: 140602,
  บ้านโพ: 140603,
  บ้านกรด: 140604,
  บางกระสั้น: 140605,
  คลองจิก: 140606,
  บ้านหว้า: 140607,
  วัดยม: 140608,
  บางประแดง: 140609,
  สามเรือน: 140610,
  เกาะเกิด: 140611,
  บ้านพลับ: 140612,
  บ้านแป้ง: 140613,
  คุ้งลาน: 140614,
  ตลิ่งชัน: 140615,
  บ้านสร้าง: 140616,
  ตลาดเกรียบ: 140617,
  ขนอนหลวง: 140618,
  บางปะหัน: 140701,
  ขยาย: 140702,
  บางเดื่อ: 140703,
  เสาธง: 140704,
  ทางกลาง: 140705,
  บางเพลิง: 140706,
  หันสัง: 140707,
  บางนางร้า: 140708,
  ตานิม: 140709,
  ทับน้ำ: 140710,
  บ้านม้า: 140711,
  ขวัญเมือง: 140712,
  บ้านลี่: 140713,
  โพธิ์สามต้น: 140714,
  พุทเลา: 140715,
  ตาลเอน: 140716,
  บ้านขล้อ: 140717,
  ผักไห่: 140801,
  อมฤต: 140802,
  บ้านแค: 140803,
  ลาดน้ำเค็ม: 140804,
  ตาลาน: 140805,
  ท่าดินแดง: 140806,
  ดอนลาน: 140807,
  นาคู: 140808,
  กุฎี: 140809,
  ลำตะเคียน: 140810,
  โคกช้าง: 140811,
  จักราช: 140812,
  หนองน้ำใหญ่: 140813,
  ลาดชิด: 140814,
  หน้าโคก: 140815,
  ภาชี: 140901,
  โคกม่วง: 140902,
  ระโสม: 140903,
  หนองน้ำใส: 140904,
  ดอนหญ้านาง: 140905,
  ไผ่ล้อม: 140906,
  กระจิว: 140907,
  พระแก้ว: 140908,
  ลาดบัวหลวง: 141001,
  หลักชัย: 141002,
  สามเมือง: 141003,
  พระยาบันลือ: 141004,
  สิงหนาท: 141005,
  คู้สลอด: 141006,
  คลองพระยาบันลือ: 141007,
  ลำตาเสา: 141101,
  บ่อตาโล่: 141102,
  วังน้อย: 141103,
  ลำไทร: 141104,
  สนับทึบ: 141105,
  พยอม: 141106,
  หันตะเภา: 141107,
  วังจุฬา: 141108,
  ข้าวงาม: 141109,
  ชะแมบ: 141110,
  เสนา: 141201,
  บ้านแพน: 141202,
  เจ้าเจ็ด: 141203,
  สามกอ: 141204,
  บางนมโค: 141205,
  หัวเวียง: 141206,
  มารวิชัย: 141207,
  บ้านโพธิ์: 141208,
  รางจรเข้: 141209,
  บ้านกระทุ่ม: 141210,
  บ้านแถว: 141211,
  ชายนา: 141212,
  สามตุ่ม: 141213,
  ลาดงา: 141214,
  ดอนทอง: 141215,
  บ้านหลวง: 141216,
  เจ้าเสด็จ: 141217,
  บางซ้าย: 141301,
  แก้วฟ้า: 141302,
  เต่าเล่า: 141303,
  ปลายกลัด: 141304,
  เทพมงคล: 141305,
  วังพัฒนา: 141306,
  คานหาม: 141401,
  บ้านช้าง: 141402,
  สามบัณฑิต: 141403,
  บ้านหีบ: 141404,
  หนองไม้ซุง: 141405,
  อุทัย: 141406,
  เสนา: 141407,
  หนองน้ำส้ม: 141408,
  โพสาวหาญ: 141409,
  ธนู: 141410,
  ข้าวเม่า: 141411,
  หัวไผ่: 141501,
  กะทุ่ม: 141502,
  มหาราช: 141503,
  น้ำเต้า: 141504,
  บางนา: 141505,
  โรงช้าง: 141506,
  เจ้าปลุก: 141507,
  พิตเพียน: 141508,
  บ้านนา: 141509,
  บ้านขวาง: 141510,
  ท่าตอ: 141511,
  บ้านใหม่: 141512,
  บ้านแพรก: 141601,
  บ้านใหม่: 141602,
  สำพะเนียง: 141603,
  คลองน้อย: 141604,
  สองห้อง: 141605,
  ตลาดหลวง: 150101,
  บางแก้ว: 150102,
  ศาลาแดง: 150103,
  ป่างิ้ว: 150104,
  บ้านแห: 150105,
  ตลาดกรวด: 150106,
  มหาดไทย: 150107,
  บ้านอิฐ: 150108,
  หัวไผ่: 150109,
  จำปาหล่อ: 150110,
  โพสะ: 150111,
  บ้านรี: 150112,
  คลองวัว: 150113,
  โคราช: 301803,
  บุ่งขี้เหล็ก: 301804,
  โนนค่า: 301805,
  โค้งยาง: 301806,
  มะเกลือเก่า: 301807,
  มะเกลือใหม่: 301808,
  นากลาง: 301809,
  หนองตะไก้: 301810,
  กุดจิก: 301811,
  ขามทะเลสอ: 301901,
  โป่งแดง: 301902,
  พันดุง: 301903,
  หนองสรวง: 301904,
  บึงอ้อ: 301905,
  สีคิ้ว: 302001,
  บ้านหัน: 302002,
  กฤษณา: 302003,
  ลาดบัวขาว: 302004,
  หนองหญ้าขาว: 302005,
  กุดน้อย: 302006,
  หนองน้ำใส: 302007,
  วังโรงใหญ่: 302008,
  มิตรภาพ: 302009,
  คลองไผ่: 302010,
  ดอนเมือง: 302011,
  หนองบัวน้อย: 302012,
  ปากช่อง: 302101,
  กลางดง: 302102,
  จันทึก: 302103,
  วังกะทะ: 302104,
  หมูสี: 302105,
  หนองสาหร่าย: 302106,
  ขนงพระ: 302107,
  โป่งตาลอง: 302108,
  คลองม่วง: 302109,
  หนองน้ำแดง: 302110,
  วังไทร: 302111,
  พญาเย็น: 302112,
  หนองบุนนาก: 302201,
  สารภี: 302202,
  ไทยเจริญ: 302203,
  หนองหัวแรต: 302204,
  แหลมทอง: 302205,
  หนองตะไก้: 302206,
  ลุงเขว้า: 302207,
  หนองไม้ไผ่: 302208,
  บ้านใหม่: 302209,
  แก้งสนามนาง: 302301,
  โนนสำราญ: 302302,
  บึงพะไล: 302303,
  สีสุก: 302304,
  บึงสำโรง: 302305,
  โนนแดง: 302401,
  โนนตาเถร: 302402,
  สำพะเนียง: 302403,
  วังหิน: 302404,
  ดอนยาวใหญ่: 302405,
  วังน้ำเขียว: 302501,
  วังหมี: 302502,
  ระเริง: 302503,
  อุดมทรัพย์: 302504,
  ไทยสามัคคี: 302505,
  สำนักตะคร้อ: 302601,
  หนองแวง: 302602,
  บึงปรือ: 302603,
  วังยายทอง: 302604,
  เมืองยาง: 302701,
  กระเบื้องนอก: 302702,
  ละหานปลาค้าว: 302703,
  โนนอุดม: 302704,
  สระพระ: 302801,
  มาบกราด: 302802,
  พังเทียม: 302803,
  ทัพรั้ง: 302804,
  หนองหอย: 302805,
  ขุย: 302901,
  บ้านยาง: 302902,
  ช่องแมว: 302903,
  ไพล: 302904,
  เมืองพะไล: 303001,
  โนนจาน: 303002,
  บัวลาย: 303003,
  หนองหว้า: 303004,
  สีดา: 303101,
  โพนทอง: 303102,
  โนนประดู่: 303103,
  สามเมือง: 303104,
  หนองตาดใหญ่: 303105,
  ช้างทอง: 303201,
  ท่าช้าง: 303202,
  พระพุทธ: 303203,
  หนองงูเหลือม: 303204,
  หนองยาง: 303205,
  ในเมือง: 310101,
  อิสาณ: 310102,
  เสม็ด: 310103,
  บ้านบัว: 310104,
  สะแกโพรง: 310105,
  สวายจีก: 310106,
  บ้านยาง: 310108,
  พระครู: 310112,
  ถลุงเหล็ก: 310113,
  หนองตาด: 310114,
  ลุมปุ๊ก: 310117,
  สองห้อง: 310118,
  บัวทอง: 310119,
  ชุมเห็ด: 310120,
  หลักเขต: 310122,
  สะแกซำ: 310125,
  กลันทา: 310126,
  กระสัง: 310127,
  เมืองฝาง: 310128,
  คูเมือง: 310201,
  ปะเคียบ: 310202,
  บ้านแพ: 310203,
  พรสำราญ: 310204,
  หินเหล็กไฟ: 310205,
  ตูมใหญ่: 310206,
  หนองขมาร: 310207,
  กระสัง: 310301,
  ลำดวน: 310302,
  สองชั้น: 310303,
  สูงเนิน: 310304,
  หนองเต็ง: 310305,
  เมืองไผ่: 310306,
  ชุมแสง: 310307,
  บ้านปรือ: 310308,
  ห้วยสำราญ: 310309,
  กันทรารมย์: 310310,
  ศรีภูมิ: 310311,
  นางรอง: 310401,
  สะเดา: 310403,
  ชุมแสง: 310405,
  หนองโบสถ์: 310406,
  หนองกง: 310408,
  ถนนหัก: 310413,
  หนองไทร: 310414,
  ก้านเหลือง: 310415,
  บ้านสิงห์: 310416,
  ลำไทรโยง: 310417,
  ทรัพย์พระยา: 310418,
  หนองยายพิมพ์: 310424,
  หัวถนน: 310425,
  ทุ่งแสงทอง: 310426,
  หนองโสน: 310427,
  หนองกี่: 310501,
  เย้ยปราสาท: 310502,
  เมืองไผ่: 310503,
  ดอนอะราง: 310504,
  โคกสว่าง: 310505,
  ทุ่งกระตาดพัฒนา: 310506,
  ทุ่งกระเต็น: 310507,
  ท่าโพธิ์ชัย: 310508,
  โคกสูง: 310509,
  บุกระสัง: 310510,
  ละหานทราย: 310601,
  ตาจง: 310603,
  สำโรงใหม่: 310604,
  หนองแวง: 310607,
  หนองตะครอง: 310610,
  โคกว่าน: 310611,
  ประโคนชัย: 310701,
  แสลงโทน: 310702,
  บ้านไทร: 310703,
  ละเวี้ย: 310705,
  จรเข้มาก: 310706,
  ปังกู: 310707,
  โคกย่าง: 310708,
  โคกม้า: 310710,
  ไพศาล: 310713,
  ตะโกตาพิ: 310714,
  เขาคอก: 310715,
  หนองบอน: 310716,
  โคกมะขาม: 310718,
  โคกตูม: 310719,
  ประทัดบุ: 310720,
  สี่เหลี่ยม: 310721,
  บ้านกรวด: 310801,
  โนนเจริญ: 310802,
  หนองไม้งาม: 310803,
  ปราสาท: 310804,
  สายตะกู: 310805,
  หินลาด: 310806,
  บึงเจริญ: 310807,
  จันทบเพชร: 310808,
  เขาดินเหนือ: 310809,
  พุทไธสง: 310901,
  มะเฟือง: 310902,
  บ้านจาน: 310903,
  บ้านเป้า: 310906,
  บ้านแวง: 310907,
  บ้านยาง: 310909,
  หายโศก: 310910,
  ลำปลายมาศ: 311001,
  หนองคู: 311002,
  แสลงพัน: 311003,
  ทะเมนชัย: 311004,
  ตลาดโพธิ์: 311005,
  หนองกะทิง: 311006,
  โคกกลาง: 311007,
  โคกสะอาด: 311008,
  เมืองแฝก: 311009,
  บ้านยาง: 311010,
  ผไทรินทร์: 311011,
  โคกล่าม: 311012,
  หินโคน: 311013,
  หนองบัวโคก: 311014,
  บุโพธิ์: 311015,
  หนองโดน: 311016,
  สตึก: 311101,
  นิคม: 311102,
  ทุ่งวัง: 311103,
  เมืองแก: 311104,
  หนองใหญ่: 311105,
  ร่อนทอง: 311106,
  ดอนมนต์: 311109,
  ชุมแสง: 311110,
  ท่าม่วง: 311111,
  สะแก: 311112,
  สนามชัย: 311114,
  กระสัง: 311115,
  ปะคำ: 311201,
  ไทยเจริญ: 311202,
  หนองบัว: 311203,
  โคกมะม่วง: 311204,
  หูทำนบ: 311205,
  นาโพธิ์: 311301,
  บ้านคู: 311302,
  บ้านดู่: 311303,
  ดอนกอก: 311304,
  ศรีสว่าง: 311305,
  สระแก้ว: 311401,
  ห้วยหิน: 311402,
  ไทยสามัคคี: 311403,
  หนองชัยศรี: 311404,
  เสาเดียว: 311405,
  เมืองฝ้าย: 311406,
  '...': 311407,
  จันดุม: 311501,
  โคกขมิ้น: 311502,
  ป่าชัน: 311503,
  สะเดา: 311504,
  สำโรง: 311505,
  ห้วยราช: 311601,
  สามแวง: 311602,
  ตาเสา: 311603,
  บ้านตะโก: 311604,
  สนวน: 311605,
  โคกเหล็ก: 311606,
  เมืองโพธิ์: 311607,
  ห้วยราชา: 311608,
  โนนสุวรรณ: 311701,
  ทุ่งจังหัน: 311702,
  โกรกแก้ว: 311703,
  ดงอีจาน: 311704,
  ชำนิ: 311801,
  หนองปล่อง: 311802,
  เมืองยาง: 311803,
  ช่อผกา: 311804,
  ละลวด: 311805,
  โคกสนวน: 311806,
  หนองแวง: 311901,
  ทองหลาง: 311902,
  แดงใหญ่: 311903,
  กู่สวนแตง: 311904,
  หนองเยือง: 311905,
  โนนดินแดง: 312001,
  ส้มป่อย: 312002,
  ลำนางรอง: 312003,
  บ้านด่าน: 312101,
  ปราสาท: 312102,
  วังเหนือ: 312103,
  โนนขวาง: 312104,
  แคนดง: 312201,
  ดงพลอง: 312202,
  สระบัว: 312203,
  หัวฝาย: 312204,
  เจริญสุข: 312301,
  ตาเป๊ก: 312302,
  อีสานเขต: 312303,
  ถาวร: 312304,
  ยายแย้มวัฒนา: 312305,
  ในเมือง: 320101,
  ตั้งใจ: 320102,
  เพี้ยราม: 320103,
  นาดี: 320104,
  ท่าสว่าง: 320105,
  สลักได: 320106,
  ตาอ็อง: 320107,
  สำโรง: 320109,
  แกใหญ่: 320110,
  นอกเมือง: 320111,
  คอโค: 320112,
  สวาย: 320113,
  เฉนียง: 320114,
  เทนมีย์: 320116,
  นาบัว: 320118,
  เมืองที: 320119,
  ราม: 320120,
  บุฤาษี: 320121,
  ตระแสง: 320122,
  แสลงพันธ์: 320125,
  กาเกาะ: 320126,
  ชุมพลบุรี: 320201,
  นาหนองไผ่: 320202,
  ไพรขลา: 320203,
  ศรีณรงค์: 320204,
  ยะวึก: 320205,
  เมืองบัว: 320206,
  สระขุด: 320207,
  กระเบื้อง: 320208,
  หนองเรือ: 320209,
  ท่าตูม: 320301,
  กระโพ: 320302,
  พรมเทพ: 320303,
  โพนครก: 320304,
  เมืองแก: 320305,
  บะ: 320306,
  หนองบัว: 320307,
  บัวโคก: 320308,
  หนองเมธี: 320309,
  ทุ่งกุลา: 320310,
  จอมพระ: 320401,
  เมืองลีง: 320402,
  กระหาด: 320403,
  บุแกรง: 320404,
  หนองสนิท: 320405,
  บ้านผือ: 320406,
  ลุ่มระวี: 320407,
  ชุมแสง: 320408,
  เป็นสุข: 320409,
  กังแอน: 320501,
  ทมอ: 320502,
  ไพล: 320503,
  ปรือ: 320504,
  ทุ่งมน: 320505,
  ตาเบา: 320506,
  หนองใหญ่: 320507,
  โคกยาง: 320508,
  โคกสะอาด: 320509,
  บ้านไทร: 320510,
  โชคนาสาม: 320511,
  เชื้อเพลิง: 320512,
  ปราสาททนง: 320513,
  ตานี: 320514,
  บ้านพลวง: 320515,
  กันตวจระมวล: 320516,
  สมุด: 320517,
  ประทัดบุ: 320518,
  กาบเชิง: 320601,
  คูตัน: 320604,
  ด่าน: 320605,
  แนงมุด: 320606,
  โคกตะเคียน: 320607,
  ตะเคียน: 320610,
  รัตนบุรี: 320701,
  ธาตุ: 320702,
  แก: 320703,
  ดอนแรด: 320704,
  หนองบัวทอง: 320705,
  หนองบัวบาน: 320706,
  ไผ่: 320709,
  เบิด: 320711,
  น้ำเขียว: 320713,
  กุดขาคีม: 320714,
  ยางสว่าง: 320715,
  ทับใหญ่: 320716,
  สนม: 320801,
  โพนโก: 320802,
  หนองระฆัง: 320803,
  นานวน: 320804,
  แคน: 320805,
  หัวงัว: 320806,
  หนองอียอ: 320807,
  ระแงง: 320901,
  ตรึม: 320902,
  จารพัต: 320903,
  ยาง: 320904,
  แตล: 320905,
  หนองบัว: 320906,
  คาละแมะ: 320907,
  หนองเหล็ก: 320908,
  หนองขวาว: 320909,
  ช่างปี่: 320910,
  กุดหวาย: 320911,
  ขวาวใหญ่: 320912,
  นารุ่ง: 320913,
  ตรมไพร: 320914,
  ผักไหม: 320915,
  สังขะ: 321001,
  ขอนแตก: 321002,
  ดม: 321006,
  พระแก้ว: 321007,
  บ้านจารย์: 321008,
  กระเทียม: 321009,
  สะกาด: 321010,
  ตาตุม: 321011,
  ทับทัน: 321012,
  ตาคง: 321013,
  บ้านชบ: 321015,
  เทพรักษา: 321017,
  ลำดวน: 321101,
  โชคเหนือ: 321102,
  อู่โลก: 321103,
  ตรำดม: 321104,
  ตระเปียงเตีย: 321105,
  สำโรงทาบ: 321201,
  หนองไผ่ล้อม: 321202,
  กระออม: 321203,
  หนองฮะ: 321204,
  ศรีสุข: 321205,
  เกาะแก้ว: 321206,
  หมื่นศรี: 321207,
  เสม็จ: 321208,
  สะโน: 321209,
  ประดู่: 321210,
  บัวเชด: 321301,
  สะเดา: 321302,
  จรัส: 321303,
  ตาวัง: 321304,
  อาโพน: 321305,
  สำเภาลูน: 321306,
  บักได: 321401,
  โคกกลาง: 321402,
  จีกแดก: 321403,
  ตาเมียง: 321404,
  ณรงค์: 321501,
  แจนแวน: 321502,
  ตรวจ: 321503,
  หนองแวง: 321504,
  ศรีสุข: 321505,
  เขวาสินรินทร์: 321601,
  บึง: 321602,
  ตากูก: 321603,
  ปราสาททอง: 321604,
  บ้านแร่: 321605,
  หนองหลวง: 321701,
  คำผง: 321702,
  โนน: 321703,
  ระเวียง: 321704,
  หนองเทพ: 321705,
  เมืองเหนือ: 330101,
  เมืองใต้: 330102,
  คูซอด: 330103,
  ซำ: 330104,
  จาน: 330105,
  ตะดอบ: 330106,
  หนองครก: 330107,
  โพนข่า: 330111,
  โพนค้อ: 330112,
  โพนเขวา: 330115,
  หญ้าปล้อง: 330116,
  ทุ่ม: 330118,
  หนองไฮ: 330119,
  หนองแก้ว: 330121,
  น้ำคำ: 330122,
  โพธิ์: 330123,
  หมากเขียบ: 330124,
  หนองไผ่: 330127,
  ยางชุมน้อย: 330201,
  ลิ้นฟ้า: 330202,
  คอนกาม: 330203,
  โนนคูณ: 330204,
  กุดเมืองฮาม: 330205,
  บึงบอน: 330206,
  ยางชุมใหญ่: 330207,
  ดูน: 330301,
  โนนสัง: 330302,
  หนองหัวช้าง: 330303,
  ยาง: 330304,
  หนองแวง: 330305,
  หนองแก้ว: 330306,
  ทาม: 330307,
  ละทาย: 330308,
  เมืองน้อย: 330309,
  อีปาด: 330310,
  บัวน้อย: 330311,
  หนองบัว: 330312,
  ดู่: 330313,
  ผักแพว: 330314,
  จาน: 330315,
  คำเนียม: 330320,
  บึงมะลู: 330401,
  กุดเสลา: 330402,
  เมือง: 330403,
  สังเม็ก: 330405,
  น้ำอ้อม: 330406,
  ละลาย: 330407,
  รุง: 330408,
  ตระกาจ: 330409,
  จานใหญ่: 330411,
  ภูเงิน: 330412,
  ชำ: 330413,
  กระแชง: 330414,
  โนนสำราญ: 330415,
  หนองหญ้าลาด: 330416,
  เสาธงชัย: 330419,
  ขนุน: 330420,
  สวนกล้วย: 330421,
  เวียงเหนือ: 330423,
  ทุ่งใหญ่: 330424,
  ภูผาหมอก: 330425,
  กันทรารมย์: 330501,
  จะกง: 330502,
  ใจดี: 330503,
  ดองกำเม็ด: 330504,
  โสน: 330505,
  ปรือใหญ่: 330506,
  สะเดาใหญ่: 330507,
  ตาอุด: 330508,
  ห้วยเหนือ: 330509,
  ห้วยใต้: 330510,
  หัวเสือ: 330511,
  ตะเคียน: 330513,
  นิคมพัฒนา: 330515,
  โคกเพชร: 330517,
  ปราสาท: 330518,
  สำโรงตาเจ็น: 330521,
  ห้วยสำราญ: 330522,
  กฤษณา: 330524,
  ลมศักดิ์: 330525,
  หนองฉลอง: 330526,
  ศรีตระกูล: 330527,
  ศรีสะอาด: 330528,
  ไพรบึง: 330601,
  ดินแดง: 330602,
  ปราสาทเยอ: 330603,
  สำโรงพลัน: 330604,
  สุขสวัสดิ์: 330605,
  โนนปูน: 330606,
  พิมาย: 330701,
  กู่: 330702,
  หนองเชียงทูน: 330703,
  ตูม: 330704,
  สมอ: 330705,
  โพธิ์ศรี: 330706,
  สำโรงปราสาท: 330707,
  ดู่: 330708,
  สวาย: 330709,
  พิมายเหนือ: 330710,
  สิ: 330801,
  บักดอง: 330802,
  พราน: 330803,
  โพธิ์วงศ์: 330804,
  ไพร: 330805,
  กระหวัน: 330806,
  ขุนหาญ: 330807,
  โนนสูง: 330808,
  กันทรอม: 330809,
  ภูฝ้าย: 330810,
  โพธิ์กระสังข์: 330811,
  ห้วยจันทร์: 330812,
  เมืองคง: 330901,
  เมืองแคน: 330902,
  หนองแค: 330903,
  จิกสังข์ทอง: 330906,
  ด่าน: 330907,
  ดู่: 330908,
  หนองอึ่ง: 330909,
  บัวหุ่ง: 330910,
  ไผ่: 330911,
  ส้มป่อย: 330912,
  หนองหมี: 330913,
  หว้านคำ: 330914,
  สร้างปี่: 330915,
  กำแพง: 331001,
  อี่หล่ำ: 331002,
  ก้านเหลือง: 331003,
  ทุ่งไชย: 331004,
  สำโรง: 331005,
  แขม: 331006,
  หนองไฮ: 331007,
  ขะยูง: 331008,
  ตาเกษ: 331010,
  หัวช้าง: 331011,
  รังแร้ง: 331012,
  แต้: 331014,
  แข้: 331015,
  โพธิ์ชัย: 331016,
  ปะอาว: 331017,
  หนองห้าง: 331018,
  สระกำแพงใหญ่: 331022,
  โคกหล่าม: 331024,
  โคกจาน: 331025,
  เป๊าะ: 331101,
  บึงบูรพ์: 331102,
  ห้วยทับทัน: 331201,
  เมืองหลวง: 331202,
  กล้วยกว้าง: 331203,
  ผักไหม: 331204,
  จานแสนไชย: 331205,
  ปราสาท: 331206,
  โนนค้อ: 331301,
  บก: 331302,
  โพธิ์: 331303,
  หนองกุง: 331304,
  เหล่ากวาง: 331305,
  ศรีแก้ว: 331401,
  พิงพวย: 331402,
  สระเยาว์: 331403,
  ตูม: 331404,
  เสื่องข้าว: 331405,
  ศรีโนนงาม: 331406,
  สะพุง: 331407,
  น้ำเกลี้ยง: 331501,
  ละเอาะ: 331502,
  ตองปิด: 331503,
  เขิน: 331504,
  รุ่งระวี: 331505,
  คูบ: 331506,
  บุสูง: 331601,
  ธาตุ: 331602,
  ดวนใหญ่: 331603,
  บ่อแก้ว: 331604,
  ศรีสำราญ: 331605,
  ทุ่งสว่าง: 331606,
  วังหิน: 331607,
  โพนยาง: 331608,
  โคกตาล: 331701,
  ห้วยตามอญ: 331702,
  ห้วยตึ๊กชู: 331703,
  ละลม: 331704,
  ตะเคียนราม: 331705,
  ดงรัก: 331706,
  ไพรพัฒนา: 331707,
  เมืองจันทร์: 331801,
  ตาโกน: 331802,
  หนองใหญ่: 331803,
  เสียว: 331901,
  หนองหว้า: 331902,
  หนองงูเหลือม: 331903,
  หนองฮาง: 331904,
  ท่าคล้อ: 331905,
  พยุห์: 332001,
  พรหมสวัสดิ์: 332002,
  ตำแย: 332003,
  โนนเพ็ก: 332004,
  หนองค้า: 332005,
  โดด: 332101,
  เสียว: 332102,
  หนองม้า: 332103,
  ผือใหญ่: 332104,
  อีเซ: 332105,
  กุง: 332201,
  คลีกลิ้ง: 332202,
  หนองบัวดง: 332203,
  โจดม่วง: 332204,
  ในเมือง: 340101,
  หัวเรือ: 340104,
  หนองขอน: 340105,
  ปทุม: 340107,
  ขามใหญ่: 340108,
  แจระแม: 340109,
  หนองบ่อ: 340111,
  ไร่น้อย: 340112,
  กระโสบ: 340113,
  กุดลาด: 340116,
  ขี้เหล็ก: 340119,
  ปะอาว: 340120,
  นาคำ: 340201,
  แก้งกอก: 340202,
  เอือดใหญ่: 340203,
  วาริน: 340204,
  ลาดควาย: 340205,
  สงยาง: 340206,
  ตะบ่าย: 340207,
  คำไหล: 340208,
  หนามแท่ง: 340209,
  นาเลิน: 340210,
  ดอนใหญ่: 340211,
  โขงเจียม: 340301,
  ห้วยยาง: 340302,
  นาโพธิ์กลาง: 340303,
  หนองแสงใหญ่: 340304,
  ห้วยไผ่: 340305,
  คำเขื่อนแก้ว: 340306,
  เขื่องใน: 340401,
  สร้างถ่อ: 340402,
  ค้อทอง: 340403,
  ก่อเอ้: 340404,
  หัวดอน: 340405,
  ชีทวน: 340406,
  ท่าไห: 340407,
  นาคำใหญ่: 340408,
  แดงหม้อ: 340409,
  ธาตุน้อย: 340410,
  บ้านไทย: 340411,
  บ้านกอก: 340412,
  กลางใหญ่: 340413,
  โนนรัง: 340414,
  ยางขี้นก: 340415,
  ศรีสุข: 340416,
  สหธาตุ: 340417,
  หนองเหล่า: 340418,
  เขมราฐ: 340501,
  ขามป้อม: 340503,
  เจียด: 340504,
  หนองผือ: 340507,
  นาแวง: 340508,
  แก้งเหนือ: 340510,
  หนองนกทา: 340511,
  หนองสิม: 340512,
  หัวนา: 340513,
  เมืองเดช: 340701,
  นาส่วง: 340702,
  นาเจริญ: 340704,
  ทุ่งเทิง: 340706,
  สมสะอาด: 340708,
  กุดประทาย: 340709,
  ตบหู: 340710,
  กลาง: 340711,
  แก้ง: 340712,
  ท่าโพธิ์ศรี: 340713,
  บัวงาม: 340715,
  คำครั่ง: 340716,
  นากระแซง: 340717,
  โพนงาม: 340720,
  ป่าโมง: 340721,
  โนนสมบูรณ์: 340723,
  นาจะหลวย: 340801,
  โนนสมบูรณ์: 340802,
  พรสวรรค์: 340803,
  บ้านตูม: 340804,
  โสกแสง: 340805,
  โนนสวรรค์: 340806,
  โซง: 340901,
  ยาง: 340903,
  โดมประดิษฐ์: 340904,
  บุเปือย: 340906,
  สีวิเชียร: 340907,
  ยางใหญ่: 340909,
  เก่าขาม: 340911,
  โพนงาม: 341001,
  ห้วยข่า: 341002,
  คอแลน: 341003,
  นาโพธิ์: 341004,
  หนองสะโน: 341005,
  โนนค้อ: 341006,
  บัวงาม: 341007,
  บ้านแมด: 341008,
  ขุหลุ: 341101,
  กระเดียน: 341102,
  เกษม: 341103,
  กุศกร: 341104,
  ขามเปี้ย: 341105,
  คอนสาย: 341106,
  โคกจาน: 341107,
  นาพิน: 341108,
  นาสะไม: 341109,
  โนนกุง: 341110,
  ตระการ: 341111,
  ตากแดด: 341112,
  ไหล่ทุ่ง: 341113,
  เป้า: 341114,
  เซเป็ด: 341115,
  สะพือ: 341116,
  หนองเต่า: 341117,
  ถ้ำแข้: 341118,
  ท่าหลวง: 341119,
  ห้วยฝ้ายพัฒนา: 341120,
  กุดยาลวน: 341121,
  บ้านแดง: 341122,
  คำเจริญ: 341123,
  ข้าวปุ้น: 341201,
  โนนสวาง: 341202,
  แก่งเค็ง: 341203,
  กาบิน: 341204,
  หนองทันน้ำ: 341205,
  ม่วงสามสิบ: 341401,
  เหล่าบก: 341402,
  ดุมใหญ่: 341403,
  หนองช้างใหญ่: 341404,
  หนองเมือง: 341405,
  เตย: 341406,
  ยางสักกระโพหลุ่ม: 341407,
  หนองไข่นก: 341408,
  หนองเหล่า: 341409,
  หนองฮาง: 341410,
  ยางโยภาพ: 341411,
  ไผ่ใหญ่: 341412,
  นาเลิง: 341413,
  โพนแพง: 341414,
  วารินชำราบ: 341501,
  ธาตุ: 341502,
  ท่าลาด: 341504,
  โนนโหนน: 341505,
  คูเมือง: 341507,
  สระสมิง: 341508,
  คำน้ำแซบ: 341510,
  บุ่งหวาย: 341511,
  คำขวาง: 341515,
  โพธิ์ใหญ่: 341516,
  แสนสุข: 341518,
  หนองกินเพล: 341520,
  โนนผึ้ง: 341521,
  เมืองศรีไค: 341522,
  ห้วยขะยูง: 341524,
  บุ่งไหม: 341526,
  พิบูล: 341901,
  กุดชมภู: 341902,
  ดอนจิก: 341904,
  ทรายมูล: 341905,
  นาโพธิ์: 341906,
  โนนกลาง: 341907,
  โพธิ์ไทร: 341909,
  โพธิ์ศรี: 341910,
  ระเว: 341911,
  ไร่ใต้: 341912,
  หนองบัวฮี: 341913,
  อ่างศิลา: 341914,
  โนนกาหลง: 341918,
  บ้านแขม: 341919,
  ตาลสุม: 342001,
  สำโรง: 342002,
  จิกเทิง: 342003,
  หนองกุง: 342004,
  นาคาย: 342005,
  คำหว้า: 342006,
  โพธิ์ไทร: 342101,
  ม่วงใหญ่: 342102,
  สำโรง: 342103,
  สองคอน: 342104,
  สารภี: 342105,
  เหล่างาม: 342106,
  สำโรง: 342201,
  โคกก่อง: 342202,
  หนองไฮ: 342203,
  ค้อน้อย: 342204,
  โนนกาเล็น: 342205,
  โคกสว่าง: 342206,
  โนนกลาง: 342207,
  บอน: 342208,
  ขามป้อม: 342209,
  ดอนมดแดง: 342401,
  เหล่าแดง: 342402,
  ท่าเมือง: 342403,
  คำไฮใหญ่: 342404,
  คันไร่: 342501,
  ช่องเม็ก: 342502,
  โนนก่อ: 342503,
  นิคมลำโดมน้อย: 342504,
  ฝางคำ: 342505,
  คำเขื่อนแก้ว: 342506,
  หนองอ้ม: 342602,
  นาเกษม: 342603,
  กุดเรือ: 342604,
  โคกชำแระ: 342605,
  นาห่อม: 342606,
  นาเยีย: 342901,
  นาดี: 342902,
  นาเรือง: 342903,
  นาตาล: 343001,
  พะลาน: 343002,
  กองโพน: 343003,
  พังเคน: 343004,
  เหล่าเสือโก้ก: 343101,
  โพนเมือง: 343102,
  แพงใหญ่: 343103,
  หนองบก: 343104,
  แก่งโดม: 343201,
  ท่าช้าง: 343202,
  บุ่งมะแลง: 343203,
  สว่าง: 343204,
  ตาเกา: 343301,
  ไพบูลย์: 343302,
  ขี้เหล็ก: 343303,
  โคกสะอาด: 343304,
  ในเมือง: 350101,
  น้ำคำใหญ่: 350102,
  ตาดทอง: 350103,
  สำราญ: 350104,
  ค้อเหนือ: 350105,
  ดู่ทุ่ง: 350106,
  เดิด: 350107,
  ขั้นไดใหญ่: 350108,
  ทุ่งแต้: 350109,
  สิงห์: 350110,
  นาสะไมย์: 350111,
  เขื่องคำ: 350112,
  หนองหิน: 350113,
  หนองคู: 350114,
  ขุมเงิน: 350115,
  ทุ่งนางโอก: 350116,
  หนองเรือ: 350117,
  หนองเป็ด: 350118,
  ทรายมูล: 350201,
  ดู่ลาด: 350202,
  ดงมะไฟ: 350203,
  นาเวียง: 350204,
  ไผ่: 350205,
  กุดชุม: 350301,
  โนนเปือย: 350302,
  กำแมด: 350303,
  นาโส่: 350304,
  ห้วยแก้ง: 350305,
  หนองหมี: 350306,
  โพนงาม: 350307,
  คำน้ำสร้าง: 350308,
  หนองแหน: 350309,
  ลุมพุก: 350401,
  ย่อ: 350402,
  สงเปือย: 350403,
  โพนทัน: 350404,
  ทุ่งมน: 350405,
  นาคำ: 350406,
  ดงแคนใหญ่: 350407,
  กู่จาน: 350408,
  นาแก: 350409,
  กุดกุง: 350410,
  เหล่าไฮ: 350411,
  แคนน้อย: 350412,
  ดงเจริญ: 350413,
  โพธิ์ไทร: 350501,
  กระจาย: 350502,
  โคกนาโก: 350503,
  เชียงเพ็ง: 350504,
  ศรีฐาน: 350505,
  ฟ้าหยาด: 350601,
  หัวเมือง: 350602,
  คูเมือง: 350603,
  ผือฮี: 350604,
  บากเรือ: 350605,
  ม่วง: 350606,
  โนนทราย: 350607,
  บึงแก: 350608,
  พระเสาร์: 350609,
  สงยาง: 350610,
  ฟ้าห่วน: 350701,
  กุดน้ำใส: 350702,
  น้ำอ้อม: 350703,
  ค้อวัง: 350704,
  บุ่งค้า: 350802,
  สวาท: 350803,
  ห้องแซง: 350805,
  สามัคคี: 350806,
  กุดเชียงหมี: 350807,
  สามแยก: 350810,
  กุดแห่: 350811,
  โคกสำราญ: 350812,
  สร้างมิ่ง: 350813,
  ศรีแก้ว: 350814,
  ไทยเจริญ: 350901,
  น้ำคำ: 350902,
  ส้มผ่อ: 350903,
  คำเตย: 350904,
  คำไผ่: 350905,
  ในเมือง: 360101,
  รอบเมือง: 360102,
  โพนทอง: 360103,
  นาฝาย: 360104,
  บ้านค่าย: 360105,
  กุดตุ้ม: 360106,
  ชีลอง: 360107,
  บ้านเล่า: 360108,
  นาเสียว: 360109,
  หนองนาแซง: 360110,
  ลาดใหญ่: 360111,
  หนองไผ่: 360112,
  ท่าหินโงม: 360113,
  ห้วยต้อน: 360114,
  ห้วยบง: 360115,
  โนนสำราญ: 360116,
  โคกสูง: 360117,
  บุ่งคล้า: 360118,
  ซับสีทอง: 360119,
  บ้านเขว้า: 360201,
  ตลาดแร้ง: 360202,
  ลุ่มลำชี: 360203,
  ชีบน: 360204,
  ภูแลนคา: 360205,
  โนนแดง: 360206,
  คอนสวรรค์: 360301,
  ยางหวาย: 360302,
  ช่องสามหมอ: 360303,
  โนนสะอาด: 360304,
  ห้วยไร่: 360305,
  บ้านโสก: 360306,
  โคกมั่งงอย: 360307,
  หนองขาม: 360308,
  ศรีสำราญ: 360309,
  บ้านยาง: 360401,
  บ้านหัน: 360402,
  บ้านเดื่อ: 360403,
  บ้านเป้า: 360404,
  กุดเลาะ: 360405,
  โนนกอก: 360406,
  สระโพนทอง: 360407,
  หนองข่า: 360408,
  หนองโพนงาม: 360409,
  บ้านบัว: 360410,
  โนนทอง: 360412,
  หนองบัวแดง: 360501,
  กุดชุมแสง: 360502,
  ถ้ำวัวแดง: 360503,
  นางแดด: 360504,
  หนองแวง: 360507,
  คูเมือง: 360508,
  ท่าใหญ่: 360509,
  วังชมภู: 360511,
  บ้านกอก: 360601,
  หนองบัวบาน: 360602,
  บ้านขาม: 360603,
  กุดน้ำใส: 360605,
  หนองโดน: 360606,
  ละหาน: 360607,
  หนองบัวใหญ่: 360610,
  หนองบัวโคก: 360611,
  ส้มป่อย: 360613,
  บ้านชวน: 360701,
  บ้านเพชร: 360702,
  บ้านตาล: 360703,
  หัวทะเล: 360704,
  โคกเริงรมย์: 360705,
  เกาะมะนาว: 360706,
  โคกเพชรพัฒนา: 360707,
  หนองบัวระเหว: 360801,
  วังตะเฆ่: 360802,
  ห้วยแย้: 360803,
  โคกสะอาด: 360804,
  โสกปลาดุก: 360805,
  วะตะแบก: 360901,
  ห้วยยายจิ๋ว: 360902,
  นายางกลัก: 360903,
  บ้านไร่: 360904,
  โป่งนก: 360905,
  ผักปัง: 361001,
  กวางโจน: 361002,
  หนองคอนไทย: 361003,
  บ้านแก้ง: 361004,
  กุดยม: 361005,
  บ้านเพชร: 361006,
  โคกสะอาด: 361007,
  หนองตูม: 361008,
  โอโล: 361009,
  ธาตุทอง: 361010,
  บ้านดอน: 361011,
  บ้านแท่น: 361101,
  สามสวน: 361102,
  สระพัง: 361103,
  บ้านเต่า: 361104,
  หนองคู: 361105,
  ช่องสามหมอ: 361201,
  หนองขาม: 361202,
  นาหนองทุ่ม: 361203,
  บ้านแก้ง: 361204,
  หนองสังข์: 361205,
  หลุบคา: 361206,
  โคกกุง: 361207,
  เก่าย่าดี: 361208,
  ท่ามะไฟหวาน: 361209,
  หนองไผ่: 361210,
  คอนสาร: 361301,
  ทุ่งพระ: 361302,
  โนนคูณ: 361303,
  ห้วยยาง: 361304,
  ทุ่งลุยลาย: 361305,
  ดงบัง: 361306,
  ทุ่งนาเลา: 361307,
  ดงกลาง: 361308,
  บ้านเจียง: 361401,
  เจาทอง: 361402,
  วังทอง: 361403,
  แหลมทอง: 361404,
  หนองฉิม: 361501,
  ตาเนิน: 361502,
  กะฮาด: 361503,
  รังงาม: 361504,
  ซับใหญ่: 361601,
  ท่ากูบ: 361602,
  ตะโกทอง: 361603,
  บุ่ง: 370101,
  ไก่คำ: 370102,
  นาจิก: 370103,
  ปลาค้าว: 370104,
  เหล่าพรวน: 370105,
  สร้างนกทา: 370106,
  คึมใหญ่: 370107,
  นาผือ: 370108,
  น้ำปลีก: 370109,
  นาวัง: 370110,
  นาหมอม้า: 370111,
  โนนโพธิ์: 370112,
  โนนหนามแท่ง: 370113,
  ห้วยไร่: 370114,
  หนองมะแซว: 370115,
  กุดปลาดุก: 370116,
  ดอนเมย: 370117,
  นายม: 370118,
  นาแต้: 370119,
  ชานุมาน: 370201,
  โคกสาร: 370202,
  คำเขื่อนแก้ว: 370203,
  โคกก่ง: 370204,
  ป่าก่อ: 370205,
  หนองข่า: 370301,
  คำโพน: 370302,
  นาหว้า: 370303,
  ลือ: 370304,
  ห้วย: 370305,
  โนนงาม: 370306,
  นาป่าแซง: 370307,
  พนา: 370401,
  จานลาน: 370402,
  ไม้กลอน: 370403,
  พระเหลา: 370404,
  เสนางคนิคม: 370501,
  โพนทอง: 370502,
  ไร่สีสุก: 370503,
  นาเวียง: 370504,
  หนองไฮ: 370505,
  หนองสามสี: 370506,
  หัวตะพาน: 370601,
  คำพระ: 370602,
  เค็งใหญ่: 370603,
  หนองแก้ว: 370604,
  โพนเมืองน้อย: 370605,
  สร้างถ่อน้อย: 370606,
  จิกดู่: 370607,
  รัตนวารี: 370608,
  อำนาจ: 370701,
  ดงมะยาง: 370702,
  เปือย: 370703,
  ดงบัง: 370704,
  ไร่ขี: 370705,
  แมด: 370706,
  โคกกลาง: 370707,
  หนองบัว: 390101,
  หนองภัยศูนย์: 390102,
  โพธิ์ชัย: 390103,
  หนองสวรรค์: 390104,
  หัวนา: 390105,
  บ้านขาม: 390106,
  นามะเฟือง: 390107,
  บ้านพร้าว: 390108,
  โนนขมิ้น: 390109,
  ลำภู: 390110,
  กุดจิก: 390111,
  โนนทัน: 390112,
  นาคำไฮ: 390113,
  ป่าไม้งาม: 390114,
  หนองหว้า: 390115,
  นากลาง: 390201,
  ด่านช้าง: 390202,
  กุดดินจี่: 390205,
  ฝั่งแดง: 390206,
  เก่ากลอย: 390207,
  โนนเมือง: 390209,
  อุทัยสวรรค์: 390210,
  ดงสวรรค์: 390211,
  กุดแห่: 390213,
  โนนสัง: 390301,
  บ้านถิ่น: 390302,
  หนองเรือ: 390303,
  กุดดู่: 390304,
  บ้านค้อ: 390305,
  โนนเมือง: 390306,
  โคกใหญ่: 390307,
  โคกม่วง: 390308,
  นิคมพัฒนา: 390309,
  ปางกู่: 390310,
  อุดมพร: 431505,
  รัตนวาปี: 431601,
  นาทับไฮ: 431602,
  บ้านต้อน: 431603,
  พระบาทนาสิงห์: 431604,
  โพนแพง: 431605,
  โพธิ์ตาก: 431701,
  โพนทอง: 431702,
  ด่านศรีสุข: 431703,
  ตลาด: 440101,
  เขวา: 440102,
  ท่าตูม: 440103,
  แวงน่าง: 440104,
  โคกก่อ: 440105,
  ดอนหว่าน: 440106,
  เกิ้ง: 440107,
  แก่งเลิงจาน: 440108,
  ท่าสองคอน: 440109,
  ลาดพัฒนา: 440110,
  หนองปลิง: 440111,
  ห้วยแอ่ง: 440112,
  หนองโน: 440113,
  บัวค้อ: 440114,
  แกดำ: 440201,
  วังแสง: 440202,
  มิตรภาพ: 440203,
  หนองกุง: 440204,
  โนนภิบาล: 440205,
  หัวขวาง: 440301,
  ยางน้อย: 440302,
  วังยาว: 440303,
  เขวาไร่: 440304,
  แพง: 440305,
  แก้งแก: 440306,
  หนองเหล็ก: 440307,
  หนองบัว: 440308,
  เหล่า: 440309,
  เขื่อน: 440310,
  หนองบอน: 440311,
  โพนงาม: 440312,
  ยางท่าแจ้ง: 440313,
  แห่ใต้: 440314,
  หนองกุงสวรรค์: 440315,
  เลิงใต้: 440316,
  ดอนกลาง: 440317,
  โคกพระ: 440401,
  คันธารราษฎร์: 440402,
  มะค่า: 440403,
  ท่าขอนยาง: 440404,
  นาสีนวน: 440405,
  ขามเรียง: 440406,
  เขวาใหญ่: 440407,
  ศรีสุข: 440408,
  กุดใส้จ่อ: 440409,
  ขามเฒ่าพัฒนา: 440410,
  เชียงยืน: 440501,
  หนองซอน: 440503,
  ดอนเงิน: 440505,
  กู่ทอง: 440506,
  นาทอง: 440507,
  เสือเฒ่า: 440508,
  โพนทอง: 440511,
  เหล่าบัวบาน: 440512,
  บรบือ: 440601,
  บ่อใหญ่: 440602,
  วังไชย: 440604,
  หนองม่วง: 440605,
  กำพี้: 440606,
  โนนราษี: 440607,
  โนนแดง: 440608,
  หนองจิก: 440610,
  บัวมาศ: 440611,
  หนองคูขาด: 440613,
  วังใหม่: 440615,
  ยาง: 440616,
  หนองสิม: 440618,
  หนองโก: 440619,
  ดอนงัว: 440620,
  นาเชือก: 440701,
  สำโรง: 440702,
  หนองแดง: 440703,
  เขวาไร่: 440704,
  หนองโพธิ์: 440705,
  ปอพาน: 440706,
  หนองเม็ก: 440707,
  หนองเรือ: 440708,
  หนองกุง: 440709,
  สันป่าตอง: 440710,
  ปะหลาน: 440801,
  ก้ามปู: 440802,
  เวียงสะอาด: 440803,
  เม็กดำ: 440804,
  นาสีนวล: 440805,
  ราษฎร์เจริญ: 440809,
  หนองบัวแก้ว: 440810,
  เมืองเตา: 440812,
  ลานสะแก: 440815,
  เวียงชัย: 440816,
  หนองบัว: 440817,
  ราษฎร์พัฒนา: 440818,
  เมืองเสือ: 440819,
  ภารแอ่น: 440820,
  หนองแสง: 440901,
  ขามป้อม: 440902,
  เสือโก้ก: 440903,
  ดงใหญ่: 440904,
  โพธิ์ชัย: 440905,
  หัวเรือ: 440906,
  แคน: 440907,
  งัวบา: 440908,
  นาข่า: 440909,
  บ้านหวาย: 440910,
  หนองไฮ: 440911,
  ประชาพัฒนา: 440912,
  หนองทุ่ม: 440913,
  หนองแสน: 440914,
  โคกสีทองหลาง: 440915,
  นาดูน: 441001,
  หนองไผ่: 441002,
  หนองคู: 441003,
  ดงบัง: 441004,
  ดงดวน: 441005,
  หัวดง: 441006,
  ดงยาง: 441007,
  กู่สันตรัตน์: 441008,
  พระธาตุ: 441009,
  ยางสีสุราช: 441101,
  นาภู: 441102,
  แวงดง: 441103,
  บ้านกู่: 441104,
  ดงเมือง: 441105,
  ขามเรียน: 441106,
  หนองบัวสันตุ: 441107,
  กุดรัง: 441201,
  นาโพธิ์: 441202,
  เลิงแฝก: 441203,
  หนองแวง: 441204,
  ห้วยเตย: 441205,
  ชื่นชม: 441301,
  กุดปลาดุก: 441302,
  เหล่าดอกไม้: 441303,
  หนองกุง: 441304,
  ในเมือง: 450101,
  รอบเมือง: 450102,
  เหนือเมือง: 450103,
  ขอนแก่น: 450104,
  นาโพธิ์: 450105,
  สะอาดสมบูรณ์: 450106,
  สีแก้ว: 450108,
  'ปอภาร(ปอพาน)': 450109,
  โนนรัง: 450110,
  หนองแก้ว: 450117,
  หนองแวง: 450118,
  ดงลาน: 450120,
  แคนใหญ่: 450123,
  โนนตาล: 450124,
  เมืองทอง: 450125,
  เกษตรวิสัย: 450201,
  เมืองบัว: 450202,
  เหล่าหลวง: 450203,
  สิงห์โคก: 450204,
  ดงครั่งใหญ่: 450205,
  บ้านฝาง: 450206,
  หนองแวง: 450207,
  กำแพง: 450208,
  กู่กาสิงห์: 450209,
  น้ำอ้อม: 450210,
  โนนสว่าง: 450211,
  ทุ่งทอง: 450212,
  ดงครั่งน้อย: 450213,
  บัวแดง: 450301,
  ดอกล้ำ: 450302,
  หนองแคน: 450303,
  โพนสูง: 450304,
  โนนสวรรค์: 450305,
  สระบัว: 450306,
  โนนสง่า: 450307,
  ขี้เหล็ก: 450308,
  หัวช้าง: 450401,
  หนองผือ: 450402,
  เมืองหงส์: 450403,
  โคกล่าม: 450404,
  น้ำใส: 450405,
  ดงแดง: 450406,
  ดงกลาง: 450407,
  ป่าสังข์: 450408,
  อีง่อง: 450409,
  ลิ้นฟ้า: 450410,
  ดู่น้อย: 450411,
  ศรีโคตร: 450412,
  นิเวศน์: 450501,
  ธงธานี: 450502,
  หนองไผ่: 450503,
  ธวัชบุรี: 450504,
  อุ่มเม้า: 450506,
  มะอึ: 450507,
  เขวาทุ่ง: 450510,
  ไพศาล: 450515,
  เมืองน้อย: 450517,
  บึงนคร: 450520,
  ราชธานี: 450522,
  หนองพอก: 450524,
  พนมไพร: 450601,
  แสนสุข: 450602,
  กุดน้ำใส: 450603,
  หนองทัพไทย: 450604,
  โพธิ์ใหญ่: 450605,
  วารีสวัสดิ์: 450606,
  โคกสว่าง: 450607,
  โพธิ์ชัย: 450611,
  นานวล: 450612,
  คำไฮ: 450613,
  สระแก้ว: 450614,
  ค้อใหญ่: 450615,
  ชานุวรรณ: 450617,
  แวง: 450701,
  โคกกกม่วง: 450702,
  นาอุดม: 450703,
  สว่าง: 450704,
  หนองใหญ่: 450705,
  โพธิ์ทอง: 450706,
  โนนชัยศรี: 450707,
  โพธิ์ศรีสว่าง: 450708,
  อุ่มเม่า: 450709,
  คำนาดี: 450710,
  พรมสวรรค์: 450711,
  สระนกแก้ว: 450712,
  วังสามัคคี: 450713,
  โคกสูง: 450714,
  ขามเบี้ย: 450801,
  เชียงใหม่: 450802,
  บัวคำ: 450803,
  อัคคะคำ: 450804,
  สะอาด: 450805,
  คำพอุง: 450806,
  หนองตาไก้: 450807,
  ดอนโอง: 450808,
  โพธิ์ศรี: 450809,
  หนองพอก: 450901,
  บึงงาม: 450902,
  ภูเขาทอง: 450903,
  กกโพธิ์: 450904,
  โคกสว่าง: 450905,
  หนองขุ่นใหญ่: 450906,
  รอบเมือง: 450907,
  ผาน้ำย้อย: 450908,
  ท่าสีดา: 450909,
  กลาง: 451001,
  นางาม: 451002,
  เมืองไพร: 451003,
  นาแซง: 451004,
  นาเมือง: 451005,
  วังหลวง: 451006,
  ท่าม่วง: 451007,
  ขวาว: 451008,
  โพธิ์ทอง: 451009,
  ภูเงิน: 451010,
  เกาะแก้ว: 451011,
  นาเลิง: 451012,
  เหล่าน้อย: 451013,
  ศรีวิลัย: 451014,
  หนองหลวง: 451015,
  พรสวรรค์: 451016,
  ขวัญเมือง: 451017,
  บึงเกลือ: 451018,
  สระคู: 451101,
  ดอกไม้: 451102,
  นาใหญ่: 451103,
  หินกอง: 451104,
  เมืองทุ่ง: 451105,
  หัวโทน: 451106,
  บ่อพันขัน: 451107,
  ทุ่งหลวง: 451108,
  หัวช้าง: 451109,
  น้ำคำ: 451110,
  ห้วยหินลาด: 451111,
  ช้างเผือก: 451112,
  ทุ่งกุลา: 451113,
  ทุ่งศรีเมือง: 451114,
  จำปาขัน: 451115,
  หนองผือ: 451201,
  หนองหิน: 451202,
  คูเมือง: 451203,
  กกกุง: 451204,
  เมืองสรวง: 451205,
  โพนทราย: 451301,
  เมืองใหม่: 390401,
  ศรีบุญเรือง: 390402,
  หนองบัวใต้: 390403,
  กุดสะเทียน: 390404,
  นากอก: 390405,
  โนนสะอาด: 390406,
  ยางหล่อ: 390407,
  โนนม่วง: 390408,
  หนองกุงแก้ว: 390409,
  หนองแก: 390410,
  ทรายทอง: 390411,
  หันนางาม: 390412,
  นาสี: 390501,
  บ้านโคก: 390502,
  นาดี: 390503,
  นาด่าน: 390504,
  ดงมะไฟ: 390505,
  สุวรรณคูหา: 390506,
  บุญทัน: 390507,
  กุดผึ้ง: 390508,
  นาเหล่า: 390601,
  นาแก: 390602,
  วังทอง: 390603,
  วังปลาป้อม: 390604,
  เทพคีรี: 390605,
  ในเมือง: 400101,
  สำราญ: 400102,
  โคกสี: 400103,
  ท่าพระ: 400104,
  บ้านทุ่ม: 400105,
  เมืองเก่า: 400106,
  พระลับ: 400107,
  สาวะถี: 400108,
  บ้านหว้า: 400109,
  บ้านค้อ: 400110,
  แดงใหญ่: 400111,
  ดอนช้าง: 400112,
  ดอนหัน: 400113,
  ศิลา: 400114,
  บ้านเป็ด: 400115,
  หนองตูม: 400116,
  บึงเนียม: 400117,
  โนนท่อน: 400118,
  หนองบัว: 400201,
  ป่าหวายนั่ง: 400202,
  โนนฆ้อง: 400203,
  บ้านเหล่า: 400204,
  ป่ามะนาว: 400205,
  บ้านฝาง: 400206,
  โคกงาม: 400207,
  พระยืน: 400301,
  พระบุ: 400302,
  บ้านโต้น: 400303,
  หนองแวง: 400304,
  ขามป้อม: 400305,
  หนองเรือ: 400401,
  บ้านเม็ง: 400402,
  บ้านกง: 400403,
  ยางคำ: 400404,
  จระเข้: 400405,
  โนนทอง: 400406,
  กุดกว้าง: 400407,
  โนนทัน: 400408,
  โนนสะอาด: 400409,
  บ้านผือ: 400410,
  ชุมแพ: 400501,
  โนนหัน: 400502,
  นาหนองทุ่ม: 400503,
  โนนอุดม: 400504,
  ขัวเรียง: 400505,
  หนองไผ่: 400506,
  ไชยสอ: 400507,
  วังหินลาด: 400508,
  นาเพียง: 400509,
  หนองเขียด: 400510,
  หนองเสาเล้า: 400511,
  โนนสะอาด: 400512,
  สีชมพู: 400601,
  ศรีสุข: 400602,
  นาจาน: 400603,
  วังเพิ่ม: 400604,
  ซำยาง: 400605,
  หนองแดง: 400606,
  ดงลาน: 400607,
  บริบูรณ์: 400608,
  บ้านใหม่: 400609,
  ภูห่าน: 400610,
  น้ำพอง: 400701,
  วังชัย: 400702,
  หนองกุง: 400703,
  บัวใหญ่: 400704,
  สะอาด: 400705,
  ม่วงหวาน: 400706,
  บ้านขาม: 400707,
  บัวเงิน: 400708,
  ทรายมูล: 400709,
  ท่ากระเสริม: 400710,
  พังทุย: 400711,
  กุดน้ำใส: 400712,
  โคกสูง: 400801,
  บ้านดง: 400802,
  เขื่อนอุบลรัตน์: 400803,
  นาคำ: 400804,
  ศรีสุขสำราญ: 400805,
  ทุ่งโป่ง: 400806,
  หนองโก: 400901,
  หนองกุงใหญ่: 400902,
  ห้วยโจด: 400905,
  ห้วยยาง: 400906,
  บ้านฝาง: 400907,
  ดูนสาด: 400909,
  หนองโน: 400910,
  น้ำอ้อม: 400911,
  หัวนาคำ: 400912,
  บ้านไผ่: 401001,
  ในเมือง: 401002,
  เมืองเพีย: 401005,
  บ้านลาน: 401009,
  แคนเหนือ: 401010,
  ภูเหล็ก: 401011,
  ป่าปอ: 401013,
  หินตั้ง: 401014,
  หนองน้ำใส: 401016,
  หัวหนอง: 401017,
  เปือยน้อย: 401101,
  วังม่วง: 401102,
  ขามป้อม: 401103,
  สระแก้ว: 401104,
  เมืองพล: 401201,
  โจดหนองแก: 401203,
  เก่างิ้ว: 401204,
  หนองมะเขือ: 401205,
  หนองแวงโสกพระ: 401206,
  เพ็กใหญ่: 401207,
  โคกสง่า: 401208,
  หนองแวงนางเบ้า: 401209,
  ลอมคอม: 401210,
  โนนข่า: 401211,
  โสกนกเต็น: 401212,
  หัวทุ่ง: 401213,
  คอนฉิม: 401301,
  ใหม่นาเพียง: 401302,
  โนนทอง: 401303,
  แวงใหญ่: 401304,
  โนนสะอาด: 401305,
  แวงน้อย: 401401,
  ก้านเหลือง: 401402,
  ท่านางแนว: 401403,
  ละหานนา: 401404,
  ท่าวัด: 401405,
  ทางขวาง: 401406,
  หนองสองห้อง: 401501,
  คึมชาด: 401502,
  โนนธาตุ: 401503,
  ตะกั่วป่า: 401504,
  สำโรง: 401505,
  หนองเม็ก: 401506,
  ดอนดู่: 401507,
  ดงเค็ง: 401508,
  หันโจด: 401509,
  ดอนดั่ง: 401510,
  วังหิน: 401511,
  หนองไผ่ล้อม: 401512,
  บ้านเรือ: 401601,
  ในเมือง: 401602,
  หว้าทอง: 401604,
  กุดขอนแก่น: 401605,
  นาชุมแสง: 401606,
  นาหว้า: 401607,
  เขาน้อย: 401608,
  หนองกุงธนสาร: 401610,
  หนองกุงเซิน: 401612,
  สงเปือย: 401613,
  ทุ่งชมพู: 401614,
  เมืองเก่าพัฒนา: 401615,
  ดินดำ: 401616,
  ภูเวียง: 401617,
  กุดเค้า: 401701,
  สวนหม่อน: 401702,
  หนองแปน: 401703,
  โพนเพ็ก: 401704,
  คำแคน: 401705,
  นาข่า: 401706,
  นางาม: 401707,
  ท่าศาลา: 401710,
  ชนบท: 401801,
  กุดเพียขอม: 401802,
  วังแสง: 401803,
  ห้วยแก: 401804,
  บ้านแท่น: 401805,
  ศรีบุญเรือง: 401806,
  โนนพะยอม: 401807,
  ปอแดง: 401808,
  เขาสวนกวาง: 401901,
  ดงเมืองแอม: 401902,
  นางิ้ว: 401903,
  โนนสมบูรณ์: 401904,
  คำม่วง: 401905,
  โนนคอม: 402001,
  นาฝาย: 402002,
  ภูผาม่าน: 402003,
  วังสวาบ: 402004,
  ห้วยม่วง: 402005,
  กระนวน: 402101,
  คำแมด: 402102,
  บ้านโนน: 402103,
  คูคำ: 402104,
  ห้วยเตย: 402105,
  บ้านโคก: 402201,
  โพธิ์ไชย: 402202,
  ซับสมบูรณ์: 402203,
  นาแพง: 402204,
  กุดธาตุ: 402301,
  บ้านโคก: 402302,
  ขนวน: 402303,
  บ้านแฮด: 402401,
  โคกสำราญ: 402402,
  โนนสมบูรณ์: 402403,
  หนองแซง: 402404,
  โนนศิลา: 402501,
  หนองปลาหมอ: 402502,
  บ้านหัน: 402503,
  เปือยใหญ่: 402504,
  โนนแดง: 402505,
  หมากแข้ง: 410101,
  นิคมสงเคราะห์: 410102,
  บ้านขาว: 410103,
  หนองบัว: 410104,
  บ้านตาด: 410105,
  โนนสูง: 410106,
  หมูม่น: 410107,
  เชียงยืน: 410108,
  หนองนาคำ: 410109,
  กุดสระ: 410110,
  นาดี: 410111,
  บ้านเลื่อม: 410112,
  เชียงพิณ: 410113,
  สามพร้าว: 410114,
  หนองไฮ: 410115,
  นาข่า: 410116,
  บ้านจั่น: 410117,
  หนองขอนกว้าง: 410118,
  โคกสะอาด: 410119,
  นากว้าง: 410120,
  หนองไผ่: 410121,
  กุดจับ: 410201,
  ปะโค: 410202,
  ขอนยูง: 410203,
  เชียงเพ็ง: 410204,
  สร้างก่อ: 410205,
  เมืองเพีย: 410206,
  ตาลเลียน: 410207,
  หมากหญ้า: 410301,
  หนองอ้อ: 410302,
  อูบมุง: 410303,
  กุดหมากไฟ: 410304,
  น้ำพ่น: 410305,
  หนองบัวบาน: 410306,
  โนนหวาย: 410307,
  หนองวัวซอ: 410308,
  ตูมใต้: 410401,
  พันดอน: 410402,
  เวียงคำ: 410403,
  แชแล: 410404,
  เชียงแหว: 410406,
  ห้วยเกิ้ง: 410407,
  เสอเพลอ: 410409,
  สีออ: 410410,
  ปะโค: 410411,
  ผาสุก: 410413,
  ท่าลี่: 410414,
  กุมภวาปี: 410415,
  หนองหว้า: 410416,
  โนนสะอาด: 410501,
  บุ่งแก้ว: 410502,
  โพธิ์ศรีสำราญ: 410503,
  ทมนางาม: 410504,
  หนองกุงศรี: 410505,
  โคกกลาง: 410506,
  หนองหาน: 410601,
  หนองเม็ก: 410602,
  พังงู: 410605,
  สะแบง: 410606,
  สร้อยพร้าว: 410607,
  บ้านเชียง: 410609,
  บ้านยา: 410610,
  โพนงาม: 410611,
  ผักตบ: 410612,
  หนองไผ่: 410614,
  ดอนหายโศก: 410617,
  หนองสระปลา: 410618,
  ทุ่งฝน: 410701,
  ทุ่งใหญ่: 410702,
  นาชุมแสง: 410703,
  นาทม: 410704,
  ไชยวาน: 410801,
  หนองหลัก: 410802,
  คำเลาะ: 410803,
  โพนสูง: 410804,
  ศรีธาตุ: 410901,
  จำปี: 410902,
  บ้านโปร่ง: 410903,
  หัวนาคำ: 410904,
  หนองนกเขียน: 410905,
  นายูง: 410906,
  ตาดทอง: 410907,
  หนองกุงทับม้า: 411001,
  หนองหญ้าไซ: 411002,
  บะยาว: 411003,
  ผาสุก: 411004,
  คำโคกสูง: 411005,
  วังสามหมอ: 411006,
  ศรีสุทโธ: 411101,
  บ้านดุง: 411102,
  ดงเย็น: 411103,
  โพนสูง: 411104,
  อ้อมกอ: 411105,
  บ้านจันทน์: 411106,
  บ้านชัย: 411107,
  นาไหม: 411108,
  ถ่อนนาลับ: 411109,
  วังทอง: 411110,
  บ้านม่วง: 411111,
  บ้านตาด: 411112,
  นาคำ: 411113,
  บ้านผือ: 411701,
  หายโศก: 411702,
  เขือน้ำ: 411703,
  คำบง: 411704,
  โนนทอง: 411705,
  ข้าวสาร: 411706,
  จำปาโมง: 411707,
  กลางใหญ่: 411708,
  เมืองพาน: 411709,
  คำด้วง: 411710,
  หนองหัวคู: 411711,
  บ้านค้อ: 411712,
  หนองแวง: 411713,
  นางัว: 411801,
  น้ำโสม: 411802,
  หนองแวง: 411805,
  บ้านหยวก: 411806,
  โสมเยี่ยม: 411807,
  ศรีสำราญ: 411810,
  สามัคคี: 411812,
  เพ็ญ: 411901,
  บ้านธาตุ: 411902,
  นาพู่: 411903,
  เชียงหวาง: 411904,
  สุมเส้า: 411905,
  นาบัว: 411906,
  บ้านเหล่า: 411907,
  จอมศรี: 411908,
  เตาไห: 411909,
  โคกกลาง: 411910,
  สร้างแป้น: 411911,
  สร้างคอม: 412001,
  เชียงดา: 412002,
  บ้านยวด: 412003,
  บ้านโคก: 412004,
  นาสะอาด: 412005,
  บ้านหินโงม: 412006,
  หนองแสง: 412101,
  แสงสว่าง: 412102,
  นาดี: 412103,
  ทับกุง: 412104,
  นายูง: 412201,
  บ้านก้อง: 412202,
  นาแค: 412203,
  โนนทอง: 412204,
  บ้านแดง: 412301,
  นาทราย: 412302,
  ดอนกลอย: 412303,
  บ้านจีต: 412401,
  โนนทองอินทร์: 412402,
  ค้อใหญ่: 412403,
  คอนสาย: 412404,
  นาม่วง: 412501,
  ห้วยสามพาด: 412502,
  อุ่มจาน: 412503,
  กุดป่อง: 420101,
  เมือง: 420102,
  นาอ้อ: 420103,
  กกดู่: 420104,
  น้ำหมาน: 420105,
  เสี้ยว: 420106,
  นาอาน: 420107,
  นาโป่ง: 420108,
  นาดินดำ: 420109,
  น้ำสวย: 420110,
  ชัยพฤกษ์: 420111,
  นาแขม: 420112,
  ศรีสองรัก: 420113,
  กกทอง: 420114,
  นาด้วง: 420201,
  นาดอกคำ: 420202,
  ท่าสะอาด: 420203,
  ท่าสวรรค์: 420204,
  เชียงคาน: 420301,
  ธาตุ: 420302,
  นาซ่าว: 420303,
  เขาแก้ว: 420304,
  ปากตม: 420305,
  บุฮม: 420306,
  จอมศรี: 420307,
  หาดทรายขาว: 420308,
  ปากชม: 420401,
  เชียงกลม: 420402,
  หาดคัมภีร์: 420403,
  ห้วยบ่อซืน: 420404,
  ห้วยพิชัย: 420405,
  ชมเจริญ: 420406,
  ด่านซ้าย: 420501,
  ปากหมัน: 420502,
  นาดี: 420503,
  โคกงาม: 420504,
  โพนสูง: 420505,
  อิปุ่ม: 420506,
  กกสะทอน: 420507,
  โป่ง: 420508,
  วังยาว: 420509,
  นาหอ: 420510,
  นาแห้ว: 420601,
  แสงภา: 420602,
  นาพึง: 420603,
  นามาลา: 420604,
  เหล่ากอหก: 420605,
  หนองบัว: 420701,
  ท่าศาลา: 420702,
  ร่องจิก: 420703,
  ปลาบ่า: 420704,
  ลาดค่าง: 420705,
  สานตม: 420706,
  ท่าลี่: 420801,
  หนองผือ: 420802,
  อาฮี: 420803,
  น้ำแคม: 420804,
  โคกใหญ่: 420805,
  น้ำทูน: 420806,
  วังสะพุง: 420901,
  ทรายขาว: 420902,
  หนองหญ้าปล้อง: 420903,
  หนองงิ้ว: 420904,
  ปากปวน: 420905,
  ผาน้อย: 420906,
  ผาบิ้ง: 420910,
  เขาหลวง: 420911,
  โคกขมิ้น: 420912,
  ศรีสงคราม: 420913,
  ศรีฐาน: 421001,
  ผานกเค้า: 421005,
  ภูกระดึง: 421007,
  ห้วยส้ม: 421010,
  ภูหอ: 421101,
  หนองคัน: 421102,
  ห้วยสีเสียด: 421104,
  เลยวังไสย์: 421105,
  แก่งศรีภูมิ: 421106,
  ผาขาว: 421201,
  ท่าช้างคล้อง: 421202,
  โนนปอแดง: 421203,
  โนนป่าซาง: 421204,
  บ้านเพิ่ม: 421205,
  เอราวัณ: 421301,
  ผาอินทร์แปลง: 421302,
  ผาสามยอด: 421303,
  ทรัพย์ไพวัลย์: 421304,
  หนองหิน: 421401,
  ตาดข่า: 421402,
  ปวนพุ: 421403,
  ในเมือง: 430101,
  มีชัย: 430102,
  โพธิ์ชัย: 430103,
  กวนวัน: 430104,
  เวียงคุก: 430105,
  วัดธาตุ: 430106,
  หาดคำ: 430107,
  หินโงม: 430108,
  บ้านเดื่อ: 430109,
  ค่ายบกหวาน: 430110,
  สองห้อง: 430111,
  พระธาตุบังพวน: 430113,
  หนองกอมเกาะ: 430116,
  ปะโค: 430117,
  เมืองหมี: 430118,
  สีกาย: 430119,
  ท่าบ่อ: 430201,
  น้ำโมง: 430202,
  กองนาง: 430203,
  โคกคอน: 430204,
  บ้านเดื่อ: 430205,
  บ้านถ่อน: 430206,
  บ้านว่าน: 430207,
  นาข่า: 430208,
  โพนสา: 430209,
  หนองนาง: 430210,
  บึงกาฬ: 430301,
  โนนสมบูรณ์: 430303,
  หนองเข็ง: 430304,
  หอคำ: 430305,
  หนองเลิง: 430306,
  โคกก่อง: 430307,
  นาสวรรค์: 430310,
  ไคสี: 430311,
  ชัยพร: 430314,
  วิศิษฐ์: 430316,
  คำนาดี: 430318,
  โป่งเปือย: 430319,
  ศรีชมภู: 430401,
  ดอนหญ้านาง: 430402,
  พรเจริญ: 430403,
  หนองหัวช้าง: 430404,
  วังชมภู: 430405,
  ป่าแฝก: 430406,
  ศรีสำราญ: 430407,
  จุมพล: 430501,
  วัดหลวง: 430502,
  กุดบง: 430503,
  ชุมช้าง: 430504,
  ทุ่งหลวง: 430506,
  เหล่าต่างคำ: 430507,
  นาหนัง: 430508,
  เซิม: 430509,
  บ้านโพธิ์: 430513,
  บ้านผือ: 430521,
  สร้างนางขาว: 430522,
  โซ่: 430601,
  หนองพันทา: 430602,
  ศรีชมภู: 430603,
  คำแก้ว: 430604,
  บัวตูม: 430605,
  เหล่าทอง: 430607,
  พานพร้าว: 430701,
  บ้านหม้อ: 430703,
  พระพุทธบาท: 430704,
  หนองปลาปาก: 430705,
  แก้งไก่: 430801,
  ผาตั้ง: 430802,
  บ้านม่วง: 430803,
  นางิ้ว: 430804,
  สังคม: 430805,
  เซกา: 430901,
  ซาง: 430902,
  ท่ากกแดง: 430903,
  บ้านต้อง: 430906,
  ป่งไฮ: 430907,
  น้ำจั้น: 430908,
  ท่าสะอาด: 430909,
  หนองทุ่ม: 430912,
  โสกก่าม: 430913,
  ปากคาด: 431001,
  หนองยอง: 431002,
  นากั้ง: 431003,
  โนนศิลา: 431004,
  สมสนุก: 431005,
  นาดง: 431006,
  โพธิ์หมากแข้ง: 431102,
  ดงบัง: 431103,
  ท่าดอกคำ: 431104,
  ศรีวิไล: 431201,
  ชุมภูพร: 431202,
  นาแสง: 431203,
  นาสะแบง: 431204,
  นาสิงห์: 431205,
  บุ่งคล้า: 431301,
  หนองเดิ่น: 431302,
  โคกกว้าง: 431303,
  สระใคร่: 431401,
  คอกช้าง: 431402,
  บ้านฝาง: 431403,
  เฝ้าไร่: 431501,
  นาดี: 431502,
  หนองหลวง: 431503,
  วังหลวง: 431504,
  บางปลากด: 260407,
  บางลูกเสือ: 260408,
  องครักษ์: 260409,
  ชุมพล: 260410,
  คลองใหญ่: 260411,
  สระแก้ว: 270101,
  บ้านแก้ง: 270102,
  ศาลาลำดวน: 270103,
  โคกปี่ฆ้อง: 270104,
  ท่าแยก: 270105,
  ท่าเกษม: 270106,
  สระขวัญ: 270108,
  หนองบอน: 270111,
  คลองหาด: 270201,
  ไทยอุดม: 270202,
  ซับมะกรูด: 270203,
  ไทรเดี่ยว: 270204,
  คลองไก่เถื่อน: 270205,
  เบญจขร: 270206,
  ไทรทอง: 270207,
  ตาพระยา: 270301,
  ทัพเสด็จ: 270302,
  ทัพราช: 270306,
  ทัพไทย: 270307,
  โคคลาน: 270309,
  วังน้ำเย็น: 270401,
  ตาหลังใน: 270403,
  คลองหินปูน: 270405,
  ทุ่งมหาเจริญ: 270406,
  วัฒนานคร: 270501,
  ท่าเกวียน: 270502,
  ผักขะ: 270503,
  โนนหมากเค็ง: 270504,
  หนองน้ำใส: 270505,
  ช่องกุ่ม: 270506,
  หนองแวง: 270507,
  แซร์ออ: 270508,
  หนองหมากฝ้าย: 270509,
  หนองตะเคียนบอน: 270510,
  ห้วยโจด: 270511,
  อรัญประเทศ: 270601,
  เมืองไผ่: 270602,
  หันทราย: 270603,
  คลองน้ำใส: 270604,
  ท่าข้าม: 270605,
  ป่าไร่: 270606,
  ทับพริก: 270607,
  บ้านใหม่หนองไทร: 270608,
  ผ่านศึก: 270609,
  หนองสังข์: 270610,
  คลองทับจันทร์: 270611,
  ฟากห้วย: 270612,
  บ้านด่าน: 270613,
  เขาฉกรรจ์: 270701,
  หนองหว้า: 270702,
  พระเพลิง: 270703,
  เขาสามสิบ: 270704,
  โคกสูง: 270801,
  หนองม่วง: 270802,
  หนองแวง: 270803,
  โนนหมากมุ่น: 270804,
  วังสมบูรณ์: 270901,
  วังใหม่: 270902,
  วังทอง: 270903,
  ในเมือง: 300101,
  โพธิ์กลาง: 300102,
  หนองจะบก: 300103,
  โคกสูง: 300104,
  มะเริง: 300105,
  หนองระเวียง: 300106,
  ปรุใหญ่: 300107,
  หมื่นไวย: 300108,
  พลกรัง: 300109,
  หนองไผ่ล้อม: 300110,
  หัวทะเล: 300111,
  บ้านเกาะ: 300112,
  บ้านใหม่: 300113,
  พุดซา: 300114,
  บ้านโพธิ์: 300115,
  จอหอ: 300116,
  โคกกรวด: 300117,
  ไชยมงคล: 300118,
  หนองบัวศาลา: 300119,
  สุรนารี: 300120,
  สีมุม: 300121,
  ตลาด: 300122,
  พะเนา: 300123,
  หนองกระทุ่ม: 300124,
  หนองไข่น้ำ: 300125,
  แชะ: 300201,
  เฉลียง: 300202,
  ครบุรี: 300203,
  โคกกระชาย: 300204,
  จระเข้หิน: 300205,
  มาบตะโกเอน: 300206,
  อรพิมพ์: 300207,
  บ้านใหม่: 300208,
  ลำเพียก: 300209,
  ครบุรีใต้: 300210,
  ตะแบกบาน: 300211,
  สระว่านพระยา: 300212,
  เสิงสาง: 300301,
  สระตะเคียน: 300302,
  โนนสมบูรณ์: 300303,
  กุดโบสถ์: 300304,
  สุขไพบูลย์: 300305,
  บ้านราษฎร์: 300306,
  เมืองคง: 300401,
  คูขาด: 300402,
  เทพาลัย: 300403,
  ตาจั่น: 300404,
  บ้านปรางค์: 300405,
  หนองมะนาว: 300406,
  หนองบัว: 300407,
  โนนเต็ง: 300408,
  ดอนใหญ่: 300409,
  ขามสมบูรณ์: 300410,
  บ้านเหลื่อม: 300501,
  วังโพธิ์: 300502,
  โคกกระเบื้อง: 300503,
  ช่อระกา: 300504,
  จักราช: 300601,
  ท่าช้าง: 300602,
  ทองหลาง: 300603,
  สีสุก: 300604,
  หนองขาม: 300605,
  หนองงูเหลือม: 300606,
  หนองพลวง: 300607,
  หนองยาง: 300608,
  พระพุทธ: 300609,
  ศรีละกอ: 300610,
  คลองเมือง: 300611,
  ช้างทอง: 300612,
  หินโคน: 300613,
  กระโทก: 300701,
  พลับพลา: 300702,
  ท่าอ่าง: 300703,
  ทุ่งอรุณ: 300704,
  ท่าลาดขาว: 300705,
  ท่าจะหลุง: 300706,
  ท่าเยี่ยม: 300707,
  โชคชัย: 300708,
  ละลมใหม่พัฒนา: 300709,
  ด่านเกวียน: 300710,
  กุดพิมาน: 300801,
  ด่านขุนทด: 300802,
  ด่านนอก: 300803,
  ด่านใน: 300804,
  ตะเคียน: 300805,
  บ้านเก่า: 300806,
  บ้านแปรง: 300807,
  พันชนะ: 300808,
  สระจรเข้: 300809,
  หนองกราด: 300810,
  หนองบัวตะเกียด: 300811,
  หนองบัวละคร: 300812,
  หินดาด: 300813,
  ห้วยบง: 300815,
  โนนเมืองพัฒนา: 300817,
  หนองไทร: 300818,
  โนนไทย: 300901,
  ด่านจาก: 300902,
  กำปัง: 300903,
  สำโรง: 300904,
  ค้างพลู: 300905,
  บ้านวัง: 300906,
  บัลลังก์: 300907,
  สายออ: 300908,
  ถนนโพธิ์: 300909,
  พังเทียม: 300910,
  สระพระ: 300911,
  ทัพรั้ง: 300912,
  หนองหอย: 300913,
  มะค่า: 300914,
  มาบกราด: 300915,
  โนนสูง: 301001,
  ใหม่: 301002,
  โตนด: 301003,
  บิง: 301004,
  ดอนชมพู: 301005,
  ธารปราสาท: 301006,
  หลุมข้าว: 301007,
  มะค่า: 301008,
  พลสงคราม: 301009,
  จันอัด: 301010,
  ขามเฒ่า: 301011,
  ด่านคล้า: 301012,
  ลำคอหงษ์: 301013,
  เมืองปราสาท: 301014,
  ดอนหวาย: 301015,
  ลำมูล: 301016,
  ขามสะแกแสง: 301101,
  โนนเมือง: 301102,
  เมืองนาท: 301103,
  ชีวึก: 301104,
  พะงาด: 301105,
  หนองหัวฟาน: 301106,
  เมืองเกษตร: 301107,
  บัวใหญ่: 301201,
  ห้วยยาง: 301203,
  เสมาใหญ่: 301204,
  ดอนตะหนิน: 301206,
  หนองบัวสะอาด: 301207,
  โนนทองหลาง: 301208,
  หนองหว้า: 301209,
  บัวลาย: 301210,
  สีดา: 301211,
  โพนทอง: 301212,
  กุดจอก: 301214,
  ด่านช้าง: 301215,
  โนนจาน: 301216,
  สามเมือง: 301218,
  ขุนทอง: 301220,
  หนองตาดใหญ่: 301221,
  เมืองพะไล: 301222,
  โนนประดู่: 301223,
  หนองแจ้งใหญ่: 301224,
  ประทาย: 301301,
  กระทุ่มราย: 301303,
  วังไม้แดง: 301304,
  ตลาดไทร: 301306,
  หนองพลวง: 301307,
  หนองค่าย: 301308,
  หันห้วยทราย: 301309,
  ดอนมัน: 301310,
  นางรำ: 301313,
  โนนเพ็ด: 301314,
  ทุ่งสว่าง: 301315,
  โคกกลาง: 301317,
  เมืองโดน: 301318,
  เมืองปัก: 301401,
  ตะคุ: 301402,
  โคกไทย: 301403,
  สำโรง: 301404,
  ตะขบ: 301405,
  นกออก: 301406,
  ดอน: 301407,
  ตูม: 301409,
  งิ้ว: 301410,
  สะแกราช: 301411,
  ลำนางแก้ว: 301412,
  ภูหลวง: 301416,
  ธงชัยเหนือ: 301417,
  สุขเกษม: 301418,
  เกษมทรัพย์: 301419,
  บ่อปลาทอง: 301420,
  ในเมือง: 301501,
  สัมฤทธิ์: 301502,
  โบสถ์: 301503,
  กระเบื้องใหญ่: 301504,
  ท่าหลวง: 301505,
  รังกาใหญ่: 301506,
  ชีวาน: 301507,
  นิคมสร้างตนเอง: 301508,
  กระชอน: 301509,
  ดงใหญ่: 301510,
  ธารละหลอด: 301511,
  หนองระเวียง: 301512,
  ห้วยแถลง: 301601,
  ทับสวาย: 301602,
  เมืองพลับพลา: 301603,
  หลุ่งตะเคียน: 301604,
  หินดาด: 301605,
  งิ้ว: 301606,
  กงรถ: 301607,
  หลุ่งประดู่: 301608,
  ตะโก: 301609,
  ห้วยแคน: 301610,
  ชุมพวง: 301701,
  ประสุข: 301702,
  ท่าลาด: 301703,
  สาหร่าย: 301704,
  ตลาดไทร: 301705,
  ช่องแมว: 301706,
  ขุย: 301707,
  โนนรัง: 301710,
  บ้านยาง: 301711,
  หนองหลัก: 301714,
  ไพล: 301715,
  โนนตูม: 301716,
  โนนยอ: 301717,
  สูงเนิน: 301801,
  เสมา: 301802,
  หนองแก: 610108,
  โนนเหล็ก: 610109,
  หนองเต่า: 610110,
  หนองไผ่แบน: 610111,
  หนองพังค่า: 610112,
  ทุ่งใหญ่: 610113,
  เนินแจง: 610114,
  ทัพทัน: 610201,
  ทุ่งนาไทย: 610202,
  เขาขี้ฝอย: 610203,
  หนองหญ้าปล้อง: 610204,
  โคกหม้อ: 610205,
  หนองยายดา: 610206,
  หนองกลางดง: 610207,
  หนองกระทุ่ม: 610208,
  หนองสระ: 610209,
  ตลุกดู่: 610210,
  สว่างอารมณ์: 610301,
  หนองหลวง: 610302,
  พลวงสองนาง: 610303,
  ไผ่เขียว: 610304,
  บ่อยาง: 610305,
  หนองฉาง: 610401,
  หนองยาง: 610402,
  หนองนางนวล: 610403,
  หนองสรวง: 610404,
  บ้านเก่า: 610405,
  อุทัยเก่า: 610406,
  ทุ่งโพ: 610407,
  ทุ่งพง: 610408,
  เขาบางแกรก: 610409,
  เขากวางทอง: 610410,
  หนองขาหย่าง: 610501,
  หนองไผ่: 610502,
  ดอนกลอย: 610503,
  ห้วยรอบ: 610504,
  ทุ่งพึ่ง: 610505,
  ท่าโพ: 610506,
  หมกแถว: 610507,
  หลุมเข้า: 610508,
  ดงขวาง: 610509,
  บ้านไร่: 610601,
  ทัพหลวง: 610602,
  ห้วยแห้ง: 610603,
  คอกควาย: 610604,
  วังหิน: 610605,
  เมืองการุ้ง: 610606,
  แก่นมะกรูด: 610607,
  หนองจอก: 610609,
  หูช้าง: 610610,
  บ้านบึง: 610611,
  บ้านใหม่คลองเคียน: 610612,
  หนองบ่มกล้วย: 610613,
  เจ้าวัด: 610614,
  ลานสัก: 610701,
  ประดู่ยืน: 610702,
  ป่าอ้อ: 610703,
  ระบำ: 610704,
  น้ำรอบ: 610705,
  ทุ่งนางาม: 610706,
  สุขฤทัย: 610801,
  ทองหลาง: 610802,
  ห้วยคต: 610803,
  ในเมือง: 620101,
  ไตรตรึงษ์: 620102,
  อ่างทอง: 620103,
  นาบ่อคำ: 620104,
  นครชุม: 620105,
  ทรงธรรม: 620106,
  ลานดอกไม้: 620107,
  หนองปลิง: 620110,
  คณฑี: 620111,
  นิคมทุ่งโพธิ์ทะเล: 620112,
  เทพนคร: 620113,
  วังทอง: 620114,
  ท่าขุนราม: 620115,
  คลองแม่ลาย: 620117,
  ธำมรงค์: 620118,
  สระแก้ว: 620119,
  ไทรงาม: 620201,
  หนองคล้า: 620202,
  หนองทอง: 620203,
  หนองไม้กอง: 620204,
  มหาชัย: 620205,
  พานทอง: 620206,
  หนองแม่แตง: 620207,
  คลองน้ำไหล: 620301,
  โป่งน้ำร้อน: 620302,
  คลองลานพัฒนา: 620303,
  สักงาม: 620304,
  ยางสูง: 620403,
  ป่าพุทรา: 620404,
  แสนตอ: 620405,
  สลกบาตร: 620406,
  บ่อถ้ำ: 620407,
  ดอนแตง: 620408,
  วังชะพลู: 620409,
  โค้งไผ่: 620410,
  ปางมะค่า: 620411,
  วังหามแห: 620412,
  เกาะตาล: 620413,
  คลองขลุง: 620501,
  ท่ามะเขือ: 620502,
  ท่าพุทรา: 620504,
  แม่ลาด: 620505,
  วังยาง: 620506,
  วังแขม: 620507,
  หัวถนน: 620508,
  วังไทร: 620509,
  วังบัว: 620513,
  คลองสมบูรณ์: 620516,
  พรานกระต่าย: 620601,
  หนองหัววัว: 620602,
  ท่าไม้: 620603,
  วังควง: 620604,
  วังตะแบก: 620605,
  เขาคีริส: 620606,
  คุยบ้านโอง: 620607,
  คลองพิไกร: 620608,
  ถ้ำกระต่ายทอง: 620609,
  ห้วยยั้ง: 620610,
  ลานกระบือ: 620701,
  ช่องลม: 620702,
  หนองหลวง: 620703,
  โนนพลวง: 620704,
  ประชาสุขสันต์: 620705,
  บึงทับแรต: 620706,
  จันทิมา: 620707,
  ทุ่งทราย: 620801,
  ทุ่งทอง: 620802,
  ถาวรวัฒนา: 620803,
  โพธิ์ทอง: 620901,
  หินดาต: 620902,
  ปางตาไว: 620903,
  บึงสามัคคี: 621001,
  วังชะโอน: 621002,
  ระหาน: 621003,
  เทพนิมิต: 621004,
  โกสัมพี: 621101,
  เพชรชมภู: 621102,
  ลานดอกไม้ตก: 621103,
  ระแหง: 630101,
  หนองหลวง: 630102,
  เชียงเงิน: 630103,
  หัวเดียด: 630104,
  หนองบัวเหนือ: 630105,
  ไม้งาม: 630106,
  โป่งแดง: 630107,
  น้ำรึม: 630108,
  วังหิน: 630109,
  แม่ท้อ: 630111,
  ป่ามะม่วง: 630112,
  หนองบัวใต้: 630113,
  วังประจบ: 630114,
  ตลุกกลางทุ่ง: 630115,
  ตากออก: 630201,
  สมอโคน: 630202,
  แม่สลิด: 630203,
  ตากตก: 630204,
  เกาะตะเภา: 630205,
  ทุ่งกระเชาะ: 630206,
  ท้องฟ้า: 630207,
  สามเงา: 630301,
  วังหมัน: 630302,
  ยกกระบัตร: 630303,
  ย่านรี: 630304,
  บ้านนา: 630305,
  วังจันทร์: 630306,
  แม่ระมาด: 630401,
  แม่จะเรา: 630402,
  ขะเนจื้อ: 630403,
  แม่ตื่น: 630404,
  สามหมื่น: 630405,
  พระธาตุ: 630406,
  ท่าสองยาง: 630501,
  แม่ต้าน: 630502,
  แม่สอง: 630503,
  แม่หละ: 630504,
  แม่วะหลวง: 630505,
  แม่อุสุ: 630506,
  แม่สอด: 630601,
  แม่กุ: 630602,
  พะวอ: 630603,
  แม่ตาว: 630604,
  แม่กาษา: 630605,
  ท่าสายลวด: 630606,
  แม่ปะ: 630607,
  มหาวัน: 630608,
  ด่านแม่ละเมา: 630609,
  พระธาตุผาแดง: 630610,
  พบพระ: 630701,
  ช่องแคบ: 630702,
  คีรีราษฎร์: 630703,
  วาเล่ย์: 630704,
  รวมไทยพัฒนา: 630705,
  อุ้มผาง: 630801,
  หนองหลวง: 630802,
  โมโกร: 630803,
  แม่จัน: 630804,
  แม่ละมุ้ง: 630805,
  แม่กลอง: 630806,
  เชียงทอง: 630901,
  นาโบสถ์: 630902,
  ประดาง: 630903,
  ธานี: 640101,
  บ้านสวน: 640102,
  เมืองเก่า: 640103,
  ปากแคว: 640104,
  ยางซ้าย: 640105,
  บ้านกล้วย: 640106,
  บ้านหลุม: 640107,
  ตาลเตี้ย: 640108,
  ปากพระ: 640109,
  วังทองแดง: 640110,
  ลานหอย: 640201,
  บ้านด่าน: 640202,
  วังตะคร้อ: 640203,
  วังน้ำขาว: 640204,
  ตลิ่งชัน: 640205,
  หนองหญ้าปล้อง: 640206,
  วังลึก: 640207,
  โตนด: 640301,
  ทุ่งหลวง: 640302,
  บ้านป้อม: 640303,
  สามพวง: 640304,
  ศรีคีรีมาศ: 640305,
  หนองจิก: 640306,
  นาเชิงคีรี: 640307,
  หนองกระดิ่ง: 640308,
  บ้านน้ำพุ: 640309,
  ทุ่งยางเมือง: 640310,
  กง: 640401,
  บ้านกร่าง: 640402,
  ไกรนอก: 640403,
  ไกรกลาง: 640404,
  ไกรใน: 640405,
  ดงเดือย: 640406,
  ป่าแฝก: 640407,
  กกแรต: 640408,
  ท่าฉนวน: 640409,
  หนองตูม: 640410,
  บ้านใหม่สุขเกษม: 640411,
  หาดเสี้ยว: 640501,
  ป่างิ้ว: 640502,
  แม่สำ: 640503,
  แม่สิน: 640504,
  บ้านตึก: 640505,
  หนองอ้อ: 640506,
  ท่าชัย: 640507,
  ศรีสัชนาลัย: 640508,
  ดงคู่: 640509,
  บ้านแก่ง: 640510,
  สารจิตร: 640511,
  คลองตาล: 640601,
  วังลึก: 640602,
  สามเรือน: 640603,
  บ้านนา: 640604,
  วังทอง: 640605,
  นาขุนไกร: 640606,
  เกาะตาเลี้ยง: 640607,
  วัดเกาะ: 640608,
  บ้านไร่: 640609,
  ทับผึ้ง: 640610,
  บ้านซ่าน: 640611,
  วังใหญ่: 640612,
  ราวต้นจันทร์: 640613,
  เมืองสวรรคโลก: 640701,
  ในเมือง: 640702,
  คลองกระจง: 640703,
  วังพิณพาทย์: 640704,
  วังไม้ขอน: 640705,
  ย่านยาว: 640706,
  นาทุ่ง: 640707,
  คลองยาง: 640708,
  เมืองบางยม: 640709,
  ท่าทอง: 640710,
  ปากน้ำ: 640711,
  ป่ากุมเกาะ: 640712,
  เมืองบางขลัง: 640713,
  หนองกลับ: 640714,
  ศรีนคร: 640801,
  นครเดิฐ: 640802,
  น้ำขุม: 640803,
  คลองมะพลับ: 640804,
  หนองบัว: 640805,
  บ้านใหม่ไชยมงคล: 640901,
  ไทยชนะศึก: 640902,
  ทุ่งเสลี่ยม: 640903,
  กลางดง: 640904,
  เขาแก้วศรีสมบูรณ์: 640905,
  ในเมือง: 650101,
  วังน้ำคู้: 650102,
  วัดจันทร์: 650103,
  วัดพริก: 650104,
  ท่าทอง: 650105,
  ท่าโพธิ์: 650106,
  สมอแข: 650107,
  ดอนทอง: 650108,
  บ้านป่า: 650109,
  ปากโทก: 650110,
  หัวรอ: 650111,
  จอมทอง: 650112,
  บ้านกร่าง: 650113,
  บ้านคลอง: 650114,
  พลายชุมพล: 650115,
  มะขามสูง: 650116,
  อรัญญิก: 650117,
  บึงพระ: 650118,
  ไผ่ขอดอน: 650119,
  งิ้วงาม: 650120,
  นครไทย: 650201,
  หนองกะท้าว: 650202,
  บ้านแยง: 650203,
  เนินเพิ่ม: 650204,
  นาบัว: 650205,
  นครชุม: 650206,
  น้ำกุ่ม: 650207,
  ยางโกลน: 650208,
  บ่อโพธิ์: 650209,
  บ้านพร้าว: 650210,
  ห้วยเฮี้ย: 650211,
  ป่าแดง: 650301,
  ชาติตระการ: 650302,
  สวนเมี่ยง: 650303,
  บ้านดง: 650304,
  บ่อภาค: 650305,
  ท่าสะแก: 650306,
  บางระกำ: 650401,
  ปลักแรด: 650402,
  พันเสา: 650403,
  วังอิทก: 650404,
  บึงกอก: 650405,
  หนองกุลา: 650406,
  ชุมแสงสงคราม: 650407,
  นิคมพัฒนา: 650408,
  บ่อทอง: 650409,
  ท่านางงาม: 650410,
  คุยม่วง: 650411,
  บางกระทุ่ม: 650501,
  บ้านไร่: 650502,
  โคกสลุด: 650503,
  สนามคลี: 650504,
  ท่าตาล: 650505,
  ไผ่ล้อม: 650506,
  นครป่าหมาก: 650507,
  เนินกุ่ม: 650508,
  วัดตายม: 650509,
  พรหมพิราม: 650601,
  ท่าช้าง: 650602,
  วงฆ้อง: 650603,
  มะตูม: 650604,
  หอกลอง: 650605,
  ศรีภิรมย์: 650606,
  ตลุกเทียม: 650607,
  วังวน: 650608,
  หนองแขม: 650609,
  มะต้อง: 650610,
  ทับยายเชียง: 650611,
  ดงประคำ: 650612,
  วัดโบสถ์: 650701,
  ท่างาม: 650702,
  ท้อแท้: 650703,
  บ้านยาง: 650704,
  หินลาด: 650705,
  คันโช้ง: 650706,
  วังทอง: 650801,
  พันชาลี: 650802,
  แม่ระกา: 650803,
  บ้านกลาง: 650804,
  วังพิกุล: 650805,
  แก่งโสภา: 650806,
  ท่าหมื่นราม: 650807,
  วังนกแอ่น: 650808,
  หนองพระ: 650809,
  ชัยนาม: 650810,
  ดินทอง: 650811,
  ชมพู: 650901,
  บ้านมุง: 650902,
  ไทรย้อย: 650903,
  วังโพรง: 650904,
  บ้านน้อยซุ้มขี้เหล็ก: 650905,
  เนินมะปราง: 650906,
  วังยาง: 650907,
  โคกแหลม: 650908,
  ในเมือง: 660101,
  ไผ่ขวาง: 660102,
  ย่านยาว: 660103,
  ท่าฬ่อ: 660104,
  ปากทาง: 660105,
  คลองคะเชนทร์: 660106,
  โรงช้าง: 660107,
  เมืองเก่า: 660108,
  ท่าหลวง: 660109,
  บ้านบุ่ง: 660110,
  ฆะมัง: 660111,
  ดงป่าคำ: 660112,
  หัวดง: 660113,
  ป่ามะคาบ: 660115,
  สายคำโห้: 660119,
  ดงกลาง: 660120,
  วังทรายพูน: 660201,
  หนองปลาไหล: 660202,
  หนองพระ: 660203,
  หนองปล้อง: 660204,
  โพธิ์ประทับช้าง: 660301,
  ไผ่ท่าโพ: 660302,
  วังจิก: 660303,
  ไผ่รอบ: 660304,
  ดงเสือเหลือง: 660305,
  เนินสว่าง: 660306,
  ทุ่งใหญ่: 660307,
  ตะพานหิน: 660401,
  งิ้วราย: 660402,
  ห้วยเกตุ: 660403,
  ไทรโรงโขน: 660404,
  หนองพยอม: 660405,
  ทุ่งโพธิ์: 660406,
  ดงตะขบ: 660407,
  คลองคูณ: 660408,
  วังสำโรง: 660409,
  วังหว้า: 660410,
  วังหลุม: 660411,
  ทับหมัน: 660412,
  ไผ่หลวง: 660413,
  บางมูลนาก: 660501,
  บางไผ่: 660502,
  หอไกร: 660503,
  เนินมะกอก: 660504,
  วังสำโรง: 660505,
  ภูมิ: 660506,
  วังกรด: 660507,
  ห้วยเขน: 660508,
  วังตะกู: 660509,
  ลำประดา: 660514,
  โพทะเล: 660601,
  ท้ายน้ำ: 660602,
  ทะนง: 660603,
  ท่าบัว: 660604,
  ทุ่งน้อย: 660605,
  ท่าขมิ้น: 660606,
  ท่าเสา: 660607,
  บางคลาน: 660608,
  ท่านั่ง: 660611,
  บ้านน้อย: 660612,
  วัดขวาง: 660613,
  สามง่าม: 660701,
  กำแพงดิน: 660702,
  รังนก: 660703,
  เนินปอ: 660706,
  หนองโสน: 660707,
  ทับคล้อ: 660801,
  เขาทราย: 660802,
  เขาเจ็ดลูก: 660803,
  ท้ายทุ่ง: 660804,
  สากเหล็ก: 660901,
  ท่าเยี่ยม: 660902,
  คลองทราย: 660903,
  หนองหญ้าไทร: 660904,
  วังทับไทร: 660905,
  ห้วยแก้ว: 661001,
  โพธิ์ไทรงาม: 661002,
  แหลมรัง: 661003,
  บางลาย: 661004,
  บึงนาราง: 661005,
  วังงิ้วใต้: 661101,
  วังงิ้ว: 661102,
  ห้วยร่วม: 661103,
  ห้วยพุก: 661104,
  สำนักขุนเณร: 661105,
  บ้านนา: 661201,
  บึงบัว: 661202,
  วังโมกข์: 661203,
  หนองหลุม: 661204,
  ในเมือง: 670101,
  ตะเบาะ: 670102,
  บ้านโตก: 670103,
  สะเดียง: 670104,
  ป่าเลา: 670105,
  นางั่ว: 670106,
  ท่าพล: 670107,
  ดงมูลเหล็ก: 670108,
  บ้านโคก: 670109,
  ชอนไพร: 670110,
  นาป่า: 670111,
  นายม: 670112,
  วังชมภู: 670113,
  น้ำร้อน: 670114,
  ห้วยสะแก: 670115,
  ห้วยใหญ่: 670116,
  ระวิง: 670117,
  ชนแดน: 670201,
  ดงขุย: 670202,
  ท่าข้าม: 670203,
  พุทธบาท: 670204,
  ลาดแค: 670205,
  บ้านกล้วย: 670206,
  ซับพุทรา: 670208,
  ตะกุดไร: 670209,
  ศาลาลาย: 670210,
  หล่มสัก: 670301,
  วัดป่า: 670302,
  ตาลเดี่ยว: 670303,
  ฝายนาแซง: 670304,
  หนองสว่าง: 670305,
  น้ำเฮี้ย: 670306,
  สักหลง: 670307,
  ท่าอิบุญ: 670308,
  บ้านโสก: 670309,
  บ้านติ้ว: 670310,
  ห้วยไร่: 670311,
  น้ำก้อ: 670312,
  ปากช่อง: 670313,
  น้ำชุน: 670314,
  หนองไขว่: 670315,
  ลานบ่า: 670316,
  บุ่งคล้า: 670317,
  บุ่งน้ำเต้า: 670318,
  บ้านกลาง: 670319,
  ช้างตะลูด: 670320,
  บ้านไร่: 670321,
  ปากดุก: 670322,
  บ้านหวาย: 670323,
  หล่มเก่า: 670401,
  นาซำ: 670402,
  หินฮาว: 670403,
  บ้านเนิน: 670404,
  ศิลา: 670405,
  นาแซง: 670406,
  วังบาล: 670407,
  นาเกาะ: 670408,
  ตาดกลอย: 670409,
  ท่าโรง: 670501,
  สระประดู่: 670502,
  สามแยก: 670503,
  โคกปรง: 670504,
  น้ำร้อน: 670505,
  บ่อรัง: 670506,
  พุเตย: 670507,
  พุขาม: 670508,
  ภูน้ำหยด: 670509,
  ซับสมบูรณ์: 670510,
  บึงกระจับ: 670511,
  วังใหญ่: 670512,
  ยางสาว: 670513,
  ซับน้อย: 670514,
  ศรีเทพ: 670601,
  สระกรวด: 670602,
  คลองกระจัง: 670603,
  นาสนุ่น: 670604,
  โคกสะอาด: 670605,
  หนองย่างทอย: 670606,
  ประดู่งาม: 670607,
  กองทูล: 670701,
  นาเฉลียง: 670702,
  บ้านโภชน์: 670703,
  ท่าแดง: 670704,
  เพชรละคร: 670705,
  บ่อไทย: 670706,
  ห้วยโป่ง: 670707,
  วังท่าดี: 670708,
  บัววัฒนา: 670709,
  หนองไผ่: 670710,
  วังโบสถ์: 670711,
  ยางงาม: 670712,
  ท่าด้วง: 670713,
  ซับสมอทอด: 670801,
  เวียง: 570301,
  สถาน: 570302,
  ครึ่ง: 570303,
  บุญเรือง: 570304,
  ห้วยซ้อ: 570305,
  ศรีดอนชัย: 570308,
  ริมโขง: 570310,
  เวียง: 570401,
  งิ้ว: 570402,
  ปล้อง: 570403,
  แม่ลอย: 570404,
  เชียงเคี่ยน: 570405,
  ตับเต่า: 570409,
  หงาว: 570410,
  สันทรายงาม: 570411,
  ศรีดอนไชย: 570412,
  หนองแรด: 570413,
  'ยางฮอม*': 570497,
  สันมะเค็ด: 570501,
  แม่อ้อ: 570502,
  ธารทอง: 570503,
  สันติสุข: 570504,
  ดอยงาม: 570505,
  หัวง้ม: 570506,
  เจริญเมือง: 570507,
  ป่าหุ่ง: 570508,
  ม่วงคำ: 570509,
  ทรายขาว: 570510,
  สันกลาง: 570511,
  แม่เย็น: 570512,
  เมืองพาน: 570513,
  ทานตะวัน: 570514,
  เวียงห้าว: 570515,
  ป่าแดด: 570601,
  ป่าแงะ: 570602,
  สันมะค่า: 570603,
  โรงช้าง: 570605,
  ศรีโพธิ์เงิน: 570606,
  แม่จัน: 570701,
  จันจว้า: 570702,
  แม่คำ: 570703,
  ป่าซาง: 570704,
  สันทราย: 570705,
  ท่าข้าวเปลือก: 570706,
  ป่าตึง: 570708,
  แม่ไร่: 570710,
  ศรีค้ำ: 570711,
  จันจว้าใต้: 570712,
  จอมสวรรค์: 570713,
  เวียง: 570801,
  ป่าสัก: 570802,
  บ้านแซว: 570803,
  ศรีดอนมูล: 570804,
  แม่เงิน: 570805,
  โยนก: 570806,
  แม่สาย: 570901,
  ห้วยไคร้: 570902,
  เกาะช้าง: 570903,
  โป่งผา: 570904,
  ศรีเมืองชุม: 570905,
  เวียงพางคำ: 570906,
  บ้านด้าย: 570908,
  โป่งงาม: 570909,
  แม่สรวย: 571001,
  ป่าแดด: 571002,
  แม่พริก: 571003,
  ศรีถ้อย: 571004,
  ท่าก๊อ: 571005,
  วาวี: 571006,
  เจดีย์หลวง: 571007,
  สันสลี: 571101,
  เวียง: 571102,
  บ้านโป่ง: 571103,
  ป่างิ้ว: 571104,
  เวียงกาหลง: 571105,
  แม่เจดีย์: 571106,
  แม่เจดีย์ใหม่: 571107,
  แม่เปา: 571201,
  แม่ต๋ำ: 571202,
  ไม้ยา: 571203,
  เม็งราย: 571204,
  ตาดควัน: 571205,
  ม่วงยาย: 571301,
  ปอ: 571302,
  หล่ายงาว: 571303,
  ท่าข้าม: 571304,
  ต้า: 571401,
  ป่าตาล: 571402,
  ยางฮอม: 571403,
  เทอดไทย: 571501,
  แม่สลองใน: 571502,
  แม่สลองนอก: 571503,
  แม่ฟ้าหลวง: 571504,
  ดงมะดะ: 571601,
  จอมหมอกแก้ว: 571602,
  บัวสลี: 571603,
  ป่าก่อดำ: 571604,
  โป่งแพร่: 571605,
  ทุ่งก่อ: 571701,
  ดงมหาวัน: 571702,
  ป่าซาง: 571703,
  ปงน้อย: 571801,
  โชคชัย: 571802,
  หนองป่าก่อ: 571803,
  จองคำ: 580101,
  ห้วยโป่ง: 580102,
  ผาบ่อง: 580103,
  ปางหมู: 580104,
  หมอกจำแป่: 580105,
  ห้วยผา: 580106,
  ห้วยปูลิง: 580109,
  ขุนยวม: 580201,
  แม่เงา: 580202,
  เมืองปอน: 580203,
  แม่ยวมน้อย: 580204,
  แม่กิ๊: 580205,
  แม่อูคอ: 580206,
  เวียงใต้: 580301,
  เวียงเหนือ: 580302,
  แม่นาเติง: 580303,
  แม่ฮี้: 580304,
  ทุ่งยาว: 580305,
  เมืองแปง: 580306,
  โป่งสา: 580307,
  บ้านกาศ: 580401,
  แม่สะเรียง: 580402,
  แม่คง: 580403,
  แม่เหาะ: 580404,
  แม่ยวม: 580405,
  เสาหิน: 580406,
  ป่าแป๋: 580408,
  แม่ลาน้อย: 580501,
  แม่ลาหลวง: 580502,
  ท่าผาปุ้ม: 580503,
  แม่โถ: 580504,
  ห้วยห้อม: 580505,
  แม่นาจาง: 580506,
  สันติคีรี: 580507,
  ขุนแม่ลาน้อย: 580508,
  สบเมย: 580601,
  แม่คะตวน: 580602,
  กองก๋อย: 580603,
  แม่สวด: 580604,
  ป่าโปง: 580605,
  แม่สามแลบ: 580606,
  สบป่อง: 580701,
  ปางมะผ้า: 580702,
  ถ้ำลอด: 580703,
  นาปู่ป้อม: 580704,
  ปากน้ำโพ: 600101,
  กลางแดด: 600102,
  เกรียงไกร: 600103,
  แควใหญ่: 600104,
  ตะเคียนเลื่อน: 600105,
  นครสวรรค์ตก: 600106,
  นครสวรรค์ออก: 600107,
  บางพระหลวง: 600108,
  บางม่วง: 600109,
  บ้านมะเกลือ: 600110,
  บ้านแก่ง: 600111,
  พระนอน: 600112,
  วัดไทร: 600113,
  หนองกรด: 600114,
  หนองกระโดน: 600115,
  หนองปลิง: 600116,
  บึงเสนาท: 600117,
  โกรกพระ: 600201,
  ยางตาล: 600202,
  บางมะฝ่อ: 600203,
  บางประมุง: 600204,
  นากลาง: 600205,
  ศาลาแดง: 600206,
  เนินกว้าว: 600207,
  เนินศาลา: 600208,
  หาดสูง: 600209,
  ชุมแสง: 600301,
  ทับกฤช: 600302,
  พิกุล: 600303,
  เกยไชย: 600304,
  ท่าไม้: 600305,
  บางเคียน: 600306,
  หนองกระเจา: 600307,
  พันลาน: 600308,
  โคกหม้อ: 600309,
  ไผ่สิงห์: 600310,
  ฆะมัง: 600311,
  ทับกฤชใต้: 600312,
  หนองบัว: 600401,
  หนองกลับ: 600402,
  ธารทหาร: 600403,
  ห้วยร่วม: 600404,
  ห้วยถั่วใต้: 600405,
  ห้วยถั่วเหนือ: 600406,
  ห้วยใหญ่: 600407,
  ทุ่งทอง: 600408,
  วังบ่อ: 600409,
  ท่างิ้ว: 600501,
  บางตาหงาย: 600502,
  หูกวาง: 600503,
  อ่างทอง: 600504,
  บ้านแดน: 600505,
  บางแก้ว: 600506,
  ตาขีด: 600507,
  ตาสัง: 600508,
  ด่านช้าง: 600509,
  หนองกรด: 600510,
  หนองตางู: 600511,
  บึงปลาทู: 600512,
  เจริญผล: 600513,
  มหาโพธิ: 600601,
  เก้าเลี้ยว: 600602,
  หนองเต่า: 600603,
  เขาดิน: 600604,
  หัวดง: 600605,
  ตาคลี: 600701,
  ช่องแค: 600702,
  จันเสน: 600703,
  ห้วยหอม: 600704,
  หัวหวาย: 600705,
  หนองโพ: 600706,
  หนองหม้อ: 600707,
  สร้อยทอง: 600708,
  ลาดทิพรส: 600709,
  พรหมนิมิต: 600710,
  ท่าตะโก: 600801,
  พนมรอก: 600802,
  หัวถนน: 600803,
  สายลำโพง: 600804,
  วังมหากร: 600805,
  ดอนคา: 600806,
  ทำนบ: 600807,
  วังใหญ่: 600808,
  พนมเศษ: 600809,
  หนองหลวง: 600810,
  โคกเดื่อ: 600901,
  สำโรงชัย: 600902,
  วังน้ำลัด: 600903,
  ตะคร้อ: 600904,
  โพธิ์ประสาท: 600905,
  วังข่อย: 600906,
  นาขอม: 600907,
  ไพศาลี: 600908,
  พยุหะ: 601001,
  เนินมะกอก: 601002,
  นิคมเขาบ่อแก้ว: 601003,
  ม่วงหัก: 601004,
  ยางขาว: 601005,
  ย่านมัทรี: 601006,
  เขาทอง: 601007,
  ท่าน้ำอ้อย: 601008,
  น้ำทรง: 601009,
  เขากะลา: 601010,
  สระทะเล: 601011,
  ลาดยาว: 601101,
  ห้วยน้ำหอม: 601102,
  วังม้า: 601103,
  วังเมือง: 601104,
  สร้อยละคร: 601105,
  มาบแก: 601106,
  หนองยาว: 601107,
  หนองนมวัว: 601108,
  บ้านไร่: 601109,
  เนินขี้เหล็ก: 601110,
  ศาลเจ้าไก่ต่อ: 601116,
  สระแก้ว: 601117,
  ตากฟ้า: 601201,
  ลำพยนต์: 601202,
  สุขสำราญ: 601203,
  หนองพิกุล: 601204,
  พุนกยูง: 601205,
  อุดมธัญญา: 601206,
  เขาชายธง: 601207,
  แม่วงก์: 601301,
  แม่เล่ย์: 601303,
  วังซ่าน: 601304,
  เขาชนกัน: 601305,
  แม่เปิน: 601401,
  ชุมตาบง: 601501,
  ปางสวรรค์: 601502,
  อุทัยใหม่: 610101,
  น้ำซึม: 610102,
  สะแกกรัง: 610103,
  ดอนขวาง: 610104,
  หาดทนง: 610105,
  เกาะเทโพ: 610106,
  ท่าซุง: 610107,
  สามขา: 451302,
  ศรีสว่าง: 451303,
  ยางคำ: 451304,
  ท่าหาดยาว: 451305,
  อาจสามารถ: 451401,
  โพนเมือง: 451402,
  บ้านแจ้ง: 451403,
  หน่อม: 451404,
  หนองหมื่นถ่าน: 451405,
  หนองขาม: 451406,
  โหรา: 451407,
  หนองบัว: 451408,
  ขี้เหล็ก: 451409,
  บ้านดู่: 451410,
  เมยวดี: 451501,
  ชุมพร: 451502,
  บุ่งเลิศ: 451503,
  ชมสะอาด: 451504,
  โพธิ์ทอง: 451601,
  ศรีสมเด็จ: 451602,
  เมืองเปลือย: 451603,
  หนองใหญ่: 451604,
  สวนจิก: 451605,
  โพธิ์สัย: 451606,
  หนองแวงควง: 451607,
  บ้านบาก: 451608,
  ดินดำ: 451701,
  ปาฝา: 451702,
  ม่วงลาด: 451703,
  จังหาร: 451704,
  ดงสิงห์: 451705,
  ยางใหญ่: 451706,
  ผักแว่น: 451707,
  แสนชาติ: 451708,
  เชียงขวัญ: 451801,
  พลับพลา: 451802,
  พระธาตุ: 451803,
  พระเจ้า: 451804,
  หมูม้น: 451805,
  บ้านเขือง: 451806,
  หนองฮี: 451901,
  สาวแห: 451902,
  ดูกอึ่ง: 451903,
  เด่นราษฎร์: 451904,
  ทุ่งเขาหลวง: 452001,
  เทอดไทย: 452002,
  บึงงาม: 452003,
  มะบ้า: 452004,
  เหล่า: 452005,
  กาฬสินธุ์: 460101,
  เหนือ: 460102,
  หลุบ: 460103,
  ไผ่: 460104,
  ลำปาว: 460105,
  ลำพาน: 460106,
  เชียงเครือ: 460107,
  บึงวิชัย: 460108,
  ห้วยโพธิ์: 460109,
  ภูปอ: 460111,
  ภูดิน: 460113,
  หนองกุง: 460115,
  กลางหมื่น: 460116,
  ขมิ้น: 460117,
  โพนทอง: 460119,
  นาจารย์: 460120,
  ลำคลอง: 460121,
  'นามน*': 460198,
  'ยอดแกง*': 460199,
  นามน: 460201,
  ยอดแกง: 460202,
  สงเปลือย: 460203,
  หลักเหลี่ยม: 460204,
  หนองบัว: 460205,
  กมลาไสย: 460301,
  หลักเมือง: 460302,
  โพนงาม: 460303,
  ดงลิง: 460304,
  ธัญญา: 460305,
  หนองแปน: 460308,
  เจ้าท่า: 460310,
  โคกสมบูรณ์: 460311,
  ร่องคำ: 460401,
  สามัคคี: 460402,
  เหล่าอ้อย: 460403,
  บัวขาว: 460501,
  แจนแลน: 460502,
  เหล่าใหญ่: 460503,
  จุมจัง: 460504,
  เหล่าไฮงาม: 460505,
  กุดหว้า: 460506,
  สามขา: 460507,
  นาขาม: 460508,
  หนองห้าง: 460509,
  นาโก: 460510,
  สมสะอาด: 460511,
  กุดค้าว: 460512,
  คุ้มเก่า: 460601,
  สงเปลือย: 460602,
  หนองผือ: 460603,
  กุดสิมคุ้มใหม่: 460606,
  สระพังทอง: 460608,
  กุดปลาค้าว: 460611,
  ยางตลาด: 460701,
  หัวงัว: 460702,
  อุ่มเม่า: 460703,
  บัวบาน: 460704,
  เว่อ: 460705,
  อิตื้อ: 460706,
  หัวนาคำ: 460707,
  หนองอิเฒ่า: 460708,
  ดอนสมบูรณ์: 460709,
  นาเชือก: 460710,
  คลองขาม: 460711,
  เขาพระนอน: 460712,
  นาดี: 460713,
  โนนสูง: 460714,
  หนองตอกแป้น: 460715,
  ห้วยเม็ก: 460801,
  คำใหญ่: 460802,
  กุดโดน: 460803,
  บึงนาเรียง: 460804,
  หัวหิน: 460805,
  พิมูล: 460806,
  คำเหมือดแก้ว: 460807,
  โนนสะอาด: 460808,
  ทรายทอง: 460809,
  ภูสิงห์: 460901,
  สหัสขันธ์: 460902,
  นามะเขือ: 460903,
  โนนศิลา: 460904,
  นิคม: 460905,
  โนนแหลมทอง: 460906,
  โนนบุรี: 460907,
  โนนน้ำเกลี้ยง: 460908,
  ทุ่งคลอง: 461001,
  โพน: 461002,
  ดินจี่: 461005,
  นาบอน: 461006,
  นาทัน: 461007,
  เนินยาง: 461009,
  ท่าคันโท: 461101,
  กุงเก่า: 461102,
  ยางอู้ม: 461103,
  กุดจิก: 461104,
  นาตาล: 461105,
  ดงสมบูรณ์: 461106,
  หนองกุงศรี: 461201,
  หนองบัว: 461202,
  โคกเครือ: 461203,
  หนองสรวง: 461204,
  เสาเล้า: 461205,
  หนองใหญ่: 461206,
  ดงมูล: 461207,
  ลำหนองแสน: 461208,
  หนองหิน: 461209,
  สมเด็จ: 461301,
  หนองแวง: 461302,
  แซงบาดาล: 461303,
  มหาไชย: 461304,
  หมูม่น: 461305,
  ผาเสวย: 461306,
  ศรีสมเด็จ: 461307,
  ลำห้วยหลัว: 461308,
  คำบง: 461401,
  ไค้นุ่น: 461402,
  นิคมห้วยผึ้ง: 461403,
  หนองอีบุตร: 461404,
  สำราญ: 461501,
  สำราญใต้: 461502,
  คำสร้างเที่ยง: 461503,
  หนองช้าง: 461504,
  นาคู: 461601,
  สายนาวัง: 461602,
  โนนนาจาน: 461603,
  บ่อแก้ว: 461604,
  ภูแล่นช้าง: 461605,
  ดอนจาน: 461701,
  สะอาดไชยศรี: 461702,
  ดงพยุง: 461703,
  ม่วงนา: 461704,
  นาจำปา: 461705,
  ฆ้องชัยพัฒนา: 461801,
  เหล่ากลาง: 461802,
  โคกสะอาด: 461803,
  โนนศิลาเลิง: 461804,
  ลำชี: 461805,
  ธาตุเชิงชุม: 470101,
  ขมิ้น: 470102,
  งิ้วด่อน: 470103,
  โนนหอม: 470104,
  เชียงเครือ: 470106,
  ท่าแร่: 470107,
  ม่วงลาย: 470109,
  ดงชน: 470111,
  ห้วยยาง: 470112,
  พังขว้าง: 470113,
  ดงมะไฟ: 470115,
  ธาตุนาเวง: 470116,
  เหล่าปอแดง: 470117,
  หนองลาด: 470118,
  ฮางโฮง: 470120,
  โคกก่อง: 470121,
  กุสุมาลย์: 470201,
  นาโพธิ์: 470202,
  นาเพียง: 470203,
  โพธิไพศาล: 470204,
  อุ่มจาน: 470205,
  กุดบาก: 470301,
  นาม่อง: 470303,
  กุดไห: 470305,
  พรรณา: 470401,
  วังยาง: 470402,
  พอกน้อย: 470403,
  นาหัวบ่อ: 470404,
  ไร่: 470405,
  ช้างมิ่ง: 470406,
  นาใน: 470407,
  สว่าง: 470408,
  บะฮี: 470409,
  เชิงชุม: 470410,
  พังโคน: 470501,
  ม่วงไข่: 470502,
  แร่: 470503,
  ไฮหย่อง: 470504,
  ต้นผึ้ง: 470505,
  วาริชภูมิ: 470601,
  ปลาโหล: 470602,
  หนองลาด: 470603,
  คำบ่อ: 470604,
  ค้อเขียว: 470605,
  นิคมน้ำอูน: 470701,
  หนองปลิง: 470702,
  หนองบัว: 470703,
  สุวรรณคาม: 470704,
  วานรนิวาส: 470801,
  เดื่อศรีคันไชย: 470802,
  ขัวก่าย: 470803,
  หนองสนม: 470804,
  คูสะคาม: 470805,
  ธาตุ: 470806,
  หนองแวง: 470807,
  ศรีวิชัย: 470808,
  นาซอ: 470809,
  อินทร์แปลง: 470810,
  นาคำ: 470811,
  คอนสวรรค์: 470812,
  กุดเรือคำ: 470813,
  หนองแวงใต้: 470814,
  คำตากล้า: 470901,
  หนองบัวสิม: 470902,
  นาแต้: 470903,
  แพด: 470904,
  ม่วง: 471001,
  มาย: 471002,
  ดงหม้อทอง: 471003,
  ดงเหนือ: 471004,
  ดงหม้อทองใต้: 471005,
  ห้วยหลัว: 471006,
  โนนสะอาด: 471007,
  หนองกวั่ง: 471008,
  บ่อแก้ว: 471009,
  อากาศ: 471101,
  โพนแพง: 471102,
  วาใหญ่: 471103,
  โพนงาม: 471104,
  ท่าก้อน: 471105,
  นาฮี: 471106,
  บะหว้า: 471107,
  สามัคคีพัฒนา: 471108,
  สว่างแดนดิน: 471201,
  คำสะอาด: 471203,
  บ้านต้าย: 471204,
  บงเหนือ: 471206,
  โพนสูง: 471207,
  โคกสี: 471208,
  หนองหลวง: 471210,
  บงใต้: 471211,
  ค้อใต้: 471212,
  พันนา: 471213,
  แวง: 471214,
  ทรายมูล: 471215,
  ตาลโกน: 471216,
  ตาลเนิ้ง: 471217,
  ธาตุทอง: 471220,
  บ้านถ่อน: 471221,
  ส่องดาว: 471301,
  ท่าศิลา: 471302,
  วัฒนา: 471303,
  ยุหว่า: 501201,
  สันกลาง: 501202,
  ท่าวังพร้าว: 501203,
  มะขามหลวง: 501204,
  แม่ก๊า: 501205,
  บ้านแม: 501206,
  บ้านกลาง: 501207,
  ทุ่งสะโตก: 501208,
  ทุ่งต้อม: 501210,
  น้ำบ่อหลวง: 501214,
  มะขุนหวาน: 501215,
  สันกำแพง: 501301,
  ทรายมูล: 501302,
  ร้องวัวแดง: 501303,
  บวกค้าง: 501304,
  แช่ช้าง: 501305,
  ออนใต้: 501306,
  แม่ปูคา: 501310,
  ห้วยทราย: 501311,
  ต้นเปา: 501312,
  สันกลาง: 501313,
  สันทรายหลวง: 501401,
  สันทรายน้อย: 501402,
  สันพระเนตร: 501403,
  สันนาเม็ง: 501404,
  สันป่าเปา: 501405,
  หนองแหย่ง: 501406,
  หนองจ๊อม: 501407,
  หนองหาร: 501408,
  แม่แฝก: 501409,
  แม่แฝกใหม่: 501410,
  เมืองเล็น: 501411,
  ป่าไผ่: 501412,
  หางดง: 501501,
  หนองแก๋ว: 501502,
  หารแก้ว: 501503,
  หนองตอง: 501504,
  ขุนคง: 501505,
  สบแม่ข่า: 501506,
  บ้านแหวน: 501507,
  สันผักหวาน: 501508,
  หนองควาย: 501509,
  บ้านปง: 501510,
  น้ำแพร่: 501511,
  หางดง: 501601,
  ฮอด: 501602,
  บ้านตาล: 501603,
  บ่อหลวง: 501604,
  บ่อสลี: 501605,
  นาคอเรือ: 501606,
  ดอยเต่า: 501701,
  ท่าเดื่อ: 501702,
  มืดกา: 501703,
  บ้านแอ่น: 501704,
  บงตัน: 501705,
  โปงทุ่ง: 501706,
  อมก๋อย: 501801,
  ยางเปียง: 501802,
  แม่ตื่น: 501803,
  ม่อนจอง: 501804,
  สบโขง: 501805,
  นาเกียน: 501806,
  ยางเนิ้ง: 501901,
  สารภี: 501902,
  ชมภู: 501903,
  ไชยสถาน: 501904,
  ขัวมุง: 501905,
  หนองแฝก: 501906,
  หนองผึ้ง: 501907,
  ท่ากว้าง: 501908,
  ดอนแก้ว: 501909,
  ท่าวังตาล: 501910,
  สันทราย: 501911,
  ป่าบง: 501912,
  เมืองแหง: 502001,
  เปียงหลวง: 502002,
  แสนไห: 502003,
  ปงตำ: 502101,
  ศรีดงเย็น: 502102,
  แม่ทะลบ: 502103,
  หนองบัว: 502104,
  บ้านกาด: 502201,
  ทุ่งปี้: 502202,
  ทุ่งรวงทอง: 502203,
  แม่วิน: 502204,
  ดอนเปา: 502205,
  ออนเหนือ: 502301,
  ออนกลาง: 502302,
  บ้านสหกรณ์: 502303,
  ห้วยแก้ว: 502304,
  แม่ทา: 502305,
  ทาเหนือ: 502306,
  ดอยหล่อ: 502401,
  สองแคว: 502402,
  ยางคราม: 502403,
  สันติสุข: 502404,
  ในเมือง: 510101,
  เหมืองง่า: 510102,
  อุโมงค์: 510103,
  หนองช้างคืน: 510104,
  ประตูป่า: 510105,
  ริมปิง: 510106,
  ต้นธง: 510107,
  บ้านแป้น: 510108,
  เหมืองจี้: 510109,
  ป่าสัก: 510110,
  เวียงยอง: 510111,
  บ้านกลาง: 510112,
  มะเขือแจ้: 510113,
  ศรีบัวบาน: 510116,
  หนองหนาม: 510117,
  ทาปลาดุก: 510201,
  ทาสบเส้า: 510202,
  ทากาศ: 510203,
  ทาขุมเงิน: 510204,
  ทาทุ่งหลวง: 510205,
  ทาแม่ลอบ: 510206,
  บ้านโฮ่ง: 510301,
  ป่าพลู: 510302,
  เหล่ายาว: 510303,
  ศรีเตี้ย: 510304,
  หนองปลาสะวาย: 510305,
  ลี้: 510401,
  แม่ตืน: 510402,
  นาทราย: 510403,
  ดงดำ: 510404,
  ก้อ: 510405,
  แม่ลาน: 510406,
  ป่าไผ่: 510408,
  ศรีวิชัย: 510409,
  ทุ่งหัวช้าง: 510501,
  บ้านปวง: 510502,
  ตะเคียนปม: 510503,
  ปากบ่อง: 510601,
  ป่าซาง: 510602,
  แม่แรง: 510603,
  ม่วงน้อย: 510604,
  บ้านเรือน: 510605,
  มะกอก: 510606,
  ท่าตุ้ม: 510607,
  น้ำดิบ: 510608,
  นครเจดีย์: 510611,
  บ้านธิ: 510701,
  ห้วยยาบ: 510702,
  หนองล่อง: 510801,
  หนองยวง: 510802,
  วังผาง: 510803,
  เวียงเหนือ: 520101,
  หัวเวียง: 520102,
  สวนดอก: 520103,
  สบตุ๋ย: 520104,
  พระบาท: 520105,
  ชมพู: 520106,
  กล้วยแพะ: 520107,
  ปงแสนทอง: 520108,
  บ้านแลง: 520109,
  บ้านเสด็จ: 520110,
  พิชัย: 520111,
  ทุ่งฝาย: 520112,
  บ้านเอื้อม: 520113,
  บ้านเป้า: 520114,
  บ้านค่า: 520115,
  บ่อแฮ้ว: 520116,
  ต้นธงชัย: 520117,
  นิคมพัฒนา: 520118,
  บุญนาคพัฒนา: 520119,
  บ้านดง: 520201,
  นาสัก: 520202,
  จางเหนือ: 520203,
  แม่เมาะ: 520204,
  สบป้าด: 520205,
  ลำปางหลวง: 520301,
  นาแก้ว: 520302,
  ไหล่หิน: 520303,
  วังพร้าว: 520304,
  ศาลา: 520305,
  เกาะคา: 520306,
  นาแส่ง: 520307,
  ท่าผา: 520308,
  ใหม่พัฒนา: 520309,
  ทุ่งงาม: 520401,
  เสริมขวา: 520402,
  เสริมซ้าย: 520403,
  เสริมกลาง: 520404,
  หลวงเหนือ: 520501,
  หลวงใต้: 520502,
  บ้านโป่ง: 520503,
  บ้านร้อง: 520504,
  ปงเตา: 520505,
  นาแก: 520506,
  บ้านอ้อน: 520507,
  บ้านแหง: 520508,
  บ้านหวด: 520509,
  แม่ตีบ: 520510,
  แจ้ห่ม: 520601,
  บ้านสา: 520602,
  ปงดอน: 520603,
  แม่สุก: 520604,
  เมืองมาย: 520605,
  ทุ่งผึ้ง: 520606,
  วิเชตนคร: 520607,
  ทุ่งฮั้ว: 520701,
  วังเหนือ: 520702,
  วังใต้: 520703,
  ร่องเคาะ: 520704,
  วังทอง: 520705,
  วังซ้าย: 520706,
  วังแก้ว: 520707,
  วังทรายคำ: 520708,
  ล้อมแรด: 520801,
  แม่วะ: 520802,
  แม่ปะ: 520803,
  แม่มอก: 520804,
  เวียงมอก: 520805,
  นาโป่ง: 520806,
  แม่ถอด: 520807,
  เถินบุรี: 520808,
  แม่พริก: 520901,
  ผาปัง: 520902,
  แม่ปุ: 520903,
  พระบาทวังตวง: 520904,
  แม่ทะ: 521001,
  นาครัว: 521002,
  ป่าตัน: 521003,
  บ้านกิ่ว: 521004,
  บ้านบอม: 521005,
  น้ำโจ้: 521006,
  ดอนไฟ: 521007,
  หัวเสือ: 521008,
  วังเงิน: 521010,
  สันดอนแก้ว: 521011,
  สบปราบ: 521101,
  สมัย: 521102,
  แม่กัวะ: 521103,
  นายาง: 521104,
  ห้างฉัตร: 521201,
  หนองหล่ม: 521202,
  เมืองยาว: 521203,
  ปงยางคก: 521204,
  เวียงตาล: 521205,
  แม่สัน: 521206,
  วอแก้ว: 521207,
  เมืองปาน: 521301,
  บ้านขอ: 521302,
  ทุ่งกว๋าว: 521303,
  แจ้ซ้อน: 521304,
  หัวเมือง: 521305,
  ท่าอิฐ: 530101,
  ท่าเสา: 530102,
  บ้านเกาะ: 530103,
  ป่าเซ่า: 530104,
  คุ้งตะเภา: 530105,
  วังกะพี้: 530106,
  หาดกรวด: 530107,
  น้ำริด: 530108,
  งิ้วงาม: 530109,
  บ้านด่านนาขาม: 530110,
  บ้านด่าน: 530111,
  ผาจุก: 530112,
  วังดิน: 530113,
  แสนตอ: 530114,
  หาดงิ้ว: 530115,
  ขุนฝาง: 530116,
  ถ้ำฉลอง: 530117,
  วังแดง: 530201,
  บ้านแก่ง: 530202,
  หาดสองแคว: 530203,
  น้ำอ่าง: 530204,
  ข่อยสูง: 530205,
  ท่าปลา: 530301,
  หาดล้า: 530302,
  ผาเลือด: 530303,
  จริม: 530304,
  น้ำหมัน: 530305,
  ท่าแฝก: 530306,
  นางพญา: 530307,
  ร่วมจิต: 530308,
  แสนตอ: 530401,
  บ้านฝาย: 530402,
  เด่นเหล็ก: 530403,
  น้ำไคร้: 530404,
  น้ำไผ่: 530405,
  ห้วยมุ่น: 530406,
  ฟากท่า: 530501,
  สองคอน: 530502,
  บ้านเสี้ยว: 530503,
  สองห้อง: 530504,
  ม่วงเจ็ดต้น: 530601,
  บ้านโคก: 530602,
  นาขุม: 530603,
  บ่อเบี้ย: 530604,
  ในเมือง: 530701,
  บ้านดารา: 530702,
  ไร่อ้อย: 530703,
  ท่าสัก: 530704,
  คอรุม: 530705,
  บ้านหม้อ: 530706,
  ท่ามะเฟือง: 530707,
  บ้านโคน: 530708,
  พญาแมน: 530709,
  นาอิน: 530710,
  นายาง: 530711,
  ศรีพนมมาศ: 530801,
  แม่พูล: 530802,
  นานกกก: 530803,
  ฝายหลวง: 530804,
  ชัยจุมพล: 530805,
  ไผ่ล้อม: 530806,
  ทุ่งยั้ง: 530807,
  ด่านแม่คำมัน: 530808,
  ผักขวง: 530901,
  บ่อทอง: 530902,
  ป่าคาย: 530903,
  น้ำพี้: 530904,
  ในเวียง: 540101,
  นาจักร: 540102,
  น้ำชำ: 540103,
  ป่าแดง: 540104,
  ทุ่งโฮ้ง: 540105,
  เหมืองหม้อ: 540106,
  วังธง: 540107,
  แม่หล่าย: 540108,
  ห้วยม้า: 540109,
  ป่าแมต: 540110,
  บ้านถิ่น: 540111,
  สวนเขื่อน: 540112,
  วังหงษ์: 540113,
  แม่คำมี: 540114,
  ทุ่งกวาว: 540115,
  ท่าข้าม: 540116,
  แม่ยม: 540117,
  ช่อแฮ: 540118,
  ร่องฟอง: 540119,
  กาญจนา: 540120,
  ร้องกวาง: 540201,
  ร้องเข็ม: 540204,
  น้ำเลา: 540205,
  บ้านเวียง: 540206,
  ทุ่งศรี: 540207,
  แม่ยางตาล: 540208,
  แม่ยางฮ่อ: 540209,
  ไผ่โทน: 540210,
  ห้วยโรง: 540213,
  แม่ทราย: 540214,
  แม่ยางร้อง: 540215,
  ห้วยอ้อ: 540301,
  บ้านปิน: 540302,
  ต้าผามอก: 540303,
  เวียงต้า: 540304,
  ปากกาง: 540305,
  หัวทุ่ง: 540306,
  ทุ่งแล้ง: 540307,
  บ่อเหล็กลอง: 540308,
  แม่ปาน: 540309,
  สูงเม่น: 540401,
  น้ำชำ: 540402,
  หัวฝาย: 540403,
  ดอนมูล: 540404,
  บ้านเหล่า: 540405,
  บ้านกวาง: 540406,
  บ้านปง: 540407,
  บ้านกาศ: 540408,
  ร่องกาศ: 540409,
  สบสาย: 540410,
  เวียงทอง: 540411,
  พระหลวง: 540412,
  เด่นชัย: 540501,
  แม่จั๊วะ: 540502,
  ไทรย้อย: 540503,
  ห้วยไร่: 540504,
  ปงป่าหวาย: 540505,
  บ้านหนุน: 540601,
  บ้านกลาง: 540602,
  ห้วยหม้าย: 540603,
  เตาปูน: 540604,
  หัวเมือง: 540605,
  สะเอียบ: 540606,
  แดนชุมพล: 540607,
  ทุ่งน้าว: 540608,
  วังชิ้น: 540701,
  สรอย: 540702,
  แม่ป้าก: 540703,
  นาพูน: 540704,
  แม่พุง: 540705,
  ป่าสัก: 540706,
  แม่เกิ๋ง: 540707,
  แม่คำมี: 540801,
  หนองม่วงไข่: 540802,
  น้ำรัด: 540803,
  วังหลวง: 540804,
  ตำหนักธรรม: 540805,
  ทุ่งแค้ว: 540806,
  ในเวียง: 550101,
  บ่อ: 550102,
  ผาสิงห์: 550103,
  ไชยสถาน: 550104,
  ถืมตอง: 550105,
  เรือง: 550106,
  นาซาว: 550107,
  ดู่ใต้: 550108,
  กองควาย: 550109,
  สวก: 550116,
  สะเนียน: 550117,
  หนองแดง: 550202,
  หมอเมือง: 550203,
  น้ำพาง: 550204,
  น้ำปาย: 550205,
  แม่จริม: 550206,
  บ้านฟ้า: 550301,
  ป่าคาหลวง: 550302,
  สวด: 550303,
  บ้านพี้: 550304,
  นาน้อย: 550401,
  เชียงของ: 550402,
  ศรีษะเกษ: 550403,
  สถาน: 550404,
  สันทะ: 550405,
  บัวใหญ่: 550406,
  น้ำตก: 550407,
  ปัว: 550501,
  แงง: 550502,
  สถาน: 550503,
  ศิลาแลง: 550504,
  ศิลาเพชร: 550505,
  อวน: 550506,
  ไชยวัฒนา: 550509,
  เจดีย์ชัย: 550510,
  ภูคา: 550511,
  สกาด: 550512,
  ป่ากลาง: 550513,
  วรนคร: 550514,
  ริม: 550601,
  ป่าคา: 550602,
  ผาตอ: 550603,
  ยม: 550604,
  ตาลชุม: 550605,
  ศรีภูมิ: 550606,
  จอมพระ: 550607,
  แสนทอง: 550608,
  ท่าวังผา: 550609,
  ผาทอง: 550610,
  กลางเวียง: 550701,
  ขึ่ง: 550702,
  ไหล่น่าน: 550703,
  ตาลชุม: 550704,
  นาเหลือง: 550705,
  ส้าน: 550706,
  น้ำมวบ: 550707,
  น้ำปั้ว: 550708,
  ยาบหัวนา: 550709,
  ปงสนุก: 550710,
  อ่ายนาไลย: 550711,
  ส้านนาหนองใหม่: 550712,
  แม่ขะนิง: 550713,
  แม่สาคร: 550714,
  จอมจันทร์: 550715,
  แม่สา: 550716,
  ทุ่งศรีทอง: 550717,
  ปอน: 550801,
  งอบ: 550802,
  และ: 550803,
  ทุ่งช้าง: 550804,
  เชียงกลาง: 550901,
  เปือ: 550902,
  เชียงคาน: 550903,
  พระธาตุ: 550904,
  พญาแก้ว: 550908,
  พระพุทธบาท: 550909,
  นาทะนุง: 551001,
  บ่อแก้ว: 551002,
  เมืองลี: 551003,
  ปิงหลวง: 551004,
  ดู่พงษ์: 551101,
  ป่าแลวหลวง: 551102,
  พงษ์: 551103,
  บ่อเกลือเหนือ: 551201,
  บ่อเกลือใต้: 551202,
  ภูฟ้า: 551204,
  ดงพญา: 551205,
  นาไร่หลวง: 551301,
  ชนแดน: 551302,
  ยอด: 551303,
  ม่วงตึ๊ด: 551401,
  นาปัง: 551402,
  น้ำแก่น: 551403,
  น้ำเกี๋ยน: 551404,
  เมืองจัง: 551405,
  ท่าน้าว: 551406,
  ฝายแก้ว: 551407,
  ห้วยโก๋น: 551501,
  ขุนน่าน: 551502,
  เวียง: 560101,
  แม่ต๋ำ: 560102,
  แม่นาเรือ: 560104,
  บ้านตุ่น: 560105,
  บ้านต๊ำ: 560106,
  บ้านต๋อม: 560107,
  แม่ปืม: 560108,
  แม่กา: 560110,
  บ้านใหม่: 560111,
  จำป่าหวาย: 560112,
  ท่าวังทอง: 560113,
  แม่ใส: 560114,
  บ้านสาง: 560115,
  ท่าจำปี: 560116,
  สันป่าม่วง: 560118,
  ห้วยข้าวก่ำ: 560201,
  จุน: 560202,
  ลอ: 560203,
  หงส์หิน: 560204,
  ทุ่งรวงทอง: 560205,
  ห้วยยางขาม: 560206,
  พระธาตุขิงแกง: 560207,
  หย่วน: 560301,
  น้ำแวน: 560306,
  เวียง: 560307,
  ฝายกวาง: 560308,
  เจดีย์คำ: 560309,
  ร่มเย็น: 560310,
  เชียงบาน: 560311,
  แม่ลาว: 560312,
  อ่างทอง: 560313,
  ทุ่งผาสุข: 560314,
  เชียงม่วน: 560401,
  บ้านมาง: 560402,
  สระ: 560403,
  ดอกคำใต้: 560501,
  ดอนศรีชุม: 560502,
  บ้านถ้ำ: 560503,
  บ้านปิน: 560504,
  ห้วยลาน: 560505,
  สันโค้ง: 560506,
  ป่าซาง: 560507,
  หนองหล่ม: 560508,
  ดงสุวรรณ: 560509,
  บุญเกิด: 560510,
  สว่างอารมณ์: 560511,
  คือเวียง: 560512,
  ปง: 560601,
  ควร: 560602,
  ออย: 560603,
  งิม: 560604,
  ผาช้างน้อย: 560605,
  นาปรัง: 560606,
  ขุนควร: 560607,
  แม่ใจ: 560701,
  ศรีถ้อย: 560702,
  แม่สุก: 560703,
  ป่าแฝก: 560704,
  บ้านเหล่า: 560705,
  เจริญราษฎร์: 560706,
  ภูซาง: 560801,
  ป่าสัก: 560802,
  ทุ่งกล้วย: 560803,
  เชียงแรง: 560804,
  สบบง: 560805,
  ห้วยแก้ว: 560901,
  ดงเจน: 560902,
  แม่อิง: 560903,
  เวียง: 570101,
  รอบเวียง: 570102,
  บ้านดู่: 570103,
  นางแล: 570104,
  แม่ข้าวต้ม: 570105,
  แม่ยาว: 570106,
  สันทราย: 570107,
  แม่กรณ์: 570111,
  ห้วยชมภู: 570112,
  ห้วยสัก: 570113,
  ริมกก: 570114,
  ดอยลาน: 570115,
  ป่าอ้อดอนชัย: 570116,
  ท่าสาย: 570118,
  ดอยฮาง: 570120,
  ท่าสุด: 570121,
  เวียงชัย: 570202,
  ผางาม: 570203,
  เวียงเหนือ: 570204,
  ดอนศิลา: 570206,
  เมืองชุม: 570208,
  ปทุมวาปี: 471304,
  เต่างอย: 471401,
  บึงทวาย: 471402,
  นาตาล: 471403,
  จันทร์เพ็ญ: 471404,
  ตองโขบ: 471501,
  เหล่าโพนค้อ: 471502,
  ด่านม่วงคำ: 471503,
  แมดนาท่ม: 471504,
  บ้านเหล่า: 471601,
  เจริญศิลป์: 471602,
  ทุ่งแก: 471603,
  โคกศิลา: 471604,
  หนองแปน: 471605,
  บ้านโพน: 471701,
  นาแก้ว: 471702,
  นาตงวัฒนา: 471703,
  บ้านแป้น: 471704,
  เชียงสือ: 471705,
  สร้างค้อ: 471801,
  หลุบเลา: 471802,
  โคกภู: 471803,
  กกปลาซิว: 471804,
  ในเมือง: 480101,
  หนองแสง: 480102,
  นาทราย: 480103,
  นาราชควาย: 480104,
  กุรุคุ: 480105,
  บ้านผึ้ง: 480106,
  อาจสามารถ: 480107,
  ขามเฒ่า: 480108,
  บ้านกลาง: 480109,
  ท่าค้อ: 480110,
  คำเตย: 480111,
  หนองญาติ: 480112,
  ดงขวาง: 480113,
  วังตามัว: 480114,
  โพธิ์ตาก: 480115,
  ปลาปาก: 480201,
  หนองฮี: 480202,
  กุตาไก้: 480203,
  โคกสว่าง: 480204,
  โคกสูง: 480205,
  มหาชัย: 480206,
  นามะเขือ: 480207,
  หนองเทาใหญ่: 480208,
  ท่าอุเทน: 480301,
  โนนตาล: 480302,
  ท่าจำปา: 480303,
  ไชยบุรี: 480304,
  พนอม: 480305,
  พะทาย: 480306,
  เวินพระบาท: 480311,
  รามราช: 480312,
  หนองเทา: 480314,
  บ้านแพง: 480401,
  ไผ่ล้อม: 480402,
  โพนทอง: 480403,
  หนองแวง: 480404,
  นางัว: 480408,
  นาเข: 480409,
  ธาตุพนม: 480501,
  ฝั่งแดง: 480502,
  โพนแพง: 480503,
  พระกลางทุ่ง: 480504,
  นาถ่อน: 480505,
  แสนพัน: 480506,
  ดอนนางหงส์: 480507,
  น้ำก่ำ: 480508,
  อุ่มเหม้า: 480509,
  นาหนาด: 480510,
  กุดฉิม: 480511,
  ธาตุพนมเหนือ: 480512,
  เรณู: 480601,
  โพนทอง: 480602,
  ท่าลาด: 480603,
  นางาม: 480604,
  โคกหินแฮ่: 480605,
  หนองย่างชิ้น: 480607,
  เรณูใต้: 480608,
  นาขาม: 480609,
  นาแก: 480701,
  พระซอง: 480702,
  หนองสังข์: 480703,
  นาคู่: 480704,
  พิมาน: 480705,
  พุ่มแก: 480706,
  ก้านเหลือง: 480707,
  หนองบ่อ: 480708,
  นาเลียง: 480709,
  บ้านแก้ง: 480712,
  คำพี้: 480713,
  สีชมพู: 480715,
  ศรีสงคราม: 480801,
  นาเดื่อ: 480802,
  บ้านเอื้อง: 480803,
  สามผง: 480804,
  ท่าบ่อสงคราม: 480805,
  บ้านข่า: 480806,
  นาคำ: 480807,
  โพนสว่าง: 480808,
  หาดแพง: 480809,
  นาหว้า: 480901,
  นางัว: 480902,
  บ้านเสียว: 480903,
  นาคูณใหญ่: 480904,
  เหล่าพัฒนา: 480905,
  ท่าเรือ: 480906,
  โพนสวรรค์: 481001,
  นาหัวบ่อ: 481002,
  นาขมิ้น: 481003,
  โพนบก: 481004,
  บ้านค้อ: 481005,
  โพนจาน: 481006,
  นาใน: 481007,
  นาทม: 481101,
  หนองซน: 481102,
  ดอนเตย: 481103,
  วังยาง: 481201,
  โคกสี: 481202,
  ยอดชาด: 481203,
  หนองโพธิ์: 481204,
  มุกดาหาร: 490101,
  ศรีบุญเรือง: 490102,
  บ้านโคก: 490103,
  บางทรายใหญ่: 490104,
  โพนทราย: 490105,
  ผึ่งแดด: 490106,
  นาโสก: 490107,
  นาสีนวน: 490108,
  คำป่าหลาย: 490109,
  คำอาฮวน: 490110,
  ดงเย็น: 490111,
  ดงมอน: 490112,
  กุดแข้: 490113,
  นิคมคำสร้อย: 490201,
  นากอก: 490202,
  หนองแวง: 490203,
  กกแดง: 490204,
  นาอุดม: 490205,
  โชคชัย: 490206,
  ร่มเกล้า: 490207,
  ดอนตาล: 490301,
  โพธิ์ไทร: 490302,
  ป่าไร่: 490303,
  เหล่าหมี: 490304,
  บ้านบาก: 490305,
  นาสะเม็ง: 490306,
  บ้านแก้ง: 490307,
  ดงหลวง: 490401,
  หนองบัว: 490402,
  กกตูม: 490403,
  หนองแคน: 490404,
  ชะโนดน้อย: 490405,
  พังแดง: 490406,
  บ้านซ่ง: 490503,
  คำชะอี: 490504,
  หนองเอี่ยน: 490505,
  บ้านค้อ: 490506,
  บ้านเหล่า: 490507,
  โพนงาม: 490508,
  เหล่าสร้างถ่อ: 490511,
  คำบก: 490512,
  น้ำเที่ยง: 490514,
  หว้านใหญ่: 490601,
  ป่งขาม: 490602,
  บางทรายน้อย: 490603,
  ชะโนด: 490604,
  ดงหมู: 490605,
  หนองสูง: 490701,
  โนนยาง: 490702,
  ภูวง: 490703,
  บ้านเป้า: 490704,
  หนองสูงใต้: 490705,
  หนองสูงเหนือ: 490706,
  ศรีภูมิ: 500101,
  พระสิงห์: 500102,
  หายยา: 500103,
  ช้างม่อย: 500104,
  ช้างคลาน: 500105,
  วัดเกต: 500106,
  ช้างเผือก: 500107,
  สุเทพ: 500108,
  แม่เหียะ: 500109,
  ป่าแดด: 500110,
  หนองหอย: 500111,
  ท่าศาลา: 500112,
  หนองป่าครั่ง: 500113,
  ฟ้าฮ่าม: 500114,
  ป่าตัน: 500115,
  สันผีเสื้อ: 500116,
  บ้านหลวง: 500203,
  ข่วงเปา: 500204,
  สบเตี๊ยะ: 500205,
  บ้านแปะ: 500206,
  ดอยแก้ว: 500207,
  แม่สอย: 500209,
  ช่างเคิ่ง: 500301,
  ท่าผา: 500302,
  บ้านทับ: 500303,
  แม่ศึก: 500304,
  แม่นาจร: 500305,
  บ้านจันทร์: 500306,
  ปางหินฝน: 500307,
  กองแขก: 500308,
  แม่แดด: 500309,
  แจ่มหลวง: 500310,
  เชียงดาว: 500401,
  เมืองนะ: 500402,
  เมืองงาย: 500403,
  แม่นะ: 500404,
  เมืองคอง: 500405,
  ปิงโค้ง: 500406,
  ทุ่งข้าวพวง: 500407,
  เชิงดอย: 500501,
  สันปูเลย: 500502,
  ลวงเหนือ: 500503,
  ป่าป้อง: 500504,
  สง่าบ้าน: 500505,
  ป่าลาน: 500506,
  ตลาดขวัญ: 500507,
  สำราญราษฎร์: 500508,
  แม่คือ: 500509,
  ตลาดใหญ่: 500510,
  แม่ฮ้อยเงิน: 500511,
  แม่โป่ง: 500512,
  ป่าเมี่ยง: 500513,
  เทพเสด็จ: 500514,
  สันมหาพน: 500601,
  แม่แตง: 500602,
  ขี้เหล็ก: 500603,
  ช่อแล: 500604,
  แม่หอพระ: 500605,
  สบเปิง: 500606,
  บ้านเป้า: 500607,
  สันป่ายาง: 500608,
  ป่าแป๋: 500609,
  เมืองก๋าย: 500610,
  บ้านช้าง: 500611,
  กื้ดช้าง: 500612,
  อินทขิล: 500613,
  สมก๋าย: 500614,
  ริมใต้: 500701,
  ริมเหนือ: 500702,
  สันโป่ง: 500703,
  ขี้เหล็ก: 500704,
  สะลวง: 500705,
  ห้วยทราย: 500706,
  แม่แรม: 500707,
  โป่งแยง: 500708,
  แม่สา: 500709,
  ดอนแก้ว: 500710,
  เหมืองแก้ว: 500711,
  สะเมิงใต้: 500801,
  สะเมิงเหนือ: 500802,
  แม่สาบ: 500803,
  บ่อแก้ว: 500804,
  ยั้งเมิน: 500805,
  เวียง: 500901,
  ม่อนปิ่น: 500903,
  แม่งอน: 500904,
  แม่สูน: 500905,
  สันทราย: 500906,
  แม่คะ: 500910,
  แม่ข่า: 500911,
  โป่งน้ำร้อน: 500912,
  'แม่นาวาง*': 500995,
  'แม่สาว*': 500996,
  'แม่อาย*': 500997,
  แม่อาย: 501001,
  แม่สาว: 501002,
  สันต้นหมื้อ: 501003,
  แม่นาวาง: 501004,
  ท่าตอน: 501005,
  บ้านหลวง: 501006,
  มะลิกา: 501007,
  เวียง: 501101,
  ทุ่งหลวง: 501102,
  ป่าตุ้ม: 501103,
  ป่าไหน่: 501104,
  สันทราย: 501105,
  บ้านโป่ง: 501106,
  น้ำแพร่: 501107,
  เขื่อนผาก: 501108,
  แม่แวน: 501109,
  แม่ปั๋ง: 501110,
  โหล่งขอด: 501111,
  ทุ่งค่าย: 920307,
  เกาะเปียะ: 920308,
  ท่าข้าม: 920401,
  ทุ่งยาว: 920402,
  ปะเหลียน: 920403,
  บางด้วน: 920404,
  บ้านนา: 920407,
  สุโสะ: 920409,
  ลิพัง: 920410,
  เกาะสุกร: 920411,
  ท่าพญา: 920412,
  แหลมสอม: 920413,
  บ่อหิน: 920501,
  เขาไม้แก้ว: 920502,
  กะลาเส: 920503,
  ไม้ฝาด: 920504,
  นาเมืองเพชร: 920505,
  ห้วยยอด: 920601,
  บางดี: 920605,
  บางกุ้ง: 920606,
  เขากอบ: 920607,
  เขาขาว: 920608,
  เขาปูน: 920609,
  ปากแจ่ม: 920610,
  ปากคม: 920611,
  ท่างิ้ว: 920614,
  ลำภูรา: 920615,
  นาวง: 920616,
  ห้วยนาง: 920617,
  ในเตา: 920619,
  ทุ่งต่อ: 920620,
  วังคีรี: 920621,
  หนองช้างแล่น: 920622,
  เขาวิเศษ: 920701,
  วังมะปราง: 920702,
  อ่าวตง: 920703,
  ท่าสะบ้า: 920704,
  วังมะปรางเหนือ: 920705,
  นาโยงเหนือ: 920801,
  ช่อง: 920802,
  ละมอ: 920803,
  โคกสะบ้า: 920804,
  นาหมื่นศรี: 920805,
  นาข้าวเสีย: 920807,
  ควนเมา: 920901,
  คลองปาง: 920902,
  หนองบัว: 920903,
  หนองปรือ: 920904,
  เขาไพร: 920905,
  หาดสำราญ: 921001,
  บ้าหวี: 921002,
  ตะเสะ: 921003,
  คูหาสวรรค์: 930101,
  เขาเจียก: 930103,
  ท่ามิหรำ: 930104,
  โคกชะงาย: 930105,
  นาท่อม: 930106,
  ปรางหมู่: 930107,
  ท่าแค: 930108,
  ลำปำ: 930109,
  ตำนาน: 930110,
  ควนมะพร้าว: 930111,
  ร่มเมือง: 930112,
  ชัยบุรี: 930113,
  นาโหนด: 930114,
  พญาขัน: 930115,
  กงหรา: 930201,
  ชะรัด: 930202,
  คลองเฉลิม: 930203,
  คลองทรายขาว: 930204,
  สมหวัง: 930205,
  เขาชัยสน: 930301,
  ควนขนุน: 930302,
  จองถนน: 930305,
  หานโพธิ์: 930306,
  โคกม่วง: 930307,
  แม่ขรี: 930401,
  ตะโหมด: 930402,
  คลองใหญ่: 930403,
  ควนขนุน: 930501,
  ทะเลน้อย: 930502,
  นาขยาด: 930504,
  พนมวังก์: 930505,
  แหลมโตนด: 930506,
  ปันแต: 930508,
  โตนดด้วน: 930509,
  ดอนทราย: 930510,
  มะกอกเหนือ: 930511,
  พนางตุง: 930512,
  ชะมวง: 930513,
  แพรกหา: 930516,
  ปากพะยูน: 930601,
  ดอนประดู่: 930602,
  เกาะนางคำ: 930603,
  เกาะหมาก: 930604,
  ฝาละมี: 930605,
  หารเทา: 930606,
  ดอนทราย: 930607,
  เขาย่า: 930701,
  เขาปู่: 930702,
  ตะแพน: 930703,
  ป่าบอน: 930801,
  โคกทราย: 930802,
  หนองธง: 930803,
  ทุ่งนารี: 930804,
  วังใหม่: 930806,
  ท่ามะเดื่อ: 930901,
  นาปะขอ: 930902,
  โคกสัก: 930903,
  ป่าพะยอม: 931001,
  ลานข่อย: 931002,
  เกาะเต่า: 931003,
  บ้านพร้าว: 931004,
  ชุมพล: 931101,
  บ้านนา: 931102,
  อ่างทอง: 931103,
  ลำสินธุ์: 931104,
  สะบารัง: 940101,
  อาเนาะรู: 940102,
  จะบังติกอ: 940103,
  บานา: 940104,
  ตันหยงลุโละ: 940105,
  คลองมานิง: 940106,
  กะมิยอ: 940107,
  บาราโหม: 940108,
  ปะกาฮะรัง: 940109,
  รูสะมิแล: 940110,
  ตะลุโบะ: 940111,
  บาราเฮาะ: 940112,
  ปุยุด: 940113,
  โคกโพธิ์: 940201,
  มะกรูด: 940202,
  บางโกระ: 940203,
  ป่าบอน: 940204,
  ทรายขาว: 940205,
  นาประดู่: 940206,
  ปากล่อ: 940207,
  ทุ่งพลา: 940208,
  ท่าเรือ: 940211,
  นาเกตุ: 940213,
  ควนโนรี: 940214,
  ช้างให้ตก: 940215,
  เกาะเปาะ: 940301,
  คอลอตันหยง: 940302,
  ดอนรัก: 940303,
  ดาโต๊ะ: 940304,
  ตุยง: 940305,
  ท่ากำชำ: 940306,
  บ่อทอง: 940307,
  บางเขา: 940308,
  บางตาวา: 940309,
  ปุโละปุโย: 940310,
  ยาบี: 940311,
  ลิปะสะโง: 940312,
  ปะนาเระ: 940401,
  ท่าข้าม: 940402,
  บ้านนอก: 940403,
  ดอน: 940404,
  ควน: 940405,
  ท่าน้ำ: 940406,
  คอกกระบือ: 940407,
  พ่อมิ่ง: 940408,
  บ้านกลาง: 940409,
  บ้านน้ำบ่อ: 940410,
  มายอ: 940501,
  ถนน: 940502,
  ตรัง: 940503,
  กระหวะ: 940504,
  ลุโบะยิไร: 940505,
  ลางา: 940506,
  กระเสาะ: 940507,
  เกาะจัน: 940508,
  ปะโด: 940509,
  สาคอบน: 940510,
  สาคอใต้: 940511,
  สะกำ: 940512,
  ปานัน: 940513,
  ตะโละแมะนา: 940601,
  พิเทน: 940602,
  น้ำดำ: 940603,
  ปากู: 940604,
  ตะลุบัน: 940701,
  ตะบิ้ง: 940702,
  ปะเสยะวอ: 940703,
  บางเก่า: 940704,
  บือเระ: 940705,
  เตราะบอน: 940706,
  กะดุนง: 940707,
  ละหาร: 940708,
  มะนังดาลำ: 940709,
  แป้น: 940710,
  ทุ่งคล้า: 940711,
  ไทรทอง: 940801,
  ไม้แก่น: 940802,
  ตะโละไกรทอง: 940803,
  ดอนทราย: 940804,
  ตะโละ: 940901,
  ตะโละกาโปร์: 940902,
  ตันหยงดาลอ: 940903,
  ตันหยงจึงงา: 940904,
  ตอหลัง: 940905,
  ตาแกะ: 940906,
  ตาลีอายร์: 940907,
  ยามู: 940908,
  บางปู: 940909,
  หนองแรต: 940910,
  ปิยามุมัง: 940911,
  ปุลากง: 940912,
  บาโลย: 940913,
  สาบัน: 940914,
  มะนังยง: 940915,
  ราตาปันยัง: 940916,
  จะรัง: 940917,
  แหลมโพธิ์: 940918,
  ยะรัง: 941001,
  สะดาวา: 941002,
  ประจัน: 941003,
  สะนอ: 941004,
  ระแว้ง: 941005,
  ปิตูมุดี: 941006,
  วัด: 941007,
  กระโด: 941008,
  คลองใหม่: 941009,
  เมาะมาวี: 941010,
  กอลำ: 941011,
  เขาตูม: 941012,
  กะรุบี: 941101,
  ตะโละดือรามัน: 941102,
  ปล่องหอย: 941103,
  แม่ลาน: 941201,
  ม่วงเตี้ย: 941202,
  ป่าไร่: 941203,
  สะเตง: 950101,
  บุดี: 950102,
  ยุโป: 950103,
  ลิดล: 950104,
  ยะลา: 950106,
  ท่าสาป: 950108,
  ลำใหม่: 950109,
  หน้าถ้ำ: 950110,
  ลำพะยา: 950111,
  เปาะเส้ง: 950112,
  พร่อน: 950114,
  บันนังสาเรง: 950115,
  สะเตงนอก: 950116,
  ตาเซะ: 950118,
  เบตง: 950201,
  ยะรม: 950202,
  ตาเนาะแมเราะ: 950203,
  อัยเยอร์เวง: 950204,
  ธารน้ำทิพย์: 950205,
  บันนังสตา: 950301,
  บาเจาะ: 950302,
  ตาเนาะปูเต๊ะ: 950303,
  ถ้ำทะลุ: 950304,
  ตลิ่งชัน: 950305,
  เขื่อนบางลาง: 950306,
  ธารโต: 950401,
  บ้านแหร: 950402,
  แม่หวาด: 950403,
  คีรีเขต: 950404,
  ยะหา: 950501,
  ละแอ: 950502,
  ปะแต: 950503,
  บาโร๊ะ: 950504,
  ตาชี: 950506,
  บาโงยซิแน: 950507,
  กาตอง: 950508,
  กายูบอเกาะ: 950601,
  กาลูปัง: 950602,
  กาลอ: 950603,
  กอตอตือร๊ะ: 950604,
  โกตาบารู: 950605,
  เกะรอ: 950606,
  จะกว๊ะ: 950607,
  ท่าธง: 950608,
  เนินงาม: 950609,
  บาลอ: 950610,
  บาโงย: 950611,
  บือมัง: 950612,
  ยะต๊ะ: 950613,
  วังพญา: 950614,
  อาซ่อง: 950615,
  ตะโล๊ะหะลอ: 950616,
  กาบัง: 950701,
  บาละ: 950702,
  กรงปินัง: 950801,
  สะเอะ: 950802,
  ห้วยกระทิง: 950803,
  ปุโรง: 950804,
  บางนาค: 960101,
  ลำภู: 960102,
  มะนังตายอ: 960103,
  บางปอ: 960104,
  กะลุวอ: 960105,
  กะลุวอเหนือ: 960106,
  โคกเคียน: 960107,
  เจ๊ะเห: 960201,
  ไพรวัน: 960202,
  พร่อน: 960203,
  ศาลาใหม่: 960204,
  บางขุนทอง: 960205,
  เกาะสะท้อน: 960206,
  นานาค: 960207,
  โฆษิต: 960208,
  บาเจาะ: 960301,
  ลุโบะสาวอ: 960302,
  กาเยาะมาตี: 960303,
  ปะลุกาสาเมาะ: 960304,
  บาเระเหนือ: 960305,
  บาเระใต้: 960306,
  ยี่งอ: 960401,
  ละหาร: 960402,
  จอเบาะ: 960403,
  ลุโบะบายะ: 960404,
  ลุโบะบือซา: 960405,
  ตะปอเยาะ: 960406,
  วงศ์สว่าง: 102902,
  สนามบิน: 103601,
  ดอนเมือง: 103611,
  รามอินทรา: 104302,
  นวมินทร์: 102704,
  จอมพระ: 103006,
  สะพานสอง: 104502,
  นวลจันทร์: 102705,
  คลองเจ้าคุณสิงห์: 104503,
  พลับพลา: 104504,
  ตันหยงมัส: 960501,
  ตันหยงลิมอ: 960502,
  บองอ: 960506,
  กาลิซา: 960507,
  บาโงสะโต: 960508,
  เฉลิม: 960509,
  มะรือโบตก: 960510,
  รือเสาะ: 960601,
  สาวอ: 960602,
  เรียง: 960603,
  สามัคคี: 960604,
  บาตง: 960605,
  ลาโละ: 960606,
  รือเสาะออก: 960607,
  โคกสะตอ: 960608,
  สุวารี: 960609,
  ซากอ: 960701,
  ตะมะยูง: 960702,
  ศรีสาคร: 960703,
  เชิงคีรี: 960704,
  กาหลง: 960705,
  ศรีบรรพต: 960706,
  แว้ง: 960801,
  กายูคละ: 960802,
  ฆอเลาะ: 960803,
  โละจูด: 960804,
  แม่ดง: 960805,
  เอราวัณ: 960806,
  มาโมง: 960901,
  สุคิริน: 960902,
  เกียร์: 960903,
  ภูเขาทอง: 960904,
  ร่มไทร: 960905,
  'สุไหงโก-ลก': 961001,
  ปาเสมัส: 961002,
  มูโนะ: 961003,
  ปูโยะ: 961004,
  ปะลุรู: 961101,
  สุไหงปาดี: 961102,
  โต๊ะเด็ง: 961103,
  สากอ: 961104,
  ริโก๋: 961105,
  กาวะ: 961106,
  จะแนะ: 961201,
  ดุซงญอ: 961202,
  ผดุงมาตร: 961203,
  ช้างเผือก: 961204,
  จวบ: 961301,
  บูกิต: 961302,
  มะรือโบออก: 961303,
  ไม่ระบุตำบล: 999999,
  ซับไม้แดง: 670802,
  หนองแจง: 670803,
  กันจุ: 670804,
  วังพิกุล: 670805,
  พญาวัง: 670806,
  ศรีมงคล: 670807,
  สระแก้ว: 670808,
  บึงสามพัน: 670809,
  น้ำหนาว: 670901,
  หลักด่าน: 670902,
  วังกวาง: 670903,
  โคกมน: 670904,
  วังโป่ง: 671001,
  ท้ายดง: 671002,
  ซับเปิบ: 671003,
  วังหิน: 671004,
  วังศาล: 671005,
  ทุ่งสมอ: 671101,
  แคมป์สน: 671102,
  เขาค้อ: 671103,
  ริมสีม่วง: 671104,
  สะเดาะพง: 671105,
  หนองแม่นา: 671106,
  เข็กน้อย: 671107,
  หน้าเมือง: 700101,
  เจดีย์หัก: 700102,
  ดอนตะโก: 700103,
  หนองกลางนา: 700104,
  ห้วยไผ่: 700105,
  คุ้งน้ำวน: 700106,
  คุ้งกระถิน: 700107,
  อ่างทอง: 700108,
  โคกหม้อ: 700109,
  สามเรือน: 700110,
  พิกุลทอง: 700111,
  น้ำพุ: 700112,
  ดอนแร่: 700113,
  หินกอง: 700114,
  เขาแร้ง: 700115,
  เกาะพลับพลา: 700116,
  หลุมดิน: 700117,
  บางป่า: 700118,
  พงสวาย: 700119,
  คูบัว: 700120,
  ท่าราบ: 700121,
  บ้านไร่: 700122,
  จอมบึง: 700201,
  ปากช่อง: 700202,
  เบิกไพร: 700203,
  ด่านทับตะโก: 700204,
  แก้มอ้น: 700205,
  รางบัว: 700206,
  สวนผึ้ง: 700301,
  ป่าหวาย: 700302,
  ท่าเคย: 700304,
  ตะนาวศรี: 700307,
  ดำเนินสะดวก: 700401,
  ประสาทสิทธิ์: 700402,
  ศรีสุราษฎร์: 700403,
  ตาหลวง: 700404,
  ดอนกรวย: 700405,
  ดอนคลัง: 700406,
  บัวงาม: 700407,
  บ้านไร่: 700408,
  แพงพวย: 700409,
  สี่หมื่น: 700410,
  ท่านัด: 700411,
  ขุนพิทักษ์: 700412,
  ดอนไผ่: 700413,
  บ้านโป่ง: 700501,
  ท่าผา: 700502,
  กรับใหญ่: 700503,
  ปากแรต: 700504,
  หนองกบ: 700505,
  หนองอ้อ: 700506,
  ดอนกระเบื้อง: 700507,
  สวนกล้วย: 700508,
  นครชุมน์: 700509,
  บ้านม่วง: 700510,
  คุ้งพยอม: 700511,
  หนองปลาหมอ: 700512,
  เขาขลุง: 700513,
  เบิกไพร: 700514,
  ลาดบัวขาว: 700515,
  บางแพ: 700601,
  วังเย็น: 700602,
  หัวโพ: 700603,
  วัดแก้ว: 700604,
  ดอนใหญ่: 700605,
  ดอนคา: 700606,
  โพหัก: 700607,
  โพธาราม: 700701,
  ดอนกระเบื้อง: 700702,
  หนองโพ: 700703,
  บ้านเลือก: 700704,
  คลองตาคต: 700705,
  บ้านฆ้อง: 700706,
  บ้านสิงห์: 700707,
  ดอนทราย: 700708,
  เจ็ดเสมียน: 700709,
  คลองข่อย: 700710,
  ชำแระ: 700711,
  สร้อยฟ้า: 700712,
  ท่าชุมพล: 700713,
  บางโตนด: 700714,
  เตาปูน: 700715,
  นางแก้ว: 700716,
  ธรรมเสน: 700717,
  เขาชะงุ้ม: 700718,
  หนองกวาง: 700719,
  ทุ่งหลวง: 700801,
  วังมะนาว: 700802,
  ดอนทราย: 700803,
  หนองกระทุ่ม: 700804,
  ปากท่อ: 700805,
  ป่าไก่: 700806,
  วัดยางงาม: 700807,
  อ่างหิน: 700808,
  บ่อกระดาน: 700809,
  ยางหัก: 700810,
  วันดาว: 700811,
  ห้วยยางโทน: 700812,
  เกาะศาลพระ: 700901,
  จอมประทัด: 700902,
  วัดเพลง: 700903,
  บ้านคา: 701001,
  บ้านบึง: 701002,
  หนองพันจันทร์: 701003,
  บ้านเหนือ: 710101,
  บ้านใต้: 710102,
  ปากแพรก: 710103,
  ท่ามะขาม: 710104,
  แก่งเสี้ยน: 710105,
  หนองบัว: 710106,
  ลาดหญ้า: 710107,
  วังด้ง: 710108,
  ช่องสะเดา: 710109,
  หนองหญ้า: 710110,
  เกาะสำโรง: 710111,
  บ้านเก่า: 710113,
  วังเย็น: 710116,
  ลุ่มสุ่ม: 710201,
  ท่าเสา: 710202,
  สิงห์: 710203,
  ไทรโยค: 710204,
  วังกระแจะ: 710205,
  ศรีมงคล: 710206,
  บ้องตี้: 710207,
  บ่อพลอย: 710301,
  หนองกุ่ม: 710302,
  หนองรี: 710303,
  หลุมรัง: 710305,
  ช่องด่าน: 710308,
  หนองกร่าง: 710309,
  นาสวน: 710401,
  ด่านแม่แฉลบ: 710402,
  หนองเป็ด: 710403,
  ท่ากระดาน: 710404,
  เขาโจด: 710405,
  แม่กระบุง: 710406,
  พงตึก: 710501,
  ยางม่วง: 710502,
  ดอนชะเอม: 710503,
  ท่าไม้: 710504,
  ตะคร้ำเอน: 710505,
  ท่ามะกา: 710506,
  ท่าเรือ: 710507,
  โคกตะบอง: 710508,
  ดอนขมิ้น: 710509,
  อุโลกสี่หมื่น: 710510,
  เขาสามสิบหาบ: 710511,
  พระแท่น: 710512,
  หวายเหนียว: 710513,
  แสนตอ: 710514,
  สนามแย้: 710515,
  ท่าเสา: 710516,
  หนองลาน: 710517,
  ท่าม่วง: 710601,
  วังขนาย: 710602,
  วังศาลา: 710603,
  ท่าล้อ: 710604,
  หนองขาว: 710605,
  ทุ่งทอง: 710606,
  เขาน้อย: 710607,
  ม่วงชุม: 710608,
  บ้านใหม่: 710609,
  พังตรุ: 710610,
  ท่าตะคร้อ: 710611,
  รางสาลี่: 710612,
  หนองตากยา: 710613,
  ท่าขนุน: 710701,
  ปิล๊อก: 710702,
  หินดาด: 710703,
  ลิ่นถิ่น: 710704,
  ชะแล: 710705,
  ห้วยเขย่ง: 710706,
  สหกรณ์นิคม: 710707,
  หนองลู: 710801,
  ปรังเผล: 710802,
  ไล่โว่: 710803,
  พนมทวน: 710901,
  หนองโรง: 710902,
  ทุ่งสมอ: 710903,
  ดอนเจดีย์: 710904,
  พังตรุ: 710905,
  รางหวาย: 710906,
  หนองสาหร่าย: 710911,
  ดอนตาเพชร: 710912,
  เลาขวัญ: 711001,
  หนองโสน: 711002,
  หนองประดู่: 711003,
  หนองปลิง: 711004,
  หนองนกแก้ว: 711005,
  ทุ่งกระบ่ำ: 711006,
  หนองฝ้าย: 711007,
  ด่านมะขามเตี้ย: 711101,
  กลอนโด: 711102,
  จรเข้เผือก: 711103,
  หนองไผ่: 711104,
  หนองปรือ: 711201,
  หนองปลาไหล: 711202,
  สมเด็จเจริญ: 711203,
  ห้วยกระเจา: 711301,
  วังไผ่: 711302,
  ดอนแสลบ: 711303,
  สระลงเรือ: 711304,
  ท่าพี่เลี้ยง: 720101,
  รั้วใหญ่: 720102,
  ทับตีเหล็ก: 720103,
  ท่าระหัด: 720104,
  ไผ่ขวาง: 720105,
  โคกโคเฒ่า: 720106,
  ดอนตาล: 720107,
  ดอนมะสังข์: 720108,
  พิหารแดง: 720109,
  ดอนกำยาน: 720110,
  ดอนโพธิ์ทอง: 720111,
  บ้านโพธิ์: 720112,
  สระแก้ว: 720113,
  ตลิ่งชัน: 720114,
  บางกุ้ง: 720115,
  ศาลาขาว: 720116,
  สวนแตง: 720117,
  สนามชัย: 720118,
  โพธิ์พระยา: 720119,
  สนามคลี: 720120,
  เขาพระ: 720201,
  เดิมบาง: 720202,
  นางบวช: 720203,
  เขาดิน: 720204,
  ปากน้ำ: 720205,
  ทุ่งคลี: 720206,
  โคกช้าง: 720207,
  หัวเขา: 720208,
  หัวนา: 720209,
  บ่อกรุ: 720210,
  วังศรีราช: 720211,
  ป่าสะแก: 720212,
  ยางนอน: 720213,
  หนองกระทุ่ม: 720214,
  หนองมะค่าโมง: 720301,
  ด่านช้าง: 720302,
  ห้วยขมิ้น: 720303,
  องค์พระ: 720304,
  วังคัน: 720305,
  นิคมกระเสียว: 720306,
  วังยาว: 720307,
  โคกคราม: 720401,
  บางปลาม้า: 720402,
  ตะค่า: 720403,
  บางใหญ่: 720404,
  กฤษณา: 720405,
  สาลี: 720406,
  ไผ่กองดิน: 720407,
  องครักษ์: 720408,
  จรเข้ใหญ่: 720409,
  บ้านแหลม: 720410,
  มะขามล้ม: 720411,
  วังน้ำเย็น: 720412,
  วัดโบสถ์: 720413,
  วัดดาว: 720414,
  ศรีประจันต์: 720501,
  บ้านกร่าง: 720502,
  มดแดง: 720503,
  บางงาม: 720504,
  ดอนปรู: 720505,
  ปลายนา: 720506,
  วังหว้า: 720507,
  วังน้ำซับ: 720508,
  วังยาง: 720509,
  ดอนเจดีย์: 720601,
  หนองสาหร่าย: 720602,
  ไร่รถ: 720603,
  สระกระโจม: 720604,
  ทะเลบก: 720605,
  สองพี่น้อง: 720701,
  บางเลน: 720702,
  บางตาเถร: 720703,
  บางตะเคียน: 720704,
  บ้านกุ่ม: 720705,
  หัวโพธิ์: 720706,
  บางพลับ: 720707,
  เนินพระปรางค์: 720708,
  บ้านช้าง: 720709,
  ต้นตาล: 720710,
  ศรีสำราญ: 720711,
  ทุ่งคอก: 720712,
  หนองบ่อ: 720713,
  บ่อสุพรรณ: 720714,
  ดอนมะนาว: 720715,
  ย่านยาว: 720801,
  วังลึก: 720802,
  สามชุก: 720803,
  หนองผักนาก: 720804,
  บ้านสระ: 720805,
  หนองสะเดา: 720806,
  กระเสียว: 720807,
  อู่ทอง: 720901,
  สระยายโสม: 720902,
  จรเข้สามพัน: 720903,
  บ้านดอน: 720904,
  ยุ้งทะลาย: 720905,
  ดอนมะเกลือ: 720906,
  หนองโอ่ง: 720907,
  ดอนคา: 720908,
  พลับพลาไชย: 720909,
  บ้านโข้ง: 720910,
  เจดีย์: 720911,
  สระพังลาน: 720912,
  กระจัน: 720913,
  หนองหญ้าไซ: 721001,
  หนองราชวัตร: 721002,
  หนองโพธิ์: 721003,
  แจงงาม: 721004,
  หนองขาม: 721005,
  ทัพหลวง: 721006,
  พระปฐมเจดีย์: 730101,
  บางแขม: 730102,
  พระประโทน: 730103,
  ธรรมศาลา: 730104,
  ตาก้อง: 730105,
  มาบแค: 730106,
  สนามจันทร์: 730107,
  ดอนยายหอม: 730108,
  ถนนขาด: 730109,
  บ่อพลับ: 730110,
  นครปฐม: 730111,
  วังตะกู: 730112,
  หนองปากโลง: 730113,
  สามควายเผือก: 730114,
  ทุ่งน้อย: 730115,
  หนองดินแดง: 730116,
  วังเย็น: 730117,
  โพรงมะเดื่อ: 730118,
  ลำพยา: 730119,
  สระกะเทียม: 730120,
  สวนป่าน: 730121,
  ห้วยจรเข้: 730122,
  ทัพหลวง: 730123,
  หนองงูเหลือม: 730124,
  บ้านยาง: 730125,
  ทุ่งกระพังโหม: 730201,
  กระตีบ: 730202,
  ทุ่งลูกนก: 730203,
  ห้วยขวาง: 730204,
  ทุ่งขวาง: 730205,
  สระสี่มุม: 730206,
  ทุ่งบัว: 730207,
  ดอนข่อย: 730208,
  สระพัฒนา: 730209,
  ห้วยหมอนทอง: 730210,
  ห้วยม่วง: 730211,
  กำแพงแสน: 730212,
  รางพิกุล: 730213,
  หนองกระทุ่ม: 730214,
  วังน้ำเขียว: 730215,
  นครชัยศรี: 730301,
  บางกระเบา: 730302,
  วัดแค: 730303,
  ท่าตำหนัก: 730304,
  บางแก้ว: 730305,
  ท่ากระชับ: 730306,
  ขุนแก้ว: 730307,
  ท่าพระยา: 730308,
  พะเนียด: 730309,
  บางระกำ: 730310,
  โคกพระเจดีย์: 730311,
  ศรีษะทอง: 730312,
  แหลมบัว: 730313,
  ศรีมหาโพธิ์: 730314,
  สัมปทวน: 730315,
  วัดสำโรง: 730316,
  ดอนแฝก: 730317,
  ห้วยพลู: 730318,
  วัดละมุด: 730319,
  บางพระ: 730320,
  บางแก้วฟ้า: 730321,
  ลานตากฟ้า: 730322,
  งิ้วราย: 730323,
  ไทยาวาส: 730324,
  สามง่าม: 730401,
  ห้วยพระ: 730402,
  ลำเหย: 730403,
  ดอนพุทรา: 730404,
  บ้านหลวง: 730405,
  ดอนรวก: 730406,
  ห้วยด้วน: 730407,
  ลำลูกบัว: 730408,
  บางเลน: 730501,
  บางปลา: 730502,
  บางหลวง: 730503,
  บางภาษี: 730504,
  บางระกำ: 730505,
  บางไทรป่า: 730506,
  หินมูล: 730507,
  ไทรงาม: 730508,
  ดอนตูม: 730509,
  นิลเพชร: 730510,
  บัวปากท่า: 730511,
  คลองนกกระทุง: 730512,
  นราภิรมย์: 730513,
  ลำพญา: 730514,
  ไผ่หูช้าง: 730515,
  ท่าข้าม: 730601,
  ทรงคนอง: 730602,
  หอมเกร็ด: 730603,
  บางกระทึก: 730604,
  บางเตย: 730605,
  สามพราน: 730606,
  บางช้าง: 730607,
  ไร่ขิง: 730608,
  ท่าตลาด: 730609,
  กระทุ่มล้ม: 730610,
  คลองใหม่: 730611,
  ตลาดจินดา: 730612,
  คลองจินดา: 730613,
  ยายชา: 730614,
  บ้านใหม่: 730615,
  อ้อมใหญ่: 730616,
  ศาลายา: 730701,
  คลองโยง: 730702,
  มหาสวัสดิ์: 730703,
  มหาชัย: 740101,
  ท่าฉลอม: 740102,
  โกรกกราก: 740103,
  บ้านบ่อ: 740104,
  บางโทรัด: 740105,
  กาหลง: 740106,
  นาโคก: 740107,
  ท่าจีน: 740108,
  นาดี: 740109,
  ท่าทราย: 740110,
  คอกกระบือ: 740111,
  บางน้ำจืด: 740112,
  พันท้ายนรสิงห์: 740113,
  โคกขาม: 740114,
  บ้านเกาะ: 740115,
  บางกระเจ้า: 740116,
  บางหญ้าแพรก: 740117,
  ชัยมงคล: 740118,
  ตลาดกระทุ่มแบน: 740201,
  อ้อมน้อย: 740202,
  ท่าไม้: 740203,
  สวนหลวง: 740204,
  บางยาง: 740205,
  คลองมะเดื่อ: 740206,
  หนองนกไข่: 740207,
  ดอนไก่ดี: 740208,
  แคราย: 740209,
  ท่าเสา: 740210,
  บ้านแพ้ว: 740301,
  หลักสาม: 740302,
  ยกกระบัตร: 740303,
  โรงเข้: 740304,
  หนองสองห้อง: 740305,
  หนองบัว: 740306,
  หลักสอง: 740307,
  เจ็ดริ้ว: 740308,
  คลองตัน: 740309,
  อำแพง: 740310,
  สวนส้ม: 740311,
  เกษตรพัฒนา: 740312,
  แม่กลอง: 750101,
  บางขันแตก: 750102,
  ลาดใหญ่: 750103,
  บ้านปรก: 750104,
  บางแก้ว: 750105,
  ท้ายหาด: 750106,
  แหลมใหญ่: 750107,
  คลองเขิน: 750108,
  คลองโคน: 750109,
  นางตะเคียน: 750110,
  บางจะเกร็ง: 750111,
  กระดังงา: 750201,
  บางสะแก: 750202,
  บางยี่รงค์: 750203,
  โรงหีบ: 750204,
  บางคนที: 750205,
  ดอนมะโนรา: 750206,
  บางพรม: 750207,
  บางกุ้ง: 750208,
  จอมปลวก: 750209,
  บางนกแขวก: 750210,
  ยายแพง: 750211,
  บางกระบือ: 750212,
  บ้านปราโมทย์: 750213,
  อัมพวา: 750301,
  สวนหลวง: 750302,
  ท่าคา: 750303,
  วัดประดู่: 750304,
  เหมืองใหม่: 750305,
  บางช้าง: 750306,
  แควอ้อม: 750307,
  ปลายโพงพาง: 750308,
  บางแค: 750309,
  แพรกหนามแดง: 750310,
  ยี่สาร: 750311,
  บางนางลี่: 750312,
  ท่าราบ: 760101,
  คลองกระแชง: 760102,
  บางจาน: 760103,
  นาพันสาม: 760104,
  ธงชัย: 760105,
  บ้านกุ่ม: 760106,
  หนองโสน: 760107,
  ไร่ส้ม: 760108,
  เวียงคอย: 760109,
  บางจาก: 760110,
  บ้านหม้อ: 760111,
  ต้นมะม่วง: 760112,
  ช่องสะแก: 760113,
  นาวุ้ง: 760114,
  สำมะโรง: 760115,
  โพพระ: 760116,
  หาดเจ้าสำราญ: 760117,
  หัวสะพาน: 760118,
  ต้นมะพร้าว: 760119,
  วังตะโก: 760120,
  โพไร่หวาน: 760121,
  ดอนยาง: 760122,
  หนองขนาน: 760123,
  หนองพลับ: 760124,
  เขาย้อย: 760201,
  สระพัง: 760202,
  บางเค็ม: 760203,
  ทับคาง: 760204,
  หนองปลาไหล: 760205,
  หนองปรง: 760206,
  หนองชุมพล: 760207,
  ห้วยโรง: 760208,
  ห้วยท่าช้าง: 760209,
  หนองชุมพลเหนือ: 760210,
  หนองหญ้าปล้อง: 760301,
  ยางน้ำกลัดเหนือ: 760302,
  ยางน้ำกลัดใต้: 760303,
  ท่าตะคร้อ: 760304,
  ชะอำ: 760401,
  บางเก่า: 760402,
  นายาง: 760403,
  เขาใหญ่: 760404,
  หนองศาลา: 760405,
  ห้วยทรายเหนือ: 760406,
  ไร่ใหม่พัฒนา: 760407,
  สามพระยา: 760408,
  ดอนขุนห้วย: 760409,
  ท่ายาง: 760501,
  ท่าคอย: 760502,
  ยางหย่อง: 760503,
  หนองจอก: 760504,
  มาบปลาเค้า: 760505,
  ท่าไม้รวก: 760506,
  วังไคร้: 760507,
  กลัดหลวง: 760511,
  ปึกเตียน: 760512,
  เขากระปุก: 760513,
  ท่าแลง: 760514,
  บ้านในดง: 760515,
  บ้านลาด: 760601,
  บ้านหาด: 760602,
  บ้านทาน: 760603,
  ตำหรุ: 760604,
  สมอพลือ: 760605,
  ไร่มะขาม: 760606,
  ท่าเสน: 760607,
  หนองกระเจ็ด: 760608,
  หนองกะปุ: 760609,
  ลาดโพธิ์: 760610,
  สะพานไกร: 760611,
  ไร่โคก: 760612,
  โรงเข้: 760613,
  ไร่สะท้อน: 760614,
  ห้วยข้อง: 760615,
  ท่าช้าง: 760616,
  ถ้ำรงค์: 760617,
  ห้วยลึก: 760618,
  บ้านแหลม: 760701,
  บางขุนไทร: 760702,
  ปากทะเล: 760703,
  บางแก้ว: 760704,
  แหลมผักเบี้ย: 760705,
  บางตะบูน: 760706,
  บางตะบูนออก: 760707,
  บางครก: 760708,
  ท่าแร้ง: 760709,
  ท่าแร้งออก: 760710,
  แก่งกระจาน: 760801,
  สองพี่น้อง: 760802,
  วังจันทร์: 760803,
  ป่าเด็ง: 760804,
  พุสวรรค์: 760805,
  ห้วยแม่เพรียง: 760806,
  ประจวบคีรีขันธ์: 770101,
  เกาะหลัก: 770102,
  คลองวาฬ: 770103,
  ห้วยทราย: 770104,
  อ่าวน้อย: 770105,
  บ่อนอก: 770106,
  กุยบุรี: 770201,
  กุยเหนือ: 770202,
  เขาแดง: 770203,
  ดอนยายหนู: 770204,
  สามกระทาย: 770206,
  หาดขาม: 770207,
  ทับสะแก: 770301,
  อ่างทอง: 770302,
  นาหูกวาง: 770303,
  เขาล้าน: 770304,
  ห้วยยาง: 770305,
  แสงอรุณ: 770306,
  กำเนิดนพคุณ: 770401,
  พงศ์ประศาสน์: 770402,
  ร่อนทอง: 770403,
  ธงชัย: 770404,
  ชัยเกษม: 770405,
  ทองมงคล: 770406,
  แม่รำพึง: 770407,
  ปากแพรก: 770501,
  บางสะพาน: 770502,
  ทรายทอง: 770503,
  ช้างแรก: 770504,
  ไชยราช: 770505,
  ปราณบุรี: 770601,
  เขาน้อย: 770602,
  ปากน้ำปราณ: 770604,
  หนองตาแต้ม: 770607,
  วังก์พง: 770608,
  เขาจ้าว: 770609,
  หัวหิน: 770701,
  หนองแก: 770702,
  หินเหล็กไฟ: 770703,
  หนองพลับ: 770704,
  ทับใต้: 770705,
  ห้วยสัตว์ใหญ่: 770706,
  บึงนคร: 770707,
  สามร้อยยอด: 770801,
  ศิลาลอย: 770802,
  ไร่เก่า: 770803,
  ศาลาลัย: 770804,
  ไร่ใหม่: 770805,
  ในเมือง: 800101,
  ท่าวัง: 800102,
  คลัง: 800103,
  ท่าไร่: 800106,
  ปากนคร: 800107,
  นาทราย: 800108,
  กำแพงเซา: 800112,
  ไชยมนตรี: 800113,
  มะม่วงสองต้น: 800114,
  นาเคียน: 800115,
  ท่างิ้ว: 800116,
  โพธิ์เสด็จ: 800118,
  บางจาก: 800119,
  ปากพูน: 800120,
  ท่าซัก: 800121,
  ท่าเรือ: 800122,
  พรหมโลก: 800201,
  บ้านเกาะ: 800202,
  อินคีรี: 800203,
  ทอนหงส์: 800204,
  นาเรียง: 800205,
  เขาแก้ว: 800301,
  ลานสะกา: 800302,
  ท่าดี: 800303,
  กำโลน: 800304,
  ขุนทะเล: 800305,
  ฉวาง: 800401,
  ละอาย: 800403,
  นาแว: 800404,
  ไม้เรียง: 800405,
  กะเปียด: 800406,
  นากะชะ: 800407,
  ห้วยปริก: 800409,
  ไสหร้า: 800410,
  นาเขลียง: 800415,
  จันดี: 800416,
  พิปูน: 800501,
  กะทูน: 800502,
  เขาพระ: 800503,
  ยางค้อม: 800504,
  ควนกลาง: 800505,
  เชียรใหญ่: 800601,
  ท่าขนาน: 800603,
  บ้านกลาง: 800604,
  บ้านเนิน: 800605,
  ไสหมาก: 800606,
  ท้องลำเจียก: 800607,
  เสือหึง: 800610,
  การะเกด: 800611,
  เขาพระบาท: 800612,
  แม่เจ้าอยู่หัว: 800613,
  ชะอวด: 800701,
  ท่าเสม็ด: 800702,
  ท่าประจะ: 800703,
  เคร็ง: 800704,
  วังอ่าง: 800705,
  บ้านตูล: 800706,
  ขอนหาด: 800707,
  เกาะขันธ์: 800708,
  ควนหนองหงษ์: 800709,
  เขาพระทอง: 800710,
  นางหลง: 800711,
  ท่าศาลา: 800801,
  กลาย: 800802,
  ท่าขึ้น: 800803,
  หัวตะพาน: 800804,
  สระแก้ว: 800806,
  โมคลาน: 800807,
  ไทยบุรี: 800809,
  ดอนตะโก: 800810,
  ตลิ่งชัน: 800811,
  โพธิ์ทอง: 800813,
  ปากแพรก: 800901,
  ชะมาย: 800902,
  หนองหงส์: 800903,
  ควนกรด: 800904,
  นาไม้ไผ่: 800905,
  นาหลวงเสน: 800906,
  เขาโร: 800907,
  กะปาง: 800908,
  ที่วัง: 800909,
  น้ำตก: 800910,
  ถ้ำใหญ่: 800911,
  นาโพธิ์: 800912,
  เขาขาว: 800913,
  นาบอน: 801001,
  ทุ่งสง: 801002,
  แก้วแสน: 801003,
  ท่ายาง: 801101,
  ทุ่งสัง: 801102,
  ทุ่งใหญ่: 801103,
  กุแหระ: 801104,
  ปริก: 801105,
  บางรูป: 801106,
  กรุงหยัน: 801107,
  ปากพนัง: 801201,
  คลองน้อย: 801202,
  ป่าระกำ: 801203,
  ชะเมา: 801204,
  คลองกระบือ: 801205,
  เกาะทวด: 801206,
  บ้านใหม่: 801207,
  หูล่อง: 801208,
  แหลมตะลุมพุก: 801209,
  ปากพนังฝั่งตะวันตก: 801210,
  บางศาลา: 801211,
  บางพระ: 801212,
  บางตะพง: 801213,
  ปากพนังฝั่งตะวันออก: 801214,
  บ้านเพิง: 801215,
  ท่าพยา: 801216,
  ปากแพรก: 801217,
  ขนาบนาก: 801218,
  ร่อนพิบูลย์: 801301,
  หินตก: 801302,
  เสาธง: 801303,
  ควนเกย: 801304,
  ควนพัง: 801305,
  ควนชุม: 801306,
  สิชล: 801401,
  ทุ่งปรัง: 801402,
  ฉลอง: 801403,
  เสาเภา: 801404,
  เปลี่ยน: 801405,
  สี่ขีด: 801406,
  เทพราช: 801407,
  เขาน้อย: 801408,
  ทุ่งใส: 801409,
  ขนอม: 801501,
  ควนทอง: 801502,
  ท้องเนียน: 801503,
  หัวไทร: 801601,
  หน้าสตน: 801602,
  ทรายขาว: 801603,
  แหลม: 801604,
  เขาพังไกร: 801605,
  บ้านราม: 801606,
  บางนบ: 801607,
  ท่าซอม: 801608,
  ควนชะลิก: 801609,
  รามแก้ว: 801610,
  เกาะเพชร: 801611,
  บางขัน: 801701,
  บ้านลำนาว: 801702,
  วังหิน: 801703,
  บ้านนิคม: 801704,
  ถ้ำพรรณรา: 801801,
  คลองเส: 801802,
  ดุสิต: 801803,
  บ้านควนมุด: 801901,
  บ้านชะอวด: 801902,
  ควนหนองคว้า: 801903,
  ทุ่งโพธิ์: 801904,
  นาหมอบุญ: 801905,
  สามตำบล: 801906,
  นาพรุ: 802001,
  นาสาร: 802002,
  ท้ายสำเภา: 802003,
  ช้างซ้าย: 802004,
  นบพิตำ: 802101,
  กรุงชิง: 802102,
  กะหรอ: 802103,
  นาเหรง: 802104,
  ช้างกลาง: 802201,
  หลักช้าง: 802202,
  สวนขัน: 802203,
  เชียรเขา: 802301,
  ดอนตรอ: 802302,
  สวนหลวง: 802303,
  ทางพูน: 802304,
  ปากน้ำ: 810101,
  กระบี่ใหญ่: 810102,
  กระบี่น้อย: 810103,
  เขาคราม: 810105,
  เขาทอง: 810106,
  ทับปริก: 810111,
  ไสไทย: 810115,
  อ่าวนาง: 810116,
  หนองทะเล: 810117,
  คลองประสงค์: 810118,
  เขาพนม: 810201,
  เขาดิน: 810202,
  สินปุน: 810203,
  พรุเตียว: 810204,
  หน้าเขา: 810205,
  โคกหาร: 810206,
  เกาะลันตาใหญ่: 810301,
  เกาะลันตาน้อย: 810302,
  เกาะกลาง: 810303,
  คลองยาง: 810304,
  ศาลาด่าน: 810305,
  คลองท่อมใต้: 810401,
  คลองท่อมเหนือ: 810402,
  คลองพน: 810403,
  ทรายขาว: 810404,
  ห้วยน้ำขาว: 810405,
  พรุดินนา: 810406,
  เพหลา: 810407,
  'ลำทับ*': 810499,
  อ่าวลึกใต้: 810501,
  แหลมสัก: 810502,
  นาเหนือ: 810503,
  คลองหิน: 810504,
  อ่าวลึกน้อย: 810505,
  อ่าวลึกเหนือ: 810506,
  เขาใหญ่: 810507,
  คลองยา: 810508,
  บ้านกลาง: 810509,
  ปลายพระยา: 810601,
  เขาเขน: 810602,
  เขาต่อ: 810603,
  คีรีวง: 810604,
  ลำทับ: 810701,
  ดินอุดม: 810702,
  ทุ่งไทรทอง: 810703,
  ดินแดง: 810704,
  เหนือคลอง: 810801,
  เกาะศรีบอยา: 810802,
  คลองขนาน: 810803,
  คลองเขม้า: 810804,
  โคกยาง: 810805,
  ตลิ่งชัน: 810806,
  ปกาสัย: 810807,
  ห้วยยูง: 810808,
  ท้ายช้าง: 820101,
  นบปริง: 820102,
  ถ้ำน้ำผุด: 820103,
  บางเตย: 820104,
  ตากแดด: 820105,
  สองแพรก: 820106,
  ทุ่งคาโงก: 820107,
  เกาะปันหยี: 820108,
  ป่ากอ: 820109,
  เกาะยาวน้อย: 820201,
  เกาะยาวใหญ่: 820202,
  พรุใน: 820203,
  กะปง: 820301,
  ท่านา: 820302,
  เหมาะ: 820303,
  เหล: 820304,
  รมณีย์: 820305,
  ถ้ำ: 820401,
  กระโสม: 820402,
  กะไหล: 820403,
  ท่าอยู่: 820404,
  หล่อยูง: 820405,
  โคกกลอย: 820406,
  คลองเคียน: 820407,
  ตะกั่วป่า: 820501,
  บางนายสี: 820502,
  บางไทร: 820503,
  บางม่วง: 820504,
  ตำตัว: 820505,
  โคกเคียน: 820506,
  คึกคัก: 820507,
  เกาะคอเขา: 820508,
  คุระ: 820601,
  บางวัน: 820602,
  เกาะพระทอง: 820603,
  แม่นางขาว: 820605,
  ทับปุด: 820701,
  มะรุ่ย: 820702,
  บ่อแสน: 820703,
  ถ้ำทองหลาง: 820704,
  โคกเจริญ: 820705,
  บางเหรียง: 820706,
  ท้ายเหมือง: 820801,
  นาเตย: 820802,
  บางทอง: 820803,
  ทุ่งมะพร้าว: 820804,
  ลำภี: 820805,
  ลำแก่น: 820806,
  ตลาดใหญ่: 830101,
  ตลาดเหนือ: 830102,
  เกาะแก้ว: 830103,
  รัษฎา: 830104,
  วิชิต: 830105,
  ฉลอง: 830106,
  ราไวย์: 830107,
  กะรน: 830108,
  กะทู้: 830201,
  ป่าตอง: 830202,
  กมลา: 830203,
  เทพกระษัตรี: 830301,
  ศรีสุนทร: 830302,
  เชิงทะเล: 830303,
  ป่าคลอก: 830304,
  ไม้ขาว: 830305,
  สาคู: 830306,
  ตลาด: 840101,
  มะขามเตี้ย: 840102,
  วัดประดู่: 840103,
  ขุนทะเล: 840104,
  บางใบไม้: 840105,
  บางชนะ: 840106,
  คลองน้อย: 840107,
  บางไทร: 840108,
  บางโพธิ์: 840109,
  บางกุ้ง: 840110,
  คลองฉนาก: 840111,
  ท่าทองใหม่: 840201,
  ท่าทอง: 840202,
  กะแดะ: 840203,
  ทุ่งกง: 840204,
  กรูด: 840205,
  ช้างซ้าย: 840206,
  พลายวาส: 840207,
  ป่าร่อน: 840208,
  ตะเคียนทอง: 840209,
  ช้างขวา: 840210,
  ท่าอุแท: 840211,
  ทุ่งรัง: 840212,
  คลองสระ: 840213,
  ดอนสัก: 840301,
  ชลคราม: 840302,
  ไชยคราม: 840303,
  ปากแพรก: 840304,
  อ่างทอง: 840401,
  ลิปะน้อย: 840402,
  ตลิ่งงาม: 840403,
  หน้าเมือง: 840404,
  มะเร็ต: 840405,
  บ่อผุด: 840406,
  แม่น้ำ: 840407,
  เกาะพะงัน: 840501,
  บ้านใต้: 840502,
  เกาะเต่า: 840503,
  ตลาดไชยา: 840601,
  พุมเรียง: 840602,
  เลม็ด: 840603,
  เวียง: 840604,
  ทุ่ง: 840605,
  ป่าเว: 840606,
  ตะกรบ: 840607,
  โมถ่าย: 840608,
  ปากหมาก: 840609,
  ท่าชนะ: 840701,
  สมอทอง: 840702,
  ประสงค์: 840703,
  คันธุลี: 840704,
  วัง: 840705,
  คลองพา: 840706,
  ท่าขนอน: 840801,
  บ้านยาง: 840802,
  น้ำหัก: 840803,
  กะเปา: 840806,
  ท่ากระดาน: 840807,
  ย่านยาว: 840808,
  ถ้ำสิงขร: 840809,
  บ้านทำเนียบ: 840810,
  เขาวง: 840901,
  พะแสง: 840902,
  พรุไทย: 840903,
  เขาพัง: 840904,
  พนม: 841001,
  ต้นยวน: 841002,
  คลองศก: 841003,
  พลูเถื่อน: 841004,
  พังกาญจน์: 841005,
  คลองชะอุ่น: 841006,
  ท่าฉาง: 841101,
  ท่าเคย: 841102,
  คลองไทร: 841103,
  เขาถ่าน: 841104,
  เสวียด: 841105,
  ปากฉลุย: 841106,
  นาสาร: 841201,
  พรุพี: 841202,
  ทุ่งเตา: 841203,
  ลำพูน: 841204,
  ท่าชี: 841205,
  ควนศรี: 841206,
  ควนสุบรรณ: 841207,
  คลองปราบ: 841208,
  น้ำพุ: 841209,
  ทุ่งเตาใหม่: 841210,
  เพิ่มพูนทรัพย์: 841211,
  บ้านนา: 841301,
  ท่าเรือ: 841302,
  ทรัพย์ทวี: 841303,
  นาใต้: 841304,
  เคียนซา: 841401,
  พ่วงพรมคร: 841402,
  เขาตอก: 841403,
  อรัญคามวารี: 841404,
  บ้านเสด็จ: 841405,
  เวียงสระ: 841501,
  บ้านส้อง: 841502,
  คลองฉนวน: 841503,
  ทุ่งหลวง: 841504,
  เขานิพันธ์: 841505,
  อิปัน: 841601,
  สินปุน: 841602,
  บางสวรรค์: 841603,
  ไทรขึง: 841604,
  สินเจริญ: 841605,
  ไทรโสภา: 841606,
  สาคู: 841607,
  ท่าข้าม: 841701,
  ท่าสะท้อน: 841702,
  ลีเล็ด: 841703,
  บางมะเดื่อ: 841704,
  บางเดือน: 841705,
  ท่าโรงช้าง: 841706,
  กรูด: 841707,
  พุนพิน: 841708,
  บางงอน: 841709,
  ศรีวิชัย: 841710,
  น้ำรอบ: 841711,
  มะลวน: 841712,
  หัวเตย: 841713,
  หนองไทร: 841714,
  เขาหัวควาย: 841715,
  ตะปาน: 841716,
  สองแพรก: 841801,
  ชัยบุรี: 841802,
  คลองน้อย: 841803,
  ไทรทอง: 841804,
  ตะกุกใต้: 841901,
  ตะกุกเหนือ: 841902,
  เขานิเวศน์: 850101,
  ราชกรูด: 850102,
  หงาว: 850103,
  บางริ้น: 850104,
  ปากน้ำ: 850105,
  บางนอน: 850106,
  หาดส้มแป้น: 850107,
  ทรายแดง: 850108,
  เกาะพยาม: 850109,
  ละอุ่นใต้: 850201,
  ละอุ่นเหนือ: 850202,
  บางพระใต้: 850203,
  บางพระเหนือ: 850204,
  บางแก้ว: 850205,
  ในวงเหนือ: 850206,
  ในวงใต้: 850207,
  ม่วงกลวง: 850301,
  กะเปอร์: 850302,
  เชี่ยวเหลียง: 850303,
  บ้านนา: 850304,
  บางหิน: 850305,
  น้ำจืด: 850401,
  น้ำจืดน้อย: 850402,
  มะมุ: 850403,
  ปากจั่น: 850404,
  ลำเลียง: 850405,
  'จ.ป.ร.': 850406,
  บางใหญ่: 850407,
  นาคา: 850501,
  กำพวน: 850502,
  ท่าตะเภา: 860101,
  ปากน้ำ: 860102,
  ท่ายาง: 860103,
  บางหมาก: 860104,
  นาทุ่ง: 860105,
  นาชะอัง: 860106,
  ตากแดด: 860107,
  บางลึก: 860108,
  หาดพันไกร: 860109,
  วังไผ่: 860110,
  วังใหม่: 860111,
  บ้านนา: 860112,
  ขุนกระทิง: 860113,
  ทุ่งคา: 860114,
  วิสัยเหนือ: 860115,
  หาดทรายรี: 860116,
  ถ้ำสิงห์: 860117,
  ท่าแซะ: 860201,
  คุริง: 860202,
  สลุย: 860203,
  นากระตาม: 860204,
  รับร่อ: 860205,
  ท่าข้าม: 860206,
  หงษ์เจริญ: 860207,
  หินแก้ว: 860208,
  ทรัพย์อนันต์: 860209,
  สองพี่น้อง: 860210,
  บางสน: 860301,
  ทะเลทรัพย์: 860302,
  สะพลี: 860303,
  ชุมโค: 860304,
  ดอนยาง: 860305,
  ปากคลอง: 860306,
  เขาไชยราช: 860307,
  หลังสวน: 860401,
  ขันเงิน: 860402,
  ท่ามะพลา: 860403,
  นาขา: 860404,
  นาพญา: 860405,
  บ้านควน: 860406,
  บางมะพร้าว: 860407,
  บางน้ำจืด: 860408,
  ปากน้ำ: 860409,
  พ้อแดง: 860410,
  แหลมทราย: 860411,
  วังตะกอ: 860412,
  หาดยาย: 860413,
  ละแม: 860501,
  ทุ่งหลวง: 860502,
  สวนแตง: 860503,
  ทุ่งคาวัด: 860504,
  พะโต๊ะ: 860601,
  ปากทรง: 860602,
  ปังหวาน: 860603,
  พระรักษ์: 860604,
  นาโพธิ์: 860701,
  สวี: 860702,
  ทุ่งระยะ: 860703,
  ท่าหิน: 860704,
  ปากแพรก: 860705,
  ด่านสวี: 860706,
  ครน: 860707,
  วิสัยใต้: 860708,
  นาสัก: 860709,
  เขาทะลุ: 860710,
  เขาค่าย: 860711,
  ปากตะโก: 860801,
  ทุ่งตะไคร: 860802,
  ตะโก: 860803,
  ช่องไม้แก้ว: 860804,
  บ่อยาง: 900101,
  เขารูปช้าง: 900102,
  เกาะแต้ว: 900103,
  พะวง: 900104,
  ทุ่งหวัง: 900105,
  เกาะยอ: 900106,
  จะทิ้งพระ: 900201,
  กระดังงา: 900202,
  สนามชัย: 900203,
  ดีหลวง: 900204,
  ชุมพล: 900205,
  คลองรี: 900206,
  คูขุด: 900207,
  ท่าหิน: 900208,
  วัดจันทร์: 900209,
  บ่อแดง: 900210,
  บ่อดาน: 900211,
  บ้านนา: 900301,
  ป่าชิง: 900302,
  สะพานไม้แก่น: 900303,
  สะกอม: 900304,
  นาหว้า: 900305,
  นาทับ: 900306,
  น้ำขาว: 900307,
  ขุนตัดหวาย: 900308,
  ท่าหมอไทร: 900309,
  จะโหนง: 900310,
  คู: 900311,
  แค: 900312,
  คลองเปียะ: 900313,
  ตลิ่งชัน: 900314,
  นาทวี: 900401,
  ฉาง: 900402,
  นาหมอศรี: 900403,
  คลองทราย: 900404,
  ปลักหนู: 900405,
  ท่าประดู่: 900406,
  สะท้อน: 900407,
  ทับช้าง: 900408,
  ประกอบ: 900409,
  คลองกวาง: 900410,
  เทพา: 900501,
  ปากบาง: 900502,
  เกาะสะบ้า: 900503,
  ลำไพล: 900504,
  ท่าม่วง: 900505,
  วังใหญ่: 900506,
  สะกอม: 900507,
  สะบ้าย้อย: 900601,
  ทุ่งพอ: 900602,
  เปียน: 900603,
  บ้านโหนด: 900604,
  จะแหน: 900605,
  คูหา: 900606,
  เขาแดง: 900607,
  บาโหย: 900608,
  ธารคีรี: 900609,
  ระโนด: 900701,
  คลองแดน: 900702,
  ตะเครียะ: 900703,
  ท่าบอน: 900704,
  บ้านใหม่: 900705,
  บ่อตรุ: 900706,
  ปากแตระ: 900707,
  พังยาง: 900708,
  ระวะ: 900709,
  วัดสน: 900710,
  บ้านขาว: 900711,
  แดนสงวน: 900712,
  เกาะใหญ่: 900801,
  โรง: 900802,
  เชิงแส: 900803,
  กระแสสินธุ์: 900804,
  กำแพงเพชร: 900901,
  ท่าชะมวง: 900902,
  คูหาใต้: 900903,
  ควนรู: 900904,
  เขาพระ: 900909,
  สะเดา: 901001,
  ปริก: 901002,
  พังลา: 901003,
  สำนักแต้ว: 901004,
  ทุ่งหมอ: 901005,
  ท่าโพธิ์: 901006,
  ปาดังเบซาร์: 901007,
  สำนักขาม: 901008,
  เขามีเกียรติ: 901009,
  หาดใหญ่: 901101,
  ควนลัง: 901102,
  คูเต่า: 901103,
  คอหงส์: 901104,
  คลองแห: 901105,
  คลองอู่ตะเภา: 901107,
  ฉลุง: 901108,
  ทุ่งใหญ่: 901111,
  ทุ่งตำเสา: 901112,
  ท่าข้าม: 901113,
  น้ำน้อย: 901114,
  บ้านพรุ: 901116,
  พะตง: 901118,
  นาหม่อม: 901201,
  พิจิตร: 901202,
  ทุ่งขมิ้น: 901203,
  คลองหรัง: 901204,
  รัตภูมิ: 901301,
  ควนโส: 901302,
  ห้วยลึก: 901303,
  บางเหรียง: 901304,
  บางกล่ำ: 901401,
  ท่าช้าง: 901402,
  แม่ทอม: 901403,
  บ้านหาร: 901404,
  ชิงโค: 901501,
  สทิงหม้อ: 901502,
  ทำนบ: 901503,
  รำแดง: 901504,
  วัดขนุน: 901505,
  ชะแล้: 901506,
  ปากรอ: 901507,
  ป่าขาด: 901508,
  หัวเขา: 901509,
  บางเขียด: 901510,
  ม่วงงาม: 901511,
  คลองหอยโข่ง: 901601,
  ทุ่งลาน: 901602,
  โคกม่วง: 901603,
  คลองหลา: 901604,
  พิมาน: 910101,
  คลองขุด: 910102,
  ควนขัน: 910103,
  บ้านควน: 910104,
  ฉลุง: 910105,
  เกาะสาหร่าย: 910106,
  ตันหยงโป: 910107,
  เจ๊ะบิลัง: 910108,
  ตำมะลัง: 910109,
  ปูยู: 910110,
  ควนโพธิ์: 910111,
  เกตรี: 910112,
  ควนโดน: 910201,
  ควนสตอ: 910202,
  ย่านซื่อ: 910203,
  วังประจัน: 910204,
  ทุ่งนุ้ย: 910301,
  ควนกาหลง: 910302,
  อุใดเจริญ: 910303,
  ท่าแพ: 910401,
  'แป-ระ': 910402,
  สาคร: 910403,
  ท่าเรือ: 910404,
  กำแพง: 910501,
  ละงู: 910502,
  เขาขาว: 910503,
  ปากน้ำ: 910504,
  น้ำผุด: 910505,
  แหลมสน: 910506,
  ทุ่งหว้า: 910601,
  นาทอน: 910602,
  ขอนคลาน: 910603,
  ทุ่งบุหลัง: 910604,
  ป่าแก่บ่อหิน: 910605,
  ปาล์มพัฒนา: 910701,
  นิคมพัฒนา: 910702,
  ทับเที่ยง: 920101,
  นาพละ: 920104,
  บ้านควน: 920105,
  นาบินหลา: 920106,
  ควนปริง: 920107,
  นาโยงใต้: 920108,
  บางรัก: 920109,
  โคกหล่อ: 920110,
  นาโต๊ะหมิง: 920113,
  หนองตรุด: 920114,
  น้ำผุด: 920115,
  นาตาล่วง: 920117,
  บ้านโพธิ์: 920118,
  นาท่ามเหนือ: 920119,
  นาท่ามใต้: 920120,
  กันตัง: 920201,
  ควนธานี: 920202,
  บางหมาก: 920203,
  บางเป้า: 920204,
  วังวน: 920205,
  กันตังใต้: 920206,
  โคกยาง: 920207,
  คลองลุ: 920208,
  ย่านซื่อ: 920209,
  บ่อน้ำร้อน: 920210,
  บางสัก: 920211,
  นาเกลือ: 920212,
  เกาะลิบง: 920213,
  คลองชีล้อม: 920214,
  ย่านตาขาว: 920301,
  หนองบ่อ: 920302,
  นาชุมเห็ด: 920303,
  ในควน: 920304,
  โพรงจระเข้: 920305,
  ทุ่งกระบือ: 920306,
  ถ้ำเจริญ: 970001,
  ปากคาด: 380501,
  จันทราสุข: 104505,
  โนนสว่าง: 380502,
  หอคำ: 380503,
  บึงกาฬ: 380504,
  พญาไท: 101402,
  พระโขนงใต้: 100906,
  รัชดาภิเษก: 102602,
  ซาง: 380505,
  คลองบางพราน: 104904,
  ทับช้าง: 104402,
  บางนาใต้: 104702,
  บึงโขงหลง: 380506,
  ท่าสะอาด: 380507,
  พัฒนาการ: 103402,
  บางนาเหนือ: 104703,
  อ่อนนุช: 103403,
  ราษฎร์พัฒนา: 104403,
  '910': 910703,
  คลองสามประเวศ: 101103,
  คลองสาม: 130203,
  สระทอง: 301803,
  สระทอง: 312306,
  สระทอง: 312307,
  บางบอน: 105002,
}

export const area = {
  กิ่งอำเภอนบพิตำ: 8021,
  กิ่งอำเภอช้างกลาง: 8022,
  เฉลิมพระเกียรติ: 8023,
  เทศบาลตำบลปากแพรก: 8097,
  เทศบาลเมืองปากพนัง: 8098,
  เทศบาลเมืองนครศรีธรร: 8099,
  เมืองกระบี่: 8101,
  เขาพนม: 8102,
  เกาะลันตา: 8103,
  คลองท่อม: 8104,
  อ่าวลึก: 8105,
  ปลายพระยา: 8106,
  ลำทับ: 8107,
  เหนือคลอง: 8108,
  เทศบาลเมืองกระบี่: 8199,
  เมืองพังงา: 8201,
  เกาะยาว: 8202,
  กะปง: 8203,
  ตะกั่วทุ่ง: 8204,
  ตะกั่วป่า: 8205,
  คุระบุรี: 8206,
  ทับปุด: 8207,
  ท้ายเหมือง: 8208,
  เทศบาลเมืองตะกั่วป่า: 8298,
  เทศบาลเมืองพังงา: 8299,
  เมืองภูเก็ต: 8301,
  กะทู้: 8302,
  ถลาง: 8303,
  เทศบาลเมืองภูเก็ต: 8399,
  เมืองสุราษฎร์ธานี: 8401,
  กาญจนดิษฐ์: 8402,
  ดอนสัก: 8403,
  เกาะสมุย: 8404,
  เกาะพะงัน: 8405,
  ไชยา: 8406,
  ท่าชนะ: 8407,
  คีรีรัฐนิคม: 8408,
  บ้านตาขุน: 8409,
  พนม: 8410,
  ท่าฉาง: 8411,
  บ้านนาสาร: 8412,
  บ้านนาเดิม: 8413,
  เคียนซา: 8414,
  เวียงสระ: 8415,
  พระแสง: 8416,
  พุนพิน: 8417,
  ชัยบุรี: 8418,
  กิ่งอำเภอวิภาวดี: 8419,
  เทศบาลตำบลท่าข้าม: 8497,
  เทศบาลตำบลนาสาร: 8498,
  เทศบาลเมืองสุราษฎร์ธ: 8499,
  เมืองระนอง: 8501,
  ละอุ่น: 8502,
  กะเปอร์: 8503,
  กระบุรี: 8504,
  กิ่งอำเภอสุขสำราญ: 8505,
  เทศบาลเมืองระนอง: 8599,
  เมืองชุมพร: 8601,
  ท่าแซะ: 8602,
  ปะทิว: 8603,
  หลังสวน: 8604,
  ละแม: 8605,
  พะโต๊ะ: 8606,
  สวี: 8607,
  ทุ่งตะโก: 8608,
  เทศบาลตำบลหลังสวน: 8698,
  เทศบาลเมืองชุมพร: 8699,
  เมืองสงขลา: 9001,
  สทิงพระ: 9002,
  จะนะ: 9003,
  นาทวี: 9004,
  เทพา: 9005,
  สะบ้าย้อย: 9006,
  ระโนด: 9007,
  กระแสสินธุ์: 9008,
  รัตภูมิ: 9009,
  สะเดา: 9010,
  หาดใหญ่: 9011,
  นาหม่อม: 9012,
  ควนเนียง: 9013,
  บางกล่ำ: 9014,
  สิงหนคร: 9015,
  คลองหอยโข่ง: 9016,
  เทศบาลตำบลสะเดา: 9097,
  เทศบาลเมืองหาดใหญ่: 9098,
  เทศบาลเมืองสงขลา: 9099,
  เมืองสตูล: 9101,
  ควนโดน: 9102,
  ควนกาหลง: 9103,
  ท่าแพ: 9104,
  ละงู: 9105,
  ทุ่งหว้า: 9106,
  กิ่งอำเภอมะนัง: 9107,
  เทศบาลเมืองสตูล: 9199,
  เมืองตรัง: 9201,
  กันตัง: 9202,
  ย่านตาขาว: 9203,
  ปะเหลียน: 9204,
  สิเกา: 9205,
  ห้วยยอด: 9206,
  วังวิเศษ: 9207,
  นาโยง: 9208,
  รัษฎา: 9209,
  กิ่งอำเภอหาดสำราญ: 9210,
  เทศบาลตำบลห้วยยอด: 9297,
  เทศบาลตำบลกันตัง: 9298,
  เทศบาลเมืองตรัง: 9299,
  เมืองพัทลุง: 9301,
  กงหรา: 9302,
  เขาชัยสน: 9303,
  ตะโหมด: 9304,
  ควนขนุน: 9305,
  ปากพะยูน: 9306,
  ศรีบรรพต: 9307,
  ป่าบอน: 9308,
  บางแก้ว: 9309,
  ป่าพะยอม: 9310,
  กิ่งอำเภอศรีนครินทร์: 9311,
  เทศบาลเมืองพัทลุง: 9399,
  เมืองปัตตานี: 9401,
  โคกโพธิ์: 9402,
  หนองจิก: 9403,
  ปะนาเระ: 9404,
  มายอ: 9405,
  ทุ่งยางแดง: 9406,
  สายบุรี: 9407,
  ไม้แก่น: 9408,
  ยะหริ่ง: 9409,
  ยะรัง: 9410,
  กะพ้อ: 9411,
  แม่ลาน: 9412,
  เทศบาลตำบลตะลุปัน: 9498,
  เทศบาลเมืองปัตตานี: 9499,
  เมืองยะลา: 9501,
  เบตง: 9502,
  บันนังสตา: 9503,
  ธารโต: 9504,
  ยะหา: 9505,
  รามัน: 9506,
  กาบัง: 9507,
  กิ่งอำเภอกรงปินัง: 9508,
  เทศบาลตำบลเบตง: 9598,
  เทศบาลเมืองยะลา: 9599,
  เมืองนราธิวาส: 9601,
  ตากใบ: 9602,
  บาเจาะ: 9603,
  ยี่งอ: 9604,
  ระแงะ: 9605,
  รือเสาะ: 9606,
  ศรีสาคร: 9607,
  แว้ง: 9608,
  สุคิริน: 9609,
  'สุไหงโก-ลก': 9610,
  สุไหงปาดี: 9611,
  จะแนะ: 9612,
  เจาะไอร้อง: 9613,
  'เทศบาลตำบลสุไหงโก-ลก': 9698,
  เทศบาลเมืองนราธิวาส: 9699,
  กิ่งอำเภอโนนนารายณ์: 3217,
  เทศบาลเมืองสุรินทร์: 3299,
  เมืองศรีสะเกษ: 3301,
  ยางชุมน้อย: 3302,
  กันทรารมย์: 3303,
  กันทรลักษ์: 3304,
  ขุขันธ์: 3305,
  ไพรบึง: 3306,
  ปรางค์กู่: 3307,
  ขุนหาญ: 3308,
  ราษีไศล: 3309,
  อุทุมพรพิสัย: 3310,
  บึงบูรพ์: 3311,
  ห้วยทับทัน: 3312,
  โนนคูณ: 3313,
  ศรีรัตนะ: 3314,
  น้ำเกลี้ยง: 3315,
  วังหิน: 3316,
  ภูสิงห์: 3317,
  เมืองจันทร์: 3318,
  เบญจลักษ์: 3319,
  พยุห์: 3320,
  กิ่งอำเภอโพธิ์ศรีสุวรรณ: 3321,
  กิ่งอำเภอศิลาลาด: 3322,
  เทศบาลเมืองศรีสะเกษ: 3399,
  เมืองอุบลราชธานี: 3401,
  ศรีเมืองใหม่: 3402,
  โขงเจียม: 3403,
  เขื่องใน: 3404,
  เขมราฐ: 3405,
  เดชอุดม: 3407,
  นาจะหลวย: 3408,
  น้ำยืน: 3409,
  บุณฑริก: 3410,
  ตระการพืชผล: 3411,
  กุดข้าวปุ้น: 3412,
  ม่วงสามสิบ: 3414,
  วารินชำราบ: 3415,
  พิบูลมังสาหาร: 3419,
  ตาลสุม: 3420,
  โพธิ์ไทร: 3421,
  สำโรง: 3422,
  ดอนมดแดง: 3424,
  สิรินธร: 3425,
  ทุ่งศรีอุดม: 3426,
  กิ่งอำเภอนาเยีย: 3429,
  กิ่งอำเภอนาตาล: 3430,
  กิ่งอำเภอเหล่าเสือโก้ก: 3431,
  กิ่งอำเภอสว่างวีระวงศ์: 3432,
  กิ่งอำเภอน้ำขุ่น: 3433,
  เทศบาลตำบลพิบูลย์มัง: 3497,
  เทศบาลตำบลวารินชำราบ: 3498,
  เทศบาลเมืองอุบลราชธา: 3499,
  เมืองยโสธร: 3501,
  ทรายมูล: 3502,
  กุดชุม: 3503,
  คำเขื่อนแก้ว: 3504,
  ป่าติ้ว: 3505,
  มหาชนะชัย: 3506,
  ค้อวัง: 3507,
  เลิงนกทา: 3508,
  ไทยเจริญ: 3509,
  เทศบาลเมืองยโสธร: 3599,
  เมืองชัยภูมิ: 3601,
  บ้านเขว้า: 3602,
  คอนสวรรค์: 3603,
  เกษตรสมบูรณ์: 3604,
  หนองบัวแดง: 3605,
  จัตุรัส: 3606,
  บำเหน็จณรงค์: 3607,
  หนองบัวระเหว: 3608,
  เทพสถิต: 3609,
  ภูเขียว: 3610,
  บ้านแท่น: 3611,
  แก้งคร้อ: 3612,
  คอนสาร: 3613,
  ภักดีชุมพล: 3614,
  เนินสง่า: 3615,
  กิ่งอำเภอซับใหญ่: 3616,
  เทศบาลเมืองชัยภูมิ: 3699,
  เมืองอำนาจเจริญ: 3701,
  ชานุมาน: 3702,
  ปทุมราชวงศา: 3703,
  พนา: 3704,
  เสนางคนิคม: 3705,
  หัวตะพาน: 3706,
  ลืออำนาจ: 3707,
  เมืองหนองบัวลำภู: 3901,
  นากลาง: 3902,
  โนนสัง: 3903,
  ศรีบุญเรือง: 3904,
  สุวรรณคูหา: 3905,
  นาวัง: 3906,
  เมืองขอนแก่น: 4001,
  บ้านฝาง: 4002,
  พระยืน: 4003,
  หนองเรือ: 4004,
  ชุมแพ: 4005,
  สีชมพู: 4006,
  น้ำพอง: 4007,
  กิ่งอำเภอหนองฮี: 4519,
  กิ่งอำเภอทุ่งเขาหลวง: 4520,
  เทศบาลเมืองร้อยเอ็ด: 4599,
  เมืองกาฬสินธุ์: 4601,
  นามน: 4602,
  กมลาไสย: 4603,
  ร่องคำ: 4604,
  กุฉินารายณ์: 4605,
  เขาวง: 4606,
  ยางตลาด: 4607,
  ห้วยเม็ก: 4608,
  สหัสขันธ์: 4609,
  คำม่วง: 4610,
  ท่าคันโท: 4611,
  หนองกุงศรี: 4612,
  สมเด็จ: 4613,
  ห้วยผึ้ง: 4614,
  กิ่งอำเภอสามชัย: 4615,
  กิ่งอำเภอนาคู: 4616,
  กิ่งอำเภอดอนจาน: 4617,
  กิ่งอำเภอฆ้องชัย: 4618,
  เทศบาลเมืองกาฬสินธุ์: 4699,
  เมืองสกลนคร: 4701,
  กุสุมาลย์: 4702,
  กุดบาก: 4703,
  พรรณานิคม: 4704,
  พังโคน: 4705,
  วาริชภูมิ: 4706,
  นิคมน้ำอูน: 4707,
  วานรนิวาส: 4708,
  คำตากล้า: 4709,
  บ้านม่วง: 4710,
  อากาศอำนวย: 4711,
  สว่างแดนดิน: 4712,
  ส่องดาว: 4713,
  เต่างอย: 4714,
  โคกศรีสุพรรณ: 4715,
  เจริญศิลป์: 4716,
  โพนนาแก้ว: 4717,
  ภูพาน: 4718,
  เทศบาลเมืองสกลนคร: 4799,
  เมืองนครพนม: 4801,
  ปลาปาก: 4802,
  ท่าอุเทน: 4803,
  บ้านแพง: 4804,
  ธาตุพนม: 4805,
  เรณูนคร: 4806,
  นาแก: 4807,
  ศรีสงคราม: 4808,
  นาหว้า: 4809,
  โพนสวรรค์: 4810,
  นาทม: 4811,
  กิ่งอำเภอวังยาง: 4812,
  เทศบาลเมืองนครพนม: 4899,
  เมืองมุกดาหาร: 4901,
  นิคมคำสร้อย: 4902,
  ดอนตาล: 4903,
  ดงหลวง: 4904,
  คำชะอี: 4905,
  หว้านใหญ่: 4906,
  หนองสูง: 4907,
  เทศบาลเมืองมุกดาหาร: 4999,
  เมืองเชียงใหม่: 5001,
  จอมทอง: 5002,
  แม่แจ่ม: 5003,
  เชียงดาว: 5004,
  ดอยสะเก็ด: 5005,
  แม่แตง: 5006,
  แม่ริม: 5007,
  สะเมิง: 5008,
  ฝาง: 5009,
  แม่อาย: 5010,
  พร้าว: 5011,
  สันป่าตอง: 5012,
  สันกำแพง: 5013,
  สันทราย: 5014,
  หางดง: 5015,
  ฮอด: 5016,
  ดอยเต่า: 5017,
  อมก๋อย: 5018,
  สารภี: 5019,
  เวียงแหง: 5020,
  ไชยปราการ: 5021,
  แม่วาง: 5022,
  กิ่งอำเภอแม่ออน: 5023,
  กิ่งอำเภอดอยหล่อ: 5024,
  เทศบาลนครเชียงใหม่: 5099,
  เมืองลำพูน: 5101,
  แม่ทา: 5102,
  บ้านโฮ่ง: 5103,
  ลี้: 5104,
  ทุ่งหัวช้าง: 5105,
  ป่าซาง: 5106,
  บ้านธิ: 5107,
  กิ่งอำเภอเวียงหนองล่อง: 5108,
  เทศบาลเมืองลำพูน: 5199,
  เมืองลำปาง: 5201,
  แม่เมาะ: 5202,
  เกาะคา: 5203,
  เสริมงาม: 5204,
  งาว: 5205,
  แจ้ห่ม: 5206,
  วังเหนือ: 5207,
  เถิน: 5208,
  แม่พริก: 5209,
  แม่ทะ: 5210,
  สบปราบ: 5211,
  ห้างฉัตร: 5212,
  เมืองปาน: 5213,
  เทศบาลเมืองลำปาง: 5299,
  เมืองอุตรดิตถ์: 5301,
  ตรอน: 5302,
  ท่าปลา: 5303,
  ดุสิต: 1002,
  หนองจอก: 1003,
  บางรัก: 1004,
  บางเขน: 1005,
  บางกะปิ: 1006,
  ปทุมวัน: 1007,
  ป้อมปราบศัตรูพ่าย: 1008,
  พระโขนง: 1009,
  มีนบุรี: 1010,
  ลาดกระบัง: 1011,
  ยานนาวา: 1012,
  สัมพันธวงศ์: 1013,
  พญาไท: 1014,
  ธนบุรี: 1015,
  บางกอกใหญ่: 1016,
  ห้วยขวาง: 1017,
  คลองสาน: 1018,
  ตลิ่งชัน: 1019,
  บางกอกน้อย: 1020,
  บางขุนเทียน: 1021,
  ภาษีเจริญ: 1022,
  หนองแขม: 1023,
  ราษฎร์บูรณะ: 1024,
  บางพลัด: 1025,
  ดินแดง: 1026,
  บึงกุ่ม: 1027,
  สาทร: 1028,
  บางซื่อ: 1029,
  จตุจักร: 1030,
  บางคอแหลม: 1031,
  ประเวศ: 1032,
  คลองเตย: 1033,
  สวนหลวง: 1034,
  จอมทอง: 1035,
  ดอนเมือง: 1036,
  ราชเทวี: 1037,
  ลาดพร้าว: 1038,
  วัฒนา: 1039,
  บางแค: 1040,
  หลักสี่: 1041,
  สายไหม: 1042,
  คันนายาว: 1043,
  สะพานสูง: 1044,
  วังทองหลาง: 1045,
  คลองสามวา: 1046,
  บางนา: 1047,
  ทวีวัฒนา: 1048,
  ทุ่งครุ: 1049,
  บางบอน: 1050,
  เมืองสมุทรปราการ: 1101,
  บางบ่อ: 1102,
  บางพลี: 1103,
  พระประแดง: 1104,
  พระสมุทรเจดีย์: 1105,
  กิ่งอำเภอบางเสาธง: 1106,
  เทศบาลเมืองพระประแดง: 1198,
  เทศบาลเมืองสมุทรปราก: 1199,
  เมืองนนทบุรี: 1201,
  บางกรวย: 1202,
  บางใหญ่: 1203,
  บางบัวทอง: 1204,
  ไทรน้อย: 1205,
  ปากเกร็ด: 1206,
  เทศบาลตำบลปากเกร็ด: 1297,
  เทศบาลเมืองบางบัวทอง: 1298,
  เทศบาลเมืองนนทบุรี: 1299,
  เมืองปทุมธานี: 1301,
  คลองหลวง: 1302,
  ธัญบุรี: 1303,
  หนองเสือ: 1304,
  ลาดหลุมแก้ว: 1305,
  ลำลูกกา: 1306,
  สามโคก: 1307,
  เทศบาลเมืองปทุมธานี: 1399,
  พระนครศรีอยุธยา: 1401,
  ท่าเรือ: 1402,
  นครหลวง: 1403,
  บางไทร: 1404,
  บางบาล: 1405,
  บางปะอิน: 1406,
  บางปะหัน: 1407,
  ผักไห่: 1408,
  ภาชี: 1409,
  ลาดบัวหลวง: 1410,
  วังน้อย: 1411,
  เสนา: 1412,
  บางซ้าย: 1413,
  อุทัย: 1414,
  มหาราช: 1415,
  บ้านแพรก: 1416,
  เทศบาลตำบลท่าเรือ: 1497,
  เทศบาลเมืองเสนา: 1498,
  เทศบาลเมืองนครศรีอยุธยา: 1499,
  เมืองอ่างทอง: 1501,
  ไชโย: 1502,
  ป่าโมก: 1503,
  โพธิ์ทอง: 1504,
  แสวงหา: 1505,
  วิเศษชัยชาญ: 1506,
  สามโก้: 1507,
  เทศบาลตำบลป่าโมก: 1598,
  เทศบาลเมืองอ่างทอง: 1599,
  เมืองลพบุรี: 1601,
  พัฒนานิคม: 1602,
  โคกสำโรง: 1603,
  ชัยบาดาล: 1604,
  ท่าวุ้ง: 1605,
  บ้านหมี่: 1606,
  ท่าหลวง: 1607,
  สระโบสถ์: 1608,
  โคกเจริญ: 1609,
  ลำสนธิ: 1610,
  หนองม่วง: 1611,
  เทศบาลตำบลโคกสำโรง: 1697,
  เทศบาลเมืองบ้านหมี่: 1698,
  เทศบาลเมืองลพบุรี: 1699,
  เมืองสิงห์บุรี: 1701,
  บางระจัน: 1702,
  ค่ายบางระจัน: 1703,
  พรหมบุรี: 1704,
  ท่าช้าง: 1705,
  อินทร์บุรี: 1706,
  เทศบาลเมืองสิงห์บุรี: 1799,
  เมืองชัยนาท: 1801,
  มโนรมย์: 1802,
  วัดสิงห์: 1803,
  สรรพยา: 1804,
  สรรคบุรี: 1805,
  หันคา: 1806,
  กิ่งอำเภอหนองมะโมง: 1807,
  กิ่งอำเภอเนินขาม: 1808,
  เทศบาลตำบลวัดสิงห์: 1898,
  เทศบาลเมืองชัยนาท: 1899,
  เมืองสระบุรี: 1901,
  แก่งคอย: 1902,
  หนองแค: 1903,
  วิหารแดง: 1904,
  หนองแซง: 1905,
  บ้านหมอ: 1906,
  ดอนพุด: 1907,
  หนองโดน: 1908,
  พระพุทธบาท: 1909,
  เสาไห้: 1910,
  มวกเหล็ก: 1911,
  วังม่วง: 1912,
  เฉลิมพระเกียรติ: 1913,
  เทศบาลตำบลพระพุทธบาท: 1996,
  เทศบาลตำบลหนองแค: 1997,
  เทศบาลตำบลแก่งคอย: 1998,
  เทศบาลเมืองสระบุรี: 1999,
  เมืองชลบุรี: 2001,
  บ้านบึง: 2002,
  หนองใหญ่: 2003,
  บางละมุง: 2004,
  พานทอง: 2005,
  พนัสนิคม: 2006,
  ศรีราชา: 2007,
  เกาะสีชัง: 2008,
  สัตหีบ: 2009,
  บ่อทอง: 2010,
  กิ่งอำเภอเกาะจันทร์: 2011,
  เทศบาลตำบลแหลมฉะบัง: 2093,
  เทศบาลตำบลแสนสุข: 2094,
  เทศบาลตำบลบ้านบึง: 2095,
  เทศบาลตำบลศรีราชา: 2097,
  เทศบาลเมืองพนัสนิคม: 2098,
  เทศบาลเมืองชลบุรี: 2099,
  เมืองระยอง: 2101,
  บ้านฉาง: 2102,
  แกลง: 2103,
  วังจันทร์: 2104,
  บ้านค่าย: 2105,
  ปลวกแดง: 2106,
  กิ่งอำเภอเขาชะเมา: 2107,
  กิ่งอำเภอนิคมพัฒนา: 2108,
  เทศบาลตำบลมาบตาพุด: 2197,
  เทศบาลตำบลทางเกวียน: 2198,
  เทศบาลเมืองระยอง: 2199,
  เมืองจันทบุรี: 2201,
  ขลุง: 2202,
  ท่าใหม่: 2203,
  โป่งน้ำร้อน: 2204,
  มะขาม: 2205,
  แหลมสิงห์: 2206,
  สอยดาว: 2207,
  แก่งหางแมว: 2208,
  นายายอาม: 2209,
  กิ่งอำเภอเขาคิชฌกูฏ: 2210,
  เทศบาลตำบลขลุง: 2297,
  เทศบาลตำบลท่าใหม่: 2298,
  เมืองตราด: 2301,
  คลองใหญ่: 2302,
  เขาสมิง: 2303,
  บ่อไร่: 2304,
  แหลมงอบ: 2305,
  กิ่งอำเภอเกาะกูด: 2306,
  กิ่งอำเภอเกาะช้าง: 2307,
  เทศบาลเมืองตราด: 2399,
  เมืองฉะเชิงเทรา: 2401,
  บางคล้า: 2402,
  บางน้ำเปรี้ยว: 2403,
  บางปะกง: 2404,
  บ้านโพธิ์: 2405,
  พนมสารคาม: 2406,
  ราชสาส์น: 2407,
  สนามชัยเขต: 2408,
  แปลงยาว: 2409,
  ท่าตะเกียบ: 2410,
  กิ่งอำเภอคลองเขื่อน: 2411,
  เทศบาลตำบลบางคล้า: 2498,
  เทศบาลเมืองฉะเชิงเทร: 2499,
  เมืองปราจีนบุรี: 2501,
  กบินทร์บุรี: 2502,
  นาดี: 2503,
  บ้านสร้าง: 2506,
  ประจันตคาม: 2507,
  ศรีมหาโพธิ: 2508,
  ศรีมโหสถ: 2509,
  เทศบาลตำบลอรัญประเทศ: 2597,
  เทศบาลตำบลกบินทร์บุร: 2598,
  เทศบาลเมืองปราจีนบุร: 2599,
  เมืองนครนายก: 2601,
  ปากพลี: 2602,
  บ้านนา: 2603,
  องครักษ์: 2604,
  เทศบาลเมืองนครนายก: 2699,
  เมืองสระแก้ว: 2701,
  คลองหาด: 2702,
  ตาพระยา: 2703,
  วังน้ำเย็น: 2704,
  วัฒนานคร: 2705,
  อรัญประเทศ: 2706,
  เขาฉกรรจ์: 2707,
  กิ่งอำเภอโคกสูง: 2708,
  กิ่งอำเภอวังสมบูรณ์: 2709,
  เทศบาลตำบลอรัญญประเท: 2798,
  เมืองนครราชสีมา: 3001,
  ครบุรี: 3002,
  เสิงสาง: 3003,
  คง: 3004,
  บ้านเหลื่อม: 3005,
  จักราช: 3006,
  โชคชัย: 3007,
  ด่านขุนทด: 3008,
  โนนไทย: 3009,
  โนนสูง: 3010,
  ขามสะแกแสง: 3011,
  บัวใหญ่: 3012,
  ประทาย: 3013,
  ปักธงชัย: 3014,
  พิมาย: 3015,
  ห้วยแถลง: 3016,
  ชุมพวง: 3017,
  สูงเนิน: 3018,
  ขามทะเลสอ: 3019,
  สีคิ้ว: 3020,
  ปากช่อง: 3021,
  หนองบุญมาก: 3022,
  แก้งสนามนาง: 3023,
  โนนแดง: 3024,
  วังน้ำเขียว: 3025,
  กิ่งอำเภอเทพารักษ์: 3026,
  กิ่งอำเภอเมืองยาง: 3027,
  กิ่งอำเภอพระทองคำ: 3028,
  กิ่งอำเภอลำทะเมนชัย: 3029,
  กิ่งอำเภอบัวลาย: 3030,
  กิ่งอำเภอสีดา: 3031,
  เฉลิมพระเกียรติ: 3032,
  เทศบาลตำบลโนนสูง: 3096,
  เทศบาลตำบลบัวใหญ่: 3097,
  เทศบาลตำบลปากช่อง: 3098,
  เทศบาลเมืองนครราชสีม: 3099,
  เมืองบุรีรัมย์: 3101,
  คูเมือง: 3102,
  กระสัง: 3103,
  นางรอง: 3104,
  หนองกี่: 3105,
  ละหานทราย: 3106,
  ประโคนชัย: 3107,
  บ้านกรวด: 3108,
  พุทไธสง: 3109,
  ลำปลายมาศ: 3110,
  สตึก: 3111,
  ปะคำ: 3112,
  นาโพธิ์: 3113,
  หนองหงส์: 3114,
  พลับพลาชัย: 3115,
  ห้วยราช: 3116,
  โนนสุวรรณ: 3117,
  ชำนิ: 3118,
  บ้านใหม่ไชยพจน์: 3119,
  โนนดินแดง: 3120,
  กิ่งอำเภอบ้านด่าน: 3121,
  กิ่งอำเภอแคนดง: 3122,
  เฉลิมพระเกียรติ: 3123,
  เทศบาลตำบลนางรอง: 3198,
  เทศบาลเมืองบุรีรัมย์: 3199,
  เมืองสุรินทร์: 3201,
  ชุมพลบุรี: 3202,
  ท่าตูม: 3203,
  จอมพระ: 3204,
  ปราสาท: 3205,
  กาบเชิง: 3206,
  รัตนบุรี: 3207,
  สนม: 3208,
  ศีขรภูมิ: 3209,
  สังขะ: 3210,
  ลำดวน: 3211,
  สำโรงทาบ: 3212,
  บัวเชด: 3213,
  กิ่งอำเภอพนมดงรัก: 3214,
  กิ่งอำเภอศรีณรงค์: 3215,
  พระนคร: 1001,
  กิ่งอำเภอเขวาสินรินทร์: 3216,
  น้ำปาด: 5304,
  ฟากท่า: 5305,
  บ้านโคก: 5306,
  พิชัย: 5307,
  ลับแล: 5308,
  ทองแสนขัน: 5309,
  เทศบาลตำบลศรีพนมมาศ: 5398,
  เทศบาลเมืองอุตรดิตถ์: 5399,
  เมืองแพร่: 5401,
  ร้องกวาง: 5402,
  ลอง: 5403,
  สูงเม่น: 5404,
  เด่นชัย: 5405,
  สอง: 5406,
  วังชิ้น: 5407,
  หนองม่วงไข่: 5408,
  เทศบาลเมืองแพร่: 5499,
  เมืองน่าน: 5501,
  แม่จริม: 5502,
  บ้านหลวง: 5503,
  นาน้อย: 5504,
  ปัว: 5505,
  ท่าวังผา: 5506,
  เวียงสา: 5507,
  ทุ่งช้าง: 5508,
  เชียงกลาง: 5509,
  นาหมื่น: 5510,
  สันติสุข: 5511,
  บ่อเกลือ: 5512,
  สองแคว: 5513,
  ภูเพียง: 5514,
  เฉลิมพระเกียรติ: 5515,
  เทศบาลเมืองน่าน: 5599,
  เมืองพะเยา: 5601,
  จุน: 5602,
  เชียงคำ: 5603,
  เชียงม่วน: 5604,
  ดอกคำใต้: 5605,
  ปง: 5606,
  แม่ใจ: 5607,
  กิ่งอำเภอภูซาง: 5608,
  กิ่งอำเภอภูกามยาว: 5609,
  เทศบาลเมืองพะเยา: 5699,
  เมืองเชียงราย: 5701,
  เวียงชัย: 5702,
  เชียงของ: 5703,
  เทิง: 5704,
  พาน: 5705,
  ป่าแดด: 5706,
  แม่จัน: 5707,
  เชียงแสน: 5708,
  แม่สาย: 5709,
  แม่สรวย: 5710,
  เวียงป่าเป้า: 5711,
  พญาเม็งราย: 5712,
  เวียงแก่น: 5713,
  ขุนตาล: 5714,
  แม่ฟ้าหลวง: 5715,
  แม่ลาว: 5716,
  กิ่งอำเภอเวียงเชียงรุ้ง: 5717,
  กิ่งอำเภอดอยหลวง: 5718,
  เทศบาลเมืองเชียงราย: 5799,
  เมืองแม่ฮ่องสอน: 5801,
  ขุนยวม: 5802,
  ปาย: 5803,
  แม่สะเรียง: 5804,
  แม่ลาน้อย: 5805,
  สบเมย: 5806,
  ปางมะผ้า: 5807,
  เทศบาลเมืองแม่ฮ่องสอ: 5899,
  เมืองนครสวรรค์: 6001,
  โกรกพระ: 6002,
  ชุมแสง: 6003,
  หนองบัว: 6004,
  บรรพตพิสัย: 6005,
  เก้าเลี้ยว: 6006,
  ตาคลี: 6007,
  ท่าตะโก: 6008,
  ไพศาลี: 6009,
  พยุหะคีรี: 6010,
  ลาดยาว: 6011,
  ตากฟ้า: 6012,
  แม่วงก์: 6013,
  กิ่งอำเภอแม่เปิน: 6014,
  กิ่งอำเภอชุมตาบง: 6015,
  เทศบาลตำบลตาคลี: 6097,
  เทศบาลเมืองชุมแสง: 6098,
  เทศบาลเมืองนครสวรรค์: 6099,
  เมืองอุทัยธานี: 6101,
  ทัพทัน: 6102,
  สว่างอารมณ์: 6103,
  หนองฉาง: 6104,
  หนองขาหย่าง: 6105,
  บ้านไร่: 6106,
  ลานสัก: 6107,
  ห้วยคต: 6108,
  เทศบาลเมืองอุทัยธานี: 6199,
  เมืองกำแพงเพชร: 6201,
  ไทรงาม: 6202,
  คลองลาน: 6203,
  ขาณุวรลักษบุรี: 6204,
  คลองขลุง: 6205,
  พรานกระต่าย: 6206,
  ลานกระบือ: 6207,
  ทรายทองวัฒนา: 6208,
  ปางศิลาทอง: 6209,
  กิ่งอำเภอบึงสามัคคี: 6210,
  กิ่งอำเภอโกสัมพีนคร: 6211,
  เทศบาลเมืองกำแพงเพชร: 6299,
  เมืองตาก: 6301,
  บ้านตาก: 6302,
  สามเงา: 6303,
  แม่ระมาด: 6304,
  ท่าสองยาง: 6305,
  แม่สอด: 6306,
  พบพระ: 6307,
  อุ้มผาง: 6308,
  กิ่งอำเภอวังเจ้า: 6309,
  เทศบาลตำบลแม่สอด: 6398,
  เทศบาลเมืองตาก: 6399,
  เมืองสุโขทัย: 6401,
  บ้านด่านลานหอย: 6402,
  คีรีมาศ: 6403,
  กงไกรลาศ: 6404,
  ศรีสัชนาลัย: 6405,
  ศรีสำโรง: 6406,
  สวรรคโลก: 6407,
  ศรีนคร: 6408,
  ทุ่งเสลี่ยม: 6409,
  เทศบาลเมืองสวรรคโลก: 6498,
  เทศบาลเมืองสุโขทัย: 6499,
  เมืองพิษณุโลก: 6501,
  นครไทย: 6502,
  ชาติตระการ: 6503,
  บางระกำ: 6504,
  บางกระทุ่ม: 6505,
  พรหมพิราม: 6506,
  วัดโบสถ์: 6507,
  วังทอง: 6508,
  เนินมะปราง: 6509,
  เทศบาลเมืองพิษณุโลก: 6599,
  เมืองพิจิตร: 6601,
  วังทรายพูน: 6602,
  โพธิ์ประทับช้าง: 6603,
  ตะพานหิน: 6604,
  บางมูลนาก: 6605,
  โพทะเล: 6606,
  สามง่าม: 6607,
  ทับคล้อ: 6608,
  กิ่งอำเภอสากเหล็ก: 6609,
  กิ่งอำเภอบึงนาราง: 6610,
  กิ่งอำเภอดงเจริญ: 6611,
  วชิรบารมี: 6612,
  เทศบาลเมืองบางมูลนาก: 6697,
  เทศบาลตำบลตะพานหิน: 6698,
  เทศบาลเมืองพิจิตร: 6699,
  เมืองเพชรบูรณ์: 6701,
  ชนแดน: 6702,
  หล่มสัก: 6703,
  หล่มเก่า: 6704,
  วิเชียรบุรี: 6705,
  ศรีเทพ: 6706,
  หนองไผ่: 6707,
  บึงสามพัน: 6708,
  น้ำหนาว: 6709,
  วังโป่ง: 6710,
  เขาค้อ: 6711,
  เทศบาลตำบลหล่มสัก: 6798,
  เทศบาลเมืองเพชรบูรณ์: 6799,
  เมืองราชบุรี: 7001,
  จอมบึง: 7002,
  สวนผึ้ง: 7003,
  ดำเนินสะดวก: 7004,
  บ้านโป่ง: 7005,
  บางแพ: 7006,
  โพธาราม: 7007,
  ปากท่อ: 7008,
  วัดเพลง: 7009,
  กิ่งอำเภอบ้านคา: 7010,
  เทศบาลเมืองโพธาราม: 7097,
  เทศบาลเมืองบ้านโป่ง: 7098,
  เทศบาลเมืองราชบุรี: 7099,
  เมืองกาญจนบุรี: 7101,
  ไทรโยค: 7102,
  บ่อพลอย: 7103,
  ศรีสวัสดิ์: 7104,
  ท่ามะกา: 7105,
  ท่าม่วง: 7106,
  ทองผาภูมิ: 7107,
  สังขละบุรี: 7108,
  พนมทวน: 7109,
  เลาขวัญ: 7110,
  ด่านมะขามเตี้ย: 7111,
  หนองปรือ: 7112,
  ห้วยกระเจา: 7113,
  เทศบาลตำบลท่าเรือพระ: 7198,
  เทศบาลเมืองกาญจนบุรี: 7199,
  เมืองสุพรรณบุรี: 7201,
  เดิมบางนางบวช: 7202,
  ด่านช้าง: 7203,
  บางปลาม้า: 7204,
  ศรีประจันต์: 7205,
  ดอนเจดีย์: 7206,
  สองพี่น้อง: 7207,
  สามชุก: 7208,
  อู่ทอง: 7209,
  อุบลรัตน์: 4008,
  กระนวน: 4009,
  บ้านไผ่: 4010,
  เปือยน้อย: 4011,
  พล: 4012,
  แวงใหญ่: 4013,
  แวงน้อย: 4014,
  หนองสองห้อง: 4015,
  ภูเวียง: 4016,
  มัญจาคีรี: 4017,
  ชนบท: 4018,
  เขาสวนกวาง: 4019,
  ภูผาม่าน: 4020,
  กิ่งอำเภอซำสูง: 4021,
  กิ่งอำเภอโคกโพธิ์ไชย: 4022,
  กิ่งอำเภอหนองนาคำ: 4023,
  กิ่งอำเภอบ้านแฮด: 4024,
  กิ่งอำเภอโนนศิลา: 4025,
  เทศบาลตำบลชุมแพ: 4096,
  เทศบาลตำบลบ้านไฝ่: 4097,
  เทศบาลตำบลเมืองพล: 4098,
  เทศบาลเมืองขอนแก่น: 4099,
  เมืองอุดรธานี: 4101,
  กุดจับ: 4102,
  หนองวัวซอ: 4103,
  กุมภวาปี: 4104,
  โนนสะอาด: 4105,
  หนองหาน: 4106,
  ทุ่งฝน: 4107,
  ไชยวาน: 4108,
  ศรีธาตุ: 4109,
  วังสามหมอ: 4110,
  บ้านดุง: 4111,
  บ้านผือ: 4117,
  น้ำโสม: 4118,
  เพ็ญ: 4119,
  สร้างคอม: 4120,
  หนองแสง: 4121,
  นายูง: 4122,
  พิบูลย์รักษ์: 4123,
  กิ่งอำเภอกู่แก้ว: 4124,
  กิ่งอำเภอประจักษ์ศิลปาคม: 4125,
  เทศบาลเมืองอุดรธานี: 4199,
  เมืองเลย: 4201,
  นาด้วง: 4202,
  เชียงคาน: 4203,
  ปากชม: 4204,
  ด่านซ้าย: 4205,
  นาแห้ว: 4206,
  ภูเรือ: 4207,
  ท่าลี่: 4208,
  วังสะพุง: 4209,
  ภูกระดึง: 4210,
  ภูหลวง: 4211,
  ผาขาว: 4212,
  กิ่งอำเภอเอราวัณ: 4213,
  กิ่งอำเภอหนองหิน: 4214,
  เทศบาลเมืองเลย: 4299,
  เมืองหนองคาย: 4301,
  ท่าบ่อ: 4302,
  บึงกาฬ: 4303,
  พรเจริญ: 4304,
  โพนพิสัย: 4305,
  ศรีเชียงใหม่: 4307,
  สังคม: 4308,
  เซกา: 4309,
  ปากคาด: 4310,
  ศรีวิไล: 4312,
  บุ่งคล้า: 4313,
  กิ่งอำเภอสระใคร่: 4314,
  กิ่งอำเภอเฝ้าไร่: 4315,
  กิ่งอำเภอรัตนวาปี: 4316,
  กิ่งอำเภอโพธิ์ตาก: 4317,
  เทศบาลเมืองหนองคาย: 4399,
  เมืองมหาสารคาม: 4401,
  แกดำ: 4402,
  โกสุมพิสัย: 4403,
  กันทรวิชัย: 4404,
  เชียงยืน: 4405,
  บรบือ: 4406,
  นาเชือก: 4407,
  พยัคฆภูมิพิสัย: 4408,
  วาปีปทุม: 4409,
  นาดูน: 4410,
  ยางสีสุราช: 4411,
  กิ่งอำเภอกุดรัง: 4412,
  กิ่งอำเภอชื่นชม: 4413,
  เทศบาลเมืองมหาสารคาม: 4499,
  เมืองร้อยเอ็ด: 4501,
  เกษตรวิสัย: 4502,
  ปทุมรัตต์: 4503,
  จตุรพักตรพิมาน: 4504,
  ธวัชบุรี: 4505,
  พนมไพร: 4506,
  โพนทอง: 4507,
  โพธิ์ชัย: 4508,
  หนองพอก: 4509,
  เสลภูมิ: 4510,
  สุวรรณภูมิ: 4511,
  เมืองสรวง: 4512,
  โพนทราย: 4513,
  อาจสามารถ: 4514,
  เมยวดี: 4515,
  ศรีสมเด็จ: 4516,
  จังหาร: 4517,
  กิ่งอำเภอเชียงขวัญ: 4518,
  ไม่ระบุอำเภอ: 9999,
  หนองหญ้าไซ: 7210,
  เทศบาลตำบลสองพี่น้อง: 7298,
  เทศบาลเมืองสุพรรบุรี: 7299,
  เมืองนครปฐม: 7301,
  กำแพงแสน: 7302,
  นครชัยศรี: 7303,
  ดอนตูม: 7304,
  บางเลน: 7305,
  สามพราน: 7306,
  พุทธมณฑล: 7307,
  เทศบาลเมืองนครปฐม: 7399,
  เมืองสมุทรสาคร: 7401,
  กระทุ่มแบน: 7402,
  บ้านแพ้ว: 7403,
  เทศบาลตำบลกระทุ่มแบน: 7498,
  เทศบาลเมืองสมุทรสาคร: 7499,
  เมืองสมุทรสงคราม: 7501,
  บางคนที: 7502,
  อัมพวา: 7503,
  เทศบาลตำบลอัมพวา: 7598,
  เทศบาลเมืองสมุทรสงคร: 7599,
  เมืองเพชรบุรี: 7601,
  เขาย้อย: 7602,
  หนองหญ้าปล้อง: 7603,
  ชะอำ: 7604,
  ท่ายาง: 7605,
  บ้านลาด: 7606,
  บ้านแหลม: 7607,
  แก่งกระจาน: 7608,
  เทศบาลตำบลชะอำ: 7698,
  เมืองประจวบคีรีขันธ์: 7701,
  กุยบุรี: 7702,
  ทับสะแก: 7703,
  บางสะพาน: 7704,
  บางสะพานน้อย: 7705,
  ปราณบุรี: 7706,
  หัวหิน: 7707,
  กิ่งอำเภอสามร้อยยอด: 7708,
  เทศบาลตำบลหัวหิน: 7798,
  เทศบาลเมืองประจวบคีร: 7799,
  เมืองนครศรีธรรมราช: 8001,
  พรหมคีรี: 8002,
  ลานสะกา: 8003,
  ฉวาง: 8004,
  พิปูน: 8005,
  เชียรใหญ่: 8006,
  ชะอวด: 8007,
  ท่าศาลา: 8008,
  ทุ่งสง: 8009,
  นาบอน: 8010,
  ทุ่งใหญ่: 8011,
  ปากพนัง: 8012,
  ร่อนพิบูลย์: 8013,
  สิชล: 8014,
  ขนอม: 8015,
  หัวไทร: 8016,
  บางขัน: 8017,
  ถ้ำพรรณรา: 8018,
  จุฬาภรณ์: 8019,
  พระพรหม: 8020,
  โซ่พิสัย: 9700,
  ปากคาด: 3805,
  เมือง: 3806,
  เซกา: 3804,
  บางบอน: 1051,
  บึงกาฬ: 3807,
  อำเภอมะนัง: 9108,
  หนองหงษ์: 3200,
}

export const province = {
  กรุงเทพมหานคร: 10,
  สมุทรปราการ: 11,
  นนทบุรี: 12,
  ปทุมธานี: 13,
  พระนครศรีอยุธยา: 14,
  อ่างทอง: 15,
  ลพบุรี: 16,
  สิงห์บุรี: 17,
  ชัยนาท: 18,
  สระบุรี: 19,
  ชลบุรี: 20,
  ระยอง: 21,
  จันทบุรี: 22,
  ตราด: 23,
  ฉะเชิงเทรา: 24,
  ปราจีนบุรี: 25,
  นครนายก: 26,
  สระแก้ว: 27,
  นครราชสีมา: 30,
  บุรีรัมย์: 31,
  สุรินทร์: 32,
  ศรีสะเกษ: 33,
  อุบลราชธานี: 34,
  ยโสธร: 35,
  ชัยภูมิ: 36,
  อำนาจเจริญ: 37,
  หนองบัวลำภู: 39,
  ขอนแก่น: 40,
  อุดรธานี: 41,
  เลย: 42,
  หนองคาย: 43,
  มหาสารคาม: 44,
  ร้อยเอ็ด: 45,
  กาฬสินธุ์: 46,
  สกลนคร: 47,
  นครพนม: 48,
  มุกดาหาร: 49,
  เชียงใหม่: 50,
  ลำพูน: 51,
  ลำปาง: 52,
  อุตรดิตถ์: 53,
  แพร่: 54,
  น่าน: 55,
  พะเยา: 56,
  เชียงราย: 57,
  แม่ฮ่องสอน: 58,
  นครสวรรค์: 60,
  อุทัยธานี: 61,
  กำแพงเพชร: 62,
  ตาก: 63,
  สุโขทัย: 64,
  พิษณุโลก: 65,
  พิจิตร: 66,
  เพชรบูรณ์: 67,
  ราชบุรี: 70,
  กาญจนบุรี: 71,
  สุพรรณบุรี: 72,
  นครปฐม: 73,
  สมุทรสาคร: 74,
  สมุทรสงคราม: 75,
  เพชรบุรี: 76,
  ประจวบคีรีขันธ์: 77,
  นครศรีธรรมราช: 80,
  กระบี่: 81,
  พังงา: 82,
  ภูเก็ต: 83,
  สุราษฎร์ธานี: 84,
  ระนอง: 85,
  ชุมพร: 86,
  สงขลา: 90,
  สตูล: 91,
  ตรัง: 92,
  พัทลุง: 93,
  ปัตตานี: 94,
  ยะลา: 95,
  นราธิวาส: 96,
  ไม่ระบุจังหวัด: 99,
  บึงกาฬ: 38,
}

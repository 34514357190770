import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Modal, DatePicker, Button, Checkbox } from 'antd'
import { PressureText } from './styles'
import FilterButtonGroup from '../FilterButtonGroup'
import moment from 'moment'

const { RangePicker } = DatePicker

function PressureValueCard(props) {
  const [visible, setVisible] = useState(false)
  const [startDate, setStartDate] = useState(false)
  const [endDate, setEndDate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const { min, max, average } = props.pressure
  const { title, filterReport, filterByLevelAndDate, checkAllPressure } = props

  useEffect(() => {
    setCheckAll(checkAllPressure)
  }, [checkAllPressure])

  const validDate = (date) => {
    return moment(date).isValid(date) ? moment(date) : null
  }

  const onDateChange = (value, dateString, checked) => {
    if (checked) {
      setStartDate(false)
      setEndDate(false)
    } else {
      setStartDate(dateString[0] || null)
      setEndDate(dateString[1] || null)
    }
    setCheckAll(checked)
  }

  const handleOk = () => {
    filterReport(startDate, endDate, checkAll)
    setVisible(false)
  }

  return (
    <Card
      title={
        <Row justify="space-between">
          <Col span={4}>{title}</Col>
          {filterReport && (
            <Col span={20} className="d-flex justify-content-end">
              <FilterButtonGroup filterByLevelAndDate={filterByLevelAndDate} checkAll={checkAllPressure} />
              <Button type="primary" onClick={() => setVisible(true)}>
                เลือกช่วงวันที่ ที่ต้องการแสดงผล
              </Button>
            </Col>
          )}
        </Row>
      }
    >
      <Modal title="เลือกช่วงวันที่ ที่ต้องการแสดงผล" visible={visible} onOk={handleOk} onCancel={() => setVisible(false)}>
        <Row align="middle">
          <RangePicker className="mr-3" format="DD MMM YYYY" value={[validDate(startDate), validDate(endDate)]} onChange={onDateChange} />
          <Checkbox checked={checkAll} onChange={(e) => onDateChange(false, false, e.target.checked)}>
            แสดงทั้งหมด
          </Checkbox>
        </Row>
      </Modal>
      <Row>
        <PressureText span={8} color="#e13549">
          ต่ำสุด
        </PressureText>
        <PressureText span={8} color="#e13549">
          สูงสุด
        </PressureText>
        <PressureText span={8} color="#e13549">
          ค่าเฉลี่ย
        </PressureText>
        <PressureText span={8} color="blue">
          {Math.ceil(min)}
        </PressureText>
        <PressureText span={8} color="red">
          {Math.ceil(max)}
        </PressureText>
        <PressureText span={8} color="black">
          {Math.ceil(average)}
        </PressureText>
      </Row>
    </Card>
  )
}

export default PressureValueCard

export const TabPanes = [
  {
    title: 'นัดหมายทั้งหมด',
    key: 'bookings',
    roles: ['callCenter', 'doctor', 'nurse', 'physiotherapist', 'speechCorrectionSpecialist', 'pharmacySchedule', 'nutritionist', 'pharmacy'],
  },
  {
    title: 'นัดหมายวันนี้',
    key: 'todayBookings',
    roles: ['callCenter', 'doctor', 'nurse', 'physiotherapist', 'speechCorrectionSpecialist', 'pharmacySchedule', 'nutritionist', 'pharmacy'],
  },
  {
    title: 'นัดหมายที่กำลังจะมาถึง',
    key: 'futureBookings',
    roles: ['callCenter', 'doctor', 'nurse', 'physiotherapist', 'speechCorrectionSpecialist', 'pharmacySchedule', 'nutritionist'],
  },
  {
    title: 'นัดหมายที่ผ่านมา',
    key: 'pastBookings',
    roles: ['callCenter', 'doctor', 'nurse', 'physiotherapist', 'speechCorrectionSpecialist', 'pharmacySchedule', 'nutritionist', 'pharmacy'],
  },
  {
    title: 'นัดหมายที่ยกเลิก',
    key: 'cancelBookings',
    roles: ['callCenter', 'pharmacy'],
  },
  {
    title: 'การแจ้งปัญหา',
    key: 'alert',
    roles: ['callCenter', 'doctor', 'nurse', 'physiotherapist', 'speechCorrectionSpecialist', 'pharmacySchedule', 'nutritionist', 'pharmacy'],
  },
]

import React from 'react'
import { View, Document, Page, Text } from '@react-pdf/renderer'
import styles from './styles'
import moment from 'moment'
import 'moment/locale/th'

export default function EmsPdfForm({ data }) {
  return (
    <Document>
      <Page size={[70.8661417, 141.732283]} orientation="landscape" dpi={72}>
        <View key={1} style={styles.mainContainer}>
          <Text style={{ ...styles.titleText, lineHeight: 2 }}>{`${data?.title ?? ''} ${data?.fullName ?? ''}`}</Text>
          <Text style={{ ...styles.titleText, lineHeight: 1.8 }}>{`HN ${data?.hn ?? '-'} Group ${data?.aboGroup ?? 'ไม่ทราบ'}${data?.rhGroup ?? ''}`}</Text>
          <Text style={{ ...styles.titleText, fontSize: 7, lineHeight: 1.8 }}>{`วัน ${
            data.donateCompletedDateTime
              ? moment(data.donateCompletedDateTime)
                  .add(543, 'years')
                  .format('DD/MM/YY')
              : '-'
          } เวลา ${data.donateCompletedDateTime ? moment(data.donateCompletedDateTime).format('HH:mm:ss') : '-'} เจาะเก็บ  `}</Text>
          <Text style={{ ...styles.titleText, fontSize: 7, textAlign: 'left', lineHeight: 0 }}>{`ผู้เจาะเก็บ ${data?.bloodNeedlerName ?? '-'} `}</Text>
        </View>
      </Page>
      <Page size={[70.8661417, 141.732283]} orientation="landscape" dpi={72}>
        <View key={2} style={styles.mainContainer}>
          <Text style={{ ...styles.titleText, lineHeight: 2 }}>{`${data?.title ?? ''} ${data?.fullName ?? ''}`}</Text>
          <Text style={{ ...styles.titleText, lineHeight: 1.8 }}>{`HN ${data?.hn ?? '-'} Group ${data?.aboGroup ?? 'ไม่ทราบ'}${data?.rhGroup ?? ''}`}</Text>
          <Text style={{ ...styles.titleText, fontSize: 7, lineHeight: 1.8 }}>{`วัน ${
            data.donateCompletedDateTime
              ? moment(data.donateCompletedDateTime)
                  .add(543, 'years')
                  .format('DD/MM/YY')
              : '-'
          } เวลา ${data.donateCompletedDateTime ? moment(data.donateCompletedDateTime).format('HH:mm:ss') : '-'} เจาะเก็บ  `}</Text>
          <Text style={{ ...styles.titleText, fontSize: 7, textAlign: 'left', lineHeight: 0 }}>{`ผู้เจาะเก็บ ${data?.bloodNeedlerName ?? '-'} `}</Text>
        </View>
      </Page>
      <Page size={[70.8661417, 141.732283]} orientation="landscape" dpi={72}>
        <View key={3} style={styles.mainContainer}>
          <Text style={{ ...styles.titleText, lineHeight: 2 }}>{`${data?.title ?? ''} ${data?.fullName ?? ''}`}</Text>
          <Text style={{ ...styles.titleText, lineHeight: 1.8 }}>{`HN ${data?.hn ?? '-'} Group ${data?.aboGroup ?? 'ไม่ทราบ'}${data?.rhGroup ?? ''}`}</Text>
          <Text style={{ ...styles.titleText, fontSize: 7, lineHeight: 1.8 }}>{`วัน ${
            data.donateCompletedDateTime
              ? moment(data.donateCompletedDateTime)
                  .add(543, 'years')
                  .format('DD/MM/YY')
              : '-'
          } เวลา ${data.donateCompletedDateTime ? moment(data.donateCompletedDateTime).format('HH:mm:ss') : '-'} เจาะเก็บ  `}</Text>
          <Text style={{ ...styles.titleText, fontSize: 7, textAlign: 'left', lineHeight: 0 }}>{`ผู้เจาะเก็บ ${data?.bloodNeedlerName ?? '-'} `}</Text>
        </View>
      </Page>
    </Document>
  )
}

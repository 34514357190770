import moment from 'moment';

const textCenter = { horizontal: 'center' }
const border = {
  top: { style: 'thin', color: { auto: 1 } },
  bottom: { style: 'thin', color: { auto: 1 } },
  left: { style: 'thin', color: { auto: 1 } },
  right: { style: 'thin', color: { auto: 1 } }
}
const failedColor = { rgb: 'C1141A' }
const bgGroupA = { patternType: "solid", fgColor: { rgb: "FFFF66" } }
const bgGroupB = { patternType: "solid", fgColor: { rgb: "FAAAAD" } }
const bgGroupO = { patternType: "solid", fgColor: { rgb: "C6E2FF" } }
const styles = {
  header: {
    font: { sz: "14" },
    alignment: textCenter,
    border
  },
  headerFailed: {
    font: { sz: "14", color: failedColor, bold: true },
    alignment: textCenter,
    border
  },
  groupA: {
    alignment: textCenter,
    border,
    fill: bgGroupA,
  },
  groupB: {
    alignment: textCenter,
    border,
    fill: bgGroupB
  },
  groupO: {
    alignment: textCenter,
    border,
    fill: bgGroupO
  },
  groupAB: {
    alignment: textCenter,
    border,
  }
}

const EMPTY_DOC = [[
  { value: '', style: { border } },
  { value: '', style: { border } },
  { value: '', style: { border } },
  { value: '', style: { border } },
  { value: '', style: { border } },
  { value: '', style: { border } },
  { value: '', style: { border } },
  { value: '', style: { border } },
  { value: '', style: { border } },
  { value: '', style: { border } },
  { value: '', style: { border } },
  { value: '', style: { border } },
  { value: '', style: { border } },
]]

const COLUMN_DONOR_EXCEL = [
  { title: "วันที่ีบริจาค", width: { wpx: 100 }, style: styles.header },
  { title: "ลำดับ", width: { wpx: 80 }, style: styles.header },
  { title: "ชื่อ-สกุลผู้ป่วย", width: { wpx: 300 }, style: styles.header },
  { title: "HN/AN", width: { wpx: 100 }, style: styles.header },
  { title: "AN", width: { wpx: 100 }, style: styles.header },
  { title: "หมู่เลือดผู้ป่วย", width: { wpx: 100 }, style: styles.header },
  { title: "A", width: { wpx: 80 }, style: { ...styles.header, fill: bgGroupA } },
  { title: "B", width: { wpx: 80 }, style: { ...styles.header, fill: bgGroupB } },
  { title: "O", width: { wpx: 80 }, style: { ...styles.header, fill: bgGroupO } },
  { title: "AB", width: { wpx: 80 }, style: styles.header },
  { title: "จำนวน(ยูนิต)", width: { wpx: 100 }, style: styles.header },
  { title: "ไม่ผ่าน", width: { wpx: 100 }, style: { ...styles.header, font: { sz: "14", bold: true, color: failedColor } } },
  { title: "วันผ่าตัด", width: { wpx: 100 }, style: styles.header },
]

const mapDonationBooking = (payload = []) => {
  try {
    const filterData = payload.filter((item) => item?.bloodDonationBooking?.donateStatus === 'donationCompleted' || item?.bloodDonationBooking?.screenStatus === 'screenFailed')
    const reduceData = filterData.reduce((a, c, i) => {
      const isIncludeBloodType = ['A', 'B', 'O', 'AB'].includes(c?.aboGroup)
      const bloodReceiver = typeof c?.bloodReceiver === 'string' ? c?.bloodReceiver.trim().replace(/[\u200B-\u200D\uFEFF]/g, '') : ''
      if (!a[bloodReceiver]) {
        a[bloodReceiver] = {
          donationDate: c?.bloodDonationBooking?.donationDate || '',
          bloodReceiver: bloodReceiver,
          A: c?.aboGroup === 'A' ? 1 : 0,
          B: c?.aboGroup === 'B' ? 1 : 0,
          O: c?.aboGroup === 'O' ? 1 : 0,
          AB: c?.aboGroup === 'AB' ? 1 : 0,
          totalUnit: isIncludeBloodType ? 1 : 0,
          screenFailed: isIncludeBloodType && c?.bloodDonationBooking?.screenStatus === 'screenFailed' ? 1 : 0
        }
      } else {
        a[bloodReceiver] = {
          ...a[bloodReceiver],
          A: c?.aboGroup === 'A' ? a[bloodReceiver]?.['A'] + 1 : a[bloodReceiver]?.['A'],
          B: c?.aboGroup === 'B' ? a[bloodReceiver]?.['B'] + 1 : a[bloodReceiver]?.['B'],
          O: c?.aboGroup === 'O' ? a[bloodReceiver]?.['O'] + 1 : a[bloodReceiver]?.['O'],
          AB: c?.aboGroup === 'AB' ? a[bloodReceiver]?.['AB'] + 1 : a[bloodReceiver]?.['AB'],
          totalUnit: isIncludeBloodType ? a[bloodReceiver]?.['totalUnit'] + 1 : a[bloodReceiver]?.['totalUnit'],
          screenFailed: isIncludeBloodType && c?.bloodDonationBooking?.screenStatus === 'screenFailed' ? a[bloodReceiver]?.['screenFailed'] + 1 : a[bloodReceiver]?.['screenFailed']
        }
      }
      return a
    }, {})
    const mapData = Object.values(reduceData).flatMap((ele, index) => {
      if (ele.totalUnit > 0) {
        return [[
          { value: moment(ele?.donationDate).isValid() ? moment(ele?.donationDate).format('DD/MM/YYYY') : 'ระบุไม่ได้' || '', style: { border, alignment: { horizontal: 'right' } } },
          { value: index + 1, style: { alignment: textCenter, border } },
          { value: ele?.bloodReceiver || '', style: { border } },
          { value: '', style: { border } },
          { value: '', style: { border } },
          { value: '', style: { border } },
          { value: ele['A'] || '', style: styles.groupA },
          { value: ele['B'] || '', style: styles.groupB },
          { value: ele['O'] || '', style: styles.groupO },
          { value: ele['AB'] || '', style: styles.groupAB },
          { value: ele['totalUnit'] || '', style: { alignment: textCenter, border, font: { bold: true } } },
          { value: ele['screenFailed'] || '', style: { alignment: textCenter, border, font: { color: failedColor } } },
          { value: '', style: { border } },
        ]]
      } else {
        return []
      }
    })

    return [{
      columns: COLUMN_DONOR_EXCEL,
      data: mapData.length > 0 ? mapData : EMPTY_DOC
    }]
  } catch (e) {
    console.error('mapExcelError', e?.message)
    return [{
      columns: COLUMN_DONOR_EXCEL,
      data: EMPTY_DOC
    }]
  }
}

export {
  mapDonationBooking
}
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { UserOutlined } from '@ant-design/icons'

export const Container = styled.div`
  display: flex;
  overflow: auto;
`
export const TopHeaderSection = styled(Col)`
  height: 48px;
`
export const UserIcon = styled(UserOutlined)`
  height: 30px;
  width: 30px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
`
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'
import moment from 'moment'
import styled, { css } from 'styled-components'
import Text from '../../components/Text'

const Time = styled.div`
  text-align: center;
`
const TimeText = styled(Text)`
  background-color: lightgray;
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
`
const AvatarContainer = styled.div`
  display: flex;
  margin-top: 10px;
  .avatar {
    margin-right: 10px;
  }
  p {
    font-size: 11px;
  }
`
const getStyleText = props => {
  if (props.position === 'left') {
    return css`
        border-bottom-left-radius: 0;
      `
  }
  if (props.position === 'right') {
    return css`
        border-bottom-right-radius: 0;
      `
  }
  return ''
}

const TextContainer = styled.div`
  display: flex;
  margin-top: 10px;
  ${props => {
    if (props.position === 'left') {
      return css`
        justify-content: flex-start;
      `
    }
    return css`
     justify-content: flex-end;
    `
  }}
`
const CustomText = styled(Text)`
  padding: 5px 10px;
  display: inline-block;
  background-color: lightgray;
  border-radius: 10px;
  ${getStyleText}
`

export default class Message extends Component {
  render() {
    const { message,
      showAvatar,
      position,
      isShowDate,
    } = this.props
    return (
      <>
        {
        isShowDate
        && (
        <Time>
          <TimeText>
            {moment(message.createdAt).fromNow()}
          </TimeText>
        </Time>
        )
      }
        <TextContainer
          position={position}
        >
          <CustomText
            {...this.props}
          >
            {message.text}
          </CustomText>

        </TextContainer>
        <Text second style={{ textAlign: position, fontSize: 11 }}>
          {moment(message.createdAt).format('LT')}
        </Text>
        {
          showAvatar && position === 'left'
        && (
        <AvatarContainer>
          <Avatar
            src={message.user.avatar}
            className="avatar"
          />
          <div>
            <Text>{message.user.name}</Text>
            {/* <Text second>
              {moment(message.createdAt).format('LT')}
            </Text> */}
          </div>
        </AvatarContainer>
        )
        }
      </>
    )
  }
}

Message.propTypes = {
  message: PropTypes.object.isRequired,
  showAvatar: PropTypes.bool.isRequired,
  isShowDate: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
}

export const initialValues = {
  allergies: '',
  currentMedication: '',
  event: '',
  lastOralIntake: '',
  injuryPain: {},
  underyingDisease: [],
  signAndSymptom: '',
  trauma: '',
  pain: {},
}

export const initialTable = {
  disFx: {
    head: false,
    face: false,
    neck: false,
    chest: false,
    abdomen: false,
    backOrFrank: false,
    pelvisOrHip: false,
    leftArm: false,
    RightArm: false,
    leftLeg: false,
    RightLeg: false,
  },
  deformity: {
    head: false,
    face: false,
    neck: false,
    chest: false,
    abdomen: false,
    backOrFrank: false,
    leftArm: false,
    RightArm: false,
    pelvisOrHip: false,
    leftLeg: false,
    RightLeg: false,
  },
  contusion: {
    head: false,
    face: false,
    neck: false,
    chest: false,
    abdomen: false,
    backOrFrank: false,
    pelvisOrHip: false,
    leftArm: false,
    RightArm: false,
    leftLeg: false,
    RightLeg: false,
  },
  abrasion: {
    head: false,
    face: false,
    neck: false,
    chest: false,
    abdomen: false,
    backOrFrank: false,
    pelvisOrHip: false,
    leftArm: false,
    RightArm: false,
    leftLeg: false,
    RightLeg: false,
  },
  penetratingPuncture: {
    head: false,
    face: false,
    neck: false,
    chest: false,
    abdomen: false,
    backOrFrank: false,
    pelvisOrHip: false,
    leftArm: false,
    RightArm: false,
    leftLeg: false,
    RightLeg: false,
  },
  laceration: {
    head: false,
    face: false,
    neck: false,
    chest: false,
    abdomen: false,
    backOrFrank: false,
    pelvisOrHip: false,
    leftArm: false,
    RightArm: false,
    leftLeg: false,
    RightLeg: false,
  },
  swelling: {
    head: false,
    face: false,
    neck: false,
    chest: false,
    abdomen: false,
    backOrFrank: false,
    pelvisOrHip: false,
    leftArm: false,
    RightArm: false,
    leftLeg: false,
    RightLeg: false,
  },
  tenderness: {
    head: false,
    face: false,
    neck: false,
    chest: false,
    abdomen: false,
    backOrFrank: false,
    pelvisOrHip: false,
    leftArm: false,
    RightArm: false,
    leftLeg: false,
    RightLeg: false,
  },
  instability: {
    head: false,
    face: false,
    neck: false,
    chest: false,
    abdomen: false,
    backOrFrank: false,
    pelvisOrHip: false,
    leftArm: false,
    RightArm: false,
    leftLeg: false,
    RightLeg: false,
  },
  crepitus: {
    head: false,
    face: false,
    neck: false,
    chest: false,
    abdomen: false,
    backOrFrank: false,
    pelvisOrHip: false,
    leftArm: false,
    RightArm: false,
    leftLeg: false,
    RightLeg: false,
  },
  gunshot: {
    head: false,
    face: false,
    neck: false,
    chest: false,
    abdomen: false,
    backOrFrank: false,
    pelvisOrHip: false,
    leftArm: false,
    RightArm: false,
    leftLeg: false,
    RightLeg: false,
  },
  blunt: {
    head: false,
    face: false,
    neck: false,
    chest: false,
    abdomen: false,
    backOrFrank: false,
    pelvisOrHip: false,
    leftArm: false,
    RightArm: false,
    leftLeg: false,
    RightLeg: false,
  },
  burns: {
    head: '',
    face: '',
    neck: '',
    chest: '',
    abdomen: '',
    backOrFrank: '',
    pelvisOrHip: '',
    leftArm: '',
    RightArm: '',
    leftLeg: '',
    RightLeg: '',
  },
  other: {
    name: '',
    head: '',
    face: '',
    neck: '',
    chest: '',
    abdomen: '',
    backOrFrank: '',
    pelvisOrHip: '',
    leftArm: '',
    RightArm: '',
    leftLeg: '',
    RightLeg: '',
  },
}

export const UNDERYING_DISEASE = [
  {
    label: 'Asthma',
  },
  {
    label: 'Chronic liver disease',
  },
  {
    label: 'Covid-19',
  },
  {
    label: 'HD',
  },
  {
    label: 'Parkinson',
  },
  {
    label: 'Bed ridden',
  },
  {
    label: 'Chronic kidney disease',
  },
  {
    label: 'DLP',
  },
  {
    label: 'HT',
  },
  {
    label: 'Other',
  },
  {
    label: 'CA',
  },
  {
    label: 'COPD',
  },
  {
    label: 'DM',
  },
  {
    label: 'Old CVA',
  },
]

export const formTemplate = [
  {
    label: 'Sign and Symptom',
    value: 'signAndSymptom',
    disabled: false,
    required: false,
    requiredMessage: '',
    type: 'textarea',
    cols: 12,
  },
  {
    label: 'Allergies',
    value: 'allergies',
    disabled: false,
    required: false,
    requiredMessage: '',
    type: 'textarea',
    cols: 12,
  },
  {
    label: 'Current medication',
    value: 'currentMedication',
    disabled: false,
    required: false,
    requiredMessage: '',
    type: 'textarea',
    cols: 12,
  },
  {
    label: 'Last oral intake',
    value: 'lastOralIntake',
    disabled: false,
    required: false,
    requiredMessage: '',
    type: 'textarea',
    cols: 12,
  },
  {
    label: 'Underying Disease',
    value: 'underyingDisease',
    disabled: false,
    required: false,
    requiredMessage: '',
    type: 'checkbox',
    cols: 24,
  },
  {
    label: 'Event',
    value: 'event',
    disabled: false,
    required: false,
    requiredMessage: '',
    type: 'textarea',
    cols: 24,
  },
  {
    label: 'Trauma',
    value: 'trauma',
    disabled: false,
    required: false,
    requiredMessage: '',
    type: 'radio',
    items: ['Non-trauma pt.', 'Trauma pt.'],
    cols: 24,
  },
  {
    label: 'Pain',
    value: 'pain',
    disabled: false,
    required: false,
    requiredMessage: '',
    type: 'pain',
    cols: 24,
  },
]

/* eslint-disable */

import React from 'react'
import { PropTypes } from 'prop-types'
import { FileOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import styled from 'styled-components'
// import _get from 'lodash/get'
import { Formik } from 'formik'
import * as Yup from 'yup'
import TextBase from '../Text'
import Button from '../Button'
import { TextInput } from './TextInput'

const { Option } = Select

export const ErrMsg = styled.p`
  &&& {
    color: red;
  }
`
const Text = styled(TextBase)`
  font-weight: bold;
`

const HeaderWrapper = styled.div`
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
const Content = styled.div`
  padding: 0px 20px;
`

const ButtonGroup = styled.div`
  height: 50px;
  align-items: flex-end;
  display: flex;
`

const Container = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  background-color: white;

`

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
})


const DepartmentForm = ({ onSubmit, data, title, doctorTypes }) => (
  <Formik
    initialValues={data}
    enableReinitialize
    onSubmit={(values, { setSubmitting }) => {
      onSubmit(values)
      setSubmitting(false)
    }}
    validationSchema={validationSchema}
  >
    {props => {
      const { values, handleChange, handleSubmit } = props
      return (
        <Container>
          <form>
            <HeaderWrapper>
              <FileOutlined />
              <Text bold>{title}</Text>
            </HeaderWrapper>
            <Content>
              <div>
                <h5 style={{ marginBottom: 5 }}>Symptom name: </h5>
                <TextInput
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  style={{ width: 220 }}
                />
              </div>
              <div>
                <h5 style={{ marginBottom: 5 }}>Doctor type: </h5>
                <Select
                  value={values.doctorTypeId}
                  style={{ width: 220 }}
                  onChange={(value) => {
                    const e = { target: { value, name: 'doctorTypeId' } }
                    handleChange(e)
                  }}
                >
                  {doctorTypes.map(({ id, name }) => (
                    <Option value={id}>{name}</Option>
                  ))}
                </Select>
              </div>
              <ButtonGroup>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  primary
                >
                Save
                </Button>
              </ButtonGroup>
            </Content>
          </form>
        </Container>
      );
    }}
  </Formik>
)

DepartmentForm.defaultProps = {
  values: {},
  handleChange: () => null,
  onSubmit: () => {},
  handleSubmit: () => {},
  data: {},
  title: '',
  doctorTypes: [],
}

DepartmentForm.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  data: PropTypes.object,
  title: PropTypes.string,
  doctorTypes: PropTypes.array,
}
export default DepartmentForm

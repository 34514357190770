import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/userFcmTokens'

class UserFcmToken {
  @observable info = {
    data: [],
    error: null
  }

  @action fetchUserFcmToken = async userId => {
    try {
      const { data } = await http.get(`${apiEndpoint}/getUserFcmTokenByUserId`, {
        params: {
          userId
        }
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }
}

export default new UserFcmToken()

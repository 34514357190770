export const DASHBOARD_DATA = {
  callCenter: [
    {
      backgroundColor: '#1b4b63',
      text: 'รอยืนยัน KYC',
      key: 'verifyId',
      icon: 'fe fe-calendar',
      to: 'userlist',
      state: { key: 'kycWaitingList' },
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'รอยืนยันนัดหมาย',
      key: 'patientDraft',
      icon: 'fe fe-clock',
      to: 'bookingpatient',
      state: { key: 'patientDraft' },
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'มีนัดหมายต่อ',
      key: 'bookingFollowUp',
      icon: 'fe fe-refresh-cw',
      to: 'follow-up',
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'รอใน Lobby',
      key: 'patientInLobby',
      icon: 'fe fe-video',
      to: 'telemed-lobby',
      state: { key: 'waiting' },
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'ผู้ป่วยที่กำลัง VideoCall',
      key: 'patientVideoCalling',
      icon: 'fe fe-video',
      to: 'telemed-lobby',
      state: { key: 'calling' },
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'รอ Confirm ยา',
      key: 'ephisPending',
      icon: 'fe fe-clipboard',
      to: 'bookingpatient',
      state: { key: 'ephisPending' },
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'รอยืนยันการชำระเงิน',
      key: 'patientPendingPayment',
      icon: 'fe fe-dollar-sign',
      to: 'bookingpatient',
      state: { key: 'patientPendingPayment' },
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'รอจัดส่งยา',
      key: 'patientEms',
      icon: 'fe fe-truck',
      to: 'bookingpatient',
      state: { key: 'patientEms' },
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'รอแก้ไขปัญหา',
      key: 'patientAlert',
      icon: 'fe fe-alert-circle',
      to: 'bookingpatient',
      state: { key: 'alert' },
      count: 0,
    },
  ],
  pharmacy: [
    {
      backgroundColor: '#1b4b63',
      text: 'รอยืนยันนัดหมาย',
      key: 'pendingBooking',
      icon: 'fe fe-calendar',
      to: 'bookingpatient',
      state: { key: 'phamacyPendingBookings' },
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'ผู้ป่วยที่กำลังรอใน Lobby',
      key: 'patientInLobby',
      icon: 'fe fe-clock',
      to: 'telemed-lobby',
      state: { key: 'waiting' },
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'ผู้ป่วยที่กำลัง VideoCall',
      key: 'patientVideoCalling',
      icon: 'fe fe-video',
      to: 'telemed-lobby',
      state: { key: 'calling' },
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'รอยืนยันรายการยา',
      key: 'pharmacyPendingRx',
      icon: 'fe fe-clipboard',
      to: 'prescription',
      state: { key: 'pharmacyPendingRx' },
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'รอชำระเงิน',
      key: 'patientPendingPayment',
      icon: 'fe fe-dollar-sign',
      to: 'prescription',
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'รอจัดส่งยา',
      key: 'patientEms',
      icon: 'fe fe-truck',
      to: 'prescription',
      state: { key: 'pharmacyEms' },
      count: 0,
    },
    {
      backgroundColor: '#1b4b63',
      text: 'รอแก้ไขปัญหา',
      key: 'pharmacyAlert',
      icon: 'fe fe-alert-circle',
      to: 'bookingpatient',
      state: { key: 'alert' },
      count: 0,
    },
  ],
}

export const INPROGRESS_STATUS_EMERGENCY = ['patientWaiting', 'actionWaiting', 'exitWaiting', 'moving', 'arrived', 'completedSending', 'inHospital']

export const COMPLETED_STATUS_EMERGENCY = ['completed', 'cancelled', 'pass']

export const PATIENT_WAITING = 'patientWaiting'

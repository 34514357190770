import React, { useRef, useState } from "react";
import { FiArrowDown, FiArrowUp, FiCode } from "react-icons/fi";
import './picker.css'
import './stepper.css'
const Picker = ({picked, choices, chosen, align, showStepper}) => {
    const [isOpenned, setOpenned] = useState(false)
    const [isControllerShown, setControllerShown] = useState(false)
    const wrapper = useRef()
    const pickerComponent = useRef()
    const stepper = useRef()
    const handleClick = (e) => {
        if (!isOpenned){
            e.stopPropagation()
            console.log("adding closing listener");
            document.addEventListener("click", collapsePicker)
        }
        setOpenned(!isOpenned)
    }
    const handlePick = (v, i, e) => {
        e.stopPropagation()
        picked && picked(v, i)
        console.log("picked");
    }
    const collapsePicker = (e) => {
        e.stopPropagation()
        setOpenned(false)
        setControllerShown(false)
        document.removeEventListener("click", collapsePicker)
    }
    const handleControlToggle = () => {
        setControllerShown(true)
        document.addEventListener("click", hidePickerStepper, true)
    }
    const hidePickerStepper = (e) => {
        if (stepper.current && (!stepper.current.contains(e.target))) {
            // e.stopPropagation()
            setControllerShown(false)
            document.removeEventListener("click", hidePickerStepper, true)
        }
    }
    const stepUp = () => {
        const outputIndex = chosen < choices.length-1 ? chosen+1 : chosen 
        picked && picked(choices[outputIndex].value, outputIndex)
    }
    const stepBack = () => {
        const outputIndex = chosen >= 1 ? chosen-1 : chosen 
        picked && picked(choices[outputIndex].value, outputIndex)
    }
    const picker = (
            <div ref={wrapper} className="neo-picker-item-wrapper" style={isOpenned ? {height: `${wrapper.current.scrollHeight}px`} : {}} onClickCapture={handleClick}>
                <div className="neo-picker-item-position" style={!isOpenned ? {top: `calc(2em * -${ showStepper ? (choices.length-1 - (chosen || 0)) : (chosen || 0)})`} : {}}>
                {choices.map((choice, index) => 
                    <div 
                        key={choice.value} 
                        className={"neo-picker-item" + (showStepper ? ((choices.length - 1) - (chosen || 0)  == index ? " picked" : "") : (chosen == index ? " picked" : ""))} 
                        value={choice.value} 
                        onClick={e => handlePick(choice.value, index, e)}>
                        <span>
                            {choice.label}
                        </span>
                    </div>
                )}
                </div>
            </div>
    )
    return(
        <div ref={pickerComponent} className={"neo-picker" + (align ? ` ${align}` : "") + (showStepper ? " bare" : "")} data-openned={isOpenned}>

            {
                showStepper ? 
                (
                    <>
                        <button type="button" className="neo-bare-stepper-up" onClick={stepUp}><FiArrowUp/></button>
                        <div className="neo-picker-list-container">
                        { picker }
                        </div>
                        <button type="button" className="neo-bare-stepper-back" onClick={stepBack}><FiArrowDown/></button>
                    </>
                ) : (
                    <>
                    { picker }
                    {
                        !isControllerShown ? 
                        <button type="button" className="neo-stepper-expand" onClick={() => handleControlToggle()}><FiCode/></button>
                        :
                        <div ref={stepper} className="neo-stepper-controller">
                            <button type="button" className="neo-stepper-up" onClick={stepBack}><FiArrowUp/></button>
                            <button type="button" className="neo-stepper-back" onClick={stepUp}><FiArrowDown/></button>
                        </div>
                    }
                    </>
                )
            }
        </div>
    )
}
export default Picker
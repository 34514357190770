import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Form, Input, Button, Checkbox } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import Chart from 'react-apexcharts'
import http from '../../../services/httpServiceHealthCare'
import { WeightText } from './styles'
import { FilterOptions } from '../constant'
import { cloneDeep } from 'lodash'

function WeightGraphCard(props) {
  const { graphs } = props
  const { lines } = graphs
  const options = cloneDeep(lines.options)
  const series = cloneDeep(lines.series)

  return (
    <Card className="mt-2" title="กราฟแนวโน้มน้ำหนัก">
      <Chart options={options} series={series} type="line" width="600" />
    </Card>
  )
}

export default WeightGraphCard

import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/Medicines'

class Medicine {
  @observable info = {
    data: [],
    error: null
  }

  @action fetchMedicines = async () => {
    try {
      const { data } = await http.get(`${apiEndpoint}`)
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @computed get getMedicine() {
    return toJS(this.medicine.data)
  }
}

export default new Medicine()

import React, { Component } from 'react'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import jwtDecode from 'jwt-decode'
import { FileOutlined, PhoneOutlined, LoadingOutlined, LeftOutlined } from '@ant-design/icons'
import { Tag, Popconfirm, Row, Col, Modal, Select, Card, Spin, DatePicker, TimePicker, Radio } from 'antd'
import { message } from 'antd'
import { find, sortBy, intersectionBy } from 'lodash'
import moment from 'moment'
import 'moment/locale/th'
import firebase from 'firebase'
import Button from '../Button'
import { BOOKING_DOCTOR_STATUS } from '../../constant'
import { PRACTITIONER_ROLES } from '../../constant'
import SelectVisitNumberForm from '../SelectVisitNumberForm'
const ModalDocTorAvailableTime = styled(Modal)`
  .ant-modal-body {
    height: 50vh;
    overflow-y: scroll;
  }
`
@inject('Appointment')
@inject('Booking')
@inject('Prescription')
@inject('AppUserIdentity')
@inject('UserInfo')
@inject('DoctorAvailableTime')
@inject('PharmacyAvailableTime')
@inject('Prescription')
@inject('PrescriptionPickup')
@inject('UserFcmToken')
@inject('FCM')
@inject('User')
@observer
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      roles: jwtDecode(localStorage.getItem('token')).roles,
      isLoading: false,
      orderNo: [],
      drugs: [],
      vn: null,
      patient: {},
      bookingId: null,
      prescriptionId: null,
      admitTime: null,
      orderNumber: null,
      doctorName: null,
      antIcon: <LoadingOutlined style={{ fontSize: 24 }} spin />,
      availableDays: [],
      visibleModalOrderNo: false,
      visibleModalDoctorAvailableTime: false,
      visibleModalEphis: false,
      visibleModalEditBooking: false,
      forPharmacy: false,
      indexDoctorAvailableTime: null,
      selectTime: null,
      showAvailableTime: false,
      forPharmacy: false,
      availableDate: false,
      availableValue: false,
      pharmacyTimes: [],
      setChangeTime: '',
      setChangeDate: '',
      setChangePractitioner: '',
      visitNumberList: [],
      visibleDoctorCompleteModal: false,
      selectedVnNumber: '',
      followUpStatus: true,
      nextAppointment: null,
      practitionerLists: [],
      practitionerLoading: false,
    }
  }

  async componentDidMount() {
    await this.getUser()
  }

  getUser = () => {
    const user = jwtDecode(localStorage.getItem('token'))
    this.setState({
      user: user,
    })
  }

  setStatus = async (id, status, isUpdate) => {
    const { Booking } = this.props
    const response = await Booking.setStatus(id, status)
    if (response) {
      message.success('เปลี่ยนสถานะเสร็จสิ้น')
      this.props.refreshData()
    }
  }

  handleGetNextAppointment = async (id) => {
    const { Appointment } = this.props
    const response = await Appointment.getNextAppointment(id)
    if (response) {
      this.setState({
        nextAppointment: response.appointmentDateTime,
      })
    } else {
      this.setState({
        nextAppointment: null,
      })
    }
  }

  handleUpdateBooking = async (id, data) => {
    const { Booking } = this.props
    const response = await Booking.updateBooking(id, data)
    if (response) {
      message.success('อัปเดตข้อมูลนัดหมายเสร็จสิ้น')
      this.props.refreshData()
    }
  }

  fetchOrder = (id, patientId, prescriptionId, selectedDate) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { UserInfo, User, booking } = this.props
        const admitDate = moment(booking.admitTime).format('YYYY-MM-DD')
        const userRes = await User.fetchUserInfo(this.state.user.id)
        const everOmaId = await this.fetchAppUserIdentity(patientId)
        await UserInfo.triggerUpdateData(everOmaId, admitDate)
        const response = await UserInfo.selectOrderNumber(everOmaId, selectedDate, userRes.employeeId)
        if (response && response.orderNo && response.orderNo.length) {
          this.setState({
            orderNo: response.orderNo,
          })
        } else {
          this.setState({
            orderNo: [],
          })
        }
        this.setState({
          isLoading: false,
        })
      },
    )
  }

  fetchAppUserIdentity = async (id) => {
    const { AppUserIdentity } = this.props
    const response = await AppUserIdentity.fetchAppUserIdentity(id)
    if (response && response.length) {
      return response[response.length - 1].everOmaId
    } else {
      return false
    }
  }

  fetchDrugByOrderNumber = (orderNumber, patientId, isNeedUpdate = false) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { UserInfo, booking } = this.props
        const admitDate = moment(booking.admitTime).format('YYYY-MM-DD')
        const everOmaId = await this.fetchAppUserIdentity(patientId)
        if (isNeedUpdate) {
          await UserInfo.triggerUpdateData(everOmaId, admitDate)
        }
        if (everOmaId) {
          const response = await UserInfo.checkDrugsDetailByOrderNo(everOmaId, orderNumber)
          this.setState({
            drugs: response.billingItems,
            vn: response.vn,
            doctorName: response.doctorName,
            isLoading: false,
          })
        }
      },
    )
  }

  updateVisitNumber = async (id, patchData, isPrescription) => {
    const { Booking } = this.props
    const response = await Booking.updateBooking(id, patchData, isPrescription)
    if (response) {
      message.success('อัปเดตหมายเลขวิสิตเสร็จสิ้น')
    }
  }

  fetchUserFcmToken = async (id, message, time, screen) => {
    const { UserFcmToken, FCM } = this.props
    const everOmaId = await this.fetchAppUserIdentity(id)
    if (everOmaId) {
      const response = await UserFcmToken.fetchUserFcmToken(everOmaId)
      if (response && response.length) {
        response.forEach(async (res) => {
          let notification = {
            body: message,
            title: 'แจ้งเตือน',
            icon: 'ic_launcher',
            color: '#f1c40f',
            sound: 'default',
          }
          let data = {
            bookingTime: time,
            screen: screen,
          }
          await FCM.sendMessage(res.fcmToken, notification, data)
        })
      }
    }
  }

  convertDataBookingTime = (admitTimeDate, timeBooking) => {
    const bookingTime = moment()
      .startOf('week')
      .add(timeBooking + 1440, 'minutes')
      .format('HH:mm')
    const timeBookingHour = bookingTime.split(':')[0]
    const timeBookingMinute = bookingTime.split(':')[1]
    const date = new Date(admitTimeDate).setHours(timeBookingHour, timeBookingMinute)
    return date
  }

  callToPatient = (record) => {
    const { id } = jwtDecode(localStorage.getItem('token'))
    firebase
      .database()
      .ref(`patientStatus/patient${record.patientId}pharmacy`)
      .update({
        status: 'pharmacyCallBack',
        officerId: id,
        bookingId: record.id,
        roomId: `pharmacy${record.patientId}`,
      })
  }

  setStatusPrescription = async (prescriptionId, status) => {
    const { Prescription } = this.props
    await Prescription.updateStatus(prescriptionId, status)
    this.props.refreshData()
  }

  createPrescription = async (bookingId, patientId) => {
    const { Prescription, Booking } = this.props
    const response = await Prescription.createPrescription(patientId)
    await Booking.updateBooking(bookingId, response.id, true)
    this.props.refreshData()
  }

  editBookingDateTime = async (admitTime, bookingTime, doctorId, patientId) => {
    const { Booking } = this.props
    const bookingPatch = {
      admitTime: admitTime,
      bookingTime: bookingTime,
      doctorId: doctorId,
      patientId: patientId,
    }
    const resp = await Booking.editBooking(this.props.booking.id, bookingPatch)
    if (resp) {
      message.success('เปลี่ยนแปลงนัดหมายเสร็จสิ้น')
      this.setState({
        visibleModalEditBooking: false,
      })
      this.props.refreshData()
    }
  }

  updatePrescription = async (medicines, bookingId) => {
    const { Prescription } = this.props
    const response = await Prescription.updatePrescription(this.state.prescriptionId, medicines)
    if (response) {
      this.setStatus(bookingId, 'PHARMACY_PENDING_RX')
      this.props.refreshData()
    }
  }

  fetchDoctorAvailableTimes = async (doctorId) => {
    const { DoctorAvailableTime, Booking } = this.props
    const response = await DoctorAvailableTime.fetchfilterType(doctorId)
    const resBooking = await Booking.getFilterByDoctorId(doctorId)
    const availableDays = []
    response.time.forEach((t) => {
      const timeStart = t[0]
      const timeEnd = t[1]
      const data = {
        day: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format('D'),
        date: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format('dddd'),
        times: [],
        value: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format(),
      }
      for (let time = timeStart; time <= timeEnd; time += response.minute) {
        const timeForSelect = {
          label: moment()
            .startOf('isoWeek')
            .add(time, 'minutes')
            .format('HH:mm'),
          isAvailable: find(resBooking, { bookingTime: time }),
        }
        data.times.push(timeForSelect)
      }
      availableDays.push(data)
    })
    this.setState({ availableDays: availableDays })
  }

  fetchPharmacyAvailableTimes = async (doctorId) => {
    const { PharmacyAvailableTime, Booking } = this.props
    await PharmacyAvailableTime.fetchPharmacyAvailableTime()
    const response = await PharmacyAvailableTime.getPharmacyAvailableTime
    const resBooking = await Booking.getFilterByDoctorId(doctorId)
    const availableDays = []
    response.forEach((t) => {
      const timeStart = t.time[0]
      const timeEnd = t.time[1]
      const data = {
        day: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format('D'),
        date: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format('dddd'),
        data: {
          date: moment()
            .startOf('isoWeek')
            .add(timeStart, 'minutes')
            .format('YYYY-MM-DD'),
          startTime: timeStart,
          endTime: timeEnd,
          day: moment()
            .startOf('isoWeek')
            .add(timeStart, 'minutes')
            .format('dddd'),
        },
        value: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format(),
      }
      availableDays.push(data)
    })
    const sortByAvailableDays = sortBy(availableDays, [
      function (o) {
        return o.day
      },
    ])
    const intersection = intersectionBy(sortByAvailableDays, 'day')
    this.setState({
      availableDays: intersection,
    })
  }

  patchBooking = async (id, date, time, admitTime, isForPharmacy) => {
    const { Booking } = this.props
    const dateAsMinutes =
      (moment()
        .day(date)
        .format('d') -
        2) *
      1440
    const asMinutes = moment.duration(time).asMinutes()
    const sumAsMinutes = dateAsMinutes + asMinutes
    const response = await Booking.patchBooking(id, sumAsMinutes, admitTime)
    if (response) {
      if (isForPharmacy) {
        this.setStatus(id, 'PHARMACY_PENDING_BOOKING', false)
      } else {
        this.setStatus(id, 'DOCTOR_PENDING', false)
      }
      message.success('ทำการจองวัน เวลานัดกับแพทย์เสร็จสิ้น')
    }
  }

  fetchCheckBookingNumberPharmacy = async (data) => {
    const { PharmacyAvailableTime } = this.props
    const response = await PharmacyAvailableTime.checkBookingNumberPharmacy(data)
    let availableDays = []
    response.map((res) => {
      const availableTime = {
        isAvailable: res.maxQuantity !== res.count,
        time: `${moment()
          .startOf('isoWeek')
          .add(res.startTime, 'minutes')
          .format('HH:mm')} - ${moment()
            .startOf('isoWeek')
            .add(res.endTime, 'minutes')
            .format('HH:mm')}`,
        value: moment()
          .startOf('isoWeek')
          .add(res.startTime, 'minutes')
          .format('HH:mm'),
      }
      availableDays.push(availableTime)
    })
    this.setState({
      pharmacyTimes: availableDays,
    })
  }

  fetchUserInfo = async () => {
    const { User } = this.props
    const { id } = jwtDecode(localStorage.getItem('token'))
    const doctorData = await User.fetchUserInfo(id)
    if (doctorData) {
      return doctorData
    }
    return null
  }

  fetchVisitNumber = async (patientId, vstDate, startDate, endDate) => {
    this.setState({
      isLoading: true,
      visitNumberList: [],
    })
    const { UserInfo } = this.props
    const everOmaId = await this.fetchAppUserIdentity(patientId)
    await UserInfo.triggerUpdateData(everOmaId, vstDate)
    const res = await UserInfo.newCheckUserVisitNumber(everOmaId, startDate, endDate)
    if (res && res.docs?.length) {
      let vnList = []
      res.docs.map((item) => {
        vnList.push(item.vn)
      })
      this.setState({
        visitNumberList: vnList,
        isLoading: false,
      })
    } else {
      this.setState({
        isLoading: false,
      })
    }
  }

  checkVisitNumber = async (patientId, vstDate, selectedVnNumber) => {
    const { UserInfo } = this.props
    const res = await UserInfo.checkVisitNumber(patientId, vstDate)
    if (res && res.length) {
      const filterVisit = res.find((item) => item.vn === selectedVnNumber)
      console.log('filterVisit')
      console.log(filterVisit)
      if (filterVisit) {
        return filterVisit.paymentSummarys[0]
      }
      return null
    }
    return null
  }

  createPrescriptionPickup = async (data) => {
    const { PrescriptionPickup } = this.props
    const response = await PrescriptionPickup.createPrescriptionPickup(data)
    if (response) {
      return response
    } else {
      message.error('เกิดข้อผิดพลาดในการส่งข้อมูล')
    }
  }

  createBooking = async (nextAdmitTime) => {
    const { Booking, booking } = this.props
    const data = {
      admitTime: nextAdmitTime,
      bookingTime: booking.bookingTime,
      patientId: booking.patientId,
      doctorId: booking.doctorId,
      bookingEndTime: booking.bookingEndTime,
      bookingType: booking.bookingType,
      bookingDevice: booking.bookingDevice ? booking.bookingDevice : null,
      notes: booking.notes,
      followUp: false,
    }
    await Booking.createBooking(data)
  }

  handleDoctorCompleteFlow = async () => {
    const { booking } = this.props
    const { followUpStatus } = this.state
    this.setState({
      isLoading: true,
    })
    const everOmaId = await this.fetchAppUserIdentity(booking.patientId)
    const doctorData = await this.fetchUserInfo()

    const status = this.state.roles.includes('doctor') ? 'DOCTOR_COMPLETED' : 'STAFF_COMPLETED'
    if (followUpStatus) {
      if (booking.bookingType && booking.bookingType === 'ARI (OPSI)') {
        //check if booking type = ARI (OPSI) and followUp is true
        //create new booking by duplicate old booking and add admitTime 1day
        const nextAdmitTime = moment(booking.admitTime)
          .add(1, 'days')
          .format()
        this.createBooking(nextAdmitTime)
        this.setState({
          nextAppointment: nextAdmitTime,
        })
      } else {
        await this.handleGetNextAppointment(booking.id)
      }
    }
    if ((booking.bookingType && booking.bookingType !== 'ARI (OPSI)') || !booking.bookingType) {
      //booking type normal flow
      const resVisit = await this.checkVisitNumber(everOmaId, moment(booking.admitTime).format('YYYY-MM-DD'), this.state.selectedVnNumber)
      const data = {
        userId: everOmaId,
        bookingTime: booking.admitTime,
        vnNumber: this.state.selectedVnNumber || null,
        orderNumber: null,
        hnId: booking.patient.hnId || null,
        drugItems: null,
        amount: resVisit ? resVisit.unrcptamt : null,
        prescriptionId: booking.prescriptionId,
        doctor: doctorData,
        bookingId: booking.id,
        bookingMinutesTime: booking.bookingTime,
        bookingMinutesEndTime: booking.bookingEndTime,
      }
      await this.createPrescriptionPickup(data)
      await this.updateVisitNumber(booking.id, { vn: this.state.selectedVnNumber }, false)
    }
    booking.prescriptionId && (await this.setStatusPrescription(booking.prescriptionId, 'PATIENT_PENDING_PAYMENT'))
    await this.handleUpdateBooking(booking.id, { followUp: followUpStatus, nextAppointment: this.state.nextAppointment, status, vn: this.state.selectedVnNumber })
    this.setState({
      isLoading: false,
    })
  }
  fetchPractitionerList = async (booking) => {
    this.setState({
      practitionerLoading: true,
    })
    const role = booking?.doctor?.roles[0]?.name
    let responseList = []
    if (role === 'doctor') {
      responseList = await this.fetchDoctorTypeUsers(booking?.doctor?.doctorTypeId)
    } else {
      responseList = await this.fetchOtherTypesUsers(booking?.doctor?.otherTypeId)
    }
    this.setState({
      practitionerList: responseList,
      practitionerLoading: false,
    })
    return responseList
  }

  fetchDoctorTypeUsers = async (doctorTypeId) => {
    const { User } = this.props
    const response = await User.fetchDoctorTypeUsers(doctorTypeId)
    if (response) {
      return response
    } else {
      message.error('เกิดข้อผิดพลาดในการเชื่อมต่อข้อมูลแผนก')
    }
  }
  fetchOtherTypesUsers = async (otherTypeId) => {
    const { User } = this.props
    const response = await User.fetchOtherTypesUsers(otherTypeId)
    if (response) {
      return response
    } else {
      message.error('เกิดข้อผิดพลาดในการเชื่อมต่อข้อมูลแผนก')
    }
  }

  submitEditAppointment = async (admitTime, bookingTime, doctorId, patientId) => {
    const timeBookFromCallcenter = moment.duration(bookingTime).asMinutes()
    const resp = await this.editBookingDateTime(admitTime, timeBookFromCallcenter, doctorId, patientId)
  }
  render() {
    const { booking, setVisibleReportProblemModal, setVisibleCancelBookingModal, setBookingId, setPatientId, setActionStatus, clearReportProblems, currentTab, handleDeclineCall, refreshData, history, roleAction } = this.props
    const { practitionerLists, practitionerLoading } = this.state
    return (
      <>
        {PRACTITIONER_ROLES.includes(this.state.roles[0]) ? (
          <>
            {['รอแพทย์ดำเนินการ', 'รอเจ้าหน้าที่ดำเนินการ'].includes(BOOKING_DOCTOR_STATUS[booking.status]) ? (
              <Row gutter={16}>
                <Col>
                  <Popconfirm
                    title="ต้องการอนุมัติรายการนี้ใช่หรือไม่"
                    onConfirm={() => {
                      const status = this.state.roles.includes('doctor') ? 'DOCTOR_CONFIRM' : ' STAFF_CONFIRM'
                      this.setStatus(booking.id, status)
                    }}
                    okText="ใช่"
                    cancelText="ไม่"
                  >
                    <Button type="primary">อนุมัติ</Button>
                  </Popconfirm>
                </Col>
                <Col>
                  <Popconfirm
                    title="ต้องการยกเลิกรายการนี้ใช่หรือไม่"
                    onConfirm={() => {
                      const status = this.state.roles.includes('doctor') ? 'DOCTOR_DECLINE' : ' STAFF_DECLINE'
                      this.setStatus(booking.id, status)
                    }}
                    okText="ใช่"
                    cancelText="ไม่"
                  >
                    <Button type="danger">ยกเลิก</Button>
                  </Popconfirm>
                </Col>
              </Row>
            ) : ['แพทย์ทำการอนุมัติ', 'เจ้าหน้าที่ทำการอนุมัติ'].includes(BOOKING_DOCTOR_STATUS[booking.status]) ? (
              <Row gutter={16}>
                {this.state.roles.includes('doctor') && booking.bookingType !== 'ARI (OPSI)' && (
                  <Col>
                    <Button
                      onClick={() => {
                        this.fetchOrder(booking.id, booking.patientId, booking.prescriptionId, moment(booking.admitTime).format('YYYY-MM-DD'))
                        this.setState({
                          patient: booking.patient,
                          bookingId: booking.id,
                          prescriptionId: booking.prescriptionId,
                          admitTime: moment(booking.admitTime).format('YYYY-MM-DD'),
                          visibleModalOrderNo: true,
                        })
                      }}
                      type="primary"
                    >
                      ส่งต่อเภสัชกร
                    </Button>
                  </Col>
                )}
                <Col>
                  <Popconfirm
                    title="ต้องการเสร็จสิ้นรายการนี้ใช่หรือไม่"
                    onConfirm={() => {
                      booking.bookingType !== 'ARI (OPSI)' &&
                        this.fetchVisitNumber(
                          booking.patientId,
                          moment(booking.admitTime).format('YYYY-MM-DD'),
                          moment(booking.admitTime)
                            .subtract(1, 'days')
                            .format('YYYY-MM-DD'),
                          moment(booking.admitTime)
                            .add(1, 'days')
                            .format('YYYY-MM-DD'),
                        )
                      this.setState({
                        visibleDoctorCompleteModal: true,
                      })
                    }}
                    okText="ใช่"
                    cancelText="ไม่"
                  >
                    <Button type="primary">เสร็จสิ้น (ไม่มีสั่งจ่ายยา)</Button>
                  </Popconfirm>
                </Col>
                <Col>
                  <Button
                    type="danger"
                    onClick={() => {
                      const status = this.state.roles.includes('doctor') ? 'DOCTOR_ALERT' : 'STAFF_ALERT'
                      setVisibleReportProblemModal(true)
                      setActionStatus(status)
                      setBookingId(booking.id)
                    }}
                  >
                    แจ้งปัญหา
                  </Button>
                </Col>
              </Row>
            ) : null}
          </>
        ) : this.state.roles.includes('pharmacy') ? (
          <>
            {BOOKING_DOCTOR_STATUS[booking.status] === 'รอเภสัชทำการจองนัด' ? (
              <Row gutter={16}>
                <Col>
                  <Popconfirm
                    title="ต้องการอนุมัติรายการนี้ใช่หรือไม่"
                    onConfirm={() => {
                      this.setStatus(booking.id, 'PHARMACY_CONFIRM_BOOKING')
                      this.fetchUserFcmToken(booking.patientId, 'เภสัชกรได้ทำการอนุมัติการนัดหมายของคุณเรียบร้อยแล้ว', this.convertDataBookingTime(booking.admitTime, booking.bookingTime), 'MyBookingsUI')
                    }}
                    okText="ใช่"
                    cancelText="ไม่"
                  >
                    <Button type="primary">อนุมัติ</Button>
                  </Popconfirm>
                </Col>
                <Col>
                  <Button
                    type="danger"
                    onClick={() => {
                      setVisibleCancelBookingModal(true)
                      setActionStatus('PHARMACY_DECLINE_BOOKING')
                      setBookingId(booking.id)
                      setPatientId(booking.patientId)
                    }}
                  >
                    ยกเลิก
                  </Button>
                </Col>
              </Row>
            ) : BOOKING_DOCTOR_STATUS[booking.status] === 'เภสัชทำการอนุมัติการจองนัด' || BOOKING_DOCTOR_STATUS[booking.status] === 'เสร็จสิ้นกระบวนการพบเภสัชกร' ? (
              <Row gutter={16}>
                <Col>
                  <Popconfirm
                    title="ต้องการจะโทรหาผู้ป่วยใช่หรือไม่"
                    okText="ใช่"
                    cancelText="ไม่"
                    onConfirm={() => {
                      this.callToPatient(booking)
                      this.fetchUserFcmToken(booking.patientId, 'เภสัชกำลังโทรหาคุณรบกวนเข้าไปรอใน waiting Room', null, 'MyBookingsUI')
                      if (history?.location?.pathname?.includes('telemed-lobby')) {
                        refreshData()
                      } else {
                        history.push('/telemed-lobby')
                      }
                    }}
                  >
                    <Button type="primary" style={{ display: 'flex', alignItems: 'center' }}>
                      <PhoneOutlined style={{ color: '#ffffff' }} />
                    </Button>
                  </Popconfirm>
                </Col>
                {BOOKING_DOCTOR_STATUS[booking.status] !== 'เสร็จสิ้นกระบวนการพบเภสัชกร' && (
                  <Col>
                    <Popconfirm title="ต้องการเสร็จสิ้นรายการนี้ใช่หรือไม่" onConfirm={() => this.setStatus(booking.id, 'PHARMACY_COMPLETED')} okText="ใช่" cancelText="ไม่">
                      <Button type="primary">เสร็จสิ้น</Button>
                    </Popconfirm>
                  </Col>
                )}
                {BOOKING_DOCTOR_STATUS[booking.status] !== 'เสร็จสิ้นกระบวนการพบเภสัชกร' && (
                  <Col>
                    <Button
                      type="danger"
                      onClick={() => {
                        setVisibleReportProblemModal(true)
                        setActionStatus('PHARMACY_ALERT')
                        setBookingId(booking.id)
                      }}
                    >
                      แจ้งปัญหา
                    </Button>
                  </Col>
                )}
              </Row>
            ) : null}
          </>
        ) : this.state.roles.includes('callCenter') ? (
          <>
            {booking.prescription && booking.prescription.paymentStatus === 'PATIENT_PENDING_PAYMENT' ? (
              <div>
                <Popconfirm title="ต้องการยืนยันการชำระการชำระเงินของรายการนี้ใช่หรือไม่" onConfirm={() => this.setStatusPrescription(booking.prescription.id, 'PATIENT_SUCCESS_PAYMENT')} okText="ใช่" cancelText="ไม่">
                  <Button type="primary">ยืนยันการชำระเงิน</Button>
                </Popconfirm>
              </div>
            ) : BOOKING_DOCTOR_STATUS[booking.status] === 'รอคอลเซ็นเตอร์ดำเนินการ' ? (
              <div>
                <Popconfirm
                  title="ต้องการอนุมัติรายการนี้ใช่หรือไม่"
                  onConfirm={() => {
                    const bookingInfo = booking
                    const doctorDepartment = bookingInfo.doctor.detail?.department
                    if (bookingInfo.doctor.roles[0].name === 'doctor') {
                      this.setStatus(bookingInfo.id, 'DOCTOR_CONFIRM')
                    } else {
                      this.setStatus(bookingInfo.id, 'STAFF_CONFIRM')
                    }
                    if (!bookingInfo.bookingType && doctorDepartment === 'ARI (OPSI)') {
                      //change booking type to covid flow ( no prescription )
                      this.handleUpdateBooking(bookingInfo.id, { bookingType: 'ARI (OPSI)' })
                    }
                    this.createPrescription(bookingInfo.id, bookingInfo.patientId)
                    this.fetchUserFcmToken(bookingInfo.patientId, `${roleAction}ได้ทำการอนุมัติการนัดหมายเรียบร้อยแล้ว`, this.convertDataBookingTime(bookingInfo.admitTime, bookingInfo.bookingTime), 'MyBookingsUI')
                  }}
                  okText="ใช่"
                  cancelText="ไม่"
                >
                  <Button type="primary">อนุมัติ</Button>
                </Popconfirm>
                <Button
                  className="ml-2"
                  dangerous
                  onClick={async () => {
                    console.log('doctorID = ', booking?.doctorId)
                    const responseLists = await this.fetchPractitionerList(booking)
                    this.setState({
                      practitionerLists: responseLists,
                      setChangePractitioner: booking?.doctorId,
                      visibleModalEditBooking: true,
                    })
                  }}
                >
                  แก้ไข
                </Button>
                <Button
                  className="ml-2"
                  type="danger"
                  onClick={() => {
                    setVisibleCancelBookingModal(true)
                    setActionStatus('CALL_CENTER_DECLINE')
                    setBookingId(booking?.id)
                    setPatientId(booking?.patientId)
                  }}
                >
                  ยกเลิก
                </Button>
              </div>
            ) : BOOKING_DOCTOR_STATUS[booking.status] === 'รอแพทย์ดำเนินการ' ? (
              <Popconfirm title="ต้องการย้อนกลับรายการนี้ใช่หรือไม่" onConfirm={() => this.setStatus(booking.id, 'PATIENT_DRAFT')} okText="ใช่" cancelText="ไม่">
                <Button type="primary">ย้อนกลับ</Button>
              </Popconfirm>
            ) : ['DOCTOR_ALERT', 'PHARMACY_ALERT', 'STAFF_ALERT'].includes(booking.status) ? (
              <Popconfirm
                title="ต้องการเปลี่ยนสถานะใช่หรือไม่ ?"
                okText="ใช่"
                cancelText="ไม่"
                onConfirm={() => {
                  const { id, status } = booking
                  const rollBackStatus = status.includes('DOCTOR_ALERT') ? 'DOCTOR_CONFIRM' : status.includes('PHARMACY_ALERT') ? 'PHARMACY_CONFIRM_BOOKING' : status.includes('STAFF_ALERT') ? 'STAFF_CONFIRM' : null
                  this.setStatus(id, rollBackStatus)
                  clearReportProblems(id)
                }}
              >
                <Button type="primary">แก้ไขแล้ว</Button>
              </Popconfirm>
            ) : BOOKING_DOCTOR_STATUS[booking.status] === 'รอคอนเฟิร์มใบสั่งยา' ? (
              <Row gutter={16}>
                <Col>
                  <Popconfirm
                    title="ต้องการอนุมัติรายการนี้ใช่หรือไม่"
                    okText="ใช่"
                    cancelText="ไม่"
                    onConfirm={() => {
                      this.setStatusPrescription(booking.prescriptionId, 'PHARMACY_PENDING_RX')
                      const isNotEmsLogisticsId = [1, 3]
                      isNotEmsLogisticsId.includes(booking.prescription.logisticId) ? this.setStatus(booking.id, 'DOCTOR_COMPLETED') : this.setStatus(booking.id, 'EPHIS_CONFIRM')
                      this.setState({
                        visibleModalEphis: false,
                      })
                      this.fetchUserFcmToken(booking?.patientId, `${roleAction}ได้สั่งยาให้คุณแล้ว กรุณานัดเภสัชกรเพื่อเรียนรู้วิธีใช้ยา`, null, 'MyBookingsUI')
                    }}
                  >
                    <Button type="primary">อนุมัติ</Button>
                  </Popconfirm>
                </Col>
              </Row>
            ) : ['todayBookings'].includes(currentTab) ? (
              <>
                <Popconfirm
                  title="โปรดระวัง! กรุณาใช้กับห้องวิดีโอคอลที่ค้างเท่านั้น"
                  onConfirm={() => {
                    const roomId = `patient${booking?.patient?.id}${booking?.doctor?.roles[0].name}${booking?.doctor?.id}`
                    handleDeclineCall(roomId)
                  }}
                  okText="เคลียร์ห้องค้าง"
                  cancelText="ยกเลิก"
                >
                  <Button className="ml-2" type="danger">
                    เคลียร์ห้องค้าง
                  </Button>
                </Popconfirm>
                <Button
                  className="ml-2"
                  type="danger"
                  onClick={() => {
                    setVisibleCancelBookingModal(true)
                    setActionStatus('CALL_CENTER_DECLINE')
                    setBookingId(booking?.id)
                    setPatientId(booking?.patientId)
                  }}
                >
                  ยกเลิก
                </Button>
              </>
            ) : ['futureBookings'].includes(currentTab) ? (
              <>
                <Button
                  className="ml-2"
                  type="danger"
                  onClick={() => {
                    setVisibleCancelBookingModal(true)
                    setActionStatus('CALL_CENTER_DECLINE')
                    setBookingId(booking?.id)
                    setPatientId(booking?.patientId)
                  }}
                >
                  ยกเลิก
                </Button>
              </>
            ) : null}
          </>
        ) : null}
        <Modal
          visible={this.state.visibleModalEditBooking}
          title="แก้ไขนัดหมาย"
          footer={
            <div>
              <Button
                type="primary"
                onClick={() => {
                  console.log(
                    'submitEditAppointment =',
                    this.state.setChangeDate || moment(booking.admitTime).format('YYYY-MM-DD'),
                    this.state.setChangeTime ||
                    moment()
                      .startOf('day')
                      .add(booking.bookingTime, 'minutes')
                      .format('HH:mm'),
                    this.state.setChangePractitioner,
                    booking.patientId,
                  )
                  this.submitEditAppointment(
                    this.state.setChangeDate || moment(booking.admitTime).format('YYYY-MM-DD'),
                    this.state.setChangeTime ||
                    moment()
                      .startOf('day')
                      .add(booking.bookingTime, 'minutes')
                      .format('HH:mm'),
                    this.state.setChangePractitioner,
                    booking.patientId,
                  )
                  this.fetchUserFcmToken(
                    booking.patientId,
                    `${roleAction}ได้ทำการเปลี่ยนแปลงวันนัดหมายของท่านจากวันที่ ${moment(booking?.admitTime).format('DD/MM/YYYY')} เวลา ${moment()
                      .startOf('day')
                      .add(booking.bookingTime, 'minutes')
                      .format('HH:mm')} น. เป็นวันที่ ${this.state.setChangeDate ? moment(new Date(this.state.setChangeDate)).format('DD/MM/YYYY') : moment(booking.admitTime).format('DD/MM/YYYY')} เวลา ${this.state.setChangeTime ||
                      moment()
                        .startOf('day')
                        .add(booking.bookingTime, 'minutes')
                        .format('HH:mm')} น.
                    `,
                    this.convertDataBookingTime(booking.admitTime, booking.bookingTime),
                    'MyBookingsUI',
                  )
                }}
              >
                บันทึก
              </Button>

              <Button
                onClick={() =>
                  this.setState({
                    visibleModalEditBooking: false,
                  })
                }
                type="danger"
              >
                ยกเลิก
              </Button>
            </div>
          }
          onOk={() => {
            this.setState({
              visibleModalEditBooking: false,
            })
          }}
          onCancel={() => {
            this.setState({
              visibleModalEditBooking: false,
            })
          }}
        >
          <Row>
            <Col span={8}>กำหนดวันนัดหมายใหม่</Col>
            <Col span={16}>
              {!practitionerLoading && (
                <DatePicker
                  onChange={(date, dateString) =>
                    this.setState({
                      setChangeDate: dateString,
                    })
                  }
                  defaultValue={moment(new Date(booking.admitTime), 'YYYY-MM-DD')}
                  style={{ width: '100%' }}
                />
              )}
            </Col>
            <Col span={8}>เวลานัดหมาย</Col>
            <Col span={16}>
              {!practitionerLoading && (
                <TimePicker
                  onChange={(time, timeString) =>
                    this.setState({
                      setChangeTime: timeString,
                    })
                  }
                  defaultValue={moment(
                    moment()
                      .startOf('day')
                      .add(booking.bookingTime, 'minutes')
                      .format('HH:mm'),
                    'HH:mm',
                  )}
                  format={'HH:mm'}
                  style={{ width: '100%' }}
                />
              )}
            </Col>
            <Col span={8}>ระบุเจ้าหน้าที่</Col>
            <Col span={16}>
              {!practitionerLoading && practitionerLists.length && (
                <Select
                  showSearch
                  style={{
                    width: '100%',
                  }}
                  defaultValue={booking?.doctorId}
                  onChange={(value) => this.setState({ setChangePractitioner: value })}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.includes(input)}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {practitionerLists.map((item) => (
                    <Select.Option value={item.id} key={`practitioners${item.id}`}>
                      {item.fullname ?? item.email}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Col>
          </Row>
        </Modal>

        <Modal
          visible={this.state.visibleModalOrderNo}
          title="หมายเลขใบสั่งยา"
          footer={
            <div>
              <Button style={{ backgroundColor: 'yellow' }} onClick={() => this.fetchDrugByOrderNumber(this.state.orderNumber, this.state.patient.id, true)}>
                อัปเดตรายการยา
              </Button>
              <Button
                onClick={() =>
                  this.setState({
                    visibleModalOrderNo: false,
                  })
                }
                type="danger"
              >
                ยกเลิก
              </Button>
              <Button
                disabled={!this.state.orderNumber}
                type="primary"
                onClick={() => {
                  this.setStatus(this.state.bookingId, 'EPHIS_PENDING')
                  const data = {
                    vnNumber: this.state.orderNumber,
                    vn: this.state.vn,
                  }
                  this.updateVisitNumber(this.state.bookingId, data, false)
                  this.setState({
                    visibleModalOrderNo: false,
                  })
                }}
              >
                บันทึก
              </Button>
            </div>
          }
          onOk={() => {
            this.setState({
              orderNumber: null,
              vn: null,
              orderNo: [],
              visibleModalOrderNo: false,
            })
          }}
          onCancel={() => {
            this.setState({
              orderNumber: null,
              vn: null,
              orderNo: [],
              visibleModalOrderNo: false,
            })
          }}
        >
          <Spin indicator={this.state.antIcon} spinning={this.state.isLoading} tip="กรุณารอสักครู่...">
            <Row>
              <Col span={12}>หมายเลขใบสั่งยา</Col>
              <Col span={12}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="เลือกหมายเลขใบสั่งยา"
                  onChange={(order) => {
                    this.fetchDrugByOrderNumber(order, this.state.patient.id, false)
                    this.setState({
                      orderNumber: order,
                    })
                  }}
                  value={this.state.orderNumber}
                >
                  {this.state.orderNo.length &&
                    this.state.orderNo.map((order, index) => (
                      <Option key={order + index} value={order}>
                        {order}
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>
            {this.state.orderNumber && this.state.drugs.length ? (
              <Row>
                <Col span={6}>ชื่อแพทย์ผู้สั่งยา</Col>
                <Col span={18}>{this.state.doctorName}</Col>
                {this.state.drugs.map((drug, index) => (
                  <Card className="mt-2" key={drug.drugNondugName + index}>
                    <Row>
                      <Col span={6}>ลำดับที่</Col>
                      <Col span={18}>{index + 1}</Col>
                      <Col span={6}>ชื่อยา</Col>
                      <Col span={18}>{drug.drugNondugName}</Col>
                      <Col span={6}>จำนวน</Col>
                      <Col span={18}>{drug.qty}</Col>
                      <Col span={6}>วิธีใช้</Col>
                      <Col span={18}>{drug.drugUsageText ? drug.drugUsageText : drug.drugUsage}</Col>
                      <Col offset={6} span={18}>
                        {drug.medlblhlp1_name}
                      </Col>
                      <Col offset={6} span={18}>
                        {drug.medlblhlp2_name}
                      </Col>
                      <Col offset={6} span={18}>
                        {drug.medlblhlp3}
                      </Col>
                      <Col offset={6} span={18}>
                        {drug.medlblhlp4}
                      </Col>
                    </Row>
                  </Card>
                ))}
              </Row>
            ) : null}
          </Spin>
        </Modal >

        <ModalDocTorAvailableTime
          visible={this.state.visibleModalDoctorAvailableTime}
          title="จองวันว่าง"
          onCancel={() => {
            this.setState({
              indexDoctorAvailableTime: null,
              selectTime: null,
              visibleModalDoctorAvailableTime: false,
            })
          }}
          footer={
            this.state.selectTime ? (
              <Button
                type="primary"
                onClick={() => {
                  this.patchBooking(this.state.bookingId, this.state.availableDate, this.state.selectTime, this.state.availableValue, this.state.forPharmacy)
                  this.setState({
                    showAvailableTime: false,
                    indexDoctorAvailableTime: null,
                    selectTime: null,
                  })
                }}
              >
                บันทึก
              </Button>
            ) : null
          }
          style={{ height: '50vh' }}
        >
          <Row gutter={24}>
            {this.state.indexDoctorAvailableTime === null && this.state.availableDays
              ? this.state.availableDays.map((availableDay, index) => (
                <Col span={8} key={availableDay.value + index}>
                  <Button
                    style={{ width: '100%', height: 'auto' }}
                    type="primary"
                    onClick={() => {
                      this.state.forPharmacy ? this.fetchCheckBookingNumberPharmacy(availableDay.data) : ''
                      this.setState({
                        showAvailableTime: true,
                        indexDoctorAvailableTime: index,
                        availableDate: availableDay.date,
                        availableValue: availableDay.value,
                      })
                    }}
                  >
                    <p>{availableDay.day}</p>
                    <p>{availableDay.date}</p>
                  </Button>
                </Col>
              ))
              : null}
            {this.state.indexDoctorAvailableTime !== null ? (
              <Col span={24}>
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({
                      indexDoctorAvailableTime: null,
                      selectTime: null,
                    })
                  }}
                >
                  ย้อนกลับ
                </Button>
                <Row gutter={24}>
                  {this.state.showAvailableTime
                    ? this.state.forPharmacy
                      ? this.state.pharmacyTimes.map(pharmacyTime, (index) => (
                        <Col span={12} key={pharmacyTime.value + index}>
                          {pharmacyTime.isAvailable ? (
                            <Button
                              className="mt-2"
                              onClick={() =>
                                this.setState({
                                  selectTime: pharmacyTime.value,
                                })
                              }
                            >
                              {pharmacyTime.time}
                            </Button>
                          ) : (
                            <ButtonTime className="mt-2">{pharmacyTime.time}</ButtonTime>
                          )}
                        </Col>
                      ))
                      : this.state.availableDays[this.state.indexDoctorAvailableTime].times.map(time, (index) => (
                        <Col span={12} key={time.label + index}>
                          {time.isAvailable ? (
                            <ButtonTime className="mt-2">{time.label}</ButtonTime>
                          ) : (
                            <Button className="mt-2" onClick={() => this.setState({ selectTime: time.label })}>
                              {time.label}
                            </Button>
                          )}
                        </Col>
                      ))
                    : ''}
                </Row>
              </Col>
            ) : null}
          </Row>
        </ModalDocTorAvailableTime>

        <Modal
          className="text-center"
          visible={this.state.visibleDoctorCompleteModal}
          onOk={() => {
            this.setState({
              visibleDoctorCompleteModal: false,
            })
          }}
          onCancel={() => {
            this.setState({
              visibleDoctorCompleteModal: false,
            })
          }}
          title={booking.bookingType && booking.bookingType === 'ARI (OPSI)' ? 'ผู้ป่วยมีนัดหมายต่อหรือไม่' : 'กรุณาเลือกหมายเลขวิสิต'}
          footer={
            <Button
              type="primary"
              disabled={booking.bookingType && booking.bookingType === 'ARI (OPSI)' ? false : this.state.selectedVnNumber.length > 0 ? false : true}
              onClick={() => {
                this.handleDoctorCompleteFlow()
                this.setState({
                  visibleDoctorCompleteModal: false,
                })
              }}
            >
              บันทึก
            </Button>
          }
          width={600}
        >
          {this.state.isLoading ? (
            <Spin indicator={this.state.antIcon} spinning={this.state.isLoading} tip="กำลังโหลดข้อมูล"></Spin>
          ) : (
            <>
              {((booking.bookingType && booking.bookingType !== 'ARI (OPSI)') || !booking.bookingType) && (
                <SelectVisitNumberForm
                  visitNumberList={this.state.visitNumberList}
                  setSelectedVnNumber={(vnNumber) => {
                    this.setState({
                      selectedVnNumber: vnNumber,
                    })
                  }}
                />
              )}
              <Row justify="center" className="mt-3">
                {booking.bookingType && booking.bookingType !== 'ARI (OPSI)' && <Col span={24}>ผู้ป่วยมีนัดหมายต่อหรือไม่</Col>}
                <Col span={24}>
                  <Radio.Group
                    onChange={(e) => {
                      console.log(e.target.value)
                      this.setState({
                        followUpStatus: e.target.value,
                      })
                    }}
                    value={this.state.followUpStatus}
                  >
                    <Radio.Button value={true}>มีนัดหมายต่อ</Radio.Button>
                    <Radio.Button value={false}>ไม่มี</Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
            </>
          )}
        </Modal>
      </>
    )
  }
}

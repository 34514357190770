import styled from 'styled-components'
import TextBase from '../../components/Text'

export const Container = styled.div`
  width: 100%;
  padding: 20px;
`
export const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
export const Text = styled(TextBase)`
  font-weight: bold;
`
export const Card = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.14);
`

import styled from 'styled-components'
import { Row, Col } from 'antd'
import { UserOutlined } from '@ant-design/icons'
export const TopHeaderSection = styled(Col)`
  height: 48px;
  background: #1b4b63;
`
export const UserIcon = styled(UserOutlined)`
  height: 30px;
  width: 30px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
`
export const MenuLists = styled('ul')`
  display: flex;
  margin-left: 5px;
`
export const MenuItem = styled('li')`
  list-style-type: none;
  padding: 0px 4px;
  cursor: pointer;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
`
export const CardBadge = styled.div`
  cursor: pointer;
  border: none;
  box-shadow: none;
  border-radius: 8px;
  &:hover {
    filter: brightness(120%);
  }
`

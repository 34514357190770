import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input } from 'antd'
import { initiaSepsisValues, sepsisFormTemplate } from './constant'
import ToggleToken from '../../ToggleToken'
import styled from 'styled-components'
import TextInput from '../../TextInput'
const Text = styled('p')`
  font-size: 18px;
  padding-right: 10px;
  font-weight: bold;
`
const FormBox = styled(Row)`
  padding: 10px;
`

const Sepsis = ({ setFormValue, initialValues }) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({ esl1: [], esl2: [] })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (initialValues && initialValues.sepsis) {
      setFormData((prev) => ({ ...prev, ...initialValues.sepsis }))
      form.setFieldsValue(initialValues.sepsis)
    }
    setLoading(false)
  }, [initialValues])

  const handleValue = (field, value) => {
    form.setFieldsValue({ [field]: value })
    const allData = form.getFieldsValue(true)
    setFormValue({ ['sepsis']: allData })
  }

  return loading ? (
    <></>
  ) : (
    <Form form={form} initialValues={initiaSepsisValues} name="cardiacArrest" className="additional-form-section">
      <h2>Sepsis</h2>
      {sepsisFormTemplate.map((template) => (
        <div className="additional-form-subsection">
          {template.header && <Text>{template.header}</Text>}
          {template.data.map((data) => (
            <Form.Item name={data.value}>
              {data.type === 'checkbox' ? (
                <ToggleToken
                  title={data.label}
                  options={data.items}
                  values={formData[data.value]}
                  setValues={(value) => {
                    setFormData((prevState) => ({ ...prevState, [data.value]: value }))
                    handleValue(data.value, value)
                  }}
                />
              ) : (
                data.type === 'text' && (
                  <TextInput
                    label={data.label}
                    values={formData[data.value]}
                    onChange={(event) => {
                      setFormData((prevState) => ({ ...prevState, [data.value]: event.target.value }))
                      handleValue(data.value, event.target.value)
                    }}
                  />
                )
              )}
            </Form.Item>
          ))}
        </div>
      ))}
    </Form>
  )
}

export default Sepsis

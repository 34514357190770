import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Divider, DatePicker, Card, Typography, Form, Input, Radio, InputNumber, Modal, Spin, Table, Popconfirm, Select, Checkbox, Tabs, message } from 'antd'
import moment from 'moment'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import Section6 from './Section6'
import firebase from 'firebase'
import styled from 'styled-components'
import http from '../../services/httpServiceEver'
import EmsPdfForm from '../EmsPdfForm'
import { pdf } from '@react-pdf/renderer'
import './layout.css'
import { RESPONSE_DATA, DEMOGRAPHIC_DATA, HISTORY_DATA, IMPRESSION_DATA, TABS_NAME, ASSESSMENT_DATA, TRANSFER_DATA } from './constant'
const { TabPane } = Tabs
const Container = styled.div`
  padding: 20px;
  width: 100%;
`
const index = ({ formDetail, updateEmsForm, fetchUserInfos }) => {
  const [ambulanceList, setAmbulanceList] = useState([])
  const [emsTypologiesData, setEmsTypologiesData] = useState([])
  const [emsUniqueId, setEmsUniqueId] = useState('')
  const [tab, setTabs] = useState('responseData')
  const [responseData, setResponseData] = useState(RESPONSE_DATA)
  const [demographicData, setDemographicData] = useState(DEMOGRAPHIC_DATA)
  const [assessmentData, setAssessmentData] = useState(ASSESSMENT_DATA)
  const [assessmentPickedRow, setAssessmentPickedRow] = useState(0)
  const [historyData, setHistoryData] = useState(HISTORY_DATA)
  const [impressionData, setImpressionData] = useState(IMPRESSION_DATA)
  const [transferData, setTransferData] = useState(TRANSFER_DATA)

  useEffect(() => {
    fetchAmbulanceList()
    fetchEmsTypologies()
  }, [])

  useEffect(() => {
    if (formDetail && Object.keys(formDetail).length > 0 && formDetail.emsUniqueId) {
      setEmsUniqueId(formDetail.emsUniqueId)
    }
  }, [formDetail])

  const fetchEmsTypologies = async () => {
    try {
      const response = await http.get('/EmsTypologies')
      if (response && response.status === 200) {
        setEmsTypologiesData(response.data)
      }
    } catch (error) {
      message.error('เกิดข้อผิดพลาดในการโหลดข้อมูล EMS Typologies')
    }
  }

  const fetchAmbulanceList = () => {
    firebase
      .database()
      .ref('ambulance')
      .on('value', (value) => {
        let data = []
        value.forEach((val) => {
          if (val.val().availability) {
            data.push({ ...val.val(), key: val.key })
          }
        })
        setAmbulanceList(data)
      })
  }

  const openPDFHandler = React.useCallback(async (obj = {}) => {
    const blob = await pdf(<EmsPdfForm objectData={obj} />).toBlob()
    const url = URL.createObjectURL(blob)
    window.open(url, '_blank', 'noopener,noreferrer')
  }, [])

  const handleDataUpdateForm = (sectionKey) => {
    let data = {}
    switch (tab) {
      case 'responseData':
        data = responseData
        break
      case 'demographicData':
        data = demographicData
        break
      case 'assessmentData': {
        let value = assessmentData
        if (assessmentData && assessmentData.timeLog) {
          value.timeLog = assessmentData.timeLog.filter((item, i, log) => i < log.length - 1)
          if (value.timeLog.length) {
            value.record = assessmentData.timeLog[assessmentPickedRow]
          }
        }
        data = value
        break
      }
      case 'historyData':
        data = historyData
        break
      case 'impressionData':
        data = impressionData
        break
      case 'transferData':
        data = transferData
        break
      default:
        data = responseData
        break
    }

    const dataToUpdate = {
      [tab]: data,
      emsUniqueId,
    }
    if (tab == 'responseData') {
      dataToUpdate['operationDateTime'] = responseData.operationDateTime
    }
    updateEmsForm(tab, dataToUpdate)
  }

  return (
    <Container>
      <Card>
        <Row justify="space-between" className="my-2">
          <Col xs={12} md={8} className="d-flex align-items-center">
            <Input.Group compact className="d-flex align-items-center">
              <label htmlFor="doc-no" style={{ whiteSpace: 'nowrap', alignSelf: 'center', margin: '0' }}>
                เลขที่:
              </label>
              <Input id="doc-no" value={emsUniqueId} onChange={(e) => setEmsUniqueId(e.target.value)} className="ml-2" style={{ maxWidth: '250px' }} />
            </Input.Group>
          </Col>
          <Col xs={12} md={8} className="d-flex justify-content-end align-items-center">
            <Button
              onClick={() => {
                handleDataUpdateForm()
              }}
              type="primary"
              className="mr-2"
            >
              {TABS_NAME[tab]}
            </Button>
            <Button onClick={() => openPDFHandler(formDetail)} type="danger">
              PDF
            </Button>
          </Col>
        </Row>
        <Tabs onChange={(tab) => setTabs(tab)} defaultActiveKey="responseData" type="card" size="large">
          <TabPane tab="ข้อมูลการออกปฏิบัติการ" key="responseData">
            <Section1
              ambulanceList={ambulanceList}
              emsTypologiesData={emsTypologiesData}
              formDetail={formDetail || {}}
              updateEmsForm={handleDataUpdateForm}
              formValues={responseData}
              setFormValues={(values) => {
                setResponseData((prev) => ({ ...prev, ...values }))
              }}
            />
          </TabPane>
          <TabPane tab="ข้อมูลผู้ป่วย" key="demographicData">
            <Section2
              emsTypologiesData={emsTypologiesData}
              formDetail={formDetail || {}}
              updateEmsForm={handleDataUpdateForm}
              fetchUserInfos={fetchUserInfos}
              formValues={demographicData}
              setFormValues={(values) => {
                setDemographicData((prev) => ({ ...prev, ...values }))
              }}
            />
          </TabPane>
          <TabPane tab="การประเมินและสัญญาณชีพ" key="assessmentData">
            <Section3
              updateEmsForm={handleDataUpdateForm}
              formDetail={formDetail}
              formValues={assessmentData}
              assessmentPickedRow={assessmentPickedRow}
              setAssessmentPickedRow={setAssessmentPickedRow}
              setFormValues={(values) => {
                setAssessmentData((prev) => ({ ...prev, ...values }))
              }}
            />
          </TabPane>
          <TabPane tab="ข้อมูลการซักประวัติและการตรวจร่างกาย" key="historyData">
            <Section4
              updateEmsForm={handleDataUpdateForm}
              formDetail={formDetail || {}}
              formValues={historyData}
              setFormValues={(values) => {
                setHistoryData((prev) => ({ ...prev, ...values }))
              }}
            />
          </TabPane>
          <TabPane tab="EMS Impression and Treatment" key="impressionData">
            <Section5
              updateEmsForm={handleDataUpdateForm}
              formDetail={formDetail || {}}
              formValues={impressionData}
              setFormValues={(values) => {
                setImpressionData((prev) => ({ ...prev, ...values }))
              }}
            />
          </TabPane>
          <TabPane tab="Transfer to Definite Care" key="transferData">
            <Section6
              emsTypologiesData={emsTypologiesData}
              updateEmsForm={handleDataUpdateForm}
              formDetail={formDetail}
              formValues={transferData}
              setFormValues={(values) => {
                setTransferData((prev) => ({ ...prev, ...values }))
              }}
            />
          </TabPane>
        </Tabs>
      </Card>
    </Container>
  )
}

export default index

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import firebase from 'firebase/app'

@inject('User')
@observer
class Logout extends React.Component {
  componentDidMount() {
    const { User } = this.props
    // this.firebaseLogout()
    User.logout()
  }

  // firebaseLogout() {
  //   firebase.auth().signOut()
  // }

  render() {
    const { User } = this.props
    if (!User.getSession) return <Redirect to="/login" />
    return null
  }
}

Logout.propTypes = {
  User: PropTypes.object, //eslint-disable-line
}

Logout.defaultProps = {
  // User: null
}
export default Logout

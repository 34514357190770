import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Select, Cascader } from 'antd'
import { initiaTraumaticInjuryValues, traumaInjuryFormTemplate } from './constant'
import ToggleToken from '../../ToggleToken'
import styled from 'styled-components'
import UploadImage from './UploadImage'
const Text = styled('p')`
  font-size: 18px;
  padding-right: 10px;
  font-weight: bold;
`
const FormBox = styled(Row)`
  padding: 10px;
`

const TraumaticInjury = ({ setFormValue, initialValues, formId }) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({ traumaLevel1: [] })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (initialValues && initialValues.traumaticInjury) {
      setFormData((prev) => ({ ...prev, ...initialValues.traumaticInjury }))
      setFormValue({ ['traumaticInjury']: { ...initialValues.traumaticInjury } })
    }
    setLoading(false)
  }, [initialValues])

  const handleValue = (field, value) => {
    form.setFieldsValue({ [field]: value })
    const allData = form.getFieldsValue(true)
    setFormValue({ ['traumaticInjury']: allData })
  }

  return loading ? (
    <></>
  ) : (
    <Form form={form} initialValues={initiaTraumaticInjuryValues} name="cardiacArrest" layout="vertical" labelCol={{ span: 10 }} wrapperCol={{ span: 24 }}>
      <div>
        <h2>Traumatic Injury</h2>
        <Row>
          {traumaInjuryFormTemplate.map((template, index) => (
            <Col span={template.cols} key={index}>
              {template.header && <p className="h5">{template.header}</p>}
              {template.data.map((data) => (
                <Form.Item name={data.value}>
                  {data.type === 'upload' ? (
                    <UploadImage
                      label={data.label}
                      preview={formData[data.value]}
                      path={`EmsImages/${formId}/TraumaticInjury`}
                      handleValue={(value) => {
                        handleValue(data.value, value)
                        setFormData((prevState) => ({ ...prevState, [data.value]: value }))
                      }}
                    />
                  ) : data.type === 'checkbox' ? (
                    <FormBox justify="start">
                      <Col span={24}>
                        <ToggleToken
                          title={data.label}
                          options={data.items}
                          values={formData[data.value]}
                          setValues={(value) => {
                            setFormData((prevState) => ({ ...prevState, [data.value]: value }))
                            handleValue(data.value, value)
                          }}
                        />
                      </Col>
                    </FormBox>
                  ) : (
                    data.type === 'text' && (
                      <FormBox justify="space-around">
                        {data.header && (
                          <Col span={24} className="d-flex justify-content-center pb-3">
                            <Text>{data.header}</Text>
                          </Col>
                        )}
                        <Col span={12}>
                          <Text>{data.label}</Text>
                        </Col>
                        <Col span={12}>
                          <Input
                            value={formData[data.value]}
                            onChange={(event) => {
                              setFormData((prevState) => ({ ...prevState, [data.value]: event.target.value }))
                              handleValue(data.value, event.target.value)
                            }}
                          />
                        </Col>
                      </FormBox>
                    )
                  )}
                </Form.Item>
              ))}
            </Col>
          ))}
        </Row>
      </div>
    </Form>
  )
}

export default TraumaticInjury

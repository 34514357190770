import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, Spin } from 'antd'
import moment from 'moment'
import { LabHeaders } from '../constant'
import { Column, Container, Loading, Table } from './styles'
import { filter } from 'lodash-es'

function LabTable({ labs, isLoading }) {
  const [countLab, setCountLab] = useState(0)
  const [formatLabs, setFormatLabs] = useState(false)
  let count = 0
  const checkKey = (key) => {
    const filterLab = labs.filter((lab) => lab.labItemsCode === key)
    const sortLab = filterLab.sort((a, b) => new Date(b.orderDateTime) - new Date(a.orderDateTime))
    if (sortLab.length > count) {
      count = sortLab.length
    }
    return sortLab
  }

  const checkNormalValueRef = (key) => {
    const filterLab = labs.filter((lab) => lab.labItemsCode === key)
    let NormalValueRef = ''
    if (filterLab[0]) {
      NormalValueRef = '(' + filterLab[0].labItemsNormalValueRef + ')'
    }
    return NormalValueRef
  }

  const checkLabData = () => {
    const filterData = { headers: LabHeaders, values: {} }
    LabHeaders.map((header, index) => {
      filterData.values = {
        ...filterData.values,
        [header.key]: checkKey(header.key),
      }
    })
    setCountLab(count)
    setFormatLabs(filterData)
  }

  const renderData = (index, key) => {
    const headers = []
    for (let i = 0; i < countLab; i++) {
      const text = formatLabs.values[key][i] && formatLabs.values[key][i].labOrderResult ? formatLabs.values[key][i].labOrderResult : false
      const dateTime = text ? moment(formatLabs.values[key][i].orderDateTime).format('YYYY-MM-DD') : '-'
      headers.push(
        <Column header={!index}>
          <div>{index ? text : i + 1}</div>
          {text && <div>({dateTime})</div>}
        </Column>,
      )
    }
    return headers
  }

  useEffect(() => {
    if (labs.length) {
      checkLabData()
    }
  }, [labs])

  return (
    <Container>
      <Spin spinning={isLoading} style={{ minHeight: '40vh' }}>
        {labs.length && formatLabs ? (
          <Table className="w-100" style={{ maxWidth: '1000px' }}>
            {formatLabs.headers.map((header, index) => (
              <Row key={header.title + index} style={{ width: 'max-content' }}>
                <Column header={!index} center fixed>
                  <div>
                    <p>{header.title}</p>
                    {checkNormalValueRef(header.key)}
                  </div>
                </Column>
                {renderData(index, header.key)}
              </Row>
            ))}
          </Table>
        ) : (
          'ไม่มีข้อมูล'
        )}
      </Spin>
    </Container>
  )
}
export default LabTable

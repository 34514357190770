import React, { useState, useEffect } from 'react'
import { Row, Col, Checkbox, Input } from 'antd'
import { itemChoices, fastedChoices } from './constant'
import ToggleToken from '../../ToggleToken'
import styled from 'styled-components'
import './style.css'
const Text = styled('p')`
  font-size: 18px;
  padding-right: 10px;
  font-weight: bold;
  text-align: center;
`

const Stroke = ({ setFormValue, initialValues }) => {
  const [formData, setFormData] = useState({
    activateStrokeFastTrack: '',
    fmc: '',
    time: '',
    facialPalsyNormal: '',
    facialPalsyNormalParalysis: '',
    armNoDrift: '',
    armEffortAgainstGravityType: '',
    armAgainstGravity: '',
    armNoEffortAgainstGravityType: '',
    armNoEffortAgainstGravity: '',
    eyeDeviationNormal: '',
    eyeDeviationDeviationType: '',
    eyeDeviationDeviation: '',
    denialNeglectAbsent: '',
    denialNeglectNotRecognize: '',
    facialPalsyDisable: false,
    armWeaknessDisable: false,
    eyeDeviationDisable: false,
    neglectDisable: false,
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (initialValues && initialValues.stroke) {
      setFormData((prev) => ({ ...prev, ...initialValues.stroke }))
      setFormValue({ ['stroke']: { ...initialValues.stroke } })
    }
    setLoading(false)
  }, [initialValues])

  const handleValue = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }))
    setFormValue({ ['stroke']: allData })
  }

  const handleTotalValue = () => {
    let point =
      handlePoint(formData['facialPalsyNormal']) +
      handlePoint(formData['facialPalsyNormalParalysis']) +
      handlePoint(formData['armNoDrift']) +
      handlePoint(formData['armAgainstGravity']) +
      handlePoint(formData['armNoEffortAgainstGravity']) +
      handlePoint(formData['eyeDeviationNormal']) +
      handlePoint(formData['eyeDeviationDeviation']) +
      handlePoint(formData['denialNeglectAbsent']) +
      handlePoint(formData['denialNeglectNotRecognize'])
    return point
  }

  const handlePoint = (value) => {
    let finalValue
    if (value) {
      return (finalValue = parseInt(value))
    }
    return 0
  }

  return loading ? (
    <></>
  ) : (
    <div className="stroke-table">
      <h2>Stroke</h2>
      <Row className="py-2">
        <Col span={24} className="d-flex align-items-center justify-content-center">
          <p>EMS activat Stroke Fast Track</p> <Input value={formData['activateStrokeFastTrack']} onChange={(e) => handleValue('activateStrokeFastTrack', e.target.value)} className="mx-2" style={{ maxWidth: '300px' }} /> <p> mins</p>
        </Col>
        <Col span={24} className="d-flex align-items-center justify-content-center my-3">
          <p>FMC</p> <Input value={formData['fmc']} onChange={(e) => handleValue('fmc', e.target.value)} className="mx-2" style={{ maxWidth: '300px' }} /> <p> mins</p>
          <p className="ml-4 mr-2">Time</p>
          <ToggleToken
            toStr={true}
            max={1}
            options={[{ label: '≤ 4.5hrs.' }, { label: '≥ 4.5 hrs' }]}
            values={formData['time']}
            setValues={(value) => {
              handleValue('time', value)
            }}
          />
        </Col>
      </Row>
      <table className="w-100">
        <thead>
          <tr style={{ background: '#429791' }}>
            <th scope="col">ITEM</th>
            <th scope="col">FAST-ED</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ background: '#45C1B8' }}>
            <th scope="row">Facial Palsy</th>
            <td className="text-right">
              <Checkbox
                checked={formData['facialPalsyDisable']}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFormValue({ ['stroke']: { ['facialPalsyNormal']: '', ['facialPalsyNormalParalysis']: '' } })
                  } else {
                    setFormValue({
                      ['stroke']: {
                        ['facialPalsyNormal']: formData['facialPalsyNormal'] ? formData['facialPalsyNormal'] : '',
                        ['facialPalsyNormalParalysis']: formData['facialPalsyNormalParalysis'] ? formData['facialPalsyNormalParalysis'] : '',
                      },
                    })
                  }
                  handleValue('facialPalsyDisable', e.target.checked)
                }}
              >
                N/A
              </Checkbox>
            </td>
          </tr>
          <tr>
            <th scope="row">Normal</th>
            <td style={{ background: formData['facialPalsyDisable'] && '#ccc' }}>
              {!formData['facialPalsyDisable'] && (
                <ToggleToken
                  toStr={true}
                  max={1}
                  options={fastedChoices}
                  values={formData['facialPalsyNormal']}
                  setValues={(value) => {
                    handleValue('facialPalsyNormal', value)
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">Paralysis</th>
            <td style={{ background: formData['facialPalsyDisable'] && '#ccc' }}>
              {!formData['facialPalsyDisable'] && (
                <ToggleToken
                  toStr={true}
                  max={1}
                  options={fastedChoices}
                  values={formData['facialPalsyNormalParalysis']}
                  setValues={(value) => {
                    handleValue('facialPalsyNormalParalysis', value)
                  }}
                />
              )}
            </td>
          </tr>
          <tr style={{ background: '#45C1B8' }}>
            <th scope="row">Arm weakness</th>
            <td className="text-right">
              <Checkbox
                checked={formData['armWeaknessDisable']}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFormValue({
                      ['stroke']: {
                        ['armNoDrift']: '',
                        ['armAgainstGravity']: '',
                        ['armNoEffortAgainstGravity']: '',
                      },
                    })
                  } else {
                    setFormValue({
                      ['stroke']: {
                        ['armNoDrift']: formData['armNoDrift'],
                        ['armAgainstGravity']: formData['armAgainstGravity'],
                        ['armNoEffortAgainstGravity']: formData['armNoEffortAgainstGravity'],
                      },
                    })
                  }
                  handleValue('armWeaknessDisable', e.target.checked)
                }}
              >
                N/A
              </Checkbox>
            </td>
          </tr>
          <tr>
            <th scope="row">No Drift</th>
            <td style={{ background: formData['armWeaknessDisable'] && '#ccc' }}>
              {!formData['armWeaknessDisable'] && (
                <ToggleToken
                  toStr={true}
                  max={1}
                  options={fastedChoices}
                  values={formData['armNoDrift']}
                  setValues={(value) => {
                    handleValue('armNoDrift', value)
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <p>Drift or some effort against gravity</p>
              <ToggleToken
                toStr={true}
                max={1}
                options={itemChoices}
                values={formData['armEffortAgainstGravityType']}
                setValues={(value) => {
                  handleValue('armEffortAgainstGravityType', value)
                }}
              />
            </th>
            <td style={{ background: formData['armWeaknessDisable'] && '#ccc' }}>
              {!formData['armWeaknessDisable'] && (
                <ToggleToken
                  toStr={true}
                  max={1}
                  options={fastedChoices}
                  values={formData['armAgainstGravity']}
                  setValues={(value) => {
                    handleValue('armAgainstGravity', value)
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <p>No effort against gravity</p>
              <ToggleToken
                toStr={true}
                max={1}
                options={itemChoices}
                values={formData['armNoEffortAgainstGravityType']}
                setValues={(value) => {
                  handleValue('armNoEffortAgainstGravityType', value)
                }}
              />
            </th>
            <td style={{ background: formData['armWeaknessDisable'] && '#ccc' }}>
              {!formData['armWeaknessDisable'] && (
                <ToggleToken
                  toStr={true}
                  max={1}
                  options={fastedChoices}
                  values={formData['armNoEffortAgainstGravity']}
                  setValues={(value) => {
                    handleValue('armNoEffortAgainstGravity', value)
                  }}
                />
              )}
            </td>
          </tr>
          <tr style={{ background: '#45C1B8' }}>
            <th scope="row">Eye deviation</th>
            <td className="text-right">
              <Checkbox
                checked={formData['eyeDeviationDisable']}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFormValue({
                      ['stroke']: {
                        ['eyeDeviationNormal']: '',
                        ['eyeDeviationDeviation']: '',
                      },
                    })
                  } else {
                    setFormValue({
                      ['stroke']: {
                        ['eyeDeviationNormal']: formData['eyeDeviationNormal'],
                        ['eyeDeviationDeviation']: formData['eyeDeviationDeviation'],
                      },
                    })
                  }
                  handleValue('eyeDeviationDisable', e.target.checked)
                }}
              >
                N/A
              </Checkbox>
            </td>
          </tr>
          <tr>
            <th scope="row">Normal</th>
            <td style={{ background: formData['eyeDeviationDisable'] && '#ccc' }}>
              {!formData['eyeDeviationDisable'] && (
                <ToggleToken
                  toStr={true}
                  max={1}
                  options={fastedChoices}
                  values={formData['eyeDeviationNormal']}
                  setValues={(value) => {
                    handleValue('eyeDeviationNormal', value)
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <p>Deviation</p>
              <ToggleToken
                toStr={true}
                max={1}
                options={itemChoices}
                values={formData['eyeDeviationDeviationType']}
                setValues={(value) => {
                  handleValue('eyeDeviationDeviationType', value)
                }}
              />
            </th>
            <td style={{ background: formData['eyeDeviationDisable'] && '#ccc' }}>
              {!formData['eyeDeviationDisable'] && (
                <ToggleToken
                  toStr={true}
                  max={1}
                  options={fastedChoices}
                  values={formData['eyeDeviationDeviation']}
                  setValues={(value) => {
                    handleValue('eyeDeviationDeviation', value)
                  }}
                />
              )}
            </td>
          </tr>
          <tr style={{ background: '#45C1B8' }}>
            <th scope="row">Denial/Neglect</th>
            <td className="text-right">
              <Checkbox
                checked={formData['neglectDisable']}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFormValue({
                      ['stroke']: {
                        ['denialNeglectAbsent']: '',
                        ['denialNeglectNotRecognize']: '',
                      },
                    })
                  } else {
                    setFormValue({
                      ['stroke']: {
                        ['denialNeglectAbsent']: formData['denialNeglectAbsent'],
                        ['denialNeglectNotRecognize']: formData['denialNeglectNotRecognize'],
                      },
                    })
                  }
                  handleValue('neglectDisable', e.target.checked)
                }}
              >
                N/A
              </Checkbox>
            </td>
          </tr>
          <tr>
            <th scope="row">Absent</th>
            <td style={{ background: formData['neglectDisable'] && '#ccc' }}>
              {!formData['neglectDisable'] && (
                <ToggleToken
                  toStr={true}
                  max={1}
                  options={fastedChoices}
                  values={formData['denialNeglectAbsent']}
                  setValues={(value) => {
                    handleValue('denialNeglectAbsent', value)
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">Does not recognize own hand/orients only one side of the body</th>
            <td style={{ background: formData['neglectDisable'] && '#ccc' }}>
              {!formData['neglectDisable'] && (
                <ToggleToken
                  toStr={true}
                  max={1}
                  options={fastedChoices}
                  values={formData['denialNeglectNotRecognize']}
                  setValues={(value) => {
                    handleValue('denialNeglectNotRecognize', value)
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Text>Total {handleTotalValue()}</Text>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Stroke

import React, { useEffect, useState } from 'react'
import Layout from '../Home'
import { hooks } from './hooks'
import { useSelector, useDispatch } from 'react-redux'
import { fetchNote, updateNote } from '../../store/healthCheckUpSlice'
import { isEmpty } from 'lodash'
import { Spin, Row, Col, Button, Radio, Image, Popconfirm, Checkbox, message, Table, Typography, Space } from 'antd'
import TextEditor from '../../components/TextEditor'
import { Container, EditorBox, Text, ColFlexCenter, InfoBox, FloatTitleBox, FloatTitleText } from './style'
import Avatar from '../../images/default_avatar.png'
import moment from 'moment'
import 'moment/locale/th'
import CheckUpLogsModal from '../../components/Modal/CheckUpLogsModal'
import { getLatestSummary } from '../../apiServices/healthCheckupServices'
import { triggerUpdateDataByCid } from '../../apiServices/userServices'
const { Title } = Typography;

const note = (props) => {
  const [summaryNote, setSummaryNote] = useState('<br><br><br><br><br>')
  const [labNote, setLabNote] = useState('<br><br><br><br><br>')
  const [xrayNote, setXrayNote] = useState('<br><br><br><br><br>')
  const [labStatus, setLabStatus] = useState(null)
  const [adviceSelected, setAdviceSelected] = useState([])
  const [modifiedLogs, setModifiedLogs] = useState([])
  const [visibleModal, setVisibleModal] = useState(false)
  const [patientInfo, setPatientInfo] = useState(false)
  const [summaryLab, setSummaryLab] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch()
  const { events, params } = hooks()
  const healthCheckUpNotesId = props.match?.params?.healthCheckUpNotesId
  const [healthCheckUpNote, userInfo, errorMessage, isLoadingData] = useSelector((state) => {
    const { note, isLoadingData, errorMessage } = state.healthCheckUp
    const { userInfo } = state.user
    return [note, userInfo, errorMessage, isLoadingData]
  })

  useEffect(() => {
    const isNumberOnly = !isNaN(healthCheckUpNotesId)
    if (isNumberOnly) {
      dispatch(fetchNote(parseInt(healthCheckUpNotesId)))
    } else {
      props.history.push('/health-checkup')
    }
  }, [healthCheckUpNotesId])

  useEffect(() => {
    if (errorMessage) {
      props.history.push('/health-checkup')
    }
  }, [errorMessage])

  useEffect(() => {
    if (!isEmpty(healthCheckUpNote)) {
      const hieInfo = events.fecthUserInfoByCId(healthCheckUpNote?.cId)
      events.fetchDoctorAdvices()
      setSummaryNote(healthCheckUpNote?.summaryNote ?? '<br><br><br><br><br>')
      setLabNote(healthCheckUpNote?.labNote ?? '<br><br><br><br><br>')
      setXrayNote(healthCheckUpNote?.xrayNote ?? '<br><br><br><br><br>')
      setLabStatus(healthCheckUpNote?.labStatus ?? null)
      setAdviceSelected(healthCheckUpNote?.doctorAdvices ?? [])
      setModifiedLogs(healthCheckUpNote?.modifiedLogs ?? [])
      Promise.resolve(hieInfo).then((info) => setPatientInfo(info))
    }
  }, [healthCheckUpNote])

  useEffect(() => {
    if (patientInfo?.cid) {
      checkLatestCheckUpSummary(patientInfo.cid)
    }
  }, [patientInfo])

  const checkLatestCheckUpSummary = async (cid) => {
    setIsLoading(true)
    const startDate = moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')
    await triggerUpdateDataByCid(cid, startDate, endDate)
    const response = await getLatestSummary(cid)
    if (response.data) {
      setSummaryLab(response.data.summary)
      setIsLoading(false)
    }
  }

  // const processLabSummary = (data, gender) => {
  //   const summary = data.summary;

  //   const processLabItem = (labItem, gender) => {
  //     const resultValue = parseFloat(labItem.result);
  //     const labName = labItem.label.main.toLowerCase();
  //     let interpretation = labItem.info;

  //     const ranges = {
  //       fbs: {
  //         low: { range: [0, 70], message: "ระดับน้ำตาลอยู่ในระดับต่ำกว่าปกติสามารถเกิดขึ้นได้จากการงดรับประทานอาหารก่อนมาตรวจสุขภาพ" },
  //         normal: { range: [70, 99], message: "ผลการตรวจระดับน้ำตาลปกติ" },
  //         risk: { range: [100, 125], message: "ผลตรวจน้ำตาลในเลือดอยุ่ในกลุ่มเสี่ยงใกล้จะเป็นโรคเบาหวาน..." },
  //         disease: { range: [126, Infinity], message: "ผลตรวจน้ำตาลในเลือดอยุ่ในกลุ่มโรคเบาหวาน..." }
  //       },
  //       hba1c: {
  //         normal: { range: [4.0, 6.0], message: "ผลตรวจน้ำตาลสะสมในเลือดปกติไม่พบความเสี่ยงของโรคเบาหวาน" },
  //         risk: { range: [6.1, 6.5], message: "ผลตรวจน้ำตาลสะสมในเลือดอยุ่ในกลุ่มเสี่ยงใกล้จะเป็นโรคเบาหวาน..." },
  //         disease: { range: [6.6, Infinity], message: "ผลตรวจน้ำตาลสะสมในเลือดอยุ่ในกลุ่มโรคเบาหวาน..." }
  //       },
  //       cholesterol: {
  //         normal: { range: [150, 200], message: "ผลการตรวจระดับคลอเรสเตอรอลในเลือดปกติ" },
  //         risk: { range: [201, 230], message: "ระดับคลอเรสเตอรอลในเลือดสูงกว่าปกติเล็กน้อย..." },
  //         disease: { range: [231, Infinity], message: "ระดับคลอเรสเตอรอลในเลือดสูงกว่าปกติ..." }
  //       },
  //       hdl: {
  //         low: { range: [0, 40], message: "ระดับไขมันดีอยุ่ในระดับต่ำกว่าปกติ..." },
  //         normal: { range: [41, Infinity], message: "ระดับไขมันดีอยู่ในเกณฑ์ปกติ" }
  //       },
  //       ldl: {
  //         normal: { range: [0, 130], message: "ระดับไขมันเลวอยู่ในเกณฑ์ปกติ" },
  //         risk: { range: [131, 150], message: "ระดับไขมันเลวอยู่ในเกณฑ์สูงกว่าปกติเล็กน้อย..." },
  //         disease: { range: [151, Infinity], message: "ระดับไขมันเลวอยู่ในเกณฑ์ของโรคไขมันในเลือดสูง..." }
  //       },
  //       triglyceride: {
  //         normal: { range: [0, 150], message: "ระดับไตรกลีเซอร์ไรด์อยู่ในเกณฑ์ปกติ" },
  //         risk: { range: [151, 500], message: "ระดับไตรกรีเซอไรด์อยู่ในเกณฑ์สูงกว่าปกติ..." },
  //         disease: { range: [501, Infinity], message: "ระดับไตรกรีเซอไรด์อยู่ในเกณฑ์เป็นโรคไขมันในเลือดสูง..." }
  //       },
  //       uricAcid: {
  //         normal: gender === 'male' ? { range: [0, 7.0], message: "ผลการตรวจกรดยูริกในเลือดปกติ" } : { range: [0, 5.7], message: "ผลการตรวจกรดยูริกในเลือดปกติ" },
  //         risk: gender === 'male' ? { range: [7.0, 9.0], message: "ผลการตรวจกรดยูริกในสูงกว่าระดับปกติ..." } : { range: [5.7, 9.0], message: "ผลการตรวจกรดยูริกในสูงกว่าระดับปกติ..." },
  //         disease: { range: [9.1, Infinity], message: "ผลการตรวจกรดยูริกในสูงกว่าระดับปกติ..." }
  //       },
  //       ast: {
  //         normal: { range: [0, 32], message: "ผลการตรวจค่าการทำงานของตับปกติ" },
  //         risk: { range: [33, 80], message: "ผลการตรวจค่าการทำงานของตับสูงเล็กน้อย..." },
  //         disease: { range: [81, Infinity], message: "ผลการตรวจค่าการทำงานของตับสุง..." }
  //       },
  //       // Add other ranges for ALT, ALP, BUN, Creatinine, etc. similarly
  //     };

  //     const getInterpretationByRange = (labKey, value) => {
  //       const labRanges = ranges[labKey];
  //       if (!labRanges) return interpretation;

  //       for (const [key, { range, message }] of Object.entries(labRanges)) {
  //         if (value >= range[0] && value < range[1]) {
  //           return message;
  //         }
  //       }
  //       return interpretation;
  //     };

  //     switch (labName) {
  //       case 'fbs':
  //         interpretation = getInterpretationByRange('fbs', resultValue);
  //         break;
  //       case 'hba1c':
  //         interpretation = getInterpretationByRange('hba1c', resultValue);
  //         break;
  //       case 'cholesterol':
  //         interpretation = getInterpretationByRange('cholesterol', resultValue);
  //         break;
  //       case 'hdl':
  //         interpretation = getInterpretationByRange('hdl', resultValue);
  //         break;
  //       case 'ldl':
  //         interpretation = getInterpretationByRange('ldl', resultValue);
  //         break;
  //       case 'triglyceride':
  //         interpretation = getInterpretationByRange('triglyceride', resultValue);
  //         break;
  //       case 'uric acid':
  //         interpretation = getInterpretationByRange('uricAcid', resultValue);
  //         break;
  //       case 'ast':
  //         interpretation = getInterpretationByRange('ast', resultValue);
  //         break;
  //       // Additional cases for all other labs based on the table
  //       default:
  //         interpretation = labItem.info;
  //     }

  //     return {
  //       labItemName: labItem.label.main,
  //       result: labItem.result,
  //       normalReference: labItem.normalReference,
  //       isNormal: labItem.isNormal,
  //       interpretation: interpretation || labItem.info
  //     };
  //   };

  //   const resultSummary = {};

  //   // Iterate through each examination category (completeBloodCount, urineAnalysis, etc.)
  //   for (const [key, examination] of Object.entries(summary)) {
  //     const labResults = [];

  //     // Check if the examination category has specific lab items to process
  //     if (examination && typeof examination === "object") {
  //       for (const [labKey, labItem] of Object.entries(examination)) {
  //         // Ensure labItem has a result to be processed
  //         if (labItem && typeof labItem === "object" && labItem.result) {
  //           labResults.push(processLabItem(labItem, gender));
  //         }
  //       }
  //     }

  //     // Only add categories that contain processed results
  //     if (labResults.length > 0) {
  //       resultSummary[key] = {
  //         label: examination.label,
  //         results: labResults
  //       };
  //     }
  //   }
  //   console.log('resultSummary', resultSummary);

  //   return resultSummary;
  // };

  const getColumns = () => [
    {
      title: 'Test',
      dataIndex: 'test',
      key: 'test',
    },
    {
      title: 'Result',
      dataIndex: 'result',
      key: 'result',
    },
    {
      title: 'Normal Range',
      dataIndex: 'normalReference',
      key: 'normalReference',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Info',
      dataIndex: 'info',
      key: 'info',
    },
  ];
  const LabCategory = ({ categoryKey, categoryData }) => {
    const columns = getColumns();

    // Map lab items to table data
    const dataSource = Object.keys(categoryData)
      .filter((key) => key !== 'label' && key !== 'abnormalCount')
      .map((key, index) => ({
        key: index,
        test: `${categoryData[key].label.main} (${categoryData[key].label.second})`,
        result: categoryData[key].result,
        normalReference: categoryData[key].normalReference || '-',
        status: categoryData[key].isNormal === "normal" ? "Normal" : "Abnormal",
        info: categoryData[key].info,
      }));

    return categoryData?.label && (
      <div style={{ marginBottom: '30px' }}>
        <Title level={4}>
          {categoryData.label.en} ({categoryData.label.th})
        </Title>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
          rowKey="key"
        />
      </div>
    );
  };

  // Main component for rendering all lab categories
  const LabSummary = ({ data }) => (
    <Space direction="vertical" style={{ width: '100%' }}>
      {Object.keys(data).map((categoryKey) => (
        <LabCategory
          key={categoryKey}
          categoryKey={categoryKey}
          categoryData={data[categoryKey]}
        />
      ))}
    </Space>
  );


  const saveHealthCheckUp = () => {
    const newModifiedLogs = [...modifiedLogs, { doctorName: userInfo?.fullname, doctorId: userInfo?.id, date: new Date(), data: { summaryNote, labNote, xrayNote, doctorAdvices: adviceSelected } }]
    const params = {
      id: healthCheckUpNote?.id,
      data: { summaryNote, labNote, xrayNote, labStatus, doctorAdvices: adviceSelected, doctorName: userInfo?.fullname, doctorId: userInfo?.id, modifiedLogs: newModifiedLogs },
      callback: (updateStatus) => {
        if (updateStatus) {
          message.success('อัปเดตข้อมูลสำเร็จ')
        } else {
          message.error('อัปเดตข้อมูลล้มเหลว')
        }
      },
    }
    dispatch(updateNote(params))
  }

  const handleAdviceSelected = (isSelected, value) => {
    if (isSelected) {
      setAdviceSelected((prev) => [...prev, parseInt(value.id)])
    } else {
      const selectedAdvices = adviceSelected.filter((item) => item !== parseInt(value.id))
      setAdviceSelected(selectedAdvices)
    }
  }

  const handleDefaulthChecked = (item) => {
    const checked = adviceSelected.map((x) => {
      if (parseInt(item.id) === x) {
        return true
      } else {
        return false
      }
    })
    const status = checked.some((item) => item === true)
    return status
  }

  return (
    <Layout
      history={props.history}
      render={() =>
        params.isHieLoading || isLoadingData ? (
          <Container className="d-flex justify-content-center align-items-center">
            <Spin size="large" />
          </Container>
        ) : (
          <Row className="w-100 p-3 m-0" gutter={20}>
            <Col xs={24} md={8}>
              <EditorBox>
                <FloatTitleBox>
                  <FloatTitleText borderColor="#757575" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                    {'วันที่ตรวจรักษา'}
                  </FloatTitleText>
                </FloatTitleBox>
                <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${healthCheckUpNote?.orderDateTime
                  ? moment(healthCheckUpNote?.orderDateTime)
                    .add(543, 'years')
                    .format('LL')
                  : '-'
                  }`}</Text>
              </EditorBox>
              <EditorBox justify="center" gutter={[16, 16]}>
                <ColFlexCenter span={24}>
                  <Image width={150} src={patientInfo?.imageBase64 ? `data:image/png;base64,${patientInfo?.imageBase64}` : Avatar} style={{ borderRadius: '50%' }} />
                </ColFlexCenter>
                <ColFlexCenter span={24}>
                  <Text style={{ fontSize: '20px' }}>{`${patientInfo?.fullname?.title}${patientInfo?.fullname?.firstName} ${patientInfo?.fullname?.lastName}`}</Text>
                </ColFlexCenter>
                {patientInfo?.hn?.length > 0 && (
                  <Col span={24} className="p-0">
                    <EditorBox borderColor="#ededed">
                      <FloatTitleBox>
                        <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                          {'HN'}
                        </FloatTitleText>
                      </FloatTitleBox>
                      <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${events.convertHn(patientInfo?.hn[0]?.hn)}`}</Text>
                    </EditorBox>
                    <EditorBox borderColor="#ededed">
                      <FloatTitleBox>
                        <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                          {'บัตรประชาชน'}
                        </FloatTitleText>
                      </FloatTitleBox>
                      <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.cid}`}</Text>
                    </EditorBox>
                    <EditorBox borderColor="#ededed">
                      <FloatTitleBox>
                        <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                          {'เพศ'}
                        </FloatTitleText>
                      </FloatTitleBox>
                      <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.sexName}`}</Text>
                    </EditorBox>
                    <EditorBox borderColor="#ededed">
                      <FloatTitleBox>
                        <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                          {'วันเกิด'}
                        </FloatTitleText>
                      </FloatTitleBox>
                      <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.DOB
                        ? moment(patientInfo?.DOB)
                          .add(543, 'years')
                          .format('LL')
                        : '-'
                        }`}</Text>
                    </EditorBox>
                    <EditorBox borderColor="#ededed">
                      <FloatTitleBox>
                        <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                          {'เบอร์โทร'}
                        </FloatTitleText>
                      </FloatTitleBox>
                      <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.contactInfo?.mobileNumber.length > 0 ? patientInfo?.contactInfo?.mobileNumber : '-'}`}</Text>
                    </EditorBox>
                    <EditorBox borderColor="#ededed">
                      <FloatTitleBox>
                        <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                          {'ที่อยู่'}
                        </FloatTitleText>
                      </FloatTitleBox>
                      <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.address?.houseNumber} ตำบล ${patientInfo?.address?.tmbpart ?? '-'}, อำเภอ ${patientInfo?.address?.amppart ??
                        '-'}, จังหวัด ${patientInfo?.address?.chwpart ?? '-'} ${patientInfo?.address?.addressPart}`}</Text>
                    </EditorBox>
                  </Col>
                )}
              </EditorBox>
            </Col>
            <Col xs={24} md={16}>
              <Row gutter={[0, 12]} className="w-100">
                <Col flex="auto">
                  <TextEditor title="สรุปการตรวจร่างกายทั่วไป" value={summaryNote} setValue={setSummaryNote} />
                </Col>
                <Col flex="auto">
                  <TextEditor title="สรุปผลแลป" value={labNote} setValue={setLabNote} />
                  <div className="d-flex align-items-center px-3">
                    <p className="mr-2">{`สถานะผลแลป: `}</p>
                    <Radio.Group
                      defaultValue={labStatus}
                      onChange={(e) => {
                        setLabStatus(e.target.value)
                      }}
                    >
                      <Radio value={true}>
                        <p>{`ไม่ผิดปกติ`}</p>
                      </Radio>
                      <Radio value={false}>
                        <p style={{ color: 'red' }}>{`มีบางอย่างผิดปกติ`}</p>
                      </Radio>
                    </Radio.Group>
                  </div>
                </Col>
                <Col span={24}>
                  <Text>ผลตรวจแลปบน HIS</Text>
                  {isLoading ? <Spin size="large" /> : <LabSummary data={summaryLab} />}
                </Col>
                <Col flex="auto">
                  <TextEditor title="สรุปผลตรวจ X-Ray" value={xrayNote} setValue={setXrayNote} />
                </Col>
              </Row>
              <Row justify="start" className="pt-2 pb-3 px-3">
                <Col span={24}>
                  <Text style={{ fontWeight: 'bold' }}>เอกสารที่แนะนำสำหรับผู้ป่วยรายนี้</Text>
                </Col>
                {params.doctorAdvices?.length > 0 &&
                  params.doctorAdvices?.map((item, index) => (
                    <Col xs={24} sm={12} key={'adviceList' + index}>
                      <Checkbox
                        checked={handleDefaulthChecked(item)}
                        onChange={(e) => {
                          handleAdviceSelected(e.target.checked, item)
                        }}
                      >
                        {item.label}
                      </Checkbox>
                    </Col>
                  ))}
              </Row>
              <div className="text-right">
                <Popconfirm title="ยืนยันการบันทึกข้อมูล" onConfirm={() => saveHealthCheckUp()} okText="ยืนยัน" cancelText="ยกเลิก">
                  <Button type="primary" block size="large" className="my-1 w-100">
                    <p style={{ fontSize: '18px' }}>บันทึกข้อมูล</p>
                  </Button>
                </Popconfirm>
                <Button className="my-2" onClick={() => setVisibleModal(true)}>
                  ดูประวัติการแก้ไขข้อมูล
                </Button>
              </div>
            </Col>
            <CheckUpLogsModal visibleModal={visibleModal} setVisibleModal={setVisibleModal} healthCheckUpData={healthCheckUpNote} />
          </Row>
        )
      }
    />
  )
}

export default note

import React from 'react'
import { Modal } from 'antd'
const CancelBookingModal = ({ visibleCancelBookingInfoModal, setVisibleCancelBookingInfoModal, cancelBookingCause }) => {
  return (
    <Modal
      visible={visibleCancelBookingInfoModal}
      onOk={() => {
        setVisibleCancelBookingInfoModal(false)
      }}
      onCancel={() => {
        setVisibleCancelBookingInfoModal(false)
      }}
      title="สาเหตุการยกเลิก"
      footer={null}
    >
      <p>{cancelBookingCause}</p>
    </Modal>
  )
}

export default CancelBookingModal

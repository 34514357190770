import React from 'react'
import { View, Text, Image } from '@react-pdf/renderer'
import styles, { Column, Divider, Row, CheckboxWithLabel } from '../styles'
import { CARE_TITLE, DECISION_STATUS, REASON, LEVEL, CONDITION, COLOR_CONDITION, PDF_VERSION } from '../constant'
import { get, map } from 'lodash'

export default function DecisionTable({ data }) {

  const color = get(data, "onSceneTriage", "")
  const stability = get(data, "stability", "")
  const hospitalType = get(data, "destination.type", "")
  const hospitalName = get(data, "destination.hospital", "")
  const reasons = get(data, "reasons", [])
  const erTriage = get(data, "assessment.erTriageLabel", "")
  const hn = get(data, "assessment.hn", "")
  const diagnosis = get(data, "assessment.diagnosis", "")
  const assessment = {
    breathingCare: map(get(data, "assessment.breathingCare", [])),
    respiratoryCare: get(data, "assessment.respiratoryCare", []),
    bloodCirculationCare: get(data, "assessment.bloodCirculationCare", []),
    boneCast: get(data, "assessment.boneCast", []),
    bleedingCare: get(data, "assessment.bleedingCare", [])
  }
  const reporterName = get(data, "reporter.name", "")
  const reporterPosition = get(data, "reporter.position", "")
  const assessorSignature = get(data, "assessor.signature", "")
  const assessorPosition = get(data, "assessor.position", "")

  return (
    <View style={{ flexGrow: 1, height: "100%" }}>
      <View style={[styles.borderedContainer, { flex: 1 }]}>
        <Row flex={1} wrap>
          <Column spaceBetween borderRight flex={8}>
            <Column>
              <Row wrap paddingVertical={1} paddingHorizontal={5}>
                <Text style={[styles.smallerText, { fontWeight: 'bold', paddingHorizontal: 0, paddingRight: 3 }]}>ผลการดูแลรักษาข้ันต้น</Text>
                {CONDITION.map((e) => {
                  return <CheckboxWithLabel key={e} paddingHorizontal={1} small title={e} checked={stability === e} />
                })}
              </Row>
              <Row wrap paddingVertical={1} paddingHorizontal={5}>
                <Text style={[styles.smallerText, { fontWeight: 'bold', paddingHorizontal: 0, paddingRight: 3 }]}>ระดับการคัดแยกณจดุเกิดเหต</Text>
                {COLOR_CONDITION.map((e) => {
                  return <CheckboxWithLabel key={e} paddingHorizontal={1} small title={e} checked={color === e} />
                })}
              </Row>
              <Text style={[styles.smallerText, { fontWeight: 'bold', paddingHorizontal: 4 }]}>
                เกณฑ์การตัดสินใจส่งโรงพยาบาล(โดยหัวหน้าหน่วยและผ่านการเห็นชอบของศูนย์ฯ)
              </Text>
              <Text style={[styles.smallerText, { marginTop: 2 }]}>นําส่งห้องฉุกเฉินโรงพยาบาล </Text>
              <Text style={[styles.smallerText, { marginLeft: 3 }]}>{hospitalName}{" "}</Text>
              <Divider color="grey" width="96%" marginVertical={0} marginBottom={5} />
              <Row width="60%" spaceBetween paddingHorizontal={5}>
                <CheckboxWithLabel small checked={hospitalType === "โรงพยาบาลรัฐ"} title="รพ.รัฐ" key="โรงพยาบาลรัฐ" />
                <CheckboxWithLabel small checked={hospitalType === "โรงพยาบาลเอกชน"} title="รพ.เอกชน" key="โรงพยาบาลเอกชน" />
              </Row>
              <Row paddingVertical={1} paddingHorizontal={3}>
                <Column paddingRight={3}>
                  <Text style={[styles.smallerText, { fontWeight: 'bold' }]}>
                    เหตุผล
                  </Text>
                </Column>
                <Row wrap>
                  {REASON.map((e) => {
                    return <CheckboxWithLabel small key={e} checked={reasons.includes(e)} title={e} paddingHorizontal={3} />
                  })}
                </Row>
              </Row>
            </Column>
            <Row>
              <Column flex={1}>
                <Text style={styles.smallerText}>ผู้สรุปรายงาน {reporterName}{" "}</Text>
              </Column>
              <Column flex={1}>
                <Text style={styles.smallerText}>ตําแหน่ง {reporterPosition}{" "}</Text>
              </Column>
            </Row>
          </Column>
          <Column backgroundColor="#e7e6e6" flex={10}>
            <Row wrap>
              <Column flex={1} borderBottom>
                <Row spaceBetween width="50%">
                  <Text style={styles.smallerText}>HN {hn}{" "}</Text>
                  <Text style={styles.smallerText}>การวินิจฉัย {diagnosis}{" "}</Text>
                </Row>
                <Row>
                  <Column>
                    <Text style={[styles.smallerText, { marginRight: 2 }]}>ระดับการคัดกรอง(ER Triage)</Text>
                  </Column>
                  <Column flex={1}>
                    <Row wrap>
                      {DECISION_STATUS.map((e) => {
                        return (
                          <CheckboxWithLabel small key={e.label} title={e.label} checked={e.key === erTriage} paddingHorizontal={1} />
                        )
                      })}
                    </Row>
                  </Column>
                </Row>
              </Column>
            </Row>
            <Row flex={1}>
              <Column spaceBetween flexGrow={1}>
                {
                  CARE_TITLE.map((e) => {
                    return (
                      <Row key={e.key} paddingVertical={0}>
                        <Column flex={1.5}>
                          <Text style={styles.smallerText}>{e.label}</Text>
                        </Column>
                        <Column flex={4}>
                          <ChoiceLevel key={e.key} selected={assessment[e.key]} />
                        </Column>
                      </Row>
                    )
                  })
                }
                <Column height={20}>
                  <Row itemsCenter flex={1}>
                    <Column flex={1}>
                      <Row itemsCenter>
                        <Text style={[styles.smallerText, { position: 'relative' }]}>ชื่อผู้ประเมิน</Text>
                        {assessorSignature && <Image src={assessorSignature} style={{
                          marginLeft: 3,
                          width: 'auto',
                          height: 20,
                          objectFit: 'contain'
                        }}
                        />}
                      </Row>
                    </Column>
                    <Column flex={1}>
                      <Text style={styles.smallerText}>ตําแหน่ง {assessorPosition}{" "}</Text>
                    </Column>
                  </Row>
                </Column>
              </Column>
            </Row>
          </Column>
        </Row>
      </View>
      <Text style={[styles.smallerText, { textAlign: 'right', marginTop: 1.5 }]}>{PDF_VERSION}</Text>
    </View>
  )
}

const ChoiceLevel = ({ selected = [""] }) => {
  const data = selected[0] || ""
  const more = data.includes("→")
  const moreContent = data.split("→").length > 0 ? data.split("→")[1] : ""
  return (
    <Row>
      {LEVEL.map((e) => {
        return (
          <CheckboxWithLabel key={e} title={e} checked={data.includes(e)} small paddingHorizontal={2} more={more && data.includes(e) ? moreContent : ""} />
        )
      })}
    </Row>
  )
}

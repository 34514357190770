import React, { useState, useEffect } from 'react'
import { Tabs, Radio, DatePicker, Space, Button, Col, Card, Row, Select } from 'antd'
import Table from '../Table'
import { Container, Search, HeaderWrapper, Text } from './style'
import { HomeFilled } from '@ant-design/icons'
import moment from 'moment'
import 'moment/locale/th'

const { TabPane } = Tabs
const { RangePicker } = DatePicker

const BloodDonorTable = ({ dataSource, convertTime, loading }) => {
  const [tab, setTab] = useState('allDonates')
  const [tableData, setTableData] = useState([])
  const [timeRange, setTimeRange] = useState(1)
  const [dateRange, setDateRange] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [pageSize, setPageSize] = useState(7)
  const theme = localStorage.getItem('theme')
  useEffect(() => {
    const dataToFilter = dataSource.filter((item) => item.screenStatus === 'waitingConfirm' || (item.screenStatus === 'screenPass' && item.donateStatus === 'waitingDonation'))
    if (timeRange === 5) {
      setTableData(dataToFilter)
    }
    if (tab === 'allDonates') {
      setTableData(dataToFilter)
    } else if (tab === 'generalDonates') {
      setTableData(dataToFilter.filter((item) => item.isSpecialDonation === false))
    } else if (tab === 'specialDonates') {
      setTableData(dataToFilter.filter((item) => item.isSpecialDonation === true))
    } else if (tab === 'outDonates') {
      const set = dataToFitler.filter((item) => item.bloodDonationForm?.unitNumber?.includes('N'))
      setTableData(set)
    }
    if (timeRange === 1) {
      setTableData((tableData) => {
        return tableData.filter((item) => moment(item.donationDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY'))
      })
    } else if (timeRange === 2) {
      setTableData((tableData) => {
        return tableData.filter(
          (item) =>
            moment(item.donationDate).format('DD/MM/YYYY') ===
            moment()
              .add(1, 'days')
              .format('DD/MM/YYYY'),
        )
      })
    } else if (timeRange === 3) {
      setTableData((tableData) => {
        return tableData.filter((item) => moment(item.donationDate).format('DD/MM/YYYY') > moment().format('DD/MM/YYYY'))
      })
    } else if (timeRange === 4) {
      setTableData((tableData) => {
        return tableData.filter((item) => moment(item.donationDate).isBetween(moment(dateRange[0]).startOf('day'), moment(dateRange[1]).endOf('day')))
      })
    }
    if (searchText) {
      setTableData((tableData) => {
        return tableData.filter(
          (item) =>
            item.appUser?.userInformation?.firstname?.includes(searchText) ||
            item.appUser?.userInformation?.lastname?.includes(searchText) ||
            `${item.appUser?.userInformation?.firstname} ${item.appUser?.userInformation?.lastname}`?.includes(searchText),
        )
      })
    }
  }, [tab, dataSource, timeRange, dateRange, searchText])
  const columns = [
    {
      title: 'ชื่อผู้บริจาค',
      width: '50',
      key: 'donorName',
      align: 'center',
      render: (record) => {
        return <Text>{`${record.appUser?.userInformation?.firstname ?? ''} ${record.appUser?.userInformation?.lastname ?? ''}`}</Text>
      },
    },
    {
      title: 'วันที่',
      width: '50',
      key: 'donorDate',
      align: 'center',
      sorter: (a, b) => moment(a?.donationDate).unix() - moment(b?.donationDate).unix(),
      render: (record) => (
        <Text onClick={() => console.log(record)}>
          {moment(record?.donationDate)
            .add(543, 'years')
            .format('LL')}
        </Text>
      ),
    },
    {
      title: 'ช่วงเวลา',
      width: '50',
      key: 'donorPeriod',
      align: 'center',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => convertTime(a?.donationTime[0], a?.donationTime[1], true) - convertTime(b?.donationTime[0], b?.donationTime[1], true),
      render: (record) => <Text>{convertTime(record?.donationTime[0], record?.donationTime[1])}</Text>,
    },
    {
      title: 'ประเภทการบริจาค',
      width: '50',
      key: 'donorType',
      align: 'center',
      render: (record) => <Text>{record?.isSpecialDonation ? 'ผู้บริจาคกรณีพิเศษ' : 'ผู้บริจาคกรณีทั่วไป'}</Text>,
    },
    {
      title: 'ดูแบบยินยอม',
      width: '50',
      key: 'displayConsentForm',
      align: 'center',
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            window.open(`/blood-donation-edit-form/${record.id}`)
          }}
        >{`ดูข้อมูลแบบฟอร์ม`}</Button>
      ),
    },
  ]

  return (
    <>
      <Container>
        <HeaderWrapper>
          <HomeFilled className="mr-1" />
          <Text bold>รายการผู้บริจาคเลือด</Text>
          <div style={{ marginLeft: 'auto' }}>
            <Search
              style={{ width: 200 }}
              placeholder="ชื่อผู้บริจาค"
              onSearch={(value) => {
                console.log(value)
                setSearchText(value)
              }}
            />
          </div>
        </HeaderWrapper>
      </Container>
      <Container>
        <Row justify="space-between" align="middle">
          <Col>
            <Radio.Group
              onChange={(e) => {
                setTimeRange(e.target.value)
              }}
              value={timeRange}
            >
              <Radio value={1}>วันนี้</Radio>
              <Radio value={2}>พรุ่งนี้</Radio>
              <Radio value={3}>ที่กำลังจะมาถึง</Radio>
              <Radio value={5}>ทั้งหมด</Radio>
            </Radio.Group>
          </Col>

          <Col>
            <Card style={{ borderRadius: '50px', textAlign: 'center' }}>
              <p className="font-weight-bold">{`${tableData.length ?? 0}`}</p>
              <p>รายการ</p>
            </Card>
          </Col>
        </Row>
        <Space direction="vertical" size={12}>
          <RangePicker
            placeholder={['เลือกช่วงเวลา', 'เลือกช่วงเวลา']}
            onChange={(value) => {
              console.log(value, 'rororo')
              setDateRange(value)
              if (value) {
                setTimeRange(4)
              } else {
                setTimeRange(1)
              }
            }}
          />
        </Space>
        <Tabs
          defaultActiveKey="allDonates"
          onChange={(tabName) => {
            setTab(tabName)
          }}
        >
          <TabPane tab="ผู้บริจาคทั้งหมด" key="allDonates">
            <Table columns={columns} dataSource={tableData} loading={loading} onChange={(paginate) => setPageSize(paginate.pageSize)} pageSize={pageSize} />
          </TabPane>
          <TabPane tab="ผู้บริจาคกรณีทั่วไป" key="generalDonates">
            <Table columns={columns} dataSource={tableData} loading={loading} onChange={(paginate) => setPageSize(paginate.pageSize)} pageSize={pageSize} />
          </TabPane>
          <TabPane tab="ผู้บริจาคกรณีพิเศษ" key="specialDonates">
            <Table columns={columns} dataSource={tableData} loading={loading} onChange={(paginate) => setPageSize(paginate.pageSize)} pageSize={pageSize} />
          </TabPane>
        </Tabs>
      </Container>
    </>
  )
}

export default BloodDonorTable

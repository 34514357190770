import http from '../services/httpServiceEver'
import httpOma from '../services/httpServiceHealthCare'
const API_URL = '/users'
const USER_INFO_API_URL = '/userinfos'

export const getUserInfo = async () => {
  try {
    const response = await http.get(`${API_URL}/me`)
    return response
  } catch (error) {
    return error
  }
}
export const triggerUpdateDataByCid = async (cId, vstDateFrom, vstDateTo) => {
  try {
    const response = await httpOma.get(`${USER_INFO_API_URL}/triggerUpdateDataByCId`, {
      params: {
        cId,
        vstDateFrom,
        vstDateTo,
      },
    })
    return response
  } catch (error) {
    return error
  }
}



import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
const Button = styled('button')`
  background-color: #45c1b8;
  border-color: 40a9ff;
  border-radius: 2px;
  &:hover {
    background-color: #40a9ff;
  }
`
const AddDepartmentModal = ({ onSubmit }) => (
  <Formik
    initialValues={{
      name: '',
    }}
    onSubmit={(values, { resetForm }) => {
      onSubmit(values)
      resetForm()
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().required('กรุณากรอกชื่อแผนก'),
    })}
    render={({ errors, status, touched }) => (
      <Form>
        <div className="form-group text-center">
          <label htmlFor="name">โปรดกรอกชื่อแผนกที่ต้องการเพิ่ม</label>
          <Field name="name" type="text" className={`form-control${errors.name && touched.name ? ' is-invalid' : ''} my-3`} />
          <ErrorMessage name="name" component="div" className="invalid-feedback" />
        </div>

        <div className="form-group text-right">
          <Button type="submit" className="btn btn-primary">
            ยืนยัน
          </Button>
        </div>
      </Form>
    )}
  />
)

export default AddDepartmentModal

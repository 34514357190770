import { bdrh, gender, bagType, bloodgrp, title, occptn, subArea, area, province } from './constant'
import moment from 'moment'
export const mapEphisData = (form, booking, patientInfo) => {
  const mappedData = {
    prefix: title[patientInfo?.fullname.title],
    name: patientInfo?.fullname?.firstName,
    surname: patientInfo?.fullname?.lastName,
    hn: patientInfo?.hn[0]?.hn,
    personal_ID: patientInfo?.cid,
    date_of_birth: moment(patientInfo?.DOB)
      .add(543, 'years')
      .format('YYYY-MM-DD'),
    male: gender[patientInfo?.sexName],
    occptn: occptn[form?.occupation] ?? '',
    phone: patientInfo?.contactInfo?.mobileNumber,
    dnrdate: moment(booking?.donationDate)
      .add(543, 'years')
      .format('YYYY-MM-DD'),
    dnrtime: `${moment()
      .startOf('isoWeek')
      .add(booking?.donationTime[0], 'minutes')
      .format('HHmmss')}`,
    bloodgrp: bloodgrp[form?.aboGroup],
    rh: bdrh[form?.rhGroup],
    dnrno: form?.unitNumber,
    bagtype: bagType[form?.bagType],
    weight: form?.weight,
    bp: `${form?.sbp}/${form?.dbp}`,
    address: [
      {
        addrpart: patientInfo?.address?.addressPart,
        moopart: patientInfo?.address?.moopart,
        soi: '',
        road: '',
        tmbpart: subArea[patientInfo?.address?.tmbpart] ?? '',
        amppart: area[patientInfo?.address?.amppart] ?? '',
        chwpart: province[patientInfo?.address?.chwpart] ?? '',
        zipcode: '',
      },
    ],
  }
  return mappedData
}

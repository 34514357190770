import React from 'react'
import Layout from '../Home'
import { Tabs, Card } from 'antd'
import BloodDonorAvailableTime from '../../components/BloodDonorAvailableTime'
import { Container } from './style'
import { hooks } from './hooks'
const { TabPane } = Tabs
const index = (props) => {
  const { events } = hooks()
  const { history } = props

  const renderAvailableTime = (type) => {
    return (
      <BloodDonorAvailableTime
        getAvailableTime={() => events.getAvailableTime(type)}
        getSlotDataByTimeRange={(startTime, endTime) => events.getSlotDataByTimeRange(startTime, endTime, type)}
        addAvailableTime={(value) => events.addAvailableTime(value, type)}
        updateAvailableTime={events.updateAvailableTime}
        deleteAvailableTimeById={events.deleteAvailableTimeById}
      />
    )
  }

  return (
    <Layout
      history={history}
      render={() => {
        return (
          <Container>
            <Tabs defaultActiveKey="normalAvailableTime" destroyInactiveTabPane={true}>
              {/* <TabPane tab="ตั้งค่าส่วนตัว" key="profile"></TabPane> */}
              <TabPane tab="ช่วงเวลาปกติ" key="normalAvailableTime">
                <Card>{renderAvailableTime('normal')}</Card>
              </TabPane>
              <TabPane tab="ช่วงเวลาวันหยุด" key="holidayAvailableTime">
                <Card>{renderAvailableTime('holiday')}</Card>
              </TabPane>
            </Tabs>
          </Container>
        )
      }}
    />
  )
}

export default index

import React, { useRef, useState, useEffect } from 'react'
import { CloseCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { Card, Tooltip, Input, Row, Col } from 'antd'
import './Chat.css'
import styled from 'styled-components'
import http from '../../services/httpServiceEver'
import jwtDecode from 'jwt-decode'
import firebase from 'firebase'
import 'firebase/firestore'
import axios from 'axios'

import { useCollectionData } from 'react-firebase-hooks/firestore'
import { COMPLETIONSTATEMENT_TYPES } from '@babel/types'
import { PRACTITIONER_ROLES } from '../../constant'
import { useSelector } from 'react-redux'

const Container = styled.div`
  width: 100%;
`
const ChatCard = styled(Card)`
  border-radius: 8px;
  min-height: ${(props) => (props.isShow ? '50vh' : '80vh')};
  .ant-card-body {
    padding: 0px;
    border: 1px solid #e0e0e0;
  }
`

const ChatBody = styled.div`
  height: ${(props) => props.isHeight - 125}px;
  overflow-y: scroll;
`

const Text = styled.p`
  color: #595c97;
`

const Send = styled.p`
  color: #c8c4db;
`

const ChatBox = styled.div`
  background-color: #f2f4f8;
  position: relative;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  margin-bottom: 8px;
`

function Chat(props) {
  const { patient, onCloseChat, isRoom, isShow, booking } = props
  const firestore = firebase.firestore()
  const user = jwtDecode(localStorage.getItem('token'))
  const userInfo = useSelector((state) => state.user?.userInfo)
  const id = user.id
  const role = user.roles[0]
  let firebaseDocument = `patient${Number(patient.id)}${booking?.doctor.roles[0].name}${booking?.doctor?.id}`

  const messagesRef = firestore
    .collection('THREADS_production')
    .doc(firebaseDocument)
    .collection('MESSAGES')

  const onUploadImage = async (e) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > 2097152) {
        message.error('เกิดข้อผิดพลาด กรุณาอัปโหลดรูปภาพที่มีขนาดไม่เกิน 2MB')
      } else {
        const fileUpload = e.target.files[0]
        const fileName = e.target.files[0].lastModified.toString()
        const storageRef = firebase.storage().ref(fileName)
        const task = storageRef.put(fileUpload)
        task.on(
          'state_changed',
          (snapshort) => {
            // let percentage = (snapshort.bytesTransferred / snapshort.totalBytes) * 100
            // Process
          },
          (error) => {
            // Error
            message.error(error.message)
          },
          async () => {
            // Success
            const image = await storageRef.getDownloadURL()
            await messagesRef.add({
              image,
              createdAt: new Date().getTime(),
              derivered: false,
              read: false,
              user: {
                _id: id,
                cid: null,
              },
            })
          },
        )
      }
    }
  }
  return (
    <Container>
      <ChatCard
        isShow={isShow}
        extra={
          isShow ? null : (
            <div>
              <label htmlFor="upload-button" className="btn btn-primary d-flex align-items-center">
                <UploadOutlined
                  style={{
                    cursor: 'pointer',
                    fontSize: '24px',
                    marginRight: 10,
                  }}
                />
                <span>อัพโหลดรูป</span>
              </label>
              <input type="file" accept="image/png, image/jpeg" id="upload-button" style={{ display: 'none' }} onChange={(e) => onUploadImage(e)} />
              {!isRoom && (
                <CloseCircleOutlined
                  onClick={() => onCloseChat(false)}
                  style={{
                    cursor: 'pointer',
                    color: '#eeeeee',
                    fontSize: '24px',
                  }}
                />
              )}
            </div>
          )
        }
        title={isShow ? null : patient && patient.fullname ? `${patient.fullname.title} ${patient.fullname.firstName} ${patient.fullname.lastName}` : 'ผู้ป่วย'}
      >
        <ChatRoom patient={patient} isShow={isShow} booking={booking} id={id} role={role} userInfo={userInfo} />
      </ChatCard>
    </Container>
  )
}

function ChatRoom(props) {
  const { patient, isShow, id, role, booking, userInfo } = props
  const [formValue, setFormValue] = useState('')
  const messagesEndRef = useRef(null)
  const doctorId = booking?.doctor?.id
  let firebaseDocument = `patient${Number(patient.id)}${booking?.doctor.roles[0].name}${doctorId}`

  const [heightChat, setHeightChat] = useState(826)
  const firestore = firebase.firestore()
  const messagesRef = firestore
    .collection('THREADS_production')
    .doc(firebaseDocument)
    .collection('MESSAGES')
  const query = messagesRef.orderBy('createdAt')
  const [messages] = useCollectionData(query, { idField: 'id' })

  useEffect(() => {
    firestore
      .collection('THREADS_production')
      .doc(firebaseDocument)
      .set({
        name: 'ห้องโทรเวช',
        latestMessage: {
          text: 'You have joined the room.',
          createdAt: new Date().getTime(),
        },
      })
  }, [])

  useEffect(() => {
    scrollToBottom()
    if (messages) {
      const chatnumber = messages.filter((e) => {
        if (e.user._id == patient.id) {
          return e
        }
      })
    }
  }, [messages])
  useEffect(() => {
    if (document.querySelector('.ant-tabs-content-holder') && document.querySelector('.ant-tabs-content-holder').offsetHeight) {
      setTimeout(() => {
        setHeightChat(document.querySelector('.ant-tabs-content-holder').offsetHeight)
      }, 1000)
    }
  }, [window.innerWidth])
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const fetchUserFcmToken = async (id, message, time, screen) => {
    const fetchAppUserIdentity = async (id) => {
      try {
        const { data } = await http.get(`/AppUserIdentities?filter[where][appUserId]=${id}`)
        return data
      } catch (error) {
        console.warn(error)
      }
    }

    const extractOmaId = async (id) => {
      const response = await fetchAppUserIdentity(id)
      if (response && response.length) {
        return response[response.length - 1].everOmaId
      } else {
        return false
      }
    }

    const fetchFcmToken = async (userId) => {
      try {
        const { data } = await http.get(`/userFcmTokens/getUserFcmTokenByUserId`, {
          params: {
            userId,
          },
        })
        return data
      } catch (error) {
        console.log(error)
      }
    }

    const sendNotiMessage = async (to, notification, data) => {
      try {
        const { info } = await axios.post(
          'https://fcm.googleapis.com/fcm/send',
          {
            to,
            notification,
            data,
          },
          {
            headers: {
              Authorization: `key=${process.env.REACT_APP_FCM_API_KEY || 'AAAAg2wfmIg:APA91bHTFQu2B2mGSeSmsVdad-2Lpirx_bVCtcS2T7qS4kX_efVbm60XQT2XKCO7M8EIqTl1dH467SYVvHG4r_0s5k-QNHbtF9X0u88iDo16hZDVum4QDSylJw1hl8NhMo9PKlt2qD_k'}`,
              'Content-Type': 'application/json',
            },
          },
        )
        return info
      } catch (error) {
        console.log(error)
      }
    }

    const everOmaId = await extractOmaId(id)

    if (everOmaId) {
      const response = await fetchFcmToken(everOmaId)

      if (response && response.length) {
        response.forEach(async (res) => {
          let notification = {
            body: message,
            title: 'แจ้งเตือน',
            icon: 'ic_launcher',
            color: '#f1c40f',
            sound: 'default',
          }
          let data = {
            bookingTime: time,
            screen: screen,
          }
          await sendNotiMessage(res.fcmToken, notification, data)
        })
      }
    }
  }

  const sendMessage = async (e) => {
    e.preventDefault()
    await messagesRef.add({
      text: formValue,
      createdAt: new Date().getTime(),
      derivered: false,
      read: false,
      user: {
        _id: id,
        cid: null,
        role,
        name: userInfo.fullname
      },

    })

    let message = role === 'doctor' ? 'แพทย์ได้ส่งข้อความถึงคุณ.' : role === 'pharmacy' ? 'เภสัชได้ส่งข้อความถึงคุณ.' : 'เจ้าหน้าที่ได้ส่งข้อความถึงคุณ'

    fetchUserFcmToken(patient.id, message, null, 'VideoCall')
    setFormValue('')
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage(e)
    }
  }

  return (
    <div>
      <ChatBody isHeight={heightChat} isShow={isShow}>
        {messages &&
          messages.map((msg, index) => (
            <div key={msg.text + index} ref={messagesEndRef}>
              <ChatMessage message={msg} id={id} role={role} />
            </div>
          ))}
      </ChatBody>
      {!isShow ? (
        <div className="input-group mb-3 pl-5 pr-5">
          <input type="text" className="form-control" placeholder="กรอกข้อความ..." value={formValue} onChange={(e) => setFormValue(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} />
          <div className="input-group-append">
            <button className="btn btn-primary" type="button">
              <i onClick={sendMessage} className="fe fe-send align-middle" />
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

function ChatMessage(props) {
  const ROLES = {
    'doctor': 'แพทย์',
    'callCenter': 'เจ้าหน้าที่',
    'observerStaff': 'เจ้าหน้าที่',
    'nurse': 'พยาบาล',
    'user': 'ผู้ป่วย'
  }
  const { text, image, user, read, photoURL } = props.message
  const classMessage = user._id === props.id ? 'send' : 'received'
  const transformRole = ROLES[user.role]
  return (
    <div className={`message ${classMessage}`}>
      <img src={photoURL || ''} alt="" />
      <ChatBox>
        <Send>{classMessage === 'send' ? 'คุณ' : transformRole}</Send>
        {image ? <img style={{ width: '200px', height: '200px' }} src={image} alt="" /> : <Text>{text}</Text>}
      </ChatBox>
    </div>
  )
}
export default Chat

/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { CloseCircleOutlined, LinkOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';
import PropTypes from 'prop-types'
import { get } from 'lodash'
import Select from 'react-select'
import TextBase from '../../components/Text'
import Table from '../../components/Table'

const Text = styled(TextBase)`
  font-weight: bold;
`
// const Footer = styled.footer`
//   display: flex;
//   justify-content: space-between;
// `
const Order = styled.div`
  flex: 1;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
`
const Total = styled.div`
  padding: 20px;
  background-color: #c8edea;
  display: flex;
  justify-content: space-between;
`
const Container = styled.div`
  padding: 20px;
  display: flex;
`
const PatientInfo = styled.div`
  min-width: 250px;
  padding-right: 20px;
  .header {
    font-size: 18px;
  }
`
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0;
`
const columns = [
  {
    title: '#',
    key: 'number',
    render: (text, record) => <Text>{record.index + 1}</Text>
  },
  {
    title: 'ชื่อยา',
    key: 'item',
    render: (text, record) => <Text>{record.name}</Text>
  },
  {
    title: 'จำนวน',
    key: 'quantity',
    render: (text, record) => (
      <InputNumber
        min={0}
        defaultValue={0}
        value={record.quantity}
        onChange={record.onChangeQuantity}
      />
    )
  },
  {
    title: 'ราคา',
    key: 'price',
    render: (text, record) => {
      const price = record.quantity * record.price
      return <Text>{price}</Text>
    }
  },
  {
    key: 'action',
    render: (text, record) => (
      <CloseCircleOutlined onClick={record.onClickDelete} style={{ color: '#FF0000' }} />
    )
  }
]
@inject('User')
@inject('ConsultCase')
@observer
class SummaryOrder extends Component {
  state = {
    selectedOption: null
  }

  async componentDidMount() {
    const { User } = this.props
    await User.fetchInfo()
  }

  handleChangeSelect = selectedOption => {
    const { onChange, medicines, selectOptions } = this.props
    const filter = selectOptions.filter(option => option.label !== selectedOption.label)
    const med = [...medicines, { ...selectedOption, quantity: 1 }]
    onChange('selectOptions', filter)
    onChange('medicines', med)
  }

  render() {
    const { selectedOption } = this.state
    const { User, callingData, medicines, onChange, selectOptions, doctor } = this.props
    const totalPrice = medicines.reduce((acc, cur) => {
      const sum = acc + cur.price * cur.quantity
      return sum
    }, 0)
    const doctorInfo = doctor || User.getInfo
    return (
      <Container>
        <PatientInfo>
          <Text bold className="header">
            ประวัติผู้ป่วย
          </Text>
          <Row>
            <Text>ชื่อ:</Text>
            <Text>{get(callingData, 'fullname')}</Text>
          </Row>
          <Row>
            <Text>อาการ:</Text>
            <Text>{get(callingData, 'symptom', '')}</Text>
          </Row>
          <Row>
            <Text>รายละเอียดเพิ่มเติม: </Text>
            <Text>{get(callingData, 'description')}</Text>
          </Row>
        </PatientInfo>
        <Order>
          <Text bold style={{ padding: 15 }}>
            <LinkOutlined style={{ marginRight: 10 }} />
            รายการยาเพิ่มเติม
          </Text>
          <Table
            pagination={{ pageSize: 5 }}
            columns={columns}
            dataSource={medicines.map((item, index) => {
              const temp = item
              temp.index = index
              temp.onClickDelete = () => {
                medicines.splice(index, 1)
                onChange('medicines', medicines)
                onChange('selectOptions', [...selectOptions, item])
              }
              temp.onChangeQuantity = value => {
                const drug = medicines[index]
                drug.quantity = value
                onChange('medicines', medicines)
              }
              return temp
            })}
          />
          <div style={{ padding: 20 }}>
            <Select
              options={selectOptions}
              value={selectedOption}
              placeholder="เพิ่มยา"
              onChange={this.handleChangeSelect}
            />
          </div>
          <Total>
            <Text>รวม</Text>
            <Text>{totalPrice} บาท</Text>
          </Total>
        </Order>
      </Container>
    );
  }
}

SummaryOrder.propTypes = {
  User: PropTypes.object, // eslint-disable-line
  doctor: PropTypes.object, // eslint-disable-line
  ConsultCase: PropTypes.object, // eslint-disable-line
  history: PropTypes.object, //eslint-disable-line
  callingData: PropTypes.object, //eslint-disable-line
  onChange: PropTypes.func, //eslint-disable-line
  medicines: PropTypes.array, //eslint-disable-line
  selectOptions: PropTypes.array //eslint-disable-line
}

export default SummaryOrder

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table, Button, Row, Col, DatePicker } from 'antd'
import ReactExport from 'react-data-export'
import { DISTRICT } from './constant'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ReportEmsTab = ({ reportEmsData, fetchEmsReports, isLoading }) => {
  const [startDateTime, setStartDateTime] = useState(
    moment()
      .startOf('month')
      .set('hours', 0)
      .format('YYYY-MM-DD'),
  )
  const [endDateTime, setEndDateTime] = useState(
    moment()
      .set('hours', 0)
      .format('YYYY-MM-DD'),
  )

  useEffect(() => {
    fetchEmsReports(startDateTime, endDateTime)
  }, [])

  const columns = [
    {
      title: 'พื้นที่เขต',
      key: 'district',
      render: (record) => <p>{DISTRICT[record.district]}</p>,
      align: 'center',
    },
    {
      title: 'ปฎิบัติการ',
      key: 'nbOfOperationRecord',
      render: (record) => <p>{record.nbOfOperationRecord}</p>,
      sorter: (a, b) => a.nbOfOperationRecord - b.nbOfOperationRecord,
      align: 'center',
    },
    {
      title: 'ยกเลิก',
      key: 'nbOfCancelRecord',
      render: (record) => <p>{record.nbOfCancelRecord}</p>,
      sorter: (a, b) => a.nbOfCancelRecord - b.nbOfCancelRecord,
      align: 'center',
    },
    {
      title: 'ส่งต่อ',
      key: 'nbOfPassRecord',
      render: (record) => <p>{record.nbOfPassRecord}</p>,
      sorter: (a, b) => a.nbOfPassRecord - b.nbOfPassRecord,
      align: 'center',
    },
    {
      title: 'ทั้งหมด',
      key: 'total',
      render: (record) => <p>{record.nbOfOperationRecord + record.nbOfCancelRecord + record.nbOfPassRecord}</p>,
      align: 'center',
    },
  ]
  return (
    <div>
      <Row justify="center" gutter={[10, 10]} className="my-3">
        <Col className="d-flex align-items-center">
          <p>วันที่เริ่มต้น:</p>
          <DatePicker
            format="DD/MM/YYYY"
            defaultValue={moment(startDateTime)}
            onChange={(value) => {
              setStartDateTime(
                moment(value)
                  .set('hours', 0)
                  .format('YYYY-MM-DD'),
              )
            }}
            abbr
          />
        </Col>
        <Col className="d-flex align-items-center">
          วันที่สิ้นสุด:
          <DatePicker
            format="DD/MM/YYYY"
            defaultValue={moment(endDateTime)}
            onChange={(value) => {
              setEndDateTime(
                moment(value)
                  .set('hours', 0)
                  .format('YYYY-MM-DD'),
              )
            }}
            abbr
          />
        </Col>
        <Col className="d-flex align-items-center">
          <Button type="primary" onClick={() => fetchEmsReports(startDateTime, endDateTime)}>
            แสดงข้อมูล
          </Button>
        </Col>
        {reportEmsData.length > 0 ? (
          <Col className="d-flex align-items-center">
            <ExcelFile
              element={<Button>Export File</Button>}
              filename={`Report_EMS_${moment()
                .add(543, 'years')
                .format('DD/MM/YYYY')}`}
            >
              <ExcelSheet data={reportEmsData} name="Report EMS">
                <ExcelColumn label="พื้นที่เขต" value="district" />
                <ExcelColumn label="ปฎิบัติการ" value="nbOfOperationRecord" />
                <ExcelColumn label="ยกเลิก" value="nbOfCancelRecord" />
                <ExcelColumn label="ส่งต่อ" value="nbOfPassRecord" />
              </ExcelSheet>
            </ExcelFile>
          </Col>
        ) : (
          ''
        )}
      </Row>
      <Table
        columns={columns}
        dataSource={reportEmsData}
        pagination={false}
        loading={isLoading}
        bordered
        summary={(pageData) => {
          let totalOperationRecord = 0
          let totalCancelRecord = 0
          let totalPassRecord = 0
          let totalAllRecord = 0
          pageData.forEach(({ nbOfOperationRecord, nbOfCancelRecord, nbOfPassRecord }) => {
            totalOperationRecord += nbOfOperationRecord
            totalCancelRecord += nbOfCancelRecord
            totalPassRecord += nbOfPassRecord
            totalAllRecord += nbOfOperationRecord += nbOfCancelRecord += nbOfPassRecord
          })
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} align="center">
                  ทั้งหมด
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="center">
                  <p>{totalOperationRecord}</p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center">
                  <p>{totalCancelRecord}</p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="center">
                  <p>{totalPassRecord}</p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="center">
                  <p>{totalAllRecord}</p>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
        }}
      />
    </div>
  )
}

export default ReportEmsTab

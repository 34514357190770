import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import { WeightValue, WeightTrend, WeightGraph } from '../constant'
import WeightValueCard from './WeightValueCard'
import WeightTrendCard from './WeightTrendCard'
import WeightGraphCard from './WeightGraphCard'

function Weight(props) {
  const { editable, weights, defaultWeights, patient, filterReport, filterByLevelAndDate,checkAllWeights } = props
  const [values, setValues] = useState(WeightValue)
  const [trends, setTrends] = useState(WeightTrend)
  const [graphs, setGraphs] = useState(WeightGraph)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (weights.data && weights.data.arr.length && weights.data.dates.length) {
      checkWeightValue()
    } else {
      setValues(WeightValue)
      setTrends(WeightTrend)
      setGraphs(WeightGraph)
      setIsLoading(false)
    }
    console.log('weights')
    console.log(weights)
    console.log('loading')
    console.log(isLoading)
  }, [weights])

  const findMin = (arr) => Math.min(...arr)
  const findMax = (arr) => Math.max(...arr)
  const findAverage = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length

  const checkWeightValue = () => {
    const { arr } = weights.data
    setValues({
      min: findMin(arr),
      max: findMax(arr),
      average: findAverage(arr),
    })
    checkWeightTrend()
  }

  const checkWeightTrend = (defaultParams) => {
    if (weights.data && defaultWeights) {
      const { arr } = weights.data
      let lowest = defaultWeights[0]
      let highest = defaultWeights[1]
      if (defaultParams && defaultParams.length) {
        lowest = defaultParams[0]
        highest = defaultParams[1]
      }
      const trend = { ...WeightTrend }
      arr.map((value) => {
        if (Number(value) < Number(lowest)) {
          trend.low++
        } else if (Number(value) >= Number(lowest) && Number(value) <= Number(highest)) {
          trend.mid++
        } else if (Number(value) > Number(highest)) {
          trend.high++
        }
      })
      setTrends((prev) => ({
        ...trend,
        pie: {
          ...prev.pie,
          datasets: [
            {
              ...prev.pie.datasets[0],
              data: [trend.mid, trend.low, trend.high],
            },
          ],
        },
      }))
      checkWeightGraph(lowest, highest)
    }
  }

  const checkWeightGraph = (lowest, highest) => {
    const { arr, height, bmi, dates } = weights.data
    setGraphs((prev) => ({
      defaultLow: lowest,
      defaultHigh: highest,
      lines: {
        options: {
          ...prev.lines.options,
          xaxis: {
            categories: dates,
          },
          annotations: {
            yaxis: [
              {
                ...prev.lines.options.annotations.yaxis[0],
                y: highest,
              },
              {
                ...prev.lines.options.annotations.yaxis[1],
                y: lowest,
                y2: highest,
              },
              {
                ...prev.lines.options.annotations.yaxis[2],
                y: lowest,
              },
            ],
          },
          tooltip: {
            enabled: true,
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
              return `<div class="arrow_box" style="padding: 16px">
                      <p>Weight Value: <b>${series[seriesIndex][dataPointIndex]}</b></p>
                      <p>Height: <b>${height[dataPointIndex] || '-'}</b></p>
                      <p>BMI: <b>${bmi[dataPointIndex] || '-'}</b></p>
                      <p>Date: <b>${dates[dataPointIndex] || '-'}</b></p>
                      </div>`
            },
          },
        },
        series: [
          {
            name: 'Weight Value',
            data: arr,
          },
        ],
      },
    }))
    setIsLoading(false)
  }

  return (
    !isLoading && (
      <div>
        <WeightValueCard checkAllWeights={checkAllWeights} values={values} filterReport={filterReport} filterByLevelAndDate={filterByLevelAndDate} />
        <WeightTrendCard trends={trends} />
        <WeightGraphCard editable={editable} graphs={graphs} patient={patient} onFinishWeight={checkWeightTrend} />
      </div>
    )
  )
}

export default Weight

import React, { useRef, useState } from 'react'
import { FiCalendar, FiX } from 'react-icons/fi'
import Scratcher from './Scratcher'
import './datepicker.css'
import { Days, Months } from './date-constant'
import Picker from './Picker'
import Stepper from './Stepper'
const DatePicker = ({ value, mode, calendar, onChange, abbr }) => {
  const pad = (num) => String(num).padStart(2, '0')
  const BECal = calendar == 'BE'
  const monthPicker = Object.freeze(Months.map((month) => Object.freeze({ label: BECal ? month.th.full : month.en.full, value: month.en.full.toLowerCase() })))
  const [isPicking, setPicking] = useState(false)
  const today = new Date()
  const origin = value ? new Date(value) : today
  const yearOffset = BECal ? 543 : 0
  const [year, setYear] = useState(origin.getFullYear())
  const [month, setMonth] = useState(origin.getMonth())
  const [day, setDay] = useState(origin.getDate())
  const [offset, setOffset] = useState(new Date(year, month, 1).getDay())
  const [daysInLastMonth, setDaysInLastMonth] = useState(new Date(year, month, 0).getDate())
  const [daysInThisMonth, setdaysInThisMonth] = useState(new Date(year, parseInt(month) + 1, 0).getDate())
  const [weeksInThisMonth, setWeeksInThisMonth] = useState([...Array(Math.ceil((daysInThisMonth + offset) / 7))])
  const [selectedDay, setSelectedDay] = useState({ y: year, m: month, d: day })
  const [hour, setHour] = useState(origin.getHours())
  const [minute, setMinute] = useState(origin.getMinutes())
  const interpretOutput = (y, m, d, hrs, mins) => {
    const monthStr = BECal ? (abbr ? Months[m]?.th?.abbr : Months[m]?.th?.full) : abbr ? Months[m]?.en?.abbr : Months[m]?.en?.full
    return `${d} ${monthStr} ${abbr ? (BECal ? '' : '’') + String(y + yearOffset).slice(-2) : y + yearOffset} ${mode.match('time') ? hrs + ':' + pad(mins) + (BECal ? 'น.' : '') : ''}`
  }
  const originStr = value ? interpretOutput(year, month, day, hour, minute) : ''
  const [strVal, setStrVal] = useState(originStr)
  const datepickerComponent = useRef()
  const updateCalendar = (m, y) => {
    const offs = new Date(y, m, 1).getDay()
    const ditm = new Date(y, parseInt(m) + 1, 0).getDate()
    setMonth(m)
    setYear(y)
    setOffset(offs)
    setDaysInLastMonth(new Date(y, m, 0).getDate())
    setdaysInThisMonth(ditm)
    setWeeksInThisMonth([...Array(Math.ceil((ditm + offs) / 7))])
    var counter = 0
  }
  const selectDay = (y, m, d) => {
    setDay(d)
    setSelectedDay({ y, m, d })
    setStrVal(interpretOutput(y, m, d, hour, minute))
    // onChange(new Date(y, m, d, hour, minute))
  }
  const isPicked = (y, m, d) => {
    return d == selectedDay.d && m == selectedDay.m && y == selectedDay.y
  }
  const isToday = (y, m, d) => {
    return d == today.getDate() && m == today.getMonth() && y == today.getFullYear()
  }
  const handleTimeChange = (hrs, mins) => {
    setHour(hrs)
    setMinute(mins)
    setStrVal(interpretOutput(selectedDay.y, selectedDay.m, selectedDay.d, hrs, mins))
    // onChange(new Date(selectedDay.y, selectedDay.m, selectedDay.d, hrs, mins))
  }
  const handlePickCurrentDate = () => {
    const d = today.getDate(),
      m = today.getMonth(),
      y = today.getFullYear(),
      hrs = today.getHours(),
      mins = today.getMinutes()
    setDay(d)
    setMonth(m)
    setYear(y)
    setHour(hrs)
    setMinute(mins)
    setSelectedDay({ y, m, d })
    setStrVal(interpretOutput(y, m, d, hrs, mins))
    console.log('picked now')
    // onChange(new Date(y, m, d, hrs, mins))
  }
  const showDatePicker = (e) => {
    e.stopPropagation()
    setPicking(true)
    strVal === '' && handlePickCurrentDate()
    document.addEventListener('click', hideDatePicker, true)
  }
  const hideDatePicker = (e) => {
    if (datepickerComponent.current && !datepickerComponent.current.contains(e.target)) {
      // e.stopPropagation()
      setPicking(false)
      setStrVal(originStr)
      setDay(origin.getDate())
      setMonth(origin.getMonth())
      setYear(origin.getFullYear())
      setHour(origin.getHours())
      setMinute(origin.getMinutes())
      setSelectedDay({ y: origin.getFullYear(), m: origin.getMonth(), d: origin.getDate() })
      document.removeEventListener('click', hideDatePicker, true)
    }
  }
  const clearOutput = (e) => {
    e.stopPropagation()
    setStrVal('')
    onChange && onChange(null)
  }
  const applyOutput = () => {
    setPicking(false)
    document.removeEventListener('click', hideDatePicker, true)
    onChange && onChange(new Date(selectedDay.y, selectedDay.m, selectedDay.d, hour, minute))
  }
  return (
    <div>
      <div className="neo-date-picker-input-wrapper">
        <input className={'neo-date-picker-input' + (abbr ? ' abbr' : '')} onClick={showDatePicker} value={strVal} readOnly />
        {strVal == '' ? <FiCalendar onClick={showDatePicker} /> : <FiX onClick={clearOutput} />}
      </div>
      {isPicking && (
        <div className="neo-cal-placeholder">
          <div ref={datepickerComponent} className="neo-date-picker">
            {(!mode || mode.match('date')) && (
              <div className="neo-calendar">
                <div className="neo-calendar-controller">
                  <Picker choices={monthPicker} picked={(v, i) => updateCalendar(i, year)} chosen={month}></Picker>
                  <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'stretch', gap: '0.5em' }}>
                    <label htmlFor="neo-calendar-year" style={{ margin: '0', alignSelf: 'center' }}>
                      {BECal ? 'พ.ศ.' : 'AD'}
                    </label>
                    <Stepper
                      id="neo-calendar-year"
                      value={year + yearOffset}
                      digits={4}
                      onChange={(val) => updateCalendar(month, val - yearOffset)}
                      // style={{
                      //     width: "4em",
                      //     textAlign: "right",
                      //     padding: "0 0.5em",
                      //     border: "1px solid #6662",
                      //     borderBottomColor: "#6664",
                      //     borderRadius: "0.2em"
                      // }}
                    />
                  </div>
                </div>
                <div className="neo-calendar">
                  <table>
                    <thead>
                      <tr>{BECal ? Days.map((d) => <th key={d.en.full}>{d.th.abbr}</th>) : Days.map((d) => <th key={d.en.full}>{d.en.abbr}</th>)}</tr>
                    </thead>
                    <tbody>
                      {weeksInThisMonth.map((_, week) => {
                        return (
                          <tr key={`week-${week}`}>
                            {Days.map((_, dayOfWeek) => {
                              const dayNo = week == 0 ? ((daysInLastMonth - offset + dayOfWeek) % daysInLastMonth) + 1 : ((week * 7 + dayOfWeek - offset) % daysInThisMonth) + 1
                              const isThisMonth = !((dayNo > 23 && week == 0) || (dayNo < 7 && week > 3))
                              const calcMonth = isThisMonth ? month : week == 0 ? month - 1 : month + 1
                              const correctMonth = (calcMonth + 12) % 12
                              const correctYear = calcMonth == correctMonth ? year : calcMonth < 0 ? year - 1 : year + 1
                              return (
                                <td
                                  key={`day-${dayOfWeek}-of-week-${week}`}
                                  className={`${isThisMonth ? '' : 'other-month'} ${isPicked(correctYear, correctMonth, dayNo) ? 'picked' : ''} ${isToday(correctYear, correctMonth, dayNo) ? 'today' : ''}`}
                                  onClick={() => selectDay(correctYear, correctMonth, dayNo)}
                                >
                                  {dayNo}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className="neo-time">
              {(!mode || mode.match('time')) && (
                <div className="neo-clock">
                  <div className="neo-time-wrapper">
                    <Picker picked={(v) => handleTimeChange(v, minute)} choices={Array.from({ length: 24 }, (_, i) => ({ value: i, label: `${i}` }))} chosen={hour} align="right" showStepper />
                  </div>
                  <div className="neo-time-wrapper">
                    <Picker picked={(v) => handleTimeChange(hour, v)} choices={Array.from({ length: 60 }, (_, i) => ({ value: i, label: `${i}`.padStart(2, '0') }))} chosen={minute} align="right" showStepper />
                  </div>
                </div>
              )}
              <div className="neo-date-picker-buttons">
                <button type="button" className="neo-set-now" onClick={handlePickCurrentDate}>
                  Now
                </button>
                <button type="button" className="neo-done-date-picking" onClick={applyOutput}>
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default DatePicker

import React, { useEffect, useState } from 'react'
import { Spin, Row, Col, Card, Modal } from 'antd'
import Table from '../../components/Table'
import styled from 'styled-components'
import DateTable from './DateTable'
const Text = styled.p`
  font-size: 15px;
  color: #2b2b2b;
  cursor: pointer;
`
const Container = styled.div`
  width: 100%;
  padding: 20px;
`

const StatisticTab = ({ dataSource, isLoading, setIsloading, ...rest }) => {
  const [statistic, setStatistic] = useState(false)
  const [showModal, setShowmodal] = useState(modalStatus ? true : false)
  const [modalStatus, setModalStatus] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [smbgCount, setSmbgCount] = useState(0)
  const [smbgClickedCount, setSmbgClickedCount] = useState(0)
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    combineDuplicatePatientData()
  }, [dataSource])

  useEffect(() => {
    if (modalStatus) {
      setShowmodal(modalStatus)
    }
  }, [modalStatus])
  const combineDuplicatePatientData = () => {
    const combinedStatisticData = dataSource.reduce((accumulator, object) => {
      let objectFound
      if ((objectFound = accumulator.find((arrItem) => arrItem.userId === object.userId))) {
        const duplicateData = dataSource.filter((x) => x.userId === objectFound.userId)
        let data = {
          telemedicineCount: 0,
          chatbotAiMessageCount: 0,
          telemedicineTimeCount: 0,
          chatbotMessageFromUserCount: 0,
          emergencyCount: 0,
          gameCount: 0,
          diaryCount: 0,
          pickupByEmsCount: 0,
          smbgCount: 0,
          hbpmCount: 0,
          bookingStatusCount: 0,
          drugTakingCount: 0,
          drugTrackingCount: 0,
          timestampKey: {
            telemedicineCountTimestamp: [],
            chatbotAiMessageCountTimestamp: [],
            telemedicineTimeCountTimestamp: [],
            chatbotMessageFromUserCountTimestamp: [],
            emergencyCountTimestamp: [],
            gameCountTimestamp: [],
            diaryCountTimestamp: [],
            pickupByEmsCountTimestamp: [],
            bookingStatusCountTimestamp: [],
            drugTakingCountTimestamp: [],
            drugTrackingCountTimestamp: [],
            smbgCountTimestamp: [],
            hbpmCountTimestamp: [],
          },
        }
        duplicateData.map((item) => {
          const key = item.timestampKey
          let dataKey = item.timestampKey
          data.userId = item.userId
          data.user = item.user
          data.telemedicineCount += item.telemedicineCount
          data.chatbotAiMessageCount += item.chatbotAiMessageCount
          data.telemedicineTimeCount += item.telemedicineTimeCount
          data.chatbotMessageFromUserCount += item.chatbotMessageFromUserCount
          data.emergencyCount += item.emergencyCount
          data.gameCount += item.gameCount
          data.diaryCount += item.diaryCount
          data.pickupByEmsCount += item.pickupByEmsCount
          data.smbgCount += item.smbgCount
          data.hbpmCount += item.hbpmCount
          data.bookingStatusCount += item.bookingStatusCount
          data.drugTakingCount += item.drugTakingCount
          data.drugTrackingCount += item.drugTrackingCount
          if (item && key) {
            const timestampKey = {
              telemedicineCountTimestamp: key && key.telemedicineCountTimestamp ? [...dataKey.telemedicineCountTimestamp, ...key.telemedicineCountTimestamp] : [],
              chatbotAiMessageCountTimestamp: key && key.chatbotAiMessageCountTimestamp ? [...dataKey.chatbotAiMessageCountTimestamp, ...key.chatbotAiMessageCountTimestamp] : [],
              telemedicineTimeCountTimestamp: key && key.telemedicineTimeCountTimestamp ? [...dataKey.telemedicineTimeCountTimestamp, ...key.telemedicineTimeCountTimestamp] : [],
              chatbotMessageFromUserCountTimestamp: key && key.chatbotMessageFromUserCountTimestamp ? [...dataKey.chatbotMessageFromUserCountTimestamp, ...key.chatbotAiMessageCountTimestamp] : [],
              emergencyCountTimestamp: key && key.emergencyCountTimestamp ? [...dataKey.emergencyCountTimestamp, ...key.emergencyCountTimestamp] : [],
              gameCountTimestamp: key && key.gameCountTimestamp ? [...dataKey.gameCountTimestamp, ...key.gameCountTimestamp] : [],
              diaryCountTimestamp: key && key.diaryCountTimestamp ? [...dataKey.diaryCountTimestamp, ...key.diaryCountTimestamp] : [],
              pickupByEmsCountTimestamp: key && key.pickupByEmsCountTimestamp ? [...dataKey.pickupByEmsCountTimestamp, ...key.pickupByEmsCountTimestamp] : [],
              bookingStatusCountTimestamp: key && key.bookingStatusCountTimestamp ? [...dataKey.bookingStatusCountTimestamp, ...key.bookingStatusCountTimestamp] : [],
              drugTakingCountTimestamp: key && key.drugTakingCountTimestamp ? [...dataKey.drugTakingCountTimestamp, ...key.drugTakingCountTimestamp] : [],
              drugTrackingCountTimestamp: key && key.drugTrackingCountTimestamp ? [...dataKey.drugTrackingCountTimestamp, ...key.drugTrackingCountTimestamp] : [],
              smbgCountTimestamp: key && key.smbgCountTimestamp ? [...dataKey.smbgCountTimestamp, ...key.smbgCountTimestamp] : [],
              hbpmCountTimestamp: key && key.hbpmCountTimestamp ? [...dataKey.hbpmCountTimestamp, ...key.hbpmCountTimestamp] : [],
            }
            data.timestampKey = timestampKey
          }
        })
        objectFound = data
      } else {
        accumulator.push(object)
      }
      return accumulator
    }, [])
    setStatistic(combinedStatisticData)
  }

  useEffect(() => {
    if (statistic) {
      countSmbg(statistic)
    }
  }, [statistic])

  const countSmbg = (dat) => {
    if (dat) {
      const filteredData = dat?.filter((item) => !!item?.smbgCount)
      const smbgData = filteredData?.map((item) => item?.smbgCount)
      const smbgClickAll = smbgData?.reduce((a, b) => a + b, 0)
      setSmbgCount(filteredData.length)
      setSmbgClickedCount(smbgClickAll)
    }
  }
  var toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10)
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? '0' + v : v))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':')
  }
  const handleShowModal = (status, data, text) => {
    setModalStatus(status)
    setTableData(data)
    setModalTitle(text)
  }

  const columns = [
    {
      title: 'ชื่อผู้ป่วย',
      key: 'customerName',
      render: (record) => (record && record.user ? <Text onClick={() => console.log(record)}>{record.user?.fullnameHIE ? record.user?.fullnameHIE : record.user?.fullname}</Text> : '-'),
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'จำนวนการกดปุ่มฉุกเฉิน',
      key: 'EmergencyCount',
      render: (record) => {
        const timestampKey = record.timestampKey
        return record && record.emergencyCount ? <Text onClick={() => handleShowModal(true, (timestampKey && timestampKey.emergencyCountTimestamp) || [], 'จำนวนการกดปุ่มฉุกเฉิน')}>{record.emergencyCount}</Text> : '-'
      },
      sorter: (a, b) => a.emergencyCount - b.emergencyCount,
      align: 'center',
    },
    {
      title: 'จำนวนการใช้งานTelecommu',
      key: 'telemedicineCount',
      render: (record) => {
        const timestampKey = record.timestampKey
        return record && record.telemedicineCount ? <Text onClick={() => handleShowModal(true, (timestampKey && timestampKey.telemedicineCountTimestamp) || [], 'จำนวนการใช้งานTelecommu')}>{record.telemedicineCount}</Text> : '-'
      },
      sorter: (a, b) => a.telemedicineCount - b.telemedicineCount,
      defaultSortOrder: 'descend',
      align: 'center',
    },
    {
      title: 'เวลาในใช้งานTelecommu',
      key: 'telemedicineTimeCount',
      render: (record) => {
        const timestampKey = record.timestampKey
        return record && record.telemedicineTimeCount ? (
          <Text onClick={() => handleShowModal(true, (timestampKey && timestampKey.telemedicineTimeCountTimestamp) || [], 'เวลาในใช้งานTelecommu')}>{toHHMMSS(record.telemedicineTimeCount)}</Text>
        ) : (
          '-'
        )
      },
      sorter: (a, b) => a.telemedicineTimeCount - b.telemedicineTimeCount,
      align: 'center',
    },
    {
      title: 'จำนวนการกดปุ่มยิ่งเล่น ยิ่งรู้',
      key: 'gameCount',
      render: (record) => {
        const timestampKey = record.timestampKey
        return record && record.gameCount ? <Text onClick={() => handleShowModal(true, (timestampKey && timestampKey.gameCountTimestamp) || [], 'จำนวนการกดปุ่มยิ่งเล่น ยิ่งรู้')}>{record.gameCount}</Text> : '-'
      },
      sorter: (a, b) => a.gameCount - b.gameCount,
      align: 'center',
    },
    {
      title: 'จำนวนการกดปุ่ม SMBG',
      key: 'smbgCount',
      render: (record) => {
        const timestampKey = record.timestampKey
        return record && record.smbgCount ? <Text onClick={() => handleShowModal(true, (timestampKey && timestampKey.smbgCountTimestamp) || [], 'จำนวนการกดปุ่ม SMBG')}>{record.smbgCount}</Text> : '-'
      },
      sorter: (a, b) => a.smbgCount - b.smbgCount,
      align: 'center',
    },
    {
      title: 'จำนวนการกดปุ่ม HBPM',
      key: 'hbpmCount',
      render: (record) => {
        const timestampKey = record.timestampKey
        return record && record.hbpmCount ? <Text onClick={() => handleShowModal(true, (timestampKey && timestampKey.hbpmCountTimestamp) || [], 'จำนวนการกดปุ่ม HBPM')}>{record.hbpmCount}</Text> : '-'
      },
      sorter: (a, b) => a.hbpmCount - b.hbpmCount,
      align: 'center',
    },
    {
      title: 'จำนวนการกดปุ่มรับยาทางไปรษณีย์',
      key: 'pickupByEmsCount',
      render: (record) => {
        const timestampKey = record.timestampKey
        return record && record.pickupByEmsCount ? <Text onClick={() => handleShowModal(true, (timestampKey && timestampKey.pickupByEmsCountTimestamp) || [], 'จำนวนการกดปุ่มรับยาทางไปรษณีย์')}>{record.pickupByEmsCount}</Text> : '-'
      },
      sorter: (a, b) => a.pickupByEmsCount - b.pickupByEmsCount,
      align: 'center',
    },
    {
      title: 'จำนวนการกดปุ่มสถานะรายการ',
      key: 'bookingStatusCount',
      render: (record) => {
        const timestampKey = record.timestampKey
        return record && record.bookingStatusCount ? <Text onClick={() => handleShowModal(true, (timestampKey && timestampKey.bookingStatusCountTimestamp) || [], 'จำนวนการกดปุ่มสถานะรายการ')}>{record.bookingStatusCount}</Text> : '-'
      },
      sorter: (a, b) => a.bookingStatusCount - b.bookingStatusCount,
      align: 'center',
    },
    {
      title: 'จำนวนการกดปุ่มรายการยา',
      key: 'drugTakingCount',
      render: (record) => {
        const timestampKey = record.timestampKey
        return record && record.drugTakingCount ? <Text onClick={() => handleShowModal(true, (timestampKey && timestampKey.drugTakingCountTimestamp) || [], 'จำนวนการกดปุ่มรายการยา')}>{record.drugTakingCount}</Text> : '-'
      },
      sorter: (a, b) => a.drugTakingCount - b.drugTakingCount,
      align: 'center',
    },
    {
      title: 'จำนวนการกดปุ่มติดตามยา',
      key: 'drugTrackingCount',
      render: (record) => {
        const timestampKey = record.timestampKey
        return record && record.drugTrackingCount ? <Text onClick={() => handleShowModal(true, (timestampKey && timestampKey.drugTrackingCountTimestamp) || [], 'จำนวนการกดปุ่มติดตามยา')}>{record.drugTrackingCount}</Text> : '-'
      },
      sorter: (a, b) => a.drugTrackingCount - b.drugTrackingCount,
      align: 'center',
    },
  ]

  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Spin className="text-center" />
        </div>
      ) : (
        <>
          <div className="w-100 p-4">
            {/* <div className="d-flex flex-column align-items-center mb-2">
              <Card>
                <h3 style={{ margin: '10px' }}>
                  จำนวน SMBG ทั้งหมด: <b>{smbgCount}</b>
                </h3>
                <h3 style={{ margin: '10px' }}>
                  จำนวนการเข้าใช้งานหน้า SMBG ทั้งหมด: <b>{smbgClickedCount}</b>
                </h3>
              </Card>
            </div> */}
            <Table columns={columns} dataSource={statistic} scroll={{ x: 'max-content' }} />
            <Modal
              title={modalTitle ? modalTitle : 'วันที่ใช้งานระบบ'}
              visible={showModal}
              footer={null}
              onCancel={() => {
                setTableData([])
                setModalStatus(false)
                setShowmodal(false)
                setModalTitle('')
              }}
            >
              <DateTable statisticData={tableData} />
            </Modal>
          </div>
        </>
      )}
    </>
  )
}

export default StatisticTab

// Pre-implement loging service

function init() {}

function log(error) {
  console.error(error) // eslint-disable-line
}

export default {
  init,
  log
}

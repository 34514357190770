import React, { Component } from 'react'
import Layout from '../Home'
import PatientListTable from '../../components/PatientListTable'
import { observer, inject } from 'mobx-react'
import { Spin } from 'antd'

@inject('UserInfo')
@inject('KycPatientImage')
@inject('MonitoringReport')
@observer
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      modalLoading: true,
      dataSource: [],
      image: '',
      userDetail: null,
      reports: [],
    }
  }

  componentDidMount() {
    this.mounted()
  }
  async mounted() {
    const { UserInfo } = this.props
    const response = await UserInfo.fetchUserInfos(true)
    this.setState({
      dataSource: response,
      isLoading: false,
    })
  }
  verifyStatus = async (id) => {
    const { UserInfo } = this.props
    const info = { verifyId: true }
    const response = await UserInfo.updateUserInfo(id, info)
    let updateItems = this.state.dataSource.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          verifyId: response.verifyId,
        }
      }
      return data
    })
    this.setState({
      dataSource: updateItems,
    })
  }

  checkUserDetail = async (id) => {
    const { UserInfo } = this.props
    const response = await UserInfo.checkUserDetail(id)
    this.setState({
      userDetail: response,
    })
  }

  checkMonitoringReport = async (userId) => {
    const { MonitoringReport } = this.props
    const response = await MonitoringReport.fetchData(userId)
    this.setState({
      reports: response,
    })
  }

  fetchUserImage = async (id) => {
    this.setState({
      modalLoading: true,
    })
    const { KycPatientImage } = this.props
    const response = await KycPatientImage.fetchPatientImage(id)
    this.setState({
      image: response && response.image ? response.image : response.cIdImage,
      modalLoading: false,
    })
  }

  render() {
    const { isLoading, modalLoading } = this.state
    return (
      <Layout
        {...this.props}
        render={(
          props, //eslint-disable-line
        ) => (
          <PatientListTable
            verifyStatus={this.verifyStatus}
            fetchUserImage={this.fetchUserImage}
            checkUserDetail={this.checkUserDetail}
            checkMonitoringReport={this.checkMonitoringReport}
            loading={isLoading}
            modalLoading={modalLoading}
            {...props}
            state={this.state}
            style={{ width: '100%' }}
          />
        )}
      />
    )
  }
}

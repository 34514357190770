import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
const CardBadge = styled.div`
  cursor: pointer;
  border: none;
  box-shadow: none;
  border-radius: 8px;
  &:hover {
    filter: brightness(120%);
  }
`
const MonitorCard = styled.div`
  @media (max-width: 1440px) {
    min-height: 135px;
  }
  @media (max-width: 1024px) {
    min-height: 163px;
  }
  @media (max-width: 768px) {
    min-height: 126px;
  }
`
const DashboardMonitorCards = ({ data, history }) => {
  return (
    <>
      <Row gutter={[16, 16]} justify="center" wrap={true}>
        {data &&
          data.length &&
          data.map((item, index) => {
            return (
              <Col xs={24} sm={24} md={12} lg={4} xl={4} key={item.text + index}>
                <CardBadge
                  style={{ backgroundColor: item.backgroundColor }}
                  onClick={() => {
                    history.push({ pathname: item.to, state: item.state })
                  }}
                >
                  <MonitorCard className="card-body pb-1">
                    <Row gutter={[6, 8]} justify="space-around" align="bottom" style={{ minHeight: '124px' }}>
                      <Col span={24} className="text-white align-self-start" style={{ fontSize: '18px' }}>
                        {item.text}
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col span={12} className="text-white d-flex align-items-center" style={{ fontSize: '24px', marginTop: '2px' }}>
                            <i className={item.icon}></i>
                          </Col>
                          <Col span={12} className="text-white d-flex align-items-end align-items-end text-uppercase font-weight-bold" style={{ fontSize: '32px' }} align="bottom">
                            {item.count}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </MonitorCard>
                </CardBadge>
              </Col>
            )
          })}
      </Row>
    </>
  )
}

export default DashboardMonitorCards

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import * as serviceWorker from './serviceWorker'
import 'antd/dist/antd.css'
import { initializeFirebase } from './services/push-notification'
import * as stores from './models'
import App from './containers/App'
import Theme from './theme'
import './index.css'
import ReduxStore from './store/'
import { Provider as ProviderRedux } from 'react-redux'

const GlobalStyle = createGlobalStyle`
  &&& {
  body {
    .ant-btn-primary {
      background-color: ${(props) => props.theme.primaryButton};
      border-color: ${(props) => props.theme.primaryButton};
    }
    .ant-btn-primary:hover {
      background-color: ${(props) => props.theme.secondaryButton};
      border-color: ${(props) => props.theme.secondaryButton};
    }
    .ant-pagination.ant-table-pagination {
      margin-right: 10px;
    }
    .ant-slider-track, .ant-slider:hover .ant-slider-track {
      background-color: ${(props) => props.theme.primaryButton};
    }
    .ant-slider-handle, .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open), .ant-slider:hover .ant-slider-handle.ant-tooltip-open, .ant-slider-handle:focus, .ant-input:focus, .ant-input-focused, .ant-input:hover {
      border-color: ${(props) => props.theme.primaryButton};
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${(props) => props.theme.primaryText};
      font-weight: 700;
    }
    .ant-tabs-tab:hover, .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
      color: ${(props) => props.theme.primaryButton};
    }
    .ant-layout-sider-trigger{
      background: ${(props) => props.theme.sideBarTrigger};
    }
  }
  .donationFormBox .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:last-child {
    color: red !important;
    border: 1px solid red !important;
  }
  .donationFormBox .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
    background-color: red;
  }
  }
  .patient-profile-edit-form .ant-descriptions-row > th, .ant-descriptions-row > td {
    padding-bottom: 10px;
    padding-left: 0px;
  }
  .patient-profile-edit-form .ant-descriptions-item-container {
    display: flex;
    align-items: center;
  }
  .top-header-bar {
    background: ${(props) => props.theme.topHeaderBar}
  }
`

initializeFirebase()
const Root = (
  <ProviderRedux store={ReduxStore}>
    <Provider {...stores}>
      <ThemeProvider theme={Theme}>
        <React.Fragment>
          <App />
          <GlobalStyle />
        </React.Fragment>
      </ThemeProvider>
    </Provider>
  </ProviderRedux>
)

ReactDOM.render(Root, document.getElementById('root'))

serviceWorker.unregister()

import { observable, action, computed, toJS } from 'mobx'
import http from '../../services/httpServiceHealthCare'

const apiEndpoint = '/UserReports'

class UserReport {
  @observable info = {
    data: [],
    error: null,
  }

  @action fetchUserReports = async () => {
    try {
      const { data } = await http.get(`${apiEndpoint}?filter[order]=timestamp desc`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @computed get getUserInfo() {
    return toJS(this.info.data)
  }
}

export default new UserReport()

import styled from 'styled-components'

export const EditorBox = styled(`div`)`
  padding: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  background: #fff;
  min-width: 100px;
  position: relative;
  border-radius: 0px 20px 0px 20px;
  // border: 2px solid #1b4b63;
  background-image: repeating-linear-gradient(60deg, #dbdbdb, #dbdbdb 5px, transparent 5px, transparent 9px, #dbdbdb 9px), repeating-linear-gradient(150deg, #dbdbdb, #dbdbdb 5px, transparent 5px, transparent 9px, #dbdbdb 9px),
    repeating-linear-gradient(240deg, #dbdbdb, #dbdbdb 5px, transparent 5px, transparent 9px, #dbdbdb 9px), repeating-linear-gradient(330deg, #dbdbdb, #dbdbdb 5px, transparent 5px, transparent 9px, #dbdbdb 9px);
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
`

export const FloatTitleBox = styled(`div`)`
  position: absolute;
  top: -10px;
  left: -2px;
`
export const FloatTitleText = styled(`div`)`
  background: ${(props) => props.backgroundColor ?? '#45c0bb'};
  padding: 0px 24px 0px 12px;
  color: ${(props) => (props.titleColor === 'dark' ? '#212121' : '#fff')};
  border-radius: 0px 20px 20px 0px;
  border-top: 2px solid #1b4b63;
  border-bottom: 2px solid #1b4b63;
  border-left: 2px solid #1b4b63;
  font-weight: bold;
`

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _groupBy from 'lodash/groupBy'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import _orderBy from 'lodash/orderBy'
import Channel from './Channel'

const Container = styled.div`
  overflow-y: auto;
  border-radius: 3px;
  border: 1px solid lightgray;
  box-shadow: 0 0 10px -1px;
  background-color: white;
`

@inject('MessengerHistories')
@observer
class ChannelList extends Component {
  static propTypes = {
    patientConsultId: PropTypes.string.isRequired,
    userInfo: PropTypes.object.isRequired,
    MessengerHistories: PropTypes.object.isRequired,
    onClickPatientChannel: PropTypes.func.isRequired
  }

  state = {
    sortedMessages: []
  }

  componentDidMount() {
    const { userInfo } = this.props
    this.fetchChannels(userInfo.id)
  }

  getSortedMessages = MessengerHistories => {
    const messages = MessengerHistories.getMessages
    const channels = _groupBy(messages, message => message.user._id) //eslint-disable-line
    const mapMessages = Object.values(channels).map(values => values[0])
    const sortedMessages = _orderBy(mapMessages, ['createdAt'], ['desc'])
    return sortedMessages
  }

  fetchChannels = async doctorId => {
    const { MessengerHistories, patientConsultId, onClickPatientChannel } = this.props
    const filter = JSON.stringify({
      where: {
        receiverId: doctorId
      },
      order: 'createdAt DESC'
    })
    await MessengerHistories.fetchMessages(filter)
    const sortedMessages = this.getSortedMessages(MessengerHistories)

    if (patientConsultId === 'all' && sortedMessages.length) {
      onClickPatientChannel(sortedMessages[0].senderId)()
    }

    this.setState({ sortedMessages })
  }

  render() {
    const { sortedMessages } = this.state
    const { patientConsultId } = this.props
    return (
      <Container>
        {sortedMessages.map(message => (
          <Channel
            isSelected={Number(patientConsultId) === message.senderId}
            {...this.props}
            message={message}
          />
        ))}
      </Container>
    )
  }
}
export default ChannelList

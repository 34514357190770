import React, { useEffect, useState } from 'react'
import Layout from '../Home'
import BloodDonorTable from '../../components/BloodDonorTable'
import { filterBookingsByType } from '../BloodDonationHistory/utils'
import { hooks } from './hooks'
const BloodDonorList = (props) => {
  const [bookings, setBookings] = useState([])
  const { history } = props
  const { events, params } = hooks()
  const donationOperationType = localStorage.getItem('donationOperationType') ?? 'default'
  useEffect(() => {
    events.dispatch(events.fetchBookings())
  }, [])

  useEffect(() => {
    if (params.donorBookingLists?.length) {
      // const filteredBookings = filterBookingsByType(params.donorBookingLists, donationOperationType)
      const filteredBookings = params.donorBookingLists
      setBookings(filteredBookings)
    }
  }, [params.donorBookingLists])

  return (
    <Layout
      history={history}
      render={() => {
        return (
          <div className="w-100">
            <BloodDonorTable dataSource={bookings} convertTime={events.convertTime} loading={params.isDonorBookingLoading} />
          </div>
        )
      }}
    />
  )
}

export default BloodDonorList

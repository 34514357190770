import styled from 'styled-components'
import TextBase from '../Text'
import { Input } from 'antd'
const { Search: SearchAnt } = Input
export const Text = styled(TextBase)`
  font-weight: bold;
`
export const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`
export const QrSection = styled.div``
export const DownloadSection = styled.p`
  text-align: center;
  padding-bottom: 10px;
`
export const ButtonSection = styled.button`
  border: 0;
  padding: 15px;
  color: #222;
  font-size: 14px;
  background: #eee;
  border-radius: export const(--space);
  border-radius: 20px;
`

export const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`

export const Container = styled.div`
  width: 100%;
  padding: 20px;
`

export const ChatBody = styled.div`
  height: 70vh;
  overflow-y: scroll;
`

export const ChatBox = styled.div`
  background-color: #f2f4f8;
  position: relative;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  margin-bottom: 8px;
`

export const Send = styled.p`
  color: #c8c4db;
`

import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Form, Input, Checkbox, Spin } from 'antd'
import { initialValues, UNDERYING_DISEASE, formTemplate } from './constant'
import styled from 'styled-components'
import InjuryTable from './InjuryTable'
import ToggleToken from '../../ToggleToken'
import Pain from './Pain'
import { get } from 'lodash'
const FormBox = styled(Col)`
  border: 1px solid black;
`
const index = ({ updateEmsForm, formDetail, formValues, setFormValues }) => {
  const [form] = Form.useForm()
  const [textDisabled, setTextDiabled] = useState({})
  const [initialForm, setInitialForm] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (Object.keys(formDetail).length !== 0) {
      setDefaultValue()
    }
  }, [formDetail])

  const setDefaultValue = () => {
    const { historyData } = formDetail
    const initial = {
      allergies: get(historyData, 'allergies', ''),
      currentMedication: get(historyData, 'currentMedication', ''),
      event: get(historyData, 'event', ''),
      lastOralIntake: get(historyData, 'lastOralIntake', ''),
      injuryPain: get(historyData, 'injuryPain', {}),
      underyingDisease: get(historyData, 'underyingDisease', []),
      signAndSymptom: get(historyData, 'signAndSymptom', ''),
      trauma: get(historyData, 'trauma', ''),
      pain: get(historyData, 'pain', formValues['pain']),
    }
    setFormValues(initial)
    setInitialForm(initial)
    handleFormDataDefaultValues(initial)
    setLoading(false)
  }

  const handleFormDataDefaultValues = (values) => {
    const data = [
      { name: 'signAndSymptom', value: values.signAndSymptom },
      { name: 'allergies', value: values.allergies },
      { name: 'underyingDisease', value: values.underyingDisease },
      { name: 'currentMedication', value: values.currentMedication },
      { name: 'lastOralIntake', value: values.lastOralIntake },
      { name: 'event', value: values.event },
      { name: 'trauma', value: values.trauma },
      { name: 'pain', value: values.pain },
    ]
    data.map((item) => {
      if (Array.isArray(item.value) && item.value[0] === 'no_data') {
        setTextDiabled((prevState) => ({ ...prevState, [item.name]: true }))
      } else {
        setFormValues({ [item.name]: item.value })
      }
    })
  }
  const handlePainValue = (field, value) => {
    setFormValues({ pain: { ...formValues['pain'], [field]: value } })
  }
  const onFinish = (value) => {
    console.log(value)
    updateEmsForm('historyData', { historyData: value })
  }

  return loading ? (
    <div className="w-100 d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
      <Spin></Spin>
    </div>
  ) : (
    <Form
      form={form}
      name="ems_section4"
      initialValues={initialForm ? initialForm : initialValues}
      layout="vertical"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 24 }}
      onFinish={(value) => {
        onFinish(value)
      }}
    >
      <Row style={{ border: '1px solid #ccc' }}>
        {formTemplate.map((template, templateIndex) => (
          <FormBox className="p-2" xs={24} md={template.cols} key={templateIndex}>
            <Form.Item name={template.value} label={<p style={{ fontSize: '21px', fontWeight: '500', paddingLeft: '10px' }}>{template.label}</p>} className="mb-0">
              {template.type === 'textarea' ? (
                <Input.TextArea
                  disabled={textDisabled[template.value]}
                  value={formValues[template.value]}
                  onChange={(event) => {
                    setFormValues({ [template.value]: event.target.value })
                  }}
                />
              ) : template.type === 'checkbox' && !textDisabled[template.value] ? (
                <ToggleToken
                  options={UNDERYING_DISEASE}
                  values={formValues[template.value]}
                  extraInputs={['HD', 'Other', 'CA']}
                  setValues={(value) => {
                    setFormValues({ [template.value]: value })
                  }}
                />
              ) : template.type === 'radio' ? (
                <ToggleToken
                  toStr={true}
                  max={1}
                  options={template.items}
                  values={formValues[template.value]}
                  setValues={(value) => {
                    setFormValues({ [template.value]: value })
                  }}
                />
              ) : (
                template.type === 'pain' && <Pain formValues={formValues} handlePainValue={handlePainValue} />
              )}
              <div className="w-100 d-flex justify-content-end align-items-end">
                {!['pain', 'trauma'].includes(template.value) && (
                  <Checkbox
                    checked={textDisabled[template.value]}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setFormValues({ [template.value]: template.type === 'checkbox' ? [] : '' })
                      } else {
                        setFormValues({ [template.value]: initialForm[template.value] })
                      }
                      setTextDiabled((prevState) => ({ ...prevState, [template.value]: event.target.checked }))
                    }}
                  >
                    N/A
                  </Checkbox>
                )}
              </div>
            </Form.Item>
          </FormBox>
        ))}
      </Row>
      <Form.Item name={'underyingDisease'}></Form.Item>
      <Form.Item name={'injuryPain'} label={''}>
        <InjuryTable setFormValues={setFormValues} formValues={formValues} initialForm={initialForm} />
      </Form.Item>
    </Form>
  )
}

export default index

/* eslint-disable no-empty-pattern */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { FileOutlined } from '@ant-design/icons';
import { Input, Modal, Button, Popconfirm, Tag, Row, Col, Spin } from 'antd';
import styled from 'styled-components'
import { Formik } from 'formik'
import Table from '../Table'
import TextBase from '../Text'

const { Search: SearchAnt } = Input
const Text = styled(TextBase)`
  font-weight: bold;
`
const Search = styled(SearchAnt)`
  i {
    color: ${props => props.theme.primaryButton};
  }
`

const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
const Container = styled.div`
  width: 100%;
  padding: 20px;
`

function VajiraKYCTable({ state, verifyStatus, fetchUserImage, checkUserDetail, ...rest }) {
  const [visibleProfile, setVisibleProfile] = useState(false)
  const [profileImage, setProfileImage] = useState()
  const [visiblePatientProfile, setVisiblePatientProfile] = useState()
  const [patientId, setPatientId] = useState()
  const setColor = status => {
    if (status) {
      return 'green'
    }
    return 'red'
  }
  const columns = [
    {
      title: 'หมายเลขผู้ป่วย',
      key: 'hnId',
      render: record => <Text>{record.hnId}</Text>,
      align: 'center'
    },
    {
      title: 'ชื่อ',
      key: 'date',
      render: record => (
        <Text
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setVisibleProfile(true)
            fetchUserImage(record.userId)
          }}
        >
          {record.firstname} {record.lastname}
        </Text>
      )
    },
    {
      title: 'เลขบัตรประชาชน',
      key: 'cId',
      render: record => <Text>{record.cId}</Text>
    },
    {
      title: 'เบอรโทรศัพท์',
      key: 'mobileNumber',
      render: record => <Text>{record.mobileNumber}</Text>
    },
    {
      title: 'คำสั่ง',
      key: 'action',
      render: record => !record.verifyId ? (
        <Button
          type="primary"
          onClick={() => {
            setPatientId(record.id)
            checkUserDetail(record.userId)
            setVisiblePatientProfile(true)
          }}
        >
          ยืนยันตัวตน
        </Button>
      ) : (
        <div />
      )
    },
    {
      title: 'สถานะ',
      key: 'verified',
      render: record => (
        <div>
          <Tag color={setColor(record.verifyId)}>
            {record.verifyId ? 'ได้รับการยืนยันตัวตน' : 'ยังไม่ได้รับการยืนยันตัวตน'}
          </Tag>
        </div>
      )
    }
  ]

  return (
    <Formik initialValues={{ filter: '' }}>
      {props => {
        const {} = props
        return (
          <Container>
            <HeaderWrapper>
              <FileOutlined />
              <Text bold>ระบบวชิร KYC</Text>
              <Search style={{ width: 200, marginLeft: 'auto' }} />
            </HeaderWrapper>
            <Table
              scroll={{ x: '100vw' }}
              columns={columns}
              dataSource={state.dataSource}
              {...rest}
            />
            <Modal
              title="รูปภาพ"
              visible={visibleProfile}
              style={{ textAlign: 'center' }}
              onCancel={() => setVisibleProfile(false)}
              footer={null}
            >
              {state.image ? (
                <img width={200} src={`data:image/jpeg;base64,${state.image}`} />
              ) : (
                <Text>No Image</Text>
              )}
            </Modal>
            <Modal
              title="รายละเอียดผู้ป่วย"
              visible={visiblePatientProfile}
              style={{ textAlign: 'center' }}
              onCancel={() => {
                setVisiblePatientProfile(false)
              }}
              onOk={() => {
                verifyStatus(patientId)
                setVisiblePatientProfile(false)
              }}
            >
              {state.userDetail ? (
                <div>
                  <Row>
                    <Col span={4} className="gutter-row text-left">
                      ชื่อผู้ป่วย
                    </Col>
                    <Col span={20} className="gutter-row text-left">
                      {`${state.userDetail.fullname.title} ${state.userDetail.fullname.firstName} ${state.userDetail.fullname.lastName}`}
                    </Col>
                  </Row>
                  {state.userDetail.drugAllergies.map(drugAllergy => (
                    <div>
                      <Row>
                        <Col span={4} className="gutter-row text-left">
                          ชื่อยา
                        </Col>
                        <Col span={20} className="gutter-row text-left">
                          {drugAllergy.genericname}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4} className="gutter-row text-left">
                          อาการ
                        </Col>
                        <Col span={20} className="gutter-row text-left">
                          {drugAllergy.symptom}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              ) : (
                <div>ไม่มีข้อมูล</div>
              )}
            </Modal>
          </Container>
        );
      }}
    </Formik>
  );
}
export default VajiraKYCTable

import React from 'react'
import { View, Document, Page, Image } from '@react-pdf/renderer'
import styles, { Row } from './styles'
import HeaderSection from './components/HeaderSection'
import PatientTable from './components/PatientTable'
import DonationInfo from './components/DonationInfo'
import StaffName from './components/StaffName'

export default function EmsPdfForm({ data, convertTime, convertHn }) {
  return (
    <Document>
      {data.map((item, index) => {
        return (
          <Page size="A4" key={index}>
            <View key="container" style={styles.mainContainer}>
              <View key="content">
                <HeaderSection key="header" data={item} convertTime={convertTime} />
                <PatientTable data={item} patientInfo={item.patientInfo} convertHn={convertHn} />
                <DonationInfo data={item} />
                <StaffName data={item} />
              </View>
            </View>
          </Page>
        )
      })}
    </Document>
  )
}

/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react'
import Layout from '../Home'
import ListTable from '../../components/ListTable'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import jwtDecode from 'jwt-decode'
import { Tabs } from 'antd'

const { TabPane } = Tabs
const Container = styled.div`
  width: 100%;
  padding: 20px;
`
@inject('Booking')
@observer
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataSource: [],
      dataResponse: [],
      key: 'doctor',
      currentTab: 'doctor',
      hash: '',
      time: '15:00',
      roles: '',
    }
  }

  componentDidMount() {
    const { roles } = jwtDecode(localStorage.getItem('token'))
    const { history } = this.props
    this.setState({
      hash: history,
      roles,
    })
    if (history.location.hash.split('#')[1]) {
      this.setState({
        currentTab: history.location.hash.split('#')[1]
      })
    }
    this.getBooking()
    if (roles.includes('callCenter')) {
      this.updateTimer(15)
    }
  }

  async getBooking() {
    const {Booking} = this.props
    const response = await Booking.fetchBooking()
    this.setState({
      dataResponse: response.map(res => ({
        ...res,
        key: res.id
      })),
    })
    this.callback(this.state.currentTab)
  }

  updateTimer = num => {
    var minutes = 60 * num
    this.startTimer(minutes)
  }

  startTimer = duration => {
    var timer = duration,
      minutes,
      seconds
    setInterval(() => {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10)
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      let showTime = minutes + ':' + seconds
      this.setState({
        time: showTime
      })
      if (--timer < 0) {
        timer = duration
        this.getBooking()
      }
    }, 1000)
  }

  callback(key) {
    if (key === 'doctor') {
      this.setState({
        dataSource: this.state.dataResponse.filter(data => {
          return [
            'DOCTOR_CONFIRM',
            'DOCTOR_PENDING',
            'DOCTOR_DECLINE',
            'DOCTOR_PENDING_RX',
            'DOCTOR_COMPLETED'
          ].includes(data.status)
        })
      })
    } else if (key === 'pharmacy') {
      this.setState({
        dataSource: this.state.dataResponse.filter(data => {
          return [
            'PHARMACY_PENDING_BOOKING',
            'PHARMACY_CONFIRM_BOOKING',
            'PHARMACY_DECLINE_BOOKING',
            'PHARMACY_COMPLETED'
          ].includes(data.status)
        })
      })
    }
  }

  render() {
    return (
      <Layout
        {...this.props}
        render={props => (
          <Container>
            <Tabs
              defaultActiveKey={this.state.currentTab}
              onChange={key => {
                this.setState({ key })
                this.callback(key)
                this.props.history.replace({
                  hash: key
                })
              }}
            >
              <TabPane tab="หมอ" key="doctor">
                <ListTable
                  {...props}
                  state={this.state}
                  refreshData={()=>this.getBooking}
                  style={{ width: '100%' }}
                />
              </TabPane>
              <TabPane tab="เภสัช" key="pharmacy">
                <ListTable
                  {...props}
                  state={this.state}
                  refreshData={()=>this.getBooking}
                  style={{ width: '100%' }}
                />
              </TabPane>
            </Tabs>
          </Container>
        )}
      />
    )
  }
}

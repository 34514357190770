import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Switch, Button, Input, Typography } from 'antd'

const { Title } = Typography

function RiskModal(props) {
  const { visible, setVisible, setRisk, updateRisk, riskData = false } = props
  const [useBlood, setUseBlood] = useState(true)
  const [form, setForm] = useState({
    age: 0,
    sex: 1,
    smoke: 1,
    dm: 1,
    sbp: 0,
    tc: 0,
    ldl: 0,
    hdl: 0,
    wc: 0,
    bhd: 0,
  })

  useEffect(() => {
    setForm({
      age: riskData.age ? riskData.age : 0,
      sex: riskData.sex || riskData.sex === 0 ? riskData.sex : 1,
      smoke: riskData.smoke || riskData.smoke === 0 ? riskData.smoke : 1,
      dm: riskData.dm || riskData.dm === 0 ? riskData.dm : 1,
      sbp: riskData.sbp ? riskData.sbp : 0,
      tc: riskData.tc ? riskData.tc : 0,
      ldl: riskData.ldl ? riskData.ldl : 0,
      hdl: riskData.hdl ? riskData.hdl : 0,
      wc: riskData.wc ? riskData.wc : 0,
      bhd: riskData.bhd ? riskData.bhd : 0,
    })
  }, [riskData])

  const calculateRisk = () => {
    const { age } = form
    const { sex } = form
    const { smoke } = form
    const { dm } = form
    const { sbp } = form
    let { tc } = form
    let { ldl } = form
    let { hdl } = form
    let wc = parseInt(parseFloat(form.wc) * 2.5)
    const { bhd } = form
    let whr = wc / bhd
    if (useBlood) {
      whr = 0
      wc = 0
    } else {
      tc = 0
      ldl = 0
      hdl = 0
    }
    let full_score = 0
    let compare_score = 0
    let predicted_risk = 0
    let compare_risk = 0
    let compare_whr = 0.52667
    let compare_wc = 79
    let compare_sbp = 120
    let compare_hdl = 44
    const sur_root = 0.978296
    if (sex == 0) {
      compare_hdl = 49
    }
    if (sex == 1 && age > 60) {
      compare_sbp = 132
    }
    if (sex == 0 && age <= 60) {
      compare_sbp = 115
    }
    if (sex == 0 && age > 60) {
      compare_sbp = 130
    }
    if (sex == 1) {
      compare_whr = 0.58125
      compare_wc = 93
    }
    if (age > 1 && sbp > 50) {
      if (ldl > 0) {
        if (hdl > 0) {
          full_score = 0.08305 * age + 0.24893 * sex + 0.02164 * sbp + 0.65224 * dm + 0.00243 * ldl + -0.01965 * hdl + 0.43868 * smoke
          predicted_risk = 1 - Math.pow(sur_root, Math.exp(full_score - 5.9826))
          compare_score = 0.08305 * age + 0.24893 * sex + 0.02164 * compare_sbp + 0.00243 * 130 + -0.01965 * compare_hdl
          compare_risk = 1 - Math.pow(sur_root, Math.exp(compare_score - 5.9826))
        } else {
          full_score = 0.08169 * age + 0.35156 * sex + 0.02084 * sbp + 0.65052 * dm + 0.02094 * ldl + 0.45639 * smoke
          predicted_risk = 1 - Math.pow(sur_root, Math.exp(full_score - 6.99911))
          compare_score = 0.08169 * age + 0.35156 * sex + 0.02084 * compare_sbp + 0.02094 * 130
          compare_risk = 1 - Math.pow(sur_root, Math.exp(compare_score - 6.99911))
        }
      } else if (tc > 0) {
        if (hdl > 0) {
          full_score = 0.083 * age + 0.28094 * sex + 0.02111 * sbp + 0.69005 * dm + 0.00214 * tc + -0.02148 * hdl + 0.40068 * smoke
          predicted_risk = 1 - Math.pow(sur_root, Math.exp(full_score - 6.00168))
          compare_score = 0.083 * age + 0.28094 * sex + 0.02111 * compare_sbp + 0.00214 * 200 + -0.02148 * compare_hdl
          compare_risk = 1 - Math.pow(sur_root, Math.exp(compare_score - 6.00168))
        } else {
          full_score = 0.08183 * age + 0.39499 * sex + 0.02084 * sbp + 0.69974 * dm + 0.00212 * tc + 0.41916 * smoke
          predicted_risk = 1 - Math.pow(sur_root, Math.exp(full_score - 7.04423))
          compare_score = 0.08183 * age + 0.39499 * sex + 0.02084 * compare_sbp + 0.00212 * 200
          compare_risk = 1 - Math.pow(sur_root, Math.exp(compare_score - 7.04423))
        }
      } else if (whr > 0) {
        // ของ อ.ปริญ มีสูตรนี้สูตรเดียว
        full_score = 0.079 * age + 0.128 * sex + 0.019350987 * sbp + 0.58454 * dm + 3.512566 * whr + 0.459 * smoke
        predicted_risk = 1 - Math.pow(sur_root, Math.exp(full_score - 7.720484))
        compare_score = 0.079 * age + 0.128 * sex + 0.019350987 * compare_sbp + 3.512566 * compare_whr
        compare_risk = 1 - Math.pow(sur_root, Math.exp(compare_score - 7.720484))
      } else if (wc > 0) {
        full_score = 0.08372 * age + 0.05988 * sex + 0.02034 * sbp + 0.59953 * dm + 0.01283 * wc + 0.459 * smoke
        predicted_risk = 1 - Math.pow(sur_root, Math.exp(full_score - 7.31047))
        compare_score = 0.08372 * age + 0.05988 * sex + 0.02034 * compare_sbp + 0.01283 * compare_wc
        compare_risk = 1 - Math.pow(sur_root, Math.exp(compare_score - 7.31047))
      } else {
      }
    }
    if (predicted_risk > 0.3) {
      predicted_risk = 0.3
    }
    const risk = new Array(full_score, predicted_risk, compare_score, compare_risk)
    const convertRisk = (risk[1] * 100).toFixed(2)
    setRisk(convertRisk)
    const thaiCVRick = {
      result: convertRisk,
      data: form,
    }
    updateRisk(thaiCVRick)
    setVisible(false)
  }
  return (
    <Modal
      visible={visible}
      title="Thai CV risk score"
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={
        <Row justify="space-between">
          <Col style={{ color: 'red' }}>* หากผลเลือดของท่านมีค่ามากกว่าที่กำหนดให้เลือกค่าสูงสุด</Col>
          <Col>
            <Button onClick={() => setVisible(false)}>ยกเลิก</Button>
            <Button type="primary" onClick={() => calculateRisk()}>
              แสดงผล
            </Button>
          </Col>
        </Row>
      }
      width="1000px"
    >
      <Title level={4} className="text-center">
        ข้อมูลที่จำเป็น (ต้องกรอกทุกข้อ)
      </Title>
      <Row>
        <Col style={{ padding: '10px' }} span={10}>
          อายุ
        </Col>
        <Col style={{ padding: '10px' }} span={14}>
          <Input onChange={(e) => setForm({ ...form, age: Number(e.target.value) })} value={form.age} />
        </Col>
        <Col style={{ padding: '10px' }} span={10}>
          เพศ
        </Col>
        <Col style={{ padding: '10px' }} span={14}>
          <Switch checkedChildren="ชาย" unCheckedChildren="หญิง" onChange={(condition) => setForm({ ...form, sex: condition ? 1 : 0 })} defaultChecked={form.sex === 0 ? false : true} />
        </Col>
        <Col style={{ padding: '10px' }} span={10}>
          สูบบุหรี่อยู่
        </Col>
        <Col style={{ padding: '10px' }} span={14}>
          <Switch checkedChildren="ใช่" unCheckedChildren="ไม่" onChange={(condition) => setForm({ ...form, smoke: condition ? 1 : 0 })} defaultChecked={form.smoke === 0 ? false : true} />
        </Col>
        <Col style={{ padding: '10px' }} span={10}>
          เป็นโรคเบาหวาน
        </Col>
        <Col style={{ padding: '10px' }} span={14}>
          <Switch checkedChildren="ใช่" unCheckedChildren="ไม่" onChange={(condition) => setForm({ ...form, dm: condition ? 1 : 0 })} defaultChecked={form.dm === 0 ? false : true} />
        </Col>
        <Col style={{ padding: '10px' }} span={10}>
          ความดันโลหิตตัวบน
        </Col>
        <Col style={{ padding: '10px' }} span={14}>
          <Input onChange={(e) => setForm({ ...form, sbp: Number(e.target.value) })} value={form.sbp} />
        </Col>
        <Col style={{ padding: '20px' }} span={24}>
          <Switch checkedChildren="ใช้ผลเลือด" unCheckedChildren="ไม่ใช้ผลเลือด" defaultChecked={useBlood} onChange={(condition) => setUseBlood(condition)} />
        </Col>
        <Col style={{ padding: '10px' }} span={10}>
          โคเรสเตอรอลรวม (Cholesterol)
        </Col>
        <Col style={{ padding: '10px' }} span={14}>
          <Input disabled={!useBlood} onChange={(e) => setForm({ ...form, tc: Number(e.target.value) })} value={form.tc} />
        </Col>
        <Col style={{ padding: '10px' }} span={10}>
          โคเรสเตอรอลเลว (LDL)
        </Col>
        <Col style={{ padding: '10px' }} span={14}>
          <Input disabled={!useBlood} onChange={(e) => setForm({ ...form, ldl: Number(e.target.value) })} value={form.ldl} />
        </Col>
        <Col style={{ padding: '10px' }} span={10}>
          โคเรสเตอรอลดี (HDL)
        </Col>
        <Col style={{ padding: '10px' }} span={14}>
          <Input disabled={!useBlood} onChange={(e) => setForm({ ...form, hdl: Number(e.target.value) })} value={form.hdl} />
        </Col>
        <Col style={{ padding: '10px' }} span={10}>
          วัดรอบเอว
        </Col>
        <Col style={{ padding: '10px' }} span={14}>
          <Input disabled={useBlood} onChange={(e) => setForm({ ...form, wc: Number(e.target.value) })} value={form.wc} />
        </Col>
        <Col style={{ padding: '10px' }} span={10}>
          ส่วนสูง
        </Col>
        <Col style={{ padding: '10px' }} span={14}>
          <Input disabled={useBlood} onChange={(e) => setForm({ ...form, bhd: Number(e.target.value) })} value={form.bhd} />
        </Col>
      </Row>
    </Modal>
  )
}

export default RiskModal

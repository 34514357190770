
import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import Layout from '../Home'
import DoctorTypeForm from '../../components/DoctorTypeForm'


@inject('DoctorTypes')
@observer
class EditDepartment extends Component {
  state = {
    hasSubmitted: false,
    data: {},
  }

  async componentDidMount() {
    const { DoctorTypes, match: { params } } = this.props
    const data = await DoctorTypes.getDoctorTypeById(params.doctorTypeId)
    this.setState({ data })
  }

  handleSubmit = async (values) => {
    const { DoctorTypes, history, match: { params } } = this.props
    await DoctorTypes.updateDoctorType(values, params.doctorTypeId)
    history.goBack()
  }

  render() {
    const { hasSubmitted, data } = this.state

    return (
      <Layout
        {...this.props}
        render={(props) => (
          <DoctorTypeForm
            onSubmit={this.handleSubmit}
            hasSubmitted={hasSubmitted}
            data={data}
            title="Edit Doctor Type"
            {...props}
            style={{ width: '100%' }}
          />
        )}
      />
    )
  }
}

EditDepartment.propTypes = {
}

EditDepartment.defaultProps = {
}

export default EditDepartment

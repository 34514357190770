import styled from 'styled-components'
import TextBase from '../../components/Text'

export const Card = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 10px;
  text-align: center;
  box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.1);
`
export const TitleText = styled(TextBase)`
  font-weight: bold;
  font-size: 24px;
  color: #1b4b63;
`
export const Text = styled(TextBase)`
  font-weight: bold;
  font-size: 20px;
  color: #000000;
`

import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'
import { RowFlexCenter, ColFlexCenter } from '../../containers/BloodDonationForm/style'
const index = ({ data, setData }) => {
  return (
    <>
      <RowFlexCenter>
        <ColFlexCenter span={24}>
          <BarcodeScannerComponent
            width="100%"
            height={500}
            onUpdate={(err, result) => {
              if (result) {
                setData(result.text)
                console.log(result, 'res')
              } else setData('')
            }}
          />
        </ColFlexCenter>
        <ColFlexCenter span={24} className="mt-2">
          <p>{data}</p>
        </ColFlexCenter>
      </RowFlexCenter>
    </>
  )
}

export default index

import React, { Component } from 'react'
import { Modal, Row, Col, InputNumber, message, Button } from 'antd'
import { observer, inject } from 'mobx-react'

@inject('Prescription')
@observer
export default class PrescriptionTrackingNumber extends Component {
  constructor(props) {
    super(props)
    this.state = {
      quantity: 0
    }
  }

  addTrackingNumber = async () => {
    const { Prescription, prescriptionId, isSuccess } = this.props
    const response = await Prescription.addTrackingNumber(prescriptionId, this.state.quantity)
    message.success('สำเร็จ')
    isSuccess()
  }

  render() {
    const { visible, setVisible } = this.props
    const { quantity } = this.state
    return (
      <Modal
        visible={visible}
        title="EMS"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={
          <div>
            <Button
              onClick={() => {
                setVisible(false)
              }}
              type="danger"
            >
              ยกเลิก
            </Button>
            <Button
              type="primary"
              onClick={() => {
                this.addTrackingNumber()
                setVisible(false)
              }}
            >
              บันทึก
            </Button>
          </div>
        }
        width="60vw"
      >
        <Row>
          <Col span={12}>กรุณากรอกจำนวน EMS</Col>
          <Col span={12}>
            <InputNumber
              min={0}
              defaultValue={0}
              value={quantity}
              onChange={number => this.setState({ quantity: number })}
            />
          </Col>
        </Row>
      </Modal>
    )
  }
}

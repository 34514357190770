import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import httpOma from '../../services/httpServiceHealthCare'
import { fetchDonorFormById, updateDonorForm } from '../../store/bloodFormSlice'
import { fetchBooking } from '../../store/bloodBookingSlice'
import { message } from 'antd'
const hiePatientInfo = '/UserInfos/hiePatientInfo'
const hooks = () => {
  const [isPatientLoading, setIsPatientLoading] = useState(false)
  const [isEditLogLoading, setIsEditLogLoading] = useState(false)
  const [patientInfo, setPatientInfo] = useState(null)

  const dispatch = useDispatch()
  const [donorForm, isFormDataLoading, errorMessage, donorBooking] = useSelector((state) => {
    const { donorLatestForm, isFormDataLoading, errorMessage } = state.bloodDonationForm
    const { donorBooking } = state.bloodDonationBooking

    return [donorLatestForm, isFormDataLoading, errorMessage, donorBooking]
  })

  const getPatientInfo = async (cid) => {
    if (cid && cid.length === 13) {
      setIsPatientLoading(true)
      const response = await httpOma.get(`${hiePatientInfo}?cId=${cid}`)
      if (response?.status <= 200 && response?.status >= 300) {
        setIsPatientLoading(false)
        message.error('เกิดข้อผิดพลาดในการรับข้อมูลประวัติบุคคล')
      } else {
        setPatientInfo(response.data)
        setIsPatientLoading(false)
      }
    } else if (cid) {
      setPatientInfo(null)
      message.error('เลขบัตรประชาชนไม่ถูกต้อง')
    }
  }

  const addFormEditLog = async (staffName, reasonToEdit, bloodDonationFormId) => {
    setIsEditLogLoading(true)
    const response = await httpOma.post(`/BloodDonationFormEditLogs`, {
      staffName,
      reasonToEdit,
      bloodDonationFormId,
    })
    if (response?.status <= 200 && response?.status >= 300) {
      setIsPatientLoading(false)
      message.error('เกิดข้อผิดพลาดในการเก็บประวัติการอัพเดท')
    }
    setIsEditLogLoading(false)
  }

  const validateTextFormat = (str, isNumberOnly = false) => {
    //no thai text
    if (!str.length) {
      return true
    }
    const regex = /^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/
    const regexNumberOnly = /^(0|[1-9][0-9]*)$/
    return isNumberOnly ? regexNumberOnly.test(str) : regex.test(str)
  }

  const convertHn = (hn) => {
    const hnString = hn?.toString()
    const second = hnString.substring(0, 2)
    const past = hnString.substring(2).split('')
    let pass = 0
    const final = past.map((string) => {
      if (pass || string !== '0') {
        pass = 1
        return string
      }
    })
    return final.join('').concat('/', second)
  }

  return {
    events: {
      getPatientInfo,
      dispatch,
      setPatientInfo,
      validateTextFormat,
      fetchBooking,
      fetchDonorFormById,
      updateDonorForm,
      convertHn,
      addFormEditLog,
    },
    params: {
      isPatientLoading,
      isFormDataLoading,
      isEditLogLoading,
      donorBooking,
      patientInfo,
      donorForm,
      errorMessage,
    },
  }
}

export { hooks }

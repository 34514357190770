export const RESPONSE_DATA = {
  areaOfResponsibility: '',
  idcCode: '',
  injuryByOperation: '',
  medicalEmergencyStaff: [],
  notifiedSymptoms: '',
  operationDateTime: '',
  operationNo: '',
  paramedic: [],
  policeStationArea: '',
  rcCode: '',
  sceneDescription: '',
  sceneSite: '',
  vehicle: '',
  vehicleType: '',
  paramedicDoctor: '',
  emergencyStaff: '',
  travelLog: {
    time: {
      erawanReceiveCase: '',
      vajiraReceiveCase: '',
      ordered: '',
      leaveBase: '',
      arrivedScene: '',
      leaveScene: '',
      arrivedHospital: '',
      arrivedBase: '',
    },
    totalTime: {
      activateTime: '',
      responseTime: '',
      onSceneTime: '',
      leaveSceneToHospitalTime: '',
      hospitalToBaseTime: '',
    },
    kilometers: {
      beforeLeaveBase: '',
      beforeLeaveScene: '',
      arrivedHospital: '',
      arrivedBase: '',
    },
    distance: {
      fromBaseToScene: '',
      fromSceneToHospital: '',
      fromHospitalToBase: '',
    },
    atReason: '',
    rtReason: '',
    ostReason: '',
  },
}

export const DEMOGRAPHIC_DATA = {
  titleName: '',
  firstName: '',
  lastName: '',
  age: '',
  gender: '',
  weight: '',
  cid: '',
  nationality: '',
  weight: '',
  treatment: '',
}

export const HISTORY_DATA = {
  allergies: '',
  currentMedication: '',
  event: '',
  lastOralIntake: '',
  injuryPain: {},
  underyingDisease: [],
  signAndSymptom: '',
  trauma: '',
  pain: { onSet: '', provoke: '', quality: [], radiate: '', severity: '', time: '' },
}

export const ASSESSMENT_DATA = {
  timeLog: [
    {
      time: '',
      vital: {
        bt: '',
        sbp: '',
        dbp: '',
        pr: '',
        rr: '',
        spo2: '',
      },
      neuro: {
        e: '',
        v: '',
        m: '',
      },
      pupils: {
        lt: '',
        ltRtl: 'reactive',
        rt: '',
        rtRtl: 'reactive',
      },
      dtx: '',
    },
  ],
  record: '',
  respiratory: {
    airway: [],
    chestMovement: [],
    percussion: [],
    breathSoundL: [],
    breathSoundR: [],
  },
  circulation: {
    pulse: [],
    capillaryRefill: [],
    skinTemp: [],
    moisture: [],
    color: [],
  },
}

export const IMPRESSION_DATA = {
  obviousDeath: {},
  cardiacArrest: {},
  emsImpression: [],
  emsTreatment: {},
}

export const TRANSFER_DATA = {
  stability: '',
  onSceneTriage: '',
  destination: {
    hospital: '',
    type: '',
  },
  reasons: [],
  reporter: {
    name: '',
    position: '',
  },
  assessor: {
    name: '',
    signature: '',
    position: '',
  },
  assessment: {
    hn: '',
    diagnosis: '',
    erTriageLabel: '',
    breathingCare: [],
    respiratoryCare: [],
    bloodCirculationCare: [],
    boneCast: [],
    bleedingCare: [],
  },
}

export const TABS_NAME = {
  responseData: 'บันทึกข้อมูลการออกปฏิบัติการ',
  demographicData: 'บันทึกข้อมูลผู้ป่วย',
  assessmentData: 'บันทึกข้อมูลการประเมินและสัญญาณชีพ',
  historyData: 'บันทึกข้อมูลการซักประวัติและตรวจร่างกาย',
  impressionData: 'บันทึกข้อมูล EMS Impression and Treatment',
  transferData: 'บันทึกข้อมูล Transfer to Definite Care',
}

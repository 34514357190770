import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input } from 'antd'
import { initiaRespiratoryValues, respiratoryFormTemplate } from './constant'
import ToggleToken from '../../ToggleToken'
import styled from 'styled-components'
import TextInput from '../../TextInput'
const Text = styled('p')`
  font-size: 18px;
  padding-right: 10px;
  font-weight: bold;
`
const FormBox = styled(Row)`
  padding: 10px;
`

const Respiratory = ({ setFormValue, initialValues }) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({ endotrachealIntubation: '' })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (initialValues && initialValues.respiratory) {
      setFormData((prev) => ({ ...prev, ...initialValues.respiratory }))
      form.setFieldsValue(initialValues.respiratory)
    }
    setLoading(false)
  }, [initialValues])

  const handleValue = (field, value) => {
    form.setFieldsValue({ [field]: value })
    const allData = form.getFieldsValue(true)
    setFormValue({ ['respiratory']: allData })
  }

  return loading ? (
    <></>
  ) : (
    <Form form={form} initialValues={initiaRespiratoryValues} name="cardiacArrest" className="additional-form-section">
      <h2>Respiratory</h2>
      <div className="additional-form-subsection">
        {respiratoryFormTemplate.map((data) => (
          <Form.Item name={data.value}>
            {data.type === 'checkbox' ? (
              <ToggleToken
                toStr={true}
                max={1}
                title={data.label}
                options={data.items}
                values={formData[data.value]}
                setValues={(value) => {
                  setFormData((prevState) => ({ ...prevState, [data.value]: value }))
                  handleValue(data.value, value)
                }}
              />
            ) : (
              data.type === 'text' && (
                <TextInput
                  label={data.label}
                  value={formData[data.value]}
                  onChange={(event) => {
                    setFormData((prevState) => ({ ...prevState, [data.value]: event.target.value }))
                    handleValue(data.value, event.target.value)
                  }}
                />
              )
            )}
          </Form.Item>
        ))}
      </div>
    </Form>
  )
}

export default Respiratory

/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react'
import Layout from '../Home'
import UserListTable from '../../components/UserListTable'
import { observer, inject } from 'mobx-react'
import { message } from 'antd'
import jwtDecode from 'jwt-decode'

@inject('UserInfo')
@inject('KycPatientImage')
@inject('ResetPin')
@inject('AppUser')
@inject('AppUserIdentity')
@inject('UserFcmToken')
@inject('FCM')
@observer
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataSource: [],
      images: [],
      userDetail: null,
      loading: true,
      imageLoading: true,
      time: '15:00',
      roles: '',
      hash: '',
      currentTab: 'allUserlist',
    }
  }
  intervalID = 0
  componentDidMount() {
    const { roles } = jwtDecode(localStorage.getItem('token'))
    const { history } = this.props
    this.setState({
      roles,
      hash: history,
      currentTab: history.location.hash.split('#')[1],
    })
    this.mounted()
    if (roles.includes('callCenter')) {
      this.updateTimer(15)
    }
  }

  async mounted() {
    const { UserInfo } = this.props
    const response = await UserInfo.fetchUserInfos()
    this.setState({
      dataSource: response,
      loading: false,
    })
  }

  updateTimer = (num) => {
    clearInterval(this.intervalID)
    var minutes = 60 * num
    this.startTimer(minutes)
  }

  startTimer = (duration) => {
    var timer = duration,
      minutes,
      seconds
    this.intervalID = setInterval(() => {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10)
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      let showTime = minutes + ':' + seconds
      this.setState({
        time: showTime,
      })
      if (--timer < 0) {
        timer = duration
        this.mounted()
      }
    }, 1000)
  }

  verifyStatus = async (id, condition) => {
    const { UserInfo } = this.props
    const { userDetail } = this.state
    const hn = userDetail && userDetail.hn[0] ? userDetail.hn[0].hn : null
    const info = { verifyId: condition, hnId: hn }
    const response = await UserInfo.updateUserInfo(id, info)
    if (response) {
      message.success('ทำการเปลี่ยนสถานะเรียบร้อย')
      let updateItems = this.state.dataSource.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            verifyId: response.verifyId,
          }
        }
        return data
      })
      this.setState({
        dataSource: updateItems,
      })
    }
  }

  checkUserDetail = async (id) => {
    const { UserInfo } = this.props
    const response = await UserInfo.checkUserDetail(id)
    this.setState({
      userDetail: response,
    })
  }

  fetchUserImage = async (id) => {
    this.setState({
      imageLoading: true,
    })
    const { KycPatientImage } = this.props
    const response = await KycPatientImage.fetchPatientImage(id)
    if (response) {
      this.setState({
        images: [response.image, response.cIdImage],
      })
    } else {
      this.setState({
        images: [],
      })
    }
    this.setState({
      imageLoading: false,
    })
  }

  resetPin = async (id) => {
    const { ResetPin } = this.props
    const response = await ResetPin.createPin(id)
    if (response && response.status === 'SUCCESS') {
      message.success('รีเซ็ตรหัสผ่านเสร็จสิ้น')
      let updateItems = this.state.dataSource.map((data) => {
        if (data.userId === id) {
          return {
            ...data,
            pin: response.pin,
          }
        }
        return data
      })
      this.setState({
        dataSource: updateItems,
      })
    }
  }

  deleteAccount = async (userId, id) => {
    const { UserInfo, AppUser } = this.props
    const resAppUser = await AppUser.deleteUser(userId)
    const resUserInfo = await UserInfo.deleteUserInfo(id)
    if (resUserInfo && resAppUser) {
      message.success('ลบชื่อผู้ใช้เสร็จสิ้น')
      let updateItems = this.state.dataSource.filter((data) => data.id !== id)
      this.setState({
        dataSource: updateItems,
      })
    }
  }

  fetchUserFcmToken = async (id, message, time, screen) => {
    const { UserFcmToken, FCM } = this.props
    if (id) {
      const response = await UserFcmToken.fetchUserFcmToken(id)
      if (response && response.length) {
        response.forEach(async (res) => {
          let notification = {
            body: message,
            title: 'แจ้งเตือน',
            icon: 'ic_launcher',
            color: '#f1c40f',
            sound: 'default',
          }
          let data = {
            bookingTime: time,
            screen: screen,
          }
          await FCM.sendMessage(res.fcmToken, notification, data)
        })
      }
    }
  }

  triggerUpdateDataWithPatientInfo = async (everOmaId) => {
    const { UserInfo } = this.props
    message.loading({ content: `กำลังอัปเดตข้อมูลผู้ป่วยไอดี ${everOmaId}`, key: everOmaId, duration: 300 })
    await UserInfo.triggerUpdateDataWithPatientInfo(everOmaId)
    message.success({ content: `อัปเดตข้อมูลผู้ป่วยไอดี ${everOmaId}`, key: everOmaId, duration: 2 })
  }

  cancelDeleteAccount = async (id) => {
    const { UserInfo } = this.props
    const response = await UserInfo.cancelDeleteAccount(id)
    if (response) {
      message.success('ยกเลิกการลบบัญชีผู้ใช้เสร็จสิ้น')
      let updateItems = this.state.dataSource.filter((data) => data.id !== id)
      this.setState({
        dataSource: updateItems,
      })
    }
  }
  render() {
    return (
      <Layout
        {...this.props}
        render={(
          props, //eslint-disable-line
        ) => (
          <UserListTable
            verifyStatus={this.verifyStatus}
            fetchUserImage={this.fetchUserImage}
            checkUserDetail={this.checkUserDetail}
            resetPin={this.resetPin}
            refreshData={() => this.mounted()}
            deleteAccount={this.deleteAccount}
            loading={this.state.loading}
            imageLoading={this.state.imageLoading}
            fetchUserFcmToken={this.fetchUserFcmToken}
            triggerUpdateDataWithPatientInfo={this.triggerUpdateDataWithPatientInfo}
            setCurrentTab={(tab) => this.setState({ currentTab: tab })}
            currentTab={this.state.currentTab}
            cancelDeleteAccount={this.cancelDeleteAccount}
            history={this.props.history}
            {...props}
            state={this.state}
            style={{ width: '100%' }}
          />
        )}
      />
    )
  }
}

import React, { useState, useEffect } from 'react'
import { Modal, Button, InputNumber, Row, Col, message } from 'antd'
import { sortBy } from 'lodash'
import AvailableTimes from 'react-available-times'
const { confirm } = Modal
const index = ({ getAvailableTime, getSlotDataByTimeRange, addAvailableTime, deleteAvailableTimeById, updateAvailableTime }) => {
  const [initialSelections, setInitialSelections] = useState([]) //เวลาอย่างเดียว [start,end]
  const [selections, setSelections] = useState([])
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [nbOfDonor, setNbOfDonor] = useState(20)
  const [newSlot, setNewSlot] = useState(null)
  const [timesWithData, setTimesWithData] = useState([]) //Object ทั้งหมด เวลา+qty
  useEffect(() => {
    initialTimeUpdate()
  }, [])

  const initialTimeUpdate = async () => {
    setIsTableLoading(true)
    const availableTimes = await getAvailableTime()
    let timeOnly = []
    let timeData = []
    availableTimes.map((item) => {
      timeOnly.push(item.time)
      timeData.push(item)
    })
    setTimesWithData(
      sortBy(timeData, [
        function(o) {
          return o.time[0]
        },
      ]),
    )
    handleTimeSelectionFormat(timeOnly)
    setIsTableLoading(false)
  }

  const updateTimes = (response, id) => {
    let updateItems = timesWithData.map((data) => {
      if (data.id === id) {
        return response
      }
      return data
    })
    setTimesWithData(
      sortBy(updateItems, [
        function(o) {
          return o.time[0]
        },
      ]),
    )
  }

  const handleTimeSelectionFormat = (time) => {
    const timeSelections = time.map((t) => {
      return { start: t[0], end: t[1] }
    })
    setSelections(timeSelections)
    setInitialSelections(timeSelections)
  }

  const submitTime = () => {
    let noOfDonor
    setNbOfDonor((state) => {
      noOfDonor = state
      return state
    })
    setNewSlot((state) => {
      //call the latest value in this state
      if (state) {
        handleAvailableTime(state, noOfDonor)
        setInitialSelections(selections)
        setNbOfDonor(20)
      }
      return state
    })
    initialTimeUpdate()
  }

  const handleAvailableTime = async (newTime, qty) => {
    let info
    if (newTime?.id) {
      info = {
        time: [newTime?.time[0], newTime?.time[1]],
        quantity: qty,
      }
      const response = await updateAvailableTime(newTime?.id, info)
      if (response) {
        message.success('เปลี่ยนแปลงเสร็จสิ้น')
        updateTimes(response, newTime?.id)
      }
    } else {
      info = {
        time: [newTime?.start, newTime?.end],
        quantity: qty,
      }
      const response = await addAvailableTime(info)
      if (response) {
        message.success('เปลี่ยนแปลงเสร็จสิ้น')
        initialTimeUpdate()
        setNewSlot(null)
      }
    }
  }

  const showConfirm = async (selectedValue) => {
    setSelections(selectedValue)
    let addedSlot = selectedValue.filter((elem) => !initialSelections.find(({ start }) => elem.start === start))
    let deletedSlotStart = initialSelections.filter((elem) => !selectedValue.find(({ start }) => elem.start === start))
    let deletedSlotEnd = selectedValue.filter((elem) => !initialSelections.find(({ end }) => elem.end === end))
    let toDeleteSlot = initialSelections.filter((elem) => !selectedValue.find(({ end }) => elem.end === end))

    const inCaseOfNoTimeUpDate = addedSlot?.length === 0 && deletedSlotStart?.length === 0 && deletedSlotEnd?.length === 0 && toDeleteSlot?.length === 0
    const isInCaseOfDeleteItem = addedSlot.length === 0 && deletedSlotStart.length > 0 && deletedSlotEnd.length === 0 && toDeleteSlot.length > 0
    if (isInCaseOfDeleteItem) {
      const toBeDeleteData = await getSlotDataByTimeRange(toDeleteSlot[0].start, toDeleteSlot[0].end)
      console.log(toBeDeleteData, 'toBeDeleteData')
      await deleteAvailableTimeById(toBeDeleteData[0].id)
      setNewSlot(deletedSlotEnd[0])
      initialTimeUpdate()
    } else if (inCaseOfNoTimeUpDate) {
      return
    } else {
      confirm({
        title: 'จำนวนผู้บริจาคเลือดในช่วงเวลานี้',
        content: (
          <Row>
            <Col span={12}>โปรดระบุจำนวนผู้บริจาคเลือด</Col>
            <Col span={12}>
              <InputNumber min={1} defaultValue={nbOfDonor} onChange={(value) => setNbOfDonor(value)} />
            </Col>
          </Row>
        ),
        onOk: async () => {
          handleChange(selectedValue).then(() => submitTime())
        },
        onCancel: () => {
          setIsTableLoading(true)
          initialTimeUpdate()
          setNbOfDonor(20)
          refreshTable()
        },
      })
    }
    setInitialSelections(selectedValue)
  }

  const handleChange = async (selectedValue) => {
    let addedSlot = selectedValue.filter((elem) => !initialSelections.find(({ start }) => elem?.start === start))
    let deletedSlotStart = initialSelections.filter((elem) => !selectedValue.find(({ start }) => elem?.start === start))
    let deletedSlotEnd = selectedValue.filter((elem) => !initialSelections.find(({ end }) => elem?.end === end))
    let toDeleteSlot = initialSelections.filter((elem) => !selectedValue.find(({ end }) => elem?.end === end))
    console.log('addedSlot', addedSlot)
    console.log('deletedSlotStart', deletedSlotStart)
    console.log('deletedSlotEnd', deletedSlotEnd)
    console.log('toDeleteSlot', toDeleteSlot)
    console.log(nbOfDonor, 'nb')
    const isInCaseOfEditItem = addedSlot?.length > 0 && deletedSlotStart?.length > 0 && deletedSlotEnd?.length > 0 && toDeleteSlot?.length > 0
    const isInCaseOfEditTime = deletedSlotEnd?.length > 0 && toDeleteSlot?.length > 0
    const isInCaseOfAddItem = addedSlot.length > 0
    const isAvailableTimeTableIsNotUpToDate = selections?.length > initialSelections?.length
    // if (isInCaseOfEditItem) {
    //   const deletedSlot = find(timesWithData, {
    //     time: [deletedSlotStart[0]?.start, deletedSlotStart[0]?.end],
    //   })
    //   await deleteAvailableTimeById(deletedSlot?.id)
    //   setTimesWithData(timesWithData?.filter((time) => time?.id !== deletedSlot?.id))
    //   setInitialSelections(selections)
    // } else
    if (isInCaseOfEditTime) {
      //delete old timeSlot and POST new timeSlot by return timeSlot to submitTime()
      const id = await getSlotDataByTimeRange(toDeleteSlot[0]?.start, toDeleteSlot[0]?.end)
      await deleteAvailableTimeById(id[0]?.id ?? id)
      setNewSlot(deletedSlotEnd[0])
      setInitialSelections(selections)
    } else if (isInCaseOfAddItem) {
      //add new timeSlot by return timeSlot to submitTime()
      setNewSlot(addedSlot[0])
    }
    if (isAvailableTimeTableIsNotUpToDate) {
      //setInitialSelections to update availableTime table
      setNewSlot(addedSlot[0])
      setInitialSelections(selections)
    }
  }

  const refreshTable = () => {
    setTimeout(() => setIsTableLoading(false), 100)
  }
  return (
    <div>
      {!isTableLoading && <AvailableTimes timeConvention="24h" timeZone="Asia/Bangkok" height={600} weekStartsOn="monday" start={new Date()} onChange={(value) => showConfirm(value)} initialSelections={initialSelections} recurring={true} />}
    </div>
  )
}

export default index

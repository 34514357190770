import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchBookings } from '../../store/bloodBookingSlice'
import { fetchExcelDonorForm } from '../../store/bloodFormSlice'
import moment from 'moment'
const hooks = () => {
  const dispatch = useDispatch()
  const [donorBookingLists, isDonorBookingLoading] = useSelector((state) => {
    const { donorBookingLists, isLoadingData } = state.bloodDonationBooking
    return [donorBookingLists, isLoadingData]
  })
  const [donorExcelBookingForm, isDonorExcelBookingFormLoading] = useSelector((state) => {
    const { donorFormExcel, isLoadingExcel } = state.bloodDonationForm
    return [donorFormExcel, isLoadingExcel]
  })

  const convertTime = (start, end, unix = false) => {
    if (unix) {
      return parseInt(
        moment()
          .startOf('isoWeek')
          .add(start, 'minutes')
          .format('HH'),
      )
    }
    return `${moment()
      .startOf('isoWeek')
      .add(start, 'minutes')
      .format('HH:mm')} - ${moment()
      .startOf('isoWeek')
      .add(end, 'minutes')
      .format('HH:mm')}`
  }

  return {
    events: { dispatch, fetchBookings, convertTime, fetchExcelDonorForm },
    params: { donorBookingLists, isDonorBookingLoading, donorExcelBookingForm, isDonorExcelBookingFormLoading },
  }
}

export { hooks }

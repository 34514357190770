import styled from 'styled-components'
import { Tabs as TabsBase } from 'antd'

const Tabs = styled(TabsBase)`
  .ant-tabs-nav .ant-tabs-tab-active {
    color: ${props => props.theme.primaryText};
  }
  .ant-tabs-ink-bar-animated {
    background-color: ${props => props.theme.primaryText};
  }
`
export default Tabs

// border-color: ${props => props.theme.primaryText};
import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'
import { getRole } from '../../constant'

const apiEndpoint = '/logistics'

class Logistic {
  @observable info = {
    data: [],
    error: null
  }

  @action fetchTrackingNumber = async trackingNumber => {
    try {
      const { data } = await http.get(`${apiEndpoint}/trackingNumber`, {
        params: { trackingNumber }
      })
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @computed get getLogistic() {
    return toJS(this.logistic.data)
  }
}

export default new Logistic()

import React, { useState, useEffect, useMemo } from 'react'
import { Spin, Row, Col, Modal, Tabs, Image } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import './table.css'
const { TabPane } = Tabs
const PatientImage = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

const PatientHistory = ({ hieData, visible, setVisible, patient, isLoading, role, image, medicalBenefit }) => {
  const [drugs, setDrugs] = useState(false)
  const imageFromAppUrl = useMemo(() => (!patient?.img || patient?.img?.match(/https:\/\/api.adorable.io\/avatars\//i)?.length > 0) ? image : patient?.img, [patient?.img, image])

  useEffect(() => {
    const checkDrug = () => {
      let name
      const data = hieData.drugAllergies.map((drug, index) => {
        if (drug.genericgrp_name) {
          if (drug.genericgrp_name !== name) {
            name = drug.genericgrp_name
            return {
              label: 'GenericGrp',
              value: drug.genericgrp_name,
              key: 'genericgrp_name',
              probability: drug.narranjo_name,
              date: drug.updateDateTime,
              symptom: drug.symptom,
            }
          }
        } else if (drug.genericname) {
          return {
            label: 'Generic',
            value: drug.genericname,
            key: 'genericname',
            probability: drug.narranjo_name,
            date: drug.updateDateTime,
            symptom: drug.symptom,
          }
        } else {
          return {
            label: 'ชื่ออื่น',
            value: drug.othermed,
            key: 'othermed',
            probability: drug.narranjo_name,
            date: drug.updateDateTime,
            symptom: drug.symptom,
          }
        }
      })
      setDrugs(data.filter((d) => d !== undefined))
    }
    if (hieData && hieData.drugAllergies && hieData.drugAllergies.length) {
      checkDrug()
    } else {
      setDrugs([])
    }
  }, [hieData])

  const convertHn = (hn) => {
    const hnString = hn.toString()
    const second = hnString.substring(0, 2)
    const past = hnString.substring(2).split('')
    let pass = 0
    const final = past.map((string) => {
      if (pass || string !== '0') {
        pass = 1
        return string
      }
    })
    return final.join('').concat('/', second)
  }

  const showAge = (birthDate) => {
    const currentYear = moment(new Date())
      .add(543, 'year')
      .format('YYYY')
    const age = currentYear - moment(birthDate).format('YYYY')
    return age
  }

  return (
    <Modal visible={visible} title="ประวัติ" onOk={() => setVisible(false)} onCancel={() => setVisible(false)} footer={null} width="60vw">
      <Spin spinning={isLoading} style={{ width: '100%' }}>
        {!isLoading ? (
          <Tabs defaultActiveKey="1">
            {hieData && hieData.fullname ? (
              <TabPane tab="ข้อมูลจาก HIE" key="1">
                <div className="text-center mb-4">{hieData.imageBase64 ? <img src={`data:image/png;base64,${hieData.imageBase64}`} width="150px" height="150px" alt="" /> : 'ไม่มีรูปภาพ'}</div>
                <Row>
                  <Col span={6}>ชื่อ</Col>
                  <Col span={18}>{`${hieData.fullname.title} ${hieData.fullname.firstName} ${hieData.fullname.lastName}`}</Col>
                  <Col span={6}>HN</Col>
                  <Col span={18}>{hieData.hn.length && convertHn(hieData.hn[0].hn)}</Col>
                  <Col span={6}>เบอร์ติดต่อ</Col>
                  <Col span={18}>{hieData.contactInfo.mobileNumber || '-'}</Col>
                  <Col span={6}>เพศ</Col>
                  <Col span={18}>{hieData.sexName}</Col>
                  <Col span={6}>เลขบัตรประชาชน</Col>
                  <Col span={18}>{hieData.cid}</Col>
                  <Col span={6}>ที่อยู่</Col>
                  <Col span={18}>{`${hieData.address.addressPart} ${hieData.address.amppart} ${hieData.address.chwpart} ${hieData.address.country} ${hieData.address.houseNumber} ${hieData.address.moopart} ${hieData.address.tmbpart}`}</Col>
                  {role !== 'pharmacyStore' ? (
                    <Row className="col-12 pl-0">
                      <Col className="font-weight-bold" span={6}>
                        แพ้ยา
                      </Col>
                      {drugs.length ? (
                        <Col className="pl-3 tableStyle" span={24}>
                          <table className="pl-3 col" span={24}>
                            <thead>
                              <th>วันที่</th>
                              <th>Probability</th>
                              <th>ชื่อตัวยา</th>
                              <th>อาการ</th>
                            </thead>
                            <tbody>
                              {drugs.map((drug) => (
                                <tr>
                                  <td>{moment(drug.date).format('DD/MM/YYYY')}</td>
                                  <td>{drug.probability}</td>
                                  <td>{`${drug.value ? drug.value : '-'} (${drug.label})`}</td>
                                  <td>{drug.symptom}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                      ) : (
                        'ไม่ระบุ'
                      )}
                    </Row>
                  ) : (
                    ''
                  )}
                  {medicalBenefit ? (
                    <>
                      <Col span={6}>สิทธิ์</Col>
                      <Col span={18}>{medicalBenefit}</Col>
                    </>
                  ) : (
                    ''
                  )}
                </Row>
              </TabPane>
            ) : hieData && hieData.FNAME ? (
              <TabPane tab="ข้อมูลสิทธิ" key="1">
                <Row>
                  {image ? <Image src={image} /> : ''}
                  <Col span={6}>ชื่อ</Col>
                  <Col span={18}>{`${hieData.TITLE_NAME} ${hieData.FNAME} ${hieData.LNAME}`}</Col>
                  <Col span={6}>ชื่อสิทธิ</Col>
                  <Col span={18}>{hieData.PTTYPE_NAME}</Col>
                  <Col span={6}>รหัส</Col>
                  <Col span={18}>{hieData.PID}</Col>
                </Row>
              </TabPane>
            ) : (
              ''
            )}
            <TabPane tab="ข้อมูลผู้ป่วยจาก App" key="2">
              {patient ? (
                <Row>
                  {imageFromAppUrl ? (
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <Image src={imageFromAppUrl} width={200} height={200} />
                    </Col>
                  ) : (
                    <Col span={24} style={{ textAlign: 'center', margin: 15 }}>
                      <span>ไม่มีรูปภาพ</span>
                    </Col>
                  )}
                  <Col span={8}>ชื่อ</Col>
                  <Col span={16}>{`${patient.firstname} ${patient.lastname}`}</Col>
                  <Col span={8}>อายุ</Col>
                  <Col span={16}>
                    {`
                      ${showAge(patient.birthDate)}
                    ปี `}
                  </Col>
                  <Col span={8}>HN</Col>
                  <Col span={16}>{patient.hnId && convertHn(patient.hnId)}</Col>
                  <Col span={8}>เบอร์ติดต่อ</Col>
                  <Col span={16}>{patient.mobileNumber}</Col>
                  <Col span={8}>เลขบัตรประชาชน</Col>
                  <Col span={16}>{patient.cId}</Col>
                  <Col span={8}>ที่อยู่ปัจจุบัน</Col>
                  <Col span={16}>{`${patient.address?.address || '-'} ตำบล ${patient.address?.address2 || '-'}, อำเภอ ${patient.address?.area || '-'}, จังหวัด ${patient.address?.province || '-'} ${patient.address?.postalCode || '-'}`}</Col>
                  <Col span={8}>สัญชาติ</Col>
                  <Col span={16}>{patient.nationality}</Col>
                </Row>
              ) : (
                <Row>
                  {image ? (
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <Image src={image} width={200} height={200} />
                    </Col>
                  ) : (
                    <Col span={24} style={{ textAlign: 'center', margin: 15 }}>
                      <span>ไม่มีรูปภาพ</span>
                    </Col>
                  )}
                  <Col className="text-center" style={{ paddingTop: 23 }} span={24}>
                    ไม่พบข้อมูล
                  </Col>
                </Row>
              )}
            </TabPane>
          </Tabs>
        ) : (
          ''
        )}
      </Spin>
    </Modal>
  )
}

export default PatientHistory

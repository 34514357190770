import React, { Component } from 'react'
import Layout from '../Home'
import ConsultCaseTable from '../../components/ConsultCaseTable'
import { TABLE_TYPES } from '../../constant'
import { observer, inject } from 'mobx-react'
import jwtDecode from 'jwt-decode'
import { message } from 'antd'
import moment from 'moment'

@inject('Booking')
@inject('Logistic')
@inject('Prescription')
@inject('UserInfo')
@inject('AppUserIdentity')
@inject('TrackingData')
@inject('UserFcmToken')
@inject('FCM')
@inject('PharmacyStore')
@inject('KycPatientImage')
@observer
export default class index extends Component {
  constructor() {
    super()
    this.state = {
      prescriptions: [],
      roles: [],
      trackingNumber: '',
      billingItems: [],
      hieData: {},
      isLoading: true,
      ems: [],
      stickers: [],
      isSticker: false,
      patientData: false,
      print: false,
      pharmacyStore: false,
      emsAvailable: false,
      drugsDetail: {},
      time: '05:00',
    }
  }
  intervalID = 0
  filterByPayments = async (response) => {
    return Promise.all(response.map((res) => this.fetchBillings(res)))
  }

  fetchBillings = async (res) => {
    const { patientId, vnNumber, admitTime, patient } = res
    let vnnumber = vnNumber
    const visitDate = moment(admitTime).format('YYYY-MM-DD')
    if (!vnnumber) {
      const getVnNumber = await this.checkVisitNumber(patientId, visitDate)
      if (getVnNumber && getVnNumber.length) {
        vnnumber = getVnNumber[0].vn
      }
    }
    const billings = await this.fetchBillingFromHie(patientId, vnnumber, patient.cId)
    return {
      ...res,
      billings,
    }
  }

  checkVisitNumber = async (patientId, visitDate) => {
    const { UserInfo } = this.props
    const everOmaId = await this.fetchAppUserIdentity(patientId)
    const response = await UserInfo.checkVisitNumber(everOmaId, visitDate)
    return response
  }
  filterStatusByRole = (role, response) => {
    const filterStatus =
      role === 'pharmacy'
        ? [
            'PHARMACY_PENDING_RX',
            'PHARMACY_CONFIRM_RX',
            'PATIENT_PENDING_PAYMENT',
            'PATIENT_SUCCESS_PAYMENT',
            'PATIENT_DECLINE_PAYMENT',
            'WAIT_FOR_PATIENT_HOSPITAL',
            'WAIT_FOR_PATIENT_EMS',
            'WAIT_FOR_PATIENT_PHARMACY',
            'WAIT_FOR_PATIENT_PHAMACYSTORE',
            'WAIT_FOR_PHARMACYSTORE_NOTIFY',
          ]
        : ['WAIT_FOR_PHARMACYSTORE_NOTIFY', 'WAIT_FOR_PATIENT_PHAMACYSTORE', 'SUCCESS_BY_PHARMACYSTORE', 'CANCELED_BY_PATIENT', 'CANCELED_BY_PHARMACYSTORE']
    return response.filter((res) => res.prescription && res.prescription.status && filterStatus.includes(res.prescription.status))
  }

  mounted = async () => {
    this.fetchBookingPrescriptions()
    this.updateTimer(5)
  }

  checkPath = (dataSource) => {
    let sortedData = dataSource
    const hash = this.props.history.location.hash
    if (hash === '#pharmacyPendingRx') {
      sortedData = this.sortDataByStatus('PHARMACY_PENDING_RX', sortedData)
    } else if (hash === '#pharmacyEms') {
      sortedData = this.sortDataByStatus('WAIT_FOR_PHARMACYSTORE_NOTIFY', sortedData)
      sortedData = this.sortDataByStatus('WAIT_FOR_PATIENT_EMS', sortedData)
    } else {
      sortedData = sortedData.sort((a, b) => {
        return b.bookingTime - a.bookingTime
      })
      sortedData = sortedData.sort((a, b) => {
        return moment(b.admitTime).unix() - moment(a.admitTime).unix()
      })
    }
    return sortedData
  }

  sortDataByStatus = (status, dataSource) => {
    return dataSource.sort((a, b) => {
      if ((a.prescription.status.includes(status) && b.prescription.status.includes(status)) || (!a.prescription.status.includes(status) && !b.prescription.status.includes(status))) {
        return 0
      } else if (!a.prescription.status.includes(status) && b.prescription.status.includes(status)) {
        return 1
      } else if (a.prescription.status.includes(status) && !b.prescription.status.includes(status)) {
        return -1
      }
    })
  }

  fetchBookingPrescriptions = async () => {
    const { Booking, Logistic } = this.props
    const user = jwtDecode(localStorage.getItem('token'))
    let response
    // const responseLogistic = await Logistic.fetchTrackingNumber('EB21999770TH')
    // if (responseLogistic && responseLogistic.result) {
    //   this.setState({
    //     trackingNumber: responseLogistic.result
    //   })
    // }
    response = await Booking.fetchBookingFilterByPrescription()
    if (user.roles.includes('pharmacy')) {
      response = await this.filterStatusByRole('pharmacy', response)
      this.fetchPayment(response)
      console.log('RESPONSE', response)
    } else if (user.roles.includes('pharmacyStore')) {
      response = this.filterStatusByRole('pharmacyStore', response)
    }

    response = this.checkPath(response)

    this.setState({
      prescriptions: response,
      roles: user.roles,
      isLoading: false,
    })
  }
  fetchPayment = async (response) => {
    const { Booking, Logistic } = this.props
    const user = jwtDecode(localStorage.getItem('token'))
    // response = await this.filterByPayments(response)
    response = await this.filterStatusByRole('pharmacy', response)
    //response.dataRes = await this.filterByPayments(response.dataRes)
    response = this.checkPath(response)
    this.setState({
      prescriptions: response,
      roles: user.roles,
      isLoading: false,
    })
  }
  fetchUserFcmToken = async (id, message, screen) => {
    const { UserFcmToken, FCM } = this.props
    const everOmaId = await this.fetchAppUserIdentity(id)
    if (everOmaId) {
      const response = await UserFcmToken.fetchUserFcmToken(everOmaId)
      if (response && response.length) {
        response.forEach(async (res) => {
          let notification = {
            body: message,
            title: 'แจ้งเตือน',
            icon: 'ic_launcher',
            color: '#f1c40f',
            sound: 'default',
          }
          let data = {
            screen: screen,
          }
          await FCM.sendMessage(res.fcmToken, notification, data)
        })
      }
    }
  }

  componentDidMount() {
    this.mounted()
  }

  updateStatus = (response, id) => {
    let updateItems = this.state.prescriptions.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          status: response.status,
        }
      }
      return data
    })
    this.setState({
      prescriptions: updateItems,
    })
  }

  fetchBillingFromHie = async (patientId, vnNumber, cid) => {
    if (vnNumber) {
      const { UserInfo } = this.props
      const response = await UserInfo.fetchUserPaymentStatus(cid, vnNumber)
      if (response) {
        return vnNumber ? response : []
      }
    }
    return []
  }

  fetchUserVisitedByVerifiedFromHIE = (patientId, vnNumber, visitDate = null) => {
    const { UserInfo } = this.props
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const everOmaId = await this.fetchAppUserIdentity(patientId)
        await UserInfo.triggerUpdateData(everOmaId, visitDate)
        const response = await UserInfo.checkDrugsDetailByOrderNo(everOmaId, vnNumber)
        console.log(response, 'response drugdetail')
        this.setState({
          billingItems: vnNumber ? response.billingItems : [],
          drugsDetail: response,
          isLoading: false,
        })
      }
    )
  }

  fetchAppUserIdentity = async (id) => {
    const { AppUserIdentity } = this.props
    const response = await AppUserIdentity.fetchAppUserIdentity(id)
    if (response && response.length) {
      return response[response.length - 1].everOmaId
    } else {
      return false
    }
  }

  setStatus = async (id, status) => {
    const { Booking } = this.props
    const response = await Booking.setStatus(id, status)
    if (response) {
      message.success('ยืนยันรายการเสร็จสิ้น')
      this.updateStatus(response, id)
      this.fetchBookingPrescriptions()
    } else {
      message.error('เกิดข้อผิดพลาดบางอย่าง')
    }
  }

  confirmOrder = async (prescriptionId) => {
    const { Prescription } = this.props
    await Prescription.confirmOrderPrescription(prescriptionId)
    this.setStatusPrescription(prescriptionId, 'PATIENT_PENDING_PAYMENT')
  }

  setStatusPrescription = async (id, status) => {
    const { Prescription } = this.props
    const response = await Prescription.updateStatus(id, status)
    if (response) {
      message.success('ยืนยันรายการเสร็จสิ้น')
      this.updateStatusPrescription(response, id)
      this.fetchBookingPrescriptions()
    } else {
      message.error('เกิดข้อผิดพลาดบางอย่าง')
    }
  }

  updateStatusPrescription = (response, id) => {
    let updateItems = this.state.prescriptions.map((data) => {
      if (data.prescriptionId === id) {
        return {
          ...data,
          prescription: response,
        }
      }
      return data
    })
    this.setState({
      prescriptions: updateItems,
    })
  }

  fetchDetailTrackingNumber = async (id, isSticker) => {
    const { Prescription } = this.props
    const response = await Prescription.fetchDetailTrackingNumber(id)
    if (isSticker) {
      this.setState({
        stickers: response,
        isSticker,
        print: true,
      })
    } else {
      this.setState({
        ems: response,
        print: true,
      })
    }
    setTimeout(() => this.setState({ print: false }), 1000)
  }

  fetchPatient = (id) => {
    const { AppUserIdentity, UserInfo } = this.props
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const everOmaId = await this.fetchAppUserIdentity(id)
        if (everOmaId) {
          const resHie = await UserInfo.checkUserDetailByVerifiedFromHIE(everOmaId)
          const resPatient = await UserInfo.fetchUserInfoByPatientId(everOmaId)
          this.setState({
            hieData: resHie,
            patientData: resPatient,
          })
        }
        this.setState({
          isLoading: false,
        })
      }
    )
  }
  fetchUserImage = async (id) => {
    const { KycPatientImage } = this.props
    const everOmaId = await this.fetchAppUserIdentity(id)
    if (everOmaId) {
      const response = await KycPatientImage.fetchPatientImage(everOmaId)
      if (response) {
        this.setState({
          image: response.image,
        })
      } else {
        this.setState({
          image: false,
        })
      }
    }
  }
  fetchPharmacyStore = async (id) => {
    const { PharmacyStore } = this.props
    const response = await PharmacyStore.fetchPharmacyStore(id)
    if (response && response.status === 200) {
      this.setState({
        pharmacyStore: response.data,
      })
    }
  }

  addTrackingNumber = async (prescriptionId, quantity) => {
    const { Prescription } = this.props
    const response = await Prescription.addTrackingNumber(prescriptionId, quantity)
    message.success('สำเร็จ')
  }

  setPrintTime = () => {
    this.setState({
      print: true,
    })
    setTimeout(() => this.setState({ print: false }), 1000)
  }

  thaiPost = async (barcode) => {
    const { TrackingData } = this.props
    await TrackingData.updateThaipost(barcode)
  }

  trackingAvailable = async () => {
    const { TrackingData } = this.props
    const response = await TrackingData.fetchTrackingDataAvailable()
    this.setState({
      emsAvailable: response,
    })
  }

  updateTimer = (num) => {
    clearInterval(this.intervalID)
    var minutes = 60 * num
    this.startTimer(minutes)
  }

  startTimer = (duration) => {
    var timer = duration,
      minutes,
      seconds
    this.intervalID = setInterval(() => {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10)
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      let showTime = minutes + ':' + seconds
      this.setState({
        time: showTime,
      })
      if (--timer < 0) {
        timer = duration
        this.mounted()
      }
    }, 1000)
  }

  render() {
    return (
      <Layout
        {...this.props}
        render={(props) => (
          <ConsultCaseTable
            {...props}
            state={this.state}
            tableType={TABLE_TYPES.prescription}
            style={{ width: '100%' }}
            confirmOrder={this.confirmOrder}
            getTracking={this.handleTracking}
            fetchUserVisitedByVerifiedFromHIE={this.fetchUserVisitedByVerifiedFromHIE}
            fetchDetailTrackingNumber={this.fetchDetailTrackingNumber}
            fetchPatient={this.fetchPatient}
            setStatus={this.setStatus}
            setStatusPrescription={this.setStatusPrescription}
            refreshData={this.mounted}
            addTrackingNumber={this.addTrackingNumber}
            thaiPost={this.thaiPost}
            fetchUserFcmToken={this.fetchUserFcmToken}
            setIsSticker={(condition) => this.setState({ isSticker: condition })}
            setPrint={(condition) => this.setState({ print: condition })}
            fetchPharmacyStore={this.fetchPharmacyStore}
            setPrintTime={this.setPrintTime}
            setPharmacyStore={(condition) => this.setState({ pharmacyStore: condition })}
            trackingAvailable={this.trackingAvailable}
            fetchUserImage={this.fetchUserImage}
            fetchAppUserIdentity={this.fetchAppUserIdentity}
            fetchBillingFromHie={this.fetchBillingFromHie}
          />
        )}
      />
    )
  }
}

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Table from '../../components/Table'
import { Row, Col, Button, Spin, Card } from 'antd'
import ReactExport from 'react-data-export'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ReportSmbgTab = ({ reportSmbgData, fetchSmbgReports, isLoading }) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(7)
  const [smbgCount, setSmbgCount] = useState(0)
  const [lowerSmbgCount, setLowerSmbgCount] = useState(0)
  const [mediumSmbgCount, setMediumSmbgCount] = useState(0)
  const [overSmbgCount, setOverSmbgCount] = useState(0)

  useEffect(() => {
    fetchSmbgReports(1, 10000)
  }, [])

  useEffect(() => {
    if (reportSmbgData?.docs) {
      countSmbg(reportSmbgData.docs)
    }
  }, [reportSmbgData])

  const onChangePage = (pageDetail) => {
    setPage(pageDetail.current)
    setLimit(pageDetail.pageSize)
    fetchSmbgReports(pageDetail.current, pageDetail.pageSize)
  }
  const countSmbg = (dat) => {
    if (dat) {
      setSmbgCount(dat.length)
      const lower = dat.filter((item) => item.minGlucose < 70).length
      const medium = dat.filter((item) => item.minGlucose >= 70 && item.maxGlucose < 250).length
      const over = dat.filter((item) => item.maxGlucose >= 250).length
      setLowerSmbgCount(lower)
      setMediumSmbgCount(medium)
      setOverSmbgCount(over)
    }
  }
  const columns = [
    {
      title: 'HN',
      key: 'hn',
      render: (record) => <p>{record.hn ? record.hn : '-'}</p>,
      align: 'center',
    },
    {
      title: 'ชื่อผู้ป่วย',
      key: 'patient',
      render: (record) => <p>{record.patient}</p>,
      align: 'center',
    },
    {
      title: 'เพศ',
      key: 'gender',
      render: (record) => <p>{record.gender}</p>,
      align: 'center',
    },
    {
      title: 'จำนวนข้อมูล SMBG',
      key: 'nbOfSmbgRecord',
      sorter: (a, b) => a.nbOfSmbgRecord - b.nbOfSmbgRecord,
      render: (record) => <p>{record.nbOfSmbgRecord}</p>,
      align: 'center',
    },
    {
      title: 'วันที่บันทึกครั้งแรก',
      key: 'firstDateRecord',
      render: (record) => <p>{moment(record.firstDateRecord).format('DD/MM/YYYY')}</p>,
      align: 'center',
    },
    {
      title: 'วันที่สุดท้าย',
      key: 'lastDateRecord',
      sorter: (a, b) => moment(a.lastDateRecord).unix() - moment(b.lastDateRecord).unix(),
      defaultSortOrder: 'descend',
      render: (record) => <p>{moment(record.lastDateRecord).format('DD/MM/YYYY')}</p>,
      align: 'center',
    },
    {
      title: 'ค่าเฉลี่ยน้ำตาล',
      key: 'averageGlucose',
      render: (record) => <p>{record.averageGlucose}</p>,
      align: 'center',
    },
    {
      title: 'ค่าต่ำสุด',
      key: 'minGlucose',
      render: (record) => <p>{record.minGlucose}</p>,
      align: 'center',
    },
    {
      title: 'ค่าสูงสุด',
      key: 'maxGlucose',
      render: (record) => <p>{record.maxGlucose}</p>,
      align: 'center',
    },
    {
      title: 'TIR เขียว(ครั้ง)',
      key: 'nbOfInStardardRecord',
      render: (record) => <p>{record.nbOfInStardardRecord}</p>,
      align: 'center',
    },
    {
      title: 'TAR ฟ้า(ครั้ง)',
      key: 'nbOfAboveStardardRecord',
      render: (record) => <p>{record.nbOfAboveStardardRecord}</p>,
      align: 'center',
    },
    {
      title: 'TBR แดง(ครั้ง)',
      key: 'nbOfBelowStandardRecord',
      render: (record) => <p>{record.nbOfBelowStandardRecord}</p>,
      align: 'center',
    },
  ]
  return (
    <div>
      <Spin spinning={isLoading}>
        <Row type="flex" justify="center" gutter={[10, 10]} className="my-3">
          {/* <Col xs={24} lg={24} className="d-flex flex-column align-items-center">
            <Card>
              <h2>การตรวจ SMBG ทั้งหมด: {smbgCount}</h2>
              <h3 style={{ margin: '10px' }}>
                ค่าต่ำกว่า 70 มีจำนวน: <b>{lowerSmbgCount}</b>
              </h3>
              <h3 style={{ margin: '10px' }}>
                ค่าอยู่ระหว่าง 70-249 มีจำนวน: <b>{mediumSmbgCount}</b>
              </h3>
              <h3 style={{ margin: '10px' }}>
                ค่ามากกว่า 250 มีจำนวน: <b>{overSmbgCount}</b>
              </h3>
            </Card>
          </Col> */}
          {reportSmbgData?.docs?.length > 0 ? (
            <Col className="d-flex align-items-center">
              <ExcelFile
                element={<Button>Export File</Button>}
                filename={`Refer_SMBG${moment()
                  .add(543, 'years')
                  .format('DD/MM/YYYY')}`}
              >
                <ExcelSheet data={reportSmbgData?.docs} name="Refer SMBG Report">
                  <ExcelColumn label="HN" value="hn" />
                  <ExcelColumn label="ชื่อผู้ป่วย" value="patient" />
                  <ExcelColumn label="เพศ" value="gender" />
                  <ExcelColumn label="จำนวนข้อมูล SMBG" value="nbOfSmbgRecord" />
                  <ExcelColumn label="วันที่บันทึกครั้งแรก" value="firstDateRecord" />
                  <ExcelColumn label="วันที่สุดท้าย" value="lastDateRecord" />
                  <ExcelColumn label="ค่าเฉลี่ยน้ำตาล" value="averageGlucose" />
                  <ExcelColumn label="ค่าต่ำสุด" value="minGlucose" />
                  <ExcelColumn label="ค่าสูงสุด" value="maxGlucose" />
                  <ExcelColumn label="TIR เขียว(ครั้ง)" value="nbOfInStardardRecord" />
                  <ExcelColumn label="TAR ฟ้า(ครั้ง)" value="nbOfAboveStardardRecord" />
                  <ExcelColumn label="TBR แดง(ครั้ง)" value="nbOfBelowStandardRecord" />
                </ExcelSheet>
              </ExcelFile>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </Spin>
      <Table columns={columns} dataSource={reportSmbgData.docs} loading={isLoading} pagination={{ defaultPageSize: 7 }}></Table>
      {/* <Table columns={columns} dataSource={reportSmbgData.docs} loading={isLoading} onChange={onChangePage} pagination={{ total: reportSmbgData.totalDocs, current: page, pageSize: limit }}></Table> */}
    </div>
  )
}

export default ReportSmbgTab

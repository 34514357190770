import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Form, Input, Button, Checkbox } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import Chart from 'react-apexcharts'
import http from '../../../services/httpServiceHealthCare'
import { GlucoseText } from './styles'
import { FilterOptions } from '../constant'
import { cloneDeep } from 'lodash'

function GlucoseGraphCard(props) {
  const { graphs, patient, onFinishGlucose, editable, filterByDrugTime } = props
  const { defaultLow, defaultHigh, lines } = graphs
  const [form] = Form.useForm()
  const options = cloneDeep(lines.options)
  const series = cloneDeep(lines.series)
  useEffect(() => {
    if (graphs) {
      form.setFieldsValue({ low: graphs.defaultLow, high: graphs.defaultHigh })
    }
  }, [graphs])

  const onFinish = async (values) => {
    if (Number(values.high) > Number(values.low)) {
      await http.patch(`/UserInfos/${patient.id}`, {
        defaultGlucoseValue: [values.low, values.high],
      })
      onFinishGlucose([values.low, values.high])
    } else {
      alert('กรุณากรอกค่า Maximum range ปกติให้มากกว่าค่า Minimum')
    }
  }
  return (
    <Card className="mt-2" title="กราฟแนวโน้มค่าน้ำตาล">
      {editable ? (
        <Form
          form={form}
          layout="inline"
          onFinish={onFinish}
          style={{ maxWidth: '100%' }}
          initialValues={{
            low: defaultLow,
            high: defaultHigh,
          }}
        >
          <Row>
            <Col span={24} className="mb-2">
              <div>ตั้งค่าน้ำตาลในช่วงปกติ (ต่ำสุด - สูงสุด)</div>
            </Col>
            <Col span={10}>
              <Form.Item
                name="low"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกค่าก่อนส่งยืนยัน',
                  },
                ]}
              >
                <Input prefix={<EditOutlined className="site-form-item-icon" />} placeholder="low" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="high"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกค่าก่อนส่งยืนยัน',
                  },
                ]}
              >
                <Input prefix={<EditOutlined className="site-form-item-icon" />} type="text" placeholder="high" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  ตั้งค่าข้อมูล
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="mt-4 mb-4">
          <Row>
            <GlucoseText span={16}>ค่าน้ำตาลในช่วงปกติที่ตั้งไว้ (ต่ำสุด - สูงสุด)</GlucoseText>
          </Row>
          <Row>
            <GlucoseText span={8}>
              ต่ำสุด&nbsp; : &nbsp;<b>{defaultLow}</b>
            </GlucoseText>
            <GlucoseText span={8}>
              สูงสุด&nbsp; : &nbsp;<b>{defaultHigh}</b>
            </GlucoseText>
          </Row>
        </div>
      )}
      <div className="py-3">
        <Checkbox.Group
          options={FilterOptions}
          defaultValue={['beforeMeal', 'afterMeal', 'all']}
          onChange={(vals) => {
            filterByDrugTime(vals, 'glucoses')
          }}
        />
      </div>
      <Chart options={options} series={series} type="line" width="600" />
    </Card>
  )
}

export default GlucoseGraphCard

import React, { Component } from 'react'
import { Table as TableAnt } from 'antd'
import styled from 'styled-components'

const Table = styled(TableAnt)`
  &&&& {
    thead.ant-table-thead > tr > th {
      background-color: ${(props) => props.theme.primaryText};
      border-radius: 0;
      color: white;
    }
    tr.ant-table-row {
      &:nth-child(odd) {
        td {
          background-color: #f8f8f8;
        }
      }
    }
    .ant-table-tbody > tr {
      &:hover {
        td {
          background-color: #e2e2e2;
        }
      }
    }
    .ant-table-tbody > tr > td {
      padding: 10px;
      background-color: white;
      border: none;
      i {
        color: ${(props) => props.theme.primaryButton};
      }
    }
    .ant-table-placeholder {
      z-index: 0;
    }
  }
  @media only screen and (max-width: 936px), (min-device-width: 936px) and (max-device-width: 1366px) {
    .ant-table-container {
      height: 600px;
      overflow-y: scroll;
    }
    .ant-table table {
      width: 100% !important;
    }
    .ant-table-measure-row {
      display: none !important;
    }
    .set_scroll {
      height: 400px;
      overflow-y: scroll;
    }

    .set_scroll::-webkit-scrollbar,
    .table-responsive::-webkit-scrollbar {
      display: none;
    }
    .table-responsive {
      display: block;
      width: 100%;
      overflow-x: hidden !important;
      -webkit-overflow-scrolling: touch;
    }

    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block !important;
    }

    tr:hover {
      background-color: #e2e2e2 !important;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px !important;
      left: -9999px !important;
    }

    tr {
      margin: 0 0 1rem 0 !important;
    }

    tr:nth-child(odd) {
      background: #e4e4e4 !important;
      padding: 12px !important;
      border-radius: 12px !important;
    }
    tr:nth-child(even) {
      padding: 12px !important;
      border-radius: 12px !important;
    }

    td {
      /* Behave  like a "row" */
      border: none !important;
      border-bottom: 1px dashed #d5d5d5 !important;
      position: relative !important;
      padding-left: 50% !important;
    }

    td:before {
      /* Now like a table header */
      position: absolute !important;
      /* Top/left values mimic padding */
      top: 0 !important;
      left: 6px !important;
      width: 45% !important;
      padding-right: 10px !important;
      /*white-space: nowrap;*/
    }

    /*
    Label the data
        You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
    */
    ${(props) => colStyle(props ? props : '')}
    .ant-table-thead > tr > th {
      font-weight: 500 !important;
      text-align: center !important;
      font-size: 16px !important;
      vertical-align: top !important;
    }
    .ant-table-tbody > tr > td {
      font-size: 14px !important;
      width: unset !important;
      text-align: center !important;
      overflow-x: auto;
    }
    // .ant-table-tbody > tr > td:last-child {
    //   padding: 0px !important;
    // }
    .center-printer {
      width: 50% !important;
      float: right !important;
    }
  }
`
const colStyle = (props) => {
  const history = window.location.pathname
  let colResponsive = ''
  if (props.columns) {
    props.columns.map((header, index) => {
      header.title !== 'สถานะ'
        ? (colResponsive += 'td:nth-of-type(' + (index + 1) + '):before { content: "' + (header.title ? header.title : '') + '"; font-weight:500;text-align: center !important;}\n')
        : history === '/your-settings'
        ? (colResponsive += 'td:nth-of-type(' + (index + 1) + '):before { content: ""; font-weight:500; }\n')
        : (colResponsive += 'td:nth-of-type(' + (index + 1) + '):before { content: "' + header.title + '"; font-weight:500; }\n')
    })
  }
  return colResponsive
}
export default class index extends Component {
  render() {
    return <Table pagination={{ pageSize: this.props.pageSize ?? 7 }} onChange={this.props.onChange} {...this.props} />
  }
}

/* eslint-disable no-empty-pattern */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { FileOutlined } from '@ant-design/icons';
import { Input, Tag, Popconfirm, Row, Col } from 'antd';
import styled from 'styled-components'
import { Formik } from 'formik'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import Table from '../Table'
import TextBase from '../Text'
import Button from '../Button'
import { BOOKING_DOCTOR_STATUS } from '../../constant'

const { Search: SearchAnt } = Input
const Text = styled(TextBase)`
  font-weight: bold;
`
const Search = styled(SearchAnt)`
  i {
    color: ${props => props.theme.primaryButton};
  }
`

const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
const Container = styled.div`
  width: 100%;
  padding: 20px;
`
const StoreTable = ({ state, updateStatus, ...rest }) => {
  const user = jwtDecode(localStorage.getItem('token'))

  const setColor = (status, key) => {
    if (!status) {
      return 'blue'
    }
    if (status.includes('PENDING')) {
      return 'orange'
    }
    if (status.includes('CONFIRM') || status.includes('PAID')||status.includes('COMPLETED')) {
      return 'green'
    }
    if (status.includes('DECLINE') || status.includes('CANCEL')) {
      return 'red'
    }
    return 'blue'
  }
  const columns = [
    {
      title: 'ชื่อผู้ป่วย',
      key: 'patientName',
      render: record => record.patient && record.patient.fullname ? <Text>{record.patient.fullname}</Text> : '-',
      align: 'center'
    },
    {
      title: 'เลขบัตรประชาชน',
      key: 'patientId',
      render: record => record.patient && record.patient.fullname ? <Text>{record.patient.fullname}</Text> : '-',
      align: 'center'
    },
    {
      title: 'วันที่',
      key: 'date',
      render: record => <Text>{moment(record.admitTime).format('DD/MM/YYYY')}</Text>,
      align: 'center'
    },
    {
      title: 'เวลา',
      key: 'time',
      render: record => (
        <Text>
          {moment()
            .startOf('day')
            .add(record.bookingTime, 'minutes')
            .format('HH:mm')}
        </Text>
      ),
      align: 'center'
    },
    {
      title: 'ปรึกษาเกี่ยวกับ',
      key: 'consultAbout',
      render: record => <Text>{record.pharmacy.fullname || '-'}</Text>,
      align: 'center'
    },
    {
      title: 'เลขพัสดุ',
      key: 'trackingNumber',
      render: record => <Text>{record.trackingNumber || '-'}</Text>,
      align: 'center'
    },
    {
      title: 'สถานะพัสดุ',
      key: 'supplyStatus',
      render: record => ''
    },
    {
      title: 'สถานะยาที่ร้าน',
      key: 'drugStatus',
      width: 270,
      render: record => record.status === 'WAIT_FOR_PHARMACYSTORE_NOTIFY' ? (
        <Popconfirm
          title="ต้องการยืนยันสถานะการรับยาใช่หรือไม่"
          onConfirm={() => updateStatus(record.id, 'WAIT_FOR_PATIENT_PHAMACYSTORE')}
          okText="ใช่"
          cancelText="ไม่"
        >
          <Button>ยามาถึงแล้ว</Button>
        </Popconfirm>
      ) : record.status === 'WAIT_FOR_PATIENT_PHAMACYSTORE' ? (
        <Row gutter={16}>
          <Col className="gutter-row" span={8}>
            <Popconfirm
              title="ต้องการย้อนกลับสถานะการรับยาใช่หรือไม่"
              onConfirm={() => updateStatus(record.id, 'WAIT_FOR_PHARMACYSTORE_NOTIFY')}
              okText="ใช่"
              cancelText="ไม่"
            >
              <Button>ย้อนกลับ</Button>
            </Popconfirm>
          </Col>
          <Col className="gutter-row" span={8}>
            <Popconfirm
              title="ต้องการยืนยันสถานะการรับยาของผู้ป่วยใช่หรือไม่"
              onConfirm={() => updateStatus(record.id, 'SUCCES_BY_PHARMACYSTORE')}
              okText="ใช่"
              cancelText="ไม่"
            >
              <Button>ผู้ป่วยมารับยาแล้ว</Button>
            </Popconfirm>
          </Col>
        </Row>
      ) : (
        <Popconfirm
          title="ต้องการย้อนกลับสถานะการรับยาของผู้ป่วยใช่หรือไม่"
          onConfirm={() => updateStatus(record.id, 'WAIT_FOR_PATIENT_PHAMACYSTORE')}
          okText="ใช่"
          cancelText="ไม่"
        >
          <Button>ย้อนกลับ</Button>
        </Popconfirm>
      )
    }
  ]

  return (
    <Formik initialValues={{ filter: '' }}>
      {props => (
        <Container>
          <HeaderWrapper>
            <FileOutlined />
            <Text bold>ร้านค้า</Text>
            <Search style={{ width: 200, marginLeft: 'auto' }} />
          </HeaderWrapper>
          <Table
            scroll={{ x: '100vw' }}
            columns={columns}
            dataSource={state.dataSource}
            {...rest}
          />
        </Container>
      )}
    </Formik>
  );
}
export default StoreTable

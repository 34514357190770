import styled from 'styled-components'
import { Row, Col, Button, Radio } from 'antd'
export const Container = styled('div')`
  width: 100%;
  padding: 12px;
`
export const ContainerLoading = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`
export const RowFlexCenter = styled(Row)`
  display: flex;
  justify-content: center;
  height: 100%;
`
export const ColFlexCenter = styled(Col)`
  display: flex;
  justify-content: center;
`

export const MenuButton = styled(Button)`
max-width: 400px;
height: 100px;
font-size: 24px
display: flex;
align-items: center;
justify-content: center;`

export const TitleText = styled(`h2`)`
  color: #1b4b63;
`

export const InfoBox = styled(`div`)`
  display: flex;
  align-items: center;
  padding: 20px 12px 8px 14px;
  margin-top: 8px;
  margin-bottom: 16px;
  background: #ebebeb;
  min-width: 100px;
  position: relative;
  border-radius: 0px 20px 0px 20px;
  // border: 2px solid #1b4b63;
  background-image: repeating-linear-gradient(60deg, #dbdbdb, #dbdbdb 5px, transparent 5px, transparent 9px, #dbdbdb 9px), repeating-linear-gradient(150deg, #dbdbdb, #dbdbdb 5px, transparent 5px, transparent 9px, #dbdbdb 9px),
    repeating-linear-gradient(240deg, #dbdbdb, #dbdbdb 5px, transparent 5px, transparent 9px, #dbdbdb 9px), repeating-linear-gradient(330deg, #dbdbdb, #dbdbdb 5px, transparent 5px, transparent 9px, #dbdbdb 9px);
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
`

export const FloatTitleBox = styled(`div`)`
  position: absolute;
  top: -10px;
  left: -2px;
`

export const FloatTitleText = styled(`div`)`
  background: ${(props) => props.backgroundColor ?? '#45c0bb'};
  padding: 0px 6px;
  color: ${(props) => (props.titleColor === 'dark' ? '#212121' : '#fff')};
  border-radius: 0px 10px 10px 0px;
  border-top: 2px solid #1b4b63;
  border-bottom: 2px solid #1b4b63;
  border-left: 2px solid #1b4b63;
`
export const DescriptionText = styled(`p`)`
  background: white;
  padding: 4px 8px;
  border-radius: 24px;
`
export const FormBox = styled(Col)`
  background-color: #fcfcfc;
  background-image: repeating-linear-gradient(60deg, #84b8b4, #84b8b4 5px, transparent 5px, transparent 9px, #84b8b4 9px), repeating-linear-gradient(150deg, #84b8b4, #84b8b4 5px, transparent 5px, transparent 9px, #84b8b4 9px),
    repeating-linear-gradient(240deg, #84b8b4, #84b8b4 5px, transparent 5px, transparent 9px, #84b8b4 9px), repeating-linear-gradient(330deg, #84b8b4, #84b8b4 5px, transparent 5px, transparent 9px, #84b8b4 9px);
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 8px;
  box-shadow: 11px 12px 23px -15px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 11px 12px 23px -15px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 11px 12px 23px -15px rgba(0, 0, 0, 0.29);
`

import React from 'react'
import { Modal } from 'antd'
const ReportProblemFormModal = ({ visibleReportProblemModal, setVisibleReportProblemModal, children }) => {
  return (
    <Modal
      visible={visibleReportProblemModal}
      onOk={() => {
        setVisibleReportProblemModal(false)
      }}
      onCancel={() => {
        setVisibleReportProblemModal(false)
      }}
      title="แจ้งปัญหา"
      footer={null}
    >
      {children}
    </Modal>
  )
}

export default ReportProblemFormModal

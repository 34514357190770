import React, { Component } from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'

const StyledSpin = styled(Spin)`
  display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 3px;
    box-shadow: 0 0 10px -1px black;
`
export default class Loading extends Component {
  render() {
    return (
      <StyledSpin tip="loading..." size="large" />
    )
  }
}

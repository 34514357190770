import React from 'react'
import Layout from '../Home'
import { Button, Row, Col, Input, message, Spin, Card } from 'antd'
import { BarcodeOutlined, IdcardOutlined, HomeFilled } from '@ant-design/icons'
import { isEmpty } from 'lodash'
import BarcodeReader from '../../components/BarcodeReader'
import { Container, ContainerLoading, RowFlexCenter, ColFlexCenter, MenuButton, TitleText, FormBox, FloatTitleText, FloatTitleBox } from './style'
import { PatientInfo } from './PatientInfo'
import { hooks } from './hooks'
import DonationForm from './FormSection'
const BloodDonationForm = (props) => {
  const { events, params } = hooks()
  const { history } = props

  return (
    <Layout
      history={history}
      render={() => {
        return params.isPatientLoading ? (
          <ContainerLoading>
            <Spin size="large" />
          </ContainerLoading>
        ) : (
          <Container>
            {!params.cId ? (
              <RowFlexCenter>
                <Col span={24} style={{ gap: '12px' }}>
                  <Row gutter={[20, 20]} className="w-100">
                    <Col>
                      <MenuButton
                        type="primary"
                        size="large"
                        icon={<BarcodeOutlined />}
                        onClick={() => {
                          events.setIsCameraOn(!params.isCameraOn)
                          events.setIsInputCidOn(false)
                        }}
                      >
                        {params.isCameraOn ? `ปิดกล้องสแกนบาร์โค้ด` : `กล้องสแกนบาร์โค้ด`}
                      </MenuButton>
                    </Col>
                    <Col>
                      <MenuButton
                        type="primary"
                        size="large"
                        icon={<IdcardOutlined />}
                        onClick={() => {
                          events.setIsInputCidOn(!params.isInputCidOn)
                          events.setIsCameraOn(false)
                        }}
                      >
                        {`เครื่องสแกนบาร์โค้ด`}
                      </MenuButton>
                    </Col>
                    {params.isInputCidOn && (
                      <Col span={24}>
                        <Input.Search
                          ref={params.inputRef}
                          allowClear
                          maxLength={13}
                          size="large"
                          addonBefore="เลขบัตรประชาชน"
                          placeholder="เลขบัตรประจำตัวประชาชน"
                          enterButton="ค้นหาข้อมูล"
                          style={{ maxWidth: '750px' }}
                          onSearch={(text) => {
                            const isValidTextFormat = events.validateTextFormat(text, true)
                            if (isValidTextFormat) {
                              events.setCid(text)
                            } else {
                              message.error('กรุณาเปลี่ยนภาษาคีย์บอร์ดเป็นภาษาอังกฤษ และกรอกเฉพาะตัวเลข')
                            }
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
                {params.isCameraOn && (
                  <ColFlexCenter span={24}>
                    <BarcodeReader data={params.barcodeData} setData={(value) => events.setBarcodeData(value)} />
                  </ColFlexCenter>
                )}
              </RowFlexCenter>
            ) : (
              <Card
                className="mt-2"
                title={<TitleText>{`ข้อมูลผู้บริจาคเลือด`}</TitleText>}
                extra={
                  <Button
                    onClick={() => {
                      events.setCid(null)
                      events.setBarcodeData('')
                      events.setPatientInfo(null)
                    }}
                    icon={<HomeFilled />}
                    className="d-flex align-items-center justfy-content-center"
                  >
                    {`ย้อนกลับหน้าแรก`}
                  </Button>
                }
              >
                <Row gutter={[24, 16]}>
                  {!params.isFormDataLoading ? (
                    <Col xs={24} md={11}>
                      <PatientInfo patientInfo={params.patientInfo} donorLatestForm={params.donorLatestForm} className="mt-2" />
                    </Col>
                  ) : (
                    <ColFlexCenter xs={24} md={11} className="align-items-center" style={{ minHeight: '500px' }}>
                      <Spin size="large" />
                    </ColFlexCenter>
                  )}
                  {!params.isFormDataLoading ? (
                    <>
                      {params.donorLatestForm?.bloodDonationBooking?.screenStatus === 'screenPass' && params.donorLatestForm?.bloodDonationBooking?.donateStatus === 'donationCompleted' ? (
                        <ColFlexCenter xs={24} md={13} className="align-items-center">
                          <p className="h3" style={{ color: '#1b4b63' }}>{`บริจาคเลือดเรียบร้อยแล้ว`}</p>
                        </ColFlexCenter>
                      ) : !isEmpty(params.donorFormLists) ? (
                        <FormBox xs={24} md={13} className="p-4">
                          <FloatTitleBox>
                            <FloatTitleText>
                              <p className="h4 my-1 px-2" style={{ color: '#1b4b63' }}>
                                แบบฟอร์มเจ้าหน้าที่
                              </p>
                            </FloatTitleText>
                          </FloatTitleBox>
                          <DonationForm
                            patientInfo={params.patientInfo}
                            clearData={() => {
                              events.setCid(null)
                              events.setBarcodeData('')
                              events.setPatientInfo(null)
                            }}
                          />
                        </FormBox>
                      ) : (
                        <ColFlexCenter xs={24} md={13} className="align-items-center">
                          <p className="h3" style={{ color: '#1b4b63' }}>{`ไม่พบข้อมูลการลงทะเบียนบริจาคเลือด`}</p>
                        </ColFlexCenter>
                      )}
                    </>
                  ) : (
                    <ColFlexCenter xs={24} md={13} className="align-items-center" style={{ minHeight: '500px' }}>
                      <Spin size="large" />
                    </ColFlexCenter>
                  )}
                </Row>
              </Card>
            )}
          </Container>
        )
      }}
    />
  )
}

export default BloodDonationForm

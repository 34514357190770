import axios from 'axios'
import logger from './logService'
// import { toast } from 'react-toastify'

axios.defaults.baseURL = process.env.REACT_APP_API_URL_EVERAPP

axios.interceptors.response.use(null, error => {
  // eslint-disable-next-line dot-notation
  axios.defaults.params['access_token'] = localStorage.getItem('token')
    ? localStorage.getItem('token')
    : null
  const expectedError = error.response
    && error.response.status >= 400
    && error.response.status < 500

  if (!expectedError) {
    logger.log(error)
    // toast.error('An unexpected error occurrred.')
  }
  return Promise.reject(error)
})

function setJwt(jwt) {
  if (jwt) {
    // axios.defaults.headers.common['x-auth-token'] = jwt
    axios.defaults.params = {}
    axios.defaults.params['access_token'] = jwt //eslint-disable-line
  }
}

function clearJwt() {
  if (axios.defaults.params) {
    delete axios.defaults.params
  }
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  setJwt,
  clearJwt,
}

import React, { useState } from 'react'
import { View, Text, Svg, G, Image } from '@react-pdf/renderer'
import styles, { Row } from '../styles'
import moment from 'moment'
import 'moment/locale/th'
export default function HeaderSection({ data, convertTime }) {
  return (
    <View>
      <Text style={{ ...styles.titleText, marginTop: 50 }}>ข้อมูลการบริจาคเลือด</Text>
      <Text style={styles.titleText}>{`วันที่ ${moment(data?.donationDate)
        .add(543, 'years')
        .format('LL')} รอบเวลา ${convertTime(data?.donationTime[0], data?.donationTime[1])}น.`}</Text>
      {data?.imageCid && <Image src={data?.imageCid} style={{ width: 150, height: 75, position: 'absolute', left: 0, textAlign: 'left' }} />}
    </View>
  )
}

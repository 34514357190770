import React from 'react'
import { View, Text, Image } from '@react-pdf/renderer'
import styles, { CheckboxWithLabel, Column, Row } from '../styles'
import { get } from 'lodash'
import moment from 'moment'

export default function PatientTable({ data, patientInfo, convertHn }) {
  const { bloodDonationForm } = data
  const convertForPDF = (text) => {
    return JSON.stringify(`${text || ''}`).replaceAll('"', ' ')
  }
  return (
    <View style={styles.borderedContainer}>
      <Row>
        <Column borderBottom borderRight flex={3}>
          <Text style={styles.normalText}>{`เคยบริจาคเลือดหรือไม่`}</Text>
          <Text style={styles.normalText}>{convertForPDF(`${bloodDonationForm?.haveDonatedBlood ?? '-'}`)}</Text>
        </Column>
        <Column borderBottom borderRight flex={2}>
          <Text style={styles.normalText}>{`ประเภทการบริจาค`}</Text>
          <Text style={styles.normalText}>{convertForPDF(`${bloodDonationForm?.donateType === 'blood' ? 'เลือด' : bloodDonationForm?.donateType === 'bloodPlatelet' ? 'เกล็ดเลือด' : '-'}`)}</Text>
        </Column>
        <Column borderBottom flex={7}>
          <Text style={styles.normalText}>{`วัตถุประสงค์การบริจาค`}</Text>
          <Text style={styles.normalText}>{convertForPDF(`${bloodDonationForm?.donateFor + ' ' ?? '-'}`)}</Text>
        </Column>
      </Row>
      <Row>
        <Column borderBottom borderRight flex={2}>
          <Text style={styles.normalText}>{`วันที่ต้องการใช้เลือดผ่าตัด`}</Text>
          <Text style={styles.normalText}>{bloodDonationForm?.bloodForSurgeryDate ? moment(bloodDonationForm?.bloodForSurgeryDate).format('DD/MM/YYYY') : '-'}</Text>
        </Column>
        <Column borderBottom borderRight flex={2}>
          <Text style={styles.normalText}>{`ผู้ที่รับเลือด`}</Text>
          <Text style={styles.normalText}>{convertForPDF(bloodDonationForm?.bloodReceiver ?? '-')}</Text>
        </Column>
        <Column borderBottom flex={2}>
          <Text style={styles.normalHighlightText}>{`บริจาคครั้งที่`}</Text>
          <Text style={styles.normalHighlightText}>{`${bloodDonationForm?.donatedCount ?? '-'}`}</Text>
        </Column>
      </Row>

      <Row>
        <Column borderBottom borderRight flex={2}>
          <Text style={styles.normalText}>{`เลขประจำตัวผู้บริจาคเลือด/เลขบัตรประชาชน  `}</Text>
          <Text style={styles.normalText}>{`${bloodDonationForm?.donationPersonId ?? '-'}     `}</Text>
        </Column>
        <Column borderBottom borderRight flex={2}>
          <Text style={styles.normalHighlightText}>{`หมู่เลือด ABO`}</Text>
          <Text style={styles.normalHighlightText}>{`${bloodDonationForm?.aboGroup ?? '-'}`}</Text>
        </Column>
        <Column borderBottom flex={2}>
          <Text style={styles.normalText}>{`หมู่เลือด Rh`}</Text>
          <Text style={styles.normalText}>{`${bloodDonationForm?.rhGroup ?? '-'}`}</Text>
        </Column>
      </Row>

      <Row>
        <Column borderBottom borderRight width={155} style={{ display: 'flex', justifyContent: 'center' }}>
          <Text style={styles.titleText}>{`Unit Number`}</Text>
          {data?.ImageUnitNumber ? <Image src={data?.ImageUnitNumber} style={{ width: 150, height: 75, position: 'relative' }} /> : <Text style={styles.normalHighlightText}>-</Text>}
        </Column>
        <Column borderBottom borderRight flex={2}>
          <Text style={styles.normalText}>{`ชนิดถุงเลือด`}</Text>
          <Text style={styles.normalText}>{`${bloodDonationForm?.bagType ?? '-'}`}</Text>
        </Column>
        <Column borderBottom borderRight flex={2}>
          <Text style={styles.normalText}>{`ความดัน SBP/DBP`}</Text>
          <Text style={styles.normalText}>{`${bloodDonationForm?.sbp ?? '-'}/${bloodDonationForm?.dbp ?? '-'}`}</Text>
        </Column>
        <Column borderBottom flex={2}>
          <Text style={styles.normalText}>{`ความดันเลือดปกติหรือไม่`}</Text>
          <Text style={styles.normalText}>{`${bloodDonationForm?.isBloodPressureNormal === true ? 'ปกติ' : bloodDonationForm?.isBloodPressureNormal === false ? 'ไม่ปกติ' : '-'}`}</Text>
        </Column>
      </Row>

      <Row>
        <Column borderBottom borderRight flex={2}>
          <Text style={styles.normalText}>{`ชีพจร (ครั้ง/นาที)`}</Text>
          <Text style={styles.normalText}>{`${bloodDonationForm?.pulse ?? '-'} `}</Text>
        </Column>
        <Column borderBottom borderRight flex={2}>
          <Text style={styles.normalText}>{`ชีพจรปกติหรือไม่`}</Text>
          <Text style={styles.normalText}>{`${bloodDonationForm?.isPulseNormal === true ? 'ปกติ' : bloodDonationForm?.isPulseNormal === false ? 'ไม่ปกติ' : '-'}`}</Text>
        </Column>
        <Column borderBottom borderRight flex={2}>
          <Text style={styles.normalText}>{`Hb (ก/ดล.)`}</Text>
          <Text style={styles.normalText}>{`${bloodDonationForm?.hb ?? '-'} `}</Text>
        </Column>
        <Column borderBottom flex={2}>
          <Text style={styles.normalText}>{`Hb ปกติหรือไม่`}</Text>
          <Text style={styles.normalText}>{`${bloodDonationForm?.isHbNormal === true ? 'ปกติ' : bloodDonationForm?.isHbNormal === false ? 'ไม่ปกติ' : '-'}`}</Text>
        </Column>
      </Row>

      <Row>
        <Column borderRight flex={2}>
          <Text style={styles.normalText}>{`วันที่บริจาคเลือดครั้งแรก`}</Text>
          <Text style={styles.normalText}>
            {bloodDonationForm?.firstDonationDate
              ? `${moment(bloodDonationForm?.firstDonationDate)
                  .add(543, 'years')
                  .format('LL')} `
              : '-'}
          </Text>
        </Column>
        <Column borderRight flex={2}>
          <Text style={styles.normalText}>{`สถานที่บริจาคเลือดครั้งแรก`}</Text>
          <Text style={styles.normalText}>{convertForPDF(`${bloodDonationForm?.firstDonationPlace ?? '-'}`)}</Text>
        </Column>
        <Column borderRight flex={2}>
          <Text style={styles.normalText}>{`วันที่บริจาคเลือดครั้งล่าสุด)`}</Text>
          <Text style={styles.normalText}>
            {bloodDonationForm?.latestDonateDate
              ? `${moment(bloodDonationForm?.latestDonateDate)
                  .add(543, 'years')
                  .format('LL')} `
              : '-'}
          </Text>
        </Column>
        <Column flex={2}>
          <Text style={styles.normalText}>{`สถานที่บริจาคเลือดครั้งล่าสุด`}</Text>
          <Text style={styles.normalText}>{convertForPDF(`${bloodDonationForm?.latestDonatePlace ?? '-'}`)}</Text>
        </Column>
      </Row>
    </View>
  )
}

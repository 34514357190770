import React from 'react'
import { Image, Divider, Descriptions, Row, Col } from 'antd'
import Avatar from '../../images/default_avatar.png'
import { hooks } from './hooks'
import moment from 'moment'
import 'moment/locale/th'
export const PatientInfo = ({ patientInfo, bookingData }) => {
  const { events } = hooks()

  const checkNameSpecialChar = () => {
    const regex = /^[a-zA-Zก-๙]+$/
    const firstNameTest = patientInfo?.fullname?.firstName.match(regex)
    const lastNameTest = patientInfo?.fullname?.lastName.match(regex)
    if (firstNameTest?.length && lastNameTest?.length) {
      return `${patientInfo?.fullname?.firstName || ''} ${patientInfo?.fullname?.lastName || ''}`
    }
    return `${bookingData?.appUser?.userInformation?.firstname || ''} ${bookingData?.appUser?.userInformation?.lastname || ''}`
  }

  return (
    patientInfo && (
      <Row className="w-100 text-center" justify="center">
        <Col xs={4} md={4} lg={4} className="mb-3">
          <Image src={patientInfo?.imageBase64 ? `data:image/png;base64,${patientInfo?.imageBase64}` : Avatar} style={{ maxWidth: '200px', width: '100%', minWidth: '100px', padding: '10px' }} />
        </Col>
        <Col xs={24} md={24} lg={20}>
          <Descriptions title="ข้อมูลส่วนตัว" column={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} layout="horizontal" className="patient-profile-edit-form">
            <Descriptions.Item className="px-2" label="คำนำหน้า" labelStyle={{ fontWeight: 'bold', fontSize: '12px' }}>{`${patientInfo?.fullname?.title ?? ''}`}</Descriptions.Item>
            <Descriptions.Item className="px-2" label="เลขบัตรประชาชน" labelStyle={{ fontWeight: 'bold', fontSize: '12px' }}>
              {patientInfo?.cid}
            </Descriptions.Item>
            <Descriptions.Item
              className="px-2"
              label={
                <div className="d-flex flex-column align-items-center">
                  <p>ชื่อจาก</p>
                  <p>ระบบHIE</p>
                </div>
              }
              labelStyle={{ fontWeight: 'bold', fontSize: '12px' }}
            >{`${patientInfo?.fullname?.firstName || ''} ${patientInfo?.fullname?.lastName || ''}`}</Descriptions.Item>
            <Descriptions.Item className="px-2" label="HN" labelStyle={{ fontWeight: 'bold', fontSize: '12px' }}>
              {events.convertHn(patientInfo?.hn[0]?.hn)}
            </Descriptions.Item>
            <Descriptions.Item
              className="px-2"
              label={
                <div className="d-flex flex-column align-items-center">
                  <p>ชื่อจาก</p>
                  <p>Vajira@Home</p>
                </div>
              }
              labelStyle={{ fontWeight: 'bold', fontSize: '12px' }}
            >{`${bookingData?.appUser?.userInformation?.firstname || ''} ${bookingData?.appUser?.userInformation?.lastname || ''}`}</Descriptions.Item>
            <Descriptions.Item className="px-2" label="เพศ" labelStyle={{ fontWeight: 'bold', fontSize: '12px' }}>
              {patientInfo?.sexName}
            </Descriptions.Item>
            <Descriptions.Item className="px-2" label="อาชีพ" labelStyle={{ fontWeight: 'bold', fontSize: '12px' }}>
              {bookingData?.occupation ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item className="px-2" label="วันเกิด" labelStyle={{ fontWeight: 'bold', fontSize: '12px' }}>
              {moment(bookingData?.appUser?.userInformation?.birthDate).format('LL')}
            </Descriptions.Item>
            <Descriptions.Item className="px-2" label="เบอร์ติดต่อ" labelStyle={{ fontWeight: 'bold', fontSize: '12px' }}>
              {bookingData?.appUser?.userInformation?.mobileNumber}
            </Descriptions.Item>
            <Descriptions.Item className="px-2" label="ที่อยู่" labelStyle={{ fontWeight: 'bold', fontSize: '12px' }}>{`${bookingData?.appUser?.userInformation?.address?.address ?? ''} ${bookingData?.appUser?.userInformation?.address
              ?.address2 ?? ''} เขต ${bookingData?.appUser?.userInformation?.address?.area ?? '-'} จังหวัด ${bookingData?.appUser?.userInformation?.address?.province ?? '-'} ${bookingData?.appUser?.userInformation?.address?.postalCode ??
              ''}`}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    )
  )
}

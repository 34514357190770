import React, { useEffect, useState, useRef } from 'react'
import { Modal, Table, message, Input, Spin, Row, Col, Radio, Checkbox, Popconfirm, Button, Image } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import TextEditor from '../TextEditor'
import { isEmpty } from 'lodash'
import Avatar from '../../images/default_avatar.png'
const Text = styled('p')`
  font-weight: bold;
`
const { Search } = Input

const EditorBox = styled(Row)`
  padding: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  background: #fff;
  min-width: 100px;
  position: relative;
  border-radius: 12px;
  // border: 2px solid #1b4b63;
  background-image: repeating-linear-gradient(60deg, ${(props) => props.borderColor ?? '#dbdbdb'}, ${(props) => props.borderColor ?? '#dbdbdb'} 5px, transparent 5px, transparent 9px, ${(props) => props.borderColor ?? '#dbdbdb'} 9px),
    repeating-linear-gradient(150deg, ${(props) => props.borderColor ?? '#dbdbdb'}, ${(props) => props.borderColor ?? '#dbdbdb'} 5px, transparent 5px, transparent 9px, ${(props) => props.borderColor ?? '#dbdbdb'} 9px),
    repeating-linear-gradient(240deg, ${(props) => props.borderColor ?? '#dbdbdb'}, ${(props) => props.borderColor ?? '#dbdbdb'} 5px, transparent 5px, transparent 9px, ${(props) => props.borderColor ?? '#dbdbdb'} 9px),
    repeating-linear-gradient(330deg, ${(props) => props.borderColor ?? '#dbdbdb'}, ${(props) => props.borderColor ?? '#dbdbdb'} 5px, transparent 5px, transparent 9px, ${(props) => props.borderColor ?? '#dbdbdb'} 9px);
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
`

const ColFlexCenter = styled(Col)`
  display: flex;
  justify-content: center;
`

const FloatTitleBox = styled(`div`)`
  position: absolute;
  top: -10px;
  left: -2px;
`
const FloatTitleText = styled(`div`)`
  background: ${(props) => props.backgroundColor ?? '#45c0bb'};
  padding: 0px 24px 0px 12px;
  color: ${(props) => (props.titleColor === 'dark' ? '#212121' : '#fff')};
  border-radius: 0px 20px 20px 0px;
  border-top: 2px solid #1b4b63;
  border-bottom: 2px solid #1b4b63;
  border-left: 2px solid #1b4b63;
  border-color: ${(props) => props.borderColor ?? '#1b4b63'};
  font-weight: bold;
`

const CreateHealthCheckUp = (props) => {
  const { visibleModal, setVisibleModal, onSearch, isModalLoading, patientInfo, latestSummary, doctorAdvices, setPatientInfo, setLatestSummary, convertHn, createCheckUpNote } = props
  const [summaryNote, setSummaryNote] = useState(null)
  const [xrayNote, setXrayNote] = useState(null)
  const [labNote, setLabNote] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [labStatus, setLabStatus] = useState(null)
  const [adviceSelected, setAdviceSelected] = useState([])
  const inputRef = useRef()
  useEffect(() => {
    if (visibleModal) {
      setTimeout(() => inputRef?.current?.focus(), 300)
    }
  }, [visibleModal])

  const handleAdviceSelected = (isSelected, value) => {
    if (isSelected) {
      setAdviceSelected((prev) => [...prev, parseInt(value.id)])
    } else {
      const selectedAdvices = adviceSelected.filter((item) => item !== parseInt(value.id))
      setAdviceSelected(selectedAdvices)
    }
  }

  const handleDefaulthChecked = (item) => {
    const checked = adviceSelected.map((x) => {
      if (parseInt(item.id) === x) {
        return true
      } else {
        return false
      }
    })
    const status = checked.some((item) => item === true)
    return status
  }
  const clearData = () => {
    setSearchText('')
    setPatientInfo({})
    setLatestSummary({})
    setLabNote('')
    setSummaryNote('')
    setXrayNote('')
    setLabStatus(null)
    setAdviceSelected([])
  }

  return (
    <Modal
      visible={visibleModal}
      onCancel={() => {
        setVisibleModal(false)

        clearData()
      }}
      footer={null}
      width={1000}
      title="เพิ่มรายการใหม่"
      destroyOnClose={true}
    >
      <div className="w-100 d-flex justify-content-center">
        {isModalLoading ? (
          <Spin />
        ) : (
          <>
            {
              <Row className="w-100" gutter={[20, 20]}>
                <Col span={24}>
                  <Search
                    ref={inputRef}
                    placeholder="รหัสบัตรประชาชน"
                    enterButton="ค้นหาข้อมูล"
                    size="large"
                    maxLength={13}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    className="mb-4"
                    onSearch={(value) => {
                      if (!isNaN(value) && value?.length == 13) {
                        onSearch(value)
                        setSearchText('')
                      } else {
                        message.error('กรุณาตรวจสอบเลขบัตรประชาชน (ตัวเลข 13หลัก เท่านั้น)')
                      }
                    }}
                  />
                </Col>
                {!isEmpty(patientInfo) && (
                  <>
                    <Col xs={24} sm={8}>
                      <EditorBox>
                        <FloatTitleBox>
                          <FloatTitleText borderColor="#757575" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                            {'วันที่ตรวจรักษา'}
                          </FloatTitleText>
                        </FloatTitleBox>
                        <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${latestSummary?.visit?.orderDate
                          ? moment(latestSummary?.visit?.orderDate)
                            .add(543, 'years')
                            .format('LL')
                          : '-'
                          }`}</Text>
                      </EditorBox>
                      <EditorBox justify="center" gutter={[16, 16]}>
                        <ColFlexCenter span={24}>
                          <Image width={150} src={patientInfo?.imageBase64 ? `data:image/png;base64,${patientInfo?.imageBase64}` : Avatar} style={{ borderRadius: '50%' }} />
                        </ColFlexCenter>
                        <ColFlexCenter span={24}>
                          <Text style={{ fontSize: '20px' }}>{`${patientInfo?.fullname?.title}${patientInfo?.fullname?.firstName} ${patientInfo?.fullname?.lastName}`}</Text>
                        </ColFlexCenter>
                        {patientInfo?.hn?.length > 0 && (
                          <Col span={24} className="p-0">
                            <EditorBox borderColor="#ededed">
                              <FloatTitleBox>
                                <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                                  {'HN'}
                                </FloatTitleText>
                              </FloatTitleBox>
                              <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${convertHn(patientInfo?.hn[0]?.hn)}`}</Text>
                            </EditorBox>
                            <EditorBox borderColor="#ededed">
                              <FloatTitleBox>
                                <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                                  {'บัตรประชาชน'}
                                </FloatTitleText>
                              </FloatTitleBox>
                              <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.cid}`}</Text>
                            </EditorBox>
                            <EditorBox borderColor="#ededed">
                              <FloatTitleBox>
                                <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                                  {'เพศ'}
                                </FloatTitleText>
                              </FloatTitleBox>
                              <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.sexName}`}</Text>
                            </EditorBox>
                            <EditorBox borderColor="#ededed">
                              <FloatTitleBox>
                                <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                                  {'วันเกิด'}
                                </FloatTitleText>
                              </FloatTitleBox>
                              <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.DOB
                                ? moment(patientInfo?.DOB)
                                  .add(543, 'years')
                                  .format('LL')
                                : '-'
                                }`}</Text>
                            </EditorBox>
                            <EditorBox borderColor="#ededed">
                              <FloatTitleBox>
                                <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                                  {'เบอร์โทร'}
                                </FloatTitleText>
                              </FloatTitleBox>
                              <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.contactInfo?.mobileNumber.length > 0 ? patientInfo?.contactInfo?.mobileNumber : '-'}`}</Text>
                            </EditorBox>
                            <EditorBox borderColor="#ededed">
                              <FloatTitleBox>
                                <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                                  {'ที่อยู่'}
                                </FloatTitleText>
                              </FloatTitleBox>
                              <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.address?.houseNumber} ตำบล ${patientInfo?.address?.tmbpart ?? '-'}, อำเภอ ${patientInfo?.address
                                ?.amppart ?? '-'}, จังหวัด ${patientInfo?.address?.chwpart ?? '-'} ${patientInfo?.address?.addressPart}`}</Text>
                            </EditorBox>
                          </Col>
                        )}
                      </EditorBox>
                    </Col>
                    <Col xs={24} sm={16}>
                      <Row gutter={[0, 12]} className="w-100">
                        <Col flex="auto">
                          <TextEditor title="สรุปการตรวจร่างกายทั่วไป" value={summaryNote} setValue={setSummaryNote} />
                        </Col>
                        <Col flex="auto">
                          <TextEditor title="สรุปผลแลป" value={labNote} setValue={setLabNote} />
                          <div className="d-flex align-items-center px-3">
                            <p className="mr-2">{`สถานะผลแลป: `}</p>
                            <Radio.Group
                              defaultValue={labStatus}
                              onChange={(e) => {
                                setLabStatus(e.target.value)
                              }}
                            >
                              <Radio value={true}>
                                <p>{`ไม่ผิดปกติ`}</p>
                              </Radio>
                              <Radio value={false}>
                                <p style={{ color: 'red' }}>{`มีบางอย่างผิดปกติ`}</p>
                              </Radio>
                            </Radio.Group>
                          </div>
                        </Col>
                        <Col flex="auto">
                          <TextEditor title="สรุปผลตรวจ X-Ray" value={xrayNote} setValue={setXrayNote} />
                        </Col>
                      </Row>
                      <Row justify="start" className="pt-2 pb-3 px-3">
                        <Col span={24}>
                          <Text style={{ fontWeight: 'bold' }}>เอกสารที่แนะนำสำหรับผู้ป่วยรายนี้</Text>
                        </Col>
                        {doctorAdvices?.length > 0 &&
                          doctorAdvices?.map((item, index) => (
                            <Col xs={24} sm={12} key={'adviceList' + index}>
                              <Checkbox
                                checked={handleDefaulthChecked(item)}
                                onChange={(e) => {
                                  handleAdviceSelected(e.target.checked, item)
                                }}
                              >
                                {item.label}
                              </Checkbox>
                            </Col>
                          ))}
                      </Row>
                      <div className="text-right">
                        <Popconfirm
                          title="ยืนยันการบันทึกข้อมูล"
                          okText="ยืนยัน"
                          cancelText="ยกเลิก"
                          onConfirm={() => {
                            const newHealthCheckUpData = {
                              summaryNote,
                              xrayNote,
                              labNote,
                              labStatus,
                              doctorAdvices: adviceSelected,
                              doctorName: latestSummary?.visit?.doctor?.name,
                              vn: latestSummary?.visit?.vn,
                              visitDateTime: latestSummary?.visit?.date ? moment(latestSummary?.visit?.date) : null,
                              orderDateTime: latestSummary?.visit?.orderDate ? moment(latestSummary?.visit?.orderDate) : null,
                              departmentCode: latestSummary?.visit?.department?.code,
                              cId: patientInfo?.cid,
                              title: patientInfo?.fullname?.title ?? '',
                              firstName: patientInfo?.fullname?.firstName,
                              lastName: patientInfo?.fullname?.lastName,
                              hn: patientInfo?.hn[0]?.hn,
                              modifiedLogs: [],
                            }
                            createCheckUpNote(newHealthCheckUpData)
                            clearData()
                          }}
                        >
                          <Button type="primary" block size="large" className="my-1 w-100">
                            <p style={{ fontSize: '18px' }}>บันทึกข้อมูล</p>
                          </Button>
                        </Popconfirm>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            }
          </>
        )}
      </div>
    </Modal>
  )
}

export default CreateHealthCheckUp

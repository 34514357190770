import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Table from '../../components/Table'
import { Row, Col, Button, Spin } from 'antd'
import ReactExport from 'react-data-export'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ReportHbpmTab = ({ reportHbpmData, fetchHbpmReports, isLoading }) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(7)

  useEffect(() => {
    fetchHbpmReports(1, 10000)
  }, [])

  const onChangePage = (pageDetail) => {
    setPage(pageDetail.current)
    setLimit(pageDetail.pageSize)
    fetchHbpmReports(pageDetail.current, pageDetail.pageSize)
  }

  const columns = [
    {
      title: 'HN',
      key: 'hn',
      render: (record) => <p>{record.hn ? record.hn : '-'}</p>,
      align: 'center',
    },
    {
      title: 'ชื่อผู้ป่วย',
      key: 'patient',
      render: (record) => <p>{record.patient}</p>,
      align: 'center',
    },
    {
      title: 'จำนวนข้อมูล HBPM',
      key: 'nbOfHbpmRecord',
      sorter: (a, b) => a.nbOfHbpmRecord - b.nbOfHbpmRecord,
      render: (record) => <p>{record.nbOfHbpmRecord}</p>,
      align: 'center',
    },
    {
      title: 'วันที่บันทึกครั้งแรก',
      key: 'firstDateRecord',
      sorter: (a, b) => moment(a.firstDateRecord).unix() - moment(b.firstDateRecord).unix(),
      defaultSortOrder: 'descend',
      render: (record) => <p>{moment(record.firstDateRecord).format('DD/MM/YYYY')}</p>,
      align: 'center',
    },
    {
      title: 'ค่าล่างต่ำสุด',
      key: 'minBelowPressure',
      render: (record) => <p>{record.minBelowPressure}</p>,
      align: 'center',
    },
    {
      title: 'ค่าบนสูงสุด',
      key: 'maxAbovePressure',
      render: (record) => <p>{record.maxAbovePressure}</p>,
      align: 'center',
    },
    {
      title: 'TIR เขียว(ครั้ง)',
      key: 'nbOfInStardardRecord',
      render: (record) => <p>{record.nbOfInStardardRecord}</p>,
      align: 'center',
    },
    {
      title: 'TAR ฟ้า(ครั้ง)',
      key: 'nbOfAboveStardardRecord',
      render: (record) => <p>{record.nbOfAboveStardardRecord}</p>,
      align: 'center',
    },
    {
      title: 'TBR แดง(ครั้ง)',
      key: 'nbOfBelowStandardRecord',
      render: (record) => <p>{record.nbOfBelowStandardRecord}</p>,
      align: 'center',
    },
  ]
  return (
    <div>
      <Spin spinning={isLoading}>
        <Row type="flex" justify="center" gutter={[10, 10]} className="my-3">
          {reportHbpmData?.docs?.length > 0 ? (
            <Col className="d-flex align-items-center">
              <ExcelFile
                element={<Button>Export File</Button>}
                filename={`Refer_HBPM${moment()
                  .add(543, 'years')
                  .format('DD/MM/YYYY')}`}
              >
                <ExcelSheet data={reportHbpmData?.docs} name="Refer HBPM Report">
                  <ExcelColumn label="HN" value="hn" />
                  <ExcelColumn label="ชื่อผู้ป่วย" value="patient" />
                  <ExcelColumn label="จำนวนข้อมูล HBPM" value="nbOfHbpmRecord" />
                  <ExcelColumn label="วันที่บันทึกครั้งแรก" value="firstDateRecord" />
                  <ExcelColumn label="ค่าล่างต่ำสุด" value="minBelowPressure" />
                  <ExcelColumn label="ค่าบนสูงสุด" value="maxAbovePressure" />
                  <ExcelColumn label="TIR เขียว(ครั้ง)" value="nbOfInStardardRecord" />
                  <ExcelColumn label="TAR ฟ้า(ครั้ง)" value="nbOfAboveStardardRecord" />
                  <ExcelColumn label="TBR แดง(ครั้ง)" value="nbOfBelowStandardRecord" />
                </ExcelSheet>
              </ExcelFile>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </Spin>
      <Table columns={columns} dataSource={reportHbpmData.docs} loading={isLoading} pagination={{ defaultPageSize: 7 }}></Table>
      {/* <Table columns={columns} dataSource={reportHbpmData.docs} loading={isLoading} onChange={onChangePage} pagination={{ total: reportHbpmData.totalDocs, current: page, pageSize: limit }}></Table> */}
    </div>
  )
}

export default ReportHbpmTab

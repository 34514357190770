import React, { useState } from 'react'
import { ReactInternetSpeedMeter } from 'react-internet-meter'
import { Tag } from 'antd'
const InternetMeter = () => {
  const [internetSpeed, setInternetSpeed] = useState(0)
  const handleColor = (speed) => {
    if (speed > 10) {
      return 'green'
    }
    return 'red'
  }
  return (
    <>
      <ReactInternetSpeedMeter
        txtSubHeading={'ความเร็วอินเตอร์เน็ตช้า'}
        outputType="empty"
        customClassName={null}
        txtMainHeading="Opps..."
        pingInterval={3000} // milliseconds
        thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
        threshold={8}
        imageUrl="https://telemed.vajira.everapp.io/static/media/Logo.b73ba39f.png"
        downloadSize="32700" //bytes
        callbackFunctionOnNetworkDown={(speed) => setInternetSpeed(speed * 8)}
        callbackFunctionOnNetworkTest={(speed) => setInternetSpeed(speed * 8)}
      />
      <Tag color={handleColor(internetSpeed)} style={{ alignSelf: 'center', maxWidth: '250px' }}>{`ความเร็วอินเตอร์เน็ต ${internetSpeed} Mbps`}</Tag>
    </>
  )
}

export default InternetMeter

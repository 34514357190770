import React, { useState, useEffect, useMemo } from 'react'
import { Tabs, Radio, DatePicker, Space, Select, Tag, Button, Row, Card, Col, Modal, Popconfirm } from 'antd'
import Table from '../Table'
import ReactExport from 'react-data-export'
import { Container, Search, HeaderWrapper, Text } from './style'
import { HistoryOutlined, DownloadOutlined, PrinterFilled } from '@ant-design/icons'
import IdentifyDonorSticker from '../../components/IdentifyDonorSticker'
import moment from 'moment'
import 'moment/locale/th'
import { pdf } from '@react-pdf/renderer'
import { orderBy } from 'lodash'
import BloodDonationPdfForm from '../BloodDonationPdfForm'
import httpOma from '../../services/httpServiceHealthCare'
const hiePatientInfo = '/UserInfos/hiePatientInfo'
import Barcode from 'react-hooks-barcode'
import { mapDonationBooking } from '../../utils/blood-donor/mapHistoryBloodDonor'
const { TabPane } = Tabs
const { RangePicker } = DatePicker
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const HistoryTable = ({ dataSource, dataExport, convertTime, loading, isExcelLoading }) => {
  const [tab, setTab] = useState('allDonates')
  const [tableData, setTableData] = useState([])
  const [userCount, setUserCount] = useState([])
  const [timeRange, setTimeRange] = useState(1)
  const [dateRange, setDateRange] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [screenStatus, setScreenStatus] = useState('screenPass')

  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [visibleEditPdfData, setVisibleEditPdfData] = useState(false)
  const [sortPdfBy, setSortPdfBy] = useState('unitNumber')
  const [sortPdfOrder, setSortPdfOrder] = useState('ascend')
  const [pageSize, setPageSize] = useState(7)

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [preGenerateData, setPreGenerateData] = useState([])
  const [filteredPreGenerateData, setFilteredPreGenerateData] = useState([])

  useEffect(() => {
    handleTableData()
  }, [tab, dataSource, timeRange, dateRange, screenStatus, searchText])

  useEffect(() => {
    initialUserCount()
  }, [dataSource])

  const dataMappingSource = useMemo(() => mapDonationBooking(dataExport), [dataExport])

  const handleTableData = () => {
    const dataToFitler = dataSource.filter((item) => item.donateStatus === 'donationCompleted' || (item.donateStatus === 'waitingDonation' && item.screenStatus === 'screenFailed'))
    if (timeRange === 4) {
      setTableData(dataToFitler)
    }
    if (tab === 'allDonates') {
      setTableData(dataToFitler)
    } else if (tab === 'generalDonates') {
      setTableData(dataToFitler.filter((item) => item.isSpecialDonation === false))
    } else if (tab === 'specialDonates') {
      setTableData(dataToFitler.filter((item) => item.isSpecialDonation === true))
    } else if (tab === 'outDonates') {
      const set = dataToFitler.filter((item) => item.bloodDonationForm?.unitNumber?.includes('N'))
      setTableData(set)
    }
    if (timeRange === 1) {
      setTableData((tableData) => {
        return tableData.filter((item) => moment(item.donationDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY'))
      })
    } else if (timeRange === 2) {
      setTableData((tableData) => {
        return tableData.filter(
          (item) =>
            moment(item.donationDate).format('DD/MM/YYYY') ===
            moment()
              .subtract(1, 'days')
              .format('DD/MM/YYYY'),
        )
      })
    } else if (timeRange === 3) {
      setTableData((tableData) => {
        return tableData.filter((item) => moment(item.donationDate).unix() <= moment().unix())
      })
    } else if (timeRange === 4) {
      setTableData((tableData) => {
        return tableData.filter((item) => moment(item.donationDate).isBetween(moment(dateRange[0]).startOf('day'), moment(dateRange[1]).endOf('day')))
      })
    }
    if (screenStatus === 'allStatus') {
      setTableData((tableData) => {
        return tableData.filter((item) => item.screenStatus === 'screenPass' || item.screenStatus === 'screenFailed')
      })
    } else if (screenStatus === 'screenPass' || screenStatus === 'screenFailed') {
      setTableData((tableData) => {
        return tableData.filter((item) => item.screenStatus === screenStatus)
      })
    }
    if (searchText) {
      setTableData((tableData) => {
        return tableData.filter(
          (item) =>
            item.appUser?.userInformation?.firstname?.includes(searchText) ||
            item.appUser?.userInformation?.lastname?.includes(searchText) ||
            `${item.appUser?.userInformation?.firstname} ${item.appUser?.userInformation?.lastname}`?.includes(searchText) ||
            item.bloodDonationForm?.unitNumber.includes(searchText),
        )
      })
    }
  }

  const initialUserCount = () => {
    if (dataSource && dataSource?.length) {
      const bookings = dataSource
      const result = bookings.reduce((unique, o) => {
        if (!unique.some((obj) => obj.appUserId === o.appUserId)) {
          unique.push(o)
        }
        return unique
      }, [])
      setUserCount(result.length)
    }
  }

  const handleSearchPreGenerateData = (value) => {
    const filteredByValue = preGenerateData.filter((item) => {
      if (item?.pdfName.includes(value) || item?.bloodDonationForm?.unitNumber.includes(value)) {
        return item
      }
    })
    setFilteredPreGenerateData(filteredByValue)
  }

  const columns = [
    {
      title: 'Unit Number',
      width: '50',
      key: 'unitNumber',
      align: 'center',
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        tab === 'outDonates' ? parseInt(a?.bloodDonationForm?.unitNumber?.substr(-5)) - parseInt(b?.bloodDonationForm?.unitNumber?.substr(-5)) : parseInt(a?.bloodDonationForm?.unitNumber) - parseInt(b?.bloodDonationForm?.unitNumber),
      render: (record) => {
        return <Text>{`${record.bloodDonationForm?.unitNumber ?? ''}`}</Text>
      },
    },
    {
      title: 'ชื่อผู้บริจาค',
      width: '50',
      key: 'donorName',
      align: 'center',
      render: (record) => {
        return <Text>{`${record.appUser?.userInformation?.firstname ?? ''} ${record.appUser?.userInformation?.lastname ?? ''}`}</Text>
      },
    },
    {
      title: 'วันที่',
      width: '50',
      key: 'donationDate',
      align: 'center',
      sorter: (a, b) => moment(a?.donationDate).unix() - moment(b?.donationDate).unix(),
      render: (record) => (
        <Text onClick={() => console.log(record)}>
          {moment(record?.donationDate)
            .add(543, 'years')
            .format('LL')}
        </Text>
      ),
    },
    {
      title: 'ช่วงเวลา',
      width: '50',
      key: 'donorPeriod',
      align: 'center',
      sorter: (a, b) => convertTime(a?.donationTime[0], a?.donationTime[1], true) - convertTime(b?.donationTime[0], b?.donationTime[1], true),
      render: (record) => <Text>{convertTime(record?.donationTime[0], record?.donationTime[1])}</Text>,
    },
    {
      title: 'ประเภทการบริจาค',
      width: '50',
      key: 'donorType',
      align: 'center',
      render: (record) => <Text>{record?.isSpecialDonation ? 'ผู้บริจาคกรณีพิเศษ' : 'ผู้บริจาคกรณีทั่วไป'}</Text>,
    },
    {
      title: 'ผ่านการคัดกรอง',
      width: '50',
      key: 'screenStatus',
      align: 'center',
      render: (record) => <Tag color={record?.screenStatus === 'screenPass' ? 'success' : 'error'}>{record?.screenStatus === 'screenPass' ? 'ผ่านการคัดกรอง' : 'ไม่ผ่านการคัดกรอง'}</Tag>,
    },
    {
      title: 'ดูแบบยินยอม',
      width: '50',
      key: 'displayConsentForm',
      align: 'center',
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            window.open(`/blood-donation-edit-form/${record.id}`)
          }}
        >{`ดูข้อมูลแบบฟอร์ม`}</Button>
      ),
    },
    {
      title: 'พิมพ์สติ๊กเกอร์',
      width: '50',
      key: 'printSticker',
      align: 'center',
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            console.log(record, 'record')
            const dataToPrintSticker = {
              ...record?.bloodDonationForm,
              hn: record?.appUser?.userInformation?.hnId ? convertHn(record?.appUser?.userInformation?.hnId) : '-',
              donateCompletedDateTime: record?.donateCompletedDateTime ? record?.donateCompletedDateTime : record?.donationDate,
            }
            generateStickerToPrint(dataToPrintSticker)
          }}
        >
          <PrinterFilled />
        </Button>
      ),
    },
  ]

  const generateStickerToPrint = async (data) => {
    const blob = await pdf(<IdentifyDonorSticker data={data} />).toBlob()
    const url = URL.createObjectURL(blob)
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const generatePictureCid = (cid, unitNumber, callbackImage) => {
    const canvas = document.createElement('canvas')

    const svg = document.querySelector(`#qr-gen${cid} svg`)
    const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)))
    // const w = parseInt(svg.getAttribute('width'))
    // const h = parseInt(svg.getAttribute('height'))
    // get canvas context for drawing on canvas
    let context = canvas.getContext('2d')
    // set canvas size
    canvas.width = 200
    canvas.height = 150
    // create image in memory(not in DOM)
    let image = new Image()
    image.src = 'data:image/svg+xml;base64,' + base64doc
    // later when image loads run this
    image.onload = function() {
      // async (happens later)
      // clear canvas
      context.clearRect(0, 0, 200, 150)
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, 200, 150)
      // snapshot canvas as png
      let pngData = canvas.toDataURL('image/png')
      // pass png data URL to callback
      if (unitNumber) {
        const canvas2 = document.createElement('canvas')
        const svgUnitNumber = document.querySelector(`#qr-genUnit${cid} svg`)
        const base64doc2 = btoa(unescape(encodeURIComponent(svgUnitNumber.outerHTML)))
        let context2 = canvas2.getContext('2d')
        canvas2.width = 200
        canvas2.height = 150
        let image2 = new Image()
        image2.src = 'data:image/svg+xml;base64,' + base64doc2
        image2.onload = function() {
          context2.clearRect(0, 0, 200, 150)
          context2.drawImage(image2, 0, 0, 200, 150)
          let pngData2 = canvas2.toDataURL('image/png')
          callbackImage(pngData, pngData2)
        }
      } else {
        callbackImage(pngData)
      }
    } // end async
  }

  const checkNameSpecialChar = (patientInfo, bookingData) => {
    const regex = /^[a-zA-Zก-๙]+$/
    const firstNameTest = patientInfo?.fullname?.firstName.match(regex)
    const lastNameTest = patientInfo?.fullname?.lastName.match(regex)
    if (firstNameTest?.length && lastNameTest?.length) {
      return `${patientInfo?.fullname?.firstName || ''} ${patientInfo?.fullname?.lastName || ''}`
    }
    return `${bookingData?.appUser?.userInformation?.firstname || ''} ${bookingData?.appUser?.userInformation?.lastname || ''}`
  }

  const handlePreGenerateData = async (donatedList) => {
    const combinedPatientInfoList = donatedList.map((item) => {
      if (item?.bloodDonationForm?.cId) {
        return getPatientInfo(item?.bloodDonationForm?.cId).then((patientInfo) => {
          return { ...item, patientInfo, pdfName: checkNameSpecialChar(patientInfo, item) }
        })
      }
      return item
    })
    Promise.all(combinedPatientInfoList).then((values) => {
      setPreGenerateData(values)
      setIsModalLoading(false)
    })
    setIsModalLoading(false)
  }

  const handleChangePdfName = (name, id) => {
    const newPreData = preGenerateData.map((preData) => {
      if (preData?.id === id) {
        preData.pdfName = name
      }
      return preData
    })
    setPreGenerateData(newPreData)
  }

  const generatePdfWithBarcode = async (data) => {
    let dataWithBarcode = []
    data.forEach(async (item, index) => {
      generatePictureCid(item?.id, item?.bloodDonationForm?.unitNumber, async (cidImg, unitNumberImg) => {
        dataWithBarcode.push({ ...item, imageCid: cidImg, ImageUnitNumber: unitNumberImg })
        if (dataWithBarcode?.length === data.length) {
          let sortedPdfData = dataWithBarcode
          let sortBy = ''
          let sortOrder = ''
          setSortPdfOrder((latestState) => {
            sortOrder = latestState
            return latestState
          })
          setSortPdfBy((latestState) => {
            sortBy = latestState
            return latestState
          })
          if (sortBy === 'unitNumber') {
            sortedPdfData = orderBy(dataWithBarcode, (item) => parseInt(item?.bloodDonationForm?.unitNumber), sortOrder === 'ascend' ? ['asc'] : ['desc'])
          } else if (sortBy === 'donationDate') {
            sortedPdfData = orderBy(dataWithBarcode, (item) => moment(item?.donationDate).unix(), sortOrder === 'ascend' ? ['asc'] : ['desc'])
          }
          genPDF(sortedPdfData)
        }
      })
    })
  }

  const openPDFHandler = async (selectedDonationData) => {
    const dataWithCidOnly = selectedDonationData.filter((item) => item?.bloodDonationForm && item?.bloodDonationForm?.cId)
    await generatePdfWithBarcode(dataWithCidOnly)
  }

  const genPDF = async (data) => {
    const blob = await pdf(<BloodDonationPdfForm data={data} convertTime={convertTime} convertHn={convertHn} />).toBlob()
    const url = URL.createObjectURL(blob)
    window.open(url, '_blank', 'noopener,noreferrer')
    setTimeout(() => {
      setIsModalLoading(false)
      setIsTableLoading(false)
      setPreGenerateData([])
      setFilteredPreGenerateData([])
      setVisibleEditPdfData(false)
    }, 1000)
  }

  const getPatientInfo = async (cid) => {
    try {
      const response = await httpOma.get(`${hiePatientInfo}?cId=${cid}`)
      if (response?.status <= 200 && response?.status >= 300) {
        throw new Error(`HTTP error: ${response.status}`)
      }
      return response?.data
    } catch (error) {
      console.error(`Error ${error}`)
    }
  }

  const convertHn = (hn) => {
    const hnString = hn?.toString()
    const second = hnString.substring(0, 2)
    const past = hnString.substring(2).split('')
    let pass = 0
    const final = past.map((string) => {
      if (pass || string !== '0') {
        pass = 1
        return string
      }
    })
    return final.join('').concat('/', second)
  }

  const onSelectChange = async (newSelectedRowKeys, rows) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setSelectedRows(rows)
  }

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys,
  }

  let modalColumns = [
    {
      title: 'Unit Number',
      width: '50',
      key: 'unitNumberPdfTable',
      align: 'center',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => parseInt(a?.bloodDonationForm?.unitNumber) - parseInt(b?.bloodDonationForm?.unitNumber),
      render: (record) => {
        return <Text>{`${record.bloodDonationForm?.unitNumber ?? ''}`}</Text>
      },
    },
    {
      title: 'ชื่อผู้บริจาค',
      width: '50',
      key: 'donorNamePdfTable',
      align: 'center',
      render: (record) => {
        const recordHie = record?.patientInfo?.fullname
        const recordAppUser = record?.appUser?.userInformation
        const hieName = `${recordHie?.firstName} ${recordHie?.lastName}`
        const appUserName = `${recordAppUser?.firstname} ${recordAppUser?.lastname}`
        return (
          <Select
            defaultValue={record?.pdfName}
            style={{ width: '100%' }}
            key={record?.id}
            onChange={(value) => {
              handleChangePdfName(value, record.id)
            }}
          >
            <Select.Option value={hieName}>{hieName}</Select.Option>
            {hieName !== appUserName && <Select.Option value={appUserName}>{appUserName}</Select.Option>}
          </Select>
        )
      },
    },
  ]

  const renderTable = () => {
    return (
      <Table
        loading={isTableLoading || loading}
        rowKey={(record) => record.id}
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        columns={columns}
        dataSource={tableData}
        pageSize={pageSize}
        onChange={(page, filter, sorter) => {
          setPageSize(page.pageSize)
          if (['unitNumber', 'donationDate'].includes(sorter?.columnKey)) {
            setSortPdfBy(sorter.columnKey)
            setSortPdfOrder(sorter.order)
          } else {
            setSortPdfBy('unitNumber')
            setSortPdfOrder('ascend')
          }
        }}
      />
    )
  }
  return (
    <>
      <Container>
        <HeaderWrapper>
          <HistoryOutlined className="mr-1" />
          <Text bold>รายการผู้บริจาคเลือด</Text>
          <Search
            allowClear
            style={{ width: 300, marginLeft: 'auto' }}
            placeholder="ชื่อผู้บริจาค หรือ Unit Number"
            onSearch={(value) => {
              setSearchText(value)
            }}
          />
        </HeaderWrapper>
      </Container>
      <Container>
        <Row justify="space-between" align="middle">
          <Col>
            <Radio.Group
              onChange={(e) => {
                console.log(e)
                setTimeRange(e.target.value)
                setSelectedRowKeys([])
                setSelectedRows([])
              }}
              value={timeRange}
            >
              <Radio value={1}>วันนี้</Radio>
              <Radio value={2}>เมื่อวาน</Radio>
              <Radio value={3}>ผู้ที่เคยบริจาคทั้งหมด</Radio>
            </Radio.Group>
          </Col>
          <Col className="d-flex">
            <Card style={{ borderRadius: '20px', textAlign: 'center', marginRight: '10px' }}>
              <p className="font-weight-bold">{`${userCount ?? 0}`}</p>
              <p>ยอดผู้ใช้งานทั้งหมด</p>
            </Card>
            <Card style={{ borderRadius: '20px', textAlign: 'center' }}>
              <p className="font-weight-bold">{`${tableData.length ?? 0}`}</p>
              <p>รายการที่แสดง</p>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col>
            <RangePicker
              placeholder={['เลือกช่วงเวลา', 'เลือกช่วงเวลา']}
              onChange={(value) => {
                setDateRange(value)
                if (value) {
                  setTimeRange(4)
                } else {
                  setTimeRange(1)
                }
              }}
            />
          </Col>
          <Col>
            <Select
              defaultValue={screenStatus}
              style={{
                minWidth: 250,
              }}
              onChange={(value) => {
                setSelectedRowKeys([])
                setSelectedRows([])
                setScreenStatus(value)
              }}
            >
              <Select.Option value="allStatus">{`แสดงคัดกรองผ่านและไม่ผ่าน`}</Select.Option>
              <Select.Option value="screenPass">
                <p style={{ color: 'green' }}>แสดงการคัดกรองที่ผ่านเท่านั้น</p>
              </Select.Option>
              <Select.Option value="screenFailed">
                <p style={{ color: 'red' }}>แสดงการคัดกรองที่ไม่ผ่านเท่านั้น</p>
              </Select.Option>
            </Select>
          </Col>
          {screenStatus === 'screenPass' && (
            <>
              <Col>
                <Button
                  type="danger"
                  icon={<DownloadOutlined />}
                  disabled={selectedRows?.length == 0 ? true : false}
                  onClick={() => {
                    if (selectedRows?.length > 0) {
                      handlePreGenerateData(selectedRows)
                      setVisibleEditPdfData(true)
                      setIsTableLoading(true)
                      setIsModalLoading(true)
                    }
                  }}
                  loading={isTableLoading}
                >
                  {`PDF ที่เลือก (${selectedRows?.length ?? 0})`}
                </Button>
              </Col>
              <Col>
                <Popconfirm
                  title={() => (
                    <>
                      <p className="h6">แน่ใจหรือไม่ที่จะดาวน์โหลด PDF ทั้งหมด?</p> <p>(หากมีจำนวนข้อมูลเยอะอาจใช้เวลานาน)</p>
                    </>
                  )}
                  onConfirm={() => {
                    if (tableData?.length > 0) {
                      handlePreGenerateData(tableData)
                      setVisibleEditPdfData(true)
                      setIsTableLoading(true)
                      setIsModalLoading(true)
                    }
                  }}
                  okText="ตกลง"
                  cancelText="ยกเลิก"
                >
                  <Button type="danger" icon={<DownloadOutlined style={{ fontSize: '16px' }} />} loading={isTableLoading}>
                    {`PDF ทั้งหมด (${tableData?.length ?? 0})`}
                  </Button>
                </Popconfirm>
              </Col>
              <Col>
                <ExcelFile
                  element={
                    <Button type="primary" icon={<DownloadOutlined style={{ fontSize: '16px' }} />} loading={isExcelLoading}>
                      สถิติการบริจาคให้ผู้ป่วย
                    </Button>
                  }
                >
                  <ExcelSheet dataSet={dataMappingSource} name="สถิติการบริจาคให้ผู้ป่วย" />
                </ExcelFile>
              </Col>
            </>
          )}
        </Row>

        <Tabs
          defaultActiveKey="allDonates"
          onChange={(tabName) => {
            setTab(tabName)
            setSelectedRowKeys([])
            setSelectedRows([])
          }}
        >
          <TabPane tab="ผู้บริจาคทั้งหมด" key="allDonates">
            {renderTable()}
          </TabPane>
          <TabPane tab="ผู้บริจาคกรณีทั่วไป" key="generalDonates">
            {renderTable()}
          </TabPane>
          <TabPane tab="ผู้บริจาคกรณีพิเศษ" key="specialDonates">
            {renderTable()}
          </TabPane>
          <TabPane tab="ผู้บริจาคหน่วยนอก" key="outDonates">
            {renderTable()}
          </TabPane>
        </Tabs>
        {selectedRows?.length > 0 &&
          selectedRows?.map((item) => (
            <div key={`selectData${item.id}`}>
              <div id={`qr-gen${item?.id}`} style={{ display: 'none' }}>
                <Barcode value={item?.bloodDonationForm?.cId} />
              </div>
              <div id={`qr-genUnit${item?.id}`} style={{ display: 'none' }}>
                <Barcode value={item?.bloodDonationForm?.unitNumber} />
              </div>
            </div>
          ))}
        {tableData?.length > 0 &&
          tableData?.map((item) => (
            <div key={`allData${item.id}`}>
              <div id={`qr-gen${item?.id}`} style={{ display: 'none' }}>
                <Barcode value={item?.bloodDonationForm?.cId} />
              </div>
              <div id={`qr-genUnit${item?.id}`} style={{ display: 'none' }}>
                <Barcode value={item?.bloodDonationForm?.unitNumber} />
              </div>
            </div>
          ))}
      </Container>
      <Modal
        visible={visibleEditPdfData}
        destroyOnClose={true}
        onCancel={() => {
          setVisibleEditPdfData(false)
          setIsTableLoading(false)
          setPreGenerateData([])
          setFilteredPreGenerateData([])
        }}
        width={1000}
        title={<p className="h3 text-center">{`ตรวจสอบข้อมูล (${filteredPreGenerateData.length ? filteredPreGenerateData.length : preGenerateData.length} รายการ)`}</p>}
        footer={[
          <Button
            onClick={() => {
              setVisibleEditPdfData(false)
              setIsTableLoading(false)
              setPreGenerateData([])
              setFilteredPreGenerateData([])
            }}
          >
            ยกเลิก
          </Button>,
          <Button
            type="danger"
            size="large"
            loading={isModalLoading}
            onClick={() => {
              setIsModalLoading(true)
              openPDFHandler(preGenerateData)
            }}
          >
            สร้าง PDF
          </Button>,
        ]}
      >
        <Space direction="vertical" size={12} className="w-100 text-center">
          <Search
            allowClear
            style={{ width: 300, textAlign: 'center' }}
            placeholder="ชื่อผู้บริจาค หรือ Unit Number"
            onSearch={(value) => {
              handleSearchPreGenerateData(value)
            }}
          />

          <Table columns={modalColumns} dataSource={filteredPreGenerateData.length ? filteredPreGenerateData : preGenerateData} loading={isModalLoading} pagination={{ defaultPageSize: 7 }} />
        </Space>
      </Modal>
    </>
  )
}

export default HistoryTable

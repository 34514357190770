const useHnConverter = (hn) => {
    const convertHN = () => {
        const hnParts = hn.toString().split('');
        const converted = hnParts.slice(-5).join('') + '/' + hnParts.slice(0, -5).join('');
        return converted;
    };

    const revertHN = () => {
        const [lastFive, firstPart] = hn.split('/');
        const paddedLastFive = lastFive.padStart(7, '0');
        const revertedHN = firstPart + paddedLastFive;
        return revertedHN;
    };

    return { convertHN, revertHN };
};

export default useHnConverter;

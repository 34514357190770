import React , { useEffect , useState } from 'react' 
import { render } from 'react-dom'
import Button from '../Button'
import { Spin,Tag } from 'antd'
import http from '../../services/httpServiceHealthCare'

const PaymentStatus = ({data , loading}) => {
    const [ status , setStatus ] = useState(false)
    useEffect(()=>{
        setStatus(false)
        instantStatusPayment(data)
    },[data])


    const statusPaymentTag = (billings) => {
        let statusPayment = []
        const paidStatus = ['ชำระเงินแล้ว', 'ปิดบัญชี']
        const unPaidStatus = ['ยังไม่ชำระเงิน']
        billings.map((billing) => {
          if (billing && billing.length) {
            const checkPaid = billing.every((pm) => paidStatus.includes(pm.paidstName) || (pm.itemno && pm.itemno === -1 && pm.incamt === 0))
            const checkUnPaid = billing.every((pm) => unPaidStatus.includes(pm.paidstName) || (pm.itemno && pm.itemno !== -1 && pm.incamt !== 0))
            statusPayment = checkPaid ? { color: 'green', title: 'ชำระเงินแล้ว' } : checkUnPaid ? { color: 'red', title: 'ยังไม่ชำระเงิน' } : { color: 'orange', title: 'ชำระเงินบางส่วน' }
          }
        })
        return (
          <Tag
            onClick={() => {
              console.log(billings[0].payments)
            }}
            color={statusPayment.color}
          >
            {statusPayment.title}
          </Tag>
        )
      }
      
      const instantStatusPayment = async (res) => {
        const { patientId, vnNumber, admitTime ,patient,id} = res
        if(vnNumber) {
          const { data } = await http.get(`/labResultHies/getPaymentStatus?cid=${patient.cId}&vnNumber=${vnNumber}`)
          const resp = data
          if (data && data.length) {
            vnNumber ? setStatus(statusPaymentTag(data)) : setStatus((<Tag>ไม่มีรายการชำระ</Tag>))
            
          }else setStatus((<Tag>ไม่มีรายการชำระ</Tag>))
        }
        else setStatus((<Tag>ไม่มีรายการชำระ</Tag>))
      } 

      
    return status ? status : loading
    
}

export default PaymentStatus
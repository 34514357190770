import React, { Component, useState, useEffect } from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Modal, Row, Col, Tooltip, InputNumber, Card, Button, message, Popconfirm } from 'antd'
import AvailableTimes from 'react-available-times'
import momentTimezone from 'moment-timezone'
import styled from 'styled-components'

import jwtDecode from 'jwt-decode'
import moment from 'moment'
import styles from './test.css'
import './reset.css'
import TextBase from '../../components/Text'
const Text = styled(TextBase)`
  font-weight: bold;
`
const Header = styled(Text)`
  font-size: 20px;
`

const Content = styled(Text)`
  font-size: 18px;
`

import Layout from '../Home'
import { TABLE_TYPES } from '../../constant'
import Sidebar from '../../components/Sidebar'
import { inject, observer } from 'mobx-react'
import { sortBy, find } from 'lodash'

const TIME_ZONE = 'Asia/Bangkok'

const { confirm } = Modal

@inject('PharmacyAvailableTime')
@observer
export default class CalendarPharmacy extends Component {
  constructor() {
    super()
    this.state = {
      selections: [],
      initialSelections: [],
      fullscreen: false,
      loading: true,
      show: false,
      patientNo: 1,
      newSlot: null,
      times: [],
      length: 0,
    }
    // this.handleChange = this.handleChange.bind(this)
  }
  async initialTimeUpdate() {
    const { PharmacyAvailableTime } = this.props
    await PharmacyAvailableTime.fetchPharmacyAvailableTime()
    const data = await PharmacyAvailableTime.getPharmacyAvailableTime
    var timeData = []
    let times = []
    data.map((item) => {
      timeData.push(item.time)
      times.push(item)
    })
    this.setState({
      initialSelections: timeData,
      times: sortBy(times, [
        function(o) {
          return o.time[0]
        },
      ]),
    })
    const id = jwtDecode(localStorage.getItem('token')).id
    this.checkTime(this.state.initialSelections)
    this.setState({ loading: false })
  }

  async componentDidMount() {
    this.initialTimeUpdate()
  }

  updateTimes = (response, id) => {
    let updateItems = this.state.times.map((data) => {
      if (data.id === id) {
        return response
      }
      return data
    })
    this.setState({
      times: updateItems,
    })
  }

  updateTimes = (response, id) => {
    let updateItems = this.state.times.map((data) => {
      if (data.id === id) {
        return response
      }
      return data
    })
    this.setState({
      times: sortBy(updateItems, [
        function(o) {
          return o.time[0]
        },
      ]),
    })
  }

  async addAvailableTime(newTime, qty) {
    const { PharmacyAvailableTime } = this.props
    let info
    if (newTime.id) {
      info = {
        time: [newTime.time[0], newTime.time[1]],
        quantity: qty,
      }
      const response = await PharmacyAvailableTime.updatePharmacyAvailableTime(newTime.id, info)
      if (response) {
        message.success('เปลี่ยนแปลงเสร็จสิ้น')
        this.updateTimes(response, newTime.id)
      }
    } else {
      info = {
        time: [newTime.start, newTime.end],
        quantity: qty,
      }
      const response = await PharmacyAvailableTime.addPharmacyAvailableTime(info)
      if (response) {
        message.success('เปลี่ยนแปลงเสร็จสิ้น')
        // const times = this.state.times
        // times.push(response)
        // this.setState({
        //   times: sortBy(times, [
        //     function(o) {
        //       return o.time[0]
        //     }
        //   ])
        // })
        this.initialTimeUpdate()
        this.setState({
          newSlot: null,
        })
      }
    }
  }

  async handleChange(selections) {
    const { PharmacyAvailableTime } = this.props
    var addedSlot = selections.filter((elem) => !this.state.initialSelections.find(({ start }) => elem.start === start))
    var deletedSlotStart = this.state.initialSelections.filter((elem) => !selections.find(({ start }) => elem.start === start))
    var deletedSlotEnd = selections.filter((elem) => !this.state.initialSelections.find(({ end }) => elem.end === end))
    var deletedSlotEndToDelete = this.state.initialSelections.filter((elem) => !selections.find(({ end }) => elem.end === end))

    if (addedSlot.length > 0 && deletedSlotStart.length > 0 && deletedSlotEnd.length > 0 && deletedSlotEndToDelete.length > 0) {
      console.log('DELETEDSLOTSTART', deletedSlotStart)
      const deleted = moment()
        .startOf('isoWeek')
        .add(deletedSlotStart[0].start, 'minutes')
      let day
      if (deleted.fromNow().includes('ago')) {
        day = deleted.add(7, 'days').format('YYYY-MM-DD')
      } else {
        day = deleted.format('YYYY-MM-DD')
      }
      const response = await PharmacyAvailableTime.checkBookingNumberPharmacy(day, deletedSlotStart[0].start, deletedSlotStart[0].end)
      if (response.length && response[0].count) {
        message.error('ช่วงเวลานี้มีผู้ป่วยทำการนัดหมายอยู่ ไม่สามารถลบได้')
        this.setState({ loading: true, availableTimeId: null, patientNo: 1 })
        this.refreshPharmacyCalendar()
      } else {
        const deletedSlot = find(this.state.times, {
          time: [deletedSlotStart[0].start, deletedSlotStart[0].end],
        })
        await PharmacyAvailableTime.deletePharmacyAvailableTime(deletedSlot.id)
        this.setState({
          times: this.state.times.filter((time) => time.id !== deletedSlot.id),
        })
        if (addedSlot.length > 0) {
          this.setState({
            show: true,
            newSlot: addedSlot[0],
          })
        }
        this.setState({
          initialSelections: this.state.selections,
        })
      }
    } else if (deletedSlotEnd.length > 0 && deletedSlotEndToDelete.length > 0) {
      const id = await PharmacyAvailableTime.fetchPharmacyAvailableTimeById(deletedSlotEndToDelete[0].start, deletedSlotEndToDelete[0].end)
      await PharmacyAvailableTime.deletePharmacyAvailableTime(id[0].id ? id[0].id : id)
      this.setState({
        show: true,
        newSlot: deletedSlotEnd[0],
        initialSelections: this.state.selections,
      })
    }
    if (selections.length > this.state.initialSelections.length) {
      this.setState({
        show: true,
        newSlot: addedSlot[0],
        initialSelections: this.state.selections,
      })
    }

    // else{
    //   //message.error('ช่วงเวลานี้มีผู้ป่วยทำการนัดหมายอยู่ ไม่สามารถลบได้')

    // }
  }

  deletePharmacyAvailableTime = async (id) => {
    const { PharmacyAvailableTime } = this.props
    const response = await PharmacyAvailableTime.deletePharmacyAvailableTime(id)
    if (response) {
      message.success('ลบรายการเสร็จสิ้น')
      this.setState({
        times: this.state.times,
        initialSelections: this.state.selections,
      })
      this.submitTime()
    }
  }

  handleNumber = (value) => {
    this.setState({ patientNo: value })
  }

  submitTime = () => {
    if (this.state.newSlot) {
      this.addAvailableTime(this.state.newSlot, this.state.patientNo)
      this.setState({
        show: false,
        initialSelections: this.state.selections,
        patientNo: 1,
      })
    }
    this.initialTimeUpdate()
  }

  showModal() {
    this.setState({
      show: false,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.handleTime) {
      this.props.handleTime(this.state.selections)
      this.state.length = this.state.initialSelections.length
    }
  }

  refreshPharmacyCalendar() {
    setTimeout(() => {
      this.setState({
        loading: false,
      })
    }, 100)
  }

  checkTime(time) {
    const timeSelections = time.map((t) => {
      return { start: t[0], end: t[1] }
    })
    this.setState({
      selections: timeSelections,
      initialSelections: timeSelections,
    })
  }

  async showModalNew(value) {
    const respData = modalOk()
    console.log('respDat::', respData)
    console.log('value', value)
  }

  async editPatientNo(t) {
    confirm({
      title: 'จำนวนคนไข้สำหรับช่วงเวลานี้',
      content: (
        <Row style={{ padding: '5px' }} gutter={12}>
          <Col span={12}>โปรดระบุจำนวนคนไข้</Col>
          <Col span={22}>
            <InputNumber min={1} defaultValue={t.quantity} onChange={this.handleNumber} />
          </Col>
        </Row>
      ),
      onOk: async () => {
        this.submitTime()
      },
      onCancel: async () => {
        this.setState({ loading: true, availableTimeId: null, patientNo: 1 })
        this.refreshPharmacyCalendar()
      },
    })
  }
  async showConfirm(selections, patientNo) {
    const a = patientNo
    const { PharmacyAvailableTime } = this.props
    this.setState({
      selections,
    })
    var addedSlot = selections.filter((elem) => !this.state.initialSelections.find(({ start }) => elem.start === start))
    var deletedSlotStart = this.state.initialSelections.filter((elem) => !selections.find(({ start }) => elem.start === start))
    var deletedSlotEnd = selections.filter((elem) => !this.state.initialSelections.find(({ end }) => elem.end === end))
    var deletedSlotEndToDelete = this.state.initialSelections.filter((elem) => !selections.find(({ end }) => elem.end === end))
    if (addedSlot.length === 0 && deletedSlotStart.length > 0 && deletedSlotEnd.length === 0 && deletedSlotEndToDelete.length > 0) {
      const id = await PharmacyAvailableTime.fetchPharmacyAvailableTimeById(deletedSlotEndToDelete[0].start, deletedSlotEndToDelete[0].end)
      await PharmacyAvailableTime.deletePharmacyAvailableTime(id[0].id)
      this.setState({
        show: true,
        newSlot: deletedSlotEnd[0],
        initialSelections: this.state.selections,
      })
      this.state.length = this.state.initialSelections
      this.submitTime()
    } else {
      confirm({
        title: 'จำนวนคนไข้สำหรับช่วงเวลานี้',
        content: (
          <Row style={{ padding: '5px' }} gutter={12}>
            <Col span={12}>โปรดระบุจำนวนคนไข้</Col>
            <Col span={22}>
              <InputNumber min={1} defaultValue={this.state.patientNo} onChange={this.handleNumber} />
            </Col>
          </Row>
        ),
        onOk: async () => {
          this.handleChange(selections).then(() => {
            this.submitTime()
          })
        },
        onCancel: async () => {
          this.setState({ loading: true, availableTimeId: null, patientNo: 1 })
          this.refreshPharmacyCalendar()
        },
      })
    }
  }

  render() {
    const { show } = this.state
    const dateFormatExtract = (time) => {
      return (
        'วัน' +
        moment()
          .startOf('week')
          .add(time + 1440, 'minutes')
          .format('dddd') +
        'ที่ ' +
        moment()
          .startOf('week')
          .add(time + 1440, 'minutes')
          .format('DD') +
        ' ' +
        moment()
          .startOf('week')
          .add(time + 1440, 'minutes')
          .format('MMMM') +
        ' ' +
        moment()
          .startOf('week')
          .add(543, 'years')
          .add(time + 1440, 'minutes')
          .format('yyyy')
      )
    }
    return (
      <div className={styles.example}>
        <div className={styles.main}>
          {/* <Modal
            title="จำนวนคนไข้สำหรับช่วงเวลานี้"
            visible={show}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  this.setState({ loading: true, show: false, availableTimeId: null, patientNo: 1 })
                  this.refreshPharmacyCalendar()
                }}
              >
                ยกเลิก
              </Button>,
              <Button
                disabled={!this.state.patientNo}
                key="submit"
                type="primary"
                onClick={this.submitTime}
              >
                ยืนยัน
              </Button>
            ]}
          >
            <Row style={{ padding: '5px' }} gutter={12}>
              <Col span={8}>โปรดระบุจำนวนคนไข้</Col>
              <Col span={16}>
                <InputNumber min={1} onChange={this.handleNumber} value={this.state.patientNo} />
              </Col>
            </Row>
          </Modal> */}

          {!this.state.loading ? (
            <div>
              {this.state.length !== this.state.initialSelections.length ? null : (
                <AvailableTimes
                  timeConvention="24h"
                  timeZone={TIME_ZONE}
                  height={this.state.fullscreen ? undefined : 600}
                  weekStartsOn="monday"
                  start={new Date()}
                  //onClick={console.log("this.state.selections ",this.state.selections)}
                  // onChange={this.handleChange}
                  onChange={(value) => this.showConfirm(value, this.state.patientNo)}
                  initialSelections={this.state.initialSelections}
                  recurring={true}
                />
              )}

              <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                {this.state.times.map((t, index) => (
                  <Card>
                    <Row gutter={16}>
                      <Col span={6}>
                        <Header>
                          {index === 0
                            ? dateFormatExtract(t.time[0])
                            : moment()
                                .startOf('week')
                                .add(t.time[0] + 1440, 'minutes')
                                .format('dddd') !==
                              moment()
                                .startOf('week')
                                .add(this.state.times[index - 1].time[0] + 1440, 'minutes')
                                .format('dddd')
                            ? dateFormatExtract(t.time[0])
                            : ''}
                        </Header>
                      </Col>
                      <Col span={6}>
                        <Content>
                          ช่วงเวลา{' '}
                          {moment()
                            .startOf('day')
                            .add(t.time[0], 'minutes')
                            .format('HH:mm')}{' '}
                          -{' '}
                          {moment()
                            .startOf('day')
                            .add(t.time[1], 'minutes')
                            .format('HH:mm')}
                        </Content>
                      </Col>
                      <Col span={6}>
                        <Content>จำนวน {t.quantity} คน</Content>
                      </Col>
                      <Col span={4}>
                        <Button
                          // onClick={() => {
                          //   this.setState({
                          //     show: true,
                          //     newSlot: t,
                          //     patientNo: t.quantity
                          //   })
                          // }}
                          onClick={() => {
                            this.setState({
                              show: true,
                              newSlot: t,
                              patientNo: t.quantity,
                            })
                            this.editPatientNo(t)
                          }}
                        >
                          แก้ไขจำนวน
                        </Button>
                      </Col>
                      <Col span={2}>
                        <Popconfirm title="ต้องการลบรายการนี้ใช่หรือไม่" onConfirm={() => this.deletePharmacyAvailableTime(t.id)} okText="ใช่" cancelText="ไม่">
                          <CloseCircleOutlined style={{ cursor: 'pointer', color: '#FF0000' }} />
                        </Popconfirm>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

import React, { useState, useEffect } from 'react'
import { Select, Divider, Input, Typography, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const { Option } = Select
const SelectVisitNumberForm = ({ visitNumberList = [], setSelectedVnNumber }) => {
  const [items, setItems] = useState([])
  const [visitNumber, setVisitNumber] = useState('')

  useEffect(() => {
    console.log('visitNumberList in component')
    console.log(visitNumberList)
    if (visitNumberList.length) {
      setItems(visitNumberList)
    }
  }, [visitNumberList])

  const onChange = (event) => {
    setVisitNumber(event.target.value)
  }

  const addItem = (e) => {
    e.preventDefault()
    setItems([...items, visitNumber])
    setVisitNumber('')
  }

  return (
    <Select style={{ width: '100%' }} placeholder="เลือกหมายเลขวิสิต" onSelect={setSelectedVnNumber} dropdownRender={(menu) => <>{menu}</>}>
      {items.map((item) => (
        <Option key={item}>{item}</Option>
      ))}
    </Select>
  )
}

export default SelectVisitNumberForm

export const initialValues = {
  areaOfResponsibility: '',
  idcCode: '',
  injuryByOperation: '',
  medicalEmergencyStaff: [],
  notifiedSymptoms: '',
  operationDateTime: '',
  operationNo: '',
  paramedic: [],
  policeStationArea: '',
  rcCode: '',
  sceneDescription: '',
  sceneSite: '',
  vehicle: '',
  vehicleType: '',
  paramedicDoctor: '',
  emergencyStaff: '',
  travelLog: {
    time: {
      erawanReceiveCase: '',
      vajiraReceiveCase: '',
      ordered: '',
      leaveBase: '',
      arrivedScene: '',
      leaveScene: '',
      arrivedHospital: '',
      arrivedBase: '',
    },
    totalTime: {
      activateTime: '',
      responseTime: '',
      onSceneTime: '',
      leaveSceneToHospitalTime: '',
      hospitalToBaseTime: '',
    },
    kilometers: {
      beforeLeaveBase: '',
      beforeLeaveScene: '',
      arrivedHospital: '',
      arrivedBase: '',
    },
    distance: {
      fromBaseToScene: '',
      fromSceneToHospital: '',
      fromHospitalToBase: '',
    },
    atReason: '',
    rtReason: '',
    ostReason: '',
  },
}

export const formTemplate = [
  {
    header: '',
    data: [
      {
        label: 'วันที่ออกปฏิบัติการ',
        value: 'operationDateTime',
        disabled: true,
        type: 'date',
        required: false,
        requiredMessage: '',
        placeholder: 'เลือกวันที่ออกปฏิบัติการ',
      },
      {
        label: 'พื้นที่โซน',
        value: 'areaOfResponsibility',
        disabled: false,
        type: 'input',
        required: false,
        requiredMessage: '',
      },
      {
        label: 'เลขที่ปฏิบัติการ',
        value: 'operationNo',
        disabled: false,
        type: 'input',
        required: false,
        requiredMessage: '',
      },
      {
        label: 'สถานที่เกิดเหตุ',
        value: 'sceneSite',
        disabled: false,
        type: 'input',
        required: false,
        requiredMessage: '',
      },
      {
        label: 'ขอบเขตสถานีตำรวจ',
        value: 'policeStationArea',
        disabled: false,
        type: 'input',
        required: false,
        requiredMessage: '',
      },
      {
        label: 'ยานพาหนะที่ใช้ปฏิบัติการ',
        value: 'vehicleType',
        disabled: false,
        type: 'radio',
        required: false,
        requiredMessage: '',
        items: [
          { label: 'รถพยาบาล' },
          {
            label: 'เรือพยาบาล',
          },
        ],
      },
      {
        label: 'การบาดเจ็บเกี่ยวข้องกับปฏิบัติการหรือไม่',
        value: 'injuryByOperation',
        disabled: false,
        type: 'radio',
        required: false,
        requiredMessage: '',
        items: [
          {
            label: 'ใช่',
          },
          {
            label: 'ไม่ใช่',
          },
        ],
      },
      {
        label: 'รหัสรถ/เรือที่ออกปฏิบัติการ',
        value: 'vehicle',
        disabled: false,
        type: 'dropdown',
        required: false,
        requiredMessage: '',
      },
      {
        label: 'ลักษณะของสถานที่เกิดเหตุ',
        value: 'sceneDescription',
        disabled: false,
        type: 'radio',
        required: false,
        requiredMessage: '',
        items: [
          {
            label: 'บ้าน/หอพัก/อพารทเมนต์',
          },
          {
            label: 'สถานพยาบาล',
          },
          {
            label: 'ที่กำลังก่อสร้าง',
          },
          {
            label: 'ถนน/ทางด่วน',
          },
          {
            label: 'เนอร์สซิ่งโฮม',
          },
          {
            label: 'สถานที่สาธารณะ',
          },
          {
            label: 'อื่นๆ',
          },
        ],
      },
      {
        label: 'อาการที่ได้รับแจ้ง',
        value: 'notifiedSymptoms',
        disabled: false,
        type: 'input',
      },
      {
        label: 'IDC Code',
        value: 'idcCode',
        disabled: false,
        type: 'dropdown',
        required: false,
        requiredMessage: '',
      },
      {
        label: 'RC Code',
        value: 'rcCode',
        disabled: false,
        type: 'dropdown',
        required: false,
        requiredMessage: '',
      },
    ],
  },
  {
    header: 'รายชื่อผู้ออกปฏิบัติการ',
    data: [
      {
        label: 'นักฉุกเฉินการแพทย์/พยาบาล',
        value: 'paramedic',
        disabled: false,
        type: 'multipleDropdown',
        required: false,
        requiredMessage: '',
        placeholder: 'สามารถเลือกได้หลายคน',
      },
      {
        label: 'พนักงานฉุกเฉินการแพทย์',
        value: 'medicalEmergencyStaff',
        disabled: false,
        type: 'multipleDropdown',
        required: false,
        requiredMessage: '',
        placeholder: 'สามารถเลือกได้หลายคน',
      },
      {
        label: 'แพทย์ฉุกเฉิน',
        value: 'paramedicDoctor',
        disabled: false,
        type: 'staffText',
        required: false,
        requiredMessage: '',
        placeholder: 'กรอกชื่อและนามสกุลเจ้าหน้าที่',
      },
      {
        label: 'พนักงานฉุกเฉิน',
        value: 'emergencyStaff',
        disabled: false,
        type: 'staffText',
        required: false,
        requiredMessage: '',
        placeholder: 'กรอกชื่อและนามสกุลเจ้าหน้าที่',
      },
    ],
  },
]

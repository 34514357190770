import React, { Component } from 'react'
import Layout from '../Home'
import BookingTable from '../../components/bookingTable'
import { observer, inject } from 'mobx-react'
import jwtDecode from 'jwt-decode'
import { message } from 'antd'
import moment from 'moment'
import { find, sortBy, intersectionBy, filter } from 'lodash'
import axios from 'axios'
import { PRACTITIONER_ROLES } from '../../constant'

@inject('Booking')
@inject('Prescription')
@inject('AppUserIdentity')
@inject('UserInfo')
@inject('DoctorAvailableTime')
@inject('PharmacyAvailableTime')
@inject('BookingGallery')
@inject('UserFcmToken')
@inject('FCM')
@inject('BookingRoom')
@inject('KycPatientImage')
@inject('User')
@inject('Communication')
@observer
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataSource: [],
      dataCancelBookings: [],
      dataAlert: [],
      medicines: [],
      prescriptionId: '',
      time: '15:00',
      user: {},
      hieData: {},
      medicalBenefit: false,
      visitNumber: false,
      availableDays: [],
      billingItems: [],
      pharmacyTimes: [],
      isLoading: false,
      hash: '',
      currentTab: 'bookings',
      images: [],
      image: false,
      orderNo: [],
      drugs: [],
      patientData: false,
      sounds: false,
      doctorName: false,
      links: false,
      prescriptions: [],
      roles: [],
      practitionerList: [],
      practitionerLoading: false,
    }
  }
  intervalID = 0
  fetchUserVisitedByVerifiedFromHIE = async (patientId, vnNumber) => {
    const { UserInfo } = this.props
    const everOmaId = await this.fetchAppUserIdentity(patientId)
    await UserInfo.triggerUpdateData(everOmaId)
    const response = await UserInfo.checkDrugsDetailByOrderNo(everOmaId, vnNumber)
    if (response && response.length) {
      this.setState({
        billingItems: response,
      })
    }
  }

  patchBooking = async (id, date, time, admitTime, isForPharmacy) => {
    const { Booking } = this.props
    const dateAsMinutes =
      (moment()
        .day(date)
        .format('d') -
        2) *
      1440
    const asMinutes = moment.duration(time).asMinutes()
    const sumAsMinutes = dateAsMinutes + asMinutes
    const response = await Booking.patchBooking(id, sumAsMinutes, admitTime)
    if (response) {
      if (isForPharmacy) {
        this.setStatus(id, 'PHARMACY_PENDING_BOOKING', false)
      } else {
        this.setStatus(id, 'DOCTOR_PENDING', false)
      }
      message.success('ทำการจองวัน เวลานัดกับแพทย์เสร็จสิ้น')
    }
  }

  fetchCheckBookingNumberPharmacy = async (data) => {
    const { PharmacyAvailableTime } = this.props
    const response = await PharmacyAvailableTime.checkBookingNumberPharmacy(data)
    let availableDays = []
    response.map((res) => {
      const availableTime = {
        isAvailable: res.maxQuantity !== res.count,
        time: `${moment()
          .startOf('isoWeek')
          .add(res.startTime, 'minutes')
          .format('HH:mm')} - ${moment()
            .startOf('isoWeek')
            .add(res.endTime, 'minutes')
            .format('HH:mm')}`,
        value: moment()
          .startOf('isoWeek')
          .add(res.startTime, 'minutes')
          .format('HH:mm'),
      }
      availableDays.push(availableTime)
    })
    this.setState({
      pharmacyTimes: availableDays,
    })
  }

  fetchDoctorAvailableTimes = async (doctorId) => {
    const { DoctorAvailableTime, Booking } = this.props
    const response = await DoctorAvailableTime.fetchfilterType(doctorId)
    const resBooking = await Booking.getFilterByDoctorId(doctorId)
    const availableDays = []
    response.time.forEach((t) => {
      const timeStart = t[0]
      const timeEnd = t[1]
      const data = {
        day: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format('D'),
        date: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format('dddd'),
        times: [],
        value: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format(),
      }
      for (let time = timeStart; time <= timeEnd; time += response.minute) {
        const timeForSelect = {
          label: moment()
            .startOf('isoWeek')
            .add(time, 'minutes')
            .format('HH:mm'),
          isAvailable: find(resBooking, { bookingTime: time }),
        }
        data.times.push(timeForSelect)
      }
      availableDays.push(data)
    })
    this.setState({ availableDays: availableDays })
  }

  fetchPharmacyAvailableTimes = async (doctorId) => {
    const { PharmacyAvailableTime, Booking } = this.props
    await PharmacyAvailableTime.fetchPharmacyAvailableTime()
    const response = await PharmacyAvailableTime.getPharmacyAvailableTime
    const resBooking = await Booking.getFilterByDoctorId(doctorId)
    const availableDays = []
    response.forEach((t) => {
      const timeStart = t.time[0]
      const timeEnd = t.time[1]
      const data = {
        day: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format('D'),
        date: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format('dddd'),
        data: {
          date: moment()
            .startOf('isoWeek')
            .add(timeStart, 'minutes')
            .format('YYYY-MM-DD'),
          startTime: timeStart,
          endTime: timeEnd,
          day: moment()
            .startOf('isoWeek')
            .add(timeStart, 'minutes')
            .format('dddd'),
        },
        value: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format(),
      }
      availableDays.push(data)
    })
    const sortByAvailableDays = sortBy(availableDays, [
      function (o) {
        return o.day
      },
    ])
    const intersection = intersectionBy(sortByAvailableDays, 'day')
    this.setState({
      availableDays: intersection,
    })
  }

  checkFilterByRole = (roles, data) => {
    let dataRes = []
    let dataCancel = []
    let dataAlert = []
    if (roles.includes('pharmacy')) {
      dataRes = data.filter((res) => {
        return ['PHARMACY_PENDING_BOOKING', 'PHARMACY_CONFIRM_BOOKING'].includes(res.status)
      })
      dataAlert = data.filter((res) => {
        return res.status.includes('PHARMACY_ALERT')
      })
      dataCancel = data.filter((res) => {
        return res.status.includes('PHARMACY_DECLINE_BOOKING')
      })
    } else if (roles.includes('doctor')) {
      dataRes = data.filter((res) => {
        return ['DOCTOR_CONFIRM', 'DOCTOR_PENDING', 'DOCTOR_ALERT', 'DOCTOR_DECLINE', 'DOCTOR_PENDING_RX'].includes(res.status)
      })
      dataAlert = data.filter((res) => {
        return res.status.includes('DOCTOR_ALERT')
      })
    } else if (['nurse', 'physiotherapist', 'speechCorrectionSpecialist', 'pharmacySchedule', 'nutritionist'].includes(roles[0])) {
      dataRes = data.filter((res) => {
        return ['STAFF_CONFIRM', 'STAFF_PENDING', 'STAFF_ALERT', 'STAFF_DECLINE'].includes(res.status)
      })
      dataAlert = data.filter((res) => {
        return res.status.includes('STAFF_ALERT')
      })
    } else if (roles.includes('callCenter')) {
      dataRes = data.filter((res) => {
        if (res.prescription && res.prescription.paymentStatus) {
          return ['PATIENT_PENDING_PAYMENT', 'PATIENT_SUCCESS_PAYMENT'].includes(res.prescription.paymentStatus)
        }
        if (['PATIENT_DRAFT', 'EPHIS_PENDING'].includes(res.status)) {
          return ['PATIENT_DRAFT', 'EPHIS_PENDING'].includes(res.status)
        }
        if (['DOCTOR_CONFIRM', 'DOCTOR_PENDING', 'DOCTOR_DECLINE', 'DOCTOR_COMPLETED', 'STAFF_CONFIRM', 'STAFF_PENDING', 'STAFF_DECLINE', 'STAFF_COMPLETED', 'DOCTOR_PENDING_RX'].includes(res.status)) {
          return ['DOCTOR_CONFIRM', 'DOCTOR_PENDING', 'DOCTOR_DECLINE', 'DOCTOR_COMPLETED', 'STAFF_CONFIRM', 'STAFF_PENDING', 'STAFF_DECLINE', 'STAFF_COMPLETED', 'DOCTOR_PENDING_RX'].includes(res.status)
        }

        return ['PATIENT_DRAFT', 'EPHIS_PENDING'].includes(res.status)
      })
      dataCancel = data.filter((res) => {
        return res.status.includes('CALL_CENTER_DECLINE')
      })
      dataAlert = data.filter((res) => {
        return ['DOCTOR_ALERT', 'PHARMACY_ALERT', 'STAFF_ALERT'].includes(res.status)
      })
    }
    return { dataRes, dataCancel, dataAlert }
  }

  fetchDataFilterByDoctorId = async (Booking, user) => {
    let response = await Booking.getFilterByDoctorId(user.id)
    return this.checkFilterByRole(user.roles, response)
  }

  fetchDataTable = async () => {
    this.setState({
      isLoading: true,
    })
    const { Booking } = this.props
    let response
    if (PRACTITIONER_ROLES.includes(this.state.user.roles[0])) {
      response = await this.fetchDataFilterByDoctorId(Booking, this.state.user)
    } else if (this.state.user.roles.includes('pharmacy') || this.state.user.roles.includes('callCenter')) {
      response = await this.fetchDataBookings(Booking, this.state.user)
    }

    this.setState({
      dataSource: response.dataRes,
      dataCancelBookings: response.dataCancel,
      dataAlert: response.dataAlert,
      isLoading: false,
    })
  }

  updateTimer = (num) => {
    clearInterval(this.intervalID)
    var minutes = 60 * num
    this.startTimer(minutes)
  }

  getUser = () => {
    const user = jwtDecode(localStorage.getItem('token'))
    this.setState({
      user: user,
    })
  }

  refreshData = async () => {
    await this.getUser()
    this.fetchDataTable()
    this.updateTimer(15)
  }
  mounted = async () => {
    this.updateTimer(15)
  }

  async componentDidMount() {
    const { history } = this.props
    if (history.location.pathname === '/bookingpatient') {
      this.setState({
        hash: history,
        currentTab: history.location.hash.split('#')[1],
      })
    }
    await this.getUser()
    this.fetchDataTable()
    this.fetchDepartments()
    if (this.state.user.roles.includes('callCenter')) {
      this.updateTimer(15)
    }
  }

  updateStatus = (response, id) => {
    let updateItems = this.state.dataSource.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          status: response.status,
        }
      }
      return data
    })
    this.setState({
      dataSource: updateItems,
    })
  }

  setStatus = async (id, status, isUpdate) => {
    const { Booking } = this.props
    const response = await Booking.setStatus(id, status)
    if (response) {
      message.success('เปลี่ยนสถานะเสร็จสิ้น')
      this.updateStatus(response, id)
      if (!isUpdate) {
        this.fetchDataTable()
      } else {
        const data = this.checkFilterByRole(this.state.user.roles, this.state.dataSource)
        this.setState({
          dataSource: data.dataRes,
          dataAlert: data.dataAlert,
        })
      }
    }
  }

  fetchDepartments = async () => {
    const { User } = this.props
    await User.fetchDoctorTypes()
    await User.fetchOtherTypes()
    const types = User.getDoctorTypes
    const otherTypesData = User.getOtherTypes
    this.setState({
      doctorTypes: types,
      otherTypes: otherTypesData,
    })
    console.log(types, 'DoctorType')
    console.log(otherTypesData, 'OtherType')
  }

  setStatusPrescription = async (prescriptionId, status) => {
    const { Prescription } = this.props
    await Prescription.updateStatus(prescriptionId, status)
    this.fetchDataTable()
  }

  createPrescription = async (bookingId, patientId) => {
    const { Prescription, Booking } = this.props
    const response = await Prescription.createPrescription(patientId)
    await Booking.updateBooking(bookingId, response.id, true)
  }

  updatePrescription = async (medicines, bookingId) => {
    const { Prescription } = this.props
    const response = await Prescription.updatePrescription(this.state.prescriptionId, medicines)
    if (response) {
      this.setStatus(bookingId, 'PHARMACY_PENDING_RX')
    }
  }

  startTimer = (duration) => {
    var timer = duration,
      minutes,
      seconds
    this.intervalID = setInterval(() => {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10)
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      let showTime = minutes + ':' + seconds
      this.setState({
        time: showTime,
      })
      if (--timer < 0) {
        timer = duration
        this.fetchDataTable()
      }
    }, 1000)
  }

  fetchPatient = async (id) => {
    const { AppUserIdentity, UserInfo } = this.props
    const everOmaId = await this.fetchAppUserIdentity(id)
    if (everOmaId) {
      const resPatient = await UserInfo.fetchUserInfoByPatientId(everOmaId)
      const resHie = await UserInfo.checkUserDetail(everOmaId)
      this.setState({
        hieData: resHie,
        patientData: resPatient,
      })
    }
  }

  updateVisitNumber = async (id, patchData, isPrescription) => {
    const { Booking } = this.props
    const response = await Booking.updateBooking(id, patchData, isPrescription)
    if (response) {
      message.success('อัปเดตหมายเลขวิสิตเสร็จสิ้น')
    }
  }

  fetchAppUserIdentity = async (id) => {
    const { AppUserIdentity } = this.props
    const response = await AppUserIdentity.fetchAppUserIdentity(id)
    if (response && response.length) {
      return response[response.length - 1].everOmaId
    } else {
      return false
    }
  }

  fetchUserImage = async (id) => {
    const { KycPatientImage } = this.props
    const everOmaId = await this.fetchAppUserIdentity(id)
    if (everOmaId) {
      const response = await KycPatientImage.fetchPatientImage(everOmaId)
      if (response) {
        this.setState({
          image: response.image,
        })
      } else {
        this.setState({
          image: false,
        })
      }
    }
  }

  fetchUserFcmToken = async (id, message, time, screen) => {
    const { UserFcmToken, FCM } = this.props
    const everOmaId = await this.fetchAppUserIdentity(id)
    if (everOmaId) {
      const response = await UserFcmToken.fetchUserFcmToken(everOmaId)
      if (response && response.length) {
        response.forEach(async (res) => {
          let notification = {
            body: message,
            title: 'แจ้งเตือน',
            icon: 'ic_launcher',
            color: '#f1c40f',
            sound: 'default',
          }
          let data = {
            bookingTime: time,
            screen: screen,
          }
          await FCM.sendMessage(res.fcmToken, notification, data)
        })
      }
    }
  }

  fetchMedicalBenefit = (id) => {
    const { UserInfo } = this.props
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const everOmaId = await this.fetchAppUserIdentity(id)
        if (everOmaId) {
          const response = await UserInfo.checkLatestMedicalBenefit(everOmaId)
          if (response && response.PID) {
            this.setState({
              medicalBenefit: response,
              isLoading: false,
            })
          } else {
            this.setState({
              medicalBenefit: false,
              isLoading: false,
            })
          }
        } else {
          this.setState({
            medicalBenefit: false,
            isLoading: false,
          })
        }
      },
    )
  }

  fetchVisitNumber = (id, patientId, prescriptionId, selectedDate) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { UserInfo, User } = this.props
        const userRes = await User.fetchUserInfo(this.state.user.id)
        const everOmaId = await this.fetchAppUserIdentity(patientId)
        await UserInfo.triggerUpdateData(everOmaId)
        const response = await UserInfo.selectOrderNumber(everOmaId, selectedDate, userRes.employeeId)
        if (response && response.orderNo && response.orderNo.length) {
          this.setState({
            orderNo: response.orderNo,
          })
        } else {
          this.setState({
            orderNo: [],
          })
        }
        this.setState({
          isLoading: false,
        })
      },
    )
  }

  fetchBookingRooms = (id) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { BookingRoom } = this.props
        const response = await BookingRoom.getBookingRooms(id)
        this.setState({
          sounds: response,
          isLoading: false,
        })
      },
    )
  }

  fetchRecordFromRoomId = (sid) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { Communication } = this.props
        const response = await Communication.fetchRecordListFromRoomId(sid)
        this.setState({
          links: response.link,
          isLoading: false,
        })
      },
    )
  }

  fetchBookingGallery = (id) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { BookingGallery } = this.props
        const response = await BookingGallery.getBookingGallery(id)
        const images = response.filter((res) => {
          if (res.image.length) {
            return res.image
          }
        })
        this.setState({
          images,
          isLoading: false,
        })
      },
    )
  }

  fetchOrderNumber = (orderNumber, patientId) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { UserInfo } = this.props
        const everOmaId = await this.fetchAppUserIdentity(patientId)
        await UserInfo.triggerUpdateData(everOmaId)
        if (everOmaId) {
          const response = await UserInfo.checkDrugsDetailByOrderNo(everOmaId, orderNumber)
          this.setState({
            drugs: response.billingItems,
            doctorName: response.doctorName,
            isLoading: false,
          })
        }
      },
    )
  }

  checkPath = (dataSource) => {
    let sortedData = dataSource
    const hash = this.props.history.location.hash
    if (hash === '#pharmacyPendingRx') {
      sortedData = this.sortDataByStatus('PHARMACY_PENDING_RX', sortedData)
    } else if (hash === '#pharmacyEms') {
      sortedData = this.sortDataByStatus('WAIT_FOR_PHARMACYSTORE_NOTIFY', sortedData)
      sortedData = this.sortDataByStatus('WAIT_FOR_PATIENT_EMS', sortedData)
    } else {
      sortedData = sortedData.sort((a, b) => {
        return b.bookingTime - a.bookingTime
      })
      sortedData = sortedData.sort((a, b) => {
        return moment(b.admitTime).unix() - moment(a.admitTime).unix()
      })
    }
    return sortedData
  }
  filterStatusByRole = (role, response) => {
    const filterStatus =
      role === 'pharmacy'
        ? [
          'PHARMACY_PENDING_RX',
          'PHARMACY_CONFIRM_RX',
          'PATIENT_PENDING_PAYMENT',
          'PATIENT_SUCCESS_PAYMENT',
          'PATIENT_DECLINE_PAYMENT',
          'WAIT_FOR_PATIENT_HOSPITAL',
          'WAIT_FOR_PATIENT_EMS',
          'WAIT_FOR_PATIENT_PHARMACY',
          'WAIT_FOR_PATIENT_PHAMACYSTORE',
          'WAIT_FOR_PHARMACYSTORE_NOTIFY',
        ]
        : ['WAIT_FOR_PHARMACYSTORE_NOTIFY', 'WAIT_FOR_PATIENT_PHAMACYSTORE', 'SUCCESS_BY_PHARMACYSTORE', 'CANCELED_BY_PATIENT', 'CANCELED_BY_PHARMACYSTORE']
    return response.filter((res) => res.prescription && res.prescription.status && filterStatus.includes(res.prescription.status))
  }

  checkVisitNumber = async (patientId, visitDate) => {
    const { UserInfo } = this.props
    const everOmaId = await this.fetchAppUserIdentity(patientId)
    const response = await UserInfo.checkVisitNumber(everOmaId, visitDate)
    return response
  }

  fetchDataBookings = async (Booking, user) => {
    let response = await Booking.fetchBooking()
    return this.checkFilterByRole(user.roles, response)
  }

  fetchBookingPrescriptions = async (response) => {
    const { Booking, Logistic } = this.props
    response = await this.filterByPayments(response)
    response = this.checkFilterByRole(this.state.user.roles, response)
    this.setState({
      dataSource: response.dataRes,
      dataCancelBookings: response.dataCancel,
      dataAlert: response.dataAlert,
      isLoading: false,
    })
  }

  filterByPayments = async (response) => {
    return Promise.all(response.map((res) => this.fetchBillings(res)))
  }
  fetchBillings = async (res) => {
    const { patientId, vnNumber, admitTime, patient } = res
    let vnnumber = vnNumber
    const visitDate = moment(admitTime).format('YYYY-MM-DD')
    if (!vnnumber) {
      const getVnNumber = await this.checkVisitNumber(patientId, visitDate)
      if (getVnNumber && getVnNumber.length) {
        vnnumber = getVnNumber[0].vn
      }
    }
    const billings = await this.fetchBillingFromHie(patientId, vnnumber, patient.cId)
    return {
      ...res,
      billings,
    }
  }

  fetchBillingFromHie = async (patientId, vnNumber, cid) => {
    if (vnNumber) {
      const { UserInfo } = this.props
      const response = await UserInfo.fetchUserPaymentStatus(cid, vnNumber)
      if (response) {
        return vnNumber ? response : []
      }
    }
    return []
  }

  render() {
    return (
      <Layout
        {...this.props}
        render={(
          props, //eslint-disable-line
        ) => (
          <BookingTable
            {...props}
            state={this.state}
            fetchPatient={this.fetchPatient}
            //refreshData={this.mounted}
            doctorTypes={this.state.doctorTypes}
            otherTypes={this.state.otherTypes}
            updateVisitNumber={this.updateVisitNumber}
            fetchMedicalBenefit={this.fetchMedicalBenefit}
            fetchUserVisitedByVerifiedFromHIE={this.fetchUserVisitedByVerifiedFromHIE}
            fetchVisitNumber={this.fetchVisitNumber}
            fetchBookingRooms={this.fetchBookingRooms}
            refreshData={this.refreshData}
            fetchBookingGallery={this.fetchBookingGallery}
            setStateOrder={() => this.setState({ orderNo: [] })}
            setCurrentTab={(tab) => this.setState({ currentTab: tab })}
            setImages={() => this.setState({ images: [] })}
            fetchUserImage={this.fetchUserImage}
            setSounds={() => this.setState({ sounds: false })}
            setLinks={() => this.setState({ links: false })}
            fetchRecordFromRoomId={this.fetchRecordFromRoomId}
            style={{ width: '100%' }}
            fetchUserFcmToken={(id, message, time, screen) => this.fetchUserFcmToken(id, message, time, screen)}
          />
        )}
      />
    )
  }
}

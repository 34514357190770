import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles, { Row, Column } from '../styles'
import moment from 'moment'
import { get } from 'lodash'

export default function TimeTable({ data }) {
  //* เวลา (min)
  const erawanTime = get(data, 'travelLog.time.erawanReceiveCase', undefined)
  const vajiraReceiveTime = get(data, 'travelLog.time.vajiraReceiveCase', undefined)
  const orderedTime = get(data, 'travelLog.time.ordered', undefined)
  const leaveBaseTime = get(data, 'travelLog.time.leaveBase', undefined)
  const arrivedTime = get(data, 'travelLog.time.arrivedScene', undefined)
  const leaveTime = get(data, 'travelLog.time.leaveScene', undefined)
  const arrivedHospitalTime = get(data, 'travelLog.time.arrivedHospital', undefined)
  const arrivedBaseTime = get(data, 'travelLog.time.arrivedBase', undefined)
  //* รวมเวลา (min)
  const activateTime = get(data, 'travelLog.totalTime.activateTime', 0)
  const responseTime = get(data, 'travelLog.totalTime.responseTime', 0)
  const onSceneTime = get(data, 'travelLog.totalTime.onSceneTime', 0)
  const leaveSceneToHospitalTime = get(data, 'travelLog.totalTime.leaveSceneToHospitalTime', 0)
  //* เลข กม.
  const kmBeforeLeaveBase = get(data, 'travelLog.kilometers.beforeLeaveBase', 0)
  const kmBeforeLeaveScene = get(data, 'travelLog.kilometers.beforeLeaveScene', 0)
  const kmArrivedHospital = get(data, 'travelLog.kilometers.arrivedHospital', 0)
  const kmArrivedBase = get(data, 'travelLog.kilometers.arrivedBase', 0)
  //* ระยะทาง
  const fromBaseToScene = get(data, 'travelLog.distance.fromBaseToScene', 0)
  const fromSceneToHospital = get(data, 'travelLog.distance.fromSceneToHospital', 0)
  const fromHospitalToBase = get(data, 'travelLog.distance.fromHospitalToBase', 0)

  return (
    <View style={styles.borderedContainerGrey}>
      <Row>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.boldText}>ข้อมูลเวลา</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>เอราวัณรับแจ้ง</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>วชิระรับแจ้ง</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>สั่งการ</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>ออกจากฐาน</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>ถึงที่เกิดเหตุ</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>ออกจากที่เกิดเหตุ</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>ถึง ร.พ.</Text>
        </Column>
        <Column borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>ถึงฐาน</Text>
        </Column>
      </Row>
      <Row>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>เวลา(min)</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>{moment(erawanTime, 'HH:mm').isValid() ? moment(erawanTime).format('HH:mm') : '/'}</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>{moment(vajiraReceiveTime, 'HH:mm').isValid() ? moment(vajiraReceiveTime).format('HH:mm') : '/'}</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>{moment(orderedTime, 'HH:mm').isValid() ? moment(orderedTime).format('HH:mm') : '/'}</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>{moment(leaveBaseTime, 'HH:mm').isValid() ? moment(leaveBaseTime).format('HH:mm') : '/'}</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>{moment(arrivedTime, 'HH:mm').isValid() ? moment(arrivedTime).format('HH:mm') : '/'}</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>{moment(leaveTime, 'HH:mm').isValid() ? moment(leaveTime).format('HH:mm') : '/'}</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>{moment(arrivedHospitalTime, 'HH:mm').isValid() ? moment(arrivedHospitalTime).format('HH:mm') : '/'}</Text>
        </Column>
        <Column borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>{moment(arrivedBaseTime, 'HH:mm').isValid() ? moment(arrivedBaseTime).format('HH:mm') : '/'}</Text>
        </Column>
      </Row>
      <Row>
        <Column borderRight itemsCenter grey flex={1}>
          <Text style={styles.normalText}>รวมเวลา</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={1}></Column>
        <Column borderRight borderBottom itemsCenter grey flex={3} paddingHorizontal={1}>
          <Text style={styles.normalText}>Activation time {activateTime} min</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={2} paddingHorizontal={0.5}>
          <Text style={styles.normalText}>On scene time {onSceneTime} min</Text>
        </Column>
        <Column borderBottom itemsCenter grey flex={2} paddingHorizontal={0.5}></Column>
      </Row>
      <Row borderBottom grey>
        <Column borderRight itemsCenter grey flex={1}>
          <Text style={styles.normalText}>(min)</Text>
          <View style={{ height: 12 }} />
        </Column>
        <Column borderRight flex={5} paddingHorizontal={2.5} justifyCenter itemsCenter grey>
          <Text style={styles.normalText}>Response time {responseTime} min</Text>
        </Column>
        <View style={{ flex: 3 }}>
          <Row flex={1} borderBottom grey>
            <Column borderRight itemsCenter grey flex={2} paddingHorizontal={0.5}>
              <Text style={styles.normalText}>{leaveSceneToHospitalTime}</Text>
            </Column>
            <Column itemsCenter grey flex={1}></Column>
          </Row>
          <Row flex={1}>
            <Column borderRight itemsCenter grey flex={1}>
              <Text style={styles.normalText}></Text>
            </Column>
            <Column itemsCenter grey flex={2} paddingHorizontal={2.5}>
              <Text style={styles.normalText}></Text>
            </Column>
          </Row>
        </View>
      </Row>
      <Row>
        <Column borderRight borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>เลข กม.</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={4} paddingHorizontal={1}>
          <Text style={styles.normalText}>{kmBeforeLeaveBase}</Text>
        </Column>
        <Column borderRight borderBottom itemsCenter grey flex={2} paddingHorizontal={0.5}>
          <Text style={styles.normalText}>{kmBeforeLeaveScene}</Text>
        </Column>
        <Column borderBottom borderRight itemsCenter grey flex={1}>
          <Text style={styles.normalText}>{kmArrivedHospital}</Text>
        </Column>
        <Column borderBottom itemsCenter grey flex={1}>
          <Text style={styles.normalText}>{kmArrivedBase}</Text>
        </Column>
      </Row>
      <Row>
        <Column borderRight itemsCenter grey flex={1}>
          <Text style={styles.normalText}>ระยะทาง</Text>
          <View style={{ height: 12 }} />
        </Column>
        <Column borderRight itemsCenter justifyCenter grey flex={5} paddingHorizontal={2.5}>
          <Text style={styles.normalText}>รวมระยะทางไป {fromBaseToScene} km</Text>
        </Column>
        <View style={[{ flex: 3 }]}>
          <Row borderBottom grey flex={1}>
            <Column borderRight itemsCenter grey flex={2}>
              <Text style={styles.normalText}>ระยะทางไป ร.พ. {fromSceneToHospital} km</Text>
            </Column>
            <Column itemsCenter grey flex={2}></Column>
          </Row>
          <Row flex={1}>
            <Column borderRight itemsCenter grey flex={1}></Column>
            <Column itemsCenter grey flex={2} paddingHorizontal={0.5}>
              <Text style={styles.normalText}>ระยะทางกลับ {fromHospitalToBase} km</Text>
            </Column>
          </Row>
        </View>
      </Row>
    </View>
  )
}

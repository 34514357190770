import { observable, action, computed, toJS } from 'mobx'
import http from '../../services/httpServiceHealthCare'

const apiEndpoint = '/Users'

class User {
  @observable info = {
    data: [],
    error: null
  }

  @action findUser = async (id) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/${id}`)
      return data
    } catch (err) {
      const {response} = err
      return response.data.error
    }
  }

  @action deleteUser = async (id) => {
    try {
      const { data } = await http.delete(`${apiEndpoint}/${id}`)
      return data
    } catch (err) {
      const {response} = err
      return response.data.error
    }
  }

  @computed get getUser() {
    return toJS(this.User.data)
  }
}

export default new User()

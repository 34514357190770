import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import LoginForm from '../../components/LoginForm'
import ImgBackground from '../../images/Vajira.jpg'

const Container = styled.div`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.73)), url(${(props) => props.img});
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
`
@inject('User')
@observer
class Login extends React.Component {
  state = {
    email: '',
    password: '',
  }

  handleChange = (e) => {
    this.setState(e)
  }

  handleSubmit = (e) => {
    const { email, password } = this.state
    const { User } = this.props
    User.login(email, password)
  }

  render() {
    const { email, password } = this.state
    const { User, location } = this.props
    if (User.getSession) {
      if (User.getSession?.roles[0] === 'bloodBankTechnician') {
        return <Redirect to={location.state ? location.state.from.pathname : '/blood-donor-list'} />
      } else if (User.getSession?.roles[0] === 'observerStaff') {
        return <Redirect to={location.state ? location.state.from.pathname : '/telemed-lobby'} />
      }
      return <Redirect to={location.state ? location.state.from.pathname : '/your-settings'} />
    }
    return (
      <Container img={ImgBackground}>
        <LoginForm email={email} password={password} error={User.session.error} onChange={this.handleChange} onSubmit={this.handleSubmit} />
      </Container>
    )
  }
}

Login.propTypes = {
  User: PropTypes.object,
  location: PropTypes.object,
}

Login.defaultProps = {
  // User: null
}

export default Login

import React, { Component } from 'react'
import Layout from '../Home'
import BookingTable from '../../components/bookingTable'
import { observer, inject } from 'mobx-react'
import jwtDecode from 'jwt-decode'
import { message } from 'antd'
import moment from 'moment'
import { find, sortBy, intersectionBy } from 'lodash'
import axios from 'axios'
import { PRACTITIONER_ROLES } from '../../constant'
@inject('Booking')
@inject('Prescription')
@inject('AppUserIdentity')
@inject('UserInfo')
@inject('DoctorAvailableTime')
@inject('PharmacyAvailableTime')
@inject('Prescription')
@inject('BookingGallery')
@inject('UserFcmToken')
@inject('BookingRoom')
@inject('Communication')
@inject('KycPatientImage')
@inject('FCM')
@observer
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataSource: [],
      dataAlert: [],
      dataCancelBookings: [],
      medicines: [],
      prescriptionId: '',
      time: '15:00',
      user: {},
      hieData: null,
      medicalBenefit: false,
      visitNumber: false,
      availableDays: [],
      billingItems: [],
      pharmacyTimes: [],
      isLoading: false,
      patientData: false,
      hash: '',
      currentTab: 'booking',
      images: [],
      image: false,
      orderNo: [],
      drugs: [],
      recordList: [],
      sounds: false,
      links: false,
      soundLoading: false,
      videoCompressLoading : false ,
      videoLink : '',
      tipText : '',
    }
  }
  intervalID = 0
  fetchUserVisitedByVerifiedFromHIE = async (patientId, vnNumber) => {
    const { UserInfo } = this.props
    const everOmaId = await this.fetchAppUserIdentity(patientId)
    await UserInfo.triggerUpdateData(everOmaId)
    const response = await UserInfo.checkDrugsDetailByOrderNo(everOmaId, vnNumber)
    if (response && response.length) {
      this.setState({
        billingItems: response,
      })
    }
  }

  patchBooking = async (id, date, time, admitTime, isForPharmacy) => {
    const { Booking } = this.props
    const dateAsMinutes =
      (moment()
        .day(date)
        .format('d') -
        2) *
      1440
    const asMinutes = moment.duration(time).asMinutes()
    const sumAsMinutes = dateAsMinutes + asMinutes
    const response = await Booking.patchBooking(id, sumAsMinutes, admitTime)
    if (response) {
      if (isForPharmacy) {
        this.setStatus(id, 'PHARMACY_PENDING_BOOKING', false)
      } else {
        this.setStatus(id, 'DOCTOR_PENDING', false)
      }
      message.success('ทำการจองวัน เวลานัดกับแพทย์เสร็จสิ้น')
    }
  }

  fetchCheckBookingNumberPharmacy = async (data) => {
    const { PharmacyAvailableTime } = this.props
    const response = await PharmacyAvailableTime.checkBookingNumberPharmacy(data)
    let availableDays = []
    response.map((res) => {
      const availableTime = {
        isAvailable: res.maxQuantity !== res.count,
        time: `${moment()
          .startOf('isoWeek')
          .add(res.startTime, 'minutes')
          .format('HH:mm')} - ${moment()
          .startOf('isoWeek')
          .add(res.endTime, 'minutes')
          .format('HH:mm')}`,
        value: moment()
          .startOf('isoWeek')
          .add(res.startTime, 'minutes')
          .format('HH:mm'),
      }
      availableDays.push(availableTime)
    })
    this.setState({
      pharmacyTimes: availableDays,
    })
  }

  fetchDoctorAvailableTimes = async (doctorId) => {
    const { DoctorAvailableTime, Booking } = this.props
    const response = await DoctorAvailableTime.fetchfilterType(doctorId)
    const resBooking = await Booking.getFilterByDoctorId(doctorId)
    const availableDays = []
    response.time.forEach((t) => {
      const timeStart = t[0]
      const timeEnd = t[1]
      const data = {
        day: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format('D'),
        date: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format('dddd'),
        times: [],
        value: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format(),
      }
      for (let time = timeStart; time <= timeEnd; time += response.minute) {
        const timeForSelect = {
          label: moment()
            .startOf('isoWeek')
            .add(time, 'minutes')
            .format('HH:mm'),
          isAvailable: find(resBooking, { bookingTime: time }),
        }
        data.times.push(timeForSelect)
      }
      availableDays.push(data)
    })
    this.setState({ availableDays: availableDays })
  }

  fetchPharmacyAvailableTimes = async (doctorId) => {
    const { PharmacyAvailableTime, Booking } = this.props
    await PharmacyAvailableTime.fetchPharmacyAvailableTime()
    const response = await PharmacyAvailableTime.getPharmacyAvailableTime
    const resBooking = await Booking.getFilterByDoctorId(doctorId)
    const availableDays = []
    response.forEach((t) => {
      const timeStart = t.time[0]
      const timeEnd = t.time[1]
      const data = {
        day: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format('D'),
        date: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format('dddd'),
        data: {
          date: moment()
            .startOf('isoWeek')
            .add(timeStart, 'minutes')
            .format('YYYY-MM-DD'),
          startTime: timeStart,
          endTime: timeEnd,
          day: moment()
            .startOf('isoWeek')
            .add(timeStart, 'minutes')
            .format('dddd'),
        },
        value: moment()
          .startOf('isoWeek')
          .add(timeStart, 'minutes')
          .format(),
      }
      availableDays.push(data)
    })
    const sortByAvailableDays = sortBy(availableDays, [
      function(o) {
        return o.day
      },
    ])
    const intersection = intersectionBy(sortByAvailableDays, 'day')
    this.setState({
      availableDays: intersection,
    })
  }

  checkFilterByRole = (roles, data) => {
    let dataRes = []
    let dataAlert = []
    let dataCancel = []
    if (roles.includes('pharmacy')) {
      dataRes = data.filter((res) => {
        return ['PHARMACY_COMPLETED', 'PHARMACY_DECLINE_BOOKING'].includes(res.status)
      })
      dataCancel = data.filter((res) => {
        return res.status.includes('PHARMACY_DECLINE_BOOKING')
      })
      dataAlert = data.filter((res) => {
        return ['PHARMACY_ALERT'].includes(res.status)
      })
    } else if (roles.includes('doctor')) {
      dataRes = data.filter((res) => {
        return ['DOCTOR_COMPLETED', 'DOCTOR_DECLINE'].includes(res.status)
      })
    } else if (['nurse', 'physiotherapist', 'speechCorrectionSpecialist', 'pharmacySchedule', 'nutritionist'].includes(roles[0])) {
      dataRes = data.filter((res) => {
        return ['STAFF_COMPLETED', 'STAFF_DECLINE'].includes(res.status)
      })
    } else if (roles.includes('callCenter')) {
      dataRes = data.filter((res) => {
        return ['PHARMACY_COMPLETED', 'PHARMACY_DECLINE_BOOKING', 'DOCTOR_COMPLETED', 'DOCTOR_DECLINE'].includes(res.status)
      })
      dataAlert = data.filter((res) => {
        return ['DOCTOR_ALERT', 'PHARMACY_ALERT'].includes(res.status)
      })
    }
    return { dataRes, dataCancel, dataAlert }
  }

  fetchDataFilterByDoctorId = async (Booking, user) => {
    let response = await Booking.getFilterByDoctorId(user.id)
    return this.checkFilterByRole(user.roles, response)
  }

  fetchDataBooking = async (Booking, user) => {
    let response = await Booking.fetchBooking()
    return this.checkFilterByRole(user.roles, response)
  }

  fetchDataTable = async () => {
    const { Booking } = this.props
    let response
    if (PRACTITIONER_ROLES.includes(this.state.user.roles[0])) {
      response = await this.fetchDataFilterByDoctorId(Booking, this.state.user)
    } else if (this.state.user.roles.includes('pharmacy') || this.state.user.roles.includes('callCenter')) {
      response = await this.fetchDataBooking(Booking, this.state.user)
    }
    this.setState({
      dataSource: response.dataRes,
      dataCancelBookings: response.dataCancel,
      dataAlert: response.dataAlert,
    })
  }

  updateTimer = (num) => {
    var minutes = 60 * num
    this.startTimer(minutes)
  }

  getUser = () => {
    const user = jwtDecode(localStorage.getItem('token'))
    this.setState({
      user: user,
    })
  }

  refreshData = async () => {
    await this.getUser()
    this.fetchDataTable()
  }

  async componentDidMount() {
    await this.getUser()
    this.fetchDataTable()
  }

  updateStatus = (response, id) => {
    let updateItems = this.state.dataSource.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          status: response.status,
        }
      }
      return data
    })
    this.setState({
      dataSource: updateItems,
    })
  }

  setStatus = async (id, status, isUpdate) => {
    const { Booking } = this.props
    const response = await Booking.setStatus(id, status)
    if (response) {
      message.success('เปลี่ยนสถานะเสร็จสิ้น')
      this.updateStatus(response, id)
      if (!isUpdate) {
        this.fetchDataTable()
      } else {
        const data = this.checkFilterByRole(this.state.user.roles, this.state.dataSource)
        this.setState({
          dataSource: data.dataRes,
          dataAlert: data.dataAlert,
        })
      }
    }
  }

  setStatusPrescription = async (prescriptionId, status) => {
    const { Prescription } = this.props
    await Prescription.updateStatus(prescriptionId, status)
    this.fetchDataTable()
  }

  createPrescription = async (bookingId, patientId) => {
    const { Prescription, Booking } = this.props
    const response = await Prescription.createPrescription(patientId)
    await Booking.updateBooking(bookingId, response.id, true)
  }

  updatePrescription = async (medicines, bookingId) => {
    const { Prescription } = this.props
    const response = await Prescription.updatePrescription(this.state.prescriptionId, medicines)
    if (response) {
      this.setStatus(bookingId, 'PHARMACY_PENDING_RX')
    }
  }

  startTimer = (duration) => {
    var timer = duration,
      minutes,
      seconds
    setInterval(() => {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10)
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      let showTime = minutes + ':' + seconds
      this.setState({
        time: showTime,
      })
      if (--timer < 0) {
        timer = duration
        this.fetchDataTable()
      }
    }, 1000)
  }

  fetchPatient = async (id) => {
    const { AppUserIdentity, UserInfo } = this.props
    const everOmaId = await this.fetchAppUserIdentity(id)
    if (everOmaId) {
      const resPatient = await UserInfo.fetchUserInfoByPatientId(everOmaId)
      const resHie = await UserInfo.checkUserDetail(everOmaId)
      this.setState({
        hieData: resHie,
        patientData: resPatient,
      })
    }
  }

  updateVisitNumber = async (id, vnNumber, isPrescription) => {
    const { Booking } = this.props
    const response = await Booking.updateBooking(id, vnNumber, isPrescription)
    if (response) {
      message.success('อัปเดตหมายเลขวิสิตเสร็จสิ้น')
    }
  }

  fetchAppUserIdentity = async (id) => {
    const { AppUserIdentity } = this.props
    const response = await AppUserIdentity.fetchAppUserIdentity(id)
    if (response && response.length) {
      return response[response.length - 1].everOmaId
    } else {
      return false
    }
  }

  fetchUserFcmToken = async (id, message) => {
    const { UserFcmToken, FCM } = this.props
    const everOmaId = await this.fetchAppUserIdentity(id)
    if (everOmaId) {
      const response = await UserFcmToken.fetchUserFcmToken(everOmaId)
      if (response && response.length) {
        response.forEach(async (res) => {
          let notification = {
            body: message,
            title: 'แจ้งเตือน',
            icon: 'ic_launcher',
            color: '#f1c40f',
            sound: 'default',
          }
          await FCM.sendMessage(res.fcmToken, notification)
        })
      }
    }
  }

  fetchMedicalBenefit = (id) => {
    const { UserInfo } = this.props
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const everOmaId = await this.fetchAppUserIdentity(id)
        if (everOmaId) {
          const response = await UserInfo.checkLatestMedicalBenefit(everOmaId)
          if (response && response.PID) {
            this.setState({
              medicalBenefit: response,
              isLoading: false,
            })
          } else {
            this.setState({
              medicalBenefit: false,
              isLoading: false,
            })
          }
        } else {
          this.setState({
            medicalBenefit: false,
            isLoading: false,
          })
        }
      }
    )
  }

  fetchVisitNumber = (id, patientId, prescriptionId, selectedDate) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { UserInfo } = this.props
        const everOmaId = await this.fetchAppUserIdentity(patientId)
        await UserInfo.triggerUpdateData(everOmaId)
        const response = await UserInfo.selectOrderNumber(everOmaId, selectedDate)
        if (response && response.orderNo && response.orderNo.length) {
          this.setState({
            orderNo: response.orderNo,
          })
        } else {
          this.setState({
            orderNo: [],
          })
        }
        this.setState({
          isLoading: false,
        })
      }
    )
  }

  fetchBookingGallery = (id) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { BookingGallery } = this.props
        const response = await BookingGallery.getBookingGallery(id)
        const images = response.filter((res) => {
          if (res.image) {
            return res.image
          }
        })
        this.setState({
          images,
          isLoading: false,
        })
      }
    )
  }

  fetchUserImage = async (id) => {
    const { KycPatientImage } = this.props
    const everOmaId = await this.fetchAppUserIdentity(id)
    if (everOmaId) {
      const response = await KycPatientImage.fetchPatientImage(everOmaId)
      if (response) {
        this.setState({
          image: response.image,
        })
      } else {
        this.setState({
          image: false,
        })
      }
    }
  }

  fetchBookingRooms = (id) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { BookingRoom } = this.props
        const response = await BookingRoom.getBookingRooms(id)
        this.setState({
          sounds: response,
          isLoading: false,
        })
      }
    )
  }

  fetchRecordFile = async (sid, recordingId) => {
    this.setState({
      soundLoading: true,
    })
    const { BookingRoom } = this.props
    const response = await BookingRoom.fetchRecordFileByRoomAndId(sid, recordingId)
    if (response && response.status === 'success') {
      window.open(response.link)
      this.setState({
        links: response.link,
      })
    } else {
      message.error('ไม่พบข้อมูลไฟล์วิดีโอ/เสียง')
    }
    this.setState({
      soundLoading: false,
    })
  }

  fetchRecordListFromRoomId = async (sid) => {
    this.setState({
      soundLoading: true,
    })
    const { BookingRoom } = this.props
    const response = await BookingRoom.fetchRecordListFromRoomId(sid)
    if (response) {
      this.setState({
        recordList: response,
        soundLoading: false,
      })
    } else {
      message.error('ไม่พบข้อมูลในระบบ')
    }
    this.setState({
      soundLoading: false,
    })
  }

  fetchOrderNumber = (orderNumber, patientId) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { UserInfo } = this.props
        const everOmaId = await this.fetchAppUserIdentity(patientId)
        await UserInfo.triggerUpdateData(everOmaId)
        if (everOmaId) {
          const response = await UserInfo.checkDrugsDetailByOrderNo(everOmaId, orderNumber)
          this.setState({
            drugs: response,
            isLoading: false,
          })
        }
      }
    )
  }

  checkAndRenderCompositionVideo = async (roomId) => {
    const { BookingRoom } = this.props
    let count = 0
    this.setState({
      tipText : 'กำลังโหลดไฟล์วีดีโอ...',
      videoCompressLoading: true,
    })
    this.intervalID = setInterval(async () => {
      const respFile = await BookingRoom.getCompositionFile(roomId);
      if(respFile.status == false){
        const resp = await BookingRoom.compressRecordFileComposition(roomId);
        this.setState({
          tipText : 'กำลังรวมไฟล์วีดีโอ...'
        })
      }else if(respFile && respFile.status == 'success'){
          this.setState({
            videoLink : respFile.link,
            videoCompressLoading : false
          })
          clearInterval(this.intervalID);
      }else if (count > 5){
        this.setState({
          tipText : 'การรวมไฟล์อาจใช้เวลานาน เนื่องจากไฟล์มีขนาดใหญ่...'
        })
      }
      count++
    }, 5000)
  }

  componentDidUpdate() {
    if(this.state.videoCompressLoading === false){
      clearInterval(this.intervalID);
    }
  }
  render() {
    return (
      <Layout
        {...this.props}
        render={(
          props //eslint-disable-line
        ) => (
          <BookingTable
            {...props}
            state={this.state}
            fetchPatient={this.fetchPatient}
            setStatus={this.setStatus}
            createPrescription={this.createPrescription}
            updatePrescription={this.updatePrescription}
            updateVisitNumber={this.updateVisitNumber}
            fetchMedicalBenefit={this.fetchMedicalBenefit}
            fetchVisitNumber={this.fetchVisitNumber}
            fetchDoctorAvailableTimes={this.fetchDoctorAvailableTimes}
            patchBooking={this.patchBooking}
            fetchUserVisitedByVerifiedFromHIE={this.fetchUserVisitedByVerifiedFromHIE}
            setStatusPrescription={this.setStatusPrescription}
            fetchPharmacyAvailableTimes={this.fetchPharmacyAvailableTimes}
            fetchCheckBookingNumberPharmacy={this.fetchCheckBookingNumberPharmacy}
            refreshData={this.refreshData}
            fetchRecordSounds={this.fetchRecordSounds}
            fetchBookingGallery={this.fetchBookingGallery}
            fetchUserFcmToken={this.fetchUserFcmToken}
            fetchOrderNumber={this.fetchOrderNumber}
            fetchUserImage={this.fetchUserImage}
            setCurrentTab={(tab) => this.setState({ currentTab: tab })}
            setImages={() => this.setState({ images: [] })}
            setSounds={() => this.setState({ sounds: false })}
            setLinks={() => this.setState({ links: false })}
            fetchBookingRooms={this.fetchBookingRooms}
            fetchRecordListFromRoomId={this.fetchRecordListFromRoomId}
            fetchRecordFile={this.fetchRecordFile}
            setRecordList={(data) => this.setState({ recordList: data })}
            checkAndRenderCompositionVideo={this.checkAndRenderCompositionVideo}
            setState={(val) => this.setState(val)}
            style={{ width: '100%' }}
          />
        )}
      />
    )
  }
}

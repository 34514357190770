import React, { useEffect, useState } from 'react'
import { Spin, Tag } from 'antd'
import http from '../../services/httpServiceHealthCare'

const CheckHnnumber = ({ data, loading }) => {
  const [hnNumber, setHnNumber] = useState(false)
  const [loadSpin, setLoadSpin] = useState(false)
  useEffect(() => {
    setHnNumber(false)
    fetchHnId(data)
  }, [data])

  const fetchHnId = async (data) => {
    setLoadSpin(true);
    
    if (data && data.patient && !data.patient.hnId) {
      const cId = data?.patient?.cId ?? null;
      
      if (cId) {
        try {
          const { data: hieData } = await http.get(`/UserInfos/hiePatientInfo`, {
            params: { cId },
          });
  
          if (hieData?.hn?.length) {
            let hn = hieData.hn[0].hn ? hieData.hn[0].hn : '-';
            setHnNumber(convertHn(hn.toString()));
          } else {
            setHnNumber('-');
          }
        } catch (error) {
          console.error('Error fetching HIE data:', error);
          setHnNumber('-');
        }
      }
    } else if (data && data.patient && data.patient.hnId) {
      setHnNumber(convertHn(data.patient.hnId.toString()));
    } else {
      setHnNumber('-');
    }
    
    setLoadSpin(false);
  }
  

  const convertHn = (hn) => {
    const second = hn.substring(0, 2)
    const past = hn.substring(2).split('')
    let pass = 0
    const final = past.map((string) => {
      if (pass || string !== '0') {
        pass = 1
        return string
      }
    })
    return final.join('').concat('/', second)
  }

  return hnNumber ? hnNumber : loading
}

export default CheckHnnumber

import { observable, action, computed, toJS } from 'mobx'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/AppUserAndOtherTypes'

class AppUserAndOtherType {
  @observable info = {
    data: [],
    error: null
  }

  @action addOtherType = async (appUserId, otherTypeId) => {
    try {
      const { data } = await http.post(apiEndpoint, {
        appUserId,
        otherTypeId
      })
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @action getUserOtherTypeId = async (appUserId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}?filter[where][appUserId]=${appUserId}`)
      if (data) {
        return data[0].id
      }
      throw "User not found"
    } catch (error) {
      console.warn(error)
    }
  }

  @action updateOtherType = async (appUserId, otherTypeId) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${appUserId}`, {
        otherTypeId
      })
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  @computed get getAppUserAndOtherType() {
    return toJS(this.AppUserAndOtherType.data)
  }
}

export default new AppUserAndOtherType()

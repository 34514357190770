import React, { useEffect, useState } from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Layout, Menu } from 'antd'
import styled from 'styled-components'
import { find } from 'lodash'
import logo from '../../images/Logo.png'
import './style.css'

const { Sider: SiderAnt } = Layout
const Sider = styled(SiderAnt)`
  &&&&&&& {
    background-color: ${(props) => props.theme.primaryText};
    img {
      margin: 0px auto;
      padding-top: 20px;
      display: block;
    }
    h5 {
      color: white;
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
      font-weight: bold;
    }
    ul {
      background-color: ${(props) => props.theme.primaryText};
      li {
        color: white;
      }

      i {
        color: ${(props) => props.theme.primaryButton};
      }

      .ant-menu-item-selected {
        background-color: ${(props) => props.theme.activeMenu};
      }
    }
  }
`

const index = (props) => {
  const { options, history } = props
  const [collapsed, setCollapsed] = useState(false)
  const [currentMenu, setCurrentMenu] = useState('')

  useEffect(() => {
    checkPath()
    if (window.screen.availWidth <= 768) {
      setCollapsed(true)
    }
  }, [])

  useEffect(() => {
    if (options?.length > 0) {
      checkPath()
    }
  }, [options])

  const checkPath = () => {
    const path = history.location.pathname.split('/')[1]
    const findPath = find(options, { value: path })
    if (findPath) {
      setCurrentMenu(`nav-${findPath.label}`)
    }
  }

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={(collapsed) => setCollapsed(collapsed)}>
      <div className="sticky-header">
        <img src={logo} alt="logo" width={100} height={100} />
        {!collapsed ? <h5>Vajira@Home</h5> : ''}
        {props.liveStatus && (
          <div>
            <div className="holder" style={{ display: 'inline-block' }}>
              <div className="livenow">
                <div className="child1" key={1} />
                <div className="child2" key={2} />
                <div className="child3" key={3} />
              </div>
            </div>
            <div style={{ display: 'inline-block' }}>
              <div style={{ marginTop: 10 }}>
                <h5 style={{ lineHeight: '50px', color: 'rgba(57,167,95,1)' }}>ออนไลน์</h5>
              </div>
            </div>
          </div>
        )}
        <div className="scrollable-menu">
          <Menu theme="dark" mode="inline" onClick={(e) => setCurrentMenu(e.key)} selectedKeys={[currentMenu]}>
            {props.options.map((option) => (
              <Menu.Item onClick={option.onClick} key={`nav-${option.label}`}>
                <LegacyIcon type={option.icon} />
                <span>{option.label}</span>
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </div>
    </Sider>
  )
}

export default index

import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles, { CheckboxWithLabel, Row, Column } from '../styles'
import { get } from 'lodash'

export default function SymtomTable({ data }) {

  const signAndSymptom = get(data, "signAndSymptom", "")
  const allergies = get(data, "allergies", "")
  const currentMedication = get(data, "currentMedication", "")
  const lastOralIntake = get(data, "lastOralIntake", "")
  const underyingDisease = get(data, "underyingDisease", [])
  const event = get(data, "event", "")

  return (
    <View style={styles.borderedContainer}>
      <Row>
        <Column borderBottom borderRight width={185}>
          <Row itemsTop spaceBetween>
            <Column>
              <Text style={styles.normalText}>Sign and Symptom</Text>
              <Text style={styles.normalText}>{signAndSymptom.includes("no_data") ? "" : signAndSymptom}</Text>
            </Column>
            <CheckboxWithLabel small checked={signAndSymptom.includes("no_data")} title="N/A" />
          </Row>
        </Column>
        <Column borderBottom width={120}>
          <Row itemsTop spaceBetween>
            <Column>
              <Text style={styles.normalText}>Allergies</Text>
              <Text style={styles.normalText}>{allergies.includes("no_data") ? "" : allergies}</Text>
            </Column>
            <CheckboxWithLabel small checked={allergies.includes("no_data")} title="N/A" />
          </Row>
        </Column>
      </Row>
      <Row>
        <Column borderBottom borderRight width={185}>
          <Row itemsTop spaceBetween>
            <Column>
              <Text style={styles.normalText}>Current Medication</Text>
              <Text style={styles.normalText}>{currentMedication.includes("no_data") ? "" : currentMedication}</Text>
            </Column>
            <CheckboxWithLabel small checked={currentMedication.includes("no_data")} title="N/A" />
          </Row>
        </Column>
        <Column borderBottom width={120}>
          <Row itemsTop spaceBetween>
            <Column>
              <Text style={styles.normalText}>Last oral intake</Text>
              <Text style={styles.normalText}>{lastOralIntake.includes("no_data") ? "" : lastOralIntake}</Text>
            </Column>
            <CheckboxWithLabel small checked={lastOralIntake.includes("no_data")} title="N/A" />
          </Row>
        </Column>
      </Row>
      <Row>
        <Column borderBottom width={305}>
          <Row itemsTop spaceBetween>
            <Column>
              <Text style={styles.normalText}>Underying Disease</Text>
              <Text style={styles.normalText}>{underyingDisease.includes("no_data") ? "" : underyingDisease.map((item) => {
                return `${item} `
              })}</Text>
            </Column>
            <CheckboxWithLabel small checked={underyingDisease.includes("no_data")} title="N/A" />
          </Row>
        </Column>
      </Row>
      <Row>
        <Column width={305}>
          <Row itemsTop spaceBetween>
            <Column>
              <Text style={styles.normalText}>Event</Text>
              <Text style={styles.normalText}>{event.includes("no_data") ? "" : event}</Text>
            </Column>
            <CheckboxWithLabel small checked={event.includes("no_data")} title="N/A" />
          </Row>
        </Column>
      </Row>
    </View>
  )
}

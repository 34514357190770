import React, { Component } from 'react'
import ChangePasswordForm from '../../components/ChangePasswordForm'
import { FileImageOutlined, LeftOutlined } from '@ant-design/icons'
import { Menu, Popconfirm, Row, Col, Modal, Tooltip, Input, message, Alert, Spin, Tag, Tabs, Select } from 'antd'
import Button from '../../components/Button'
import jwtDecode from 'jwt-decode'
import 'react-html5-camera-photo/build/css/index.css'
import { inject, observer } from 'mobx-react'
import firebase from 'firebase'

@inject('User')
@observer
export default class PatientBooking extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = {
      user: {},
      fullname: '',
      changeProfileName: false,
      visibleModalChangePassword: false,
      confirmPassword: '',
      isLoading: false,
      showChangePassword: false,
    }
  }

  componentDidMount() {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { User } = this.props
        const user = jwtDecode(localStorage.getItem('token'))
        const userRes = await User.fetchUserInfo(user.id)
        this.setState({
          fullname: userRes ? userRes.fullname : null,
          user: userRes ? userRes : null,
          isLoading: false,
        })
      },
    )
  }

  onSubmitChangePassword = async (value) => {
    const { User } = this.props
    const response = await User.changePassword(value.oldPassword, value.newPassword)
    if (response.statusCode === 400) {
      message.error(`${response.name}: ${response.message}`)
    } else {
      message.success('เปลี่ยนรหัสผ่านสำเร็จ')
    }
    this.setState({
      visibleModalChangePassword: false,
    })
  }

  handleChangeProfileImage = (e) => {
    const { User } = this.props
    const { id } = jwtDecode(localStorage.getItem('token'))
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > 2097152) {
        message.error('เกิดข้อผิดพลาด กรุณาอัปโหลดรูปภาพที่มีขนาดไม่เกิน 2MB')
      } else {
        const fileUpload = e.target.files[0]
        const fileName = e.target.files[0].lastModified.toString()
        const storageRef = firebase.storage().ref(fileName)
        const task = storageRef.put(fileUpload)
        message.warning('กำลังอัปโหลดรูปภาพ', 1)
        task.on(
          `state_changed`,
          (snapshort) => {
            // let percentage = (snapshort.bytesTransferred / snapshort.totalBytes) * 100
            //Process
          },
          (error) => {
            //Error
            message.error(error.message)
          },
          async () => {
            //Success
            message.success('อัปโหลดรูปสำเร็จ')
            const image = await storageRef.getDownloadURL()
            const response = await User.updateProfileImage(id, image)
            this.setState((prevState) => ({
              user: {
                ...prevState.user,
                profileImage: image,
              },
            }))
          },
        )
      }
    }
  }

  render() {
    const { userInfo } = this.props.props
    return (
      <div style={{ width: '100%' }}>
        <Modal
          visible={this.props.visibleModalProfile}
          onOk={() => {
            this.props.setVisibleModalProfile(false)
            this.setState({ showChangePassword: false })
          }}
          onCancel={() => {
            this.props.setVisibleModalProfile(false)
            this.setState({ showChangePassword: false })
          }}
          title={
            <Row gutter={24}>
              {this.state.showChangePassword && (
                <Col>
                  <LeftOutlined style={{ cursor: 'pointer' }} onClick={() => this.setState({ showChangePassword: false })} />
                </Col>
              )}
              <Col style={{ marginTop: 3 }}>{this.state.showChangePassword ? 'เปลี่ยนรหัสผ่าน' : 'โปรไฟล์'}</Col>
            </Row>
          }
          footer={null}
        >
          {!this.state.showChangePassword ? (
            <>
              <Row
                gutter={16}
                style={{
                  padding: '30px 15px',
                  margin: '0px',
                }}
              >
                <Col xs={{ span: 6 }}>
                  <div>
                    <label htmlFor="upload-button">{this.state.user && this.state.user.profileImage ? <img src={this.state.user.profileImage} style={{ cursor: 'pointer' }} width="100" height="100" /> : <FileImageOutlined />}</label>
                    <input type="file" accept="image/png, image/jpeg" id="upload-button" style={{ display: 'none' }} onChange={this.handleChangeProfileImage} />
                    <br />
                  </div>
                </Col>
                <Col style={{ overflowWrap: 'break-word' }} xs={{ span: 18 }}>
                  <div style={{ fontWeight: 'bold', fontSize: 18 }}>
                    {this.state.changeProfileName ? (
                      <input
                        ref={this.inputRef}
                        value={this.state.fullname}
                        onChange={(e) => {
                          this.setState({ fullname: e.target.value })
                        }}
                      />
                    ) : this.state.fullname ? (
                      <span onClick={() => this.setState({ changeProfileName: true })}>{this.state.fullname}</span>
                    ) : null}
                  </div>
                  <div>{userInfo && userInfo.email}</div>
                </Col>
                <Col span={24}>
                  <p style={{ color: 'red', fontSize: '12px' }}>* สูงสุด 2MB. ประเภท .png .jpeg</p>
                </Col>
                <Col span={24} className="text-right">
                  <a
                    href="#"
                    style={{ fontSize: '12px' }}
                    onClick={() =>
                      this.setState({
                        showChangePassword: true,
                      })
                    }
                  >
                    เปลี่ยนรหัสผ่าน
                  </a>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <ChangePasswordForm onSubmit={this.onSubmitChangePassword} />
            </>
          )}
        </Modal>
      </div>
    )
  }
}

import styled from 'styled-components'
import { Col } from 'antd'

export const PressureText = styled(Col)`
  text-align: center;
  border: 1px solid #eee;
  padding: 10px;
  backgroundcolor: #fffff;
  color: ${props => props.color};
`

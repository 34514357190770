import { observable, action, computed, toJS } from 'mobx'

import http from '../../services/httpService'

const apiEndpoint = '/Symptoms'

class Symptoms {
  @observable symptoms = {
    data: [],
    error: null
  }


  @action fetchSymptoms = async () => {
    try {
      const symptoms = await http.get(`${apiEndpoint}`, {
        params: {
          filter: JSON.stringify({
            include: 'doctorType'
          })
        }
      })
      this.symptoms.data = symptoms.data
    } catch (error) {
      this.symptoms.error = error.response
    }
  }

  @action getSymptomById = async (id) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/${id}`)
      return data
    } catch (error) {
      this.symptoms.error = error.response
      return null
    }
  }

  @action createSymptom = async (payload) => {
    try {
      const { data } = await http.post(`${apiEndpoint}`, payload)
      return data
    } catch (error) {
      console.log('register error', error)
      return error
    }
  }

   @action updateSymptom = async (data, id) => {
     try {
       http.patch(`${apiEndpoint}/${id}`, data)
       await this.fetchSymptoms()
     } catch (e) {
       // console.error(e)
     }
   }

   @action deleteSymptom = async (id) => {
     try {
       await http.delete(`${apiEndpoint}/${id}`)
       await this.fetchSymptoms()
     } catch (e) {
       // console.error(e)
     }
   }

  @computed get getSymptoms() {
     return toJS(this.symptoms.data)
   }
}

export default new Symptoms()

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

const ErrMsg = styled.div`
  color: red;
`
const PatientProfile = styled.img`
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 20px;
  object-fit: cover;
`
@inject('User')
@inject('ConsultCase')
@observer
class index extends Component {
  static propTypes = {
    User: PropTypes.object.isRequired,
    err: PropTypes.string,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
    err: ''
  }

  async componentDidMount() {
    const { User } = this.props
    await User.fetchInfo()
    // await User.setUpNotification()
    // ConsultCase.fetchCases()
  }

  render() {
    const {
      User,
      err,
      history,
    } = this.props

    return (
      <React.Fragment>
        <div>
          <PatientProfile
            src={User.info.data ? User.info.data.profileImage : null}
            alt="profileImage"
          />
        </div>
        <div>
          <button
            type="button"
            onClick={() => history.push('/logout')}
          >
            Logout
          </button>
        </div>
        {
          err && <ErrMsg>กรุณาอนุญาตการแจ้งเตือน</ErrMsg>
        }
        {
          User.info.data && User.info.data.doctorTypeId && User.info.data.nationality === 'thai'
          && (
            <div>
              <button type="button" onClick={() => history.push('/')}>History consult case</button>
              <button type="button" onClick={() => history.push('/prescriptionlist')}>Pending prescription approval</button>
            </div>
          )
        }
      </React.Fragment>
    )
  }
}

export default index

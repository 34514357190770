import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_HEALTH_CARE
})

function setJwt(jwt) {
  if (jwt) {
    // axios.defaults.headers.common['x-auth-token'] = jwt
    axios.defaults.params = {}
    axios.defaults.params['access_token'] = jwt //eslint-disable-line
  }
}

function clearJwt() {
  if (axios.defaults.params) {
    delete axios.defaults.params
  }
}

export default {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  patch: instance.patch,
  setJwt,
  clearJwt
}

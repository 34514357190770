import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spin, Card, Row, Tabs, Table, Button, DatePicker } from 'antd';
import moment from 'moment';
import SurveyBarChart from '../../components/Chart/SurveyChart'; // Import the pie chart component
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const DataCard = styled(Card)`
  height: 100%;
  width: 400px;
  min-width: 250px;
  cursor: pointer;
  border-radius: 5px;
  border: 0.5px solid #d3d3d3;
  &:hover {
    transform: scale(1.08);
    z-index: 999;
  }
  transition: 0.3s;
`;

const View = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h5`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const Description = styled.p`
  font-size: 2.4rem;
  color: #6e6e6e;
`;

const ReportSubmission = (props) => {
    const { submissions, surveys } = props;
    const [summary, setSummary] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [filteredDataSource, setFilteredDataSource] = useState([])
    const [columnsAnswer, setColumnsAnswer] = useState([]);
    const [answerDataSource, setAnswerDataSource] = useState([]);
    const [tab, setTab] = useState('0');
    const [viewMode, setViewMode] = useState('table'); // Add state for view mode
    const [dateRange, setDateRange] = useState([null, null]);

    useEffect(() => {
        if (surveys.length > 0 && submissions.length > 0) {
            countSubmission();
            querySubmission(tab);
        }
    }, [submissions, surveys]);

    useEffect(() => {
        if (dataSource.length === 0) {
            setAnswerDataSource([]);
            setColumnsAnswer([]);
        }
        if (dataSource.length > 0) {
            querySubmissionByMonth(dataSource);
        }
    }, [dataSource]);


    useEffect(() => {
        if (dateRange[0] && dateRange[1] && dataSource.length > 0) {
            countSubmission();
            querySubmission(tab);
            querySubmissionByMonth(filteredDataSource.length > 0 ? filteredDataSource : dataSource);
        } else if (dateRange[0] === null && dateRange[1] === null && dataSource.length > 0) {
            setFilteredDataSource([])
            querySubmissionByMonth(dataSource)
        }
    }, [dateRange])

    const querySubmissionByMonth = (source) => {
        const currentDate = new Date();
        let answerMap = {};
        const MONTH_NAMES = [
            'มกราคม',
            'กุมภาพันธ์',
            'มีนาคม',
            'เมษายน',
            'พฤษภาคม',
            'มิถุนายน',
            'กรกฎาคม',
            'สิงหาคม',
            'กันยายน',
            'ตุลาคม',
            'พฤศจิกายน',
            'ธันวาคม',
        ];
        const THAI_YEAR_OFFSET = 543;
        source.forEach((submission) => {
            let count = {};
            submission.forEach((sub) => {
                if (!(sub.answer >= 0 && sub.answer < 6)) {
                    const splitAnswer = sub.answer.split(', ');
                    splitAnswer.reduce((acc, choice) => {
                        count[choice] = (count[choice] || 0) + 1;
                        return acc;
                    }, {});
                }

                const submissionYear = new Date(sub.createdAt).getFullYear();
                const submissionMonth = new Date(sub.createdAt).getMonth() + 1;
                const submissionDate = `${submissionYear}-${submissionMonth}`;
                const questionId = sub.questionId;
                const questionTitle = sub.questionTitle;

                if (!answerMap[questionId]) {
                    answerMap[questionId] = {};
                }

                if (!answerMap[questionId][submissionDate]) {
                    answerMap[questionId][submissionDate] = {
                        answer: 0,
                        maximum: 0,
                        questionTitle,
                        count: 0,
                    };
                }
                if (sub.answer.length === 1) {
                    answerMap[questionId][submissionDate].answer += parseInt(sub.answer);
                    answerMap[questionId][submissionDate].maximum += 5;
                    answerMap[questionId][submissionDate].count += 1;
                }
            });
        });

        const questionIds = Object.keys(answerMap);
        const submissionDate = new Set();

        questionIds.forEach((questionId) => {
            Object.keys(answerMap[questionId]).forEach((date) => {
                submissionDate.add(date);
            });
        });

        const columns = [];
        columns.push({
            title: 'Question',
            dataIndex: 'questionTitle',
            key: 'questionTitle',
            fixed: 'left',
        });
        let datesArray = Array.from(submissionDate);
        datesArray.sort((a, b) => {
            // Convert 'YYYY-M' to 'YYYY-MM' format
            let formattedA = a.split('-');
            formattedA[1] = formattedA[1].padStart(2, '0');
            let dateA = new Date(formattedA.join('-'));

            let formattedB = b.split('-');
            formattedB[1] = formattedB[1].padStart(2, '0');
            let dateB = new Date(formattedB.join('-'));

            return dateB - dateA;
        });
        if (dateRange[0] && dateRange[1]) {
            const startDate = moment(dateRange[0]).startOf('month');
            const endDate = moment(dateRange[1]).endOf('month');
            datesArray = datesArray.filter((date) => {
                const [year, month] = date.split('-');
                const dateMoment = moment(`${year}-${month.padStart(2, '0')}`, 'YYYY-MM');
                return dateMoment.isBetween(startDate, endDate, null, '[]');
            });
        }

        datesArray.forEach((date) => {
            const year = date.split('-')[0];
            const month = date.split('-')[1];
            if (currentDate >= new Date(year, month - 1)) {
                const columnKey = `${date}`;
                columns.push({
                    title: `${MONTH_NAMES[month - 1]} ${parseInt(year) + THAI_YEAR_OFFSET}`,
                    dataIndex: columnKey,
                    key: columnKey,
                });
            }
        });
        const dataSourceQuery = questionIds.map((questionId) => {
            const dataRow = {
                questionId,
            };

            datesArray.forEach((date) => {
                const year = date.split('-')[0];
                const month = date.split('-')[1];
                if (answerMap[questionId]?.[date] !== undefined) {
                    const cellValue = answerMap[questionId]?.[date]?.answer || 0;
                    const maxValue = answerMap[questionId]?.[date]?.maximum || 1;
                    const countValue = answerMap[questionId]?.[date]?.count || 0;
                    const percentage = `จำนวนคน: ${countValue} และ จำนวนคะแนน ${cellValue}/${maxValue} (${parseFloat((cellValue / maxValue) * 100).toFixed(2)}%)`;
                    const cellKey = `${year}-${month}`;
                    dataRow[cellKey] = percentage;
                    dataRow['questionTitle'] = answerMap[questionId]?.[date]?.questionTitle;
                }
            });

            return dataRow;
        });
        const filterQuery = unusedQuestion(dataSourceQuery);
        setColumnsAnswer(columns);
        setAnswerDataSource(filterQuery);
    };


    const unusedQuestion = (questions) => {
        const filterOuts = ['เลือกได้มากกว่า 1 ข้อ', 'เพศ', 'ภูมิลำเนา', 'ช่วงอายุ', 'อุปกรณ์ที่ใช้', 'ผู้ใช้บริการโทรเวชกรรม (Telemedicine)'];
        return questions.filter((question) => {
            for (const filterOut of filterOuts) {
                if (question?.questionTitle?.includes(filterOut)) {
                    return false;
                }
            }
            return true;
        });
    };

    const countSubmission = () => {
        let count = {};
        submissions.forEach((submission) => {
            const surveyId = submission.survey.id;
            if (!count[surveyId]) {
                count[surveyId] = 1;
            } else {
                count[surveyId]++;
            }
        });
        setSummary(count);
    };

    const querySubmission = (key) => {
        setIsLoading(true);
        let filterColumns = [];
        let filterDataSource = [];
        const parseKey = parseInt(key);
        const survey = surveys[parseKey];
        const filterSubmissions = submissions.filter((submission) => submission.surveyId === survey.id);
        if (filterSubmissions.length > 0) {
            filterColumns = filterSubmissions[0].survey.questions.map((question) => ({
                title: question.text,
                key: 'question',
                align: 'center',
                id: question.id,
                render: (record) => {
                    const filterRecord = record.filter((rec) => rec.questionId === question.id);
                    return filterRecord.length > 0 ? filterRecord[0].answer : '';
                },
            }));
            let filterDateSubmissions = filterSubmissions
            if (dateRange[0] !== null && dateRange[1] !== null) {
                filterDateSubmissions = filterSubmissions.filter((filterSubmission) => {
                    const startDate = moment(dateRange[0]);
                    const endDate = moment(dateRange[1]);
                    const currentDate = moment(filterSubmission.createdAt);
                    return currentDate.isBetween(startDate, endDate);
                });
            }
            filterDataSource = filterDateSubmissions.map((filterDateSubmission) => {
                return filterDateSubmission.answers.map((answer) => {
                    const selectedIds = answer.selectedOption.map((selected) => selected.id);
                    const answers = answer.question.options.filter((option) => selectedIds.includes(option.id)).map((option) => option.label);
                    return {
                        questionTitle: answer.question.text,
                        questionId: answer.questionId,
                        answer: answers.join(', '),
                        createdAt: filterDateSubmission.createdAt,
                    };
                });
            });
        }
        setColumns(filterColumns);
        if (dateRange[0] !== null && dateRange[1] !== null) {
            setFilteredDataSource(filterDataSource);
        } else {
            setDataSource(filterDataSource);
        }
        setTab(key);
        setIsLoading(false);
    };

    const disabledDate = (current) => {
        return current && current > dayjs().endOf('month')
    }

    return (
        <div className="h-100">
            {isLoading ? (
                <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                    <Spin className="text-center" />
                </div>
            ) : (
                <Row>
                    {surveys.map((survey, index) => (
                        <DataCard key={index} className="ml-3 mr-3 mt-2 mb-2 pt-1 pb-1" bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <View>
                                <Title className="pb-1">{survey.title}</Title>
                                <View className="pt-2" style={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                                    <Description style={{ margin: 0 }}>{summary[survey.id] || 0}</Description>
                                    <Description style={{ fontSize: 14 }}>ฟอร์ม</Description>
                                </View>
                            </View>
                        </DataCard>
                    ))}
                    <Tabs style={{ width: '100%' }} defaultActiveKey="0" onChange={(key) => {
                        querySubmission(key)
                        setDateRange([null, null])
                    }}>
                        {surveys.map((survey, surveyIndex) => (
                            <Tabs.TabPane tab={survey.title} key={surveyIndex}>
                                <Button onClick={() => setViewMode(viewMode === 'table' ? 'chart' : 'table')}>
                                    {viewMode === 'table' ? 'เปลี่ยนเป็นมุมมองกราฟ' : 'เปลี่ยนเป็นมุมมองตาราง'}
                                </Button>
                                <RangePicker
                                    format="YYYY-MM"
                                    picker="month"
                                    disabledDate={disabledDate}
                                    onChange={(dates) => {
                                        if (dates !== null) {
                                            const startDate = moment(dates[0]).startOf('month')
                                            const endDate = moment(dates[1]).endOf('month')
                                            setDateRange([startDate, endDate])
                                        } else {
                                            setDateRange([null, null])
                                        }

                                    }}
                                    style={{ marginBottom: '20px' }}
                                />
                                {viewMode === 'table' ? (
                                    <>
                                        <Table columns={columns} dataSource={filteredDataSource.length > 0 ? filteredDataSource : dataSource} scroll={{ x: 'max-content' }} />
                                        <Table columns={columnsAnswer} dataSource={answerDataSource} scroll={{ x: 'max-content' }} />
                                    </>
                                ) : (
                                    <SurveyBarChart dataSource={answerDataSource} />
                                )}
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                </Row>
            )}
        </div>
    );
};

export default ReportSubmission;

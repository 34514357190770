import moment from 'moment'
import React from 'react'

const renderTimeText = (date) => {
  let txtTime = ""
  let time = moment(date).format("HH:mm")
  const dateDiff = moment().startOf('day').diff(moment(date).startOf('day'), 'day')
  switch (dateDiff) {
    case 0:
      txtTime = "วันนี้ " + time
      break;
    case 1:
      txtTime = "เมื่อวาน " + time
      break;
    case dateDiff < 0:
      txtTime = "วันนี้ " + time
    default:
      txtTime = `${dateDiff} วันที่ผ่านมา ` + time
  }
  return txtTime
}

function RenderDateTime({ date }) {
  return (
    <p style={{ fontSize: 12 }}>{renderTimeText(date)}</p>
  )
}

export default React.memo(RenderDateTime);

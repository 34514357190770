import { Input } from 'antd'
import React from 'react'
import './style.css'

const TimeInput = (props) => {
    const formatTime = (e) => {
        const val = e.target.value
        const raw = val.replace(/\D+/g, "")
        e.target.value = `${ (raw.length > 4) ? raw.slice(-6, -4) + ":" : "" }${(raw.length > 2) ? raw.slice(-4, -2) + ":" : "" }${ raw.slice(-2) }`
        props.onInput(e)
    }
    return <Input className="time-input" type="text" onInput={formatTime} placeholder="00:00" onFocus={props.onFocus} onBlur={props.onBlur} value={props.value}/>

}
export default TimeInput
import React from 'react'

export const SMBGPie = {
  labels: ['น้ำตาลปกติ', 'น้ำตาลต่ำ', 'น้ำตาลสูง'],
  datasets: [
    {
      label: 'ค่าน้ำตาล',
      backgroundColor: ['green', 'red', 'blue'],
      hoverBackgroundColor: ['green', 'red', 'blue'],
      data: [],
    },
  ],
}

export const HBPMPie = {
  labels: ['ความดันปกติ', 'ความดันต่ำ', 'ความดันสูง'],
  datasets: [
    {
      label: 'ความดัน',
      backgroundColor: ['green', 'blue', 'red'],
      hoverBackgroundColor: ['green', 'blue', 'red'],
      data: [0],
    },
  ],
}

export const WeightPie = {
  labels: ['อยู่ในเกณฑ์', 'ต่ำกว่าเกณฑ์', 'สูงกว่าเกณฑ์'],
  datasets: [
    {
      label: 'ค่าดัชนีมวลกาย',
      backgroundColor: ['green', 'red', 'blue'],
      hoverBackgroundColor: ['green', 'red', 'blue'],
      data: [],
    },
  ],
}

export const SMBGLine = {
  options: {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: [],
    },
    annotations: {
      yaxis: [
        {
          y: 139,
          y2: 600,
          borderColor: '#000',
          fillColor: 'blue',
          opacity: 0.2,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '10px',
              color: '#333',
              background: '#FEB019',
            },
          },
        },
        {
          y: 60,
          y2: 139,
          borderColor: '#000',
          fillColor: '#94e194',
          opacity: 0.2,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '10px',
              color: '#333',
              background: '#FEB019',
            },
          },
        },
        {
          y: 60,
          y2: 0,
          borderColor: '#000',
          fillColor: 'red',
          opacity: 0.2,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '10px',
              color: '#333',
              background: '#FEB019',
            },
          },
        },
      ],
    },
  },
  series: [
    {
      name: 'Glucose Value',
      data: [],
    },
  ],
}

export const HBPMLine = {
  options: {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: [],
    },
    annotations: {
      yaxis: [
        {
          y: 139,
          y2: 600,
          borderColor: '#000',
          fillColor: 'red',
          opacity: 0.2,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '10px',
              color: '#333',
              background: '#FEB019',
            },
          },
        },
        {
          y: 60,
          y2: 139,
          borderColor: '#000',
          fillColor: '#94e194',
          opacity: 0.2,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '10px',
              color: '#333',
              background: '#FEB019',
            },
          },
        },
        {
          y: 0,
          y2: 0,
          borderColor: '#000',
          fillColor: 'blue',
          opacity: 0.2,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '10px',
              color: '#333',
              background: '#FEB019',
            },
          },
        },
      ],
    },
  },
  series: [
    {
      name: 'Glucose Value',
      data: [],
    },
  ],
}

export const WeightLine = {
  options: {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: [],
    },
    annotations: {
      yaxis: [
        {
          y: 139,
          y2: 600,
          borderColor: '#000',
          fillColor: 'blue',
          opacity: 0.2,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '10px',
              color: '#333',
              background: '#FEB019',
            },
          },
        },
        {
          y: 60,
          y2: 139,
          borderColor: '#000',
          fillColor: '#94e194',
          opacity: 0.2,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '10px',
              color: '#333',
              background: '#FEB019',
            },
          },
        },
        {
          y: 60,
          y2: 0,
          borderColor: '#000',
          fillColor: 'red',
          opacity: 0.2,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '10px',
              color: '#333',
              background: '#FEB019',
            },
          },
        },
      ],
    },
  },
  series: [
    {
      name: 'Weight Value',
      data: [],
    },
  ],
}

export const LabHeaders = [
  {
    title: 'Lab/Date',
    key: 'date',
  },
  { title: 'FBS', key: '1905' },
  { title: 'HbA1c', key: '1007' },
  { title: 'LDL', key: '1014' },
  { title: 'Cr', key: '1010' },
  { title: 'eGFR', key: '1987' },
  { title: 'Microalbuminuria', key: '0001' },
  { title: 'AST', key: '1026' },
  { title: 'ALT', key: '1027' },
  { title: 'ALP', key: '1028' },
  { title: 'FT4', key: '1957' },
  { title: 'TSH', key: '1960' },
  { title: 'Ca', key: '0002' },
  { title: 'PO4', key: '0003' },
  { title: 'Alb', key: '1030' },
]

export const GlucoseValue = {
  min: 0,
  max: 0,
  average: 0,
}

export const GlucoseTrend = {
  low: 0,
  high: 0,
  mid: 0,
  pie: SMBGPie,
}

export const GlucoseGraph = {
  defaultLow: 0,
  defaultHigh: 0,
  lines: SMBGLine,
}

export const WeightValue = {
  min: 0,
  max: 0,
  average: 0,
}

export const WeightTrend = {
  low: 0,
  high: 0,
  mid: 0,
  pie: WeightPie,
}
export const WeightGraph = {
  defaultLow: 0,
  defaultHigh: 0,
  lines: WeightLine,
}

export const PressureValue = {
  systolic: {
    min: 0,
    max: 0,
    average: 0,
  },
  diastolic: {
    min: 0,
    max: 0,
    average: 0,
  },
}

export const PressureTrend = {
  low: 0,
  high: 0,
  mid: 0,
  pie: HBPMPie,
}

export const PressureGraph = {
  defaultLowAbove: 0,
  defaultHighAbove: 0,
  defaultLowBelow: 0,
  defaultHighBelow: 0,
  lines: HBPMLine,
}

export const DRUGTIME = {
  beforeMeal: 'ก่อนอาหาร',
  afterMeal: 'หลังอาหาร',
  wake: 'ตื่นนอน',
}

export const FilterOptions = [
  { label: 'ก่อนอาหาร', value: 'beforeMeal' },
  { label: 'หลังอาหาร', value: 'afterMeal' },
  { label: 'อื่นๆ', value: 'all' },
]

import React, { useEffect } from 'react'
import { Spin, Card } from 'antd'
import styled from 'styled-components'
import { useIntercom } from 'react-use-intercom'
import { hiddenChatPath } from './constant'
import jwtDecode from 'jwt-decode'

export const CardMiddle = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`

const Intercom = ({ path, auth }) => {
  const { boot, shutdown, hide, show, update } = useIntercom()
  useEffect(() => {
    if (auth) {
      boot({ name: auth.email, customAttributes: { user_id: `Vajira_${auth.id}`, email: auth.email || '' } })
    }
    if (hiddenChatPath.includes(path)) {
      shutdown()
    }
  }, [auth, path])

  return null
}

export default Intercom

import React, { useState, useEffect } from 'react'
import { Row, Col, Input, InputNumber } from 'antd'
import { DatePicker } from '../../Neo'
import moment from 'moment'
import './style.css'
import styled from 'styled-components'
const Text = styled('p')`
  font-weight: bold;
`
const TimeTable = ({ setFormValue, formValues }) => {
  const [tableValue, setTableValue] = useState({
    time: { erawanReceiveCase: '', vajiraReceiveCase: '', ordered: '', leaveBase: '', arrivedScene: '', leaveScene: '', arrivedHospital: '', arrivedBase: '' },
    totalTime: {
      activateTime: 0,
      onSceneTime: 0,
      responseTime: 0,
      leaveSceneToHospitalTime: 0,
      hospitalToBaseTime: 0,
    },
    kilometers: {
      beforeLeaveBase: '',
      beforeLeaveScene: '',
      arrivedHospital: '',
      arrivedBase: '',
    },
    distance: {
      fromBaseToScene: '',
      fromSceneToHospital: '',
      fromHospitalToBase: '',
    },
    atReason: '',
    rtReason: '',
    ostReason: '',
  })

  useEffect(() => {
    handleTotalTime()
  }, [tableValue.time])

  useEffect(() => {
    handleDistance()
  }, [tableValue.kilometers])

  useEffect(() => {
    if (formValues.travelLog && formValues.travelLog.time) {
      handleDefaultValue()
    }
  }, [formValues])

  const handleInputText = (majorField, field, value) => {
    const data = field ? { [majorField]: { [field]: value } } : { [majorField]: value }
    setFormValue({ travelLog: { ...tableValue, ...data } })
  }

  const handleTotalTime = () => {
    const data = tableValue['time']
    const totalTime = {
      activateTime:
        data.leaveBase && data.vajiraReceiveCase
          ? moment(data.leaveBase)
              .set('second', 0)
              .diff(moment(data.vajiraReceiveCase).set('second', 0), 'minutes')
          : 0,
      onSceneTime:
        data.leaveScene && data.arrivedScene
          ? moment(data.leaveScene)
              .set('second', 0)
              .diff(moment(data.arrivedScene).set('second', 0), 'minutes')
          : 0,
      responseTime:
        data.arrivedScene && data.vajiraReceiveCase
          ? moment(data.arrivedScene)
              .set('second', 0)
              .diff(moment(data.vajiraReceiveCase).set('second', 0), 'minutes')
          : 0,
      leaveSceneToHospitalTime:
        data.arrivedHospital && data.leaveScene
          ? moment(data.arrivedHospital)
              .set('second', 0)
              .diff(moment(data.leaveScene).set('second', 0), 'minutes')
          : 0,
      hospitalToBaseTime:
        data.arrivedBase && data.arrivedHospital
          ? moment(data.arrivedBase)
              .set('second', 0)
              .diff(moment(data.arrivedHospital).set('second', 0), 'minutes')
          : 0,
    }
    setTableValue((prevState) => ({ ...prevState, totalTime }))
    setFormValue({ travelLog: { ...tableValue, totalTime } })
  }

  const handleDistance = () => {
    const data = tableValue['kilometers']
    const distance = {
      fromBaseToScene: data.beforeLeaveBase && data.beforeLeaveScene ? data.beforeLeaveScene - data.beforeLeaveBase : '',
      fromSceneToHospital: data.arrivedHospital && data.beforeLeaveScene ? data.arrivedHospital - data.beforeLeaveScene : '',
      fromHospitalToBase: data.arrivedBase && data.arrivedHospital ? data.arrivedBase - data.arrivedHospital : '',
    }
    setTableValue((prevState) => ({ ...prevState, distance }))
    setFormValue({ travelLog: { ...tableValue, distance } })
  }

  const handleDefaultValue = () => {
    const { time, totalTime, kilometers, distance, atReason, rtReason, ostReason } = formValues.travelLog
    const defaultValue = {
      time: {
        erawanReceiveCase: time.erawanReceiveCase,
        vajiraReceiveCase: time.vajiraReceiveCase,
        ordered: time.ordered,
        leaveBase: time.leaveBase,
        arrivedScene: time.arrivedScene,
        leaveScene: time.leaveScene,
        arrivedHospital: time.arrivedHospital,
        arrivedBase: time.arrivedBase,
      },
      totalTime: {
        activateTime: moment(time.leaveBase)
          .set('second', 0)
          .diff(moment(time.vajiraReceiveCase).set('second', 0), 'minutes'),
        onSceneTime: moment(time.leaveScene)
          .set('second', 0)
          .diff(moment(time.arrivedScene).set('second', 0), 'minutes'),
        responseTime: moment(time.arrivedScene)
          .set('second', 0)
          .diff(moment(time.vajiraReceiveCase).set('second', 0), 'minutes'),
        leaveSceneToHospitalTime: moment(time.arrivedHospital)
          .set('second', 0)
          .diff(moment(time.leaveScene).set('second', 0), 'minutes'),
        hospitalToBaseTime: moment(time.arrivedBase)
          .set('second', 0)
          .diff(moment(time.arrivedHospital).set('second', 0), 'minutes'),
      },
      kilometers: {
        beforeLeaveBase: kilometers.beforeLeaveBase,
        beforeLeaveScene: kilometers.beforeLeaveScene,
        arrivedHospital: kilometers.arrivedHospital,
        arrivedBase: kilometers.arrivedBase,
      },
      distance: {
        fromBaseToScene: distance.fromBaseToScene,
        fromSceneToHospital: distance.fromSceneToHospital,
        fromHospitalToBase: distance.fromHospitalToBase,
      },
      atReason,
      rtReason,
      ostReason,
    }
    setTableValue(defaultValue)
    setFormValue({ travelLog: defaultValue })
  }
  return (
    <>
      <div className="my-3 timelog-table">
        <table className="mb-3 w-100">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">เอราวัณรับแจ้ง</th>
              <th scope="col">วชิรรับแจ้ง</th>
              <th scope="col">สั่งการ</th>
              <th scope="col">ออกจากฐาน</th>
              <th scope="col">ถึงที่เกิดเหตุ</th>
              <th scope="col">ออกจากที่เกิดเหตุ</th>
              <th scope="col">ถึง ร.พ.</th>
              <th scope="col">ถึงฐาน</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">เวลา (min)</th>
              <td>
                {tableValue['time']['erawanReceiveCase'] != '' && (
                  <DatePicker
                    mode="datetime"
                    calendar="BE"
                    value={tableValue['time']['erawanReceiveCase']}
                    onChange={(value, dateString) => {
                      setTableValue((prevState) => ({ ...prevState, ['time']: { ...prevState.time, ['erawanReceiveCase']: moment(value).toISOString() } }))
                      handleInputText('time', 'erawanReceiveCase', value ? moment(value).toISOString() : '')
                    }}
                    abbr
                  />
                )}
              </td>
              <td>
                {tableValue['time']['vajiraReceiveCase'] != '' && (
                  <DatePicker
                    mode="datetime"
                    calendar="BE"
                    value={tableValue['time']['vajiraReceiveCase']}
                    onChange={(value, dateString) => {
                      setTableValue((prevState) => ({ ...prevState, ['time']: { ...prevState.time, ['vajiraReceiveCase']: moment(value).toISOString() } }))
                      handleInputText('time', 'vajiraReceiveCase', value ? moment(value).toISOString() : '')
                    }}
                    abbr
                    // className="mx-1"
                    // size="small"
                  />
                )}
              </td>
              <td>
                {tableValue['time']['ordered'] != '' && (
                  <DatePicker
                    mode="datetime"
                    calendar="BE"
                    value={tableValue['time']['ordered']}
                    onChange={(value, dateString) => {
                      setTableValue((prevState) => ({ ...prevState, ['time']: { ...prevState.time, ['ordered']: moment(value).toISOString() } }))
                      handleInputText('time', 'ordered', value ? moment(value).toISOString() : '')
                    }}
                    abbr
                  />
                )}
              </td>
              <td>
                {tableValue['time']['leaveBase'] != '' && (
                  <DatePicker
                    mode="datetime"
                    calendar="BE"
                    value={tableValue['time']['leaveBase']}
                    onChange={(value, dateString) => {
                      setTableValue((prevState) => ({ ...prevState, ['time']: { ...prevState.time, ['leaveBase']: moment(value).toISOString() } }))
                      handleInputText('time', 'leaveBase', value ? moment(value).toISOString() : '')
                    }}
                    abbr
                  />
                )}
              </td>
              <td>
                {tableValue['time']['arrivedScene'] != '' && (
                  <DatePicker
                    mode="datetime"
                    calendar="BE"
                    value={tableValue['time']['arrivedScene']}
                    onChange={(value, dateString) => {
                      setTableValue((prevState) => ({ ...prevState, ['time']: { ...prevState.time, ['arrivedScene']: moment(value).toISOString() } }))
                      handleInputText('time', 'arrivedScene', value ? moment(value).toISOString() : '')
                    }}
                    abbr
                    // className="mx-1"
                    // size="small"
                  />
                )}
              </td>
              <td>
                {tableValue['time']['leaveScene'] != '' && (
                  <DatePicker
                    mode="datetime"
                    calendar="BE"
                    value={tableValue['time']['leaveScene']}
                    onChange={(value, dateString) => {
                      setTableValue((prevState) => ({ ...prevState, ['time']: { ...prevState.time, ['leaveScene']: moment(value).toISOString() } }))
                      handleInputText('time', 'leaveScene', value ? moment(value).toISOString() : '')
                    }}
                    abbr
                  />
                )}
              </td>
              <td>
                {tableValue['time']['arrivedHospital'] != '' && (
                  <DatePicker
                    mode="datetime"
                    calendar="BE"
                    value={tableValue['time']['arrivedHospital']}
                    onChange={(value, dateString) => {
                      setTableValue((prevState) => ({ ...prevState, ['time']: { ...prevState.time, ['arrivedHospital']: moment(value).toISOString() } }))
                      handleInputText('time', 'arrivedHospital', value ? moment(value).toISOString() : '')
                    }}
                    abbr
                  />
                )}
              </td>
              <td>
                {tableValue['time']['arrivedBase'] != '' && (
                  <DatePicker
                    mode="datetime"
                    calendar="BE"
                    value={tableValue['time']['arrivedBase']}
                    onChange={(value, dateString) => {
                      setTableValue((prevState) => ({ ...prevState, ['time']: { ...prevState.time, ['arrivedBase']: moment(value).toISOString() } }))
                      handleInputText('time', 'arrivedBase', value ? moment(value).toISOString() : '')
                    }}
                    abbr
                  />
                )}
              </td>
            </tr>
            <tr>
              <th rowSpan="3" scope="row">
                รวมเวลา
              </th>
              <td colSpan="1" style={{ backgroundColor: '#ccc' }}></td>
              <td colSpan="3" onClick={() => console.log(tableValue)}>
                {`Activate time =`}
                <Input
                  onChange={(value) => {
                    handleInputText('totalTime', 'activateTime', value)
                  }}
                  min={0}
                  className="mx-1"
                  size="small"
                  style={{ maxWidth: '50px' }}
                  value={tableValue['totalTime']['activateTime']}
                  disabled={true}
                />
                {`min`}
              </td>
              <td colSpan="2">
                {`On scence time =`}
                <Input
                  onChange={(value) => {
                    handleInputText('totalTime', 'onSceneTime', value)
                  }}
                  min={0}
                  className="mx-1"
                  size="small"
                  style={{ maxWidth: '50px' }}
                  value={tableValue['totalTime']['onSceneTime']}
                  disabled={true}
                />
                {`min`}
              </td>
              <td colSpan="2" style={{ backgroundColor: '#ccc' }}></td>
            </tr>
            <tr>
              <td rowSpan="2" colSpan="5">
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '55px' }}>
                  {`Response time =`}
                  <Input
                    onChange={(value) => {
                      handleInputText('totalTime', 'responseTime', value)
                    }}
                    min={0}
                    className="mx-1"
                    size="small"
                    style={{ maxWidth: '50px' }}
                    value={tableValue['totalTime']['responseTime']}
                    disabled={true}
                  />
                  {`min`}
                </div>
              </td>
              <td colSpan="2">
                <Input
                  onChange={(value) => {
                    handleInputText('totalTime', 'leaveSceneToHospitalTime', value)
                  }}
                  min={0}
                  className="mx-1"
                  size="small"
                  style={{ maxWidth: '80px' }}
                  value={tableValue['totalTime']['leaveSceneToHospitalTime']}
                  disabled={true}
                />
                {`min`}
              </td>
              <td colSpan="1" style={{ backgroundColor: '#ccc' }}></td>
            </tr>
            <tr>
              <td colSpan="1" style={{ backgroundColor: '#ccc' }}></td>
              <td colSpan="2">
                <Input
                  onChange={(value) => {
                    handleInputText('totalTime', 'hospitalToBaseTime', value)
                  }}
                  min={0}
                  className="mx-1"
                  size="small"
                  style={{ maxWidth: '80px' }}
                  value={tableValue['totalTime']['hospitalToBaseTime']}
                  disabled={true}
                />
                {`min`}
              </td>
            </tr>
            <tr>
              <th scope="row">เลข กม.</th>
              <td colSpan="4">
                <InputNumber
                  onChange={(value) => {
                    handleInputText('kilometers', 'beforeLeaveBase', value)
                    setTableValue((prevState) => ({ ...prevState, ['kilometers']: { ...prevState.kilometers, ['beforeLeaveBase']: value } }))
                  }}
                  className="mx-1"
                  size="small"
                  style={{ maxWidth: '100px' }}
                  value={tableValue['kilometers']['beforeLeaveBase']}
                />
              </td>
              <td colSpan="2">
                <InputNumber
                  onChange={(value) => {
                    handleInputText('kilometers', 'beforeLeaveScene', value)
                    setTableValue((prevState) => ({ ...prevState, ['kilometers']: { ...prevState.kilometers, ['beforeLeaveScene']: value } }))
                  }}
                  className="mx-1"
                  size="small"
                  style={{ maxWidth: '100px' }}
                  value={tableValue['kilometers']['beforeLeaveScene']}
                />
              </td>
              <td colSpan="1">
                <InputNumber
                  onChange={(value) => {
                    handleInputText('kilometers', 'arrivedHospital', value)
                    setTableValue((prevState) => ({ ...prevState, ['kilometers']: { ...prevState.kilometers, ['arrivedHospital']: value } }))
                  }}
                  className="mx-1"
                  size="small"
                  style={{ maxWidth: '100px' }}
                  value={tableValue['kilometers']['arrivedHospital']}
                />
              </td>
              <td colSpan="1">
                <InputNumber
                  onChange={(value) => {
                    handleInputText('kilometers', 'arrivedBase', value)
                    setTableValue((prevState) => ({ ...prevState, ['kilometers']: { ...prevState.kilometers, ['arrivedBase']: value } }))
                  }}
                  className="mx-1"
                  size="small"
                  style={{ maxWidth: '100px' }}
                  value={tableValue['kilometers']['arrivedBase']}
                />
              </td>
            </tr>
            <tr>
              <th rowSpan="2" scope="row">
                ระยะทาง.
              </th>
              <td rowSpan="2" colSpan="5">
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '55px' }}>
                  {`รวมระยะทางไป`}
                  <Input
                    onChange={(value) => {
                      handleInputText('distance', 'fromBaseToScene', value)
                    }}
                    min={0}
                    className="mx-1"
                    size="small"
                    style={{ maxWidth: '65px' }}
                    value={tableValue['distance']['fromBaseToScene']}
                    disabled={true}
                  />
                  {`km`}
                </div>
              </td>
              <td colSpan="2">
                {`ระยะทางไป ร.พ.`}
                <Input
                  onChange={(value) => {
                    handleInputText('distance', 'fromSceneToHospital', value)
                  }}
                  min={0}
                  className="mx-1"
                  size="small"
                  style={{ maxWidth: '65px' }}
                  value={tableValue['distance']['fromSceneToHospital']}
                  disabled={true}
                />
                {`km`}
              </td>
              <td colSpan="1" style={{ backgroundColor: '#ccc' }}></td>
            </tr>
            <tr>
              <td colSpan="1" style={{ backgroundColor: '#ccc' }}></td>
              <td colSpan="2">
                {`ระยะทางกลับ`}
                <Input
                  onChange={(value) => {
                    handleInputText('distance', 'fromHospitalToBase', value)
                  }}
                  min={0}
                  className="mx-1"
                  size="small"
                  style={{ maxWidth: '65px' }}
                  value={tableValue['distance']['fromHospitalToBase']}
                  disabled={true}
                />
                {`km`}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Row justify="space-between">
        <Col className="input-box d-flex flex-column justify-content-end">
          <Text>{`เหตุผลที่ AT > 2นาที`}</Text>
          <Input
            value={tableValue['atReason']}
            onChange={(event) => {
              setTableValue((prevState) => ({ ...prevState, atReason: event.target.value }))
              handleInputText('atReason', false, event.target.value)
            }}
          />
        </Col>
        <Col className="input-box d-flex flex-column justify-content-end">
          <Text>{`เหตุผลที่ RT > 8 นาที ในระยะ5 km`}</Text>
          <Input
            value={tableValue['rtReason']}
            onChange={(event) => {
              setTableValue((prevState) => ({ ...prevState, rtReason: event.target.value }))
              handleInputText('rtReason', false, event.target.value)
            }}
          />
        </Col>
        <Col className="input-box d-flex flex-column justify-content-end">
          <Text>{`เหตุผลที่ OST > 10 นาที ในเคส Major trauma`}</Text>
          <Input
            value={tableValue['ostReason']}
            onChange={(event) => {
              setTableValue((prevState) => ({ ...prevState, ostReason: event.target.value }))
              handleInputText('ostReason', false, event.target.value)
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default TimeTable

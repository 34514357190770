
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Layout from '../Home'
import SymptomTable from '../../components/SymptomTable'
import { TABLE_TYPES } from '../../constant'

@inject('Symptoms')
@observer
class index extends Component {
  state= {
    data: []
  }

  async componentDidMount() {
    const { Symptoms } = this.props
    await Symptoms.fetchSymptoms()
    this.setState({ data: Symptoms.getSymptoms })
  }

  handleDelete = async id => {
    //  const { DoctorTypes } = this.props
    //  await DoctorTypes.deleteDoctorTypes(id)
    //  this.setState({ data: DoctorTypes.getDoctorTypes })
    console.log(id)
  }

  render() {
    const { data } = this.state

    return (
      <Layout
        {...this.props}
        render={(props) => (
          <SymptomTable
            {...props}
            data={data}
            handleDelete={this.handleDelete}
            tableType={TABLE_TYPES.doctorTypes}
            style={{ width: '100%' }}
          />
        )}
      />
    )
  }
}

export default index

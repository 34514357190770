import React from 'react'
import { Card, Row, Col, Select } from 'antd'

const { Option } = Select

function DrugCard(props) {
  const { drugs } = props
  return (
    <Card>
      <Row justify="center" gutter={24}>
        <Col span={12} className="text-center">
          <Select style={{ width: '80%' }} placeholder="เลือกยา">
            {drugs && drugs.map((drug) => <Option value={drug}>{drug}</Option>)}
          </Select>
        </Col>
        <Col span={12} className="text-center">
          <Select style={{ width: '80%' }} placeholder="ช่วงเวลา" />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="mt-2">
          (ช่วงเวลา 1 พฤศจิกายน 2563 ถึง 30 พฤศจิกายน 2563)
        </Col>
        <Col span={24}>Metformin</Col>
        <Col span={8}>จำนวนครั้งที่ต้องทานทั้งหมด</Col>
        <Col span={16}>30 ครั้ง</Col>
        <Col span={8} style={{ color: 'lightgreen' }}>
          จำนวนครั้งที่ได้ทานจริง
        </Col>
        <Col span={16} style={{ color: 'lightgreen' }}>
          29 ครั้ง
        </Col>
        <Col span={8} style={{ color: 'red' }}>
          จำนวนครั้งที่ไม่ได้ทาน
        </Col>
        <Col span={16} style={{ color: 'red' }}>
          1 ครั้ง
        </Col>
      </Row>
    </Card>
  )
}

export default DrugCard

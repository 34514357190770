import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/Bookings'

class Booking {
  @observable info = {
    data: [],
    error: null,
  }

  @action fetchBooking = async () => {
    try {
      const { data } = await http.get(`${apiEndpoint}/?filter[include]=doctor&filter[include]=patient&filter[include]=prescription`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchBookingFilterByPrescription = async () => {
    try {
      const { data } = await http.get(`${apiEndpoint}/?filter[include]=prescription&filter[include]=patient&filter[include]=doctor`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchFollowUpBooking = async () => {
    try {
      const { data } = await http.get(`${apiEndpoint}/?filter[where][followUp]=true&filter[include]=doctor&filter[include]=patient&filter[include]=prescription`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action getFilterByDoctorId = async (id, status) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/filterByDoctorId`, {
        params: {
          doctorId: id,
          status,
        },
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action setStatus = async (id, status) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}`, {
        id,
        status,
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action updateBooking = async (id, patchData, isPrescription) => {
    try {
      const detail = isPrescription ? { prescriptionId: patchData } : { ...patchData }
      const { data } = await http.patch(`${apiEndpoint}/${id}`, detail)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action createBooking = async (bookingData) => {
    try {
      const { data } = await http.post(`${apiEndpoint}`, bookingData)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action editBooking = async (id, patchData) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${id}`, patchData)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action patchBooking = async (id, bookingTime, admitTime) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${id}`, {
        bookingTime: bookingTime < 0 ? bookingTime * -1 : bookingTime,
        admitTime,
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action addVoiceRecord = async (id, blob) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${id}`, {
        soundRecording: blob,
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @computed get getBooking() {
    return toJS(this.booking.data)
  }
}

export default new Booking()

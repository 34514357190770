import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceEver'

const apiEndpoint = '/bookingRooms'

class BookingRoom {
  @observable info = {
    data: [],
    error: null
  }

  @action createBookingRoom = async (bookingId, sid) => {
    try {
      await http.post(`${apiEndpoint}`, {
        bookingId,
        sid
      })
    } catch (error) {
      console.log(error)
    }
  }

  @action getBookingRooms = async (bookingId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}?filter[where][bookingId]=${bookingId}&filter[order]=id DESC`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchRecordListFromRoomId = async (sid) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/getRecordListsByRoomId`, {
        params: { roomId: sid }
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action compressRecordFileComposition = async (sid) => {
    try {
      const { data } = await http.post(`${apiEndpoint}/compressRecordFileComposition`, {
        roomId: sid
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }
  @action getCompositionFile = async (sid) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/getCompositionFile?roomId=${sid}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchRecordFileByRoomAndId = async (sid, recordingId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/getRecordingFileByRoomIdAndRecordId`, {
        params: { roomId: sid, recordingId }
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @computed get getBookingGallert() {
    return toJS(this.info.data)
  }
}

export default new BookingRoom()

import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import http from '../../services/httpServiceHealthCare'

const apiEndpoint = '/resetPins'

class ResetPin {
  @observable info = {
    data: [],
    error: null
  }
  @action createPin = async (patientId) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/createPin`,{
        params:{
          patientId
        }
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @computed get getPin() {
    return toJS(this.resetPin.data)
  }
}

export default new ResetPin()

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { Input } from 'antd'
import styled from 'styled-components'


const Form = styled.div`
  display: flex;
  padding: 10px;
`
export default class FormMessage extends Component {
  static propTypes = {
    onSend: PropTypes.func.isRequired,
  }

  render() {
    const { onSend } = this.props
    return (
      <Formik
        initialValues={{
          text: ''
        }}
        onSubmit={(values, { setSubmitting, setFieldValue }) => {
          onSend(values)
          setFieldValue('text', '')
          setSubmitting(false)
        }}
      >
        {(props) => {
        const { values, handleChange, handleSubmit,} = props //eslint-disable-line
          return (
            <Form>
              <Input
                value={values.text}
                placeholder="Message..."
                name="text"
                onChange={handleChange}
                onPressEnter={handleSubmit}
              />
            </Form>
          )
        }}
      </Formik>
    )
  }
}

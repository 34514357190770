import React from 'react'
import { Row, Col, Popconfirm } from 'antd'
import { StatusButton } from '../../styles'

export default function ButtonAction({ tab, state, setKey, setState, emergency, sendEmsData, updatePatient, updateStatusEms, updateEmsPositionAlert, updateEmsCarStatusGoOut, history, setTabActiveKey }) {
  return (
    <Row justify="center" gutter={12}>
      {tab != '3' && tab != '4' && (
        <Col>
          <StatusButton
            onClick={() => setState({ visibleModalCancel: true, key: emergency.key, id: emergency.id, ambulanceNo: emergency.ambulanceNo })}
            type="danger"
            style={{
              color: '#fff',
            }}
          >
            ยกเลิก
          </StatusButton>
        </Col>
      )}
      <Col>
        {emergency.status === 'patientWaiting' ? (
          <Popconfirm
            title="ต้องการยืนยันการรับ case หรือไม่"
            onConfirm={() => {
              updateStatusEms(emergency.key, 'actionWaiting', emergency.id)
              updateEmsPositionAlert(emergency.key, 'actionWaiting')
            }}
            okText="ใช่"
            cancelText="ไม่"
          >
            <StatusButton
              style={{
                color: '#fff',
                backgroundColor: '#45C1B8',
              }}
            >
              ยืนยัน
            </StatusButton>
          </Popconfirm>
        ) : emergency.status === 'actionWaiting' ? (
          <Popconfirm
            title="ต้องการยืนยันการสั่งการหรือไม่"
            onConfirm={() => {
              updateStatusEms(emergency.key, 'exitWaiting')
              updateEmsPositionAlert(emergency.key, 'exitWaiting')
            }}
            okText="ใช่"
            cancelText="ไม่"
          >
            <StatusButton
              style={{
                color: '#fff',
                backgroundColor: '#45C1B8',
              }}
            >
              สั่งการแล้ว
            </StatusButton>
          </Popconfirm>
        ) : emergency.status === 'exitWaiting' ? (
          <Popconfirm
            title="ต้องการยืนยันออกจากฐานหรือไม่"
            onConfirm={() => {
              if (!state.ambulanceSelected) {
                setState({ errorBorder: 'red' })
              } else {
                updateStatusEms(emergency.key, 'moving', emergency.id)
                updateEmsPositionAlert(emergency.key, 'moving', state.ambulanceSelected)
                updateEmsCarStatusGoOut(state.ambulanceSelected, 'รถพยาบาลไม่พร้อม', emergency.id, state.licensePlate, emergency.operationDateTime)
                setTabActiveKey('2')
                console.log('state ', state)
              }
            }}
            okText="ใช่"
            cancelText="ไม่"
          >
            <StatusButton
              style={{
                color: '#fff',
                backgroundColor: '#45C1B8',
              }}
            >
              ออกจากฐาน
            </StatusButton>
          </Popconfirm>
        ) : emergency.status === 'moving' ? (
          <Popconfirm
            title="ต้องการยืนยันการถึงที่เกิดเหตุแล้วหรือไม่"
            onConfirm={() => {
              updateStatusEms(emergency.key, 'arrived', emergency.id)
              updateEmsPositionAlert(emergency.key, 'arrived')
            }}
            okText="ใช่"
            cancelText="ไม่"
          >
            <StatusButton
              style={{
                color: '#fff',
                backgroundColor: '#45C1B8',
              }}
            >
              ถึงที่แล้ว
            </StatusButton>
          </Popconfirm>
        ) : emergency.status === 'arrived' ? (
          <Popconfirm
            title="ต้องการยืนยันการออกจากที่เกิดเหตุหรือไม่"
            onConfirm={() => {
              updateStatusEms(emergency.key, 'completedSending', emergency.id)
              updateEmsPositionAlert(emergency.key, 'completedSending')
            }}
            okText="ใช่"
            cancelText="ไม่"
          >
            <StatusButton
              style={{
                color: '#fff',
                backgroundColor: '#45C1B8',
              }}
            >
              ออกจากที่แล้ว
            </StatusButton>
          </Popconfirm>
        ) : emergency.status === 'completedSending' ? (
          <Popconfirm
            title="ต้องการยืนยันการถึงรพ.แล้วหรือไม่"
            onConfirm={() => {
              updateStatusEms(emergency.key, 'inHospital', emergency.id)
              updateEmsPositionAlert(emergency.key, 'inHospital')
            }}
            okText="ใช่"
            cancelText="ไม่"
          >
            <StatusButton
              style={{
                color: '#fff',
                backgroundColor: '#45C1B8',
              }}
            >
              ถึงรพ.แล้ว
            </StatusButton>
          </Popconfirm>
        ) : emergency.status === 'inHospital' ? (
          <Popconfirm
            title="ต้องการยืนยันการถึงฐานแล้วหรือไม่"
            onConfirm={() => {
              updateStatusEms(emergency.key, 'completed', emergency.id)
              updateEmsPositionAlert(emergency.key, 'completed')
              updateEmsCarStatusGoOut(state.ambulanceSelected, 'พร้อมใช้งาน')
              setTabActiveKey('3')
              localStorage.setItem('ambulanceNo', '')
            }}
            okText="ใช่"
            cancelText="ไม่"
          >
            <StatusButton
              style={{
                color: '#fff',
                backgroundColor: '#45C1B8',
              }}
            >
              ถึงฐานแล้ว
            </StatusButton>
          </Popconfirm>
        ) : emergency.status === 'completed' ? (
          <StatusButton
            disabled={emergency.isCreateForm}
            style={{
              color: '#ffffff',
              backgroundColor: emergency.isCreateForm ? 'gray' : '#45C1B8',
            }}
            onClick={() => {
              history.push({ pathname: `/ems-forms`, state: { key: emergency.key, cId: emergency.cId } })
            }}
          >
            ลงข้อมูล
          </StatusButton>
        ) : (
          ''
        )}
      </Col>
      {tab == '4' && (
        <Col>
          <StatusButton
            onClick={() => setState({ visibleModalCancel: true, customAddData: emergency, id: emergency.id })}
            type="danger"
            style={{
              color: '#fff',
            }}
          >
            ยกเลิก
          </StatusButton>
        </Col>
      )}
      {tab == '4' && (
        <Col>
          <StatusButton
            onClick={() => history.push({ pathname: `/ems-forms`, state: { key: emergency.id, type: 'customAdd' } })}
            type="primary"
            style={{
              color: '#fff',
            }}
          >
            แก้ไขฟอร์ม
          </StatusButton>
        </Col>
      )}
      {tab != '3' && tab != '4' && (
        <Col>
          <StatusButton
            onClick={() => setState({ visibleModalForword: true, key: emergency.key, id: emergency.id, ambulanceNo: emergency.ambulanceNo })}
            style={{
              color: '#fff',
              backgroundColor: '#1A9FEA',
            }}
          >
            ส่งต่อ
          </StatusButton>
        </Col>
      )}
    </Row>
  )
}

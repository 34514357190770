import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Select, Cascader } from 'antd'
import UploadImage from './UploadImage'
const ChestPain = ({ setFormValue, initialValues, formId }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (initialValues && initialValues.chestPain) {
      setValue(initialValues.chestPain)
      setFormValue('chestPain', initialValues.chestPain)
    }
  }, [initialValues])

  const handleValue = (value) => {
    setValue(value)
    setFormValue({ ['chestPain']: value })
  }

  return (
    <Row>
      <h2>Cardiac Arrhythmia & Chest pain/Discomfort</h2>
      <Col span={24}>
        <UploadImage label={'อัพโหลดรูปแผ่นแปะ ECG'} preview={value} path={`EmsImages/${formId}/ChestPain`} handleValue={(value) => handleValue(value)} />
      </Col>
    </Row>
  )
}

export default ChestPain

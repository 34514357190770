import React, { Component } from 'react'
import Layout from '../Home'
import { inject, observer } from 'mobx-react'
import EmsFormTable from '../../components/EmsFormTable'

@inject('EmsForm')
@observer
export default class EmsForm extends Component {
  constructor() {
    super()
    this.state = {
      dataSource: [],
      dataSourceCancel: [],
      dataSourcePass: [],
      dataSourceReject: [],
      isLoading: false,
    }
  }

  fetchEmsFormReject = async (startDate, endDate) => {
    this.setState({ isLoading: true })
    const { EmsForm } = this.props
    const response = await EmsForm.fetchEmsFormReject(startDate, endDate)
    this.setState({
      dataSourceReject: response,
      isLoading: false,
    })
  }

  fetchEmsFormCancel = async (startDate, endDate) => {
    this.setState({ isLoading: true })
    const { EmsForm } = this.props
    const response = await EmsForm.fetchEmsFormReport(startDate, endDate)
    this.setState({
      dataSourceCancel: response,
      isLoading: false,
    })
  }

  fetchEmsFormPass = async (startDate, endDate) => {
    this.setState({ isLoading: true })
    const { EmsForm } = this.props
    const response = await EmsForm.fetchEmsFormReport(startDate, endDate)
    this.setState({
      dataSourcePass: response,
      isLoading: false,
    })
  }

  fetchEmsForm = async (startDate, endDate) => {
    this.setState({ isLoading: true })
    const { EmsForm } = this.props
    const response = await EmsForm.fetchEmsFormReport(startDate, endDate)
    this.setState({
      dataSource: response,
      isLoading: false,
    })
  }
  render() {
    return (
      <Layout
        {...this.props}
        render={(props) => (
          <EmsFormTable state={this.state} history={this.props.history} fetchEmsForm={this.fetchEmsForm} fetchEmsFormCancel={this.fetchEmsFormCancel} fetchEmsFormPass={this.fetchEmsFormPass} fetchEmsFormReject={this.fetchEmsFormReject} />
        )}
      />
    )
  }
}

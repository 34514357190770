/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react'
import Layout from '../Home'
import { observer, inject } from 'mobx-react'
import UserReportTable from '../../components/UserReportTable'

@inject('UserReport')
@observer
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      reports: [],
      filteredReports: [],
    }
  }
  componentDidMount() {
    this.fetchUserReports()
  }

  fetchUserReports = async () => {
    this.setState({
      isLoading: true,
    })
    const { UserReport } = this.props
    const response = await UserReport.fetchUserReports()
    this.setState({
      reports: response,
      isLoading: false,
    })
  }

  onSearch = (cId) => {
    const filterByCid = this.state.reports.filter((item) => item.cId.includes(cId))
    this.setState({
      filteredReports: filterByCid,
    })
  }

  render() {
    return (
      <Layout
        {...this.props}
        render={(
          props //eslint-disable-line
        ) => (
          <div className="w-100">
            <UserReportTable reports={this.state.filteredReports.length ? this.state.filteredReports : this.state.reports} loading={this.state.isLoading} onSearch={this.onSearch} />
          </div>
        )}
      />
    )
  }
}

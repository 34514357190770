import React, { Component } from 'react'
import Layout from '../Home'
import EmergencyForm from '../../components/EmergencyForm'
import { inject, observer } from 'mobx-react'
import { message } from 'antd'

@inject('User')
@inject('UserInfo')
@inject('EmsForm')
@observer
export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = { formDetail: {} }
  }
  componentDidMount() {
    this.mounted()
  }

  mounted = () => {
    const { history } = this.props
    if (history.location.state && history.location.state.key && (!history.location.state.type || (history.location.state.type && history.location.state.type !== 'customAdd'))) {
      this.findEmsFormByUserId(history.location.state.key)
    } else if (history.location.state && history.location.state.key && history.location.state.type && history.location.state.type === 'customAdd') {
      this.findEmsFormById(history.location.state.key)
    } else {
      history.push('/emsform')
    }
  }

  fetchUserInfos = async () => {
    const { history } = this.props
    if (history.location.state && history.location.state.cId) {
      const { UserInfo } = this.props
      const userInfoById = await UserInfo.fecthUserInfoByCId(history.location.state.cId)
      return userInfoById
    }
    return {}
  }

  findEmsFormByUserId = async (userId) => {
    const { EmsForm } = this.props
    const emsForms = await EmsForm.findEmsFormByUserId(userId)
    const emsForm = emsForms.filter((emsForm) => emsForm.status !== 'noted' && emsForm.status !== 'cancelled')
    const latestEmsForm = emsForm.pop()
    console.log(latestEmsForm)
    this.setState({
      formDetail: latestEmsForm,
    })
  }

  findEmsFormById = async (id) => {
    const { EmsForm } = this.props
    const emsForm = await EmsForm.findEmsFormById(id)
    console.log('emsForm')
    console.log(emsForm)
    this.setState({
      formDetail: emsForm.length > 0 ? emsForm[0] : {},
    })
  }

  updateEmsForm = async (sectionKey, updateData) => {
    const { EmsForm, User } = this.props
    let detail = structuredClone(this.state.formDetail)
    delete detail.stateTimeStamp
    delete detail.id
    if (Object.keys(this.state.formDetail).length > 0) {
      const data = {
        ...detail.formDetail,
        ...updateData,
        logHistory: [
          ...(detail?.logHistory || []),
          {
            date: new Date().toISOString(),
            staffId: User.getInfo?.id,
            data: sectionKey,
          },
        ],
      }
      const response = await EmsForm.updateEmsForm(this.state.formDetail.id, data)
      if (response) {
        this.mounted()
        message.success('บันทึกข้อมูลสำเร็จ')
      } else {
        message.error('บันทึกข้อมูลล้มเหลว')
      }
    } else {
      message.error('ไม่พบข้อมูลแบบฟอร์ม')
    }
  }

  render() {
    return (
      <Layout
        {...this.props}
        render={(
          props, //eslint-disable-line
        ) => <EmergencyForm formDetail={this.state.formDetail} updateEmsForm={this.updateEmsForm} fetchUserInfos={this.fetchUserInfos} />}
      />
    )
  }
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Row, Col, Radio, Select, Card, Modal, message } from 'antd'
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { LeftOutlined, UserOutlined, KeyOutlined } from '@ant-design/icons'
import Button from '../Button'
import { TextInput } from './TextInput'
import AddDepartmentModal from '../AddDepartmentModal'
import httpEver from '../../services/httpServiceEver'
import { PRACTITIONER_ROLES } from '../../constant'
const { Option } = Select
const RadioGroup = Radio.Group
export const ErrMsg = styled.p`
  &&& {
    color: red;
  }
`
const LabelText = styled.h2`
  color: ${(props) => props.theme.primaryText};
  font-weight: bold;
`

const validationSchema = Yup.object().shape({
  fullname: Yup.string().required('กรุณากรอกชื่อและนามสกุล'),
  email: Yup.string()
    .email('อีเมลไม่ถูกต้อง')
    .required('กรุณากรอกอีเมล'),
  password: Yup.string()
    .min(6, 'กรุณากรอกรหัสผ่านอย่างน้อย 6 ตัวอักษร')
    .required('กรุณากรอกรหัสผ่าน'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'รหัสผ่านไม่ตรงกัน')
    .required('กรุณากรอกยืนยันรหัสผ่าน'),
  gender: Yup.string().required('กรุณาเลือกเพศ'),
  nationality: Yup.string().required('กรุณาเลือกสัญชาติ'),
  role: Yup.string().required('กรุณาเลือกหน้าที่'),
})

const RegisterForm = ({ onSubmit, doctorTypes, otherTypes, roles, hasSubmitted, refreshDepartment }) => {
  const [isShowDepartmentModal, setIsShowDepartmentModal] = useState(false)
  const [selectedRole, setSelectedRole] = useState(false)
  const [otherTypeForSelect, setOtherTypeForSelect] = useState([])
  useEffect(() => {
    if (selectedRole) {
      const filterByRole = otherTypes.filter((type) => selectedRole === type.role)
      setOtherTypeForSelect(filterByRole)
    }
  }, [selectedRole, otherTypes])

  const addDepartmentForm = async (data) => {
    const apiEndpoint = selectedRole.includes('doctor') ? '/DoctorTypes' : 'OtherTypes'
    if (['nurse', 'physiotherapist', 'speechCorrectionSpecialist', 'pharmacySchedule', 'nutritionist'].includes(selectedRole)) {
      data.role = selectedRole
    }
    try {
      const res = await httpEver.post(apiEndpoint, data)
      if (res && res.status === 200) {
        message.success(`เพิ่มแผนก ${res.data.name} เรียบร้อยแล้ว`)
        refreshDepartment()
      }
    } catch (error) {
      message.error('เพิ่มแผนกไม่สำเร็จ')
    }
    setIsShowDepartmentModal(false)
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        confirmPassword: '',
        gender: '',
        nationality: '',
        doctorTypeId: '',
        otherTypeId: '',
        role: '',
        fullname: '',
        no: '',
        educational: '',
        hospital: '',
        specialist: '',
        employeeId: '',
        tel: '',
        detail: {},
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (PRACTITIONER_ROLES.includes(values.role)) {
          const department = values.doctorTypeId ? doctorTypes.find((item) => values.doctorTypeId === item.id) : values.otherTypeId ? otherTypes.find((item) => values.otherTypeId === item.id) : null
          values.department = department ? department.name : ''
          values.detail.department = department ? department.name : ''
          values.detail.educational = values.educational
          values.detail.hospital = values.hospital
          values.detail.no = values.no
          values.detail.specialist = values.specialist
        }
        onSubmit(values)
        setSubmitting(false)
        resetForm()
      }}
      validationSchema={validationSchema}
    >
      {(props) => {
        const { values, handleChange, handleSubmit, errors, setFieldValue } = props
        const { email, password, confirmPassword, gender, nationality, doctorTypeId, role, employeeId, fullname, no, educational, hospital, specialist, tel, otherTypeId } = values
        return (
          <>
            <form className="p-3 w-100">
              <Card className="w-100">
                <LabelText className="h2">ลงทะเบียนเจ้าหน้าที่</LabelText>
                <Row gutter={[16, 16]} className="w-80 p-4 mt-1 pb-1">
                  <Col span="12">
                    <LabelText>
                      <span style={{ color: 'red' }}>*</span>ชื่อ - นามสกุล
                    </LabelText>
                    <TextInput placeholder="กรอกชื่อและนามสกุล" name="fullname" prefix={<UserOutlined />} value={fullname} onChange={handleChange} />
                    <ErrorMessage component={ErrMsg} name="fullname" />
                  </Col>
                  <Col span="12">
                    <LabelText>
                      <span style={{ color: 'red' }}>*</span>อีเมล
                    </LabelText>
                    <TextInput placeholder="กรอกอีเมล" name="email" prefix={<UserOutlined />} value={email} onChange={handleChange} required />
                    <ErrorMessage component={ErrMsg} name="email" />
                  </Col>

                  <Col span="12">
                    <LabelText>
                      <span style={{ color: 'red' }}>*</span>หน้าที่
                    </LabelText>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                      style={{ width: '100%' }}
                      name="role"
                      value={role}
                      onChange={(value) => {
                        setSelectedRole(value)
                        setFieldValue('role', value)
                      }}
                    >
                      {roles.map((role) => (
                        <Option key={role.value} value={role.value}>
                          {role.name}
                        </Option>
                      ))}
                    </Select>
                    <ErrorMessage component={ErrMsg} name="role" />
                  </Col>

                  {PRACTITIONER_ROLES.includes(role) && (
                    <>
                      <Col span="12">
                        <LabelText>เลขที่ใบประกอบวิชาชีพ</LabelText>
                        <TextInput type="text" name="no" placeholder="กรอกเลขที่ใบประกอบวิชาชีพ" value={no} required onChange={handleChange} />
                        <ErrorMessage component={ErrMsg} name="no" />
                      </Col>
                      <Col span="12">
                        <LabelText>วุฒิการศึกษา</LabelText>
                        <TextInput type="text" name="educational" placeholder="กรอกวุฒิการศึกษา" value={educational} required onChange={handleChange} />
                        <ErrorMessage component={ErrMsg} name="educational" />
                      </Col>
                      <Col span="12">
                        <LabelText>โรงพยาบาลประจำ</LabelText>
                        <TextInput type="text" name="hospital" placeholder="กรอกโรงพยาบาลประจำ" value={hospital} required onChange={handleChange} />
                        <ErrorMessage component={ErrMsg} name="hospital" />
                      </Col>

                      <Col span="12">
                        <Row gutter={[8, 8]}>
                          <Col span="18">
                            <LabelText>แผนก</LabelText>
                            <Select
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                              style={{ width: '100%' }}
                              name={role === 'doctor' ? 'doctorTypeId' : 'otherTypeId'}
                              value={role === 'doctor' ? doctorTypeId : otherTypeId}
                              onChange={(value) => setFieldValue(role === 'doctor' ? 'doctorTypeId' : 'otherTypeId', value)}
                            >
                              {role === 'doctor'
                                ? doctorTypes.map((type) => (
                                    <Option key={type.id} value={type.id}>
                                      {type.name}
                                    </Option>
                                  ))
                                : otherTypeForSelect.map((type) => (
                                    <Option key={type.id} value={type.id}>
                                      {type.name}
                                    </Option>
                                  ))}
                            </Select>
                            <ErrorMessage component={ErrMsg} name={role === 'doctor' ? 'doctorTypeId' : 'otherTypeId'} />
                          </Col>
                          <Col span="6" className="d-flex align-items-end">
                            <Button type="submit" onClick={() => setIsShowDepartmentModal(true)} primary style={{ flex: 1, marginRight: 15, width: '100%', heigh: '100%' }}>
                              เพิ่มแผนก
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col span="12">
                        <LabelText>ความเชี่ยวชาญ</LabelText>
                        <TextInput type="text" name="specialist" placeholder="กรอกความเชี่ยวชาญ" value={specialist} required onChange={handleChange} />
                        <ErrorMessage component={ErrMsg} name="specialist" />
                      </Col>
                      {role === 'doctor' && (
                        <Col span="12">
                          <LabelText>หมายเลขหมอ</LabelText>
                          <TextInput type="text" name="employeeId" placeholder="กรอกหมายเลขหมอ" value={employeeId} required onChange={handleChange} />
                          <ErrorMessage component={ErrMsg} name="employeeId" />
                        </Col>
                      )}

                      <Col span="12">
                        <LabelText>{role === 'doctor' ? 'เบอร์ติดต่อแพทย์' : 'เบอร์ติดต่อ'}</LabelText>
                        <TextInput type="text" name="tel" placeholder={role === 'doctor' ? 'เบอร์ติดต่อแพทย์' : 'เบอร์ติดต่อ'} value={tel} required onChange={handleChange} />
                        <ErrorMessage component={ErrMsg} name="tel" />
                      </Col>
                    </>
                  )}
                  <Col span="12">
                    <LabelText>
                      <span style={{ color: 'red' }}>*</span>รหัสผ่าน
                    </LabelText>
                    <TextInput type="password" name="password" placeholder="กรอกรหัสผ่าน" prefix={<KeyOutlined />} value={password} required onChange={handleChange} />
                    <ErrorMessage component={ErrMsg} name="password" />
                  </Col>
                  <Col span="12">
                    <LabelText>
                      <span style={{ color: 'red' }}>*</span>ยืนยันรหัสผ่าน
                    </LabelText>
                    <TextInput type="password" placeholder="ยืนยันรหัสผ่าน" name="confirmPassword" prefix={<KeyOutlined />} value={confirmPassword} required onChange={handleChange} />
                    <ErrorMessage component={ErrMsg} name="confirmPassword" />
                  </Col>
                  <Col span="4">
                    <LabelText>
                      <span style={{ color: 'red' }}>*</span>เพศ
                    </LabelText>
                    <RadioGroup name="gender" onChange={handleChange} value={gender}>
                      <Radio value="male">ชาย</Radio>
                      <Radio value="female">หญิง</Radio>
                    </RadioGroup>
                    <ErrorMessage component={ErrMsg} name="gender" />
                  </Col>
                  <Col span="4">
                    <LabelText>
                      <span style={{ color: 'red' }}>*</span>สัญชาติ
                    </LabelText>
                    <RadioGroup name="nationality" onChange={handleChange} value={nationality}>
                      <Radio value="thai">ไทย</Radio>
                      <Radio value="other">อื่น ๆ</Radio>
                    </RadioGroup>
                    <ErrorMessage component={ErrMsg} name="nationality" />
                  </Col>
                  <Col span="24" className=" mt-3">
                    <Button type="submit" size="large" onClick={handleSubmit} primary style={{ flex: 1, marginRight: 15, width: '100%' }}>
                      ลงทะเบียน
                    </Button>
                  </Col>
                </Row>
              </Card>
            </form>
            <Modal
              visible={isShowDepartmentModal}
              onOk={() => {
                setIsShowDepartmentModal(false)
              }}
              onCancel={() => {
                setIsShowDepartmentModal(false)
              }}
              title="เพิ่มแผนก"
              footer={null}
            >
              <AddDepartmentModal onSubmit={addDepartmentForm}></AddDepartmentModal>
            </Modal>
          </>
        )
      }}
    </Formik>
  )
}

RegisterForm.propTypes = {
  onSubmit: PropTypes.func,
  roles: PropTypes.array,
  doctorTypes: PropTypes.array,
  otherTypes: PropTypes.array,
  hasSubmitted: PropTypes.bool,
}

RegisterForm.defaultProps = {
  onSubmit: () => {},
  doctorTypes: [],
  otherTypes: [],
  roles: [],
}

export default RegisterForm

import axios from 'axios'
// import logger from './logService'
// import { toast } from 'react-toastify'

const instance = axios.create({
  baseURL: process.env.REACT_APP_MIRACART_API_URL
})

// axios.interceptors.response.use(null, error => {
//   const expectedError = error.response
//     && error.response.status >= 400
//     && error.response.status < 500

//   if (!expectedError) {
//     logger.log(error)
//     // toast.error('An unexpected error occurrred.')
//   }
//   return Promise.reject(error)
// })

function setJwt(jwt) {
  if (jwt) {
    // axios.defaults.headers.common['x-auth-token'] = jwt
    axios.defaults.params = {}
    axios.defaults.params['access_token'] = jwt //eslint-disable-line
  }
}

function clearJwt() {
  if (axios.defaults.params) {
    delete axios.defaults.params
  }
}

export default {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  setJwt,
  clearJwt
}

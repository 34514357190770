import React, { useEffect, useState } from 'react'
import Layout from '../Home'
import { Spin, Card, Space } from 'antd'
import { isEmpty } from 'lodash'
import { Container, ContainerLoading } from './style'
import { hooks } from './hooks'
import DonationEditForm from './DonationEditForm'
import { PatientInfo } from './PatientInfo'
const BloodDonationEditForm = (props) => {
  const { events, params } = hooks()
  const { history } = props
  const bookingId = props.match?.params?.bookingId
  useEffect(() => {
    if (!events.validateTextFormat(bookingId, true)) {
      history.push('/blood-donor-list')
    } else {
      events.dispatch(events.fetchBooking(bookingId))
      events.dispatch(events.fetchDonorFormById(bookingId))
    }
  }, [bookingId])

  useEffect(() => {
    if (params.donorForm) {
      events.getPatientInfo(params.donorForm?.cId)
    }
  }, [params.donorForm])

  useEffect(() => {
    if (params.errorMessage) {
      history.push('/blood-donor-list')
    }
  }, [params.errorMessage])

  return (
    <Layout
      history={history}
      render={() => {
        return params.isPatientLoading || params.isDonorBookingLoading || params.isFormDataLoading || isEmpty(params.donorForm) || isEmpty(params.donorBooking) ? (
          <ContainerLoading>
            <Spin size="large" />
          </ContainerLoading>
        ) : (
          <Container>
            <Space direction="vertical" size={12}>
              <Card>
                <PatientInfo patientInfo={params.patientInfo} donorLatestForm={params.donorForm} bookingData={params.donorBooking} className="mt-2" />
              </Card>
              <Card>
                <DonationEditForm formData={params.donorForm} bookingData={params.donorBooking} />
              </Card>
            </Space>
          </Container>
        )
      }}
    />
  )
}

export default BloodDonationEditForm

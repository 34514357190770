import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import httpOma from '../../services/httpServiceHealthCare'
import http from '../../services//httpServiceEver'
import { fetchDonorFormLists, updateDonorForm } from '../../store/bloodFormSlice'
import { updateBookingById } from '../../store/bloodBookingSlice'
import { addToEphis } from '../../apiServices/bloodBookingServices'
import { message } from 'antd'
import Axios from 'axios'
import moment from 'moment'
const hiePatientInfo = '/UserInfos/hiePatientInfo'
const hooks = () => {
  const [isPatientLoading, setIsPatientLoading] = useState(false)
  const [patientInfo, setPatientInfo] = useState(null)
  const [isCameraOn, setIsCameraOn] = useState(false)
  const [isInputCidOn, setIsInputCidOn] = useState(false)
  const [barcodeData, setBarcodeData] = useState('')
  const [cId, setCid] = useState(null)
  const inputRef = useRef(null)

  const dispatch = useDispatch()
  const [donorFormLists, donorLatestForm, isFormDataLoading, errorMessage] = useSelector((state) => {
    const { donorFormLists, donorLatestForm, isLoadingData, errorMessage } = state.bloodDonationForm
    return [donorFormLists, donorLatestForm, isLoadingData, errorMessage]
  })
  const [donorBooking, isDonorBookingLoading, donorErrorMessage] = useSelector((state) => {
    const { donorBooking, isLoadingData, errorMessage } = state.bloodDonationBooking
    return [donorBooking, isLoadingData, errorMessage]
  })

  useEffect(() => {
    if (barcodeData) {
      setCid(barcodeData)
    }
  }, [barcodeData])

  useEffect(() => {
    if (isInputCidOn) {
      inputRef?.current?.focus({
        cursor: 'end',
      })
    }
  }, [isInputCidOn])

  useEffect(() => {
    getPatientInfo(cId)
  }, [cId])

  const getAge = (birthDate) => {
    if (birthDate) {
      return moment().diff(birthDate, 'years', false)
    }
    return 0
  }

  const calculateNextAvailableTime = (birthDate) => {
    //return amount of months
    const age = getAge(birthDate)
    if (age >= 17 && age <= 62) {
      return 3
    } else if (age >= 63 && age <= 65) {
      return 4
    } else if (age > 65) {
      return 6
    }
    return 3
  }

  const getPatientInfo = async (cid) => {
    if (cid && cid.length === 13) {
      setIsPatientLoading(true)
      const response = await httpOma.get(`${hiePatientInfo}?cId=${cid}`)
      dispatch(fetchDonorFormLists(cid))
      if (response?.status <= 200 && response?.status >= 300) {
        setIsPatientLoading(false)
        message.error('เกิดข้อผิดพลาดในการรับข้อมูลประวัติบุคคล')
      } else {
        setPatientInfo(response.data)
        setIsInputCidOn(false)
        setIsPatientLoading(false)
      }
    } else if (cid) {
      setCid(null)
      setPatientInfo(null)
      message.error('เลขบัตรประชาชนไม่ถูกต้อง')
    }
  }

  const validateTextFormat = (str, isNumberOnly = false) => {
    //no thai text
    if (!str.length) {
      return true
    }
    const regex = /^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/
    const regexNumberOnly = /^(0|[1-9][0-9]*)$/
    return isNumberOnly ? regexNumberOnly.test(str) : regex.test(str)
  }

  const convertHn = (hn) => {
    const hnString = hn?.toString()
    const second = hnString.substring(0, 2)
    const past = hnString.substring(2).split('')
    let pass = 0
    const final = past.map((string) => {
      if (pass || string !== '0') {
        pass = 1
        return string
      }
    })
    return final.join('').concat('/', second)
  }

  const sendNotificationNextAvailableTime = async (nextTime, appUserId) => {
    try {
      const { data } = await http.get(`/userFcmTokens/getUserFcmTokenByUserId?userId=${appUserId}`)
      if (data && data.length) {
        data.forEach(async (res) => {
          let notification = {
            body: message,
            title: 'แจ้งเตือน',
            icon: 'ic_launcher',
            color: '#f1c40f',
            sound: 'default',
          }
          let data = {
            nextAvailableTime: nextTime,
            screen: 'BloodDonor',
          }
          await sendMessage(res?.fcmToken, notification, data)
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const sendMessage = async (to, notification, data) => {
    try {
      const { info } = await Axios.post(
        'https://fcm.googleapis.com/fcm/send',
        {
          to,
          notification,
          data,
        },
        {
          headers: {
            Authorization: `key=${process.env.REACT_APP_FCM_API_KEY || 'AAAAg2wfmIg:APA91bHTFQu2B2mGSeSmsVdad-2Lpirx_bVCtcS2T7qS4kX_efVbm60XQT2XKCO7M8EIqTl1dH467SYVvHG4r_0s5k-QNHbtF9X0u88iDo16hZDVum4QDSylJw1hl8NhMo9PKlt2qD_k'}`,
            'Content-Type': 'application/json',
          },
        },
      )
      return info
    } catch (error) {
      console.log(error)
    }
  }

  const updateFormAndBooking = async (formId, latestDonorFormData, bookingId, latestDonorBookingData) => {
    dispatch(
      updateDonorForm({
        formId,
        latestDonorFormData,
        callback: (isUpdateFormSuccess) => {
          if (isUpdateFormSuccess) {
            dispatch(
              updateBookingById({
                bookingId,
                latestDonorBookingData,
                callback: async (isUpdateBookingSuccess) => {
                  if (isUpdateBookingSuccess) {
                    message.success('บันทึกข้อมูลสำเร็จ')
                  } else {
                    message.error('บันทึกข้อมูลล้มเหลว เบื้องต้นกรุณาตรวจสอบข้อมูล หรือติดต่อเจ้าหน้าที่')
                  }
                },
              }),
            )
          }
        },
      }),
    )
  }
  return {
    events: {
      getPatientInfo,
      validateTextFormat,
      convertHn,
      dispatch,
      fetchDonorFormLists,
      updateDonorForm,
      updateBookingById,
      setIsCameraOn,
      setIsInputCidOn,
      setCid,
      setBarcodeData,
      setPatientInfo,
      calculateNextAvailableTime,
      sendNotificationNextAvailableTime,
      updateFormAndBooking,
      addToEphis,
    },
    params: {
      isPatientLoading,
      isFormDataLoading,
      isDonorBookingLoading,
      donorFormLists,
      donorLatestForm,
      donorErrorMessage,
      errorMessage,
      patientInfo,
      isCameraOn,
      isInputCidOn,
      barcodeData,
      cId,
      inputRef,
    },
  }
}

export { hooks }

import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Button } from 'antd'
import * as Yup from 'yup'

const ChangePasswordForm = ({ onSubmit }) => (
  <Formik
    initialValues={{
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    }}
    onSubmit={(values, { resetForm }) => {
      onSubmit(values)
      resetForm()
    }}
    validationSchema={Yup.object().shape({
      oldPassword: Yup.string()
        .min(4, 'กรุณากรอกรหัสผ่านเก่าอย่างน้อย 8 ตัวอักษร')
        .required('กรุณากรอกรหัสผ่านเก่า'),
      newPassword: Yup.string()
        .min(4, 'กรุณากรอกรหัสผ่านใหม่อย่างน้อย 8 ตัวอักษร')
        .required('กรุณากรอกรหัสผ่านใหม่'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'รหัสผ่านไม่ตรงกัน')
        .required('กรุณากรอกยืนยันรหัสผ่าน'),
    })}
    render={({ errors, status, touched }) => (
      <Form>
        <div className="form-group">
          <label htmlFor="email">รหัสผ่านเก่า</label>
          <Field name="oldPassword" type="password" className={`form-control${errors.oldPassword && touched.oldPassword ? ' is-invalid' : ''}`} />
          <ErrorMessage name="email" component="div" className="invalid-feedback" />
        </div>
        <div className="form-group">
          <label htmlFor="password">รหัสผ่านใหม่</label>
          <Field name="newPassword" type="password" className={`form-control${errors.newPassword && touched.newPassword ? ' is-invalid' : ''}`} />
          <ErrorMessage name="password" component="div" className="invalid-feedback" />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">ยืนยันรหัสผ่านใหม่</label>
          <Field name="confirmPassword" type="password" className={`form-control${errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : ''}`} />
          <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
        </div>
        <div className="form-group text-right">
          <Button type="primary" htmlType="submit">
            ยืนยัน
          </Button>
        </div>
      </Form>
    )}
  />
)

export default ChangePasswordForm

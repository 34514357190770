import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles, { CheckboxWithLabel, Column, Row } from '../styles'
import { AIRWAY, BREATH_SOUNDS, CIRCULATION_COLOR, EYES } from '../constant'
import { get, map, reduce } from 'lodash'

export default function MoreSymtomTable({ data }) {
  //* Airway
  const airways = get(data, "respiratory.airway", [])
  const airwaysArray = map(airways, (item) => item.includes("→") ? item.split("→")[0] : item)
  const airwaysMoreContent = reduce(airways, (acc, element) => {
    return element.includes("→") ? {
      ...acc, [element.split("→")[0]]: element.split("→").length > 1 ? element.split("→")[1] : ""
    }
      : { ...acc }
  }, {})
  //* Breath Sounds
  const breathSoundL = get(data, "respiratory.breathSoundL", [])
  const breathSoundR = get(data, "respiratory.breathSoundR", [])
  //* Eyes
  const recordTime = get(data,"record.time","")
  const recordTimeLog = get(data, "record.pupils", {});
  const ltNumber = get(recordTimeLog, "lt", "");
  const rtNumber = get(recordTimeLog, "rt", "")
  const eyeConditionsL = get(recordTimeLog, "ltRtl", "")
  const eyeConditionsR = get(recordTimeLog, "rtRtl", "")
  //* Circulation
  const pulse = get(data, "circulation.pulse", [])
  const capillaryRefill = get(data, "circulation.capillaryRefill", [])
  const capillaryRefillArray = map(capillaryRefill, (item) => item.includes("→") ? item.split("→")[0] : item)
  const capillaryRefillMoreContent = reduce(capillaryRefill, (acc, element) => {
    return element.includes("→") ? {
      ...acc,
      [element.split("→")[0]]: element.split("→").length > 1 ? element.split("→")[1] : ""
    } : { ...acc }
  }, {})
  const moisture = get(data, "circulation.moisture", [])
  const color = get(data, "circulation.color", [])

  return (
    <View style={styles.borderedContainer}>
      <Row>
        <Column key="airway" borderRight borderBottom width={85} paddingHorizontal={3}>
          <CheckboxWithLabel small justifyEnd title="N/A" checked={airwaysArray.includes("no_data")} />
          <Text style={styles.boldText}>Airway</Text>
          <Column paddingLeft={3}>
            {
              AIRWAY.map((e) => {
                return (
                  <CheckboxWithLabel small key={e} checked={airwaysArray.includes(e)} title={e} more={get(airwaysMoreContent, e, "")} />
                )
              })
            }
          </Column>
        </Column>
        <Column key="breathsound" borderRight borderBottom width={85} paddingHorizontal={3}>
          <CheckboxWithLabel small justifyEnd title="N/A" checked={breathSoundL.includes("no_data") && breathSoundR.includes("no_data")} />
          <Text style={styles.boldText}>Breath Sounds</Text>
          <Column>
            {BREATH_SOUNDS.map((e) => {
              return (
                <Row itemsCenter key={e}>
                  <LeftRightText selected={breathSoundR.includes(e)} right />
                  <Text style={styles.smallerText}>{e}</Text>
                  <LeftRightText selected={breathSoundL.includes(e)} left />
                </Row>)
            })}
          </Column>
        </Column>
        <Column key="eye" borderBottom width={85} paddingHorizontal={3}>
          <CheckboxWithLabel small justifyEnd title="N/A" checked={eyeConditionsL.includes("no_data") || eyeConditionsR.includes("no_data")} />
          <Text style={styles.boldText}>Eyes</Text>
          <Column>
            {EYES.map((e) => {
              return (
                <Row itemsCenter key={e.key}>
                  <LeftRightText number={recordTime && eyeConditionsR === e.key && rtNumber} selected={recordTime && rtNumber && eyeConditionsR === e.key} right />
                  <Text style={styles.smallerText}>{e.label}</Text>
                  <LeftRightText number={recordTime && eyeConditionsL === e.key && ltNumber} selected={recordTime && ltNumber && eyeConditionsL === e.key} left />
                </Row>
              )
            })}
          </Column>
        </Column>
      </Row>
      <Row flex={1} paddingHorizontal={3}>
        <Column flexGrow={1}>
          <Row spaceBetween>
            <Text style={styles.boldText}>Circulation</Text>
            <CheckboxWithLabel small justifyEnd title="N/A" checked={pulse.includes("no_data") && capillaryRefill.includes("no_data") && moisture.includes("no_data") && color.includes("no_data")} />
          </Row>
          <Row>
            <Column paddingRight={10}>
              <Text style={styles.normalText}>- Pulse</Text>
              <CheckboxWithLabel small key="reg" checked={pulse.includes("Regular")} title="Reg" />
              <CheckboxWithLabel small key="irr" checked={pulse.includes("Irregular")} title="Irr" />
              <Text style={styles.normalText}>- Capillary refill</Text>
              <CheckboxWithLabel small key="normal" checked={capillaryRefillArray.includes("Normal")} more={capillaryRefillMoreContent["Normal"]} title="Normal" />
              <CheckboxWithLabel small key="delayed" checked={capillaryRefillArray.includes("Delay")} more={capillaryRefillMoreContent["Delay"]} title="Delayed" />
            </Column>
            <Column paddingRight={20}>
              <Text style={styles.normalText}>- Moisture</Text>
              <Row>
                <Column paddingRight={5}>
                  <CheckboxWithLabel small key="normal" checked={moisture.includes("Normal")} title="Normal" />
                  <CheckboxWithLabel small key="dry" checked={moisture.includes("Dry")} title="Dry" />
                </Column>
                <Column>
                  <CheckboxWithLabel small key="moist" checked={moisture.includes("Moist")} title="Moist" />
                  <CheckboxWithLabel small key="diaphoresis" checked={moisture.includes("Diaphoresis")} title="Diaphoresis" />
                </Column>
              </Row>
            </Column>
            <Column>
              <Text style={styles.normalText}>- Color</Text>
              {CIRCULATION_COLOR.map((e) => {
                return (
                  <CheckboxWithLabel small key={e} checked={color.includes(e)} title={e} />
                )
              })}
            </Column>
          </Row>
        </Column>
      </Row>
    </View>
  )
}

const LeftRightText = ({ number = "", right, left, selected }) => {
  return (
    <Column>
      <View style={[selected ? { borderRadius: 999, borderWidth: 1, borderColor: 'grey' } : {}]}>
        <Text style={[styles.smallerText, { minWidth: 9 }]}>{number}{right ? "R" : left ? "L" : ""}</Text>
      </View>
    </Column>
  )
}

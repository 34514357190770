import { observable, action, } from 'mobx'
import http from '../../services/httpServiceHealthCare'
const apiEndpoint = '/Surveys'

class Survey {
  @observable info = {
    data: [],
    error: null,
  }

  @action fetchSurveys = async () => {
    try {
      const { data } = await http.get(`${apiEndpoint}`)
      return data
    } catch (error) {
      console.log(error)
    }
  }

}

export default new Survey()

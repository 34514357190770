import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import Text from '../../components/Text'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  align-items: center;
  cursor: pointer;
  ${props => props.isSelected && 'background-color: lightgray;'}
  .info {
    flex: 1;
    align-items: flex-end;
  }
  .ant-avatar {
    margin-right: 10px;
    width: 32px;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
    &.time {
      font-size: 12px;
    }
    &.name {
      flex: 1;
    }
  }
`
export default class Channel extends Component {
  static propTypes = {
    isSelected: PropTypes.bool.isRequired,
    message: PropTypes.object.isRequired,
    onClickPatientChannel: PropTypes.func.isRequired,
  }

  render() {
    const {
      message,
      onClickPatientChannel,
      isSelected
    } = this.props
    return (
      // eslint-disable-next-line
      <Container onClick={onClickPatientChannel(message.user._id)}
        isSelected={isSelected}
      >
        <Avatar src={message.user.avatar} />
        <div className="info">
          <Row>
            <Text className="name" bold>{message.user.name}</Text>
            <Text
              className="time"
              second
            >{moment(message.createdAt).fromNow()}
            </Text>
          </Row>
          <Text>{message.text}</Text>
        </div>
      </Container>
    )
  }
}

import React, { useEffect, useState } from 'react'
import { Input, Tag, Row, Col, Tabs, Spin, Card, Steps, DatePicker } from 'antd'
import { CarryOutOutlined, CalendarOutlined, VideoCameraOutlined, FileDoneOutlined, DollarOutlined, CheckCircleOutlined, FileExcelOutlined, HistoryOutlined, BellOutlined, LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import moment from 'moment'
import Table from '../Table'
import TextBase from '../Text'
import CheckHnnumber from '../CheckHnNumber'
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const { RangePicker } = DatePicker
const { TabPane } = Tabs
const { Search: SearchAnt } = Input
const { Step } = Steps
const Text = styled(TextBase)`
  font-weight: bold;
`
const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`
const dateFormatList = 'DD/MM/YYYY'

const CallCenterDashboardTable = ({ dataSource, setVisibleModalTracking, emsAvailable, history }) => {
  const location = history.location
  const [isLoading, setIsloading] = useState(false)
  const [bookings, setBookings] = useState([])
  const [todayBookings, setTodayBookings] = useState([])
  const [futureBookings, setFutureBookings] = useState([])
  const [pastBookings, setPastBookings] = useState([])
  const [filterBookings, setFilterBookings] = useState(false)
  const [filterTodayBookings, setFilterTodayBookings] = useState(false)
  const [filterFutureBookings, setFilterFutureBookings] = useState(false)
  const [filterPastBookings, setFilterPastBookings] = useState(false)
  const [currentTab, setCurrentTab] = useState('bookings')
  const [searchDate, setSearchDate] = useState([])
  useEffect(() => {
    handleBookings(dataSource)
    setCurrentTab(location.hash.split('#')[1])
  }, [dataSource])

  const handleBookings = (dataSource) => {
    setIsloading(true)
    let callCenterData = dataSource.filter((item) => {
      const isAllowedStatus =
        ['DOCTOR_PENDING', 'DOCTOR_CONFIRM', 'STAFF_PENDING', 'STAFF_CONFIRM', 'DOCTOR_PENDING_RX', 'DOCTOR_COMPLETED', 'STAFF_COMPLETED', 'EPHIS_PENDING', 'EPHIS_CONFIRM', 'PHARMACY_PENDING_BOOKING', 'PHARMACY_CONFIRM_BOOKING'].includes(
          item.status,
        ) ||
        (item.prescription &&
          item.prescription.status &&
          [
            'PHARMACY_PENDING_RX',
            'PHARMACY_CONFIRM_RX',
            'SUCCESS_BY_EMS',
            'SUCCESS_BY_PHARMACYSTORE',
            'SUCCESS_BY_PHARMACY',
            'SUCCESS_BY_PATIENT',
            'WAIT_FOR_PHARMACYSTORE_NOTIFY',
            'WAIT_FOR_PATIENT_PHARMACY',
            'WAIT_FOR_PATIENT_EMS',
            'WAIT_FOR_PATIENT_PHAMACYSTORE',
            'WAIT_FOR_PATIENT_HOSPITAL',
          ].includes(item.prescription.status)) ||
        (item.prescription && ['PATIENT_PENDING_PAYMENT', 'PATIENT_SUCCESS_PAYMENT'].includes(item.prescription.paymentStatus)) ||
        (item.status === 'DOCTOR_COMPLETED' && item.prescription && item.prescription.paymentStatus === 'PATIENT_PENDING_PAYMENT')
      return isAllowedStatus
    })

    let duplicatedBookingIdDoctor = []
    let duplicatedBookingIdPharmacy = []

    callCenterData.map((item) => {
      const filter = callCenterData.filter((x) => {
        if (item.id === x.doctorBookingId) {
          const isPharmacyBooking =
            (item.prescription &&
              ![
                'WAIT_FOR_PHARMACYSTORE_NOTIFY',
                'WAIT_FOR_PATIENT_PHARMACY',
                'WAIT_FOR_PATIENT_EMS',
                'WAIT_FOR_PATIENT_PHAMACYSTORE',
                'WAIT_FOR_PATIENT_HOSPITAL',
                'SUCCESS_BY_EMS',
                'SUCCESS_BY_PHARMACYSTORE',
                'SUCCESS_BY_PHARMACY',
                'SUCCESS_BY_PATIENT',
              ].includes(item.prescription.status) &&
              ['PHARMACY_PENDING_BOOKING', 'PHARMACY_CONFIRM_BOOKING'].includes(x.status)) ||
            (x.prescription && ['PHARMACY_PENDING_RX', 'PHARMACY_CONFIRM_RX'].includes(x.prescription.status))
          if (isPharmacyBooking) {
            return duplicatedBookingIdDoctor.push(item.id)
          } else {
            return duplicatedBookingIdPharmacy.push(item.id)
          }
        }
      })
      return item
    })
    if (duplicatedBookingIdDoctor) {
      //delete booking doctor for show only booking pharmacy
      callCenterData = callCenterData.filter((item) => !duplicatedBookingIdDoctor.includes(item.id))
    }
    if (duplicatedBookingIdPharmacy) {
      //delete booking pharmacy for show only booking doctor
      callCenterData = callCenterData.filter((item) => !duplicatedBookingIdPharmacy.includes(item.doctorBookingId))
    }

    callCenterData.sort((a, b) => {
      return a.bookingTime - b.bookingTime
    })

    setBookings(callCenterData)
    splitDatasourceByTimePeriod(callCenterData)
    setIsloading(false)
  }

  const splitDatasourceByTimePeriod = (callCenterData) => {
    setIsloading(true)
    setPastBookings([])
    setFutureBookings([])
    setTodayBookings([])
    let today = moment()
    today = today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    callCenterData.map((data) => {
      let admitTime = moment(data.admitTime)
      admitTime = admitTime.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      const checkDateDiff = today.diff(admitTime, 'days')
      if (checkDateDiff > 0) {
        //Push data for Past Booking Section
        setPastBookings((pastBookings) => [...pastBookings, data])
      } else if (checkDateDiff < 0) {
        //Push data for Future Booking Section
        setFutureBookings((futureBookings) => [...futureBookings, data])
      } else {
        //Push data for Future Booking Section
        setTodayBookings((todayBookings) => [...todayBookings, data])
      }
    })
    setIsloading(false)
  }
  const convertHn = (hn) => {
    const second = hn.substring(0, 2)
    const past = hn.substring(2).split('')
    let pass = 0
    const final = past.map((string) => {
      if (pass || string !== '0') {
        pass = 1
        return string
      }
    })
    return final.join('').concat('/', second)
  }

  const handleStatusSteps = (record) => {
    let step = 0
    const isNotEmsLogistic = record.prescription && [1, 3].includes(record.prescription.logisticId)
    if (isNotEmsLogistic) {
      const logisticId = record.prescription.logisticId
      const prescriptionStatus = record.prescription.status
      if (logisticId === 1) {
        if (['DOCTOR_CONFIRM', 'EPHIS_PENDING'].includes(record.status) || (record.status === 'DOCTOR_COMPLETED' && prescriptionStatus === 'PHARMACY_PENDING_RX')) {
          return 0
        }
        if (prescriptionStatus === 'WAIT_FOR_PHARMACYSTORE_NOTIFY') {
          return 1
        }
        if (prescriptionStatus === 'WAIT_FOR_PATIENT_PHAMACYSTORE') {
          return 2
        }
        if (prescriptionStatus === 'SUCCESS_BY_PHARMACYSTORE') {
          return 3
        }
      }
      if (logisticId === 3) {
        if (['DOCTOR_CONFIRM', 'EPHIS_PENDING'].includes(record.status) || (record.status === 'DOCTOR_COMPLETED' && prescriptionStatus === 'PHARMACY_PENDING_RX')) {
          return 0
        } else if (prescriptionStatus === 'WAIT_FOR_PATIENT_HOSPITAL') {
          return 1
        } else if (prescriptionStatus === 'SUCCESS_BY_PHARMACY') {
          return 2
        }
      }
    }
    if (record.status === 'DOCTOR_COMPLETED') {
      const isPrescriptionAndNotSuccessPayment = record.prescription && record.prescription.status === 'DRAFT_PRESCRIPTION' && record.prescription.paymentStatus !== 'PATIENT_SUCCESS_PAYMENT'
      const isPrescriptionAndSuccessPayment = record.prescription && ['PATIENT_SUCCESS_PAYMENT'].includes(record.prescription.paymentStatus)

      if (!record.prescription || isPrescriptionAndNotSuccessPayment) {
        return 2
      }
      if (isPrescriptionAndSuccessPayment) {
        return 3
      }
    }
    if (record.prescription) {
      const prescriptionStatus = record.prescription.status
      const paymentStatus = record.prescription.paymentStatus
      if (record.status === 'STAFF_COMPLETED' && paymentStatus === 'PATIENT_SUCCESS_PAYMENT') {
        return 2
      }
      if (['SUCCESS_BY_EMS', 'SUCCESS_BY_PHARMACYSTORE', 'SUCCESS_BY_PHARMACY', 'SUCCESS_BY_PATIENT'].includes(prescriptionStatus)) {
        return (step = 7)
      } else if (paymentStatus === 'PATIENT_SUCCESS_PAYMENT' || ['WAIT_FOR_PHARMACYSTORE_NOTIFY', 'WAIT_FOR_PATIENT_PHARMACY', 'WAIT_FOR_PATIENT_EMS', 'WAIT_FOR_PATIENT_PHAMACYSTORE'].includes(prescriptionStatus)) {
        return (step = 6)
      } else if (['PHARMACY_CONFIRM_RX'].includes(prescriptionStatus)) {
        return (step = 5)
      } else if (['PHARMACY_PENDING_RX', 'WAIT_FOR_PATIENT_HOSPITAL'].includes(prescriptionStatus) && record.status !== 'EPHIS_CONFIRM') {
        return (step = 5)
      }
    }
    const status = record.status
    switch (status) {
      case 'DOCTOR_PENDING':
      case 'DOCTOR_PENDING_RX':
      case 'STAFF_CONFIRM':
      case 'DOCTOR_CONFIRM': {
        step = 0
        break
      }
      case 'EPHIS_PENDING':
      case 'STAFF_COMPLETED':
        step = 1
        break
      case 'EPHIS_CONFIRM':
        step = 2
        break
      case 'PHARMACY_PENDING_BOOKING':
        step = 3
        break
      case 'PHARMACY_CONFIRM_BOOKING':
        step = 4
        break
      default:
        step = 0
        break
    }
    return step
  }

  const columns = [
    {
      title: 'วันที่นัดหมาย',
      key: 'appointmentDate',
      width: 100,
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.admitTime).unix() - moment(b.admitTime).unix(),
      render: (record) => <Text>{moment(record.admitTime).format('DD/MM/YYYY')}</Text>,
      align: 'center',
    },
    {
      title: 'เวลานัดหมาย',
      key: 'appointmentTime',
      width: 70,
      render: (record) => (
        <Text>
          {moment()
            .startOf('day')
            .add(record.bookingTime, 'minutes')
            .format('HH:mm')}
        </Text>
      ),
      align: 'center',
    },
    {
      title: 'ชื่อ-นามสกุล',
      key: 'fullname',
      width: 200,
      render: (record) => <Text>{record.patient?.fullnameHIE || record.patient?.fullname || '-'}</Text>,
      align: 'center',
    },
    {
      title: 'HN',
      key: 'hn',
      align: 'center',
      width: 80,
      render: (record) => (
        <Text>
          <CheckHnnumber key={record} data={record} loading={<Spin />} />
        </Text>
      ),
    },
    {
      title: 'เจ้าหน้าที่',
      key: 'doctorName',
      align: 'center',
      width: 100,
      sorter: (a, b) => {
        let doctorA = a.doctor ? (a.doctor.fullnameHIE ? a.doctor.fullnameHIE : a.doctor.fullname) : '-'
        let doctorB = b.doctor ? (b.doctor.fullnameHIE ? b.doctor.fullnameHIE : b.doctor.fullname) : '-'
        return doctorA.localeCompare(doctorB)
      },
      render: (record) => <Text>{record.doctor ? (record.doctor.fullnameHIE ? record.doctor.fullnameHIE : record.doctor.fullname) : '-'}</Text>,
    },
    {
      title: 'แผนก',
      key: 'doctorDepartment',
      align: 'center',
      width: 150,
      sorter: (a, b) => {
        let departmentA = a.doctor && a.doctor.detail.department ? a.doctor.detail.department : ''
        let departmentB = b.doctor && b.doctor.detail.department ? b.doctor.detail.department : ''
        return departmentA.localeCompare(departmentB)
      },
      render: (record) => (
        <Text
          onClick={() => {
            console.log(record)
          }}
        >
          {(record.doctor && record.doctor.detail?.department) || '-'}
        </Text>
      ),
    },
    {
      title: 'สถานะ',
      key: 'status',
      align: 'center',
      width: 400,
      sorter: (a, b) => {
        a = a.status.includes('DOCTOR_COMPLETED') && (!a.prescription || (a.prescription && a.prescription.status === 'DRAFT_PRESCRIPTION')) ? 8 : handleStatusSteps(a)
        b = b.status.includes('DOCTOR_COMPLETED') && (!b.prescription || (b.prescription && b.prescription.status === 'DRAFT_PRESCRIPTION')) ? 8 : handleStatusSteps(b)
        return a - b
      },
      render: (record) => {
        const step = handleStatusSteps(record)
        return record.prescription && [1, 3].includes(record.prescription.logisticId) ? (
          <>
            {record.prescription.logisticId === 1 ? (
              <div style={{ width: '100%' }}>
                <Steps current={step} labelPlacement="vertical" size="small">
                  <Step title="ยืนยันรายการยา" icon={<CarryOutOutlined />} />
                  <Step title="กำลังจัดส่งไปร้านยา" icon={<HistoryOutlined />} />
                  <Step title="ร้านยาได้รับยาและแจ้งผู้ป่วยแล้ว" icon={<BellOutlined />} />
                  <Step title="ผู้ป่วยมารับยาที่ร้านแล้ว" icon={<CheckCircleOutlined />} />
                </Steps>
              </div>
            ) : (
              record.prescription.logisticId === 3 && (
                <div style={{ width: '100%' }}>
                  <Steps current={step} labelPlacement="vertical" size="small">
                    <Step title="ยืนยันรายการยา" icon={<CarryOutOutlined />} />
                    <Step title="รอผู้ป่วยมารับยาที่ รพ." icon={<HistoryOutlined />} />
                    <Step title="ผู้ป่วยมารับยาที่ รพ. แล้ว" icon={<CheckCircleOutlined />} />
                  </Steps>
                </div>
              )
            )}
          </>
        ) : record.status.includes('DOCTOR_COMPLETED') && (!record.prescription || (record.prescription && record.prescription.status === 'DRAFT_PRESCRIPTION')) ? (
          <div style={{ width: '100%' }}>
            <Steps current={step} labelPlacement="vertical" size="small">
              <Step title="ยืนยันนัดหมาย" icon={<CarryOutOutlined />} />
              <Step title="แพทย์ Call เสร็จสิ้น" icon={<VideoCameraOutlined />} />
              <Step title="ไม่มีรายการยา" icon={<FileExcelOutlined />} />
              {record.prescription && ['PATIENT_PENDING_PAYMENT', 'PATIENT_SUCCESS_PAYMENT'].includes(record.prescription.paymentStatus) && (
                <>
                  <Step title="ชำระเงิน" icon={<CheckCircleOutlined />} />
                </>
              )}
            </Steps>
          </div>
        ) : record.status.includes('STAFF') ? (
          <div style={{ width: '100%' }}>
            <Steps current={step} labelPlacement="vertical" size="small">
              <Step title="ยืนยันนัดหมาย" icon={<CarryOutOutlined />} />
              <Step title="เจ้าหน้าที่ Call เสร็จสิ้น" icon={<VideoCameraOutlined />} />
              <Step title="ชำระเงิน" icon={<DollarOutlined />} />
            </Steps>
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            <Steps current={step} labelPlacement="vertical" size="small">
              <Step title="ยืนยันนัดหมาย" icon={<CarryOutOutlined />} />
              <Step title="แพทย์ Call เสร็จสิ้น" icon={<VideoCameraOutlined />} />
              <Step title="ยืนยันรายการยา" icon={<FileDoneOutlined />} />
              <Step title="นัดหมายเภสัชกร" icon={<CalendarOutlined />} />
              <Step title="เภสัชกรยืนยันนัดหมาย" icon={<CarryOutOutlined />} />
              <Step title="เภสัชกร Call เสร็จสิ้น" icon={<VideoCameraOutlined />} />
              <Step title="ชำระเงิน" icon={<DollarOutlined />} />
              <Step title="ได้รับยา" icon={<CheckCircleOutlined />} />
            </Steps>
          </div>
        )
      },
    },
  ]
  const handleDateChange = (date, key) => {
    if (date) {
      searchByDate(date, key)
      setSearchDate(date)
    } else {
      clearSearchData()
      setSearchDate([])
    }
  }

  const searchByDate = (date, key) => {
    switch (key) {
      case 'bookings':
        const filterBookingsData = bookings.filter((item) => {
          return moment(item.admitTime).isSameOrAfter(moment(date[0]), 'day') && moment(item.admitTime).isSameOrBefore(moment(date[1]), 'day')
        })
        setFilterBookings(filterBookingsData)
        break
      case 'todayBookings':
        const filterToday = todayBookings.filter((item) => {
          return moment(item.admitTime).isSameOrAfter(moment(date[0]), 'day') && moment(item.admitTime).isSameOrBefore(moment(date[1]), 'day')
        })
        setFilterTodayBookings(filterToday)
        break
      case 'pastBookings':
        const filterPast = pastBookings.filter((item) => {
          return moment(item.admitTime).isSameOrAfter(moment(date[0]), 'day') && moment(item.admitTime).isSameOrBefore(moment(date[1]), 'day')
        })
        setFilterPastBookings(filterPast)
        break
      case 'futureBookings':
        const filterFuture = futureBookings.filter((item) => {
          return moment(item.admitTime).isSameOrAfter(moment(date[0]), 'day') && moment(item.admitTime).isSameOrBefore(moment(date[1]), 'day')
        })
        setFilterFutureBookings(filterFuture)
        break
      default:
        clearSearchData()
        break
    }
  }

  const onSearch = (data, key) => {
    if (data.length) {
      let dataForFilter = []
      switch (key) {
        case 'bookings':
          dataForFilter = searchDate.length ? filterBookings : bookings
          break
        case 'todayBookings':
          dataForFilter = searchDate.length ? filterTodayBookings : todayBookings
          break
        case 'pastBookings':
          dataForFilter = searchDate.length ? filterPastBookings : pastBookings
          break
        case 'futureBookings':
          dataForFilter = searchDate.length ? filterFutureBookings : futureBookings
          break
        default:
          dataForFilter = searchDate.length ? filterBookings : bookings
          break
      }

      const dataFiltered = dataForFilter.filter((item) => {
        return (
          (item.patient && item.patient.fullnameHIE.includes(data)) ||
          (item.patient && item.patient.fullname.includes(data)) ||
          (item.patient && item.patient.hnId.includes(data)) ||
          (item.patient && item.patient.cId.includes(data)) ||
          (item.doctor && item.doctor.fullnameHIE && item.doctor.fullnameHIE.includes(data)) ||
          (item.doctor && item.doctor.fullname.includes(data)) ||
          (item.doctor && item.doctor.detail && item.doctor.detail.department.includes(data)) ||
          item.status.includes(data)
        )
      })
      switch (key) {
        case 'bookings':
          setFilterBookings(dataFiltered)
          break
        case 'todayBookings':
          setFilterTodayBookings(dataFiltered)
          break
        case 'pastBookings':
          setFilterPastBookings(dataFiltered)
          break
        case 'futureBookings':
          setFilterFutureBookings(dataFiltered)
          break
        default:
          setFilterBookings(dataFiltered)
          break
      }
    } else {
      if (searchDate.length) {
        clearSearchData()
        searchByDate(searchDate, key)
      } else {
        clearSearchData()
      }
    }
  }

  const clearSearchData = () => {
    setFilterBookings(false)
    setFilterTodayBookings(false)
    setFilterPastBookings(false)
    setFilterFutureBookings(false)
  }
  return (
    <>
      <div className="w-100">
        <Card>
          <Tabs
            activeKey={currentTab ? currentTab : location.hash.split('#')[1]}
            defaultActiveKey={currentTab ? currentTab : location.hash.split('#')[1]}
            onChange={(tab) => {
              setCurrentTab(tab)
              history.replace({
                hash: `${tab}`,
              })
            }}
          >
            <TabPane tab={`นัดหมายทั้งหมด (${bookings.length})`} key="bookings">
              <Row gutter={[16, 16]} className="w-100 my-2" justify="center">
                <Col xs={24} md={7}>
                  <RangePicker
                    placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                    ranges={{
                      วันนี้: [moment(), moment()],
                      เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
                    }}
                    format={dateFormatList}
                    onChange={(date) => handleDateChange(date, 'bookings')}
                    className="w-100"
                  />
                </Col>
                <Col xs={24} md={15}>
                  <Search allowClear onSearch={(data) => onSearch(data, 'bookings')} className="w-100" placeholder="HN, ชื่อ-นามสกุล, หมายเลขบัตรประชาชน, ชื่อ-นามสกุลแพทย์, แผนก" />
                </Col>
              </Row>

              <Spin spinning={isLoading}>
                <Table
                  scroll={{ x: 'max-content' }}
                  columns={columns}
                  dataSource={filterBookings || bookings}
                  onChange={(page) => {
                    history.replace({
                      hash: currentTab,
                      state: { page: page.current },
                    })
                  }}
                  pagination={{ current: location.state && location.state.page ? location.state.page : 1, pageSize: 7 }}
                />
              </Spin>
            </TabPane>
            <TabPane tab={`นัดหมายวันนี้ (${todayBookings.length})`} key="todayBookings">
              <Row gutter={[16, 16]} className="w-100 my-2" justify="center">
                <Col xs={24} md={7}>
                  <RangePicker
                    placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                    ranges={{
                      วันนี้: [moment(), moment()],
                      เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
                    }}
                    format={dateFormatList}
                    onChange={(date) => handleDateChange(date, 'todayBookings')}
                    className="w-100"
                  />
                </Col>
                <Col xs={24} md={15}>
                  <Search allowClear onSearch={(data) => onSearch(data, 'todayBookings')} className="w-100" placeholder="HN, ชื่อ-นามสกุล, หมายเลขบัตรประชาชน, ชื่อ-นามสกุลแพทย์, แผนก" />
                </Col>
              </Row>

              <Spin spinning={isLoading}>
                <Table
                  scroll={{ x: 'max-content' }}
                  columns={columns}
                  dataSource={filterTodayBookings || todayBookings}
                  onChange={(page) => {
                    history.replace({
                      hash: currentTab,
                      state: { page: page.current },
                    })
                  }}
                  pagination={{ current: location.state && location.state.page ? location.state.page : 1, pageSize: 7 }}
                />
              </Spin>
            </TabPane>
            <TabPane tab={`นัดหมายที่กำลังจะมาถึง (${futureBookings.length})`} key="futureBookings">
              <Row gutter={[16, 16]} className="w-100 my-2" justify="center">
                <Col xs={24} md={7}>
                  <RangePicker
                    placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                    ranges={{
                      วันนี้: [moment(), moment()],
                      เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
                    }}
                    format={dateFormatList}
                    onChange={(date) => handleDateChange(date, 'futureBookings')}
                    className="w-100"
                  />
                </Col>
                <Col xs={24} md={15}>
                  <Search allowClear onSearch={(data) => onSearch(data, 'futureBookings')} className="w-100" placeholder="HN, ชื่อ-นามสกุล, หมายเลขบัตรประชาชน, ชื่อ-นามสกุลแพทย์, แผนก" />
                </Col>
              </Row>

              <Spin spinning={isLoading}>
                <Table
                  scroll={{ x: 'max-content' }}
                  columns={columns}
                  dataSource={filterFutureBookings || futureBookings}
                  onChange={(page) => {
                    history.replace({
                      hash: currentTab,
                      state: { page: page.current },
                    })
                  }}
                  pagination={{ current: location.state && location.state.page ? location.state.page : 1, pageSize: 7 }}
                />
              </Spin>
            </TabPane>
            <TabPane tab={`นัดหมายที่ผ่านมา (${pastBookings.length})`} key="pastBookings">
              <Row gutter={[16, 16]} className="w-100 my-2" justify="center">
                <Col xs={24} md={7}>
                  <RangePicker
                    placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                    ranges={{
                      วันนี้: [moment(), moment()],
                      เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
                    }}
                    format={dateFormatList}
                    onChange={(date) => handleDateChange(date, 'pastBookings')}
                    className="w-100"
                  />
                </Col>
                <Col xs={24} md={15}>
                  <Search allowClear onSearch={(data) => onSearch(data, 'pastBookings')} className="w-100" placeholder="HN, ชื่อ-นามสกุล, หมายเลขบัตรประชาชน, ชื่อ-นามสกุลแพทย์, แผนก" />
                </Col>
              </Row>

              <Spin spinning={isLoading}>
                <Table
                  scroll={{ x: 'max-content' }}
                  columns={columns}
                  dataSource={filterPastBookings || pastBookings}
                  onChange={(page) => {
                    history.replace({
                      hash: currentTab,
                      state: { page: page.current },
                    })
                  }}
                  pagination={{ current: location.state && location.state.page ? location.state.page : 1, pageSize: 7 }}
                />
              </Spin>
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </>
  )
}

export default CallCenterDashboardTable

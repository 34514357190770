import React from 'react'
import { Font, StyleSheet, View, Text } from '@react-pdf/renderer'
import FontRegular from '../../asset/fonts/Sarabun-Regular.ttf'
import FontBold from '../../asset/fonts/Sarabun-Bold.ttf'
import FontItalic from '../../asset/fonts/Sarabun-Italic.ttf'
import Checkbox from './widgets/Checkbox'
import CheckboxActive from './widgets/CheckboxActive'

Font.register({
  family: 'Sarabun',
  fonts: [
    {
      src: FontRegular,
    },
    {
      src: FontBold,
      fontWeight: 'bold',
    },
    {
      src: FontItalic,
      fontStyle: 'italic',
    },
  ],
})

const normalFont = 'Sarabun'
const normalSize = 8
const smallSize = 7
const smallLineHeight = 10
const titleSize = 8.5
const lineHeight = 11.5

const styles = StyleSheet.create({
  titleText: {
    fontFamily: normalFont,
    fontSize: titleSize,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 3,
  },
  normalText: {
    minHeight: lineHeight,
    fontFamily: normalFont,
    fontSize: normalSize,
    maxLines: 1,
    // textOverflow: 'ellipsis',
    paddingHorizontal: 3,
  },
  normalHighlightText: {
    minHeight: lineHeight,
    fontFamily: normalFont,
    fontSize: normalSize,
    fontWeight: 'bold',
    maxLines: 1,
    // textOverflow: 'ellipsis',
    paddingHorizontal: 3,
  },
  smallerText: {
    fontFamily: normalFont,
    fontSize: smallSize,
    paddingHorizontal: 3,
    maxLines: 1,
    // textOverflow: 'ellipsis',
    // paddingVertical: 1
  },
  smallLabel: {
    fontFamily: normalFont,
    fontSize: smallSize,
    paddingHorizontal: 1,
    maxLines: 1,
    // textOverflow: 'ellipsis',
  },
  boldText: {
    fontFamily: normalFont,
    fontSize: normalSize,
    fontWeight: 'bold',
    paddingHorizontal: 3,
  },
  mainContainer: {
    padding: 12,
  },
  borderedContainer: {
    borderWidth: 1,
    borderColor: 'black',
    marginVertical: 10,
  },
  borderedContainerGrey: {
    width: '100%',
    borderWidth: 1,
    borderColor: 'grey',
  },
})

export default styles

export const CheckboxWithLabel = ({ checked, title, justifyEnd, small, paddingHorizontal, paddingVertical, more, maxWidth }) => {
  const size = small ? 7 : 9
  return (
    <Column paddingVertical={0} maxWidth={maxWidth}>
      <Row itemsCenter justifyEnd={justifyEnd} paddingHorizontal={paddingHorizontal} paddingVertical={paddingVertical}>
        {checked ? <CheckboxActive width={size} height={size} /> : <Checkbox width={size} height={size} />}
        <Text style={[small ? styles.smallLabel : styles.normalText]}>
          {title} {more}
        </Text>
      </Row>
    </Column>
  )
}

export const Column = ({
  children,
  height,
  width,
  maxWidth,
  flex,
  flexGrow,
  borderColor = 'black',
  backgroundColor,
  grey,
  borderRight,
  borderBottom,
  borderTop,
  itemsCenter,
  justifyCenter,
  paddingVertical = 1,
  paddingHorizontal,
  paddingLeft,
  paddingRight,
  position,
  spaceBetween,
}) => {
  return (
    <View
      style={[
        {
          position,
          // overflow: 'auto',
          // textOverflow: 'ellipsis',
          borderColor,
          paddingVertical,
          paddingHorizontal,
          backgroundColor,
          paddingLeft,
          paddingRight,
          flexGrow,
          flex,
          height,
          width,
          maxWidth,
        },
        grey && { borderColor: 'grey' },
        itemsCenter && { alignItems: 'center' },
        spaceBetween && { justifyContent: 'space-between' },
        justifyCenter && { justifyContent: 'center' },
        borderRight && { borderRightWidth: borderRight ? borderRight : 1 },
        borderBottom && { borderBottomWidth: borderBottom ? borderBottom : 1 },
        borderTop && { borderTopWidth: borderTop ? borderTop : 1 },
      ]}
    >
      {children}
    </View>
  )
}

export const Row = ({
  children,
  center,
  flex,
  itemsCenter,
  position,
  itemsTop,
  spaceBetween,
  spaceAround,
  spaceEvenly,
  justifyEnd,
  borderRight,
  grey,
  borderBottom,
  paddingHorizontal,
  paddingVertical,
  wrap,
  width,
  borderColor = 'black',
}) => {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          // overflow: 'hidden',
          // textOverflow: 'ellipsis',
          flex,
          position,
          width,
          borderColor,
          paddingHorizontal,
          paddingVertical,
        },
        grey && { borderColor: 'grey' },
        center && { alignItems: 'center', justifyContent: 'center' },
        itemsCenter && { alignItems: 'center' },
        itemsTop && { alignItems: 'flex-start' },
        spaceBetween && { justifyContent: 'space-between' },
        spaceAround && { justifyContent: 'space-around' },
        spaceEvenly && { justifyContent: 'space-evenly' },
        wrap && { flexWrap: 'wrap' },
        justifyEnd && { justifyContent: 'flex-end' },
        borderRight && { borderRightWidth: borderRight ? borderRight : 1 },
        borderBottom && { borderBottomWidth: borderBottom ? borderBottom : 1 },
      ]}
    >
      {children}
    </View>
  )
}

export const Divider = ({ height, width, color, marginVertical, marginBottom }) => {
  return (
    <View
      style={{
        height: height ? height : 1,
        width: width ? width : '100%',
        backgroundColor: color ? color : 'black',
        alignSelf: 'center',
        marginBottom,
        marginVertical: marginVertical ? marginVertical : 2,
      }}
    />
  )
}

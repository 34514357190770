import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import styles, { CheckboxWithLabel, Column, Row } from '../styles'
import { get } from 'lodash'
import moment from 'moment'
import Barcode from 'react-hooks-barcode'
import 'moment/locale/th'
export default function PatientTable({ data, patientInfo, convertHn }) {
  const convertForPDF = (text) => {
    return JSON.stringify(`${text || ''}`).replaceAll('"', ' ')
  }

  return (
    <View style={styles.borderedContainer}>
      <Row>
        <Column borderBottom borderRight width={60}>
          <Text style={styles.normalHighlightText}>{convertForPDF(`คำนำหน้าชื่อ   `)}</Text>
          <Text style={styles.normalHighlightText}>{`${patientInfo?.fullname?.title ?? ''}`}</Text>
        </Column>
        <Column borderBottom borderRight width={180}>
          <Text style={styles.normalHighlightText}>{`ชื่อ นามสกุล      `}</Text>
          <Text style={styles.normalHighlightText}>{convertForPDF(data?.pdfName)}</Text>
        </Column>
        <Column borderBottom borderRight flex={1}>
          <Text style={styles.normalHighlightText}>เลขบัตรประชาชน</Text>
          <Text style={styles.normalHighlightText}>{`${patientInfo?.cid ?? ''}`}</Text>
        </Column>
        <Column borderBottom flex={1}>
          <Text style={styles.normalText}>วัน/เดือน/ปีเกิด</Text>
          <Text style={styles.normalText}>{moment(data?.appUser?.userInformation?.birthDate).isValid() ? moment(data?.appUser?.userInformation?.birthDate).format('LL') : '-'}</Text>
        </Column>
      </Row>
      <Row>
        <Column borderBottom borderRight flex={1}>
          <Text style={styles.normalText}>HN</Text>
          <Text style={styles.normalText}>{convertHn(patientInfo?.hn[0]?.hn)}</Text>
        </Column>
        <Column borderBottom borderRight flex={1}>
          <Text style={styles.normalText}>เพศ</Text>
          <Row paddingHorizontal={3}>
            <CheckboxWithLabel small checked={patientInfo?.sexName === 'ชาย'} title="ชาย" />
            <CheckboxWithLabel small checked={patientInfo?.sexName === 'หญิง'} title="หญิง" />
          </Row>
        </Column>
        <Column borderBottom borderRight flex={1}>
          <Text style={styles.normalText}>น้ำหนัก </Text>
          <Text style={styles.normalText}>{`${data?.bloodDonationForm?.weight ? data?.bloodDonationForm?.weight + ' กิโลกรัม' : '-'}`}</Text>
        </Column>
        <Column borderBottom flex={1}>
          <Text style={styles.normalText}>อาชีพ </Text>
          <Text style={styles.normalText}>{convertForPDF(`${data?.bloodDonationForm?.occupation ?? '-'}`)}</Text>
        </Column>
      </Row>
      <Row>
        <Column borderRight width={400}>
          <Text style={styles.normalHighlightText}>ที่อยู่</Text>
          <Text style={styles.normalHighlightText}>
            {convertForPDF(
              `${data?.appUser?.userInformation?.address?.address ?? ''} ${data?.appUser?.userInformation?.address?.address2 ?? ''} อำเภอ ${data?.appUser?.userInformation?.address?.area ?? ''} จังหวัด ${data?.appUser?.userInformation
                ?.address?.province ?? ''} รหัสไปรษณีย์ ${data?.appUser?.userInformation?.address?.postalCode ?? ''} `,
            )}
          </Text>
        </Column>
        <Column flex={1}>
          <Text style={styles.normalHighlightText}>เบอร์ติดต่อ</Text>
          <Row paddingHorizontal={3}>
            <Text style={styles.normalHighlightText}>{`${data?.appUser?.userInformation?.mobileNumber ?? ''} `}</Text>
          </Row>
        </Column>
        <Barcode value={data?.bloodDonationForm?.cId} />
        <Column>
          <Barcode value={data?.bloodDonationForm?.cId} />
        </Column>
      </Row>
    </View>
  )
}

import { Button, Input, Spin } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import ToggleToken from '../../ToggleToken'
import TextInput from '../../TextInput'
import './style.css'
import SignArea from '../../SignArea'
import { get } from 'lodash'

const index = ({ emsTypologiesData, updateEmsForm, formDetail, formValues, setFormValues }) => {
  const [isFormLoaded, setFormLoaded] = useState(false)
  const [isTypologyLoaded, setIsTypologyLoaded] = useState(false)
  const [signArea, setSignArea] = useState(false)

  const stabilities = ['ไม่มีการรักษา', 'ทุเลา', 'คงเดิม', 'ทรุดหนัก', 'เสียชีวิต ณ จุดเกิดเหตุ', 'เสียชีวิตขณะนำส่ง']
  const triageLabels = [{ label: 'แดง', color: '#c12222' }, { label: 'เหลือง', color: '#dbb211' }, { label: 'เขียว', color: '#009534' }, { label: 'ขาว' }, { label: 'ดำ', color: '#3a3a3a' }]
  const erTriageLabels = [{ label: 'วิกฤต', color: '#c12222' }, { label: 'เร่งด่วน', color: '#dbb211' }, { label: 'ไม่รุนแรง', color: '#009534' }, { label: 'ทั่วไป' }, { label: 'รับบริการสาธารณสุขอื่นๆ', color: '#3a3a3a' }]
  const procedure = ['ไม่จำเป็น', 'ไม่ได้ทำ', 'ทำและเหมาะสม', 'ทำแต่ไม่เหมาะสม']

  const [ASSESSOR, SET_ASSESSOR] = useState([])
  const [REPORTER, SET_REPORTER] = useState([])

  useEffect(() => {
    console.log(formDetail)
    if (formDetail.transferData) {
      const transferData = {
        stability: get(formDetail, 'transferData.stability', ''),
        onSceneTriage: get(formDetail, 'transferData.onSceneTriage', ''),
        destination: {
          hospital: get(formDetail, 'transferData.destination.hospital', ''),
          type: get(formDetail, 'transferData.destination.type', ''),
        },
        reasons: get(formDetail, 'transferData.reasons', []) || [],
        reporter: {
          name: get(formDetail, 'transferData.reporter.name', ''),
          position: get(formDetail, 'transferData.reporter.position', ''),
        },
        assessor: {
          name: get(formDetail, 'transferData.assessor.name', ''),
          signature: get(formDetail, 'transferData.assessor.signature', ''),
          position: get(formDetail, 'transferData.assessor.position', ''),
        },
        assessment: {
          hn: get(formDetail, 'transferData.assessment.hn', ''),
          diagnosis: get(formDetail, 'transferData.assessment.diagnosis', ''),
          erTriageLabel: get(formDetail, 'transferData.assessment.erTriageLabel', ''),
          breathingCare: get(formDetail, 'transferData.assessment.breathingCare', []) || [],
          respiratoryCare: get(formDetail, 'transferData.assessment.respiratoryCare', []) || [],
          bloodCirculationCare: get(formDetail, 'transferData.assessment.bloodCirculationCare', []) || [],
          boneCast: get(formDetail, 'transferData.assessment.boneCast', []) || [],
          bleedingCare: get(formDetail, 'transferData.assessment.bleedingCare', []) || [],
        },
      }
      console.log('transferData')
      console.log(transferData)
      setFormValues(transferData)
    }
    setFormLoaded(true)
  }, [formDetail])
  useEffect(() => {
    if (emsTypologiesData.length > 0) {
      handleEmsTypologies(emsTypologiesData)
      setIsTypologyLoaded(true)
    }
  }, [emsTypologiesData])

  const handleEmsTypologies = (emsTypologiesData) => {
    const ASS = emsTypologiesData.filter((item) => item.field === 'ASSESSOR')
    const REP = emsTypologiesData.filter((item) => item.field === 'REPORTER')
    SET_ASSESSOR(ASS.map((item) => item.label))
    SET_REPORTER(REP.map((item) => item.label))
  }

  return (
    <Spin spinning={!isFormLoaded}>
      {!isFormLoaded ? (
        <div style={{ height: 800 }}></div>
      ) : (
        <div id="transfer-to-definite-care">
          <div className="column-view">
            <div id="transferring" className="section">
              <ToggleToken title="ผลการดูแลรักษาขั้นต้น" options={stabilities} max={1} values={formValues['stability']} setValues={(value) => setFormValues({ stability: value })} toStr />
              <ToggleToken title="ระดับการคัดแยก ณ จุดเกิดเหตุ" options={triageLabels} max={1} values={formValues['onSceneTriage']} setValues={(value) => setFormValues({ onSceneTriage: value })} toStr />
              <div className="toggle-token-panel">
                <div className="toggle-token-title">
                  <h2>เกณฑ์การตัดสินใจส่งโรงพยาบาล</h2>
                </div>
                <div className="toggle-token-text">
                  <TextInput label="นำส่งห้องฉุกเฉินโรงพยาบาล" id="destination" values={formValues['destination']['hospital']} onInput={(e) => setFormValues({ destination: { ...formValues['destination'], hospital: e.target.value } })} />
                </div>
                <div className="toggle-token-text">
                  <ToggleToken options={['โรงพยาบาลรัฐ', 'โรงพยาบาลเอกชน']} max={1} values={formValues['destination']['type']} setValues={(value) => setFormValues({ destination: { ...formValues['destination'], type: value } })} toStr />
                </div>
              </div>
              <ToggleToken title="เหตุผล" options={['เหมาะสม/สามารถรักษาได้', 'อยู่ใกล้', 'มีหลักประกัน', 'เป็นผู้ป่วยเก่า', 'เป็นความประสงค์']} values={formValues['reasons']} setValues={(value) => setFormValues({ reasons: value })} />
              <div style={{ marginBottom: '2em' }}>
                <h2>ผู้สรุปรายงาน</h2>
                <div className="button-group">
                  <Input value={formValues['reporter']['name']} onInput={(e) => setFormValues({ reporter: { ...formValues['reporter'], name: e.target.value } })} />
                  {/* <Button className="sign-button" type="primary" onClick={() => setSignArea1(true)}>{signature1 ? "เซ็นใหม่" : "ลงชื่อ"}</Button> */}
                  {isTypologyLoaded ? <ToggleToken options={REPORTER} max={1} values={formValues['reporter']['position']} setValues={(value) => setFormValues({ reporter: { ...formValues['reporter'], position: value } })} toStr /> : <></>}
                </div>
              </div>
            </div>
            <div id="report" className="section">
              <div>
                <h1>การประเมินการนำส่ง</h1>
                <TextInput label="HN" id="hn" value={formValues['assessment']['hn']} onInput={(e) => setFormValues({ assessment: { ...formValues['assessment'], hn: e.target.value } })} />
                <TextInput label="การวินิจฉัย" id="diagnosis" value={formValues['assessment']['diagnosis']} onInput={(e) => setFormValues({ assessment: { ...formValues['assessment'], diagnosis: e.target.value } })} />
              </div>
              <ToggleToken
                title="ระดับการคัดกรอง"
                options={erTriageLabels}
                max={1}
                values={formValues['assessment']['erTriageLabel']}
                setValues={(value) => setFormValues({ assessment: { ...formValues['assessment'], erTriageLabel: value } })}
                toStr
              />
              <ToggleToken
                title="การดูแลระบบหายใจ"
                options={procedure}
                max={1}
                values={formValues['assessment']['breathingCare']}
                setValues={(value) => setFormValues({ assessment: { ...formValues['assessment'], breathingCare: value } })}
                extraInput="ทำแต่ไม่เหมาะสม"
              />
              <ToggleToken
                title="การดูแลระบบทางเดินหายใจ"
                options={procedure}
                max={1}
                values={formValues['assessment']['respiratoryCare']}
                setValues={(value) => setFormValues({ assessment: { ...formValues['assessment'], respiratoryCare: value } })}
                extraInput="ทำแต่ไม่เหมาะสม"
              />
              <ToggleToken
                title="การดูแลระบบไหลเวียนโลหิต"
                options={procedure}
                max={1}
                values={formValues['assessment']['bloodCirculationCare']}
                setValues={(value) => setFormValues({ assessment: { ...formValues['assessment'], bloodCirculationCare: value } })}
                extraInput="ทำแต่ไม่เหมาะสม"
              />
              <ToggleToken
                title="การดามกระดูก"
                options={procedure}
                max={1}
                values={formValues['assessment']['boneCast']}
                setValues={(value) => setFormValues({ assessment: { ...formValues['assessment'], boneCast: value } })}
                extraInput="ทำแต่ไม่เหมาะสม"
              />
              <ToggleToken
                title="การห้ามเลือด"
                options={procedure}
                max={1}
                values={formValues['assessment']['bleedingCare']}
                setValues={(value) => setFormValues({ assessment: { ...formValues['assessment'], bleedingCare: value } })}
                extraInput="ทำแต่ไม่เหมาะสม"
              />
              <h2>ผู้ประเมิน</h2>
              {formValues['assessor']['signature'] ? <img src={formValues['assessor']['signature']} /> : <></>}
              <Input className="assessor" value={formValues['assessor']['name']} onInput={(e) => setFormValues({ assessor: { ...formValues['assessor'], name: e.target.value } })}></Input>
              <div className="button-group">
                <Button className="sign-button" type="primary" onClick={() => setSignArea(true)}>
                  {formValues['assessor']['signature'] ? 'เซ็นใหม่' : 'ลงชื่อ'}
                </Button>
                {isTypologyLoaded ? <ToggleToken options={ASSESSOR} max={1} values={formValues['assessor']['position']} setValues={(value) => setFormValues({ assessor: { ...formValues['assessor'], position: value } })} toStr /> : <></>}
              </div>
            </div>
          </div>
          <SignArea title="ผู้ประเมิน" signature={(value) => setFormValues({ assessor: { ...formValues['assessor'], signature: value } })} isOn={signArea} close={setSignArea} />
        </div>
      )}
    </Spin>
  )
}
export default index

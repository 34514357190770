import React, { useEffect } from 'react'
import Tabs from '../../Tabs'
import Table from '../../Table'
import { Spin, Card } from 'antd'
import styled from 'styled-components'
import { tabTableInfo } from './tabTableConstanst'
import FirstTabReport from './FirstTabReport'
import { FilterableTable } from './FirstTabReport'
const { TabPane } = Tabs
const Container = styled.div`
  width: 100%;
  padding: 20px;
`
const startDefault = new Date(new Date().setHours(0, 0, 0, 0))
const endDefault = new Date()
export default (props) => {
  const { onChangeTab, events, fetchEmsForm, fetchEmsFormCancel, fetchEmsFormPass, fetchEmsFormReject, state } = props

  useEffect(() => {
    fetchEmsFormCancel(startDefault, endDefault)
    fetchEmsFormPass(startDefault, endDefault)
    fetchEmsFormReject(startDefault, endDefault)
  }, [])

  const onSearchFilterByDateCancel = (values) => {
    fetchEmsFormCancel(values.startDateTime ? values.startDateTime.toDate() : startDefault, values.endDateTime ? values.endDateTime.toDate() : endDefault)
  }

  const onSearchFilterByDatePass = (values) => {
    fetchEmsFormPass(values.startDateTime ? values.startDateTime.toDate() : startDefault, values.endDateTime ? values.endDateTime.toDate() : endDefault)
  }

  const onSearchFilterByDateReject = (values) => {
    fetchEmsFormReject(values.startDateTime ? new Date(values.startDateTime) : new Date(startDefault), values.endDateTime ? new Date(values.endDateTime) : new Date(endDefault))
  }

  const validateMessages = {
    required: 'กรุณากรอก ${label}',
  }
  return (
    <Tabs defaultActiveKey="1" onChange={(key) => onChangeTab(key)}>
      {tabTableInfo.map((item, index) => (
        <TabPane tab={item.tabName} key={item.key}>
          {item.key == 1 ? (
            <FirstTabReport {...item} isLoading={state.isLoading} fetchEmsForm={fetchEmsForm} events={events} dataSource={events[item.tabDataSource]} />
          ) : (
            <Card>
              <Container>
                <FilterableTable onSearchFilterByDate={item.key == 2 ? onSearchFilterByDateCancel : item.key == 4 ? onSearchFilterByDateReject : onSearchFilterByDatePass} validateMessages={validateMessages} />
                <Spin spinning={state.isLoading}>
                  <Table scroll={{ x: '50vw' }} columns={events[item.column]} dataSource={events[item.tabDataSource]} />
                </Spin>
              </Container>
            </Card>
          )}
        </TabPane>
      ))}
    </Tabs>
  )
}

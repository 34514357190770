import { PureComponent } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import * as Chat from 'twilio-chat'

const getChannel = async (channels, email) => {
  if (channels.items.length) return channels.items[0]
  return Chat.createChannel({
    uniqueName: email,
    friendlyName: email
  })
}

@inject('Communication')
@observer
class FetchChatToken extends PureComponent {
  state = {
    token: '',
    channel: '',
  }

  async componentDidMount() {
    const { email } = this.props
    if (email) {
      const { Communication } = this.props
      await Communication.fetchToken(email)
      const token = Communication.getToken
      const client = await Chat.Client.create(token)
      const channels = await client.getPublicChannelDescriptors()
      const channel = await getChannel(channels, email)
        .then(_channel => _channel.getChannel())
        .then(_channel => _channel.join())
      this.setState({ channel, token })
    }
  }

  render() {
    const { render } = this.props
    return (
      render(this.state)
    )
  }
}
FetchChatToken.propTypes = {
  Communication: PropTypes.object, //eslint-disable-line
  email: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
}

export default FetchChatToken
